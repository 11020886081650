import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

const UpdateAcknowledgeBtnComponent1 = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        // props.context.componentParent.onClickEditProductionData(props);
        props.context.onClickupdateAcknowledgeBtn1(props);
    };
    var colr = props.data.comment_exists;
    
    return (
        <div>
       <button
            style={{ color: "#fff" }}
            onClick={handleRouteClick}
            className="btn btn-primary custom-btn f12 label label-success"
            title=""
        >
            Update & Ack
        </button>
    </div>
    );
};

export default UpdateAcknowledgeBtnComponent1;
