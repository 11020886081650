import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');

var criticaldata=[]
export default class ColumnGroupComponent extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng"
		}

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()
		$(".highcharts-drilldown-point").on("click",function(e){
			console.log("E ",e)
		})

	}
	componentWillReceiveProps (newprops){
		//document.getElementById ("clicktransport").addEventListener ("click", getData, false);
		// var el = document.getElementById('clicktransport');
		// if(el){
		//   el.addEventListener('click', getData, false);
		// }

		//console.log("asdasad",newprops.critical_data)
		//criticaldata=this.series.userOptions.critical_data;
	}
	hideGrid(params){
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params){
		hideTransGrid(params)
	}
    render(){
    	//console.log("this.props.code) ", this.props.code)
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: {
					  type: 'column',
					  height:'490'
	      		  },
	              plotOptions: {
                    column: {
                        pointPadding: 0.2,
						borderWidth: 0,
						dataLabels: {
							enabled: true
						}
					},
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							crop: false,
							overflow: "none",
							//format: '{point.y}'
							formatter: function() {
								var tooltip="";
								var totalcnt = 	this.series.chart.series[0].data;
								var toolseries = this.series.chart.series
								var cnt = this.series.yData[0]
								var percent = parseInt(Math.round((cnt/totalcnt[0].y)*100))
								if(this.series.name == 'Total')
								{
									tooltip += this.series.yData[0]
								}
								else{
									tooltip += this.series.yData[0]+' ('+percent+'%)'
								}
								console.log("tooltip ", tooltip)
								return tooltip;
							},
						},
						cursor: 'pointer',
						point: {
							events: {
								click: async function (params) {	
														
									//console.log("Series" ,this.series)
									if(this.series.name == "Critical")
									{							
										var relateddata = this.series.userOptions.critical_list;
										var grouped = groupBy(relateddata, rdata => rdata.transporters_name);
										var convertdata = []
										grouped.forEach((gitem, key) => {
											if(key != '')
											{
												var itemtotcnt=0;
												var minarr=[];
												var maxarr=[]
												gitem.map((irem) => {
													//console.log("irem ", irem)
													itemtotcnt=itemtotcnt+parseInt(irem.len)
													minarr.push(parseInt(irem.min))
													maxarr.push(parseInt(irem.max))
													
												})
												var consignergrouped = groupBy(gitem, rdata => rdata.consigner_code);
												//console.log("consignergrouped ", consignergrouped)
												var consignerarr=[];
												consignergrouped.forEach((cons,ky)=>{
													var concnt=0;
													cons.map((cn) =>{
														concnt=concnt+parseInt(cn.len);
													})
													consignerarr.push({
														consigner:ky,
														count:concnt		
													})
												})
												//console.log("consignerarr ",consignerarr);
												convertdata.push({
													//delay_type:irem.delay_type,
													len:parseInt(itemtotcnt),
													max:arrayMax(maxarr),
													min:arrayMin(minarr),
													transporters_name:key,
													consigners:consignerarr
												})
											}
										})
										
										convertdata.sort(GetSortOrder("len"));
										$("#sptitle"+this.series.userOptions.code).html(this.series.userOptions.dept_name+" - Critical Delay");
										var html = '<div class="todo-options" style="margin:30px 10px;">'
										html +='<ul>';
										convertdata.forEach(item => {
											if(item.min == item.max)
											{
												var drange = item.min;
											}
											else{
												var drange = item.min+" - "+item.max;
											}
											
											if(item.transporters_name != '')
											{
												var transportname = item.transporters_name;
											}
											else{
												var transportname = 'NA'
											}
											html +='<li><div class="col-xl-12 col-lg-12 f16 bordergray n-p-0 textAlignCenter"><h4 class="summary-grid-title">'+transportname+'</h4><div class="col-xl-12 col-lg-12 row"><div class="col-xl-5 col-lg-5"><div class="col-xl-12 col-lg-12 f40 hcolor transporter" ><a href="javascript:;" data="'+JSON.stringify(this.series.userOptions.critical_data)+'" deptcode="'+this.series.userOptions.code+'"  dept_code="'+this.series.userOptions.dept_code+'" class="clicktransport" transporter="'+transportname+'" data-type="transporter" >'+item.len+'</a></div><div class="col-xl-12 col-lg-12 summary-body">Delay Range<br /> '+drange+' days</div></div><div class="col-xl-7 col-lg-7" style="border-left:1px solid #ccc;">';
											//html += '<div class="col-xl-12 col-lg-12">Plant</div>';
											html += '<div class="todo-options">'
											html +='<ul>';
											item.consigners.forEach(consigner => {
												console.log("consigner[0].consigner ",consigner.consigner)
												html +='<li class="textAlignLeft"><a href="javascript:;"  deptcode="'+this.series.userOptions.code+'"  dept_code="'+this.series.userOptions.dept_code+'" consigner="'+consigner.consigner+'" class="clickconsigner" transporter="'+transportname+'" data-type="transporter" >'+consigner.consigner+'<span class="badge badge-pill txt-danger pull-right mr-0 cpointer" >'+consigner.count+'</span></a></li>';	
												
											});
											html +='</ul>';
											html +='</div></div></div></li>'
										})
										
										html +='</ul>';
										html +='</div>';

										$("#grid"+this.series.userOptions.code).html(html);

										$(".grid-"+this.series.userOptions.code).fadeIn();
									}
									
								}
							}
						}
					}
				},
				
                title: {
                    text: this.props.title
                },
                subtitle: {
                    text: this.props.subtitle
				},
				
				xAxis: {
					categories: [''],
					title: {
						text: null
					},
					labels: {
						enabled:true,
						y : 20, 
						rotation: -45, 
						align: 'right'
					}

				},
				yAxis: {
					min: 0,
					title: {
						text: this.props.yaxistitle
					}
				},
				tooltip: {
					formatter: function() {
						var tooltip="";
						var totalcnt = 	this.series.chart.series[0].data;
						var toolseries = this.series.chart.series
						var cnt = this.series.yData[0]
						var percent = parseInt(Math.round((cnt/totalcnt[0].y)*100))
						if(this.series.name == 'Total')
						{
							tooltip += '<span style="color:'+this.series.color+'">'+this.series.name+'</span>: <b>'+this.series.yData[0]
						}
						else{
							tooltip += '<span style="color:'+this.series.color+'">'+this.series.name+'</span>: <b>'+this.series.yData[0]+' ('+percent+'%)'
						}
						
						return tooltip;
					},
				},
				series: this.props.series,
	      		colors:[ '#4099ff','#0abb80','#f3d800', '#FF5370'],
                  credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
					},
					getTransportData:function(params){
						console.log("Tras", params)
					}
	      		};
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
				<div className={"summary-grid grid-"+(this.props.code)} >
					<div className="textAlignCenter">
						<span id={"sptitle"+this.props.code} className="summary-span"></span>
						<span className={"float-right btn-warning summary-back cpointer"} id={this.props.code} onClick={this.hideGrid.bind(this,this.props.code)}>Back</span>
					</div>
					<div id={"grid"+this.props.code}></div>
				</div>
				<div className={"summary-grid grid-trans-"+(this.props.code)} >
					<div className="textAlignCenter">
						<span id={"sptitle"+this.props.code} className="summary-span"></span>
						<span className="float-right btn-warning summary-back cpointer hidetransport z999" style={{top:"16px"}} id={this.props.code}>Back</span>
					</div>
					<div className={"gridtrans"+this.props.code}></div>
				</div>
				<div className={"dataLoadpage dataLoadpagesum"+(this.props.code)}></div>
				<div className={"dataLoadpageimg dataLoadpageimgsum"+(this.props.code)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part z9999 overlay-partsum"+(this.props.code)}></div>
			
			</div>
			);
		}
}


function hideGrid(id)
{
	console.log("id ",id)
	$(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
	console.log("id ",id)
	$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }

// function getData(){
// 	alert("cons")
// }

$(document).ready(function(){
	
})

$(document).on('click',".clicktransport", function(){
	var criticaldata=[];
	var transporter = $(this).attr("transporter")
	var deptcode = $(this).attr("deptcode");
	var transporterdata = $(this).attr("data")
	$(".dataLoadpagesum"+deptcode).show()
	$(".dataLoadpageimgsum"+deptcode).show()		
	$(".overlay-partsum"+deptcode).show()	
	var dept_code = $(this).attr("dept_code");
	var rqparams={
		dept_code:dept_code
	}
	//console.log("dept_code ",dept_code);
	if(dept_code == "LOG-PRT (Courier)")
	{
		var blocktitle='Consignment Code';
	}
	else{
		var blocktitle='Truck No';
	}
	redirectURL.post("/consignments/summarysecondleveldata",rqparams)
	.then(async (res) =>{
		criticaldata=res.data.message[0].critical_data;
		console.log("criticaldata ", criticaldata)
		$(".dataLoadpagesum"+deptcode).hide()
		$(".dataLoadpageimgsum"+deptcode).hide()
		$(".overlay-partsum"+deptcode).hide();	
		var criticaldataarr=[]
		if(criticaldata.length > 0)
		{
			criticaldata.map((item) =>{
				if(dept_code == 'LOG-SC')
				{
					criticaldataarr.push({
						container_no:item.container_no,
						country_code:item.country_code,
						critical_msil_eta:item.critical_msil_eta,
						delay_reason:item.delay_reason,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						delay_reason:item.delay_reason,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:item.status,
						transit_delay_days:parseInt(item.transit_delay_days),
						transporters_name:item.transporters_name,
						supplier_code:(item.supplier_code == "")?"NA":item.supplier_code,
						uom:item.uom,
					})
				}
				else
				{
					criticaldataarr.push({
						consignee_city:item.consignee_city,
						consignee_code:item.consignee_code,
						consignee_name:item.consignee_name,
						consigner_code:item.consigner_code,
						consignment_code:item.consignment_code,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						delay_reason:item.delay_reason,
						expected_trip_end:item.expected_trip_end,
						invoice_date:item.invoice_date,
						plant_location:item.plant_location,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:parseInt(item.status),
						transit_delay_days:parseInt(item.transit_delay_days),
						transporter_name:item.transporter_name,
						transporters_name:item.transporters_name,
						uom:item.uom,
					})
				}
			})
		}
		//alert(transporter)
		console.log("criticaldata here,", criticaldata)
		criticaldataarr.sort(GetSortOrder("transit_delay_days"));

		var filterdata=[]
		var containerimprt=[]
		if(criticaldataarr.length > 0)
		{
			criticaldataarr.map((item)=>{
				if(item.dept_code == 'LOG-SC')
				{
					//console.log("Itemm ", item)
					if(item.transporters_name == transporter){
						containerimprt.push(item)
					}
				}
				else
				{
					if(item.transporter_name == transporter || item.transporters_name == transporter){
						filterdata.push(item)
					}
				}
			})
		}
		//console.log("containerimprt ",containerimprt)
		//console.log("filterdata ", filterdata)
		var html = '<div class="col-xl-12 col-lg-12 mt-10p here"><div class="col-xl-12 col-lg-12 n-p-0"><h4 class="summary-grid-title">'+transporter+'</h4></div>';
		//console.log("filterdata.length ", filterdata.length)
		if(filterdata.length > 0)
		{
			var i=0;
			filterdata.map((itm) =>{
				if(i%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">'+blocktitle+'</span><br /><span class="f16">'+itm.consignment_code+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Loading Plant</span><br /><span class="f16">'+itm.consigner_code+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Consignee Name</span><br /><span class="f16">'+((itm.consignee_name == "")?"NA":itm.consignee_name)+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Destination City</span><br /><span class="f16">'+((itm.consignee_city == "")?"NA":itm.consignee_city)+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Original ETA</span><br /><span class="f16">'+getDDMMYYYY(itm.expected_trip_end)+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></label></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></label></div>';
				html += '</div>';
				i=i+1
			})
		}
		//console.log("containerimprt.length", containerimprt.length)
		/*LOG-SC Container Imports Critical Delay view*/
		if(containerimprt.length > 0)
		{
			var n=0;
			containerimprt.map((itm) =>{
				if(n%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Container No</span><br /><span class="f16">'+itm.container_no+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">POL/Country of Origin</span><br /><span class="f16">'+itm.country_code+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Supplier</span><br /><span class="f16">'+itm.supplier_code+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Current Status </span><br /><span class="f16">'+itm.status+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">MSIL Requirement Date</span><br /><span class="f16">'+getDDMMYYYY(itm.critical_msil_eta)+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></label></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></label></div>';
				html += '</div>';
				i=i+1
			})
		}
		html += '</div>'
		console.log(html)
		console.log("deptcode ",deptcode)
		$(".grid-"+deptcode).hide();
		$(".gridtrans"+deptcode).html(html);
		
		$(".grid-trans-"+deptcode).show();
	})
	.catch(function(e){
		console.log("Error ",e)
	})

});


$(document).on('click',".clickconsigner", function(){
	var criticaldata=[];
	var consigner = $(this).attr("consigner")
	var transporter = $(this).attr("transporter")
	var deptcode = $(this).attr("deptcode");
	$(".dataLoadpagesum"+deptcode).show()
	$(".dataLoadpageimgsum"+deptcode).show()		
	$(".overlay-partsum"+deptcode).show()	
	var dept_code = $(this).attr("dept_code");
	var rqparams={
		dept_code:dept_code
	}
	if(dept_code == "LOG-PRT (Courier)")
	{
		var blocktitle='Consignment Code';
	}
	else{
		var blocktitle='Truck No';
	}
	//console.log("rqparams ",rqparams);
	redirectURL.post("/consignments/summarysecondleveldata",rqparams)
	.then(async (res) =>{
		//console.log("res.data.message[0] ",res.data.message[0])
		criticaldata=res.data.message[0].critical_data;
		var dept_code=res.data.message[0].dept_code;
		$(".dataLoadpagesum"+deptcode).hide()
		$(".dataLoadpageimgsum"+deptcode).hide()
		$(".overlay-partsum"+deptcode).hide();	
	

		var criticaldataarr=[]
		if(criticaldata.length > 0)
		{
			criticaldata.map((item) =>{
				if(dept_code == 'LOG-SC')
				{
					criticaldataarr.push({
						container_no:item.container_no,
						country_code:item.country_code,
						critical_msil_eta:item.critical_msil_eta,
						delay_reason:item.delay_reason,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						delay_reason:item.delay_reason,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:item.status,
						transit_delay_days:parseInt(item.transit_delay_days),
						transporters_name:item.transporters_name,
						supplier_code:(item.supplier_code == "")?"NA":item.supplier_code,
						uom:item.uom,
					})
				}
				else
				{
					criticaldataarr.push({
						consignee_city:item.consignee_city,
						consignee_code:item.consignee_code,
						consignee_name:item.consignee_name,
						consigner_code:item.consigner_code,
						consignment_code:item.consignment_code,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						delay_reason:item.delay_reason,
						expected_trip_end:item.expected_trip_end,
						invoice_date:item.invoice_date,
						plant_location:item.plant_location,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:parseInt(item.status),
						transit_delay_days:parseInt(item.transit_delay_days),
						transporter_name:item.transporter_name,
						transporters_name:item.transporters_name,
						uom:item.uom,
					})
				}
			})
		}
		//alert(transporter)
		console.log("criticaldata here,", criticaldata)
		criticaldataarr.sort(GetSortOrder("transit_delay_days"));

		var filterdata=[]
		var containerimprt= []
		if(criticaldataarr.length > 0)
		{
			criticaldataarr.map((item)=>{
				if(item.dept_code == 'LOG-SC')
				{
					if(item.country_code == consigner && item.transporters_name == transporter)
					{
						containerimprt.push(item)
					}
				}
				else
				{
					if(item.consigner_code == consigner && (item.transporter_name == transporter || item.transporters_name == transporter)){
						filterdata.push(item)
					}
				}
			})
		}
		var html = '<div class="col-xl-12 col-lg-12 mt-10p"><div class="col-xl-12 col-lg-12 n-p-0"><h4 class="summary-grid-title">'+transporter+" - "+consigner+'</h4></div>';
		if(filterdata.length > 0)
		{
			var i=0;
			filterdata.map((itm) =>{
				if(i%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">'+blocktitle+'</span><br /><span class="f16">'+itm.consignment_code+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Loading Plant</span><br /><span class="f16">'+itm.consigner_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Consignee Name</span><br /><span class="f16">'+itm.consignee_name+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Destination City</span><br /><span class="f16">'+itm.consignee_city+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Original ETA</span><br /><span class="f16">'+getDDMMYYYY(itm.expected_trip_end)+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
				html += '</div>';
				i=i+1
			})
		}

		/*LOG-SC Container Imports Critical Delay view*/
		if(containerimprt.length > 0)
		{
			var n=0;
			containerimprt.map((itm) =>{
				if(n%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Container No</span><br /><span class="f16">'+itm.container_no+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">POL/Country of Origin</span><br /><span class="f16">'+itm.country_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Supplier</span><br /><span class="f16">'+itm.supplier_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Current Status</span><br /><span class="f16">'+itm.status+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">MSIL Requirement Date</span><br /><span class="f16">'+getDDMMYYYY(itm.critical_msil_eta)+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
				html += '</div>';
				i=i+1
			})
		}
		html += '</div>'
		//console.log(html)
		//console.log("deptcode ",deptcode)
		$(".grid-"+deptcode).hide();
		$(".gridtrans"+deptcode).html(html);
		$(".grid-trans-"+deptcode).show();
	})
	.catch(function(e){
		console.log("Error ",e)
	})

	
   //alert(transporter)
	//console.log("criticaldata here,", criticaldata)
	
});

$(document).on('click',".hidetransport", function(){
	var id = $(this).attr("id")
	$(".grid-"+id).show();
	$(".grid-trans-"+id).hide();
});