import React, { useEffect, useRef, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { countries } from "country-flags-svg";
import moment from "moment";
import "./eximgrid.css"


const EximGrid = ({ container_data, paginationSize}) => {
  console.log("eximgrid",paginationSize)

  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.name.toLowerCase() == countryName.toLowerCase()) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };

   
  const gridRef = useRef();
  const flagImage=prop=>(prop.value.countryFlag=="" || prop.value.countryFlag==undefined)?<>NA</>:
  <><img src={prop.value.countryFlag} alt="SRC" className="flag_class" />&nbsp;&nbsp;{prop.value.countryName}</>
  const [state, setState] = useState({
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      pivot: true,
      enableValue: true,
      enableRowGroup: false,
      menuTabs: ['filterMenuTab']
    },
    rowData: "",
    container_columnDefs: [
      {
        headerName: "Container No",
        field: "container_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.container_no==="")?"NA":params.data.container_no;
          } else {
            return "";
          }
        },
      },
      {
        headerName: "BL No",
        field: "mbl_number",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.mbl_number==="")?"NA":params.data.mbl_number;
          } else {
            return "NA";
          }
        },
      },

      {
        headerName: "Booking No",
        field: "booking_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.booking_no==="")?"NA":params.data.booking_no;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Work Order Number",
        field: "work_order_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.work_order_no==="")?"NA":params.data.work_order_no;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Truck Number",
        field: "truck_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.truck_no==="")?"NA":params.data.truck_no;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Ocean Line",
        field: "shipping_line",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.shipping_line==="")?"NA":params.data.shipping_line;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Origin Country",
        field: "source_location",
        filter: true,
        width: 180,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            let countryFlag=getFlagIcon(params.data.source_location)
            return (countryFlag===null?"NA":{countryFlag:countryFlag?.img, countryName:countryFlag?.name});
          } else {
            return "";
          }
        },
        cellRenderer: flagImage,
      },
      {
        headerName: "Destination Country",
        field: "destination_location",
        filter: true,
        width: 180,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            let countryFlag=getFlagIcon(params.data.destination_location)
            return (countryFlag===null?"NA":{countryFlag:countryFlag?.img, countryName:countryFlag?.name});
          } else {
            return "";
          }
        },
        cellRenderer: flagImage,
      },
      {
        headerName: "Last Event",
        field: "event_name",
        filter: true,
        resizable: true,
        width: 250,
        valueGetter: function (params) {
          if (params) {
            if(params.data.status==="NEW")
            return "Newly Added"
            else if(params.data.status==="UNTRACKABLE")
            return "Untrackable"
            return (params.data.last_event_data[0].event_name===""?"NA":params.data.last_event_data[0].event_name);
            // return "Event name";
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Event Status",
        field: "event_status",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return (params.data.last_event_data[0].status===""?"NA":params.data.last_event_data[0].status);
            // return ""
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Created Date",
        field: "created_date",
        filter: true,
        width: 200,
        resizable: true,
        comparator: (date1, date2) => {
          if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
              let d1 = moment(date1,'DD/MM/YYYY HH:mm')
              let d2 = moment(date2,'DD/MM/YYYY HH:mm')
              return d2.isBefore(d1) ? 1: -1
          }
      },
        valueGetter: function (params) {
          if (params) {
            if(params.data.status==="NEW" || params.data.status==="UNTRACKABLE")
            return "NA"
            return moment
              .parseZone(params.data.created_date)
              .utcOffset("+05:30")
              .format("DD/MM/YYYY HH:mm");
          } else {
            return "NA";
          }
        },
      },
      // {
      //   headerName: "Event Status",
      //   field: "",
      //   filter: true,
      //   width: 150,
      //   valueGetter: function (params) {
      //     if (params) {
      //       if()
      //       return moment.parseZone(params.data.tracking_updated_at).utcOffset("+05:30").format("DD/MM/YYYY HH:mm")
      //       // return params.data.last_event_data[0].status;
      //       // return ""
      //     } else {
      //       return "";
      //     }
      //   },
      // },
    ],
  });

  // useEffect(()=>{
  //   setState({
  //     ...state,
  //     paginationSize: paginationSize
  //   })
  // },[paginationSize])



  const onGridReady = (params) => {
    const gridApi = params.api;
    const gridColumnApi = params.columnApi;
    console.log("Grid is ready");
  };

  // const renderCellClick = (rowData) => {
  //   console.log(rowData);
  // };
  const gridOptions = {
    getRowStyle: params => ({ verticalAlign: 'middle' }),
    getCellStyle: params => ({ verticalAlign: 'middle' }),
    // other grid options...
  };
  return (
    <div
      className="ag-theme-alpine mb-3 txt-center"
      style={{ width: "100%", height: "400px" }}
    >
      <AgGridReact
        // rowHeight={rowHeight}
        defaultColDef={state.defaultColDef}
        pagination={true}
        gridOptions={gridOptions}
        ref={gridRef}
        rowData={ container_data}
        // columnDefs={state.columnDefs}
        columnDefs={state.container_columnDefs}
        rowHeight={70}
        headerHeight={55}
        animateRows={true}
        //paginationPageSize={parseInt((tabActive === "BL/Booking" ? bl_data : container_data).length)}
        paginationPageSize={paginationSize.value}
        suppressCellSelection={true}
        suppressMovableColumns={true}
        allowScrolling={true}
        showNoRowsOverlay={false}
        suppressRowHoverHighlight={true}
        onGridReady={onGridReady}
        // onCellClicked={(e) => {
        //   renderCellClick(e.data);
        // }}
      />
    </div>
  );
};

export default EximGrid;
