import React, { Component } from "react";
import withRouter from "../withRouter";
import redirectURL from "../redirectURL";
import { countries } from "country-flags-svg";
import moment from "moment";
import "./trackingDetails.css";
import Datetime from "react-datetime";
import $ from "jquery";
import "jquery-ui/ui/core";
import "react-datetime/css/react-datetime.css";
import {
  getYYYYMMDDHHMMSS,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMMYYYY,
} from "../utils";
import SweetAlert from "react-bootstrap-sweetalert";
import { eventStatusCodes, eventStatusNames } from "./constants";
import Select from "react-select";
import Modal from "react-responsive-modal";

// import $ from "jquery";
// import "jquery-ui/ui/widgets/datepicker.js";

class TrackingDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      container_no: "",
      carrier_type: "",
      email: "",
      openModal: false,
      loadshow: "show-n",
      trackingData: [],
      mapViewTab: "btn-active",
      tableViewTab: "btn-default",
      documentsTab: "btn-default",
      mapinfo: "",
      defTransitCoords: "",
      events: [],
      lastUpdatedData: {},
      isScheduledDatesActive: false,
      isScheduledDatesEditEnabled: false,
      shipmentReadyDate: "",
      scheduledDepart: "",
      scheduledArrivalDate: "",
      isScheduledDatesValuesChanged: false,

      // isArrivalEstimatesActive: false,
      // isManualEtaActive: true,
      // isCarrierEtaActive: false,
      // carrierETA: "",
      // manualETA: "None",
      // etaStatus: "None",
      // estimateSource: "",
      // manualDelayReason: "None",
      // carrierDelayReason: "None",
      // lastUpdated: "",
      // updatedArrivalEta: "",
      // changedArrivalEta: "",
      // isManualEtaEnable: false,
      // updatedDelayReason: "",
      // changedDelayReason: "",
      // isArrivalEstimatesValuesChanged: false,
      isArrivalEstimatesActive: false,
      isArrivalEstimatesEditable: false,
      timeToDestination: "",
      etaStatus: "None",
      delayReason: "None",
      lastUpdatedAt: "",
      isTimelineActive: false,
      show: false,
      basicType: "default",
      basicTitle: "",
      // inputRef: React.createRef(),
      tabActive: "details",
      tabStyling: { width: "58px", left: "2px" },
      isdetailsTabActive: false,
      shipmentName: "None",
      billOfLading: "None",
      poNumbers: "None",
      modeType: "",
      modeTypeSet: "",
      incoTerms: [],
      incoTermsSet: "None",
      hsCodes: "None",
      isShipmentReferencesEditable: false,
      isShipmentReferenceValuesChanged: false,

      isOriginAddressActive: false,
      isOriginAddressEditEnabled: false,
      isOriginAddressValuesChanged: false,
      originContactCustomer: "",
      originStreetAddress1: "",
      originStreetAddress2: "",
      originCity: "",
      originCountry: "",
      originPostcode: "",

      isDeliveryAddressActive: false,
      isDeliveryAddressEditEnabled: false,
      isDeliveryAddressValuesChanged: false,
      deliveryContactCustomer: "",
      deliveryStreetAddress1: "",
      deliveryStreetAddress2: "",
      deliveryCity: "",
      deliveryCountry: "",
      deliveryPostcode: "",

      isContainerDetailsActive: false,
      isContainerDetailsEditEnabled: false,
      isContainerDetailsValuesChanged: false,
      sealId: "",
      containerType: "",
      reeferSetPoint: "",
      doorOff: "",
      containerTypeOptions: [
        {
          label: "20' Standard",
          value: "20' Standard",
        },
        {
          label: "20' Reefer",
          value: "20' Reefer",
        },
        {
          label: "20' High Cube",
          value: "20' High Cube",
        },
        {
          label: "40' Standard",
          value: "40' Standard",
        },
        {
          label: "40' Reefer",
          value: "40' Reefer",
        },
        {
          label: "40' High Cube",
          value: "40' High Cube",
        },
        {
          label: "45' Standard",
          value: "45' Standard",
        },
        {
          label: "45' High Cube",
          value: "45' High Cube",
        },
        {
          label: "53' Standard",
          value: "53' Standard",
        },
        {
          label: "53' High Cube",
          value: "53' High Cube",
        },
        {
          label: "20' Hard Top",
          value: "20' Hard Top",
        },
        {
          label: "20' Hard Top High Cube",
          value: "20' Hard Top High Cube",
        },
        {
          label: "40' Hard Top",
          value: "40' Hard Top",
        },
        {
          label: "40' Hard Top High Cube",
          value: "40' Hard Top High Cube",
        },
        {
          label: "20' Flatrack / Platform",
          value: "20' Flatrack / Platform",
        },
        {
          label: "40' Flatrack / Platform",
          value: "40' Flatrack / Platform",
        },
        {
          label: "20' Open Top",
          value: "20' Open Top",
        },
        {
          label: "20' Open Top High Cube",
          value: "20' Open Top High Cube",
        },
        {
          label: "40' Open Top",
          value: "40' Open Top",
        },
        {
          label: "40' Open Top High Cube",
          value: "40' Open Top High Cube",
        },
        {
          label: "Other (Please specify in shipment notes)",
          value: "Other (Please specify in shipment notes)",
        },
      ],
      doorOffOption: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
      productsCount: 0,
      weightsCount: 0,
      cbmCount: 0,
      valueCount: 0.0,
      allProducts: [],
      productsVisibility: [],
      prod_0: false,
      allProductsData: [],
      productDetails: {
        _id: "",
        active: false,
        product_name: "",
        product_description: "",
        product_type: "",
        product_weight: "",
        product_value: "",
        product_length: "",
        product_width: "",
        product_height: "",
        product_cbm: "0",
      },
      attachmentsTitleArr: [],
      attachmentType: { value: "", label: "Type" },
      attachmentTitle: "",
      attachmentsDocArr: [],
      attachmentsDoc: "",
      isAttachmentsDocChanged: false,
      isTrackingDataFetched: false,
      overly: "show-n",
      documentsTranslate: "",
      documentExtension: "",
      openModal: false,
      documentType: "",
      documentUrl: "",
      lastVesselLocation: "",
      routeLatLngs: [],
      tracking_user_mapping_id:""
    };
  }

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  handleAddAttachmentsBtn(e) {
    e.preventDefault();
    if (this.state.attachmentTitle && this.state.attachmentsDoc) {
      console.log("this.state.attachmentsDoc",this.state.attachmentsDoc);
      const formData = new FormData();
      // formData.append("exim_container_no", this.state.container_no);
      formData.append("created_by", this.state.email);
      formData.append("document_title", this.state.attachmentTitle);
      formData.append("document_type", this.state.attachmentType.value);
      formData.append("exim_tracking_doc", this.state.attachmentsDoc);
      formData.append("document_extension", this.state.documentExtension);
      formData.append("tracking_user_mapping_id", this.state.tracking_user_mapping_id);
      // formData.append("carrier_type", this.state.carrier_type);

      if (this.state.isAttachmentsDocChanged) {
        redirectURL
          .post("/exim/saveEximTrackingDocuments", formData)
          .then((response) => {
            if (response.data.status === "success") {
              this.getEximTrackingDocuments();
              document.getElementById("attachmentsDoc").value = null;
              this.setState({
                attachmentType: { label: "", value: "" },
                attachmentTitle: "",
                documentExtension: "",
              });
              this.onClickHideAll();
              // var uploadfilebulk = $("#exim-document").val(null);
              // if (response.data.document_title) {
              //   this.state.attachmentsDocArr =
              //     this.state.attachmentsDocArr.filter(
              //       (obj) => obj.document_title != response.data.document_title
              //     );
              //   let attachmentsDocArr = [
              //     {
              //       document_title: response.data.document_title,
              //       exim_tracking_doc: response.data.exim_file_url,
              //     },
              //   ];
              //   this.setState({
              //     attachmentsDocArr: [
              //       ...this.state.attachmentsDocArr,
              //       ...attachmentsDocArr,
              //     ],
              //     attachmentTitle: "",
              //     attachmentType: "",
              //     overly: "show-n",
              //     documentsTranslate: "",
              //   });
              // }
            }
          });
      }
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  renderMap = () => {
    this.setState({
      loadshow: 'show-m'
    })
    loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap"
    );
    window.initMap = this.initMap;
  };

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  newFilter = (filterType, selOption) => {
    this.setState({
      [filterType]: selOption,
      // isShipmentReferenceValuesChanged: true,
      // isContainerDetailsValuesChanged: true,
    });
  };

  handleFilterOptions = (filterType, valuesChangedType, selOption) => {
    console.log(filterType, valuesChangedType, selOption);
    this.setState({
      [filterType]: selOption,
      [valuesChangedType]: true,
    });
  };

  productNewFilter = (filterType, index, selOption) => {
    this.setState((prevState) => {
      const newState = [...prevState.allProductsData];
      newState[index] = {
        ...newState[index],
        [filterType]: selOption,
      };
      return { allProductsData: newState };
    });
    // let modifiedProductData = this.state.allProductsData.map(
    //   (eachData, innerIndex) => {
    //     if (innerIndex === outerIndex) {
    //       eachData[filterType] = selOption;
    //       // eachData.product_valuesChanged = true
    //     }
    //     return eachData;
    //   }
    // );
    // this.setState({
    //   allProductsData: modifiedProductData,
    // });
  };

  componentDidMount = async () => {
    this.setState({
      loadshow: "show-m",
    });
    let id = this.props.match.params.id;
    let userEmail = localStorage.getItem("email");
    let containerNumber = id.split("-")[1];
    let carrierType = id.split("-")[0];
    let tracking_user_mapping_id = atob(id.split("-")[2]);
    this.setState({
      // container_no: containerNumber,
      email: userEmail,
      // carrier_type: carrierType,
      tracking_user_mapping_id: tracking_user_mapping_id,
    });
    let payload = {
      // container_no: containerNumber,
      // carrier_type: carrierType,
      tracking_user_mapping_id: tracking_user_mapping_id,
      email: userEmail
    };
    await redirectURL
      .post("/exim/getTrackingDetails", payload)
      .then(async (resp) => {
        console.log("getTrackingDetails", resp.data);
        // await this.getContainerHistoryData();
        // await this.getProductDataForContainerNum();
        // await this.getEximTrackingDocuments();
        let trackingData = resp.data.trackingData;
        if (trackingData[0].hasOwnProperty("last_vessel_location")) {
          console.log("lastVesselLocation", JSON.parse(trackingData[0].last_vessel_location));
          this.setState({
            lastVesselLocation: JSON.parse(trackingData[0].last_vessel_location)
          })
        }

        if (trackingData[0].hasOwnProperty("route")) {
          console.log("routeLatLngs", JSON.parse(trackingData[0].route));
          this.setState({
            routeLatLngs: JSON.parse(trackingData[0].route)
          })
        }

        if (trackingData[0].hasOwnProperty("created_at")) {
          let lastUpdated = moment(trackingData[0].created_at).format(
            "DD MMM YYYY"
          );
          this.setState({ lastUpdatedAt: lastUpdated });
        }


        if (trackingData[0].hasOwnProperty("updated_at")) {
          let lastUpdated = moment(trackingData[0].updated_at).format(
            "DD MMM YYYY"
          );
          this.setState({ lastUpdatedAt: lastUpdated });
        }

        if (trackingData[0].hasOwnProperty("origin_city")) {
          this.setState({
            originCity: trackingData[0].origin_city
          })
        }

        if (trackingData[0].hasOwnProperty("origin_country")) {
          this.setState({
            originCountry: trackingData[0].origin_country
          })
        }

        if (trackingData[0].hasOwnProperty("eta_status")) {
          this.setState({
            etaStatus: trackingData[0].eta_status,
          });
        }

        if (trackingData[0].hasOwnProperty("arrival_eta")) {
          if (trackingData[0].arrival_eta !== "None") {
            this.setState({
              manualETA: moment
                .parseZone(trackingData[0].arrival_eta)
                .format("YYYY-MM-DD HH:mm:ss"),
            });
          } else {
            this.setState({
              manualETA: trackingData[0].arrival_eta,
            });
          }
        } else {
          this.setState({
            manualETA: "None",
          });
        }

        if (trackingData[0].hasOwnProperty("delay_reason")) {
          this.setState({
            delayReason: trackingData[0].delay_reason,
          });
        }

        if (trackingData[0].hasOwnProperty("shipment_ready_date")) {
          if (trackingData[0].shipment_ready_date !== "None") {
            this.setState({
              shipmentReadyDate: moment
                .parseZone(trackingData[0].shipment_ready_date)
                .format("YYYY-MM-DD HH:mm:ss"),
            });
          } else {
            this.setState({
              shipmentReadyDate: trackingData[0].shipment_ready_date,
            });
          }
        } else {
          this.setState({
            shipmentReadyDate: "None",
          });
        }

        if (trackingData[0].hasOwnProperty("shipment_name")) {
          this.setState({
            shipmentName: trackingData[0].shipment_name,
          });
        }

        if (trackingData[0].hasOwnProperty("bill_of_lading")) {
          this.setState({
            billOfLading: trackingData[0].bill_of_lading,
          });
        }

        if (trackingData[0].hasOwnProperty("po_numbers")) {
          this.setState({
            poNumbers: trackingData[0].po_numbers,
          });
        }

        if (trackingData[0].hasOwnProperty("mode_type")) {
          if (trackingData[0].mode_type !== "") {
            // this.state.modeType
            this.setState({
              modeType: {
                label: trackingData[0].mode_type,
                value: trackingData[0].mode_type,
              },
            });
          } else {
            this.setState({
              modeType: "",
            });
          }
        }

        if (trackingData[0].hasOwnProperty("inco_terms")) {
          if (trackingData[0].inco_terms.length > 0) {
            let allIncoOptions = [];
            let allIncoTerms = "";
            trackingData[0].inco_terms.forEach((each) => {
              allIncoTerms = allIncoTerms + " " + each;
              let eachOption = {
                label: each,
                value: each,
              };
              allIncoOptions.push(eachOption);
            });
            // data.inco_terms = allIncoTerms;
            this.setState({
              incoTerms: allIncoOptions,
              incoTermsSet: allIncoTerms,
            });
          } else {
            this.setState({
              incoTerms: [],
              incoTermsSet: "None",
            });
          }
        }

        if (trackingData[0].hasOwnProperty("hs_codes")) {
          this.setState({
            hsCodes: trackingData[0].hs_codes,
          });
        }

        // Origin

        // if (trackingData[0].hasOwnProperty("origin_city_country")) {
        //   this.setState({
        //     originCityCountry: trackingData[0].origin_city_country,
        //   });
        // }

        if (trackingData[0].hasOwnProperty("origin_contact_customer")) {
          this.setState({
            originContactCustomer: trackingData[0].origin_contact_customer,
          });
        }

        if (trackingData[0].hasOwnProperty("origin_postcode")) {
          this.setState({
            originPostcode: trackingData[0].origin_postcode,
          });
        }

        if (trackingData[0].hasOwnProperty("origin_street_address1")) {
          this.setState({
            originStreetAddress1: trackingData[0].origin_street_address1,
          });
        }

        if (trackingData[0].hasOwnProperty("origin_street_address2")) {
          this.setState({
            originStreetAddress2: trackingData[0].origin_street_address2,
          });
        }

        // Delivery
        // if (trackingData[0].hasOwnProperty("delivery_city_country")) {
        //   this.setState({
        //     deliveryCityCountry: trackingData[0].delivery_city_country,
        //   });
        // }

        if (trackingData[0].hasOwnProperty("destination_city")) {
          this.setState({
            deliveryCity: trackingData[0].destination_city,
          });
        }

        if (trackingData[0].hasOwnProperty("destination_country")) {
          this.setState({
            deliveryCountry: trackingData[0].destination_country,
          });
        }

        if (trackingData[0].hasOwnProperty("delivery_contact_customer")) {
          this.setState({
            deliveryContactCustomer: trackingData[0].delivery_contact_customer,
          });
        }

        if (trackingData[0].hasOwnProperty("delivery_postcode")) {
          this.setState({
            deliveryPostcode: trackingData[0].delivery_postcode,
          });
        }

        if (trackingData[0].hasOwnProperty("delivery_street_address1")) {
          this.setState({
            deliveryStreetAddress1: trackingData[0].delivery_street_address1,
          });
        }

        if (trackingData[0].hasOwnProperty("delivery_street_address2")) {
          this.setState({
            deliveryStreetAddress2: trackingData[0].delivery_street_address2,
          });
        }

        //container_type
        if (trackingData[0].hasOwnProperty("container_type")) {
          this.setState({
            // containerType: trackingData[0].container_type,
            containerType: {
              value: trackingData[0].container_type,
              label: trackingData[0].container_type,
            },
          });
        }

        if (trackingData[0].hasOwnProperty("seal_id")) {

          this.setState({
            sealId: trackingData[0].seal_id,
          });
        }

        if (trackingData[0].hasOwnProperty("reefer_set_point")) {
          this.setState({
            reeferSetPoint: trackingData[0].reefer_set_point,
          });
        }

        if (trackingData[0].hasOwnProperty("door_off")) {
          this.setState({
            doorOff: {
              value: trackingData[0].door_off,
              label: trackingData[0].door_off,
            },
          });
        }

        if (trackingData[0].hasOwnProperty("document_title")) {
          // let eventAttachmentsData = trackingData
          let attachmentsDocArr = trackingData.map(
            ({ document_title, document_type, exim_tracking_doc }) => ({
              document_title,
              document_type,
              exim_tracking_doc,
            })
          );
          this.setState({
            attachmentsDocArr: attachmentsDocArr,
          });
        }

        let eventsOrder = [];
        let locationsOrder = [];
        let { events } = trackingData[0];
        let locations = events.reduce((agg, e) => {
          if (Object.keys(e).includes('location_details')) {
            let found = agg.some(el => el.id === e.location);
            if (!found) agg.push(e.location_details)
          }
          return agg
        },[])
        
        let firstEvent = { ...events.find(Detail => Object.keys(Detail).includes('date')) }
        var startOfTrip = firstEvent.date;
        let lastEvent = { ...events.findLast(Detail => Object.keys(Detail).includes('date')) }
        var endOfTrip = lastEvent.date;
        startOfTrip = moment(startOfTrip);
        endOfTrip = moment(endOfTrip);
        const duration = endOfTrip.diff(startOfTrip, "days");

        this.setState({
          carrierETA: `${duration} days, ${moment(endOfTrip).format(
            "YYYY-MM-DD HH:mm"
          )}`,
        });


        // let filteredEventsArr = this.createTrackingArray(events)
        // let historyEvents = await this.getContainerHistoryData();
        // let filteredHistoryEventArr = this.createTrackingArray(historyEvents)

        let filteredEvents = events.reduce((agg, e) => {
          // if (historyEvents.length > 0) {
          //   let history_event_obj = { ...filteredHistoryEventArr.filter(historyEvent => historyEvent.tracking_status == e.tracking_status) }
          //   let history_event = { ...history_event_obj }
          //   if (Object.keys(history_event_obj).length > 1) {
          //     let history_event_arr = Object.values(history_event_obj);
          //     history_event = { ...history_event_arr.filter(historyEvent => historyEvent.tracking_index == e.tracking_index) }
          //   }
          //   e.planned_date = Object.values(history_event).length > 0 ? history_event[0].date : null
          // } else {
          //   e.planned_date = e.date
          // }
          // e.days_delayed = moment(e.date).diff(moment(e.planned_date), 'days')
          e.event_name = eventStatusNames[e.tracking_status];
          // if (e.location)
          //   e.location_details = locations.filter((l) => l.id == e.location)[0];
          // if (e.vessel)
          //   e.vessel_details = vessels.filter((l) => l.id == e.vessel)[0];
          agg.push(e);
          if (e.status == "CEP") agg.push({ event_name: "Dispatch" });
          return agg;
        }, []);

        let lastUpdatedData = events.findLast(
          (Detail) => Detail.actual == true
        );
        this.setState({
          lastUpdatedData: lastUpdatedData,
        });
        for (let each of events) {
          const { location } = each;
          if (!eventsOrder.includes(location)) {
            eventsOrder.push(location);
          }
        }
        const lastActualTrueIndex = filteredEvents.reduce(
          (lastIndex, obj, index) => {
            if (obj.actual === true) {
              return index;
            } else {
              return lastIndex;
            }
          },
          -1
        );
        const firstActualFalseIndex = filteredEvents.findIndex(event => (event.actual == false && Object.keys(event).includes('date')))
        const currentEtaStatus = trackingData[0].eta_status ? trackingData[0].eta_status : 'Pending'
        const currentDelay = trackingData[0].delay_days >= 0 ? trackingData[0].delay_days.toString() : ''
        const currentDelayType = trackingData[0].probable_delay ? "Probably" : ''

        this.setState({
          trackingData: trackingData,
          events: filteredEvents,
          lastActualTrueIndex: lastActualTrueIndex,
          firstActualFalseIndex: firstActualFalseIndex,
          currentEtaStatus: currentEtaStatus,
          currentDelay: currentDelay,
          currentDelayType: currentDelayType,
        });

        for (let each of eventsOrder) {
          for (let coord of locations) {
            let { id, lat, lng } = coord;
            if (id === each) {
              if (lat && lng) {
                locationsOrder.push(coord);
              }
            }
          }
        }

        let trackingCoordinates = {
          coords: locationsOrder,
          breaks: [],
          route_events: events,
        };

        this.setState({
          mapinfo: trackingCoordinates,
        });

        await this.renderMap();
        this.setState({
          // loadshow: "show-n",
          isTrackingDataFetched: true,
        });
      })
      .catch((err) => {
        console.log("getTrackingDetails err", err);
      });

    await this.getProductDataForContainerNum();
    await this.getEximTrackingDocuments();
  };

  // createTrackingArray = (events) => {
  //   let emptyPickupDetails = {};
  //   let firstEventDetails = {};
  //   emptyPickupDetails = { ...events.find((e) => e.status == "CEP") };
  //   if (emptyPickupDetails) emptyPickupDetails.tracking_status = "CEP";
  //   else {
  //     gateInDetails = {
  //       tracking_status: "CEP",
  //       actual: false,
  //     };
  //   }

  //   firstEventDetails = events[0];

  //   let gateInDetails = {};
  //   gateInDetails = {
  //     ...events.find(
  //       (e) =>
  //         e.status == "CGI" ||
  //         e.description.toLowerCase().includes("Gate In".toLowerCase()) ||
  //         e.description.toLowerCase().includes("Received".toLowerCase()) ||
  //         e.description.toLowerCase().includes("Arrival".toLowerCase())
  //     ),
  //   };
  //   if (gateInDetails) gateInDetails.tracking_status = "CGI";
  //   else {
  //     gateInDetails = {
  //       ...events.find((e) => e.status == "LTS" || e.status == "BTS"),
  //     };
  //     if (gateInDetails) gateInDetails.tracking_status = "CGI";
  //     else {
  //       gateInDetails = {
  //         tracking_status: "CGI",
  //         actual: false,
  //       };
  //     }
  //   }

  //   let originDepartureDetails = {};
  //   originDepartureDetails = {
  //     ...events.findLast((e) => e.location == gateInDetails.location),
  //   };
  //   if (originDepartureDetails) {
  //     originDepartureDetails.tracking_status = "VDL";
  //   } else {
  //     originDepartureDetails = {
  //       tracking_status: "VDL",
  //       actual: false,
  //     };
  //   }
  //   if (originDepartureDetails) {
  //     console.log("originDepartureDetails", originDepartureDetails);
  //     if (originDepartureDetails.hasOwnProperty("date")) {
  //       this.setState({
  //         scheduledDepart: moment(originDepartureDetails.date).format(
  //           "DD MMM YYYY"
  //         ),
  //       });
  //     }
  //   }

  //   let emptyReturnDetails = {};
  //   let lastEventDetails = {};
  //   let destinationArrivalDetails = {};
  //   let gateOutDetails = {};
  //   emptyReturnDetails = events.find((e) => e.status == "CER");
  //   lastEventDetails = events[events.length - 1];
  //   if (emptyReturnDetails) {
  //     emptyReturnDetails.tracking_status = "CER";

  //     destinationArrivalDetails = events.find(
  //       (e) => e.location == emptyReturnDetails.location
  //     );
  //     if (destinationArrivalDetails)
  //       destinationArrivalDetails.tracking_status = "VAD";
  //     else {
  //       originDepartureDetails = {
  //         tracking_status: "VAD",
  //         actual: false,
  //       };
  //     }

  //     gateOutDetails = events.findLast(
  //       (e) =>
  //         (e.status == "CGO" ||
  //           e.description
  //             .toLowerCase()
  //             .includes("Gate Out".toLowerCase())) &&
  //         e.location == emptyReturnDetails.location
  //     );
  //     if (gateOutDetails) gateOutDetails.tracking_status = "CGO";
  //     else {
  //       gateOutDetails = {
  //         tracking_status: "CGO",
  //         actual: false,
  //       };
  //       // gateOutDetails = events.findLast(e => e.status == 'LTS' || e.status == 'BTS')
  //       // if (gateOutDetails)
  //       //   gateOutDetails.tracking_status = 'CGO'
  //     }
  //   } else {
  //     destinationArrivalDetails = events.find(
  //       (e) => e.location == lastEventDetails.location
  //     );
  //     if (destinationArrivalDetails)
  //       destinationArrivalDetails.tracking_status = "VAD";
  //     else {
  //       originDepartureDetails = {
  //         tracking_status: "VAD",
  //         actual: false,
  //       };
  //     }

  //     gateOutDetails = events.findLast(
  //       (e) =>
  //         (e.status == "CGO" ||
  //           e.description
  //             .toLowerCase()
  //             .includes("Gate Out".toLowerCase())) &&
  //         e.location == lastEventDetails.location
  //     );
  //     if (gateOutDetails) gateOutDetails.tracking_status = "CGO";
  //     else {
  //       gateOutDetails = {
  //         tracking_status: "CGO",
  //         actual: false,
  //       };
  //       // gateOutDetails = events.findLast(e => e.status == 'LTS' || e.status == 'BTS')
  //       // if (gateOutDetails)
  //       //   gateOutDetails.tracking_status = 'CGO'
  //     }
  //   }

  //   if (destinationArrivalDetails) {
  //     if (destinationArrivalDetails.hasOwnProperty("date")) {
  //       console.log(
  //         "destinationArrivalDetails.date",
  //         destinationArrivalDetails.date
  //       );
  //       this.setState({
  //         scheduledArrivalDate: moment(
  //           destinationArrivalDetails.date
  //         ).format("DD MMM YYYY"),
  //       });
  //     }
  //   }
  //   if (destinationArrivalDetails && originDepartureDetails) {
  //     if (
  //       destinationArrivalDetails.hasOwnProperty("date") &&
  //       originDepartureDetails.hasOwnProperty("date")
  //     ) {
  //       let destTime = moment(destinationArrivalDetails.date);
  //       let orgTime = moment(originDepartureDetails.date);
  //       let timeToDest = destTime.diff(orgTime, "days");
  //       this.setState({
  //         timeToDestination: timeToDest,
  //       });
  //       console.log("timeToDest", timeToDest);
  //     }
  //   }

  //   let transhipmentDetails = {};
  //   let condition_pickup = emptyPickupDetails
  //     ? emptyPickupDetails
  //     : firstEventDetails;
  //   let condition_return = emptyReturnDetails
  //     ? emptyReturnDetails
  //     : lastEventDetails;
  //   if (gateInDetails)
  //     transhipmentDetails = events.filter(
  //       (e) =>
  //         e.location != firstEventDetails.location &&
  //         e.location != condition_pickup.location &&
  //         e.location != gateInDetails.location &&
  //         e.location != condition_return.location &&
  //         e.location != lastEventDetails.location
  //     );
  //   else
  //     transhipmentDetails = events.filter(
  //       (e) =>
  //         e.location != firstEventDetails.location &&
  //         e.location != condition_pickup.location &&
  //         e.location != condition_return.location &&
  //         e.location != lastEventDetails.location
  //     );

  //   let tanshipmentlocationDetails = [
  //     ...new Set(transhipmentDetails.map((e) => e.location)),
  //   ];
  //   let transhipmentArr = [];
  //   let transhipment_stage = "";
  //   if (tanshipmentlocationDetails.length > 0) {
  //     tanshipmentlocationDetails.forEach((locationDetail, index) => {
  //       let arrival = transhipmentDetails.find(
  //         (Detail) => Detail.location == locationDetail
  //       );
  //       let transshipmentArrival = { ...arrival };
  //       transshipmentArrival.tracking_status = "VAT";
  //       transshipmentArrival.tracking_index = index;
  //       if (transshipmentArrival.actual)
  //         transhipment_stage =
  //           index.toString() +
  //           "/" +
  //           tanshipmentlocationDetails.length.toString();

  //       let departure = transhipmentDetails.findLast(
  //         (Detail) => Detail.location == locationDetail
  //       );
  //       let transshipmentDeparture = { ...departure };
  //       transshipmentDeparture.tracking_status = "VDT";
  //       transshipmentDeparture.tracking_index = index;
  //       if (transshipmentDeparture.actual)
  //         transhipment_stage =
  //           (index + 1).toString() +
  //           "/" +
  //           tanshipmentlocationDetails.length.toString();

  //       transhipmentArr.push(transshipmentArrival);
  //       transhipmentArr.push(transshipmentDeparture);
  //     });
  //   }

  //   console.log("transhipmentArr", transhipmentArr);

  //   if (transhipmentArr.length == 0) {
  //     transhipmentArr = [
  //       {
  //         tracking_status: "VAT",
  //         actual: false,
  //       },
  //       {
  //         tracking_status: "VDT",
  //         actual: false,
  //       },
  //     ];
  //   }

  //   let filteredEventsArr = [];
  //   if (emptyPickupDetails) filteredEventsArr.push(emptyPickupDetails);

  //   filteredEventsArr.push(
  //     gateInDetails,
  //     originDepartureDetails,
  //     ...transhipmentArr,
  //     destinationArrivalDetails,
  //     gateOutDetails
  //   );

  //   if (emptyReturnDetails)
  //     filteredEventsArr.push(emptyReturnDetails);

  //   return filteredEventsArr;
  // }

  getEximTrackingDocuments = async () => {
    let data = {
      // email: this.state.email,
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      // email: this.state.email,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id
    };

    await redirectURL
      .post("/exim/getEximTrackingDocuments", data)
      .then((resp) => {
        // console.log("getEximTrackingDocuments resp", resp.data);

        let trackingDocuments = resp.data.eximDocuments;
        let allDocuments = [];

        trackingDocuments.forEach((eachDocx) => {
          let eachDOcxDetails = eachDocx.eximDocuments[0];
          allDocuments.push(eachDOcxDetails);
        });

        this.setState({
          attachmentsDocArr: allDocuments,
        });
      })
      .catch((err) => {
        console.log("getEximTrackingDocuments err", err);
      });
  };

  deleteEximDocument = async (documentDetails) => {
    console.log("documentDetails", documentDetails);
    let payload = {
      _id: documentDetails._id,
    };
    await redirectURL
      .post("/exim/deleteEximDocument", payload)
      .then(async (resp) => {
        console.log("deleteEximDocument", resp.data);
        if (resp.data.success) {
          await this.getEximTrackingDocuments();
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: resp.data.message,
          });
        }
      })
      .catch((err) => {
        console.log("deleteEximDocument err", err);
      });
    // .then((resp) => {
    //   console.log("deleteProduct", resp.data);
    //   if (resp.data.success) {
    //     this.setState(
    //       {
    //         show: true,
    //         basicType: "success",
    //         basicTitle: resp.data.message,
    //       },
    //       () => {
    //         this.getProductDataForContainerNum();
    //       }
    //     );
    //   })
  };

  // getContainerHistoryData = async () => {
  //   let params = {
  //     container_no: this.state.container_no,
  //     carrier_type: this.state.carrier_type
  //   };
  //   let historyEventsData = []
  //   await redirectURL.post("/exim/fetchContainerHistory", params).then((res) => {
  //     if (res.data.historyData) {
  //       let historyData = res.data.historyData;
  //       let lastHistoryTrueIdx = historyData.tracking_details.container.events.reduce((lastIndex, obj, index) => {
  //         if (obj.actual === true) {
  //           return index;
  //         } else {
  //           return lastIndex;
  //         }
  //       }, -1)
  //       historyEventsData = historyData.tracking_details.container.events
  //       let lastHistoryTrueEvent = historyData.tracking_details.container.events[lastHistoryTrueIdx];
  //       let histOriginalDate = lastHistoryTrueEvent.date;
  //       let lastHistoryTrueStatus = lastHistoryTrueEvent.status;
  //       let lastHistoryTrueDesc = lastHistoryTrueEvent.description;
  //       this.setState({
  //         histOriginalDate: histOriginalDate,
  //         lastHistoryTrueStatus: lastHistoryTrueStatus,
  //         lastHistoryTrueDesc: lastHistoryTrueDesc
  //       })
  //     }
  //   })
  //   return historyEventsData
  // }

  getProductDataForContainerNum = async () => {
    let data = {
      // email: this.state.email,
      // carrier_type: this.state.carrier_type,
      // container_no: this.state.container_no,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id
    };
    await redirectURL
      .post("/exim/getProductDataForContainerNum", data)
      .then((resp) => {
        let productsData = resp.data.productsData;
        console.log("getProductDataForContainerNum", productsData);
        let modifiedData = productsData.map((each) => {
          let product = each.productDetails[0];
          let option = {
            label: product.product_type,
            value: product.product_type,
          };
          product.active = false;
          product.product_type = option;
          return product;
        });
        this.setState(
          {
            allProductsData: modifiedData,
          },
          () => {
            this.updateWeightCount();
            this.updateCbmCount();
            this.updateValueCount();
          }
        );
      })
      .catch((err) => {
        console.log("getProductDataForContainerNum err", err);
      });
  };

  handlerDateTime = (variableName, event) => {
    var d = new Date(event._d);
    let startdate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    this.setState({
      [variableName]: moment(startdate).format("YYYY-MM-DD HH:mm:ss"),
      isScheduledDatesValuesChanged: true,
      isArrivalEstimatesValuesChanged: true,
    });
  };

  handleScheduledDateAndTimes = () => {
    let data = {
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      email: this.state.email
    };

    if (this.state.shipmentReadyDate) {
      data.shipmentReadyDate = this.state.shipmentReadyDate;
    } else {
      data.shipmentReadyDate = "None";
    }

    if (this.state.isScheduledDatesValuesChanged) {
      this.setState({
        loadshow: "show-m",
      });
      redirectURL
        .post("/exim/updateScheduledDateAndTime", data)
        .then((resp) => {
          console.log("updateScheduledDateAndTime", resp);
          if (resp.data.status === 200) {
            this.setState({
              loadshow: "show-n",
              show: true,
              basicType: "success",
              basicTitle: "Schedules dates are updated",
              isScheduledDatesEditEnabled: false,
              isScheduledDatesValuesChanged: false,
            });
          }
        });
    }
  };

  handleShipmentReferences = () => {
    let data = {
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      // email: this.state.email,
      shipment_name: this.state.shipmentName,
      bill_of_lading: this.state.billOfLading,
      po_numbers: this.state.poNumbers,
      hs_codes: this.state.hsCodes,
    };

    if (this.state.modeType === "") {
      data.mode_type = "";
    } else {
      data.mode_type = this.state.modeType.value;
    }

    if (this.state.incoTerms.length === 0) {
      data.inco_terms = [];
    } else {
      let allIncoTerms = [];
      this.state.incoTerms.forEach((each) => {
        allIncoTerms.push(each.value);
      });
      data.inco_terms = allIncoTerms;
    }

    if (
      this.state.isShipmentReferenceValuesChanged &&
      this.state.shipmentName
    ) {
      this.setState({
        loadshow: "show-m",
      });
      redirectURL
        .post("/exim/updateShipmentReferences", data)
        .then((resp) => {
          console.log("updateShipmentReferences", resp.data);
          if (resp.data.status === 200) {
            let trackingData = resp.data.trackingData;
            this.setState({
              shipmentName: trackingData[0].shipment_name,
              billOfLading: trackingData[0].bill_of_lading,
              poNumbers: trackingData[0].po_numbers,
              hsCodes: trackingData[0].hs_codes,
              loadshow: "show-n",
              show: true,
              basicType: "success",
              basicTitle: "Shipment References are updated",
              isShipmentReferencesEditable: false,
              isShipmentReferenceValuesChanged: false,
            });

            if (trackingData[0].mode_type !== "") {
              // this.state.modeType
              this.setState({
                modeType: {
                  label: trackingData[0].mode_type,
                  value: trackingData[0].mode_type,
                },
              });
            } else {
              this.setState({
                modeType: "",
              });
            }

            if (trackingData[0].inco_terms.length > 0) {
              let allIncoOptions = [];
              let allIncoTerms = "";
              trackingData[0].inco_terms.forEach((each) => {
                allIncoTerms = allIncoTerms + " " + each;
                let eachOption = {
                  label: each,
                  value: each,
                };
                allIncoOptions.push(eachOption);
              });
              // data.inco_terms = allIncoTerms;
              this.setState({
                incoTerms: allIncoOptions,
                incoTermsSet: allIncoTerms,
              });
            } else {
              this.setState({
                incoTerms: [],
                incoTermsSet: "None",
              });
            }
          }
        })
        .catch((err) => {
          console.log("updateShipmentReferences", err);
        });
    } else if (this.state.shipmentName === "") {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Shipment name is required",
      });
    }
  };

  handleContainerDetails = () => {
    let data = {
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      // email: this.state.email,
      seal_id: this.state.sealId,
      container_type: this.state.containerType.value,
      reefer_set_point: this.state.reeferSetPoint,
      door_off: this.state.doorOff.value,
    };

    if (this.state.isContainerDetailsValuesChanged) {
      this.setState({
        loadshow: "show-m",
      });
      redirectURL
        .post("/exim/updateContainerDetails", data)
        .then((resp) => {
          console.log("updateContainerDetails", resp.data);
          if (resp.data.status === 200) {
            this.setState({
              loadshow: "show-n",
              show: true,
              basicType: "success",
              basicTitle: "Container details are updated",
              isContainerDetailsEditEnabled: false,
              isContainerDetailsValuesChanged: false,
            });
          }
        })
        .catch((err) => {
          console.log("updateContainerDetails err", err);
        });
    }
  };

  accordianHandle = (index) => {
    this.setState((prevState) => {
      const newState = [...prevState.allProductsData];
      newState[index] = {
        ...newState[index],
        active: !newState[index].active,
      };
      return { allProductsData: newState };
    });
  };

  handleOriginAddress = () => {
    let data = {
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      // email: this.state.email,
      originContactCustomer: this.state.originContactCustomer,
      originStreetAddress1: this.state.originStreetAddress1,
      originStreetAddress2: this.state.originStreetAddress2,
      originPostcode: this.state.originPostcode,
    };

    if (this.state.isOriginAddressValuesChanged) {
      this.setState({
        loadshow: "show-m",
      });
      redirectURL
        .post("/exim/updateOriginAddress", data)
        .then((resp) => {
          console.log("updateOriginAddress", resp.data);
          if (resp.data.status === 200) {
            this.setState({
              loadshow: "show-n",
              show: true,
              basicType: "success",
              basicTitle: "Origin address details are updated",
              isOriginAddressEditEnabled: false,
              isOriginAddressValuesChanged: false,
            });
          }
        })
        .catch((err) => {
          console.log("updateOriginAddress err", err);
        });
    }
  };

  handleDeliveryAddress = () => {
    this.setState({
      loadshow: "show-m",
    });
    let data = {
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      // email: this.state.email,
      deliveryContactCustomer: this.state.deliveryContactCustomer,
      deliveryStreetAddress1: this.state.deliveryStreetAddress1,
      deliveryStreetAddress2: this.state.deliveryStreetAddress2,
      deliveryPostcode: this.state.deliveryPostcode,
    };
    // console.log("data", data);
    if (this.state.isDeliveryAddressValuesChanged) {
      redirectURL
        .post("/exim/updateDeliveryAddress", data)
        .then((resp) => {
          console.log("updateDeliveryAddress", resp.data);
          if (resp.data.status === 200) {
            this.setState({
              loadshow: "show-n",
              show: true,
              basicType: "success",
              basicTitle: "Delivery address details are updated",
              isDeliveryAddressEditEnabled: false,
              isDeliveryAddressValuesChanged: false,
            });
          }
        })
        .catch((err) => {
          console.log("updateDeliveryAddress err", err);
        });
    }
  };

  handleOnChangeForProducts = (index, keyValue, changedValue) => {
    let volumeArr = ["product_length", "product_width", "product_height"];
    this.setState(
      (prevState) => {
        const newState = [...prevState.allProductsData];
        newState[index] = {
          ...newState[index],
          [keyValue]: changedValue,
          // product_valuesChanged:true
        };
        return { allProductsData: newState };
      },
      () => {
        if (volumeArr.includes(keyValue)) {
          this.updateCubicMeterCount(index);
          this.updateCbmCount();
        }

        if (keyValue === "product_weight") {
          this.updateWeightCount();
        }

        if (keyValue === "product_value") {
          this.updateValueCount();
        }
      }
    );
  };

  updateWeightCount = () => {
    var totalWeight = 0;
    this.state.allProductsData.forEach((eachKey) => {
      let { product_weight } = eachKey;
      if (product_weight) {
        product_weight = parseInt(product_weight);
        totalWeight += product_weight;
      }
    });
    this.setState({
      weightsCount: totalWeight,
    });
  };

  updateValueCount = () => {
    var totalValue = 0;
    this.state.allProductsData.forEach((eachKey) => {
      let { product_value } = eachKey;
      if (product_value) {
        product_value = parseInt(product_value);
        totalValue += product_value;
      }
    });
    this.setState({
      valueCount: totalValue,
    });
  };

  updateCubicMeterCount = async (outerIndex) => {
    let { product_length, product_width, product_height } =
      this.state.allProductsData[outerIndex];
    if (product_length && product_width && product_height) {
      var volume = 0;
      product_length = parseFloat(product_length);
      product_width = parseFloat(product_width);
      product_height = parseFloat(product_height);
      volume = product_length * product_width * product_height;
      volume = volume / 1000000;
      this.handleOnChangeForProducts(outerIndex, "product_cbm", volume);
    }
  };

  updateCbmCount = () => {
    var totalVolume = 0;
    this.state.allProductsData.forEach((eachData) => {
      let { product_length, product_width, product_height } = eachData;
      if (product_length && product_width && product_height) {
        product_length = parseFloat(product_length);
        product_width = parseFloat(product_width);
        product_height = parseFloat(product_height);
        let volume = product_length * product_width * product_height;
        totalVolume = totalVolume + volume;
      }
    });
    totalVolume = totalVolume / 1000000;
    this.setState({
      cbmCount: totalVolume,
    });
  };

  removeProductAndUpdateCounters = (index) => {
    let allProducts = this.state.allProductsData;
    let productToBeDeleted = this.state.allProductsData[index];
    if (productToBeDeleted._id === "") {
      const updatedArray = [
        ...allProducts.slice(0, index),
        ...allProducts.slice(index + 1),
      ];
      this.setState({ allProductsData: updatedArray }, () => {
        this.updateCbmCount();
        this.updateWeightCount();
        this.updateValueCount();
      });
    } else {
      let payload = {
        _id: productToBeDeleted._id,
      };
      redirectURL
        .post("/exim/deleteProduct", payload)
        .then((resp) => {
          console.log("deleteProduct", resp.data);
          if (resp.data.success) {
            this.setState(
              {
                show: true,
                basicType: "success",
                basicTitle: resp.data.message,
              },
              () => {
                this.getProductDataForContainerNum();
              }
            );
          }
        })
        .catch((err) => {
          console.log("deleteProduct err", err);
        });
    }
    // console.log("data",data);
  };

  saveAndUpdateProductData = (index) => {
    // let productData = this.state.allProductsData[index];
    // console.log("productData", this.state.allProductsData);

    let {
      _id,
      product_description,
      product_type,
      product_name,
      product_weight,
      product_value,
      product_length,
      product_width,
      product_height,
      product_cbm,
    } = this.state.allProductsData[index];

    let payload = {
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      // email: this.state.email,
      _id,
      product_description,
      product_type: product_type.value,
      product_name,
      product_weight,
      product_value,
      product_length,
      product_width,
      product_height,
      product_cbm,
    };

    // console.log("productData at index", payload);

    if (product_name === "") {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Product name should not be empty",
      });
    } else {
      redirectURL
        .post("/exim/saveAndUpdateProductData", payload)
        .then((resp) => {
          if (resp.data.success) {
            this.setState(
              {
                show: true,
                basicType: "success",
                basicTitle: resp.data.message,
              },
              () => {
                this.getProductDataForContainerNum();
              }
            );
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: resp.data.message,
            });
          }
          console.log("saveAndUpdateProductData", resp.data);
        })
        .catch((err) => {
          console.log("saveAndUpdateProductData err", err);
        });
    }
  };

  handleArrivalEstimates = () => {
    let data = {
      // container_no: this.state.container_no,
      // carrier_type: this.state.carrier_type,
      tracking_user_mapping_id: this.state.tracking_user_mapping_id,
      // email: this.state.email
    };

    // if (this.state.changedArrivalEta) {
    //   data.updatedArrivalEta = this.state.changedArrivalEta;
    // } else {
    //   data.updatedArrivalEta = "None";
    // }

    if (this.state.changedDelayReason) {
      data.updatedDelayReason = this.state.changedDelayReason;
    } else {
      data.updatedDelayReason = "None";
    }

    console.log("data", data);

    if (this.state.isArrivalEstimatesValuesChanged) {
      this.setState({
        loadshow: "show-m",
      });
      redirectURL
        .post("/exim/updateArrivalEstimates", data)
        .then((resp) => {
          console.log("updateArrivalEstimates", resp.data);
          let trackingData = resp.data.trackingData;
          if (resp.data.status === 200) {
            this.setState({
              basicTitle: "Arrival Estimate is updated",
              basicType: "success",
              show: true,
              delayReason: trackingData[0].delay_reason,
              // manualETA: moment
              //   .parseZone(trackingData[0].arrival_eta)
              //   .format("YYYY-MM-DD HH:mm:ss"),
              loadshow: "show-n",
              // changedArrivalEta: "",
              changedDelayReason: "",
              isArrivalEstimatesValuesChanged: false,
              // isManualEtaEnable: false,
              isArrivalEstimatesEditable: false,
            });
          }
        })
        .catch((err) => {
          console.log("updateArrivalEstimates err", err);
        });
    }
    // else {
    //   this.setState({
    //     basicTitle: "Time to Destination field is needed",
    //     basicType: "danger",
    //     show: true,
    //   });
    // }
  };

  handleTabs = (oVal) => {
    // console.log("events",this.state.events);
    if (oVal === 1) {
      this.setState({
        mapViewTab: "btn-active",
        tableViewTab: "btn-default",
        documentsTab: "btn-default",
      });
      this.componentDidMount();
      //   this.renderMap();
    }
    if (oVal === 2) {
      this.setState({
        mapViewTab: "btn-default",
        tableViewTab: "btn-active",
        documentsTab: "btn-default",
      });
    }
    if (oVal === 3) {
      this.setState({
        mapViewTab: "btn-default",
        tableViewTab: "btn-default",
        documentsTab: "btn-active",
      });
    }
  };

  initMap = () => {
    console.log("mapinfo ", this.state.mapinfo);
    var currentwindow;

    var jsondata = this.state.mapinfo;
    var locations = jsondata.breaks;
    var arr = jsondata.coords;

    console.log("Here", arr);
    if (arr.length > 0) {
      var lt = arr[0].lat;
      var ln = arr[0].lng;
    } else {
      if (this.state.defTransitCoords == "") {
        var lt = 28.4519751;
        var ln = 77.0310713;
      } else {
        var lt = JSON.parse(this.state.defTransitCoords).lat;
        var ln = JSON.parse(this.state.defTransitCoords).lng;
      }
    }
    var routeinfo = jsondata.route_events;
    console.log("routeinfo", routeinfo);
    let allTrueEvents = []

    routeinfo.forEach((each) => {
      let { location, actual } = each;
      if (actual) {
        if (!allTrueEvents.includes(location)) {
          allTrueEvents.push(location)
        }
      } else {
        if (allTrueEvents.includes(location)) {
          allTrueEvents.pop();
        }
      }
    })
    console.log("arr", arr);
    var lastTrueIdx = routeinfo.reduce((lastIndex, obj, index) => {
      if (obj.actual === true) {
        return index;
      } else {
        return lastIndex;
      }
    }, -1);
    var lastTrueLoc = routeinfo[lastTrueIdx].location;
    console.log("lastTrueLoc", lastTrueLoc);
    var lastTrueLat = arr.filter((obj) => obj.id === lastTrueLoc).lat;
    var lastTrueLong = arr.filter((obj) => obj.id === lastTrueLoc).lng;
    var mapOptions = {
      zoom: 2,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      labels: true,
      // mapTypeControlOptions: {
      //   mapTypeIds: ["hybrid", "roadmap"],
      // },
      center: new window.google.maps.LatLng(lt, ln),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    };

    // Create a grayscale style
    var grayscaleStyle = [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ];

    var map = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );
    map.setOptions({ styles: grayscaleStyle });

    var bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(lt, ln));
    // Create our info window content
    var currentinfowindow = null;
    var line = new window.google.maps.Polyline({
      map: map,
      strokeColor: "#0059b3",
      strokeOpacity: 1.0,
      strokeWeight: 2.5,
      icons: [
        {
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: "#4d4d4d",
            fillColor: "#4d4d4d",
            fillOpacity: 1,
            strokeWeight: 2,
          },
          repeat: "200px",
          path: [],
        },
      ],
    });

    // console.log("window.google.maps.SymbolPath", window.google.maps.SymbolPath);
    /*
    BACKWARD_CLOSED_ARROW: 3
    BACKWARD_OPEN_ARROW: 4
    CIRCLE: 0
    FORWARD_CLOSED_ARROW: 1 
    FORWARD_OPEN_ARROW: 2
    */


    if (this.state.routeLatLngs.length > 0 && this.state.lastVesselLocation) {
      //________________________For Lines___________________________//
      for (let i = 0; i < this.state.routeLatLngs.length; i++) {
        var path = line.getPath().getArray();

        let latLng = new window.google.maps.LatLng(this.state.routeLatLngs[i].lat, this.state.routeLatLngs[i].lng);
        path.push(latLng);
        line.setPath(path);

        //Change line color based on map type
        window.google.maps.event.addListener(
          map,
          "maptypeid_changed",
          function () {
            if (
              map.getMapTypeId() == "hybrid" ||
              map.getMapTypeId() == "satellite"
            ) {
              var line = new window.google.maps.Polyline({
                map: map,
                strokeColor: "#0059b3",
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                      strokeColor: "#4d4d4d",
                      fillColor: "#4d4d4d",
                      fillOpacity: 1,
                      strokeWeight: 2,
                    },
                    repeat: "200px",
                    path: [],
                  },
                ],
              });
            } else {
              var line = new window.google.maps.Polyline({
                map: map,
                strokeColor: "#0059b3",
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      strokeColor: "#4d4d4d",
                      fillColor: "#4d4d4d",
                      fillOpacity: 1,
                      strokeWeight: 2,
                    },
                    repeat: "200px",
                    path: [],
                  },
                ],
              });
            }

            for (i = 0; i < this.state.routeLatLngs.length; i++) {
              console.log(this.state.routeLatLngs[i].lat, this.state.routeLatLngs[i].lng);
              var path = line.getPath().getArray();
              let latLng = new window.google.maps.LatLng(
                this.state.routeLatLngs[i].lat,
                this.state.routeLatLngs[i].lng
              );
              path.push(latLng);
              line.setPath(path);
            }

            var x = map.getZoom();
            var c = map.getCenter();
            window.google.maps.event.trigger(map, "resize");
            map.setZoom(x);
            map.setCenter(c);
          }
        );

        bounds.extend(new window.google.maps.LatLng(this.state.routeLatLngs[i].lat, this.state.routeLatLngs[i].lng));
      }
      //________________________For Lines___________________________//
      //________________________For Points and Events______________//

      //If lastLoc not matched then we will have extra point
      let lastLocationCoincededWithEventCoord = arr.filter((eachCoord) => {
        let { lat, lng } = eachCoord;
        if (
          lat === this.state.lastVesselLocation.lat &&
          lng === this.state.lastVesselLocation.lng
        ) {
          // lastVesselIndex = index;
          return eachCoord;
        }
      });
      console.log("lastLocationCoincededWithEventCoord", lastLocationCoincededWithEventCoord.length);
      //Since no match of last_latLong with events, extra point of last_latlng point has to be added
      if (lastLocationCoincededWithEventCoord.length === 0) {
        arr.forEach((each, j) => {
          var marker;
          var infowindow = new window.google.maps.InfoWindow();
          var pinInnerMarker;
          var outerMarker;
          var pinOuterMarker;
          let eventsForLeg = this.getEventDetails(arr[j].id);
          //For all events,
          marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 6,
              fillColor: allTrueEvents.includes(arr[j].id) ? "rgb(76, 187, 135)" : "#ebc034",
              fillOpacity: 1,
              strokeWeight: 0,
            },
            map: map,
          });
          outerMarker = new window.google.maps.Marker({
            position: marker.getPosition(),
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 9, // Adjust the size of the outer circle
              fillColor: "transparent", // Set fill color to transparent
              fillOpacity: 0, // Set fill opacity to 0 to make it transparent
              strokeWeight: 1, // Adjust the stroke weight of the outer circle
              strokeColor: allTrueEvents.includes(arr[j].id) ? "rgb(76, 187, 135)" : "#ebc034", // Customize the stroke color
              // strokeColor: "#333333", // Customize the stroke color
            },
            map: map,
          });
          window.google.maps.event.addListener(
            outerMarker,
            "click",
            (function (outerMarker) {
              return function () {
                var contentString =
                  "<p style='color:#4d4d4d' class='p-2'>" +
                  "<B style=';'>" +
                  arr[j].name +
                  ", " +
                  arr[j].state +
                  ", " +
                  arr[j].country +
                  "<div class='row justify-content-between mx-0'>" +
                  "<div class='col-8'><label>Event Name </label></div>" +
                  "<div class='col-4'><label>Date</label></div>" +
                  "</div>";

                for (let each of eventsForLeg) {
                  let { eventName, date } = each;
                  contentString =
                    contentString +
                    "<div class='row justify-content-between mx-0'>" +
                    "<div class='col-8'> <label>" +
                    eventName +
                    "</label></div>" +
                    "<div class='col-4'><label>" +
                    date +
                    "</label></div>" +
                    "</div>";
                }
                infowindow.setContent(contentString);
                currentwindow = infowindow;
                infowindow.open(map, outerMarker);
              };
            })(outerMarker)
          );

          //Extra latLng point
          pinInnerMarker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              this.state.lastVesselLocation.lat,
              this.state.lastVesselLocation.lng
            ),
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 6,
              fillColor: "red",
              fillOpacity: 1,
              strokeWeight: 0,
            },
            map: map,
          });
          pinOuterMarker = new window.google.maps.Marker({
            position: pinInnerMarker.getPosition(),
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 9, // Adjust the size of the outer circle
              fillColor: "transparent", // Set fill color to transparent
              fillOpacity: 0, // Set fill opacity to 0 to make it transparent
              strokeWeight: 0, // Adjust the stroke weight of the outer circle
              // strokeColor: arr[j].id === lastTrueLoc ? "red" : "#333333", // Customize the stroke color
              strokeColor: "red", // Customize the stroke color
            },
            map: map,
          });

          let lastUpdatedAt = this.state.lastUpdatedAt
          let lastLocations = String(this.state.lastVesselLocation.lat) + ", " + String(this.state.lastVesselLocation.lng)

          //hover
          window.google.maps.event.addListener(
            pinOuterMarker,
            "mouseover",
            (function (pinOuterMarker) {
              return function () {
                var contentString = "<p style='color:#4d4d4d' class='p-2'>" +
                  "<B style=';'>" +
                  "<div class='row justify-content-between mx-0'>" +
                  "<div class='col-6'><label>Last Updated At</label></div>" +
                  "<div class='col-6'><label>" + lastUpdatedAt + "</label></div>" +
                  "</div>" +
                  "<div class='row justify-content-between mx-0'>" +
                  "<div class='col-6'><label>Last Location</label></div>" +
                  "<div class='col-6'><label>" + lastLocations + "</label></div>" +
                  "</div>";

                ;
                infowindow.setContent(contentString);
                currentwindow = infowindow;
                infowindow.open(map, pinOuterMarker);
              };
            })(pinOuterMarker)
          );

          window.google.maps.event.addListener(
            pinOuterMarker,
            "mouseout",
            (function (pinOuterMarker) {
              return function () {
                currentwindow = infowindow;
                infowindow.close(map, pinOuterMarker);
              };
            })(pinOuterMarker)
          );

          this.animateRipple(pinOuterMarker);
        });
      }
      else if (lastLocationCoincededWithEventCoord.length > 0) {
        for (let j = 0; j < arr.length; j++) {
          var marker;
          var pinMarker;
          var outerMarker;
          var pinOuterMarker;

          var infowindow = new window.google.maps.InfoWindow();
          let { lat, lng } = arr[j];

          if (lat === this.state.lastVesselLocation.lat && lng === this.state.lastVesselLocation.lng) {
            pinMarker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 6,
                fillColor: "red",
                fillOpacity: 1,
                strokeWeight: 0,
              },
              map: map,
            });
            pinOuterMarker = new window.google.maps.Marker({
              position: pinMarker.getPosition(),
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 9, // Adjust the size of the outer circle
                fillColor: "transparent", // Set fill color to transparent
                fillOpacity: 0, // Set fill opacity to 0 to make it transparent
                strokeWeight: 1, // Adjust the stroke weight of the outer circle
                // strokeColor: arr[0].id === lastTrueLoc ? "red" : "#333333", // Customize the stroke color
                strokeColor: "red", // Customize the stroke color
              },
              map: map,
            });
            let eventDetails = this.getEventDetails(arr[j].id);
            //Click pinOuterMarker
            window.google.maps.event.addListener(
              pinOuterMarker,
              "click",
              (function (pinOuterMarker) {
                return function () {
                  var contentString =
                    "<p style='color:#4d4d4d' class='p-2'>" +
                    "<B style=';'>" +
                    arr[j].name +
                    ", " +
                    arr[j].state +
                    ", " +
                    arr[j].country +
                    "<div class='row justify-content-between mx-0'>" +
                    "<div class='col-8'><label>Event Name </label></div>" +
                    "<div class='col-4'><label>Date</label></div>" +
                    "</div>";

                  for (let each of eventDetails) {
                    let { eventName, date } = each;
                    contentString =
                      contentString +
                      "<div class='row justify-content-between mx-0'>" +
                      "<div class='col-8'> <label>" +
                      eventName +
                      "</label></div>" +
                      "<div class='col-4'><label>" +
                      date +
                      "</label></div>" +
                      "</div>";
                  }
                  infowindow.setContent(contentString);
                  currentwindow = infowindow;
                  infowindow.open(map, pinOuterMarker);
                };
              })(pinOuterMarker)
            );

            let lastLocations = arr[j].lat + ", " + arr[j].lng
            let lastUpdatedAt = this.state.lastUpdatedAt
            //hover
            window.google.maps.event.addListener(
              pinOuterMarker,
              "mouseover",
              (function (pinOuterMarker) {
                // document.getElementsByClassName("gm-ui-hover-effect")[0].classList.remove("d-none");
                console.log("outer else", j);
                return function () {
                  var contentString = "<p style='color:#4d4d4d' class='p-2'>" +
                    "<B style=';'>" +
                    "<div class='row justify-content-between mx-0'>" +
                    "<div class='col-8'><label>Last Updated At</label></div>" +
                    "<div class='col-4'><label>" + lastUpdatedAt + "</label></div>" +
                    "</div>" +
                    "<div class='row justify-content-between mx-0'>" +
                    "<div class='col-8'><label>Last Location</label></div>" +
                    "<div class='col-4'><label>" + lastLocations + "</label></div>" +
                    "</div>"
                  infowindow.setContent(contentString);
                  currentwindow = infowindow;
                  infowindow.open(map, pinOuterMarker);
                };
              })(pinOuterMarker)
            );

            window.google.maps.event.addListener(
              pinOuterMarker,
              "mouseout",
              (function (pinOuterMarker) {
                // document.getElementsByClassName("gm-ui-hover-effect")[0].classList.add("d-none");
                console.log("outer else", j);
                return function () {
                  var contentString = "<p style='color:#4d4d4d' class='p-2'>" +
                    "<B style=';'>" +
                    "<div class='row justify-content-between mx-0'>" +
                    "<div class='col-8'><label>Last Updated At</label></div>" +
                    "<div class='col-4'><label>" + lastUpdatedAt + "</label></div>" +
                    "</div>" +
                    "<div class='row justify-content-between mx-0'>" +
                    "<div class='col-8'><label>Last Location</label></div>" +
                    "<div class='col-4'><label>" + lastLocations + "</label></div>" +
                    "</div>"
                  infowindow.setContent(contentString);
                  currentwindow = infowindow;
                  infowindow.open(map, pinOuterMarker);
                };
              })(pinOuterMarker)
            );

            this.animateRipple(pinOuterMarker)
          } else {
            marker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 6,
                // fillColor: "#333333",
                fillColor: allTrueEvents.includes(arr[j].id) ? "rgb(76, 187, 135)" : "#ebc034",
                fillOpacity: 1,
                strokeWeight: 0,
              },
              map: map,
            });
            outerMarker = new window.google.maps.Marker({
              position: marker.getPosition(),
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 9, // Adjust the size of the outer circle
                fillColor: "transparent", // Set fill color to transparent
                fillOpacity: 0, // Set fill opacity to 0 to make it transparent
                strokeWeight: 1, // Adjust the stroke weight of the outer circle
                // strokeColor: arr[0].id === lastTrueLoc ? "red" : "#333333", // Customize the stroke color
                // strokeColor: "#333333", // Customize the stroke color
                strokeColor: allTrueEvents.includes(arr[j].id) ? "rgb(76, 187, 135)" : "#ebc034"
              },
              map: map,
            });
            let eventDetails = this.getEventDetails(arr[j].id);
            //hover
            // window.google.maps.event.addListener(
            //   outerMarker,
            //   "mouseover",
            //   (function (outerMarker) {
            //     console.log("outer else",j);
            //       return function () {
            //         var contentString =
            //       "<p style='color:#4d4d4d' class='p-2'>" +
            //       "<B style=';'>" +
            //       arr[j].name +
            //       ", " +
            //       arr[j].state +
            //       ", " +
            //       arr[j].country +
            //       "<div class='row justify-content-between mx-0'>" +
            //       "<div class='col-8'><label>Event Name </label></div>" +
            //       "<div class='col-4'><label>Date</label></div>" +
            //       "</div>";

            //       for (let each of eventDetails) {
            //         let { eventName, date } = each;
            //         contentString =
            //           contentString +
            //           "<div class='row justify-content-between mx-0'>" +
            //           "<div class='col-8'> <label>" +
            //           eventName +
            //           "</label></div>" +
            //           "<div class='col-4'><label>" +
            //           date +
            //           "</label></div>" +
            //           "</div>";
            //       }
            //       infowindow.setContent(contentString);
            //       currentwindow = infowindow;
            //       infowindow.open(map, outerMarker);
            //     };
            //   })(outerMarker)
            // );
            // console.log(j, "--> " ,eventsForLeg);
            window.google.maps.event.addListener(
              outerMarker,
              "click",
              (function (outerMarker) {
                console.log("outer else", j);
                return function () {
                  var contentString =
                    "<p style='color:#4d4d4d' class='p-2'>" +
                    "<B style=';'>" +
                    arr[j].name +
                    ", " +
                    arr[j].state +
                    ", " +
                    arr[j].country +
                    "<div class='row justify-content-between mx-0'>" +
                    "<div class='col-8'><label>Event Name </label></div>" +
                    "<div class='col-4'><label>Date</label></div>" +
                    "</div>";

                  for (let each of eventDetails) {
                    let { eventName, date } = each;
                    contentString =
                      contentString +
                      "<div class='row justify-content-between mx-0'>" +
                      "<div class='col-8'> <label>" +
                      eventName +
                      "</label></div>" +
                      "<div class='col-4'><label>" +
                      date +
                      "</label></div>" +
                      "</div>";
                  }
                  infowindow.setContent(contentString);
                  currentwindow = infowindow;
                  infowindow.open(map, outerMarker);
                };
              })(outerMarker)
            );
          }
        }
      }
    }
    else if (arr.length > 0 && this.state.routeLatLngs.length === 0) {
      //________________________For Lines___________________________//
      for (let i = 0; i < arr.length; i++) {
        var path = line.getPath().getArray();

        let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
        path.push(latLng);
        line.setPath(path);

        //Change line color based on map type
        window.google.maps.event.addListener(
          map,
          "maptypeid_changed",
          function () {
            if (
              map.getMapTypeId() == "hybrid" ||
              map.getMapTypeId() == "satellite"
            ) {
              var line = new window.google.maps.Polyline({
                map: map,
                strokeColor: "#0059b3",
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                      strokeColor: "#4d4d4d",
                      fillColor: "#4d4d4d",
                      fillOpacity: 1,
                      strokeWeight: 2,
                    },
                    repeat: "200px",
                    path: [],
                  },
                ],
              });
            } else {
              var line = new window.google.maps.Polyline({
                map: map,
                strokeColor: "#0059b3",
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      strokeColor: "#4d4d4d",
                      fillColor: "#4d4d4d",
                      fillOpacity: 1,
                      strokeWeight: 2,
                    },
                    repeat: "200px",
                    path: [],
                  },
                ],
              });
            }

            for (i = 0; i < arr.length; i++) {
              var path = line.getPath().getArray();
              let latLng = new window.google.maps.LatLng(
                arr[i].lat,
                arr[i].lng
              );
              path.push(latLng);
              line.setPath(path);
            }

            var x = map.getZoom();
            var c = map.getCenter();
            window.google.maps.event.trigger(map, "resize");
            map.setZoom(x);
            map.setCenter(c);
          }
        );

        bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
      }
      //________________________For Lines___________________________//
      //________________________For Points and Events_______________//
      arr.forEach((each, j) => {
        console.log("each arr", each);
        var marker;
        var infowindow = new window.google.maps.InfoWindow();
        var outerMarker;
        let eventsForLeg = this.getEventDetails(arr[j].id);
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 6,
            fillColor: arr[j].id === lastTrueLoc ? "red" : "#333333",
            fillOpacity: 1,
            strokeWeight: 0,
          },
          map: map,
        });
        outerMarker = new window.google.maps.Marker({
          position: marker.getPosition(),
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 9, // Adjust the size of the outer circle
            fillColor: "transparent", // Set fill color to transparent
            fillOpacity: 0, // Set fill opacity to 0 to make it transparent
            strokeWeight: 1, // Adjust the stroke weight of the outer circle
            strokeColor: arr[j].id === lastTrueLoc ? "red" : "#333333", // Customize the stroke color
          },
          map: map,
        });


        if (arr[j].id === lastTrueLoc) {
          this.animateRipple(outerMarker);
          let lastUpdatedAt = this.state.lastUpdatedAt ? this.state.lastUpdatedAt : "-"
          let lastLocations = String(arr[j].lat) + ", " + String(arr[j].lng)
          console.log("lastUpdatedAt", lastUpdatedAt);
          console.log("lastLocations", lastLocations);
          window.google.maps.event.addListener(
            outerMarker,
            "mouseover",
            (function (outerMarker) {
              return function () {
                var contentString = "<p style='color:#4d4d4d' class='p-2'>" +
                  "<B style=';'>" +
                  "<div class='row justify-content-between mx-0'>" +
                  "<div class='col-6'><label>Last Updated At</label></div>" +
                  "<div class='col-6'><label>" + lastUpdatedAt + "</label></div>" +
                  "</div>" +
                  "<div class='row justify-content-between mx-0'>" +
                  "<div class='col-6'><label>Last Location</label></div>" +
                  "<div class='col-6'><label>" + lastLocations + "</label></div>" +
                  "</div>";

                ;
                infowindow.setContent(contentString);
                currentwindow = infowindow;
                infowindow.open(map, outerMarker);
              };
            })(outerMarker)
          );

          window.google.maps.event.addListener(
            outerMarker,
            "mouseout",
            (function (outerMarker) {
              // document.getElementsByClassName("gm-ui-hover-effect")[0].classList.add("d-none");
              return function () {
                currentwindow = infowindow;
                infowindow.close(map, outerMarker);
              };
            })(outerMarker)
          );
        }

        window.google.maps.event.addListener(
          outerMarker,
          "click",
          (function (outerMarker) {
            return function () {
              var contentString =
                "<p style='color:#4d4d4d' class='p-2'>" +
                "<B style=';'>" +
                arr[j].name +
                ", " +
                arr[j].state +
                ", " +
                arr[j].country +
                "<div class='row justify-content-between mx-0'>" +
                "<div class='col-8'><label>Event Name </label></div>" +
                "<div class='col-4'><label>Date</label></div>" +
                "</div>";

              for (let each of eventsForLeg) {
                let { eventName, date } = each;
                contentString =
                  contentString +
                  "<div class='row justify-content-between mx-0'>" +
                  "<div class='col-8'> <label>" +
                  eventName +
                  "</label></div>" +
                  "<div class='col-4'><label>" +
                  date +
                  "</label></div>" +
                  "</div>";
              }
              infowindow.setContent(contentString);
              currentwindow = infowindow;
              infowindow.open(map, outerMarker);
            };
          })(outerMarker)
        );
        // pinInnerMarker = new window.google.maps.Marker({
        //   position: new window.google.maps.LatLng(
        //     this.state.lastVesselLocation.lat,
        //     this.state.lastVesselLocation.lng
        //   ),
        //   icon: {
        //     path: window.google.maps.SymbolPath.CIRCLE,
        //     scale: 6,
        //     fillColor: "red",
        //     fillOpacity: 1,
        //     strokeWeight: 0,
        //   },
        //   map: map,
        // });
        // pinOuterMarker = new window.google.maps.Marker({
        //   position: pinInnerMarker.getPosition(),
        //   icon: {
        //     path: window.google.maps.SymbolPath.CIRCLE,
        //     scale: 9, // Adjust the size of the outer circle
        //     fillColor: "transparent", // Set fill color to transparent
        //     fillOpacity: 0, // Set fill opacity to 0 to make it transparent
        //     strokeWeight: 0, // Adjust the stroke weight of the outer circle
        //     // strokeColor: arr[0].id === lastTrueLoc ? "red" : "#333333", // Customize the stroke color
        //     strokeColor: "red", // Customize the stroke color
        //   },
        //   map: map,
        // });
        // this.animateRipple(pinOuterMarker);
      });
      //________________________For Points and Events_______________//
    }

    map.fitBounds(bounds);
    this.setState({
      loadshow: "show-n",
    });
  };
  animateRipple(marker) {
    var animationInterval;
    var scale = 9;
    animationInterval = setInterval(function () {
      scale += 1; // Increase the scale of the outer circle

      // Update the scale of the outerMarker
      marker.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: scale,
        fillColor: "red",
        fillOpacity: 0, // Set fill opacity to 0 to make it transparent
        strokeWeight: 1, // Adjust the stroke weight of the outer circle
        strokeColor: "red", // Customize the stroke color
      });

      // If desired scale is reached, reset the scale and restart the animation
      if (scale >= 12) {
        scale = 9; // Reset the scale
      }
    }, 200); // Set the animation interval in milliseconds
  }

  getEventDetails = (id) => {
    let eventDetails = [];
    var routeEvents = this.state.mapinfo.route_events;
    let eachEvent = {};
    for (let eve of routeEvents) {
      let {
        location,
        description,
        type,
        vessel,
        voyage,
        date,
        actual,
        status,
      } = eve;
      if (location === id) {
        // eventDetails = eventDetails + " \n " + description;
        // lastDateOfUpdate = moment(date).format("DD MMM YYYY HH:mm");
        eachEvent = {
          eventName: description,
          date: moment(date).format("DD MMM YYYY"),
          actual: actual,
          status: status,
          location: location,
        };
        eventDetails.push(eachEvent);
      }
    }
    return eventDetails;
  };

  changeAttachmentsFileHandler(event) {
    const file = event.target.files[0];
    console.log("file", file);
    let validFileExtensions = [
      "png",
      "jpeg",
      "gif",
      "bmp",
      "pdf",
      "csv",
      "xlsx",
      "mp4",
      "webm",
      "mp3",
    ];
    const documentExtension = file.name.split(".").pop();
    console.log("documentExtension", documentExtension);

    if (validFileExtensions.includes(documentExtension)) {
      const previousFile = this.state.attachmentsDoc;
      if (file && file !== previousFile) {
        this.setState({
          isAttachmentsDocChanged: true,
          attachmentsDoc: file,
          documentExtension: documentExtension,
        });
      } else {
        this.setState({
          isAttachmentsDocChanged: false,
        });
      }
    } else {
      document.getElementById("attachmentsDoc").value = null;
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Invalid file format.",
      });
    }
  }

  previewDocument = (document) => {
    console.log("document", document);
    this.setState({
      openModal: true,
      documentUrl: document.document_url,
      documentType: document.document_extension,
    });
  };

  onClickHideAll = () => {
    //var uploadfilebulk = $("#uploadfile").val(null)
    this.setState({
      overly: "show-n",
      documentsTranslate: "",
    });
  };

  openDocumentsSlider = () => {
    this.setState({
      overly: "show-m",
      documentsTranslate: "slider-translate",
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="spinner-grow text-primary mr-2" role="status"></div>
          <div className="spinner-grow text-primary mr-2" role="status"></div>
          <div className="spinner-grow text-primary" role="status"></div>
        </div>
        <Modal open={this.state.openModal} onClose={this.closeModal}>
          <div aria-disabled={true}>
            <h6
              className="bg-primary text-white"
              style={{
                paddingTop: "10px",
                height: "38px",
                paddingLeft: "253px",
              }}
            >
              File Preview
            </h6>
            {this.state.documentType === "pdf" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "csv" ||
              this.state.documentType === "xlsx" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "png" ||
              this.state.documentType === "jpeg" ||
              this.state.documentType === "jpg" ? (
              <div className="p-3">
                <img
                  src={this.state.documentUrl}
                  // frameborder="1"
                  height="500px"
                  width="100%"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="card mx-0" style={{ minHeight: "85vh" }}>
          {/* Basic Details */}
          {this.state.trackingData.hasOwnProperty("events") &&
            this.state.trackingData.map((each) => {
              // console.log("each trackingData", each);
              let allEvents = each.events;
              var srcEvent = allEvents.find(e => Object.keys(e).includes('date'));
              var dstEvent = allEvents.findLast(e => Object.keys(e).includes('date'));
              let etaTime = moment(dstEvent.date).format(
                "DD MMM YYYY HH:mm:ss"
              );
              let eta = moment(dstEvent.date);
              let now = moment().format("DD MMM YYYY");

              // let srcCountryName = each.tracking_details.locations.filter(
              //   (eachLoc) => {
              //     if (eachLoc.id === srcEvent.location) {
              //       return each;
              //     }
              //   }
              // );

              let srcFlag = countries.filter((eachCountry) => {
                if (eachCountry.name === srcEvent.location_details.country) {
                  return eachCountry;
                }
              });
              // console.log("srcFlag",srcFlag);
              // console.log("srcCountryName", srcCountryName);

              // let dstCountryName = each.tracking_details.locations.filter(
              //   (eachLoc) => {
              //     if (eachLoc.id === dstEvent.location) {
              //       return each;
              //     }
              //   }
              // );

              let dstFlag = countries.filter((eachCountry) => {
                if (eachCountry.name === dstEvent.location_details.country) {
                  return eachCountry;
                }
              });
              // console.log("dstFlag",dstFlag);
              // console.log("dstCountryName", dstCountryName);
              return (
                <>
                  <div
                    key={each.container_no}
                    className="d-none"
                    style={{ minHeight: "80px" }}
                  >
                    <div className="row mx-0 justify-contenter-between">
                      <div
                        className="card col-2 pl-4 pt-1 mb-1"
                        style={{
                          borderRadius: "0px",
                          borderRight: "1px solid",
                        }}
                      >
                        <div>
                          <span>RF:-</span>
                          <span className="f22 label txt-primary">
                            <i className="fas fa-user-friends"></i>
                          </span>
                        </div>
                        <div>
                          <label htmlFor="">{each.container_no}</label>
                        </div>
                      </div>

                      <div
                        className="card col-1 pl-4 pt-1 mb-1"
                        style={{
                          borderRadius: "0px",
                          borderRight: "1px solid",
                        }}
                      >
                        <div>
                          <span>Carrier</span>
                        </div>
                        <div>
                          <label htmlFor="">
                            {each.carrier_type}
                            <i className="ml-1 fas fa-ship"></i>
                          </label>
                        </div>
                      </div>
                      <div
                        className="card col-3 pl-4 pt-1 mb-1"
                        style={{
                          borderRadius: "0px",
                          borderRight: "1px solid",
                        }}
                      >
                        <div>
                          <label htmlFor="">
                            <img
                              src={srcFlag[0].flag}
                              alt=""
                              style={{ width: "30px" }}
                            />
                            <span className="ml-1">{`${srcEvent.location_details.name +
                              ", " +
                              srcEvent.location_details.country +
                              ", " +
                              srcEvent.location_details.locode
                              }`}</span>
                          </label>
                        </div>
                        <div>
                          <label htmlFor="">
                            <img
                              src={dstFlag[0].flag}
                              alt=""
                              style={{ width: "30px" }}
                            />
                            <span className="ml-1">{`${dstEvent.location_details.name +
                              ", " +
                              dstEvent.location_details.country +
                              ", " +
                              dstEvent.location_details.locode
                              }`}</span>
                          </label>
                        </div>
                      </div>

                      <div
                        className="card col-1 pt-1 mb-1"
                        style={{
                          borderRadius: "0px",
                          fontSize: "15px",
                          borderRight: "1px solid",
                        }}
                      >
                        <div>
                          <span style={{ fontSize: "13px" }}>Consignee</span>
                        </div>
                        <div>
                          <label htmlFor="">--</label>
                        </div>
                      </div>

                      <div
                        className="card col-2 pl-4 pt-1 mb-1"
                        style={{
                          borderRadius: "0px",
                          borderRight: "1px solid",
                        }}
                      >
                        <div>
                          <span>
                            {eta.isAfter(now) ? "Carrier ETA" : "Arrived On"}
                          </span>
                        </div>
                        <div>
                          <label htmlFor="">{etaTime || ""}</label>
                        </div>
                      </div>

                      <div
                        className="card col-2 pl-4 pt-1 mb-1"
                        style={{
                          borderRadius: "0px",
                          borderRight: "1px solid",
                        }}
                      >
                        <div
                          style={{
                            background: "#555fea",
                            color: "white",
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          {eta.isAfter(now) ? "ACTIVE" : "COMPLETED"}
                        </div>
                      </div>

                      <div
                        className="card col-1 pl-4 pt-4 mb-1"
                        style={{ borderRadius: "0px" }}
                      >
                        <div>
                          <i className="mr-4 fas fa-share-alt"></i>
                          <i className="fas fa-ellipsis-v"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

          <div className="row justify-content-between mx-0">
            <div className="col-5 mt-3">
              {/* Scheduled dates */}
              <div className="accContainer card col mb-2 border border-gray">
                <div
                  role="button"
                  className="accTitleContainer text-white row justify-content-between align-items-center p-1 px-3"
                  style={{ backgroundColor: "#ededed" }}
                  onClick={() =>
                    this.setState({
                      isScheduledDatesActive:
                        !this.state.isScheduledDatesActive,
                    })
                  }
                >
                  <label
                    htmlFor=""
                    className="mt-2"
                    style={{ color: "dimgray", fontWeight: 800 }}
                  >
                    Scheduled Dates
                  </label>
                  {this.state.isScheduledDatesActive ? (
                    <label htmlFor="">
                      <i
                        className="fa fa-chevron-up"
                        style={{ color: "dimgray" }}
                        aria-hidden="true"
                      ></i>
                    </label>
                  ) : (
                    <i
                      className="fa fa-chevron-down"
                      style={{ color: "dimgray" }}
                      aria-hidden="true"
                    ></i>
                  )}
                  {/* </div> */}
                </div>
                {this.state.isScheduledDatesActive && (
                  <div className="pt-3">
                    <div className="row justify-content-between px-1">
                      <div className="col-5">
                        <label htmlFor="">Shipment Ready Date</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">
                          {this.state.isScheduledDatesEditEnabled ? (
                            <div style={{ fontSize: "0.8rem" }}>
                              <Datetime
                                name="shipmentReadyDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) =>
                                  current.isAfter(moment().subtract(1, "day"))
                                }
                                value={this.state.shipmentReadyDate}
                                dateFormat="DD MMM YYYY"
                                // timeFormat="HH:mm:ss"
                                timeFormat={false}
                                onChange={this.handlerDateTime.bind(
                                  this,
                                  "shipmentReadyDate"
                                )}
                                required
                              />
                            </div>
                          ) : (
                            `${moment(this.state.shipmentReadyDate).format(
                              "DD MMM YYYY"
                            )}`
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-between px-1">
                      <div className="col-5">
                        <label htmlFor="">Scheduled Depart</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">
                          {/* {this.state.isScheduledDatesEditEnabled ? (
                            <>
                              <Datetime
                                name="scheduledDepart"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) =>
                                  current.isAfter(moment().subtract(1, "day"))
                                }
                                value={this.state.scheduledDepart}
                                dateFormat="DD MMM YYYY"
                                // timeFormat="HH:mm:ss"
                                timeFormat={false}
                                onChange={this.handlerDateTime.bind(
                                  this,
                                  "scheduledDepart"
                                )}
                                required
                              />
                            </>
                          ) : (
                            `${moment(this.state.scheduledDepart).format(
                              "DD MMM YYYY"
                            )}`
                          )} */}
                          {this.state.scheduledDepart
                            ? this.state.scheduledDepart
                            : "-"}
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-between px-1 pb-2">
                      <div className="col-5">
                        <label htmlFor="">Scheduled Arrival</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">
                          {/* {this.state.isScheduledDatesEditEnabled ? (
                            <>
                              <Datetime
                                name="scheduledArrivalDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) =>
                                  current.isAfter(moment().subtract(1, "day"))
                                }
                                value={this.state.scheduledArrivalDate}
                                dateFormat="DD MMM YYYY"
                                // timeFormat="HH:mm:ss"
                                timeFormat={false}
                                onChange={this.handlerDateTime.bind(
                                  this,
                                  "scheduledArrivalDate"
                                )}
                                required
                              />
                            </>
                          ) : (
                            `${this.state.scheduledArrivalDate
                              ? moment(
                                this.state.scheduledArrivalDate
                              ).format("DD MMM YYYY")
                              : "NA"
                            }`
                          )} */}
                          {this.state.scheduledArrivalDate
                            ? this.state.scheduledArrivalDate
                            : "-"}
                        </label>
                      </div>
                    </div>
                    {/* <div
                      className="row justify-content-end"
                      style={{ backgroundColor: "#ededed" }}
                    >
                      {this.state.isScheduledDatesEditEnabled ? (
                        <>
                          <button
                            className="btn btn-primary my-2"
                            onClick={this.handleScheduledDateAndTimes}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Save
                            </label>
                          </button>
                          <button
                            className="btn btn-primary m-2"
                            onClick={() => {
                              this.setState({
                                isScheduledDatesEditEnabled:
                                  !this.state.isScheduledDatesEditEnabled,
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Cancel
                            </label>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-primary m-2 px-3"
                            onClick={() => {
                              this.setState({
                                isScheduledDatesEditEnabled:
                                  !this.state.isScheduledDatesEditEnabled,
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Edit
                            </label>
                          </button>
                        </>
                      )}
                    </div> */}
                    <div
                      className="row justify-content-end"
                      style={{ backgroundColor: "#ededed" }}
                    >
                      {this.state.isScheduledDatesEditEnabled ? (
                        <>
                          <button
                            className="btn btn-primary my-2"
                            onClick={this.handleScheduledDateAndTimes}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Save
                            </label>
                          </button>
                          <button
                            className="btn btn-primary m-2"
                            onClick={() => {
                              this.setState({
                                isScheduledDatesEditEnabled:
                                  !this.state.isScheduledDatesEditEnabled,
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Cancel
                            </label>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-primary m-2 px-3"
                            onClick={() => {
                              this.setState({
                                isScheduledDatesEditEnabled:
                                  !this.state.isScheduledDatesEditEnabled,
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Edit
                            </label>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Arrival Estimates */}
              <div className="accContainer card col mb-2 border border-gray">
                <div
                  role="button"
                  className="accTitleContainer text-white row justify-content-between align-items-center p-1 px-3"
                  style={{ backgroundColor: "#ededed" }}
                  onClick={() =>
                    this.setState({
                      isArrivalEstimatesActive:
                        !this.state.isArrivalEstimatesActive,
                    })
                  }
                >
                  <label
                    htmlFor=""
                    className="mt-2"
                    style={{ color: "dimgray", fontWeight: 800 }}
                  >
                    Arrival Estimates
                  </label>
                  {this.state.isArrivalEstimatesActive ? (
                    <label htmlFor="">
                      <i
                        className="fa fa-chevron-up"
                        style={{ color: "dimgray" }}
                        aria-hidden="true"
                      ></i>
                    </label>
                  ) : (
                    <i
                      className="fa fa-chevron-down"
                      style={{ color: "dimgray" }}
                      aria-hidden="true"
                    ></i>
                  )}
                  {/* </div> */}
                </div>
                {this.state.isArrivalEstimatesActive && (
                  <div className="pt-3">
                    <div className="row justify-content-between px-1">
                      <div className="col-5">
                        <label htmlFor="">Time to Destination</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">
                          {/* {this.state.isManualEtaActive ? (
                            this.state.isManualEtaEnable ? (
                              <>
                                <Datetime
                                  name="changedArrivalEta"
                                  disableCloseOnClickOutside={false}
                                  closeOnSelect={true}
                                  isValidDate={(current) =>
                                    current.isAfter(moment().subtract(1, "day"))
                                  }
                                  value={this.state.changedArrivalEta}
                                  dateFormat="DD MMM YYYY"
                                  // timeFormat="HH:mm:ss"
                                  timeFormat={false}
                                  onChange={this.handlerDateTime.bind(
                                    this,
                                    "changedArrivalEta"
                                  )}
                                  required
                                />
                              </>
                            ) : (
                              moment(this.state.manualETA).format("DD MMM YYYY")
                            )
                          ) : this.state.isCarrierEtaActive ? (
                            this.state.carrierETA
                          ) : (
                            ""
                          )} */}
                          {this.state.timeToDestination
                            ? `${this.state.timeToDestination} days, `
                            : ""}
                          {this.state.scheduledArrivalDate
                            ? this.state.scheduledArrivalDate
                            : "-"}
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-between px-1">
                      <div className="col-5">
                        <label htmlFor="">ETA Status</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">{this.state.etaStatus}</label>
                      </div>
                    </div>
                    <div className="row justify-content-between px-1">
                      <div className="col-5">
                        <label htmlFor="">Estimate Source</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">
                          {/* {this.state.isManualEtaActive
                            ? "Manual Estimate"
                            : this.state.isCarrierEtaActive
                              ? "Carrier Estimate"
                              : ""} */}
                          Carrier Estimate
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-between px-1">
                      <div className="col-5">
                        <label htmlFor="">Delay Reason</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">
                          {/* {this.state.isManualEtaActive ? (
                            this.state.isManualEtaEnable ? (
                              <>
                                <input
                                  type="text"
                                  style={{ width: "197.5px" }}
                                  value={this.state.changedDelayReason}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      changedDelayReason: e.target.value,
                                      isArrivalEstimatesValuesChanged: true,
                                    });
                                  }}
                                />
                              </>
                            ) : (
                              this.state.manualDelayReason
                            )
                          ) : (
                            this.state.carrierDelayReason
                          )} */}
                          {this.state.isArrivalEstimatesEditable ? (
                            <>
                              <input
                                type="text"
                                style={{ width: "100%" }}
                                value={this.state.changedDelayReason}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    changedDelayReason: e.target.value,
                                    isArrivalEstimatesValuesChanged: true,
                                  });
                                }}
                              />
                            </>
                          ) : (
                            this.state.delayReason
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-between px-1 pb-2">
                      <div className="col-5">
                        <label htmlFor="">Last Updated</label>
                      </div>
                      <div className="col-7">
                        <label htmlFor="">
                          {/* {this.state.lastUpdatedData.hasOwnProperty("date")
                            ? moment(this.state.lastUpdatedData.date).format(
                              "DD MMM YYYY HH:mm"
                            )
                            : "None"} */}
                          {this.state.lastUpdatedAt
                            ? this.state.lastUpdatedAt
                            : ""}
                        </label>
                      </div>
                    </div>
                    {/* <div
                      className="row justify-content-end"
                      style={{ backgroundColor: "#ededed" }}
                    >
                      <button className="btn btn-primary my-2 d-none">
                        <label htmlFor="" role="button" className="mb-0">
                          Reset Carrier Data
                        </label>
                      </button>
                      {this.state.isManualEtaActive ? (
                        <>
                          <button
                            className="btn btn-primary my-2 ml-2"
                            onClick={() => {
                              this.setState({
                                isManualEtaActive: false,
                                isCarrierEtaActive: true,
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Use Carrier ETA
                            </label>
                          </button>
                          {this.state.isManualEtaEnable ? (
                            <>
                              <button
                                className="btn btn-primary ml-2 my-2"
                                onClick={() => {
                                  this.setState({
                                    isManualEtaEnable: false,
                                    changedArrivalEta: "",
                                    changedDelayReason: "",
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Cancel
                                </label>
                              </button>
                              <button className="btn btn-primary m-2">
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                  onClick={this.handleArrivalEstimates}
                                >
                                  Save
                                </label>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary m-2"
                                onClick={() => {
                                  this.setState({
                                    isManualEtaEnable: true,
                                    // openModal:true
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Edit
                                </label>
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-primary m-2"
                            onClick={() => {
                              this.setState({
                                isManualEtaActive: true,
                                isCarrierEtaActive: false,
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0 d-none">
                              Use Manual ETA
                            </label>
                          </button>
                        </>
                      )}
                    </div> */}
                    <div
                      className="row justify-content-end"
                      style={{ backgroundColor: "#ededed" }}
                    >
                      {this.state.isArrivalEstimatesEditable ? (
                        <>
                          <button
                            className="btn btn-primary ml-2 my-2"
                            onClick={() => {
                              this.setState({
                                isArrivalEstimatesEditable: false,
                                changedDelayReason: "",
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Cancel
                            </label>
                          </button>
                          <button className="btn btn-primary m-2">
                            <label
                              htmlFor=""
                              role="button"
                              className="mb-0"
                              onClick={this.handleArrivalEstimates}
                            >
                              Save
                            </label>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-primary m-2"
                            onClick={() => {
                              this.setState({
                                isArrivalEstimatesEditable: true,
                              });
                            }}
                          >
                            <label htmlFor="" role="button" className="mb-0">
                              Edit
                            </label>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Timeline */}
              <div className="accContainer card col mb-2 border border-gray">
                <div
                  role="button"
                  className="accTitleContainer text-white row justify-content-between align-items-center p-1 px-3"
                  style={{ backgroundColor: "#ededed" }}
                  onClick={() =>
                    this.setState({
                      isTimelineActive: !this.state.isTimelineActive,
                    })
                  }
                >
                  <label
                    htmlFor=""
                    className="mt-2"
                    style={{ color: "dimgray", fontWeight: 800 }}
                  >
                    Timeline
                  </label>
                  {this.state.isTimelineActive ? (
                    <label htmlFor="">
                      <i
                        className="fa fa-chevron-up"
                        style={{ color: "dimgray" }}
                        aria-hidden="true"
                      ></i>
                    </label>
                  ) : (
                    <i
                      className="fa fa-chevron-down"
                      style={{ color: "dimgray" }}
                      aria-hidden="true"
                    ></i>
                  )}
                  {/* </div> */}
                </div>
                {this.state.isTimelineActive && (
                  <div className="tracker-container">
                    {/* {this.state.events.map((eachEvent, index) => {
                        let {
                          event_name,
                          actual,
                          location,
                          location_details,
                          vessel_details,
                          date,
                          description,
                          geofence_date,
                          status,
                          type,
                          vessel,
                          voyage,
                        } = eachEvent; */}
                    {this.state.events.map((eachEvent, index) => {
                      console.log("eachEvent", eachEvent);
                      // let {event_name, location_details, date} = eachEvent;
                      return (
                        <React.Fragment key={index}>
                          <div className="each-event">
                            <div className="location-info">
                              <div className="status">
                                {eachEvent.event_name}
                              </div>
                              <div className="location">
                                {eachEvent.hasOwnProperty("location_details")
                                  ? eachEvent.location_details.name +
                                  ", " +
                                  eachEvent.location_details.country
                                  : "-"}
                              </div>
                            </div>
                            <div className="event-tick">
                              <div
                                className="line-before"
                                style={{
                                  height:
                                    index == this.state.events.length - 1 && 0,
                                  backgroundColor:
                                    eachEvent.event_name !== "Dispatch" &&
                                    (index == this.state.lastActualTrueIndex ||
                                      !eachEvent.actual) &&
                                    "rgb(222, 220, 220)",
                                }}
                              ></div>
                              <div
                                data-tooltip={index == this.state.lastActualTrueIndex
                                  ? eachEvent.days_delayed > 0 ? "Delayed by " + eachEvent.days_delayed.toString() + " days" : ""
                                  : index == this.state.firstActualFalseIndex ? this.state.currentEtaStatus == "Delayed" ? this.state.currentDelayType + " " + this.state.currentEtaStatus + " by " + this.state.currentDelay + " days" : "" : ""
                                }
                                className={`tracking-icon-container ${index == this.state.lastActualTrueIndex
                                  ? eachEvent.days_delayed > 0 ? "blinker-container tooltip-container" : "blinker-container"
                                  : index == this.state.firstActualFalseIndex ? this.state.currentEtaStatus == "Delayed" ? "blinker-container tooltip-container" : "blinker-container" : ""
                                  }`}
                                style={{
                                  backgroundColor:
                                    !eachEvent.actual ? index == this.state.firstActualFalseIndex
                                      ? this.state.currentEtaStatus == "Delayed" ? "rgb(187 76 76)" : "rgb(222, 220, 220)"
                                      : "rgb(222, 220, 220)"
                                      :
                                      index == this.state.lastActualTrueIndex
                                        ?
                                        eachEvent.days_delayed > 0 ?
                                          "rgb(187 76 76)" : ""
                                        : ""
                                }}
                              >
                                {(index == this.state.lastActualTrueIndex || (index == this.state.firstActualFalseIndex && this.state.currentEtaStatus == "Delayed")) && (
                                  <div className="blinker" style={{
                                    border:
                                      eachEvent.days_delayed > 0 ?
                                        "8px solid rgb(187 76 76)"
                                        : index == this.state.firstActualFalseIndex ? this.state.currentEtaStatus == "Delayed" ?
                                          "8px solid rgb(187 76 76)" : ""
                                          : ""
                                  }}></div>
                                )}
                                <i
                                  className={`fas fa-check tracking-icons ${(index == this.state.lastActualTrueIndex || (index == this.state.firstActualFalseIndex && this.state.currentEtaStatus == "Delayed"))
                                    ? "blinker-icon"
                                    : ""
                                    }`}
                                ></i>
                              </div>
                            </div>
                            <div className="date">
                              <div className="planned-date">
                                PD :
                                {/* {!eachEvent.hasOwnProperty("date") ? "-" : (eachEvent.status == this.state.lastHistoryTrueStatus && eachEvent.description == this.state.lastHistoryTrueDesc)
                                  ? moment(this.state.histOriginalDate).format("DD MMM YYYY")
                                  : moment(eachEvent.date).format("DD MMM YYYY")} */}
                                {!eachEvent.planned_date ? "-" : moment(eachEvent.planned_date).format("DD MMM YYYY")}
                              </div>
                              <div className="actual-date">
                                AD :
                                {eachEvent.hasOwnProperty("date") &&
                                  eachEvent.actual
                                  ? moment(eachEvent.date).format("DD MMM YYYY")
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 col-7">
              <div
                id="map"
                className="card mx-0 border border-gray"
                style={{ width: "100%", height: "50vh", marginBottom: "10px" }}
              ></div>
              <div className="tabs mt-2">
                <label
                  htmlFor=""
                  style={{ width: "70px", marginLeft: "10px", fontWeight: 600 }}
                  role="button"
                  onClick={() => {
                    this.setState({
                      tabActive: "details",
                      tabStyling: { width: "58px", left: "2px" },
                    });
                  }}
                >
                  Details
                </label>
                <label
                  htmlFor=""
                  style={{ width: "70px", fontWeight: 600 }}
                  role="button"
                  onClick={() => {
                    this.setState({
                      tabActive: "events",
                      tabStyling: { width: "58px", left: "60px" },
                      tabStyling: { width: "58px", left: "70px" },
                    });
                  }}
                >
                  Events
                </label>
                <label
                  htmlFor=""
                  style={{ width: "60px", fontWeight: 600 }}
                  role="button"
                  onClick={() => {
                    this.setState({
                      tabActive: "route",
                      tabStyling: { width: "58px", left: "140px" },
                    });
                  }}
                >
                  Route
                </label>
                <label
                  htmlFor=""
                  style={{ width: "70px", fontWeight: 600 }}
                  role="button"
                  onClick={() => {
                    this.setState({
                      tabActive: "product",
                      tabStyling: { width: "70px", left: "200px" },
                    });
                  }}
                >
                  Product
                </label>
                <label
                  htmlFor=""
                  style={{ width: "80px", fontWeight: 600 }}
                  role="button"
                  onClick={() => {
                    this.setState({
                      tabActive: "container",
                      tabStyling: { width: "85px", left: "268px" },
                    });
                  }}
                >
                  Container
                </label>
                <label
                  htmlFor=""
                  style={{ width: "75px", fontWeight: 600 }}
                  role="button"
                  onClick={() => {
                    this.setState({
                      tabActive: "documents",
                      tabStyling: { width: "87px", left: "350px" },
                    });
                  }}
                >
                  Documents
                </label>
                <div
                  className="underlineStyling bg-primary mb-2"
                  style={this.state.tabStyling}
                ></div>
              </div>

              {/* Details */}
              {this.state.tabActive === "details" && (
                <div className="card mx-0">
                  <div className="accContainer col">
                    <div
                      role="button"
                      className="accTitleContainer row justify-content-between align-items-center p-1 px-3"
                      style={{ background: "#ededed" }}
                      onClick={() =>
                        this.setState({
                          isdetailsTabActive: !this.state.isdetailsTabActive,
                        })
                      }
                    >
                      <label
                        htmlFor=""
                        className="mt-2"
                        style={{ color: "dimgray", fontWeight: 800 }}
                      >
                        Shipment References
                      </label>
                      {this.state.isdetailsTabActive ? (
                        <label htmlFor="">
                          <i
                            className="fa fa-chevron-up"
                            aria-hidden="true"
                          ></i>
                        </label>
                      ) : (
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      )}
                      {/* </div> */}
                    </div>
                    {this.state.isdetailsTabActive && (
                      <div className="pt-3" style={{ fontSize: "0.8rem" }}>
                        <div className="row justify-content-between px-1 mb-2">
                          <div className="col-5">
                            <label htmlFor="">
                              Shipment Name
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-7">
                            {this.state.isShipmentReferencesEditable ? (
                              <input
                                type="text"
                                style={{ width: "100%" }}
                                value={this.state.shipmentName}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    shipmentName: e.target.value,
                                    isShipmentReferenceValuesChanged: true,
                                  });
                                }}
                              />
                            ) : (
                              this.state.shipmentName
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 mb-2">
                          <div className="col-5">
                            <label htmlFor="">Bill of Lading</label>
                          </div>
                          <div className="col-7">
                            {this.state.isShipmentReferencesEditable ? (
                              <input
                                name="billOfLading"
                                type="text"
                                style={{ width: "100%" }}
                                value={this.state.billOfLading}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    billOfLading: e.target.value,
                                    isShipmentReferenceValuesChanged: true,
                                  });
                                }}
                              />
                            ) : (
                              this.state.billOfLading
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 mb-2">
                          <div className="col-5">
                            <label htmlFor="">PO Numbers</label>
                          </div>
                          <div className="col-7">
                            {this.state.isShipmentReferencesEditable ? (
                              <input
                                type="text"
                                name="poNumbers"
                                style={{ width: "100%" }}
                                value={this.state.poNumbers}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    poNumbers: e.target.value,
                                    isShipmentReferenceValuesChanged: true,
                                  });
                                }}
                              />
                            ) : (
                              this.state.poNumbers
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 mb-2">
                          <div className="col-5">
                            <label htmlFor="">Mode</label>
                          </div>
                          <div className="col-7">
                            {this.state.isShipmentReferencesEditable ? (
                              <Select
                                closeMenuOnSelect={true}
                                name="modeType"
                                className="border-radius-0"
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                  }),
                                }}
                                onChange={this.handleFilterOptions.bind(
                                  this,
                                  "modeType",
                                  "isShipmentReferenceValuesChanged"
                                )}
                                options={[
                                  { value: "LCL", label: "LCL" },
                                  { value: "FCL", label: "FCL" },
                                ]}
                                value={this.state.modeType}
                                required
                              ></Select>
                            ) : this.state.modeType === "" ? (
                              "None"
                            ) : this.state.modeType.hasOwnProperty("value") ? (
                              this.state.modeType.value
                            ) : (
                              "None"
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 mb-2">
                          <div className="col-5">
                            <label htmlFor="">IncoTerms</label>
                          </div>
                          <div className="col-7">
                            {this.state.isShipmentReferencesEditable ? (
                              <Select
                                closeMenuOnSelect={true}
                                name="incoTerms"
                                isMulti={true}
                                className="border-radius-0"
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                  }),
                                }}
                                onChange={this.handleFilterOptions.bind(
                                  this,
                                  "incoTerms",
                                  "isShipmentReferenceValuesChanged"
                                )}
                                options={[
                                  { value: "CFR", label: "CFR" },
                                  { value: "CIF", label: "CIF" },
                                  { value: "CIP", label: "CIP" },
                                  { value: "CPT", label: "CPT" },
                                  { value: "DAP", label: "DAP" },
                                  { value: "DDP", label: "DDP" },
                                  { value: "DPU", label: "DPU" },
                                  { value: "EXW", label: "EXW" },
                                  { value: "FAS", label: "FAS" },
                                  { value: "FCA", label: "FCA" },
                                  { value: "FOB", label: "FOB" },
                                ]}
                                value={this.state.incoTerms}
                                required
                              ></Select>
                            ) : (
                              this.state.incoTermsSet
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 mb-2">
                          <div className="col-5">
                            <label htmlFor="">HS Codes</label>
                          </div>
                          <div className="col-7">
                            {this.state.isShipmentReferencesEditable ? (
                              <input
                                type="text"
                                style={{ width: "100%" }}
                                className="form-control"
                                value={this.state.hsCodes}
                                onChange={(e) => {
                                  this.setState({
                                    hsCodes: e.target.value,
                                    isShipmentReferenceValuesChanged: true,
                                  });
                                }}
                              />
                            ) : (
                              this.state.hsCodes
                            )}
                          </div>
                        </div>

                        <div
                          className="row justify-content-end pr-3"
                          style={{ background: "#f9f9f9" }}
                        >
                          {this.state.isShipmentReferencesEditable ? (
                            <>
                              <button
                                className="btn btn-primary mr-2 mr-2"
                                onClick={() => {
                                  this.setState({
                                    isShipmentReferencesEditable:
                                      !this.state.isShipmentReferencesEditable,
                                  });
                                }}
                              >
                                <label
                                  className="mb-0"
                                  htmlFor=""
                                  role="button"
                                >
                                  Cancel
                                </label>
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={this.handleShipmentReferences}
                              >
                                <label
                                  className="mb-0"
                                  htmlFor=""
                                  role="button"
                                >
                                  Save
                                </label>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary m-2 px-3"
                                onClick={() => {
                                  this.setState({
                                    isShipmentReferencesEditable:
                                      !this.state.isShipmentReferencesEditable,
                                  });
                                }}
                              >
                                <label
                                  className="mb-0"
                                  htmlFor=""
                                  role="button"
                                >
                                  Edit
                                </label>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Events */}
              {this.state.tabActive === "events" && (
                <div className="card mx-0">
                  <table className="table1 eventsTable" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Event</th>
                        <th>Location</th>
                        <th style={{ width: "100px" }}>Original Plan</th>
                        <th style={{ width: "100px" }}>Current Plan</th>
                        <th style={{ width: "100px" }}>Actual Date</th>
                        <th>Geofence Date</th>
                        <th>Mode</th>
                        <th>Vessel Name</th>
                        <th>Voyage Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.events.map((eachEvent, index) => {
                        console.log("currentDelay", this.state.currentDelay)

                        let {
                          event_name,
                          actual,
                          planned_date,
                          location,
                          location_details,
                          vessel_details,
                          date,
                          description,
                          geofence_date,
                          status,
                          type,
                          vessel,
                          voyage,
                        } = eachEvent;
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td className={` ${index == this.state.lastActualTrueIndex
                                ? eachEvent.days_delayed > 0 ? "tooltip-container" : ""
                                : index == this.state.firstActualFalseIndex ? this.state.currentEtaStatus == "Delayed" ? "tooltip-container" : ""
                                  : ""
                                }`}
                                style={{ minWidth: "180px" }}
                                data-tooltip={index == this.state.lastActualTrueIndex
                                  ? eachEvent.days_delayed > 0 ? "Delayed by " + eachEvent.days_delayed.toString() + " days" : ""
                                  : index == this.state.firstActualFalseIndex ? this.state.currentEtaStatus == "Delayed" ? this.state.currentDelayType + " " + this.state.currentEtaStatus + " by " + this.state.currentDelay + " days" : ""
                                    : ""
                                }
                              >
                                <div className="grid-event-container">
                                  <div className="events-blinker-container">
                                    {(index ==
                                      this.state.lastActualTrueIndex || (index == this.state.firstActualFalseIndex && this.state.currentEtaStatus == "Delayed")) && (
                                        <div className="events-blinker" style={{
                                          border:
                                            eachEvent.days_delayed > 0 ?
                                              "8px solid rgb(187 76 76)" : index == this.state.firstActualFalseIndex ? this.state.currentEtaStatus == "Delayed" ? "8px solid rgb(187 76 76)" : ""
                                                : ""
                                        }}></div>
                                      )}
                                    <i
                                      className={
                                        actual
                                          ? index == this.state.lastActualTrueIndex
                                            ? eachEvent.days_delayed > 0 ? "fas fa-check icons active delayed" : "fas fa-check icons active"
                                            : "fas fa-check icons active"
                                          : index == this.state.firstActualFalseIndex ? this.state.currentEtaStatus == "Delayed" ? "fas fa-check icons active delayed" : "fas fa-check icons disabled"
                                            : "fas fa-check icons disabled"
                                      }
                                    ></i>
                                  </div>
                                  <div className="grid-event-name">
                                    {event_name}
                                  </div>
                                </div>
                              </td>
                              <td>
                                {location_details
                                  ? location_details.name +
                                  ", " +
                                  location_details.country
                                  : "-"}
                              </td>
                              <td style={{ minWidth: "90px" }}>
                                {planned_date
                                  ? moment(planned_date).format("DD MMM YYYY")
                                  : "-"}
                              </td>
                              <td style={{ minWidth: "90px" }}>
                                {planned_date
                                  ? moment(date).format("DD MMM YYYY")
                                  : "-"}
                              </td>
                              <td style={{ minWidth: "90px" }}>
                                {actual
                                  ? moment(date).format("DD MMM YYYY")
                                  : "-"}
                              </td>
                              <td>
                                {geofence_date
                                  ? moment(date).format("DD MMM YYYY")
                                  : "-"}
                              </td>
                              <td>
                                {type
                                  ? type[0].toUpperCase() + type.substring(1)
                                  : "-"}
                              </td>
                              <td>
                                {vessel_details ? vessel_details.name : "-"}
                              </td>
                              <td>{voyage ? voyage : "-"}</td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Documents */}
              {this.state.tabActive === "documents" && (
                <div className="col bg-white">
                  <button
                    className="btn btn-primary"
                    onClick={this.openDocumentsSlider.bind(this)}
                  >
                    Add Shipment Documents
                  </button>

                  <div className="row pl-3 mr-1">
                    <div className="col px-0">
                      {this.state.attachmentsDocArr.length === 0 ? (
                        <label htmlFor="" className="mt-2 pl-3">
                          No Documents are attached
                        </label>
                      ) : (
                        <div
                          className="row my-2"
                          style={{ paddingLeft: "12px" }}
                        >
                          <table
                            className="table1 table-bordered tptinfo"
                            style={{ overflowY: "auto" }}
                          >
                            <thead>
                              {/* <tr> */}
                              <th>
                                <label htmlFor="">Document Name</label>
                              </th>
                              <th>
                                <label htmlFor="">Document Type</label>
                              </th>
                              <th>
                                <label htmlFor="">Created By</label>
                              </th>
                              <th>
                                <label htmlFor="">Created At</label>
                              </th>
                              <th></th>
                            </thead>
                            <tbody>
                              {this.state.attachmentsDocArr.map(
                                (eachDocx, index) => {
                                  let createdDate = eachDocx.created_at;
                                  createdDate = moment
                                    .parseZone(createdDate)
                                    .format("MM/DD/YY, HH:mm A");
                                  return (
                                    <tr key={eachDocx._id}>
                                      <td>{eachDocx.document_title}</td>
                                      <td>{eachDocx.document_type}</td>
                                      <td>{eachDocx.created_by}</td>
                                      <td>{createdDate}</td>
                                      <td style={{ width: "95px" }}>
                                        <div>
                                          <i
                                            style={{
                                              fontSize: "20px",
                                              cursor: "pointer",
                                            }}
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                            onClick={this.deleteEximDocument.bind(
                                              this,
                                              eachDocx
                                            )}
                                          ></i>
                                          <a
                                            style={{
                                              fontSize: "20px",
                                              cursor: "pointer",
                                              color: "black",
                                            }}
                                            href={`${eachDocx.document_url}`}
                                          >
                                            <i
                                              class="fa fa-download mx-2"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                          <i
                                            style={{
                                              fontSize: "20px",
                                              cursor: "pointer",
                                            }}
                                            class="fa fa-eye"
                                            aria-hidden="true"
                                            onClick={this.previewDocument.bind(
                                              this,
                                              eachDocx
                                            )}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div
                className={"slide-r " + this.state.documentsTranslate}
                style={{ overflow: "auto" }}
              >
                <div className="document-slider bg-primary text-white pt-2 px-3">
                  <h6>
                    Add Document
                    <span
                      className="float-right closebtn"
                      style={{ marginRight: "25px" }}
                      onClick={this.onClickHideAll.bind(this)}
                    >
                      X
                    </span>
                  </h6>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                  <div className="p-2 d-flex flex-column">
                    <div className="pb-3">
                      <input
                        name="attachmentTitle"
                        className="form-control mr-3 mt-1"
                        id="exim-document"
                        placeholder="Name"
                        style={{ width: "93%" }}
                        onChange={(e) =>
                          this.setState({
                            attachmentTitle: e.target.value,
                          })
                        }
                        value={this.state.attachmentTitle}
                        required
                      />
                    </div>

                    <div className="pb-3">
                      <Select
                        closeMenuOnSelect={true}
                        name="modeType"
                        className="border-radius-0"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            fontSize: "0.8rem",
                            color: "#6e707e",
                            width: "93%",
                          }),
                        }}
                        onChange={this.newFilter.bind(this, "attachmentType")}
                        options={[
                          { value: "Bill of Lading", label: "Bill of Lading" },
                          { value: "Booking Note", label: "Booking Note" },
                          {
                            value: "Certificate of Origin",
                            label: "Certificate of Origin",
                          },
                          {
                            value: "Commercial Invoice",
                            label: "Commercial Invoice",
                          },
                          {
                            value: "Forwarders Cargo Receipt",
                            label: "Forwarders Cargo Receipt",
                          },
                          {
                            value: "Freight Invoice",
                            label: "Freight Invoice",
                          },
                          {
                            value: "Hazardous Declaration",
                            label: "Hazardous Declaration",
                          },
                          { value: "Manifest", label: "Manifest" },
                          { value: "Packing List", label: "Packing List" },
                          { value: "Purchase Order", label: "Purchase Order" },
                          { value: "Sea Waybill", label: "Sea Waybill" },
                        ]}
                        value={this.state.attachmentType}
                        required
                      />
                    </div>
                    <div className="">
                      <input
                        type="file"
                        name="attachmentsDoc"
                        style={{ fontSize: "0.8rem" }}
                        id="attachmentsDoc"
                        onChange={this.changeAttachmentsFileHandler.bind(this)}
                        // className="col-7 mt-3 border mr-3"
                        required
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary mt-3"
                        onClick={this.handleAddAttachmentsBtn.bind(this)}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Route */}
              {this.state.tabActive === "route" && (
                <div className="card mx-0">
                  <div className="accContainer col mb-2">
                    <div
                      role="button"
                      className="accTitleContainer row justify-content-between align-items-center p-1 px-3"
                      style={{ background: "#ededed" }}
                      onClick={() =>
                        this.setState({
                          isOriginAddressActive:
                            !this.state.isOriginAddressActive,
                        })
                      }
                    >
                      <label
                        htmlFor=""
                        className="mt-2"
                        style={{ color: "dimgray", fontWeight: 800 }}
                      >
                        Origin Address
                      </label>
                      {this.state.isOriginAddressActive ? (
                        <label htmlFor="">
                          <i
                            className="fa fa-chevron-up"
                            aria-hidden="true"
                          ></i>
                        </label>
                      ) : (
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      )}
                      {/* </div>
                       */}
                    </div>
                    {this.state.isOriginAddressActive && (
                      <div className="pt-3">
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">Contact &#47; Customer</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isOriginAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.originContactCustomer}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      originContactCustomer: e.target.value,
                                      isOriginAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.originContactCustomer ? this.state.originContactCustomer : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">Street Address 1</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isOriginAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.originStreetAddress1}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      originStreetAddress1: e.target.value,
                                      isOriginAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.originStreetAddress1 ? this.state.originStreetAddress1 : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">Street Address 2</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isOriginAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.originStreetAddress2}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      originStreetAddress2: e.target.value,
                                      isOriginAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.originStreetAddress2 ? this.state.originStreetAddress2 : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">City, Country</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {
                                this.state.originCity ? this.state.originCity + ", " : "-"
                              }
                              {
                                this.state.originCountry ? this.state.originCountry : ""
                              }
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1 pb-2">
                          <div className="col-5">
                            <label htmlFor="">Postcode</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isOriginAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.originPostcode}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      originPostcode: e.target.value,
                                      isOriginAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.originPostcode ? this.state.originPostcode : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="row justify-content-end"
                          style={{ background: "#f9f9f9" }}
                        >
                          {this.state.isOriginAddressEditEnabled ? (
                            <>
                              <button
                                className="btn btn-primary mr-2"
                                onClick={() => {
                                  this.setState({
                                    isOriginAddressEditEnabled:
                                      !this.state.isOriginAddressEditEnabled,
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Cancel
                                </label>
                              </button>
                              <button
                                className="btn btn-primary mr-2"
                                onClick={this.handleOriginAddress}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Save
                                </label>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary m-2 px-3"
                                onClick={() => {
                                  this.setState({
                                    isOriginAddressEditEnabled:
                                      !this.state.isOriginAddressEditEnabled,
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Edit
                                </label>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="accContainer col">
                    <div
                      role="button"
                      className="accTitleContainer row justify-content-between align-items-center p-1 px-3"
                      style={{ background: "#ededed" }}
                      onClick={() =>
                        this.setState({
                          isDeliveryAddressActive:
                            !this.state.isDeliveryAddressActive,
                        })
                      }
                    >
                      <label
                        htmlFor=""
                        className="mt-2"
                        style={{ color: "dimgray", fontWeight: 800 }}
                      >
                        Delivery Address
                      </label>
                      {this.state.isDeliveryAddressActive ? (
                        <label htmlFor="">
                          <i
                            className="fa fa-chevron-up"
                            aria-hidden="true"
                          ></i>
                        </label>
                      ) : (
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      )}
                      {/* </div>
                       */}
                    </div>
                    {this.state.isDeliveryAddressActive && (
                      <div className="pt-3">
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">Contact &#47; Customer</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isDeliveryAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.deliveryContactCustomer}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      deliveryContactCustomer: e.target.value,
                                      isDeliveryAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.deliveryContactCustomer ? this.state.deliveryContactCustomer : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">Street Address 1</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isDeliveryAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.deliveryStreetAddress1}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      deliveryStreetAddress1: e.target.value,
                                      isDeliveryAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.deliveryStreetAddress1 ? this.state.deliveryStreetAddress1 : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">Street Address 2</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isDeliveryAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.deliveryStreetAddress2}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      deliveryStreetAddress2: e.target.value,
                                      isDeliveryAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.deliveryStreetAddress2 ? this.state.deliveryStreetAddress2 : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">City, Country</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {
                                this.state.deliveryCity ? this.state.deliveryCity + ", " : "-"
                              }
                              {
                                this.state.deliveryCountry ? this.state.deliveryCountry : ""
                              }
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-between px-1 pb-2">
                          <div className="col-5">
                            <label htmlFor="">Postcode</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">
                              {this.state.isDeliveryAddressEditEnabled ? (
                                <input
                                  type="text"
                                  value={this.state.deliveryPostcode}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      deliveryPostcode: e.target.value,
                                      isDeliveryAddressValuesChanged: true,
                                    });
                                  }}
                                />
                              ) : (
                                `${this.state.deliveryPostcode ? this.state.deliveryPostcode : "None"}`
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="row justify-content-end"
                          style={{ background: "#f9f9f9" }}
                        >
                          {this.state.isDeliveryAddressEditEnabled ? (
                            <>
                              <button
                                className="btn btn-primary mr-2"
                                onClick={() => {
                                  this.setState({
                                    isDeliveryAddressEditEnabled:
                                      !this.state.isDeliveryAddressEditEnabled,
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Cancel
                                </label>
                              </button>
                              <button
                                className="btn btn-primary mr-2"
                                onClick={this.handleDeliveryAddress}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Save
                                </label>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary m-2 px-3"
                                onClick={() => {
                                  this.setState({
                                    isDeliveryAddressEditEnabled:
                                      !this.state.isDeliveryAddressEditEnabled,
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Edit
                                </label>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Product */}
              {this.state.tabActive === "product" && (
                <div className="card mx-0">
                  <div className="row justify-content-between m-2 px-3">
                    <div
                      className="col-2 text-center"
                      style={{
                        backgroundColor: "#ededed",
                        borderRadius: "10px",
                        color: "gray",
                      }}
                    >
                      <div style={{ paddingTop: "5px" }}>
                        <label htmlFor="">
                          {this.state.allProductsData.length}
                        </label>
                      </div>
                      <div>
                        <label htmlFor="">Products</label>
                      </div>
                    </div>

                    <div
                      className="col-2 text-center"
                      style={{
                        backgroundColor: "#ededed",
                        borderRadius: "10px",
                        color: "gray",
                      }}
                    >
                      <div style={{ paddingTop: "5px" }}>
                        <label htmlFor="">{this.state.weightsCount} Kgs</label>
                      </div>
                      <div>
                        <label htmlFor="">Weight</label>
                      </div>
                    </div>

                    <div
                      className="col-2 text-center"
                      style={{
                        backgroundColor: "#ededed",
                        borderRadius: "10px",
                        color: "gray",
                      }}
                    >
                      <div style={{ paddingTop: "5px" }}>
                        <label htmlFor="">
                          {this.state.cbmCount} m
                          <label
                            style={{
                              position: "relative",
                              // right: "6px",
                              bottom: "5px",
                              fontSize: "9px",
                            }}
                          >
                            3
                          </label>
                        </label>
                      </div>
                      <div style={{ marginTop: "-8px" }}>
                        <label htmlFor="" className="mb-2 pr-1">
                          CBM
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-2 text-center"
                      style={{
                        backgroundColor: "#ededed",
                        borderRadius: "10px",
                        color: "gray",
                      }}
                    >
                      <div style={{ paddingTop: "5px" }}>
                        <label htmlFor="">$ {this.state.valueCount}</label>
                      </div>
                      <div>
                        <label htmlFor="">Value</label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 ml-3">
                    <button
                      className="btn btn-primary mb-2 ml-2"
                      style={{ fontSize: "14px" }}
                      onClick={() => {
                        // console.log(
                        //   "productDetails",
                        //   this.state.productDetails
                        // );
                        this.setState({
                          allProductsData: this.state.allProductsData.concat(
                            this.state.productDetails
                          ),
                        });
                      }}
                    >
                      {this.state.allProductsData.length === 0
                        ? "Add Product"
                        : "Add Another Product"}
                    </button>
                  </div>

                  {this.state.allProductsData.map((each, outerIndex) => {
                    return (
                      <div key={outerIndex} className="accContainer col mt-3">
                        <div
                          role="button"
                          className="accTitleContainer row justify-content-between align-items-center p-1 px-3"
                          style={{ background: "#ededed" }}
                          onClick={(e) => {
                            this.accordianHandle(outerIndex);
                          }}
                        >
                          <label
                            htmlFor=""
                            className="mt-2"
                            style={{ color: "dimgray", fontWeight: 800 }}
                          >
                            {each.product_name === ""
                              ? "Product"
                              : each.product_name}
                          </label>
                          {each.active ? (
                            <label htmlFor="">
                              <i
                                className="fa fa-chevron-up"
                                aria-hidden="true"
                              ></i>
                            </label>
                          ) : (
                            <i
                              className="fa fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                          )}
                          {/* </div> */}
                        </div>
                        {each.active && (
                          <div>
                            <div className="row justify-content-between px-1 mb-2 mt-2">
                              <div className="col-5">
                                <label htmlFor="">Name</label>
                              </div>
                              <div className="col-7">
                                {each._id === "" ? (
                                  <input
                                    style={{
                                      width: "100%",
                                      fontSize: "0.8rem",
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Name your product"
                                    value={each.product_name}
                                    onChange={(e) => {
                                      this.handleOnChangeForProducts(
                                        outerIndex,
                                        "product_name",
                                        e.target.value
                                      );
                                    }}
                                  />
                                ) : (
                                  each.product_name
                                )}
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Description</label>
                              </div>
                              <div className="col-7">
                                <input
                                  style={{ width: "100%", fontSize: "0.8rem" }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Describe your product"
                                  value={each.product_description}
                                  onChange={(e) => {
                                    this.handleOnChangeForProducts(
                                      outerIndex,
                                      "product_description",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Product Type</label>
                              </div>
                              <div className="col-7">
                                <Select
                                  closeMenuOnSelect={true}
                                  name="product_type"
                                  className="border-radius-0"
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      fontSize: "0.8rem",
                                      color: "#6e707e",
                                    }),
                                  }}
                                  onChange={this.productNewFilter.bind(
                                    this,
                                    "product_type",
                                    outerIndex
                                  )}
                                  options={[
                                    {
                                      value: "Select Product Type",
                                      label: "Select Product Type",
                                    },
                                    { value: "Cartons", label: "Cartons" },
                                    { value: "Pallets", label: "Pallets" },
                                    { value: "Boxes", label: "Boxes" },
                                    { value: "Barrels", label: "DAP" },
                                  ]}
                                  value={each.product_type}
                                  required
                                ></Select>
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Weight &#40;Kg&#41;</label>
                              </div>
                              <div className="col-7">
                                <input
                                  type="number"
                                  style={{ width: "100%", fontSize: "0.8rem" }}
                                  className="form-control"
                                  placeholder=""
                                  value={each.product_weight}
                                  onChange={(e) => {
                                    this.handleOnChangeForProducts(
                                      outerIndex,
                                      "product_weight",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Value &#40;$&#41;</label>
                              </div>
                              <div className="col-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  placeholder=""
                                  value={each.product_value}
                                  onChange={(e) => {
                                    this.handleOnChangeForProducts(
                                      outerIndex,
                                      "product_value",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Length &#40;cm&#41;</label>
                              </div>
                              <div className="col-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  placeholder=""
                                  value={each.product_length}
                                  onChange={(e) => {
                                    this.handleOnChangeForProducts(
                                      outerIndex,
                                      "product_length",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Width &#40;cm&#41;</label>
                              </div>
                              <div className="col-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  placeholder=""
                                  value={each.product_width}
                                  onChange={(e) => {
                                    this.handleOnChangeForProducts(
                                      outerIndex,
                                      "product_width",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Height &#40;cm&#41;</label>
                              </div>
                              <div className="col-7">
                                <input
                                  type="number"
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  placeholder=""
                                  value={each.product_height}
                                  onChange={async (e) => {
                                    this.handleOnChangeForProducts(
                                      outerIndex,
                                      "product_height",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row justify-content-between px-1 mb-2">
                              <div className="col-5">
                                <label htmlFor="">Cubic Meters</label>
                              </div>
                              <div className="col-7">
                                <label htmlFor="">{each.product_cbm} m3</label>
                              </div>
                            </div>

                            <div
                              className="row justify-content-end"
                              style={{ background: "#ededed" }}
                            >
                              <button
                                className="btn btn-primary my-2"
                                onClick={this.removeProductAndUpdateCounters.bind(
                                  this,
                                  outerIndex
                                )}
                              // onClick={async () => {
                              //   let filterArr =
                              //     await this.state.product_names.filter(
                              //       (eachName) => {
                              //         return eachName !== each;
                              //       }
                              //     );
                              //   await this.setState(
                              //     {
                              //       product_names: filterArr,
                              //       productsCount: filterArr.length,
                              //     },
                              //     () => {
                              //       this.updateCbmCount();
                              //       this.updateWeightCount();
                              //       this.updateValueCount();
                              //     }
                              //   );
                              // }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Cancel
                                </label>
                              </button>
                              <button
                                className="btn btn-primary m-2 px-3"
                                // onClick={this.handleContainerDetails}
                                onClick={this.saveAndUpdateProductData.bind(
                                  this,
                                  outerIndex
                                )}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Save
                                </label>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Container */}
              {this.state.tabActive === "container" && (
                <div className="card mx-0">
                  <div className="accContainer col">
                    <div
                      role="button"
                      className="accTitleContainer row justify-content-between align-items-center p-1 px-3"
                      style={{ background: "#ededed" }}
                      onClick={() =>
                        this.setState({
                          isContainerDetailsActive:
                            !this.state.isContainerDetailsActive,
                        })
                      }
                    >
                      <label
                        htmlFor=""
                        className="mt-2"
                        style={{ color: "dimgray", fontWeight: 800 }}
                      >
                        Container Details
                      </label>
                      {this.state.isContainerDetailsActive ? (
                        <label htmlFor="">
                          <i
                            className="fa fa-chevron-up"
                            aria-hidden="true"
                          ></i>
                        </label>
                      ) : (
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      )}
                      {/* </div> */}
                    </div>
                    {this.state.isContainerDetailsActive && (
                      <div className="pt-3">
                        <div className="row justify-content-between px-1">
                          <div className="col-5">
                            <label htmlFor="">Container No.</label>
                          </div>
                          <div className="col-7">
                            <label htmlFor="">{this.state.container_no}</label>
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 pb-2">
                          <div className="col-5">
                            <label htmlFor="">Seal ID</label>
                          </div>
                          <div className="col-7">
                            {this.state.isContainerDetailsEditEnabled ? (
                              <input
                                style={{ width: "100%", fontSize: "0.8rem" }}
                                type="text"
                                value={this.state.sealId}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    sealId: e.target.value,
                                    isContainerDetailsValuesChanged: true,
                                  });
                                }}
                              />
                            ) : (
                              <label htmlFor="">{this.state.sealId ? this.state.sealId : "None"}</label>
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 pb-2">
                          <div className="col-5">
                            <label htmlFor="">Container Type</label>
                          </div>
                          <div className="col-7">
                            {this.state.isContainerDetailsEditEnabled ? (
                              <Select
                                closeMenuOnSelect={true}
                                name="containerType"
                                className="border-radius-0"
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                  }),
                                }}
                                onChange={this.handleFilterOptions.bind(
                                  this,
                                  "containerType",
                                  "isContainerDetailsValuesChanged"
                                )}
                                options={this.state.containerTypeOptions}
                                value={this.state.containerType}
                                required
                              ></Select>
                            ) : this.state.containerType === "" ? (
                              <label htmlFor="">None</label>
                            ) : this.state.containerType.hasOwnProperty(
                              "value"
                            ) ? (
                              <label htmlFor="">
                                {this.state.containerType.value}
                              </label>
                            ) : (
                              <label htmlFor="">None</label>
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 pb-2">
                          <div className="col-5">
                            <label htmlFor="">
                              Reefer Set Point &#40;°C&#41;
                            </label>
                          </div>
                          <div className="col-7">
                            {this.state.isContainerDetailsEditEnabled ? (
                              <input
                                style={{ width: "100%", fontSize: "0.8rem" }}
                                type="number"
                                value={this.state.reeferSetPoint}
                                className="form-control"
                                onChange={(e) => {
                                  console.log("e", e.target.value);
                                  this.setState({
                                    reeferSetPoint: e.target.value,
                                    isContainerDetailsValuesChanged: true,
                                  });
                                }}
                              />
                            ) : this.state.reeferSetPoint == 0 ||
                              this.state.reeferSetPoint === "" ? (
                              <label htmlFor="">None</label>
                            ) : (
                              <label htmlFor="">
                                {this.state.reeferSetPoint}
                              </label>
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-between px-1 pb-2">
                          <div className="col-5">
                            <label htmlFor="">Door Off</label>
                          </div>
                          <div className="col-7">
                            {this.state.isContainerDetailsEditEnabled ? (
                              <Select
                                closeMenuOnSelect={true}
                                name="doorOff"
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                  }),
                                }}
                                className="border-radius-0"
                                onChange={this.handleFilterOptions.bind(
                                  this,
                                  "doorOff",
                                  "isContainerDetailsValuesChanged"
                                )}
                                options={this.state.doorOffOption}
                                value={this.state.doorOff}
                                required
                              ></Select>
                            ) : this.state.doorOff === "" ? (
                              <label htmlFor="">None</label>
                            ) : this.state.doorOff.hasOwnProperty("value") ? (
                              <label htmlFor="">
                                {this.state.doorOff.value}
                              </label>
                            ) : (
                              <label htmlFor="">None</label>
                            )}
                          </div>
                        </div>

                        <div
                          className="row justify-content-end"
                          style={{ background: "#ededed" }}
                        >
                          {this.state.isContainerDetailsEditEnabled ? (
                            <>
                              <button
                                className="btn btn-primary my-2 mr-2"
                                onClick={() => {
                                  this.setState({
                                    isContainerDetailsEditEnabled:
                                      !this.state.isContainerDetailsEditEnabled,
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Cancel
                                </label>
                              </button>
                              <button
                                className="btn btn-primary my-2 mr-2"
                                onClick={this.handleContainerDetails}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Save
                                </label>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-primary m-2 px-3"
                                onClick={() => {
                                  this.setState({
                                    isContainerDetailsEditEnabled:
                                      !this.state.isContainerDetailsEditEnabled,
                                  });
                                }}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Edit
                                </label>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TrackingDetails);

function loadScript(url) {
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}
