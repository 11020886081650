import React, { useEffect } from "react";
import "./ProgressbarCard.css";
import moment from "moment";

const ProgressbarCard = (props) => {
  // console.log("ProgressbarCard", props);
  useEffect(() => {}, [props]);
  return (
    <div className="card p-3" style={{ borderRadius: "10px", display: "block"}}>
      {/* Heading Part */}
      <div class="pl-4">
        <label for="">
          Container No: &nbsp;&nbsp;
        <label style={{fontWeight:"bold"}}>
        {props.eventData[0].container_no?props.eventData[0].container_no:""}
        </label></label>
      </div>

      {/* Progress Bar */}
      <div className="wrapper pl-3 pt-4">
        <ul className="StepProgress">
          {
           (!props.eventData||props.eventData.length===0 || props.eventData===undefined)?<></>:
           <>
           {props.eventData.map((each, i) => {
            let classname = "";
            let idname = "";
            if (!each.hasOwnProperty("actual_time")) {
              //for active events only current class is enough
              classname = classname + " current";
            } else {
              // for finished events we set is-done which gives blue color
              classname = classname + " is-done";
              //for finished events again we have to determine delay / early and then assign green or red color
              if (each.statusCode === "Delay") {
                classname = classname + " late-event";
              } else if (each.statusCode === "Early") {
                classname = classname + " early-event";
              }

              if (each.is_last_event) {
                idname = "last-event";
              }
            }
            //If event has no actual_time, then that even is yet to get complete
            // if (!each.hasOwnProperty("actual_time")) {
            //   classname = "current ";
            // } else {
            //   // each.hasOwnProperty("is_last_event")
            //   if (
            //     each.hasOwnProperty("statusCode") &&
            //     each.hasOwnProperty("is_last_event")
            //   ) {
            //     //have actual_time and it means event is done
            //     // && each.is_last_event === false

            //     //if status is on time and last time is false then add blue circle
            //     if (each.statusCode === "On Time") {
            //       classname = classname + " " + "is-done";
            //     }

            //     //if status is delay and last time is false then add red circle
            //     if (each.statusCode === "Delay") {
            //       classname = classname + " " + "late-event";
            //     }

            //     //if status is early and last time is false then add green circle
            //     if (each.statusCode === "Early") {
            //       classname = classname + " " + "early-event";
            //     }

            //     //for event is last event, again on status extra have to add id also
            //     if (each.is_last_event) {
            //       idname = "last-event";
            //     }
            //   }
            // }
            return (
              <div
                className={`StepProgress-item ${classname}`}
                id={idname ? idname : ""}
                key={`${each.event_name}-${i}`}
              >
                <strong>{`${each.event_name}, ${each.event_group}, ${each.location}`}</strong>
                {moment(each.actual_time).format("MMMM Do YYYY")}
              </div>
            );
          })}
           </> 
          }
        </ul>
      </div>
    </div>
  );
};

export default ProgressbarCard;
