import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import redirectURL from "../redirectURL";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHHMM } from '../common/utils';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Make sure to import CSS for the DatePicker
import { json } from 'react-router-dom';


const OutlookvsRegion = () => {

    var [rowData, setrowData] = useState([]);
   
    var [overly, setoverly] = useState('show-n')
    // const [selectedDate, setSelectedDate] = useState(new Date());
    var [currentDate, setCurrentDate] = useState(new Date());
    var initialDate =`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
    var [datefilter, setDatefilter]= useState(initialDate);
    // const [showDatePicker, setShowDatePicker] = useState(false);
    var [loadshow, setloadshow] = useState('show-n')
    useEffect(() => {
        setloadshow('show-m')
        var params ={
            month: new Date()
        }
        redirectURL.post('/consignments/getoutlookvsregion',params).then((resp) => {
            try{
                var data = resp.data;
                if (data !='' && data !=undefined){
                    data = resp.data.outlook_order_released
                    if (data!='' && data != undefined){
                        if (data.length>0){
                            data = JSON.parse(data)
                            setrowData(data);
                        }else{
                            setrowData([])
                        }
                    }else{
                        setrowData([])
                    }
                }
            }catch(e){
                setrowData([])
                console.error('Error:', e);
            }
           finally{
            setTimeout(() => {
                setloadshow('show-n')
            }, 3000);
           }
           
        }).catch(error => {
            console.error('Fetch error:', error);
            setrowData([]); // Ensure rowData is empty in case of fetch error
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    
    }, [])
   
    const onClickHideAll = () => {
        setloadshow('show-n')
    }

   
    return (
        <>
        <div className='card-header2'>
            <div className='d-flex justify-content-between'>
                <div className='postioningHeader d-flex' style={{ position: "relative" }}>
                    <h2 className='heading3 mt-20p mb-20p' style={{ width: "800px" }}>Outlook vs Order Release</h2>
                  
                </div>
             
            </div>

         
            <div className='scrollable-table-container'>
            <table className="table"  style={{border:"0px",overflow: 'auto', width:"80%", height:"100%" }}>
                    <thead>
                        <tr>
                            <th style={{height:'10px'}} className='table-outlookvsorders'>Region</th>
                            <th style={{height:'10px'}} className='table-outlookvsorders'>Outlook</th>
                            <th style={{height:'10px'}} className='table-outlookvsorders'>Orders Release</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {rowData.length> 0 ? (rowData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.region}</td>
                                <td>{item.outlook}</td>
                                <td>{item.orders}</td>
                            </tr>
                        ))) : ( <tr style={{height:"170px"}}>
                        <td colSpan={3} style={{ textAlign: 'center', verticalAlign:'middle' }}>No rows to show</td>
                    </tr>)}
                    </tbody>
                </table>
                </div>
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </>

    )
}

export default OutlookvsRegion;