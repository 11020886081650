import React, { useState, useEffect, useRef } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SubmitBtn from "../layouts/submitBtn";
import SubmitRevisedBtn from "./submitrevisedbtn";

import BulkForceFileHandler from "../layouts/changebulkuploadhandler";

import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import Newcomponent from "./plantNewcomponent";
import Plannedcomponent from "./plantPlannedcomponent";
import Modifiedcomponent from "./plantModifiedcomponent";
import ClosedComponentForPlant from "./closedForPlantScreen";
import IBStuffingForPlant from "./ibStuffingforPlant";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
const TruckGPS = (props) => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [checkinput, setcheckinput] = useState(0)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    const [rerenderTrigger, setRerenderTrigger] = useState(false);
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [counters, setCounters] = useState({
        allData: [],
        new_plant: [],
        planned_plant: [],
        modified_plant: [],
        closed_data: [],
        ib_stuffing_data: []
    })
    var [tabsforcontainerReport, setTabsForContainerReport] = useState({
        container_report_new: [],
        container_report_planned: [],
        container_report_modified: [],
        container_new_1: [],
        container_new_2: []
    })
    const [isClicked, setIsClicked] = useState({
        total: true,
        active: false,
        inactive: false
    });
    const [transList, settransList] = useState([])
    const [check, setcheck] = useState(1)
    const [reloadData, setReloadData] = useState(true);
    const [activeTrucksCount, setactiveTrucksCount] = useState([])
    const [inactiverecords, setinactiverecords] = useState([])
    const [gpsInstalledCount, setgpsInstalledCount] = useState([])
    useEffect(() => {
        if (gridRef.current) {
            setGridApi(gridRef.current.api);
            setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        if (reloadData) {
            getPlantsData()
            setReloadData(false);
        }
    }, [reloadData]);
    const getPlantsData = () => {
        redirectURL.post("/dashboard/truckgpsdata")
            .then((response) => {
                try {
                    var records = JSON.parse(response.data.truck_gps_data);
                    if (localStorage.getItem('role') == 'tpt') {
                        records = records.filter(k => k.transporter_name == localStorage.getItem('tpt_name'))
                    }
                    var transporters = [{ label: 'All', value: 'all' }]
                    records.map((e) => {
                        if (e.transporter_name != '' && e.transporter_name != undefined) {
                            transporters.push({ label: e.transporter_name, value: e.transporter_name })
                        }
                    })
                    transporters = removeDuplicateObjects(transporters);
                    settransList(transporters)
                    var activerecords = [];
                    var inactiverecords = [];
                    if (records.length > 0) {
                        records.map((itm) => {
                            if (itm.gps_status == 1) {
                                activerecords.push(itm);
                            }
                            if (itm.gps_status == 0) {
                                inactiverecords.push(itm);
                            }
                        })
                    }
                    setrowData(records)
                    setoriginalData(records)
                    setactiveTrucksCount(activerecords)
                    setinactiverecords(inactiverecords)
                    setgpsInstalledCount(records)
                }
                catch (e) {

                }
            }, () => {
                //console.log(this.state.rowData, "gpsInstalledDetails")
            })
            .catch(function (e) {
                //console.log("Error ", e)
            })

    }
    var typeofselection = [
        { label: 'Ship to Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'SKU', value: 'sku_code' },
        { label: 'Brand', value: 'brand' },
        { label: 'Variant', value: 'variant' }, { label: 'WO Release Date', value: 'wo_release_date' }
    ]
    const handleReloadData = (e) => {
        setReloadData(e);
    };
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        from_date = moment(from_date, 'DD-MM-YYYY')
        var to_date = woReleaseDate.wo_release_to_date
        to_date = moment(to_date, 'DD-MM-YYYY')

        var filteredData = originalData.filter(item => {
            var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
            itemDate = moment(itemDate, 'DD-MM-YYYY')
            return itemDate.isBetween(from_date, to_date, null, '[]')
        });
        if (filteredData != '' && filteredData != undefined) {
            if (localStorage.getItem('role') == 'tpt') {
                filteredData = filteredData.filter(k => k.transporter_name == localStorage.getItem('tpt_name'))
            }
        }
        setrowData(filteredData)
    }
    const onClickHide = () => {
        setsliderForBulkupload('')
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const handleFilterOptions = (e) => {
        //console.log(e)
        var selectedtrans = e.value
        if (selectedtrans == 'all') {
            setrowData(originalData)
            var records = originalData
            var activerecords = [];
            var inactiverecords = [];
            if (records.length > 0) {
                records.map((itm) => {
                    if (itm.gps_status == 1) {
                        activerecords.push(itm);
                    }
                    if (itm.gps_status == 0) {
                        inactiverecords.push(itm);
                    }
                })
            }
            setrowData(originalData)
            setactiveTrucksCount(activerecords)
            setinactiverecords(inactiverecords)
            setgpsInstalledCount(originalData)
        }
        else {
            var data = originalData.filter(e => e.transporter_name == selectedtrans)
            var activerecords = [];
            var inactiverecords = [];
            if (data.length > 0) {
                data.map((itm) => {
                    if (itm.gps_status == 1) {
                        activerecords.push(itm);
                    }
                    if (itm.gps_status == 0) {
                        inactiverecords.push(itm);
                    }
                })
            }
            setrowData(data)
            setactiveTrucksCount(activerecords)
            setinactiverecords(inactiverecords)
            setgpsInstalledCount(data)
        }
    }
    const onclickbulkupload = () => {
        setsliderForBulkupload("slider-translate")
        setoverly('show-m')
    }
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        var filteredData
        if (typeoffield == 'ship_to_country') {
            filteredData = originalData.filter(item => item.ship_to_country.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'pod') {
            filteredData = originalData.filter(item => item.pod.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'sku_code') {
            filteredData = originalData.filter(item => item.sku_code.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'brand') {
            filteredData = originalData.filter(item => item.brand.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'variant') {
            filteredData = originalData.filter(item => item.variant.toLowerCase() == val.toLowerCase());
        }
        if (filteredData != '' && filteredData != undefined) {
            if (localStorage.getItem('role') == 'tpt') {
                filteredData = filteredData.filter(k => k.transporter_name == localStorage.getItem('tpt_name'))
            }
        }
        setrowData(filteredData)
    }
    var summaryViewcols = [
        {
            headerName: "Truck No",
            field: "truck_no",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Address",
            field: "address",
            width: 300,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latitude",
            field: "latitude",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Longitude",
            field: "longitude",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Speed",
            field: "speed",
            width: 100,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Last Packet Time",
            field: "timestamp",
            width: 170,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.timestamp != '' && params.data.timestamp != undefined) {
                        return getHyphenDDMMYYYY(params.data.timestamp);
                    }
                }
                catch (e) {
                }
            }
        }
    ]

    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Created Date",
            field: "wo_created_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_created_date != "" && params.data.wo_created_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_created_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship to Country",
            field: "ship_to_country",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Destination Country",
            field: "destination_country",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Order Type",
            field: "order_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "2wh/3wh/4wh",
            field: "wh2_wh3_wh4",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Requested Delivery Date",
            field: "requested_delivery_date",
            width: 190,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.requested_delivery_date != "" && params.data.requested_delivery_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.requested_delivery_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Ref No",
            field: "lc_ref_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Actual LC No",
            field: "actual_lc_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Expiry Date",
            field: "lc_expiry_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.lc_expiry_date != "" && params.data.lc_expiry_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.lc_expiry_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 170,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CP Invoice Date",
            field: "cp_invoice_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.cp_invoice_date != "" && params.data.cp_invoice_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.cp_invoice_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Delivery Block",
            field: "delivery_block",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Status",
            field: "payment_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Weight Status",
            field: "weight_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Buyer",
            field: "buyer",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Consignee",
            field: "consignee",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },


    ]
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                data: csvcontent,
            }
            redirectURL.post('/consignments/uploadBulkuploadforProductiondates', rparams).then((resp) => {
                if (resp.data.status == 'success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
            })
        }
    }
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Production Date', inputName: 'production_date', required: true },
        ])
        setcsvcontent(csvdata)
    }
    const onClickEditProductionData = (rowdata) => {
        setreload(true)
        var row = rowdata.data
        var production_date = row.production_date
        var data = [{ 'production_date': production_date, 'work_order_no': row.work_order_no }]
        var params = {
            data: data
        }
        redirectURL.post('/consignments/uploadBulkuploadforProductiondates', params).then((resp) => {
            if (resp.data.status == 'success') {
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
            }
            else {
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")

            }
        })
        // redirectURL.post('/consignments/editProductionDate', params).then((resp) => {
        //     if (resp.data.status == 'success') {
        //         setshow(true)
        //         setbasicTitle("Successfully Updated")
        //         setbasicType("success")
        //     }
        // })
    }
    const onClickRevisedProductionData = (rowdata) => {
        var row = rowdata.data
        var revised_production_date = row.revised_production_date
        var data = [{ 'revised_production_date': revised_production_date, 'work_order_no': row.work_order_no }]
        var params = {
            data: data
        }
        redirectURL.post('/consignments/uploadBulkuploadforRevisedProductiondates', params).then((resp) => {
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
            }
            else {
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")

            }
        })
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const columnwithDefs = summaryViewcols
    var productionDateCol = {
        headerName: "Production Date",
        field: "production_date",
        width: 150,
        filter: true,
        resizable: true,
        editable: true,
        cellEditor: DateEditor,
        //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
        valueGetter: function (params) {
            try {
                if (params.data.production_date != "" && params.data.production_date != undefined) {
                    return getHyphenDDMMYYYY(params.data.production_date);
                } else {
                    return "Not Available";
                }
            } catch (e) {
                // Handle the exception if needed
            }
        },
        comparator: dateComparator1,

    }
    var productionCol = {
        headerName: "Production Date",
        field: "production_date",
        width: 150,
        filter: true,
        resizable: true,
        valueGetter: function (params) {
            try {
                if (params.data.production_date != "" && params.data.production_date != undefined) {
                    return getHyphenDDMMYYYY(params.data.production_date);
                } else {
                    return "Not Available";
                }
            } catch (e) {
                // Handle the exception if needed
            }
        },
    }
    var submitCol = {
        headerName: "",
        field: "",
        width: 80,
        filter: true, resizable: true,
        // cellRenderer: 'SubmitBtn'
        cellRendererFramework: SubmitBtn

    }
    var revisedProdCol = {
        headerName: "Revised Production Date",
        field: "revised_production_date",
        width: 170,
        filter: true,
        resizable: true,
        editable: true,
        //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
        cellEditor: DateEditor,
        valueGetter: function (params) {
            try {
                if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
                    return getHyphenDDMMYYYY(params.data.revised_production_date);
                } else {
                    return "Not Available";
                }
            } catch (e) {
                // Handle the exception if needed
            }
        },
        comparator: dateComparator1,
    }
    var revisedCol = {
        headerName: "Revised Production Date",
        field: "revised_production_date",
        width: 170,
        filter: true,
        resizable: true,
        valueGetter: function (params) {
            try {
                if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
                    return getHyphenDDMMYYYY(params.data.revised_production_date);
                } else {
                    return "Not Available";
                }
            } catch (e) {
            }
        },
    }
    var submitRevisedCol = {
        headerName: "",
        field: "",
        width: 80,
        filter: true, resizable: true,
        // hide:hideformodified,
        // cellRenderer: 'SubmitBtn'
        cellRendererFramework: SubmitRevisedBtn

    }
    const onFetchActiveTrucks = (oval) => {
        //console.log(oval, "counter");
        var data = originalData
        if (oval == 1) {
            setrowData(data)
            setIsClicked(prev => ({
                ...prev,
                active: false,
                inactive: false,
                total: true
            }))
        }
        else if (oval == 2) {
            setrowData(activeTrucksCount)
            setIsClicked(prev => ({
                ...prev,
                active: true,
                inactive: false,
                total: false
            }))
        }
        else if (oval == 3) {
            setrowData(inactiverecords)
            setIsClicked(prev => ({
                ...prev,
                active: false,
                inactive: true,
                total: false
            }))
        }
    }
    //console.log(counters, 'reloaddata')
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>

            {props.allcounter == 1 ?
                '' :
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect " style={{ position: "relative", zIndex:9999 }}>
                    <div className="form-group d-flex justify-content-between">
                        <h5 className="heading mt-30p">
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Trucks GPS Integration
                        </h5>

                       {(localStorage.getItem('role')=='tpt')?"": <div className="d-flex" style={{ position: "absolute", top: "20px", right: "-10px", zIndex:99999 }}>
                            <Select
                                closeMenuOnSelect={true}

                                name="filterModeType"
                                className="border-radius-0"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        fontSize: "0.8rem",
                                        color: "#6e707e",
                                        borderRadius: "11px",
                                        height: "48px",
                                        width: "260px",
                                        textAlign: "center",
                                        marginRight: '25px',
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        zIndex:999999
                                    })
                                }}
                                onChange={handleFilterOptions}
                                options={transList}
                                // value={selectedval.dropdownval}
                                required
                            />

                        </div>}
                    </div>
                </div>}
            <div className={props.allcounter == 1 ? '' : "card m-10p card-container"} style={props.allcounter === 1 ? {} : { padding: '25px', margin: '15px', position: "relative", zIndex:1 }}>
                <div style={{ position: "absolute", right: "10px", top: "10px" }}>
                    <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                        Back
                    </a>
                </div>

                <div className="row bghighliter mt-30p" style={{ height: "20%" }}>

                    <div className="plantCounterdiv4 cursorPointer m-3" style={{ background: isClicked.total ? 'rgb(64,81,120)' : '' }} onClick={() => onFetchActiveTrucks('1')}>
                        <div className="col-12 txt-center" style={{ padding: '3px', color: isClicked.total ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Total Trucks With GPS Installed
                            </h5>
                            <div>{gpsInstalledCount.length}</div>
                        </div>

                    </div>
                    <div className="plantCounterdiv4 cursorPointer m-3" style={{ background: isClicked.active ? 'rgb(64,81,120)' : '' }} onClick={() => onFetchActiveTrucks('2')}>
                        <div className="col-12 txt-center" style={{ padding: '3px', color: isClicked.active ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Total Active Trucks
                            </h5>
                            <div>{activeTrucksCount.length}</div>
                        </div>

                    </div>
                    <div className="plantCounterdiv4 cursorPointer m-3" style={{ background: isClicked.inactive ? 'rgb(64,81,120)' : '' }} onClick={() => onFetchActiveTrucks('3')}>
                        <div className="col-12 txt-center" style={{ padding: '3px', color: isClicked.inactive ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Total Inactive Trucks
                            </h5>
                            <div>{inactiverecords.length}</div>
                        </div>
                    </div>
                </div>
                <div id="idgrid" style={{ width: "100%", height: "480px" }} className="ag-theme-balham">
                    <AgGridReact
                        columnDefs={columnwithDefs}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            editable: false,
                            resizable: true,
                            menuTabs: ['filterMenuTab']
                        }}
                        // paddingTop={10}
                        // rowHeight={70}
                        // headerHeight={70}
                        rowData={rowData}
                        suppressRowClickSelection={true}
                        frameworkComponents={{}}
                        enableCellChangeFlash={true}
                        suppressCellFlash={true}
                        enableRangeSelection={true}
                        paginationPageSize={25}
                        rowSelection={'multiple'}
                        masterDetail={true}
                        pagination={true}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: "columns",
                                    labelDefault: "Columns",
                                    labelKey: "columns",
                                    iconKey: "columns",
                                    toolPanel: "agColumnsToolPanel"
                                },
                                {
                                    id: "filters",
                                    labelDefault: "Filters",
                                    labelKey: "filters",
                                    iconKey: "filter",
                                    toolPanel: "agFiltersToolPanel"
                                }
                            ]
                        }}
                        statusBar={{
                            statusPanels: [
                                { statusPanel: "agFilteredRowCountComponent" },
                                { statusPanel: "agSelectedRowCountComponent" },
                                { statusPanel: "agAggregationComponent" }
                            ]
                        }}
                        gridOptions={{
                            icons: {
                                // You can customize other icons as well
                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                last: `<img src="${customLastIcon}" alt="Last" />`,
                            },
                        }}
                        ref={gridRef}
                        context={{ onClickRevisedProductionData }}
                    />

                </div>
            </div>
            <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/plant_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Work Order No: It Should be in Numbers<br />Production Date: It Should be in DD-MM-YYYY format</p>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}

export default TruckGPS