import React from 'react'
import EditGeofence from './editgeofence';

const EditClick = (props) => {
    const {navigate, rowData} = props;
    const handleEditClick = (e) => {
        e.preventDefault();
        // Assuming you want to pass some data from the row to the EditGeoFence component
        const modifiedName = rowData.geofence_name;
        const editLink = `/mlleditGeofence/${modifiedName}`;
        navigate(editLink);
        // <EditGeofence rowData={rowData} />
      };
    



  return (
    <div>
        <a href="#" className="custom-btn label label-success" onClick={handleEditClick}><i className="icofont icofont-edit"></i> Edit</a>;
        
    </div>
  )
}


export default EditClick;