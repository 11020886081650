
import React from 'react';
 
const ViewPODoc = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.onClickUpdateItem(props);
    };
// console.log("props ", props)
    return (
        <div>
            <button type="button" 
            onClick={handleClick}
                target="_blank"
              className={"btn btn-warning nopadding font12"} 
              title="Update">
                  <i className="icofont icofont-eye f16"></i>&nbsp; Update
            </button>
          
        </div>
    );
};

export default ViewPODoc;
