import React, { useState, forwardRef, useImperativeHandle } from 'react';

const CustomCellEditorforfloat = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    }
  }));

  const handleChange = e => {
    let inputValue = e.target.value;
    // Remove any non-digit characters and dot
    inputValue = inputValue.replace(/[^\d.]/g, '');
    // Limit the input to exactly 10 digits or less
    if (inputValue.length <= 6) {
      setValue(inputValue);
    }
  };
  
  
  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
    //   placeholder="Enter 10-digit mobile number"
    />
  );
});

export default CustomCellEditorforfloat;
