import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "./constants";
import {getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenDDMMMYYYY} from "../utils";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ViewPODoc from "./viewpodocument";
import ViewPOItems from "./viewitems";
import ProduceItems from "./vendorproducesbtn";
import UpdateItemAction from "./updateitem";
import Counters from './counters';
import VendorCounters from './vendorcounters';
import ItemAdd from "./produceitemsadd";
import ChecklistItems from "./checklistitems";
import ViewTotalBookingItems from "./totalbookingitems";
import UpdateRowItem from "./updateRowItem";

export default class Bookings extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true,
                menuTabs: ['filterMenuTab']
			},
            frameworkComponents:{
                ViewPODoc:ViewPODoc,
                ViewPOItems:ViewPOItems,
                ProduceItems:ProduceItems,
                ViewTotalBookingItems:ViewTotalBookingItems,
                UpdateRowItem:UpdateRowItem
                // ChecklistItems:ChecklistItems
            },
            components: { datePicker: getDatePicker() },
			detailCellRendererParams : {},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            produceRowData:[],
            actiontype:"",
            rowSelection:"multiple",
            oRowData:[],
            activebookings:[],
            completedbookings:[],
            pendingvendors:[],
            sendtoqa:[],
            invconf:[],
            overlay:"show-n",
            actBtn:"btn-active",
            vendBtn:"btn-default",
            qaBtn:"btn-default",
            invBtn:"btn-default",
            compBtn:"btn-default",
            totalvolume:0,
            pendingwithvendorvolume:0,
            qavolume:0,
            completedvolume:0,
            producedvolume:0,
            hilapprovedvolume:0,
            hilrejectvolume:0,
            remainingvolume:0,
            totalInvbatchcount:0,
            producesitems:"",
            poNumber:"",
            poVendorCode:"",
            poVendorName:"",
            poProduceQty:0,
            poTotalQty:0,
            poRemainingQty:0,
            rownode:[],
            poitemDesc:[],
            checklistslider:"",
            uploadinvslider:"",
            checkRownode:[],
            checklistFile:"",
            approved_qty:0,
            batchRowData:[],
            updateStockslider:"",
            open_stock:"",
            orginalTotalBookings:[],
            totalapprvecount:0,
            allstock:[],
            openingStock:0,
            pinnedBottomRowData:[],
            checklist_remarks:'',
            
        }
        this.onClickViewItems = this.onClickViewItems.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
        this.onClickShowProductItems = this.onClickShowProductItems.bind(this);
        this.onClickSaveProduceItems = this.onClickSaveProduceItems.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.onSubmitChecklist = this.onSubmitChecklist.bind(this);
        this.onClickInvoiceItem = this.onClickInvoiceItem.bind(this);
        this.onSubmitInvDoc = this.onSubmitInvDoc.bind(this);
        this.onLoadBookingData = this.onLoadBookingData.bind(this);
        this.onClickTotalBookingViewItems = this.onClickTotalBookingViewItems.bind(this);
        this.onClickUpdateRowItem = this.onClickUpdateRowItem.bind(this);
    }
	componentDidMount(){
        if(localStorage.getItem("role") === "vendor")
        {
            this.setState({
                actBtn:"btn-default",
                vendBtn:"btn-active",
            })
        }
        redirectURL.post("/booking/openingstock")
        .then((response) => {
            if(response.data.status === "success")
            {
                this.setState({
                    allstock:response.data.records
                })
            }
            this.onLoadBookingData();
        })
       
	}
    
    onLoadBookingData(){
        var params = {}
        if(localStorage.getItem("user_code") !== '' && localStorage.getItem("user_code") !== undefined)
        {
            if(localStorage.getItem("role") === 'vendor')
            {
                params.vendor_code = localStorage.getItem("user_code");
            }
        }
        redirectURL.post("/booking/list",params)
       .then((response) => {
            if(response.data.status === "success")
            {
                var records = response.data.records;
                var batches = response.data.batches;
                // console.log("batches ", batches)
                var activebookings = [];
                var completedbookings  = [];
                var pendingvendors = [];
                var sendtoqa = [];
                var invconf = [];
                var totalvolume= 0;
                var pendingwithvendorvolume= 0;
                var vendorvolumependin = 0;
                var qavolume= 0;
                var completedvolume= 0;
                var producedvolume= 0;
                var hilapprovedvolume= 0;
                var hilrejectvolume= 0;
                var remainingvolume= 0;
                var batchwisearr = [];
                var invoicebatches = [];
                var totalapprvecount = 0;
                
                var totalbatchcount=0;
                var totalInvbatchcount=0;
                if(records.length > 0)
                {
                    records.map((item) => {
                        var totalitemqty = 0;
                        var produced_qty = 0;
                        var qa_approved_qty = 0;
                        var qa_bal_qty = 0;
                        // if(item.po_status === 0)
                        // {
                        //     item.po_status_txt = "Created";
                        //     activebookings.push(item);
                        // }
                        if(item.poitems !== "" && item.poitems !== undefined)
                        {
                            var poitems = item.poitemslist;
                            if(poitems.length > 0)
                            {
                                poitems.map((im) => {
                                    if(localStorage.getItem("user_code") === im.vendor_code)
                                    {
                                        totalvolume = parseInt(totalvolume)+parseInt(im.item_qty);
                                    }
                                    else{
                                        totalvolume = parseInt(totalvolume)+parseInt(im.item_qty);
                                        pendingwithvendorvolume = parseInt(pendingwithvendorvolume)+parseInt(im.item_qty);
                                    }
                                    totalitemqty = parseInt(totalitemqty)+parseInt(im.item_qty);
                                    var vendprdqty = 0;
                                    var vendaprvqty = 0;
                                    var vendbalqty = 0;
                                    if(item.vendorproduces.length > 0)
                                    {
                                        item.vendorproduces.map((f) => {
                                            if(f.item_desc === im.item_desc)
                                            {
                                                if(f.item_produce_qty !== undefined)
                                                {
                                                    vendprdqty = parseInt(vendprdqty)+parseInt(f.item_produce_qty);
                                                }
                                                else{
                                                    vendprdqty = parseInt(vendprdqty)+0;
                                                }
                                                
                                                if(f.approved_qty !== undefined)
                                                {
                                                    vendaprvqty = parseInt(vendaprvqty)+parseInt(f.approved_qty);
                                                }
                                                else{
                                                    vendaprvqty = parseInt(vendaprvqty)+0;
                                                }
                                                
                                            }
                                        })
                                    }
                                    im.vendor_produce_qty = vendprdqty;
                                    im.vendor_approve_qty = vendaprvqty;
                                    im.vendor_balance_qty = parseInt(im.item_qty) - parseInt(vendprdqty) - parseInt(vendaprvqty);
                                })
                            }
                        }
                        
                        if(item.vendorproduces !== "" && item.vendorproduces !== undefined)
                        {
                            var vendorproduces = item.vendorproduces;
                            if(vendorproduces.length > 0)
                            {
                                vendorproduces.map((im) => {
                                    if(im.item_produce_qty !== "" && im.item_produce_qty !== undefined)
                                    {
                                        produced_qty = parseInt(produced_qty)+parseInt(im.item_produce_qty);
                                    }
                                    if(im.approved_qty !== "" && im.approved_qty !== undefined)
                                    {
                                        qa_approved_qty = parseInt(qa_approved_qty)+parseInt(im.approved_qty);
                                    }
                                    if(im.balance_qty !== "" && im.balance_qty !== undefined)
                                    {
                                        // console.log("im.balance_qty ", im.balance_qty)
                                        qa_bal_qty = parseInt(qa_bal_qty)+parseInt(im.balance_qty);
                                    }
                                    
                                });
                               
                                
                            }
                            
                        }
                        // console.log("produced_qty ", produced_qty)
                        // console.log("qa_approved_qty ", qa_approved_qty)
                        // console.log("qa_bal_qty ", qa_bal_qty)
                        item["totalvolume"] = totalitemqty;
                        item["produced_qty"] = produced_qty;
                        item["qa_approved_qty"] = qa_approved_qty;
                        item["qa_bal_qty"] = totalitemqty-produced_qty-qa_approved_qty;
                        
                        if(item.po_status === 0)
                        {
                            item.po_status_txt = "Pending With Vendor";
                            pendingvendors.push(item);
                        }
                        
                        // if(item.po_status === 1)
                        // {
                        //     item.po_status_txt = "Send To QA";
                            
                        // }
                        // if(item.po_status === 2)
                        // {
                        //     item.po_status_txt = "Completed";
                        //     completedbookings.push(item);
                        // }

                    })
                }
                var batcharr = [];
                if(batches.length >0)
                {
                    batches.map((item) => {
                        // console.log("batchitem ", item.item_desc)
                        var poitems = item.poitemslist;
                        if(poitems.length > 0)
                        {
                            var currentitems = poitems.map((f) => {
                                if(f.item_desc === item.item_desc)
                                {
                                    if(item.item_produce_qty !== undefined)
                                    {
                                        item.vendor_produce_qty = parseInt(item.item_produce_qty);
                                    }
                                    else{
                                        item.vendor_produce_qty = 0;
                                    }
                                    if(item.approved_qty !== undefined)
                                    {
                                        item.approved_qty = parseInt(item.approved_qty);
                                    }
                                    else{
                                        item.approved_qty = 0;
                                    }
                                    item.balance_qty = parseInt(item.vendor_produce_qty)-parseInt(item.approved_qty)
                                }
                             });

                                // f.vendor_produce_qty = f.;
                                // console.log("poitems ", currentitems)
                                
                                // item.vendor_produce_qty = currentitems.
                            
                        }
                        batcharr.push(item);
                        // console.log("item ", item)
                        if(item.item_status === 1)
                        {
                            sendtoqa.push(item);
                            if(item.item_produce_qty !== "" && item.item_produce_qty !== undefined)
                            {
                                qavolume = parseInt(qavolume)+parseInt(item.item_produce_qty)
                            }
                            
                        }
                        
                        if(item.item_status === 2)
                        {
                            invconf.push(item);
                            if(item.invoice_document === "" || item.invoice_document === undefined)
                            {
                                totalapprvecount = parseInt(totalapprvecount)+parseInt(item.approved_qty)
                            }
                            else{
                                totalInvbatchcount = parseInt(totalInvbatchcount)+parseInt(item.approved_qty)
                            }
                           
                        }
                        if(item.item_status === 3)
                        {
                            // console.log(" complete item ", item)
                            completedbookings.push(item);
                            completedvolume = parseInt(completedvolume)+parseInt(item.approved_qty)
                        }
                        if(item.balance_qty !== "" && item.balance_qty !== undefined)
                        {
                            remainingvolume = parseInt(remainingvolume)+parseInt(item.balance_qty);
                        }
                        
                    })
                }
                
                pendingwithvendorvolume = parseInt(pendingwithvendorvolume)-parseInt(qavolume)-parseInt(totalInvbatchcount)-parseInt(totalapprvecount)-parseInt(completedvolume);
                vendorvolumependin = parseInt(totalvolume)-parseInt(qavolume)-parseInt(totalInvbatchcount)-parseInt(totalapprvecount)-parseInt(completedvolume);
                // console.log("batcharr ", batcharr)
                if(localStorage.getItem("role") === "vendor")
                {
                    this.setState({
                        oRowData:records,
                        batchRowData:batcharr,
                        rowData:pendingvendors,
                        activebookings:activebookings,
                        pendingvendors:pendingvendors,
                        sendtoqa:sendtoqa,
                        invconf:invconf,
                        completedbookings:completedbookings,
                        totalvolume:totalvolume,
                        pendingwithvendorvolume:vendorvolumependin,
                        qavolume:(qavolume > 0)?qavolume:0,
                        completedvolume:completedvolume,
                        producedvolume:producedvolume,
                        hilapprovedvolume:hilapprovedvolume,
                        hilrejectvolume:hilrejectvolume,
                        remainingvolume:remainingvolume,
                        totalInvbatchcount:totalInvbatchcount,
                        totalapprvecount:totalapprvecount
                    })
                }
                else{
                    var totBoks = [];
                    var allTotalStocks = 0;
                    var fopenstock = 0;
                    var ftotal = 0;
                    var fproduce = 0;
                    var fapprove = 0;
                    var fbal = 0;
                    var vendorsdata = groupBy(records, rdata => (rdata.vendor_code));
                    // console.log("vendorsdata ", vendorsdata)
                    try {
                        var footerRow = {};
                        vendorsdata.forEach((values,key) => {
                            
                            var totalbatch=[];
                            var vendorstock = this.state.allstock;
                            if(vendorstock.length > 0)
                            {
                                var vStock = vendorstock.filter((d)=> d.vendor_code == key);
                                // console.log("vStock ", vStock)
                            }
                            if(vStock.length > 0)
                            {
                                var opnStock = vStock[0].open_stock;
                            }
                            else{
                                var opnStock = 0;
                            }
                            var rwv = {}
                            var poitemsqty = 0;
                            var poprodqty = 0;
                            var poaprvqty = 0;
                            rwv["vendor_code"] = key;
                            if(values.length > 0)
                            {
                                rwv["vendor_name"] = values[0].vendor_name;
                                values.map((it) => {
                                    if(it.poitemslist != "" && it.poitemslist != undefined)
                                    {
                                        
                                        var venditems = it.poitemslist;
                                        var venditemsarr = [];
                                        if(venditems.length > 0)
                                        {
                                            venditems.map((m) => {
                                                poitemsqty = parseInt(poitemsqty)+parseInt(m.item_qty);
                                                m.item_no = parseInt(m.item_no);
                                                venditemsarr.push(m);
                                            })
                                            // console.log("venditemsarr ", venditemsarr)
                                        
                                            venditemsarr.sort(GetSortAscOrder("item_no"));
                                        }
                                       
                                        rwv["poitemslist"] = venditemsarr;
                                    }
                                    if(it.vendorproduces != "" && it.vendorproduces != undefined)
                                    {
                                        var vendorproducest = it.vendorproduces;
                                        if(vendorproducest.length > 0)
                                        {
                                            vendorproducest.map((m1) => {
                                                totalbatch.push(m1);
                                                // console.log('m1.approved_qty ', m1.approved_qty)
                                                if(m1.item_produce_qty !== "" && m1.item_produce_qty !== undefined)
                                                {
                                                    poprodqty = parseInt(poprodqty)+parseInt(m1.item_produce_qty);
                                                }
                                                if(m1.approved_qty !== undefined && m1.approved_qty !== "")
                                                {
                                                    poaprvqty = parseInt(poaprvqty)+parseInt(m1.approved_qty);
                                                }
                                                
                                            })
                                        }
                                    }
                                    
                                })
                            }
                            // console.log("poaprvqty ", poprodqty)
                            rwv["totalQty"] = poitemsqty;
                            rwv["producedQty"] = poprodqty;
                            rwv["approvedQty"] = poaprvqty;
                            rwv["remainingQty"] = parseInt(poitemsqty)-parseInt(poprodqty)-parseInt(poaprvqty);
                            rwv["total_stock"] = parseInt(opnStock)+(parseInt(poprodqty)-parseInt(poaprvqty));
                            allTotalStocks=parseInt(allTotalStocks)+(parseInt(opnStock)+(parseInt(poprodqty)-parseInt(poaprvqty)));
                            rwv["batches"] = totalbatch;
                            rwv["open_stock"] = opnStock;
                            totBoks.push(rwv);
                            fopenstock = parseInt(fopenstock)+parseInt(opnStock);
                            ftotal = parseInt(ftotal)+parseInt(poitemsqty);
                            fproduce = parseInt(fproduce)+parseInt(poprodqty);
                            fapprove = parseInt(fapprove)+parseInt(poaprvqty);
                            fbal = parseInt(fbal)+(parseInt(poitemsqty)-parseInt(poaprvqty));
                        })
                    } catch (error) {
                        
                    }
                    footerRow["vendor_code"]="";
                    footerRow["vendor_name"]="Total";
                    footerRow["open_stock"]=fopenstock;
                    footerRow["totalQty"]=ftotal;
                    footerRow["producedQty"]=fproduce;
                    footerRow["approvedQty"]=fapprove;
                    footerRow["remainingQty"]=fbal;
                    footerRow["viewitems"]="";
                    totBoks.push(footerRow);
                    // console.log("totBoks ", totBoks)
                    this.setState({
                        oRowData:records,
                        batchRowData:batcharr,
                        rowData:totBoks,
                        orginalTotalBookings:totBoks,
                        activebookings:activebookings,
                        pendingvendors:pendingvendors,
                        sendtoqa:sendtoqa,
                        invconf:invconf,
                        completedbookings:completedbookings,
                        totalvolume:totalvolume,
                        pendingwithvendorvolume:pendingwithvendorvolume,
                        qavolume:(qavolume > 0)?qavolume:0,
                        completedvolume:completedvolume,
                        producedvolume:producedvolume,
                        hilapprovedvolume:hilapprovedvolume,
                        hilrejectvolume:hilrejectvolume,
                        remainingvolume:remainingvolume,
                        totalInvbatchcount:totalInvbatchcount,
                        totalapprvecount:totalapprvecount,
                        openingStock:allTotalStocks,
                        // pinnedBottomRowData:[footerRow]
                    })
                }
            }
            else{

            }
       });
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onGridReady1 = (params) => {
		this.gridApiNew = params.api;
		this.gridColumnApiNew = params.columnApi;

    };
    
    hideSlideBlock(){
        this.setState({
            slider:"",
            producesitems:"",
            checklistslider:"",
            uploadinvslider:"",
            updateStockslider:"",
            overlay:"show-n"
        })
    }
    showTabView(oVal){
        if(oVal === 1)
        {
            this.setState({                
                actBtn:"btn-active",
                vendBtn:"btn-default",
                qaBtn:"btn-default",
                compBtn:"btn-default",
                invBtn:"btn-default",
                rowData:this.state.orginalTotalBookings
            })
        }
        if(oVal === 2)
        {
            this.setState({                
                actBtn:"btn-default",
                vendBtn:"btn-active",
                qaBtn:"btn-default",
                compBtn:"btn-default",
                invBtn:"btn-default",
                rowData:this.state.pendingvendors
            })
        }
        if(oVal === 3)
        {
            // console.log("this.state.sendtoqa ", this.state.sendtoqa)
            var sqa = this.state.sendtoqa;
            var batchwise = groupBy(sqa, rdata => (rdata.batch_no));
            var totalbatchcount = 0;
            var totalInvbatchcount = 0;
            var batchwisearr = [];
            var invoicebatches = [];
            var defcol = [];
            try {
                batchwise.forEach((vlues,key) => {
                    if(vlues.length > 0)
                    {
                        var totalInvbatchcount = 0;
                        var approveqty = 0;
                        var balqty = 0;
                       var rw =  {
                            _id:vlues[0]._id,
                            batch_no:vlues[0].batch_no,
                            po_no:vlues[0].po_no,
                            vendor_code:vlues[0].vendor_code,
                            vendor_name:vlues[0].vendor_name,
                            item_produce_qty:vlues[0].item_produce_qty,
                            batch_damage_qty:vlues[0].batch_damage_qty
                        }
                        vlues.map((im) => {
                            if(im.item_status === 1)
                            {
                                if(im.item_produce_qty !== "" && im.item_produce_qty !== undefined)
                                {
                                    totalbatchcount = parseInt(totalbatchcount)+parseInt(im.item_produce_qty);
                                }
                                batchwisearr.push(im);
                            }
                            // console.log("im.status ", im.item_status)
                            // if(im.item_status === 2)
                            // {
                            //     if(im.item_produce_qty !== "" && im.item_produce_qty !== undefined)
                            //     {
                            //         var item_produce_qty = im.item_produce_qty;
                            //     }
                            //     else{
                            //         var item_produce_qty = 0;
                            //     }
                            //     totalInvbatchcount = parseInt(totalInvbatchcount)+(parseInt(item_produce_qty)-parseInt(im.balance_qty));
                            //         approveqty = parseInt(approveqty)+parseInt(im.approved_qty);
                            //      invoicebatches.push(im);
                            // }
                        });
                        rw["batchTotalQty"] = totalbatchcount;
                        rw["batchApprovedTotalQty"] = approveqty;
                        rw["batchBalTotalQty"] = balqty;
                        rw["batches"] = batchwisearr;
                        defcol.push(rw);
                    }
                });
                
            } catch (error) {
                
            }
            // console.log("defcol ", defcol)
           
            this.setState({                
                actBtn:"btn-default",
                vendBtn:"btn-default",
                qaBtn:"btn-active",
                compBtn:"btn-default",
                invBtn:"btn-default",
                sendtoqa:this.state.sendtoqa,
                rowData:defcol
            })
        }
        if(oVal === 4)
        {
            var sqa = this.state.completedbookings;
            // console.log("sqa ", sqa)
            var batchwise = groupBy(sqa, rdata => (rdata.po_no));
            var totalbatchcount = 0;
            var batchwisearr = [];
            var invoicebatches = [];
            var approveqty = 0;
            var balqty = 0;
            var defcol = [];
            try {
                batchwise.forEach((vlues,key) => {
                    if(vlues.length > 0)
                    {
                        var approveqty = 0;
                        var balqty = 0;
                       var rw =  {
                            _id:vlues[0]._id,
                            batch_no:vlues[0].batch_no,
                            po_no:vlues[0].po_no,
                            vendor_code:vlues[0].vendor_code,
                            vendor_name:vlues[0].vendor_name,
                            item_produce_qty:vlues[0].item_produce_qty,
                            batch_damage_qty:vlues[0].batch_damage_qty
                        }
                        vlues.map((im) => {
                            
                            if(im.item_status === 3)
                            {
                                totalbatchcount = parseInt(totalbatchcount)+(parseInt(im.item_produce_qty));
                                approveqty = parseInt(approveqty)+parseInt(im.approved_qty);
                                balqty = parseInt(balqty)+parseInt(im.balance_qty);
                                batchwisearr.push(im);
                            }
                        });
                        rw["batchTotalQty"] = totalbatchcount;
                        rw["batchApprovedTotalQty"] = approveqty;
                        rw["item_produce_qty"] = totalbatchcount;
                        rw["approved_qty"] = approveqty;
                        rw["balance_qty"] = balqty;
                        if(vlues[0].batch_damage_qty !== "" && vlues[0].batch_damage_qty !== undefined)
                        {
                            var damager_qty = vlues[0].batch_damage_qty;
                        }
                        else{
                            var damager_qty = 0;
                        }
                        rw["batchBalTotalQty"] = totalbatchcount-approveqty-damager_qty;
                        rw["batches"] = batchwisearr;
                        defcol.push(rw);
                    }
                });
                
            } catch (error) {
                
            }
            this.setState({                
                actBtn:"btn-default",
                vendBtn:"btn-default",
                qaBtn:"btn-default",
                compBtn:"btn-active",
                invBtn:"btn-default",
                completedbookings:this.state.completedbookings,
                rowData:defcol
            })
        }
        if(oVal === 5)
        {
            var sqa = this.state.invconf;
            var batchwise = groupBy(sqa, rdata => (rdata.batch_no));
           
            var batchwisearr = [];
           
            var defcol = [];
            try {
                batchwise.forEach((vlues,key) => {
                    if(vlues.length > 0)
                    {
                        var totalbatchcount = 0;
                        var totalInvbatchcount = 0;
                        var approveqty = 0;
                        var balqty = 0;
                        var invoicebatches = [];
                        var rw =  {
                            _id:vlues[0]._id,
                            batch_no:vlues[0].batch_no,
                            po_no:vlues[0].po_no,
                            vendor_code:vlues[0].vendor_code,
                            vendor_name:vlues[0].vendor_name,
                            item_produce_qty:vlues[0].item_produce_qty,
                            batch_damage_qty:vlues[0].batch_damage_qty
                        }
                        vlues.map((im) => {
                            // if(im.item_status === 1)
                            // {
                            //     totalbatchcount = parseInt(totalbatchcount)+parseInt(im.item_produce_qty);
                            //     batchwisearr.push(im);
                            // }
                            // console.log("im.status ", im.item_status)
                            if(im.item_status === 2)
                            {
                                if(im.item_produce_qty !== "" && im.item_produce_qty !== undefined)
                                {
                                    totalInvbatchcount = parseInt(totalInvbatchcount)+parseInt(im.item_produce_qty);
                                }
                                approveqty = parseInt(approveqty)+parseInt(im.approved_qty);
                                balqty = parseInt(balqty)+parseInt(im.balance_qty);
                                invoicebatches.push(im);
                            }
                        });
                        rw["batchTotalQty"] = totalInvbatchcount;
                        rw["batchApprovedTotalQty"] = approveqty;
                        rw["batchBalTotalQty"] = balqty;
                        rw["batches"] = invoicebatches;
                        defcol.push(rw);
                    }
                });
                
            } catch (error) {
                
            }

            this.setState({                
                actBtn:"btn-default",
                vendBtn:"btn-default",
                qaBtn:"btn-default",
                compBtn:"btn-default",
                invBtn:"btn-active",
                invconf:this.state.invconf,
                rowData:defcol
            })
        }
    }
    onClickApprove(){

    }
    onClickReject(){

    }
    onClickViewItems(rownode){
        // console.log("rownode ", rownode);
        if(this.state.actBtn === "btn-active")
        {
            var currentBtn = "totalbookings";
        }
        if(this.state.vendBtn === "btn-active")
        {
            var currentBtn = "vendorpending";
        }
        if(this.state.qaBtn === "btn-active")
        {
            var currentBtn = "qa";
        }
        if(this.state.invBtn === "btn-active")
        {
            var currentBtn = "inv";
        }
        if(this.state.compBtn === "btn-active")
        {
            var currentBtn = "completed";
        }
        this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: (currentBtn !== "qa" && currentBtn !== "inv" && currentBtn !== "completed")?
                    [
                    // {
                    //     headerName:Constants.GRID_TRANSPORTER_CODE,
                    //     field:"transporter_code",
                    //     width:160,
                    // },           
                    // {
                    //     headerName:Constants.GRID_TRANSPORTER_NAME,
                    //     field:"transporter_name",
                    //     width:160,
                    // },  
                    {
                        headerName:Constants.GRID_PO_ITEM_NO,
                        field:"item_no",
                        editable:false,
                        width:100
                    },
                    {
                        headerName:Constants.GRID_PO_ITEM_DESC,
                        field:"item_desc",
                        editable:false
                    },
                    {
                        headerName:Constants.GRID_PO_ITEM_QTY,
                        field:"item_qty",
                        editable:false,
                        width:100
                    },
                    {
                        headerName:Constants.GRID_PO_ITEM_UM,
                        field:"item_um",
                        editable:false,
                        width:100
                    },
                    {
                        headerName:Constants.GRID_PO_ITEM_HSN,
                        field:"item_hsn",
                        editable:false,
                        width:100
                    },
                    {
                        headerName:Constants.GRID_PO_ITEM_PRICE,
                        field:"item_price",
                        editable:false,
                        width:100
                    },
                    {
                        headerName:Constants.GRID_PO_PRODUCE_QTY,
                        field:"vendor_produce_qty",
                        editable:false
                    },
                    {
                        headerName:Constants.GRID_PO_APPROVED_QTY,
                        field:"vendor_approve_qty",
                        editable:false
                    },
                    {
                        headerName:Constants.GRID_PO_BAL_QTY,
                        field:"vendor_balance_qty",
                        editable:false
                    },
                  
                    ]:[
                        {
                            headerName:Constants.GRID_BATCH_NO,
                            field:"batch_no",
                            width:140,
                            resizable:true
                        },  
                        {
                            headerName:Constants.GRID_PO_NO,
                            field:"po_no",
                            width:120,
                            resizable:true
                        },           
                        {
                            headerName:Constants.GRID_VENDOR_CODE,
                            field:"vendor_code",
                            width:120,
                            resizable:true
                        },  
                        {
                            headerName:Constants.GRID_VENDOR_NAME,
                            field:"vendor_name",
                            editable:false,
                            width:200,
                            resizable:true
                        },
                        {
                            headerName:Constants.GRID_PO_ITEM_DESC,
                            field:"item_desc",
                            editable:false,
                            resizable:true
                        },
                        {
                            headerName:Constants.GRID_PO_PRODUCE_QTY,
                            field:"item_produce_qty",
                            editable:false,
                            width:100,
                            resizable:true,
                           
                        },
                        
                        {
                            headerName:Constants.GRID_PO_APPROVED_QTY,
                            field:"approved_qty",
                            editable:false,
                            width:100,
                            resizable:true,
                           
                        },
                        {
                            headerName:Constants.GRID_PO_BAL_QTY,
                            field:"balance_qty",
                            editable:false,
                            width:100,
                            resizable:true,
                           
                        },
                        {
                            headerName:Constants.GRID_PO_PRODUCE_DATE,
                            field:"item_produce_date",
                            editable:false,
                            width:150,
                            resizable:true,
                            valueGetter:function(params){
                                try {
                                    if(params.data.item_produce_date !== "" && params.data.item_produce_date !== undefined)
                                    {
                                        return getHyphenDDMMMYYYY(params.data.item_produce_date);
                                    }
                                    else{
                                        return "";
                                    }
                                } catch (error) {
                                    
                                }
                            }
                        },
                        {
                            headerName:"",
                            field:"_id",
                            width:300,
                            cellRenderer:ChecklistItems
                        }
                        ],
                        defaultColDef :{
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable:true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable:true,
                            menuTabs: ['filterMenuTab']
                        },
                    context: this,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                components: { datePicker: getDatePicker() },
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    
                    if(currentBtn === "qa" || currentBtn === "inv" || currentBtn === "completed")
                    {
                        // console.log("params ", param.data)
                        var rownodes = param.data.batches;
                        var rowitems = [];
                        if(rownodes.length > 0)
                        {
                            rownodes.map((itm) => {
                                // console.log("itm ", itm)
                                if(itm.batch_no === param.data.batch_no && itm.po_no === param.data.po_no)
                                {
                                    rowitems.push(itm);
                                }
                            })
                        }
                        // if(param.data.batches.length > 0)
                        // {
                        //     console.log("param.data.batches ", param.data)
                        //     console.log("param.data.poitemslist ", param.data)
                        //     var rowitems = [];
                        //     param.data.poitemslist.filter((d) =>{
                        //         if(param.data.batches.length > 0)
                        //         {
                        //             param.data.batches.map((f) => {
                        //                 if(f.item_desc === d.item_desc)
                        //                 {
                        //                     console.log("D ", f)
                        //                     if(f.item_produce_qty !== "" && f.item_produce_qty !== undefined)
                        //                     {
                        //                         var vendor_produce_qty = parseInt(f.item_produce_qty);
                        //                         d.vendor_produce_qty = parseInt(vendor_produce_qty);
                                                
                        //                     }
                        //                     else{
                        //                         var vendor_produce_qty = 0;
                        //                         d.vendor_produce_qty = parseInt(vendor_produce_qty);
                        //                     }
                        //                     if(f.approved_qty !== "" && f.approved_qty !== undefined)
                        //                     {
                        //                         var vendor_approve_qty = parseInt(f.approved_qty);
                        //                         d.vendor_approve_qty = parseInt(vendor_approve_qty);
                                                
                        //                     }
                        //                     else{
                        //                         var vendor_approve_qty = 0;
                        //                         d.vendor_approve_qty = parseInt(vendor_approve_qty);
                        //                     }
                        //                     d.vendor_balance_qty = parseInt(d.item_qty)-parseInt(vendor_produce_qty)-parseInt(vendor_approve_qty);
                        //                     // return d;
                        //                     rowitems.push(d);
                        //                 }
                        //                 // else{                                            
                        //                 //     d.vendor_produce_qty = 0;
                        //                 //     d.vendor_approve_qty = 0;
                        //                 //     d.vendor_balance_qty = parseInt(d.item_qty);
                        //                 //     rowitemsarr.push(d);
                        //                 // }
                        //             })
                                    
                        //         } 
                                
                        //     })
                            
                        // }
                        // else{
                        //     console.log("param.data ", param.data)
                        //     var rowitems = [];
                        //     if(param.data.poitemslist.length > 0)
                        //     {
                        //         param.data.poitemslist.map((itm) => {
                        //             itm.vendor_approve_qty = 0;
                        //             itm.vendor_produce_qty = 0;
                        //             itm.vendor_balance_qty = itm.item_qty;
                        //             rowitems.push(itm);
                        //         })
                        //     }
                        // }
                    }
                    else{
                        var rowitems = param.data.poitemslist;
                        rowitems.sort(GetSortAscOrder("item_no"));
                    }
                        param.successCallback(rowitems);
                        
                    // })
                        
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'viewitems')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    onClickTotalBookingViewItems(rownode){
        // console.log("rownode ", rownode);
        
        this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                        // {
                        //     headerName:Constants.GRID_TRANSPORTER_CODE,
                        //     field:"transporter_code",
                        //     width:160,
                        // },           
                        // {
                        //     headerName:Constants.GRID_TRANSPORTER_NAME,
                        //     field:"transporter_name",
                        //     width:160,
                        // },  
                        {
                            headerName:Constants.GRID_PO_ITEM_NO,
                            field:"item_no",
                            editable:false,
                            width:100
                        },
                        {
                            headerName:Constants.GRID_PO_ITEM_DESC,
                            field:"item_desc",
                            editable:false
                        },
                        {
                            headerName:Constants.GRID_PO_ITEM_QTY,
                            field:"item_qty",
                            editable:false,
                            width:100
                        },
                        {
                            headerName:Constants.GRID_PO_ITEM_UM,
                            field:"item_um",
                            editable:false,
                            width:100
                        },
                        {
                            headerName:Constants.GRID_PO_ITEM_HSN,
                            field:"item_hsn",
                            editable:false,
                            width:100
                        },
                        {
                            headerName:Constants.GRID_PO_ITEM_PRICE,
                            field:"item_price",
                            editable:false,
                            width:100
                        },
                        {
                            headerName:Constants.GRID_PO_PRODUCE_QTY,
                            field:"vendor_produce_qty",
                            editable:false
                        },
                        {
                            headerName:Constants.GRID_PO_APPROVED_QTY,
                            field:"vendor_approve_qty",
                            editable:false
                        },
                        {
                            headerName:Constants.GRID_PO_BAL_QTY,
                            field:"vendor_balance_qty",
                            editable:false
                        },
                        // {
                        //     headerName:"Produce Date",
                        //     field:"vendor_produce_date",
                        //     editable:false,
                        //     cellEditor: 'datePicker',
                        //     // cellRenderer: (data) => {
                        //     //     return data.value.split('/')[1]+'-' +data.value.split('/')[0]+'-'+data.value.split('/')[2];
                        //     // },
                        // },
                        // {
                        //     headerName:"",
                        //     field:"_id",
                        //     cellRenderer:UpdateItemAction,
                        // }
                        
                    ],
                    context: this,
                    defaultColDef :{
                        // flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable:true,
                        initialWidth: 200,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        sortable:true,
                        menuTabs: ['filterMenuTab']
                    },
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                components: { datePicker: getDatePicker() },
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    
                        if(param.data.batches.length > 0)
                        {
                            // console.log("param.data.batches ", param.data)
                            var rowitems = [];
                            param.data.poitemslist.filter((d) =>{
                                if(param.data.batches.length > 0)
                                {
                                    param.data.batches.map((f) => {
                                        if(f.item_desc === d.item_desc)
                                        {
                                            // console.log("D ", f)
                                            if(f.item_produce_qty !== "" && f.item_produce_qty !== undefined)
                                            {
                                                var vendor_produce_qty = parseInt(f.item_produce_qty);
                                                d.vendor_produce_qty = parseInt(vendor_produce_qty);
                                               
                                            }
                                            else{
                                                var vendor_produce_qty = 0;
                                                d.vendor_produce_qty = parseInt(vendor_produce_qty);
                                            }
                                            if(f.approved_qty !== "" && f.approved_qty !== undefined)
                                            {
                                                var vendor_approve_qty = parseInt(f.approved_qty);
                                                d.vendor_approve_qty = parseInt(vendor_approve_qty);
                                               
                                            }
                                            else{
                                                var vendor_approve_qty = 0;
                                                d.vendor_approve_qty = parseInt(vendor_approve_qty);
                                            }
                                            d.vendor_balance_qty = parseInt(d.item_qty)-parseInt(vendor_produce_qty)-parseInt(vendor_approve_qty);
                                            // return d;
                                            rowitems.push(d);
                                        }
                                        // else{                                            
                                        //     d.vendor_produce_qty = 0;
                                        //     d.vendor_approve_qty = 0;
                                        //     d.vendor_balance_qty = parseInt(d.item_qty);
                                        //     rowitemsarr.push(d);
                                        // }
                                    })
                                    
                                } 
                                
                            })
                            // console.log("rowitemsarr ", rowitemsarr)
                            // var rowitemslist = groupBy(rowitemsarr, rdata => (rdata.item_no));
                            // var rowitems=[];
                            // try {
                            //     rowitemslist.forEach((valus,key) => {
                            //         rowitems.push(valus[0]);
                            //     })
                            // } catch (error) {
                                
                            // }
                            // var rowitems = [];
                            // var rowitems= param.data.batches;
                            // if(rowitemsarr.length > 0){
                            //     rowitemsarr.map((itm) => {
                            //         var 
                            //     })
                            // }
                        }
                        else{
                            // console.log("param.data ", param.data)
                            var rowitems = [];
                            if(param.data.poitemslist.length > 0)
                            {
                                param.data.poitemslist.map((itm) => {
                                    itm.vendor_approve_qty = 0;
                                    itm.vendor_produce_qty = 0;
                                    itm.vendor_balance_qty = itm.item_qty;
                                    rowitems.push(itm);
                                })
                            }
                        }
                        param.successCallback(rowitems);
                        
                    // })
                        
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'viewitems')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    onClickUpdateRowItem(rownode)
    {
        // console.log("rownode ", rownode)
        var params = {
            rownode:rownode.data
        }
        redirectURL.post("/booking/updateDamageQty",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        })
    }
    onClickUpdateItem(rownode)
    {
        // console.log("rownode ", rownode)
        this.setState({
            checklistslider:"slide45",
            overlay:"show-m",
            checkRownode:[rownode.data]
        });
    }
    onClickInvoiceItem(rownode){
        // console.log("rownode ", rownode)
        this.setState({
            uploadinvslider:"slide30",
            overlay:"show-m",
            checkRownode:[rownode.data]
        });
    }
    
    changeFileHandler(event){
        this.setState({
            checklistFile:event.target.files[0]
        })
    }
    onClickShowProductItems(rownode)
    {
        // console.log("rownode) ", rownode)
        var poTotalQty = 0 ;
        var poProduceQty = 0 ;
        var poRemainingQty = 0 ;
        var itemDesc = ["Select"];
        if(rownode.data.poitemslist  !== "" && rownode.data.poitemslist !== undefined)
        {
            var poitemslist = rownode.data.poitemslist;
            if(poitemslist.length > 0)
            {
                poitemslist.map((item) => {
                    poTotalQty = parseInt(poTotalQty)+parseInt(item.item_qty);
                    itemDesc.push(item.item_desc)
                })
            }
        }
        poRemainingQty = poTotalQty-poProduceQty;
        this.setState({
            producesitems:"slide75",
            overlay:"show-m",
            rownode:rownode.data,
            poNumber:rownode.data.po_no,
            poVendorCode:rownode.data.vendor_code,
            poVendorName:rownode.data.vendor_name,
            poTotalQty:poTotalQty,
            poRemainingQty:poRemainingQty,
            poProduceQty:poProduceQty,
            poitemDesc:itemDesc
        })
    }
    changeDateTimeValue = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	this.setState({
    		poDate:getHyphenDDMMYYYY(d)
    	});
    }
    addItemRow(){
        var row = [{
            item_produce_qty:"",
            item_produce_date:""
        }]
        this.setState({
            produceRowData:row
        })
    }
    onClickSaveProduceItems(rownodes){
        // console.log("PArente ", rownodes)
        this.setState({
            producesitems:"",
            overlay:"show-n",
        })
        this.onLoadBookingData();
        window.location.reload();
        
    }
    changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    onSubmitChecklist(event){
        event.preventDefault();
        var checklistFile = this.state.checklistFile;
        var approved_qty = this.state.approved_qty;
        var checklistRow = this.state.checkRownode;
        var checklist_remarks = this.state.checklist_remarks;
        var qa_parameters = $(".qa_parameters")
        var qa_parametersarr = [];
        $(".qa_parameters").each(function(){
            var val = $(this).val();
            // console.log("val ", val)
             var vals = val.split("__");
            qa_parametersarr.push({
                qa_status:vals[0],
                qa_characteristic:vals[1],
                qa_units:vals[2],
                qa_specifications:vals[3],
            })
        })

        const formData = new FormData();
        formData.append("checklistFile", checklistFile);
        formData.append("approved_qty", approved_qty);
        formData.append("po_no", checklistRow[0].po_no);
        formData.append("rowid", checklistRow[0]._id);
        formData.append("batch_no", checklistRow[0].batch_no);
        formData.append("item_produce_qty", checklistRow[0].item_produce_qty);
        formData.append("checklist_remarks", checklist_remarks);
        formData.append("qa_parameters", JSON.stringify(qa_parametersarr));
        
        redirectURL.post("/booking/updatechecklist",formData)
        .then((response) => {
            if(response.data.status === "success")
            {
                this.setState({
                    checklistFile:"",
                    approved_qty:0,
                    checklistRow:[],
                    checklistslider:"",
                    overlay:"show-n",
                    checkRownode:[]
                })
                
                document.getElementById("formID").reset();
            }
            
            this.onLoadBookingData();
            window.location.reload();
        })
    }
    onSubmitInvDoc(event){
        event.preventDefault();
        var checklistFile = this.state.checklistFile;
        var checklistRow = this.state.checkRownode;
        const formData = new FormData();
        formData.append("checklistFile", checklistFile);
        formData.append("po_no", checklistRow[0].po_no);
        formData.append("rowid", checklistRow[0]._id);
        formData.append("batch_no", checklistRow[0].batch_no);
        redirectURL.post("/booking/uploadpoinvoice",formData)
        .then((response) => {
            if(response.data.status === "success")
            {
                this.setState({
                    uploadinvslider:"",
                    checklistRow:[],
                    checklistslider:"",
                    overlay:"show-n",
                    checkRownode:[]
                })
                document.getElementById("formUploadID").reset();
            }
            
            this.onLoadBookingData();
            window.location.reload();
        })
    }
    onClickSHowOpenStock(){
        this.setState({
            updateStockslider:"slide30",
            overlay:"show-m"
        })
    }
    onSubmitStock(event){
        event.preventDefault();
        if(localStorage.getItem("user_code") !== "" && localStorage.getItem("user_code") !== undefined)
        {
            var usercode = localStorage.getItem("user_code");
        }
        else{
            var usercode = "";
        }
        var params = {
            vendor_code:usercode,
            open_stock:this.state.open_stock
        }
        redirectURL.post("/booking/saveopenstock",params)
        .then((response) => {
            window.location.reload();
        })
    }
    render(){
		var columnDefs = [
            {
                headerName:Constants.GRID_PO_NO,
                field:"po_no",
                width:100,
                // checkboxSelection:true
            },   
            {
                headerName:Constants.GRID_VENDOR_CODE,
                field:"vendor_code",
                width:100,
            },           
            {
                headerName:Constants.GRID_VENDOR_NAME,
                field:"vendor_name",
                width:160,
            },           
                     
            {
                headerName:Constants.GRID_DELIVERED_DATE,
                field:"delivered_date",
                width:160,
                valueGetter:function(params)
                {
                    try {
                        if(params.data.delivered_date !== "" && params.data.delivered_date !== undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.delivered_date);
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                }
            },           
            {
                headerName:Constants.GRID_DELIVER_ADDRESS,
                field:"shipping_address",
                width:250,
            },           
            {
                headerName:Constants.GRID_PO_TOTAL_QTY,
                field:"totalvolume",
                width:100,
            },          
            {
                headerName:Constants.GRID_PO_PRODUCE_QTY,
                field:"produced_qty",
                width:100,
            },          
            {
                headerName:Constants.GRID_PO_APPROVED_QTY,
                field:"qa_approved_qty",
                width:100,
            },          
            {
                headerName:Constants.GRID_PO_BAL_QTY,
                field:"qa_bal_qty",
                width:100,
            },         
            {
                headerName:"",
                field:"poDoc",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewPODoc"
                    }
                },
            },        
            {
                headerName:"",
                field:"viewitems",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewPOItems"
                    }
                },
            }
        ]
        columnDefs.push(       
            {
                headerName:"",
                field:"produceitems",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ProduceItems"
                    }
                },
        });
        var poitemDesc = this.state.poitemDesc;
        
        if(this.state.compBtn === "btn-active")
        {
            var hideCl = true;
        }
        else{
            
            var hideCl = false;
        }
        var columnDefsQA = [
            {
                headerName:Constants.GRID_BATCH_NO,
                field:"batch_no",
                width:160,
                hide:hideCl
                // checkboxSelection:true
            },  
            {
                headerName:Constants.GRID_PO_NO,
                field:"po_no",
                width:160,
                // checkboxSelection:true
            },   
            // {
            //     headerName:Constants.GRID_PO_TOTAL_QTY,
            //     field:"totalvolume",
            //     width:150,
            // },        
            {
                headerName:Constants.GRID_PO_PRODUCE_QTY,
                field:"batchTotalQty",
                width:150,
            },  
            {
                headerName:Constants.GRID_PO_APPROVED_QTY,
                field:"batchApprovedTotalQty",
                width:150,
            },  
            {
                headerName:Constants.GRID_PO_BAL_QTY,
                field:"batchBalTotalQty",
                width:150,
            },       
            {
                headerName:"",
                field:"viewitems",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewPOItems"
                    }
                },
            }
        ]
        var columnDefsComplete = [
            {
                headerName:Constants.GRID_BATCH_NO,
                field:"batch_no",
                width:160,
                hide:hideCl
                // checkboxSelection:true
            },  
            {
                headerName:Constants.GRID_PO_NO,
                field:"po_no",
                width:160,
                // checkboxSelection:true
            },   
            // {
            //     headerName:Constants.GRID_PO_TOTAL_QTY,
            //     field:"totalvolume",
            //     width:150,
            // },        
            {
                headerName:Constants.GRID_PO_PRODUCE_QTY,
                field:"batchTotalQty",
                width:150,
            },  
            {
                headerName:Constants.GRID_PO_APPROVED_QTY,
                field:"batchApprovedTotalQty",
                width:150,
            },  
            {
                headerName:Constants.GRID_PO_BAL_QTY,
                field:"batchBalTotalQty",
                width:150,
            },    
            {
                headerName:Constants.GRID_PO_BATCH_QTY,
                field:"batch_damage_qty",
                width:150,
                editable:true
            },         
            {
                headerName:"",
                field:"updateitem",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"UpdateRowItem"
                    }
                },
            },    
            {
                headerName:"",
                field:"viewitems",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewPOItems"
                    }
                },
            }
        ]
        
        var columnsTotBookings = [
            
            {
                headerName:Constants.GRID_VENDOR_CODE,
                field:"vendor_code",
                width:150,
            },  {
                headerName:Constants.GRID_VENDOR_NAME,
                field:"vendor_name",
                width:250,
            },    
            
            {
                headerName:Constants.GRID_PO_OPEN_STOCK,
                field:"open_stock",
                width:150,
            }, 
            {
                headerName:Constants.GRID_PO_TOTAL_QTY,
                field:"totalQty",
                width:150,
            },    
            
            {
                headerName:Constants.GRID_PO_PRODUCE_QTY,
                field:"producedQty",
                width:150,
            },    
            
            {
                headerName:Constants.GRID_PO_APPROVED_QTY,
                field:"approvedQty",
                width:150,
            },    
            {
                headerName:Constants.GRID_PO_BAL_QTY,
                field:"remainingQty",
                width:150,
            },    
            {
                headerName:"",
                field:"viewitems",
                width:120,
                cellRendererSelector:function(params){
                    if(params.data.viewitems === "Total" || params.data.viewitems === "")
                    {
                        return "";
                    }
                    else{
                        return {
                            component:"ViewTotalBookingItems"
                        }
                    }
                },
            }
        ]

        if(this.state.qaBtn === "btn-active" || this.state.invBtn === "btn-active")
        {
            var gridColumnDefs=columnDefsQA;
        }
        else if(this.state.actBtn === "btn-active")
        {
            var gridColumnDefs=columnsTotBookings;
        }
        else if(this.state.compBtn === "btn-active")
        {
            var gridColumnDefs=columnDefsComplete;
        }
        else{
            var gridColumnDefs=columnDefs;
        }
        return (
            <div className="container-fluid">
                 <div className="row mb-4">
                    <div className="col-xl-12 col-md-12 ">
                    {(localStorage.getItem("role") === "admin")?
                        <Counters
                            totalvolume={this.state.totalvolume}
                            pendingvendors={this.state.pendingwithvendorvolume}
                            sendtoqa={this.state.qavolume}
                            totalapprvecount={this.state.totalapprvecount}
                            totalInvbatchcount={this.state.totalInvbatchcount}
                            completedbookings={this.state.completedvolume}
                            openingStock={this.state.openingStock}

                        />
                        :""}

                        {(localStorage.getItem("role") === "vendor")?
                        <VendorCounters
                            totalvolume={this.state.totalvolume}
                            producedvolume={this.state.pendingwithvendorvolume}
                            qavolume={this.state.qavolume}
                            totalapprvecount={this.state.totalapprvecount}
                            hilapprovedvolume={this.state.totalInvbatchcount}
                            hilrejectvolume={this.state.completedvolume}
                            remainingvolume={this.state.remainingvolume}
                            openingStock={this.state.openingStock}
                        />
                        :""}
                    </div>
                </div>

				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">{Constants.BOOKING_PAGE_TITLE}
                    {(localStorage.getItem("role") === "admin")?
                        <a href={"/create-booking"} className="btn btn-warning float-right">Create Booking Request</a>
                        :
                        // <button type="button" className="btn btn-warning float-right" onClick={this.onClickSHowOpenStock.bind(this)}>Add Open Stock</button>
                        ""
                        }
                    </h1>
				</div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 ">
                        <ul className={"navtabs "}>
                        {(localStorage.getItem("role") === "admin")?
                            <li>
                                <button onClick={this.showTabView.bind(this,1)} type="button" className={"btn "+(this.state.actBtn)}>Total Bookings</button>
                            </li>
                            :""}
                            <li>
                                <button onClick={this.showTabView.bind(this,2)} type="button" className={"btn "+(this.state.vendBtn)}>{Constants.GRID_COUNTER_PENDING_VENDOR}</button>
                            </li>
                            {(localStorage.getItem("role") !== "vendor")?
                            <li>
                                <button onClick={this.showTabView.bind(this,3)} type="button" className={"btn "+(this.state.qaBtn)}>{Constants.GRID_COUNTER_PENDING_QA}</button>
                            </li>
                            :""}
                            {(localStorage.getItem("role") === "vendor" || localStorage.getItem("role") === "admin")?
                            <li>
                                <button onClick={this.showTabView.bind(this,5)} type="button" className={"btn "+(this.state.invBtn)}>{Constants.GRID_COUNTER_INV_QTY}</button>
                            </li>
                            :""}
                            <li>
                                <button onClick={this.showTabView.bind(this,4)} type="button" className={"btn "+(this.state.compBtn)}>{Constants.GRID_COUNTER_COMPLETED}</button>
                            </li>
                           
                        </ul>
                    </div>
                </div>
                <div className={this.state.liableshow}>
                    <div className="row">

                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card shadow h-100 py-2">
                                <div className="card-body">
                                    <div style={{width:"100%",height:"50vh"}} className="ag-theme-balham">
                                    <AgGridReact 
                                        rowData={this.state.rowData} 
                                        columnDefs={gridColumnDefs}
                                        defaultColDef ={this.state.defaultColDef }
                                        enableRangeSelection={true}
                                        allowContextMenuWithControlKey={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        components={this.state.components}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        rowSelection={this.state.rowSelection}
                                        pinnedBottomRowData={this.state.pinnedBottomRowData}
                                    />
                                    </div> 
                                    {/* <div className="row mt-2">
                                        <div className="col-md-12" style={{textAlign:"right"}}>
                                            
                                                <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                           
                                                <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                           
                                        </div> 
                                    
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
               
                <div className={"sliderBlock2 "+(this.state.producesitems)}>
                    <h5 className="crd-bg p-10p">Items Produce</h5>
                    <div className="">   
                        <div className="slide-body">
                            <div className="row p-20p">                     
                                <div className="col-sm-2">
                                    PO Number: {this.state.poNumber}
                                </div>      
                                <div className="col-sm-4">
                                    Vendor: {this.state.poVendorName +" - "+this.state.poVendorCode}
                                </div>
                                <div className="col-sm-6">
                                    <span style={{marginLeft:"20px"}}>
                                        Total Quantity: {this.state.poTotalQty} 
                                    </span>
                                    {/* <span style={{marginLeft:"20px"}}>
                                        Produced: {this.state.poProduceQty}
                                    </span>
                                    <span style={{marginLeft:"20px"}}>
                                    Remaining: {this.state.poRemainingQty}
                                    </span> */}
                                </div>
                            </div>
                            <div className="row p-20p" style={{marginTop:"20px"}} id="produceItemData">   
                                {/* <button type="button" className="btn btn-primary" onClick={this.addItemRow.bind(this)}>Add Item</button>                   */}
                                {/* <div className="col-sm-3">
                                    <label>Item Description</label>
                                    <select className="prodItems" data-id="1">
                                        <option value="">Select Item</option>
                                    </select>
                                </div>      
                                <div className="col-sm-3">
                                    <label>Quantity</label>
                                    <input type="text" className="form-control" name="p_qty_1" id="p_qty_1" placeholder="Enter Quantity" />
                                </div>      
                                <div className="col-sm-3">
                                    <label>Produce Date</label>
                                    <Datetime 
                                            inputProps={{ placeholder: 'Delivery Date', name: 'startDate', autoComplete:'off', required:true }} 
                                            dateFormat="YYYY-MM-DD" 
                                            timeFormat={false} 
                                            name="startDate" 
                                            onChange={this.changeDateTimeValue.bind(this)}
                                        />
                                </div>  */}


                                <ItemAdd
                                    poitemDesc={this.state.poitemDesc}
                                    poNumber={this.state.poNumber}
                                    poVendorCode={this.state.poVendorCode}
                                    poVendorName={this.state.poVendorName}
                                    context={this}
                                    rownode={this.state.rownode}
                                />

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"sliderBlock2 "+(this.state.checklistslider)}>
                    <h5 className="crd-bg p-10p">Checklist</h5>
                    <div className="">   
                        <form className="theme" id="formID" method="POST" enctype="multipart/form-data" onSubmit={this.onSubmitChecklist}>
                            <div className="slide-body">
                                <div className="row p-20p">                     
                                    <div className="col-sm-6 mt-4">
                                        <label>Produced Quantity</label>
                                        <span className="form-control" >{this.state.checkRownode.length > 0?this.state.checkRownode[0].item_produce_qty:0}</span>
                                    </div>                     
                                    <div className="col-sm-6 mt-4">
                                        <label>Approved Quantity</label>
                                        <input type="text" className="form-control" name="approved_qty" onChange={this.changeHandler.bind(this)} value={this.state.approved_qty} />
                                    </div>   
                                                      
                                    <div className="col-sm-12 mt-4">
                                        <label>QA Parameters</label>
                                        <table className="table table-bordered" width="100%" cellPadding={5} >
                                            <tr>
                                                <th>Characteristics</th>
                                                <th>Units</th>
                                                <th>Specification</th>
                                                <th>Action</th>
                                            </tr>
                                            <tr>
                                                <td>Block Dimensional</td>
                                                <td>mm</td>
                                                <td>Tolerance limit</td>
                                                <td>
                                                    <select className="qa_parameters" name="block_dim_mm_tolerance" id="block_dim_mm_tolerance" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Block Dimensional__mm__Tolerance limit">Yes</option>    
                                                        <option value="No__Block Dimensional__mm__Tolerance limit">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Block Dimensional</td>
                                                <td>mm</td>
                                                <td>Length ±5mm</td>
                                                <td>
                                                    <select className="qa_parameters" name="block_dim_mm_lengthsum" id="block_dim_mm_lengthsum" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Block Dimensional__mm__Length ±5mm">Yes</option>    
                                                        <option value="No__Block Dimensional__mm__Length ±5mm">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Block Dimensional</td>
                                                <td>mm</td>
                                                <td>Thickness & width ±3mm</td>
                                                <td>
                                                    <select className="qa_parameters" name="block_dim_mm_thickness" id="block_dim_mm_thickness" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Block Dimensional__mm__Thickness & width ±3mm">Yes</option>    
                                                        <option value="No__Block Dimensional__mm__Thickness & width ±3mm">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Density</td>
                                                <td>Kg/m2</td>
                                                <td>551 - 650 Kg/m²</td>
                                                <td>
                                                    <select className="qa_parameters" name="density" id="density" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Density__Kg/m2__551 - 650 Kg/m²">Yes</option>    
                                                        <option value="No__Density__Kg/m2__551 - 650 Kg/m²">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Compressive strength</td>
                                                <td>N/mm2</td>
                                                <td>Minimum 4.0</td>
                                                <td>                                                    
                                                    <select className="qa_parameters" name="compressive" id="compressive" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Compressive strength__N/mm2__Minimum 4.0">Yes</option>    
                                                        <option value="No__Compressive strength__N/mm2__Minimum 4.0">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Drying shrinkage </td>
                                                <td>%</td>
                                                <td>Maximum -0.05%</td>
                                                <td>
                                                    <select className="qa_parameters" name="dyingShrink" id="dyingShrink" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Drying shrinkage__%__Tolerance limit">Yes</option>    
                                                        <option value="No__Drying shrinkage__%__Tolerance limit">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Thermal conductivity</td>
                                                <td>W /mk </td>
                                                <td>Maximum -0.24</td>
                                                <td>
                                                    <select className="qa_parameters" name="thermalconductivity" id="thermalconductivity" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Thermal conductivity__W /mk__Maximum -0.24">Yes</option>    
                                                        <option value="No__Thermal conductivity__W /mk__Maximum -0.24">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Thermal conductivity</td>
                                                <td>W /mk </td>
                                                <td>If Density 551-650 kg/m²</td>
                                                <td>                                                    
                                                    <select className="qa_parameters" name="thermalconductivity_dense" id="thermalconductivity_dense" required>
                                                        <option value="">Select</option>    
                                                        <option value="Yes__Thermal conductivity__W /mk__If Density 551-650 kg/m²">Yes</option>    
                                                        <option value="No__Thermal conductivity__W /mk__If Density 551-650 kg/m²">No</option>   
                                                    </select>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>                 
                                    <div className="col-sm-12 mt-4">
                                        <label>Remarks</label>
                                        <textarea className="form-control" name="checklist_remarks" onChange={this.changeHandler.bind(this)} value={this.state.checklist_remarks} ></textarea>
                                    </div>
                                    <div className="col-sm-12 mt-4">
                                        <label>Upload Document</label>
                                        <input type="file" className="form-control" name="checklistFile" onChange={this.changeFileHandler} />
                                    </div>
                                    
                                    <div className="col-sm-12" style={{marginTop:"10px"}}>
                                        <button type="submit" className="btn btn-danger">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className={"sliderBlock2 "+(this.state.uploadinvslider)}>
                    <h5 className="crd-bg p-10p">Upload Invoice</h5>
                    <div className="">   
                        <form className="theme" id="formUploadID" method="POST" enctype="multipart/form-data" onSubmit={this.onSubmitInvDoc}>
                            <div className="slide-body">
                                <div className="row p-20p">                     
                                  
                                    <div className="col-sm-12 mt-4">
                                        <label>Upload Document</label>
                                        <input type="file" className="form-control" name="checklistFile" onChange={this.changeFileHandler} />
                                    </div>
                                    
                                    <div className="col-sm-12" style={{marginTop:"10px"}}>
                                        <button type="submit" className="btn btn-danger">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={"sliderBlock2 "+(this.state.updateStockslider)}>
                    <h5 className="crd-bg p-10p">Add / Update Open Stock</h5>
                    <div className="">   
                        <form className="theme" id="formUploadID" method="POST" enctype="multipart/form-data" onSubmit={this.onSubmitStock.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">                     
                                  
                                    <div className="col-sm-12 mt-4">
                                        <label>Open Stock </label>
                                        <input type="text" className="form-control" name="open_stock" value={this.state.open_stock} onChange={this.changeHandler.bind(this)} />
                                    </div>
                                    
                                    <div className="col-sm-12" style={{marginTop:"10px"}}>
                                        <button type="submit" className="btn btn-danger">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12" style={{color:"#ff0000",fontSize:"12px",fontWeight:"bold"}}>
                        Note: ** Total Stock = Opening Stock+(Produced Quantity - Approved Quantity)
                    </div>
                </div>
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
			</div>
        );
    }
}   
function onClickUpdateItem(rowdn){
    // console.log("rowdn ", rowdn)
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy',minDate:1 });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        minDate:1
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}
function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 