

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import Select from 'react-select';
import CommentActions from "../layouts/commentsComponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from '../layouts/markDeliveredButton';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import Datetime from "react-datetime";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from './updateManualExportsBtn';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import Modal from 'react-responsive-modal';
import download from 'js-file-download';
import FFSeaImportsgridData from "./ffseaimportsgriddata";
import ChaSeaImportsgridData from "./chaseaimportsgriddata";
import TransSeaImportsgridData from "./transseaimportsgriddata";
import ClosureSeaImportsgridData from "./closureseaimportsgriddata";
import PreclosuregridData from "./preclosuregriddata";
import redirectURL from "../redirectURL";
import CSVFileValidator from 'csv-file-validator';
import withRouter from '../withRouter';
import * as XLSX from 'xlsx';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenDDMMMYYYY } from '../common/utils'; 
import { all } from 'axios';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");




class SeaImportsListComponent extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
            resizable: true,
            menuTabs: ['filterMenuTab']
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        openModal: false,
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        erpData:[],
        reqContainersData:[],
        ringiData:[],
        cargoData:[],
        sliderTitle:"",
        countryOptions: [],
        erpLength : 0,
        ringiLength : 0,
        cargoLength : 0,
        allCnt: 0,
        openCnt : 0,
        pendingCnt : 0,
        doPendingCnt: 0,
        pendingTranCnt : 0,
        invCnt: 0,
        blPendingCnt : 0,
        transCnt : 0,
        closureCnt : 0,
        pending:"activet",
        ffupdates:"",
        chaupdates:"",
        tranupdates:"",
        closure:"",
        preclosure:"",
        alldetails:"",
        dataType:0,
        pendingData:"show-m",
        ffData:"show-n",
        chaData:"show-n",
        tranData:"show-n",
        closureData:"show-n",
        preclosureData:"show-n",
        country:{value: "all", label: "All"},
        criteria:[],
        dataType:0,
        rejectRowId:"",
        rejectedreason:0,
        sliderRso: "",
        csvOrdersContent: [],
        detailCellRendererParams:{},
        pendingbtn: "btn-active",
        inprocessbtn: "btn-default",
        // ffupdatesbtn: "btn-default",
        // chaupdatesbtn: "btn-default",
        // tranupdatesbtn: "btn-default",
        closurebtn: "btn-default"
        //components : {datepicker:getDatePicker()}
    }
    this.downloadRingiFile = this.downloadRingiFile.bind(this);
    this.rejectCargoRequest = this.rejectCargoRequest.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}

handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);

    var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
    if (this.state.endDate != undefined && this.state.endDate != "") {
        console.log(startdate,"startdate")
        console.log(this.state.endDate,"endDate")
        var startDate = new Date(startdate);
        var endDate = new Date(this.state.endDate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        // console.log(startTime,endTime);
        if (startTime > endTime) {
            this.setState({
                startDate: this.state.startDate,
                show: true,
                basicType: "danger",
                basicTitle: "From Date shouldn't be greater than To Date"
            });
        }
        else {
            this.setState({
                startDate: startdate
            });
        }
    }
    else {
        this.setState({
            startDate: startdate
        });
    }
    console.log("startDate", this.state.startDate)
    //let value = event.target.value;
    //this.setState({'startDate':value});
}

handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
    if (this.state.endDate != undefined && this.state.endDate != "") {
        // console.log(this.state.startDate,"startdate")
        // console.log(edate,"endDate")
        var startDate = new Date(this.state.startDate);
        var endDate = new Date(edate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        // console.log(startTime,endTime);
        if (startTime > endTime) {
            this.setState({
                endDate: this.state.endDate,
                show: true,
                basicType: "danger",
                basicTitle: "To Date shouldn't be lesser than From Date"
            });
        }
        else {
            this.setState({
                endDate: edate
            });
        }
    }
    else {
        this.setState({
            endDate: edate
        });
    }
    console.log("endDate", this.state.endDate)
}


componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
    
}
onLoadPageData(){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
        // dataType : dataType,
    });
    // commented 8/7 
    // var country = this.state.country;
    // var criteria = this.state.criteria;
    // var startDate = $("#from_date").val();
    // var endDate = $("#to_date").val();
    // var query = {};
    // var check = 0;
    // if(country !=undefined && country !='')
    // {
    //     query.country = country.value;
    // }
    // if(criteria !=undefined && criteria !='' && startDate !=undefined && startDate !='' && endDate !=undefined && endDate !='')
    // {
    //     query.criteria = criteria.value;
    //     query.startDate = startDate;
    //     query.endDate = endDate;
    // }
    // redirectURL.post("/consignments/getSeaImports",query)
    redirectURL.post("/consignments/getCountryList").then((res) => {
        var countries = res.data.countries;
        var countryOptions = [];
        // console.log("countries", countries)     
        //items.push({value:'all',label:'All'}); 
        countries.forEach(item =>{
            countryOptions.push({value:item,label:item});
        });
        countryOptions.push({value: "all", label: "All"});
        this.setState({
            countryOptions: countryOptions,
        })
        // console.log("items", items)
    })   
    var params = {
        cha_flag: 1
    }    
    redirectURL.post("consignments/getBookingData", params)    
    .then((response) => {
        console.log(response.data, "pendingData")
        // if(dataType == 0)
        // {  
        var records =  response.data.bookingData;
        // }
        // else if(dataType == 1)
        // {
        //     var results =  response.data;
        // }
        // else if(dataType == 2)
        // {
        //     var results =  response.data.chaData;
        // }
        // else if(dataType == 3)
        // {
        //     var results =  response.data.transData;
        // }
        // else if(dataType == 4)
        // {
        //     var results =  response.data.closureData;
        // }
        // else if(dataType == 5)
        // {
        //     var results = response.data.allData
        // }
        // else if(dataType == 6){
        //     var results =  response.data.preClosureData;
        // }
        // var records = results.bookingData;
        // var erpLength = results.erpLength;
        // var ringiLength = results.ringiLength;
        // var cargoLength = results.cargoLength;
        // var allCnt = response.data.allData.records.length;
        // var pendingCnt = response.data.pendingData.records.length;
        // var ffCnt = response.data.ffData.records.length;
        // var chaCnt = response.data.chaData.records.length;
        // var transCnt = response.data.transData.records.length;
        // var closureCnt = response.data.closureData.records.length;
        // var payload = {
        //     cha_flag: {$ne: 7}
        // }
        // redirectURL.post("/consignments/getBookingData", payload).then((response) => {
        //     var allrecords = response.data.bookingData;
        //     var allCnt = allrecords.length;
        //     var pendingCnt = records.length;
        var counters = response.data.counters;
        console.log(records, "records set")
        this.setState({
            rowData: records,
            pendingbtn: "btn-active",
            inprocessbtn: "btn-default",
            closurebtn: "btn-default",
            allCnt: counters.allCount,
            openCnt: counters.openCount,
            doPendingCnt: counters.doPendingCount,
            pendingCnt: counters.pendingCount,
            pendingTranCnt: counters.tranPendingCount,
            invCnt: counters.invCount,
            blPendingCnt: counters.blPendingCount,
            closureCnt: counters.closureCount,
            overly:"show-n",
            loadshow:"show-n",
        })
        // this.setState({
        //     rowData : records,
        //     // erpLength : erpLength,
        //     // ringiLength : ringiLength,
        //     // cargoLength : cargoLength,
        //     // allCnt : allCnt,
        //     // pendingCnt : pendingCnt,
        //     // ffCnt : ffCnt,
        //     // chaCnt : chaCnt,
        //     // transCnt : transCnt,
        //     // closureCnt : closureCnt,
        //     loadshow:'show-n',
        //     overly:'show-n',
        // })        
    })
    .catch(function(e){
        console.log("Error ",e)
    })
    
}

previewDocument = (documentUrl) => {

    // console.log("document", document);

    this.setState({
      openModal: true,
      documentUrl: documentUrl,
      documentType: "pdf",
    });

};

onLoadInProcessData = async() => {
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    var params = {
        "cha_flag" : {$in: [2,3,4,5]}
    }
    await redirectURL.post("/consignments/getBookingData",params)    
    .then((response) => {
        var records = response.data.bookingData;
        console.log("records ", records)
        this.setState({
            rowData : records,
            pendingbtn: "btn-default",
            inprocessbtn: "btn-active",
            closurebtn: "btn-default",
            overly:"show-n",
            loadshow:"show-n",
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}

onLoadClosureData = async() => {
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    
    var params = {
        "cha_flag" : 7
    }
    await redirectURL.post("/consignments/getBookingData",params)    
    .then((response) => {
        var records = response.data.bookingData;
        console.log("records ", records)
        this.setState({
            rowData : records,            
            pendingbtn: "btn-default",
            inprocessbtn: "btn-default",
            closurebtn: "btn-active",
            overly:"show-n",
            loadshow:"show-n",
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}

onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onClickHideAll(){
    document.getElementById("uploadfile").value = null
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        erpData:[],
        ringiData:[],
        cargoData:[],
        rejectedreason:"",
        sliderRso: "",
    });
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-60"
    })
}

resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}

changeOrderFileHandlerFc = async (e) => {
    let fileExtension = e.target.files[0].name.split(".").pop();
    if (fileExtension === "xlsx") {
      const config = {
        headers: [
          {
            name: 'WO No.',
            inputName: 'booking_no',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'CHA',
            inputName: 'cha_entity',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Country',
            inputName: 'destination_country',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Qty',
            inputName: 'item_quantity',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Container Type',
            inputName: 'container_size',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'No. of containers',
            inputName: 'container_quantity',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Plant',
            inputName: 'plant_code',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'POD',
            inputName: 'destination_port',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Buyer',
            inputName: 'buyer_code',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Consignee',
            inputName: 'consignee_code',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Haz/Non-Haz',
            inputName: 'container_type',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'Stuffing Plan',
            inputName: 'stuffing_planned_date',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
          {
            name: 'POL',
            inputName: 'loading_port',
            required: true,
            requiredError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            }
          },
        ]
      }
      this.setState({
        selectedFile: e.target.files[0]
      }, () => {
        if (this.state.selectedFile) {
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);

            CSVFileValidator(csvData, config).then((csvContent) => {
                // console.log("csvContent",csvContent);
              if (csvContent.inValidData.length === 0) {
                this.setState({
                    csvOrdersContent: csvContent.data
                }, () => {
                  console.log("Valid data is found");
                })
              } else {
                document.getElementById("uploadfile").value = null
                this.setState({
                  show: true,
                  basicType: 'danger',
                  basicTitle: 'Please upload file having valid headers',
                });
              }
            }).catch((err) => {
              console.log("err", err);
            })
          };
          fileReader.readAsArrayBuffer(this.state.selectedFile);
        }
      })
    } else {
      document.getElementById("uploadfile").value = null
      this.setState({
        show: true,
        basicType: 'danger',
        basicTitle: 'Please upload file having extensions xlsx only.',
      });
    }
}

handleOrderBulkUploadClick = () => {
    this.setState({
        sliderRso: "slider-translate"
      });
}

onSubmitOrderBulkUpload = async (event) => {
    event.preventDefault();
    this.setState({
        loadshow: "show-m",
        overly: "show-m"
    })
    let ordersData = this.state.csvOrdersContent;
    let duplicateOrders = [];
    let specialCharacters = 0;
    let uniqueData = {};
    let finalOrdersData = [];
    if (ordersData) {
        for (let i=0; i < ordersData.length; i++) {
            let stuffDate = ordersData[i]["stuffing_planned_date"];
            let dateSplitArr = stuffDate.split("/");
            let stuffingPlanDate = dateSplitArr[0] + "-" + dateSplitArr[1] + "-" + dateSplitArr[2];
            ordersData[i]["stuffing_planned_date"] = stuffingPlanDate;
            const value = ordersData[i]["booking_no"];
            if (!ordersData[i]["booking_no"].match(/[^a-zA-Z0-9]/g)) {
                if (!uniqueData[value]) {
                    uniqueData[value] = true;
                    finalOrdersData.push(ordersData[i]);
                }
            }
        }
    }
    let params = {
        csvcontent: JSON.stringify(finalOrdersData),
        email: localStorage.getItem("email")
    }

    await redirectURL.post("/consignments/bulkUploadBookingOrders", params).then((res) => {
        if (res.status == 200) {
            document.getElementById("uploadfile").value = null
            this.setState({
              show: true,
              basicType: 'success',
              loadshow: "show-n",
              overly: "show-n",
              rowData: res.data.results,
              basicTitle: `Uploaded Successfully`,
              sliderRso: ""
            });
            this.onLoadPageData();
        }
    })
}

viewDetails(e){
    if(e.colDef.field == "erpData")
    {
        var erpData = JSON.parse(e.data.erpData);
        erpData.map(function(e){
            var eDate = e.erPoDate;
            if(eDate !=undefined && eDate !='')
            {
                if(eDate.includes("T") == true)
                {
                    eDate = eDate.split("T");
                    eDate = eDate[0];
                    eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0];
                }
            }
            e.erPoDate = eDate;
        })
        var sliderTitle = e.data.job_no+" - ERP Details";
        this.setState({
            overly :"show-m",
            erpData:erpData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "ringiData")
    {
        var ringiData = JSON.parse(e.data.ringiData);
        var sliderTitle = e.data.job_no+" - Ringi Details";
        this.setState({
            overly :"show-m",
            ringiData:ringiData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "cargoData")
    {
        var cargoData = JSON.parse(e.data.cargoData);
        var sliderTitle = e.data.job_no+" - Cargo Details";
        this.setState({
            overly :"show-m",
            cargoData:cargoData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "closed")
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var params = {
                rowId : rowId
            }
            redirectURL.post("/consignments/closeSeaImportsRequest",params)    
            .then((response) => {
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Successfully marked as Closed",
                        basicType : "success",
                        show : true,
                        overly:"show-n",
                        loadshow:"show-n",
                    })
                    //window.location.reload();
                    this.onLoadPageData(4);
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to mark as closed.",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                    })
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
    }
    else if(e.colDef.field == "reject")
    {
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var sliderTitle = e.data.job_no+" - Reject Reason";
            $("#reject_reason").val("");
            this.setState({
                rejectRowId : rowId,
                rejectedreason : 1,
                overly :"show-m",
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            });
        }
    }
    if(e.colDef.field == "reqContainerData")
    {
        var reqContainersData = JSON.parse(e.data.reqContainersData);
        var sliderTitle = e.data.job_no+" - COntainer Details";
        this.setState({
            overly :"show-m",
            reqContainersData:reqContainersData,
            bulkslide : "slider-translate-60p",
            sliderTitle : sliderTitle
        })
    }
}

onShowTicketHistory = async (rowdata) =>{
    console.log("rowdata ", rowdata)
    console.log(this.state.closurebtn, this.state.inprocessbtn)
    var transitedelaydata=[];
    var closureBtn = this.state.closurebtn;
    var inprocessBtn = this.state.inprocessbtn;
    await this.setState({
            
        detailCellRendererParams:{
            suppressRefresh: true,
            detailGridOptions: {
                columnDefs: [
                    // { 
                    //     headerName: "Update",
                    //     field: "", 
                    //     width:180,
                    //     resizable: true,
                    //     cellRendererFramework: (params)=>{ return <button className='btn btn-primary'
                    //         style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                    //         onClick={this.openDocumentsSlider.bind(this, params)}>Update</button>
                    //     }
                    //     // valueGetter:function(params){
                    //     //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                    //     // },
                    // },						
                    { 
                        headerName: "Work Order No",
                        field: "booking_no",
                        width:150,
                        resizable: true,
                    },
                    { 
                        headerName: "Requirement ID",
                        field: "requirement_id", 
                        width:180,
                        resizable: true,
                    },
                    { 
                        headerName: "Truck No",
                        field: "truck_number", 
                        width:180,
                        resizable: true,
                        
                     },
                     { 
                        headerName: "Container No",
                        field: "container_number", 
                        width:180,
                        resizable: true,
                        
                     },
                     { 
                        headerName: "Pick Up Date",
                        field: "pickup_date_time", 
                        width:180,
                        resizable: true,
                        valueGetter:function(params){
                            //console.log(params);
                            if(params.data.stime != ""){
                              return getHyphenDDMMMYYYY(params.data.pickup_date_time);
                            }
                            else{
                                return "";
                            }   
                       },   
                     },
                     { 
                         headerName: "Reporting to CHA Time",
                         field: "reporting_to_cha_datetime", 
                         width:180,
                         resizable: true,
                         valueGetter:function(params){
                            //console.log(params);
                            if(params.data.stime != ""){
                              return getHyphenDDMMMYYYY(params.data.reporting_to_cha_datetime);
                            }
                            else{
                                return "";
                            }   
                       },                         
                      },
                      { 
                          headerName: "Gate In Time",
                          field: "gatein_datetime", 
                          width:180,
                          resizable: true,
                          valueGetter:function(params){
                            //console.log(params);
                            if(params.data.stime != ""){
                              return getHyphenDDMMMYYYY(params.data.gatein_datetime);
                            }
                            else{
                                return "";
                            }   
                       },   
                       },						
                      { 
                          headerName: "Shipped Onboard Time",
                          field: "shipped_onboard_time", 
                          width:180,
                          resizable: true,
                          valueGetter:function(params){
                            //console.log(params);
                            if(params.data.stime != ""){
                              return getHyphenDDMMMYYYY(params.data.shipped_onboard_time);
                            }
                            else{
                                return "";
                            }   
                       }, 
                       }						
                ],
                overlayNoRowsTemplate: 'No data available',                     
            },
            // getDetailRowData: function(param) {
            //     //console.log("Step 4 ",transitedelaydata)
            //     // console.log("Step 4 ",param)
            //     //----------------------------------//

            //     var legdata=[{
            //         _id:param.data._id,
            //         // consignment_codes:param.data.consignment_codes,
            //         // exception_closed:param.data.exception_closed,
            //         booking_no:param.data.booking_no,
            //         requirement_id:param.data.requirement_id,
            //         reporting_to_cha_datetime:param.data.reporting_to_cha_datetime,
            //         gatein_datetime:param.data.gatein_datetime,
            //         shipped_onboard_time:param.data.shipped_onboard_time,
            //         // ready_to_notify:param.data.ready_to_notify,
            //         // packet_received_on:packetreceived,
            //         // reset_packet_latitude:param.data.reset_packet_latitude,
            //         // reset_packet_longitude:param.data.reset_packet_longitude,
            //         // no_gps_ticket:param.data.no_gps_ticket,
            //         // _id: param.data._id,
            //         // consignment_codes:""
            //     }]
            //     // legsarr.push(legdata)
            //     param.successCallback(legdata);

            getDetailRowData: function(params) {
                console.log("Step 4 ",params)
                console.log("inprocesstab", inprocessBtn)
                if (inprocessBtn === "btn-active") {
                    var payload = {
                        // ticket_id:params.data.ticket_id,
                        booking_no:params.data.booking_no,
                        cha_flag: {$in:[2,3,4,5]}
                    }
                }
                if (closureBtn === "btn-active") {
                    payload = {
                        booking_no:params.data.booking_no,
                        cha_flag: 7
                    }
                }
                redirectURL.post('/consignments/getBookingLegsData', payload)
                .then(async (response) =>{
                    console.log("Step 1 ",response.data)
                    params.successCallback(response.data.bookingData);
                    //console.log("Step 2 ",response.data)
                    
                });
                
            }
        }
    });

    //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
    if(rowdata.column.colDef.field == '_id')
    {
        rowdata.node.setExpanded(!rowdata.node.expanded);
        if(rowdata.node.expanded == false)
        {
            transitedelaydata=[]
        }
    }
    else{

        rowdata.node.setExpanded(false);
    }
}
closeModal = () => {
    this.setState({
        openModal: false,
    });  
};

rejectCargoRequest(event){
    event.preventDefault();
    var rejectRowId = this.state.rejectRowId;
    var reject_reason = $("#reject_reason").val();
    if(rejectRowId !=undefined && rejectRowId !='')
    {
        if(reject_reason !=undefined && reject_reason !='')
        {
            var params = {
                rowId : rejectRowId,
                reject_reason : reject_reason,
            }
            redirectURL.post("/consignments/rejectSeaImportsRequest",params)    
            .then((response) => {
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Marked as Rejected",
                        basicType : "success",
                        show : true,
                        overly:"show-n",
                        loadshow:"show-n",
                        rejectedreason:0,
                        bulkslide:"",
                    })
                    //window.location.reload();
                    this.onLoadPageData(0);
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to mark as Rejected.",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        bulkslide:"",
                    })
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Reason can not be empty.",
                basicType : "warning",
                show : true,
                loadshow:'show-n',
            })
        }
    }
    else
    {
        this.setState({
            basicTitle:"Something went wrong, Please try again.",
            basicType : "warning",
            show : true,
            loadshow:'show-n',
            overly:'show-n',
            rejectedreason:0,
            bulkslide:"",
        })
    }
}
downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadSeaImportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

async onClickDataExport(){
    var expKeys = ['job_no_1', 'date_1', 'dept_name_1', 'indenter_name_1', 'mobile_1', 'indenter_email_1', 'erpPO_1'];
    
    var erpLength = this.state.erpLength;
    for(var e=0;e<erpLength;e++)
    {
        var eIndex = e+1;
        var erpPO = "erpPoNo"+eIndex;
        var erppoDate  = "erPoDate"+eIndex;
        expKeys.push(erpPO);
        expKeys.push(erppoDate);
    }
    var ringiLength = this.state.ringiLength;
    for(var r=0;r<ringiLength;r++)
    {
        var rIndex = r+1;
        var rngFile = "ringi_file"+rIndex;
        var rngDesc  = "ringi_desc"+rIndex;
        expKeys.push(rngFile);
        expKeys.push(rngDesc);
    }
    expKeys.push('cargo_delivery_plant_1',"no_of_packets_1");
    var cargoLength = this.state.cargoLength;
    for(var c=0;c<cargoLength;c++)
    {
        var cIndex = c+1;
        var itemDesc = "item_description"+cIndex;
        var cgw  = "cargo_gross_weight"+cIndex;
        var dimensions  = "dimensions"+cIndex;
        expKeys.push(itemDesc);
        expKeys.push(cgw);
        expKeys.push(dimensions);
    }
    expKeys.push("supplier_name_1","supplier_address_1","supplier_contact_person_1","country_1","email_1","phone_1","delivery_terms_1","pick_up_address_1","port_1","airport_name_1","cargo_date_1","lc_1","lc_no_1","nature_1","free_trade_1","benefit_certificate_1");
    var params = {
        columnKeys: expKeys
    };
   
    //console.log(params,"expparams")
    await this.gridApi.exportDataAsExcel(params);
}
// onClickTab(pageType){
//     if(pageType == 1)
//     {
//         var pending = "activet";
//         var ffupdates = "";
//         var chaupdates = "";
//         var tranupdates = "";
//         var closure = "";
//         var alldetails = "";
//         var preclosure = "";
//         var pendingData = "show-m";
//         var ffData = "show-n";
//         var chaData = "show-n";
//         var tranData = "show-n";
//         var closureData = "show-n";
//         var preclosureData = "show-n";
//         this.onLoadPageData(0);
//     }
//     else if(pageType == "ffupdates")
//     {
//         var pending = "";
//         var ffupdates = "activet";
//         var chaupdates = "";
//         var tranupdates = "";
//         var closure = "";
//         var alldetails = "";
//         var preclosure = "";
//         var pendingData = "show-n";
//         var ffData = "show-m";
//         var chaData = "show-n";
//         var tranData = "show-n";
//         var closureData = "show-n";
//         var preclosureData = "show-n";
//         this.onLoadPageData(1);
//     }
//     else if(pageType == "chaupdates")
//     {
//         var pending = "";
//         var ffupdates = "";
//         var chaupdates = "activet";
//         var tranupdates = "";
//         var closure = "";
//         var alldetails = "";
//         var preclosure = "";
//         var pendingData = "show-n";
//         var ffData = "show-n";
//         var chaData = "show-m";
//         var tranData = "show-n";
//         var closureData = "show-n";
//         var preclosureData = "show-n";
//         this.onLoadPageData(2);
//     }
//     else if(pageType == "tranupdates")
//     {
//         var pending = "";
//         var ffupdates = "";
//         var chaupdates = "";
//         var tranupdates = "activet";
//         var closure = "";
//         var alldetails = "";
//         var preclosure = "";
//         var pendingData = "show-n";
//         var ffData = "show-n";
//         var chaData = "show-n";
//         var tranData = "show-m";
//         var closureData = "show-n";
//         var preclosureData = "show-n";
//         this.onLoadPageData(3);
//     }
//     else if(pageType == "closure")
//     {
//         var pending = "";
//         var ffupdates = "";
//         var chaupdates = "";
//         var tranupdates = "";
//         var closure = "activet";
//         var alldetails = "";
//         var preclosure = "";
//         var pendingData = "show-n";
//         var ffData = "show-n";
//         var chaData = "show-n";
//         var tranData = "show-n";
//         var closureData = "show-m";
//         var preclosureData = "show-n";
//         this.onLoadPageData(4);
//     }
//     else if(pageType == "all")
//     {
//         var pending = "";
//         var ffupdates = "";
//         var chaupdates = "";
//         var tranupdates = "";
//         var closure = "";
//         var alldetails = "activet";
//         var preclosure = "";
//         this.onLoadPageData(5);
//     }
//     else if(pageType == "preclosure")
//     {
//         var pending = "";
//         var ffupdates = "";
//         var chaupdates = "";
//         var tranupdates = "";
//         var closure = "";
//         var alldetails = "";
//         var preclosure = "activet";
//         var pendingData = "show-n";
//         var ffData = "show-n";
//         var chaData = "show-n";
//         var tranData = "show-n";
//         var closureData = "show-n";
//         var preclosureData = "show-m";
//         this.onLoadPageData(6);
//     }
//     this.setState({
//         pending:pending,
//         ffupdates:ffupdates,
//         chaupdates:chaupdates,
//         tranupdates:tranupdates,
//         closure:closure,
//         alldetails:alldetails,
//         preclosure:preclosure,
//         pendingData:pendingData,
//         ffData:ffData,
//         chaData:chaData,
//         tranData:tranData,
//         closureData:closureData,
//         preclosureData:preclosureData,
//     })
// }

onClickTab = async(oVal) => {
    if(oVal === 1) {
        await this.setState({
            pendingbtn: "btn-active"
        })
        this.onLoadPageData();
        console.log("pendingtab", this.state.pendingbtn)
    }
    if(oVal === 2) {
        await this.setState({
            inprocessbtn: "btn-active"
        })
        this.onLoadInProcessData();
        console.log("inprocesstab", this.state.inprocessbtn)
    }
    if(oVal === 3) {
        await this.setState({
            closurebtn: "btn-active"
        })
        this.onLoadClosureData();
        console.log("closuretab", this.state.closurebtn)
    }

    // if(oVal === 3)
    // {
    //     this.setState({                
    //         actBtn:"btn-default",
    //         inPrcsBtn:"btn-default",
    //         clsrBtn:"btn-active",
    //         rowData: this.state.inClsrPffDetails
    //     })
    // }
}

newFilter = (filterType, selOption) => {
    this.setState({ [filterType]: selOption });
};

// getCountryListItems = country =>{
//     this.setState({
//       [country]: country
//     });
    
//     this.setState({
//         errorMessage:'',
//         successMessage:''
//     })
// }
// selectCountryOptionsItems = async() => {
// }

getCriteriaListItems = criteria =>{
    this.setState(
      { criteria },
      () => console.log(this.state.criteria)
    );
    
    this.setState({
        errorMessage:'',
        successMessage:''
    })
}
selectCriteriaOptionsItems(){
    let items = [
        {value:'ata_igi_airport_date',label:'ATA IGI Airport Date'},
        {value:'ooc_date',label:'OOC Date'},
        {value:'actual_delivery_at_msil',label:'MSIL Actual Delivery Date'},
    ];
    return items;
}
onClickGetData = (event) =>{
    var country = this.state.country.value;
    var criteria = this.state.criteria;
    // var startDate = $("#from_date").val();
    // var endDate = $("#to_date").val();
    var startDate = this.state.startDate;
    var endDate = this.state.endDate;
    // startDate = moment.parseZone(startDate)._d;
    // endDate = moment.parseZone(endDate)._d;
    if (startDate) {
        var splitStartDate = startDate.split("-")
        if (splitStartDate[1] < 10) {
            startDate = splitStartDate[0] + "-" + "0" + splitStartDate[1] + "-" + splitStartDate[2] 
        }
        console.log("startDate", startDate)
    }
    
    if (endDate) {
        var splitEndDate = endDate.split("-")
        if (splitEndDate[1] < 10) {
            endDate = splitEndDate[0] + "-" + "0" + splitEndDate[1] + "-" + splitEndDate[2] 
        }
        console.log("endDate", endDate)
    }
    
    var params = { cha_flag: 1 }

    if (country != "all") {
        /* The above code is a comment in JavaScript. It is not doing anything, but it is used to provide
        information or explanations about the code to other developers. */
        params.destination_country = country
    }
    if (startDate && endDate) {
        params.stuffing_planned_date = {$gte: startDate, $lte: endDate}
    } else if (startDate) {
        params.stuffing_planned_date = {$gte: startDate}
    } else if(endDate){
        params.stuffing_planned_date = {$lte: endDate}
    } else {

    }
    if (country == "all" && !startDate && !endDate) {
        params = { 
            cha_flag: 1
        }
    }
    console.log("filterParams", params)
    redirectURL.post("/consignments/getBookingData", params).then((res) => {
        this.setState({
            rowData: res.data.bookingData,
            allCnt: res.data.counters.allCount,
            openCnt: res.data.counters.openCount,
            doPendingCnt: res.data.counters.doPendingCount,
            pendingCnt: res.data.counters.pendingCount,
            pendingTranCnt: res.data.counters.tranPendingCount,
            invCnt: res.data.counters.invCount,
            blPendingCnt: res.data.counters.blPendingCount,
            closureCnt: res.data.counters.closureCount,
        })
    })
    // var check = 0;
    // if(country !=undefined && country !='')
    // {
        
    // }
    // if(criteria !=undefined && criteria !='' && startDate !=undefined && startDate !='' && endDate !=undefined && endDate !='')
    // {
    //     check = 1;
    // }
    // if(check == 1)
    // {
    //     var dataType = this.state.dataType;
    //     this.onLoadPageData(dataType)
    // }
    // else
    // {
    //     this.setState({
    //         basicTitle:"Select options to continue.",
    //         basicType : "warning",
    //         show : true,
    //         loadshow:'show-n',
    //         overly:'show-n',
    //     })
    // }
}
  render() {
    // var dataType = this.state.dataType;
    // console.log(dataType,"dataType")
    
    // if(dataType == 4)
    // {
    //     var hideEdit = true;
    //     var hideClose = false;
    // }
    // else
    // {
    //     var hideEdit = false;
    //     var hideClose = true;
    // }
    // var columnwithDefs = [    
    //     {
    //         field:'',
    //         headerName:'',
    //         colId:"_id",
    //         hide:hideEdit,
    //         //pinned:"left",
    //         width: 72,
    //         cellRenderer:function(params){
    //             let basestring = params.data._id;
    //             let encryptedstring = window.btoa(basestring);
    //             if(params.data.request_type == 3)
    //             {
    //                 var htmloption = '<a href="/editffnewaircargoform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
    //             }
    //             else
    //             {
    //                 var htmloption = '<a href="/editseaimportsform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
    //             }
    //             return htmloption;
    //         }            
    //     },
    //     {
    //         headerName: "",
    //         field: "reject",
    //         width:100,
    //         filter: true,
    //         resizable: true,
    //         cellRenderer:function(params){
    //             var htmloption = '<button class="btn btn-primary" style="padding:0 9px">Reject</button>';
    //             return htmloption;
    //         }        
    //     },
    //     {
    //         headerName: "Job No",
    //         field: "job_no",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Date",
    //         field: "date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.date !=undefined && params.data.date !='')
    //             {
    //                 return moment.parseZone(params.data.date).format("DD-MM-YYYY")
    //             }
    //         }
    //     },
    //     {
    //         headerName: "Department Name",
    //         field: "dept_name",
    //         width:120,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Indenter Name",
    //         field: "indenter_name",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Mobile No",
    //         field: "mobile",
    //         width:100,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Indenter Email",
    //         field: "indenter_email",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Request Type",
    //         field: "request_type",
    //         width:150,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.request_type == 1)
    //             {
    //                 return "FCL - Booking Request";
    //             }
    //             else if(params.data.request_type == 2)
    //             {
    //                 return "LCL - Booking Request";
    //             }
    //             else if(params.data.request_type == 3)
    //             {
    //                 return "Prepaid Shipment";
    //             }
    //             else
    //             {
    //                 return "FF New Request";
    //             }
    //         }           
    //     },
    //     {
    //         headerName: "Submitted By",
    //         field: "created_by_user",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "ERP",
    //         field: "erpPO",
    //         width:100,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "ERP Data",
    //         field: "erpData",
    //         width:100,
    //         filter: true,
    //         resizable: true,
    //         cellRenderer:function(params){
    //             let basestring = params.data.batch_name;
    //             let encryptedstring = window.btoa(basestring);
    //             var htmloption = '<button class="btn btn-info" style="padding-top:0px">View</button>';
    //             return htmloption;
    //         }      
    //     },
    //     {
    //         headerName: "Ringi Data",
    //         field: "ringiData",
    //         width:100,
    //         filter: true,
    //         resizable: true,
    //         cellRenderer:function(params){
    //             let basestring = params.data.batch_name;
    //             let encryptedstring = window.btoa(basestring);
    //             var htmloption = '<button class="btn btn-danger" style="padding-top:0px">View</button>';
    //             return htmloption;
    //         }
    //     },
    //     {
    //         headerName: "No Of Packets",
    //         field: "item_desc",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Cargo Gross Weight (Metric Tons)",
    //         field: "gross_weight",
    //         width:150,
    //         filter: true,
    //         resizable: true, 
    //         valueGetter: function(params){
    //             if(params.data.gross_weight !="undefined" && params.data.gross_weight !=undefined)
    //             {
    //                 return params.data.gross_weight;
    //             }
    //         }
    //     },
    //     {
    //         headerName: "Volume (CBM)*",
    //         field: "dimensions_of_package",
    //         width:150,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.dimensions_of_package !="undefined" && params.data.dimensions_of_package !=undefined)
    //             {
    //                 return params.data.dimensions_of_package;
    //             }
    //         }
    //     },
    //     {
    //         headerName: "Gross Cargo Volume (CBM)",
    //         field: "gross_cargo_volume",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.gross_cargo_volume !="undefined" && params.data.gross_cargo_volume !=undefined)
    //             {
    //                 return params.data.gross_cargo_volume;
    //             }
    //         }
    //     },
    //     // {
    //     //     headerName: "Size Of Container required",
    //     //     field: "size_of_container",
    //     //     width:150,
    //     //     filter: true,
    //     //     resizable: true,
    //     //     valueGetter: function(params){
    //     //         if(params.data.size_of_container !="undefined" && params.data.size_of_container !=undefined)
    //     //         {
    //     //             return params.data.size_of_container;
    //     //         }
    //     //     }
    //     // },
    //     // {
    //     //     headerName: "Type Of Container",
    //     //     field: "type_of_container",
    //     //     width:130,
    //     //     filter: true,
    //     //     resizable: true,
    //     //     valueGetter: function(params){
    //     //         if(params.data.type_of_container !="undefined" && params.data.type_of_container !=undefined)
    //     //         {
    //     //             return params.data.type_of_container;
    //     //         }
    //     //     }
    //     // },
    //     {
    //         headerName: "No Of Containers required",
    //         field: "no_of_containers",
    //         width:150,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.no_of_containers !="undefined" && params.data.no_of_containers !=undefined)
    //             {
    //                 return params.data.no_of_containers;
    //             }
    //         }
    //     },
    //     {
    //         headerName: "Containers Data",
    //         field: "reqContainerData",
    //         width:100,
    //         filter: true,
    //         resizable: true,
    //         cellRenderer:function(params){
    //             let basestring = params.data.batch_name;
    //             let encryptedstring = window.btoa(basestring);
    //             var htmloption = '<button class="btn btn-warning" style="padding-top:0px">View</button>';
    //             return htmloption;
    //         }      
    //     },
    //     // {
    //     //     headerName: "Shipment is",
    //     //     field: "shipment_type",
    //     //     width:130,
    //     //     filter: true,
    //     //     resizable: true,
    //     //     valueGetter: function(params){
    //     //         if(params.data.shipment_type !="undefined" && params.data.shipment_type !=undefined)
    //     //         {
    //     //             return params.data.shipment_type;
    //     //         }
    //     //     }
    //     // },
    //     {
    //         headerName: "Cargo Type",
    //         field: "cargo_type",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.cargo_type !="undefined" && params.data.cargo_type !=undefined)
    //             {
    //                 return params.data.cargo_type;
    //             }
    //         }
    //     },
    //     // {
    //     //     headerName: "Handling Type",
    //     //     field: "handling_type",
    //     //     width:130,
    //     //     filter: true,
    //     //     resizable: true,
    //     //     valueGetter: function(params){
    //     //         if(params.data.handling_type !="undefined" && params.data.handling_type !=undefined)
    //     //         {
    //     //             return params.data.handling_type;
    //     //         }
    //     //     }
    //     // },
    //     {
    //         headerName: "Delivery in MSIL Plant",
    //         field: "cargo_delivery_plant",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.cargo_delivery_plant !="undefined" && params.data.cargo_delivery_plant !=undefined)
    //             {
    //                 return params.data.cargo_delivery_plant;
    //             }
    //         }
    //     },
    //     {
    //         headerName: "Supplier Name",
    //         field: "supplier_name",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Supplier Address",
    //         field: "supplier_address",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Supplier contact person",
    //         field: "supplier_contact_person",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Country of Supplier",
    //         field: "country",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Email id",
    //         field: "email",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Phone No",
    //         field: "phone",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "In co Terms",
    //         field: "delivery_terms",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Address",
    //         field: "pick_up_address",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.pick_up_address !=undefined && params.data.pick_up_address !="undefined")
    //                 {
    //                     return params.data.pick_up_address;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }           
    //     },
    //     // {
    //     //     headerName: "Place/port",
    //     //     field: "port",
    //     //     width:130,
    //     //     filter: true,
    //     //     resizable: true, 
    //     //     valueGetter: function(params){
    //     //         try{
    //     //             if(params.data.port !=undefined && params.data.port !="undefined")
    //     //             {
    //     //                 return params.data.port;
    //     //             }
    //     //         }
    //     //         catch(e){

    //     //         }
    //     //     }          
    //     // },
    //     // {
    //     //     headerName: "Airport Name",
    //     //     field: "airport_name",
    //     //     width:130,
    //     //     filter: true,
    //     //     resizable: true, 
    //     //     valueGetter: function(params){
    //     //         try{
    //     //             if(params.data.airport_name !=undefined && params.data.airport_name !="undefined")
    //     //             {
    //     //                 return params.data.airport_name;
    //     //             }
    //     //         }
    //     //         catch(e){

    //     //         }
    //     //     }          
    //     // },
    //     {
    //         headerName: "Proposed cargo readiness Date",
    //         field: "cargo_date",
    //         width:180,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.cargo_date !=undefined && params.data.cargo_date !='')
    //             {
    //                 return moment.parseZone(params.data.cargo_date).format("DD-MM-YYYY")
    //             }
    //         }           
    //     },
    //     {
    //         headerName: "LC Available",
    //         field: "lc",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "LC No",
    //         field: "lc_no",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.lc_no !=undefined && params.data.lc_no !="undefined")
    //                 {
    //                     return params.data.lc_no;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }           
    //     },
    //     {
    //         headerName: "Port Of Dispatch",
    //         field: "port_of_dispatch",
    //         width:130,
    //         filter: true,
    //         resizable: true          
    //     },
    //     {
    //         headerName: "Nature Of Shipment",
    //         field: "nature",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.nature !=undefined && params.data.nature !="undefined")
    //                 {
    //                     return params.data.nature;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }          
    //     },
    //     {
    //         headerName: "Free Trade Agreement",
    //         field: "free_trade",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.free_trade !=undefined && params.data.free_trade !="undefined")
    //                 {
    //                     return params.data.free_trade;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }         
    //     },
    //     {
    //         headerName: "R&D benefit Certificate",
    //         field: "benefit_certificate",
    //         width:130,
    //         filter: true,
    //         resizable: true, 
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.benefit_certificate !=undefined && params.data.benefit_certificate !="undefined")
    //                 {
    //                     return params.data.benefit_certificate;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }           
    //     },
    //     {
    //         headerName: "EPCG",
    //         field: "epcg",
    //         width:130,
    //         filter: true,
    //         resizable: true, 
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.epcg !=undefined && params.data.epcg !="undefined")
    //                 {
    //                     return params.data.epcg;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }           
    //     },
    //     // {
    //     //     headerName: "ETA IGI Airport Date",
    //     //     field: "eta_igi_airport_date",
    //     //     width:130,
    //     //     filter: true,
    //     //     resizable: true, 
    //     //     valueGetter: function(params){
    //     //         try{
    //     //             if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="undefined")
    //     //             {
    //     //                 return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
    //     //             }
    //     //         }
    //     //         catch(e){

    //     //         }
    //     //     }           
    //     // },
    //     {
    //         headerName: "Freight Forworder",
    //         field: "assign_to_ff",
    //         width:130,
    //         filter: true,
    //         resizable: true, 
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.assign_to_ff !=undefined && params.data.assign_to_ff !="undefined")
    //                 {
    //                     return params.data.assign_to_ff;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }           
    //     },
    //     {
    //         headerName: "FF Contact person Details",
    //         field: "ff_contact_person_details",
    //         width:150,
    //         filter: true,
    //         resizable: true, 
    //         valueGetter: function(params){
    //             try{
    //                 if(params.data.ff_contact_person_details !=undefined && params.data.ff_contact_person_details !="undefined")
    //                 {
    //                     return params.data.ff_contact_person_details;
    //                 }
    //             }
    //             catch(e){

    //             }
    //         }           
    //     }
    // ]
    // var erpLength = this.state.erpLength;
    // for(var e=0;e<erpLength;e++)
    // {
    //     var eIndex = e+1;
    //     var erpPO = "erpPoNo"+eIndex;
    //     var erpPODate = "erPoDate"+eIndex;
    //     var erpData = {
    //         headerName: "ERP PO No "+"-"+eIndex,
    //         field: erpPO,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     };
    //     columnwithDefs.push(erpData);
    //     var erpDate = {
    //         headerName: "ERP PO Date "+"-"+eIndex,
    //         field: erpPODate,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data[erpPODate] !=undefined && params.data[erpPODate] !='')
    //             {
    //                 return moment.parseZone(params.data[erpPODate]).format("DD-MM-YYYY")
    //             }
    //         }           
    //     };
    //     columnwithDefs.push(erpDate);
    // }
    // var ringiLength = this.state.ringiLength;
    // for(var r=0;r<ringiLength;r++)
    // {
    //     var rIndex = r+1;
    //     var ringiFile = "ringi_file"+rIndex;
    //     var ringiDesc = "ringi_desc"+rIndex;
    //     var ringiData = {
    //         headerName: "Ringi File"+"-"+rIndex,
    //         field: ringiFile,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     };
    //     columnwithDefs.push(ringiData);
    //     var ringiDescData = {
    //         headerName: "Ringi No"+"-"+rIndex,
    //         field: ringiDesc,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     };
    //     columnwithDefs.push(ringiDescData);
    // }
    // var cargoLength = this.state.cargoLength;
    // for(var c=0;c<cargoLength;c++)
    // {
    //     var cIndex = c+1;
    //     var itemDesc = "item_description"+cIndex;
    //     var cargogw = "cargo_gross_weight"+cIndex;
    //     var dimensions = "dimensions"+cIndex;
    //     var cargoData = {
    //         headerName: "Item Description"+"-"+cIndex,
    //         field: itemDesc,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     };
    //     columnwithDefs.push(cargoData);
    //     var cargogrossData = {
    //         headerName: "Cargo Gross Weight"+"-"+cIndex,
    //         field: cargogw,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     };
    //     columnwithDefs.push(cargogrossData);
    //     var cargodmData = {
    //         headerName: "Dimensions"+"-"+cIndex,
    //         field: dimensions,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     };
    //     columnwithDefs.push(cargodmData);
    // }
    // var airCargoDefs = columnwithDefs;
    // console.log(airCargoDefs, "airCargoDefs")
    // var columnDefs = [
    //     {
    //         headerName:"Update Booking Details",
    //         field:"Update Here",
    //         width:200,
    //         cellRendererSelector:function(params){
    //             return {
    //                 component:"UpdateBookingItem"
    //             }
    //         }
    //     },   
    //     {
    //         headerName:"Required Id",
    //         field:"Id",
    //         width:160,
    //     },           
    //     {
    //         headerName:"Container No",
    //         field:"Container No",
    //         width:160,
    //     },              
    //     {
    //         headerName:"Container Requirement Date",
    //         field:"Assigned Date",
    //         editable:false,
    //         width:200,
    //         valueGetter:function(params)
    //         {
    //             try {
    //                 if(params.data.departure_date !== "" && params.data.departure_date !== undefined)
    //                 {
    //                     return getHyphenDDMMMYYYYHHMM(params.data.departure_date);
    //                 }
    //                 else{
    //                     return "";
    //                 }
    //             } catch (error) {
                    
    //             }
    //         }
    //     },           
    //     {
    //         headerName:"Stuffing Location",
    //         field:"Departure Location",
    //         width:160,
    //     },           
    //     {
    //         headerName:"Country",
    //         field:"Country",
    //         width:160,
    //     },          
    //     {
    //         headerName:"Container Type",
    //         field:"Container Type",
    //         width:160,
    //     },          
    //     {
    //         headerName:"Container Size",
    //         field:"Container Size",
    //         width:160,
    //     },          
    //     {
    //         headerName:"Vehicle No",
    //         field:"Vehicle No",
    //         width:160,
    //     },
    //     {
    //         headerName:"Port Of Destination",
    //         field:"Port Of Destination",
    //         width:160,
    //     },
    //     {
    //         headerName:"BKG",
    //         field:"BKG",
    //         width:160,
    //     },
    //     {
    //         headerName:"Vessel",
    //         field:"Vessel",
    //         width:160,
    //     },              
    //     {
    //         headerName:"est_time_departure",
    //         field:"est_time_departure",
    //         editable:false,
    //         width:160,
    //         valueGetter:function(params)
    //         {
    //             try {
    //                 if(params.data.estimated_delivered_date !== "" && params.data.estimated_delivered_date !== undefined)
    //                 {
    //                     return getHyphenDDMMMYYYYHHMM(params.data.estimated_delivered_date);
    //                 }
    //                 else{
    //                     return "";
    //                 }
    //             } catch (error) {
                    
    //             }
    //         }
    //     },  
    //     {
    //         headerName:"Pickup Location",
    //         field:"Pickup Location",
    //         width:160,
    //     }, 
    //     {
    //         headerName:"Incoterms",
    //         field:"Incoterms",
    //         width:160,
    //     },
    // ]
    var pendingColumnDefs = [
        {
            field:'',
            headerName:'',
            colId:"_id",
            width: 72,
            hide: true
        },
        // {
        //     headerName: "",
        //     field: "",
        //     width:100,
        //     filter: true,
        //     resizable: true,
        //     cellRenderer: function(params) {
        //         var htmloption = '<button class="btn btn-primary" style="padding:0 9px">Reject</button>';
        //         return htmloption;
        //     }        
        // },
        {
            headerName: "Work Order No",
            field: "booking_no",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "CHA Entity",
            field: "cha_entity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Destination",
            field: "destination_country",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Item Quantity",
            field: "item_quantity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Size",
            field: "container_size",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Quantity",
            field: "container_quantity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Plant Code",
            field: "plant_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Destination Port",
            field: "destination_port",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Buyer Code",
            field: "buyer_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Type",
            field: "container_type",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Planned Date",
            field: "stuffing_planned_date",
            width:130,
            filter: true,
            resizable: true,      
            valueGetter:function(params){
                //console.log(params);
                if(params.data.stime != ""){
                  return getHyphenDDMMMYYYY(params.data.stuffing_planned_date);
                }
                else{
                    return "";
                }   
           }, 
        },
        {
            headerName: "Loading Port",
            field: "loading_port",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Gate Open Date",
            field: "gate_open_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter:function(params){
                //console.log(params);
                if(params.data.stime != ""){
                  return getHyphenDDMMMYYYY(params.data.gate_open_date);
                }
                else{
                    return "";
                }   
           },           
        },
        {
            headerName: "SL Cutoff Date",
            field: "sl_cutoff_date",
            width:130,
            filter: true,
            resizable: true,  
            valueGetter:function(params){
                //console.log(params);
                if(params.data.stime != ""){
                  return getHyphenDDMMMYYYY(params.data.sl_cutoff_date);
                }
                else{
                    return "";
                }   
           },         
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Vessel ETD",
            field: "vessel_etd",
            width:130,
            filter: true,
            resizable: true,      
            valueGetter:function(params){
                //console.log(params);
                if(params.data.stime != ""){
                  return getHyphenDDMMMYYYY(params.data.vessel_etd);
                }
                else{
                    return "";
                }   
           },     
        },
        {
            headerName: "Date",
            field: "created_at",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params) {
                try {
                    if(params.data.created_at !== "" && params.data.created_at !== undefined) {
                        return moment.parseZone(params.data.created_at).format("DD-MM-YYYY")
                    }
                    else {
                        return "";
                    }
                } catch (error) {
                    // Handle error if needed
                }
            }          
        }
    ];

    var inProcessColumnDefs = [
        {
            headerName: "Legs",
            field: "_id",
            width: 70,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>{ 
                // var html = '<a><i class="icofont icofont-rounded-expand"></i></a>'
                return <a 
                style={{cursor:"pointer", fontSize:"20px", fontWeight: "bold" }}
                onClick={this.onShowTicketHistory.bind(this, params)}><i className="icofont icofont-rounded-expand"></i></a>
            }
        },        
        {
            headerName: "DO File",
            field: "",
            width:130,
            filter: true,
            resizable: true,
            cellRendererFramework: (params)=>{
                return <button className='btn btn-primary'
                    style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                    onClick={this.previewDocument.bind(this, params.data.do_file_url)}>View</button>
            }    
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Transporter Name",
            field: "transporter_assigned",
            width:130,
            filter: true,
            resizable: true,          
        },
    ]

    var closureColumnDefs = [
        {
            headerName: "BL File",
            field: "",
            width:130,
            filter: true,
            resizable: true,
            cellRendererFramework: (params) => {
                return <button className='btn btn-primary'
                    style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                    onClick={this.previewDocument.bind(this, params.data.bl_file_url)}>View</button>
            }          
        }
    ]

    if(this.state.pendingbtn === "btn-active")
    {
        var gridColumnDefs=pendingColumnDefs;
    }else if(this.state.inprocessbtn === "btn-active")
    {
        pendingColumnDefs.unshift(inProcessColumnDefs[0])
        var gridColumnDefs=[...pendingColumnDefs, ...inProcessColumnDefs.slice(1)];
    } else {
        pendingColumnDefs.unshift(inProcessColumnDefs[0])
        // inProcessColumnDefs.slice(1);
        closureColumnDefs.unshift(inProcessColumnDefs[1]);
        var gridColumnDefs=[...pendingColumnDefs, ...inProcessColumnDefs.slice(2), ...closureColumnDefs ];
    }
        // else if (this.state.chaupdatesbtn === "btn-active")
        // {
        //     var gridColumnDefs = columnsInClosure
        // }else if ( this.state.tranupdatesbtn === "btn-active"){
        //     var gridColumnDefs = columnsInClosure
        // }else if (this.state.closurebtn === "btn-active"){
        //     var gridColumnDefs = columnsInClosure
        // }
    return (
        <div class="container-fluid">          
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <Modal open={this.state.openModal} onClose={this.closeModal}>
                <div aria-disabled={true}>
                    <h6
                    className="bg-primary text-white"
                    style={{
                        paddingTop: "10px",
                        height: "38px",
                        paddingLeft: "253px",
                    }}
                    >
                    File Preview
                    </h6>
                    {this.state.documentType === "pdf" ? (
                    <div className="p-3">
                        <iframe
                        src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                        frameborder="1"
                        height="500px"
                        width="100%"
                        ></iframe>
                    </div>
                    ) : (
                    <></>
                    )}

                    {this.state.documentType === "csv" ||
                    this.state.documentType === "xlsx" ? (
                    <div className="p-3">
                        <iframe
                        src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                        frameborder="1"
                        height="500px"
                        width="100%"
                        ></iframe>
                    </div>
                    ) : (
                    <></>
                    )}

                    {this.state.documentType === "png" ||
                    this.state.documentType === "jpeg" ||
                    this.state.documentType === "jpg" ? (
                    <div className="p-3">
                        <img
                        src={this.state.documentUrl}
                        // frameborder="1"
                        height="500px"
                        width="100%"
                        ></img>
                    </div>
                    ) : (
                    <></>
                    )}
                </div>
                </Modal>
            <div className="row">
            <div className="row col-xl-12 col-lg-12">
                <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
					<div className="form-group col-xl-3 col-lg-3" style={{borderRight:"1px solid #aaa"}}>
						<label>Select Destination Country: </label>
						<Select 
                            placeholder={"Destination"} 
                            closeMenuOnSelect={true} 
                            value={this.state.country} 
                            // multi={true}
                            className={"border-radius-0"} 
                            onChange={this.newFilter.bind(this, "country")} 
                            style={{borderRadius:"0px"}} 
                            styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  fontSize: "0.8rem",
                                  color: "#6e707e",
                                }),
                            }}
                            options={this.state.countryOptions} 
                        />
                                       
					</div>
					{/* <div className="form-group col-xl-2 col-lg-2">
						<label>Select Criteria: </label>
						<Select 
                            placeholder={"Criteria"} 
                            closeMenuOnSelect={true} 
                            value={this.state.criteria} 
                            className={"border-radius-0"} 
                            onChange={this.getCriteriaListItems.bind(this)} 
                            style={{borderRadius:"0px"}}
                            styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  fontSize: "0.8rem",
                                  color: "#6e707e",
                                }),
                            }}
                            options={this.selectCriteriaOptionsItems()} 
                        />
                                       
					</div> */}
					<div className="col-xl-2 col-lg-2 form-group">
						<label>From Date</label>
						<Datetime 
                            placeholder="Date" 
                            name="startDate"
                            value={this.state.startDate}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            onChange={this.handlerStartDateTime.bind(this)}
                            required />
					</div>
					<div className="col-xl-2 col-lg-2 form-group">
						<label>To Date</label>
						<Datetime 
                            placeholder="Date" 
                            name="to_date" 
                            value={this.state.endDate}  
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            onChange={this.handlerEndDateTime.bind(this)}
                            required />
					</div>
					<div className="form-group d-flex justify-content-center align-items-center mt-2 pl-0 mb-0">
						<button type="button" className="btn btn-success mr-2" style={{ fontSize: "0.8rem" }} onClick={this.onClickGetData.bind(this)}>Submit</button>
						{/* <a href="/seaimports" style={{ fontSize: "0.8rem" }} className="btn btn-primary mr-2">Reset</a> */}
                        <button type="button" className='btn btn-warning' style={{ fontSize: "0.8rem" }} onClick={this.handleOrderBulkUploadClick.bind(this)}>Bulk Upload</button>
					</div>
					</form>
				</div>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body n-p-0">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d d-flex flex-column justify-content-center align-items-center">
                                        <span className="f13">Total</span>
                                        <span className='f12'>(Open + Closure)</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.allCnt)?this.state.allCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d d-flex flex-column justify-content-center align-items-center">
                                        <span className="f13">Open</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.openCnt)?this.state.openCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d d-flex flex-column justify-content-center align-items-center">
                                        <span className="f13">Pending For DO</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.doPendingCnt)?this.state.doPendingCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    {/* <div className="col cirlce-d cpointer d-flex flex-column justify-content-center align-items-center" onClick={this.onClickTab.bind(this,"pending")}>
                                        <span className="f13">Pending For DO</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.pendingCnt)?this.state.pendingCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div> */}

                                    <div className="col cirlce-d cpointer d-flex flex-column justify-content-center align-items-center" onClick={this.onClickTab.bind(this,"ffupdates")}>
                                        <span className="f13">Pending For Transporter Allocation</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.pendingTranCnt)?this.state.pendingTranCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d cpointer d-flex flex-column justify-content-center align-items-center" onClick={this.onClickTab.bind(this,"ffupdates")}>
                                        <span className="f13">Pending For Dispatch</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.invCnt)?this.state.invCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d cpointer d-flex flex-column justify-content-center align-items-center" onClick={this.onClickTab.bind(this,"chaupdates")}>
                                        <span className="f13">Pending For BL</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.blPendingCnt)?this.state.blPendingCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    {/* <div className="col cirlce-d cpointer d-flex flex-column justify-content-center align-items-center" onClick={this.onClickTab.bind(this,"tranupdates")}>
                                        <span className="f13">Pending For Transportation Updates</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.transCnt)?this.state.transCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div> */}

                                    <div className="col cirlce-d cpointer d-flex flex-column justify-content-center align-items-center" onClick={this.onClickTab.bind(this,"closure")}>
                                        <span className="f13">Closures</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.closureCnt)?this.state.closureCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                </div>                                
                            </div>
                        
                        </div>
                    </div>
                </div>
           
            </div>
            <div className="row">					
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                            <i class="icofont icofont-ship cus-i"></i> <span>Bookings </span>
                                <button className="btn btn-danger d-none" style={{float:"right",marginRight:"10px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                            </h5>
                        </div>
                        
                        <div className="card-body">
                            <div className="f16" style={{marginBottom:"10px"}}>
                                <ul className="d-tabs">

                                    {/* <li onClick={this.onClickTab.bind(this,"all")} className={"lttabs "+(this.state.alldetails)}>
                                        <button type="button" className="btn">ALL</button>
                                    </li> */}

                                    {/* <li onClick={this.onClickTab.bind(this,"pending")} className={"lttabs "+(this.state.pending)}>
                                        <button type="button" className="btn">Pending for Assignment</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"ffupdates")} className={"lttabs "+(this.state.ffupdates)}>
                                        <button type="button" className="btn">Pending for FF</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"chaupdates")} className={"lttabs "+(this.state.chaupdates)}>
                                        <button type="button" className="btn">Pending for CHA</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"tranupdates")} className={"lttabs "+(this.state.tranupdates)}>
                                        <button type="button" className="btn">Pending for Transportation</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"closure")} className={"lttabs "+(this.state.closure)}>
                                        <button type="button" className="btn">Closure</button>
                                    </li> */}

                                    {/* <li onClick={this.onClickTab.bind(this,1)} className={"lttabs "+(this.state.pendingbtn)}>
                                        <button type="button" className="btn">Pending for Assignment</button>
                                    </li> */}

                                    {/* <li onClick={this.onClickTab.bind(this,2)} className={"lttabs "+(this.state.ffupdatesbtn)}>
                                        <button type="button" className="btn">Pending for FF</button>
                                    </li> */}

                                    {/* <li onClick={this.onClickTab.bind(this,3)} className={"lttabs "+(this.state.chaupdatesbtn)}>
                                        <button type="button" className="btn">Pending for CHA</button>
                                    </li> */}

                                    {/* <li onClick={this.onClickTab.bind(this,4)} className={"lttabs "+(this.state.tranupdatesbtn)}>
                                        <button type="button" className="btn">Pending for Transportation</button>
                                    </li> */}

                                    <li onClick={this.onClickTab.bind(this,1)} className={"lttabs "+(this.state.pendingbtn)}>
                                        <button type="button" className="btn">Pending</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,2)} className={"lttabs "+(this.state.inprocessbtn)}>
                                        <button type="button" className="btn">In Process</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,3)} className={"lttabs "+(this.state.closurebtn)}>
                                        <button type="button" className="btn">Closures</button>
                                    </li>

                                    {/* <li onClick={this.onClickTab.bind(this,"preclosure")} className={"lttabs "+(this.state.preclosure)}>
                                        <button type="button" className="btn">Closures Pending for LSP</button>
                                    </li> */}
                                </ul>
                            </div>
                            {/* <div className={this.state.pendingData}>
                                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={airCargoDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        onCellClicked={this.viewDetails.bind(this)}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                        />
                                </div>
                            </div> */}
                            <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={gridColumnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    masterDetail={true}
                                    suppressRowClickSelection={true}
                                    // components={this.state.components}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    enableRangeSelection= {true}
                                    onCellClicked={this.viewDetails.bind(this)}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                            </div>
                            
                            {/* <div className={"ag-theme-balham"+this.state.ffData}>
                                {(this.state.ffData == "show-m") ?
                                    <FFSeaImportsgridData ffRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.chaData}>
                                {(this.state.chaData == "show-m") ?
                                    <ChaSeaImportsgridData chaRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.tranData}>
                                {(this.state.tranData == "show-m") ?
                                    <TransSeaImportsgridData transRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.closureData}>
                                {(this.state.closureData == "show-m") ?
                                    <ClosureSeaImportsgridData closureRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.preclosureData}>
                                {(this.state.preclosureData == "show-m") ?
                                    <PreclosuregridData ffRowData={this.state.rowData}/>
                                : ""}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* slider area */}
            <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
            <div className="slide-r-title">
                <h4>
                Bulk Upload
                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

                </h4>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                <div className="theme-form col-xl-12 col-lg-12">

                    <form action="" onSubmit={this.onSubmitOrderBulkUpload.bind(this)}>
                    <div className="col-xl-12 col-lg-12 row form-group">
                        <label className="col-xl-12 col-lg-12">Upload File *</label>
                        <input
                        type="file"
                        name="uploadfile"
                        id="uploadfile"
                        className="form-control"
                        onChange={this.changeOrderFileHandlerFc.bind(this)}
                        required
                        />
                    </div>

                    <div className="col-xl-12 col-lg-12 form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                    </form>
                    <div className="col-xl-12 col-lg-12 form-group">
                    <a href={require("../../assets/json/Sample_export_booking_data.xlsx")} target="_blank" className="btn btn-warning">Download Template</a>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                <h3 className="subH">
                    {this.state.sliderTitle}
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                </h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                    {(this.state.erpData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>ERP PO No</th>
                                                <th>ERP PO Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.erpData.map((e, index) => (
                                            <tr>
                                                <td>{e.erpPoNo}</td>
                                                <td>{e.erPoDate}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.ringiData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Ringi No</th>
                                                <th>Ringi (File)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ringiData.map((e, index) => (
                                            <tr>
                                                <td>{e.ringi_desc}</td>
                                                <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.ringi_file)}>{e.ringi_file}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.cargoData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Item Description</th>
                                                <th>Cargo Gross Weight</th>
                                                <th>Dimensions (cms)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.cargoData.map((e, index) => (
                                            <tr>
                                                <td>{e.item_description}</td>
                                                <td>{e.cargo_gross_weight}</td>
                                                <td>{e.dimensions}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.rejectedreason == 1) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <form method="POST" id="rejectform" className="theme-form" onSubmit={this.rejectCargoRequest}>
                                        <div className="form-group mt-20p">
                                            <label className="">Reason</label> 
                                            <textarea name="reject_reason" id="reject_reason" className="form-control" required></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Reject</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.reqContainersData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Size Of Container required</th>
                                                <th>Type Of Container</th>
                                                <th>Shipment Type</th>
                                                <th>Handling Type</th>
                                                <th>Spl Handling Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reqContainersData.map((e, index) => (
                                            <tr>
                                                <td>{e.containerSize}</td>
                                                <td>{e.containerType}</td>
                                                <td>{e.shipmentIs}</td>
                                                <td>{e.handlingType}</td>
                                                <td>{e.splHandling}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                </div>
            </div>
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
export default withRouter(SeaImportsListComponent);