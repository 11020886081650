import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import redirectURL from "../redirectURL";
var infoBox = require('../common/google-infowindow');
// var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var doOnce = true;
var rad = function (x) {
    return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;
var consigners = [];
export default class TruckWithGeofenceComponent extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pageTitle: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            radius: '15',
            rlatitude: '',
            rlongitude: '',
            latitude: '',
            longitude: '',
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true,
                menuTabs: ['filterMenuTab']
            },
            rowData: [],
            originalrowData: [],
            plant_coordinates_data:[],
            selectedTabLat: '',
            selectedTabLng: '',
            deftab: 'all',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,

            plant_code: '',
            showPlant: 'show-n',
            selectTitle:'Plant',
            showAddr: 'show-n',
            request_type: '',
            view_type: '',
            tabplants: [],
            clickabletab: '',
            plantlist: [],
            stateFormData: "",
            sliderStateEmailsTranslate: "",
            overly: "show-n",
            showStateTrucksMsg: "show-n",
            states_list: [],
            selectedState: [],
            selectedType:[],
            showStates: "show-n",
            showRadius: "show-m",
            usermanualmodal: false,
            allplants: [],
            plantlist1:[],
            typeList1:[],
            pickuplist:[],
            typeList:[
                {'label':'Plant','value':'plant'},
                {'label':'Container Pickup Yard','value':'container_pickup_yard'},
                {'label':'Parking Plaza','value':'parking_plaza'},
                {'label':'Buffer Yard Plaza', 'value':'buffer_yard_plaza'}
                // {'label':'Buffer Yard','value':'buffer_yard'},
                // {'label':'Port Gate','value':'port_gate'}
            ],
            selectDropdown:true
        };
        this.popmarker = this.popmarker.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false,
            loadshow:'show-n'
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        //console.log("path ", this.props.match.path)
        // var upath = props.match.path.split("/")
        // console.log("upath ", upath)

        this.renderMapDefault();


        var hText = 'All';


        this.logPageView(); // Log page view to GA
        redirectURL.post("/vicinity/getDistinctStates").then((response) => {
            // console.log(response.data, "response")
            this.setState({
                states_list: response.data,
                overly: "show-n",
                loadshow: "show-n"
            });
        }).catch((e) => {
            // console.log(e)
        })

        redirectURL.post("/consignments/allplants",).then(async (response) => {
            let responseData = response.data
            // console.log("responseData",responseData)
            if (responseData.length > 0) {
                responseData = await distinctArrayBy(responseData, "plant_code");
                this.setState({
                    allplants: responseData
                })
            }
        })
        redirectURL.post("/consignments/getplantdata").then((resp) => {
            try {
                var data = resp.data
                // console.log(data,'64')
                var plant = [{ label: 'All', value: 'ALL' }]
                data.map((e) => {
                    plant.push({ label: e.plant_code, value: e.plant_code })
                })
                plant = removeDuplicateObjects(plant);
                this.setState({
                    plantlist1:plant,
                    plant_code:plant
                })
            }
            catch (e) {

            }
        })
        redirectURL.post("/consignments/getcontainerpickupdata").then((resp) => {
            try {
                var data = resp.data
                // console.log(data,'64')
                var pickuplist = [{ label: 'All', value: 'ALL' }]
                data.map((e) => {
                    pickuplist.push({ label: e.pickup_yard_name, value: e.pickup_yard_name })
                })
                // console.log(pickuplist,'pickuplistpickuplist')
                pickuplist = removeDuplicateObjects(pickuplist);
                this.setState({
                    pickuplist:pickuplist,
                    // plant_code:pickuplist
                })
            }
            catch (e) {

            }
        })

    }
    renderMapDefault = () => {

        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMapDefault");
        window.initMapDefault = this.initMapDefault
    }
    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }
    initMapDefault = () => {
		
		var lt=21.141058047268885;
		var ln=72.65782317936426;
		
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
		});

		var input = document.getElementById('search');
		var searchBox = new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function() {

		  searchBox.setBounds(map.getBounds());
		});
		
		var markers = [];
		//console.log("searchBox",searchBox);
		searchBox.addListener('places_changed', function() 
		{
		  var places = searchBox.getPlaces();
			
		  if (places.length == 0) {
			return;
		  }

		  // Clear out the old markers.
		  markers.forEach(function(marker) {
			//   console.log("Search Marker ", marker)
			marker.setMap(null);
		  });
		  markers = [];
			// For each place, get the icon, name and location.
		  var bounds = new window.google.maps.LatLngBounds();
		  places.forEach(function(place) {
			if (!place.geometry) {
			//   console.log("Returned place contains no geometry");
			  return;
			}
			var icon = {
			  url: place.icon,
			  size: new window.google.maps.Size(71, 71),
			  origin: new window.google.maps.Point(0, 0),
			  anchor: new window.google.maps.Point(17, 34),
			  scaledSize: new window.google.maps.Size(25, 25)
			};

			// Create a marker for each place.
			//console.log("Plae Lat", place.geometry.location.lat());
			document.getElementById("latitude").value =place.geometry.location.lat();

			document.getElementById("longitude").value =place.geometry.location.lng();
			
			markers.push(new window.google.maps.Marker({
			  map: map,
			  icon: icon,
			  title: place.name,
			  position: place.geometry.location
			}));

			if (place.geometry.viewport) {
			  // Only geocodes have viewport.
			  bounds.union(place.geometry.viewport);
			} else {
			  bounds.extend(place.geometry.location);
			}
		  });
		  
		  map.fitBounds(bounds);
		});
		
		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			document.getElementById("latitude").value = event.latLng.lat();
			document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		
		
		
		var eventtrigger = document.getElementsByClassName('truck');

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		 
	}

    toggleBounce() {
        if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
        } else {
            marker.setAnimation(window.google.maps.Animation.BOUNCE);
        }

    }

    changeInputHandler = (event) => {
        this.setState({
            selectedType:event
        })
        // this.setState({ [name]: value, selectedType:value });
        // console.log(this.state.selectedType, "selectedType")
    }
    
    changePlantInputHandler = (selectedType) => {
        this.setState({
            selectedType},()=>{console.log("Plant",this.state.selectedType)})
        // this.setState({ [name]: value, selectedType:value });
        // console.log(this.state.selectedType, "selectedType")
    }

    changeRadiusHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
        circle.setRadius(value);
    }
    onChangePlantCode = async plant_code => {
        //console.log("Select", event.target.value);
        await this.setState(
            { plant_code },
            () => console.log(`Option selected:`, this.state.plant_code)
        );

    }
    onChangeViewType = (event) => {
        // console.log('event',event)
        let value = event.value;
        if (value == 'plant') {
            this.setState({
                selectedType:[],
                showPlant: "show-m",
                showAddr: "show-n",
                showStateTrucksMsg: "show-n",
                radius: 15,
                showStates: "show-n",
                showRadius: "show-m",
                selectTitle:'Plant',
                typeList1:this.state.plantlist1,
                selectDropdown:true
            });
        }
        else if (value == 'container_pickup_yard') {
            this.setState({
                selectedType:[],
                showPlant: "show-n",
                showAddr: "show-n",
                showStateTrucksMsg: "show-n",
                radius: 15,
                showStates: "show-n",
                showRadius: "show-m",
                selectTitle:'Pickup Yard',
                typeList1:this.state.pickuplist,
                selectDropdown:true
            });
        }
        else if (value == "parking_plaza") {
            this.setState({
                selectedType:[],
                showPlant: "show-n",
                showAddr: "show-n",
                showStateTrucksMsg: "show-m",
                radius: 15,
                showStates: "show-n",
                showRadius: "show-m",
                selectTitle:'Parking Plaza',
                selectDropdown:false
            });
        }else if(value == "buffer_yard_plaza") {
            this.setState({
                selectedType:[],
                showPlant: "show-n",
                showAddr: "show-n",
                showStateTrucksMsg: "show-n",
                radius: 15,
                showStates: "show-n",
                showRadius: "show-m",
                selectTitle:'Buffer Yard Plaza',
                selectDropdown:false
            })
        }
        // else if (value == "buffer_yard") {

        //     this.setState({
        //         showPlant: "show-n",
        //         showAddr: "show-n",
        //         showStateTrucksMsg: "show-n",
        //         showStates: "show-m",
        //         radius: 15,
        //         showRadius: "show-n"

        //     });

        // }
        // else if (value == "port_gate") {

        //     this.setState({
        //         showPlant: "show-n",
        //         showAddr: "show-n",
        //         showStateTrucksMsg: "show-n",
        //         showStates: "show-m",
        //         radius: 15,
        //         showRadius: "show-n"

        //     });

        // } 
        else { }
        this.setState({
            selectedState:event
        });
    }


    selectStateOptions() {
        var states = this.state.states_list;
        var items = [];
        states.map(function (e) {
            if (e != "" && e != "NA" && e != "Chukha District" && e != "Dadra and Nagar Haveli and Daman and Diu" && e != "Kachchh") {
                items.push({ "label": e, "value": e })
            }

        });
        return items;
    }
    onSelectState(states) {
        // console.log(states, "states103")
        try {
            this.setState({
                selectedState: states
            })
        } catch (e) { }
    }
    selectOptionsItems() {
        let items = [];
        var consigners = this.state.allplants;

        //console.log("consigners ", consigners)
        // items.push({value:'all',label:'All'});

        try {
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined) {
                items.push({ value: localStorage.getItem("pc"), label: localStorage.getItem("pc") });
            }
            else {
                if (consigners.length > 0) {
                    consigners.forEach(item => {
                        items.push({ value: item.plant_code, label: item.plant_name });
                    });
                }
            }
        } catch (e) {

        }



        return items;
    }

    submitFormHandler(formdata) {
        rlatitude = document.getElementById("latitude").value;
        rlongitude = document.getElementById("longitude").value;
        var urlpath = '/vicinity/plantRadiusCoordinates';
        redirectURL.post(urlpath, formdata)
            .then(
                async (response) => {
                    // console.log("response", response);
                    if (response.data == "success") {
                        $("#email_ids").val("");
                        this.setState({
                            sliderStateEmailsTranslate: "",
                            show: true,
                            basicTitle: "Reports will be sent to provided emails",
                            basicType: "success",
                            overly: "show-n"
                        })
                    }
                    else {
                        var tabplants = [];
                        if (response.data.cluster_centriods.length > 0) {
                            // console.log("consigners ", this.state.plant_code)
                            response.data.cluster_centriods.forEach(item => {
                                // var title = consigners.filter(codes =>{
                                // 	console.log("codes ",codes)
                                // 	if(codes.code == item.plant_code)
                                // 	{
                                // 		return codes.value;
                                // 	} 
                                // });
                                item.label = this.state.plant_code[0].value;
                                tabplants.push(item);
                            })
                        }
                        var trucks_data = JSON.parse(response.data.trucks_data);
                        //allcords=[];
                        var allmarkers = response.data.trucks_data;
                        // console.log("allmarkers ", trucks_data);
                        var dCode = this.state.dept_code;
                        if (trucks_data.length > 0) {
                            //console.log("UPpcase Dept ", dCode);
                            var markers = [];
                            var mainlat = '';
                            var mainlng = '';
                            var maincoords = [];
                            // await allmarkers.forEach(item => {
                            // 	if(item.dept_code == dCode.toUpperCase())
                            // 	{
                            //         markers.push(item);
                            //        // maincoords.push(item.cluster_center_coordinates);
                            //        //console.log("item ",item.cluster_center_coordinates)
                            // 	}
                            // });
                            //console.log("Markers ", markers);
                            //console.log("maincoords ", JSON.parse(maincoords[0]));

                            //console.log("rlatitude ", rlatitude);
                            var mlat = '';
                            var mlng = '';
                            // if(maincoords.length > 0)
                            // {
                            //     var mdata = JSON.parse(maincoords[0]);

                            //     if(rlatitude == '' && rlongitude == '')
                            //     {
                            //         mlat=mdata.lat;
                            //         mlng=mdata.lng;
                            //     }
                            //     else
                            //     {
                            //         mlat=formdata.latitude;
                            //         mlng=formdata.longitude;

                            //     }
                            // }
                            // console.log(tabplants,"tabplants");
                            this.setState({
                                defaultradius: formdata.radius,
                                coordinates: trucks_data,
                                mwidth: "col-xl-7 col-lg-7",
                                rowData: trucks_data,
                                originalrowData: trucks_data,
                                radius: formdata.radius,
                                loadshow: 'show-n',
                                showform: 'show-n',
                                showrefresh: 'show-m',
                                rlatitude: mlat,
                                rlongitude: mlng,
                                tabplants: tabplants,
                            });


                            var coords = markers;
                            /*oms = new oms.OverlappingMarkerSpiderfier(map, {
                                markersWontMove: true,
                                markersWontHide: true,
                                keepSpiderfied: true,
                                basicFormatEvents: true
                            });*/
                            var p1 = { lat: parseFloat(formdata.latitude), lng: parseFloat(formdata.longitude) };
                            // await markers.map((marker) => {
                            // 	//console.log("marker", marker);
                            // 	var p2 = {lat:marker.latitude, lng:marker.longitude};
                            // 	//console.log("Distace is ", this.getDistance(p1,p2));
                            // 	//console.log(marker.distance +"<= "+ response.data.radius)
                            // 	var distance =  this.getDistance(p1,p2);
                            // 	//console.log(window.google.maps.geometry.spherical.computeDistanceBetween (p1, p2))
                            // 	if (distance <= formdata.radius)
                            // 	{
                            // 		allcords.push(marker);
                            // 	}

                            // });
                            // console.log("allcords.length ", allcords)

                            window.initMap = await this.initMap
                            await this.renderMap();
                        }
                        else {
                            this.setState({
                                show: true, basicType: 'warning', basicTitle: "No Data found.",
                                loadshow: 'show-n',
                                showform: 'show-m',
                            });

                        }
                    }

                })
            .catch(function (error) {
                // console.log(error);
            });
    }
    submitMailForm = (event) => {
        event.preventDefault();
        var emails = document.getElementById("email_ids").value;
        // console.log(emails);
        try {
            if (emails.includes(",")) {
                emails = emails.replace(/\s/g, "");
                emails = emails.split(",");
            }
            else {

                emails = [emails]
            }
            var formdata = this.state.stateFormData;
            formdata['emails'] = JSON.stringify(emails);
            if ($("#gps_record_flag").prop("checked")) {
                formdata['gps_records_flag'] = 1;
            }
            else {
                formdata['gps_records_flag'] = 0;
            }
            this.submitFormHandler(formdata);
        }
        catch (e) {
            // console.log(e)
        }
    }
    formHandler=(event)=>{
        event.preventDefault();
        this.setState({loadshow:'show-m', overly:'show-m'})
        var pCode = this.state.selectedType 
        // console.log('this.state.selectedType  ',this.state.selectedType )
        var plantCode = "";
        if(pCode.value!='' && pCode.value!=undefined){
             plantCode = pCode.value 
        }
        var radius = this.state.radius 
        var params = {
            'radius':radius
        }
        if(this.state.selectTitle=='Plant'){
            params.plant_code = plantCode
        }
        else if(this.state.selectTitle == 'Pickup Yard'){
            params.pickup_yard = plantCode
        }
        else if(this.state.selectTitle == 'Parking Plaza'){
            params.parking_plaza_flag = 1 
        }
        else if(this.state.selectTitle == 'Buffer Yard Plaza'){
            params.buffer_flag = 1
        }
        if(this.state.selectTitle!='Parking Plaza' && this.state.selectTitle!='Buffer Yard Plaza'){
            if(this.state.selectedType!='' && this.state.selectedType!=undefined && this.state.selectedState!='' && this.state.selectedState!=undefined){
                redirectURL.post('/consignments/gettruckwithgeofence',params).then((response)=>{
                    if (response.data.status=="Failure"){
                        // console.log("response88", response.data.status)
                        this.setState({
                            basicTitle:response.data.message,
                            basicType:"danger",
                            overly:"show-n",
                            loadshow:'show-n',
                            show:true,
                            })
                    }
                    else{
                        var trucks_data = JSON.parse(response.data.trucks_data);
                        // console.log(trucks_data,'trucks_data')
                        var plantCoords = response.data.coordinates
                        if(plantCoords!='' && plantCoords!=undefined){
                            plantCoords = JSON.parse(plantCoords)
                        }
                        // console.log(response.data.plant_coordinates,'plant_coordinates')
                        var allmarkers=response.data.trucks_data;
                        var dCode = this.state.dept_code;
                        if(trucks_data.length > 0)
                        {
                            var markers = [];
                            var mainlat='';
                            var mainlng='';
                            var maincoords=[];
                            var mlat='';
                            var mlng='';
                            this.setState({
                                defaultradius:radius,
                                coordinates:trucks_data,
                                mwidth:"col-xl-7 col-lg-7",
                                rowData:trucks_data,
                                originalrowData:trucks_data,
                                plant_coordinates_data:plantCoords,
                                radius:radius,
                                loadshow:'show-n',
                                overly:'show-n',
                                showform:'show-n',
                                showrefresh:'show-m',
                                rlatitude:mlat, 
                                rlongitude:mlng,
                            });
                            var coords = markers;
                            window.initMap =  this.initMap
                             this.renderMap();
                        }
                        else{
                            this.setState({
                                show: true, basicType:'warning', basicTitle:"No Data found.",
                                loadshow:'show-n',
                                showform:'show-m',
                            });
                        }
                        // console.log("response88", response.data.status)
                    }
                })
            }
            else{
                this.setState({
                    basicTitle:'Please Select All Fields',
                    basicType:"danger",
                    overly:"show-n",
                    loadshow:'show-n',
                    show:true,
                    })
            }
        }
        else {
            // console.log(this.state.selectTitle,'798')
            if(this.state.selectTitle!='' && this.state.selectTitle!=undefined){
                redirectURL.post('/consignments/gettruckwithgeofence',params).then((response)=>{
                    if (response.data.status=="Failure"){
                        // console.log("response88", response.data.status)
                        this.setState({
                            basicTitle:response.data.message,
                            basicType:"danger",
                            overly:"show-n",
                            loadshow:'show-n',
                            show:true,
                            })
                    }
                    else{
                        var trucks_data = JSON.parse(response.data.trucks_data);
                        // console.log(trucks_data,'trucks_data')
                        var plantCoords = response.data.coordinates
                        if(plantCoords!='' && plantCoords!=undefined){
                            plantCoords = JSON.parse(plantCoords)
                        }
                        // console.log(response.data.plant_coordinates,'plant_coordinates')
                        var allmarkers=response.data.trucks_data;
                        var dCode = this.state.dept_code;
                        if(trucks_data.length > 0)
                        {
                            var markers = [];
                            var mainlat='';
                            var mainlng='';
                            var maincoords=[];
                            var mlat='';
                            var mlng='';
                            this.setState({
                                defaultradius:radius,
                                coordinates:trucks_data,
                                mwidth:"col-xl-7 col-lg-7",
                                rowData:trucks_data,
                                originalrowData:trucks_data,
                                plant_coordinates_data:plantCoords,
                                radius:radius,
                                loadshow:'show-n',
                                overly:'show-n',
                                showform:'show-n',
                                showrefresh:'show-m',
                                rlatitude:mlat, 
                                rlongitude:mlng,
                            });
                            var coords = markers;
                            window.initMap =  this.initMap
                             this.renderMap();
                        }
                        else{
                            this.setState({
                                show: true, basicType:'warning', basicTitle:"No Data found.",
                                loadshow:'show-n',
                                showform:'show-m',
                            });
                        }
                        // console.log("response88", response.data.status)
                    }
                })
            }
            else{
                this.setState({
                    basicTitle:'Please Select All Fields',
                    basicType:"danger",
                    overly:"show-n",
                    loadshow:'show-n',
                    show:true,
                    })
            }
        }
    }
    
    onCloseRouteDiv() {
        $("#email_ids").val("");
        this.setState({
            overly: "show-n",
            sliderStateEmailsTranslate: ""
        })
    }

    initMap = () => {
        try {
            var currentwindow;
            if (this.state.selectedTabLat != '' && this.state.selectedTabLng != '') {
                var lt = this.state.selectedTabLat;
                var ln = this.state.selectedTabLng;
            }
            else {
                var lt = this.state.rlatitude;
                var ln = this.state.rlongitude;
            }
            map = new window.google.maps.Map(document.getElementById('map_canvas'), {
                zoom: 10,
                center: new window.google.maps.LatLng(lt, ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                /*mapTypeControl: true,
                gestureHandling: 'greedy',
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },            
                disableDefaultUI: true,
                zoomControl: true*/
            });

            var input = document.getElementById('search');
            var searchBox = new window.google.maps.places.SearchBox(input);
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', function () {

                searchBox.setBounds(map.getBounds());
            });

            markers = this.state.rowData;
            /*SEARCH START*/
            //console.log("searchBox",searchBox);
            searchBox.addListener('places_changed', function () {
                var places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                // Clear out the old markers.
                markers.forEach(function (marker) {
                    // console.log("Search Marker ", marker)
                    marker.setMap(null);
                });
                markers = [];
                // For each place, get the icon, name and location.
                var bounds = new window.google.maps.LatLngBounds();
                places.forEach(function (place) {
                    if (!place.geometry) {
                        // console.log("Returned place contains no geometry");
                        return;
                    }
                    var icon = {
                        url: place.icon,
                        size: new window.google.maps.Size(71, 71),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(25, 25)
                    };

                    // Create a marker for each place.
                    //console.log("Plae Lat", place.geometry.location.lat());
                    document.getElementById("latitude").value = place.geometry.location.lat();

                    document.getElementById("longitude").value = place.geometry.location.lng();

                    markers.push(new window.google.maps.Marker({
                        map: map,
                        icon: icon,
                        title: place.name,
                        position: place.geometry.location
                    }));

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
            });

            /*SEARCH END*/

            circle = new window.google.maps.Circle({
                map: map
            });
            marker = new window.google.maps.Marker({
                map: map,
                draggable: true,
                animation: window.google.maps.Animation.DROP,
                //position: {lat: 28.48, lng: 77.06}
            });

            marker.addListener('click', function () {
                marker.setAnimation(window.google.maps.Animation.BOUNCE)
            });

            window.google.maps.event.addListener(map, 'click', function (event) {

                document.getElementById("latitude").value = event.latLng.lat();
                document.getElementById("longitude").value = event.latLng.lng();
                marker.setPosition(event.latLng);

            });
            circle.bindTo('center', marker, 'position');
            var markerLatlng1;
            //console.log("Radius ", this.state.radius)
            //console.log("state Lat ",this.state.rlatitude)
            var bounds = new window.google.maps.LatLngBounds();
            var infowindow = new window.google.maps.InfoWindow();
            // console.log(this.state.rlatitude,'874',this.state.rlongitude,'874')
            var rlatitude = this.state.plant_coordinates_data 
            // console.log(rlatitude,'rlatitude')
            rlatitude = rlatitude[0]
                // console.log(rlatitude.latitude,'rlatituderlatitude')
            if (rlatitude.latitude != '' && rlatitude.longitude != '') {
                markerLatlng1 = new window.google.maps.LatLng(rlatitude.latitude, rlatitude.longitude);
                // console.log("markerLatlng", markerLatlng1)
                // console.log(this.state.radius,'this.state.radius')
                circle = new window.google.maps.Circle({
                    strokeColor: '#71caab',
                    strokeOpacity: 0.7,
                    strokeWeight: 1,
                    fillColor: '#71caab',
                    fillOpacity: 0.25,
                    map: map,
                    center: markerLatlng1,
                    radius: Math.sqrt(this.state.radius) * 10000
                });
            }

            //console.log("allcords initmap ", allcords)
            if (doOnce) {
                // initOMS();
                doOnce = false;
            }

            //console.log("allcords ", allcords)
            if (this.state.rowData.length > 0) {
                var markers = this.state.rowData.map((marker) => {
                    //console.log("marker ", marker)
                    if (marker.status == 1) {
                        var truckText = "Inside Plant";
                        var color = "#333333";
                        var image = require('../../assets/icons/truck_inside_24.png');
                    }
                    else if (marker.status == 2) {
                        var truckText = "Going to Destination";
                        var color = "#333333";
                        var image = require('../../assets/icons/truck_going_24.png');
                    }
                    else if (marker.status == 3 || marker.status == 4) {
                        var truckText = "At Destination Location";
                        var color = "#333333";
                        var image = require('../../assets/icons/truck_atdealer_24.png');
                    }
                    else if (marker.status == 5) {
                        var truckText = "Left Destination Location";
                        var color = "#333333";
                        var image = require('../../assets/icons/truck_leftdealer_24.png');
                    }
                    else if (marker.status == 6) {
                        var truckText = "Return to Plant";
                        var color = "#333333";
                        var image = require('../../assets/icons/truck_return_24.png');
                    }
                    else if (marker.status == 7) {
                        var truckText = "Empty Truck Outside Plant";
                        var color = "#333333";
                        var image = require('../../assets/icons/truck_empty_24.png');
                    }
                    else {
                        var truckText = "N/A";
                        var color = "#333333";
                        var image = require('../../assets/icons/truck_na_24.png');
                    }
                    var image = require('../../assets/icons/truck_na_24.png');
                    var contentarr = []
                    var headerTitle = marker.truck_no
                    contentarr.push({ "key": "Truck No", "value": marker.truck_no });
                    contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
                    // contentarr.push({ "key": "Distance from geofence", "value": marker.distance_from_coordinates });
                    //contentarr.push({"key":"LSP Name", "value":marker.lsp_name});
                    // contentarr.push({ "key": "Address", "value": marker.area + ", " + marker.state });
                    var contentString = GoogleInfoWindow(image, headerTitle, contentarr, '')
                    //var contentString = "Here "+marker.truck_no;
                    var infowindow = new window.google.maps.InfoWindow({
                        content: contentString
                    });

                    var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
                    bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));

                    var image = require('../../assets/icons/truck_na_24.png');

                    /*if(marker.overspeeding_exception == 1)
                    {
                        var image =  require('../../assets/icons/truck_overspeed.png');
                    }
                    if(marker.nightdriving_exception == 1)
                    {
                        var image =  require('../../assets/icons/truck-end.png');
                    }*/

                    if (marker.status == 1) {
                        var image = require('../../assets/icons/truck_inside_24.png');
                    }
                    else if (marker.status == 2) {
                        var image = require('../../assets/icons/truck_going_24.png');
                    }
                    else if (marker.status == 3 || marker.status == 4) {
                        var image = require('../../assets/icons/truck_atdealer_24.png');
                    }
                    else if (marker.status == 5) {
                        var image = require('../../assets/icons/truck_leftdealer_24.png');
                    }
                    else if (marker.status == 6) {
                        var image = require('../../assets/icons/truck_return_24.png');
                    }
                    else if (marker.status == 7) {
                        var image = require('../../assets/icons/truck_empty_24.png');
                    }
                    else {
                        var image = require('../../assets/icons/truck_na_24.png');
                    }

                    var image = require('../../assets/icons/truck_na_24.png');
                    var mark = new window.google.maps.Marker({
                        position: markerLatlng,
                        map: map,
                        title: marker.truck_no,
                        icon: image
                    });
                    mark.addListener('click', function () {
                        infowindow.open(map, mark);
                    });
                    mark.setMap(map);
                    return mark
                });
                // var markerCluster = new MarkerClusterer(map, markers,
                // 	{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            }
            var eventtrigger = document.getElementsByClassName('truck');
            // console.log("this.state.selectedTabLat ", this.state.selectedTabLat)

            if (this.state.selectedTabLat != '' && this.state.selectedTabLng != '') {
                //console.log(" this.state.selectedTabLat ",parseFloat(this.state.selectedTabLat.toFixed(6)))
                //console.log(" this.state.selectedTabLng ",parseFloat(this.state.selectedTabLng.toFixed(6)))
                markerLatlng1 = new window.google.maps.LatLng(this.state.selectedTabLat, this.state.selectedTabLng);
                //console.log("markerLatlng", markerLatlng1)
                circle = new window.google.maps.Circle({
                    strokeColor: '#71caab',
                    strokeOpacity: 0.7,
                    strokeWeight: 1,
                    fillColor: '#71caab',
                    fillOpacity: 0.25,
                    map: map,
                    center: markerLatlng1,
                    radius: Math.sqrt(this.state.radius) * 8000
                });
                bounds.extend(new window.google.maps.LatLng(parseFloat(this.state.selectedTabLat), parseFloat(this.state.selectedTabLng)));
                var mainmark = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(parseFloat(this.state.selectedTabLat), parseFloat(this.state.selectedTabLng)),
                    map: map,
                    //title:item.plant_code
                });

                mainmark.setMap(map);
                map.setZoom(5);
                // console.log("mainmark.getPosition() ", mainmark.getPosition())
                //	map.setCenter(mainmark.getPosition());
                //map.setZoom(10)
                //map.fitBounds(bounds)
            }
            else {
                console.log("Here")
                if (this.state.tabplants.length > 0) {
                    this.state.tabplants.forEach(item => {
                        // console.log("Item Marker ", item)
                        markerLatlng1 = new window.google.maps.LatLng(item.lat, item.lng);
                        //console.log("markerLatlng", markerLatlng1)
                        circle = new window.google.maps.Circle({
                            strokeColor: '#71caab',
                            strokeOpacity: 0.7,
                            strokeWeight: 1,
                            fillColor: '#71caab',
                            fillOpacity: 0.25,
                            map: map,
                            center: markerLatlng1,
                            radius: Math.sqrt(this.state.radius) * 8000
                        });
                        bounds.extend(new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)));
                        var mainmark = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)),
                            map: map,
                            title: item.plant_code
                        });
                        mainmark.addListener('click', (function () {
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Plant"
                            contentarr.push({ "key": "Code", "value": item.plant_code })

                            var contentString = GoogleInfoWindow("", header, contentarr, '')

                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);

                        })(marker));
                        mainmark.setMap(map);
                    })
                }
            }
            // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
            //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
            map.fitBounds(bounds)
        }
        catch (e) {
            // console.log(e);
        }



    }

    goBack() {
        window.location.reload();
    }
    getDistance = (p1, p2) => {
        //console.log("p1",p1);
        //console.log("p2",p2);
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat - p1.lat);
        var dLong = rad(p2.lng - p1.lng);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d / 1000; // returns the distance in meter
    };
    onShowInfo = (e) => {
        // console.log(JSON.parse(e.target.id));
        this.setState({
            contentString: "Here Success"
        });
        var ourMarker = e.target.id;
        window.google.maps.event.trigger(ourMarker, 'click');
        var infowindow = new window.google.maps.InfoWindow({
            content: "Here Success"
        });
        infowindow.open(map, marker);

    }
    onClickHideAll() {
        $("#email_ids").val("");
        this.setState({
            overly: "show-n",
            sliderStateEmailsTranslate: "",

        })
    }
    popmarker(e) {
        // console.log("OnClick Table ", e.data)
        var lat = parseFloat(e.data.latitude);
        var lng = parseFloat(e.data.longitude);
        var data = e.data;
        map.setCenter(new window.google.maps.LatLng(lat, lng));
        map.setZoom(12);
        var markerLatlng = new window.google.maps.LatLng(lat, lng);
        //var image =  require('../../assets/icons/truckblue.png');
        var image = require('../../assets/icons/truck_na_24.png');
        /*if(data.overspeeding_exception == 1)
        {
            var image =  require('../../assets/icons/truck_overspeed.png');
        }
    	
        if(data.nightdriving_exception == 1)
        {
            var image =  require('../../assets/icons/truck-end.png');
        }*/

        if (data.status == 1) {
            var truckText = "Inside TPA";
            var color = "#333333";
            var image = require('../../assets/icons/truck_inside_24.png');
        }
        else if (data.status == 2) {
            var truckText = "Going to Destination";
            var color = "#333333";
            var image = require('../../assets/icons/truck_going_24.png');
        }
        else if (data.status == 3 || data.status == 4) {
            var truckText = "At Destination Location";
            var color = "#333333";
            var image = require('../../assets/icons/truck_atdealer_24.png');
        }
        else if (data.status == 5) {
            var truckText = "Left Destination Location";
            var color = "#333333";
            var image = require('../../assets/icons/truck_leftdealer_24.png');
        }
        else if (data.status == 6) {
            var truckText = "Return to Plant";
            var color = "#333333";
            var image = require('../../assets/icons/truck_return_24.png');
        }
        else if (data.status == 7) {
            var truckText = "Empty Truck Outside Plant";
            var color = "#333333"; var image = require('../../assets/icons/truck_na_24.png');
            var image = require('../../assets/icons/truck_empty_24.png');
        }
        else {
            var truckText = data.truck_no;
            var color = "#333333";
            var image = require('../../assets/icons/truck_na_24.png');
        }
        var image = require('../../assets/icons/truck_na_24.png');
        var marker = new window.google.maps.Marker({
            position: markerLatlng,
            map: map,
            title: data.truck_no,
            icon: image
        });


        var contentarr = []
        var headerTitle = data.truck_no
        contentarr.push({ "key": "Truck No", "value": data.truck_no });
        contentarr.push({ "key": "Transporter Name", "value": data.transporter_name });
        contentarr.push({ "key": "Distance from geofence", "value": data.distance_from_coordinates });
        //contentarr.push({"key":"LSP Name", "value":data.lsp_name});
        contentarr.push({ "key": "Address", "value": data.area + ", " + data.state });

        var contentString = GoogleInfoWindow(image, headerTitle, contentarr, '')
        /*
        var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
        var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
        contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
        contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
        contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
            */
        var infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });

        marker.setAnimation(window.google.maps.Animation.DROP)
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });

        marker.setMap(map);


    }
    /*Truck Sidebar Component Starts*/
    async onShowSidebarAquireComponent(e) {
        this.setState({
            sliderTranslate: 'slider-translate',
            loadshow: 'show-m'
        });
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

    };

    onClickShowMap = async (tabcode) => {
        // console.log("tabcode ", tabcode)
        var dataarray = [];
        if (tabcode != 'all') {
            await this.state.originalrowData.map((item) => {
                if (item.plant_code == tabcode.plant_code) {
                    dataarray.push(item);
                }
            });
            await this.setState({
                rowData: dataarray,
                selectedTabLat: tabcode.lat,
                selectedTabLng: tabcode.lng,
                deftab: tabcode.plant_code
            });

            this.gridColumnApi.setColumnsVisible(["plant_code"], false);

        }
        else {
            await this.setState({
                rowData: this.state.originalrowData,
                selectedTabLat: "",
                selectedTabLng: "",
                deftab: "all"
            });
            this.gridColumnApi.setColumnsVisible(["plant_code"], true);

        }
        //window.initMap = await this.initMap
        await this.renderMap();

        //    if(tabcode != 'all')
        //    {
        // 		console.log("Code ", tabcode.plant_code)
        // 		let isFilterDataAvailable = this.state.rowData.filter(rec => {
        // 			if (rec["plant_code"]) {
        // 				return rec["plant_code"] == tabcode.plant_code;
        // 			}
        // 		});
        // 		var filterComponent = this.gridApi.getFilterInstance("plant_code");
        // 		filterComponent.selectNothing();
        // 		filterComponent.selectValue(tabcode.plant_code);

        // 		filterComponent.applyModel();

        // 		this.gridApi.setRowData(isFilterDataAvailable);
        // 		this.gridApi.onFilterChanged();
        //    }
        //    else{
        // 		//ALL
        // 		this.gridApi.setRowData(this.state.originalrowData);
        // 		this.gridApi.onFilterChanged();
        //    }

    }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render() {
        const modalStyles = {
            width: '1300px !important',
        }

        const { usermanualmodal } = this.state;
        if (this.state.request_type == 2) {
            var showonlysnd = (this.state.dept_code == 'SNDG' || this.state.dept_code == 'sndg') ? false : true;
            var showonlyprt = (this.state.dept_code == 'LOG-PRT' || this.state.dept_code == 'log-prt') ? false : true;
            var hidecol = false;
        }
        else {
            var showonlysnd = true;
            var showonlyprt = true;
            var hidecol = true;
        }
        // console.log("dept_code ", this.state.dept_code)
        // console.log("request_type ", this.state.request_type)
        // console.log("showonlyprt ", showonlyprt)
        var hideDistance = (this.state.view_type == "within_states") ? true : false;
        var columnDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 140,


            },
            // 	{
            // 	  headerName: "LSP Code",
            // 	  field: "transporter_code",
            // 	  width: 200,

            //   },  	        
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,

            },
            // 	{
            // 	headerName: "Department Code",
            // 	field: "dept_code",
            // 	width: 120,

            // },
            {
                headerName: "Tracking Mode",
                field: "actual_lspuser",
                width: 200,
                valueGetter: function (params) {
                    if (params.data.actual_lspuser == "SIM_TRACK_JIO") {
                        return "SIM Tracking";
                    }
                    else {
                        return "GPS Tracking";
                    }
                }
            },
            {
                headerName: "Plant Code",
                field: "plant_code",
                width: 120,
                valueGetter: function (params) {

                    var keys = ["HZ"];

                    // An array of values 
                    var values = ["Hazira"];

                    // Object created 
                    var obj = {};

                    // Using loop to insert key 
                    // value in Object 
                    for (var i = 0; i < keys.length; i++) {
                        if (keys[i] == params.data.plant_code) {
                            return values[i];
                        }

                    }

                }

            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                width: 150,
                hide: showonlysnd,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                },


            },
            {
                headerName: "Distance from Location (KM)",
                field: "distance_from_coordinates",
                width: 200,
                hide: hideDistance
            },
            {
                headerName: "Last Known City/Town",
                field: "area",
                width: 180,
            },
            {
                headerName: "Last Known State",
                field: "state",
                width: 180,
            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 180,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
                }
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
                },
                hide: showonlyprt

            },

            {
                headerName: "Consignee City",
                field: "consignee_city",
                width: 140,
                hide: hidecol
            },

            {
                headerName: "Consignee State",
                field: "consignee_state",
                width: 140,
                hide: hidecol
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 120,
                hide: showonlysnd
            },
            {
                headerName: "Route",
                field: "route_id",
                width: 120,
                hide: showonlysnd
            },

        ];

        const dStyles = {
            width: '90%',
            marginLeft: '0px',
            paddingLeft: '0px',
            height: '500px'
        }
        const btstye = {
            marginBottom: "10px"
        }

        function restrictToNumbers(event) {
            event.target.value = event.target.value.replace(/[^0-9.]/g, '');

            event.target.value = event.target.value.replace(/^0+(\d)/, '$1');

            event.target.value = event.target.value.replace(/^(\d+)\.(\d+)\..*$/, '$1.$2');
        
        }

        const onClickHide=()=>{
                this.setState({
                    loadshow:'show-n'})
        }
        const handleSubmit = (event)=>{
            event.preventDefault();
    }

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="form-group d-flex justify-content-between  mt-2 pl-0 mb-0">
                    <h5 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Trucks Within Geofence
                    </h5>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card m-10p mt-15p">
                    
                            <div className="card-body row">
                                <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
                                    <div className="row" style={{position:"relative"}}>
                                        <div className="form-group  col-xl-2 col-lg-2">
                                            <label className="c-lbl" style={{color:"black"}}>Search Type <span className="err-txt">*</span></label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                name="view_type"
                                                // isMulti={true}
                                                placeholder="Select"
                                                className="rselect"
                                                value={this.state.selectedState}
                                                styles={{
                                                    singleValue:(provided,state)=>({
                                                        ...provided,
                                                        fontSize:'0.9rem'
                                                    }),
                                                    control:(provided)=>({
                                                        ...provided,
                                                        width:"200px"
                                                    })
                                                }}
                                                onChange={this.onChangeViewType.bind(this)}
                                                options={this.state.typeList} />
                                           
                                        </div>
                                       {this.state.selectDropdown && <div className="form-group  col-xl-2 col-lg-2">
                                       <form onSubmit={this.handleSubmit}>
                                            <label className="c-lbl" style={{color:"black"}}>Select {this.state.selectTitle} <span className="err-txt">*</span></label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                name="request_type"
                                                isMulti={false}
                                                placeholder={`Select ${this.state.selectTitle}`}
                                                className="rselect"
                                                value={this.state.selectedType}
                                                styles={{
                                                    singleValue:(provided,state)=>({
                                                        ...provided,
                                                        fontSize:'0.9rem'
                                                    }),
                                                    control:(provided)=>({
                                                        ...provided,
                                                        width:"200px"
                                                    })
                                                }}
                                                onChange={this.changePlantInputHandler.bind(this)}
                                                options={this.state.typeList1}
                                                required />
                                                </form>
                                        </div>}
                                        {/* <div className={"form-group col-xl-2 col-lg-2 " + (this.state.showPlant)}>
                                            <label className="c-lbl" style={{color:"black"}}>Select Plant *</label>
                                            <Select 
                                            closeMenuOnSelect={false} 
                                            name="plant_code" 
                                            isMulti={true} 
                                            placeholder="Select Plant" 
                                            className="rselect" 
                                            value={this.state.plant_code} 
                                            onChange={this.onChangePlantCode.bind(this)} 
                                            options={this.selectOptionsItems()} 
                                            />
                                        </div> */}
                                        <div className={"form-group  col-xl-2 col-lg-2 " + this.state.showRadius}>
                                            <label className="c-lbl" style={{color:"black"}}>Radius (KM) <span className="err-txt">*</span></label>
                                            <input type="text" name="radius" id="radius" value={this.state.radius} onChange={this.changeRadiusHandler.bind(this)} onInput={(e)=> restrictToNumbers(e)} className="form-control" placeholder="Radius" required />
                                        </div>
                                        <div className={"form-group col-xl-2col-lg-2 "+(this.state.showAddr)}>
					               			<label className="c-lbl">Latitude *</label>
					               			<input type="text" name="latitude" id="latitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Latitude" />
					               		</div>
                                        <div className={"form-group col-xl-2 col-lg-2 "+(this.state.showAddr)}>
					               			<label className="c-lbl">Longitude *</label>
					               			<input type="text" name="longitude" id="longitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Longitude" />
					               		</div>
                                        <div className="form-group  col-xl-2 col-lg-2">
                                            <br />
                                            <label className="c-lbl" style={{color:"black",marginTop:"20px"}}><input type="checkbox" id="gps_record_flag" /> &nbsp;&nbsp;Trucks with GPS (24 Hrs)</label>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2">
                                            <button type="submit" style={{ marginTop: "35px" }} className={"btn btn-success "}>Submit</button>
                                            
                                        </div>
                                        <a href={"/reportscreen"} className="f12 mr-5p btn btn-default" style={{position:"absolute","top":"0","right":"0"}}>
                                                Back
                                            </a>
                                    </div>
                                </form>
                                
                               

                                <div className={"col-xl-12 col-lg-12 row " + (this.state.showrefresh)}>
                                    {(this.state.tabplants.length > 0) ?
                                        <ul className="col-xl-10 col-lg-10 float-left  mpatabs">
                                            <li className={"btn " + (this.state.deftab == 'all' ? "btn-success" : "")} style={{ float: "left", padding: "10px", cursor: "pointer", background: "#eee", margin: "0px 0px 0px 5px" }} onClick={this.onClickShowMap.bind(this, 'all')}>All</li>
                                            {this.state.tabplants.map((item) =>
                                                <li className={"btn " + (this.state.deftab == item.plant_code ? "btn-success" : "")} style={{ float: "left", padding: "10px", cursor: "pointer", background: "#eee", margin: "0px 0px 0px 5px" }} onClick={this.onClickShowMap.bind(this, item)}>{item.plant_code}</li>
                                            )}
                                        </ul>
                                        : ""}
                                    {/* <div className="col-xl-2 col-lg-2 float-right ">
                                        <button type="button" style={{ marginRight: "30px" }} className={"btn btn-danger float-right"} onClick={this.goBack} >Back</button>
                                    </div> */}
                                </div>
                                <div className="row col-xl-12 col-lg-12">
                                <div className={"dataLoadpage " + (this.state.loadshow)}>
                                </div>
                                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                                </div>
                                <div className={"overlay-part " + (this.state.overly)} onClick={onClickHide}></div>
                                  
                                    <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" style={{ marginTop: "12px", height: "37px" }} id="search" placeholder="Enter Address to search" />
                                    <div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
                                    {
                                        (this.state.rowData.length > 0) ?
                                            <div className="col-xl-5 col-lg-5 sidediv">

                                                <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={columnDefs}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.rowData}
                                                        enableCharts={false}
                                                        enableRangeSelection={false}
                                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                        onGridReady={this.onGridReady}
                                                        onGridState={this.onGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                        // enableRangeSelection={false}
                                                        floatingFilter={false}
                                                        //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                        onCellClicked={this.popmarker}
                                                    />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.sliderTranslate)} >


                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            {this.displayData}
                        </div>
                    </div>
                </div>

                <div className={"slide-r " + (this.state.sliderStateEmailsTranslate)} >
                    <div className="slide-r-title">
                        <h4>
                            Near By State
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="col-xl-12 col-lg-12">
                            <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.submitMailForm}>
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Email *:</label>
                                    <textarea className="form-control" id="email_ids" placeholder="Enter multiple e-mail addresses separated by comma" required></textarea>
                                </div>
                                <button type="submit" class="btn btn-info">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}


function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

    var len = geoFencingArea.getPath().getLength();
    // console.log(len);
    var htmlStr = "";
    var coordinates = [];
    for (var i = 0; i < len; i++) {
        // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
        // coordinates.push()
        var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
        var spliting = strlatlng.split(",");
        var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
        coordinates.push(latlngd);
        // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
        //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
        //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
    }
    // console.log("htmlStr", coordinates);

}
// function initOMS(){
//     // Initializing OMS
//     oms = new oms.OverlappingMarkerSpiderfier(map, {
//       //markersWontMove: true,
//       //keepSpiderfied: true,
//       //nearbyDistance: 10,
//       //circleFootSeparation: 60,
// 	  //legWeight: 1.5
// 	  markersWontMove: true,
// 	markersWontHide: true,
// 	keepSpiderfied: true,
// 	basicFormatEvents: true
//     });
// }
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
var GoogleInfoWindow = (icon, headerTitle, rows, mode) => {
	// console.log("Props ", headerTitle)
	var imgis;
	var content = "<div>";
	if (icon === '') {
		imgis = "<img class='c-google-img' src='" + require("../../assets/icons/dot.png") + "' />"
	}
	else {
		imgis = "<img class='c-google-img' src='" + icon + "' />";
	}
	content += "<h5 class='g-header'> " + imgis + " <span class='ml-10p'>" + headerTitle + "</span></h5>";
	if (mode === 'table') {
		content += rows;
	}
	else {

		content += "<table class='table table-border'>";
		rows.map((item) => {
			content += "<tr><th>" + item.key + "</th><td class='fw400'>" + item.value + "</td></tr>"
		})
		content += "</table>";
	}
	content += "</div>";
	//console.log("Geo Infowindow content ", content)
	return content;
}
