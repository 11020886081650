
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const ViewDOdetailsComponent = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickDOdetails(props);
    };
    var downloadBtn = "#1976d2";
      var  cursorPoint = "pointer";
    return (
        // <div className="map-icon-component">
        //     <img onClick={handleRouteClick}  src={require("../../assets/icons/Vector.png")}/>
        // </div>
         <div className="map-icon-component">
         <span onClick={handleRouteClick} className="custom-btn label label-success" title="Download Attachment"><i style={{color:downloadBtn,cursor:cursorPoint,marginTop:"10%"}} className="fa fa-download f20"></i> </span>
     </div>
    );
};
export default ViewDOdetailsComponent;
