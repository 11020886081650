import React from 'react'
// import PlantVsActualDashboard from './plantvsactualdashboard';
import CHADOReleaseTAT from './chaDOReleaseTAT';
import BufferStatus from './bufferStatusdashboard';
import TPTSOB from './tptsob';
import TPTTargetvsAchievement from './tpttargetvsachievement';
import PlantTAT from './planttatdashboard';
import TransporterTAT from './transportertatdashboard';



const Dashboard3=() =>{
  return (
    <>
    <div className=''>
        <div className='row'>
            
            <div className='col-6 mb-20p'>
            <TPTTargetvsAchievement/>
            </div>
            <div className='col-6 mb-20p'>
            <TPTSOB/>
            </div>
        </div>
        <div className='row'>
            
            <div className='col-6 mb-20p'>
            <BufferStatus/>
            </div>
            <div className='col-6 mb-20p'>
            <PlantTAT/>
            </div>
        </div>
        <div className='row'>
        <div className='col-6 mb-20p'>
        <TransporterTAT/>
            </div>
            <div className='col-6 mb-20p'>
            <CHADOReleaseTAT/>
            </div>

        </div>
    </div>
   
    </>
  )
}


export default Dashboard3;