var fetchGrid = function(defCols , gridCols) {
    // console.log("columns ", defCols);
    var updateCols = [];
    if(gridCols.length > 0)
    {
        gridCols.map((item) => {
            defCols.map((ab) => {
                if(item.colId == ab.field)
                {
                    ab.hide = item.hide
                    updateCols.push(ab);
                }
            })
        })
        //  console.log(" updateCols",updateCols)
    }
    return updateCols;
}
exports.fetchGrid = fetchGrid;