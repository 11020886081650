import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import redirectURL from "../redirectURL";
import DatePicker from 'react-datepicker';

import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHHMM } from '../common/utils';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";


const CHAWiseBLStatusDashboard = () => {

    var [rowData, setrowData] = useState([]);
    var [plantsCollection, setPlantsCollection] = useState([]);
    var [columns, setColumns] = useState([]);
    var [showFourDaysView, setShowFourDaysView] = useState("");
    var [rowData2, setrowData2] = useState([]);
    var [overly, setoverly] = useState('show-n')
    const [tableHeaders, setTableHeaders] = useState(['Date']); // Initialize with 'Date'
    var [loadshow, setloadshow] = useState('show-n');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    var [currentDate, setCurrentDate] = useState(new Date());
    var initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
    var [datefilter, setDatefilter] = useState(initialDate);
    var dateFormat = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
    var [dateText, setDateText] = useState(dateFormat);

    var columnDefs2 = [
        {
            headerName: "WORK ORDER NO",
            headerClass: "showDataStyle",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
            cellStyle: { textAlign: "center" }
        },
        {
            headerName: "Container No",
            headerClass: "showDataStyle",
            field: "container_no",
            width: 160,
            filter: true,
            resizable: true,
            cellStyle: { textAlign: "center" }
        },
        {
            headerName: "CHA",
            // headerClass:"headerStyle",
            field: "cha_name",
            width: 200,
            filter: true,
            resizable: true,
            // valueGetter: function (params) {
            //     try {
            //         if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
            //             return getHyphenDDMMYYYY(params.data.stuffing_date);
            //         }
            //         else {
            //             return "Not Available";
            //         }
            //     }
            //     catch (e) { }
            // },
            cellStyle: { textAlign: "center" }
        },
        {
            headerName: "B/L (NO OF DAYS)",
            // headerClass:"headerStyle",
            field: "bl_days",
            width: 160,
            filter: true,
            resizable: true,
            cellStyle: { textAlign: "center" }
        },

    ]
    useEffect(()=>{
        if (showFourDaysView ==='slide110'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showFourDaysView])
    
    useEffect(() => {
        setloadshow('show-m')
        getData(datefilter);
    }, [])
    const getData = (formattedDate) => {
        setloadshow('show-m')
        var date;
        if (formattedDate != '' && formattedDate != undefined) {
            date = formattedDate;
        } else {
            date = datefilter
        }
        var params = {
            month: date
        }

        redirectURL.post('/consignments/getBLStatusData',params).then((resp) => {
            //console.log(resp.data, "getblstatusdata")
            try {

                var data  = resp.data
                if(data!='' && data!=undefined){
                    //console.log(data,'100100')
                    var chaData = data.cha_bl_wise
                    chaData = JSON.parse(chaData)
                    //console.log(chaData,'102102')
                    setrowData(chaData);
                    var summaryData = data.view_summary_data
                    //console.log(summaryData,'summaryDataforblstatus')
                    summaryData = JSON.parse(summaryData)
                    setrowData2(summaryData)
                // setrowData2(data)
                }
                else{
                    setrowData([]);
                    setrowData2([])
                }
              
            } catch (e) {
                setrowData([]);
                setrowData2([]);
                console.error('Error:', e);
            }finally{
                setTimeout(() => {
                    setloadshow('show-n')
                }, 3000);
            }
        }).catch(error => {
            console.error('Fetch error:', error);
            setrowData([]);
            setrowData2([]) // Ensure rowData is empty in case of fetch error
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    
        var uniqueHeaders = ['CHA','<3 Days','3 - 7 Days','>10 Days']
        setTableHeaders(uniqueHeaders)
        
    }
    const fourDaysplanView = () => {
        //console.log("click view summarys")
        setShowFourDaysView("slide110");
        setoverly("show-m");
    }
    const hideSlideBlock = () => {
        setShowFourDaysView("");
        setoverly("show-n");
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const handleDateChange = (date) => {
        //console.log(date, "selecteddate")
        setSelectedDate(date);
        //console.log(`Selected month/year: ${date.toLocaleDateString()}`);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        //console.log(formattedDate, 'formattedDate')
        setDatefilter(formattedDate)
        var dateFormat1= `${month}-${year}`

        setDateText(dateFormat1)
        // setfilters(prev => ({
        //     ...prev,
        //     datefilter: formattedDate
        // }))
        getData(formattedDate)
        setShowDatePicker(false)
    };
    const handleKeyDown = (e) => {
        e.preventDefault(); // Prevent any keyboard input
    }
    return (
        <>
        <div className='card-header2'>
            <div className='d-flex justify-content-between'>
                <h1 className='heading3 mt-20p'>CHA Wise B/L Status</h1>
                <div className='d-flex'>
                <h3 className='heading4 mr-10p mt-25p'>{dateText}</h3>

                <div className='mt-15p mr-20p'>

                        <img src={require("../../assets/images/calendar.png")} onClick={() => setShowDatePicker(!showDatePicker)} alt="" width="25px" className="image-icons positionIcon" />
                        {showDatePicker && (
                            <div style={{ position: 'relative', zIndex: 1000 }}>
                                <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                minDate={new Date(1900, 0)}
                                maxDate={new Date(2100, 11)}
                                onKeyDown={handleKeyDown}
                                className="custom-datepicker"
                                calendarClassName="custom-datepicker-dropdown"
                                inline
                            />
                            </div>
                        )}
                    </div>
                <div className="form-group ml-20p">
                    <button type="button" className="btn-showdata mt-15p" style={{ fontSize: "0.8rem", height: "30px" }} onClick={fourDaysplanView}>
                    <i class="fa fa-eye"><span className='ml-2p view-summary'>View Data</span></i>
                                    </button>
                </div>
                </div>
            </div>
            <div className='scrollable-table-container'>
                <table className="table table-small-rows"  style={{border:"0px", overflow: 'auto', height:"100%" }}>
                    <thead>
                        <tr>
                        {tableHeaders.map((header) => (
                            <th className='table-outlookvsorders'>{header}</th> // Dynamic headers
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rowData.length > 0 ? (rowData.map((item, index) => {
                            let cha = item.cha_name.split(" ")
                            let chaLength = cha.length
                            if (chaLength > 2){
                                let chaName = cha[0]+ " "+cha[1]
                                return (
                                    <tr key={index}>
                                        <td style={{width:"500px"}}>{chaName}</td>
                                        <td>{item.less_than_3days}</td>
                                        <td>{item.btw_3days_7days}</td>
                                        <td>{item.greater_than_10_days}</td>
                                    </tr>
                                )
                            }

                        })) : (
                            <tr style={{height:"170px"}}>
                            <td colSpan={tableHeaders.length} style={{ textAlign: 'center', verticalAlign:'middle' }}>No rows to show</td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>    
            <div className={"sliderBlock3 " + (showFourDaysView)} >
                <h1 className='fourdays-heading ml-40p mt-40p mb-20p' >CHA Wise B/L Status Data View</h1>
                <span className="float-right ffclosebtn1" style={{ marginRight: "25px", fontSize: '20px' }} onClick={hideSlideBlock}>
                    <img src={require('../../assets/images/closeIcon.png')} className='closeIcon mt-5p' style={{ marginTop: "-40px" }} />
                </span>
                <div className="row" >
                    <div className="col-sm-12 ml-40p" >
                        <div id="" style={{ width: "83%", height: "320px" }} className="ag-theme-balham">
                            <AgGridReact
                                // ref={gridApi}
                                // modules={AllModules}
                                columnDefs={columnDefs2}
                                defaultColDef={{
                                    sortable: true,
                                    // headerClass:'headerStyle',
                                    filter: true,
                                    editable: false,
                                    resizable: true,
                                    menuTabs: ['filterMenuTab']
                                }}
                                rowData={rowData2}
                                suppressRowClickSelection={true}
                                enableCellChangeFlash={true}
                                suppressCellFlash={true}
                                enableRangeSelection={true}
                                paginationPageSize={7}
                                // onGridReady={onGridReady}
                                // onGridState={onGridState}
                                rowSelection={'multiple'}
                                masterDetail={true}
                                pagination={true}
                                // frameworkComponents={{ CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                // sideBar={{
                                //     toolPanels: [
                                //         {
                                //             id: "columns",
                                //             labelDefault: "Columns",
                                //             labelKey: "columns",
                                //             iconKey: "columns",
                                //             toolPanel: "agColumnsToolPanel"
                                //         },
                                //         {
                                //             id: "filters",
                                //             labelDefault: "Filters",
                                //             labelKey: "filters",
                                //             iconKey: "filter",
                                //             toolPanel: "agFiltersToolPanel"
                                //         }
                                //     ]
                                // }}
                                // statusBar={{
                                //     statusPanels: [
                                //         { statusPanel: "agFilteredRowCountComponent" },
                                //         { statusPanel: "agSelectedRowCountComponent" },
                                //         { statusPanel: "agAggregationComponent" }
                                //     ]
                                // }}
                                gridOptions={{
                                    icons: {
                                        next: `<img src="${customNextIcon}" alt="Next" />`,
                                        previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                        first: `<img src="${customFirstIcon}" alt="First" />`,
                                        last: `<img src="${customLastIcon}" alt="Last" />`,
                                    },
                                }}
                            // context={{ onClickDOdetails }}
                            />

                        </div>
                    </div>
                </div>
                {/* <div className='ml-40p scrollable-table-container' style={{maxWidth:"500px", maxHeight:"300px", overflow:"auto"}}>
                   <table className="table table-small-rows1" style={{ border: '0px', overflow: 'auto', marginBottom:"0px", marginTop:"0px" }}>
                    <thead>
                        <tr>
                            <th className='table2-fourdays'>CHA</th>
                            <th className='table2-fourdays'>3 Days</th>
                            <th className='table2-fourdays'>3 - 7 Days</th>
                            <th className='table2-fourdays'>10 Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowData2.map((item, index) => (
                            <tr key={index}>
                                <td>{item.cha_name}</td>
                                <td>{item.less_than_3days}</td>
                                <td>{item.btw_3days_7days}</td>
                                <td>{item.greater_than_10_days}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div> */}
            </div>
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </>

    )
}

export default CHAWiseBLStatusDashboard;