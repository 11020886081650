import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import {getHyphenDDMMYYYY, getHyphenDDMMYYYYHHMM} from "../utils";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { Constants } from 'ag-grid-enterprise';
import Consts from "./constants";

export default class CreatePO extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            rowData:[],
            delivered_date:"",
            shipping_address:"",
            transporter:{"value":"","label":"Select"},
            vendor:{"value":"","label":"Select"},
            transporters:[],
            overlay:"show-n",
            itemcount:1,
            podDoc:'',
            vendorslist:[],
            plant_name:"",
            indenter_name:"",
            indenter_mobile:"",
            indenter_email:"",
            vendor_address:"",
            vendor_sales_person:"",
            vendor_sales_person_mobile:"",
            poDate:"",
            po_no:"",
            payment_terms:"",
            inco_terms:"",
            freight:"",
            insurance:"",
            terms_conditions:"",
            allvendors:[]
        }
        this.loadTransporters = this.loadTransporters.bind(this);
        this.onSubmitPO = this.onSubmitPO.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.vendorslist = this.vendorslist.bind(this);
    }

	componentDidMount(){
        this.loadTransporters();
        this.vendorslist();
	}
    loadTransporters(){
        redirectURL.post("/booking/transporters")
		.then((response) => {
			var records = response.data.records;
            var transporters = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    transporters.push({
                        value:item.transporter_code,
                        label:item.transporter_name
                    });
                });
            }
           this.setState({
            transporters:transporters
           })
            
		});
    }
    
    vendorslist(){
        redirectURL.post("/booking/vendors")
		.then((response) => {
			var records = response.data.records;
            var vendorslist = [];
            var allvendors  = records;
            if(records.length > 0)
            {
                records.map((item) => {
                    vendorslist.push({
                        value:item.vendor_code,
                        label:item.vendor_name
                    });
                });
            }
            // console.log("vendorslist ", vendorslist)
           this.setState({
            vendorslist:vendorslist,
            allvendors:allvendors,
           })
            
		});
    }
    
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    
    
    hideSlideBlock(){
        this.setState({
            slider:"",
            overlay:"show-n"
        })
    }
    changeDateTimeValue = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	this.setState({
    		delivered_date:getHyphenDDMMYYYYHHMM(d)
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    changePODateTimeValue = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	this.setState({
    		poDate:getHyphenDDMMYYYY(d)
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    onChangeTransporter(transporter)
    {
        this.setState({transporter})
    }
    onChangeVendor(vendor)
    {
        this.setState({vendor},()=>{
            if(this.state.allvendors.length > 0)
            {
                var allvendors = this.state.allvendors;
                var selectedVendor = allvendors.filter((d) => d.vendor_code == this.state.vendor.value);
                if(selectedVendor.length > 0)
                {
                    this.setState({
                        vendor_address:selectedVendor[0].vendor_address
                    })
                }
            }
        })
    }
    onClickAddItem(){
        var itemcount = parseInt(this.state.itemcount)+1;
        var lsps = this.state.transporters;
        var html='';
        html += '<div class="row mt-4 brd-btm">';
            // html += '<div class="col-sm-2"> <lable>Select Transporter:</lable>';
            // html +='<select name="item_lsp_'+itemcount+'" data-id="'+itemcount+'" id="item_lsp_'+itemcount+'" class="form-control itemlist">';
            // if(lsps.length > 0)
            // {
            //     lsps.map((item) => {
            //         html += '<option value="'+item.value+'">'+item.label+' - '+item.value+'</option>'
            //     })
            // }
            // html +='</select>';
            // html +='</div>';
            html += '<div class="col-sm-3"> <lable>Item No:</lable> <input type="text" name="item_no_'+itemcount+'" data-id="'+itemcount+'" id="item_no_'+itemcount+'" class="form-control itemlist" /></div>';
            html += '<div class="col-sm-3"> <lable>Item Description:</lable>  <input type="text" data-id="'+itemcount+'" name="item_desc_'+itemcount+'" id="item_desc_'+itemcount+'" class="form-control " /> </div>';        
            html += '<div class="col-sm-3"> <lable>Quantity:</lable><input type="text" name="item_qty_'+itemcount+'" data-id="'+itemcount+'" id="item_qty_'+itemcount+'" class="form-control " /> </div>';        
            html += '<div class="col-sm-3"> <lable>UM:</lable>  <input type="text" name="item_um_'+itemcount+'" data-id="'+itemcount+'" id="item_um_'+itemcount+'" class="form-control " /> </div>';        
            html += '<div class="col-sm-3" style="margin-top:10px;"><lable>HSN/SAC Code:</lable> <input type="text" name="item_hsncode_'+itemcount+'" data-id="'+itemcount+'" id="item_hsncode_'+itemcount+'" class="form-control " /> </div>';        
            html += '<div class="col-sm-3" style="margin-top:10px;"> <lable>Price:</lable><input type="text" name="item_price_'+itemcount+'" data-id="'+itemcount+'" id="item_price_'+itemcount+'" class="form-control " /></div>';
        html += '</div>';
        $("#itemlists").append(html);
        this.setState({
            itemcount:itemcount
        })
    }
    changeFileHandler(event){
        this.setState({
            podDoc:event.target.files[0]
        })
    }
    onSubmitPO(event){
        event.preventDefault();
        // var transporter_code = this.state.transporter.value;
        // var transporter_name = this.state.transporter.label;
        var vendor_code = this.state.vendor.value;
        var vendor_name = this.state.vendor.label;
        var delivered_date = this.state.delivered_date;
        var shipping_address = this.state.shipping_address;
        var plant_name = this.state.plant_name;
        var indenter_name = this.state.indenter_name;
        var indenter_mobile = this.state.indenter_mobile;
        var indenter_email = this.state.indenter_email;
        var vendor_address = this.state.vendor_address;
        var vendor_sales_person = this.state.vendor_sales_person;
        var vendor_sales_person_mobile = this.state.vendor_sales_person_mobile;
        var poDate = this.state.poDate;
        var payment_terms = this.state.payment_terms;
        var inco_terms = this.state.inco_terms;
        var freight = this.state.freight;
        var insurance = this.state.insurance;
        var terms_conditions = this.state.terms_conditions;
        var po_no = this.state.po_no;
        var lists = getListItems();
        // console.log("lists ", lists)
        var poitms = JSON.stringify(lists);
        const formData = new FormData();
        // formData.append("transporter_code",transporter_code);
        // formData.append("transporter_name",transporter_name);
        formData.append("poitems",poitms);
        formData.append("vendor_code",vendor_code);
        formData.append("vendor_name",vendor_name);
        formData.append("delivered_date",delivered_date);
        formData.append("shipping_address",shipping_address);
        formData.append("plant_name",plant_name);
        formData.append("indenter_name",indenter_name);
        formData.append("indenter_mobile",indenter_mobile);
        formData.append("indenter_email",indenter_email);
        formData.append("vendor_address",vendor_address);
        formData.append("vendor_sales_person",vendor_sales_person);
        formData.append("vendor_sales_person_mobile",vendor_sales_person_mobile);
        formData.append("poDate",poDate);
        formData.append("po_no",po_no);
        formData.append("payment_terms",payment_terms);
        formData.append("inco_terms",inco_terms);
        formData.append("freight",freight);
        formData.append("insurance",insurance);
        formData.append("terms_conditions",terms_conditions);
        formData.append("podDoc",this.state.podDoc);
        
       
        redirectURL.post("/booking/savepoinformation",formData)
		.then((response) => {
			//console.log("response ", response.data)
            if(response.data.status === "success")
            {
                window.location.reload();
            }
		});
    }
    render(){
		
        return (
            <div className="container-fluid">
                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">{Consts.BOOKING_CREATE_PAGE_TITLE}</h1>
				</div>
                <div className="card">
                    <div className='card-body'>                        
                        <form className="theme" method="POST" enctype="multipart/form-data" onSubmit={this.onSubmitPO}>
                            <div className="row brd-btm">
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Plant Name *</label>
                                        <input type="text" name="plant_name" className='form-control'  value={this.state.plant_name} onChange={this.changeHandler.bind(this)} required />
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Indenter Name *</label>
                                        <input type="text" name="indenter_name" className='form-control'  value={this.state.indenter_name} onChange={this.changeHandler.bind(this)} required />
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Indenter Mobile No *</label>
                                        <input type="text" name="indenter_mobile" className='form-control'  value={this.state.indenter_mobile} onChange={this.changeHandler.bind(this)} required />
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Indenter Email ID *</label>
                                        <input type="text" name="indenter_email" className='form-control'  value={this.state.indenter_email} onChange={this.changeHandler.bind(this)} required />
                                    </div>
                                </div>
                            </div>
                            <div className="row brd-btm">
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Select Vendor *</label>
                                        <Select
                                            inputProps={{required:true}}
                                            options={this.state.vendorslist}
                                            value={this.state.vendor}
                                            onChange={this.onChangeVendor.bind(this)}
                                        />
                                    </div>
                                </div>
                                
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Vendor Address *</label>
                                        <input type="text" name="vendor_address" className='form-control'  value={this.state.vendor_address} onChange={this.changeHandler.bind(this)} required />
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Sales Person </label>
                                        <input type="text" name="vendor_sales_person" className='form-control'  value={this.state.vendor_sales_person} onChange={this.changeHandler.bind(this)}  />
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Sales Person Contact No </label>
                                        <input type="text" name="vendor_sales_person_mobile" className='form-control'  value={this.state.vendor_sales_person_mobile} onChange={this.changeHandler.bind(this)}  />
                                    </div>
                                </div>
                            </div>
                            <div className="row brd-btm">
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>PO Date *</label>
                                        <Datetime 
                                            inputProps={{ placeholder: 'PO Date', name: 'startDate', autoComplete:'off', required:true }} 
                                            dateFormat="YYYY-MM-DD" 
                                            timeFormat={false}  
                                            name="poDate" 
                                            onChange={this.changePODateTimeValue.bind(this)}
                                        />
                                    </div>
                                </div>
                                
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>PO Number *</label>
                                        <input type="text" name="po_no" className='form-control'  value={this.state.po_no} onChange={this.changeHandler.bind(this)} required />
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Upload PO Document *</label>
                                        <input type="file" name="podDoc" id="podDoc" onChange={this.changeFileHandler} className="form-control" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row brd-btm">   
                                <div className='col-sm-4'>
                                    <div className="form-group">
                                        <label>Delivery Date *</label>
                                        <Datetime 
                                            inputProps={{ placeholder: 'Delivery Date', name: 'startDate', autoComplete:'off', required:true }} 
                                            dateFormat="YYYY-MM-DD" 
                                            timeFormat="HH:mm"  
                                            name="startDate" 
                                            onChange={this.changeDateTimeValue.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className="form-group">
                                        <label>Shipping Address *</label>
                                        <input type="text" name="shipping_address" id="shipping_address" onChange={this.changeHandler.bind(this)} className="form-control" value={this.state.shipping_address} required />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-12">
                                    <h5>Item Description:
                                        <input type="hidden" id="itemcnt" value={this.state.itemcount} />
                                        <button type="button" className="float-right btn btn-info" onClick={this.onClickAddItem.bind(this)}>Add Item</button>
                                    </h5>
                                </div>
                            </div>
                            <div className="mt-4 brd-btm" id="itemlists">
                                <div className="row">
                                    {/* <div className='col-sm-3'>
                                        <div className="form-group">
                                            <label>Select Transporter *</label>
                                            
                                            <select name="item_lsp_1" data-id="1" id="item_lsp_1" class="form-control itemlist" required >
                                                <option value="">Select</option>
                                                {(this.state.transporters.length > 0)?
                                                (this.state.transporters.map(item =>
                                                
                                                    <option value={item.value}>{item.label+" - "+item.value}</option>
                                                    
                                                )):""}
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-3">
                                        <lable>Item No *</lable>
                                        <input type="text" name="item_no_1" id="item_no_1" data-id="1" className="form-control itemlist" required  />
                                    </div>
                                    
                                    <div className="col-sm-3">
                                        <lable>Item Description *</lable>
                                        <input type="text" name="item_desc_1" id="item_desc_1" data-id="1" className="form-control " required />
                                    </div>
                                    
                                    <div className="col-sm-3">
                                        <lable>Quantity *</lable>
                                        <input type="text" name="item_qty_1" id="item_qty_1" data-id="1" className="form-control " required />
                                    </div>
                                    
                                    <div className="col-sm-3">
                                        <lable>UM *</lable>
                                        <input type="text" name="item_um_1" id="item_um_1" data-id="1" className="form-control " required />
                                    </div>
                                    
                                    <div className="col-sm-3" style={{marginTop:"10px"}}>
                                        <lable>HSN/SAC Code *</lable>
                                        <input type="text" name="item_hsncode_1" id="item_hsncode_1" data-id="1" className="form-control " required />
                                    </div>
                                    
                                    <div className="col-sm-3">
                                        <lable>Price *</lable>
                                        <input type="text" name="item_price_1" id="item_price_1" data-id="1" className="form-control " required />
                                    </div>
                                </div>
                            </div>
                            <div className="row brd-btm" style={{marginTop:"40px"}}>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label>Payment Terms</label>
                                        <textarea rows={4} name="payment_terms" className='form-control'  value={this.state.payment_terms} onChange={this.changeHandler.bind(this)} ></textarea>
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label>Inco Terms</label>
                                        <textarea rows={4} name="inco_terms" className='form-control'  value={this.state.inco_terms} onChange={this.changeHandler.bind(this)} ></textarea>
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label>Terms & Conditions</label>
                                        <textarea rows={4} name="terms_conditions" className='form-control'  value={this.state.terms_conditions} onChange={this.changeHandler.bind(this)} ></textarea>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Freight</label>
                                        <input type="text" name="freight" className='form-control'  value={this.state.freight} onChange={this.changeHandler.bind(this)}  />
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-group">
                                        <label>Insurance</label>
                                        <input type="text" name="insurance" className='form-control'  value={this.state.insurance} onChange={this.changeHandler.bind(this)}  />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-12" style={{textAlign:"center"}}>
                                    <button type="submit" className="btn btn-success">Submit PO</button>
                                </div>
                            </div>
                        </form>
                
                    </div>
                </div>
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
function getListItems()
{
    var listitems = [];
    $(".itemlist").each(function(){
        // console.log("item ", $(this).attr("data-id"))
        var rID = $(this).attr("data-id");
        // console.log("rID ", rID);
        // var itemlsp = $("#item_lsp_"+rID).val();
        var itemno = $("#item_no_"+rID).val();
        var itemdesc = $("#item_desc_"+rID).val();
        var itemqty = $("#item_qty_"+rID).val();
        var itemum = $("#item_um_"+rID).val();
        var itemhsn = $("#item_hsncode_"+rID).val();
        var itemprice = $("#item_price_"+rID).val();
        // listitems.push(itemno+"__"+itemdesc+"__"+itemqty+"__"+itemum+"__"+itemhsn+"__"+itemprice);
        listitems.push({
            item_lsp:"",
            item_no:itemno,
            item_desc:itemdesc,
            item_qty:itemqty,
            item_um:itemum,
            item_hsn:itemhsn,
            item_price:itemprice
        });
    });
    // console.log("listitems ", listitems)
    return listitems;
}