import React, { useState, useEffect, useRef, useCallback, useDebugValue } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from "react-select";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import SubmitBtn from "./submitBtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
import { object } from "@amcharts/amcharts5";
const OnTimePerformanceComponent = () => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    const [transList, settransList] = useState([])
    var [trucksdata,setTrucksData] = useState([])
    const currentDate = new Date();
    const initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    var [selectedDate, setSelectedDate] = useState(new Date());
    var [selectedfilters, setfilters] = useState({
        yearFilter:  {'label':'FY 24-25','value':'FY 24-25'},
        transfilter: { label: 'All', value: 'ALL' }
    })
    var [groupdata, setgroupdata] = useState([])
    const [modifiedRows, setModifiedRows] = useState(new Set());
    var [selecteddata,setselecteddata] = useState([])
    var [modifiedRownodes, setModifiedRownodes] = useState([]);
    var [updatedData, setUpdatedData] = useState([]);
    const [years, yearsList] = useState([
        {'label':'FY 22-23','value':'FY 22-23'},
        {'label':'FY 23-24','value':'FY 23-24'},
        {'label':'FY 24-25','value':'FY 24-25'},
        // {'label':'FY 25-26','value':'FY 25-26'}
    ])
    useEffect(() => {
        if (gridRef.current) {
            // setGridApi(gridRef.current.api);
            // setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        setloadshow("show-m")
        setoverly("show-m")
        redirectURL.post("/dashboard/gettrucksdata").then((resp) => {
            try {
                var records = resp.data.records
                var data = resp.data.total_data 
                setTrucksData(data)
                var transporters = [{ label: 'All', value: 'ALL' }]
                data.map((e) => {
                    transporters.push({ label: e.transporter_name, value: e.transporter_name })
                })
                 transporters = removeDuplicateObjects(transporters);
                settransList(transporters)
            }
            catch (e) {

            }
        })
        getdata()
    }, [])
    const handleCellValueChanged = (params) => {
        var updatedRowData = [...updatedData];
        updatedRowData.push(params.data)
        setUpdatedData(updatedRowData);
      };
    const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
    }, []);
    const getRowStyle = (params) => {
        if (!params || !params.data || !params.data.report_date) {
            return null;
        }
        var currentWeekDates = getCurrentWeekDates();
        var reportDate = params.data.report_date;
        if (currentWeekDates.includes(reportDate)) {
            return { backgroundColor: '#FF7F7F' }; 
        }
        return null;
    };
    
    
    const submitdata = ()=>{
        //console.log(updatedData,'updatedData')
        setloadshow('show-m')
        setoverly("show-m")
        var updatedrecord = []
        updatedData.map((e)=>{
            var keys = Object.keys(e);
            keys.map((k)=>{
                if(k.includes("_name") == true)
                {
                    //console.log(k,"e[k]")
                    var tptName = k.split("_")[0];
                    var tptNamewithoutspaces = tptName.trim().replace(/ /g, "");
                }
                if(e[tptNamewithoutspaces+"_mark"] !=undefined && e[tptNamewithoutspaces+"_mark"] !="undefined"&&e[tptNamewithoutspaces+"_mark"] !='')
                {
                    updatedrecord.push({
                        "report_date" : e["report_date"],
                        "transporter_name" : e[tptNamewithoutspaces+"_name"],
                        "mark_availability" : e[tptNamewithoutspaces+"_mark"],
                    }); 
                }
            });
            // var row = {'report_date':e.report_date,'mark_availability':e.}
        })
        var params = {
            data:updatedrecord
        }
       redirectURL.post('/consignments/insertfortruckavailreport',params).then((resp)=>{
        if(resp.data.status=='Success'){
            setshow(true)
            setbasicTitle("Successfully Updated")
            setbasicType("success")
            getdata()
            setloadshow('show-n')
        }
        else{
            setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')

        }
       })
    }
    var columnwithDef = [
        {
            headerName: "",
            field: "report_date",
            headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
            width: 150,
            filter: true,
            resizable: true,
            children: [
                {
                    headerName: "DATE",
                    headerClass: ["cellstylegrid"],
                    field: 'report_date',
                    width: 100,
                    cellClass: (params) => {
                        if (params.data && params.data.report_date) {
                            const currentWeekDates = getCurrentWeekDates();
                            if (currentWeekDates.includes(params.data.report_date)) {
                                return 'cellHighlighted'; // Define a CSS class for highlighted cells
                            }
                        }
                        return ''; 
                    },
                },
            ]
        }
        
    ]
    var getdata = () => {
        setloadshow("show-m")

        var params = {
             'financial_year': selectedfilters.yearFilter.value,
             'transporter_name': selectedfilters.transfilter.value
        }
        redirectURL.post('/consignments/getontimeperformancedata', params)
            .then((resp) => {
                var records = JSON.parse(resp.data.tpt_ontime_performance);
                if(localStorage.getItem('role')=='tpt'){
                    if(localStorage.getItem('tpt_name')!='' && localStorage.getItem('tpt_name')!='undefined'){
                        records = records.filter(e=>e.transporter_name==localStorage.getItem('tpt_name'))
                    }
                }
                const trans_names = groupBy(records, (rdata) => rdata.transporter_name);
                const currentMonths = groupBy(records, (rdata) => rdata.month);
                const finalData = [];
                const dateKeys = [...currentMonths.keys()];
                if (dateKeys.length > 0) {
                    dateKeys.forEach((month) => {
                        const row = {
                            report_date: month,
                        };
                        if (month) {
                            records.map((item) => {
                                if (month == item.month) {
                                    const transporterName =  item.transporter_name.trim().replace(/ /g, "").replace(/[^\w\s-]/g, '')
                                   
                                    row[`${transporterName}_do_allocated`] = item.do_allocated;
                                    row[`${transporterName}_do_ontime`] = item.do_ontime;
                                    row[`${transporterName}_achieved_percent`] = item.achieved_percent;
                                    row[`${transporterName}_trucks_ontime`] = item.trucks_ontime
                                }
                            });
                        }
                        finalData.push(row);
                    });
                }
                setrowData(finalData);
                setloadshow("show-n")
                setoverly("show-n")
                setgroupdata(trans_names);
            })
            .catch((error) => {
            });
    }

    var handleFilterOptions = (e) => {
        setfilters(prev => ({
            ...prev,
            transfilter: e
        }))
    }
    var handleFilterOptions1 = (e) => {
        setfilters(prev => ({
            ...prev,
            yearFilter: e
        }))
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var onClickHide =()=>{
        setloadshow('show-n')
    }
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        //console.log(formattedDate, 'formattedDate')
        setfilters(prev => ({
            ...prev,
            datefilter: formattedDate
        }))
    };
    const colors = ['#98FF98', '#87CEEB', '#FA8072', '#9FE2BF', '#CCCCFF']; // Predefined colors

    // Function to add the color style to the document
    function addColorStyle(keyWithoutSpaces, color) {
    var style = document.createElement('style');
    document.head.appendChild(style);
    // Add rule for background color
    style.sheet.insertRule(`.header-${keyWithoutSpaces}, .cell-${keyWithoutSpaces} { background-color: ${color}; }`, 0);
    // Add rule to remove header cell borders
    style.sheet.insertRule(`.ag-header-cell.header-${keyWithoutSpaces}, .ag-header-group-cell.header-${keyWithoutSpaces} { border-right: none !important; }`, 1);
}
    const keys = [...groupdata.keys()];
    keys.forEach(function (key, index) {
        var keyWithoutSpaces = key.trim().replace(/ /g, "").replace(/[^\w\s-]/g, '');
        var colorIndex = index % colors.length; // Use modulo to cycle through the colors array
        var color = colors[colorIndex]; // Pick the color based on the index
        // Add the color style
        addColorStyle(keyWithoutSpaces, color);
        // var row = groupdata[key];
        var columnDefinition = {
            headerName: key,
            field:'',
            headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the parent header
            cellClass: `cell-${keyWithoutSpaces}`, // Apply the class to parent column cells
            children: [
                {
                    headerName: "Do Allocated",
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    field: keyWithoutSpaces + "_do_allocated",
                    width: 110,
                     valueGetter:function(params){
                        if(params.data&&params.data[keyWithoutSpaces+"_do_allocated"]!=undefined){
                            return params.data[keyWithoutSpaces + "_do_allocated"]
                        }
                    }
                },
                {
                    headerName: "DO Ontime",
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    field: keyWithoutSpaces + "_do_ontime",
                    width: 100,
                    valueGetter:function(params){
                        if(params.data && params.data[keyWithoutSpaces+"_do_ontime"]!=undefined){
                            return params.data[keyWithoutSpaces + "_do_ontime"]
                        }
                    }
                },
                {
                    headerName: "Trucks On Time",
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    field: keyWithoutSpaces + "_trucks_ontime",
                    width: 120,
                    valueGetter:function(params){
                        if(params.data && params.data[keyWithoutSpaces+'_trucks_ontime']!=undefined){
                            return params.data[keyWithoutSpaces + "_trucks_ontime"]
                        }
                    }
                },
                {
                    headerName: "Achieved %",
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    field: keyWithoutSpaces + "_achieved_percent",
                    width: 100,
                    valueGetter:function(params){
                        if(params.data && params.data[keyWithoutSpaces+"_achieved_percent"]!=undefined){
                            return params.data[keyWithoutSpaces + "_achieved_percent"]
                        }
                    }
                },
            ],
            // cellStyle: function (params) {
            //     if (params.colDef.headerName === key) {
            //         if (key === 'BHATIA SHIPPING PVT. LTD.') {
            //             return { backgroundColor: 'red' };
            //         } else if (key === 'AnotherKey') {
            //             return { backgroundColor: 'green' };
            //         } else {
            //             return { backgroundColor: 'yellow' };
            //         }
            //     }
            //     return null; 
            // }
            
        };
        columnwithDef.push(columnDefinition);
    });
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
               <h1 className="heading mt-30p">
                   <i className="icofont icofont-vehicle-delivery-van cus-i"></i>On Time Performance
               </h1>
                </div>
           <div className="card m-10p">
            <div className="card-body" id="counterCardBody">
                <div className="row">
                <div className="col-sm-8 checkForDateFilter_gap">
                <div className="row">
                <div className="form-group col-xl-4 col-lg-4">
                <Select
                        closeMenuOnSelect={true}
                        name="filterModeType"
                        // className="border-radius-0"
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                fontSize: "0.9rem",
                                color: "#6e707e",
                                borderRadius: "11px",
                                height: "48px",
                                width: "260px",
                                // textAlign: "center",
                                // marginRight: '25px'
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                fontSize: "0.9rem", // Adjust the font size here
                            }),
                            menu:(provided, state)=>({
                                ...provided,
                                width:"260px"
                            })
                        }}
                        onChange={handleFilterOptions1}
                        options={years}
                        value={selectedfilters.yearFilter}
                        required
                    />
                    
                </div>
                {/* <div className="form-group col-xl-4 col-lg-4">
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy" // Format to display only month and year
                        showMonthYearPicker
                        minDate={new Date(1900, 0)} // Set the minimum year you want to allow
                        maxDate={new Date(2100, 11)}
                        className="custom-datepicker"
                        calendarClassName="custom-datepicker-dropdown"// Set the maximum year you want to allow
                    />
                </div> */}
                
                {(localStorage.getItem('role')=='tpt')?'':
                <div className="form-group col-xl-4 col-lg-4">
                    <Select
                        closeMenuOnSelect={true}
                        name="filterModeType"
                        // className="border-radius-0"
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                fontSize: "0.9rem",
                                color: "#6e707e",
                                borderRadius: "11px",
                                height: "48px",
                                width: "260px",
                                // textAlign: "center",
                                // marginRight: '25px'
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                fontSize: "0.9rem", // Adjust the font size here
                            }),
                            menu:(provided, state)=>({
                                ...provided,
                                width:"260px"
                            })
                        }}
                        onChange={handleFilterOptions}
                        options={transList}
                        value={selectedfilters.transfilter}
                        required
                    />
                </div>}
                
                <div className="form-group col-xl-4 col-lg-4">
                    <button
                        
                        onClick={getdata}
                        className="btn btn-success"
                    >
                        Submit
                    </button>
                </div>
                
               
                </div>
                </div>
                <div className="col-sm-4">
                    <div className="row">
                    <div className="col textAlignRight">                   
                    <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                    Back 
                    </a>
                     </div>
                    </div>
                </div>
                </div>  
                {/* <div className="row" >
                <div className="col-sm-12" >
                <div id="idgrid" style={{ width: "100%", height: "550px" }} className="ag-theme-balham ">
      
                <AgGridReact
                    columnDefs={columnwithDef}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        editable: false,
                        resizable: true
                    }}
                    headerHeight={70}
                     // rowHeight={70}
                    rowData={rowData}
                    suppressRowClickSelection={true}
                    frameworkComponents={{}}
                    enableCellChangeFlash={true}
                    suppressCellFlash={true}
                    enableRangeSelection={true}
                    paginationPageSize={100}
                    rowSelection={'multiple'}
                    masterDetail={true}
                    pagination={true}
                    sideBar={{
                        toolPanels: [
                            {
                                id: "columns",
                                labelDefault: "Columns",
                                labelKey: "columns",
                                iconKey: "columns",
                                toolPanel: "agColumnsToolPanel"
                            },
                            {
                                id: "filters",
                                labelDefault: "Filters",
                                labelKey: "filters",
                                iconKey: "filter",
                                toolPanel: "agFiltersToolPanel"
                            }
                        ]
                    }}
                    statusBar={{
                        statusPanels: [
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" }
                        ]
                    }}
                    gridOptions={{
                        icons: {
                            next: `<img src="${customNextIcon}" alt="Next" />`,
                            previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                            first: `<img src="${customFirstIcon}" alt="First" />`,
                            last: `<img src="${customLastIcon}" alt="Last" />`,
                        },
                    }}
                    ref={gridRef}
                     onCellEditingStopped={handleCellValueChanged}
                     onGridReady={onGridReady}
                    context={{}}
                    getRowStyle={getRowStyle}
                />
            </div>
            </div>
                </div> */}


                <div className="" style={{ margin: '10px', marginTop: '12px', width: "100%", height: "478px" }}>
                            <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                                <AgGridReact
                                    ref={gridApi}
                                    columnDefs={columnwithDef}
                                    defaultColDef={{
                                        sortable: true,
                                        filter: true,
                                        editable: false,
                                        resizable: true,
                                        menuTabs: ['filterMenuTab']
                                        // rowHeight: 80,
                                    }}
                                    // paddingTop={10}
                                    // rowHeight={70}
                                    //  headerHeight = {70}
                                    // headerHeight={50}
                                    rowData={rowData}
                                    suppressRowClickSelection={true}
                                    // detailCellRendererParams={state.detailCellRendererParams}
                                    // frameworkComponents={{ ConsignmentTrucksLegs: ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    onGridReady={onGridReady}
                                    // onGridState={onGridState}
                                    enableRangeSelection={true}
                                    paginationPageSize={50}
                                    rowSelection={'multiple'}
                                    masterDetail={true}
                                    pagination={true}
                                    sideBar={{
                                        toolPanels: [
                                            {
                                                id: "columns",
                                                labelDefault: "Columns",
                                                labelKey: "columns",
                                                iconKey: "columns",
                                                toolPanel: "agColumnsToolPanel"
                                            },
                                            {
                                                id: "filters",
                                                labelDefault: "Filters",
                                                labelKey: "filters",
                                                iconKey: "filter",
                                                toolPanel: "agFiltersToolPanel"
                                            }
                                        ]
                                    }}
                                    statusBar={{
                                        statusPanels: [
                                            { statusPanel: "agFilteredRowCountComponent" },
                                            { statusPanel: "agSelectedRowCountComponent" },
                                            { statusPanel: "agAggregationComponent" }
                                        ]
                                    }}
                                    gridOptions={{
                                        icons: {
                                            // You can customize other icons as well
                                            next: `<img src="${customNextIcon}" alt="Next" />`,
                                            previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                            first: `<img src="${customFirstIcon}" alt="First" />`,
                                            last: `<img src="${customLastIcon}" alt="Last" />`,
                                        },
                                    }}
                                    onCellEditingStopped={handleCellValueChanged}
                                    // ref={gridRef}
                                    getRowStyle={(params) => ({ textAlign: 'left' })}
                                // context={{ onClickShowShipmentLegs, updateContainerDetails }}
                                />

                            </div>
                        </div>
            </div>
            </div>   
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHide}></div>

        </div>
        </>
    )
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
// const getCurrentWeekDates = () => {
//     let currentDate = moment(); // Get the current date
//     let startOfWeek = currentDate.clone().startOf('week'); // Get the start of the week (Sunday)
//     let endOfWeek = startOfWeek.clone().add(6, 'days'); // Get the end of the week (Saturday)

//     // Adjust startOfWeek to be the current date if it's later in the week than Sunday
//     if (currentDate > startOfWeek) {
//         startOfWeek = currentDate.clone();
//     }

//     let dates = []; // Array to store the dates

//     // Loop through each day of the week and format the date as '22-Feb-2024'
//     for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'days')) {
//         dates.push(date.format('DD-MMM-YYYY'));
//     }

//     return dates;
// };
const getCurrentWeekDates = () => {
    let currentDate = moment(); // Get the current date

    // Calculate the start date as today
    let startOfWeek = currentDate.clone();

    // Calculate the end date as the 3rd of next month
    let endOfWeek = moment().endOf('month').add(3, 'days');

    let dates = []; // Array to store the dates

    // Loop through each day of the week and format the date as '22-Feb-2024'
    for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'days')) {
        dates.push(date.format('DD-MMM-YYYY'));
    }

    return dates;
};

export default OnTimePerformanceComponent