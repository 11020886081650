import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Select from "react-select";

import { Navigate, Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHHMM } from '../common/utils';
// import AutometricsEIcon from "../../assets/icons/left-align.png"
import logo from '../../assets/icons/logo.png'
import ConsignmentTrucksLegs from '../layouts/containertruckslegs';
import WeatherMap from '../layouts/weathermap';
import redirectURL from "../redirectURL";
import enmovilLogo from "../../assets/icons/enmovil.png"
import Modal from 'react-responsive-modal';
import moment from 'moment';
import MyContext from '../sidebar/context';

export default class Navbar extends Component {
    static contextType = MyContext;
    constructor(props) {
        super(props);
        this.state = {
            workorderlist: [],
            open: false,
            selectedwr: null,
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                menuTabs: ['filterMenuTab'],
                editable: true,
            },
            overly:'show-n',
            rowData: [],
            rowData1: [],
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 1000,
            geofencelist: [],
            sliderRouteTranslate: "",
            detailedWeatherMap: 0,
            geoFenceData: "",
            frameworkComponents: {
                ConsignmentTrucksLegs: ConsignmentTrucksLegs
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
        }
        this.onClickShowShipmentLegs = this.onClickShowShipmentLegs.bind(this)
    }
    logout = async (e) => {
        // e.preventDefault();
        // await localStorage.removeItem('token');
        // await localStorage.removeItem('email');
        // await localStorage.removeItem('user_type');
        // await localStorage.removeItem('role');
        // await localStorage.removeItem('user_code');
        // await localStorage.clear();
        // sessionStorage.setItem('ref',null);
        // //localStorage.setItem('menuText',[]);
        // await delete axios.defaults.headers.common['Authorization'];
        // this.setState({
        //     redirectToReferrer:''
        // });
        // //console.log("logout successfully",localStorage.getItem('token'));
        // window.location.reload();
        // await relaodpage(loginType);
        localStorage.clear();
    }
    componentDidMount() {
        redirectURL.post('/consignments/getworkordernos').then((resp) => {
            var workorderlist = []
            var data = resp.data
            data.map((e) => {
                workorderlist.push({ 'label': e, 'value': e })
            })
            this.setState({
                workorderlist: workorderlist
            })
        })
    }
    changeHandler = (e) => {
        this.setState({
            sliderRouteTranslate: "slider-translate-60p",
            overly: "show-m",
        })
    }
    handleOnChange = (e) => {
        this.setState({
            open: true,
            selectedwr: { label: e.value, value: e.value }
        })
        var params = {
            'work_order_no': e.value
        }
        redirectURL.post('/consignments/getwrdata', params).then((resp) => {
            var data = resp.data
            this.setState({
                rowData: data
            })
        })
        redirectURL.post('/consignments/getlegsdata', params).then((resp1) => {
            var data1 = resp1.data
            this.setState({
                rowData1: data1
            })
        })
    }
    onClickShowShipmentLegs = async (rownode) => {
        //console.log("rownode", rownode)
        var commentHeaders = [
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHHMM(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,

            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Plant Gate-In Time',
                field: "gate_in_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },

            {
                headerName: 'Plant Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },


            {
                headerName: 'Reporting Time At Parking Plaza',
                field: "reporting_at_parking",
                resizable: true,
                width: 240,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.reporting_at_parking);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },

            {
                headerName: 'Gate-In Time At Port',
                field: "gate_in_time_port",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time_port != "" && params.data.gate_in_time_port != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time_port);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },
            {
                headerName: 'Shipping On Board Date',
                field: "shipping_on_board_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.shipping_on_board_date != "" && params.data.shipping_on_board_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.shipping_on_board_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },
            {
                headerName: 'BL No',
                field: "bl_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Bl Date',
                field: "bl_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.bl_date != "" && params.data.bl_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.bl_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            }
        ]
        await this.setState({

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',

                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    var parameters = {
                        'work_order_no': param.data.work_order_no
                    }
                    await redirectURL.post("/consignments/getlegsdata", parameters).then(async (response) => {
                        ////console.log("Step 1 ",response.data)
                        // //console.log(response.data.data);
                        var comments = response.data;
                        var output = []
                        // output.sort(GetSortASCOrder("orderpos"));
                        param.successCallback(comments);


                        ////console.log("Step 2 ",response.data)

                    }).catch(function (error) {
                        //console.log(error);
                    })

                },
                masterDetail: true
            }
        });
        // //console.log(propsdata);
        if (rownode.column.colDef.field == 'shipmentlegs') {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else {

            rownode.node.setExpanded(false);
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
        ////console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });
        this.gridApi.forEachLeafNode((node) => {
            //console.log("node here ", node)
            node.setSelected(true);

        });

    };


    onGridState = () => {
        //console.log(this.gridApi);
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    handleSidebar = () => {
        if ($(".sidebarMain").css('display') != "block") {
            $(".sidebarMain").css('display', 'block')
        } else {
            $(".sidebarMain").css('display', 'none')
        }

        if ($("#logo-card-navbar").css('display') != "block") {
            $("#logo-card-navbar").css('display', 'block')
        } else {
            $("#logo-card-navbar").css('display', 'none')
        }

        // $("#logo-card-navbar").css('display','none')
    }
    onCloseModal = () => {
        this.setState({
            open: false,
            selectedwr: null
        });
    }
    onClickHideAll = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            sliderTranslate: "",
            overly: "show-n"
        })
    }
    
    onCloseRouteDiv = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            overly: "show-n"
        })
    }


    showHideMenus() {
        // $("#sidebarToggleTop").on('click', function(e) {

        var body = $("body");
        //console.log("body", body.hasClass("sidebar-toggled"))
        if ($("body").hasClass("sidebar-toggled")) {
            $("body").removeClass("sidebar-toggled");
            $(".sidebar").show();
            $(".c-logo").hide();
            $(".c-logo").addClass("d-none");
            $(".logosec").removeClass("logopsb");
            $("#sidebarToggleTop").removeClass("float-right")
            $("#sidebarToggleTop").removeClass("toglbtn")
        }
        else {
            $("body").addClass("sidebar-toggled");
            $(".sidebar").hide();
            $(".c-logo").show();
            $(".c-logo").removeClass("d-none");
            $(".logosec").addClass("logopsb");
            $("#sidebarToggleTop").addClass("float-right")
            $("#sidebarToggleTop").addClass("toglbtn")
        }
        // $("body").toggleClass("sidebar-toggled");
        // $(".sidebar").toggleClass("toggled");
        // if ($(".sidebar").hasClass("toggled")) {
        //   $('.sidebar .collapse').hide();
        // };
        //   });
    }
    render() {
        const {currentActivity}= this.context;
        const { open, selectedwr } = this.state;
        if (localStorage.getItem("token") === "" || localStorage.getItem("token") === undefined || localStorage.getItem("token") === null) {
            return <Navigate to={"/login"} push={true} />
        }
        var url = window.location.pathname
        //console.log(url, '88')
        var columnDefs = [
            // {
            //     headerName: "",
            //     field: "containerLegs",
            //     width: 60,
            //     pinned: 'left',
            //     cellRenderer: 'ConsignmentTrucksLegs'
            // },
            {
                headerName: "Work Order No",
                field: "work_order_no",
                width: 140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "WO Release Date",
                field: "wo_release_date",
                width: 160,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.wo_release_date);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Production Date",
                field: "production_date",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.production_date != "" && params.data.production_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.production_date);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Plan Month",
                field: "plan_month",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Status",
                field: "status",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.new_plant == 1) {
                        return 'New'
                    }
                    else if (params.data.cha_vessel_new == 1) {
                        return 'At CHA Vessel Availability'
                    }
                    else if (params.data.ib_stuffing_new == 1) {
                        return 'At IB Stuffing'
                    }
                    else if (params.data.cha_do_new == 1) {
                        return 'At Upload DO'
                    }
                    else if (params.data.ib_tpt_new == 1) {
                        return 'At IB Tpt Allocation'
                    }
                    else if (params.data.tpt_trucks_new == 1) {
                        return 'At Update Trucks'
                    }
                    else if (params.data.tpt_container_new == 1) {
                        return 'At Update Containers'
                    }
                    else if (params.data.ib_plant_gate_in_new == 1) {
                        return 'In Transit to Plant'
                    }
                    else if (params.data.at_plant_new == 1) {
                        return 'At Plant'
                    }
                    else if (params.data.shipping_new == 1) {
                        return 'At Shipping Bill'
                    }
                    else if (params.data.cha_reporting_new == 1) {
                        return 'In Transit to Parking Plaza'
                    }
                    else if (params.data.cha_leo_new == 1) {
                        return 'At LEO'
                    }
                    else if (params.data.cha_gate_in_port_new == 1) {
                        return 'At Port Gate In'
                    }
                    else if (params.data.cha_shipping_onboard_new == 1) {
                        return 'At Shipping Onboard'
                    }
                    else if (params.data.cha_bl_new == 1) {
                        return 'At B/L'
                    }
                    else if (params.data.buffer_raised == 1) {
                        return 'Awaiting At Buffer Approval'
                    }
                    else if (params.data.buffer_accepted == 1) {
                        return 'At Buffer Yard'
                    }
                    else {
                        return 'Completed'
                    }
                }
            },
            {
                headerName: "Ship To Country",
                field: "ship_to_country",
                width: 130,
                filter: true,
                resizable: true,
                // cellRenderer: 'CountryFlagComponent'
            },
            {
                headerName: "Destination Country",
                field: "destination_country",
                width: 160,
                filter: true,
                resizable: true,
                // cellRenderer: 'DestinationFlagComponent'
            },
            {
                headerName: "40ft Containers",
                field: "ft40_containers",
                width: 140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "20ft Containers",
                field: "ft20_containers",
                width: 140,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Pendency",
            //     field: "total_trucks",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            // },
            {
                headerName: "POD",
                field: "pod",
                width: 200,
                filter: true,
                resizable: true,
            },

            {
                headerName: "Plant",
                field: "plant",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "SKU Code",
                field: "sku_code",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Brand",
                field: "brand",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Brand Variant",
                field: "brand_variant",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Quantity",
                field: "quantity",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Assy Config Type",
                field: "assy_config_type",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Quotation",
                field: "quotation",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Latest Shipment Date",
                field: "latest_shipment_date",
                width: 160,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "VIN Type",
                field: "vin_type",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Hardware Mode",
                field: "hardware_mode",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Payment Term",
                field: "payment_term",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Vessel Name",
                field: "vessel_name",
                width: 200,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Tentative Gate Open Date",
            //     field: "tentative_gate_open_date",
            //     width: 170,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
            //             } else {
            //                 return "Not Available";
            //             }
            //         } catch (e) {
            //         }
            //     },
            // },

            // {
            //     headerName: "Tentative Cut Off Date ",
            //     field: "tentative_cut_off_date",
            //     width: 170,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
            //             } else {
            //                 return "Not Available";
            //             }
            //         } catch (e) {
            //         }
            //     },
            // },
            {
                headerName: "SL Cut Off Date",
                field: "sl_cutoff_date",
                width: 170,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.sl_cutoff_date != "" && params.data.sl_cutoff_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.sl_cutoff_date);
                        } else {
                            return "Not Available";
                        }
                    } catch (e) {
                    }
                },
            },
            {
                headerName: "Stuffing Date",
                field: "stuffing_date",
                width: 150,
                filter: true,
                resizable: true,
                editable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.stuffing_date);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
            },
            // {
            //     headerName: "Booking No",
            //     field: "booking_no",
            //     width: 160,
            //     filter: true,
            //     resizable: true,
            // },

            {
                headerName: "CHA",
                field: "cha",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Expected Payment Clearance By",
                field: "expected_payment_clearance_by",
                width: 220,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                            return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "HAZ Classification",
                field: "haz_classification",
                width: 160,
                filter: true,
                resizable: true,
            }, {
                headerName: "Booking No",
                field: "booking_no",
                width: 200,
                filter: true,
                resizable: true,
            },

            {
                headerName: "DO Valid From",
                field: "do_valid_from",
                width: 190,
                filter: true,
                resizable: true,
                editable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.do_valid_from != "" && params.data.do_valid_from != undefined) {
                            return getHyphenDDMMYYYY(params.data.do_valid_from);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "DO Valid To",
                field: "do_valid_to",
                width: 190,
                filter: true,
                resizable: true,
                editable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.do_valid_to != "" && params.data.do_valid_to != undefined) {
                            return getHyphenDDMMYYYY(params.data.do_valid_to);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Shipping Line",
                field: "shipping_line",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Freight Forwarder",
                field: "freight_forwarder",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Pickup Yard",
                field: "pickup_yard",
                width: 200,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Shipping Bill No",
            //     field: "shipping_bill_no",
            //     width: 160,
            //     filter: true,
            //     resizable: true,
            // },
            // {
            //     headerName: "Shipping Bill Date",
            //     field: "shipping_bill_date",
            //     width: 160,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.shipping_bill_date != "" && params.data.shipping_bill_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.shipping_bill_date);
            //             }
            //             else {
            //                 return "Not Available";
            //             }
            //         }
            //         catch (e) { }
            //     },
            // },
            {
                headerName: 'LEO Date',
                field: "leo_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.leo_date != "" && params.data.leo_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.leo_date);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },
            },
        ]
        var columndefs1 = [
            // {
            //     headerName: 'Requirement Id',
            //     field: "requirement_id",
            //     resizable: true,
            //     width: 180,
            //     filter: true
            // },
            // {
            //     headerName: "",
            //     field: "",
            //     resizable: true,
            //     width: 60,
            //     filter: true,
            //     // cellRendererFramework: LegRouteActiveTruckMap,
            //     cellRendererFramework: (params) => {
            //         return (
            //             <div>
            //                 <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
            //                     <i className="icofont icofont-map-pins f20"></i>
            //                 </button>
            //             </div>
            //         );
            //     },
            // },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHHMM(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,

            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Plant Gate-In Time',
                field: "gate_in_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },

            {
                headerName: 'Plant Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },


            {
                headerName: 'Reporting Time At Parking Plaza',
                field: "reporting_at_parking",
                resizable: true,
                width: 240,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.reporting_at_parking);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },

            {
                headerName: 'Gate-In Time At Port',
                field: "gate_in_time_port",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time_port != "" && params.data.gate_in_time_port != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time_port);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },
            {
                headerName: 'Shipping On Board Date',
                field: "shipping_on_board_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.shipping_on_board_date != "" && params.data.shipping_on_board_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.shipping_on_board_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },
            {
                headerName: 'BL No',
                field: "bl_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Bl Date',
                field: "bl_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.bl_date != "" && params.data.bl_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.bl_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            }
        ]
        //console.log(this.state.sliderRouteTranslate,'sliderRouteTranslate')
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-2 static-top shadow navbarHeight" style={{ height: '50px' }}>
                <h4 className="txt-center">
                    <img src={logo} alt="" width="125px" style={{ position: 'relative', top: '10px' }} />
                    <div className='poweredby' style={{ marginBottom: '5px' }}>
                        {/* <span className='poweredSpan' style={{fontSize:'8px'}}>Powered by</span> */}
                        {/* <img src={enmovilLogo} alt="" className='enmovil-log' style={{width:'78px'}}/> */}
                    </div>
                </h4>

                {/* <span className="logosec" id='navIcon' style={{background:"#3e64ff", position: 'absolute',width: '180px', height: '71px', left: '0px'}}>
                        <span className="c-logo">
                            <a className="sidebar-brand d-flex align-items-center justify-content-center" href={"/"}>
                                <div className="sidebar-brand-icon rotate-n-15">
                                    <i className="fas fa-fw fa-chart-area"></i>
                                </div>
                                <div className="sidebar-brand-text mx-3">Enmovil - Dashboard</div>
                            </a>
                            <Link to="/" className="logo" style={{color: "white", position: 'relative',top: '20px'}} >
                                <span className="main-logo" >autom</span>
                                <img src={AutometricsEIcon} alt="" width="25px" style={{position: 'relative', top: '-8px'}} />
                                <span className="main-logo">rics</span> 
                                <span className="version-style">2.0</span>
                            </Link>
                        </span>
                        
                        
                    </span> */}
                {/* <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-3" onClick={this.showHideMenus.bind(this)}>
                            <i className="fa fa-bars"></i>
                        </button> */}
                {/* <span style={{marginLeft:"14rem"}}>
                    <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    </span> */}
                {url == '/container-tracking' ?
                    <div className='mb-10p ml-auto'>
                        <h5 className="heading mt-20p txt-center">
                            Container Tracking
                        </h5>
                    </div> : <div className='mb-10p ml-auto'>
                        <h5 className="heading1 mt-20p txt-center">
                            Export Operations Control Tower
                        </h5>
                    </div>
                }




                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-search fa-fw"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>
                    <li>
                        {/* <Select
                            closeMenuOnSelect={true}
                            name=""
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    borderRadius: "11px",
                                    height: "40px",
                                    width: "260px",
                                    marginTop: "14px",
                                    // padding:"22px",
                                    textAlign: "center",
                                    placeholder:"Select..."
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    fontSize: '0.9rem'
                                })
                            }}
                            value={selectedwr}
                            options={this.state.workorderlist}
                            onChange={this.handleOnChange.bind(this)}
                            required
                        /> */}
                        <Select
                            closeMenuOnSelect={true}
                            name=""
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    borderRadius: "11px",
                                    height: "40px",
                                    width: "260px",
                                    marginTop: "14px",
                                    textAlign: "center",
                                    placeholder: "Select...",
                                    outline: 'none'
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    fontSize: '0.9rem'
                                })
                            }}
                            value={selectedwr}
                            options={this.state.workorderlist}
                            onChange={this.handleOnChange.bind(this)}
                            onBlur={() => {
                                if (this.state.open != true) {
                                    // Remove focus and blur the select dropdown when the modal is closed
                                    document.activeElement.blur(); // Blur the currently focused element
                                }
                            }}
                            required
                        />

                    </li>
                    {/* <li>
                        <a href="#" className="" onClick={this.changeHandler.bind(this)}>
                            <i className="icofont icofont-ui-weather" style={{ color: "#000", height: '50px', width: '100px' }}></i>
                        </a>
                    </li> */}
                    <li >
                        <div className="">
                            <img src={require("../../assets/images/bajaj.png")}
                                className="logo-login ml-15p mr-10p" alt="" style={{ width: '145px', height: '30px', marginTop: "20px" }} />
                        </div>
                    </li>
                    {/* <li className="nav-item dropdown no-arrow mx-1">
                                <a href="/" className="nav-link dropdown-toggle">
                                    <i className="icofont icofont-home"></i>
                                </a>
                            </li> */}
                    <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle" onClick={this.changeHandler.bind(this)} 
                                 >
                                <i className="icofont1 icofont-ui-weather"></i>
                            </a>
                            </li>
                    {/* <li className="nav-item dropdown no-arrow mx-1">
                                <div className=''>
                                {localStorage.getItem('user_type')=='admin'?
                                <a href={"/bookingmanagementsystem"} className="txt-dec-none clr333">
                                    <i className="icofont icofont-home" style={{position:'relative',top:'20px'}}></i>
                                </a>
                                :''}
                                {localStorage.getItem('user_type')=='cha'?
                                <a href={"/booking/cha"} className="txt-dec-none clr333">
                                    <i className="icofont icofont-home" style={{position:'relative',top:'20px'}}></i>
                                </a>
                                :''}
                                {localStorage.getItem('user_type')=='plant'?
                                <a href={"/booking/plant"} className="txt-dec-none clr333">
                                    <i className="icofont icofont-home" style={{position:'relative',top:'20px'}}></i>
                                </a>
                                :''}
                                 {localStorage.getItem('user_type')=='transporter'?
                                <a href={"/booking/transporter"} className="txt-dec-none clr333">
                                    <i className="icofont icofont-home" style={{position:'relative',top:'20px'}}></i>
                                </a>
                                :''}
                                </div>
                            </li> */}
                    {/* <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-bell fa-fw"></i>
                                <span className="badge badge-danger badge-counter">3+</span>
                            </a>
                            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="alertsDropdown">
                                <h6 className="dropdown-header">
                                    Alerts Center
                                </h6>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 12, 2019</div>
                                        <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-success">
                                            <i className="fas fa-donate text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 7, 2019</div>
                                        $290.29 has been deposited into your account!
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-warning">
                                            <i className="fas fa-exclamation-triangle text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 2, 2019</div>
                                        Spending Alert: We've noticed unusually high spending for your account.
                                    </div>
                                </a>
                                <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                            </div>
                        </li> */}

                    {/* <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-envelope fa-fw"></i>
                                <span className="badge badge-danger badge-counter">7</span>
                            </a>
                            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="messagesDropdown">
                                <h6 className="dropdown-header">
                                    Message Center
                                </h6>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                            alt="..." />
                                        <div className="status-indicator bg-success"></div>
                                    </div>
                                    <div className="font-weight-bold">
                                        <div className="text-truncate">Hi there! I am wondering if you can help me with a
                                            problem I've been having.</div>
                                        <div className="small text-gray-500">Emily Fowler · 58m</div>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                            alt="..." />
                                        <div className="status-indicator"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">I have the photos that you ordered last month, how
                                            would you like them sent to you?</div>
                                        <div className="small text-gray-500">Jae Chun · 1d</div>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                            alt="..." />
                                        <div className="status-indicator bg-warning"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">Last month's report looks great, I am very happy with
                                            the progress so far, keep up the good work!</div>
                                        <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                            alt="..." />
                                        <div className="status-indicator bg-success"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                                            told me that people say this to all dogs, even if they aren't good...</div>
                                        <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                    </div>
                                </a>
                                <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                            </div>
                        </li> */}
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-lg-inline text-gray-600 small">{localStorage.getItem("email")}</span>
                            {/* <i className="icofont icofont-user"></i> */}
                            <img src={require("../../assets/images/user-icon.png")} />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            {/* <a className="dropdown-item" href="#">
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Profile
                                </a>
                                <a className="dropdown-item" href="#">
                                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Settings
                                </a>
                                <a className="dropdown-item" href="#">
                                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Activity Log
                                </a> */}
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" to={"/login"} onClick={this.logout.bind(this)}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </Link>
                        </div>
                    </li>
                </ul>
                <Modal open={open} onClose={this.onCloseModal.bind(this)} >
                    <div className={""}>
                        <h1 className='nav-item ml-40p mt-50p' style={{ color: "black" }}>Work Order Data</h1>
                        <div id="idgrid" open={open} onClose={this.onCloseModal} style={{ width: "80vw", height: "25vh" }} className="ag-theme-balham m-10p ml-40p">
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                // enableCharts={false}
                                // enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                // statusBar={this.state.statusBar}
                                // sideBar={this.state.sideBar}
                                // paginationPageSize={this.state.paginationPageSize}
                                // pagination={true}
                                // components={this.state.components}
                                frameworkComponents={this.state.frameworkComponents}
                                singleClickEdit={true}
                                //floatingFilter={true}
                                // onCellEditingStopped={this.onCellEditData.bind(this)}
                                //onCellClicked={this.popmarker}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                            />

                        </div>
                        <h1 className='nav-item ml-40p mt-20p' style={{ color: "black" }}>Container Data</h1>

                        <div id="idgrid" open={open} onClose={this.onCloseModal} style={{ width: "80vw", height: "40vh" }} className="ag-theme-balham m-10p ml-40p mb-30p">
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={columndefs1}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData1}
                                enableCharts={false}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                // statusBar={this.state.statusBar}
                                // sideBar={this.state.sideBar}
                                // paginationPageSize={this.state.paginationPageSize}
                                // pagination={true}
                                // components={this.state.components}
                                frameworkComponents={this.state.frameworkComponents}
                                singleClickEdit={true}
                                //floatingFilter={true}
                                // onCellEditingStopped={this.onCellEditData.bind(this)}
                                //onCellClicked={this.popmarker}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                            />

                        </div>
                    </div>
                </Modal>
                {currentActivity === 'sidebar' ? "" : <>{this.state.sliderRouteTranslate != '' ?
                    <WeatherMap context={this}
                        sliderValue={this.state.sliderRouteTranslate}
                        onCloseRouteDiv={this.onCloseRouteDiv.bind(this)}
                        detailedWeatherMap={this.state.detailedWeatherMap}
                    />
                    : ""
                }</>}
            <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
            </nav>
        );
    }
}