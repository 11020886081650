
import React, { Component } from 'react';
 
const ViewPODButton = (props) => {
    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onClickViewPOD(props);
    // };
// console.log("props ", props.data)
    return (
        <div>
            <a href={(props.data.pod_document !== "" && props.data.pod_document !== undefined)?props.data.pod_document:"#"} target="_blank" 
              className={"btn btn-info nopadding font12"} 
              title="View POD" >
                  <i className="icofont icofont-eye f16"></i>&nbsp; View
            </a>
          
        </div>
    );
};

export default ViewPODButton;
