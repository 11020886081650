

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from './updateManualExportsBtn';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import download from 'js-file-download';

import redirectURL from "../redirectURL";
import withRouter from '../withRouter';
import Modal from "react-responsive-modal";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class ChaSeaImportsList extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
            resizable: true,
            menuTabs: ['filterMenuTab']
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        pending:"activet",
        reportToChaTab:"",
        gateInTab:"",
        shippedOnboardTab:"",
        blUploadTab:"",
        closure:"",
        invoiceData:[],
        pageType:1,
        ccSlider : "", 
        jobNo : "",
        ccData : [],
        cc_docs : "",
        statusDate:"",
        detailCellRendererParams:{},
        gateOpenDate:"",
        slCutOffDate:"",
        vesselName:"",
        shippingLine:"",
        vesselEtd:"",
        openModal: false,
        chaLegFlag:2
        //components : {datepicker:getDatePicker()}
    }
    this.downloadRingiFile = this.downloadRingiFile.bind(this);
    this.changeDocFileHandler = this.changeDocFileHandler.bind(this);
    this.uploadCCFileFormHandler = this.uploadCCFileFormHandler.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}

componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
}

onLoadPageData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    
    var supplier_code = localStorage.getItem("supplier_code");
    var params = {
        "cha_flag" : 1,
        "do_file_url": {$exists: 0}
    }
    redirectURL.post("/consignments/getBookingData",params)    
    .then((response) => {
        var records = response.data.bookingData;
        records.forEach(object => {
            object.chaLegFlag = 1;
        });
        console.log("records ", records)
        this.setState({
            rowData : records,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch(function(e){
        console.log("Error ",e)
    })
}

onLoadInProcessData(chaLegFlag, chaFlagArr = [2,3,4]){
    console.log("chaLegFlag===>", chaLegFlag)
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    
    // var supplier_code = localStorage.getItem("supplier_code");
    var params = {
        "cha_flag" : {$in: chaFlagArr}
    }
    redirectURL.post("/consignments/getBookingData",params)    
    .then((response) => {
        var records = response.data.bookingData;
        records.forEach(object => {
            object.chaLegFlag = chaLegFlag;
        });
        
        console.log("records ", records)
        this.setState({
            rowData : records,
            overly:"show-n",
            loadshow:"show-n",
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
    // var params = {
    //     cha_flag: 2
    // }
    // redirectURL.post("/consignments/getLegsDataForBooking", params).then((res) => {
    //     if (res.data.status == "success") {
    //         this.setState({
    //             rowData : res.data.legsData,
    //             overly:"show-n",
    //             loadshow:"show-n",
    //         })
    //     }
    // })
}

onLoadClosureData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    
    var params = {
        "cha_flag" : 7
    }
    redirectURL.post("/consignments/getBookingData",params)    
    .then((response) => {
        var records = response.data.bookingData;
        console.log("records ", records)
        this.setState({
            rowData : records,
            overly:"show-n",
            loadshow:"show-n",
            // gridRowNode:""
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}

previewDocument = (url) => {
    console.log("document", url);
    this.setState({
      openModal: true,
      documentUrl:url,
      documentType: "pdf",
    });
};

closeModal = () => {
    this.setState({
        openModal: false,
    });
    };

onShowTicketHistory = async (rowdata) =>{
    console.log("rowdata ", rowdata)
    // this.setState({
    //     gridRowNode: rowdata,
    // })
    var transitedelaydata=[];
    
    await this.setState({
            
        detailCellRendererParams:{
            // suppressRefresh: true,
            refreshStrategy: 'everything',
            detailGridOptions: {
                enableCellChangeFlash: true,
                columnDefs: [
                    { 
                        headerName: "Update",
                        field: "", 
                        width:180,
                        resizable: true,
                        cellRendererFramework: (params)=>{ 
                            if(params.data.cha_flag != 5) {
                                return <button className='btn btn-primary'
                                style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                                onClick={this.openDocumentsSlider.bind(this, params.data)}>Update</button>
                            }else{
                                return <button className='btn btn-primary'
                                style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                                disabled>Update</button>
                            }
                        }
                        // valueGetter:function(params){
                        //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                        // },
                    },						
                    { 
                        headerName: "Work Order No",
                        field: "booking_no",
                        width:160,
                        resizable: true,
                    },
                    { 
                        headerName: "Requirement Id",
                        field: "requirement_id", 
                        width:180,
                        resizable: true,
                    },
                    { 
                        headerName: "Reporting to CHA Time",
                        field: "reporting_to_cha_datetime", 
                        width:180,
                        resizable: true,
                        valueGetter: function(params){
                            try{
                                if(params.data.reporting_to_cha_datetime !='' && params.data.reporting_to_cha_datetime !=undefined)
                                {
                                    return getHyphenDDMMYYYY(params.data.reporting_to_cha_datetime);
                                }
                            }
                            catch(e){
                            }
                        }   
                    },
                    { 
                         headerName: "Gate In Time",
                         field: "gatein_datetime", 
                         width:180,
                         resizable: true,
                         valueGetter: function(params){
                            try{
                                if(params.data.gatein_datetime !='' && params.data.gatein_datetime !=undefined)
                                {
                                    return getHyphenDDMMYYYY(params.data.gatein_datetime);
                                }
                            }
                            catch(e){
                            }
                        }   
                    },						
                    { 
                         headerName: "Shipped Onboard Time",
                         field: "shipped_onboard_time", 
                         width:180,
                         resizable: true,
                         valueGetter: function(params){
                            try{
                                if(params.data.shipped_onboard_time !='' && params.data.shipped_onboard_time !=undefined)
                                {
                                    return getHyphenDDMMYYYY(params.data.shipped_onboard_time);
                                }
                            }
                            catch(e){
                            }
                        }   
                    },
                    {
                        headerName: "Truck No",
                        field: "truck_number",
                        width:120,
                        filter: true,
                        resizable: true,
                    },
                    // {
                    //     headerName: "Pickup Date Time",
                    //     field: "pickup_date_time",
                    //     width:120,
                    //     filter: true,
                    //     resizable: true,
                    // },
                    {
                        headerName: "View LR Details",
                        field: "",
                        width: 150,
                        filter:false,
                        floatingFilter:false,
                        cellRendererFramework: (params)=>{return <button className='btn btn-primary' 
                        style={{ fontSize:"12px", width:"125px", cursor:"pointer" }} 
                        onClick={this.previewDocument.bind(this, params.data.lr_file_url)}>View LR Details</button>}
                    },
                    {
                        headerName: "Container No",
                        field: "container_number",
                        width:120,
                        filter: true,
                        resizable: true,
                    }						
                ],
                overlayNoRowsTemplate: 'No data available',                     
            },
            getDetailRowData: function(params) {
                console.log("Step 4 ",params)
                var qu = {
                    // ticket_id:params.data.ticket_id,
                    booking_no:params.data.booking_no,
                    cha_flag: params.data.chaLegFlag
                }
                redirectURL.post('/consignments/getBookingLegsData', qu)
                .then(async (response) =>{
                    console.log("Step 1 ",response.data)


                    params.successCallback(response.data.bookingData);
                    //console.log("Step 2 ",response.data)
                    
                });
                
            }
        }
    });

    //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
    if(rowdata.column.colDef.field == '_id')
    {
        rowdata.node.setExpanded(!rowdata.node.expanded);
        if(rowdata.node.expanded == false)
        {
            transitedelaydata=[]
        }
    }
    else{

        rowdata.node.setExpanded(false);
    }
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    invoiceData:[],
    ccData:[],
    ccSlider:"",
    transporterTranslate: "",
    jobNo:"",
    cc_docs:"",
    statusDate:""

    });
    
}

onClickTab(pageType){
    if(pageType == "pending")
    {
        var pending = "activet";
        var reportToChaTab= "";
        var gateInTab= "";
        var shippedOnboardTab= "";
        var blUploadTab= "";
        var closure = "";
        var pageType = 1;
        this.onLoadPageData();
    }
    else if(pageType == "reportToChaTab")
    {
        var pending = "";
        var reportToChaTab= "activet";
        var gateInTab= "";
        var shippedOnboardTab= "";
        var blUploadTab= "";
        var closure = "";
        var pageType = 2;
        var chaLegFlag = 2
        this.onLoadInProcessData(2, [2]);
    }
    else if(pageType == "gateInTab")
    {
        var pending = "";
        var reportToChaTab= "";
        var gateInTab= "activet";
        var shippedOnboardTab= "";
        var blUploadTab= "";
        var closure = "";
        var pageType = 2;
        var chaLegFlag = 3
        this.onLoadInProcessData(3, [3]);
    }
    else if(pageType == "shippedOnboardTab")
    {
        var pending = "";
        var reportToChaTab= "";
        var gateInTab= "";
        var shippedOnboardTab= "activet";
        var blUploadTab= "";
        var closure = "";
        var pageType = 2;
        var chaLegFlag = 4
        this.onLoadInProcessData(4, [4]);
    }
    else if(pageType == "blUploadTab")
    {
        var pending = "";
        var reportToChaTab= "";
        var gateInTab= "";
        var shippedOnboardTab= "";
        var blUploadTab= "activet";
        var closure = "";
        var pageType = 2;
        var chaLegFlag = 5
        this.onLoadInProcessData(5,[5]);
    }
    else if(pageType == "closure")
    {
        var pending = "";
        var reportToChaTab= "";
        var gateInTab= "";
        var shippedOnboardTab= "";
        var blUploadTab= "";
        var closure = "activet";
        var pageType = 3;
        this.onLoadClosureData();
    }
    this.setState({
        pending:pending,
        reportToChaTab:reportToChaTab,
        gateInTab:gateInTab,
        shippedOnboardTab:shippedOnboardTab,
        blUploadTab:blUploadTab,
        closure:closure,
        pageType:pageType,
        chaLegFlag:chaLegFlag
    })
}

viewDetails(e){
    if(e.colDef.field == "invoiceData")
    {
        var invoiceData = JSON.parse(e.data.invoiceData);
        var sliderTitle = e.data.job_no+" - Pre alert documents";
        this.setState({
            overly :"show-m",
            invoiceData:invoiceData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    if(e.colDef.field == "other_docs")
    {
        var ringiFile = e.data.other_docs
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadRingiFile",rngParam).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    if(e.colDef.field == "ccData")
    {
        var ccData = [];
        if(e.data.ccData !=undefined && e.data.ccData !='')
        {
            ccData = JSON.parse(e.data.ccData);
        }
        var sliderTitle = e.data.job_no+" - Custom clearance documents";
        this.setState({
            overly :"show-m",
            ccData:ccData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle,
            ccSlider : 1,
        })
        var pageType = this.state.pageType;
        if(pageType == 1)
        {
            this.setState({
                jobNo : e.data.job_no,
            })
        }
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadSeaImportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        cc_docs : e.target.files[0]
    });
}
uploadCCFileFormHandler(event){
    event.preventDefault();
    var jobNo = this.state.jobNo;
    if(jobNo !=undefined && jobNo !='')
    {
        if(this.state.cc_docs !=undefined && this.state.cc_docs !='')
        {
            let saveData = new FormData();
            saveData.append("cc_docs", this.state.cc_docs);
            saveData.append("job_no", this.state.jobNo);
            saveData.append("username", localStorage.getItem("username"));
            redirectURL.post("/consignments/uploadChaCCDocuments",saveData,{
                headers:{
                    'content-type': 'multipart/form-data'
                }
            }).then((response)=>{
                var rowData = this.state.rowData;
                var ccDetails = [];
                var filteredData = rowData.filter(rec => (rec.job_no == jobNo));
                if(filteredData[0].ccData !=undefined && filteredData[0].ccData !='')
                {
                    var ccData = JSON.parse(filteredData[0].ccData);
                    for(var r=0;r<ccData.length;r++)
                    {
                        ccDetails.push({
                            "custom_clearance_file" : ccData[r].custom_clearance_file
                        });
                    }
                }
                ccDetails.push({
                    "custom_clearance_file" : this.state.cc_docs.name
                });
                $("#other_docs").val("");
                this.setState({
                    basicTitle:"Success",
                    basicType : "success",
                    show : true,
                    ccData:ccDetails,
                    cc_docs:"",
                });
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                basicTitle:"File should not be empty",
                basicType : "warning",
                show : true
            });
        }
    }
    else
    {
        this.setState({
            basicTitle:"Something Went wrong, Please try again",
            basicType : "warning",
            show : true
        });
    }
}

openDocumentsSlider = (rowData) => {
    this.setState({
      overly: "show-m",
      transporterTranslate: "slider-translate",
      gridRowData: rowData,
      chaFlagValue: rowData.cha_flag
    });
};

changeAttachmentsFileHandler(event) {
    const file = event.target.files[0];
    let validFileExtensions = [
    //   "png",
    //   "jpeg",
    //   "gif",
    //   "bmp",
      "pdf",
    //   "csv",
    //   "xlsx",
    //   "mp4",
    //   "webm",
    //   "mp3",
    ];
    const documentExtension = file.name.split(".").pop();

    if (validFileExtensions.includes(documentExtension)) {
      if (file) {
        this.setState({
          attachmentsDoc: file,
          documentExtension: documentExtension,
        });
      }else{
        this.setState({
            attachmentsDoc: "",
            documentExtension: "",
          });
      }
    } else {
      document.getElementById("attachmentsDoc").value = null;
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Invalid file format.",
      });
    }
}

handleChaPendingDetails = (e) => {
    e.preventDefault();
    console.log(this.state.gateOpenDate , this.state.slCutOffDate , this.state.vesselName , this.state.shippingLine , this.state.vesselEtd , this.state.attachmentsDoc)
    if(this.state.gateOpenDate && this.state.slCutOffDate && this.state.vesselName && this.state.shippingLine && this.state.vesselEtd && this.state.attachmentsDoc ){
        const formData = new FormData();
        formData.append("gate_open_date", moment(this.state.gateOpenDate).format('YYYY-MM-DD'));
        formData.append("sl_cutoff_date", moment(this.state.slCutOffDate).format('YYYY-MM-DD'));
        formData.append("vessel_name", this.state.vesselName);
        formData.append("shipping_line", this.state.shippingLine);
        formData.append("vessel_etd", moment(this.state.vesselEtd).format('YYYY-MM-DD'));
        formData.append("document_extension", this.state.documentExtension);
        formData.append("do_file", this.state.attachmentsDoc);  
        formData.append("booking_no",this.state.gridRowData.booking_no);

        redirectURL.post('/consignments/updatePendingChaDetails', formData).then((resp)=>{
            console.log("updatePendingChaDetails resp",resp.data);
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
                invoiceData:[],
                ccData:[],
                ccSlider:"",
                transporterTranslate: "",
                jobNo:"",
                cc_docs:"",
                statusDate:""
            },() =>{
                if(this.state.chaFlagValue == 2){
                    console.log("this.state.chaFlagValue", this.state.chaFlagValue)
                    this.onLoadPageData();
                }else if(this.state.chaFlagValue == 5){
                    this.onLoadInProcessData(5,[5]);
                }
            });
        }).catch((err) => {
            console.log("updatePendingTransporterDetails err", err);
        })
    }else{
        this.setState({
            basicTitle:"All the fields are required",
            basicType : "warning",
            show : true
        });
    }
    this.onLoadPageData();
}

handleChaProcessDetails = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("document_extension", this.state.documentExtension);
    formData.append("bl_file", this.state.attachmentsDoc);  
    formData.append("booking_no",this.state.gridRowData.booking_no);

    redirectURL.post('/consignments/updatePendingChaDetails', formData).then((resp)=>{
        console.log("updatePendingChaDetails resp",resp.data);
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            invoiceData:[],
            ccData:[],
            ccSlider:"",
            transporterTranslate: "",
            jobNo:"",
            cc_docs:"",
            statusDate:""
        },() =>{
            if(this.state.chaFlagValue == 2){
                this.onLoadPageData();
            }else if(this.state.chaFlagValue == 5){
                this.onLoadInProcessData(5,[5]);
            }
        });
    }).catch((err) => {
        console.log("updatePendingTransporterDetails err", err);
    })
}

handlerGateOpenDate = (event) => { 
    var d = new Date(event._d);
    var gateOpenDate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    console.log(gateOpenDate)

    var now = new Date();
    if (gateOpenDate) {
        if (gateOpenDate < now) {
            this.setState({ gateOpenDate: getHyphenYYYYMMDDHHMMSS(now) })
        }else{
            this.setState({ gateOpenDate: gateOpenDate })
        }
    } else {
        this.setState({ gateOpenDate: "" })
    }
}

handlerSlCutOffDate = (event) => { 
    var d = new Date(event._d);
    var slCutOffDate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    console.log(slCutOffDate)

    var now = new Date();
    if (slCutOffDate) {
        if (slCutOffDate < now) {
            this.setState({ slCutOffDate: getHyphenYYYYMMDDHHMMSS(now) })
        }else{
            this.setState({ slCutOffDate: slCutOffDate })
        }
    } else {
        this.setState({ slCutOffDate: "" })
    }
}
handlerVesselEtd = (event) => { 
    var d = new Date(event._d);
    var vesselEtd = new Date(getHyphenYYYYMMDDHHMMSS(d));
    console.log(vesselEtd)

    var now = new Date();
    if (vesselEtd) {
        if (vesselEtd < now) {
            this.setState({ vesselEtd: getHyphenYYYYMMDDHHMMSS(now) })
        }else{
            this.setState({ vesselEtd: vesselEtd })
        }
    } else {
        this.setState({ vesselEtd: "" })
    }
}

handlerStatusDateTime = (event) => { 
    var d = new Date(event._d);
    var statusDate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    console.log(statusDate)

    var now = new Date();
    if (statusDate) {
        if (statusDate >= now) {
            this.setState({ statusDate: getHyphenYYYYMMDDHHMMSS(now) })
        }else{
            this.setState({ statusDate: statusDate })
        }
    } else {
        this.setState({ statusDate: "" })
    }
}

submitStatus = (event) => {
    event.preventDefault()
    if(this.state.statusDate){
        let statusDate = moment(this.state.statusDate).format('YYYY-MM-DD')
        let cha_flag = this.state.chaFlagValue
        let requirement_id = this.state.gridRowData.requirement_id
        let booking_no = this.state.gridRowData.booking_no
        let params = {}
        switch(cha_flag){
            case 2:
                params.reporting_to_cha_datetime = statusDate
                break;
            case 3:
                params.gatein_datetime = statusDate
                break;
            case 4:
                params.shipped_onboard_time = statusDate
                break;
            default:
                break;            

        }
        params.cha_flag = cha_flag + 1
        params.requirement_id = requirement_id
        params.booking_no = booking_no

        
        redirectURL.post("/consignments/updateBookingLegsChaStatus",params)    
        .then((response) => {
            console.log("response ", response.data)
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
                invoiceData:[],
                ccData:[],
                ccSlider:"",
                transporterTranslate: "",
                jobNo:"",
                cc_docs:"",
                statusDate:"",
                gridRowData:[]
            },() =>{
                this.onLoadInProcessData(cha_flag);
            });
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }else{
        this.setState({
            basicTitle:"Date should not be empty",
            basicType : "warning",
            show : true
        });
    }
}

  render() {
    var pageType = this.state.pageType;
    console.log(pageType,"pageType");
    if(pageType == 1)
    {
        var editHide = true;
        var hideUpdate = false;
    }
    else if(pageType == 3)
    {
        var editHide = true;
        var hideUpdate = true;
        this.gridColumnApi.moveColumns(['do_file_url','bl_file_url'], 0);
    }
    else
    {
        var editHide = false;
        var hideUpdate = false;
    }
    var supplier_code = localStorage.getItem("supplier_code");
    console.log(supplier_code,"supplier_code")
    var columnwithDefs = [    
        // {
        //     field:'_id',
        //     headerName:'Edit',
        //     colId:"_id",
        //     //pinned:"left",
        //     width: 72,
        //     hide: editHide,
        //     cellRenderer:function(params){
        //         let basestring = params.data._id;
        //         let encryptedstring = window.btoa(basestring);
        //         var htmloption = '<a href="/editchaseaimportsform/'+encryptedstring+'/1" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
        //         if(pageType == 1)
        //         {
        //             return htmloption;
        //         }
        //         else
        //         {
        //             return "N/A";
        //         }
        //     }            
        // }, 
        {
            headerName: "",
            field: "_id",
            hide:hideUpdate,
            width: 100,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>{ 
                if(this.state.pageType == 1){
                    if (!params.data.do_file_url) {
                        return <button className='btn btn-primary'
                            style={{ fontSize:"12px", width:"100px", cursor:"pointer" }}
                            onClick={this.openDocumentsSlider.bind(this, params.data)}>Update</button>
                    } else {
                        return <button className='btn btn-primary'
                            style={{ fontSize:"12px", width:"100px", cursor:"pointer" }}
                            disabled>Update</button>
                    }
                    
                }else if(this.state.pageType == 2){
                    {return <a 
                    style={{ fontSize:"20px", fontWeight: "bold", cursor:"pointer" }}
                    onClick={this.onShowTicketHistory.bind(this, params)}><i className='icofont icofont-rounded-expand'></i></a>
                    }
                }       
            },
        },
        {
            headerName: "",
            field: "",
            width: 150,
            hide:editHide,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>{ 
                if(params.data.cha_flag == 5){
                    return <button className='btn btn-primary'
                    style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                    onClick={this.openDocumentsSlider.bind(this, params.data)}>Upload BL</button>
                }else{
                    return <button className='btn btn-primary'
                    style={{ fontSize:"12px", width:"125px", cursor:"pointer" }} disabled>Upload BL</button>
                }
            }
        },
        // {
        //     headerName: "Custom Duty Paid",
        //     field: "custom_duty_paid",
        //     width:150,
        //     filter: true,
        //     resizable: true,
        //     cellRenderer:function(params){
        //         if(supplier_code !=undefined && supplier_code !="undefined" && supplier_code !='')
        //         {
        //             if(params.data.custom_duty_paid !=undefined)
        //             {
        //                 return params.data.custom_duty_paid;
        //             }
        //         }
        //         else
        //         {
        //             var htmloption = '<button class="btn btn-info" style="padding-top:0px">Mark As Paid</button>';
        //             if(params.data.custom_duty_paid == undefined || params.data.custom_duty_paid =='' || params.data.custom_duty_paid == 'no')
        //             {
        //                 if(pageType == 1)
        //                 {
        //                     //return htmloption;
        //                     return params.data.custom_duty_paid;
        //                 }
        //                 else
        //                 {
        //                     return params.data.custom_duty_paid;
        //                 }
        //             }
        //             else
        //             {
        //                 return params.data.custom_duty_paid;
        //             }
        //         }
        //     }     
        // },
        {
            headerName: "Work Order No",
            field: "booking_no",
            width:160,
            filter: true,
            resizable: true,
            // cellRendererFramework: (params)=>{ if(this.state.pageType ==2) {return <a 
            // style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
            // onClick={this.onShowTicketHistory.bind(this, params)}>{params.data.booking_no}</a>}else{
            //     return params.data.booking_no
            // }}          
        },
        {
            headerName: "CHA",
            field: "cha_entity",
            width:120,
            filter: true,
            resizable: true,
            // valueGetter: function(params){
            //     if(params.data.request_type == 1)
            //     {
            //         return "FCL";
            //     }
            //     else
            //     {
            //         return "LCL";
            //     }
            // }         
        },
        {
            headerName: "Destination Country",
            field: "destination_country",
            width:120,
            filter: true,
            resizable: true,
            // valueGetter: function(params){
            //     try{
            //         if(params.data.eta_igi_airport_date !='' && params.data.eta_igi_airport_date !=undefined)
            //         {
            //             return getHyphenDDMMYYYY(params.data.eta_igi_airport_date);
            //         }
            //     }
            //     catch(e){
            //     }
            // }           
        },
        {
            headerName: "Item Quantity",
            field: "item_quantity",
            width:120,
            filter: true,
            resizable: true,
            // valueGetter: function(params){
            //     try{
            //         if(params.data.ata_igi_airport_date !='' && params.data.ata_igi_airport_date !=undefined)
            //         {
            //             return getHyphenDDMMYYYY(params.data.ata_igi_airport_date);
            //         }
            //     }
            //     catch(e){
            //     }
            // }            
        },
        {
            headerName: "Container Size",
            field: "container_size",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Container Quantity",
            field: "container_quantity",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Plant Code",
            field: "plant_code",
            width:130,
            filter: true,
            resizable: true,
            // cellRenderer:function(params){
            //     var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
            //     return htmloption;
            // }         
        },
        {
            headerName: "Destination Port",
            field: "destination_port",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Buyer Code",
            field: "buyer_code",
            width:120,
            filter: true,
            resizable: true,
            // valueGetter: function(params){
            //     try{
            //         if(params.data.gigm_date !='' && params.data.gigm_date !=undefined)
            //         {
            //             return getHyphenDDMMYYYY(params.data.gigm_date);
            //         }
            //     }
            //     catch(e){
            //     }
            // }            
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Type",
            field: "container_type",
            width:120,
            filter: true,
            resizable: true,
            // valueGetter: function(params){
            //     try{
            //         if(params.data.ligm_date !='' && params.data.ligm_date !=undefined)
            //         {
            //             return getHyphenDDMMYYYY(params.data.ligm_date);
            //         }
            //     }
            //     catch(e){
            //     }
            // }            
        },
        {
            headerName: "Stuffind Planned Date",
            field: "stuffing_planned_date",
            width:120,
            filter: true,
            resizable: true,  
            valueGetter: function(params){
                try{
                    if(params.data.stuffing_planned_date !='' && params.data.stuffing_planned_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.stuffing_planned_date);
                    }
                }
                catch(e){
                }
            }        
        },
        {
            headerName: "Loading Port",
            field: "loading_port",
            width:120,
            filter: true,
            resizable: true,
            // valueGetter: function(params){
            //     try{
            //         if(params.data.boe_date !='' && params.data.boe_date !=undefined)
            //         {
            //             return getHyphenDDMMYYYY(params.data.boe_date);
            //         }
            //     }
            //     catch(e){
            //     }
            // }            
        },
        {
            headerName: "Gate Open Date",
            field: "gate_open_date",
            width:120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "SL Cutoff Date",
            field: "sl_cutoff_date",
            width:120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width:120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width:120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Vessel ETD",
            field: "vessel_etd",
            width:120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "View DO Details",
            field: "do_file_url",
            width: 150,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>{return <button className='btn btn-primary' 
            style={{ fontSize:"12px", width:"125px", cursor:"pointer" }} 
            onClick={this.previewDocument.bind(this, params.data.do_file_url)}>View DO Details</button>}
        },
        {
            headerName: "View BL Details",
            field: "bl_file_url",
            width: 150,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>{return <button className='btn btn-primary' 
            style={{ fontSize:"12px", width:"125px", cursor:"pointer" }} 
            onClick={this.previewDocument.bind(this, params.data.bl_file_url)}>View BL Details</button>}
        }
    ]
    return (
      <div class="container-fluid">
          
          <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>CHA Updates </span>
          </h5>
          {/* <button className="btn btn-success" onClick={this.addNewRow.bind(this)}>Add New Holiday</button> */}
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <Modal open={this.state.openModal} onClose={this.closeModal}>
          <div aria-disabled={true}>
            <h6
              className="bg-primary text-white"
              style={{
                paddingTop: "10px",
                height: "38px",
                paddingLeft: "253px",
              }}
            >
              File Preview
            </h6>
            {this.state.documentType === "pdf" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "csv" ||
              this.state.documentType === "xlsx" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "png" ||
              this.state.documentType === "jpeg" ||
              this.state.documentType === "jpg" ? (
              <div className="p-3">
                <img
                  src={this.state.documentUrl}
                  // frameborder="1"
                  height="500px"
                  width="100%"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>
        <div className="f16" style={{marginBottom:"10px"}}>
            <ul className="d-tabs">
                <li onClick={this.onClickTab.bind(this,"pending")} className={"lttabs "+(this.state.pending)}>
                    <button type="button" className="btn">Upload DO</button>
                </li>

                <li onClick={this.onClickTab.bind(this,"reportToChaTab")} className={"lttabs "+(this.state.reportToChaTab)}>
                    <button type="button" className="btn">Report To CHA</button>
                </li>
                <li onClick={this.onClickTab.bind(this,"gateInTab")} className={"lttabs "+(this.state.gateInTab)}>
                    <button type="button" className="btn">Gate In</button>
                </li>
                <li onClick={this.onClickTab.bind(this,"shippedOnboardTab")} className={"lttabs "+(this.state.shippedOnboardTab)}>
                    <button type="button" className="btn">Shipped Onboard</button>
                </li>
                <li onClick={this.onClickTab.bind(this,"blUploadTab")} className={"lttabs "+(this.state.blUploadTab)}>
                    <button type="button" className="btn">BL Upload</button>
                </li>

                <li onClick={this.onClickTab.bind(this,"closure")} className={"lttabs "+(this.state.closure)}>
                    <button type="button" className="btn">Closure</button>
                </li>
            </ul>
        </div>
        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
            <AgGridReact
                modules={this.state.modules}
                columnDefs={columnwithDefs}
                defaultColDef={this.state.defaultColDef}
                masterDetail={true}
                rowData={this.state.rowData}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                    context: { componentParent: this }
                }}
                detailCellRendererParams={this.state.detailCellRendererParams}
                // components={this.state.components}
                enableRangeSelection={true}
                onCellClicked={this.viewDetails.bind(this)}
                //floatingFilter={true}
                enableCellChangeFlash={true}
                // suppressCellFlash={true}
                // suppressRowClickSelection={true}

                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                />
        </div>
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"slide-r " + this.state.transporterTranslate} style={{ overflow: "auto" }}>
            {this.state.pageType == 1 ?
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6> Update DO Details
                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} > X</span>
                </h6>
                <div class="container-fluid" style={{color:"black"}}>
                    <form>
                        <div className={'text-left mt-5 text-dark'}>
                            <label>Gate Open Date: <span className='text-danger'>*</span></label>
                        </div>
                        <div className="form-group">
                            <Datetime
                                name="gateOpenDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
                                value={this.state.gateOpenDate}
                                // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                onChange={this.handlerGateOpenDate.bind(this)}
                                required
                            />
                        </div>
                        <div className={'text-left mt-2 text-dark'}>
                            <label>SL Cut-Off Date: <span className='text-danger'>*</span></label>
                        </div>
                        <div className="form-group">
                            <Datetime
                                name="slCutOffDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
                                value={this.state.slCutOffDate}
                                // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                onChange={this.handlerSlCutOffDate.bind(this)}
                                required
                            />
                        </div>
                        <div className={'text-left mt-2 text-dark'}>
                            <label>Vessel Name: <span className='text-danger'>*</span></label>
                        </div>
                        <div className="form-group">
                            <input
                                name="vesselName"
                                className="form-control mr-3 mt-1"
                                id="vesselName"
                                placeholder="Vessel Name"
                                onChange={(e) =>
                                this.setState({
                                    vesselName: e.target.value,
                                })
                                }
                                value={this.state.vesselName}
                                required
                            />                        
                        </div>
                        <div className={'text-left mt-2 text-dark'}>
                            <label>Shipping Line: <span className='text-danger'>*</span></label>
                        </div>
                        <div className="form-group">
                            <input
                                name="shippingLine"
                                className="form-control mr-3 mt-1"
                                id="shippingLine"
                                placeholder="Shipping Line"
                                onChange={(e) =>
                                this.setState({
                                    shippingLine: e.target.value,
                                })
                                }
                                value={this.state.shippingLine}
                                required
                            />                        
                        </div>
                        <div className={'text-left mt-2 text-dark'}>
                            <label>Vessel ETD: <span className='text-danger'>*</span></label>
                        </div>
                        <div className="form-group">
                            <Datetime
                                name="vesselEtd"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
                                value={this.state.vesselEtd}
                                // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                onChange={this.handlerVesselEtd.bind(this)}
                                required
                            />
                        </div>
                        <div className="form-group mt-20p">
                            <label htmlFor="">DO File: </label>
                            <input
                                type="file"
                                name="attachmentsDoc"
                                style={{ fontSize: "0.8rem" }}
                                id="attachmentsDoc"
                                onChange={this.changeAttachmentsFileHandler.bind(this)}
                                // className="col-7 mt-3 border mr-3"
                                required
                            />
                        </div>
                        <button className='btn btn-info' type="button" onClick={this.handleChaPendingDetails.bind(this)}>Add Details</button>
                    </form>
                
                </div>
            </div>
            : this.state.pageType == 2 ?
            this.state.chaFlagValue  == 5?
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6> Update BL Details
                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} > X</span>
                </h6>
                <div class="container-fluid" style={{color:"black"}}>
                    <form>
                        <div className="form-group mt-20p">
                            <label htmlFor="">BL Details</label>
                            <input
                                type="file"
                                name="attachmentsDoc"
                                style={{ fontSize: "0.8rem" }}
                                id="attachmentsDoc"
                                onChange={this.changeAttachmentsFileHandler.bind(this)}
                                // className="col-7 mt-3 border mr-3"
                                required
                            />
                        </div>
                        <button className='btn btn-info' type="button" onClick={this.handleChaProcessDetails.bind(this)}>Add Details</button>
                    </form>
                
                </div>
            </div>
            :
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6> Update Container Details
                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} > X</span>
                    </h6>
                    <div className={'col-9 text-left mt-5 text-dark'}>
                        <label>Container No: <b>{this.state.gridRowData ? this.state.gridRowData.container_number : '-'}</b></label>
                    </div>

                    <div className={'col-9 text-left mt-2 text-dark'}>
                        <label>Set Reporting to CHA Time: <span className='text-danger'></span></label>
                    </div>
                    <div className={'col-9 text-dark'}>
                        <div className="form-group">
                            {
                            this.state.chaFlagValue == 2 ?
                            <Datetime
                                name="statusDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) => current.isBefore(moment().subtract(0, 'day'))}
                                value={this.state.statusDate}
                                // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                onChange={this.handlerStatusDateTime.bind(this)}
                                required
                            />
                            : <label className='text-dark'>{this.state.chaFlagValue >= 2 ? this.state.gridRowData.reporting_to_cha_datetime : '-'}</label>
                            }
                        </div>
                    </div>
                    <div className={'col-9 text-left mt-2 text-dark'}>
                        <label>Set Gate-In Time: <span className='text-danger'>*</span></label>
                    </div>
                    <div className={'col-9 text-dark'}>
                        <div className="form-group">
                            {
                            this.state.chaFlagValue == 3 ?
                            <Datetime
                                name="statusDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) => current.isBefore(moment().subtract(0, 'day'))}
                                value={this.state.statusDate}
                                // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                onChange={this.handlerStatusDateTime.bind(this)}
                                required
                            />
                            : <label className='text-dark'>{this.state.chaFlagValue >= 3 ? this.state.gridRowData.gatein_datetime : '-'}</label>
                            }
                        </div>
                    </div>
                    <div className={'col-9 text-left mt-2 text-dark'}>
                        <label>Set Shipped on Board Time: <span className='text-danger'>*</span></label>
                    </div>
                    <div className={'col-9 text-dark'}>
                        <div className="form-group">
                            {
                            this.state.chaFlagValue == 4 ?
                            <Datetime
                                name="statusDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                isValidDate={(current) => current.isBefore(moment().subtract(0, 'day'))}
                                value={this.state.statusDate}
                                // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                onChange={this.handlerStatusDateTime.bind(this)}
                                required
                            />
                            : <label className='text-dark'>{this.state.chaFlagValue >= 4 ? this.state.gridRowData.shipped_onboard_time : '-'}</label>
                            }
                        </div>
                    </div>
                    <button className='btn btn-info mr-3' onClick={this.submitStatus.bind(this)}>Submit</button>
            </div>
            :""
        }
        </div>;

        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">
                {this.state.sliderTitle}
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                {(this.state.invoiceData.length > 0) ?                         
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered tptinfo">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.invoiceData.map((e, index) => (
                                        <tr>
                                            <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                        </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                : ""}
                {(this.state.ccSlider == 1) ?                         
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered tptinfo">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.ccData.map((e, index) => (
                                        <tr>
                                            <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.custom_clearance_file)}>{e.custom_clearance_file}</td>
                                        </tr>
                                            ))}
                                    </tbody>
                                </table>
                                {(this.state.jobNo != "") ?
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadCCFileFormHandler} style={{marginTop:"2%"}}>
                                    <div className="form-group">
                                        <label className="">Upload New Document</label>
                                        <input type="file" name="other_docs" id="other_docs" className="form-control" onChange={this.changeDocFileHandler} required/>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                                : ""} 
                            </div>
                        </div>
                    </div>
                : ""}
            </div>
        </div>

        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
export default withRouter(ChaSeaImportsList);