export const eventStatusCodes = {
    // Container empty to shipper
    CEP: 'empty_pickup',
    // Container arrival at first POL (Gate in)
    CGI: 'gate_in',
    // Vessel departure from first POL
    VDL: 'origin',
    // Vessel arrival at T/S port
    VAT: 'transhipment',
    // Vessel departure from T/S
    VDT: 'transhipment',
    // Vessel arrival at final POD
    VAD: 'destination',
    // Container departure from final POD (Gate out)
    CGO: 'gate_out',
    // Container empty return to depot
    CER: 'empty_return',
}

export const eventStatusNames = {
    // Container empty to shipper
    CEP: 'Empty Pickup',
    // Container arrival at first POL (Gate in)
    CGI: 'Gate In',
    // Vessel departure from first POL
    VDL: 'Origin Departure',
    // Vessel arrival at T/S port
    VAT: 'Transhipment Arrival',
    // Vessel departure from T/S
    VDT: 'Transhipment Departure',
    // Vessel arrival at final POD
    VAD: 'Destination Arrival',
    // Container departure from final POD (Gate out)
    CGO: 'Gate Out',
    // Container empty return to depot
    CER: 'Empty Return',
}

export const carrierTypeMapping = {
    "AC Container Line": "ALRB",
    "Admiral Container Lines": "ADMU",
    "Aladin Express": "ALXP",
    "Alianca": "ANRM",
    "Allalouf Shipping Line": "ALLF",
    "Altun Logistics": "ALKU",
    "American President Lines (APL)": "APLU",
    "Arkas": "ARKU",
    "Asyad Line": "ASLU",
    "Atlantic Container Line (ACL)": "ACLU",
    "Australia National Line (ANL)": "ANNU",
    "Avana Global FZCO (BALAJI)": "BLJU",
    "BAL Container Line": "BURU",
    "Bee Logistics Corp": "BELC",
    "BLPL Singapore": "BLZU",
    "Blue Anchor America Line": "BANQ",
    "Blue Water Lines (BWL)": "BWLU",
    "Blue World Line": "BWLE",
    "BMC Line Shipping": "BMSU",
    "BNSF Logistics": "BNLS",
    "Camellia Line": "CAKU",
    "Cargo-Partner": "CPNU",
    "Carpenters Shipping": "MBFU",
    "China United Lines": "CULU",
    "CK Line": "CKLU",
    "CMA CGM": "CMDU",
    "CNC (Cheng Lie Navigation)": "11DX",
    "Containerships": "CSHP",
    "Cordelia Container Shipping Line": "CSYU",
    "COSCO": "COSU",
    "*Cosiarma S.p.A.": "CRAU",
    "Crane Worldwide Logistics": "MLCW",
    "Crowley Maritime": "CMCU, CAMN",
    "Dachser": "DTRA",
    "Dalreftrans": "DLTU",
    "Damco": "DMCQ",
    "DB Schenker": "SHKK",
    "Deutsche Afrika-Linien (DAL)": "DAYU",
    "DHL Global Forwarding": "DHC2",
    "Dongjin Shipping": "11PG",
    "Dong Young Shipping": "PCSL",
    "Dsv Ocean Transport": "DSVF",
    "Econship": "ECNU",
    "ECU Worldwide": "ECUW",
    "Eimskip": "EIMU",
    "EIO": "EXPO",
    "Emirates Shipping Line": "ESPU",
    "Emkay Lines": "EMKU",
    "Ethiopian Shipping Line": "ESLU",
    "Eukor": "EUKO",
    "Evergreen": "EGLV",
    "FESCO": "FESO",
    "Gold Star Line": "GSLU",
    "Grimaldi Deep Sea S.P.A.": "GRIU",
    "Hai Hua Shipping (HASCO)": "12GE",
    "Hamburg Sud": "SUDU",
    "Hapag-Lloyd": "HLCU",
    "Hecny Shipping": "HYSL",
    "*Hellmann Worldwide Logistics": "HIFI",
    "Heung-A Shipping": "11QU",
    "Hillebrand Gori": "HGLU",
    "Hyundai Merchant Marine (HMM)": "HDMU",
    "Ignazio Messina": "LMCU",
    "Independent Container Line": "IILU",
    "*Indus Container Lines (IDCL)": "IDCL",
    "Interasia Lines": "12AT",
    "JAS Worldwide (Ocean)": "JASO",
    "Jin Jiang Shipping (SHJJ)": "11WJ",
    "Kalypso Compagnia di Navigazione SpA": "KCDU",
    "Kambara Kisen": "KKCL",
    "Kawasaki Kisen Kaisha (K Line)": "KKLU",
    "Korea Marine Transport (KMTC)": "KMTU",
    "Kuehne + Nagel (KN)": "KHNN",
    "Lancer Container Lines": "LCUU",
    "Laurel Navigation": "LNLU",
    "Leschaco": "LEHO",
    "MacAndrews": "MCAW",
    "Maersk": "MAEU",
    "Maersk Line Limited (MLL)": "MAEI",
    "Marguisa Shipping Lines": "MGSU",
    "Mariana Express Lines (MELL)": "MEXU",
    "Maritime Carrier Shipping (MACS)": "MCSM",
    "Maritime Marfret": "MFTU",
    "Matson Navigation Company Inc (MATS)": "MATS",
    "Maxicon Container Line (MCL)": "MXCU",
    "Mediterranean Shipping Company (MSC)": "MSCU, MEDU",
    "Medkon Lines": "MKLU",
    "*Meratus Line": "MRTU",
    "Minsheng Ocean Shipping": "13CQ",
    "Mitsui O.S.K. Lines (MOL)": "MOLU",
    "Namsung Shipping": "NSRU",
    "National Shipping of America": "NSHA",
    "Nauka Lines": "NOKU",
    "Neptune Pacific Direct Line (NPDL)": "PDLU",
    "NewStar": "NSTR",
    "Nile Dutch Africa Line": "NIDU",
    "Nippon Express": "NEDF, NPNE",
    "Nippon Yusen Kaisha (NYK Line)": "NYKS",
    "Nirint Shipping": "32GH",
    "North Sea Container Line (NCL)": "NSCL",
    "Ocean Network Express (ONE)": "ONEY",
    "Odyssey Logistics & Technology": "OYLT",
    "Oman Container Lines": "OCLU",
    "Orient Overseas Container Line (OOCL)": "OOLU",
    "Orient Star": "OSTI",
    "Pacific International Lines (PIL)": "PCIU",
    "*Pan Asia Line": "PALU",
    "Pan Continental Shipping": "15AC",
    "Pan Ocean": "POBU",
    "Pasha Hawaii": "PSHI",
    "*Perma Shipping Line": "PMLU",
    "Polynesia Line": "PLLU",
    "PSL Navegacao": "PSL1",
    "Qatar Navigation Lines (QNL)": "QNLU",
    "Regional Container Lines (RCL)": "REGU",
    "Rif Line": "RIFU",
    "Romocean": "ROMO",
    "Route Planner": "SRRP",
    "Safmarine": "SAFM",
    "Salam Pacific Indonesia Lines (SPIL)": "SPNU",
    "Samudera Shipping Line": "SIKU",
    "Sarjak Container Lines": "SJKU",
    "Seaboard Marine": "SMLU",
    "Sea Hawk Lines (SHAL)": "SHKU",
    "Sealand": "SEJJ, MCCQ, SEAU",
    "Sealead Shipping": "SJHH",
    "Seatrade": "SGNV",
    "Seino Logix Co": "SEIN",
    "SETH Shipping": "SSPH",
    "Shipco Transport": "SHPT",
    "Shipping Corporation of India (SCI)": "SCIU",
    "Sinokor": "SKLU",
    "Sinotrans Container Lines": "12IH",
    "SITC Container Lines": "12PD",
    "SM Line (SML)": "SMLM",
    "Stolt Tank Containers (STC)": "SNTU",
    "Sunmarine Shipping Services": "BAXU",
    "Swire Shipping": "CHVW",
    "*Taicang Container Lines": "32GG",
    "Tailwind Shipping Lines": "TSHG",
    "Tarros": "GETU",
    "TOTE Maritime": "TOTE",
    "Trans Asian Shipping Services": "TLXU",
    "Transfar Shipping": "TJFH",
    "Transvision Shipping Line": "TVSU",
    "Tropical": "TSCW",
    "T.S. Lines": "13DF",
    "Turkon": "TRKU",
    "UWL": "UWLD",
    "Vanguard Logistics": "VGLT",
    "Vasco Maritime (VAS)": "VMLU",
    "VASI Shipping": "VASU",
    "Volta Container Line": "VCLU",
    "Wallenius Wilhelmsen": "WLWH",
    "Wan Hai": "WHLC, 22AA, WHLU",
    "W.E.C. (West European Container) Lines": "WECU",
    "Westwood Shipping Lines": "WWSU",
    "White Line Shipping": "WTLU",
    "World Direct Shipping (WDS)": "WDSB",
    "Yang Ming": "YMLU, YMPR, YMJA",
    "Yusen Logistics": "YASV",
    "ZIM": "ZIMU",
};
