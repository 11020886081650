import React, { useState, useEffect, useRef, useCallback } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from "react-select";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import SubmitBtn from "./submitBtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
import { object } from "@amcharts/amcharts5";
const PlantTAT = () => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    const [transList, settransList] = useState([])
    var [trucksdata, setTrucksData] = useState([])
    const currentDate = new Date();
    const initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    var [selectedDate, setSelectedDate] = useState(new Date());
    var [selectedfilters, setfilters] = useState({
        yearFilter: { 'label': 'FY 24-25', 'value': 'FY 24-25' },
        transfilter: { label: 'All', value: 'ALL' }
    })
    var [groupdata, setgroupdata] = useState([])
    const [modifiedRows, setModifiedRows] = useState(new Set());
    var [selecteddata, setselecteddata] = useState([])
    var [modifiedRownodes, setModifiedRownodes] = useState([]);
    var [updatedData, setUpdatedData] = useState([]);
    var [plantList, setplantlist] = useState([])
    const [years, yearsList] = useState([
        { 'label': 'FY 22-23', 'value': 'FY 22-23' },
        { 'label': 'FY 23-24', 'value': 'FY 23-24' },
        { 'label': 'FY 24-25', 'value': 'FY 24-25' },
        // {'label':'FY 25-26','value':'FY 25-26'}
    ])
    useEffect(() => {
        if (gridRef.current) {
            // setGridApi(gridRef.current.api);
            // setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        redirectURL.post("/consignments/getplantdata").then((resp) => {
            try {
                var data = resp.data
                //console.log(data, 'planttatdata')
                var plant = [{ label: 'All', value: 'ALL' }]
                data.map((e) => {
                    plant.push({ label: e.plant_code, value: e.plant_code })
                })
                plant = removeDuplicateObjects(plant);
                setplantlist(plant)
            }
            catch (e) {

            }
        })
        getdata()

    }, [])
    const handleCellValueChanged = (params) => {
        //console.log(params.data)
        var updatedRowData = [...updatedData];
        updatedRowData.push(params.data)
        setUpdatedData(updatedRowData);
        //console.log(updatedRowData, 'updatedRowData')
    };
    const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
    }, []);
    const getRowStyle = (params) => {
        if (!params || !params.data || !params.data.report_date) {
            return null;
        }
        var currentWeekDates = getCurrentWeekDates();
        var reportDate = params.data.report_date;
        if (currentWeekDates.includes(reportDate)) {
            return { backgroundColor: '#FF7F7F' };
        }
        return null;
    };



    const submitdata = () => {
        //console.log(updatedData, 'updatedData')
        var updatedrecord = []
        updatedData.map((e) => {
            var keys = Object.keys(e);
            keys.map((k) => {
                if (k.includes("_name") == true) {
                    //console.log(k, "e[k]")
                    var tptName = k.split("_")[0];
                    var tptNamewithoutspaces = tptName.trim().replace(/ /g, "");
                }
                if (e[tptNamewithoutspaces + "_mark"] != undefined && e[tptNamewithoutspaces + "_mark"] != "undefined" && e[tptNamewithoutspaces + "_mark"] != '') {
                    updatedrecord.push({
                        "report_date": e["report_date"],
                        "transporter_name": e[tptNamewithoutspaces + "_name"],
                        "mark_availability": e[tptNamewithoutspaces + "_mark"],
                    });
                }
            });
            //console.log(updatedrecord, 'updatedrecord--1')
            // var row = {'report_date':e.report_date,'mark_availability':e.}
        })
        var params = {
            data: updatedrecord
        }
        redirectURL.post('/consignments/insertfortruckavailreport', params).then((resp) => {
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
                getdata()
            }
            else {
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
            }
        })
    }
    var columnwithDef = [
        {
            headerName: "Month",
            field: "report_date",
            // headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
            width: 120,
            filter: true,
            resizable: true,
            // children: [
            //     {
            //         headerName: "",
            //         headerClass: ["cellstylegrid"],
            //         field: 'report_date',
            //         // cellClass: ["cellTopHeaderTemplate16", "cellstylegrid", "cpointer"],
            //         width: 160,
            //         cellClass: (params) => {
            //             if (params.data.report_date) {
            //                 const currentWeekDates = getCurrentWeekDates();
            //                 if (currentWeekDates.includes(params.data.report_date)) {
            //                     return 'cellHighlighted'; // Define a CSS class for highlighted cells
            //                 }
            //             }
            //             return ''; // Return empty string for default styling
            //         },
            //         // editable: true
            //     },
            // ]
        },
    ]
    var getdata = () => {
        var params = {
            'financial_year': selectedfilters.yearFilter.value,
            'plant_code': selectedfilters.transfilter.value
        }
        redirectURL.post('/consignments/getPlantTAT', params)
            .then((resp) => {
                var records = JSON.parse(resp.data.plant_tat);
                //console.log(records, '182')
                // if (localStorage.getItem('role') == 'tpt') {
                //     if (localStorage.getItem('tpt_name') != '' && localStorage.getItem('tpt_name') != 'undefined') {
                //         //console.log(localStorage.getItem('tpt_name'))
                //         records = records.filter(e => e.transporter_name == localStorage.getItem('tpt_name'))
                //     }
                // }
                const trans_names = groupBy(records, (rdata) => rdata.plant_code);
                const currentMonths = groupBy(records, (rdata) => rdata.month);
                const finalData = [];
                const dateKeys = [...currentMonths.keys()];
                if (dateKeys.length > 0) {
                    dateKeys.forEach((month) => {
                        const row = {
                            report_date: month,
                        };
                        if (month) {
                            records.map((item) => {
                                if (month == item.month) {
                                    const transporterName = item.plant_code.trim().replace(/ /g, "");
                                    row[`${transporterName}_avg_tat`] = item.plant_tat_value;
                                }
                            });
                        }
                        finalData.push(row);
                    });
                }
                setrowData(finalData);
                //console.log("finalData", finalData);
                setgroupdata(trans_names);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }
    var handleFilterOptions = (e) => {
        setfilters(prev => ({
            ...prev,
            transfilter: e
        }))
    }
    var handleFilterOptions1 = (e) => {
        setfilters(prev => ({
            ...prev,
            yearFilter: e
        }))
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        //console.log(formattedDate, 'formattedDate')
        setfilters(prev => ({
            ...prev,
            datefilter: formattedDate
        }))
    };
    // const keys = [...groupdata.keys()];
    // keys.forEach(function (key) {
    //     var keyWithoutSpaces = key.trim().replace(/ /g, "");
    //     var columnDefinition = {
    //         headerName: key,
    //         field:'',
    //         width: 150,
    //         valueGetter:function(params){
    //             if(params.data && params.data[keyWithoutSpaces+'_avg_tat']){
    //                 //console.log(params.data[keyWithoutSpaces+"_avg_tat"])
    //             return params.data[keyWithoutSpaces + "_avg_tat"]
    //             }
    //         }
    //     };
    //     columnwithDef.push(columnDefinition);
    // });
    const keys = [...groupdata.keys()];
    keys.forEach(function (key) {
        var keyWithoutSpaces = key.trim().replace(/ /g, "");
        var columnDefinition = {
            headerName: key,
            field: '',
            width: 100,
            valueGetter: function (params) {
                if (params.data && params.data[keyWithoutSpaces + '_avg_tat'] !== undefined) {
                    //console.log(params.data[keyWithoutSpaces + "_avg_tat"])
                    return params.data[keyWithoutSpaces + "_avg_tat"];
                }
                return null;
            }
        };

        columnwithDef.push(columnDefinition);
    });

    //console.log(columnwithDef, "330")
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Plant TAT
                    </h1>
                </div>
                <div className="card m-10p">
                    <div className="card-body" id="counterCardBody">
                        <div className="row">
                            {/* <div className="form-group col-xl-2 col-lg-2 mt-20p">
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy" // Format to display only month and year
                        showMonthYearPicker
                        minDate={new Date(1900, 0)} // Set the minimum year you want to allow
                        maxDate={new Date(2100, 11)}
                        className="custom-datepicker"
                        calendarClassName="custom-datepicker-dropdown"// Set the maximum year you want to allow
                    />
                </div> */}
                            <div className="col-sm-8">
                                <div className="row">
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <Select
                                            closeMenuOnSelect={true}
                                            name="filterModeType"
                                            // className="border-radius-0"
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.9rem",
                                                    color: "#6e707e",
                                                    borderRadius: "11px",
                                                    height: "48px",
                                                    width: "260px",
                                                    // textAlign: "center",
                                                    // marginRight: '25px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.9rem", // Adjust the font size here
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    width: "260px"
                                                })
                                            }}
                                            onChange={handleFilterOptions1}
                                            options={years}
                                            value={selectedfilters.yearFilter}
                                            required
                                        />

                                    </div>
                                    {(localStorage.getItem('role') == 'tpt') ? '' :
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <Select
                                                closeMenuOnSelect={true}
                                                name="filterModeType"
                                                // className="border-radius-0"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.9rem",
                                                        color: "#6e707e",
                                                        borderRadius: "11px",
                                                        height: "48px",
                                                        width: "260px",
                                                        // textAlign: "center",
                                                        // marginRight: '25px'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.9rem", // Adjust the font size here
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        width: "260px"
                                                    })
                                                }}
                                                onChange={handleFilterOptions}
                                                options={plantList}
                                                value={selectedfilters.transfilter}
                                                required
                                            />
                                        </div>}
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <button
                                            onClick={getdata}
                                            className="btn btn-success"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col textAlignRight">
                                        <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                                            Back
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-sm-12" >
                                <div id="idgrid" style={{ width: "100%", height: "480px" }} className="ag-theme-balham">

                                    <AgGridReact
                                        columnDefs={columnwithDef}
                                        defaultColDef={{
                                            sortable: true,
                                            filter: true,
                                            editable: false,
                                            resizable: true,
                                            menuTabs: ['filterMenuTab']
                                        }}
                                        headerHeight={70}
                                        // rowHeight={70}
                                        rowData={rowData}
                                        suppressRowClickSelection={true}
                                        frameworkComponents={{}}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        enableRangeSelection={true}
                                        paginationPageSize={100}
                                        rowSelection={'multiple'}
                                        masterDetail={true}
                                        pagination={true}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel"
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                        gridOptions={{
                                            icons: {
                                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                                last: `<img src="${customLastIcon}" alt="Last" />`,
                                            },
                                        }}
                                        ref={gridRef}
                                        onCellEditingStopped={handleCellValueChanged}
                                        onGridReady={onGridReady}
                                        context={{}}
                                        getRowStyle={getRowStyle}
                                    />
                                </div>
                                {/* <div style={{ float: 'right', marginRight: '40px',marginBottom:'30px' }} onClick={submitdata}>
                <button type="submit" className="btn btn-success">Submit</button>
            </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
// const getCurrentWeekDates = () => {
//     let currentDate = moment(); // Get the current date
//     let startOfWeek = currentDate.clone().startOf('week'); // Get the start of the week (Sunday)
//     let endOfWeek = startOfWeek.clone().add(6, 'days'); // Get the end of the week (Saturday)

//     // Adjust startOfWeek to be the current date if it's later in the week than Sunday
//     if (currentDate > startOfWeek) {
//         startOfWeek = currentDate.clone();
//     }

//     let dates = []; // Array to store the dates

//     // Loop through each day of the week and format the date as '22-Feb-2024'
//     for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'days')) {
//         dates.push(date.format('DD-MMM-YYYY'));
//     }

//     return dates;
// };
const getCurrentWeekDates = () => {
    let currentDate = moment(); // Get the current date

    // Calculate the start date as today
    let startOfWeek = currentDate.clone();

    // Calculate the end date as the 3rd of next month
    let endOfWeek = moment().endOf('month').add(3, 'days');

    let dates = []; // Array to store the dates

    // Loop through each day of the week and format the date as '22-Feb-2024'
    for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'days')) {
        dates.push(date.format('DD-MMM-YYYY'));
    }

    return dates;
};
export default PlantTAT