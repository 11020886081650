import React from "react";
import moment from "moment";
import { countries } from "country-flags-svg";

const EventsCard = (props) => {
  //console.log("EventsCard", props);

  function snakeCaseToTitleCase(str) {
    // Split the string into words
    const words = str.split("_");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words back together with spaces
    const titleCaseString = capitalizedWords.join(" ");

    return titleCaseString;
  }
  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        className="align-content-center justify-content-start mt-3 mx-3 row"
        style={{
          minHeight: "50px",
          background: "#e4edfc",
          borderRadius: "10px",
        }}
      >
        <div class="col-1">
          <label for="">Event Group</label>
        </div>
        <div class="col-2">
          <label for="">Event Name</label>
        </div>
        <div class="col-2">
          <label for="">Sub Event</label>
        </div>
        <div class="col-1">
          <label for="">Status</label>
        </div>
        <div class="col-2">
          <label for="">Location</label>
        </div>
        <div class="col-1">
          <label for="">Original Plan</label>
        </div>
        <div class="col-1">
          <label for="">Current Plan</label>
        </div>
        <div class="col-1">
          <label for="">Actual Plan</label>
        </div>
        <div class="col-1">
          <label for="">Updated Time</label>
        </div>
      </div>
      {(!props.eventsData || props.eventsData.length === 0 || props.eventsData===undefined)?<></>:<>
      {props.eventsData.map((each) => {
        let {
          _id,
          estimated_time,
          current_time,
          actual_time,
          event_group,
          event_name,
          location,
          location_code,
          status,
          sub_event_group,
          updated_date,
        } = each;
        let eventGroup = "NA";
        if (event_group) {
          eventGroup =
            event_group.charAt(0).toUpperCase() + event_group.slice(1);

          if (eventGroup === "In_transit") {
            eventGroup = "In Transit";
          }
        }

        let eventName = "NA";
        if (event_name) {
          eventName = event_name;
        }

        let subEvent = "NA";
        if (sub_event_group) {
          subEvent = snakeCaseToTitleCase(sub_event_group);
        }

        let Status = "NA";
        if (status) {
          Status = status;
        }

        let eventLocation = "NA";
        if (location) {
          eventLocation = location;
        } else if (location_code) {
          eventLocation = location_code;
        }
        let srcIcon = getFlagIcon(location_code.slice(0, 2));

        let estimatedTime = "-";
        if (estimated_time) {
          estimatedTime = moment(
            estimated_time,
            "YYYY-MM-DD hh:mm:ss A"
          ).format("Do MMM, YYYY");
        }

        let currentTime = "-";
        if (current_time) {
          currentTime = moment(current_time, "YYYY-MM-DD hh:mm:ss A").format(
            "Do MMM, YYYY"
          );
        }

        let actualTime = "-";
        if (actual_time) {
          actualTime = moment(actual_time, "YYYY-MM-DD hh:mm:ss A").format(
            "Do MMM, YYYY"
          );
        }

        let updatedTime = "-";
        if (updated_date) {
          updatedTime = moment(updated_date, "YYYY-MM-DD hh:mm:ss A").format(
            "Do MMM, YYYY"
          );
        }
        return (
          <div
            class="justify-content-start mx-3 row"
            style={{ minHeight: "50px", borderBottom: "1px solid #dadada" }}
            key={_id}
          >
            <div class="col-1">
              <label for="" style={{ fontWeight: "normal" }}>
                {eventGroup}
              </label>
            </div>
            <div class="col-2">
              <label for="" style={{ fontWeight: "normal" }}>
                {eventName}
              </label>
            </div>
            <div class="col-2">
              <label for="" style={{ fontWeight: "normal" }}>
                {subEvent}
              </label>
            </div>
            <div class="col-1">
              <label for="" style={{ fontWeight: "normal" }}>
                {Status}
              </label>
            </div>
            <div class="col-2" style={{display:"flex",alignItems:"center"}}>
              {srcIcon.hasOwnProperty("img") &&
              srcIcon.hasOwnProperty("name") ? (
                <>
                  <img
                    src={srcIcon?.img}
                    alt="SRC"
                    className="flag_class"
                    style={{
                      marginRight:"10px",
                      transform: `${
                        srcIcon.name === "China" ? "rotateX(45deg)" : ""
                      }`,
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              <label for="" style={{ fontWeight: "normal" }}>
                {eventLocation}
              </label>
            </div>
            <div class="col-1"  >
              <label for="" style={{ fontWeight: "normal" }}>
                {estimatedTime}
              </label>{" "}
            </div>
            <div class="col-1"  >
              <label for="" style={{ fontWeight: "normal" }}>
                {currentTime}
              </label>{" "}
            </div>
            <div class="col-1" >
              <label for="" style={{ fontWeight: "normal" }}>
                {actualTime}
              </label>{" "}
            </div>
            <div class="col-1" >
              <label for="" style={{ fontWeight: "normal" }}>
                {updatedTime}
              </label>
            </div>
          </div>
        );
      })}
    </>}
    </>
  );
};

export default EventsCard;
