
import React, { Component } from 'react';
 
const DeleteItem = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickDeleteItem(props);
    };
// console.log("props ", props.data)
    return (
        <div>
            <button type="button"
              className={"btn btn-danger nopadding font12"} 
              title="Update" 
              onClick={handleClick}>
                  <i className="icofont icofont-eye f16"></i>&nbsp; Delete
            </button>
          
        </div>
    );
};

export default DeleteItem;
