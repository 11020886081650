import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TrackingShipGreen from "../../assets/icons/tracking-ship-green.svg";
import TrackingShipRed from "../../assets/icons/tracking-ship-red.svg";
import TrackingShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import { countries } from "country-flags-svg";
import Modal from "react-responsive-modal";
import redirectURL from "../redirectURL";
import moment from "moment";
import copyIcon from "../../assets/icons/copyIcon.png";
import $ from "jquery";
import { Tooltip } from "react-tooltip";
//import "react-tooltip/dist/react-tooltip.css";
import "./trackingTile.css";
import Pagination from 'react-bootstrap/Pagination';
import InfoIcon from '../../assets/icons/info.png';
import "./encss.css"

var shareURL = "http://164.52.218.255:3007"

const TrackingTile = (props) => {
  console.log("tracktile propsprops", props);
  let currentItems;
  const [currentPage, setCurrentPage] = useState(1);
  let ITEMS_PER_PAGE =props.tilesPerPage.value;
  let indexOfLastItem =currentPage * ITEMS_PER_PAGE;
  let indexOfFirstItem =indexOfLastItem - ITEMS_PER_PAGE;

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState({
    openCloseTrackingModal: false,
    openEditShipmentDetailsModal: false,
    openDeleteModal: false,
    openShareModal: false,
    modalOpened: "",
    trackingDeletionReason: "",
    shipmentToBeDeleted: "",
    sharingType: "",
    tracking_url: "",
    tracking_value: "",
    emailID: "",
    shareEmailButton: "disabled",
    responseMessage: "",
    responseMessageClass: "",
    tabType: "",
    trackingToBeClosedType: "",
    trackingToBeClosedTypeValue: "",
    forceCloseTrackingReason: "",
  });
    currentItems = props.container_data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(()=>{
    if(props.tilesPerPage.label==="ALL"){
      ITEMS_PER_PAGE=props.tilesPerPage.value
      indexOfFirstItem=0
      indexOfLastItem=props.tilesPerPage.value-1
      setCurrentPage(1)
    }
      currentItems = props.container_data.slice(indexOfFirstItem, indexOfLastItem);
  },[props,currentItems])

  const [shipmentUniqueId, setshipmentuniqueid] = useState("");

  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.name.toLowerCase() == countryName.toLowerCase()) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };
  
  function getValue(inputString) {
    if(inputString==="")
    return ""
    var words = inputString.slice(0,10)+"...";
    return words;
}

  const closeTrackingModalOpen = (serialType, serialNumber) => {
    setState({
      ...state,
      openCloseTrackingModal: true,
      modalOpened: "closeTracking",
      trackingToBeClosedType: serialType,
      trackingToBeClosedTypeValue: serialNumber,
    });
  };

  const editShipmentDetails = (shipment_unique_id) => {
    // console.log(shipment_unique_id);
    setState({
      ...state,
      openEditShipmentDetailsModal: true,
      modalOpened: "editTracking",
    });
    setshipmentuniqueid(shipment_unique_id);
  };

  const openDeleteTrackingModal = (tabType, trackingNum) => {
    setState({
      ...state,
      openDeleteModal: true,
      modalOpened: "deleteTracking",
      shipmentToBeDeleted: trackingNum,
      tabType: tabType,
    });
  };

  const[actualDuration,setActualDuration]=useState([])
  const deleteTracking = () => {
    // console.log(state.shipmentToBeDeleted);
    let payload = {};
    if (state.tabType === "BL") {
      payload = {
        mbl_number: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    } else {
      payload = {
        shipment_no: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    }

    if (state.shipmentToBeDeleted && state.trackingDeletionReason) {
      redirectURL
        .post("/exim/deleteTracking", payload)
        .then((resp) => {
          if (resp.data.status === "success") {
            closeModal();
            props.trackingDataFun();
            setState({
              ...state,
              trackingDeletionReason: "",
              shipmentToBeDeleted: "",
            });
          }
        })
        .catch((err) => {
          console.log("deleteTracking err", err);
        });
    } else {
    }
  };

  const refreshData = () => {
    props.trackingDataFun();
  };

  const forceCloseTracking = () => {
    // console.log(
    //   state.trackingToBeClosedTypeValue,
    //   state.forceCloseTrackingReason,
    //   state.trackingToBeClosedType
    // );

    let payload = {
      [state.trackingToBeClosedType]: state.trackingToBeClosedTypeValue, //shipment_no or mbl_number
      forceCloseTrackingReason: state.forceCloseTrackingReason,
    };

    redirectURL
      .post("/exim/forceCloseTracking", payload)
      .then((resp) => {
        if (resp.data.status === "success") {
          closeModal();
          props.trackingDataFun();
        }
      })
      .catch((err) => {
        console.log("forceCloseTracking err", err);
      });
  };

  const handleCopyClick = () => {
    $("#clipboardMsg").removeClass("show-n").addClass("clipboard-msg");
    // let url = document.getElementById("trackingURL");
    const inputElement = document.getElementById('trackingURL');
      inputElement.select();

      const range = document.createRange();
      range.selectNode(inputElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      // url.select();
    try {
      if (document.execCommand) {
        document.execCommand('copy');
        // alert('Text copied to clipboard!');
      } else {
        // alert('Copying to clipboard not supported.');
      }
    } catch (error) {
      console.error('Failed to copy text to clipboard', error);
    }
    // navigator.clipboard.writeText(url);
    setInterval(() => {
      $("#clipboardMsg").addClass("show-n").removeClass("clipboard-msg");
    }, 2000);
  };

  const shareTrackingDetails = (sharingType, tracking_value, tracking_url) => {
    // "Container",url, container_no
    // tracking_type, tracking_value, tracking_url
    let live_url = `${shareURL}${tracking_url}`;
    setState({
      ...state,
      openShareModal: true,
      modalOpened: "shareTracking",
      sharingType,
      tracking_url: live_url,
      tracking_value,
    });
  };

  const closeModal = () => {
    if (state.modalOpened === "closeTracking") {
      setState({
        ...state,
        openCloseTrackingModal: false,
        trackingToBeClosedType: "",
        forceCloseTrackingReason: "",
        trackingToBeClosedTypeValue: "",
      });
    } else if (state.modalOpened === "editTracking") {
      setState({
        ...state,
        openEditShipmentDetailsModal: false,
      });
    } else if (state.modalOpened === "deleteTracking") {
      setState({
        ...state,
        openDeleteModal: false,
        trackingDeletionReason: "",
        shipmentToBeDeleted: "",
      });
    } else if (state.modalOpened === "shareTracking") {
      setState({
        ...state,
        openShareModal: false,
      });
    }
  };

  // const closeShareModal = () => {
  //     setState({
  //         ...state,
  //         openShareModal: false
  //     })
  // }

  const redirectToTrackingDetails = (shipment_no, each) => {
      navigate(`/tracking_details/${shipment_no}`,{state:{each:each}});
  };
 
  const sendTrackingUrl = () => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (emailPattern.test(state.emailID)) {
      //   sharingType, tracking_value, tracking_url, mail

      let payload = {
        sharingType: state.sharingType,
        tracking_value: state.tracking_value,
        tracking_url: state.tracking_url,
        mail: state.emailID,
      };

      redirectURL
        .post("/exim/shareTrackingURL", payload)
        .then((resp) => {
          // console.log("shareTrackingURL data", resp.data);
          if (resp.data.status === "success") {
            setState({
              ...state,
              responseMessage: "Mail has been sent",
              responseMessageClass: "txt-info",
            });

            setTimeout(() => {
              setState({
                ...state,
                openShareModal: false,
                responseMessage: "",
                responseMessageClass: "",
                sharingType: "",
                tracking_url: "",
                tracking_value: "",
                emailID: "",
              });
            }, 2000);
          }
        })
        .catch((err) => {
          console.log("shareTrackingURL err", err);
        });

      //     sharingType,
      //   tracking_url,
      //   tracking_value,
    } else {
      setState({
        ...state,
        responseMessage: "Invalid Email ID",
        responseMessageClass: "txt-danger",
      });
      setTimeout(() => {
        setState({
          ...state,
          responseMessage: "",
          responseMessageClass: "",
        });
      }, 2000);
    }
  };

  return (
    <>
      {/* <Modal open={state.openCloseTrackingModal} onClose={closeModal}
      center
      classNames={{
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      animationDuration={800}> 
        <div class="">
        <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>
            Are you sure to want to close this tracking ?
          </h5>

          <div class="pt-3 ml-4">
            <div class="">
              <label for="">Reason</label>
            </div>
            <textarea
              name=""
              id=""
              cols="65"
              value={state.forceCloseTrackingReason}
              onChange={(e) => {
                setState({
                  ...state,
                  forceCloseTrackingReason: e.target.value,
                });
              }}
              rows="3"
              placeholder="Reason for Tracking Closing"
              style={{
                resize: "none",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingTop: "5px",
                width:"97%"
              }}
              required
            ></textarea>
          </div>

          <div class="button-box txt-center">
            <button class="btn btn-outline-dark mr-2" onClick={closeModal}>
              Close
            </button>
            <button
              class="btn btn-primary"
              onClick={forceCloseTracking}
              disabled={
                state.trackingToBeClosedTypeValue &&
                state.forceCloseTrackingReason &&
                state.trackingToBeClosedType
                  ? null
                  : "disabled"
              }
            >
              Complete
            </button>
          </div>
        </div>
      </Modal> */}

      {/* <Modal
        open={state.openEditShipmentDetailsModal}
        onClose={closeModal}
        // center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "custom-modal-class-edit",
        //   overlayAnimationIn: 'customEnterOverlayAnimation',
        //   overlayAnimationOut: 'customLeaveOverlayAnimation',
        //   modalAnimationIn: 'customEnterModalAnimation',
        //   modalAnimationOut: 'customLeaveModalAnimation',
        }}
        //animationDuration={800}
      >
        <div class="">
          <h6
            className="modal-heading"
            style={{ position: "relative", width: "85vw",borderTopRightRadius:"15px",borderTopLeftRadius:"15px" }}
          >
            Edit Shipment Details
          </h6>
          <div className="mt-3">
            <ModalEditShipment shipment_unique_id={shipmentUniqueId} closeModal={closeModal} />
          </div>
        </div>
      </Modal> */}

      {/* <Modal open={state.openDeleteModal} onClose={closeModal} 
      center
      classNames={{
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      animationDuration={800}>
        <div class="" style={{ height: "220px" }}>
        <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>
            Are you sure to want to delete this tracking ?
          </h5>

          <div class="pt-2 ml-4">
            <div class="">
              <label for="">Reason</label>
            </div>
            <textarea
              name=""
              id=""
              cols="65"
              value={state.trackingDeletionReason}
              onChange={(e) => {
                setState({
                  ...state,
                  trackingDeletionReason: e.target.value,
                });
              }}
              rows="3"
              placeholder="Reason for Tracking Deletion"
              style={{
                resize: "none",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingTop: "5px",
                width:"97%"
              }}
              required
            ></textarea>
          </div>

          <div class="button-box txt-center">
            <button class="btn btn-outline-dark mr-2" onClick={closeModal}>
              Close
            </button>
            <button
              class="btn btn-primary"
              onClick={deleteTracking}
              disabled={
                state.shipmentToBeDeleted && state.trackingDeletionReason
                  ? null
                  : "disabled"
              }
            >
              Delete
            </button>
          </div>
        </div>
      </Modal> */}

      {/* <Modal
        open={state.openShareModal}
        onClose={closeModal}
        center
      classNames={{
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      animationDuration={800}
        id="sharingTracking"
        styles={{ width: "531px" }}
      >
        <div class="" style={{ height: "0px" }}>
        <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>Share tracking details</h5>
        </div>
        <div className="pl-3" style={{ marginTop: "63px" }}>
          <p>
            Tracking Url for {state.sharingType} No,{" "}
            <b>{state.tracking_value}</b> is{" "}
          </p>
          <span className="show-n" id="clipboardMsg">
            Link Copied to clipboard
          </span>
          <input
            ref={inputRef}
            type="text"
            id="trackingURL"
            placeholder="Text to be copied"
            value={state.tracking_url}
            style={{
              width: "95%",
              borderRadius: "10px",
              paddingLeft: "6px",
              userSelect: "none",
              cursor: "default",
              height: "39px",
              border: "1px solid #d9d9d9",
            }}
            readOnly
            disabled
          />

          <button
            onClick={handleCopyClick}
            style={{
              borderRadius: "0px 10px 10px 0px",
              border: "none",
              height: "37px",
              position: "relative",
              right: "29px",
              background: "none",
              outline: "none",
              top: "-2px",
            }}
          >
            <img
              src={copyIcon}
              alt=""
              width="16px"
              className="cursor-class"
              style={{ filter: "invert(1)" }}
            />
          </button>

          <p class={`${state.responseMessageClass} txt-center my-1`}>
            {state.responseMessage}
          </p>

          <div class="mt-3 mb-2">
            <input
              type="email"
              name=""
              id=""
              placeholder="Enter Email to send tracking Url"
              style={{
                borderRadius: "10px",
                height: "39px",
                width: "95%",
                paddingLeft: "10px",
                outline: "none",
              }}
              value={state.emailID}
              onChange={(e) => {
                setState({
                  ...state,
                  emailID: e.target.value,
                });
              }}
            />
            <span style={{ position: "absolute", right: "29px" }}>
              <button
                class="btn btn-primary"
                style={{
                  width: "93px",
                  height: "39px",
                  borderRadius: "0px 10px 10px 0px",
                  outline: "none",
                }}
                onClick={sendTrackingUrl}
              >
                Send
              </button>
            </span>
          </div>
        </div>
      </Modal> */}

      {/* Container Tile */}
      <div className="show-m">
        {
          (!currentItems || currentItems.length===0 || currentItems===undefined)?
          <>
          <div
            key={""}
            className="card py-2 pr-3"
            style={{
              borderRadius: "18px",
              textAlign:"center",
              height:"50px",
              marginTop:"40px",
              marginBottom:"100px"
            }}
          >
            No data found.
            </div>
          </>:
          <>
          {currentItems.map((each, index) => {
          // let {shipment_no, sub_status1, mbl_number } = each;
          let {
            booking_no="NA",
            container_no="NA",
            discharge_port_code="NA",
            last_event_data="NA",
            last_port_ata="NA",
            last_port_eta="NA",
            loading_port_atd="NA",
            loading_port_code="NA",
            loading_port_etd="NA",
            mbl_number="NA",
            movement_type="NA",
            shipment_no="NA",
            shipping_line="NA",
            shipping_line_code="NA",
            tracking_updated_at="NA",
            url="NA",
            delay_message="NA",
            delay_status="NA", // ["On Time", "Early", "Delay"]
            last_port="NA",
            loading_port="NA",
            first_destination_event="NA",
            last_origin_event="NA"
          } = each;

          // console.log("first_destination_event cont",first_destination_event)
          // console.log("last_origin_event cont",last_origin_event)
          let editButtonDisable = null;
          if (
            each.hasOwnProperty("shipment_unique_id") &&
            !each.shipment_unique_id
          ) {
            editButtonDisable = "disabled";
          }

          let image = 'NA';
          let imgStatus=true;
          try{
            let MyModule=require(`../../../public/Gocomet/${shipping_line_code}.png`)
          }catch(err){
            imgStatus=false
          }

          let shipIcon;
          let progressLine;
          let textDecoration;          
          let updated_at = "";          
          let { event_group, status, location } = last_event_data[0];
          let imgPos = "0%";
          let barPos = "0%";
          // from last_origin_event
          let srcIcon = {};
          let dstnIcon = {};
          let eventLabel="NA"
          if(each.status!=="NEW" || each.status!=="UNTRACKABLE"){
          eventLabel=(each.hasOwnProperty("force_close"))?each.force_close:"NA"
          if(eventLabel===1){
            eventLabel="Force Closed"
          }
          else if(each.status==="COMPLETED"){
            eventLabel=each.all_events[each.all_events.length-1].event_name||"NA"
          }
          else{
            eventLabel=last_event_data[0].event_name||"NA"
          }

            shipIcon = TrackingShipBlue;
            progressLine = "tracking-progress-blue-line";
            textDecoration = "ontime-text";
          


          // let hours = moment().diff(tracking_updated_at, "hours");

          // if (hours > 24) {
          //   let dayTime = moment().diff(tracking_updated_at, "days");
          //   if (dayTime === 1) {
          //     updated_at = `Updated ${dayTime} day ago`;
          //   } else {
          //     updated_at = `Updated ${dayTime} days ago`;
          //   }
          // } else {
          //   if (hours === 1) {
          //     updated_at = `Updated ${hours} hour ago`;
          //   } else {
          //     updated_at = `Updated ${hours} hours ago`;
          //   }
          // }
          // if(each.status==="NEW" || each.status==="UNTRACKABLE")
          // updated_at=""

          updated_at=`Create on: ${moment(each.created_date).format("Do MMM, YYYY") }`



          if (status != "COMPLETED") {
            if (event_group === "origin") {
              barPos = "0%";
              imgPos = "0%";
            } else if (
              event_group === "in_transit" ||
              event_group === "transshipment"
            ) {
              barPos = "33%";
              imgPos = "31%";
            } else if (event_group === "destination") {
              barPos = "66%";
              imgPos = "62%";
            } else {
              barPos = "100%";
              imgPos = "95%";
            }
          }
          if (status === "COMPLETED" || last_port === location  || (each.hasOwnProperty("force_close")?each.force_close:"")===1) {
            barPos = "100%";
            imgPos = "95%"; 
          }
          if(each !== undefined)
          {
            shipIcon = TrackingShipBlue;
            progressLine = "tracking-progress-blue-line";
            textDecoration = "ontime-text";
          }


          if (each.source_location) {
            srcIcon = getFlagIcon(each.source_location);
          }

          if (each.destination_location) {
            dstnIcon = getFlagIcon(each.destination_location);
          }
        }
        if(each.status==="NEW")
        eventLabel="Newly Added"
        else if(each.status==="UNTRACKABLE")
        eventLabel="Untrackable"
          return (
            <div
              key={shipment_no}
              className="card py-4 pr-3 d-flex flex-row justify-content-between align-items-center"
              style={{
                borderRadius: "18px",
                //   cursor: "pointer"
              }}
            >
              <div className="d-flex justify-content-start align-items-start">
                    <div
                      className="d-flex flex-column justify-content-center align-items-center p-2"
                      style={{ background: "#0664AE" }}
                    >
                      <img
                        style={{
                          width: "10px",
                          marginBottom: "5px",
                        }}
                        src={ExportIcon}
                      />
                      <div
                        style={{
                          color: "white",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        EX
                      </div>
                    </div>
              </div>
              <div className="" style={{ width: "8%" }}>
              {(imgStatus)?
                <img
                  src={require(`../../../public/Gocomet/${shipping_line_code}.png`)}
                  alt={`${shipping_line}`}
                  style={{ width: "100%" }}
                />:
                <CustomSVG text={shipping_line} height="5vw" width= "7vw"/>
                }
              </div>

              <div className="d-flex flex-column justify-content-start eachCard mr-5">
                <div className="d-flex flex-row justify-content-between">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                    BL No:
                  </div>
                  {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      marginLeft: "8px",
                    }}
                  >
                    {(mbl_number==null || mbl_number=="")?"NA":mbl_number}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                    Booking No:
                  </div>
                  {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      marginLeft: "8px",
                    }}
                  >
                    {booking_no}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                    Container No: 
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      marginLeft: "8px",
                    }}
                  >
                    {container_no}
                  </div>
                </div>
              </div>

              <Tooltip
                id={`tracking_line_${shipment_no}`}
                // style={{ top: "-27.875px" }}
              />

              <div
                className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl"
                data-tooltip-id={`tracking_line_${shipment_no}`}
                data-tooltip-content={(each.status==="UNTRACKABLE" || each.status==="NEW")?"":"Click for Tracking details"}
                onClick={() => {
                  if(each.status==="UNTRACKABLE" || each.status==="NEW"){}
                  else
                  redirectToTrackingDetails(shipment_no, each);
                }}
              >
                <div className="align-items-center ml-1 portpos">
                  <div className="row textAlignCenter">
                    <div className="col-12" style={{whiteSpace:"nowrap"}}>
                      <img className="portind" src={LocationBlue}></img>
                      <br />
                      {srcIcon.hasOwnProperty("img") &&
                      srcIcon.hasOwnProperty("name") ? (
                        <>
                          <img
                            src={srcIcon?.img}
                            alt={srcIcon.hasOwnProperty("name") ? srcIcon.name : ""}
                            className="flag_class"
                            style={{
                              transform: `${
                                srcIcon.name === "China" ? "rotateX(45deg)" : ""
                              }`,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                        <div className="col-12 portxt" 
                      style={{fontSize:"10px",fontWeight:600,marginTop:"0px"}}
                      >{srcIcon.hasOwnProperty("name") ? srcIcon.name : ""}</div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12">
                        <img
                          src={srcIcon?.img}
                          alt="SRC"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              srcIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        />
                        </div>
                        <div
                          className="portxt col-12"
                        >
                          <div className="portTxtpos">
                          {loading_port}, {srcIcon?.name}
                          </div>
                        </div>
                      </div> */}
                  </div>
                </div>

                <div className="current-activity">
                  <span
                    className="ontime-text"
                    style={{
                      fontSize: "10px",
                      padding: "2px 5px",
                      position: "relative",
                      top: "-11px",
                      borderRadius: "3px",
                      fontWeight: "bolder",
                      left: "-15px",
                    }}
                  >
                     { eventLabel }
                  </span>
                </div>
                <div className="d-flex flex-row tracking-background-progress">
                  <div
                    // style={{ height: "2px", backgroundColor: "#0664AE" }}
                    style={{ "--highlight": barPos }}
                    // className="tracking-progress-green-line"
                    className={progressLine}
                  ></div>

                  <div
                    className="tracking-image-background"
                    style={{ "--highlight2": imgPos }}
                    // style={{ background: "white", position: "absolute", left: "100px", bottom: "-5px", paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <img
                      className=""
                      // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                      // src={TrackingShipGreen}
                      src={shipIcon}
                      width="23px"
                      // ["On Time", "Early", "On Time"]
                    ></img>
                  </div>
                </div>
                
                <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
                  <div className="row textAlignCenter location-flag">
                    <div className="col-12">
                      <img src={FlagGrey} className="desflg"></img>
                      <br />

                      {dstnIcon.hasOwnProperty("img") &&
                      dstnIcon.hasOwnProperty("name") ? (
                        <>
                          <img
                            src={dstnIcon?.img}
                            alt={(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}
                            className="flag_class"
                            style={{
                              transform: `${
                                dstnIcon.name === "China"
                                  ? "rotateX(45deg)"
                                  : ""
                              }`,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12" style={{whiteSpace:"nowrap"}}>
                      {/* <img src={props.destinationFlag}></img> */}
                      {/* <img
                          src={dstnIcon?.img}
                          alt="DSTN"
                          className="flag_class portsimg"
                          style={{
                            transform: `${
                              dstnIcon.name === "China" ? "rotateX(45deg)" : ""
                            }`,
                          }}
                        /> */}
                      {/* <div style={{ fontSize: "11px" }}>{props.destination}</div> */}
                        <div className="col-12 portxt" 
                      style={{fontSize:"10px",fontWeight:600,marginTop:"0px"}}
                      >{(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mr-4 d-flex flex-column justify-content-start"
                style={
                  {
                    // width: "171.575px",
                    // marginLeft:"96px"
                  }
                }
              >
                <div className="d-flex flex-row justify-content-between mb-1"  title="Vessel Estimated Time of Departure">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                  Vessel ETD:
                  </div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                      //width:"5rem"
                    }}
                  >
                    {(each.vessel_etd==null || each.vessel_etd=="")?"NA":moment(each.vessel_etd).format("Do MMM, YYYY")}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-1" title="Shipping on Board Date">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                  Shipping On Board:
                  </div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                      //width:"5rem"
                    }}
                  >
                    {(each.shipping_on_board_date==null || each.shipping_on_board_date=="")?"NA":moment(each.shipping_on_board_date).format("Do MMM, YYYY")}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-1" title="Bill of Lading Date">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                  BL Date:
                  </div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                      //width:"5rem"
                    }}
                  >
                    {(each.bill_of_lading_date==null || each.bill_of_lading_date=="")?"NA":moment(each.bill_of_lading_date).format("Do MMM, YYYY")}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-row justify-content-between mb-3">
                  <div
                    className={
                      "cursor-class " +
                      // `${
                      //   (last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE")? 
                           "trip-done"
                      //     : ""
                      // }`
                    }
                    // onClick={() => {
                    //   if (last_event_data[0].status !== "COMPLETED") {
                    //   if((each.hasOwnProperty("force_close")?each.force_close:"") ===1 || each.status==="NEW" || each.status==="UNTRACKABLE"){}
                    //   else
                    //   closeTrackingModalOpen("shipment_no", shipment_no);
                    //   }
                    // }}
                    // data-tooltip-id={`close_${shipment_no}`}
                    // data-tooltip-content="Mark as complete"
                  >
                    <img src={CompletedIcon}></img>
                  </div>
                  {/* {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"") ===1 || each.status==="NEW" || each.status==="UNTRACKABLE") ? (
                    ""
                  ) : (
                    <Tooltip id={`close_${shipment_no}`} />
                  )} */}

                  <div
                    className={
                      "cursor-class " +
                      // `${
                      //   (last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE")
                      //     ?
                           "trip-done"
                      //     : ""
                      // }`
                    }
                    // data-tooltip-id={`edit_${shipment_no}`}
                    // data-tooltip-content="Edit Shipment Details"
                    // onClick={() => {
                    //   if (last_event_data[0].status !== "COMPLETED") {
                    //     if((each.hasOwnProperty("force_close")?each.force_close:"") ===1 || each.status==="NEW" || each.status==="UNTRACKABLE"){}
                    //     else
                    //   editShipmentDetails(each.shipment_unique_id);
                    //   }
                    // }}
                  >
                    <img src={EditIcon}></img>
                  </div>
                  {/* {(last_event_data[0].status === "COMPLETED"  || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE") ? (
                    ""
                  ) : (
                    <Tooltip id={`edit_${shipment_no}`} />
                  )} */}
                  <div
                    className={
                      "cursor-class " +
                      // `${
                      //   (last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE")? 
                        "trip-done"
                      //     : ""
                      // }`
                    }
                    // onClick={() => {
                    //   if (last_event_data[0].status !== "COMPLETED") {
                    //   if((each.hasOwnProperty("force_close")?each.force_close:"") ===1 || each.status==="NEW" || each.status==="UNTRACKABLE"){}
                    //   else
                    //   openDeleteTrackingModal("BL", mbl_number);
                    //   }
                    // }}
                    // data-tooltip-id={`delete_${shipment_no}`}
                    // data-tooltip-content="Delete Tracking"
                  >
                    <img src={DeleteIcon}></img>
                  </div>
                  {/* {(last_event_data[0].status === "COMPLETED"  || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE") ? (
                    ""
                  ) : (
                    <Tooltip id={`delete_${shipment_no}`} />
                  )} */}
                  <div
                    className="cursor-class trip-done"
                    // onClick={() => {
                    //   //tracking_details/BL/SZPDL7587900
                    //   let local_url = `/tracking_details/BL/${mbl_number}`;
                    //   shareTrackingDetails(
                    //     "Container",
                    //     container_no,
                    //     local_url
                    //   );
                    // }}
                    // data-tooltip-id={`share_${shipment_no}`}
                    // data-tooltip-content="Sharing Tracking Details"
                  >
                    <img src={ShareIcon}></img>
                  </div>
                  <Tooltip id={`truck_number`} />
                </div>
                <div style={{ width: "120px", textAlign: "center" }}>
                  <div
                    className={`${textDecoration} py-1 px-3`}
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      borderRadius: "14px",
                    }}
                    data-tooltip-id={`truck_number`}
                    data-tooltip-content="Truck Number"
                  >
                    {each.truck_no}
                  </div>
                </div>
              </div>
              
              {(last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"")===1|| each.status==="NEW" || each.status==="UNTRACKABLE") ? (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <label for="" style={{ fontSize: "8px", marginBottom: "0.1rem" }}>
                    {
                        ((each.hasOwnProperty("force_close")?each.force_close:"")===1) ?
                        "Trip was Force Closed":
                        (each.status==="NEW"?"Newly Added":(each.status==="UNTRACKABLE"?"Untrackable":((each.last_event_data[0].event_group==="transshipment")?"In Transshipment":"Trip is Completed")))
                      }
                    </label>
                    <label for="" style={{ fontSize: "8px" }}>
                      {updated_at}
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    //onClick={refreshData} 
                  >
                    <img
                      className={
                        "mb-3 cursor-class " +
                        `${
                          //(last_event_data[0].status === "COMPLETED"||(each.hasOwnProperty("force_close")?each.force_close:"")===1 || each.status==="NEW" || each.status==="UNTRACKABLE")?
                             "trip-done"
                            //: ""
                        }`
                      }
                      //data-tooltip-id={`refresh_${shipment_no}`}
                      //data-tooltip-content="Refresh Tracking"
                      src={RefreshIcon}
                    />
                    {/* {last_event_data[0].status === "COMPLETED" ? 
                    (
                      ""
                    ) 
                    : (
                      <Tooltip id={`refresh_${shipment_no}`} />
                    )
                    } */}
                    <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                      {updated_at}
                    </div>
                    <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                      {`Last Event: ${moment(each.last_event_data[0].actual_time).format("Do MMM, YYYY")}`}
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
          </>
        }
        
        
        <Pagination className="custom-pagination">
        {Array.from({ length: Math.ceil(props.container_data.length / ITEMS_PER_PAGE) }, (_, i) => (
          <Pagination.Item className="custom-paginationItem"
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => setCurrentPage(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
        {/* {
          props.container_untrackable.map((each,index)=>{
            if(props.eventName==="untrackable" || props.eventName==="all"){
              return <UntrackableTiles eachNew={each} tabView= "container" eventName={ props.eventName }/>
            }
          })
        } */}
      </div>
      {/* Container Tile */}
    </>
  );
};
const CustomSVG = ({ text, height, width }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" rx="15" ry="15" fill="#f0f0f0" />
    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="12px" fill="#333">
      {text}
    </text>
  </svg>
);

export default TrackingTile;
