'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Dashboard1 from './dashboard1';
import Dashboard2 from './dashboard2';
import Dashboard3 from './dashboard3';

// const $ = window.$;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class Dashboards extends Component {
  constructor(props) {
    super(props);
      this.state = {
        tabActive:'dashboard1',
        dashboard1:'active',
        dashboard2: '',
        dashboard3:''
        //components : {datepicker:getDatePicker()}
    }
    this.onClickDashboard1 = this.onClickDashboard1.bind(this);
    this.onClickDashboard2 = this.onClickDashboard2.bind(this);
    this.onClickDashboard3 = this.onClickDashboard3.bind(this);
  }

componentDidMount(){
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
onClickDashboard1(){
    this.setState({
        tabActive:'dashboard1'
    })
}
onClickDashboard2(){
    this.setState({
        tabActive:'dashboard2'
    })
}
onClickDashboard3(){
    this.setState({
        tabActive:'dashboard3'
    })
}
 
  render() {
    const {tabActive} = this.state;
    return (
      <div class="container-fluid">
        <div className="card">
            <div className="card-header d-flex">
                <div onClick={this.onClickDashboard1} className={`mr-10p dashboard1 p-10p ${tabActive=='dashboard1'? "activeDashboard" :'inactiveDashboard'}`}>
                <h5>
                Planning
                    {/* <i className="icofont icofont-map-pins cus-i"></i> <span>Containers Summary</span> */}
                </h5>
                </div>
                <div onClick={this.onClickDashboard2} className={`mr-10p dashboard1 p-10p ${tabActive=='dashboard2'? "activeDashboard" :'inactiveDashboard'}`}>
                <h5>
                Execution
                    {/* <i className="icofont icofont-map-pins cus-i"></i> <span>Containers Summary</span> */}
                </h5>
                </div>
                <div onClick={this.onClickDashboard3} className={`p-10p dashboard1 ${tabActive=='dashboard3'? "activeDashboard" :'inactiveDashboard'}`}>
                <h5>
                Performance
                    {/* <i className="icofont icofont-map-pins cus-i"></i> <span>Containers Summary</span> */}
                </h5>
                
                </div>
            </div>
           
        </div>

        <div className="">
                {/* <iframe 
                    style={{overflow:"hidden",border:"0px", margin:"0px", width:"100%", height:"100vh"}} 
                    width="100%" 
                    frameborder="0"
                    height="100vh" 
                    src={"https://app.powerbi.com/links/ICKt1UGzCN?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5&pbi_source=linkShare"}>
                    
                </iframe> */}
                {/* <iframe title="(CDP-VS-2223-1092) Stage wise Rail TT MIS2 (TVPIN)" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=74b14979-b72c-4b16-9b77-b11e5ea2ff42&autoAuth=true&ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5" frameborder="0" allowFullScreen="true"></iframe> */}
                {/* <iframe 
                    style={{overflow:"hidden",border:"0px", margin:"0px", width:"100%", height:"100vh"}} 
                    width="100%" 
                    frameborder="0"
                    height="100vh"
                    allowFullScreen="true" 
                    src={"https://app.powerbi.com/view?r=eyJrIjoiYjFhODYxZmEtNzVlOS00OGFhLTg5YTQtZjIxOTliZjJmYWM4IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9"}>
                    
                </iframe> */}
                {tabActive =='dashboard1' ? <Dashboard1/>:''}
                {tabActive == 'dashboard2' ? <Dashboard2/>:''}
                {tabActive == 'dashboard3' ? <Dashboard3/>:''}
            </div>
      </div>

    );
  }
}