import React, { Component, useState, useEffect, useRef } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";

import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import PTData from '../../assets/json/payment_term_description.json'
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import moment from "moment";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import CountryFlagComponent from "./countryFlag";
import redirectURL from "../redirectURL";
const CHAallComponent = (props) => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [ptd, setptd] = useState(PTData.PTData ?? [])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [check, setcheck] = useState(0)
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [counters, setCounters] = useState({
        vessel_avai: []
    })
    var [workordernoList, setworkOrderList] = useState([])
    const [isClicked, setIsClicked] = useState({
        vessel_avail: false,
        uploadDo: false
    });
    var [checkresetfilter,setcheckresetfilter] = useState(0)

    const [showAddForm, setshowAddForm] = useState('')
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        wrno: '',
        booking_no: '',
        do_valid_from: '',
        do_valid_to: '',
        shipping_line: '',
        voyaga_no: '',
        freight_forwarder: '',
        vessel_name: '',
        vessel_etd_date: '',
        do_copy: '',
        terminal: '',
        pickup_yard: ''

    })
    useEffect(() => {
        if (gridRef.current) {
            setGridApi(gridRef.current.api);
            setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        loadDateTimeScript();
        if (props.data != '' && props.data != undefined) {
            setrowData(props.data)
            setoriginalData(props.data)
        }
    }, [])
    var typeofselection = [
        { label: 'Ship To Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'Plant', value: 'plant' }, { label: 'Production Date', value: 'production_date' }
    ]

    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const changeHandleDate = (e) => {
        var d = moment(e._d);
        var formattedDate = d.format('DD-MM-YYYY');
        return formattedDate
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'production_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const manualbulkUploadForCha = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if(val!='' && val!=undefined){
            if (typeoffield == 'ship_to_country') {
                var filteredData = originalData.filter(item => item.ship_to_country == val)
            }
            else if (typeoffield == 'pod') {
                var filteredData = originalData.filter(item => item.pod == val)
            }
            else if (typeoffield == 'plant') {
                var filteredData = originalData.filter(item => item.plant == val)
            }
            else if (typeoffield == 'brand') {
                var filteredData = originalData.filter(item => item.brand == val)
            }
            else if (typeoffield == 'variant') {
                var filteredData = originalData.filter(item => item.variant == val)
            }
            console.log(filteredData)
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
    }
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        from_date = moment(from_date, 'DD-MM-YYYY')
        var to_date = woReleaseDate.wo_release_to_date
        to_date = moment(to_date, 'DD-MM-YYYY')
        var filteredData = rowData.filter(item => {
            var itemDate = getHyphenDDMMYYYY(item.production_date)
            itemDate = moment(itemDate, 'DD-MM-YYYY')
            return itemDate.isBetween(from_date, to_date, null, '[]')
        });
        setrowData(filteredData)
    }
    const refreshData = ()=>{
        setcheckresetfilter(0)
        setrowData(props.data)
        setselectedval(prev=>({
            ...prev,
            inputval:''
        }))

    }
    var summaryViewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Status",
            field: "status",
            width: 180,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.status == 1) {
                    return 'New'
                }
                else if (params.data.status == 2 || params.data.status == 3) {
                    return 'Pending For CHA Vessel Availability'
                }
                else if (params.data.status == 5) {
                    return 'Pending For Stuffing Date'
                }
                else if (params.data.status == 6) {
                    return 'Pending For Upload DO'
                }
                else if (params.data.status == 7) {
                    return 'Pending For IB TPT Allocation'
                }
                else if (params.data.status == 8) {
                    return 'Pending To Update Truck'
                }
                else if (params.data.status == 12) {
                    return 'Shipping Details Raised'
                }
            }
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenYYYYMMDD(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
    ]
    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 120,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Status",
            field: "status",
            width: 180,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.status == 1) {
                    return 'New'
                }
                else if (params.data.status == 2 || params.data.status == 3) {
                    return 'Pending For CHA Vessel Availability'
                }
                else if (params.data.status == 5) {
                    return 'Pending For Stuffing Date'
                }
                else if (params.data.status == 6) {
                    return 'Pending For Upload DO'
                }
                else if (params.data.status == 7) {
                    return 'Pending For IB TPT Allocation'
                }
                else if (params.data.status == 8) {
                    return 'Pending To Update Truck'
                }
                else if (params.data.status == 12) {
                    return 'Shipping Details Raised'
                }
            }
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        // {
        //     headerName: "",
        //     field: "",
        //     width: 80,
        //     filter: true, resizable: true,
        //     // cellRenderer: 'SubmitBtn'
        //     cellRendererFramework: SubmitBtn

        // },

        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenYYYYMMDD(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            valueGetter: function (params) {
                try {
                    console.log(ptd, "ptd");
                    console.log(params.data.payment_term, "params.data.payment_term");
                    if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                        let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                        if (temp)
                            return temp.payment_term + " - " + temp.description
                        else
                            return params.data.payment_term
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            width: 350,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Tentative Shipping Line",
        //     field: "tentative_shipping_line",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenYYYYMMDD(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        // {
        //     headerName: "Tentative Gate Open Date",
        //     field: "tentative_gate_open_date",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
        //                 return getHyphenYYYYMMDD(params.data.tentative_gate_open_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        // {
        //     headerName: "Tentative Cut Off Date",
        //     field: "tentative_cut_off_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
        //                 return getHyphenYYYYMMDD(params.data.tentative_cut_off_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Remarks (By CHA)",
            field: "remarks_by_cha",
            width: 160,
            filter: true,
            resizable: true,
        },


    ]
    const onsubmitdata = (e) => {
        e.preventDefault()
        var saveData = new FormData()
        console.log("S1")
        saveData.append("work_order_no", manualUploadDetails.wrno.value)
        saveData.append("booking_no", manualUploadDetails.booking_no)
        saveData.append("do_valid_from", manualUploadDetails.do_valid_from)
        saveData.append("do_valid_to", manualUploadDetails.do_valid_to)
        saveData.append("shipping_line", manualUploadDetails.shipping_line)
        saveData.append("voyage_no", manualUploadDetails.voyage_no)
        saveData.append("freight_forwarder", manualUploadDetails.freight_forwarder)
        saveData.append("vessel_name", manualUploadDetails.vessel_name)
        saveData.append("vessel_etd_date", manualUploadDetails.vessel_etd_date)
        saveData.append("terminal", manualUploadDetails.terminal)
        saveData.append("pickup_yard", manualUploadDetails.pickup_yard)
        saveData.append("do_copy", manualUploadDetails.do_copy)
        console.log("S2")
        saveData.append("sl_cutoff_date", document.getElementById("sl_cutoff_date").value)
        saveData.append("port_cutoff_date", document.getElementById("port_cutoff_date").value)
        saveData.append("gate_open_date", document.getElementById("gate_open_datetime").value)
        console.log("S3")
        redirectURL.post('/consignments/uploadBulkuploadforUploadDO', saveData).then((resp) => {
            console.log(resp.data, '176')
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
                setloadshow('show-n')
                setoverly('show-n')
                setshowAddForm('')
            }
            else {
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
                setloadshow('show-n')
                setoverly('show-n')
                setshowAddForm('')
            }
            setmanualUploadDetails(prev => ({
                ...prev,
                wrno: { label: '', value: '' },
                booking_no: '',
                do_valid_from: '',
                do_valid_to: '',
                shipping_line: '',
                voyaga_no: '',
                freight_forwarder: '',
                vessel_name: '',
                vessel_etd_date: '',
                do_copy: '',
                terminal: '',
                pickup_yard: ''
            }))
            document.getElementById('sl_cutoff_date').value = '';
            document.getElementById('port_cutoff_date').value = '';
            document.getElementById('gate_open_datetime').value = '';
        })


    }
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Tentative shipping line', inputName: 'tentative_shipping_line', required: true },
            { name: 'Tentative vessel ETD', inputName: 'tentative_vessel_etd', required: true },
            { name: 'Tentative Gate Open Date', inputName: 'tentative_gate_open_date', required: true },
            { name: 'Tentative cut off date', inputName: 'tentative_cut_off_date', required: true },
            { name: 'Remarks(by CHA)', inputName: 'remarks', required: true },

        ])
        console.log(csvdata, '1234')
        setcsvcontent(csvdata)
    }
    const onSelectWON = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: { label: e.value, value: e.value }
        }))
    }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                data: csvcontent,
            }
            redirectURL.post('/consignments/uploadBulkuploadforCHAdata', rparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                else {
                    setshow(true)
                    // setbasicTitle("Successfully Uploaded")
                    setbasicType("Failure")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                console.log(resp.data)
            })
        }
    }
    const onClickHide = () => {
        setsliderForBulkupload('')
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: { label: '', value: '' },
            booking_no: '',
            do_valid_from: '',
            do_valid_to: '',
            shipping_line: '',
            voyaga_no: '',
            freight_forwarder: '',
            vessel_name: '',
            vessel_etd_date: '',
            do_copy: '',
            terminal: '',
            pickup_yard: ''
        }))
        document.getElementById('sl_cutoff_date').value = '';
        document.getElementById('port_cutoff_date').value = '';
        document.getElementById('gate_open_datetime').value = '';


    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var onclickbulkupload = () => {
        setsliderForBulkupload("slider-translate")
        setoverly('show-m')
    }
    const onClickCounters = (props) => {
        if (props == 'vesselAvailability') {
            setcheck(1)
            setIsClicked({ vessel_avail: true })
        }
        if (props == 'uploadDO') {
            setcheck(2)
            setIsClicked({ uploadDo: true })
        }
        else {
            setIsClicked(false)
        }
    }

    const columnwithDefs = summaryviewcheck == 1 ? summaryViewcols : detailedviewcols
    var sDate = $("#wo_order_date").val()
    const onchangeFileHandler = (e) => {
        var check = e.target.files[0].name
        var file_check = check.split(".")
        var next_check = file_check[file_check.length - 1]
        console.log(e.target.files[0], "adfdfadfa")
        if (next_check == "pdf" || next_check == "png" || next_check == "jpeg") {
            setmanualUploadDetails(prev => ({ ...prev, do_copy: e.target.files[0] }))
        } else {
            setbasicTitle("Please Upload pdf or png File")
            setbasicType("warning")
            setshow(true)
            $("#do_copy").val("")
        }
    }
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>

            <div className="row">
                {props.check == 8 ?
                    <div className="d-flex flex-row m-24p">
                        <div className="d-flex flex-row">
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: summaryviewcheck == 1 ? '#0664AE' : "",
                                    // color: state.isTileView ? "": "#0664AE",
                                    borderTopLeftRadius: "11px",
                                    borderBottomLeftRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('summary view')}
                            >
                                <div
                                    style={{
                                        color: summaryviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Summary View
                                </div>
                            </button>

                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: detailedviewcheck == 1 ? '#0664AE' : "",
                                    borderTopRightRadius: "11px",
                                    borderBottomRightRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('detailed view')}
                            >
                                <div
                                    style={{
                                        color: detailedviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Detailed View
                                </div>
                            </button>
                        </div>

                    </div> : ''}
                {props.check == 8 ?

                    <div className="form-group col-xl-2 col-lg-2 mt-24p">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    borderRadius: "11px",
                                    height: "48px",
                                    width: "260px",
                                    // padding:"22px",
                                    textAlign: "center"
                                }),
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                    </div> : ''}
                {checkinput == 1 ?
                    <div
                        className="d-flex mt-24p mt-d"
                        style={{ position: "relative" }}
                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                        {checkresetfilter != 1 ?
                            <img
                                src={SearchIcon}
                                className="search_icon"
                                onClick={filterData}
                            /> : <img
                                src={RefreshIcon}
                                className="search_icon"
                                onClick={refreshData}
                            />}
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="mt-24p mt-d">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} />
                        <img
                            src={SearchIcon}
                            className="search_icon_for_dates"
                            onClick={filterDatafordates}
                        />
                    </div>

                    : ""}
                {/* {props.check == 8 ?

                    <div className="form-group col-xl-3 col-lg-3 mt-24p">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    borderRadius: "11px",
                                    height: "48px",
                                    width: "260px",
                                    // padding:"22px",
                                    textAlign: "center"
                                }),
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                    </div> : ''}
                {checkinput == 1 ?
                    <div
                        className="d-flex mt-24p ml-8p mr-10p"
                        style={{ position: "relative" }}
                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                        <img
                            src={SearchIcon}
                            className="search_icon"
                            onClick={filterData}
                        />
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="mt-24p">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} /> <img
                        src={SearchIcon}
                        className="search_icon_for_dates"
                        onClick={filterDatafordates}
                    /></div>

                    : ""} */}
            </div>

            {props.check == 8 ?

                <div className="" style={{ margin: '10px', marginTop: '25px' }}>
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            paddingTop={10}
                            rowHeight={70}
                            headerHeight={70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            frameworkComponents={{ CountryFlagComponent: CountryFlagComponent, CountryFlagComponent: CountryFlagComponent }}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    {
                                        statusPanel: "agTotalAndFilteredRowCountComponent",
                                        align: "left"
                                    },
                                    {
                                        statusPanel: "agTotalRowCountComponent",
                                        align: "center"
                                    },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            ref={gridRef}
                            context={{}}
                        />

                    </div>
                </div> : ''}

            <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/cha_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Tentative Shipment Line & Remarks should be in 'String' format. <br />
                                Tentative Vessel ETD & Tentative Gate Open Date & Tentative cut off date should be in 'DD-MM-YYYY' format</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No: </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workordernoList}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Booking No:*</label>
                                        <input
                                            type="text"
                                            name="booking_no"
                                            id="booking_no"
                                            value={manualUploadDetails.booking_no}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, booking_no: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid From*</label>

                                        <Datetime
                                            key={manualUploadDetails.do_valid_from}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_from"
                                            value={manualUploadDetails.do_valid_from}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_from'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, do_valid_from: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid To*</label>
                                        <Datetime
                                            key={manualUploadDetails.do_valid_to}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_to"
                                            value={manualUploadDetails.do_valid_to}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_to'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, do_valid_to: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Shipping Line:*</label>
                                        <input
                                            type="text"
                                            name="shipping_line"
                                            id="shipping_line"
                                            value={manualUploadDetails.shipping_line}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, shipping_line: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Freight Forwarder:*</label>
                                        <input
                                            type="text"
                                            name="freight_forwarder"
                                            id="freight_forwarder"
                                            value={manualUploadDetails.freight_forwarder}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel Name:*</label>
                                        <input
                                            type="text"
                                            name="vessel_name"
                                            id="vessel_name"
                                            value={manualUploadDetails.vessel_name}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, vessel_name: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Voyage No:*</label>
                                        <input
                                            type="text"
                                            name="voyaga_no"
                                            id="voyaga_no"
                                            value={manualUploadDetails.voyaga_no}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, voyaga_no: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel ETD (date)*</label>
                                        <Datetime
                                            key={manualUploadDetails.vessel_etd_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="vessel_etd_date"
                                            value={manualUploadDetails.vessel_etd_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='vessel_etd_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, vessel_etd_date: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Gate Open Datetime*</label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="gate_open_datetime"
                                            value={manualUploadDetails.gate_open_datetime}
                                            dateFormat="DD-MM-YYYY"
                                            id='gate_open_datetime'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, gate_open_datetime: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text" name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="gate_open_datetime" />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Port cut off date*</label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="port_cutoff_date"
                                            value={manualUploadDetails.port_cutoff_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='port_cutoff_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, port_cutoff_date: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text" name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="port_cutoff_date" />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>SL Cut Off Date*</label>
                                        <input type="text" name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="sl_cutoff_date" />


                                    </div>

                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Terminal*</label>
                                        <input
                                            type="text"
                                            name="terminal"
                                            // placeholder='Enter Partner Type'
                                            id="terminal"
                                            value={manualUploadDetails.terminal}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, terminal: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Pickup Yard*</label>
                                        <input
                                            type="text"
                                            name="pickup_yard"
                                            // placeholder='Enter Partner Type'
                                            id="pickup_yard"
                                            value={manualUploadDetails.pickup_yard}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, pickup_yard: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Copy*</label>
                                        <input type="file" name="do_copy" id="do_copy" className="form-control requestFields" onChange={(e) => onchangeFileHandler(e)} />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>
    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    // var num = n/1000;
    // var hours = (num / (3600*24));
    // var rhours = Math.floor(hours);
    // var minutes = (n) / (60*60);
    // var rminutes = Math.round(minutes);

    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}



function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        // mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        //console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    //console.log(date.length);

    //console.log(date);
    if (date === undefined || date === null || date.length !== 10) {

        return null;

    }

    var yearNumber = date.substring(6, 10);

    var monthNumber = date.substring(3, 5);

    var dayNumber = date.substring(0, 2);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

    return result;

}
function loadDateTimeScript2() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {

    function Datepicker() { }

    Datepicker.prototype.init = function (params) {

        this.eInput = document.createElement("input");

        //this.eInput = this.eInput.setAttribute("class","datepicker");

        this.eInput.value = params.value;

        console.log(this.eInput)

        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });

    };

    Datepicker.prototype.getGui = function () {

        return this.eInput;

    };

    Datepicker.prototype.afterGuiAttached = function () {

        this.eInput.focus();

        this.eInput.select();

    };

    Datepicker.prototype.getValue = function () {

        return this.eInput.value;

    };

    Datepicker.prototype.destroy = function () { };

    Datepicker.prototype.isPopup = function () {

        return false;

    };

    return Datepicker;

}
function DateEditor() { }
// gets called once before the renderer is used

DateEditor.prototype.init = function (params) {

    // create the cell

    console.log(params, "params")

    this.eInput = document.createElement('input');

    this.eInput.value = params.value;
    // https://jqueryui.com/datepicker/

    $(this.eInput).datetimepicker({

        dateFormat: "yy-mm-dd",

        changeMonth: true,

        changeYear: true

    });

};




// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function () {

    return this.eInput;

};




// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function () {

    this.eInput.focus();

    this.eInput.select();

};




// returns the new value after editing

DateEditor.prototype.getValue = function () {

    return this.eInput.value;

};




// any cleanup we need to be done here

DateEditor.prototype.destroy = function () {

    // but this example is simple, no cleanup, we could

    // even leave this method out as it's optional

};




// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function () {

    // and we could leave this method out also, false is the default

    return false;

};
export default CHAallComponent