import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from "../redirectURL";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Make sure to import CSS for the DatePicker
const TPTSOB = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    var [currentDate, setCurrentDate] = useState(new Date());
    var initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
    var [datefilter, setDatefilter] = useState(initialDate);
    // console.log(datefilter, "datefilter01")
    var [transporterNames, setTransporterNames] = useState([]);
    var [lspSob, setlspSob] = useState([]);
    var [actualOutlook, setActualOutlook] = useState();
    const [showDatePicker, setShowDatePicker] = useState(false);
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var dateFormat = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;

    var [dateText, setDateText] = useState(dateFormat);
    let combinedArray = transporterNames.map((name, index) => {
        return { name: name, value: lspSob[index] };
    });
    
    // Sort the array based on the value in descending order
    combinedArray.sort((a, b) => b.value - a.value);
    
    // Separate the sorted names and values back into their respective arrays
    const sortedTransporterNames = combinedArray.map(item => item.name);
    const sortedLspSob = combinedArray.map(item => item.value);
   
    const options = {
        chart: {
            type: 'column',
            height: 250,
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: sortedTransporterNames,
            title: {
                text: ""
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: 'SOB %',
            },
            labels: {
                overflow: 'justify'
            },
        },
        tooltip: {
            valueSuffix: '%'
        },
        plotOptions: {
            column: {
                pointWidth: 50,
                borderRadius: '0',
                dataLabels: {
                    enabled: true,
                    // align:'center',
                    // verticalAlign:'bottom',
                    y:-12,
                    x:0
                },
                groupPadding: 0.1
            }
        },
        series: [{
            name: 'SOB%',
            data: sortedLspSob,
            color: '#0664AE',
            events: {
                legendItemClick: function () {
                    this.setVisible(true); // Always show the series when clicking the legend item
                    return false; // Prevent default behavior (toggling visibility)
                }
            }
        }],
        credits:{
            enabled:false
        }
    };

    useEffect(() => {
        getData(datefilter);
    }, [])

    const getData = (formattedDate) => {
        setloadshow('show-m')
        var date;
        if (formattedDate != '' && formattedDate != undefined) {
            date = formattedDate;
        } else {
            date = datefilter
        }
        var params = {
            month: date
        }

        redirectURL.post('/consignments/gettptsob', params).then((resp) => {
            try{
                if (resp.data.status = 'Success') {
                    var data = resp.data.lsp_sob
                    var records = JSON.parse(data)
                    if(records!='' && records!=undefined){
                        var transporterNamesData = records.map(each => each.transporter_name)
                        var lspSobData = records.map(each => each.lsp_sob)
                        // var actualOutlookData = records.map(each => each.actual_outlook)
                        setTransporterNames(transporterNamesData);
                        setlspSob(lspSobData);
                        // setActualOutlook(actualOutlookData);
                    }
                   
    
                }
            }catch(e){
                setTransporterNames([]);
                setlspSob([]);
            }finally{
                setTimeout(() => {
                    setloadshow('show-n')
                }, 3000);
            }
        }).catch(error => {
            setTransporterNames([]);
            setlspSob([]);
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    }
    const handleDateChange = (date) => {
        
        setSelectedDate(date);
        setloadshow('show-m')
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        var dateFormat1= `${month}-${year}`
        setDatefilter(formattedDate)
        setDateText(dateFormat1)
        // setfilters(prev => ({
        //     ...prev,
        //     datefilter: formattedDate
        // }))
        getData(formattedDate)
        setShowDatePicker(false)
    };
    const handleKeyDown = (e) => {
        e.preventDefault(); // Prevent any keyboard input
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    return (
        <>
            <div className='card-header2'>
                <div className='d-flex' style={{ position: "relative" }}>
                    <h2 className='heading3 mt-20p mb-20p' style={{ width: "800px" }}>Transporter SOB</h2>
                    <div className='d-flex' style={{ width: "200px", position: "absolute", right: "-170px", top: "15px" }}>
                    <h3 className='heading4 ml-10p' style={{position:"absolute",top:"8px",right:"180px", width:"80px"}}>{dateText}</h3>

                        <img src={require("../../assets/images/calendar.png")} onClick={() => setShowDatePicker(!showDatePicker)} alt="" width="25px" className="image-icons positionIcon" />
                        {showDatePicker && (
                            <div style={{ position: 'absolute', zIndex: 1000, top: '30px', left: "-200px" }}>
                                <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                minDate={new Date(1900, 0)}
                                maxDate={new Date(2100, 11)}
                                onKeyDown={handleKeyDown}
                                className="custom-datepicker"
                                calendarClassName="custom-datepicker-dropdown"
                                inline
                            />
                            </div>
                        )}
                    </div>
                </div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>


        </>
    );
}

export default TPTSOB;
