import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "./constants";
import CountUp from 'react-countup';
export default class Counters extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            oRowData:[],
            activebookings:[],
            completedbookings:[],
            pendingvendors:[],
            sendtoqa:[],
            overlay:"show-n",
            actBtn:"btn-default",
            vendBtn:"btn-active",
            qaBtn:"btn-default",
            compBtn:"btn-default"
        }
    }
	
    
    hideSlideBlock(){
        this.setState({
            slider:"",
            overlay:"show-n"
        })
    }
   
    render(){
		
        return (
            <div className="crm-numbers pb-0">
                <div className="row">
                    <div className="col cursorPointer">
                        <div className=" cirlce-d">
                            <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> {Constants.GRID_COUNTER_TOTAL}</span>
                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.props.totalvolume}/></span></h4>
                        </div>
                    </div>
                    {/* <div className="col cursorPointer">
                        <div className=" cirlce-d">
                            <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Total Stock</span>
                         
                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={this.props.openingStock}/></span></h4>
                        </div>
                    </div> */}
                    <div className="col cursorPointer">
                        <div className=" cirlce-d">
                            <span className="sptile"><i className="fa fa-users f24 greenfont"></i><br /> {Constants.GRID_COUNTER_PENDING_VENDOR}</span>
                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={this.props.pendingvendors}/></span></h4>
                        </div>
                    </div>
                    <div className="col cursorPointer">
                        <div className=" cirlce-d">
                            <span className="sptile"><i className="fa fa-user f24 greenfont"></i><br /> {Constants.GRID_COUNTER_PENDING_QA}</span>
                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.props.sendtoqa}/></span></h4>
                        </div>
                    </div>
                    <div className="col cursorPointer">
                        <div className=" cirlce-d">
                            <span className="sptile"><i className="fa fa-user f24 greenfont"></i><br /> {Constants.GRID_COUNTER_APPROVED_QTY}</span>
                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.props.totalapprvecount}/></span></h4>
                        </div>
                    </div>
                    {/* <div className="col cursorPointer">
                        <div className=" cirlce-d">
                            <span className="sptile"><i className="fa fa-user f24 greenfont"></i><br /> {Constants.GRID_COUNTER_INV_QTY}</span>
                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.props.totalInvbatchcount}/></span></h4>
                        </div>
                    </div> */}
                    <div className="col cursorPointer">
                        <div className=" cirlce-d">
                            <span className="sptile"><i className="fa fa-braille f24 greenfont"></i><br /> {Constants.GRID_COUNTER_INV}</span>
                            <h4 className="txt-info f40"><span className="counter"><CountUp end={this.props.completedbookings}/></span></h4>
                        </div>
                    </div>
                </div>
            </div>
                    
        );
    }
}   