import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import icn from "../../img/undraw_profile.svg";
import $, { param } from 'jquery';
import 'jquery-ui';
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import withRouter from './withRouter';
import CloseDocuments from './ClosedFreightDocuments';
import FreightLogs from "./BillingFreightLogs";
import { jsPDF } from "jspdf";


class DownloadFreightDocument extends Component {
    constructor(props){
        super(props);
        console.log(props)
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                menuTabs: ['filterMenuTab']
			},
            frameworkComponents:{
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {},
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            overlay:"show-n",
            csvcontent:"",
            month:{value:"",label:"Select"},
            showdata:"show-m",
            todayDieselPrice:0,
            avgDieselPrice:0,
            ctab:"btn-success",
            tltab:"btn-default",
            dtab:"show-m",
            tolltab:"show-n",
            dieselbasis:"",
            showAfterSubmit:"show-m",
            diesel_price_changed:"",
            diesel_price_changed_date:"",
            capture_diesel_price_from_day:"",
            capture_diesel_price_to_day:"",
            changed_diesel_price:"",
            changed_diesel_price_reason:"",
            changed_diesel_price_upload:"",
            approval_freight:"",
            freightapproval:[],
            hideapprovalbtn:"show-m",
            showApprovalStatus:"show-n",
            showApprovalStatusText:"",
            showLevelApprovalStatusText:"",
            aprvColor:"#000000",
            approval_levls:[],
            showUserApproveBtn:"show-n",
            hideUserApproveTbl:"show-n",
            showApproveDocumentReason:"",
            showRejectDocumentReason:"",
            approve_remarks:"",
            reject_remarks:"",
            closedDocs:[],
            otherCosts:[],
            freightlogs:[],
            document_no:""
        }
        this.loadFreightRates = this.loadFreightRates.bind(this);
        this.getDieselPrices = this.getDieselPrices.bind(this);
        this.getAvgDieselPrices = this.getAvgDieselPrices.bind(this);
        this.loadDieselPrices = this.loadDieselPrices.bind(this);
        this.onSubmitFormData = this.onSubmitFormData.bind(this);
        this.getDieselApprovalPrice = this.getDieselApprovalPrice.bind(this);
        this.onClickSendForApproavl = this.onClickSendForApproavl.bind(this);
        this.selectedHandleMonth = this.selectedHandleMonth.bind(this);
        this.getMonthStartAndENDDates = this.getMonthStartAndENDDates.bind(this);
        this.loadApprovalLevels = this.loadApprovalLevels.bind(this);
        this.loadApprovedDocuments = this.loadApprovedDocuments.bind(this);
        this.onClickGeneraePDF = this.onClickGeneraePDF.bind(this);
    }
	componentDidMount = async () => {
        var currentURL = window.location.pathname;
        // console.log("currentURL ", currentURL)
        if(currentURL.includes("view-document-approval") === true)
        {
            if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
            {
                this.setState({
                    showUserApproveBtn:"show-m",
                    hideUserApproveTbl:"show-m"
                })
            }
        }
        redirectURL.post("/freightothercosts")
		.then(async (response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                otherCosts:records
            })
        });
        await setTimeout(() => {
            redirectURL.post("/configurations")
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                if(records.length > 0)
                {
                    this.setState({
                        conf:records[0]
                    })
                   
                    if(records[0].diesel_price_capture_val === "1")
                    {
                        var diesel_percent = records[0].diesel_price_x;
                    }
                    
                    if(records[0].diesel_price_capture_val === "2")
                    {
                        var diesel_percent = "";
                    }
                    this.loadDieselPrices({diesel_percent:diesel_percent})
                    var params = {
                        document_no:this.props.params.document_no
                    }
                    this.setState({
                        document_no:this.props.params.document_no
                    })
                    this.getDieselApprovalPrice(params);
                }
                else{
                    redirectToConfigurations()
                }
            })
            
        },500)
        
        this.loadApprovedDocuments();
	}
    loadDieselPrices(params){
        redirectURL.post("/dieselprices",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    diesel_price_changed:records[0].diesel_price,
                    diesel_price_changed_date:(records[0].diesel_price_date != "" && records[0].diesel_price_date != undefined)?moment.parseZone(records[0].diesel_price_date).format("DD-MM-YYYY"):""
                })
            }
		})
    }
   
    loadApprovedDocuments(){
        redirectURL.post("/docapprovals")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    closedDocs:records
                })
            }
		})
    }
    loadFreightRates(){

        redirectURL.post("/freightrates")
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            var recordsarr = [];
            var formulacode = this.state.conf.diesel_price_formula;
            var diesel_impact_percent = (this.state.conf.diesel_impact_percent != "")?this.state.conf.diesel_impact_percent:0;
            
            if(records.length > 0)
            {
                records.map((item) => {
                    
                    // (Distance * (latest Diesel Price - Base Diesel Price)) / Mileage
                    // "Diesel impact as a percentage of Base Freight Rate * Base Freight Rate * ((Latest Diesel Price - Base Diesel Price) / Base Diesel Price)"
                    // console.log("item ", item)
                    var distance = item.total_distance;
                    var lastest_diesel_price = this.state.avgDieselPrice;
                    // console.log("this.state.avgDieselPrice ", this.state.avgDieselPrice)
                    var base_diesel_price = item.base_diesel_rate_per_approval_ltr;
                    var mileage = item.mileage;
                    var base_freight_rate = item.base_freight_rate_per_approval;
                    var diesel_price_consider_for_revision = this.state.approval_freight;
                    var changeDieselPrice = 0;
                    // console.log("formulacode ", formulacode)
                    if(formulacode === "1")
                    {   
                        // console.log('(parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)) ', (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))
                        changeDieselPrice = ((parseFloat(distance) * (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))/parseFloat(mileage)).toFixed(2);
                        // console.log("changeDieselPrice ", changeDieselPrice)
                        var diesel_impact = 0;
                    }
                    
                    if(formulacode === "2")
                    {   
                        changeDieselPrice = (((parseFloat(diesel_impact_percent)*parseFloat(base_freight_rate))/100) * base_freight_rate * ((lastest_diesel_price) - parseFloat(base_diesel_price)/parseFloat(base_diesel_price))).toFixed(2);
                        var diesel_impact = ((parseFloat(diesel_price_consider_for_revision)-parseFloat(base_diesel_price))/parseFloat(base_diesel_price)).toFixed(2);
                    }
                    item.change_in_diesel_cost = changeDieselPrice;
                    
                    // item.diesel_price_impact =  diesel_impact*100;
                    
                    var revisedprice = 0;
                    var basepric = 0;
                    if(this.state.showApprovalStatusText === "Approved")
                    {
                        if(this.state.changed_diesel_price != "")
                        {
                            item.diesel_price_consider_for_revision = this.state.changed_diesel_price;
                            revisedprice = this.state.changed_diesel_price;
                        }
                        else{
                            item.diesel_price_consider_for_revision = this.state.approval_freight;
                            revisedprice = this.state.approval_freight;
                        }
                    }
                    else{
                        item.diesel_price_consider_for_revision = this.state.approval_freight;
                        revisedprice = this.state.approval_freight;
                    }

                    try{
                        if(item.contract_valid_from != "" && item.contract_valid_from != undefined)
                        {
                            item.contract_valid_from = moment.parseZone(item.contract_valid_from).format('DD-MM-YYYY HH:mm'); 
                        }
                        if(item.contract_valid_to != "" && item.contract_valid_to != undefined)
                        {
                            item.contract_valid_to = moment.parseZone(item.contract_valid_to).format('DD-MM-YYYY HH:mm'); 
                        }

                       



                    }
                    catch(e){}
                    if(item.base_diesel_rate_per_approval_ltr !== "" && item.base_diesel_rate_per_approval_ltr !== undefined)
                    {
                        basepric = item.base_diesel_rate_per_approval_ltr;
                    }
                    var c = (((parseFloat(revisedprice)-parseFloat(basepric))/parseFloat(basepric))*100).toFixed(2);
                    item.diesel_price_impact =  c;

                    if(item.new_toll_price != "" && item.new_toll_price != undefined)
                    {
                        if(item.new_toll_price !== "" && item.new_toll_price !== undefined)
                        {
                            var a = item.new_toll_price;
                        }
                        else{
                            var a = 0;
                        }
                        if(item.base_toll_rate_per_approval !== "" && item.base_toll_rate_per_approval !== undefined)
                        {
                            var b = item.base_toll_rate_per_approval; 
                        }
                        else{
                            var b = 0;
                        }
                        
                        item.change_in_toll_price = parseInt(a)-parseInt(b);
                    }

                    var a = parseFloat(item.base_freight_rate_per_approval);
                    // console.log("A ", a);
                    var oCosts = 0;
                    
                    var otherCosts = this.state.otherCosts;
                    if(otherCosts.length > 0)
                    {
                        otherCosts.map((item) => {
                            oCosts += parseFloat(oCosts)+parseFloat(item.freight_other_cost_col_cost)
                        })
                    }
                    var b = parseFloat(oCosts);
                    // console.log("B ", b);
                    var c = 0;
                    if(item.new_toll_price != "" && item.new_toll_price != undefined)
                    {
                        if(item.new_toll_price !== "" && item.new_toll_price !== undefined)
                        {
                            var a1 = item.new_toll_price;
                        }
                        else{
                            var a1 = 0;
                        }
                        if(item.base_toll_rate_per_approval !== "" && item.base_toll_rate_per_approval !== undefined)
                        {
                            var b1 = item.base_toll_rate_per_approval; 
                        }
                        else{
                            var b1 = 0;
                        }
                        
                        var c = parseInt(a1)-parseInt(b1);
                    }

                    var revisedprice = 0;
                    var basepric = 0;
                    if(item.diesel_price_consider_for_revision !== "" && item.diesel_price_consider_for_revision !== undefined)
                    {
                        revisedprice = item.diesel_price_consider_for_revision;
                    }
                    if(item.base_diesel_rate_per_approval_ltr !== "" && item.base_diesel_rate_per_approval_ltr !== undefined)
                    {
                        basepric = item.base_diesel_rate_per_approval_ltr;
                    }
                    var d = (((parseFloat(revisedprice)-parseFloat(basepric))/parseFloat(basepric))*100).toFixed(2);
                    // console.log("D ", d);
                    item.revised_freight_cost = parseFloat(a)+parseFloat(b)+parseFloat(c)+parseFloat(d);
                    
                    // console.log("item ", item)
                    recordsarr.push(item); 
                })
            }
            this.setState({
                rowData:recordsarr,
                showAfterSubmit:"show-m"
            })
            this.loadApprovalLevels(this.props.params.document_no)
		})
    }
    loadApprovalLevels(docno){
        var params = {
            document_no:docno
        }
        if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
        {
            // params.email  = localStorage.getItem("email");
        }
        redirectURL.post("/levelwiseapprovals",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                var recordss = []
                records.map((itm) => {
                    if(itm.approved_on != "" && itm.approved_on != undefined)
                    {
                        itm.approved_on = moment.parseZone(itm.approved_on).format("DD-MM-YYYY HH:mm:ss");
                    }
                    if(itm.level === "1" || itm.level === 1)
                    {
                        if(itm.approval_status === 0)
                        {
                            itm.level_comment = "Pending to send for approval";
                            itm.dot_color = "#ff0000";
                        }
                        
                        if(itm.approval_status === 1)
                        {
                            itm.level_comment = "Send for approval";
                            itm.dot_color = "#1cc88a";
                        }
                    }else{
                        // console.log("itm.approval_status_txt , ", itm.approval_status_txt)
                        if(itm.approval_status_txt === "Pending")
                        {
                            itm.level_comment = "Pending for approval";
                            itm.dot_color = "#ff0000";
                        }
                        
                        else if(itm.approval_status_txt === "Send Back")
                        {
                            itm.level_comment = "Send Back";
                            itm.dot_color = "#cccccc";
                        }
                        
                        else if(itm.approval_status_txt === "Approved")
                        {
                            itm.level_comment = "Approved";
                            itm.dot_color = "#1cc88a";
                        }
                        else if(itm.approval_status_txt === "Rejected")
                        {
                            itm.level_comment = "Rejected";
                            itm.dot_color = "#ff0000";
                        }
                        else{
                            itm.level_comment = "";
                            itm.dot_color = "#ccc";
                        }
                    }
                    // console.log("itm ", itm)
                    recordss.push(itm);
                })
                // console.log("recordss ", recordss)
                this.setState({
                    approval_levls:recordss
                });

                if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
                {
                    if(recordss[0].approval_status_txt === "Pending")
                    {
                        var dstat = "Pending for Approval"
                    }
                    else{
                        var dstat = recordss[0].approval_status_txt;
                    }
                    // this.setState({
                    //     showLevelApprovalStatusText:dstat,
                    //     aprvColor:"#000000"
                    // })
                }
            }
		})
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onClickShowCSVForm(){
        this.setState({
            showUploadCSVForm:"show-m",
            slider:"slide30",
            overlay:"show-m"
        })
    }
    hideSlideBlock(){
        this.setState({
            showUploadCSVForm:"show-n",
            showApproveDocumentReason:"",
            showRejectDocumentReason:"",
            slider:"",
            overlay:"show-n"
        })
    }
    monthOptions(){
        var items = [{value:"",label:"Select"}];
        var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        var cmonth = moment.parseZone().format("MM");
        var cyear = moment.parseZone().format("YYYY");
        // console.log("cmonth ", parseInt(cmonth))
        for(var i=0;i<months.length;i++)
        {
            if(i<=(parseInt(cmonth)-1))
            {
                // console.log("Month ", months[i])
                if((i+1) < 10){
                    var dd = "0"+(i+1);
                }
                else{
                    var dd = i;
                }
                items.push({
                    value:cyear+"-"+dd,
                    label:months[i]+"-"+cyear
                })
            }
            
        }
        return items;
    }
    selectedHandleMonth = async (month) => {
        this.setState({
            month:month
        })
            // console.log("optin ", this.state.month)
            if(month.value != "")
            {
                var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
                this.setState({
                    overlay:"show-m"
                })
                
                var mn =month.value;
                if(mn != "")
                {
                    var ml = mn.split("-");
                    var conf = this.state.conf;
                    // console.log("conf ", conf);
                    if(conf.diesel_price_capture_val === "2")
                    {
                        var fd_avg = conf.capture_diesel_price_from_avg;
                        var fb = conf.capture_diesel_price_from_day;
                        if(fd_avg != "")
                        {
                            var frd = fd_avg.split("-");
                            var fmon = moment.parseZone().subtract(frd[1],"months").format("YYYY-MM");
                            var capture_diesel_price_from_day = moment.parseZone(new Date(fmon+"-"+fb+" 00:00:00")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_from_day ", capture_diesel_price_from_day)
                        }
                        else{
                            var capture_diesel_price_from_day = "";
                        }
                        var td_avg = conf.capture_diesel_price_to_avg;
                        var td = conf.capture_diesel_price_to_day;
                        
                        if(td_avg != "")
                        {
                            var trd = td_avg.split("-");
                            var tmon = moment.parseZone().subtract(trd[1],"months").format("YYYY-MM");
                            // console.log("tmon+td ", tmon+"-"+td)
                            var capture_diesel_price_to_day = moment.parseZone(new Date(tmon+"-"+td+" 23:59:59")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_to_day ", capture_diesel_price_to_day)
                        }
                        else{
                            var capture_diesel_price_to_day = "";
                        }
                        // console.log("tmon ",tmon)
                        // console.log("fmon ",fmon)
                        this.setState({
                            capture_diesel_price_from_day:capture_diesel_price_from_day,
                            capture_diesel_price_to_day:capture_diesel_price_to_day,
                            diesel_price_changed_date:""
                        })
                    }
                }
                var dates = await this.getMonthStartAndENDDates(month);
                // console.log("dates ",dates)
                var params = {
                    from_date: dates.start.replace(/-/g,''),
                    to_date: dates.end.replace(/-/g,'')
                }
                var avgDieselPrice = await this.getDieselPrices(params);
                await setTimeout(()=>{
                    
                // console.log("avgDieselPrice ", avgDieselPrice)
                    this.setState({
                        showdata:"show-m",
                        overlay:"show-n"
                        // avgDieselPrice:avgDieselPrice
                    })
                },2000)
                
            }
            else{
                this.setState({
                    showdata:"show-n",
                    overlay:"show-n"
                })
            }
    
    }
    handleMonth(month){
        this.setState({month},async ()=>{
            // console.log("optin ", this.state.month)
            if(this.state.month.value != "")
            {
                var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
                this.setState({
                    overlay:"show-m"
                })
                
                var mn =this.state.month.value;
                if(mn != "")
                {
                    var ml = mn.split("-");
                    var conf = this.state.conf;
                    // console.log("conf ", conf);
                    if(conf.diesel_price_capture_val === "2")
                    {
                        var fd_avg = conf.capture_diesel_price_from_avg;
                        var fb = conf.capture_diesel_price_from_day;
                        if(fd_avg != "")
                        {
                            var frd = fd_avg.split("-");
                            var fmon = moment.parseZone().subtract(frd[1],"months").format("YYYY-MM");
                            var capture_diesel_price_from_day = moment.parseZone(new Date(fmon+"-"+fb+" 00:00:00")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_from_day ", capture_diesel_price_from_day)
                        }
                        else{
                            var capture_diesel_price_from_day = "";
                        }
                        var td_avg = conf.capture_diesel_price_to_avg;
                        var td = conf.capture_diesel_price_to_day;
                        
                        if(td_avg != "")
                        {
                            var trd = td_avg.split("-");
                            var tmon = moment.parseZone().subtract(trd[1],"months").format("YYYY-MM");
                            // console.log("tmon+td ", tmon+"-"+td)
                            var capture_diesel_price_to_day = moment.parseZone(new Date(tmon+"-"+td+" 23:59:59")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_to_day ", capture_diesel_price_to_day)
                        }
                        else{
                            var capture_diesel_price_to_day = "";
                        }
                        // console.log("tmon ",tmon)
                        // console.log("fmon ",fmon)
                        this.setState({
                            capture_diesel_price_from_day:capture_diesel_price_from_day,
                            capture_diesel_price_to_day:capture_diesel_price_to_day,
                            diesel_price_changed_date:""
                        })
                    }
                }
                var dates = await this.getStartAndENDDates();
                // console.log("dates ",dates)
                var params = {
                    from_date: dates.start.replace(/-/g,''),
                    to_date: dates.end.replace(/-/g,'')
                }
                var avgDieselPrice = await this.getDieselPrices(params);
                await setTimeout(()=>{
                    
                // console.log("avgDieselPrice ", avgDieselPrice)
                    this.setState({
                        showdata:"show-m",
                        overlay:"show-n"
                        // avgDieselPrice:avgDieselPrice
                    })
                },2000)
                
            }
            else{
                this.setState({
                    showdata:"show-n",
                    overlay:"show-n"
                })
            }
        })
    }
    getDieselPrices(params){
        var conf = this.state.conf;
        // redirectURL.post("/getDieselPrices",params)
		// .then((response) => {
		// 	// console.log("response ", response.data)
        //     var prices = response.data.prices;
        //     var avgprice = this.getAvgDieselPrices(prices);
        //     // console.log("avgprice ", avgprice)

        //     var conf = this.state.conf;
        //     var dPrice = 0;
        //     var dieselbasis = "";
        //     if(conf.diesel_price_capture_val != "" && conf.diesel_price_capture_val != undefined)
        //     {
        //         if(conf.diesel_price_capture_val == "1")
        //         {
        //             var diesel_price_x = parseFloat(conf.diesel_price_x);
        //             // console.log("diesel_price_x ", diesel_price_x)
        //             // console.log("avgprice ", avgprice)
        //             dPrice = parseFloat(avgprice)+(parseFloat(avgprice)*(parseFloat(diesel_price_x)/100));
        //             // console.log("dPrice ", dPrice);
        //             dieselbasis = "Diesel price changed by "+diesel_price_x+" %";
        //             this.setState({
        //                 avgDieselPrice:dPrice.toFixed(2),
        //                 dieselbasis:dieselbasis
        //             })
        //         }
        //         if(conf.diesel_price_capture_val == "2")
        //         {
        //             // console.log("avgprice ", avgprice)
        //             dieselbasis = " Daily diesel price averages ";
        //             dPrice = avgprice;
        //             this.setState({
        //                 avgDieselPrice:dPrice,
        //                 dieselbasis:dieselbasis
        //             })
        //         }

        //     }
        //     else{
        //         dPrice = 0;
        //         this.setState({
        //             avgDieselPrice:dPrice,
        //             dieselbasis:""
        //         })
        //     }

        var dieselbasis = "";
        if(conf.diesel_price_capture_val != "" && conf.diesel_price_capture_val != undefined)
        {
            if(conf.diesel_price_capture_val == "1")
            {
                var diesel_price_x = parseFloat(conf.diesel_price_x);
                dieselbasis = "Diesel price changed by "+diesel_price_x+" %";
                this.setState({
                    // avgDieselPrice:dPrice.toFixed(2),
                    dieselbasis:dieselbasis
                })
            }
            if(conf.diesel_price_capture_val == "2")
            {
                // console.log("avgprice ", avgprice)
                dieselbasis = " Daily diesel price averages ";
                this.setState({
                    // avgDieselPrice:dPrice,
                    dieselbasis:dieselbasis
                })
            }

        }
        else{
            this.setState({
                // avgDieselPrice:dPrice,
                dieselbasis:""
            })
        }
            
            this.loadFreightRates();
            
		// })
    }
    getAvgDieselPrices(prices){
        try{
            var allprices = []
            if(prices.length > 0)
            {
                prices.map((item) => {
                    if(item.fuel_price != "" && item.fuel_price != undefined)
                    {
                        allprices.push(item.fuel_price);
                    }
                    
                })
            }
            // console.log("allprices ", allprices.length)
            var avg =0;
            if(allprices.length > 0)
            {
                var sum = 0;
                for (let i = 0; i < allprices.length; i++) {
                    sum += allprices[i];
                }
                avg = (sum/allprices.length).toFixed(2);
            }
            return avg;
        }
        catch(e){}
        
    }
    getStartAndENDDates(){
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        var month = this.state.month.value;
        // console.log("month ", month)
        // if(this.state.conf.diesel_frequence_option == "FortNightly")
        // {
        //     var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //     var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
        //     var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //     var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //     freqFrom = frm; 
        //     freqTo = trm; 
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Weekly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days.sort();

        //         var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Monthly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days[0];
                
        //         var fm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }

        try{
            if(this.state.freightapproval[0] !== undefined && this.state.freightapproval[0] !== "")
            {
                // console.log("this.state.freightapproval[0] ", this.state.freightapproval[0])
                var freq = this.state.freightapproval[0].diesel_frequence_option;
                var freqFrom = "";
                var freqTo = "";
                
                freqFrom = this.state.freightapproval[0].diesel_frequence_from_period; 
                freqTo = this.state.freightapproval[0].diesel_frequence_to_period; 
                // console.log("{start:freqFrom,end:freqTo} ",{start:freqFrom,end:freqTo})
                return {start:freqFrom,end:freqTo};
            }
        }
        catch(e){

        }
        
    }
    
    getMonthStartAndENDDates(monthOpt){
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        var month = monthOpt.value;
        // console.log("this.state.freightapproval[0] ", this.state.freightapproval[0])
        // console.log("month ", month)
        // if(this.state.conf.diesel_frequence_option == "FortNightly")
        // {
        //     var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //     var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
        //     var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //     var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //     freqFrom = frm; 
        //     freqTo = trm; 
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Weekly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days.sort();

        //         var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        
        // if(this.state.conf.diesel_frequence_option == "Monthly")
        // {
        //     var days = this.state.conf.diesel_revision_day;
        //     if(days.length > 0)
        //     {
        //         var daylist = days[0];
                
        //         var fm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
        //         var tm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
        //         var trm = moment.parseZone(tm).format("DD-MM-YYYY");
        //         freqFrom = frm; 
        //         freqTo = trm; 
        //     }
            
        // }
        // return {start:freqFrom,end:freqTo};


        try{
            if(this.state.freightapproval[0] !== undefined && this.state.freightapproval[0] !== "")
            {
                // console.log("this.state.freightapproval[0] ", this.state.freightapproval[0])
                var freq = this.state.freightapproval[0].diesel_frequence_option;
                var freqFrom = "";
                var freqTo = "";
                
                freqFrom = this.state.freightapproval[0].diesel_frequence_from_period; 
                freqTo = this.state.freightapproval[0].diesel_frequence_to_period; 
                // console.log("{start:freqFrom,end:freqTo} ",{start:freqFrom,end:freqTo})
                return {start:freqFrom,end:freqTo};
            }
        }
        catch(e){

        }
        

    }
    showTabView(oVal){
        if(oVal == 1)
        {
            if(this.state.month.value !== "")
            {
                var shown = "show-m";
            }
            else{
                var shown = "show-n";
            }
            this.setState({
                ctab:"btn-success",
                tltab:"btn-default",
                dtab:"show-m",
                tolltab:"show-n",
                showAfterSubmit:"show-n",
                showdata:shown,
                showApprovalStatus:"show-m"
            })
        }
        if(oVal == 2)
        {
            this.setState({
                ctab:"btn-default",
                tltab:"btn-success",
                dtab:"show-n",
                tolltab:"show-m",
                showAfterSubmit:"show-m",
                showdata:"show-n",
                showApprovalStatus:"show-n"
            })
        }
    }
    changeHandler(event){
        console.log("event ", event)
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    onSubmitFormData(event){
        event.preventDefault();
        var month = this.state.month.value;
        var diesel_frequence_option = this.state.conf.diesel_frequence_option;
        var avgDieselPrice = this.state.avgDieselPrice;
        var dieselbasis = this.state.dieselbasis;
        var diesel_price_changed_date = (this.state.diesel_price_changed_date != "")?this.state.diesel_price_changed_date:"";
        var capture_diesel_price_from_day = (this.state.capture_diesel_price_from_day != "")?this.state.capture_diesel_price_from_day:"";
        var capture_diesel_price_to_day = this.state.capture_diesel_price_to_day;
        var changed_diesel_price = this.state.changed_diesel_price;
        var changed_diesel_price_reason = this.state.changed_diesel_price_reason;
        var changed_diesel_price_upload = this.state.changed_diesel_price_upload;
        var params = {
            selected_month:month,
            diesel_frequence_option:diesel_frequence_option,
            avgDieselPrice:avgDieselPrice,
            dieselbasis:dieselbasis,
            diesel_price_changed_date:diesel_price_changed_date,
            capture_diesel_price_from_day:capture_diesel_price_from_day,
            capture_diesel_price_to_day:capture_diesel_price_to_day,
            changed_diesel_price:changed_diesel_price,
            changed_diesel_price_reason:changed_diesel_price_reason,
            changed_diesel_price_upload:changed_diesel_price_upload
        }
        // console.log("params",params)
        redirectURL.post("/savedieselapprovalform",params)
		.then((response) => {
			//console.log("response ", response.data)
            // var records = response.data.records;
            // console.log("records ", records)
            if(response.data.status === "success")
            {
                var rownode = response.data.records[0];
                this.getDieselApprovalPrice(rownode);
            }
		});
    }
    getDieselApprovalPrice(params){
        redirectURL.post("/dieselapprovaldata",params)
		.then(async (response) => {
			//console.log("response ", response.data)
                var records = response.data.records;
                // console.log("records ", records)
                if(records.length > 0)
                {
                    var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
                    if(records[0].selected_month != "")
                    {
                        var s = records[0].selected_month.split("-");
                        var monthlbl = months[s[1]-1]+"-"+s[0];
                    }
                    else{
                        var monthlbl = "";
                    }
                    this.setState({
                        month:{value:records[0].selected_month,label:monthlbl}
                    })
                    
                    this.setState({
                        overlay:"show-m"
                    })
                    
                    if(records[0].approval_status > 0)
                    {
                        if(records[0].approval_status === 1)
                        {
                            this.setState({
                                showApprovalStatus:"show-m",
                                showApprovalStatusText:"Pending for approvals",
                                aprvColor:'#7e022a'
                            })
                        }
                        if(records[0].approval_status === 2)
                        {
                            this.setState({
                                showApprovalStatus:"show-m",
                                showApprovalStatusText:"Approved",
                                aprvColor:'#016e48'
                            })
                        }
                        if(records[0].approval_status === 3)
                        {
                            this.setState({
                                showApprovalStatus:"show-m",
                                showApprovalStatusText:"Rejected",
                                aprvColor:'#ff0000'
                            })
                        }
                        
                        this.setState({
                            hideapprovalbtn:"show-n",
                        })
                    }
                    
                }
                else{
                    this.setState({
                        showdata:"show-n",
                        overlay:"show-n"
                    })
                }

                this.setState({
                    approval_freight:records[0].applicable_diesel_price,                    
                    changed_diesel_price:records[0].changed_diesel_price,
                    changed_diesel_price_reason:records[0].changed_diesel_price_reason,
                    changed_diesel_price_upload:records[0].changed_diesel_price_upload,
                    avgDieselPrice:records[0].applicable_diesel_price,
                    freightapproval:records
                })
                setTimeout(() => {
                    this.selectedHandleMonth({value:records[0].selected_month,label:monthlbl})
                },500)
                
		});
    }
    onClickSendForApproavl(){
        // console.log("freightapproval ", this.state.freightapproval);
        var params = {
            document_no:this.state.freightapproval[0].document_no
        }
        redirectURL.post("/sendForApproval",params)
		.then((response) => {
			//console.log("response ", response.data)
            // var records = response.data.records;
            // console.log("records ", records)
            if(response.data.status === "success")
            {
                this.setState({
                    // approval_freight:records[0].applicable_diesel_price,
                    hideapprovalbtn:"show-n"
                })
                window.location.reload();
            }
		});
    }
    onClickSendBackDocument(){
        var document_no = this.props.params.document_no;
        var email = localStorage.getItem("email");
        var params ={
            email:email,
            document_no:document_no
        }
        // console.log("SendBack ", params);
        redirectURL.post("/sendBackDocument",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        });

    }
    onClickApproveDocument(){
        this.setState({
            showApproveDocumentReason:"slide30",
            overlay:"show-m"
        })
        
    }
    onClickRejectDocument(){
        
        this.setState({
            showRejectDocumentReason:"slide30",
            overlay:"show-m"
        })
       
    }
    formApproveDocumentItem(event){
        event.preventDefault();
        var approve_remarks = this.state.approve_remarks;

        var document_no = this.props.params.document_no;
        var email = localStorage.getItem("email");
        var params ={
            email:email,
            document_no:document_no,
            approve_remarks:approve_remarks
        }
        // console.log("Approve ", params);
        redirectURL.post("/approveDocument",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        });
    }
    
    formRejectDocumentItem(event){
        event.preventDefault();
        var reject_remarks = this.state.reject_remarks;

        var document_no = this.props.params.document_no;
        var email = localStorage.getItem("email");
        var params ={
            email:email,
            document_no:document_no,
            reject_remarks:reject_remarks
        }
        // console.log("Approve ", params);
        redirectURL.post("/rejectDocument",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        });
    }
    onCellEditingStopped = (_params) => {
        // console.log("_params ",_params)
        if (_params.newValue != _params.oldValue) {
            redirectURL.post("/updateTollPrice",_params.data)
            .then((response) => {
                if(response.data.status === "success")
                {
                    // this.gridApi.setRowData(_params);
                }
            });
        }
    }
    onClickGeneraePDF(){
        // const doc = new jsPDF({
        //     orientation: "landscape",
        //     unit: "in",
        //     format: [4, 2]
        // });
        var filename = "document-"+this.state.document_no+".pdf";
        const doc = new jsPDF({
            orientation:'portrait',
			format: [1800, 2000],
			unit: 'px',
            compress:true
		});
        var elementHTML = document.querySelector(".doucmnetData");
        doc.setFontSize(12);
        doc.html(elementHTML, {
            callback: function(doc) {
                // Save the PDF
                doc.save(filename);
            },
            x: 15,
            y: 15,
            // width: 170, //target width in the PDF document
            // windowWidth: 650 //window width in CSS pixels
        });
    }
    render(){
        var currentURL = window.location.pathname;
        // console.log("currentURL ", currentURL)
        if(currentURL.includes("view-document-approval") === true)
        {
            var iseditable = true;
            var showpagetabs="show-m";
            var showpagelogs="show-n";
        }
        else{
            var iseditable = false;
            var showpagetabs="show-n";
            var showpagelogs="show-m";
        }
        var otherCosts = this.state.otherCosts;
		var columnDefs = [
            {
                headerName:Constants.FREIGHT_RATE_ROUTE,
                field:"route",
                width:120,
                // cellRendererSelector:function(params){
                //     return {
                //         component:"ViewPOD"
                //     }
                // },
            },
            {
                headerName:Constants.FREIGHT_RATE_SOURCE,
                field:"source",
                width:120,
            },
            {
                headerName:Constants.FREIGHT_RATE_DESTINATION,
                field:"destination",
                width:160,
            },
            {
                headerName:Constants.FREIGHT_RATE_LSP,
                field:"transporter_name",
                width:140
            },
            {
                headerName:Constants.FREIGHT_RATE_RATE_CLASSIFICATION,
                field:"rate_classification",
                width:140
            },
            {
                headerName:Constants.FREIGHT_RATE_CONTRACT,
                field:"contract_approval_no",
                width:100,
            },
            
            {
                headerName:Constants.FREIGHT_RATE_CONTRACT_VALID_FROM,
                field:"contract_valid_from",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.contract_valid_from != "" && params.data.contract_valid_from != undefined)
                        {
                            return moment.parseZone(params.data.contract_valid_from).format('DD-MM-YYYY HH:mm'); 
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_CONTRACT_VALID_TO,
                field:"contract_valid_to",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.contract_valid_to != "" && params.data.contract_valid_to != undefined)
                        {
                            return moment.parseZone(params.data.contract_valid_to).format('DD-MM-YYYY HH:mm'); 
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_TRUCK_TYPE,
                field:"truck_type",
                width:100,
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_DISTANCE,
            //     field:"total_distance",
            //     width:120,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_MILEAGE,
            //     field:"mileage",
            //     width:120,
            // },
            {
                headerName:Constants.FREIGHT_RATE_BASE_FREIGHT_RATE_PER_APPROVAL,
                field:"base_freight_rate_per_approval",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_BASE_DIESEL_RATE_PER_LTR,
                field:"base_diesel_rate_per_approval_ltr",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_TOLL_RATE_PER_APPROVAL,
                field:"base_toll_rate_per_approval",
                width:180,
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_BASE_DIESEL_CITY,
            //     field:"base_diesel_city",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_BASE_DIESEL_STATE,
            //     field:"base_diesel_state",
            //     width:180,
            // },
            
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_APPLICABLE_DATE,
                field:"revised_diesel_price_applicable_date",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_CONSIDERED_FOR_REVISION,
                field:"diesel_price_consider_for_revision",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_IMPACT_PERCENT,
                field:"diesel_price_impact",
                width:180,
                valueGetter:function(params){
                    try {
                        var revisedprice = 0;
                        var basepric = 0;
                        if(params.data.diesel_price_consider_for_revision !== "" && params.data.diesel_price_consider_for_revision !== undefined)
                        {
                            revisedprice = params.data.diesel_price_consider_for_revision;
                        }
                        if(params.data.base_diesel_rate_per_approval_ltr !== "" && params.data.base_diesel_rate_per_approval_ltr !== undefined)
                        {
                            basepric = params.data.base_diesel_rate_per_approval_ltr;
                        }
                        var c = (((parseFloat(revisedprice)-parseFloat(basepric))/parseFloat(basepric))*100).toFixed(2);
                        return c;
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_DIESEL_COST,
                field:"change_in_diesel_cost",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_TOLL_REVISION_APPLICABLE_DATE,
                field:"toll_revision_applicable_date",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_NEW_TOLL_PRICE,
                field:"new_toll_price",
                width:180,
                editable:iseditable
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_TOLL_PRICE,
                field:"change_in_toll_price",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.new_toll_price != "" && params.data.new_toll_price != undefined)
                        {
                            if(params.data.new_toll_price !== "" && params.data.new_toll_price !== undefined)
                            {
                                var a = params.data.new_toll_price;
                            }
                            else{
                                var a = 0;
                            }
                            if(params.data.base_toll_rate_per_approval !== "" && params.data.base_toll_rate_per_approval !== undefined)
                            {
                                var b = params.data.base_toll_rate_per_approval; 
                            }
                            else{
                                var b = 0;
                            }
                            
                            return parseInt(a)-parseInt(b);
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_COST,
                field:"revised_freight_cost",
                width:180,
                valueGetter:function(params){
                    try {
                        var a = parseFloat(params.data.base_freight_rate_per_approval);
                        // console.log("A ", a);
                        var oCosts = 0;
                        // console.log("otherCosts ", otherCosts)
                        if(otherCosts.length > 0)
                        {
                            otherCosts.map((item) => {
                                oCosts += parseFloat(oCosts)+parseFloat(item.freight_other_cost_col_cost)
                            })
                        }
                        var b = parseFloat(oCosts);
                        // console.log("B ", b);
                        var c = 0;
                        if(params.data.new_toll_price != "" && params.data.new_toll_price != undefined)
                        {
                            if(params.data.new_toll_price !== "" && params.data.new_toll_price !== undefined)
                            {
                                var a1 = params.data.new_toll_price;
                            }
                            else{
                                var a1 = 0;
                            }
                            if(params.data.base_toll_rate_per_approval !== "" && params.data.base_toll_rate_per_approval !== undefined)
                            {
                                var b1 = params.data.base_toll_rate_per_approval; 
                            }
                            else{
                                var b1 = 0;
                            }
                            
                            var c = parseInt(a1)-parseInt(b1);
                        }

                        var revisedprice = 0;
                        var basepric = 0;
                        if(params.data.diesel_price_consider_for_revision !== "" && params.data.diesel_price_consider_for_revision !== undefined)
                        {
                            revisedprice = params.data.diesel_price_consider_for_revision;
                        }
                        if(params.data.base_diesel_rate_per_approval_ltr !== "" && params.data.base_diesel_rate_per_approval_ltr !== undefined)
                        {
                            basepric = params.data.base_diesel_rate_per_approval_ltr;
                        }
                        var d = (((parseFloat(revisedprice)-parseFloat(basepric))/parseFloat(basepric))*100).toFixed(2);
                        // console.log("D ", d);
                        return parseFloat(a)+parseFloat(b)+parseFloat(c)+parseFloat(d);
                    } catch (error) {
                        
                    }
                }
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_FROM,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_TO,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
        ]
        var closedcolumnDefs=[
            {
                headerName:Constants.FREIGHT_APPROVALS_DOCUMENT_NO,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_REVISION_MONTH,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPLICABLE_PERIOD,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_STATUS,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_VIEW_DOC,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_DOWNLOAD_DOC,
                field:"document_no"
            }
        ]
        var month = this.state.month.value;
        // console.log("month ",month)
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        if(this.state.freightapproval[0] !== undefined && this.state.freightapproval[0] !== "")
        {
            var freq = this.state.freightapproval[0].diesel_frequence_option;
            var freqFrom = "";
            var freqTo = "";
            
            freqFrom = this.state.freightapproval[0].diesel_frequence_from_period; 
            freqTo = this.state.freightapproval[0].diesel_frequence_to_period; 
        }
        

        var approval_levls = this.state.approval_levls;
        return (
            <div className="container-fluid">
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">{Constants.PAGE_TITLE_DIESEL_APPROVAL}
                        <span style={{float:"right"}}>
                            <a href={"/dieselprices"} type="button" className={"btn btn-info"} style={{marginRight:"10px"}}>Diesel Prices</a>
                            {/* <a href={"/tollprices"} type="button" className={"btn btn-success"} style={{marginRight:"10px"}}>Tolls</a> */}
                            {/* <a href={"/approvals"} type="button" className={"btn btn-danger"}>Approvals</a> */}
                            
                        </span>
                    </h1>
				</div>
                <div className="card shadow h-100 py-2 " style={{width:"100%"}}>
                <div style={{textAlign:"center",position:"absolute",left:"1%",zIndex:"9"}}>
                    <button onClick={this.onClickGeneraePDF} className="btn btn-info">Download PDF</button>
                </div>
                    <div className="card-body doucmnetData" style={{position:"relative"}}>
                        <ul className={"prg-l "+(this.state.showlevelprocess)}>
                            {(this.state.approval_levls.length > 0)?
                            (this.state.approval_levls.map((itm) =>
                                <li className={(itm.approval_status === 0)?"gryeBG":"grnlbg"}>
                                    <span className="startDot" style={{backgroundColor:(itm.dot_color)}}></span>
                                    <span className="startLine" ></span>
                                    Level-{itm.level}
                                    {
                                        (itm.approval_status === 0)?
                                        // <span className="startLine" style={{border:"2px dotted #ff0000"}}></span>
                                        <span style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"3px",borderTop:"3px dotted #ff0000"}}></span>
                                        :
                                        (itm.approval_status === 1)?
                                        // <span className="startLine" style={{border:"2px dotted #1cc88a"}} ></span>
                                        <span style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"3px",borderTop:"3px solid #1cc88a"}}></span>
                                        :""
                                    }
                                    
                                    {/* <div>{itm.email}</div> */}
                                    <div>{(itm.approval_status === 0)?<span style={{color:"#000000",fontWeight:"700"}}>{itm.level_comment}</span>:
                                    (itm.approval_status === 1)?<span style={{color:"#1cc88a",fontWeight:"700"}}>{itm.level_comment}</span>:<span style={{color:"#ff0000",fontWeight:"700"}}>{itm.level_comment}</span>}</div>
                                    {(itm.approved_on !== "" && itm.approved_on !== undefined)?
                                    <div>Approved On: {itm.approved_on}</div>
                                    :<div>&nbsp;<br /></div>}
                                    
                                </li>
                            ))
                            :""}
                        </ul>
                        <div className={this.state.dtab}>
                            
                            {/* <div style={{fontWeight:"bold"}}>
                                   Current Approval Status: 
                                   {(this.state.hideUserApproveTbl === "show-n")?
                                   <span style={{color:(this.state.aprvColor)}}>{this.state.showApprovalStatusText}</span>
                                   :
                                   <span style={{color:(this.state.aprvColor)}}>{this.state.showLevelApprovalStatusText}</span>
                                   }<br />
                            </div> */}
                            <div className="row" style={{marginTop:"20px"}}>
                                <div className="col-xl-2 col-md-12 mb-2">
                                    Select Month:
                                </div>
                                <div className="col-xl-3 col-md-12 mb-2">
                                   {this.state.month.label}
                                </div>
                                
                                <div className="col-xl-3 col-md-12 mb-2"></div>
                                <div className="col-xl-3 col-md-12 mb-2">
                                </div>
                            </div>
                            <div className={"col-12 "}>
                                <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                        Frequency: 
                                    </div>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                    {freq}
                                    <input type="hidden" id="frequency" value={freq} />
                                    </div>
                                    <div className="col-xl-1 col-md-12 mb-2">&nbsp;</div>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                        Period: &nbsp;&nbsp;
                                        From : {freqFrom}
                                        <input type="hidden" id="from_period" value={freqFrom} />
                                    </div>
                                    <div className="col-xl-3 col-md-12 mb-2">
                                        To : {freqTo}
                                        <input type="hidden" id="to_period" value={freqTo} />
                                    </div>
                                </div>
                                <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                        Applicable Diesel price: 
                                    </div>
                                    <div className="col-xl-3 col-md-12 mb-2">
                                        {this.state.avgDieselPrice}
                                    </div>
                                </div>
                                
                                <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                        Diesel prices basis: 
                                    </div>
                                    <div className="col-xl-3 col-md-12 mb-2">
                                        {this.state.dieselbasis}
                                    </div>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                        Diesel Price Dates: 
                                    </div>
                                    <div className="col-xl-3 col-md-12 mb-2">
                                        {(this.state.diesel_price_changed_date != "")?this.state.diesel_price_changed_date:""}
                                        {(this.state.capture_diesel_price_from_day != "")?"From : "+this.state.capture_diesel_price_from_day:""}
                                    </div>
                                    <div className="col-xl-3 col-md-12 mb-2">
                                        {(this.state.capture_diesel_price_to_day != "")?"To : "+this.state.capture_diesel_price_to_day:""}
                                    </div>
                                </div>
                                    <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                        <div className="col-xl-2 col-md-12 mb-2">
                                        Do you want to change Diesel price? 
                                        </div>
                                        <div className="col-xl-3 col-md-12 mb-2">
                                            {this.state.changed_diesel_price}
                                        </div>
                                        <div className="col-xl-2 col-md-12 mb-2">
                                        Reason for changin price 
                                        </div>
                                        <div className="col-xl-3 col-md-12 mb-2">
                                            {this.state.changed_diesel_price_reason}
                                        </div>
                                    </div> 
                                    <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                        <div className="col-xl-2 col-md-12 mb-2">
                                        Upload Document
                                        </div>
                                        <div className="col-xl-6 col-md-12 mb-2">
                                            <a href={this.state.changed_diesel_price_upload} target="_blank">{this.state.changed_diesel_price_upload}</a>
                                        </div>
                                    </div>
       
                            </div>
                        </div>
                        <div className={"row doucmnetData "+(this.state.showAfterSubmit)} style={{marginTop:"20px"}}>
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card shadow h-100 py-2">
                                    <div className="card-body">
                                    
                                        <div className={"row "+this.state.dtab} >
                                        
                                            {/* <div style={{width:"100%",height:"30vh"}} className="ag-theme-balham">
                                            <AgGridReact 
                                                rowData={this.state.rowData} 
                                                columnDefs={columnDefs}
                                                defaultColDef ={this.state.defaultColDef }
                                                enableRangeSelection={true}
                                                enableCharts={true}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                masterDetail={true}
                                                onGridReady={this.onGridReady}
                                                sideBar={this.state.sideBar}
                                                frameworkComponents={this.state.frameworkComponents}
                                                statusBar={this.state.statusBar}
                                                stopEditingWhenGridLosesFocus= {true}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                onCellEditingStopped={this.onCellEditingStopped.bind(this)}
                                                
                                            />
                                            </div> */}
                                            <div className="col-sm-12">
                                                <table className="table table-bordered stable">
                                                    <thead>
                                                        <tr>
                                                            <th>Operation / Route</th>
                                                            <th>Source</th>
                                                            <th>Destination</th>
                                                            <th>Service Provider (LSP)</th>
                                                            <th>Route Classification (UOM)</th>
                                                            <th>Contract / Approval Note No</th>
                                                            <th>Valid From</th>
                                                            <th>Valid To</th>
                                                            <th>Truck Type</th>
                                                            <th>Base Freight as per approval (INR)</th>
                                                            <th>Base Diesel Rate as per approval (Rs/ltr)</th>
                                                            <th>Base Toll Rate as per approval (Rs)</th>
                                                            <th>Revised Diesel Price (Rs/ltr)</th>
                                                            <th>Diesel Price Impact (%)</th>
                                                            <th>Change in Diesel Cost</th>
                                                            <th>New Toll Price</th>
                                                            <th>Change in Toll Price</th>
                                                            <th>Revised Freight Cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.rowData.length > 0)?
                                                            this.state.rowData.map(item=>
                                                            <tr>
                                                                <td>{item.route}</td>
                                                                <td>{item.source}</td>
                                                                <td>{item.destination}</td>
                                                                <td>{item.transporter_name}</td>
                                                                <td>{item.rate_classification}</td>
                                                                <td>{item.contract_approval_no}</td>
                                                                <td>{item.contract_valid_from}</td>
                                                                <td>{item.contract_valid_to}</td>
                                                                <td>{item.truck_type}</td>
                                                                <td>{item.base_freight_rate_per_approval}</td>
                                                                <td>{item.base_diesel_rate_per_approval_ltr}</td>
                                                                <td>{item.base_toll_rate_per_approval}</td>
                                                                <td>{item.diesel_price_consider_for_revision}</td>
                                                                <td>{item.diesel_price_impact}</td>
                                                                <td>{item.change_in_diesel_cost}</td>
                                                                <td>{item.new_toll_price}</td>
                                                                <td>{item.change_in_toll_price}</td>
                                                                <td>{item.revised_freight_cost}</td>
                                                            </tr>    
                                                            ):""
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "+(this.state.hideapprovalbtn)}>
                                                <div className="col-xl-4 col-md-12 mb-4" style={{textAlign:"right"}}>
                                                    <button type="button" className="btn btn-danger" onClick={this.onClickSendForApproavl}>Send For Approval</button>
                                                </div>
                                            </div>
                                            {/* {(this.state.hideUserApproveTbl === "show-n")?
                                            <div className={"row form-group no-gutters align-items-center mt-4 "+(this.state.showApprovalStatus)} style={{padding:"20px"}}>
                                                <div className="col-xl-12 col-md-12 mb-4">
                                                    <h5 style={{fontWeight:"bold"}}>Approvals Status</h5>
                                                </div>
                                                <div className="col-xl-12 col-md-12 mb-4">
                                                        {(approval_levls.length > 0)?
                                                    <div className="row">
                                                        <div className="col-md-3" style={{background: "#0f74dd",color: "#fff", padding: "5px 5px"}}><b>EMail</b></div>
                                                        <div className="col-md-2" style={{background: "#0f74dd",color: "#fff", padding: "5px 5px"}}><b>Approval Status</b></div>
                                                        <div className="col-md-2" style={{background: "#0f74dd",color: "#fff", padding: "5px 5px"}}><b>Approved On</b></div>
                                                        <div className="col-md-5" style={{background: "#0f74dd",color: "#fff", padding: "5px 5px"}}></div>
                                                        {(approval_levls.map(item=>
                                                            <div className=" col-md-12 mb-4">
                                                                <div className="row">
                                                                    <div className=" col-md-12 mt-4" style={{paddingBottom:"10px"}}>
                                                                        <b>Level - {item.level}</b>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 border-bottom  no-gutters align-items-center mt-4" style={{paddingBottom:"10px"}}>
                                                                        {item.email}
                                                                    </div>
                                                                    <div className=" col-md-2 border-bottom  no-gutters align-items-center mt-4" style={{paddingBottom:"10px"}}>
                                                                        {item.approval_status_txt}
                                                                    </div>
                                                                    <div className=" col-md-2 border-bottom  no-gutters align-items-center mt-4" style={{paddingBottom:"10px"}}>
                                                                        {item.approved_on}
                                                                    </div>
                                                                    <div className=" col-md-5 border-bottom  no-gutters align-items-center mt-4" style={{paddingBottom:"10px"}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                        :""}
                                                        
                                                </div>
                                                
                                            </div>
                                            :""} */}

                                            {(this.state.hideUserApproveTbl === "show-m")?
                                            <div className="" style={{marginTop:"10px"}}>
                                                {(this.state.freightapproval.length > 0)?
                                                (this.state.freightapproval[0].approval_status == 1)?
                                                <div className="col-md-12">
                                                    <button type="button" className="btn btn-info" onClick={this.onClickSendBackDocument.bind(this)} style={{marginRight:"10px"}}>Send Back</button>
                                                    <button type="button" className="btn btn-success" onClick={this.onClickApproveDocument.bind(this)} style={{marginRight:"10px"}}>Approve</button>
                                                    <button type="button" className="btn btn-danger" onClick={this.onClickRejectDocument.bind(this)}>Reject</button>
                                                </div>
                                                :""
                                                :""}
                                            </div>
                                            :""}
                                            <div className={showpagelogs}>
                                                <FreightLogs 
                                                    document_no={this.props.document_no}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>					
                        </div>
                    </div>
                </div>
				
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
                
                
                <div className={"sliderBlock2 "+(this.state.showApproveDocumentReason)}>
                    <h5 className="crd-bg p-10p">Remarks</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formApproveDocumentItem.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>                                        
                                        <textarea name="approve_remarks" id="approve_remarks" value={this.state.approve_remarks} className="form-control" onChange={this.changeHandler.bind(this)} rows="8" required ></textarea>
                                    </div>
                                    
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
                
                <div className={"sliderBlock2 "+(this.state.showRejectDocumentReason)}>
                    <h5 className="crd-bg p-10p">Remarks</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formRejectDocumentItem.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>                                        
                                        <textarea name="reject_remarks" id="reject_remarks" value={this.state.reject_remarks} className="form-control" onChange={this.changeHandler.bind(this)} rows="8" required ></textarea>
                                    </div>
                                    
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
                    
                    

			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
// console.log("icn ", icn)
function pushNot(){
    // showNotification();
    
	// setInterval(function(){ showNotification(); }, 20000);
}


function showNotification() {	
	if (!Notification) {
		$('body').append('<h4 style="color:red">*Browser does not support Web Notification</h4>');
		return;
	}
	if (Notification.permission !== "granted") {		
		Notification.requestPermission();
	} else {		
		$.ajax({
            url:"http://localhost:5005/billing",
			type: "GET",
			success: function(data, textStatus, jqXHR) {
				
                var theurl = "http://localhost:3005/";
                var notifikasi = new Notification("Freight Rates", {
                    icon: icn,
                    body: "You are checking Freight Rates information.",
                });
                notifikasi.onclick = function () {
                    window.open(theurl); 
                    notifikasi.close();     
                };
                setTimeout(function(){
                    notifikasi.close();
                }, 5000);
        
			},
			error: function(jqXHR, textStatus, errorThrown)	{}
		}); 
	}
};
export default withRouter(DownloadFreightDocument) ;