import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import redirectURL from '../redirectURL';
import withRouter from '../withRouter';
import download from 'js-file-download';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

class EditFFSeaImports extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true,
                menuTabs: ['filterMenuTab']
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            loadetdshow:'show-n',
            loadatdshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoFFId:"",
            other_docs:"",
            file:"",
            loadshow : "show-n",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            ringi_file : "",
            other_docs : "",
            erpData : [{}],
            cargoData : [{}],
            ringiData: [{}],
            packetrows: [{}],
            reqContainersData: [{}],
            airlines: [],
            pagetype: 1,
            disableNN:"disabled",
            fclFields:"show-m",
            lclFields:"show-n",
            pre_alert_zip_file:"",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

        var inputEventval = window.atob(this.props.match.params.eventname);
        var pagetype = this.props.match.params.pagetype;
        console.log(pagetype,"pagetype")
        this.setState({
            pagetype : pagetype
        })
		// console.log
		var cargoBookingId="";
		var reqparams = {
            eventID : inputEventval,
        }

        redirectURL.post("/consignments/getAirCargosAirlines").then((response)=>{
            this.setState({
                airlines : response.data
            });
        })

        redirectURL.post("/consignments/getSeaImportsByRequestId",reqparams).then((response)=>{
            console.log(response.data);
            var cargoBookingId=response.data[0]._id;
            var erpData=[{}];
            if(response.data[0].erpData !='' && response.data[0].erpData !=undefined)
            {
                var erpData = JSON.parse(response.data[0].erpData);
            }
            var cargoData=[{}];
            if(response.data[0].cargoData !='' && response.data[0].cargoData !=undefined)
            {
                var cargoData = JSON.parse(response.data[0].cargoData);
            }
            var cargo_date ="";
            if(response.data[0].cargo_date !=undefined && response.data[0].cargo_date !='')
            {
                cargo_date = response.data[0].cargo_date;
                if(cargo_date.includes("T") == true)
                {
                    cargo_date = cargo_date.split("T");
                    cargo_date = cargo_date[0];
                    cargo_date = cargo_date.split("-")[2]+"-"+cargo_date.split("-")[1]+"-"+cargo_date.split("-")[0];
                }
            }
            var containersData=[{}];
            if(response.data[0].containersData !='' && response.data[0].containersData !=undefined)
            {
                var containersData = JSON.parse(response.data[0].containersData);
            }
            var reqContainersData=[{}];
            if(response.data[0].reqContainersData !='' && response.data[0].reqContainersData !=undefined)
            {
                var reqContainersData = JSON.parse(response.data[0].reqContainersData);
            }
            if(response.data[0].request_type == 1)
            {
                var fclFields = "show-m";
                var lclFields = "show-n";
            }
            else
            {                
                var fclFields = "show-n";
                var lclFields = "show-m";
            }
			this.setState({
                fclFields:fclFields,
                lclFields:lclFields,
				event : response.data[0].event,
				cargoBookingId : response.data[0]._id,
                job_no : response.data[0].job_no,
                erpPO : response.data[0].erpPO,
                erpData : erpData,
                item_desc : response.data[0].item_desc,
                gross_weight : response.data[0].gross_weight,
                dimensions_of_package : response.data[0].dimensions_of_package,
                gross_cargo_volume : response.data[0].gross_cargo_volume,
                size_of_container : response.data[0].size_of_container,
                type_of_container : response.data[0].type_of_container,
                no_of_containers : response.data[0].no_of_containers,
                shipment_type : response.data[0].shipment_type,
                cargo_type : response.data[0].cargo_type,
                handling_type : response.data[0].handling_type,
                cargo_delivery_plant : response.data[0].cargo_delivery_plant,
                remarks : response.data[0].remarks,
                supplier_name : response.data[0].supplier_name,
                supplier_address : response.data[0].supplier_address,
                supplier_contact_person : response.data[0].supplier_contact_person,
                country : response.data[0].country,
                email : response.data[0].email,
                phone : response.data[0].phone,
                delivery_terms : response.data[0].delivery_terms,
                pick_up_address : response.data[0].pick_up_address,
                port : response.data[0].port,
                //airport_name : response.data[0].airport_name,
                cargo_date : cargo_date,
                lc : response.data[0].lc,
                lc_no : response.data[0].lc_no,
                nature : response.data[0].nature,
                free_trade : response.data[0].free_trade,
                benefit_certificate : response.data[0].benefit_certificate,
                epcg : response.data[0].epcg,
                reqContainersData : reqContainersData,
                pre_alert_zip_file : response.data[0].pre_alert_zip_file
            })
            
            $("#size_of_container").val(response.data[0].size_of_container);
            $("#type_of_container").val(response.data[0].type_of_container);
            $("#no_of_containers").val(response.data[0].no_of_containers);
            $("#shipment_type").val(response.data[0].shipment_type);
            $("#cargo_type").val(response.data[0].cargo_type);
            $("#handling_type").val(response.data[0].handling_type);
            $("#cargo_delivery_plant").val(response.data[0].cargo_delivery_plant);
            $("#remarks").val(response.data[0].remarks);
            $("#delivery_terms").val(response.data[0].delivery_terms);

            if(response.data[0].counterpart_details !=undefined && response.data[0].counterpart_details !="undefined")
            {
                $("#counterpart_details").val(response.data[0].counterpart_details);
            }
            
            var cargo_reciving_date ="";
            if(response.data[0].cargo_reciving_date !=undefined && response.data[0].cargo_reciving_date !='')
            {
                cargo_reciving_date = response.data[0].cargo_reciving_date;
                if(cargo_reciving_date.includes("T") == true)
                {
                    cargo_reciving_date = cargo_reciving_date.split("T");
                    cargo_reciving_date = cargo_reciving_date[0];
                    cargo_reciving_date = cargo_reciving_date.split("-")[2]+"-"+cargo_reciving_date.split("-")[1]+"-"+cargo_reciving_date.split("-")[0];
                }
            }
            $("#cargo_reciving_date").val(cargo_reciving_date);
            $("#shipping_line_name").val(response.data[0].shipping_line_name);
            
            var pickup_date ="";
            if(response.data[0].pickup_date !=undefined && response.data[0].pickup_date !='')
            {
                pickup_date = response.data[0].pickup_date;
                if(pickup_date.includes("T") == true)
                {
                    pickup_date = pickup_date.split("T");
                    pickup_date = pickup_date[0];
                    pickup_date = pickup_date.split("-")[2]+"-"+pickup_date.split("-")[1]+"-"+pickup_date.split("-")[0];
                }
            }
            var etd_port_date ="";
            if(response.data[0].etd_port_date !=undefined && response.data[0].etd_port_date !='')
            {
                etd_port_date = response.data[0].etd_port_date;
                if(etd_port_date.includes("T") == true)
                {
                    etd_port_date = etd_port_date.split("T");
                    etd_port_date = etd_port_date[0];
                    etd_port_date = etd_port_date.split("-")[2]+"-"+etd_port_date.split("-")[1]+"-"+etd_port_date.split("-")[0];
                }
            }
            var atd_port_date ="";
            if(response.data[0].atd_port_date !=undefined && response.data[0].atd_port_date !='')
            {
                atd_port_date = response.data[0].atd_port_date;
                if(atd_port_date.includes("T") == true)
                {
                    atd_port_date = atd_port_date.split("T");
                    atd_port_date = atd_port_date[0];
                    atd_port_date = atd_port_date.split("-")[2]+"-"+atd_port_date.split("-")[1]+"-"+atd_port_date.split("-")[0];
                }
            }
            
            if(etd_port_date !=atd_port_date)
            {
                this.setState({
                    loadetdshow:'show-m',
                });
            }
            else
            {
                this.setState({
                    loadetdshow:'show-n',
                });
            }
            var eta_igi_airport_date="";
            if(response.data[0].eta_igi_airport_date !=undefined && response.data[0].eta_igi_airport_date !='')
            {
                eta_igi_airport_date = response.data[0].eta_igi_airport_date;
                if(eta_igi_airport_date.includes("T") == true)
                {
                    eta_igi_airport_date = eta_igi_airport_date.split("T");
                    eta_igi_airport_date = eta_igi_airport_date[0];
                    eta_igi_airport_date = eta_igi_airport_date.split("-")[2]+"-"+eta_igi_airport_date.split("-")[1]+"-"+eta_igi_airport_date.split("-")[0];
                }
            }
            var ata_igi_airport_date="";
            if(response.data[0].ata_igi_airport_date !=undefined && response.data[0].ata_igi_airport_date !='')
            {
                ata_igi_airport_date = response.data[0].ata_igi_airport_date;
                if(ata_igi_airport_date.includes("T") == true)
                {
                    ata_igi_airport_date = ata_igi_airport_date.split("T");
                    ata_igi_airport_date = ata_igi_airport_date[0];
                    ata_igi_airport_date = ata_igi_airport_date.split("-")[2]+"-"+ata_igi_airport_date.split("-")[1]+"-"+ata_igi_airport_date.split("-")[0];
                }
            }
            if(eta_igi_airport_date !=ata_igi_airport_date)
            {
                this.setState({
                    loadatdshow:'show-m',
                });
            }
            else
            {
                this.setState({
                    loadatdshow:'show-n',
                });
            }
            var do_handover_date="";
            if(response.data[0].do_handover_date !=undefined && response.data[0].do_handover_date !='')
            {
                do_handover_date = response.data[0].do_handover_date;
                if(do_handover_date.includes("T") == true)
                {
                    do_handover_date = do_handover_date.split("T");
                    do_handover_date = do_handover_date[0];
                    do_handover_date = do_handover_date.split("-")[2]+"-"+do_handover_date.split("-")[1]+"-"+do_handover_date.split("-")[0];
                }
            }
            var rail_out_date="";
            if(response.data[0].rail_out_date !=undefined && response.data[0].rail_out_date !='')
            {
                rail_out_date = response.data[0].rail_out_date;
                if(rail_out_date.includes("T") == true)
                {
                    rail_out_date = rail_out_date.split("T");
                    rail_out_date = rail_out_date[0];
                    rail_out_date = rail_out_date.split("-")[2]+"-"+rail_out_date.split("-")[1]+"-"+rail_out_date.split("-")[0];
                }
            }
            var arrival_at_fpod="";
            if(response.data[0].arrival_at_fpod !=undefined && response.data[0].arrival_at_fpod !='')
            {
                arrival_at_fpod = response.data[0].arrival_at_fpod;
                if(arrival_at_fpod.includes("T") == true)
                {
                    arrival_at_fpod = arrival_at_fpod.split("T");
                    arrival_at_fpod = arrival_at_fpod[0];
                    arrival_at_fpod = arrival_at_fpod.split("-")[2]+"-"+arrival_at_fpod.split("-")[1]+"-"+arrival_at_fpod.split("-")[0];
                }
            }
            var hbl_date="";
            if(response.data[0].hbl_date !=undefined && response.data[0].hbl_date !='')
            {
                hbl_date = response.data[0].hbl_date;
                if(hbl_date.includes("T") == true)
                {
                    hbl_date = hbl_date.split("T");
                    hbl_date = hbl_date[0];
                    hbl_date = hbl_date.split("-")[2]+"-"+hbl_date.split("-")[1]+"-"+hbl_date.split("-")[0];
                }
            }
            $("#port_name").val(response.data[0].port_of_dispatch);
            $("#pickup_date").val(pickup_date);
            $("#etd_port_date").val(etd_port_date);
            $("#atd_port_date").val(atd_port_date);
            $("#eta_igi_airport_date").val(eta_igi_airport_date);
            $("#ata_igi_airport_date").val(ata_igi_airport_date);
            $("#if_etd_atd_not_same").val(response.data[0].if_etd_atd_not_same);
            $("#if_atd_ata_not_same").val(response.data[0].if_atd_ata_not_same);
            $("#mbl_no").val(response.data[0].mbl_no);
            $("#hbl_no").val(response.data[0].hbl_no);
            $("#hbl_date").val(hbl_date);
            $("#do_handover_date").val(do_handover_date);
            $("#rail_out_date").val(rail_out_date);
            $("#arrival_at_fpod").val(arrival_at_fpod);
            $("#ff_no_of_containers").val(response.data[0].ff_no_of_containers);
            $("#current_status_of_shipment").val(response.data[0].current_status_of_shipment);
            $("#final_port_of_destination").val(response.data[0].final_port_of_destination);
            $("#otr_docs").html(response.data[0].other_docs);
            var ringiData=[{}];
            if(response.data[0].invoiceData !=undefined && response.data[0].invoiceData !="")
            {
                var ringiData = JSON.parse(response.data[0].invoiceData);
            }
            this.setState({
                hiddeninvoice : response.data[0].invoice_value,
                hiddenotherdocs :response.data[0].other_docs,
                ringiData : ringiData,
                packetrows : containersData
            })
            // $("#hidden_invoice_value").val(response.data[0].invoice_value);
            // $("#hidden_other_docs").val(response.data[0].other_docs);
            var resparams = {
                cargoBookingId : cargoBookingId,
            }
            reqContainersData.map(function(rc,index){
                $("#size_of_container_"+index).val(rc.containerSize);
                $("#type_of_container_"+index).val(rc.containerType);
                $("#shipment_type_"+index).val(rc.shipmentIs);
                $("#handling_type_"+index).val(rc.handlingType);
                var splHandling = "";
                try{
                    if(rc.splHandling !=undefined)
                    {
                        splHandling = rc.splHandling
                    }
                }
                catch(e){
                    console.log(e)
                }
                $("#type_of_special_handling_"+index).val(splHandling);
            });

            containersData.map(function(cd,index){
                $("#containerType_"+index).val(cd.containerType);
                $("#containerSize_"+index).val(cd.containerSize);
            });
        }).catch(function(error){
            console.log(error);
		})
        
       
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });
    var pagetype = this.state.pagetype;
    if(pagetype == 1)
    {
        window.location.href="/ffseaimports";
    }
    else
    {
        window.location.href="/seaimports";
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    //this.setState({[name]:value});
    if(name == "final_port_of_destination")
    {
        if(value == "Nhavasheva")
        {
            this.setState({
                disableNN : "disabled"
            })
        }else{
            this.setState({
                disableNN : ""
            })
        }
    }
}
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        other_docs : e.target.files[0]
    });
    console.log(e)
}

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
    };
    this.setState({
        ringiData: [...this.state.ringiData, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringiData.splice(idx, 1);
    this.setState({ ringiData: this.state.ringiData });
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeSeaExportsRingiFile",rngParam).then((response)=>{
        }).catch(function(error){
            console.log(error);
        })
    }
};

handleRemoveRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeSeaExportsRingiFile",rngParam).then((response)=>{
            $(".rnFile_"+idx).html("");
            this.hide();
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadSeaImportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}



handleDownloadZipFile = (idx) => {
    var ringiFile = idx;
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/storeSeaImportsPreAlertDocuments",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                containerType: "",
                containerSize: "",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of containers should be greater than 0");
        $("#ff_no_of_containers").val(1);
        const item = {
            containerType: "",
            containerSize: "",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

formHandler(event){
    event.preventDefault();
    var cargo_reciving_date = $("#cargo_reciving_date").val();
    if(cargo_reciving_date == "__-__-____" || cargo_reciving_date == undefined)
    {
        var cargo_reciving_date="";
    }
    var etd_port_date = $("#etd_port_date").val();
    if(etd_port_date == "__-__-____" || etd_port_date == undefined)
    {
        var etd_port_date="";
    }
    var atd_port_date = $("#atd_port_date").val();
    if(atd_port_date == "__-__-____" || atd_port_date == undefined)
    {
        var atd_port_date="";
    }
    var eta_igi_airport_date = $("#eta_igi_airport_date").val();
    if(eta_igi_airport_date == "__-__-____" || eta_igi_airport_date == undefined)
    {
        var eta_igi_airport_date="";
    }
    var ata_igi_airport_date = $("#ata_igi_airport_date").val();
    if(ata_igi_airport_date == "__-__-____" || ata_igi_airport_date == undefined)
    {
        var ata_igi_airport_date="";
    }
    var hbl_date = $("#hbl_date").val();
    if(hbl_date == "__-__-____" || hbl_date == undefined)
    {
        var hbl_date="";
    }
    var do_handover_date = $("#do_handover_date").val();
    if(do_handover_date == "__-__-____" || do_handover_date == undefined)
    {
        var do_handover_date="";
    }
    var rail_out_date = $("#rail_out_date").val();
    if(rail_out_date == "__-__-____" || rail_out_date == undefined)
    {
        var rail_out_date="";
    }
    var arrival_at_fpod = $("#arrival_at_fpod").val();
    if(arrival_at_fpod == "__-__-____" || arrival_at_fpod == undefined)
    {
        var arrival_at_fpod="";
    }
    var etd_port_date = $("#etd_port_date").val();
    var counterpart_details = $("#counterpart_details").val();
    var shipping_line_name = $("#shipping_line_name").val();
    var vessel_name = $("#vessel_name").val();
    var port_name = $("#port_name").val();
    var if_etd_atd_not_same = $("#if_etd_atd_not_same").val();
    var if_atd_ata_not_same = $("#if_atd_ata_not_same").val();
    var mbl_no = $("#mbl_no").val();
    var hbl_no = $("#hbl_no").val();
    var ff_no_of_containers = $("#ff_no_of_containers").val();
    //var invoice_currency = $("#invoice_currency").val();
    var current_status_of_shipment = $("#current_status_of_shipment").val();
    var final_port_of_destination = $("#final_port_of_destination").val();
    var hidden_invoice_value = $("#hidden_invoice_value").val();
    var hidden_other_docs = $("#hidden_other_docs").val();

    let saveData = new FormData();
    //console.log("file ",this.state.file)
    
    var rngLength=$("#rngLength").val();
    console.log(rngLength,"rngLength")
    saveData.append("rngLength", rngLength);
    saveData.append("file", this.state.ringi_file);
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
        if(ringiFile !='')
        {
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
        else
        {
            var ringiFile=$("#hiddenRingiFile_"+r).val();
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
    }
    ringiData=JSON.stringify(ringiData);
    console.log(ringiData,"ringiData")
    var pktLength=$("#ff_no_of_containers").val();
    var containersData=[];
    for(var p=0;p<pktLength;p++)
    {
        var containerType=$("#containerType_"+p).val();
        var containerSize=$("#containerSize_"+p).val();

        if(containerType !='' || containerSize !='')
        {
            var temp1 = {"containerType":containerType,"containerSize":containerSize}
            containersData.push(temp1)
        }
    }
    containersData=JSON.stringify(containersData);
    saveData.append("other_docs", this.state.other_docs);
    for(var rn=0;rn<rngLength;rn++)
    {
        var ringiFile="ringi_file_"+rn;
        
        saveData.append(ringiFile,this.state[ringiFile]);
        console.log(this.state[ringiFile]);

        var hdringiFile = $("#hiddenRingiFile_"+rn).val();
        var hdringiName = "hiddenRingiFile_"+rn;
        saveData.append(hdringiName,hdringiFile);
        console.log(hdringiFile);
    }
    saveData.append("job_no",this.state.job_no);
    saveData.append("cargo_booking_id", this.state.cargoBookingId);
    saveData.append("counterpart_details", counterpart_details);
    saveData.append("shipping_line_name", shipping_line_name);
    saveData.append("vessel_name", vessel_name);
    saveData.append("port_name", port_name);
    saveData.append("etd_port_date", etd_port_date);
    saveData.append("atd_port_date", atd_port_date);
    saveData.append("eta_igi_airport_date", eta_igi_airport_date);
    saveData.append("ata_igi_airport_date", ata_igi_airport_date);
    saveData.append("if_etd_atd_not_same", if_etd_atd_not_same);
    saveData.append("if_atd_ata_not_same", if_atd_ata_not_same);
    saveData.append("mbl_no", mbl_no);
    saveData.append("hbl_no", hbl_no);
    saveData.append("hbl_date", hbl_date);
    saveData.append("ff_no_of_containers", ff_no_of_containers);
    saveData.append("containersData",containersData);
    saveData.append("ringiData",ringiData);
    saveData.append("current_status_of_shipment", current_status_of_shipment);
    saveData.append("final_port_of_destination", final_port_of_destination);
    saveData.append("cargo_reciving_date", cargo_reciving_date);
    saveData.append("do_handover_date", do_handover_date);
    saveData.append("rail_out_date", rail_out_date);
    saveData.append("arrival_at_fpod", arrival_at_fpod);
    saveData.append("airCargoFFId", this.state.airCargoFFId);
    saveData.append("hidden_invoice_value",hidden_invoice_value);
    saveData.append("hidden_other_docs",hidden_other_docs);
    saveData.append("updated_by_email",localStorage.getItem("email"));
    //console.log(this.state.airCargoFFId);
    
    redirectURL.post("/consignments/saveffSeaImportsData",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
        
    // }
    // else
    // { 
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
}



uploadFormHandler(event){
    event.preventDefault();
    
    var formData = new FormData(event.target);
    formData.append('uploadFile',this.state.file);
    
    console.log('file',formData);
    redirectURL.post("/consignments/saveffCargoData",formData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
        // window.location.reload();
    }).catch((e)=>{
        console.log(e);
    })
}


render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Job No: {this.state.job_no}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO : </label>
                                            <input className="form-control" value={this.state.erpPO} disabled />
                                        </div>
                                    </div>
                                    {this.state.erpData.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO No : </label>
                                            <input type="text" value={item.erpPoNo} className="form-control" disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO Date : </label>
                                            <input type="text" value={item.erPoDate} className="form-control" disabled />
                                        </div>
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>
                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Item Description* : </label>
                                            <input type="text" name={"item_desc"} id={"item_desc"}className="form-control" value={this.state.item_desc} onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Cargo Gross Weight (Metric Tons)* : </label>
                                            <input type="text" name={"gross_weight"} id={"gross_weight"}className="form-control num-cls" value={this.state.gross_weight} onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Volume (CBM)* : </label>
                                            <input type="text" name={"dimensions_of_package"} id={"dimensions_of_package"} placeholder="Volume (CBM)" className="form-control requestFields"  value={this.state.dimensions_of_package} onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Gross Cargo Volume (CBM)* : </label>
                                            <input type="text" name={"gross_cargo_volume"} id={"gross_cargo_volume"} className="form-control fclFields" value={this.state.gross_cargo_volume}  onChange={this.changeHandler} disabled/>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "+this.state.fclFields}></div>
                                    <div className="row col-xl-12">                                    
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">No Of Containers required* : </label>
                                            <input type="text" name="no_of_containers" id="no_of_containers" value={this.state.no_of_containers} className="form-control num-cls fclFields" disabled/>
                                        </div>
                                    </div>

                                    {this.state.reqContainersData.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Size Of Container required* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name={"size_of_container_"+idx} id={"size_of_container_"+idx} onChange={this.changeHandler}  disabled>
                                                <option value="">-- Select Size --</option>
                                                <option value="20 FT">20 FT</option>
                                                <option value="40 FT">40 FT</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Type Of Container* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name={"type_of_container_"+idx} id={"type_of_container_"+idx} onChange={this.changeHandler}  disabled>
                                                <option value="">-- Select Type --</option>
                                                <option value="GP">General Purpose (GP)</option>
                                                <option value="FR">Flat Rack (FR)</option>
                                                <option value="HC">High Cube (HC)</option>
                                                <option value="OT">Open Top (OT)</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Shipment is : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name={"shipment_type_"+idx} id={"shipment_type_"+idx} onChange={this.changeHandler}  disabled>
                                                <option value="">-- Select Shipment is --</option>
                                                <option value="In Gauge">In Gauge</option>
                                                <option value="Out Gauge">Out Gauge</option>
                                            </select>
                                        </div>  <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Handling Type* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name={"handling_type_"+idx} id={"handling_type_"+idx} onChange={this.changeHandler}  disabled>
                                                <option value="">-- Select Handling Type --</option>
                                                <option value="Normal">Normal</option>
                                                <option value="Special">Special</option>
                                            </select>
                                        </div>
                                        {(this.state["handling_type_"+idx] == "Special")?
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12"> Type of Special Handling* : </label>
                                                <input className="form-control tsplh" name={"type_of_special_handling_"+idx}id={"type_of_special_handling_"+idx} onChange={this.changeHandler} disabled/>
                                            </div>
                                        :""
                                        }
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Cargo Type* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargo_type" id="cargo_type" onChange={this.changeHandler}  disabled>
                                                <option value="">-- Select Cargo Type --</option>
                                                <option value="Hazardous">Hazardous</option>
                                                <option value="Non Hazardous">Non Hazardous</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Delivery in MSIL Plant* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargo_delivery_plant" id="cargo_delivery_plant" onChange={this.changeHandler} disabled>
                                                <option value="">-- Delivery in MSIL Plant --</option>
                                                <option value="MSIL-G">MSIL-G</option>
                                                <option value="MPT Manesar">MPT Manesar</option>
                                                <option value="Manesar Vehicle Plant">Manesar Vehicle Plant</option>
                                                <option value="HO">HO</option>
                                                <option value="MSIL-Rohtak">MSIL-Rohtak</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Remarks : </label>
                                            <input type="text" name="remarks" id="remarks" className="form-control"  onChange={this.changeHandler} disabled/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Name : </label>
                                            <input className="form-control" type="text" value={this.state.supplier_name} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Address : </label>
                                            <textarea className="form-control" value={this.state.supplier_address} disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Contact Person : </label>
                                            <input className="form-control" type="text" value={this.state.supplier_contact_person} disabled />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Country : </label>
                                            <input className="form-control" type="text" value={this.state.country} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Email ID : </label>
                                            <input className="form-control" type="text" value={this.state.email} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Phone No : </label>
                                            <input className="form-control" type="text" value={this.state.phone} disabled />
                                        </div>                                    
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">In co Terms : </label>
                                            <input className="form-control" type="text" value={this.state.delivery_terms} disabled />
                                        </div>
                                        {(this.state.delivery_terms == "Ex Works")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Pick Up Address : </label>
                                                <textarea className="form-control" value={this.state.pick_up_address} disabled/>
                                            </div>
                                        :""
                                        }
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Proposed Cargo Readiness Date : </label>
                                            <input className="form-control" type="text" value={this.state.cargo_date} disabled />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">LC Available : </label>
                                            <input className="form-control" type="text" value={this.state.lc} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">LC No : </label>
                                            <input className="form-control" type="text" value={this.state.lc_no} disabled />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Nature Of Shipment : </label>
                                            <input className="form-control" type="text" value={this.state.nature} disabled />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Free Trade Agreement : </label>
                                            <input className="form-control" type="text" value={this.state.free_trade} disabled />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">R&D Benefit Certificate : </label>
                                            <input className="form-control" type="text" value={this.state.benefit_certificate} disabled />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">EPCG* : </label>
                                            <input className="form-control" type="text" value={this.state.epcg} disabled />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Counterpart Details : </label>
                                            <input type="text" name="counterpart_details" id="counterpart_details" className="form-control" required onChange={this.changeHandler} />
                                        </div>
                                        {/* {(this.state.delivery_terms == "Ex Works")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Pick up date : </label>
                                                <input type="text" name="pickup_date" id="pickup_date" className="datetimepicker_date form-control"  onChange={this.changeHandler} />
                                            </div>                                        
                                        :
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Cargo reciving date : </label>
                                                <input type="text" name="cargo_reciving_date" id="cargo_reciving_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                            </div>
                                        } */}
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Cargo reciving date : </label>
                                            <input type="text" name="cargo_reciving_date" id="cargo_reciving_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>

                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Shipping Line Name : </label>
                                            <input type="text" name="shipping_line_name" id="shipping_line_name" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Vessel Name  : </label>
                                            <input type="text" class="form-control col-xl-12 col-lg-12" name="vessel_name" id="vessel_name"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Dispatch Port Name: </label>
                                            <input type="text" name="port_name" id="port_name" className="form-control" disabled onChange={this.changeHandler}  />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ETD Port : </label>
                                            <input type="text" name="etd_port_date" id="etd_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ATD Port : </label>
                                            <input type="text" name="atd_port_date" id="atd_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>                                     
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ETA Indian Port : </label>
                                            <input type="text" name="eta_igi_airport_date" id="eta_igi_airport_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ATA Indian Port : </label>
                                            <input type="text" name="ata_igi_airport_date" id="ata_igi_airport_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                   
                                    <div className="row col-xl-12">
                                        <div id="etdSame" className={"col-xl-6 "+(this.state.loadetdshow)}>
                                            <label className="col-xl-12 col-lg-12">IF ETD and ATD not same : </label>
                                            <textarea name="if_etd_atd_not_same" id="if_etd_atd_not_same" className="form-control"  onChange={this.changeHandler}></textarea>
                                        </div>
                                        <div id="atdSame" className={"col-xl-6 "+(this.state.loadatdshow)}>
                                            <label className="col-xl-12 col-lg-12">If ETA and ATA not same : </label>
                                            <textarea name="if_atd_ata_not_same" id="if_atd_ata_not_same" className="form-control" onChange={this.changeHandler}></textarea>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">MBL No : </label>
                                            <input type="text" name="mbl_no" id="mbl_no" className="form-control num-cls"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">HBL No : </label>
                                            <input type="text" name="hbl_no" id="hbl_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">HBL date : </label>
                                            <input type="text" name="hbl_date" id="hbl_date" placeholder="" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">No. of Containers : </label>
                                            <input type="text" name="ff_no_of_containers" id="ff_no_of_containers" className="form-control" onBlur={this.packetsHandler}/>
                                        </div>                                   
                                    </div>
                                    {this.state.packetrows.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Container Size : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name={"containerSize_"+idx} id={"containerSize_"+idx} onChange={this.changeHandler}>
                                                <option value="">-- Select Size --</option>
                                                <option value="20 FT">20 FT</option>
                                                <option value="40 FT">40 FT</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Container Type : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name={"containerType_"+idx} id={"containerType_"+idx} onChange={this.changeHandler} >
                                                <option value="">-- Select Type --</option>
                                                <option value="GP">General Purpose (GP)</option>
                                                <option value="FR">Flat Rack (FR)</option>
                                                <option value="HC">High Cube (HC)</option>
                                                <option value="OT">Open Top (OT)</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>
                                    {(this.state.pre_alert_zip_file !='' && this.state.pre_alert_zip_file !=undefined) ?
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <button type="button" className="btn btn-warning" onClick={() => this.handleDownloadZipFile(this.state.pre_alert_zip_file)}>Download Docs</button>
                                        </div>
                                    </div>
                                    :""}
                                    <div className="row col-xl-12">
                                        {this.state.ringiData.map((item, idx) => (
                                        <div className="col-xl-4" key={idx}>
                                            <div className="col-xl-12" style={{padding:"0"}}>
                                                <label className="col-xl-12 col-lg-12">Pre alert documents (BL, Invoice, Packing) : </label>
                                                <input type="file" name={"ringi_file_"+idx} id={"ringi_file_"+idx} className="form-control" onChange={this.changeFileHandler} />

                                                {((item.invoice_file != "") && (item.invoice_file != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile_"+idx}>{item.invoice_file}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleDownloadRingiFile(idx)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleRemoveRingiFile(idx)}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hiddenRingiFile_"+idx} value={item.invoice_file} />
                                                </div>
                                                :
                                                ""
                                                }
                                                
                                            </div>
                                            <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                                <h6 style={{marginTop:"1em"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                            </div>
                                        </div>
                                        ))}
                                        <input type="hidden" id="rngLength" value={this.state.ringiData.length}/>
                                        <div class="row col-sm-12">
                                            <div className="row col-xl-12 m-top-1">
                                                <div class="col-xl-12">
                                                    <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="hr-dashed"></div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Any other docs : </label>
                                            <input type="file" name="other_docs" id="other_docs" className="form-control" onChange={this.changeDocFileHandler} />
                                            <div id="otr_docs"></div>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Remarks (Current Status of Shipment) : </label>
                                            <input type="text" name="current_status_of_shipment" id="current_status_of_shipment" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Final Port Of Destination : </label>
                                            <select name="final_port_of_destination" id="final_port_of_destination" className="form-control" onChange={this.changeHandler}> 
                                                <option value="">Select Port</option>
                                                <option value="ICD Garhi">ICD Garhi</option>
                                                <option value="Nhavasheva">Nhavasheva</option>
                                                <option value="Mundra">Mundra</option>
                                                <option value="Pipavav">Pipavav</option>
                                                <option value="Kandla">Kandla</option>
                                                <option value="ACTL Faridabad">ACTL Faridabad</option>
                                                <option value="CFS Patparganj">CFS Patparganj</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">DO Handover Date : </label>
                                            <input type="text" name="do_handover_date" id="do_handover_date" placeholder="" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Rail Out date : </label>
                                            <input type="text" name="rail_out_date" id="rail_out_date" placeholder="" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Arrival at FPOD : </label>
                                            <input type="text" name="arrival_at_fpod" id="arrival_at_fpod" placeholder="" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <input type="hidden" name="hidden_invoice_value" id="hidden_invoice_value" value={this.state.hiddeninvoice}/>
                                        <input type="hidden" name="hidden_other_docs" id="hidden_other_docs" value={this.state.hiddenotherdocs}/>
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
        timepicker:false,
        onClose: function(datesel) {
            var etd_port_date = $("#etd_port_date").val();
            var atd_port_date = $("#atd_port_date").val();
            var eta_igi_airport_date = $("#eta_igi_airport_date").val();
            var ata_igi_airport_date = $("#ata_igi_airport_date").val();
            console.log(etd_port_date,"etd_port_date");
            console.log(atd_port_date,"atd_port_date");
            console.log(eta_igi_airport_date,"eta_igi_airport_date");
            console.log(ata_igi_airport_date,"ata_igi_airport_date");
            if(eta_igi_airport_date !=ata_igi_airport_date)
            {
                $("#atdSame").removeClass("show-n");
                $("#atdSame").addClass("show-m");
            }
            else
            {
                $("#atdSame").removeClass("show-m");
                $("#atdSame").addClass("show-n");
                $("#if_atd_ata_not_same").val("");
            }
            
            if(etd_port_date !=atd_port_date)
            {
                $("#etdSame").removeClass("show-n");
                $("#etdSame").addClass("show-m");
            }
            else
            {
                $("#etdSame").removeClass("show-m");
                $("#etdSame").addClass("show-n");
                $("#if_etd_atd_not_same").val("");
            }
        }
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

$(document).on("input", "#hbl_no", function() {
    if (this.value.match(/[^a-zA-Z0-9 ]/g)) {
        this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '');
    }
});

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});
export default withRouter(EditFFSeaImports);