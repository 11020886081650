import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import TrackingShipGreen from "../../assets/icons/tracking-ship-green.svg";
import TrackingShipRed from "../../assets/icons/tracking-ship-red.svg";
import TrackingShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import infoIcon from "../../assets/icons/info.png";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import { countries } from "country-flags-svg";
import Modal from "react-responsive-modal";
import redirectURL from "../redirectURL";
import moment from "moment";
import copyIcon from "../../assets/icons/copyIcon.png";
import $ from "jquery";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./trackingTile.css";
import EmissionCloud from '../../assets/icons/co2-1.svg'
import InfoIcon from '../../assets/icons/info.png';

const TrackingTile2 = (props) => { 
  console.log("TrackingTile2", props);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState({
    openCloseTrackingModal: false,
    openEditShipmentDetailsModal: false,
    openDeleteModal: false,
    openShareModal: false,
    modalOpened: "",
    trackingDeletionReason: "",
    shipmentToBeDeleted: "",
    sharingType: "",
    tracking_url: "",
    tracking_value: "",
    emailID: "",
    shareEmailButton: "disabled",
    responseMessage: "",
    responseMessageClass: "",
    tabType: "",
    trackingToBeClosedType: "",
    trackingToBeClosedTypeValue: "",
    forceCloseTrackingReason: "",
    info_msg: "",
    mostDelayedContainerEmission:"",
  });

  // useEffect(()=>{
  //   $("#info_card").text = state.info_msg
  // },[state.info_msg])

  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.name.toLowerCase() == countryName.toLowerCase()) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0]?.flag) {
      return {
        img: srcFlag[0]?.flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };

  const closeTrackingModalOpen = (serialType, serialNumber) => {
    setState({
      ...state,
      openCloseTrackingModal: true,
      modalOpened: "closeTracking",
      trackingToBeClosedType: serialType,
      trackingToBeClosedTypeValue: serialNumber,
    });
  };

  const editShipmentDetails = (bookingNo) => {
    console.log(bookingNo);
    setState({
      ...state,
      openEditShipmentDetailsModal: true,
      modalOpened: "editTracking",
    });
  };

  const openDeleteTrackingModal = (tabType, trackingNum) => {
    setState({
      ...state,
      openDeleteModal: true,
      modalOpened: "deleteTracking",
      shipmentToBeDeleted: trackingNum,
      tabType: tabType,
    });
  };

  const deleteTracking = () => {
    // console.log(state.shipmentToBeDeleted);
    let payload = {};
    if (state.tabType === "BL") {
      payload = {
        mbl_number: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    } else {
      payload = {
        shipment_no: state.shipmentToBeDeleted,
        tracking_deleted_remarks: state.trackingDeletionReason,
      };
    }

    if (state.shipmentToBeDeleted && state.trackingDeletionReason) {
      redirectURL
        .post("/exim/deleteTracking", payload)
        .then((resp) => {
          if (resp.data.status === "success") {
            closeModal();
            props.trackingDataFun();
            setState({
              ...state,
              trackingDeletionReason: "",
              shipmentToBeDeleted: "",
            });
          }
        })
        .catch((err) => {
          console.log("deleteTracking err", err);
        });
    } else {
    }
  };

  const refreshData = () => {
    props.trackingDataFun();
  };
  function getValue(inputString) {
    var words = inputString.slice(0, 14) + "...";
    return words;
  }
  const forceCloseTracking = () => {
    console.log(
      state.trackingToBeClosedTypeValue,
      state.forceCloseTrackingReason,
      state.trackingToBeClosedType
    );

    let payload = {
      [state.trackingToBeClosedType]: state.trackingToBeClosedTypeValue, //shipment_no or mbl_number
      forceCloseTrackingReason: state.forceCloseTrackingReason,
    };

    redirectURL
      .post("/exim/forceCloseTracking", payload)
      .then((resp) => {
        if (resp.data.status === "success") {
          closeModal();
          props.trackingDataFun();
        }
      })
      .catch((err) => {
        console.log("forceCloseTracking err", err);
      });
  };
  const handleCopyClick = () => {
    $("#clipboardMsg").removeClass("show-n").addClass("clipboard-msg");
    let url = document.getElementById("trackingURL").value;
    navigator.clipboard.writeText(url);
    setInterval(() => {
      $("#clipboardMsg").addClass("show-n").removeClass("clipboard-msg");
    }, 2000);
  };

  const shareTrackingDetails = (sharingType, tracking_value, tracking_url) => {
    // "Container",url, container_no
    // tracking_type, tracking_value, tracking_url
    let live_url = `http://164.52.218.255:3006/${tracking_url}`;
    setState({
      ...state,
      openShareModal: true,
      modalOpened: "shareTracking",
      sharingType,
      tracking_url: live_url,
      tracking_value,
    });
  };

  const closeModal = () => {
    if (state.modalOpened === "closeTracking") {
      setState({
        ...state,
        openCloseTrackingModal: false,
        trackingToBeClosedType: "",
        forceCloseTrackingReason: "",
        trackingToBeClosedTypeValue: "",
      });
    } else if (state.modalOpened === "editTracking") {
      setState({
        ...state,
        openEditShipmentDetailsModal: false,
      });
    } else if (state.modalOpened === "deleteTracking") {
      setState({
        ...state,
        openDeleteModal: false,
        trackingDeletionReason: "",
        shipmentToBeDeleted: "",
      });
    } else if (state.modalOpened === "shareTracking") {
      setState({
        ...state,
        openShareModal: false,
      });
    }
  };

  const sendTrackingUrl = () => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (emailPattern.test(state.emailID)) {
      //   sharingType, tracking_value, tracking_url, mail

      let payload = {
        sharingType: state.sharingType,
        tracking_value: state.tracking_value,
        tracking_url: state.tracking_url,
        mail: state.emailID,
      };

      redirectURL
        .post("/exim/shareTrackingURL", payload)
        .then((resp) => {
          console.log("shareTrackingURL data", resp.data);
          if (resp.data.status === "success") {
            setState({
              ...state,
              responseMessage: "Mail has been sent",
              responseMessageClass: "txt-info",
            });

            setTimeout(() => {
              setState({
                ...state,
                openShareModal: false,
                responseMessage: "",
                responseMessageClass: "",
                sharingType: "",
                tracking_url: "",
                tracking_value: "",
                emailID: "",
              });
            }, 2000);
          }
        })
        .catch((err) => {
          console.log("shareTrackingURL err", err);
        });

      //     sharingType,
      //   tracking_url,
      //   tracking_value,
    } else {
      setState({
        ...state,
        responseMessage: "Invalid Email ID",
        responseMessageClass: "txt-danger",
      });
      setTimeout(() => {
        setState({
          ...state,
          responseMessage: "",
          responseMessageClass: "",
        });
      }, 2000);
    }
  };

  const changeTextStyle = () => {
    // $("#info_card").text = state.info_msg
  };
  let imgPos = "0%";
  let barPos = "0%";
  let eventLabel="NAS"
  let { event_group, status, location } = props.container_data.last_event_data[0];
  let srcIcon = {};
  if (props.container_data.source_location) {
    srcIcon = getFlagIcon(props.container_data.source_location);
  }

  let dstnIcon = {};
  if (props.container_data.destination_location) {
    dstnIcon = getFlagIcon(props.container_data.destination_location);
  }

  let image = 'NA';
  let imgStatus=true;
  try{
    let MyModule=require(`../../../public/Gocomet/${props.container_data.shipping_line_code}.png`)
  }catch(err){
    imgStatus=false
  }
  return (
    <>
         {
          (!props.container_data || props.container_data ===null || props.container_data===undefined)?
          <>
          <div
            key={""}
            className="card py-2 pr-3"
            style={{
              borderRadius: "18px",
              textAlign:"center",
              height:"50px",
              marginTop:"40px",
              marginBottom:"100px"
            }}
          >
            No data found.
            </div>
          </>:
          <>
        {
          <div
          key={props.container_data.shipment_no}
          className="card py-4 pr-3 d-flex flex-row justify-content-between align-items-center"
          style={{
            borderRadius: "18px",
            //   cursor: "pointer"
          }}
        >
          <div className="d-flex justify-content-start align-items-start">
                <div
                  className="d-flex flex-column justify-content-center align-items-center p-2"
                  style={{ background: "#0664AE" }}
                >
                  <img
                    style={{
                      width: "10px",
                      marginBottom: "5px",
                    }}
                    src={ExportIcon}
                  />
                  <div
                    style={{
                      color: "white",
                      fontSize: "10px",
                      fontWeight: 700,
                    }}
                  >
                    EX
                  </div>
                </div>
          </div>
          <div className="" style={{ width: "8%" }}>
            {/* <img
              src={`../../../public/Gocomet/${props.container_data.shipping_line}.png`}
              onError={<label>{props.container_data.shipping_line.toUpperCase()}</label>}
              alt={`${props.container_data.shipping_line}`}
              style={{ width: "100%" }}
            /> */}
            {(imgStatus)?
                <img
                  src={require(`../../../public/Gocomet/${props.container_data.shipping_line_code}.png`)}
                  alt={`${props.container_data.shipping_line}`}
                  style={{ width: "100%" }}
                />:
                <CustomSVG text={props.container_data.shipping_line} height="5vw" width= "7vw"/>
                }
          </div>

          <div className="d-flex flex-column justify-content-start eachCard mr-5">
            <div className="d-flex flex-row justify-content-between">
              <div style={{ color: "#858796", fontSize: "10px" }}>
                BL No:
              </div>
              {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
              <div
                style={{
                  fontSize: "10px",
                  fontWeight: 600,
                  marginLeft: "8px",
                }}
              >
                {props.container_data.mbl_number??"NA"}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div style={{ color: "#858796", fontSize: "10px" }}>
                Booking No:
              </div>
              {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
              <div
                style={{
                  fontSize: "10px",
                  fontWeight: 600,
                  marginLeft: "8px",
                }}
              >
                {props.container_data.booking_no??"NA"}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div style={{ color: "#858796", fontSize: "10px" }}>
                Container No: 
              </div>
              <div
                style={{
                  fontSize: "10px",
                  fontWeight: 600,
                  marginLeft: "8px",
                }}
              >
                {props.container_data.container_no}
              </div>
            </div>
          </div>


          <div
            className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl"
          >
            <div className="align-items-center ml-1 portpos">
              <div className="row textAlignCenter">
                <div className="col-12" style={{whiteSpace:"nowrap"}}>
                  <img className="portind" src={LocationBlue}></img>
                  <br />
                  {srcIcon.hasOwnProperty("img") &&
                  srcIcon.hasOwnProperty("name") ? (
                    <>
                      <img
                        src={srcIcon?.img}
                        alt={srcIcon.hasOwnProperty("name") ? srcIcon.name : ""}
                        className="flag_class"
                        style={{
                          transform: `${
                            srcIcon.name === "China" ? "rotateX(45deg)" : ""
                          }`,
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                    <div className="col-12 portxt" 
                  style={{fontSize:"10px",fontWeight:600,marginTop:"0px"}}
                  >{srcIcon.hasOwnProperty("name") ? srcIcon.name : ""}</div>
                </div>
              </div>
            </div>

            <div className="current-activity">
              <span
                className="ontime-text"
                style={{
                  fontSize: "10px",
                  padding: "2px 5px",
                  position: "relative",
                  top: "-11px",
                  borderRadius: "3px",
                  fontWeight: "bolder",
                  left: "-15px",
                }}
              >
                {(props.container_data.status==="COMPLETED")?props.container_data.all_events[props.container_data.all_events.length-1].event_name:props.container_data.last_event_data[0].event_name}
              </span>
            </div>
            <div className="d-flex flex-row tracking-background-progress">
              <div
                // style={{ height: "2px", backgroundColor: "#0664AE" }}
                style={{ "--highlight": (props.container_data.last_event_data[0].event_group == "origin")?"0%":"100%" }}
                // className="tracking-progress-green-line"
                className="tracking-progress-blue-line"
              ></div>

              <div
                className="tracking-image-background"
                style={{ "--highlight2": (props.container_data.last_event_data[0].event_group === "origin")?"0%":"95%", transition: "step-end 2s" }}
                // style={{ background: "white", position: "absolute", left: "100px", bottom: "-5px", paddingLeft: "5px", paddingRight: "5px" }}
              >
                <img
                  className=""
                  // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                  // src={TrackingShipGreen}
                  src={TrackingShipBlue}
                  width="23px"
                  // ["On Time", "Early", "On Time"]
                ></img>
              </div>
            </div>
            
            <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
              <div className="row textAlignCenter location-flag">
                <div className="col-12">
                  <img src={FlagGrey} className="desflg"></img>
                  <br />

                  {dstnIcon.hasOwnProperty("img") &&
                  dstnIcon.hasOwnProperty("name") ? (
                    <>
                      <img
                        src={dstnIcon?.img}
                        alt={(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}
                        className="flag_class"
                        style={{
                          transform: `${
                            dstnIcon.name === "China"
                              ? "rotateX(45deg)"
                              : ""
                          }`,
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12" style={{whiteSpace:"nowrap"}}>
                    <div className="col-12 portxt" 
                  style={{fontSize:"10px",fontWeight:600,marginTop:"0px"}}
                  >{(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}</div>
                </div>
              </div>
            </div>
          </div>
              <div
                className="mr-4 d-flex flex-column justify-content-start"
                style={
                  {
                    // width: "171.575px",
                    // marginLeft:"96px"
                  }
                }
              >
                <div className="d-flex flex-row justify-content-between mb-1"  title="Vessel Estimated Time of Departure">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                  Vessel ETD:
                  </div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {(props.container_data.vessel_etd==null || props.container_data.vessel_etd=="")?"NA":moment(props.container_data.vessel_etd).format("Do MMM, YYYY")}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-1" title="Shipping on Board Date">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                  Shipping On Board Date:
                  </div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {(props.container_data.shipping_on_board_date==null || props.container_data.shipping_on_board_date=="")?"NA":moment(props.container_data.shipping_on_board_date).format("Do MMM, YYYY")}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-1" title="Bill of Lading Date">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                  BL Date:
                  </div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {(props.container_data.bill_of_lading_date==null || props.container_data.bill_of_lading_date=="")?"NA":moment(props.container_data.bill_of_lading_date).format("Do MMM, YYYY")}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-row justify-content-between mb-3">
                  <div
                    className={
                      "cursor-class " +
                      // `${
                      //   (last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE")? 
                           "trip-done"
                      //     : ""
                      // }`
                    }
                    // onClick={() => {
                    //   if (last_event_data[0].status !== "COMPLETED") {
                    //   if((each.hasOwnProperty("force_close")?each.force_close:"") ===1 || each.status==="NEW" || each.status==="UNTRACKABLE"){}
                    //   else
                    //   closeTrackingModalOpen("shipment_no", shipment_no);
                    //   }
                    // }}
                    // data-tooltip-id={`close_${shipment_no}`}
                    // data-tooltip-content="Mark as complete"
                  >
                    <img src={CompletedIcon}></img>
                  </div>
                  {/* {(props.container_data.last_event_data[0].status === "COMPLETED" || (props.container_data.hasOwnProperty("force_close")?props.container_data.force_close:"") ===1 || props.container_data.status==="NEW" || props.container_data.status==="UNTRACKABLE") ? (
                    ""
                  ) : (
                    <Tooltip id={`close_${shipment_no}`} />
                  )} */}

                  <div
                    className={
                      "cursor-class " +
                      // `${
                      //   (last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE")
                      //     ?
                           "trip-done"
                      //     : ""
                      // }`
                    }
                    // data-tooltip-id={`edit_${shipment_no}`}
                    // data-tooltip-content="Edit Shipment Details"
                    // onClick={() => {
                    //   if (last_event_data[0].status !== "COMPLETED") {
                    //     if((each.hasOwnProperty("force_close")?each.force_close:"") ===1 || each.status==="NEW" || each.status==="UNTRACKABLE"){}
                    //     else
                    //   editShipmentDetails(each.shipment_unique_id);
                    //   }
                    // }}
                  >
                    <img src={EditIcon}></img>
                  </div>
                  {/* {(last_event_data[0].status === "COMPLETED"  || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE") ? (
                    ""
                  ) : (
                    <Tooltip id={`edit_${shipment_no}`} />
                  )} */}
                  <div
                    className={
                      "cursor-class " +
                      // `${
                      //   (last_event_data[0].status === "COMPLETED" || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE")? 
                        "trip-done"
                      //     : ""
                      // }`
                    }
                    // onClick={() => {
                    //   if (last_event_data[0].status !== "COMPLETED") {
                    //   if((each.hasOwnProperty("force_close")?each.force_close:"") ===1 || each.status==="NEW" || each.status==="UNTRACKABLE"){}
                    //   else
                    //   openDeleteTrackingModal("BL", mbl_number);
                    //   }
                    // }}
                    // data-tooltip-id={`delete_${shipment_no}`}
                    // data-tooltip-content="Delete Tracking"
                  >
                    <img src={DeleteIcon}></img>
                  </div>
                  {/* {(last_event_data[0].status === "COMPLETED"  || (each.hasOwnProperty("force_close")?each.force_close:"") ===1|| each.status==="NEW" || each.status==="UNTRACKABLE") ? (
                    ""
                  ) : (
                    <Tooltip id={`delete_${shipment_no}`} />
                  )} */}
                  <div
                    className="cursor-class trip-done"
                    // onClick={() => {
                    //   //tracking_details/BL/SZPDL7587900
                    //   let local_url = `/tracking_details/BL/${mbl_number}`;
                    //   shareTrackingDetails(
                    //     "Container",
                    //     container_no,
                    //     local_url
                    //   );
                    // }}
                    // data-tooltip-id={`share_${shipment_no}`}
                    // data-tooltip-content="Sharing Tracking Details"
                  >
                    <img src={ShareIcon}></img>
                  </div>
                  <Tooltip id={`truck_number`} />
                </div>
                <div style={{ width: "120px", textAlign: "center" }}>
                  <div
                    className={`ontime-text py-1 px-3`}
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      borderRadius: "14px",
                    }}
                    data-tooltip-id={`truck_number`}
                    data-tooltip-content="Truck Number"
                  >
                    {props.container_data.truck_no}
                  </div>
                </div>
              </div>
              
              {(props.container_data.last_event_data[0].status === "COMPLETED" || (props.container_data.hasOwnProperty("force_close")?props.container_data.force_close:"")===1|| props.container_data.status==="NEW" || props.container_data.status==="UNTRACKABLE") ? (
                <>
                  {/* <div className="d-flex flex-column justify-content-center align-items-center">
                    <label for="" style={{ fontSize: "8px", marginBottom: "0.1rem" }}>
                    {
                        ((props.container_data.hasOwnProperty("force_close")?props.container_data.force_close:"")===1) ?
                        "Trip was Force Closed":
                        (props.container_data.status==="NEW"?"Newly Added":(props.container_data.status==="UNTRACKABLE"?"Untrackable":((props.container_data.last_event_data[0].event_group==="transshipment")?"In Transshipment":"Trip is Completed")))
                      }
                    </label>
                    <label for="" style={{ fontSize: "8px" }}>
                      {updated_at}
                    </label>
                  </div> */}
                </>
              ) : (
                <>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    //onClick={refreshData} 
                  >
                    <img
                      className={
                        "mb-3 cursor-class " +
                        `${
                          //(last_event_data[0].status === "COMPLETED"||(each.hasOwnProperty("force_close")?each.force_close:"")===1 || each.status==="NEW" || each.status==="UNTRACKABLE")?
                             "trip-done"
                            //: ""
                        }`
                      }
                      //data-tooltip-id={`refresh_${shipment_no}`}
                      //data-tooltip-content="Refresh Tracking"
                      src={RefreshIcon}
                    />
                    {/* {last_event_data[0].status === "COMPLETED" ? 
                    (
                      ""
                    ) 
                    : (
                      <Tooltip id={`refresh_${shipment_no}`} />
                    )
                    } */}
                    <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                      {`Create on: ${moment(props.container_data.created_date).format("Do MMM, YYYY") }`}
                    </div>
                    <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                      {`Last Event: ${moment(props.container_data.last_event_data[0].actual_time).format("Do MMM, YYYY")}`}
                    </div>
                  </div>
                </>
              )}
        </div>
          
        }
        </>}
      
      {/* Container Tile */}
    </>
  );
};

const CustomSVG = ({ text, height, width }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%"  rx="15" ry="15" fill="#f0f0f0" />
    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="12px" fill="#333">
      {text}
    </text>
  </svg>
);

export default TrackingTile2;
