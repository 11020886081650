import React, {Component} from 'react';
// import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants, { TRIP_REACHED_DEST_DATE } from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import ViewPOD from "./viewpodbutton";
import UploadPOD from "./uploadpodbutton";
import Datetime from 'react-datetime';
import ViewBatchItems from "./viewbatchitems";
import "react-datetime/css/react-datetime.css";
import SweetAlert from 'react-bootstrap-sweetalert';


export default class BillingTripsData extends Component {
    constructor(props){
        super(props);
        this.state={
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true,
                menuTabs: ['filterMenuTab']
			},
            frameworkComponents:{
                UploadPOD:UploadPOD,
                ViewPOD:ViewPOD,
                ViewBatchItems:ViewBatchItems
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            actiontype:"",
            rowSelection:"multiple",
            slidershow:"",
            sliderInvoiceshow:"",
            overlay:"show-n",
            rownode:[],
            delivered_date:"",
            liableData:[],
            pendingwithlspData:[],
            showSendBackBtn:0,
            showSendApproveBtn:0,
            showSendRejectBtn:0,
          
        }
        // this.loadTrips = this.loadTrips.bind(this);
        this.onClickUploadPOD = this.onClickUploadPOD.bind(this);
        this.uploadDocument = this.uploadDocument.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickViewBatchItems = this.onClickViewBatchItems.bind(this);
    }
	componentDidMount(){
        // console.log("this.props.rowData ", this.props.rowData)
        this.setState({
            rowData:this.props.rowData,
            actiontype:this.props.actiontype
        })
	}
    componentWillReceiveProps(newprops){
        // console.log("newprops ", newprops.rowData)
        // if(newprops.actiontype !== this.state.actiontype)
        // {
            var records = [];
            var liableData=[];
            var pendingwithlspData=[];
            // if(newprops.rowData.length > 0)
            // {
            //     newprops.rowData.map((item) => {

            //         if(item.trip_status === 0)
            //         {
            //             liableData.push(item);
            //         }
            //         if(item.trip_status === 1)
            //         {
            //             pendingwithlspData.push(item);
            //         }
            //         // if(newprops.actiontype === "pendinglsp")
            //         // {
            //         //     item.trip_status = "POD Submitted";
            //         // }
            //         // else if(newprops.actiontype === "pendingcust")
            //         // {
            //         //     item.trip_status = "Transporter Approved";
            //         // }
            //         // else if(newprops.actiontype === "submitfin")
            //         // {
            //         //     item.trip_status = "Customer Approved";
            //         // }
            //         // else if(newprops.actiontype === "paystatus")
            //         // {
            //         //     item.trip_status = "Submitted To Finance";
            //         //     item.trip_payment_status = "Pending";
            //         // }
            //         // else{
            //         //     item.trip_status = "Pending Upload POD";
            //         // }
            //         records.push(item);
            //     })
            // }
            if(newprops.actiontype === "pendinglsp")
            {
                this.setState({
                    // rowData:pendingwithlspData,
                    actiontype:newprops.actiontype
                })
            }
            else if(newprops.actiontype === "pendingcust")
            {
                this.setState({
                    // rowData:records,
                    actiontype:newprops.actiontype
                })
            }
            else if(newprops.actiontype === "submitfin")
            {
                this.setState({
                    // rowData:records,
                    actiontype:newprops.actiontype
                })
            }
            else if(newprops.actiontype === "paystatus")
            {
                this.setState({
                    // rowData:records,
                    actiontype:newprops.actiontype
                })
            }
            else{
                this.setState({
                    // rowData:liableData,
                    actiontype:newprops.actiontype
                })
            }
            this.setState({
                rowData:newprops.rowData,
            })
            
        // }
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onClickUploadPOD(rownode){
        this.setState({
            slidershow:"slide30",
            overlay:"show-m",
            rownode:[rownode.data]
        });
    }
    
    hideSlideBlock(){
        this.setState({
            slidershow:"",
            sliderInvoiceshow:"",
            overlay:"show-n"
        })
    }
    
    changeFileHandler(event){
        console.log("event.target.files[0] ",event.target.files[0])
        this.setState({
            podDoc:event.target.files[0]
        })
    }
    changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    uploadDocument(event){
        event.preventDefault();
        var delivered_date = this.state.delivered_date;
        var podDoc = this.state.podDoc;
        if(this.state.rownode.length > 0)
        {

            var rowid = this.state.rownode[0]._id;
            const formData = new FormData();
            formData.append("podDoc",podDoc);
            formData.append("delivered_date",delivered_date);
            formData.append("rowid",rowid);
            // console.log("FormData ", formData);
            redirectURL.post("/savepoddoc",formData)
            .then((response) => {
                window.location.reload();
            })
        }
    }
    onClickSendBack(){

    }
    onClickApprove(){
        if(this.props.actiontype === 'pendinglsp')
        {
            this.setState({
                sliderInvoiceshow:"slide30",
                overlay:"show-m",
                // rownode:[rownode.data]
            });
        }
        else{
            var rownodes = this.gridApi.getSelectedRows();
            var confirmToapprove = 0;
            if(rownodes.length > 0)
            {
                rownodes.map((item) => {
                    if(item.is_approve_status === 2)
                    {

                    }
                    else{
                        confirmToapprove = 1
                    }
                    
                })
            }
            console.log("confirmToapprove ", confirmToapprove)
            setTimeout(() => {
                if(confirmToapprove === 0)
                {
                    this.props.context.onApproveLSPData(rownodes, this.props.actiontype);
                }
                else{
                    this.setState({
                        show:true,
                        basicTitle:"Please complete deviation process.",
                        basicType:"info"
                    })
                }
            },500)
        }
                
    }
    onClickReject(){
       
        var rownodes = this.gridApi.getSelectedRows();
        this.props.context.onRejectCustomer(rownodes,this.props.actiontype);
          
    }
    changeDateTimeValue = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	// console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
    	this.setState({
    		delivered_date:getHyphenDDMMMYYYYHHMM(d)
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    onClickViewBatchItems(rownode){
        // console.log("rownode ", rownode);

        this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs:[
                        {
                            headerName:Constants.TRIP_TRUCK_NO,
                            field:"truck_no",
                            width:120,
                        },
                        {
                            headerName:Constants.TRIP_CONSIGN_NO,
                            field:"consignment_code",
                            width:160,
                        },
                        
                       
                        {
                            headerName:Constants.TRIP_CUSTOMER_CODE,
                            field:"consignee_code",
                            width:100,
                        },
                        {
                            headerName:Constants.TRIP_CUSTOMER_NAME,
                            field:"consignee_name",
                            width:180,
                        },
                      
                        {
                            headerName:Constants.TRIP_TRANSPORTER_CODE,
                            field:"transporter_code",
                            width:180,
                        },
                        {
                            headerName:Constants.TRIP_TRANSPORTER_NAME,
                            field:"transporter_name",
                            width:180,
                        },
                        {
                            headerName:Constants.TRIP_TOTAL_DISTANCE_KM,
                            field:"total_distance_travelled_km",
                            width:140,
                            valueGetter:function(params){
                                try{
                                    if(params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "")
                                    {
                                        return parseInt(parseInt(params.data.total_distance_travelled_km)/1000);
                                    }
                                }
                                catch(e){}
                            }
                        },
                        {
                            headerName:Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
                            field:"total_travelled_trip_dist_km",
                            width:100,
                        },
                        {
                            headerName:Constants.TRIP_DURATION_DAYS,
                            field:"trip_duration_days",
                            width:100,
                        },
                        {
                            headerName:Constants.TRIP_FREIGHT_COST,
                            field:"freight_rate",
                            width:140,
                        },
                        {
                            headerName:Constants.TRIP_TOTAL_COST,
                            field:"total_cost",
                            width:140
                        }
                        ],
                        defaultColDef :{
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable:true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable:true,
                            menuTabs: ['filterMenuTab']
                        },
                    context: this,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                        var rowitems = param.data.itemlist;
                   
                        param.successCallback(rowitems);
                        
                    // })
                        
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'viewitems')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render(){
        if(this.state.actiontype === 'liable')
        {
            var checkSelect = false;
        }
        else{

            var checkSelect = true;
        }
		var columnDefs = [
            
            {
                headerName:Constants.TRIP_TRUCK_NO,
                field:"truck_no",
                width:120,
                checkboxSelection:checkSelect
            },
            {
                headerName:Constants.TRIP_CONSIGN_NO,
                field:"consignment_code",
                width:160,
            },
            
            {
                headerName:Constants.TRIP_STATUS,
                field:"trip_status_txt",
                width:160,
            },
            {
                headerName:Constants.TRIP_INV_DATE,
                field:"invoice_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.invoice_date != undefined && params.data.invoice_date != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                        }

                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_REACHED_DEST_DATE,
                field:"recent_dealer_reported",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                        }
                        
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_PLANT_NAME,
                field:"consigner_name",
                width:100,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_CODE,
                field:"consignee_code",
                width:100,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_NAME,
                field:"consignee_name",
                width:180,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_CITY,
                field:"consignee_city",
                width:180,
            },
            {
                headerName:Constants.TRIP_CUSTOMER_STATE,
                field:"consignee_state",
                width:180,
            },
            {
                headerName:Constants.TRIP_TRANSPORTER_CODE,
                field:"transporter_code",
                width:180,
            },
            {
                headerName:Constants.TRIP_TRANSPORTER_NAME,
                field:"transporter_name",
                width:180,
            },
            {
                headerName:Constants.TRIP_TOTAL_DISTANCE_KM,
                field:"total_distance_travelled_km",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "")
                        {
                            return parseInt(parseInt(params.data.total_distance_travelled_km)/1000);
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
                field:"total_travelled_trip_dist_km",
                width:100,
            },
            {
                headerName:Constants.TRIP_DURATION_DAYS,
                field:"trip_duration_days",
                width:100,
            },
            {
                headerName:Constants.TRIP_FREIGHT_COST,
                field:"freight_rate",
                width:140,
            },
            {
                headerName:Constants.TRIP_TOTAL_COST,
                field:"total_cost",
                width:140
            }
        ]
        if(this.state.actiontype === 'liable')
        {
            columnDefs.push({
                headerName:"",
                field:"invoice_no",
                width:120,
                pinned:"right",
                cellRendererSelector:function(params){
                    return {
                        component:"UploadPOD"
                    }
                },
            });
        }
        else{
            if(this.state.actiontype === 'paystatus')
            {
                columnDefs.push({
                    headerName:"",
                    field:"invoice_no",
                    width:120,
                    pinned:"right",
                    cellRendererSelector:function(params){
                        return {
                            component:"ViewPOD"
                        }
                    },
                },{
                    headerName:Constants.TRIP_PAYMENT_STATUS,
                    field:"trip_payment_status",
                    pinned:"right",
                    width:160,
                });
            }
            else{
            columnDefs.push({
                    headerName:"",
                    field:"invoice_no",
                    width:120,
                    pinned:"right",
                    cellRendererSelector:function(params){
                        return {
                            component:"ViewPOD"
                        }
                    },
                });
            }
        }


        var columnBatchDefs = [
            {
                headerName:Constants.TRIP_BATCH_NO,
                field:"batch_no",
                width:200,
                checkboxSelection:checkSelect
            },
            {
                headerName:Constants.TRIP_TOTAL_COST,
                field:"total_batch_cost",
                width:180
            },
            {
                headerName:"",
                field:"viewitems",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewBatchItems"
                    }
                },
            }
           
        ]
        if(this.state.actiontype === 'liable' || this.state.actiontype === 'pendinglsp')
        {
            var columnInitDefs = columnDefs;
        }
        else{
            var columnInitDefs = columnBatchDefs;
        }
        return (
            
            <div className="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
                </SweetAlert>
                    {this.state.alert}
                <div className="col-xl-12 col-md-12 mb-4">
                    <div className="card shadow h-100 py-2">
                        <div className="card-body">
                            <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                            <AgGridReact 
                                rowData={this.state.rowData} 
                                columnDefs={columnInitDefs}
                                defaultColDef ={this.state.defaultColDef }
                                enableRangeSelection={true}
                                enableCharts={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                masterDetail={true}
                                onGridReady={this.onGridReady}
                                sideBar={this.state.sideBar}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                rowSelection={this.state.rowSelection}
                                
                            /> 
                            </div> 
                            <div className="row mt-2">
                                <div className="col-md-12" style={{textAlign:"right"}}>
                                    {(this.props.showSendBackBtn === 1)?
                                        <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendBack.bind(this)}>Send Back</button>
                                    :""}
                                    {(this.props.showSendApproveBtn === 1)?
                                        <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                    :""}
                                    {(this.props.showSendRejectBtn === 1)?
                                        <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                    :""}
                                </div> 
                               
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
                <div className={"sliderBlock2 "+(this.state.slidershow)}>
                    <h5 className="crd-bg p-10p">Upload POD</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        
                            <div className="slide-body">
                                <form className="theme" method="POST" enctype="multipart/form-data" onSubmit={this.uploadDocument}>
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Delivered Date<span className="rclr fbold">*</span></label>                                        
                                            {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                            <Datetime 
                                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }} 
                                            dateFormat="YYYY-MM-DD" 
                                            timeFormat="HH:mm"  
                                            name="startDate" 
                                            onChange={this.changeDateTimeValue.bind(this)}
                                             />
	                    	
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Upload Document<span className="rclr fbold">*</span></label>                                        
                                            <input type="file" name="podDoc" id="podDoc" className="form-control" onChange={this.changeFileHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">Uplaod</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"sliderBlock2 "+(this.state.sliderInvoiceshow)}>
                    <h5 className="crd-bg p-10p">Approvals </h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        
                            <div className="slide-body">
                                <form className="theme" method="POST" enctype="multipart/form-data" >
                                    <div className="row p-20p">
                                        
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label> Invoice No<span className="rclr fbold">*</span></label>                                        
                                            <input type="text" name="invoice_no" id="invoice_no" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Invoice Date<span className="rclr fbold">*</span></label>                                        
                                            {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                            <Datetime 
                                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }} 
                                            dateFormat="YYYY-MM-DD" 
                                            timeFormat="HH:mm"  
                                            name="startDate" 
                                            onChange={this.changeDateTimeValue.bind(this)}
                                             />
	                    	
                                        </div>
                                        
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label> Freight Amount<span className="rclr fbold">*</span></label>                                        
                                            <input type="text" name="freight_amount" id="freight_amount" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label> GST Amount<span className="rclr fbold">*</span></label>                                        
                                            <input type="text" name="gst_amount" id="gst_amount" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Upload Invoice<span className="rclr fbold">*</span></label>                                        
                                            <input type="file" name="podDoc" id="podDoc" className="form-control" onChange={this.changeFileHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">Uplaod</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}