import React, { Component, createRef, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import CommentActions from "../layouts/commentsComponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenDDMMMYYYY } from '../common/utils';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import UpdateTransporterDetails from './UpdateTransporterDetails';
import EditPartner from './editPartner';
import DeletePartner from './deletePartnerIcon';
import DisabledUpdateTransporterDetails from './DisabledUpdateTransporterDetails';
import UpdateInvoice from './UpdateInvoice';
// import expandLegs from './expandLegs';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import 'react-datetime/css/react-datetime.css';
import $, { data } from 'jquery';
import * as XLSX from 'xlsx';
import Datetime from 'react-datetime';
import download from 'js-file-download';
import redirectURL from "../redirectURL";
import withRouter from '../withRouter';
import Select from 'react-select';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import Modal from "react-responsive-modal";
import { validate } from 'uuid';
import CSVFileValidator from 'csv-file-validator';
import DatePicker from 'react-datepicker';
import BulkForceFileHandler from './changebulkuploadhandler'
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
class Partners extends Component {
    constructor(props) {
        super(props);
        var currentDate1 = new Date();
        var initialDate = `${currentDate1.getFullYear()}-${String(currentDate1.getMonth() + 1).padStart(2, '0')}`
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true,
                menuTabs: ['filterMenuTab']
            },
            eventAction: null,
            gridMargin: "gridMarginT",
            // balpendingbtn: "activet1",

            partnerBtn: "activet1",
            oval: "1",
            plantBtn: "",
            pickupYardBtn: "",
            parkingPlazaBtn: "",
            bufferYardBtn: "",
            portGateBtn: "",
            transporterBtn: "",
            truckmasterBtn: "",
            holidayCalendar: "",
            monthOutlook: "",
            targetEstimator: "",
            regionMaster: "",
            holidayTabs: "",
            plant1: 1,
            plant2: "",
            plant3: "",
            updatedData: [],
            updatedData1: [],
            displaySlider: "true",
            displayexportSlider: "true",
            displayDateFilter: "false",
            selectDropDown: "false",
            show: false,
            frameworkComponents: {
                EditPartner: EditPartner,
                DeletePartner: DeletePartner,
                //   UpdateTransporterDetails : UpdateTransporterDetails,
                //   DisabledUpdateTransporterDetails: DisabledUpdateTransporterDetails,
                //   UpdateInvoice: UpdateInvoice,
                //   expandLegs: expandLegs,
                //   consignmentforceclose:Consignmentforceclose,
                //   consignmentActions:ConsignmentActions,
                //   UpdateButton:UpdateButton,
                //   MarkDeliver : MarkDeliver,
                //   commentActions : CommentActions
            },
            modified_by: "",
            selectYear: { 'label': 'FY 24-25', 'value': 'FY 24-25' },
            selected_financial_year: 'FY 24-25',
            transporter: { value: "", label: "Select" },
            activityOptions: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
            newActivtyOptions: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
            activityValue: {},
            newActivityValue: {},
            partnerReferenceId: "",
            transporterName: "",
            showInvoiceForm: "",
            transporterOptions: [],
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            drilldownArr: [],
            file: "",
            selectedBookingNo: "",
            selectedRowInPending: "",
            selectedRowForDeletion: "",
            selectedRowInClosure: "",
            selectedRowInProcess: "",
            selectedClosureRow: "",
            rowDataInProcess: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            isvalidbulkfile:'',
            sliderForceCloseTranslate: "",
            overly: 'show-n',
            selectedRowRequirementId: "",
            rowData: [],
            enabledSlider: "Partner",
            enableAddSlider: "Partner",
            showUpdateForm: "",
            showAddForm: "",
            showEditForm: "",
            transporterArr: [],
            currentDate: new Date(),
            selectedDate: new Date(),

            newPartnerName: "",
            partnerName: "",
            partnerCode: "",
            newPartnerCode: "",
            partnerType: "",
            newPartnerType: "",

            plantName: "",
            newPlantName: "",
            plantCode: "",
            newPlantCode: "",
            latitude: "",
            newPlantLatitude: "",
            longitude: "",
            newPlantLongitude: "",

            newContainerYardName: "",
            containerYardName: "",
            newContainerYardLatitude: "",
            containerYardLatitude: "",
            newContainerYardLongitude: "",
            containerYardLongitude: "",

            newParkingPlazaName: "",
            parkingPlazaName: "",
            newParkingPlazaLatitude: "",
            parkingPlazaLatitude: "",
            newParkingPlazaLongitude: "",
            parkingPlazaLongitude: "",

            newBufferYardName: "",
            bufferYardName: "",
            newBufferYardLatitude: "",
            bufferYardLatitude: "",
            newBufferYardLongitude: "",
            bufferYardLongitude: "",

            newPortName: "",
            portName: "",
            newGateName: "",
            gateName: "",
            newPortGateLatitude: "",
            portGateLatitude: "",
            newPortGateLongitude: "",
            portGateLongitude: "",

            newTransporterCode: "",
            transporterCode: "",
            newTransporterName: "",
            transporterName: "",
            newTransporterPlantCode: "",
            transporterPlantCode: "",
            newTransporterSOB: "",
            transporterSOB: "",

            newTruckNo: "",
            truckNo: "",
            newTruckModel: "",
            truckModel: "",
            newTransporterForTruck: "",
            transporterForTruck: "",
            newTruckValidFrom: "",
            truckValidFrom: "",
            newTruckValidTill: "",
            truckValidTill: "",
            newContainerType: "",
            containerType: "",

            countryName: "",
            newCountryName: "",
            countryCode: "",
            newCountryCode: "",
            regionName: "",
            newRegion: "",
            

            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            overlay: "show-n",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            googelRoutes: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            plants: [],
            plant_name: "",
            cellPlants: [],
            closure: "",
            invoiceData: [],
            pageType: 1,
            invoiceNumber: "",
            invoiceDate: "",
            ringiData: [{}],
            job_no: "",
            pre_alert_zip_file: "",
            detailCellRendererParams: {},
            openModal: false,
            holidayData: [],
            groupdata: [],
            sliderForBulkUpload: '',
            setsliderForBulkupload: '',
            file: '',
            csvcontent: '',
            datefilter: initialDate,
            oldPartnerCode:'',
            oldPlantCode:'',
            oldContainerPickUpName:'',
            oldParkingPlaza:'',
            oldBufferYardName:'',
            oldPortName:'',
            holidayDate:'',
            plantCode1:'',
            plantName1:'',
            day:'',
            years: [
                { 'label': 'FY 22-23', 'value': 'FY 22-23' },
                { 'label': 'FY 23-24', 'value': 'FY 23-24' },
                { 'label': 'FY 24-25', 'value': 'FY 24-25' },
                { 'label': 'FY 25-26', 'value': 'FY 25-26' }
            ]
            //components : {datepicker:getDatePicker()}
        }
    
        // this.downloadRingiFile = this.downloadRingiFile.bind(this);
        // this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
        this.onClickEditItem = this.onClickEditItem.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        // this.onUpdateInvoice = this.onUpdateInvoice.bind(this);
        // this.onExpand = this.onExpand.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                //console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    previewDocument = (document) => {
        //console.log("document", document);
        this.setState({
            openModal: true,
            documentUrl: document.lr_file_url,
            documentType: "pdf",
        });
    }
    onLoadPageDataForTarget = (e) => {
        //console.log(e, '326')
        var params = {}
        if (e != '' && e != undefined) {
            params.financial_year = e
        }
        else {
            params.financial_year = 'FY 24-25'
        }
        redirectURL.post('/consignments/gettargetvsachdata', params).then((resp) => {
            var records = JSON.parse(resp.data.tpt_target_achievement);
            const currentDate = new Date();
            const currentMonth = currentDate.toLocaleString('default', { month: 'short' }) + '-' + String(currentDate.getFullYear()).slice(2);
            const nextMonthDate = new Date();
            nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
            const nextMonth = nextMonthDate.toLocaleString('default', { month: 'short' }) + '-' + String(nextMonthDate.getFullYear()).slice(2);
            //console.log(nextMonth, "nxtmnth8888")
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('tpt_name') != '' && localStorage.getItem('tpt_name') != 'undefined') {
                    // //console.log(localStorage.getItem('tpt_name'))
                    records = records.filter(e => e.transporter_name == localStorage.getItem('tpt_name'))
                }
            }
            const trans_names = groupBy(records, (rdata) => rdata.transporter_name);
            //console.log(trans_names, "group_names555")
            const currentMonths = groupBy(records, (rdata) => rdata.month);
            const finalData = [];
            const dateKeys = [...currentMonths.keys()];
            if (dateKeys.length > 0) {
                dateKeys.forEach((month) => {
                    const row = {
                        report_date: month,
                    };
                    if (month) {
                        records.map((item) => {
                            if (month == item.month) {
                                const transporterName = item.transporter_name.trim().replace(/ /g, "");
                                row[`${transporterName}_achieved`] = item.achieved;
                                row[`${transporterName}_achieved_percent`] = item.achieved_percent;
                                row[`${transporterName}_allocated`] = item.allocated;
                                row[`${transporterName}_estimated`] = item.estimated
                                row[`${transporterName}_mark_availability`] = item.mark_availability
                                row[`${transporterName}_month`] = item.month
                                row[`${transporterName}_name`] = item.transporter_name
                            }
                        });
                    }
                    finalData.push(row);
                });
            }
            //console.log(finalData, 'finalData')
            var data = finalData
            //console.log(currentMonth, 'currentMonth')
            data = data.filter(e => e.report_date == currentMonth || e.report_date == nextMonth)
            //console.log(data, '377')
            this.setState({
                rowData: data,
                groupdata: trans_names
            })
        })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    onLoadPageDataForRegionMaster = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchRegionDetails")
            .then((response) => {
                if (response.data.status === "success") {
                    let RegionData = response.data.regionDetails;
                    //console.log(RegionData, "RegionData")
                    this.setState({
                        overly: "show-n",
                        loadshow: "show-n",
                        rowData: RegionData
                    })
                }
            })
            .catch(function (e) {
                //console.log("Error ", e)
            })
    }

    getData = (formattedDate) => {
        this.setState({
            loadshow: 'show-m',
            overlay: 'show-m'
        })
        if (formattedDate != '' && formattedDate != undefined) {
            var date = formattedDate
        }
        else {
            var date = this.state.datefilter
        }
        // var formattedDate = convertDateFormat(date)

        var params = {
            month: date
        }
        //console.log(params, 'params')
        redirectURL.post('/consignments/getplantmonthoutlook', params).then((resp) => {
            if (resp.data.status = 'Success') {
                var data = resp.data
                //console.log("data555", data)
                this.setState({
                    loadshow: 'show-n',
                    overlay: 'show-n',
                    rowData: data,

                })
            }
        })
    }
    previewBLDocument = (document) => {
        //console.log("document", document);
        this.setState({
            openModal: true,
            documentUrl: document.bl_file_url,
            documentType: "pdf",
        });
    }
    previewDODocument = (document) => {
        //console.log("document", document);
        this.setState({
            openModal: true,
            documentUrl: document.do_file_url,
            documentType: "pdf",
        });
    }
    componentDidMount() {
        this.logPageView();
        loadDateTimeScript();
        this.onLoadPageDataForPartners();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.showAddForm !== this.state.showAddForm) {
            if (this.state.showAddForm === 'slide30') {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        }
    }



    onLoadPageDataForPartners = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchPartnerDetails")
            .then((response) => {
                if (response.data.status === "success") {
                    let partnerData = response.data.partnerDetails;
                    //console.log(partnerData, "partnerData")
                    this.setState({
                        overly: "show-n",
                        loadshow: "show-n",
                        rowData: partnerData
                    })
                }
            })
            .catch(function (e) {
                //console.log("Error ", e)
            })
    }

    onLoadPageDataForPlantData = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchPlantDetails")
            .then((response) => {
                if (response.data.status === "success") {
                    let plantData = response.data.plantDetails;
                    //console.log(plantData, "plantData")
                    this.setState({
                        overly: "show-n",
                        loadshow: "show-n",
                        rowData: plantData
                    })
                }
            })
            .catch(function (e) {
                //console.log("Error ", e)
            })
    }

    onLoadPageDataForPickUpYard = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchContainerPickupYardDetails")
            .then((response) => {
                let pickUpYardDetails = response.data.containerPickupYardDetails;
                //console.log(pickUpYardDetails, "pickUpYardDetails")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: pickUpYardDetails
                })
            })
            .catch((e) => {
                //console.log("message is", e.message)
            })
    }

    onLoadPageDataForParkingYard = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchParkingPlazaDetails")
            .then((response) => {
                let parkingPlazaDetails = response.data.parkingPlazaDetails;
                //console.log(parkingPlazaDetails, "parkingPlazaDetails")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: parkingPlazaDetails
                })
            })
            .catch((e) => {
                //console.log("message is", e.message)
            })
    }

    onLoadPageDataForBufferYard = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchBufferYardDetails")
            .then((response) => {
                let bufferYardDetails = response.data.bufferYardDetails;
                //console.log(bufferYardDetails, "bufferYardDetails")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: bufferYardDetails
                })
            })
            .catch((e) => {
                //console.log("message is", e.message)
            })
    }

    onLoadPageDataForPortGate = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchPortGateDetails")
            .then((response) => {
                let portGateDetails = response.data.portGateDetails;
                //console.log(portGateDetails, "portGateDetails")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: portGateDetails
                })
            })
            .catch((e) => {
                //console.log("message is", e.message)
            })
    }

    onLoadPageDataForTransporter = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchTransporterDetails")
            .then((response) => {
                let transporterDetails = response.data.transporterDetails;
                //console.log(transporterDetails, "transporterDetails")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: transporterDetails
                })
            })
            .catch((e) => {
                //console.log("message is", e.message)
            })
    }

    onLoadPageDataForTrucks = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL
            .post("/exim/fetchTrucksDetails")
            .then((response) => {
                let trucksDetails = response.data.trucksDetails;
                //console.log(trucksDetails, "trucksDetails")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: trucksDetails
                })
            })
            .catch((e) => {
                //console.log("message is", e.message)
            })
    }
    onLoadPageDataForHoliday = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL
            .post("/exim/fetchHolidayCalendar")
            .then((response) => {
                let holidayDetails = response.data.data;
                //console.log(holidayDetails, "holidayDetails")
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    holidayData: holidayDetails,
                    rowData: holidayDetails.filter((item) => item.plant_name == "Chakan")
                })
            })
            .catch((e) => {
                //console.log("message is", e.message)
            })

    }

    onLoadPageDataForRegionData = async () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        await redirectURL.post("/exim/fetchRegionDetails")
            .then((response) => {
                if (response.data.status === "success") {
                    let regionData = response.data.regionDetails;
                    //console.log(regionData, "plantData")
                    this.setState({
                        overly: "show-n",
                        loadshow: "show-n",
                        rowData: regionData
                    })
                }
            })
            .catch(function (e) {
                //console.log("Error ", e)
            })
    }


    closeModal = () => {
        this.setState({
            openModal: false,
        });
    };
    onShowTicketHistory = async (rowdata) => {
        //console.log("rowdata ", rowdata)

        var balInDetailedData = [];

        await this.setState({

            detailCellRendererParams: {
                suppressRefresh: true,
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName: "Work Order No",
                            field: "booking_no",
                            width: 100,
                            resizable: true,
                        },
                        {
                            headerName: "Truck No",
                            field: "truck_number",
                            width: 180,
                            resizable: true,
                        },
                        {
                            headerName: "Container No",
                            field: "container_number",
                            width: 180,
                            resizable: true,
                        },
                        // {
                        //     headerName: "Pickup Date",
                        //     field: "pickup_date_time",
                        //     width: 130,
                        //     filter: true,
                        //     resizable: true,
                        //     valueGetter: function (params) {
                        //         if (params.data.pickup_date_time != "") {
                        //             return getHyphenDDMMMYYYY(params.data.pickup_date_time);
                        //         }
                        //         else {
                        //             return "";
                        //         }
                        //     },
                        // },
                        {
                            headerName: "Reporting to CHA Time",
                            field: "reporting_to_cha_datetime",
                            width: 180,
                            resizable: true,
                            valueGetter: function (params) {
                                if (params.data.reporting_to_cha_datetime != "") {
                                    return getHyphenDDMMMYYYY(params.data.reporting_to_cha_datetime);
                                }
                                else {
                                    return "";
                                }
                            },
                        },
                        {
                            headerName: "Gate In Time",
                            field: "gatein_datetime",
                            width: 180,
                            resizable: true,
                            valueGetter: function (params) {
                                if (params.data.gatein_datetime != "") {
                                    return getHyphenDDMMMYYYY(params.data.gatein_datetime);
                                }
                                else {
                                    return "";
                                }
                            },
                        },
                        {
                            headerName: "Shipped Onboard Time",
                            field: "shipped_onboard_time",
                            width: 180,
                            resizable: true,
                            valueGetter: function (params) {
                                if (params.data.shipped_onboard_time != "") {
                                    return getHyphenDDMMMYYYY(params.data.shipped_onboard_time);
                                }
                                else {
                                    return "";
                                }
                            },
                        },
                        {
                            headerName: "View LR Details",
                            field: "",
                            width: 180,
                            resizable: true,
                            cellRendererFramework: (params) => {
                                return <button className='btn btn-primary'
                                    style={{ fontSize: "12px", width: "125px", cursor: "pointer" }}
                                    onClick={this.previewDocument.bind(this, params, "LR")}>View LR Details</button>
                            }
                            // valueGetter:function(params){
                            //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                            // },
                        },
                        {
                            headerName: "View BL Details",
                            field: "",
                            width: 180,
                            resizable: true,
                            cellRendererFramework: (params) => {
                                return <button className='btn btn-primary'
                                    style={{ fontSize: "12px", width: "125px", cursor: "pointer" }}
                                    onClick={this.previewDocument.bind(this, params, "BL")}>View BL Details</button>
                            }
                            // valueGetter:function(params){
                            //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                            // },
                        },
                        {
                            headerName: "View DO Details",
                            field: "",
                            width: 180,
                            resizable: true,
                            cellRendererFramework: (params) => {
                                return <button className='btn btn-primary'
                                    style={{ fontSize: "12px", width: "125px", cursor: "pointer" }}
                                    onClick={this.previewDocument.bind(this, params, "DO")}>View DO Details</button>
                            }
                            // valueGetter:function(params){
                            //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                            // },
                        },
                    ],
                    overlayNoRowsTemplate: 'No data available',
                },
                // getDetailRowData: function(param) {
                //     ////console.log("Step 4 ",transitedelaydata)
                //     // //console.log("Step 4 ",param)
                //     //----------------------------------//

                //     var legdata=[{
                //         _id:param.data._id,
                //         // consignment_codes:param.data.consignment_codes,
                //         // exception_closed:param.data.exception_closed,
                //         booking_no:param.data.booking_no,
                //         requirement_id:param.data.requirement_id,
                //         reporting_to_cha_datetime:param.data.reporting_to_cha_datetime,
                //         gatein_datetime:param.data.gatein_datetime,
                //         shipped_onboard_time:param.data.shipped_onboard_time,
                //         // ready_to_notify:param.data.ready_to_notify,
                //         // packet_received_on:packetreceived,
                //         // reset_packet_latitude:param.data.reset_packet_latitude,
                //         // reset_packet_longitude:param.data.reset_packet_longitude,
                //         // no_gps_ticket:param.data.no_gps_ticket,
                //         // _id: param.data._id,
                //         // consignment_codes:""
                //     }]
                //     // legsarr.push(legdata)
                //     param.successCallback(legdata);

                getDetailRowData: function (params) {
                    //console.log("Step 4 ", params)
                    var qu = {
                        // ticket_id:params.data.ticket_id,
                        booking_no: params.data.booking_no,
                        cha_flag: { $in: [2, 3, 4, 5, 7] }
                    }
                    redirectURL.post('/consignments/getBookingLegsData', qu)
                        .then(async (response) => {
                            //console.log("Step 1 ", response.data)


                            params.successCallback(response.data.bookingData);
                            ////console.log("Step 2 ",response.data)

                        });

                }
            }
        });

        //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
        if (rowdata.column.colDef.field == '_id') {
            rowdata.node.setExpanded(!rowdata.node.expanded);
            if (rowdata.node.expanded == false) {
                balInDetailedData = []
            }
        }
        else {

            rowdata.node.setExpanded(false);
        }
    }
    onLoadPageDataForInProcess() {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        redirectURL.post("/consignments/getBookingData")
            .then((response) => {
                let allBookingDetails = response.data.bookingData;
                //console.log(allBookingDetails, "allBookingDetails")
                let balDispatchInClosureRowDetails = [];
                balDispatchInClosureRowDetails = allBookingDetails.filter((item) => {
                    if (item.bal_flag === 2 && !item.hasOwnProperty("invoice_number")) {
                        return item;
                    }
                })

                //console.log("balDispatchInClosureRowDetails", balDispatchInClosureRowDetails);
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: balDispatchInClosureRowDetails,
                    inBalClosure: balDispatchInClosureRowDetails,
                })
            }, () => {
                //console.log(this.state.rowData, "ffupdates pending")
            })
            .catch(function (e) {
                //console.log("Error ", e)
            })
    }
    onLoadPageDataForClosure() {
        this.setState({
            overly: "show-m",
            loadshow: "show-m",
        });
        redirectURL.post("/consignments/getBookingData")
            .then((response) => {
                let allBookingDetails = response.data.bookingData;
                //console.log(allBookingDetails, "allBookingDetails")
                let balDispatchInClosureRowDetails = [];
                balDispatchInClosureRowDetails = allBookingDetails.filter((item) => {
                    if (item.bal_flag === 7 && !item.hasOwnProperty("invoice_number")) {
                        return item;
                    }
                })

                //console.log("balDispatchInClosureRowDetails", balDispatchInClosureRowDetails);
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData: balDispatchInClosureRowDetails,
                    inBalClosure: balDispatchInClosureRowDetails,
                })
            }, () => {
                //console.log(this.state.rowData, "ffupdates pending")
            })
            .catch(function (e) {
                //console.log("Error ", e)
            })
    }
    invoiceNumberHandler = (e) => {
        this.setState({
            invoiceNumber: e.target.value,
        })
    }
    invoiceDateHandler = (e) => {
        this.setState({
            invoiceDate: e.target.value,
        })
    }
    // onLoadPageData = async (dataType) => {
    // this.setState({
    //     overly:"show-m",
    //     loadshow:"show-m",
    // });
    // var supplier_code = localStorage.getItem("supplier_code");
    // var params = {
    //     "ff_code" : supplier_code
    // }
    // redirectURL.post("/consignments/getFFSeaImports",params)    

    // }

    // onLoadClosureData(){
    //     this.setState({
    //         overly:"show-m",
    //         loadshow:"show-m",
    //     });
    //     var supplier_code = localStorage.getItem("supplier_code");
    //     var params = {
    //         "ff_code" : supplier_code
    //     }
    //     redirectURL.post("/consignments/getFFSeaImportsClosures",params)    
    //     .then((response) => {
    //         var records = response.data;
    //         //console.log("records ", records)
    //         this.setState({
    //             rowData : records,
    //             overly:"show-n",
    //             loadshow:"show-n",
    //         })
    //     })
    //     .catch(function(e){
    //         //console.log("Error ",e)
    //     })
    // }

    onGridReady = params => {
        this.gridApi = params.api;
        ////console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        ////console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    // onClickUpdateItem = async (rownode) => 
    //     {
    //         //console.log("Rowdnode Update", rownode)
    //         var selectedRowData = rownode.data;
    //         // //console.log(selectedRowData, "selectedRowData")
    //         var selectedBookingNo = selectedRowData.booking_no;
    //         //console.log(selectedBookingNo, "selectedBookingNo")
    //         this.setState({
    //             showUpdateForm:"slide30",
    //             showInvoiceForm: "",
    //             overly:"show-m",
    //             selectedRowInPending: selectedRowData,
    //             selectedBookingNo: selectedBookingNo
    //         })
    //         await redirectURL
    //         .get('/consignments/gettransporterDetails')
    //         .then((resp)=> {
    //             let TransporterDetails = resp.data.transporterDetails;
    //             //console.log(TransporterDetails, "TranporterDetails");
    //             let transporterArr = TransporterDetails.map((obj)=> 
    //                 ({ transporter_name: obj.transporter_name, transporter_code: obj.transporter_code })
    //             )
    //             //console.log(transporterArr, "transporterArr")
    //             this.setState({
    //                 transporterArr: transporterArr
    //             }, ()=>{
    //                 let transporterOptions = this.state.transporterArr.map((i)=> ({
    //                     value: i.transporter_code,
    //                     label: i.transporter_name
    //                 }))
    //                 //console.log(transporterOptions, "select transport")
    //                 this.setState({
    //                     transporterOptions: transporterOptions
    //                 })
    //             })
    //             // let transporterNames = TransporterDetails.map((item)=> {
    //             //     return item.transporter_name
    //             // })
    //             // //console.log(transporterNames, "transporterNames")
    //             // this.setState({
    //             //     transporterArr: transporterNames
    //             // }, () => {
    //             //     //console.log(this.state.transporterArr, "transporter Arr List")
    //             //     const transporterOptions = this.state.transporterArr.map((transporter) => ({
    //             //         value: transporter,
    //             //         label: transporter,
    //             //     }));
    //             //     //console.log(transporterOptions, "transporterOptions")
    //             //     this.setState({
    //             //         transporterOptions: transporterOptions
    //             //     })
    //             // })

    //         })
    // }
    onClickDeleteItem = async (rownode) => {
        //console.log("Rowdnode Update", rownode)
        if (rownode.data.partner_code) {
            this.setState({
                overly: "show-m",
            })
            let partner_code = { partner_code: rownode.data.partner_code };

            await redirectURL
                .post("/exim/deletePartnerDetails", partner_code)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedPartnerData")
                        let DeletedPartnerData = resp.data;
                        //console.log("DeletedPartnerData", DeletedPartnerData)
                        this.setState({
                            overly: "show-n",
                        })
                        await this.onLoadPageDataForPartners();
                    }
                })
        } else if (rownode.data.plant_code) {
            this.setState({
                overly: "show-m",
            })
            let plant_code = { plant_code: rownode.data.plant_code };
            await redirectURL
                .post("/exim/deletePlantDetails", plant_code)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedPlantData")

                        this.setState({
                            overly: "show-n",

                        })
                        await this.onLoadPageDataForPlantData();
                    }
                })
        } else if (rownode.data.pickup_yard_name) {
            this.setState({
                overly: "show-m",
            })
            let pickup_yard_name = { pickup_yard_name: rownode.data.pickup_yard_name };

            await redirectURL
                .post("/exim/deleteContainerYardDetails", pickup_yard_name)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedContainerData")

                        this.setState({
                            overly: "show-n",
                        })
                        await this.onLoadPageDataForPickUpYard();
                    }
                })
        } else if (rownode.data.parking_plaza_name) {
            this.setState({
                overly: "show-m",
            })
            let parking_plaza_name = { parking_plaza_name: rownode.data.parking_plaza_name };

            await redirectURL
                .post("/exim/deleteParkingYardDetails", parking_plaza_name)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedParkingData")

                        this.setState({
                            overly: "show-n",
                        })
                        await this.onLoadPageDataForParkingYard();
                    }
                })
        } else if (rownode.data.buffer_yard_name) {
            this.setState({
                overly: "show-m",
            })
            let buffer_yard_name = { buffer_yard_name: rownode.data.buffer_yard_name };

            await redirectURL
                .post("/exim/deleteBufferYardDetails", buffer_yard_name)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedBufferYardData")

                        this.setState({
                            overly: "show-n",
                        })
                        await this.onLoadPageDataForBufferYard();
                    }
                })
        } else if (rownode.data.port_name) {
            this.setState({
                overly: "show-m",
            })
            let port_name = { port_name: rownode.data.port_name };

            await redirectURL
                .post("/exim/deletePortGateDetails", port_name)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedPortGateData")

                        this.setState({
                            overly: "show-n",
                        })
                        await this.onLoadPageDataForPortGate();
                    }
                })
        } else if (rownode.data.transporter_reference_id) {
            this.setState({
                overly: "show-m",
            })
            let transporter_reference_id = { transporter_reference_id: rownode.data.transporter_reference_id };

            await redirectURL
                .post("/exim/deleteTransporterDetails", transporter_reference_id)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedTransporterData")

                        this.setState({
                            overly: "show-n",
                        })
                        await this.onLoadPageDataForTransporter();
                    }
                })
        } else if (rownode.data.truck_reference_id) {
            this.setState({
                overly: "show-m",
            })
            let truck_reference_id = { truck_reference_id: rownode.data.truck_reference_id };

            await redirectURL
                .post("/exim/deleteTruckDetails", truck_reference_id)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedTruckData")

                        this.setState({
                            overly: "show-n",
                        })
                        await this.onLoadPageDataForTrucks();
                    }
                })
        } else if (rownode.data.country_code) {
            this.setState({
                overly: "show-m",
            })
            let country_code = { country_code: rownode.data.country_code };
            await redirectURL
                .post("/exim/deleteRegionDetails", country_code)
                .then(async (resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data, "DeletedRegionData")

                        this.setState({
                            overly: "show-n",

                        })
                        await this.onLoadPageDataForRegionData();
                    }
                })
        }
    }
    onClickEditItem = async (rownode) => {
        //console.log("Rowdnode Update", rownode.data)
        
        if (rownode.data.partner_code) {
           
            let partner_code = { partner_code: rownode.data.partner_code };
            this.setState({
                oldPartnerCode: rownode.data.partner_code
            })
            await redirectURL
                .post("/exim/fetchPartnerDetails", partner_code)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedPartnerData, "fetchedPartnerData")
                        let fetchedPartnerData = resp.data.fetchedPartnerData;
                        this.setState({
                            partnerCode: fetchedPartnerData[0].partner_code,
                            partnerName: fetchedPartnerData[0].partner_name,
                            partnerType: fetchedPartnerData[0].partner_type,
                            activityValue: { value: `${fetchedPartnerData[0].activity}`, label: `${fetchedPartnerData[0].activity}` }
                        })
                    }
                })
                this.setState({
                    showEditForm: "slide30",
                    overly: "show-m",
                })

        }
        
        else if (rownode.data.plant_code) {
        
            //console.log('1139')
            let plant_code = { plant_code: rownode.data.plant_code };
            this.setState({
                oldPlantCode: rownode.data.plant_code
            })
            await redirectURL
                .post("/exim/fetchPlantDetails", plant_code)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedPlantData, "fetchedPlantData")
                        let fetchedPlantData = resp.data.fetchedPlantData;
                        this.setState({
                            plantCode: fetchedPlantData[0].plant_code,
                            plantName: fetchedPlantData[0].plant_name,
                            latitude: fetchedPlantData[0].latitude,
                            longitude: fetchedPlantData[0].longitude
                        })
                    }
                })
                this.setState({
                    showEditForm: "slide30",
                    overly: "show-m",
                })
           
        } else if (rownode.data.pickup_yard_name) {
            let pickup_yard_name = { pickup_yard_name: rownode.data.pickup_yard_name };
            this.setState({
                oldContainerPickUpName: rownode.data.pickup_yard_name
            })
            await redirectURL
                .post("/exim/fetchContainerPickupYardDetails", pickup_yard_name)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedPlantData, "fetchedPlantData")
                        let fetchedContainerYardData = resp.data.fetchedContainerYardData;
                        this.setState({
                            containerYardName: fetchedContainerYardData[0].pickup_yard_name,
                            containerYardLatitude: fetchedContainerYardData[0].latitude,
                            containerYardLongitude: fetchedContainerYardData[0].longitude
                        })
                    }
                })

            this.setState({
                showEditForm: "slide30",
                overly: "show-m",
            })
        } else if (rownode.data.parking_plaza_name) {
            let parking_plaza_name = { parking_plaza_name: rownode.data.parking_plaza_name };
            this.setState({
                oldParkingPlaza: rownode.data.parking_plaza_name
            })
            await redirectURL
                .post("/exim/fetchParkingPlazaDetails", parking_plaza_name)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedParkingYardData, "fetchedParkingYardData")
                        let fetchedParkingYardData = resp.data.fetchedParkingYardData;
                        this.setState({
                            parkingPlazaName: fetchedParkingYardData[0].parking_plaza_name,
                            parkingPlazaLatitude: fetchedParkingYardData[0].latitude,
                            parkingPlazaLongitude: fetchedParkingYardData[0].longitude
                        })
                    }
                })

            this.setState({
                showEditForm: "slide30",
                overly: "show-m",
            })
        } else if (rownode.data.buffer_yard_name) {
            let buffer_yard_name = { buffer_yard_name: rownode.data.buffer_yard_name };
            this.setState({
                oldBufferYardName: rownode.data.buffer_yard_name
            })
            await redirectURL
                .post("/exim/fetchBufferYardDetails", buffer_yard_name)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedBufferYardData, "fetchedBufferYardData")
                        let fetchedBufferYardData = resp.data.fetchedBufferYardData;
                        this.setState({
                            bufferYardName: fetchedBufferYardData[0].buffer_yard_name,
                            bufferYardLatitude: fetchedBufferYardData[0].latitude,
                            bufferYardLongitude: fetchedBufferYardData[0].longitude
                        })
                    }
                })

            this.setState({
                showEditForm: "slide30",
                overly: "show-m",
            })
        } else if (rownode.data.port_name) {
            let port_name = { port_name: rownode.data.port_name };
            this.setState({
                oldPortName: rownode.data.port_name
            })
            await redirectURL
                .post("/exim/fetchPortGateDetails", port_name)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedPortGateData, "fetchedPortGateData")
                        let fetchedPortGateData = resp.data.fetchedPortGateData;
                        this.setState({
                            portName: fetchedPortGateData[0].port_name,
                            gateName: fetchedPortGateData[0].gate_name,
                            portGateLatitude: fetchedPortGateData[0].latitude,
                            portGateLongitude: fetchedPortGateData[0].longitude
                        })
                    }
                })

            this.setState({
                showEditForm: "slide30",
                overly: "show-m",
            })
        } 
        else if (rownode.data.transporter_reference_id) {
            let transporter_reference_id = { transporter_reference_id: rownode.data.transporter_reference_id };
            this.setState({
                transporterReferenceId: rownode.data.transporter_reference_id
            })
            await redirectURL
                .post("/exim/fetchTransporterDetails", transporter_reference_id)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedTransporterData, "fetchedTransporterData")
                        let fetchedTransporterData = resp.data.fetchedTransporterData;
                        this.setState({
                            transporterName: fetchedTransporterData[0].transporter_name,
                            transporterCode: fetchedTransporterData[0].transporter_code,
                            transporterPlantCode: fetchedTransporterData[0].plant_code,
                            transporterSOB: fetchedTransporterData[0].sob
                        })
                    }
                })

            this.setState({
                showEditForm: "slide30",
                overly: "show-m",
            })
        } else if (rownode.data.truck_reference_id) {
            let truck_reference_id = { truck_reference_id: rownode.data.truck_reference_id };
            this.setState({
                truckReferenceId: rownode.data.truck_reference_id
            })
            await redirectURL
                .post("/exim/fetchTrucksDetails", truck_reference_id)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedTruckData, "fetchedTruckData")
                        let fetchedTruckData = resp.data.fetchedTruckData;
                        this.setState({
                            truckNo: fetchedTruckData[0].truck_number,
                            transporterForTruck: fetchedTruckData[0].transportercode_for_truck,
                            truckModel: fetchedTruckData[0].truck_model,
                            containerType: fetchedTruckData[0].container_type,
                            startDate: fetchedTruckData[0].valid_from,
                            endDate: fetchedTruckData[0].valid_till
                        })
                    }
                })

            this.setState({
                showEditForm: "slide30",
                overly: "show-m",
            })
        } else if (rownode.data.country_code) {
        
            //console.log('1139')
            let country_code = { country_code: rownode.data.country_code };
            this.setState({
                oldCountryCode: rownode.data.country_code
            })
            await redirectURL
                .post("/exim/fetchRegionDetails", country_code)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        //console.log(resp.data.fetchedRegionData, "fetchedRegionData")
                        let fetchedRegionData = resp.data.fetchedRegionData;
                        this.setState({
                            countryCode: fetchedRegionData[0].country_code,
                            countryName: fetchedRegionData[0].country_name,
                            regionName: fetchedRegionData[0].region,
                        })
                    }
                })
                this.setState({
                    showEditForm: "slide30",
                    overly: "show-m",
                })
           
        }
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }




    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            invoiceData: [],
            ringiData: [{}],
        });

    }
    handleCellValueChanged() {

    }
    onChangeHandleDate=(e)=>{
        var d = moment(e._d);
        var formattedDate = d.format('DD-MM-YYYY');
        var dayofweek = d.format('dddd')
        this.setState({holidayDate: formattedDate, day:dayofweek})
    }

    onClickTab(Oval) {
        if (Oval === 1) {
            this.onLoadPageDataForPartners();
            this.setState({
                partnerBtn: "activet1",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                monthOutlook: "",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "",
                oval: "1",
                exportBtn: "Partner",
                enabledSlider: "Partner",
                enableAddSlider: "Partner",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: "gridMarginT"
            })

        }
        else if (Oval === 2) {
            //console.log("entered second")
            this.onLoadPageDataForPlantData();
            this.setState({
                partnerBtn: "",
                plantBtn: "activet1",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                monthOutlook: "",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "",
                exportBtn: "Plant",
                enabledSlider: "Plant",
                oval: "2",
                enableAddSlider: "Plant",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: "gridMarginT"

            }, () => {
                //console.log(this.state.rowData, "second tab data")
            })
        } else if (Oval === 3) {
            //console.log("entered third")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForPickUpYard();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "activet1",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                monthOutlook: "",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "",
                exportBtn: "Container Pickup Yard",
                enabledSlider: "Container Pickup Yard",
                oval: "3",
                enableAddSlider: "Container Pickup Yard",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: "gridMarginT"

                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        } else if (Oval === 4) {
            //console.log("entered fourth")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForParkingYard();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "activet1",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                monthOutlook: "",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "",
                exportBtn: "Parking Yard",
                enabledSlider: "Parking Yard",
                oval: "4",
                enableAddSlider: "Parking Yard",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: "gridMarginT"

                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        } else if (Oval === 5) {
            //console.log("entered fifth")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForBufferYard();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "activet1",
                portGateBtn: "",
                holidayCalendar: "",
                monthOutlook: "",
                transporterBtn: "",
                targetEstimator: "",
                truckmasterBtn: "",
                regionMaster: "",
                exportBtn: "Buffer Yard",
                enabledSlider: "Buffer Yard",
                oval: "5",
                enableAddSlider: "Buffer Yard",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: "gridMarginT"

                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        } else if (Oval === 6) {
            //console.log("entered sixth")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForPortGate();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "activet1",
                holidayCalendar: "",
                targetEstimator: "",
                monthOutlook: "",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "",
                exportBtn: "Port Gate",
                enabledSlider: "Port Gate",
                oval: "6",
                enableAddSlider: "Port Gate",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: "gridMarginT"

                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        } else if (Oval === 7) {
            //console.log("entered seventh")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForTransporter()
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                transporterBtn: "activet1",
                monthOutlook: "",
                truckmasterBtn: "",
                regionMaster: "",
                exportBtn: "Transporter",
                enabledSlider: "Transporter",
                oval: "7",
                enableAddSlider: "Transporter",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: ""


                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        } else if (Oval === 8) {
            //console.log("entered eight")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForTrucks();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                monthOutlook: "",
                transporterBtn: "",
                regionMaster: "",
                truckmasterBtn: "activet1",
                exportBtn: "Trucks",
                enabledSlider: "Trucks",
                oval: "8",
                enableAddSlider: "Trucks",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: ""
                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        }
        else if (Oval === 9) {
            //console.log("entered nine")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForHoliday();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "activet1",
                monthOutlook: "",
                transporterBtn: "",
                truckmasterBtn: "",
                targetEstimator: "",
                regionMaster: "",
                exportBtn: "holiday",
                enabledSlider: "holiday",
                oval: "9",
                enableAddSlider: "Holiday",
                holidayTabs: "active",
                plant1: 1,
                plant2: 0,
                plant3: 0,
                displaySlider: "true",
                displayexportSlider: "false",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: ""

                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        }
        else if (Oval === 10) {
            //console.log("entered ten")
            // var initialDate =`${this.state.currentDate.getFullYear()}-${String(this.state.currentDate.getMonth() + 1).padStart(2, '0')}`

            // var datefilter =  this.state.initialDate
            // var selectedDate = new Date()
            this.getData(this.state.datefilter)
            // this.onLoadPageDataForClosure()
            // this.onLoadPageDataForMonth();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "",
                exportBtn: "month",
                enabledSlider: "month",
                oval: "10",
                enableAddSlider: "month",
                holidayTabs: "",
                displaySlider: "false",
                displayexportSlider: "false",
                monthOutlook: "activet1",
                displayDateFilter: "true",
                selectDropDown: "false",
                gridMargin: ""
                // rowData: this.state.inBalClosure,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        }
        else if (Oval === 11) {
            //console.log("entered eleven")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForTarget();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "activet1",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "",
                // exportBtn: "target",
                // enabledSlider: "target",
                oval: "11",
                // enableAddSlider: "target",
                holidayTabs: "",
                displaySlider: "false",
                displayexportSlider: "false",
                monthOutlook: "",
                displayDateFilter: "false",
                selectDropDown: "true",
                gridMargin: ""


                //  rowData: this.state.targetEstimatedata,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        }
        else if (Oval === 12) {
            //console.log("entered twelve")
            // this.onLoadPageDataForClosure()
            this.onLoadPageDataForRegionData();
            this.setState({
                partnerBtn: "",
                plantBtn: "",
                pickupYardBtn: "",
                parkingPlazaBtn: "",
                bufferYardBtn: "",
                portGateBtn: "",
                holidayCalendar: "",
                targetEstimator: "",
                monthOutlook: "",
                transporterBtn: "",
                truckmasterBtn: "",
                regionMaster: "activet1",
                exportBtn: "Region",
                enabledSlider: "Region",
                oval: "12",
                enableAddSlider: "Region",
                holidayTabs: "",
                displaySlider: "true",
                displayexportSlider: "true",
                displayDateFilter: "false",
                selectDropDown: "false",
                gridMargin: "gridMarginT"
                //  rowData: this.state.targetEstimatedata,
            }, () => {
                // //console.log(this.state.rowData, "third tab data")
            })
        }
    }
    onCellUpdateData1(params) {
        var updatedRowData1 = [...this.state.updatedData1]
        updatedRowData1.push(params.data)
        //console.log(updatedRowData1, 'updatedRowData1')
        var updatedrecord = []
        updatedRowData1.map((e) => {
            var keys = Object.keys(e);
            keys.map((k) => {
                if (k.includes("_name") == true) {
                    var tptName = k.split("_")[0];
                    var tptNamewithoutspaces = tptName.trim().replace(/ /g, "");
                }
                if (e[tptNamewithoutspaces + "_estimated"] != undefined && e[tptNamewithoutspaces + "_estimated"] != "undefined" && e[tptNamewithoutspaces + "_estimated"] != '') {
                    updatedrecord.push({
                        "month": e["report_date"],
                        "transporter_name": e[tptNamewithoutspaces + "_name"],
                        "estimated": e[tptNamewithoutspaces + "_estimated"],
                        "financial_year": this.state.selected_financial_year
                    });
                }
            });
            //console.log(updatedrecord, 'updatedrecord--1')
            var params = {
                data: updatedrecord
            }
            redirectURL.post('/consignments/insertestimatedval', params).then((resp) => {
                if (resp.data.status == 'Success') {
                    this.onLoadPageDataForTarget();
                }
            })
            // var row = {'report_date':e.report_date,'mark_availability':e.}
        })
    }
    onCellUpdateData(params) {
        var updatedRowData = [...this.state.updatedData];
        updatedRowData.push(params.data)
        //console.log(updatedRowData, 'updatedRowData')
        var updatedrecord = []
        var initialDate = `${this.state.currentDate.getFullYear()}-${String(this.state.currentDate.getMonth() + 1).padStart(2, '0')}`
        var params = {
            month: initialDate,
            plant_code: updatedRowData[0].plant,
            month_outlook: updatedRowData[0].month_outlook
        }
        //console.log(params, 'params')
        redirectURL.post('/consignments/getaskingratereportdata', params).then((resp) => {
            var data = resp.data.asking_rate_report
            data = JSON.parse(data)
            //console.log("data555", data)
            this.setState({
                rowData: data,
                overlay: 'show-n',
                loadshow: 'show-n'
            })

        })
    }

    viewDetails(e) {
        if (e.colDef.field == "invoiceData") {
            var invData = [];
            if (e.data.invoiceData != undefined && e.data.invoiceData != '') {
                var invoiceData = JSON.parse(e.data.invoiceData);
                if (invoiceData != undefined && invoiceData.length > 0) {
                    invData = invoiceData;
                }
            }
            var sliderTitle = e.data.job_no + " - Pre alert documents";
            var pre_alert_zip_file = "";
            if (e.data.pre_alert_zip_file != undefined && e.data.pre_alert_zip_file != "") {
                pre_alert_zip_file = e.data.pre_alert_zip_file;
            }
            this.setState({
                overly: "show-m",
                job_no: e.data.job_no,
                invoiceData: invData,
                ringiData: invData,
                bulkslide: "slider-translate-60p",
                sliderTitle: sliderTitle,
                pre_alert_zip_file: pre_alert_zip_file
            })
        }
        if (e.colDef.field == "other_docs") {
            var ringiFile = e.data.other_docs
            if (ringiFile != '') {
                var rngParam = {
                    ringiFile: ringiFile,
                }
                redirectURL.post("/consignments/downloadRingiFile", rngParam).then((response) => {
                    //console.log("dowlad response ", response)
                    download(response.data, ringiFile)
                }).catch(function (error) {
                    //console.log(error);
                })
            }
        }
    }

    downloadRingiFile = (ringiFile) => {
        ////console.log(ringiFile)
        if (ringiFile != '') {
            var rngParam = {
                ringiFile: ringiFile,
            }
            redirectURL.post("/consignments/downloadSeaImportsRingiFile", rngParam, {
                responseType: 'blob'
            }).then((response) => {
                //console.log("dowlad response ", response)
                download(response.data, ringiFile)
            }).catch(function (error) {
                //console.log(error);
            })
        }
    }

    handleAddRingiRow = () => {
        const item = {
            ringi_file: "",
        };
        this.setState({
            ringiData: [...this.state.ringiData, item]
        });
    };

    handleRemoveRingiRow = (idx) => {
        this.state.ringiData.splice(idx, 1);
        this.setState({ ringiData: this.state.ringiData });
        var ringiFile = $("#hiddenRingiFile_" + idx).val();
        //console.log(ringiFile)
        if (ringiFile != '') {
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                var rngParam = {
                    ringiFile: ringiFile,
                }
                redirectURL.post("/consignments/removeRingiFile", rngParam).then((response) => {
                }).catch(function (error) {
                    //console.log(error);
                })
            }
        }
    }

    handleRemoveRingiFile = (idx) => {
        var ringiFile = $("#hiddenRingiFile_" + idx).val();
        //console.log(ringiFile)
        if (ringiFile != '') {
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                var rngParam = {
                    ringiFile: ringiFile,
                }
                redirectURL.post("/consignments/removeRingiFile", rngParam).then((response) => {
                    $(".rnFile_" + idx).html("");
                    this.hide();
                }).catch(function (error) {
                    //console.log(error);
                })
            }
        }
    }

    handleDownloadRingiFile = (idx) => {
        var ringiFile = $("#hiddenRingiFile_" + idx).val();
        ////console.log(ringiFile)
        if (ringiFile != '') {
            var rngParam = {
                ringiFile: ringiFile,
            }
            redirectURL.post("/consignments/downloadRingiFile", rngParam, {
                responseType: 'blob'
            }).then((response) => {
                //console.log("dowlad response ", response)
                download(response.data, ringiFile)
            }).catch(function (error) {
                //console.log(error);
            })
        }
    }

    handleDownloadZipFile = (idx) => {
        var ringiFile = idx;
        ////console.log(ringiFile)
        if (ringiFile != '') {
            var rngParam = {
                ringiFile: ringiFile,
            }
            redirectURL.post("/consignments/storeSeaImportsPreAlertDocuments", rngParam, {
                responseType: 'blob'
            }).then((response) => {
                //console.log("dowlad response ", response)
                download(response.data, ringiFile)
            }).catch(function (error) {
                //console.log(error);
            })
        }
    }

    changeFileHandler = async (e) => {
        //console.log(e.target.name)
        this.setState({ [e.target.name]: e.target.files[0] });
    }
    hideSlideBlock() {
        this.setState({
            // showUpdateForm:"show-n",
            showEditForm: "show-n",
            showAddForm: "show-n",
            overly: "show-n",
            loadshow: "show-n"
            // showInvoiceForm: "show-n"
        })
    }
    downloadFile(fileno) {
        if (fileno === "1") {
            this.onLoadPageDataForPartners();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Partner Code', 'Partner Name', 'Partner Type', 'Activity Flag']
            ]

            this.state.rowData.forEach((each) => {
                let partner_code = "";
                let partner_name = "";
                let partner_type = "";
                let activity = "";

                partner_code = each.partner_code;
                partner_name = each.partner_name;
                partner_type = each.partner_type;
                activity = each.activity;

                let rowData = [
                    partner_code,
                    partner_name,
                    partner_type,
                    activity
                ]

                xlsxData.push(rowData)
                // const rowAddress = XLSX.utils.encode_row(rowIndex);
                // XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: { r: rowAddress, c: 0 } });

                // rowIndex++; 
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "partner_data.xlsx")
        } else if (fileno === "2") {
            this.onLoadPageDataForPlantData();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Plant Code', 'Plant Name', 'Latitude', 'Longitude']
            ]

            this.state.rowData.forEach((each) => {
                let plant_code = "";
                let plant_name = "";
                let latitude = "";
                let longitude = "";

                plant_code = each.plant_code;
                plant_name = each.plant_name;
                latitude = each.latitude;
                longitude = each.longitude;

                let rowData = [
                    plant_code,
                    plant_name,
                    latitude,
                    longitude
                ]

                xlsxData.push(rowData)
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "plant_data.xlsx")
        } else if (fileno === "3") {
            this.onLoadPageDataForPickUpYard();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Container Pickup Yard Name', 'Latitude', 'Longitude']
            ]

            this.state.rowData.forEach((each) => {
                let container_pickup_yard_name = "";
                let latitude = "";
                let longitude = "";

                container_pickup_yard_name = each.pickup_yard_name;
                latitude = each.latitude;
                longitude = each.longitude;

                let rowData = [
                    container_pickup_yard_name,
                    latitude,
                    longitude
                ]

                xlsxData.push(rowData)
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "container_pickup_yard_data.xlsx")
        } else if (fileno === "4") {
            this.onLoadPageDataForParkingYard();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Parking Plaza Name', 'Latitude', 'Longitude']
            ]

            this.state.rowData.forEach((each) => {
                let parking_plaza_name = "";
                let latitude = "";
                let longitude = "";

                parking_plaza_name = each.parking_plaza_name;
                latitude = each.latitude;
                longitude = each.longitude;

                let rowData = [
                    parking_plaza_name,
                    latitude,
                    longitude
                ]

                xlsxData.push(rowData)
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "parking_plaza_data.xlsx")
        } else if (fileno === "5") {
            this.onLoadPageDataForBufferYard();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Buffer Yard Name', 'Latitude', 'Longitude']
            ]

            this.state.rowData.forEach((each) => {
                let buffer_yard_name = "";
                let latitude = "";
                let longitude = "";

                buffer_yard_name = each.buffer_yard_name;
                latitude = each.latitude;
                longitude = each.longitude;

                let rowData = [
                    buffer_yard_name,
                    latitude,
                    longitude
                ]

                xlsxData.push(rowData)
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "buffer_yard_data.xlsx")
        } else if (fileno === "6") {
            this.onLoadPageDataForPortGate();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Port Name', 'Gate Name', 'Latitude', 'Longitude']
            ]

            this.state.rowData.forEach((each) => {
                let port_name = "";
                let gate_name = "";
                let latitude = "";
                let longitude = "";

                port_name = each.port_name;
                gate_name = each.gate_name;
                latitude = each.latitude;
                longitude = each.longitude;

                let rowData = [
                    port_name,
                    gate_name,
                    latitude,
                    longitude
                ]

                xlsxData.push(rowData)
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "port_gate_data.xlsx")
        } else if (fileno === "7") {
            this.onLoadPageDataForTransporter();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Transporter Name', 'Transporter Code', 'Plant Code', 'SOB']
            ]

            this.state.rowData.forEach((each) => {
                let transporter_name = "";
                let transporter_code = "";
                let plant_code = "";
                let sob = "";

                transporter_name = each.transporter_name;
                transporter_code = each.transporter_code;
                plant_code = each.plant_code;
                sob = each.sob;

                let rowData = [
                    transporter_name,
                    transporter_code,
                    plant_code,
                    sob
                ]

                xlsxData.push(rowData)
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "transporter_data.xlsx")
        } else if (fileno === "8") {
            this.onLoadPageDataForTrucks();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Truck Number', 'Transporter Code', 'Truck Model', 'Container Type', 'Valid From', 'Valid To']
            ]

            this.state.rowData.forEach((each) => {
                let truck_number = "";
                let transportercode_for_truck = "";
                let truck_model = "";
                let container_type = "";
                let valid_from = "";
                let valid_till = "";

                truck_number = each.truck_number;
                transportercode_for_truck = each.transportercode_for_truck;
                truck_model = each.truck_model;
                container_type = each.container_type;
                valid_from = each.valid_from;
                valid_till = each.valid_till;

                let rowData = [
                    truck_number,
                    transportercode_for_truck,
                    truck_model,
                    container_type,
                    valid_from,
                    valid_till
                ]

                xlsxData.push(rowData)
                // const rowAddress = XLSX.utils.encode_row(rowIndex);
                // XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: { r: rowAddress, c: 0 } });

                // rowIndex++; 
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "truck_data.xlsx")
        } else if (fileno === "12") {
            this.onLoadPageDataForRegionData();
            //console.log(this.state.rowData, "rowData")
            let xlsxData = [
                ['Country Code', 'Country Name', 'Region']
            ]

            this.state.rowData.forEach((each) => {
                let country_code = "";
                let country_name = "";
                let region = "";

                country_code = each.country_code;
                country_name = each.country_name;
                region = each.region;

                let rowData = [
                    country_code,
                    country_name,
                    region
                ]

                xlsxData.push(rowData)
            })

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, "region_data.xlsx")
        }
    }
    onExportData = (e, oval) => {
        e.preventDefault();
        //console.log(typeof (oval), "tab is")
        switch (oval) {
            case "1":
                //console.log("enter partner")
                this.downloadFile(oval);
                break;
            case "2":
                this.downloadFile(oval);
                break;
            case "3":
                this.downloadFile(oval);
                break;
            case "4":
                this.downloadFile(oval);
                break;
            case "5":
                this.downloadFile(oval);
                break;
            case "6":
                this.downloadFile(oval);
                break;
            case "7":
                this.downloadFile(oval);
                break;
            case "8":
                this.downloadFile(oval);
                break;
            case "12":
                this.downloadFile(oval);
                break;
            default:
        }

    }


    onAddData = (e, oval) => {
        e.preventDefault();
        //console.log(oval, "tab is")
        this.setState({
            showAddForm: "slide30",
            overly: "show-m"
        })
    }
    // onUpdatePartnerForm = async (e) => {
    //     e.preventDefault();
    //     // //console.log(this.state.transporter, "selected value")
    //     // const formData = new FormData();
    //     // formData.append("transporter_assigned", this.state.transporter)
    //     // formData.append("booking_no", this.state.selectedBookingNo)
    //     //console.log(this.state.selectedBookingNo, "610")
    //     var res = this.gridApi.updateRowData({ remove: [this.state.selectedRowInPending] });
    //     let payload = {
    //         booking_no: this.state.selectedBookingNo,
    //         transporter_assigned: this.state.transporter.label,
    //         transporter_code: this.state.transporter.value
    //     }
    //     // //console.log(payload, "503")
    //     if(this.state.transporter){
    //         // //console.log("hi")
    //         redirectURL
    //         .post("/consignments/onAssignedTransporter", payload)
    //         .then((response) => {
    //             if(response.status == 200){
    //                 //console.log(response.updatedData, "updatedRecordWithTransporterAssigned")
    //                 this.hideSlideBlock();
    //             }
    //         });
    //         // formData.append('ffp', ffp);
    //         // formData.append('exim_booking_pdf', this.state.bookingPDF);
    //         // formData.append('booking_id', this.state.bookingId);
    //         // if(this.state.isBookingPDFChanged) {
    //         //     redirectURL
    //         //         .post("/exim/saveFreightForwarderBookingAttchmnts", formData)
    //         //         .then((response) => {
    //         //         if (response.data.status === "success") {
    //         //             document.getElementById("bookingDoc").value = null;
    //         //             this.setState({
    //         //                 BookingId: "",
    //         //                 documentExtension: "",
    //         //             });
    //         //             this.hideSlideBlock();
    //         //         }
    //         //         });
    //         // }
    //     }else {
    //         this.setState({
    //           show: true,
    //           basicType: "danger",
    //           basicTitle: "Fields cannot be empty",
    //         });
    //     }    
    //     this.onLoadPageData();
    //     // //console.log(this.state.selectedRowRequirementId, "selectedRowRequirementId")

    //     // let payload = {
    //     //     requirement_id: this.state.selectedRowRequirementId,
    //     //     booking_id: this.state.bookingId,
    //     //     ffp: this.state.ffp
    //     // }
    //     // //console.log(payload, "payload for updating FFP")
    //     // await redirectURL
    //     // .post("/exim/updateBookingRecordFfp", payload)
    //     // .then((resp) => {
    //     //     //console.log(resp.data,"updatedBookingRecord with FFP")
    //     //   })
    //     // .catch((err) => {
    //     //     //console.log("updatedBookingRecord with FFP couldn't happen", err);
    //     // });


    //     // if(indexToRemove !== -1){

    //     // }


    //     // //console.log(this.state.rowData, "after submitting the updated rowData is")
    //     // var route = this.state.route;
    //     // var source = this.state.source;

    //     // var params = {
    //     //     route:route,
    //     //     source:source,
    //     // };
    //     // $(".othercost").each(function(){
    //     //     // //console.log("name ", $(this).attr("id"));
    //     //     var fild = $(this).attr("id");
    //     //     var fildval = $(this).val();
    //     //     // //console.log("value ", $(this).val());
    //     //     params[fild] = fildval;
    //     // });

    //     // //console.log("pp ", params)

    //     // redirectURL.post("/updatefreightrates",params)
    //     // .then((response) => {
    //     //     if(response.data.status === "success")
    //     //     {
    //     //         window.location.reload();
    //     //     }
    //     // })
    //     // redirectURL.post("/updatefreightratesrow",params)
    //     // .then((response) => {
    //     //     if(response.data.status === "success")
    //     //     {
    //     //         window.location.reload();
    //     //     }
    //     // })
    // } 
    // invoiceFormUpdateItem = async (e) => {
    //     e.preventDefault();
    //     var res = this.gridApi.updateRowData({ remove: [this.state.selectedRowInProcess] });
    //     let payload = {
    //         invoice_number: this.state.invoiceNumber,
    //         invoice_date: this.state.invoiceDate,
    //         booking_no: this.state.rowDataInProcess
    //     }
    //    await redirectURL
    //         .post("/consignments/onAssignedInvoice", payload)
    //         .then((response) => {
    //             if(response.status == 200){
    //                 //console.log(response.updatedData, "updatedRecordWithTransporterAssigned")
    //                 this.hideSlideBlock();
    //             }
    //         });
    // }

    onSubmitAddpartnerDetails = async (e) => {
        e.preventDefault();
        this.setState({
            showAddForm: "",
        })
        let uploadPartnerDetails = [];
        let partnerDetails = {}
        const now = new Date();
        // const partner_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // //console.log(partner_reference_id);
        // partnerDetails.partner_reference_id = partner_reference_id;
        partnerDetails.partner_name = this.state.newPartnerName;
        partnerDetails.partner_code = this.state.newPartnerCode;
        partnerDetails.partner_type = this.state.newPartnerType;
        partnerDetails.activity = this.state.newActivityValue;
        uploadPartnerDetails.push(partnerDetails);
        //console.log(uploadPartnerDetails, "uploadPartnerDetails");
        await redirectURL
            .post("/exim/addPartnerDetails", uploadPartnerDetails)
            .then(async (resp) => {
                //console.log(resp.data.status, "resp567")

                if (resp.data.status === "success") {
                    //console.log(resp.data.status, "resp567")
                    this.setState({
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newPartnerCode: "",
                        newPartnerName: "",
                        newPartnerType: "",
                        newActivityValue: ""
                    })
                    await this.onLoadPageDataForPartners();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
    onSubmitAddPlantDetails = async (e) => {
        e.preventDefault();
        this.setState({
            showAddForm: "",
        })
        let uploadPlantDetails = [];
        let plantDetails = {};
        // const now = new Date();
        // const plant_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // //console.log(plant_reference_id);
        // plantDetails.plant_reference_id = plant_reference_id;
        plantDetails.plant_name = this.state.newPlantName;
        plantDetails.plant_code = this.state.newPlantCode;
        plantDetails.plant_latitude = this.state.newPlantLatitude;
        plantDetails.plant_longitude = this.state.newPlantLongitude;
        uploadPlantDetails.push(plantDetails);
        //console.log(uploadPlantDetails, "uploadPlantDetails");
        await redirectURL
            .post("/exim/addPlantDetails", uploadPlantDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                       
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newPlantCode: "",
                        newPlantName: "",
                        newPlantLatitude: "",
                        newPlantLongitude: ""
                    })
                    await this.onLoadPageDataForPlantData();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
    onSubmitAddContainerYardDetails = async (e) => {
        e.preventDefault();
        let uploadContainerYardDetails = [];
        let containerYardDetails = {};
        // const now = new Date();
        // const container_yard_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // //console.log(container_yard_reference_id);
        // containerYardDetails.container_yard_reference_id = container_yard_reference_id;
        containerYardDetails.pickup_yard_name = this.state.newContainerYardName;
        containerYardDetails.pickup_yard_latitude = this.state.newContainerYardLatitude;
        containerYardDetails.pickup_yard_longitude = this.state.newContainerYardLongitude;
        uploadContainerYardDetails.push(containerYardDetails);
        //console.log(uploadContainerYardDetails, "uploadContainerYardDetails");
        await redirectURL
            .post("/exim/addContainerPickupYardDetails", uploadContainerYardDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                        showAddForm: "",
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newContainerYardName: "",
                        newContainerYardLatitude: "",
                        newContainerYardLongitude: ""
                    })
                    await this.onLoadPageDataForPickUpYard();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
    onSubmitAddParkingYardDetails = async (e) => {
        e.preventDefault();
        let uploadParkingPlazaDetails = [];
        let parkingPlazaDetails = {};
        // const now = new Date();
        // const parking_yard_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // //console.log(parking_yard_reference_id);
        // parkingPlazaDetails.parking_yard_reference_id = parking_yard_reference_id;
        parkingPlazaDetails.parking_plaza_name = this.state.newParkingPlazaName;
        parkingPlazaDetails.parking_plaza_latitude = this.state.newParkingPlazaLatitude;
        parkingPlazaDetails.parking_plaza_longitude = this.state.newParkingPlazaLongitude;
        uploadParkingPlazaDetails.push(parkingPlazaDetails);
        //console.log(uploadParkingPlazaDetails, "uploadParkingPlazaDetails");
        await redirectURL
            .post("/exim/addParkingYardDetails", uploadParkingPlazaDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                        showAddForm: "",
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newParkingPlazaName: "",
                        newParkingPlazaLatitude: "",
                        newParkingPlazaLongitude: ""
                    })
                    await this.onLoadPageDataForParkingYard();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
    onSubmitAddBufferYardDetails = async (e) => {
        e.preventDefault();
        let uploadBufferYardDetails = [];
        let bufferYardDetails = {};
        // const now = new Date();
        // const buffer_yard_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // //console.log(buffer_yard_reference_id);
        // bufferYardDetails.buffer_yard_reference_id = buffer_yard_reference_id;
        bufferYardDetails.buffer_yard_name = this.state.newBufferYardName;
        bufferYardDetails.buffer_yard_latitude = this.state.newBufferYardLatitude;
        bufferYardDetails.buffer_yard_longitude = this.state.newBufferYardLongitude;
        uploadBufferYardDetails.push(bufferYardDetails);
        //console.log(uploadBufferYardDetails, "uploadBufferYardDetails");
        await redirectURL
            .post("/exim/addBufferYardDetails", uploadBufferYardDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                        showAddForm: "",
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newBufferYardName: "",
                        newBufferYardLatitude: "",
                        newBufferYardLongitude: ""
                    })
                    await this.onLoadPageDataForBufferYard();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
    onSubmitAddPortGateDetails = async (e) => {
        e.preventDefault();
        let uploadPortGateDetails = [];
        let portGateDetails = {};
        // const now = new Date();
        // const portgate_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // //console.log(portgate_reference_id);
        // portGateDetails.portgate_reference_id = portgate_reference_id;
        portGateDetails.port_name = this.state.newPortName;
        portGateDetails.gate_name = this.state.newGateName;
        portGateDetails.port_gate_latitude = this.state.newPortGateLatitude;
        portGateDetails.port_gate_longitude = this.state.newPortGateLongitude;
        uploadPortGateDetails.push(portGateDetails);
        //console.log(uploadPortGateDetails, "uploadPortGateDetails");
        await redirectURL
            .post("/exim/addPortGateDetails", uploadPortGateDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                        showAddForm: "",
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newPortName: "",
                        newGateName: "",
                        newPortGateLatitude: "",
                        newPortGateLongitude: "",
                    })
                    await this.onLoadPageDataForPortGate();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
    onSubmitAddTransporterDetails = async (e) => {
        e.preventDefault();
        let uploadTransporterDetails = [];
        let transporterDetails = {};
        const now = new Date();
        const transporter_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        //console.log(transporter_reference_id);
        transporterDetails.transporter_reference_id = transporter_reference_id;
        transporterDetails.transporter_name = this.state.newTransporterName;
        transporterDetails.transporter_code = this.state.newTransporterCode;
        transporterDetails.transporter_plant_code = this.state.newTransporterPlantCode;
        transporterDetails.transporter_sob = this.state.newTransporterSOB;
        uploadTransporterDetails.push(transporterDetails);
        //console.log(uploadTransporterDetails, "uploadTransporterDetails");
        await redirectURL
            .post("/exim/addTransporterDetails", uploadTransporterDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                        showAddForm: "",
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newTransporterName: "",
                        newTransporterCode: "",
                        newTransporterPlantCode: "",
                        newTransporterSOB: ""
                    })
                    await this.onLoadPageDataForTransporter();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
    onSubmitAddholidayDetails = async (e) => {
        e.preventDefault();
        let uploadHolidayDetails = [];
        let holidayDetails = {};
        // // const now = new Date();
        // // const portgate_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // // //console.log(portgate_reference_id);
        // // portGateDetails.portgate_reference_id = portgate_reference_id;
        holidayDetails.holiday_date = this.state.holidayDate;
        holidayDetails.day = this.state.day;
        holidayDetails.plant_code = this.state.plantCode1;
        holidayDetails.plant_name = this.state.plantName1;
        uploadHolidayDetails.push(holidayDetails);
        //console.log(uploadHolidayDetails, "uploadHolidayDetails");
        await redirectURL
            .post("/exim/addHolidayDetails", uploadHolidayDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                        showAddForm: "",
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        holidayDate: "",
                        plantCode1: "",
                        day:'',
                        plantName1: "",
                        
                    })
                    await this.onLoadPageDataForHoliday();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }
   
    onSubmitAddTrucksDetails = async (e) => {
        e.preventDefault();
        let uploadTruckDetails = [];
        let truckDetails = {};
        const now = new Date();
        const truck_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        //console.log(truck_reference_id);
        truckDetails.truck_reference_id = truck_reference_id;
        truckDetails.truck_number = this.state.newTruckNo;
        truckDetails.truck_model = this.state.newTruckModel;
        truckDetails.transportercode_for_truck = this.state.newTransporterForTruck;
        truckDetails.container_type = this.state.newContainerType;
        truckDetails.start_date = this.state.startDate;
        truckDetails.end_date = this.state.endDate;
        uploadTruckDetails.push(truckDetails);
        //console.log(uploadTruckDetails, "uploadTruckDetails");
        await redirectURL
            .post("/exim/addTruckDetails", uploadTruckDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                        showAddForm: "",
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newTruckNo: '',
                        newTruckModel: "",
                        newTransporterForTruck: "",
                        newContainerType: "",
                        startDate: null,
                        endDate: null
                    })
                    await this.onLoadPageDataForTrucks();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }

    onSubmitAddRegionDetails = async (e) => {
        e.preventDefault();
        this.setState({
            showAddForm: "",
        })
        let uploadRegionDetails = [];
        let regionDetails = {};
        // const now = new Date();
        // const plant_reference_id = `${now.getTime()}${now.getMilliseconds()}`;
        // //console.log(plant_reference_id);
        // plantDetails.plant_reference_id = plant_reference_id;
        regionDetails.country_name = this.state.newCountryName;
        regionDetails.country_code = this.state.newCountryCode;
        regionDetails.region = this.state.newRegion;
        uploadRegionDetails.push(regionDetails);
        //console.log(uploadRegionDetails, "uploadRegionDetails");
        await redirectURL
            .post("/exim/addRegionDetails", uploadRegionDetails)
            .then(async (resp) => {
                if (resp.data.status === "success") {
                    this.setState({
                       
                        overly: "show-m",
                        basicType: "success",
                        basicTitle: resp.data.message,
                        show: true,
                        newCountryCode: "",
                        newCountryName: "",
                        newRegion: "",
                    })
                    await this.onLoadPageDataForRegionData();
                }
            })
            .catch((e) => {
                //console.log("error mssge is", e)
            })
    }



    onUpdatePartnerForm = async (e) => {
        e.preventDefault();
        let updatedPartnerCode = this.state.partnerCode;
        let updatedPartnerName = this.state.partnerName;
        let updatedPartnerType = this.state.partnerType;
        let updatedPartnerActivity = this.state.activityValue;
        // let partnerReferenceId = this.state.partnerReferenceId;
        let oldPartnerCode = this.state.oldPartnerCode
        let prevRowData = [...this.state.rowData];
        //console.log("oldcode",oldPartnerCode)
        let updatedPartnerData = {
            // partner_reference_id: partnerReferenceId,
            partner_code: updatedPartnerCode,
            partner_name: updatedPartnerName,
            partner_type: updatedPartnerType,
            activity: updatedPartnerActivity.value,
            oldPartnerCode:oldPartnerCode
        }
        //console.log(updatedPartnerData, "1757");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.partner_code === oldPartnerCode
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedPartnerData,
            };
        }
        //console.log('updatepartner', updatedPartnerData)
        await redirectURL
            .post("/exim/updatePartnerData", updatedPartnerData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "1772")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onUpdatePlantForm = async (e) => {
        e.preventDefault();
        let updatedPlantCode = this.state.plantCode;
        let updatedPlantName = this.state.plantName;
        let updatedLatitude = this.state.latitude;
        let updatedLongitude = this.state.longitude;
        // let plantReferenceId = this.state.plantReferenceId;
        let oldPlantCode = this.state.oldPlantCode
        let prevRowData = [...this.state.rowData];
        //console.log("oldcode",oldPlantCode)

        //console.log(prevRowData, "prevRowData")
        let updatedPlantData = {
            // plant_reference_id: plantReferenceId,
            plant_code: updatedPlantCode,
            plant_name: updatedPlantName,
            latitude: updatedLatitude,
            longitude: updatedLongitude,
            oldPlantCode:oldPlantCode
        }
        //console.log(updatedPlantData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.plant_code === oldPlantCode
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedPlantData,
            };
        }
        await redirectURL
            .post("/exim/updatePlantData", updatedPlantData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onUpdateContainerYardForm = async (e) => {
        e.preventDefault();
        let updatedContainerYardName = this.state.containerYardName;
        let updatedLatitude = this.state.containerYardLatitude;
        let updatedLongitude = this.state.containerYardLongitude;
        let oldContainerPickUpName = this.state.oldContainerPickUpName
        let prevRowData = [...this.state.rowData];
        //console.log(prevRowData, "prevRowData")
        let updatedContainerYardData = {
            // container_yard_reference_id: this.state.containerReferenceId,
            pickup_yard_name: updatedContainerYardName,
            latitude: updatedLatitude,
            longitude: updatedLongitude,
            oldContainerPickUpName: oldContainerPickUpName
        }
        //console.log(updatedContainerYardData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.pickup_yard_name === oldContainerPickUpName
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedContainerYardData,
            };
        }
        await redirectURL
            .post("/exim/updateContainerYardData", updatedContainerYardData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onUpdateParkingYardForm = async (e) => {
        e.preventDefault();
        let updatedParkingYardName = this.state.parkingPlazaName;
        let updatedLatitude = this.state.parkingPlazaLatitude;
        let updatedLongitude = this.state.parkingPlazaLongitude;
        let oldParkingPlaza = this.state.oldParkingPlaza
        let prevRowData = [...this.state.rowData];
        //console.log(prevRowData, "prevRowData")
        let updatedParkingYardData = {
            // parking_yard_reference_id: this.state.parkingYardReferenceId,
            parking_plaza_name: updatedParkingYardName,
            latitude: updatedLatitude,
            longitude: updatedLongitude,
            oldParkingPlaza:oldParkingPlaza
        }
        //console.log(updatedParkingYardData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.parking_plaza_name === oldParkingPlaza
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedParkingYardData,
            };
        }
        await redirectURL
            .post("/exim/updateParkingYardData", updatedParkingYardData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onUpdateBufferYardForm = async (e) => {
        e.preventDefault();
        let updatedBufferYardName = this.state.bufferYardName;
        let updatedLatitude = this.state.bufferYardLatitude;
        let updatedLongitude = this.state.bufferYardLongitude;
        let oldBufferYardName = this.state.oldBufferYardName
        let prevRowData = [...this.state.rowData];
        //console.log(prevRowData, "prevRowData")
        let updatedBufferYardData = {
            // buffer_yard_reference_id: this.state.bufferYardReferenceId,
            buffer_yard_name: updatedBufferYardName,
            latitude: updatedLatitude,
            longitude: updatedLongitude,
            oldBufferYardName:oldBufferYardName
        }
        //console.log(updatedBufferYardData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.buffer_yard_name=== oldBufferYardName
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedBufferYardData,
            };
        }
        await redirectURL
            .post("/exim/updateBufferYardData", updatedBufferYardData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onUpdatePortGateForm = async (e) => {
        e.preventDefault();
        let updatedPortName = this.state.portName;
        let updatedGateName = this.state.gateName;
        let updatedLatitude = this.state.portGateLatitude;
        let updatedLongitude = this.state.portGateLongitude;

        let oldPortName = this.state.oldPortName

        let prevRowData = [...this.state.rowData];
        //console.log(prevRowData, "prevRowData")
        let updatedPortGateData = {
            // portgate_reference_id: this.state.portGateReferenceId,
            port_name: updatedPortName,
            gate_name: updatedGateName,
            latitude: updatedLatitude,
            longitude: updatedLongitude,
            oldPortName: oldPortName
        }
        //console.log(updatedPortGateData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.port_name === oldPortName
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedPortGateData,
            };
        }
        await redirectURL
            .post("/exim/updatePortGateData", updatedPortGateData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    
    onUpdateTransporterForm = async (e) => {
        e.preventDefault();
        let updatedtransporterName = this.state.transporterName;
        let updatedtransporterCode = this.state.transporterCode;
        let updatedtransporterPlantCode = this.state.transporterPlantCode;
        let updatedSOB = this.state.transporterSOB;

        let prevRowData = [...this.state.rowData];
        //console.log(prevRowData, "prevRowData")
        let updatedTransporterData = {
            transporter_reference_id: this.state.transporterReferenceId,
            transporter_name: updatedtransporterName,
            transporter_code: updatedtransporterCode,
            plant_code: updatedtransporterPlantCode,
            sob: updatedSOB
        }
        //console.log(updatedTransporterData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.transporter_reference_id === updatedTransporterData.transporter_reference_id
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedTransporterData,
            };
        }
        await redirectURL
            .post("/exim/updateTransporterData", updatedTransporterData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onUpdateTruckForm = async (e) => {
        e.preventDefault();
        let updatedtruckNo = this.state.truckNo;
        let updatedtransporterForTruckCode = this.state.transporterForTruck;
        let updatedtruckModel = this.state.truckModel;
        let updatedContainerType = this.state.containerType;
        let updatedTruckValidFrom = this.state.startDate;
        let updatedTruckValidTll = this.state.endDate;

        let prevRowData = [...this.state.rowData];
        //console.log(prevRowData, "prevRowData")
        let updatedTruckData = {
            truck_reference_id: this.state.truckReferenceId,
            truck_number: updatedtruckNo,
            transportercode_for_truck: updatedtransporterForTruckCode,
            truck_model: updatedtruckModel,
            container_type: updatedContainerType,
            valid_till: updatedTruckValidTll,
            valid_from: updatedTruckValidFrom
        }
        //console.log(updatedTruckData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.truck_reference_id === updatedTruckData.truck_reference_id
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedTruckData,
            };
        }
        await redirectURL
            .post("/exim/updateTruckData", updatedTruckData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onUpdateRegionForm = async (e) => {
        e.preventDefault();
        let updatedCountryCode = this.state.countryCode;
        let updatedCountryName = this.state.countryName;
        let updatedRegion = this.state.regionName;
        
        // let plantReferenceId = this.state.plantReferenceId;
        let oldCountryCode = this.state.oldCountryCode
        let prevRowData = [...this.state.rowData];
        //console.log("oldcode",oldCountryCode)

        //console.log(prevRowData, "prevRowData")
        let updatedRegionData = {
            // plant_reference_id: plantReferenceId,
            country_code: updatedCountryCode,
            country_name: updatedCountryName,
            region: updatedRegion,
            oldCountryCode:oldCountryCode
        }
        //console.log(updatedRegionData, "2082");
        const indexOfTargetObject = prevRowData.findIndex(
            (item) => item.country_code === oldCountryCode
        );
        if (indexOfTargetObject !== -1) {
            prevRowData[indexOfTargetObject] = {
                ...prevRowData[indexOfTargetObject],
                ...updatedRegionData,
            };
        }
        await redirectURL
            .post("/exim/updateRegionData", updatedRegionData)
            .then((resp) => {
                if (resp.data.status === "success") {
                    //console.log(resp.data.updatedData, "2096")
                    this.setState({
                        rowData: resp.data.updatedData,
                        showEditForm: "",
                        overly: "show-n",
                    })
                }
            })
            .catch((e) => {
                //console.log("e.message", e.message)
            })
    }
    onChangeHandler = (e) => {
        const { name, value } = e.target;
        if (name === 'partnerName') {
            this.setState({ partnerName: value });
        } else if (name === 'partnerType') {
            this.setState({ partnerType: value });
        } else if (name === 'partnerCode') {
            this.setState({ partnerCode: value })
        } else if (name === 'newPartnerCode') {
            //console.log(value, "value567")
            this.setState({ newPartnerCode: value })

        } else if (name === 'newPartnerName') {
            this.setState({ newPartnerName: value })
        } else if (name === 'newPartnerType') {
            this.setState({ newPartnerType: value })
        } else if (name === "newPlantCode") {
            this.setState({
                newPlantCode: value,
            })
        } else if (name === "plantCode") {
            this.setState({
                plantCode: value
            })
        } else if (name === "plantName") {
            this.setState({
                plantName: value
            })
        } else if (name === "newPlantName") {
            this.setState({
                newPlantName: value,
            })
        } else if (name === "latitude") {
            this.setState({
                latitude: value
            })
        } else if (name === "newPlantLatitude") {
            this.setState({
                newPlantLatitude: value
            })
        } else if (name === "longitude") {
            this.setState({
                longitude: value
            })
        } else if (name === "newPlantLongitude") {
            this.setState({
                newPlantLongitude: value
            })
        } else if (name === "newContainerYardName") {
            this.setState({
                newContainerYardName: value
            })
        } else if (name === "containerYardName") {
            this.setState({
                containerYardName: value
            })
        } else if (name === "containerYardLatitude") {
            this.setState({
                containerYardLatitude: value
            })
        } else if (name === "newContainerYardLatitude") {
            this.setState({
                newContainerYardLatitude: value
            })
        } else if (name === "containerYardLongitude") {
            this.setState({
                containerYardLongitude: value
            })
        } else if (name === "newContainerYardLongitude") {
            this.setState({
                newContainerYardLongitude: value
            })
        } else if (name === "parkingPlazaName") {
            this.setState({
                parkingPlazaName: value
            })
        } else if (name === "newParkingPlazaName") {
            this.setState({
                newParkingPlazaName: value
            })
        } else if (name === "parkingPlazaLatitude") {
            this.setState({
                parkingPlazaLatitude: value
            })
        } else if (name === "newParkingPlazaLatitude") {
            this.setState({
                newParkingPlazaLatitude: value
            })
        } else if (name === "parkingPlazaLongitude") {
            this.setState({
                parkingPlazaLongitude: value
            })
        } else if (name === "newParkingPlazaLongitude") {
            this.setState({
                newParkingPlazaLongitude: value
            })
        } else if (name === "bufferYardName") {
            this.setState({
                bufferYardName: value
            })
        } else if (name === "newBufferYardName") {
            this.setState({
                newBufferYardName: value
            })
        } else if (name === "bufferYardLatitude") {
            this.setState({
                bufferYardLatitude: value
            })
        } else if (name === "newBufferYardLatitude") {
            this.setState({
                newBufferYardLatitude: value
            })
        } else if (name === "bufferYardLongitude") {
            this.setState({
                bufferYardLongitude: value
            })
        } else if (name === "newBufferYardLongitude") {
            this.setState({
                newBufferYardLongitude: value
            })
        } else if (name === "portName") {
            this.setState({
                portName: value
            })
        } else if (name === "newPortName") {
            this.setState({
                newPortName: value
            })
        } else if (name === "gateName") {
            this.setState({
                gateName: value
            })
        } else if (name === "newGateName") {
            this.setState({
                newGateName: value
            })
        } else if (name === "portGateLatitude") {
            this.setState({
                portGateLatitude: value
            })
        } else if (name === "newPortGateLatitude") {
            this.setState({
                newPortGateLatitude: value
            })
        } else if (name === "portGateLongitude") {
            this.setState({
                portGateLongitude: value
            })
        } else if (name === "newPortGateLongitude") {
            this.setState({
                newPortGateLongitude: value
            })
        } else if (name === "transporterName") {
            this.setState({
                transporterName: value
            })
        } else if (name === "newTransporterName") {
            this.setState({
                newTransporterName: value
            })
        } else if (name === "transporterCode") {
            this.setState({
                transporterCode: value
            })
        } else if (name === "newTransporterCode") {
            this.setState({
                newTransporterCode: value
            })
        } else if (name === "transporterPlantCode") {
            this.setState({
                transporterPlantCode: value
            })
        } else if (name === "newTransporterPlantCode") {
            this.setState({
                newTransporterPlantCode: value
            })
        } else if (name === "transporterSOB") {
            this.setState({
                transporterSOB: value
            })
        } else if (name === "newTransporterSOB") {
            this.setState({
                newTransporterSOB: value
            })
        } else if (name === "truckNo") {
            this.setState({
                truckNo: value
            })
        } else if (name === "newTruckNo") {
            this.setState({
                newTruckNo: value
            })
        } else if (name === "transportForTruck") {
            this.setState({
                transporterForTruck: value
            })
        } else if (name === "newTransporterForTruck") {
            this.setState({
                newTransporterForTruck: value
            })
        } else if (name === "truckModel") {
            this.setState({
                truckModel: value
            })
        } else if (name === "newTruckModel") {
            this.setState({
                newTruckModel: value
            })
        } else if (name === "containerType") {
            this.setState({
                containerType: value
            })
        } else if (name === "newContainerType") {
            this.setState({
                newContainerType: value
            })
        } else if (name === "truckValidTill") {
            this.setState({
                truckValidTill: value
            })
        } else if (name === "newTruckValidTill") {
            this.setState({
                newTruckValidTill: value
            })
        } else if (name === "truckValidFrom") {
            this.setState({
                truckValidFrom: value
            })
        } else if (name === "newTruckValidFrom") {
            this.setState({
                newTruckValidFrom: value
            })
        }
        else if (name === "plantName1"){
            this.setState({
                plantName1: value
            })
        }  else if (name === "day"){
            this.setState({
                day: value
            })
        }
       
        else if (name === "plantCode1"){
            this.setState({
                plantCode1: value
            })
        }
        else if (name === "newCountryCode") {
            this.setState({
                newCountryCode: value,
            })
        } else if (name === "countryCode") {
            this.setState({
                countryCode: value
            })
        } else if (name === "countryName") {
            this.setState({
                countryName: value
            })
        } else if (name === "newCountryName") {
            this.setState({
                newCountryName: value,
            })
        } else if (name === "regionName") {
            this.setState({
                regionName: value
            })
        } else if (name === "newRegion") {
            this.setState({
                newRegion: value,
            })
        }
      
    }
   
    handlerStartDateTime = (event) => {

        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        if (this.state.endDate) {
            //console.log(startdate, "startdate")
            //console.log(this.state.endDate, "endDate")
            var startDate = new Date(startdate);
            var endDate = new Date(this.state.endDate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // //console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    startDate: this.state.startDate,
                    show: true,
                    basicType: "danger",
                    basicTitle: "From Date shouldn't be greater than To Date"
                });
            }
            else {
                this.setState({
                    startDate: startdate
                });
            }
        }
        else {
            this.setState({
                startDate: startdate
            });
        }
        //console.log("startDate", this.state.startDate)

    }
    // handlerValidFrom = (event) => {
    //     var d = new Date(event._d);
    //     var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    //     if (this.state.truckValidTill) {
    //         //console.log(startdate,"startdate")
    //         //console.log(this.state.truckValidTill,"endDate")
    //         var startDate = new Date(startdate);
    //         var endDate = new Date(this.state.truckValidTill);
    //         var startTime = startDate.getTime();
    //         var endTime = endDate.getTime();
    //         // //console.log(startTime,endTime);
    //         if (startTime > endTime) {
    //             this.setState({
    //                 truckValidFrom: startDate,
    //                 show: true,
    //                 basicType: "danger",
    //                 basicTitle: "From Date shouldn't be greater than To Date"
    //             });
    //         }
    //         else {
    //             this.setState({
    //                 truckValidFrom: startdate
    //             });
    //         }
    //     }
    //     else {
    //         this.setState({
    //             truckValidFrom: startdate
    //         });
    //     }
    //     //console.log("truckValidFrom", this.state.truckValidFrom)
    // }
    // handlerValidTill = (event) => {

    // }
    onclickTab = (e) => {
        if (e == 'Plant1') {
            this.setState({
                plant1: 1,
                plant2: 0,
                plant3: 0
            })
        }
        else if (e == 'Plant2') {
            this.setState({
                plant1: 0,
                plant2: 1,
                plant3: 0
            })
        }
        else if (e == 'Plant3') {
            this.setState({
                plant1: 0,
                plant2: 0,
                plant3: 3
            })
        }
    }
    handleDateChangeyear = (e) => {
        this.setState({
            selectYear: e,
            selected_financial_year: e.value
        })
        this.onLoadPageDataForTarget(e.value)
    }
    handleKeyDown = (e) => {
        e.preventDefault(); // Prevent any keyboard input
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        })
        // setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        //console.log(formattedDate, 'formattedDate')
        this.setState({
            datefilter: formattedDate
        })
        // setfilters(prev => ({
        //     ...prev,
        //     datefilter: formattedDate
        // }))
        this.getData(formattedDate)
    };

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // //console.log(this.state.startDate,"startdate")
            // //console.log(edate,"endDate")
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(edate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // //console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    endDate: this.state.endDate,
                    show: true,
                    basicType: "danger",
                    basicTitle: "To Date shouldn't be lesser than From Date"
                });
            }
            else {
                this.setState({
                    endDate: edate
                });
            }
        }
        else {
            this.setState({
                endDate: edate
            });
        }
        //console.log("endDate", this.state.endDate)
    }

    newFilter = (filterType, selOption) => {
        this.setState({ [filterType]: selOption });
    };


    render() {

        var { holidayTabs, holidayData, groupdata, file, csvcontent } = this.state;
        var inProcessColumnDefs = [
            {
                headerName: "Legs",
                field: "_id",
                width: 70,
                filter: false,
                floatingFilter: false,
                cellRendererFramework: (params) => {
                    {
                        return <a style={{ fontSize: "20px", fontWeight: "bold", cursor: "pointer" }}
                            onClick={this.onShowTicketHistory.bind(this, params)}><i className='icofont icofont-rounded-expand'></i></a>
                    }
                }
            },
            {
                headerName: "Update Invoice",
                field: "",
                width: 100,
                filter: true,
                resizable: true,
                cellRendererSelector: function (params) {
                    return {
                        component: "UpdateInvoice"
                    }
                }
            },
            {
                headerName: "Transporter",
                field: "transporter_assigned",
                width: 130,
                filter: true,
                resizable: true,
            },
        ]
        var operationsColumnDefs = [
            {
                headerName: "",
                field: "_id",
                width: 90,
                cellRendererFramework: EditPartner
                // suppressSizeToFit: true,
            },
            {
                headerName: "",
                field: "_id",
                width: 50,
                cellRendererFramework: DeletePartner
                // suppressSizeToFit: true,
            },
        ]
        var coordinates = [
            {
                headerName: "Latitude",
                field: "latitude",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Longitude",
                field: "longitude",
                width: 130,
                filter: true,
                resizable: true,
            }
        ]
        var partnerColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Partner Code",
                field: "partner_code",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Partner Name",
                field: "partner_name",
                width: 230,
                filter: true,
                resizable: true,
                headerClass: { backgroundColor: 'lightblue' }
            },
            {
                headerName: "Partner Type",
                field: "partner_type",
                width: 170,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Activity",
                field: "activity",
                width: 150,
                filter: true,
                resizable: true,
            },
        ]
        var plantColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Plant Code",
                field: "plant_code",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Plant Name",
                field: "plant_name",
                width: 150,
                filter: true,
                resizable: true,
            }, ...coordinates
        ]
        var pickUpYardColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Container Pick Up Yard Name",
                field: "pickup_yard_name",
                width: 230,
                filter: true,
                resizable: true,
            }, ...coordinates
        ]
        var parkingPlazaColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Parking Plaza Name",
                field: "parking_plaza_name",
                width: 170,
                filter: true,
                resizable: true,
            }, ...coordinates
        ]
        var bufferyardColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Buffer Yard Name",
                field: "buffer_yard_name",
                width: 190,
                filter: true,
                resizable: true,
            }, ...coordinates
        ]
        var month_outlook = [
            {
                headerName: "Plant",
                field: "plant",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Country Code",
                field: "country_code",
                width: 130,
                filter: true,
                resizable: true,
                // editable:true
            },
            {
                headerName: "SKU",
                field: "sku",
                width: 130,
                filter: true,
                resizable: true,
                // editable:true
            },
            {
                headerName: "Region",
                field: "region",
                width: 130,
                filter: true,
                resizable: true,
                // editable:true
            },
            {
                headerName: "Quantity",
                field: "qty",
                width: 130,
                filter: true,
                resizable: true,
                // editable:true
            },
        ]
        var target_achievment = [
            {
                headerName: "",
                field: "report_date",
                headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
                width: 100,
                filter: true,
                resizable: true,
                children: [
                    {
                        headerName: "Month",
                        headerClass: ["cellstylegrid"],
                        field: 'report_date',
                        width: 100,
                        cellClass: (params) => {
                            if (params.data && params.data.report_date) {
                                const currentWeekDates = getCurrentWeekDates();
                                if (currentWeekDates.includes(params.data.report_date)) {
                                    return 'cellHighlight'; // Apply the cellHighlight class for styling
                                }
                            }
                            return ''; // Return empty string for default styling
                        },
                        // editable: true
                    },
                ]
            }
        ]
        var regionColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Country Code",
                field: "country_code",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Country Name",
                field: "country_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Region",
                field: "region",
                width: 150,
                filter: true,
                resizable: true,
            },
        ]
        const keys = [...groupdata.keys()];
        // monthdates.forEach(function (report_date) {
        keys.forEach(function (key) {
            var row = groupdata[key];
            var keyWithoutSpaces = key.trim().replace(/ /g, "");
            //console.log(key, "8999")
            var columnDefinition = {
                headerName: key,
                field: '',
                width: 240,
                // headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
                children: [
                    {
                        headerName: "Estimated",
                        headerClass: ["cellstylegrid"],
                        field: keyWithoutSpaces + "_estimated",
                        width: 220,
                        editable: (params) => {
                            const currentDate = new Date();
                            const currentMonth = currentDate.toLocaleString('default', { month: 'short' }) + '-' + String(currentDate.getFullYear()).slice(2);
                            const nextMonthDate = new Date();
                            nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
                            const nextMonth = nextMonthDate.toLocaleString('default', { month: 'short' }) + '-' + String(nextMonthDate.getFullYear()).slice(2);
                            if (params.data.report_date == currentMonth || params.data.report_date == nextMonth) {
                                return true
                            }
                            return false;
                        },
                        valueSetter: function (params) {
                            //console.log(params, '225')
                            params.data[keyWithoutSpaces + "_estimated"] = params.newValue
                        },
                        valueGetter: function (params) {
                            if (params.data && params.data[keyWithoutSpaces + "_estimated"] !== undefined) {
                                return params.data[keyWithoutSpaces + "_estimated"]
                            }
                            return null;
                        }
                    },
                    // {
                    //     headerName: "Achieved",
                    //     headerClass: ["cellstylegrid"],
                    //     field: keyWithoutSpaces + "_achieved",
                    //     width: 100,
                    //     valueGetter:function(params){
                    //         if(params.data && params.data[keyWithoutSpaces+"_achieved"]!=undefined){
                    //             return params.data[keyWithoutSpaces + "_achieved"]
                    //         }
                    //         return null;
                    //     }
                    // },
                    // {
                    //     headerName: "Achieved %",
                    //     headerClass: ["cellstylegrid"],
                    //     field: keyWithoutSpaces + "_achieved_percent",
                    //     width: 100,
                    //     valueGetter:function(params){
                    //         if(params.data&&params.data[keyWithoutSpaces+"_achieved_percent"]!=undefined){
                    //             return params.data[keyWithoutSpaces + "_achieved_percent"]
                    //         }
                    //         return null;
                    //     }
                    // },
                    // {
                    //     headerName: "Allocated",
                    //     headerClass: ["cellstylegrid"],
                    //     field: keyWithoutSpaces + "_allocated",
                    //     width: 100,
                    //     //  hide:true,
                    //     valueGetter:function(params){
                    //         if(params.data&&params.data[keyWithoutSpaces+"_allocated"]!=undefined){
                    //             return params.data[keyWithoutSpaces + "_allocated"]
                    //         }
                    //         return null;
                    //     }
                    // },
                    // {
                    //     headerName: "Mark Availability",
                    //     headerClass: ["cellstylegrid"],
                    //     field: keyWithoutSpaces + "_mark_availability",
                    //     width: 100,
                    //     //  hide:true,
                    //     valueGetter:function(params){
                    //         if(params.data&&params.data[keyWithoutSpaces+"_mark_availability"]!=undefined){
                    //             return params.data[keyWithoutSpaces + "_mark_availability"]
                    //         }
                    //         return null;
                    //     }
                    // },
                ]
            };
            //console.log(columnDefinition, 'columnDefinitioncolumnDefinition')

            // Push the column definition for each combination of key and report_date
            target_achievment.push(columnDefinition);
        });
        var portGateColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Port Name",
                field: "port_name",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Gate Name",
                field: "gate_name",
                width: 130,
                filter: true,
                resizable: true,
            }, ...coordinates
        ]
        var transporterColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 170,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Plant Code",
                field: "plant_code",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "SOB",
                field: "sob",
                width: 130,
                filter: true,
                resizable: true,
            },
        ]
        var trucksColumnDefs = [
            ...operationsColumnDefs,
            {
                headerName: "Truck Number",
                field: "truck_no",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 170,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Truck Model",
                field: "truck_model",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Valid From",
                field: "valid_from",
                width: 130,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.valid_from != '' && params.data.valid_from != undefined) {
                            return getHyphenDDMMYYYY(params.data.valid_from);
                        }
                    }
                    catch (e) {
                    }
                }
            },
            {
                headerName: "Valid To",
                field: "valid_to",
                width: 130,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.valid_till != '' && params.data.valid_till != undefined) {
                            return getHyphenDDMMYYYY(params.data.valid_till);
                        }
                    }
                    catch (e) {
                    }
                }
            }
        ]
        var holidayColumnDefs = [
          
            {
                headerName: "Holiday Date",
                field: "holiday_date",
                width: 130,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.holiday_date != '' && params.data.holiday_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.holiday_date);
                        }
                    }
                    catch (e) {
                    }
                }
            },
            {
                headerName: "Day",
                field: "day",
                width: 170,
                filter: true,
                resizable: true,
            },
           
            {
                headerName: "Plant Name",
                field: "plant_name",
                width: 130,
                filter: true,
                resizable: true,

            },
            {
                headerName: "Plant Code",
                field: "plant_code",
                width: 130,
                filter: true,
                resizable: true,

            },

        ];
        var monthColumnDefs = [];

        if (this.state.partnerBtn === "activet1") {
            var gridColumnDefs = partnerColumnDefs;
        } else if (this.state.plantBtn === "activet1") {
            var gridColumnDefs = plantColumnDefs;
        } else if (this.state.pickupYardBtn === "activet1") {
            var gridColumnDefs = pickUpYardColumnDefs;
        } else if (this.state.parkingPlazaBtn === "activet1") {
            var gridColumnDefs = parkingPlazaColumnDefs;
        } else if (this.state.bufferYardBtn === "activet1") {
            var gridColumnDefs = bufferyardColumnDefs;
        } else if (this.state.portGateBtn === "activet1") {
            var gridColumnDefs = portGateColumnDefs;
        } else if (this.state.transporterBtn === "activet1") {
            var gridColumnDefs = transporterColumnDefs;
        } else if (this.state.truckmasterBtn === "activet1") {
            var gridColumnDefs = trucksColumnDefs;
        } else if (this.state.holidayCalendar === "activet1") {
            var gridColumnDefs = holidayColumnDefs;
        } else if (this.state.monthOutlook === "activet1") {
            var gridColumnDefs = month_outlook;
        }
        else if (this.state.targetEstimator === "activet1") {
            var gridColumnDefs = target_achievment;
        }
        else if (this.state.regionMaster === "activet1") {
            var gridColumnDefs = regionColumnDefs;
        }



        function restrictToNumbers(event) {
            //console.log("latitude", event.target.value)

            event.target.value = event.target.value.replace(/[^0-9.]/g, '');

            event.target.value = event.target.value.replace(/^0+(\d)/, '$1');

            event.target.value = event.target.value.replace(/(\..*)\./, '$1');

            event.target.value = event.target.value.replace(/^0+(\d)/, '$1');

            event.target.value = event.target.value.replace(/^(\d+)\.(\d+)\..*$/, '$1.$2');
        }
        const changeOrderFileHandlerFcrBulk = async (e) => {
            try {
                const fieldNames = ['country_code', 'sku', 'plant', 'qty', 'region'];
                //console.log('3918')
                const isValidHeader = await validateCSVHeader(e.target.files[0], fieldNames);
                //console.log(isValidHeader,'isValidHeader')
                if (!isValidHeader) {
                    console.error("Invalid CSV header");
                    this.setState({
                         show: true,
                         basicType: "danger",
                         basicTitle: "Please Select Valid File"
                        });
                    return;
                }
                else{
                    this.setState({ file: e.target.files[0] })
                    //console.log('3932')
                const csvdata = await BulkForceFileHandler(e, [
                    { name: 'Country', inputName: 'country_code', required: true },
                    { name: 'SKU', inputName: 'sku', required: true },
                    { name: 'Plant', inputName: 'plant', required: true },
                    { name: 'Quantity', inputName: 'qty', required: true },
                    { name: 'Region', inputName: 'region', required: true },
                ]);
                this.setState({
                    csvcontent: csvdata
                });
                }
            } catch (error) {
                console.error("Error processing file:", error);
            }
        };
        const onclickbulkupload = () => {
            //console.log('3898')
            this.setState({
                setsliderForBulkupload: "slider-translate",
                overlay: 'show-m'
            })

        }
        const onClickHideAll = () => {
            this.setState({
                setsliderForBulkupload: "",
                overlay: 'show-n'
            })
            document.getElementById("uploadfile").value = null
        }

        const onSubmitOrderBulkUpload = async (event) => {
            event.preventDefault();
            this.setState({ loadshow: 'show-m', overlay:'show-n' })
            if (file != '') {
                const dateString = this.state.datefilter;

                // Split the string into year and month parts
                // const [year, month] = dateString.split("-");

                // Create a Date object
                // Note: Months in JavaScript Date objects are zero-indexed (0 for January, 1 for February, etc.)
                //console.log(dateString, "dateString")
                // const dateObject = new Date(dateString);
                // var initialDate =`${dateObject.getFullYear()}-${String(dateObject.getMonth() + 1).padStart(2, '0')}`
                // //console.log(initialDate, "date67")
                var rparams = {
                    // uploadFile: file,
                    csvcontent: csvcontent,
                    month: dateString
                }
                //console.log(rparams, 'rparams')
                await redirectURL.post('/consignments/getdatafromblukupload', rparams).then((resp) => {
                    //console.log(resp.data, "selected_month")
                    if (resp.data.status === 'Success') {
                        document.getElementById("uploadfile").value = null

                        // const formattedDate = convertDateFormat(initialDate);
                        // //console.log(formattedDate, "formattedDate")
                        let params = {
                            month: dateString
                        }
                        //console.log(params, "month_front")
                        redirectURL.post('/consignments/getplantmonthoutlook', params).then((resp) => {
                            var records = resp.data;
                            this.setState({
                                setsliderForBulkupload: '',
                                rowData: records
                            })
                        })
                    }
                })
            }
        }
        const onclickTab = (e) => {
            if (e == 'Chakan') {
                this.setState({
                    plant1: 1,
                    plant2: 0,
                    plant3: 0,
                    rowData: holidayData.filter((item) => item.plant_name == "Chakan")
                })
            }

            else if (e == 'Pantnagar') {
                this.setState({
                    plant1: 0,
                    plant2: 1,
                    plant3: 0,
                    rowData: holidayData.filter((item) => item.plant_name == "Pantnagar")

                })
            }
            else if (e == 'Waluj') {
                this.setState({
                    plant1: 0,
                    plant2: 0,
                    plant3: 1,
                    rowData: holidayData.filter((item) => item.plant_name == "Waluj")

                })
            }
        }
        //console.log(gridColumnDefs, 'gridColumnDefs')

        return (
            <>
                <div>
                    <h5 style={{ marginLeft: '40px', marginTop: "25px", marginBottom: "15px" }} className='heading'>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Master Data
                    </h5>
                </div>
                <div className='ag-theme-balham' style={{ padding: "20px", marginBottom: '35px', marginLeft: '35px', marginRight: '35px', boxShadow: "0 1px 9px 0 rgb(0 0 0 / 25%)" }}>


                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={this.closeAlert}
                    >
                    </SweetAlert>
                    <div style={{ marginBottom: "10px" }}>
                        <ul className="d-tabs" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <li onClick={this.onClickTab.bind(this, 1)} className={"lttabs2 " + (this.state.partnerBtn)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }} >
                                Partner Master
                            </li>
                            <li onClick={this.onClickTab.bind(this, 2)} className={"lttabs2 " + (this.state.plantBtn)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Plant Master
                            </li>
                            <li onClick={this.onClickTab.bind(this, 3)} className={"lttabs2 " + (this.state.pickupYardBtn)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Container Pickup Yard Master
                            </li>
                            <li onClick={this.onClickTab.bind(this, 4)} className={"lttabs2 " + (this.state.parkingPlazaBtn)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Parking Plaza Master
                            </li>
                            <li onClick={this.onClickTab.bind(this, 5)} className={"lttabs2 " + (this.state.bufferYardBtn)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Buffer Yard Master
                            </li>
                            <li onClick={this.onClickTab.bind(this, 6)} className={"lttabs2 " + (this.state.portGateBtn)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Port Gate Master
                            </li>
                            <li onClick={this.onClickTab.bind(this, 9)} className={"lttabs2 " + (this.state.holidayCalendar)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Holiday Calendar
                            </li>
                            <li onClick={this.onClickTab.bind(this, 10)} className={"lttabs2 " + (this.state.monthOutlook)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Month Outlook
                            </li>
                            <li onClick={this.onClickTab.bind(this, 11)} className={"lttabs2 " + (this.state.targetEstimator)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                TPT Monthly Target
                            </li>
                            <li onClick={this.onClickTab.bind(this, 12)} className={"lttabs2 " + (this.state.regionMaster)} style={{ padding: '12px 15px', cursor: "pointer", minWidth: '100px' }}>
                                Region Master
                            </li>
                        </ul>
                        <div className=''>
                            <div style={{ display: "flex", justifyContent: "right", marginBottom: "20px" }}>

                                {this.state.displayDateFilter === "true" && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px' }}>
                                        {/* DatePicker Container */}
                                        <div id="picker">
                                            <DatePicker
                                                selected={this.state.selectedDate}
                                                onChange={this.handleDateChange}
                                                dateFormat="MM/yyyy" // Format to display only month and year
                                                showMonthYearPicker
                                                minDate={new Date(1900, 0)} // Set the minimum year you want to allow
                                                maxDate={new Date(2100, 11)}
                                                className="custom-datepicker1"
                                                onKeyDown={this.handleKeyDown}
                                                calendarClassName="custom-datepicker-dropdown"
                                                
                                            />
                                        </div>

                                        {/* Bulk Upload Button */}
                                        <div className="form-group" style={{ marginLeft: '5px' }}>
                                            <button type="button" className="btn-bulkupload" style={{ fontSize: "0.8rem", height: "30px" }} onClick={onclickbulkupload}>
                                                <img src={require("../../assets/images/upload.png")} alt="Bulk Upload Icon" className="mr-1" />
                                                Bulk Upload
                                            </button>
                                        </div>
                                    </div>
                                )}


                                {this.state.selectDropDown === 'true' ?
                                    <Select
                                        closeMenuOnSelect={true}
                                        name=""
                                        className="border-radius-0"
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                fontFamily: 'Inter',
                                                fontSize: "0.8rem",
                                                color: "#6e707e",
                                                borderRadius: "11px",
                                                height: "40px",
                                                width: "160px",
                                                marginTop: "0px",
                                                textAlign: "center",
                                                placeholder: "Select...",
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                fontSize: '0.9rem'
                                            })
                                        }}
                                        options={this.state.years}
                                        value={this.state.selectYear}
                                        onChange={(e) => { this.handleDateChangeyear(e) }}
                                        required
                                    /> : ''}
                            </div>

                        </div>
                        <div style={{ position: "relative" }}>
                            <div style={{ position: "absolute", right: "0px", top: "-20px" }}>
                                {this.state.displayexportSlider == 'true' ? <button className='btn_hover5' style={{ marginRight: "10px" }} onClick={(e) => { this.onExportData(e, this.state.oval) }}>Export Data</button> : ''}
                                {this.state.displaySlider == "true" ? <button className="btn_hover5" onClick={(e) => { this.onAddData(e, this.state.oval) }}>Add Data</button> : ''}
                            </div>
                        </div>
                        {holidayTabs === "active" &&
                            <div className="d-flex flex-row" >
                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: this.state.plant1 == 1 ? '#0664AE' : '#FFFFFF',
                                        // color: state.isTileView ? "": "#0664AE",
                                        borderTopLeftRadius: "11px",
                                        borderBottomLeftRadius: "11px",
                                        border: "1px solid #0664AE",
                                        fontFamily: "Inter",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('Chakan')}
                                >
                                    <div
                                        style={{
                                            color: this.state.plant1 == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            // marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        Chakan
                                    </div>
                                </button>

                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: this.state.plant2 == 1 ? '#0664AE' : "#FFFFFF",
                                        border: "1px solid #0664AE",
                                        fontFamily: "Inter",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('Pantnagar')}
                                >
                                    <div
                                        style={{
                                            color: this.state.plant2 == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            // marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        Pantnagar
                                    </div>
                                </button>
                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: this.state.plant3 == 1 ? '#0664AE' : "#FFFFFF",
                                        borderTopRightRadius: "11px",
                                        borderBottomRightRadius: "11px",
                                        border: "1px solid #0664AE",
                                        fontFamily: "Inter",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('Waluj')}
                                >
                                    <div
                                        style={{
                                            color: this.state.plant3 == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            // marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        Waluj
                                    </div>
                                </button>
                            </div>}

                    </div>
                    {(this.state.targetEstimator == 'activet1') && <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={gridColumnDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            rowData={this.state.rowData}
                            suppressRowClickSelection={true}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                },
                                context: { componentParent: this }
                            }}
                            context={{}}
                            onCellEditingStopped={this.onCellUpdateData1.bind(this)}
                        //  getRowStyle={this.getRowStyle}   
                        />

                    </div>}
                    {(this.state.targetEstimator != 'activet1') &&
                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham " + (this.state.gridMargin)}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={gridColumnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                masterDetail={true}
                                enableCharts={false}
                                // rowHeight={32}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={{
                                    EditPartner: EditPartner,
                                    DeletePartner: DeletePartner
                                }}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    icons: {
                                        // You can customize other icons as well
                                        next: `<img src="${customNextIcon}" alt="Next" />`,
                                        previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                        first: `<img src="${customFirstIcon}" alt="First" />`,
                                        last: `<img src="${customLastIcon}" alt="Last" />`,
                                    },
                                    context: { componentParent: this }
                                }}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                enableRangeSelection={true}
                                onCellClicked={this.viewDetails.bind(this)}
                                suppressRowClickSelection={true}
                                onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>}
                    <div className={"overlay-part " + (this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
                    <div className={"sliderBlock2 " + (this.state.showEditForm)}>
                        <div className="slide-r-title slider_title_style">
                            <h6 style={{ fontSize: "16px", fontFamily: "Inter" }}>Edit {this.state.enabledSlider} Details</h6>
                            <span className="float-right ffclosebtn" style={{ marginRight: "25px", fontSize: '20px' }} onClick={this.hideSlideBlock.bind(this)} >X</span>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                {this.state.enabledSlider === "Partner" ?
                                    <form onSubmit={this.onUpdatePartnerForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Update Partner Code<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="partnerCode"
                                                        id="inputcode"
                                                        value={this.state.partnerCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Update Partner Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="partnerName"
                                                        id="inputcode"
                                                        value={this.state.partnerName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Partner Type<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="partnerType"
                                                        id="inputcode"
                                                        value={this.state.partnerType}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Change Activity Status: </label>
                                                    <Select
                                                        placeholder={"Activity"}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.activityValue}
                                                        // multi={true}
                                                        // className={"border-radius-0"} 
                                                        onChange={this.newFilter.bind(this, "activityValue")}
                                                        style={{ borderRadius: "0px" }}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: "0.8rem",
                                                                color: "#6e707e",
                                                            }),
                                                        }}
                                                        options={this.state.activityOptions}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enabledSlider === "Plant" ?
                                    <form onSubmit={this.onUpdatePlantForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Plant Code<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="plantCode"
                                                        id="inputcode"
                                                        value={this.state.plantCode}
                                                        className="form-control"
                                                        // onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Plant Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="plantName"
                                                        id="inputcode"
                                                        value={this.state.plantName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="latitude"
                                                        id="inputcode"
                                                        value={this.state.latitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="longitude"
                                                        id="inputcode"
                                                        value={this.state.longitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enabledSlider === "Container Pickup Yard" ?
                                    <form onSubmit={this.onUpdateContainerYardForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Container Pickup Yard Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="containerYardName"
                                                        id="inputcode"
                                                        value={this.state.containerYardName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="containerYardLatitude"
                                                        id="inputcode"
                                                        value={this.state.containerYardLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="containerYardLongitude"
                                                        id="inputcode"
                                                        value={this.state.containerYardLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enabledSlider === "Parking Yard" ?
                                    <form onSubmit={this.onUpdateParkingYardForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Parking Yard Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="parkingPlazaName"
                                                        id="inputcode"
                                                        value={this.state.parkingPlazaName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="parkingPlazaLatitude"
                                                        id="inputcode"
                                                        value={this.state.parkingPlazaLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="parkingPlazaLongitude"
                                                        id="inputcode"
                                                        value={this.state.parkingPlazaLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enabledSlider === "Buffer Yard" ?
                                    <form onSubmit={this.onUpdateBufferYardForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Buffer Yard Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="bufferYardName"
                                                        id="inputcode"
                                                        value={this.state.bufferYardName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="bufferYardLatitude"
                                                        id="inputcode"
                                                        value={this.state.bufferYardLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="bufferYardLongitude"
                                                        id="inputcode"
                                                        value={this.state.bufferYardLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enabledSlider === "Port Gate" ?
                                    <form onSubmit={this.onUpdatePortGateForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Port Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="portName"
                                                        id="inputcode"
                                                        value={this.state.portName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Gate Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="gateName"
                                                        id="inputcode"
                                                        value={this.state.gateName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="portGateLatitude"
                                                        id="inputcode"
                                                        value={this.state.portGateLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="portGateLongitude"
                                                        id="inputcode"
                                                        value={this.state.portGateLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                               
                                {this.state.enabledSlider === "Transporter" ?
                                    <form onSubmit={this.onUpdateTransporterForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Transporter Name<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="transporterName"
                                                        id="inputcode"
                                                        value={this.state.transporterName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Transporter Code<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="transporterCode"
                                                        id="inputcode"
                                                        value={this.state.transporterCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Plant Code<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="transporterPlantCode"
                                                        id="inputcode"
                                                        value={this.state.transporterPlantCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update SOB<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="transporterSOB"
                                                        id="inputcode"
                                                        value={this.state.transporterSOB}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enabledSlider === "Trucks" ?
                                    <form onSubmit={this.onUpdateTruckForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Truck Number<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="truckNo"
                                                        id="inputcode"
                                                        value={this.state.truckNo}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Transporter Code<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="transporterForTruck"
                                                        id="inputcode"
                                                        value={this.state.transporterForTruck}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Truck Model<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="truckModel"
                                                        id="inputcode"
                                                        value={this.state.truckModel}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Container Type<span className="rclr fbold">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="containerType"
                                                        id="inputcode"
                                                        value={this.state.containerType}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Valid From<span className="rclr fbold">*</span></label>
                                                    <Datetime
                                                        placeholder="Date"
                                                        name="startDate"
                                                        value={this.state.startDate}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        onChange={this.handlerStartDateTime.bind(this)}
                                                        required />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#000" }}>Update Valid Till<span className="rclr fbold">*</span></label>
                                                    <Datetime
                                                        placeholder="Date"
                                                        name="to_date"
                                                        value={this.state.endDate}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        onChange={this.handlerEndDateTime.bind(this)}
                                                        required />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                    {this.state.enabledSlider === "Region" ?
                                    <form onSubmit={this.onUpdateRegionForm.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Country Code<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="countryCode"
                                                        id="inputcode"
                                                        value={this.state.countryCode}
                                                        className="form-control"
                                                        // onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Country Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="countryName"
                                                        id="inputcode"
                                                        value={this.state.countryName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                                    <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }} className='mb-5p'>Update Region<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="regionName"
                                                        id="inputcode"
                                                        value={this.state.regionName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                            </div>
                        </div>

                    </div>
                    {/* <div className={"sliderBlock2 " + (this.state.showAddForm)} >
                    <div className="slide-r-title">
                        <h4>
                        Add {this.state.enableAddSlider} Details
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.hideSlideBlock.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                            <div className="theme-form col-xl-12 col-lg-12">

                               
                            </div>
                        </div>
                    </div>
                </div> */}

                    <div className={"sliderBlock2 " + (this.state.showAddForm)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title slider_title_style">
                            <h6 style={{ fontSize: "16px", fontFamily: "Inter" }}>Add {this.state.enableAddSlider} Details</h6>
                            <span className="float-right ffclosebtn" style={{ marginRight: "25px", fontSize: '20px' }} onClick={this.hideSlideBlock.bind(this)} >X</span>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                {this.state.enableAddSlider === "Partner" ?
                                    <form onSubmit={this.onSubmitAddpartnerDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Partner Code<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        placeholder='Enter Partner Code'
                                                        name="newPartnerCode"
                                                        id="inputcode"
                                                        value={this.state.newPartnerCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Partner Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        placeholder='Enter Partner Name'
                                                        name="newPartnerName"
                                                        id="inputcode"
                                                        value={this.state.newPartnerName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Partner Type<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPartnerType"
                                                        placeholder='Enter Partner Type'
                                                        id="inputcode"
                                                        value={this.state.newPartnerType}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Change Activity Status: </label>
                                                    <Select
                                                        placeholder="Change Activity Status"
                                                        closeMenuOnSelect={true}
                                                        value={this.state.newActivityValue}
                                                        // multi={true}
                                                        // className={"border-radius-0"} 
                                                        onChange={this.newFilter.bind(this, "newActivityValue")}
                                                        style={{ borderRadius: "0px" }}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: "0.8rem",
                                                                color: "#6e707e",
                                                            }),
                                                        }}
                                                        options={this.state.newActivtyOptions}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Plant" ?
                                    <form onSubmit={this.onSubmitAddPlantDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Plant Code<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPlantCode"
                                                        id="inputcode"
                                                        value={this.state.newPlantCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Plant Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPlantName"
                                                        id="inputcode"
                                                        value={this.state.newPlantName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPlantLatitude"
                                                        id="inputcode"
                                                        value={this.state.newPlantLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPlantLongitude"
                                                        id="inputcode"
                                                        value={this.state.newPlantLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => { restrictToNumbers(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Container Pickup Yard" ?
                                    <form onSubmit={this.onSubmitAddContainerYardDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Container Pickup Yard Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newContainerYardName"
                                                        id="inputcode"
                                                        value={this.state.newContainerYardName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newContainerYardLatitude"
                                                        id="inputcode"
                                                        value={this.state.newContainerYardLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newContainerYardLongitude"
                                                        id="inputcode"
                                                        value={this.state.newContainerYardLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Parking Yard" ?
                                    <form onSubmit={this.onSubmitAddParkingYardDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Parking Plaza Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newParkingPlazaName"
                                                        id="inputcode"
                                                        value={this.state.newParkingPlazaName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}

                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newParkingPlazaLatitude"
                                                        id="inputcode"
                                                        value={this.state.newParkingPlazaLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newParkingPlazaLongitude"
                                                        id="inputcode"
                                                        value={this.state.newParkingPlazaLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Buffer Yard" ?
                                    <form onSubmit={this.onSubmitAddBufferYardDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Buffer Yard Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newBufferYardName"
                                                        id="inputcode"
                                                        value={this.state.newBufferYardName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newBufferYardLatitude"
                                                        id="inputcode"
                                                        value={this.state.newBufferYardLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newBufferYardLongitude"
                                                        id="inputcode"
                                                        value={this.state.newBufferYardLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Port Gate" ?
                                    <form onSubmit={this.onSubmitAddPortGateDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Port Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPortName"
                                                        id="inputcode"
                                                        value={this.state.newPortName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Gate Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newGateName"
                                                        id="inputcode"
                                                        value={this.state.newGateName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Latitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPortGateLatitude"
                                                        id="inputcode"
                                                        value={this.state.newPortGateLatitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Longitude<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newPortGateLongitude"
                                                        id="inputcode"
                                                        value={this.state.newPortGateLongitude}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        onInput={(e) => restrictToNumbers(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Transporter" ?
                                    <form onSubmit={this.onSubmitAddTransporterDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Transporter Name*</label>
                                                    <input
                                                        type="text"
                                                        name="newTransporterName"
                                                        id="inputcode"
                                                        value={this.state.newTransporterName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Transporter Code*</label>
                                                    <input
                                                        type="text"
                                                        name="newTransporterCode"
                                                        id="inputcode"
                                                        value={this.state.newTransporterCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Plant Code*</label>
                                                    <input
                                                        type="text"
                                                        name="newTransporterPlantCode"
                                                        id="inputcode"
                                                        value={this.state.newTransporterPlantCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>SOB*</label>
                                                    <input
                                                        type="text"
                                                        name="newTransporterSOB"
                                                        id="inputcode"
                                                        value={this.state.newTransporterSOB}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                    {this.state.enableAddSlider === "Holiday" ?
                                    <form onSubmit={this.onSubmitAddholidayDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                            <div className="form-group col-xl-12 col-lg-12">
                                            <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Holiday Date <span className="err-txt">*</span></label>
                                                <Datetime
                                                    key={this.state.holidayDate}
                                                    placeholder="Date"
                                                    disableCloseOnClickOutside={false}
                                                    closeOnSelect={true}
                                                    name="holiday_date"
                                                    value={this.state.holidayDate}
                                                    dateFormat="DD-MM-YYYY"
                                                    id='holiday_date'
                                                    timeFormat={false}
                                                    onChange={(e) => {this.onChangeHandleDate(e) }}
                                                    required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Day<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="day"
                                                        id="inputcode"
                                                        value={this.state.day}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Plant Code<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="plantCode1"
                                                        id="inputcode"
                                                        value={this.state.plantCode1}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Plant Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="plantName1"
                                                        id="inputcode"
                                                        value={this.state.plantName1}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                               
                                                
                                                </div>
                                                
                                              
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Trucks" ?
                                    <form onSubmit={this.onSubmitAddTrucksDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Truck Number*</label>
                                                    <input
                                                        type="text"
                                                        name="newTruckNo"
                                                        id="inputcode"
                                                        value={this.state.newTruckNo}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Transporter Code*</label>
                                                    <input
                                                        type="text"
                                                        name="newTransporterForTruck"
                                                        id="inputcode"
                                                        value={this.state.newTransporterForTruck}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Truck Model*</label>
                                                    <input
                                                        type="text"
                                                        name="newTruckModel"
                                                        id="inputcode"
                                                        value={this.state.newTruckModel}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Container Type*</label>
                                                    <input
                                                        type="text"
                                                        name="newContainerType"
                                                        id="inputcode"
                                                        value={this.state.newContainerType}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Valid From*</label>
                                                    <Datetime
                                                        placeholder="Date"
                                                        name="startDate"
                                                        value={this.state.startDate}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        onChange={this.handlerStartDateTime.bind(this)}
                                                        required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p'>Valid Till*</label>
                                                    <Datetime
                                                        placeholder="Date"
                                                        name="endDate"
                                                        value={this.state.endDate}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        onChange={this.handlerEndDateTime.bind(this)}
                                                        required />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                                {this.state.enableAddSlider === "Region" ?
                                    <form onSubmit={this.onSubmitAddRegionDetails.bind(this)}>
                                        <div className="slide-body">
                                            <div className="row p-20p">
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Country Code<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newCountryCode"
                                                        id="inputcode"
                                                        value={this.state.newCountryCode}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Country Name<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newCountryName"
                                                        id="inputcode"
                                                        value={this.state.newCountryName}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className='mb-5p' style={{ fontSize: "14px", color: "#808080", fontFamily: "Inter" }}>Region<span className="err-txt">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="newRegion"
                                                        id="inputcode"
                                                        value={this.state.newRegion}
                                                        className="form-control"
                                                        onChange={(e) => { this.onChangeHandler(e) }}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 mb-20p text-center">
                                                    <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    {/* <div className={"sliderBlock2 "+(this.state.showInvoiceForm)}>
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6>BAL Dispatch Invoice Form</h6>
                <span className="float-right ffclosebtn" style={{ marginRight: "25px" }} onClick={this.hideSlideBlock.bind(this)} >X</span>
                <div className="row">
                    <div className="col-sm-12">
                    <form onSubmit={this.invoiceFormUpdateItem.bind(this)}>
                        <div className="slide-body">
                            <div className="row p-20p">
                                <div className="form-group col-sm-12 mb-20p">
                                    <label style={{color:"#000"}}>Invoice Number<span className="rclr fbold">*</span></label>                                        
                                    <input type="text" onChange={this.invoiceNumberHandler} className="form-control" name="Invoice Number"/>
                                </div>
                                <div className="form-group col-sm-12 mb-20p" style={{color: "gray"}}>
                                    <label>Invoice Date<span className="rclr fbold">*</span></label>                                        
                                    <Datetime 
                                        inputProps={{ placeholder: 'Invoice Date', name: 'startDate', autoComplete:'off', required:true }} 
                                        dateFormat="YYYY-MM-DD" 
                                        timeFormat={false}  
                                        closeOnSelect={true}
                                        name="poDate" 
                                        onChange={this.onChangeInvoiceDate.bind(this)}
                                    />
                                </div>
                                <div className="form-group col-sm-12 mb-20p text-center">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>    
        </div> */}
                </div>
                {<div className={"slide-r " + this.state.setsliderForBulkupload} style={{ overflow: "auto" }}>
                    <div className="slide-r-title slider_title_style">
                        <h6>Bulk Upload</h6>
                        <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideAll} >X</span>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <div className="theme-form col-xl-12 col-lg-12">
                                <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Upload File *</label>
                                        <input
                                            type="file"
                                            name="uploadfile"
                                            id="uploadfile"
                                            className="form-control"
                                            onChange={changeOrderFileHandlerFcrBulk}
                                            required
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/month_outlook_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
                {/* <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div> */}
            <div className={"overlay-part " + (this.state.overlay)} onClick={onClickHideAll}></div>
            </>
        );
    }
}
function dateComparator(date1, date2) {
    // //console.log("dateComparator");
    // //console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // //console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // //console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // //console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // //console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function convertDateFormat(dateStr) {
    // Parse the original format assuming the format "YYYY-MM"
    const [year, month] = dateStr.split('-');

    // JavaScript months are 0-indexed, so subtract 1
    const date = new Date(year, month - 1);

    // Format to "MMM-YYYY"
    const date_last = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
    return date_last.replace(" ", "-");
}

function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // //console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
const getCurrentWeekDates = () => {
    let currentDate = moment(); // Get the current date
    let endOfWeek = currentDate.clone().add(6, 'days'); // End of the week (1 week from today)

    let dates = []; // Array to store the dates
    for (let date = currentDate; date <= endOfWeek; date = date.clone().add(1, 'days')) {
        dates.push(date.format('DD-MMM-YYYY'));
    }
    return dates;
};
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
function validateExactFields(dataArray, fieldNames) {
    const expectedFieldCount = fieldNames.length;
    return dataArray.every(item => {
        const keys = Object.keys(item);
        return expectedFieldCount === keys.length && keys.every(key => fieldNames.includes(key));
    });
}
const validateCSVHeader = async (file, expectedFields) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const text = event.target.result;
            const headers = text.slice(0, text.indexOf("\n")).trim().split(",").map(header => header.trim());  // Trimming headers to avoid issues with spaces
            //console.log("Read headers:", headers);
            //console.log("Expected headers:", expectedFields);
            if (headers.length === expectedFields.length && headers.every(header => expectedFields.includes(header))) {
                resolve(true);  // Resolve the promise with true
            } else {
                resolve(false);  // Resolve the promise with false instead of rejecting
            }
        };
        reader.onerror = () => resolve(false);  // Resolve false on FileReader error
        reader.readAsText(file);
    });
};




export default withRouter(Partners);