
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const UpdateUploadDOBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickuploadDO(props);
    };
    return (
        <div className="map-icon-component" style={{cursor: "pointer"}}>
            {(props.data.cha_tentative_details_updated ==1) ? <img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'15px',}}/> : (
             ((props.data.ib_stuffing_modified!=undefined && props.data.ib_stuffing_modified==1) || (props.data.modified_plant==1 && props.data.modified_plant!=undefined) || (props.data.cha_vessel_modified==1 && props.data.cha_vessel_modified!=undefined))?
             <img  src={require("../../assets/images/update-gray.png")} style={{height:'15px',}}/>:
             <img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'15px',}}/>)}
             
             {/* <img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'20px',marginLeft:'30px'}}/> */}
     </div>
    );
};
export default UpdateUploadDOBtn;
