import React, { Component } from "react";
import { Link, redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import redirectURL from "../redirectURL";

export default class CreateEximBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shipmentName: "",
      productName: "",
      originCityName: "",
      destinationCityName: "",
      consigneeName: "",
      container_size: ""
    };
  }

  createNewEximBooking = (e) => {
    e.preventDefault();
    var payload = {
      shipment_name: this.state.shipmentName,
      product_name: this.state.productName,
      delivery_cityname: this.state.originCityName,
      destination_cityname: this.state.destinationCityName,
      consignee_name: this.state.consigneeName,
      requirement_id: uuidv4(),
      booking_status:"In Draft",
      ffp: 0,
      chap: 0,
      tranp: 0,
      ffip: 0,
      chaip:0,
      booking_closed: 0,
      container_no: "",
      vessel_no: "",
      pickup_location: "",
      est_time_departure: "",
      container_size: 0
    };
    console.log(payload, "payload while creating eximBooking")
    redirectURL.post("/exim/createNewEximBooking", payload).then((resp) => {
      console.log("Resp", resp.data);
    });
  };
  render() {
    return (
      <div className="card shadow m-3">
        <div className="row justify-content-between mb-3 bg-primary text-white align-items-center mx-0 py-2">
          <div className="col-11">
            <h5 className="font-weight-bold">Create Booking</h5>
          </div>
          <div className="col-1">
            <h5 className="font-weight-bold">
              <Link
                to={"/booking-shipments"}
                style={{ textDecoration: "none", color: "white" }}
              >
                X
              </Link>
            </h5>
          </div>
        </div>
        <form
          onSubmit={this.createNewEximBooking.bind(this)}
          className="px-3 user"
        >
          <div className="form-group row">
            <label htmlFor="shipmentName" className="col-sm-2 col-form-label">
              Shipment Name <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="shipmentName"
                value={this.state.shipmentName}
                onChange={(e) =>
                  this.setState({ shipmentName: e.target.value })
                }
                placeholder="Enter a unique name htmlFor your shipment."
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="productName" className="col-sm-2 col-form-label">
              Product Name <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="productName"
                value={this.state.productName}
                onChange={(e) => this.setState({ productName: e.target.value })}
                placeholder="Enter a description of your productName."
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="originCityName" className="col-sm-2 col-form-label">
              Origin City <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="originCityName"
                value={this.state.originCityName}
                onChange={(e) =>
                  this.setState({ originCityName: e.target.value })
                }
                placeholder="Start typing in the city or country name"
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="destinationCityName"
              className="col-sm-2 col-form-label"
            >
              Destination City <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="destinationCityName"
                value={this.state.destinationCityName}
                onChange={(e) =>
                  this.setState({ destinationCityName: e.target.value })
                }
                placeholder="Start typing in the city or country name"
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="consigneeName" className="col-sm-2 col-form-label">
              Consignee <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="consigneeName"
                value={this.state.consigneeName}
                onChange={(e) =>
                  this.setState({ consigneeName: e.target.value })
                }
                placeholder="Enter the name of the consignee"
                required
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}
