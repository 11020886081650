import React, { Component } from "react";
import moment from "moment";
import redirectURL from '../redirectURL';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts-drilldown';
import PieChart from "highcharts-react-official";

drilldown(Highcharts);
export default class TrackingBarChart extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        // console.log("this.props.drilldownseries ", this.props.drilldownseries)
        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const highchartoptions = {
            chart: {
                type: this.props.chartType
            },
            credits: false,
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },

            xAxis: {
                type: 'category',
                title: {
                    text: this.props.xAxisTitle
                },
                labels: {
                    enabled: true,//default is true
                    //y : 20, rotation: -45, align: 'right' 
                }

            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yAxisTitle
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {

                colorByPoint: true,
                series: {
                    borderWidth: 0,
                    // dataLabels: {
                    //     enabled: true,
                    //     format: "{point.label}"
                    // },
                    dataLabels:{
                        enabled:false
                    },
                    showInLegend: true
                }
            },
            series:this.props.barSeries,

            // series: [
            //     {
            //         name: "Carriers",
            //         data: [
            //             {
            //                 name: "ONEY",
            //                 y: 100,
            //                 label: "Oveek",
            //                 drilldown:"ONEY"
            //             },
            //             {
            //                 name: "MSC",
            //                 y: 50,
            //                 label: "oveek",
            //                 drilldown:"MSC"
            //             }
            //         ]
            //     }
            // ],
            drilldown: {
                series: [
                    {
                        name: "MSC",
                        id:"MSC",
                        data: [
                            {
                                name: "MSC",
                                y: 50,
                                label: "oveek",
                            }
                        ]
                    },
                    {
                        name: "ONEY",
                        id:"ONEY",
                        data: [
                            {
                                name: "ONEY",
                                y: 100,
                                label: "oveek",
                            }
                        ]
                    }
                ]
            }
        }


        return (
            <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

        );
    }
}