import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

const ForgetPasswordBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        // props.context.componentParent.onClickEditProductionData(props);
        props.context.onClickForgetPasswordBtn(props);
    };
    var colr = props.data.comment_exists;
    
    return (
        <div>
                <button
                    style={{ color: "#fff",backgroundColor:'rgb(64,81,120)'}}
                    onClick={handleRouteClick}
                    className="btn custom-btn f12 label label-success mb-10p"
                    title=""
                >
                    Forget Password
                </button>
            </div>
    );
};

export default ForgetPasswordBtn;
