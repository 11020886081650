
import react from 'react';
 
const ViewItems = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickViewItems(props);
    };
// console.log("props ", props)
    return (
        <div>
            <a onClick={handleClick}
              className={"btn btn-info nopadding font12"} 
              title="View Items">
                  <i className="icofont icofont-eye f16"></i>&nbsp; View Items
            </a>
          
        </div>
    );
};

export default ViewItems;
