import React, { Component } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { bottom } from '@popperjs/core';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
export default class TrackingAmPieChart extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.root = null;
    }

    componentDidMount() {

        let chartDiv = this.chartRef.current; // Access the DOM node using the ref
        var newRoot = this.root
        newRoot = am5.Root.new(chartDiv);
        newRoot.setThemes([
            am5themes_Animated.new(newRoot)
        ]);

        let colors = this.props.colors;
        let title = this.props.title;

        let chart = newRoot.container.children.push(
            am5percent.PieChart.new(newRoot, {
                radius: am5.percent(50),
                innerRadius: am5.percent(50),
                options: {
                    tooltips: true
                }
            })
        );
        
        chart.children.unshift(am5.Label.new(newRoot, {
            text:title,
            fontSize: 20,
            // fontWeight: "500",
            x: am5.percent(50),
            centerX: am5.percent(50),
            paddingTop: 20,
            paddingBottom: 0
          }));
        // Define data
        let data = this.props.piSeries[0].data;


        // Create series
        let series = chart.series.push(
            am5percent.PieSeries.new(newRoot, {
                name: "Carrier",
                valueField: "y",
                categoryField: "name",
                legendLabelText: "[{fill}]{category}[/]",
                legendValueText: ""
            })
        );

        series.get("colors").set("colors", colors.map(color => am5.color(color)));


        series.data.setAll(data);
        series.labels.template.set("forceHidden", true);
        series.ticks.template.set("forceHidden", true);
        // // Calculate how we need to adjust chart height
        // let chartHeight = 600 + chart.get("paddingTop", 0) + chart.get("paddingBottom", 0);

        // // Set it on chart's container
        // chart.root.dom.style.height = chartHeight + "px";

        // Add legend
        let legend = chart.children.push(am5.Legend.new(newRoot, {
            y: am5.percent(90),
            centerY: am5.percent(90),
            x: am5.percent(65),
            centerX: am5.percent(65),
            layout: am5.GridLayout.new(newRoot, {
                maxColumns: 5,
                fixedWidthGrid: false
            })
        }));

        legend.markers.template.setAll({
            width: 8,
            height: 8,
        });

        // legend.labels.template.setAll({
        //     fontSize:"14px",
        //     width:
        // })
        
        legend.data.setAll(series.dataItems);

        let exporting = am5plugins_exporting.Exporting.new(newRoot, {

            menu: am5plugins_exporting.ExportingMenu.new(newRoot, {}),

            dataSource: data

        });
    }

    componentWillUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    }
    render() {
        return (
            <div ref={this.chartRef} style={{ width: "100%", height: "500px" }}></div>
        );
    }
}
