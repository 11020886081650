
import React from 'react';
 
const ViewPODoc = (props) => {
    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onClickUpdateItem(props);
    // };
// console.log("props ", props.data)
    return (
        <div>
            <a href={props.data.po_document}
                target="_blank"
              className={"btn btn-warning nopadding font12"} 
              title="View Document">
                  <i className="icofont icofont-eye f16"></i>&nbsp; View PO
            </a>
          
        </div>
    );
};

export default ViewPODoc;
