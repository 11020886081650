

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import download from 'js-file-download';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class ClosureSeaImportsgridData extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
            resizable: true,
            menuTabs: ['filterMenuTab']
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        closureRowData : [],
        invoiceData:[],
        erpData:[],
        ringiData:[],
        cargoData:[],
        erpData:[],
        reqContainersData:[],
        //components : {datepicker:getDatePicker()}
    }
}
componentDidMount(){

    var closureRowData = this.props.closureRowData;
    console.log(closureRowData,"closureRowData")
    this.setState({
        closureRowData : closureRowData,
    })
}
onRecommGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onRecommGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    invoiceData:[]
    
    });
    
}

showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

closeAlert = () => {
    this.setState({
        show: false
    });
}



viewDetails(e){
    if(e.colDef.field == "erpData")
    {
        var erpData = JSON.parse(e.data.erpData);
        erpData.map(function(e){
            var eDate = e.erPoDate;
            if(eDate !=undefined && eDate !='')
            {
                if(eDate.includes("T") == true)
                {
                    eDate = eDate.split("T");
                    eDate = eDate[0];
                    eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0];
                }
            }
            e.erPoDate = eDate;
        })
        var sliderTitle = e.data.job_no+" - ERP Details";
        this.setState({
            overly :"show-m",
            erpData:erpData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "ringiData")
    {
        var ringiData = JSON.parse(e.data.ringiData);
        var sliderTitle = e.data.job_no+" - Ringi Details";
        this.setState({
            overly :"show-m",
            ringiData:ringiData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "cargoData")
    {
        var cargoData = JSON.parse(e.data.cargoData);
        var sliderTitle = e.data.job_no+" - Cargo Details";
        this.setState({
            overly :"show-m",
            cargoData:cargoData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "closed")
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var params = {
                rowId : rowId
            }
            redirectURL.post("/consignments/closeSeaImportsRequest",params)    
            .then((response) => {
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Successfully marked as Closed",
                        basicType : "success",
                        show : true,
                        overly:"show-n",
                        loadshow:"show-n",
                    })
                    //window.location.reload();
                    this.onLoadPageData(4);
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to mark as closed.",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                    })
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
    }
    else if(e.colDef.field == "reject")
    {
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var sliderTitle = e.data.job_no+" - Reject Reason";
            $("#reject_reason").val("");
            this.setState({
                rejectRowId : rowId,
                rejectedreason : 1,
                overly :"show-m",
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            });
        }
    }
    if(e.colDef.field == "reqContainerData")
    {
        var reqContainersData = JSON.parse(e.data.reqContainersData);
        var sliderTitle = e.data.job_no+" - Container Details";
        this.setState({
            overly :"show-m",
            reqContainersData:reqContainersData,
            bulkslide : "slider-translate-60p",
            sliderTitle : sliderTitle
        })
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadSeaImportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadZipFile = (idx) => {
    var ringiFile = idx;
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/storeSeaImportsPreAlertDocuments",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}
  render() {
    var columnwithDefs = [      
        // {
        //     field:'_id',
        //     headerName:'',
        //     colId:"_id",
        //     //pinned:"left",
        //     width: 72,
        //     cellRenderer:function(params){
        //         let basestring = params.data._id;
        //         let encryptedstring = window.btoa(basestring);
        //         var htmloption = '<a href="/editclosureaircargoform/'+encryptedstring+'/2" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
        //         return htmloption;
        //     }            
        // },  
        {
            headerName: "Job No",
            field: "job_no",
            width:130,
            filter: true,
            resizable: true,
            cellStyle: function(params) {
                if (params.data.rejected == 1) {
                    return {color: 'red'};
                } else {
                    return null;
                }
            },          
        },
        {
            headerName: "Date",
            field: "date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.date !=undefined && params.data.date !='')
                {
                    return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                }
            }
        },
        {
            headerName: "Department Name",
            field: "dept_name",
            width:120,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Name",
            field: "indenter_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Mobile No",
            field: "mobile",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Email",
            field: "indenter_email",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Request Type",
            field: "request_type",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.request_type == 1)
                {
                    return "FCL - Booking Request";
                }
                else if(params.data.request_type == 2)
                {
                    return "LCL - Booking Request";
                }
                else if(params.data.request_type == 3)
                {
                    return "Prepaid Shipment";
                }
                else
                {
                    return "FF New Request";
                }
            }           
        },
        {
            headerName: "Submitted By",
            field: "created_by_user",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ERP",
            field: "erpPO",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ERP Data",
            field: "erpData",
            width:100,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-info" style="padding-top:0px">View</button>';
                return htmloption;
            }      
        },
        {
            headerName: "Ringi Data",
            field: "ringiData",
            width:100,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-danger" style="padding-top:0px">View</button>';
                return htmloption;
            }
        },
        {
            headerName: "No Of Packets",
            field: "item_desc",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Cargo Gross Weight (Metric Tons)",
            field: "gross_weight",
            width:150,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                if(params.data.gross_weight !="undefined" && params.data.gross_weight !=undefined)
                {
                    return params.data.gross_weight;
                }
            }
        },
        {
            headerName: "Volume (CBM)*",
            field: "dimensions_of_package",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.dimensions_of_package !="undefined" && params.data.dimensions_of_package !=undefined)
                {
                    return params.data.dimensions_of_package;
                }
            }
        },
        {
            headerName: "Gross Cargo Volume (CBM)",
            field: "gross_cargo_volume",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.gross_cargo_volume !="undefined" && params.data.gross_cargo_volume !=undefined)
                {
                    return params.data.gross_cargo_volume;
                }
            }
        },
        // {
        //     headerName: "Size Of Container required",
        //     field: "size_of_container",
        //     width:150,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function(params){
        //         if(params.data.size_of_container !="undefined" && params.data.size_of_container !=undefined)
        //         {
        //             return params.data.size_of_container;
        //         }
        //     }
        // },
        // {
        //     headerName: "Type Of Container",
        //     field: "type_of_container",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function(params){
        //         if(params.data.type_of_container !="undefined" && params.data.type_of_container !=undefined)
        //         {
        //             return params.data.type_of_container;
        //         }
        //     }
        // },
        {
            headerName: "No Of Containers required",
            field: "no_of_containers",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.no_of_containers !="undefined" && params.data.no_of_containers !=undefined)
                {
                    return params.data.no_of_containers;
                }
            }
        },
        {
            headerName: "Containers Data",
            field: "reqContainerData",
            width:100,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-warning" style="padding-top:0px">View</button>';
                return htmloption;
            }      
        },
        // {
        //     headerName: "Shipment is",
        //     field: "shipment_type",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function(params){
        //         if(params.data.shipment_type !="undefined" && params.data.shipment_type !=undefined)
        //         {
        //             return params.data.shipment_type;
        //         }
        //     }
        // },
        {
            headerName: "Cargo Type",
            field: "cargo_type",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.cargo_type !="undefined" && params.data.cargo_type !=undefined)
                {
                    return params.data.cargo_type;
                }
            }
        },
        // {
        //     headerName: "Handling Type",
        //     field: "handling_type",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function(params){
        //         if(params.data.handling_type !="undefined" && params.data.handling_type !=undefined)
        //         {
        //             return params.data.handling_type;
        //         }
        //     }
        // },
        {
            headerName: "Delivery in MSIL Plant",
            field: "cargo_delivery_plant",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.cargo_delivery_plant !="undefined" && params.data.cargo_delivery_plant !=undefined)
                {
                    return params.data.cargo_delivery_plant;
                }
            }
        },
        {
            headerName: "Supplier Name",
            field: "supplier_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Supplier Address",
            field: "supplier_address",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Supplier contact person",
            field: "supplier_contact_person",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Country of Supplier",
            field: "country",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Email id",
            field: "email",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Phone No",
            field: "phone",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "In co Terms",
            field: "delivery_terms",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Address",
            field: "pick_up_address",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.pick_up_address !=undefined && params.data.pick_up_address !="undefined")
                    {
                        return params.data.pick_up_address;
                    }
                }
                catch(e){

                }
            }           
        },
        // {
        //     headerName: "Place/port",
        //     field: "port",
        //     width:130,
        //     filter: true,
        //     resizable: true, 
        //     valueGetter: function(params){
        //         try{
        //             if(params.data.port !=undefined && params.data.port !="undefined")
        //             {
        //                 return params.data.port;
        //             }
        //         }
        //         catch(e){

        //         }
        //     }          
        // },
        // {
        //     headerName: "Airport Name",
        //     field: "airport_name",
        //     width:130,
        //     filter: true,
        //     resizable: true, 
        //     valueGetter: function(params){
        //         try{
        //             if(params.data.airport_name !=undefined && params.data.airport_name !="undefined")
        //             {
        //                 return params.data.airport_name;
        //             }
        //         }
        //         catch(e){

        //         }
        //     }          
        // },
        {
            headerName: "Proposed cargo readiness Date",
            field: "cargo_date",
            width:180,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.cargo_date !=undefined && params.data.cargo_date !='')
                {
                    return moment.parseZone(params.data.cargo_date).format("DD-MM-YYYY")
                }
            }           
        },
        {
            headerName: "LC Available",
            field: "lc",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "LC No",
            field: "lc_no",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.lc_no !=undefined && params.data.lc_no !="undefined")
                    {
                        return params.data.lc_no;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Port Of Dispatch",
            field: "port_of_dispatch",
            width:130,
            filter: true,
            resizable: true          
        },
        {
            headerName: "Nature Of Shipment",
            field: "nature",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.nature !=undefined && params.data.nature !="undefined")
                    {
                        return params.data.nature;
                    }
                }
                catch(e){

                }
            }          
        },
        {
            headerName: "Free Trade Agreement",
            field: "free_trade",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.free_trade !=undefined && params.data.free_trade !="undefined")
                    {
                        return params.data.free_trade;
                    }
                }
                catch(e){

                }
            }         
        },
        {
            headerName: "R&D benefit Certificate",
            field: "benefit_certificate",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.benefit_certificate !=undefined && params.data.benefit_certificate !="undefined")
                    {
                        return params.data.benefit_certificate;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "EPCG",
            field: "epcg",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.epcg !=undefined && params.data.epcg !="undefined")
                    {
                        return params.data.epcg;
                    }
                }
                catch(e){

                }
            }           
        },
        // {
        //     headerName: "ETA IGI Airport Date",
        //     field: "eta_igi_airport_date",
        //     width:130,
        //     filter: true,
        //     resizable: true, 
        //     valueGetter: function(params){
        //         try{
        //             if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="undefined")
        //             {
        //                 return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
        //             }
        //         }
        //         catch(e){

        //         }
        //     }           
        // },
        {
            headerName: "Freight Forworder",
            field: "assign_to_ff",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.assign_to_ff !=undefined && params.data.assign_to_ff !="undefined")
                    {
                        return params.data.assign_to_ff;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "FF Contact person Details",
            field: "ff_contact_person_details",
            width:150,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.ff_contact_person_details !=undefined && params.data.ff_contact_person_details !="undefined")
                    {
                        return params.data.ff_contact_person_details;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Counterpart Details",
            field: "counterpart_details",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.counterpart_details !=undefined && params.data.counterpart_details !="undefined")
                {
                    return params.data.counterpart_details;
                }
            }           
        },
        {
            headerName: "Cargo reciving date",
            field: "cargo_reciving_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.cargo_reciving_date !=undefined && params.data.cargo_reciving_date !='')
                {
                    return moment.parseZone(params.data.cargo_reciving_date).format("DD-MM-YYYY")
                }
            }         
        },
        {
            headerName: "Shipping Line Name",
            field: "shipping_line_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Dispatch Port Name",
            field: "port_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETD Port date",
            field: "etd_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.etd_port_date !=undefined && params.data.etd_port_date !='')
                {
                    return moment.parseZone(params.data.etd_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATD Port date",
            field: "atd_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.atd_port_date !=undefined && params.data.atd_port_date !='')
                {
                    return moment.parseZone(params.data.atd_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ETA Indian Port",
            field: "eta_igi_airport_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !='')
                {
                    return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATA Indian Port",
            field: "ata_igi_airport_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !='')
                {
                    return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "IF ETD and ATD not same",
            field: "if_etd_atd_not_same",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "If ATD and ATA not same",
            field: "if_atd_ata_not_same",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "MBL No",
            field: "mbl_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "HBL No",
            field: "hbl_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "HBL date",
            field: "hbl_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.hbl_date !=undefined && params.data.hbl_date !='')
                {
                    return moment.parseZone(params.data.hbl_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "No. of Containers",
            field: "ff_no_of_containers",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ff_no_of_containers !=undefined && params.data.ff_no_of_containers !="undefined")
                {
                    return params.data.ff_no_of_containers;
                }
            }         
        },
        {
            headerName: "Remarks (Current Status of Shipment)",
            field: "current_status_of_shipment",
            width:130,
            filter: true,
            resizable: true
        },
        {
            headerName: "Final Port Of Destination",
            field: "final_port_of_destination",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Do Handover Date",
            field: "do_handover_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.do_handover_date !=undefined && params.data.do_handover_date !='')
                {
                    return moment.parseZone(params.data.do_handover_date).format("DD-MM-YYYY")
                }
            }          
        },
        {
            headerName: "Rail Out Date",
            field: "rail_out_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.rail_out_date !=undefined && params.data.rail_out_date !='')
                {
                    return moment.parseZone(params.data.rail_out_date).format("DD-MM-YYYY")
                }
            }          
        },
        {
            headerName: "Arrival at FPOD",
            field: "arrival_at_fpod",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.arrival_at_fpod !=undefined && params.data.arrival_at_fpod !='')
                {
                    return moment.parseZone(params.data.arrival_at_fpod).format("DD-MM-YYYY")
                }
            }          
        },
        {
            headerName: "Any other docs",
            field: "other_docs",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "GIGM No",
            field: "gigm_no",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "GIGM Date",
            field: "gigm_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.gigm_date !='' && params.data.gigm_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.gigm_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "LIGM No",
            field: "ligm_no",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "LIGM Date",
            field: "ligm_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.ligm_date !='' && params.data.ligm_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.ligm_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "BOE No",
            field: "boe_no",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BOE Date",
            field: "boe_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.boe_date !='' && params.data.boe_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.boe_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Type",
            field: "rms_status",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Exam Date",
            field: "exam_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.exam_date !='' && params.data.exam_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.exam_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "OOC Date",
            field: "ooc_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.ooc_date !='' && params.data.ooc_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.ooc_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "LPC",
            field: "lpc",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Amount",
            field: "lpc_amount",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Reason",
            field: "lpc_reason_for_delay",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Basic Custom Duty Amount",
            field: "custom_duty_amount",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Social Welfare Charges",
            field: "social_welfare",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "IGST",
            field: "igst",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Any other Duty",
            field: "any_other_duty",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Total Custom Duty Paid",
            field: "custom_duty",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Vehicle No",
            field: "vehicle_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Movement from Port Date",
            field: "movement_from_port_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.movement_from_port_date !='' && params.data.movement_from_port_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.movement_from_port_date);
                    }
                }
                catch(e){
                }
            }            
        }, 
        {
            headerName: "ETA MSIL",
            field: "eta_msil",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.eta_msil !='' && params.data.eta_msil !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.eta_msil);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Rejected Reason",
            field: "reject_reason",
            width:150,
            filter: true,
            resizable: true,           
        },
    ]

        
    return (
        <div class="row">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>
                <AgGridReact
                    modules={this.state.modules}
                    //columnDefs={columnwithDefs}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.closureRowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onRecommGridReady}
                    onGridState={this.onRecommGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    onCellClicked={this.viewDetails.bind(this)}
                    // components={this.state.components}
                    />
                </div>
            </div>
            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                <h3 className="subH">
                    {this.state.sliderTitle}
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                </h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                    {(this.state.erpData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>ERP PO No</th>
                                                <th>ERP PO Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.erpData.map((e, index) => (
                                            <tr>
                                                <td>{e.erpPoNo}</td>
                                                <td>{e.erPoDate}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.ringiData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Ringi No</th>
                                                <th>Ringi (File)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ringiData.map((e, index) => (
                                            <tr>
                                                <td>{e.ringi_desc}</td>
                                                <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.ringi_file)}>{e.ringi_file}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.cargoData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Item Description</th>
                                                <th>Cargo Gross Weight</th>
                                                <th>Dimensions (cms)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.cargoData.map((e, index) => (
                                            <tr>
                                                <td>{e.item_description}</td>
                                                <td>{e.cargo_gross_weight}</td>
                                                <td>{e.dimensions}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {/* {(this.state.rejectedreason == 1) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <form method="POST" id="rejectform" className="theme-form" onSubmit={this.rejectCargoRequest}>
                                        <div className="form-group mt-20p">
                                            <label className="">Reason</label> 
                                            <textarea name="reject_reason" id="reject_reason" className="form-control" required></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Reject</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    : ""} */}
                    {(this.state.reqContainersData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Size Of Container required</th>
                                                <th>Type Of Container</th>
                                                <th>Shipment Type</th>
                                                <th>Handling Type</th>
                                                <th>Spl Handling Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reqContainersData.map((e, index) => (
                                            <tr>
                                                <td>{e.containerSize}</td>
                                                <td>{e.containerType}</td>
                                                <td>{e.shipmentIs}</td>
                                                <td>{e.handlingType}</td>
                                                <td>{e.splHandling}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                </div>
        </div>
        </div>
        

    );
  }
}