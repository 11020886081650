import React, { Component,useState, useEffect, useRef } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import UpdateAcknowledgeBtnComponent from "./update&aknowledgeBtn";


import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import PTData from '../../assets/json/payment_term_description.json'
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY ,getHyphenYYYYMMDD, getHyphenDDMMYYYYHHMM, getHHMM} from '../common/utils';
import moment from "moment";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import CountryFlagComponent from "./countryFlag";
import UpdateUploadDOBtn from "./updateUploadDObtn";
import UpdateUploadDOBtn1 from "./updateuploaddo1";
import ViewDOdetailsComponent from "./viewDOdetails";
import download from 'js-file-download';
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
const UploadDo = (props) => {
    const gridApi = useRef(null);
    // var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [file, setfile] = useState('')
    var [ptd, setptd] = useState(PTData.PTData ?? [])
    var [csvcontent, setcsvcontent] = useState("")
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [activeDoCopy, setActiveDoCopy] = useState(false);
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [check, setcheck] = useState(0)
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [counters, setCounters] = useState({
        vessel_avai: []
    })
    var [workordernoList, setworkOrderList] = useState([])
    const [isClicked, setIsClicked] = useState({
        vessel_avail: false,
        uploadDo: false
    });
    var [checkresetfilter,setcheckresetfilter] = useState(0)
    const [showAddForm, setshowAddForm] = useState('')
    const [showAddForm1, setshowAddForm1] = useState('')
    const [showAddForm2, setshowAddForm2] = useState('')
    const [showAddForm3, setshowAddForm3] = useState('')
    const [doCopy, setdoCopy] = useState(false)
    var [ files1, setFile1] = useState({
        file1 : '',
        file2 :''
    })
    const [additionalFiles, setAdditionalFiles] = useState('')

    var [manualUploadDetails, setmanualUploadDetails] = useState({
        wrno: '',
        booking_no: '',
        do_valid_from:'',
        do_valid_to:'',
        shipping_line:'',
        voyaga_no:'',
        freight_forwarder:'',
        remarks_by_cha:'',
        do_remarks: '',
        vessel_name:'',
        vessel_etd_date:'',
        do_copy:'',
        terminal:'',
        pickup_yard:'',
        no_of_free_days:'',
        duplicate_booking_no: "",
        duplicate_do_valid_from:'',
        duplicate_do_valid_to:'',
        duplicate_shipping_line:'',
        duplicate_voyaga_no:'',
        duplicate_freight_forwarder:'',
        duplicate_vessel_name:'',
        duplicate_terminal:'',
        duplicate_pickup_yard:'',
        duplicate_no_of_free_days:'',
        duplicate_vessel_etd_date:'',

    })
    var [editManualUploadDetails, setEditManualUploadDetails] = useState({
        wrno: '',
        booking_no: '',
        do_valid_from:'',
        do_valid_to:'',
        shipping_line:'',
        voyaga_no:'',
        freight_forwarder:'',
        remarks_by_cha:'',
        do_remarks: '',
        vessel_name:'',
        vessel_etd_date:'',
        additional_do_copy:'',
        terminal:'',
        pickup_yard:'',
        no_of_free_days:'',
        port_cutoff_date:'',
        gate_open_date:'',
        sl_cutoff_date:'',
        additionl_do_copy_arr:[]
    })
    var [selected_row,setselected_row] = useState({})
    var [reloadcheck,setreloadcheck] = useState(false)
    var [classForTab, setclassforTab] = useState({
        planned: 0,
        new: 1,
        modified: 0,
    })
    var [tabDataforDO,settabDataforDO]=useState({
        DO_new_plant:[],
        DO_planned_plant:[],
                    DO_modified_plant:[]
    })
    var [shippinglist1,setshippingLineList]=useState([])
    var [terminallist,setterminallist] = useState([])
    var [frieghtlist,setfrieghtlist] = useState([])
    var [refreshSelected, setRefreshSelected ] = useState(0);
    var [wrdo, setwrdo]=useState();
    const [summaryViewcols, setsummaryViewcols] = useState([
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            cellEditor:DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,
            
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 150,
        //     filter: true,
        //     resizable: true,
        //     cellEditor:DateEditor,
        //     valueGetter: function (params) {
        //         try {
        //                 if (params.data.vessel_etd_date != "" && params.data.vessel_etd_date != undefined) {
        //                     return getHyphenDDMMYYYY(params.data.vessel_etd_date);
        //                 } else if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                     return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //                 }
        //                 else{
        //                     return "Not Available";
        //                 }
        //         } catch (e) {
        //             // Handle the exception if needed
        //         }
        //     },
        //     comparator: dateComparator1,
            
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
      

        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Remarks By IB",
            field: "remarks_by_ib",
            width: 180,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

    ])
    useEffect(()=>{
        console.log(manualUploadDetails,"manujhj");
        if (showAddForm ==='slide30'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showAddForm])
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
        loadDateTimeScript();
    }, [gridApi]);
    useEffect(() => {
        if (props.check == 2) {
          const validCols = ["Work Order No","Booking No", "DO Valid From", "DO Valid To", "Shipping Line", "Freight Forwarder",'Vessel Name','Voyage No','Vessel ETD (date)','Gate Open Datetime','Port cut off date','SI Cut Off Date','Terminal','Pickup Yard','DO Remarks'];
          const bulkUploadFields = [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Booking No', inputName: 'booking_no', required: true },
            { name: 'DO Valid From', inputName: 'do_valid_from', required: true },
            { name: 'DO Valid To', inputName: 'do_valid_to', required: true },
            { name: 'Shipping Line', inputName: 'shipping_line', required: true },
            { name: 'Freight Forwarder', inputName: 'freight_forwarder', required: true },
            { name: 'Vessel Name', inputName: 'vessel_name', required: true },
            { name: 'Voyage No', inputName: 'voyage_no', required: true },
            { name: 'Vessel ETD (date)', inputName: 'vessel_etd_date', required: true },
            { name: 'Gate Open Datetime', inputName: 'gate_open_date', required: true },
            { name: 'Port cut off date', inputName: 'port_cutoff_date', required: true },
            { name: 'SI Cut Off Date', inputName: 'sl_cutoff_date', required: true },
            { name: 'Terminal', inputName: 'terminal', required: true },
            { name: 'Pickup Yard', inputName: 'pickup_yard', required: true },
            {name:'DO Remarks', inputName:'do_remarks', required:true}
        ]
          const apiForUpload = 'uploadBulkUploadForUploadDOforbulk';
          const csvfilename = '../../assets/json/cha_based_upload_do_data.csv'
          const columndefs = [
            {
                headerName: "Work Order No",
                field: "work_order_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Booking No",
                field: "booking_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "DO Valid From",
                field: "do_valid_from",
                width: 120,
                filter: true,
                resizable: true,
                editable: (params) => {
                    const date = params.data.do_valid_from;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                    return !(date && datePattern.test(date)); // Editable if date is invalid
                },
                valueGetter: (params) => {
                    const date = params.data.do_valid_from;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                    if (date && datePattern.test(date)) {
                        return date;
                    } else {
                        return "Invalid Date Format";
                    }
                },
                cellStyle: (params) => {
                    const date = params.value;
                    // Apply red color if the date format is invalid
                    if (date === "Invalid Date Format") {
                        return { background: 'red', color: 'black' };
                    }
                    return null;
                }
            },
            {
                headerName: "DO Valid To",
                field: "do_valid_to",
                width: 120,
                filter: true,
                resizable: true,
                editable: (params) => {
                    const date = params.data.do_valid_to;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                    return !(date && datePattern.test(date)); // Editable if date is invalid
                },
                valueGetter: (params) => {
                    const date = params.data.do_valid_to;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                    if (date && datePattern.test(date)) {
                        return date;
                    } else {
                        return "Invalid Date Format";
                    }
                },
                cellStyle: (params) => {
                    const date = params.value;
                    // Apply red color if the date format is invalid
                    if (date === "Invalid Date Format") {
                        return { background: 'red', color: 'black' };
                    }
                    return null;
                }
            },
            {
                headerName: "Shipping Line",
                field: "shipping_line",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Freight Forwarder",
                field: "freight_forwarder",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Vessel Name",
                field: "vessel_name",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Voyage No",
                field: "voyage_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Tentative Vessel ETD",
            //     field: "vessel_etd_date",
            //     width: 190,
            //     filter: true,
            //     resizable: true,
            //     editable: (params) => {
            //         const date = params.data.vessel_etd_date;
            //         // Check if date is in DD-MM-YYYY format
            //         const datePattern = /^\d{2}-\d{2}-\d{4}$/;
            //         return !(date && datePattern.test(date)); // Editable if date is invalid
            //     },
            //     valueGetter: (params) => {
            //         const date = params.data.vessel_etd_date;
            //         // Check if date is in DD-MM-YYYY format
            //         const datePattern = /^\d{2}-\d{2}-\d{4}$/;
            //         if (date && datePattern.test(date)) {
            //             return date;
            //         } else {
            //             return "Invalid Date Format";
            //         }
            //     },
            //     cellStyle: (params) => {
            //         const date = params.value;
            //         // Apply red color if the date format is invalid
            //         if (date === "Invalid Date Format") {
            //             return { background: 'red', color: 'black' };
            //         }
            //         return null;
            //     }
            // },
            {
                headerName: "Gate Open Datetime",
                field: "gate_open_date",
                width: 190,
                filter: true,
                resizable: true,
                editable: (params) => {
                    const date = params.data.gate_open_date;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
                    return !(date && datePattern.test(date)); // Editable if date is invalid
                },
                valueGetter: (params) => {
                    const dateTime = params.data.gate_open_date;
                    // Check if dateTime is in DD-MM-YYYY HH:mm format
                    const dateTimePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
                    if (dateTime && dateTimePattern.test(dateTime)) {
                        return dateTime;
                    } else {
                        return "Invalid Date Format";
                    }
                },
                cellStyle: (params) => {
                    const dateTime = params.value;
                    // Apply red color if the date format is invalid
                    if (dateTime === "Invalid Date Format") {
                        return { background: 'red', color: 'black' };
                    }
                    return null;
                }
            }
            ,  
            {
                headerName: "Port Cut Off Date",
                field: "port_cutoff_date",
                width: 190,
                filter: true,
                resizable: true,
                editable: (params) => {
                    const date = params.data.port_cutoff_datee;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
                    return !(date && datePattern.test(date)); // Editable if date is invalid
                },
                valueGetter: (params) => {
                    const dateTime = params.data.port_cutoff_date;
                    // Check if dateTime is in DD-MM-YYYY HH:mm format
                    const dateTimePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
                    if (dateTime && dateTimePattern.test(dateTime)) {
                        return dateTime;
                    } else {
                        return "Invalid Date Format";
                    }
                },
                cellStyle: (params) => {
                    const date = params.value;
                    // Apply red color if the date format is invalid
                    if (date === "Invalid Date Format") {
                        return { background: 'red' ,
                            color:'black'
                        };
                    }
                    return null;
                }
            },
            {
                headerName: "SL Cut Off Date",
                field: "sl_cutoff_date",
                width: 190,
                filter: true,
                resizable: true,
                editable: (params) => {
                    const date = params.data.port_cutoff_datee;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
                    return !(date && datePattern.test(date)); // Editable if date is invalid
                },
                valueGetter: (params) => {
                    const dateTime = params.data.sl_cutoff_date;
                    // Check if dateTime is in DD-MM-YYYY HH:mm format
                    const dateTimePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
                    if (dateTime && dateTimePattern.test(dateTime)) {
                        return dateTime;
                    } else {
                        return "Invalid Date Format";
                    }
                },
                cellStyle: (params) => {
                    const date = params.value;
                    // Apply red color if the date format is invalid
                    if (date === "Invalid Date Format") {
                        return { background: 'red' ,
                            color:'black'
                        };
                    }
                    return null;
                }
            },
            {
                headerName: "Terminal",
                field: "terminal",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Pickup Yard",
                field: "pickup_yard",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "DO Remarks",
                field: "do_remarks",
                width: 120,
                filter: true,
                resizable: true,
            },



          ]
          props.uploadBulkData(validCols, bulkUploadFields, apiForUpload,columndefs);
        }
      }, [props.check]);
    useEffect(() => {
        loadDateTimeScript();
       var records = props.data 
       if(records!='' && records!=undefined){
        if(records.length>0){
            console.log(records,'104')
       var new_do = records.filter(e=>e.cha_do_new==1)
    //    var new_do = records.filter(e => e.cha_do_new == 1 && e.cha_do_detials_uploaded != 1);
    //    var new_do_bulk_uploaded = records.filter(e => e.cha_do_new == 1 && e.cha_do_detials_uploaded == 1);
    //    var final_data = new_do_bulk_uploaded.length > 0 ? new_do_bulk_uploaded : new_do;
       var planned_do =  records.filter(e=>e.cha_do_planned==1)
       var modified_do =  records.filter(e=>e.cha_do_modified==1)
       settabDataforDO({
        DO_new_plant:sortByCreatedDate(new_do),
        DO_planned_plant:sortByCreatedDate(planned_do),
        DO_modified_plant:sortByCreatedDate(modified_do)
       })
       sortByCreatedDate(new_do)
         setrowData(new_do)
         setoriginalData(new_do)
        }
       }
       redirectURL.post('/consignments/getdropdowndata').then((resp)=>{
        var shippingLineList = resp.data.shippingLineList 
        var terminal1 = resp.data.terminal
        var freight1 = resp.data.freightforwader
        if(shippingLineList!='' && shippingLineList!=undefined){
            shippingLineList = removeDuplicateObjects(shippingLineList)
            setshippingLineList(shippingLineList)
        }
        if(terminal1!='' && terminal1!=undefined){
            terminal1 = removeDuplicateObjects(terminal1)
            setterminallist(terminal1)
        }
        if(freight1!='' && freight1!=undefined){
            freight1 = removeDuplicateObjects(freight1)
            setfrieghtlist(freight1)
        }
     })
    }, [props.data])
    var typeofselection = [
        { label: 'Ship To Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'Plant', value: 'plant' }, { label: 'Production Date', value: 'production_date' },
        { label: 'Work Order No', value: 'work_order_no' },
    ]
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const onClickupdateAcknowledgeBtn = (e) => {
        console.log(e.data, "selectedrowwww")
        setselected_row(e.data);
        setshowAddForm2("slide30");
        if (e.data.container_count_increase_check == 1){
            setdoCopy(true);
        }
        setoverly("show-m");
        
        setEditManualUploadDetails((prev) => ({
          ...prev,
          additionl_do_copy_arr: (e.data.additionl_do_copy_arr !== undefined && e.data.additionl_do_copy_arr !== null && e.data.additionl_do_copy_arr !== '')? e.data.additionl_do_copy_arr : [],
          wrno: { label: e.data.work_order_no, value: e.data.work_order_no },
          booking_no: e.data.booking_no,
          do_valid_from:getHyphenDDMMYYYY(e.data.do_valid_from),
          do_valid_to:getHyphenDDMMYYYY(e.data.do_valid_to),
          shipping_line:{
            label: e.data.tentative_shipping_line,
            value: e.data.tentative_shipping_line,
          },
          voyaga_no:e.data.voyage_no,
          freight_forwarder: {
            label: e.data.freight_forwarder,
            value: e.data.freight_forwarder,
          },
          remarks_by_cha:e.data.remarks_by_cha,
          do_remarks: e.data.do_remarks,
          vessel_name:e.data.vessel_name,
          vessel_etd_date:getHyphenDDMMYYYY(e.data.vessel_etd_date),
          do_copy:e.data.attachmentFileName,
          terminal:{label:e.data.terminal,value:e.data.terminal},
          pickup_yard:e.data.pickup_yard,
          no_of_free_days:e.data.no_of_free_days,
        //   port_cutoff_date: getHyphenDDMMYYYY(e.data.port_cutoff_date),
        //   gate_open_date: getHyphenDDMMYYYY(e.data.gate_open_date),
        //   sl_cutoff_date: getHyphenDDMMYYYY(e.data.sl_cutoff_date)
         
        }));
        document.getElementById('port_cutoff_date2').value = getHyphenDDMMYYYYHHMM(e.data.tentative_cut_off_date);
        document.getElementById('gate_open_datetime2').value =getHyphenDDMMYYYYHHMM(e.data.tentative_gate_open_date);
        // document.getElementById('port_cutoff_date').value = getHyphenDDMMYYYY(e.data.port_cutoff_date);
        // document.getElementById('gate_open_datetime').value =getHyphenDDMMYYYY(e.data.gate_open_date);
        document.getElementById('sl_cutoff_date2').value = getHyphenDDMMYYYYHHMM(e.data.sl_cutoff_date);

        

      };
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }

    const handleDropDownShipping = (e) => {
        if(e.value !== manualUploadDetails.duplicate_shipping_line) {
            setActiveDoCopy(true)
        } 
        else {
            setActiveDoCopy(false)
        }
        setmanualUploadDetails(prev => ({ ...prev, shipping_line: e }))
    }

    const handleDropDownTerminal = (e) => {
        if(e.value !== manualUploadDetails.terminal) {
            setActiveDoCopy(true)
        } 
        else {
            setActiveDoCopy(false)
        }
        setmanualUploadDetails(prev => ({ ...prev, terminal: e }))
    }

    const handleDropDownFreight = (e) => {
        if(e.value !== manualUploadDetails.freight_forwarder) {
            setActiveDoCopy(true)
        } 
        else {
            setActiveDoCopy(false)
        }
        setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e }))
    }

    const changeVesselETD = (e) => {
        var d = moment(e._d).format('DD-MM-YYYY');
        if(manualUploadDetails.duplicate_vessel_etd_date !== d) {
            setActiveDoCopy(true)
        }
        else {
            setActiveDoCopy(false)
        }
        setmanualUploadDetails(prev => ({ ...prev, vessel_etd_date: changeHandleDate(e) }))
    } 

    const onChangeCompareGateOpenDateTime = () => {
        var gateOpenDate = document.getElementById("gate_open_datetime1").value
        if(gateOpenDate !== manualUploadDetails.duplicate_gate_open_date) {
            setActiveDoCopy(true)
        }
        else {
            setActiveDoCopy(false)
        }
    }

    const onChangeCompareSICutOffDate = () => {
        var slCutoffDate = document.getElementById("sl_cutoff_date1").value
        if(slCutoffDate !== manualUploadDetails.duplicate_sl_cutoff_date) {
            setActiveDoCopy(true)
        }
        else {
            setActiveDoCopy(false)
        }
    }

    const onChangeComparePortCutoffDate = () => {
        var slCutoffDate = document.getElementById("port_cutoff_date1").value
        if(slCutoffDate !== manualUploadDetails.duplicate_port_cutoff_date) {
            setActiveDoCopy(true)
        }
        else {
            setActiveDoCopy(false)
        }
    }

    const changeDOValidTo = (e) => {
        var d = moment(e._d).format('DD-MM-YYYY')
        if(manualUploadDetails.duplicate_do_valid_to !== d) {
            setActiveDoCopy(true)
        }
        else {
            setActiveDoCopy(false)
        }
        setmanualUploadDetails(prev => ({ ...prev, do_valid_to: changeHandleDate(e) }))
    }

    const changeDOValidFrom = (e) => {
        var d = moment(e._d).format('DD-MM-YYYY');
        if(manualUploadDetails.duplicate_do_valid_from !== d) {
            setActiveDoCopy(true)
        }
        else {
            setActiveDoCopy(false)
        }
        setmanualUploadDetails(prev => ({ ...prev, do_valid_from: changeHandleDate(e) }))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name == "booking_no"){
          
            var check_booking_changed = manualUploadDetails.duplicate_booking_no !== value
            if(check_booking_changed){
                setActiveDoCopy(true)
            } else {
                setActiveDoCopy(false)
            }
            setmanualUploadDetails((prevState) => ({...prevState, [name]: value}))
        }
        else if(name == "pickup_yard"){
            var check_pickup_yard = manualUploadDetails.duplicate_pickup_yard !== value
            if(check_pickup_yard){
                setActiveDoCopy(true)
            } else {
                setActiveDoCopy(false)
            }
            setmanualUploadDetails((prevState) => ({...prevState, [name]: value}))
        }
        else if(name == "voyaga_no"){
            var check_voyaga_no = manualUploadDetails.duplicate_voyaga_no !== value
            if(check_voyaga_no){
                setActiveDoCopy(true)
            } else {
                setActiveDoCopy(false)
            }
            setmanualUploadDetails((prevState) => ({...prevState, [name]: value}))
        }
        else if(name == "vessel_name"){
            var check_voyaga_no = manualUploadDetails.duplicate_vessel_name !== value
            if(check_voyaga_no){
                setActiveDoCopy(true)
            } else {
                setActiveDoCopy(false)
            }
            setmanualUploadDetails((prevState) => ({...prevState, [name]: value}))
        }
        else if(name == "no_of_free_days") {
            var check_no_of_free_days = manualUploadDetails.duplicate_no_of_free_days !== value
            if(check_no_of_free_days){
                setActiveDoCopy(true)
            } else {
                setActiveDoCopy(false)
            }
            setmanualUploadDetails((prevState) => ({...prevState, [name]: value}))
        }
    };


    const changeHandleDate = (e) => {
        var d = moment(e._d);
        var formattedDate = d.format('DD-MM-YYYY');
        return formattedDate
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'production_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const onClickAcknowledgeBtn=(rowdata)=>{
        var row = rowdata.data 
        var params = {work_order_no:row.work_order_no,record:row}
        redirectURL.post('/consignments/acknowledgeforchangecontainer',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                    setbasicTitle("Acknowledgement successful")
                    setbasicType("success")
                    setclassforTab(
                        {
                            planned: 0,
                            new: 1,
                            modified: 0,
                        }
                    )
                    props.onRequest()
                    props.onReloadData(true);
            }
        })
    }
    const getRowStyle = params => {
        console.log('398', params);
        if (params.data && (params.data.modified_plant == 1 || params.data.cha_vessel_modified == 1 || params.data.ib_stuffing_modified == 1)) {
            console.log('399', params);
            return { backgroundColor: '#FFB9B9' };
        }
        return null;
    };
    const downloadB1=(e)=>{
        console.log(files1, "filessssss1");
                var fileparams = {
                    attachmentFileName : files1.file1,
                    work_order_no:wrdo,
                    file1: 1
                }
                console.log(fileparams, "fileparamsssss1")
                redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
                }).then((response)=>{
                    const imageURL = response.data;
                     window.open(imageURL, '_blank');
                }).catch(function(error){
                    console.log(error);
                })
    }
//     const downloadB2=(e)=>{
//         // var fileparams = {
//         //     attachmentFileName : files1.file2,
//         //     work_order_no:wrdo,
//         //     file1: 2
//         // }
//         // console.log(fileparams, "fileparamsssss2")

//         // redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
//         // }).then((response)=>{
//             const imageURL = e;
//              window.open(imageURL, '_blank');
//         // }).catch(function(error){
//         //     console.log(error);
//         // })
// }
const downloadB2 = (fileName = "") => {
    if (fileName === "") {
      return;
    }
 
    redirectURL
      .post("/consignments/downloadFile", {
          fileName: fileName}
      )
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          const downloadUrl = res.data.url;

          
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName); // Optional: specify download filename
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // console.log("clicked");
        }
      })
      .catch((err) => {
        console.log("err - ", err);
      });
  };
    const onClickuploadDO=(e)=>{
        console.log(e.data,'195')
        setselected_row(e.data)
        setshowAddForm("slide30")
        setoverly("show-m")
        console.log(e.data.cha_do_details_uploaded,"flag");
        if(e.data.hasOwnProperty("cha_do_details_uploaded") && (e.data.cha_do_details_uploaded == 1)){
            console.log("hit 1");
            var vessel_etd_date = e.data.vessel_etd_date
            var shipping_line = e.data.shipping_line
            var port_cutoff_date = e.data.port_cutoff_date
            var gate_open_date = e.data.gate_open_date
            var booking_no = e.data.booking_no
            var freight_forwarder = e.data.freight_forwarder
            var do_valid_from = e.data.do_valid_from
            var do_valid_to = e.data.do_valid_to
            var vessel_name = e.data.vessel_name
            var voyaga_no = e.data.voyage_no
            var sl_cutoff_date = e.data.sl_cutoff_date
            var terminal = e.data.terminal
            var pickup_yard = e.data.pickup_yard
            var do_remarks = e.data.do_remarks
        } 
        else {
            console.log("hit 2");
            var vessel_etd_date = e.data.tentative_vessel_etd
            var shipping_line = e.data.tentative_shipping_line
            var port_cutoff_date = e.data.tentative_cut_off_date
            var gate_open_date = e.data.tentative_gate_open_date
            var freight_forwarder = e.data.freight_forwarder
            var booking_no = e.data.booking_no
            var do_valid_from = ""
            var do_valid_to = ""
            var vessel_name = e.data.vessel_name
            var voyaga_no = e.data.voyage_no
            var sl_cutoff_date = e.data.sl_cutoff_date
            var terminal = e.data.terminal
            var pickup_yard = e.data.pickup_yard
            var do_remarks = e.data.remarks_by_cha
        }
        setmanualUploadDetails(prev=>({
            ...prev,
            vessel_name: vessel_name,
            voyaga_no: voyaga_no,
            terminal: {'label':terminal,'value':terminal},
            pickup_yard: pickup_yard,
            do_remarks: do_remarks,
            booking_no: booking_no,
            do_valid_from:getHyphenDDMMYYYY(do_valid_from),
            do_valid_to:getHyphenDDMMYYYY(do_valid_to),
            vessel_etd_date:getHyphenDDMMYYYY(vessel_etd_date),
            shipping_line:{'label':shipping_line,'value':shipping_line},
            freight_forwarder:{'label':freight_forwarder,'value':freight_forwarder},
            no_of_free_days:e.data.no_of_free_days
        }))
        document.getElementById('port_cutoff_date').value = getHyphenDDMMYYYYHHMM(port_cutoff_date);
        document.getElementById('gate_open_datetime').value =getHyphenDDMMYYYYHHMM(gate_open_date);
        document.getElementById('sl_cutoff_date').value = getHyphenDDMMYYYYHHMM(sl_cutoff_date);
        // document.getElementById('sl_cutoff_date').value = e.data.sl_cutoff_date;

    }
    const onClickuploadDO1=(e)=>{
        console.log(e.data,'195')
        setselected_row(e.data)
        setshowAddForm1("slide30")
        setoverly("show-m")
        setmanualUploadDetails(prev=>({
            ...prev,
            vessel_etd_date:getHyphenDDMMYYYY(e.data.tentative_vessel_etd),
            shipping_line:{'label':e.data.tentative_shipping_line,'value':e.data.tentative_shipping_line},
            wrno: e.data.work_order_no,
        booking_no: e.data.booking_no,
        do_valid_from:getHyphenDDMMYYYY(e.data.do_valid_from),
        do_valid_to:getHyphenDDMMYYYY(e.data.do_valid_to),
        voyaga_no:e.data.voyage_no,
        vessel_name:e.data.vessel_name,
        do_copy:e.data.attachmentFileName,
        terminal: {'label':e.data.terminal, 'value':e.data.terminal},
        pickup_yard:e.data.pickup_yard,
        no_of_free_days:e.data.no_of_free_days,
        cha_do_planned:e.data.cha_do_planned,
        freight_forwarder:{'label':e.data.freight_forwarder,'value':e.data.freight_forwarder},
        duplicate_booking_no: e.data.booking_no,
        duplicate_do_valid_from:getHyphenDDMMYYYY(e.data.do_valid_from),
        duplicate_do_valid_to:getHyphenDDMMYYYY(e.data.do_valid_to),
        duplicate_shipping_line:e.data.tentative_shipping_line,
        duplicate_voyaga_no:e.data.voyage_no,
        duplicate_freight_forwarder:e.data.freight_forwarder,
        duplicate_vessel_name:e.data.vessel_name,
        duplicate_terminal:e.data.terminal,
        duplicate_pickup_yard:e.data.pickup_yard,
        duplicate_no_of_free_days:e.data.no_of_free_days,
        duplicate_vessel_etd_date:getHyphenDDMMYYYY(e.data.tentative_vessel_etd),
        duplicate_gate_open_date: getHyphenDDMMYYYYHHMM(e.data.gate_open_date),
        duplicate_port_cutoff_date: getHyphenDDMMYYYYHHMM(e.data.port_cutoff_date),
        duplicate_sl_cutoff_date: getHyphenDDMMYYYYHHMM(e.data.sl_cutoff_date)
        }))
        document.getElementById('port_cutoff_date1').value = getHyphenDDMMYYYYHHMM(e.data.tentative_cut_off_date);
        document.getElementById('gate_open_datetime1').value =getHyphenDDMMYYYYHHMM(e.data.tentative_gate_open_date);
        // document.getElementById('port_cutoff_date').value = getHyphenDDMMYYYY(e.data.port_cutoff_date);
        // document.getElementById('gate_open_datetime').value =getHyphenDDMMYYYY(e.data.gate_open_date);
         document.getElementById('sl_cutoff_date1').value = getHyphenDDMMYYYYHHMM(e.data.sl_cutoff_date);

    }
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const handleInputfilter = (e) => {
        const val = e.target.value.trim().toUpperCase(); // Trim and convert input value to uppercase
        const typeoffield = selectedval.dropdownval.value;
        let filteredData;

        if (typeoffield === 'work_order_no') {
            if (val !== '') {
                filteredData = originalData.filter((each) => {
                    return each.work_order_no.includes(val);
                });
            } else {
                filteredData = originalData;
            }
        } else {
            // Handle other field types if needed
        }

        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
        setrowData(filteredData);

    }
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))

    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if(val!='' && val!=undefined){
            if (typeoffield == 'ship_to_country') {
                var filteredData = originalData.filter(item => item.ship_to_country == val)
            }
            else if (typeoffield == 'work_order_no') {
                if (val != '' && val != undefined) {
                    filteredData = originalData.filter(item => item.work_order_no == val);
                }
                else {
                    filteredData = originalData
                }
            }
            else if (typeoffield == '') {
                if (val != '' && val != undefined) {
                    filteredData = originalData.filter(item => item.work_order_no == val);
                }
                else {
                    filteredData = originalData
                }
            }
            else if (typeoffield == 'pod') {
                var filteredData = originalData.filter(item => item.pod == val)
            }
            else if (typeoffield == 'plant') {
                var filteredData = originalData.filter(item => item.plant == val)
            }
            else if (typeoffield == 'brand') {
                var filteredData = originalData.filter(item => item.brand == val)
            }
            else if (typeoffield == 'variant') {
                var filteredData = originalData.filter(item => item.variant == val)
            }
            console.log(filteredData)
            setrowData(filteredData)
            setcheckresetfilter(1)

        }
        else{
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
        
    }
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        var to_date = woReleaseDate.wo_release_to_date
        if(from_date!='' && from_date!=undefined&&from_date!=null&&to_date!=''&&to_date!=undefined&&to_date!=null){
            from_date = moment(from_date, 'DD-MM-YYYY')
                to_date = moment(to_date, 'DD-MM-YYYY')
                var filteredData = originalData.filter(item => {
                    var itemDate = getHyphenDDMMYYYY(item.production_date)
                    itemDate = moment(itemDate, 'DD-MM-YYYY')
                    console.log(itemDate,'182')
                    return itemDate.isBetween(from_date, to_date, null, '[]')
                });
                setrowData(filteredData)
                setRefreshSelected(0);
                setcheckresetfilter(1);
        }
        else{
            setbasicTitle('Please Select Start Date and End date')
                setbasicType('danger')
                setoverly('show-n')
                setshow(true)
            }
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/chadonew' })
            .then(async (resp) => {
                console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                console.log("oCols",oCols)
                
                // setsummaryViewcols(oCols);
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const onGridState = () => {
        //console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/chadonew'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/chadonew',
                screentitle: screenpage
            }
            console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
            console.log('hit')
        }
        catch (e) {
            console.log(e)
        }
    }
    const onclickTab = (e) => {
        if (e == 'New') {
            setclassforTab({
                new:1,
                planned:0,
                modified:0
            })
            props.setcheckforbulk(true)

            setrowData(tabDataforDO.DO_new_plant)
            setoriginalData(tabDataforDO.DO_new_plant)
        }
        else if (e == 'Planned') {
            setclassforTab({
                new:0,
                planned:1,
                modified:0
            })
            props.setcheckforbulk(true)

            setrowData(tabDataforDO.DO_planned_plant)
            setoriginalData(tabDataforDO.DO_planned_plant)
        }
        else if (e == 'Modified') {
            setclassforTab({
                new:0,
                planned:0,
                modified:1
            })
            props.setcheckforbulk(false)

            setrowData(tabDataforDO.DO_modified_plant)
            setoriginalData(tabDataforDO.DO_modified_plant)
        }
    }
    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
          },
          {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
          },
          {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
          },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "trucks",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            valueGetter: function (params) {
                try {
                    console.log(ptd, "ptd");
                    console.log(params.data.payment_term, "params.data.payment_term");
                    if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                        let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                        if (temp)
                            return temp.payment_term + " - " + temp.description
                        else
                            return params.data.payment_term
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            width: 350,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Tentative Shipping Line",
        //     field: "tentative_shipping_line",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
       

        // {
        //     headerName: "Tentative Gate Open Date",
        //     field: "tentative_gate_open_date",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }

        //         catch (e) { }
        //     },
        // },
        // {
        //     headerName: "Tentative Cut Off Date",
        //     field: "tentative_cut_off_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Remarks (By CHA)",
            field: "remarks_by_cha",
            width: 160,
            filter: true,
            resizable: true,
        },
    ]
    const onsubmitdata1=(e)=>{
        e.preventDefault()

        //     var data = [{
        //         work_order_no: selected_row.work_order_no,
        //         booking_no:manualUploadDetails.booking_no,
        //         do_valid_from:manualUploadDetails.do_valid_from,
        //         do_valid_to:manualUploadDetails.do_valid_to,
        //         tentative_shipping_line:manualUploadDetails.shipping_line.value,
        //         voyage_no:manualUploadDetails.voyaga_no,
        //         freight_forwarder:manualUploadDetails.freight_forwarder.value,
        //         vessel_name:manualUploadDetails.vessel_name,
        //         terminal:manualUploadDetails.terminal.value,
        //         pickup_yard:manualUploadDetails.pickup_yard,
                
        //         // sl_cutoff_date:document.getElementById("sl_cutoff_date").value
    
        //         cha_do_planned:1
        //     }]
          
        // var rparams = {
        //     data: data,
        // }
        e.preventDefault()
        var saveData = new FormData()
        console.log("S1",manualUploadDetails.do_copy)
        saveData.append("work_order_no",selected_row.work_order_no)
        saveData.append("booking_no",manualUploadDetails.booking_no)
        saveData.append("do_valid_from",manualUploadDetails.do_valid_from)
        saveData.append("do_valid_to",manualUploadDetails.do_valid_to)
        saveData.append("shipping_line",manualUploadDetails.shipping_line.value)
        saveData.append("voyage_no",manualUploadDetails.voyaga_no)
        saveData.append("freight_forwarder",manualUploadDetails.freight_forwarder.value)
        saveData.append("vessel_name",manualUploadDetails.vessel_name)
        saveData.append("vessel_etd_date",manualUploadDetails.vessel_etd_date)
        saveData.append("terminal",manualUploadDetails.terminal.value)
        saveData.append("pickup_yard",manualUploadDetails.pickup_yard)
        saveData.append('no_of_free_days',manualUploadDetails.no_of_free_days)
        saveData.append("do_copy",manualUploadDetails.do_copy)
        saveData.append("sl_cutoff_date",document.getElementById("sl_cutoff_date1").value)
        saveData.append("port_cutoff_date",document.getElementById("port_cutoff_date1").value)
        saveData.append("gate_open_date",document.getElementById("gate_open_datetime1").value)
        saveData.append('do_remarks',manualUploadDetails.do_remarks)     
        saveData.append("cha_do_planned",manualUploadDetails.cha_do_planned) 
        
            redirectURL.post('/consignments/updatedoplanned', saveData).then((resp) => {

                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("DO Details has been successfully updated")
                    setbasicType("success")
                    setclassforTab(
                        {
                            planned: 0,
                            new: 1,
                            modified: 0,
                        }
                    )
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm1('')
                    setmanualUploadDetails(prev=>({
                        ...prev,
                        wrno: {label:'',value:''},
                        booking_no: '',
                        do_valid_from:'',
                        do_valid_to:'',
                        shipping_line:'',
                        voyaga_no:'',
                        freight_forwarder:'',
                        vessel_name:'',
                        vessel_etd_date:'',
                        do_copy:'',
                        terminal:'',
                        pickup_yard:'',
                        no_of_free_days:''
                    }))
                    document.getElementById('do_copy').value = '';
                    document.getElementById('port_cutoff_date1').value = '';
                    document.getElementById('sl_cutoff_date1').value = '';
                    document.getElementById('gate_open_datetime1').value = '';
                    props.onRequest('uploadDO')
                }
                else {
                    setshow(true)
                     setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
    }
    const onsubmitdata2=(e)=>{
        e.preventDefault()
        console.log(e, "eee",editManualUploadDetails)
        console.log(selected_row, "selected_rowww")
    //    if (selected_row.ship_to_country_changes_before_container_pickup == 1 || selected_row.cha_changes_before_container_pickup==1 || selected_row.pod_changes_before_container_pickup == 1 ){
    //     var data = [{
    //         work_order_no: selected_row.work_order_no,
    //         booking_no:editManualUploadDetails.booking_no,
    //         do_valid_from:editManualUploadDetails.do_valid_from,
    //         do_valid_to:editManualUploadDetails.do_valid_to,
    //         tentative_shipping_line:editManualUploadDetails.shipping_line.value,
    //         voyage_no:editManualUploadDetails.voyaga_no,
    //         freight_forwarder:editManualUploadDetails.freight_forwarder.value,
    //         vessel_name:editManualUploadDetails.vessel_name,
    //         terminal:editManualUploadDetails.terminal.value,
    //         pickup_yard:editManualUploadDetails.pickup_yard,
    //         // sl_cutoff_date:document.getElementById("sl_cutoff_date").value
    //         // sl_cutoff_date: document.getElementById("sl_cutoff_date2").value,
    //         // port_cutoff_date: document.getElementById("port_cutoff_date2").value,
    //         // gate_open_date: document.getElementById("gate_open_datetime2").value,

    //         cha_do_modified:1
            
    //     }]
    //     var rparams ={
    //         data:data,
    //         row: selected_row
    //     }
    //     redirectURL.post('/consignments/updatedata', rparams).then((resp) => {
    //         if (resp.data.status == 'Success') {
    //             setshow(true)
    //             setbasicTitle("DO Details has been successfully updated")
    //             setbasicType("success")
    //             setclassforTab(
    //                 {
    //                     planned: 0,
    //                     new: 1,
    //                     modified: 0,
    //                 }
    //             )
    //             setloadshow('show-n')
    //             setoverly('show-n')
    //             setshowAddForm2('')
    //             setEditManualUploadDetails(prev=>({
    //                 ...prev,
    //                 wrno: {label:'',value:''},
    //                 booking_no: '',
    //                 do_valid_from:'',
    //                 do_valid_to:'',
    //                 shipping_line:'',
    //                 voyaga_no:'',
    //                 freight_forwarder:'',
    //                 vessel_name:'',
    //                 vessel_etd_date:'',
    //                 do_copy:'',
    //                 terminal:'',
    //                 pickup_yard:''
    //             }))
    //             document.getElementById('do_copy1').value = '';
    //             document.getElementById('port_cutoff_date').value = '';
    //             document.getElementById('gate_open_datetime').value = '';
    //             props.onRequest('uploadDO')
    //         }
    //         else {
    //             setshow(true)
    //              setbasicTitle(resp.data.message)
    //             setbasicType("danger")
    //             setloadshow('show-n')
    //             setoverly('show-n')
    //         }
    //     })
    // }   
    if (selected_row.container_count_increase_check == 1 || selected_row.ship_to_country_changes_before_container_pickup == 1 || selected_row.cha_changes_before_container_pickup==1 || selected_row.pod_changes_before_container_pickup == 1 ){

        console.log("increase_check")
        console.log(editManualUploadDetails,"editManualUploadDetails")
        var saveData = new FormData()
        console.log("S1",editManualUploadDetails.additional_do_copy)
        saveData.append("work_order_no",selected_row.work_order_no)
        saveData.append("booking_no",editManualUploadDetails.booking_no)
        saveData.append("do_valid_from",editManualUploadDetails.do_valid_from)
        saveData.append("do_valid_to",editManualUploadDetails.do_valid_to)
        saveData.append("shipping_line",editManualUploadDetails.shipping_line.value)
        saveData.append("voyage_no",editManualUploadDetails.voyaga_no)
        saveData.append("freight_forwarder",editManualUploadDetails.freight_forwarder.value)
        saveData.append("vessel_name",editManualUploadDetails.vessel_name)
        saveData.append("vessel_etd_date",editManualUploadDetails.vessel_etd_date)
        saveData.append("terminal",editManualUploadDetails.terminal.value)
        saveData.append("pickup_yard",editManualUploadDetails.pickup_yard)
        saveData.append('no_of_free_days',editManualUploadDetails.no_of_free_days)


        // saveData.append("additional_do_copy",editManualUploadDetails.additional_do_copy)

        // let additional_do_copy_arr = [];
 
        if (editManualUploadDetails.additional_do_copy && editManualUploadDetails.additional_do_copy?.length > 0) {
            for (let i = 0; i < editManualUploadDetails.additional_do_copy.length; i++) {
                let temp = editManualUploadDetails.additional_do_copy[i];
                saveData.append(`additional_do${i}`, temp);
        
                // additional_do_copy_arr.push({ formFileKey: `additional_do_copy-${i}`});
            }
        }
        else{

            if (additionalFiles !== '' && additionalFiles?.length > 0){
                for (let i = 0; i < additionalFiles.length; i++) {
                    let temp = additionalFiles[i];
                    saveData.append(`additionalcopy${i}`, temp);        
                    // additional_do_copy_arr.push({ formFileKey: `additional_do_copy-${i}`});
                }
                saveData.append(`additionl_do_copy_arr`, JSON.stringify(editManualUploadDetails.additionl_do_copy_arr));        
            }
        }

        // saveData.append("additional_do_copy_arr",JSON.stringify(additional_do_copy_arr))


        saveData.append("sl_cutoff_date",document.getElementById("sl_cutoff_date2").value)
        saveData.append("port_cutoff_date",document.getElementById("port_cutoff_date2").value)
        saveData.append("gate_open_date",document.getElementById("gate_open_datetime2").value)
        saveData.append('do_remarks',editManualUploadDetails.do_remarks)      
        // saveData.append('row',selected_row)
        saveData.append('cha_do_modified',1)
        var sl_cutoff_date_ = document.getElementById("sl_cutoff_date2").value
        var port_cutoff_date_ = document.getElementById("port_cutoff_date2").value 
        var gate_open_datetime_ = document.getElementById("gate_open_datetime2").value
        console.log(saveData, "saveData")
        console.log(gate_open_datetime_,'gate_open_datetime_')
        console.log(editManualUploadDetails.additional_do_copy, '176')

        for (let [key, val] of saveData.entries()) {
            console.log(key, " - ", val);
          }

        var rparams = {
            data : saveData,
            row : selected_row
        }
        redirectURL.post('/consignments/updatedodata', saveData).then((resp) => {
                // let response = JSON.parse(resp.data)
                if (resp.data.status == 'Success') {
                    console.log("respponseeee");
                    setshow(true)
                    setbasicTitle("DO Details has been successfully updated")
                    setbasicType("success")
                    props.onRequest()

                    setclassforTab(
                        {
                            planned: 0,
                            new: 1,
                            modified: 0,
                        }
                    )
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm2('')
                    setEditManualUploadDetails(prev=>({
                        ...prev,
                        wrno: {label:'',value:''},
                        booking_no: '',
                        do_valid_from:'',
                        do_valid_to:'',
                        shipping_line:'',
                        voyaga_no:'',
                        freight_forwarder:'',
                        vessel_name:'',
                        vessel_etd_date:'',
                        additional_do_copy:'',
                        terminal:'',
                        pickup_yard:''
                    }))
                    document.getElementById('additional_do_copy').value = '';
                    document.getElementById('port_cutoff_date2').value = '';
                    document.getElementById('gate_open_datetime2').value = '';
                }
                else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
    }
           
    }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var saveData = new FormData()
        console.log("S1",manualUploadDetails.do_copy)
        saveData.append("work_order_no",selected_row.work_order_no)
        saveData.append("booking_no",manualUploadDetails.booking_no)
        saveData.append("do_valid_from",manualUploadDetails.do_valid_from)
        saveData.append("do_valid_to",manualUploadDetails.do_valid_to)
        saveData.append("shipping_line",manualUploadDetails.shipping_line.value)
        saveData.append("voyage_no",manualUploadDetails.voyaga_no)
        saveData.append("freight_forwarder",manualUploadDetails.freight_forwarder.value)
        saveData.append("vessel_name",manualUploadDetails.vessel_name)
        saveData.append("vessel_etd_date",manualUploadDetails.vessel_etd_date)
        saveData.append("terminal",manualUploadDetails.terminal.value)
        saveData.append("pickup_yard",manualUploadDetails.pickup_yard)
        saveData.append('no_of_free_days',manualUploadDetails.no_of_free_days)
        saveData.append("do_copy",manualUploadDetails.do_copy)
        saveData.append("sl_cutoff_date",document.getElementById("sl_cutoff_date").value)
        saveData.append("port_cutoff_date",document.getElementById("port_cutoff_date").value)
        saveData.append("gate_open_date",document.getElementById("gate_open_datetime").value)
        saveData.append('do_remarks',manualUploadDetails.do_remarks)      
          console.log("S3")
        var sl_cutoff_date_ = document.getElementById("sl_cutoff_date").value
        var port_cutoff_date_ = document.getElementById("port_cutoff_date").value 
        var gate_open_datetime_ = document.getElementById("gate_open_datetime").value
        console.log(gate_open_datetime_,'gate_open_datetime_')
        console.log(manualUploadDetails.do_copy, '176')

        if(manualUploadDetails.vessel_etd_date!='' && manualUploadDetails.vessel_etd_date!=undefined&&manualUploadDetails.do_valid_from!='' && 
        manualUploadDetails.do_valid_from!=undefined&&manualUploadDetails.do_valid_to!='' && 
        manualUploadDetails.do_valid_to!=undefined && sl_cutoff_date_!='__-__-____ __:__' && sl_cutoff_date_!=undefined&&port_cutoff_date_!='__-__-____ __:__' 
        && port_cutoff_date_!=undefined&&
        gate_open_datetime_!='__-__-____ __:__' && gate_open_datetime_!=undefined&& manualUploadDetails.do_copy
!='' &&       manualUploadDetails.do_copy   !=undefined){
            console.log(saveData, "saveData");
            redirectURL.post('/consignments/uploadBulkuploadforUploadDO', saveData).then((resp) => {
                console.log("saveData111");
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("DO Details has been successfully updated!")
                    setbasicType("success")
                    setclassforTab(
                        {
                            planned: 0,
                            new: 1,
                            modified: 0,
                        }
                    )
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm('')
                    setmanualUploadDetails(prev=>({
                        ...prev,
                        wrno: {label:'',value:''},
                        booking_no: '',
                        do_valid_from:'',
                        do_valid_to:'',
                        shipping_line:'',
                        voyaga_no:'',
                        freight_forwarder:'',
                        vessel_name:'',
                        vessel_etd_date:'',
                        do_copy:'',
                        terminal:'',
                        pickup_yard:''
                    }))
                    document.getElementById('do_copy').value = '';
                    document.getElementById('port_cutoff_date').value = '';
                    document.getElementById('gate_open_datetime').value = '';
                    props.onRequest('uploadDO')
                    setreloadcheck(true)
                }
                else {
                    setshow(true)
                     setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
        }
        else{
            setshow(true)
                    setbasicTitle('Please Enter All Manditory Fields!')
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
        }
        console.log(reloadcheck,'615')

    }
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Tentative shipping line', inputName: 'tentative_shipping_line', required: true },
            { name: 'Tentative vessel ETD', inputName: 'tentative_vessel_etd', required: true },
            { name: 'Tentative Gate Open Date', inputName: 'gate_open_date', required: true },
            { name: 'Tentative cut off date', inputName: 'tentative_cut_off_date', required: true },
            { name: 'Remarks(by CHA)', inputName: 'remarks', required: true },

        ])
        console.log(csvdata, '1234')
        setcsvcontent(csvdata)
    }
    const onSelectWON = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: { label: e.value, value: e.value }
        }))
    }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                data: csvcontent,
            }
            redirectURL.post('/consignments/uploadBulkuploadforCHAdata', rparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                else {
                    setshow(true)
                    // setbasicTitle("Successfully Uploaded")
                    setbasicType("Failure")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                console.log(resp.data)
            })
        }
    }
    const onClickHide = () => {
        setsliderForBulkupload('')
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno: {label:'',value:''},
            booking_no: '',
            do_valid_from:'',
            do_valid_to:'',
            shipping_line:'',
            voyaga_no:'',
            freight_forwarder:'',
            vessel_name:'',
            vessel_etd_date:'',
            do_copy:'',
            terminal:'',
            pickup_yard:''
        }))
        document.getElementById('do_copy').value = '';
        document.getElementById('port_cutoff_date').value = '';
        document.getElementById('gate_open_datetime').value = '';


    }
    const onClickHideManualUpload1 = () => {
        setshowAddForm1('')
        setoverly('show-n')
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno: {label:'',value:''},
            booking_no: '',
            do_valid_from:'',
            do_valid_to:'',
            shipping_line:'',
            voyaga_no:'',
            freight_forwarder:'',
            vessel_name:'',
            vessel_etd_date:'',
            do_copy:'',
            terminal:'',
            pickup_yard:''
        }))
        document.getElementById('do_copy').value = '';
        document.getElementById('port_cutoff_date').value = '';
        document.getElementById('gate_open_datetime').value = '';


    }
    const onClickHideManualUpload2 = () => {
        setshowAddForm2('')
        setoverly('show-n')
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno: {label:'',value:''},
            booking_no: '',
            do_valid_from:'',
            do_valid_to:'',
            shipping_line:'',
            voyaga_no:'',
            freight_forwarder:'',
            vessel_name:'',
            vessel_etd_date:'',
            do_copy:'',
            terminal:'',
            pickup_yard:''
        }))
        document.getElementById('do_copy').value = '';
        document.getElementById('port_cutoff_date').value = '';
        document.getElementById('gate_open_datetime').value = '';


    }
    const onClickHideManualUpload3 = () => {
        setshowAddForm3('')
        setoverly('show-n')
       


    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var onclickbulkupload = () => {
        setsliderForBulkupload("slider-translate")
        setoverly('show-m')
    }
    const onClickCounters = (props) => {
        if (props == 'vesselAvailability') {
            setcheck(1)
            setIsClicked({ vessel_avail: true })
        }
        if (props == 'uploadDO') {
            setcheck(2)
            setIsClicked({ uploadDo: true })
        }
        else {
            setIsClicked(false)
        }
    }
    var sDate = $("#wo_order_date").val()
    console.log(manualUploadDetails, 'manualUploadDetails')
    const onClickDOdetails=(params)=>{
        console.log('163',params)
        setshowAddForm3("slide33")
        console.log(params.data.attachmentFileName)
        setwrdo(params.data.work_order_no)
        console.log(params.data.additionl_do_copy_arr);
        setFile1({file1:params.data.uploaded_file,
            file2:params.data.additionl_do_copy_arr
        })

        // if(params.data.attachmentFileName !=undefined && params.data.attachmentFileName !="")
        // {
        //     var fileparams = {
        //         attachmentFileName : params.data.attachmentFileName,
        //         work_order_no:params.data.work_order_no
        //     }
        //     redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
        //     }).then((response)=>{
        //         const imageURL = response.data;
        //          window.open(imageURL, '_blank');
        //     }).catch(function(error){
        //         console.log(error);
        //     })
        // }
    }
    const onchangeFileHandler =(e)=>{
        var check = e.target.files[0].name
        var file_check = check.split(".")
        var next_check = file_check[file_check.length -1]
        console.log(e.target.files[0],"adfdfadfa")
            if(next_check == "pdf" || next_check == "png" || next_check == "jpeg"){
                setmanualUploadDetails(prev => ({ ...prev, do_copy: e.target.files[0] }))
            }else{
                setbasicTitle("Please Upload pdf or png File")
                setbasicType("warning")
                setshow(true)
                $("#do_copy").val("")
            }
        }
        const onchangeFileHandler1 =(e)=>{
            var check = e.target.files // []
            
            // var next_check = file_check[file_check.length -1]
            // validationn
            if (check.length <= 5 ){
                for (let i of check){
                    // console.log(i)
                    var next_check;
                    var file_check = i.name.split(".")
                    next_check = file_check[file_check.length -1] // 
                    if (!(next_check == "pdf" || next_check == "png" || next_check == "jpeg")){
                        setbasicTitle("Please Upload pdf or png File")
                        setbasicType("warning")
                        setshow(true)
                        $("#additional_do_copy").val("")
                        return
                    }
                }
                console.log("test");
                console.log(e.target.files[0],"adfdfadfa")
                // setEditManualUploadDetails(prev => ({ ...prev, additional_do_copy: e.target.files }));

                setEditManualUploadDetails(prev => ({ ...prev, additional_do_copy: e.target.files }));
                // let temp = editManualUploadDetails.additional_do_copy
                // for (let i of check){
                //     temp.push(i)
                // }
                // setEditManualUploadDetails(prev => ({
                //     ...prev,
                //     additional_do_copy: temp
                // }));
            }else{
                setbasicTitle("Maximum limit of uploaded files are 5")
                setbasicType("warning")
                setshow(true)
                $("#additional_do_copy").val("")
            }
            

            

            // if(next_check == "pdf" || next_check == "png" || next_check == "jpeg"){
            // }else{
            //     // setbasicTitle("Please Upload pdf or png File")
            //     // setbasicType("warning")
            //     // setshow(true)
            //     // $("#additional_do_copy").val("")
            // }
        }
        
        const onchangeFileHandler2 =(e)=>{
            var check = e.target.files // []
            
            // var next_check = file_check[file_check.length -1]
            // validationn
            if (check.length <= 5 ){
                for (let i of check){
                    // console.log(i)
                    var next_check;
                    var file_check = i.name.split(".")
                    next_check = file_check[file_check.length -1] // 
                    if (!(next_check == "pdf" || next_check == "png" || next_check == "jpeg")){
                        setbasicTitle("Please Upload pdf or png File")
                        setbasicType("warning")
                        setshow(true)
                        $("#additional_do_copy").val("")
                        return
                    }
                }
                console.log("test2");
                console.log(e.target.files[0],"adfdfadfa22")
                // setEditManualUploadDetails(prev => ({ ...prev, additional_do_copy: e.target.files }));

                
                setAdditionalFiles(check);
                // let temp = editManualUploadDetails.additional_do_copy
                // for (let i of check){
                //     temp.push(i)
                // }
                // setEditManualUploadDetails(prev => ({
                //     ...prev,
                //     additional_do_copy: temp
                // }));
            }else{
                setbasicTitle("Maximum limit of uploaded files are 5")
                setbasicType("warning")
                setshow(true)
                $("#additional_do_copy").val("")
            }
            

            

            // if(next_check == "pdf" || next_check == "png" || next_check == "jpeg"){
            // }else{
            //     // setbasicTitle("Please Upload pdf or png File")
            //     // setbasicType("warning")
            //     // setshow(true)
            //     // $("#additional_do_copy").val("")
            // }
        }
        var upload_do =  {
            headerName: "Update",
            field: "upload_do",
            width: 80,
            pinned: 'left',
            hide:function(params){
                return params.data.cha_do_new!=1
            },
            cellRenderer: 'UpdateUploadDOBtn'
        } 
        var upload_do1 =  {
            headerName: "Update",
            field: "",
            width: 110,
            pinned: 'left',
            cellRenderer: 'UpdateUploadDOBtn1'
        } 
        var acknowledgebtn= {
            headerName: "",
            field: "",
            width: 120,
            filter: true, resizable: true,
            hide:false,
            pinned: 'left',
            cellRendererFramework: AcknowledgeBtnComponent
        }
        var acknowledgebtn1 = {
            headerName: "",
            field: "update_acknowledge_btn_component",
            width: 120,
            filter: true,
            hide:false,
            resizable: true,
            pinned: "left",
            cellRendererFramework: UpdateAcknowledgeBtnComponent,
          };
        var updatedfields = [
            {
                headerName: "Booking No",
                field: "booking_no",
                width: 200,
                filter: true,
                resizable: true,
            },
    
            {
                headerName: "DO Valid From",
                field: "do_valid_from",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.do_valid_from != "" && params.data.do_valid_from != undefined) {
                            return getHyphenDDMMYYYY(params.data.do_valid_from);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "DO Valid To",
                field: "do_valid_to",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.do_valid_to != "" && params.data.do_valid_to != undefined) {
                            return getHyphenDDMMYYYY(params.data.do_valid_to);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Gate Open Date",
                field: "gate_open_date",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_open_date != "" && params.data.gate_open_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.gate_open_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "",
                field: "",
                hide:true,
            },
            {
                headerName: "Gate Open Time",
                field: "",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_open_date != "" && params.data.gate_open_date != undefined) {
                            return getHHMM(params.data.gate_open_date);

                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Port Cut Off Date",
                field: "port_cutoff_date",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.port_cutoff_date != "" && params.data.port_cutoff_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.port_cutoff_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Port Cut Off Time",
                field: "",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.port_cutoff_date != "" && params.data.port_cutoff_date != undefined) {
                            return getHHMM(params.data.port_cutoff_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "SI Cut Off Date",
                field: "sl_cutoff_date",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.sl_cutoff_date != "" && params.data.sl_cutoff_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.sl_cutoff_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "SI Cut Off Time",
                field: "",
                width: 190,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.sl_cutoff_date != "" && params.data.sl_cutoff_date != undefined) {
                            return getHHMM(params.data.sl_cutoff_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Terminal",
                field: "terminal",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Voyage No",
                field: "voyage_no",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Shipping Line",
                field: "shipping_line",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Freight Forwarder",
                field: "freight_forwarder",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "No Of Free Days",
                field: "no_of_free_days",
                width: 200,
                filter: true,
                resizable: true,
            },
         
            {
                headerName: "Vessel Name",
                field: "vessel_name",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Pickup Yard",
                field: "pickup_yard",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "DO Remarks",
                field: "do_remarks",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "DO Copy",
                field: "",
                width: 100,
                resizable: true,
                cellRenderer:ViewDOdetailsComponent
            },
        ]
        var newCount = tabDataforDO.DO_new_plant
        var plannedCount = tabDataforDO.DO_planned_plant
        var modifiedCount = tabDataforDO.DO_modified_plant
        console.log(classForTab,'333')
        function restrictToNumbers(event) {
            // Remove non-numeric characters from the input value
            event.target.value = event.target.value.replace(/[^0-9]/g, '');
        }
        var columns1 = [...summaryViewcols];
        if(classForTab.new==1){
            columns1.push(upload_do)
            detailedviewcols.push(upload_do)
        }
        if(classForTab.planned==1){
            columns1.push(upload_do1,...updatedfields)
            detailedviewcols.push(upload_do1,...updatedfields)
        }
        if(classForTab.modified==1){
            columns1.push(acknowledgebtn,acknowledgebtn1,...updatedfields,upload_do1)
            detailedviewcols.push(acknowledgebtn,acknowledgebtn1,...updatedfields,upload_do1)
        }
        var columnwithDefs = summaryviewcheck == 1 ? columns1 : detailedviewcols
        console.log(columnwithDefs,'1403')
        columnwithDefs.forEach(e => {
            e.cellClass = function (params) {
                const fieldModified = `${e.field}_modified`;
                if (params.data && params.data[fieldModified] === 1) {
                    return "bgColorDangerDark";
                } else {
                    return null;
                }
            };
        });
        // if(props.check==2){
        //     var validCols = ["Work Order No", "Freight Forwarder", "No Of Free Days", "Tentative shipping line", "Tentative vessel ETD"]
        //     var bulkuploadfields = ["Work Order No"]
        //     var apiforupload = 'uploadBulkuploadforUploadDO'
        //     props.uploadbulkdata(validCols,bulkuploadfields,apiforupload)
        // }
        

    const refreshData1=()=>{
            setcheckresetfilter(0);
            setrowData(originalData);
            setRefreshSelected(1);
            console.log("refreshclickfunc")
     }
     var downloadBtn = "#1976d2";
     var  cursorPoint = "pointer";

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>

            <div className="card-summary" style={{marginLeft:"10px", marginRight:"5px"}}>
                {props.check == 2 ?
                        <div className="d-flex flex-row">
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: summaryviewcheck == 1 ? '#0664AE' : "",
                                    // color: state.isTileView ? "": "#0664AE",
                                    borderTopLeftRadius: "11px",
                                    borderBottomLeftRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('summary view')}
                            >
                                <div
                                    style={{
                                        color: summaryviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Summary View
                                </div>
                            </button>

                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: detailedviewcheck == 1 ? '#0664AE' : "",
                                    borderTopRightRadius: "11px",
                                    borderBottomRightRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('detailed view')}
                            >
                                <div
                                    style={{
                                        color: detailedviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Detailed View
                                </div>
                            </button>

                    </div> : ''}
                {props.check == 2 ?

                    <div className="margin-view">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                            ...provided,
                                            fontSize: "0.8rem",
                                            color: "#6e707e",
                                            borderRadius:"11px",
                                            height:"48px",
                                            width:"260px",
                                            padding:"3px",
                                            textAlign:"center"
                                        }),
                                        singleValue:(provided,state)=>({
                                            ...provided,
                                            fontSize:'0.9rem'
                                        })
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                    </div> : ''}
                {checkinput == 1 ?
                <div className="margin-view" style={{position:"relative", width:"200px"}}

                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                         {checkresetfilter != 1 ?
                            <img
                                src={SearchIcon}
                                className=""
                                style={{position:"absolute", right:"10px", top:"18px"}}
                                onClick={filterData}
                            /> : <img
                                src={RefreshIcon}
                                className=""
                                style={{position:"absolute", right:"10px", top:"18px"}}
                                onClick={refreshData}
                            />}
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="margin-view" style={{whiteSpace:"nowrap"}}>  <Datepicker id='production_date' onDatesSelected={handleDatesSelected}  onRefreshSelected={refreshSelected}/> 
                    {checkresetfilter != 1 ?
                        <img
                            src={SearchIcon}
                            className="search_icon_for_dates pointer"
                            onClick={filterDatafordates}
                        /> 
                        : <img
                            src={RefreshIcon}
                            className="search_icon_for_dates pointer"
                            onClick={refreshData1}
                        />}</div>

                    : ""}
                {/* {props.check == 2 ?
                    <span className="float-right mt-24p" >
                        <button className='btn_style' onClick={() => manualbulkUploadForCha()} >Update CHA Upload DO</button>
                    </span>


                    : ''} */}
            </div>

            {props.check == 2 ?

            <div className="" style={{ margin: '10px', marginTop: '20px' }}>
                    <div className="d-flex flex-row" style={{position:"relative"}}>
                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: classForTab.new == 1 ? '#0664AE' : '#FFFFFF',
                                        // color: state.isTileView ? "": "#0664AE",
                                        borderTopLeftRadius: "11px",
                                        borderBottomLeftRadius: "11px",
                                        border: "1px solid #0664AE",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('New')}
                                >
                                    <div
                                        style={{
                                            color: classForTab.new == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        New - <span>{(newCount!=''&&newCount!=undefined)?newCount.length:0}</span>
                                    </div>
                                </button>

                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: classForTab.planned == 1 ? '#0664AE' : "#FFFFFF",
                                        border: "1px solid #0664AE",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('Planned')}
                                >
                                    <div
                                        style={{
                                            color: classForTab.planned == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        Planned - <span>{(plannedCount!=''&&plannedCount!=undefined)?plannedCount.length:0}</span>
                                    </div>
                                </button>
                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: classForTab.modified == 1 ? '#0664AE' : "#FFFFFF",
                                        borderTopRightRadius: "11px",
                                        borderBottomRightRadius: "11px",
                                        border: "1px solid #0664AE",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('Modified')}
                                >
                                    <div
                                        style={{
                                            color: classForTab.modified == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        Modified - <span>{(modifiedCount!=''&&modifiedCount!=undefined)?modifiedCount.length:0}</span>
                                    </div>
                                </button>
                        {(classForTab.new===1 && summaryviewcheck===1)?
                         <div style={{position:"absolute", right:"3px",top:"-25px" }}>
                            <div className='btn_hover' style={{ height: "35px", marginTop: "32px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding:"5px" }} onClick={onClickSaveGridState}>
                             <i className="icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                         </div>
                        </div>
                     :''}
                            </div>
                            {/* <div style={{display:'flex',justifyContent:'end'}}>

                            <p style={{color:'red'}}>Note: Modification Pending At Previous Action</p>
                    </div> */}
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                         ref={gridApi}
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            // paddingTop={10}
                            // rowHeight={70}
                            // headerHeight = {70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            frameworkComponents={{CountryFlagComponent:CountryFlagComponent,UpdateUploadDOBtn:UpdateUploadDOBtn,UpdateUploadDOBtn1:UpdateUploadDOBtn1,ViewDOdetailsComponent:ViewDOdetailsComponent,AcknowledgeBtnComponent:AcknowledgeBtnComponent}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            onGridReady={onGridReady}
                            onGridState={onGridState}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    // {
                                    //     statusPanel: "agTotalAndFilteredRowCountComponent",
                                    //     align: "left"
                                    // },
                                    // {
                                    //     statusPanel: "agTotalRowCountComponent",
                                    //     align: "center"
                                    // },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                  },
                            }}
                            // ref={gridRef}
                            getRowStyle={getRowStyle}   
                            context={{onClickuploadDO1,onClickuploadDO,onClickDOdetails,onClickAcknowledgeBtn,onClickupdateAcknowledgeBtn}}
                        />
                    </div>
                </div> : ''}

            <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/cha_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Tentative Shipment Line & Remarks should be in 'String' format. <br />
                                Tentative Vessel ETD & Tentative Gate Open Date & Tentative cut off date should be in 'DD-MM-YYYY' format</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter DO Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <p className='mb-5p'>Work Order No: {selected_row.work_order_no} </p>
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Booking No <span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="booking_no"
                                            id="booking_no"
                                            value={manualUploadDetails.booking_no}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, booking_no: e.target.value }))}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid From<span className="err-txt">*</span></label>

                                        <Datetime 
                                             key={manualUploadDetails.do_valid_from}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_from"
                                            value={manualUploadDetails.do_valid_from}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_from'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, do_valid_from: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid To<span className="err-txt">*</span></label>
                                        <Datetime
                                             key={manualUploadDetails.do_valid_to}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_to"
                                            value={manualUploadDetails.do_valid_to}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_to'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, do_valid_to: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Shipping Line<span className="err-txt">*</span></label>
                                        {/* <input
                                            type="text"
                                            name="shipping_line"
                                            id="shipping_line"
                                            value={manualUploadDetails.shipping_line}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, shipping_line: e.target.value }))}
                                            required
                                        /> */}
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.shipping_line}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, shipping_line: e }))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={shippinglist1}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Freight Forwarder<span className="err-txt">*</span></label>
                                        {/* <input
                                            type="text"
                                            name="freight_forwarder"
                                            id="freight_forwarder"
                                            value={manualUploadDetails.freight_forwarder}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e.target.value }))}
                                            required
                                        /> */}
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.freight_forwarder}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e }))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={frieghtlist}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel Name<span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="vessel_name"
                                            id="vessel_name"
                                            value={manualUploadDetails.vessel_name}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, vessel_name: e.target.value }))}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Voyage No<span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="voyaga_no"
                                            id="voyaga_no"
                                            value={manualUploadDetails.voyaga_no}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, voyaga_no: e.target.value }))}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel ETD (date)<span className="err-txt">*</span></label>
                                        <Datetime
                                             key={manualUploadDetails.vessel_etd_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="vessel_etd_date"
                                            value={manualUploadDetails.vessel_etd_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='vessel_etd_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, vessel_etd_date: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Gate Open Datetime<span className="err-txt">*</span></label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="gate_open_datetime"
                                            value={manualUploadDetails.gate_open_datetime}
                                            dateFormat="DD-MM-YYYY"
                                            id='gate_open_datetime'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, gate_open_datetime: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="gate_open_datetime" />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Port cut off date<span className="err-txt">*</span></label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="port_cutoff_date"
                                            value={manualUploadDetails.port_cutoff_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='port_cutoff_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, port_cutoff_date: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="port_cutoff_date" />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>SI Cut Off Date<span className="err-txt">*</span></label>
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="sl_cutoff_date" />


                                    </div>

                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Terminal<span className="err-txt">*</span></label>
                                        {/* <input
                                            type="text"
                                            name="terminal"
                                            // placeholder='Enter Partner Type'
                                            id="terminal"
                                            value={manualUploadDetails.terminal}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, terminal: e.target.value }))}
                                            required
                                        /> */}
                                         <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.terminal}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, terminal: e }))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={terminallist}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Pickup Yard<span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="pickup_yard"
                                            // placeholder='Enter Partner Type'
                                            id="pickup_yard"
                                            value={manualUploadDetails.pickup_yard}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, pickup_yard: e.target.value }))}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>No Of Free Days <span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="no_of_free_days"
                                            id="no_of_free_days"
                                            value={manualUploadDetails.no_of_free_days}
                                            className="form-control"
                                            maxlength="2"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, no_of_free_days: e.target.value }))}
                                            onInput = {e=> restrictToNumbers(e)}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>    
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Remarks<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="newPartnerType"
                                            // placeholder='Enter Partner Type'
                                            id="inputcode"
                                            value={manualUploadDetails.do_remarks}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, do_remarks: e.target.value }))}
                                        />
                                     </div>
                                   
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Copy<span className="err-txt">*</span></label>
                                        <input type="file" name="do_copy" id="do_copy" className="form-control requestFields" onChange={(e)=>onchangeFileHandler(e)} />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"sliderBlock2 " + showAddForm1} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Update DO Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload1} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata1}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <p className='mb-5p'>Work Order No: {selected_row.work_order_no} </p>
                                        {/* <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workordernoList}
                                        /> */}
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Booking No: <span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="booking_no"
                                            id="booking_no"
                                            value={manualUploadDetails.booking_no}
                                            className="form-control"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid From<span className="err-txt"></span></label>

                                        <Datetime 
                                             key={manualUploadDetails.do_valid_from}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_from"
                                            value={manualUploadDetails.do_valid_from}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_from'
                                            timeFormat={false}
                                            onChange={changeDOValidFrom
                                                // (e) => setonChangeManualUploadDetails(prev => ({ ...prev, do_valid_from: changeHandleDate(e) }))
                                            }
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid To<span className="err-txt"></span></label>
                                        <Datetime
                                             key={manualUploadDetails.do_valid_to}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_to"
                                            value={manualUploadDetails.do_valid_to}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_to'
                                            timeFormat={false}
                                            onChange={changeDOValidTo
                                                // (e) => setonChangeManualUploadDetails(prev => ({ ...prev, do_valid_to: changeHandleDate(e) }))
                                            }
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Shipping Line:<span className="err-txt"></span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.shipping_line}
                                            // value={manualUploadDetails.shipping_line}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={handleDropDownShipping}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={shippinglist1}
                                        />
                                        {/* <input
                                            type="text"
                                            name="shipping_line"
                                            id="shipping_line"
                                            value={manualUploadDetails.shipping_line}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, shipping_line: e }))}
                                            required
                                        /> */}
                                    </div>
                                    
                                    {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Freight Forwarder:<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="freight_forwarder"
                                            id="freight_forwarder"
                                            value={manualUploadDetails.freight_forwarder}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e.target.value }))}
                                            required
                                        />
                                    </div> */}
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Freight Forwarder<span className="err-txt"></span></label>
                                        {/* <input
                                            type="text"
                                            name="freight_forwarder"
                                            id="freight_forwarder"
                                            value={manualUploadDetails.freight_forwarder}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e.target.value }))}
                                            required
                                        /> */}
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.freight_forwarder}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={handleDropDownFreight}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={frieghtlist}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel Name:<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="vessel_name"
                                            id="vessel_name"
                                            value={manualUploadDetails.vessel_name}
                                            className="form-control"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Voyage No:<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="voyaga_no"
                                            id="voyaga_no"
                                            value={manualUploadDetails.voyaga_no}
                                            className="form-control"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel ETD (date)<span className="err-txt"></span></label>
                                        <Datetime
                                             key={manualUploadDetails.vessel_etd_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="vessel_etd_date"
                                            value={manualUploadDetails.vessel_etd_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='vessel_etd_date'
                                            timeFormat={false}
                                            onChange={changeVesselETD
                                                //  (e) => setonChangeManualUploadDetails(prev => ({ ...prev, vessel_etd_date: changeHandleDate(e) }))
                                                }
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Gate Open Datetime<span className="err-txt"></span></label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="gate_open_datetime"
                                            value={manualUploadDetails.gate_open_datetime}
                                            dateFormat="DD-MM-YYYY"
                                            id='gate_open_datetime'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, gate_open_datetime: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="gate_open_datetime1" onBlur={onChangeCompareGateOpenDateTime} />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Port cut off date<span className="err-txt"></span></label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="port_cutoff_date"
                                            value={manualUploadDetails.port_cutoff_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='port_cutoff_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, port_cutoff_date: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="port_cutoff_date1" onBlur={onChangeComparePortCutoffDate} />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>SI Cut Off Date<span className="err-txt"></span></label>
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="sl_cutoff_date1" onBlur={onChangeCompareSICutOffDate} />


                                    </div>

                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Terminal<span className="err-txt"></span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.terminal}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={handleDropDownTerminal}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={terminallist}
                                        />
                                        {/* <input
                                            type="text"
                                            name="terminal"
                                            // placeholder='Enter Partner Type'
                                            id="terminal"
                                            value={manualUploadDetails.terminal}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, terminal: e.target.value }))}
                                            required
                                        /> */}
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Pickup Yard<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="pickup_yard"
                                            // placeholder='Enter Partner Type'
                                            id="pickup_yard"
                                            value={manualUploadDetails.pickup_yard}
                                            className="form-control"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>No Of Free Days <span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="no_of_free_days"
                                            id="no_of_free_days"
                                            value={manualUploadDetails.no_of_free_days}
                                            className="form-control"
                                            maxlength="2"
                                            onChange={handleChange}
                                            onInput = {e=> restrictToNumbers(e)}
                                            required
                                        />
                                    </div>
                                    {activeDoCopy && (
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Copy<span className="err-txt"></span></label>
                                        <input type="file" name="do_copy" id="do_copy" className="form-control requestFields" onChange={(e)=>onchangeFileHandler(e)} />
                                    </div>
                                    )}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"sliderBlock2 " + showAddForm2} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Update DO Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload2} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata2}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <p className='mb-5p'>Work Order No: {selected_row.work_order_no} </p>
                                        {/* <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workordernoList}
                                        /> */}
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Booking No: <span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="booking_no"
                                            id="booking_no"
                                            value={editManualUploadDetails.booking_no}
                                            className="form-control"
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, booking_no: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid From<span className="err-txt"></span></label>

                                        <Datetime 
                                             key={editManualUploadDetails.do_valid_from}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_from"
                                            value={editManualUploadDetails.do_valid_from}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_from'
                                            timeFormat={false}
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, do_valid_from: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Valid To<span className="err-txt"></span></label>
                                        <Datetime
                                             key={editManualUploadDetails.do_valid_to}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="do_valid_to"
                                            value={editManualUploadDetails.do_valid_to}
                                            dateFormat="DD-MM-YYYY"
                                            id='do_valid_to'
                                            timeFormat={false}
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, do_valid_to: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Shipping Line<span className="err-txt">*</span></label>
                                        {/* <input
                                            type="text"
                                            name="shipping_line"
                                            id="shipping_line"
                                            value={manualUploadDetails.shipping_line}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, shipping_line: e.target.value }))}
                                            required
                                        /> */}
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={editManualUploadDetails.shipping_line}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, shipping_line: e }))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={shippinglist1}
                                        />
                                    </div>
                                    {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Freight Forwarder:<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="freight_forwarder"
                                            id="freight_forwarder"
                                            value={manualUploadDetails.freight_forwarder}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e.target.value }))}
                                            required
                                        />
                                    </div> */}
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Freight Forwarder<span className="err-txt"></span></label>
                                        {/* <input
                                            type="text"
                                            name="freight_forwarder"
                                            id="freight_forwarder"
                                            value={manualUploadDetails.freight_forwarder}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e.target.value }))}
                                            required
                                        /> */}
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={editManualUploadDetails.freight_forwarder}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, freight_forwarder: e }))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={frieghtlist}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel Name:<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="vessel_name"
                                            id="vessel_name"
                                            value={editManualUploadDetails.vessel_name}
                                            className="form-control"
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, vessel_name: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Voyage No:<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="voyaga_no"
                                            id="voyaga_no"
                                            value={editManualUploadDetails.voyaga_no}
                                            className="form-control"
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, voyaga_no: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Vessel ETD (date)<span className="err-txt"></span></label>
                                        <Datetime
                                             key={editManualUploadDetails.vessel_etd_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="vessel_etd_date"
                                            value={editManualUploadDetails.vessel_etd_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='vessel_etd_date'
                                            timeFormat={false}
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, vessel_etd_date: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Gate Open Datetime<span className="err-txt"></span></label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="gate_open_datetime"
                                            value={manualUploadDetails.gate_open_datetime}
                                            dateFormat="DD-MM-YYYY"
                                            id='gate_open_datetime'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, gate_open_datetime: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="gate_open_datetime2" />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Port cut off date<span className="err-txt"></span></label>
                                        {/* <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="port_cutoff_date"
                                            value={manualUploadDetails.port_cutoff_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='port_cutoff_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, port_cutoff_date: changeHandleDate(e) }))}
                                            required /> */}
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="port_cutoff_date2" />

                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>SI Cut Off Date<span className="err-txt"></span></label>
                                        <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="sl_cutoff_date2" />


                                    </div>

                                   <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Terminal<span className="err-txt">*</span></label>
                                        {/* <input
                                            type="text"
                                            name="terminal"
                                            // placeholder='Enter Partner Type'
                                            id="terminal"
                                            value={manualUploadDetails.terminal}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, terminal: e.target.value }))}
                                            required
                                        /> */}
                                         <Select
                                            closeMenuOnSelect={true}
                                            value={editManualUploadDetails.terminal}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, terminal: e.target.value }))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={terminallist}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Pickup Yard<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="pickup_yard"
                                            // placeholder='Enter Partner Type'
                                            id="pickup_yard"
                                            value={editManualUploadDetails.pickup_yard}
                                            className="form-control"
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, pickup_yard: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>No Of Free Days <span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="no_of_free_days"
                                            id="no_of_free_days"
                                            value={editManualUploadDetails.no_of_free_days}
                                            className="form-control"
                                            maxlength="2"
                                            onChange={(e) => setEditManualUploadDetails(prev => ({ ...prev, no_of_free_days: e.target.value }))}
                                            onInput = {e=> restrictToNumbers(e)}
                                            required
                                        />
                                    </div>
                                    {/* {doCopy &&  */}
                                    {
                                        (editManualUploadDetails.additionl_do_copy_arr !== '' && editManualUploadDetails.additionl_do_copy_arr !== undefined && editManualUploadDetails.additionl_do_copy_arr !== null && Array.isArray(editManualUploadDetails.additionl_do_copy_arr) && editManualUploadDetails.additionl_do_copy_arr.length>0)?
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>DO Copy<span className="err-txt">*</span></label>
                                        <input type="file" name="additional_do_copy2" id="additional_do_copy2" multiple={true} className="form-control requestFields" required onChange={(e)=>onchangeFileHandler2(e)} />
                                    </div> : 
                                    <div className="form-group col-xl-12 col-lg-12">
                                    <label className='mb-5p'>DO Copy<span className="err-txt">*</span></label>
                                    <input type="file" name="additional_do_copy" id="additional_do_copy" multiple={true} className="form-control requestFields" required onChange={(e)=>onchangeFileHandler1(e)} />
                                    </div>
                                    }
                                    
                                     {/* } */}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"sliderBlock2 " + showAddForm3} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Download DO Copies</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload3} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">

                       <div className="col-xl-12 col-lg-12 form-group mt-15p">
                        <button onClick={() => downloadB2(files1.file1)} className="btn btn-warning1">Download File 1<i style={{color:downloadBtn,cursor:cursorPoint,marginLeft:"10px", marginTop:"5px", marginBottom:"5px"}} className="fa fa-download f20"></i> </button>
                        </div>
                        <div className="col-xl-12 col-lg-12 form-group">
                        <div>
                            {
                                (files1.hasOwnProperty("file2")&& files1.file2 != undefined && files1.file2!="")?
                                files1.file2.map((file, index) => {
                                    return (<div className="mb-15p"><button key={index} onClick={() => downloadB2(file)} className="btn btn-warning1">{`Download File ${index+2}`}<i style={{color:downloadBtn,cursor:cursorPoint,marginLeft:"10px", marginTop:"5px", marginBottom:"5px"}} className="fa fa-download f20"></i> </button>
                                <br/>
                                </div>)
                                }
                                    
                                ):""
                            }
                            
                        
                        </div>
                        </div>
                    
                  
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>
    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    // var num = n/1000;
    // var hours = (num / (3600*24));
    // var rhours = Math.floor(hours);
    // var minutes = (n) / (60*60);
    // var rminutes = Math.round(minutes);

    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}



function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        // mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        //console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    //console.log(date.length);

    //console.log(date);
    if (date === undefined || date === null || date.length !== 10) {

        return null;

    }

    var yearNumber = date.substring(6, 10);

    var monthNumber = date.substring(3, 5);

    var dayNumber = date.substring(0, 2);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

    return result;

}
function loadDateTimeScript2() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {

    function Datepicker() { }

    Datepicker.prototype.init = function (params) {

        this.eInput = document.createElement("input");

        //this.eInput = this.eInput.setAttribute("class","datepicker");

        this.eInput.value = params.value;

        console.log(this.eInput)

        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });

    };

    Datepicker.prototype.getGui = function () {

        return this.eInput;

    };

    Datepicker.prototype.afterGuiAttached = function () {

        this.eInput.focus();

        this.eInput.select();

    };

    Datepicker.prototype.getValue = function () {

        return this.eInput.value;

    };

    Datepicker.prototype.destroy = function () { };

    Datepicker.prototype.isPopup = function () {

        return false;

    };

    return Datepicker;

}
function DateEditor() { }
// gets called once before the renderer is used

DateEditor.prototype.init = function (params) {

    // create the cell

    console.log(params, "params")

    this.eInput = document.createElement('input');

    this.eInput.value = params.value;
    // https://jqueryui.com/datepicker/

    $(this.eInput).datetimepicker({

        dateFormat: "yy-mm-dd",

        changeMonth: true,

        changeYear: true

    });

};




// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function () {

    return this.eInput;

};




// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function () {

    this.eInput.focus();

    this.eInput.select();

};




// returns the new value after editing

DateEditor.prototype.getValue = function () {

    return this.eInput.value;

};




// any cleanup we need to be done here

DateEditor.prototype.destroy = function () {

    // but this example is simple, no cleanup, we could

    // even leave this method out as it's optional

};




// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function () {

    // and we could leave this method out also, false is the default

    return false;

};
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
  function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
export default UploadDo