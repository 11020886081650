import React, { useState, useEffect, useRef,useReducer } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Select from "react-select";
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMYYYYHHMM, getHyphenDDMMYYYY,getHHMM,getHHMM1,getHyphenYYYYMMDDHHMM } from '../common/utils';
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CountryFlagComponent from '../layouts/countryFlag';
import ConsignmentTrucksLegs from '../layouts/containertruckslegs';
import DestinationFlagComponent from '../layouts/destinationCountryFlag';
import ViewDOdetailsComponent from "../layouts/viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
import { getDDMMYYYY } from '../utils';
var initialState = {
    detailCellRendererParams: {},
};
var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        // 	//console.log('6767')
        // 	return {
        // 		...state,
        // 		detailCellRendererParams: action.payload,
        // 	};
        default:
            return state;
    }
};
const BufferReport = () => {
    var [state, dispatch] = useReducer(reducer, initialState);
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);

    var [selectedval, setselectedval] = useState({
        dropdownval: {label: 'Invoice Date', value:'value'},
        inputval: ''
    })
    var [rowData, setrowData] = useState([])
    var [checkinput, setcheckinput] = useState(0)
    var [checkForDateFilter, setcheckForDateFilter] = useState(1)
    var [checkresetfilter, setcheckresetfilter] = useState(0)
    var [originalData, setoriginalData] = useState([])
    var [containersdata,setcontainersdata]  = useState([])
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
    var typeofselection = [
        { label: 'Invoice Date', value: 'invoice_date' },
        { label: 'CHA', value: 'cha' },
    ]
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [refreshSelected, setRefreshSelected ] = useState(0);

    useEffect(() => {
		if (gridApi.current) {
		    getUserGridState();
		}
		loadDateTimeScript();
	}, [gridApi]);
    useEffect(() => {
        redirectURL.post('/consignments/getbufferdata').then((resp) => {
            //console.log(resp.data, "response_data")
            var data = resp.data.buffer_report;
            if (!Array.isArray(data)) {
                data = data.replace(/NaN/g, "0");
                data = JSON.parse(data);
            }
            //console.log(data, "data");
            setrowData(data)
            setoriginalData(data)
        })
    }, [])
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", {screenurl: "/bufferreport" })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
            })
    }
    const restoreGridStates = (griddata) => {
		// //console.log("11111",usergridstate,"griddata",griddata[0].gridcolumns)
		
		try{
		if (griddata.length > 0) {
			//console.log('hit here loading',griddata.length)
			var windowstates = griddata;
			//console.log("windowstates.gridcolumns", windowstates[0].gridcolumns)
			//  gridApi.current.columnApi.setColumnState(windowstates[0].gridcolumns);
			 gridApi.current.columnApi.setColumnState(griddata[0].gridcolumns);
			// //console.log("windowstates[0].gridgroup", windowstates[0].gridgroup)
			 gridApi.current.columnApi.setColumnGroupState(windowstates[0].gridgroup);

			// //console.log("windowstates[0].gridcolsort", windowstates[0].gridcolsort)
			gridApi.current.api.setSortModel(windowstates[0].gridcolsort);

			// //console.log("windowstates[0].gridcolfilter", windowstates[0].gridcolfilter)
			gridApi.current.api.setFilterModel(windowstates[0].gridcolfilter);
		}
	}
		catch (e) { }
	}
    const onGridState = () => {
		////console.log(gridApi);

		/*Get  Current Columns State and Store in this.colState */
		const colState = gridApi.current.columnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		const rowGroupState =  gridApi.current.columnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		const pivotColumns =  gridApi.current.columnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = gridApi.current.api.getFilterModel();
	    gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();
	}
    const handleFilterOptions = (e) => {
        if (e.value == 'invoice_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if (val != '' && val != undefined) {
            var filteredData
            if (typeoffield == 'cha') {
                filteredData = originalData.filter(e=>e.cha==val);
            }
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
        else {
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    var onClickpendingdata=()=>{
        setloadshow('show-m')
        setoverly('show-m')
        redirectURL.post('/consignments/getbufferpendingdata').then((resp)=>{
            var data = resp.data.buffer_pending_report
            if(data!='' && data!=undefined){
                if (!Array.isArray(data)) {
                    data = data.replace(/NaN/g, "0");
                    data = JSON.parse(data);
                }
                //console.log(data, "data");
                setrowData(data)
                setoriginalData(data)
                    setloadshow('show-n')
                    setoverly('show-n')
            }
        })
    }
    var onClickSaveGridState = () => {
		// if (googleAnalytics.page.enableGA) {
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": gridActions.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
			window.colState =  gridApi.current.columnApi.getColumnState();
			window.groupState =  gridApi.current.columnApi.getColumnGroupState();
			// window.sortState = gridApi.current.columnApi.getSortModel();
			// window.filterState = gridApi.current.api.getFilterModel();
			var screenpage = '/bufferreport'
			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			//console.log("reqparams after save grid", reqparams);
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("response forr",response)
					// if(response.data.status === "200"){
						setshow(true)
						setbasicTitle("Successfully saved grid layout")
						setbasicType("success")
						// setState1({
						// 	screenurl: window.location.pathname,
						// 	screentitle: screenpage
						// })
						setTimeout(()=>{
							getUserGridState();
						},2000)
						// getUserGridState();
					// }
				})
				.catch(function (e) {
					//console.log("Error ", e)
				})
				//console.log('hit')
		}
		catch (e) {
			//console.log(e)
		}
	}
    //     try {
    //         if (gridApi.current) {
    //             const columnState = gridColumnApi.current.getColumnState();
    //             const columnGroupState = gridColumnApi.current.getColumnGroupState();
    //             const sortState = gridApi.current.api.getSortModel(); // Accessing getSortModel from gridApi.current.api
    //             const filterState = gridApi.current.api.getFilterModel();
    
    //             var screenpage = '/shipmentplanningreport';
    //             let reqparams = {
    //                 gridcolumns: columnState,
    //                 gridgroup: columnGroupState,
    //                 gridcolsort: sortState,
    //                 gridcolfilter: filterState,
    //                 userId: localStorage.getItem("userid"),
    //                 screenurl: window.location.pathname,
    //                 screentitle: screenpage
    //             };
    
    //             redirectURL.post("/consignments/saveGridStates", reqparams)
    //                 .then((response) => {
    //                     setshow(true);
    //                     setbasicTitle("Successfully saved grid layout");
    //                     setbasicType("success");
    //                     setTimeout(() => {
    //                         getUserGridState();
    //                     }, 2000);
    //                 })
    //                 .catch(function (e) {
    //                     //console.log("Error ", e);
    //                 });
    //         } else {
    //             console.error('Grid API is .');
    //         }
    //     } catch (e) {
    //         //console.log(e);
    //     }
    // };
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        var to_date = woReleaseDate.wo_release_to_date
        //console.log(originalData, '129')
        if (from_date != '' && from_date != undefined && from_date != null && to_date != '' && to_date != undefined && to_date != null) {
            from_date = moment(from_date, 'DD-MM-YYYY')
            to_date = moment(to_date, 'DD-MM-YYYY')
            var filteredData = originalData.filter(item => {
                var itemDate = getHyphenDDMMYYYY(item.invoice_date)
                itemDate = moment(itemDate, 'DD-MM-YYYY')
                return itemDate.isBetween(from_date, to_date, null, '[]')
            });
            //console.log(filteredData, 'filteredData')
            setrowData(filteredData)
            setRefreshSelected(0);
            setcheckresetfilter(1);
        }
        else {
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    const handleDatesSelected = (startDate, endDate) => {
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
   
    
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))
    }
    const columnDefs = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width:130,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Container No",
            field: "container_no",
            width:120,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width:160,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Invoice Date",
            field:"invoice_date",
            width:160,
            filter:true,
            resizble:true,
            valueGetter: function (params) {
                try {
                    if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.invoice_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Model",
            field: "model",
            width:160,
            filter:true,
            resizable:true,
            valueGetter: function(params){
                return params.data.brand+" "+params.data.brand_variant
            }
        },
        // {
        //     headerName: "Quantity",
        //     field: "quantity",
        //     width:120,
        //     filter:true,
        //     resizable:true,
        // },
        // {
        //     headerName: "Container Count",
        //     field: "container_count",
        //     width:160,
        //     filter:true,
        //     resizable:true,
        // },
        {
            headerName: "CHA",
            field: "cha",
            width:100,
            filter:true,
            resizable:true,
            // valueGetter: function(params){
            //     return params.data.vin_type+" ("+params.data.unique_code+")"
            // }
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tranporter_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Buffer Requested On",
            field: "buffer_requested_on",
            width:160,
            filter:true,
            resizable:true,
            valueGetter: function(params){
                try{
                    if (params.data.buffer_requested_on!='' && params.data.buffer_requested_on!= undefined){
                        return getHyphenDDMMYYYYHHMM(params.data.buffer_requested_on);
                    }

                }catch(e){

                }
            }
        },
        {
            headerName: "Request Remarks",
            field: "request_remarks",
            width:160,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Buffer Approved By",
            field: "buffer_approved_by",
            width:160,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Buffer Approved On",
            field: "buffer_approved_on",
            width:160,
            filter:true,
            resizable:true,
            valueGetter: function(params){
                try{
                    if (params.data.buffer_approved_on!='' && params.data.buffer_approved_on!= undefined){
                        return getHyphenDDMMYYYYHHMM(params.data.buffer_approved_on);
                    }

                }catch(e){

                }
            }
        },
        {
            headerName: "Approval Remarks",
            field: "approval_remarks",
            width:160,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Buffer Yard Name",
            field: "buffer_yard_name",
            width:160,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Buffer In Date",
            field: "buffer_in_date",
            width:160,
            filter:true,
            resizable:true,
            valueGetter: function (params) {
                try {
                    if (params.data.buffer_in_date != "" && params.data.buffer_in_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.buffer_in_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Buffer In Time",
            field: "buffer_in_time",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.buffer_in_time != "" && params.data.buffer_in_time!= undefined) {
                        return getHHMM1(params.data.buffer_in_time);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Buffer Out Date",
            field: "buffer_out_date",
            width:160,
            filter:true,
            resizable:true,
            valueGetter: function (params) {
                try {
                    if (params.data.buffer_out_date != "" && params.data.buffer_out_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.buffer_out_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Buffer Out Time",
            field: "buffer_out_time",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.buffer_out_time != "" && params.data.buffer_out_time!= undefined) {
                        return getHHMM1(params.data.buffer_out_time);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Duration (Days)",
            field: "buffer_duration",
            width:160,
            filter:true,
            resizable:true,
        },
        
        {
            headerName: "Tentative Cost",
            field: "tentative_cost",
            width:180,
            filter:true,
            resizable:true,
        },
        {
            headerName: "Vessel ETD",
            field: "tentative_vessel_etd",
            width:180,
            filter:true,
            resizable:true,
            valueGetter: function (params) {
                try {
                    if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
                        return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Port Gate In Date",
            field: "gate_in",
            width:140,
            filter:true,
            resizable:true,
            valueGetter: function (params) {
                try {
                    if (params.data.gate_in != "" && params.data.gate_in != undefined) {
                        return getHyphenDDMMYYYY(params.data.gate_in);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Port Gate In Time",
            field: "gate_in",
            width:180,
            filter:true,
            resizable:true,
            valueGetter: function (params) {
                try {
                    if (params.data.gate_in != "" && params.data.gate_in!= undefined) {
                        return getHHMM1(params.data.gate_in);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
    ]

    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const refreshData1=()=>{
        setcheckresetfilter(0);
        setrowData(originalData);
        setRefreshSelected(1);
        //console.log("refreshclickfunc")
 }

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Buffer Report
                    </h1>
                </div>
        <div className="card m-10p">
        <div className="card-body" id="counterCardBody">
            <div className="row">    
            <div className="col-sm-8">

            <div className="d-flex flex-row">
               <Select
                    closeMenuOnSelect={true}
                    name="filterModeType"
                    className="border-radius-0"
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            fontSize: "0.8rem",
                            color: "#6e707e",
                            borderRadius: "11px",
                            height: "48px",
                            width: "260px",
                            // padding:"22px",
                            textAlign: "center"
                        }),
                        singleValue: (provided, state)=>({
                            ...provided,
                            fontSize: "0,9rem",
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            zIndex: 9999, // You can set the desired z-index value here
                        }),
                    }}
                    onChange={handleFilterOptions}
                    options={typeofselection}
                    value={selectedval.dropdownval}
                    required
                />
                {checkinput == 1 ?
                    <div
                        className="d-flex ml-40p mr-10p"
                        style={{ position: "relative" }}
                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                        {checkresetfilter != 1 ?
                            <img
                                src={SearchIcon}
                                className="search_icon"
                                onClick={filterData}
                            /> : <img
                                src={RefreshIcon}
                                className="search_icon"
                                onClick={refreshData}
                            />}
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="ml-40p">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected}  onRefreshSelected={refreshSelected}/> 
                    {checkresetfilter !=1?
                    <img
                        src={SearchIcon}
                        className="search_icon_for_dates pointer"
                        onClick={filterDatafordates}
                    /> : <img src={RefreshIcon} className='search_icon_for_dates pointer' onClick={refreshData1}/>}
                    </div>

                    : ""}
                    {/* <div className='btn_showpending ml-30p' style={{height: "35px", marginTop: "5px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding:"5px" }}>
                        Show Pending
                    </div> */}
               </div>
               </div>

            <div className="col-sm-4">
        <div className="row">
            <div className="col textAlignRight">
                <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                                            Back
                                        </a>
                                        <button type="button" className="f12 btn btn-outline-success" onClick={onClickpendingdata}>
                   Current Day Pending
                </button>
                <button type="button" className="f12 btn btn-outline-danger mr-5p ml-5p" onClick={onClickSaveGridState}>
                    <i className="f12 icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                </button>
            </div>    
        </div>
            </div> 
            </div>   
            <div className="row" >
                <div className="col-sm-12" >
                    <div id="idgrid" style={{ width: "100%", height: "480px", marginTop:"15px" }} className="ag-theme-balham">
                    <AgGridReact
                        ref={gridApi}
                        // modules={AllModules}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            editable: false,
                            resizable: true,
                            menuTabs: ['filterMenuTab']
                        }}
                        rowData={rowData}
                        suppressRowClickSelection={true}
                        enableCellChangeFlash={true}
                        suppressCellFlash={true}
                        enableRangeSelection={true}
                        paginationPageSize={50}
                        onGridReady={onGridReady}
                        onGridState={onGridState}
                        rowSelection={'multiple'}
                        masterDetail={true}
                        pagination={true}
                        detailCellRendererParams={state.detailCellRendererParams}
                        // frameworkComponents={{ConsignmentTrucksLegs:ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: "columns",
                                    labelDefault: "Columns",
                                    labelKey: "columns",
                                    iconKey: "columns",
                                    toolPanel: "agColumnsToolPanel"
                                },
                                {
                                    id: "filters",
                                    labelDefault: "Filters",
                                    labelKey: "filters",
                                    iconKey: "filter",
                                    toolPanel: "agFiltersToolPanel"
                                }
                            ]
                        }}
                        statusBar={{
                            statusPanels: [
                                { statusPanel: "agFilteredRowCountComponent" },
                                { statusPanel: "agSelectedRowCountComponent" },
                                { statusPanel: "agAggregationComponent" }
                            ]
                        }}
                        gridOptions={{
                            icons: {
                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                last: `<img src="${customLastIcon}" alt="Last" />`,
                            },
                        }}
                        // context={{onClickDOdetails,onClickShowShipmentLegs}}
                    />

                </div>
            </div>
            </div>
        </div>
        </div>
        </div>
        </>
    )
}
function loadDateTimeScript() {
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

export default BufferReport;