
import React from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
  
//   function withRouter(Component) {
//     function ComponentWithRouterProp(props) {
//       let location = useLocation();
//       let navigate = useNavigate();
//       let params = useParams();
//       return (
//         <Component
//           {...props}
//           router={{ location, navigate, params }}
//         />
//       );
//     }
  
//     return ComponentWithRouterProp;
//   }


const withRouter = WrappedComponent => props => {
let location = useLocation();
let path = useLocation().pathname;
let navigate = useNavigate();
let params = useParams();
return (
    <WrappedComponent
    {...props}
    match={{ location, navigate, params, path }}
    />
);
};
export default withRouter;
