
import React, { Component } from 'react';
 
const ViewBatchItems = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickViewBatchItems(props);
    };
// console.log("props ", props.data)
    return (
        <div>
            <button type="button"
              className={"btn btn-info nopadding font12"} 
              title="View Items" 
              onClick={handleClick}>
                  <i className="icofont icofont-eye f16"></i>&nbsp; View Items
            </button>
          
        </div>
    );
};

export default ViewBatchItems;
