import React from 'react'
// import PlantVsActualDashboard from './plantvsactualdashboard';
import FourDaysPlan from './fourdaysplan';
import OutlookvsRegion from './monthoutlookvsregiondashboard';
import WeeklyPlanvsActualDashboard from './weeklyplanvsactualdashboard';
import OrdersvsProd from './ordersvsprod';


// import IOCLChart from '../billing/IOCLChart.js';
const Dashboard1=() =>{
  return (
    <>
    <div className=''>
        <div className='row'>
            <div className='col-6 mb-20p'>
            <OutlookvsRegion/>
            </div>
            <div className='col-6 mb-20p'>
            <FourDaysPlan/>
            </div>
        </div>
        <div className='row'>
           
            <div className='col-12 mb-20p'>
            <OrdersvsProd/>
           
            </div>
            <div className='col-12 mb-20p'>
            <WeeklyPlanvsActualDashboard/>
            </div>
        </div>
       
    </div>
    {/* <PlantVsActualDashboard/> */}
    
    
    {/* <IOCLChart/> */}
    </>
  )
}

export default Dashboard1;