
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const BufferExitDetailsComponent = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickbufferexitdetails(props);
    };
    return (
        <div className="map-icon-component">
              <img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'15px'}}/>
        </div>
    );
};
export default BufferExitDetailsComponent;
