
import react from 'react';
 
const ViewTotalBookingItems = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickTotalBookingViewItems(props);
    };
// console.log("props ", props)
    return (
        <div>
            {(props.data.viewitems === "Total" || props.data.viewitems === "")?"":
            <a onClick={handleClick}
              className={"btn btn-info nopadding font12"} 
              title="View Items">
                  <i className="icofont icofont-eye f16"></i>&nbsp; View Items
            </a>
            }
        </div>
    );
};

export default ViewTotalBookingItems;
