import React, { Component } from "react";
import moment from "moment";
import redirectURL from '../redirectURL';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts-drilldown';
import PieChart from "highcharts-react-official";

drilldown(Highcharts);
export default class TrackingPieChart extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        // Data retrieved from https://netmarketshare.com/
        // Make monochrome colors
        // const colors = Highcharts.getOptions().colors.map((c, i) =>
        //     // Start out with a darkened base color (negative brighten), and end
        //     // up with a much brighter color
        //     Highcharts.color(Highcharts.getOptions().colors[0])
        //         .brighten((i - 3) / 7)
        //         .get()
        // );
        
        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const options = {
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },
            chart: {
                type: "pie"
            },
            legend: {
                enabled: true
            },
            credits: false,
            innerSize: "20%",
            plotOptions: {
                series: {
                    borderWidth: 0,
                    colorByPoint: true,
                    colors:this.props.colors,
                    type: 'pie',
                    size: '100%',
                    innerSize: '50%',
                    // dataLabels: {
                    //     enabled: true,
                    //     crop: false,
                    //     // distance: '-25%',
                    //     style: {
                    //         fontWeight: 'bold',
                    //         fontSize: '16px'
                    //     },
                    //     connectorWidth: 2
                    // },
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: this.props.piSeries,
            // series: [
            //     {
            //         name: "Carriers",
            //         data: [
            //             {
            //                 name: "ONEY",
            //                 y: 100,
            //                 label: "Oveek",
            //                 drilldown:"ONEY"
            //             },
            //             {
            //                 name: "MSC",
            //                 y: 50,
            //                 label: "oveek",
            //                 drilldown:"MSC"
            //             }
            //         ]
            //     }
            // ]
            drilldown: {
                series: [
                    {
                        name: "MSC",
                        id: "MSC",
                        data: [
                            {
                                name: "MSC",
                                y: 50,
                                label: "oveek",
                            }
                        ]
                    },
                    {
                        name: "ONEY",
                        id: "ONEY",
                        data: [
                            {
                                name: "ONEY",
                                y: 100,
                                label: "oveek",
                            }
                        ]
                    }
                ]
            }
        }
        return (
            <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} options={options} />
        );
    }
}