
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const OpenexaminationBtnComponent = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickopenexaminationbtn(props);
    };
    return (
        <div className="map-icon-component" style={{cursor: "pointer"}}>
                        {props.data.modified_plant==1?<img  src={require("../../assets/images/update-gray.png")} style={{height:'15px',marginLeft:'30px',filter:'grayscale(100%)'}}/>:<img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'15px',marginLeft:'30px'}}/>}

            {/* <img onClick={handleRouteClick}   src={require("../../assets/images/Update.png")} style={{height:'20px',marginLeft:'30px'}}/> */}
 {/* <button onClick={(e) => handleRouteClick(e)} className="btn btn-primary f12 label label-success">
                           Update CHA Vessel Availability
                        </button> */}
            {/* <button onClick={handleRouteClick} className="custom-btn label label-success" title="Shipment Legs"><i style={{color:"#FF0000"}} className="icofont icofont-site-map f25"></i> </button> */}
        </div>
    );
};
export default OpenexaminationBtnComponent;
