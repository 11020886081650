import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMYYYY} from "../utils";
import { createStaticHandler } from '@remix-run/router';
import ViewPOD from "./viewpodbutton";
import moment from "moment";
import IOCLChart from "./IOCLCharts";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
const weekOfTheMonth = (myMomentDate) => {
    const startDay = moment(myMomentDate).startOf('week');
    const day = parseInt(startDay.format('DD'),10);
    if(day > 28){
      return 5;
    }

    if((day > 21) && (day <= 28) ){
      return 4;
    }

    if((day > 14) && (day <= 21) ){
      return 3;
    }

    if((day > 7) && (day <= 14) ){
      return 2;
    }

    return 1;
  }
export default class BillingDieselPrices extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true,
                menuTabs: ['filterMenuTab']
			},
            frameworkComponents:{
                ViewPOD:ViewPOD
            },
            paginationPageSize:200,
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            columnchartseries:[],
            columnchartcategories:[],
            from_date:"",
            to_date:"",
            cities:[],
            city:{"value":"","label":"Select"},
            rocode:{"value":"","label":"Select"},
            columnDefs:[],
            rocodes:[],
            statelist:[],
            dState:{"value":"Haryana","label":"Haryana"},            
            overlay:"show-n",
            vdata:"btn-active",
            vsummary:"btn-default",
            vdatash:"show-m",
            vsummarysh:"show-n"
        }
        this.loadData = this.loadData.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.loadCols = this.loadCols.bind(this);
        this.loadRows = this.loadRows.bind(this);
    }
	componentDidMount(){
        $( ".datepicker" ).datepicker({
            dateFormat:"dd-mm-yy"
        });
        var weekOfCurrentMonth = (moment().week() - (moment().month()*4));
        // console.log("weekOfCurrentMonth ", weekOfCurrentMonth)
        // let todaysDate = moment(moment.now());
        // console.log("todaysDate ", todaysDate)
        // let endOfLastMonth = moment(todaysDate).startOf('month').subtract(1, 'week');

        // let weekOfMonth = todaysDate.diff(endOfLastMonth, 'weeks');
        // console.log("weekOfMonth ", weekOfMonth)

        // const today = moment().format("DD-MM-YYYY");

        // var findweek = weekOfTheMonth(today)
        // console.log("findweek ", findweek)
        

        // var sdate = moment().startOf('month').format('DD-MM-YYYY');
        var sdate = moment.parseZone().subtract(7,"days").format('DD-MM-YYYY');
        var edate = moment.parseZone().format('DD-MM-YYYY');

        this.setState({
            from_date:sdate,
            to_date:edate,
            overlay:"show-m"
        })
        redirectURL.post("/configurations")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {            
                var p = {
                    startDate:sdate,
                    endDate:edate,
                    diesel_state:this.state.dState.value
                }
                this.loadData(p);
            
            }
            else{
                redirectToConfigurations()
            }
		})
	}
    
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    
    changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    formSubmit(event){
        event.preventDefault();
        var startDate = $("#from_date").val();
        var endDate = $("#to_date").val();
        var city = this.state.city.value;
        var diesel_state = this.state.dState.value;
        this.setState({
            overlay:"show-m"
        })
        // var rocode = this.state.rocode.value;
        var params = {
            startDate:startDate,
            endDate:endDate,
            city:city,
            diesel_state:diesel_state
            // rocode:rocode
        }
        // console.log("endDate ", params)
        this.loadData(params);
    }
    loadData(params){
        params.source = "IOCL";
        redirectURL.post("/dieselpricesbyparams",params)
        .then(async (response) => {
            // console.log("data ", response.data)
            var records = response.data.records;
            var rocodes = response.data.codes;
            var rocodesarr = [];
            var cities = response.data.cities;
            var cityarr = [];
            var states = response.data.states;
            var statesarr = [];
            if(cities.length > 0)
            {
                cities.map((item) => {
                    if(item != "" && item != undefined)
                    {
                        cityarr.push({value:item,label:item});
                    }
                })
            }
            if(rocodes.length > 0)
            {
                rocodes.map((item) => {
                    if(item != "" && item != undefined)
                    {
                        rocodesarr.push({value:item,label:item});
                    }
                })
            }
            if(states.length > 0)
            {
                states.map((item) => {
                    if(item != "" && item != undefined)
                    {
                        statesarr.push({value:item,label:item});
                    }
                })
            }
            var recordsarr = [];
            var series = []
            var categories = []
            if(records.length > 0)
            {
                records.map((item) => {
                    item.order = moment.parseZone(item.diesel_price_date).format("x");
                    item.order_date = getHyphenDDMMYYYY(item.diesel_price_date);
                    recordsarr.push(item)
                   
                })
            }
            recordsarr = await records.sort(GetSortAscOrder("order"));
           // console.log("recordsarr ",recordsarr)
            // if(recordsarr.length > 0)
            // {
            //     recordsarr.map((item) => {
            //         categories.push(getHyphenDDMMYYYY(item.diesel_price_date));
            //         series.push({
            //             name:getHyphenDDMMYYYY(item.diesel_price_date),
            //             y:parseFloat(item.diesel_price),
            //             // drilldown:getHyphenDDMMYYYY(item.diesel_price_date)
            //         })
            //     })
            // }
            var roseries = [];
            var groupByROData = groupBy(recordsarr, rdata => (rdata.diesel_ro_code));
            groupByROData.forEach((items,key) => {
                if(items.length > 0)
                {
                    var pricearr = [];
                    var aprice =0 ;
                    items.map((im) => {
                        pricearr.push(im.diesel_price);
                        aprice = parseFloat(aprice)+parseFloat(im.diesel_price);
                    })
                    if(pricearr.length > 0)
                    {
                        var avgprice = parseFloat(aprice)/pricearr.length
                    } 
                    roseries.push({
                        name:key,
                        y:avgprice
                    })
                }

            })

            var groupByDates = groupBy(recordsarr, rdata => (rdata.diesel_price_date));
            groupByDates.forEach((items,key) => {
                categories.push(getHyphenDDMMYYYY(key));
                if(items.length > 0)
                {
                    var pricearr = [];
                    var aprice =0 ;
                    items.map((im) => {
                        pricearr.push(im.diesel_price);
                        aprice = parseFloat(aprice)+parseFloat(im.diesel_price);
                    })
                    if(pricearr.length > 0)
                    {
                        var avgprice = (parseFloat(aprice)/pricearr.length).toFixed(2)
                    } 
                    series.push({
                        name:getHyphenDDMMYYYY(key),
                        y:parseFloat(avgprice)
                    })
                }

            })

            // console.log("roseries ", series)
            var chartdata = [
                {
                    name: 'Diesel Prices',
                    colorByPoint: true,
                    data: series
                }
            ];
            
            if(cityarr.length > 0)
            {
                var cityval = cityarr[0];
            }
            else{
                var cityval = {value:"",label:"Select"};
            }
            if(rocodesarr.length > 0)
            {
                // var rcodeval = {value:recordsarr[0].diesel_ro_code,label:recordsarr[0].diesel_ro_code};
                var rcodeval = {value:"",label:"Select"};
            }
            else{
                var rcodeval = {value:"",label:"Select"};
            }
            if(statesarr.length > 0)
            {
                var staeval = statesarr[0];
            }
            else{
                var staeval = {value:"",label:"Select"};
            }
            // console.log("statesarr ", chartdata)
            // console.log("staeval ",categories)
            this.setState({
                // rowData:recordsarr,
                columnchartseries:chartdata,
                columnchartcategories:categories,
                from_date:params.startDate,
                to_date:params.endDate,
                cities:cityarr,
                statelist:statesarr,
                rocodes:rocodesarr,
                // city:{"value":"Gurgaon","label":"Gurgaon"},
                dState:{value:params.diesel_state,label:params.diesel_state},
                rocode:rcodeval
            })
            setTimeout(()=>{
                this.loadCols(recordsarr);
                this.loadRows(recordsarr);
            },500)
        })
    }
    loadCols(rowdata){
        var cols = [
            {
                headerName:Constants.DIESEL_ROCODE,
                field:"diesel_ro_code",
                width:120,
            },
            {
                headerName:Constants.DIESEL_ADDR,
                field:"diesel_address",
                width:120,
            },
            {
                headerName:Constants.DIESEL_CITY,
                field:"diesel_city",
                width:120,
            },
            {
                headerName:Constants.DIESEL_STATE,
                field:"diesel_state",
                width:120,
            },
            
        ];
        var ss = $("#from_date").val();
        var tt = $("#to_date").val();
        var ts = tt.split("-");
        var ed = ts[2]+"-"+ts[1]+"-"+ts[0];

        
        var s12 = ss.split("-");
        var st = s12[2]+"-"+s12[1]+"-"+s12[0];

        var s1date = moment.parseZone(new Date(st+" 00:00:00")).format('YYYY-MM-DD');
        
        // console.log("s1date ", s1date)
        var e1date = moment.parseZone(new Date(ed+" 00:00:00")).format('YYYY-MM-DD');
        // console.log("e1date ", e1date)
        try {
            var currDate = moment(s1date).startOf('day');
            var lastDate = moment(e1date).startOf('day');
            var c1 = moment.parseZone(currDate).format("DD-MM-YYYY");
            var cX= moment.parseZone(currDate).format("x");

            cols.push({
                headerName:c1,
                field:cX
            })
            var diff = lastDate.diff(currDate,'days');
           

            while(diff > 0) {
                var d= moment.parseZone(currDate).add(1, 'days').format("YYYY-MM-DD");
                var da= moment.parseZone(currDate).add(1, 'days').format("DD-MM-YYYY");
                var dX= moment.parseZone(currDate).add(1, 'days').format("x");
                
                currDate = d
                cols.push({
                    headerName:da,
                    field:dX
                });
                diff = lastDate.diff(d,'days'); 
                // console.log("diff ", diff)
            }
            
        } catch (error) {
            
        }

        // if(rowdata.length > 0)
        // {
        //     rowdata.map((item) => {
        //         cols.push({
        //             headerName:item.order_date,
        //             field:item.order
        //         })
        //     })
        // }
        // console.log("cols ", cols)
        this.setState({
            columnDefs:cols
        })
    }
    loadRows(rowdata){
        try {
            var groupByROData = groupBy(rowdata, rdata => (rdata.diesel_ro_code));
            // console.log("groupByROData ",groupByROData)
            var rows = []
            groupByROData.forEach((items,key) => {
                
                var rw = {}
                rw["diesel_ro_code"] = key;
                if(items.length > 0)
                {
                    // let newArray = [];               
                    // // Declare an empty object 
                    // let totuniqueObject = {}; 
                    // var totobjTitle;
                    // // Loop for the array elements 
                    // for (let i in items) { 

                    //     // Extract the title 
                    //     totobjTitle = items[i]['diesel_ro_code']; 

                    //     // Use the title as the index 
                    //     totuniqueObject[totobjTitle] = items[i]; 
                    // } 

                    // // Loop to push unique object into array 
                    // for (var a in totuniqueObject) { 
                    //     newArray.push(totuniqueObject[a]); 
                    // } 
                    // console.log("newArray ",newArray)
                    items.map((itm) => {
                        
                        rw[itm.order] = itm.diesel_price;
                        rw["diesel_city"] = itm.diesel_city;
                        rw["diesel_state"] = itm.diesel_state;
                        rw["diesel_address"] = itm.diesel_address;
                        rw["diesel_price_diff_from_previous_date"] = itm.diesel_price_diff_from_previous_date;
                        rw["source"] = itm.source;
                        rw["diesel_price_date"] = itm.diesel_price_date;
                    })
                    
                    rows.push(rw);
                }
            })
            // console.log("Rows ", rows)
            // var series=[];
            // var categories=[];
            // if(rows.length > 0)
            // {
            //     rows.map((item) => {
            //         categories.push(getHyphenDDMMYYYY(item.diesel_price_date));
            //         series.push({
            //             name:getHyphenDDMMYYYY(item.diesel_price_date),
            //             y:parseFloat(item.diesel_price),
            //             // drilldown:getHyphenDDMMYYYY(item.diesel_price_date)
            //         })
            //     })
            // }
            
            // var chartdata = [
            //     {
            //         name: 'Diesel Prices',
            //         colorByPoint: true,
            //         data: series
            //     }
            // ];
            this.setState({
                rowData:rows,
                overlay:"show-n",
                // columnchartseries:chartdata,
                // columnchartcategories:categories,
            })
        } catch (error) {
            
        }
        
        
    }
    onChangeCity(city){
        this.setState({city})
    }
    onChangeRoCode(rocode){
        this.setState({rocode});
    }
    onChangeStates(dState){
        this.setState({dState})
    }
    
    hideSlideBlock(){
        this.setState({
            showUploadCSVForm:"show-n",
            showApproveDocumentReason:"",
            showRejectDocumentReason:"",
            slider:"",
            overlay:"show-n"
        })
    }
    showTabView(oVal){
        if(oVal === 1)
        {
            this.setState({
                vdata:"btn-active",
                vsummary:"btn-default",
                vdatash:"show-m",
                vsummarysh:"show-n"
            })
        }
        if(oVal === 2)
        {
            this.setState({
                vdata:"btn-default",
                vsummary:"btn-active",
                vdatash:"show-n",
                vsummarysh:"show-m"
            })
        }
    }
    render(){
		var columnDefs = [
            
            {
                headerName:Constants.DIESEL_PRICE_DATE,
                field:"diesel_price_date",
                width:160,
                valueGetter:function(params){
                    try{
                        if(params.data.diesel_price_date !== "" && params.data.diesel_price_date !== undefined)
                        {
                            return getHyphenDDMMYYYY(params.data.diesel_price_date);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.DIESEL_ROCODE,
                field:"diesel_ro_code",
                width:120,
            },
            {
                headerName:Constants.DIESEL_PRICE,
                field:"diesel_price",
                width:150,
            },
            {
                headerName:Constants.DIESEL_CITY,
                field:"diesel_city",
                width:160,
            },
            {
                headerName:Constants.DIESEL_STATE,
                field:"diesel_state",
                width:160,
            },
            {
                headerName:Constants.DIESEL_ADDR,
                field:"diesel_address",
                width:200,
            }
        ]
        return (
            <div className="container-fluid">
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">Diesel Prices
                     
                    </h1>
				</div>
                <div className="row">
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <form className="user" onSubmit={this.formSubmit.bind(this)}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <label>Start Date</label>
                                        <input type="text" name="from_date" id="from_date" value={this.state.from_date} className="form-control datepicker" onChange={this.changeHandler} placeholder="Start Date" required />
                                    </div>
                                    <div className="col-md-2">
                                        <label>End Date</label>
                                        <input type="text" placeholder="End Date" name="to_date" id="to_date" value={this.state.to_date} className="form-control datepicker" onChange={this.changeHandler} required />
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <label>State</label>
                                        <Select
                                            value={this.state.dState} 
                                            className="" 
                                            onChange={this.onChangeStates.bind(this)}
                                            options={this.state.statelist}
                                         />
                                    </div>
                                    {/* <div className="col-md-2">
                                        <label>City</label>
                                        <Select
                                            value={this.state.city} 
                                            className="" 
                                            onChange={this.onChangeCity.bind(this)}
                                            options={this.state.cities}
                                         />
                                    </div> */}
                                    {/* <div className="col-md-2">
                                        <label>RO Code</label>
                                        <Select
                                            value={this.state.rocode} 
                                            className="" 
                                            onChange={this.onChangeRoCode.bind(this)}
                                            options={this.state.rocodes}
                                         />
                                    </div> */}
                                    <div className="col-md-2">
                                        <button type="submit" className="btn btn-danger" style={{marginTop:"30px"}}>Submit</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
				<div className="row">
                    <div className="col-xl-12 col-md-12 ">
                        <ul className={"navtabs "}>
                            <li>
                                <button onClick={this.showTabView.bind(this,1)} type="button" className={"btn cbtn "+(this.state.vdata)}>View Data</button>
                            </li>
                            <li>
                                <button onClick={this.showTabView.bind(this,2)} type="button" className={"btn cbtn "+(this.state.vsummary)}>Summary</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={" "+(this.state.vdatash)}>
                    <div className={"row "}>
                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card shadow h-100 py-2">
                                <div className="card-body">
                                    <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                    <AgGridReact 
                                        rowData={this.state.rowData} 
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef ={this.state.defaultColDef }
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        
                                    />
                                    </div> 
                                    
                                </div>
                            </div>
                        </div>
					</div>
					
				</div>
                <div className={" "+(this.state.vsummarysh)}>
                    <div className={"row "}>

                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                        {(this.state.columnchartseries.length > 0)?
                                            <IOCLChart
                                                chartType="pie"
                                                title={"Daily Avg. Diesel Prices"}
                                                subtitle={""}
                                                context={this}
                                                series={this.state.columnchartseries}
                                                categories={this.state.columnchartcategories}
                                                xtitle={""}
                                                ytitle={"Price (INR)"}
                                                yaxistitle={""}
                                            />
                                        :""}
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    

                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}
$(function(){
    
    $( ".datepicker" ).datepicker({
        dateFormat:"dd-mm-yy"
    });
})

function getDatesBewteenDates(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        console.log(currDate.toDate());
        dates.push(currDate.clone().toDate());
    }

    return dates;
};