import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import TrackingTile from "./trackingTile";
import redirectURL from "../redirectURL";
import $, { each } from "jquery";
import { countries } from "country-flags-svg";
import ProgressbarCard from "./ProgressbarCard";
import Mapcard2 from "./Mapcard2";
import TrackingTile2 from "./TrackingTile2";
import moment from "moment";
import  TrackingDetailsCard from "./TrackingDetailsTabNew";
import EventsCard from "./EventsCard";

import BootstrapLoader from "./BootstrapLoader";
// import redirectURL from "../redirectURL"

const ContainerTrackingDetails = () => {
//  console.log(useParams(),"mbl_number");

  let { searchNumber } = useParams();
  let data = useLocation();
  let container=data.state.each||""
  // const [exceptionShipmentID,setExceptionShipmentID]=useState();
  const [state, setState] = useState({
    shipmentNo: "",
    showLoader: false,
    eventsData: [],
    excpetionData: [],
    tabActive: "Container",
    containerTabCard: "show-m",
    detailsTabCard: "show-n",
    eventsTabCard: "show-n",
    searchNumber,
    mapinfo: {
      coords: [
        // { lat: 8.76735, lng: 78.13425 },
        // { lat: 18.76735, lng: 88.13425 },
      ],
      breaks: [],
      route_events: [],
      last_event_coord: [],
    },
    allShipmentLocations: {
      coords: [],
      breaks: [],
      route_events: [],
    },
    container_data: [],
    all_data: [],
    bl_data: [],
    detailType: "",
    subMaps: false,
    counterData: {
      all: 0,
      origin: 0,
      in_transit: 0,
      destination: 0,
      completed: 0,
      delayed: 0,
      detained: 0,
    },
    geoLocShipmentsMap: {},
    bl_all_data: [],
    eventShow: "show-n",
    eventData: [],
    progressCardVisibility: "none",
    mapHeight: "94vh",
    shipment_unique_id: "",
    uploadedDocx: [],
    most_delayed_container_emission:""
  });

  const [containerData, setContainerData] = useState([]);

  // useEffect(() => {
  //   getEvents();
  // }, []);

  // const getEvents = () => {
  //   let payload = {};
  //   let urlType = "";
  //   if (state.searchType === "BL") {
  //     payload["mbl_number"] = state.searchNumber;
  //     urlType = "/exim/mblTrackingDetails";
  //   } else {
  //     payload["shipment_no"] = state.searchNumber;
  //     urlType = "/exim/containerTrackingDetails";
  //   }

  //   redirectURL
  //     .post(urlType, payload)
  //     .then((resp) => {
  //       console.log("trackingDetails data", resp.data);
  //     })
  //     .catch((err) => {
  //       console.log("trackingDetails err", err);
  //     });
  // };

  // const activeCardChange = (containerNum) => {
  //   console.log("containerNum",containerNum)
  //   // let restCards = containerData.filter((each) => {
  //   //   return each !== containerNum;
  //   // });
  //   // restCards.forEach((each) => {
  //   //   $(`#${each}`).removeClass("selected-card-shadow");
  //   // });

  //   // $(`#${containerNum}`).addClass("selected-card-shadow");
  // };
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const changeContainerSubData = (shipmentNo) => {
    console.log("changeContainerSubData", shipmentNo);
    let filteredData = state.bl_all_data.filter((each) => {
      if (each.shipment_no === shipmentNo) {
        return each;
      }
    });
    console.log("changeContainerSubData", filteredData);
    if (filteredData.length > 0) {
      let allCoordinates = [];
      let {
        discharge_port_coordinates,
        latest_location,
        loading_port_coordinates,
      } = filteredData[0];
      // console.log("allCoordinates",geoPoints.length)
      

      if (filteredData[0].geoPoints.length > 0) {
        let duplicateData = [];
        let uniqueCoords = [];
        let parseCoord = ""
        try{
          parseCoord=JSON.parse(filteredData[0].geoPoints[0].route);
      }catch(err){
        
      }
        parseCoord.forEach((eachCoord) => {
          let { lat, lng } = eachCoord;
          let latlng = `${lat}${lng}`;
          if (!duplicateData.includes(latlng)) {
            uniqueCoords.push({
              lat: lat,
              lng: lng,
            });
            duplicateData.push(latlng);
          }
        });
        // allCoordinates.push(...JSON.parse(filteredData[0].geoPoints[0].route));
        allCoordinates.push(...uniqueCoords);
      }

      //Push Loading coords at front
      if (
        loading_port_coordinates.latitude &&
        loading_port_coordinates.longitude
      ) {
        allCoordinates.unshift({
          lat: loading_port_coordinates.latitude,
          lng: loading_port_coordinates.longitude,
        });
      }

      //Push Loading coords at last
      if (
        discharge_port_coordinates.latitude &&
        discharge_port_coordinates.longitude
      ) {
        allCoordinates.push({
          lat: discharge_port_coordinates.latitude,
          lng: discharge_port_coordinates.longitude,
        });
      }

      // console.log("allCoordinates georoutes", JSON.parse(geoPoints[0].route));
      // console.log("allCoordinates dest", {
      //   lat: discharge_port_coordinates.latitude,
      //   lng: discharge_port_coordinates.longitude,
      // });
      // console.log("allCoordinates load", {
      //   lat: loading_port_coordinates.latitude,
      //   lng: loading_port_coordinates.longitude,
      // });
      // console.log("allCoordinates latest", latest_location);
      // console.log("allCoordinates all", allCoordinates);

      let latestCoords = [];
      if (latest_location.latitude && latest_location.longitude) {
        latestCoords.push({
          lat: latest_location.latitude,
          lng: latest_location.longitude,
        });
      }
      console.log("changeContainerSubData", allCoordinates);
      console.log("changeContainerSubData", latestCoords);

      setState({
        ...state,
        // eventData: filteredData[0].event_data,
        mapinfo: {
          ...state.mapinfo,
          coords: allCoordinates,
          last_event_coord: latestCoords,
        },
        subMaps: true,
      });
    } else {
      setState({
        ...state,
        eventData: [],
        mapinfo: {
          ...state.mapinfo,
          coords: [],
        },
      });
    }
  };

  const changeSubTileData = (shipmentNo) => {
    // console.log("allCoordinates", shipmentNo);
    let data = state.bl_all_data;
    // setExceptionShipmentID(shipmentNo)
    let filteredData = data.filter((each) => {
      if (each.shipment_no === shipmentNo) {
        return each;
      }
    });

    // console.log("allCoordinates", filteredData);
    // console.log("allCoordinates", filteredData[0].geoPoints.length);

    if (filteredData.length > 0) {
      let allCoordinates = [];
      let {
        discharge_port_coordinates,
        latest_location,
        loading_port_coordinates,
      } = filteredData[0];
      // console.log("allCoordinates",geoPoints.length)

      if (filteredData[0].geoPoints.length > 0) {
        let duplicateData = [];
        let uniqueCoords = [];
        let parseCoord = JSON.parse(filteredData[0].geoPoints[0].route);

        parseCoord.forEach((eachCoord) => {
          let { lat, lng } = eachCoord;
          let latlng = `${lat}${lng}`;
          if (!duplicateData.includes(latlng)) {
            uniqueCoords.push({
              lat: lat,
              lng: lng,
            });
            duplicateData.push(latlng);
          }
        });
        // allCoordinates.push(...JSON.parse(filteredData[0].geoPoints[0].route));
        allCoordinates.push(...uniqueCoords);
      }

      //loading port at first
      if (
        loading_port_coordinates.latitude &&
        loading_port_coordinates.longitude
      ) {
        allCoordinates.unshift({
          lat: loading_port_coordinates.latitude,
          lng: loading_port_coordinates.longitude,
        });
      }

      //discharge at last
      if (
        discharge_port_coordinates.latitude &&
        discharge_port_coordinates.longitude
      ) {
        allCoordinates.push({
          lat: discharge_port_coordinates.latitude,
          lng: discharge_port_coordinates.longitude,
        });
      }

      // console.log("allCoordinates georoutes", JSON.parse(geoPoints[0].route));
      // console.log("allCoordinates dest", {
      //   lat: discharge_port_coordinates.latitude,
      //   lng: discharge_port_coordinates.longitude,
      // });
      // console.log("allCoordinates load", {
      //   lat: loading_port_coordinates.latitude,
      //   lng: loading_port_coordinates.longitude,
      // });
      // console.log("allCoordinates latest", latest_location);
      // console.log("allCoordinates all", allCoordinates);

      let latestCoords = [];
      if (latest_location.latitude && latest_location.longitude) {
        latestCoords.push({
          lat: latest_location.latitude,
          lng: latest_location.longitude,
        });
      }

      setState({
        ...state,
        eventData: filteredData[0].event_data,
        mapHeight: "35vh",
        progressCardVisibility: "block",
        mapinfo: {
          ...state.mapinfo,
          coords: allCoordinates,
          last_event_coord: latestCoords,
        },
        subMaps: true,
        eventsData: filteredData[0].last_event_data
      });
    } else {
      setState({
        ...state,
        eventData: [],
        mapHeight: "35vh",
        progressCardVisibility: "block",
        mapinfo: {
          ...state.mapinfo,
          coords: [],
        },
        subMaps: false,
        eventsData: filteredData[0].last_event_data
      });
    }
  };


  const showTabView = (tab) => {
    if (tab == "Details") {
      setState({
        ...state,
        tabActive: tab,
        containerTabCard: "show-n",
        detailsTabCard: "show-m",
        eventsTabCard: "show-n",
        // filterModeType: { value: "bl_number", label: "BL number" }
      });
    } else if (tab == "Container") {
      setState({
        ...state,
        tabActive: "Container",
        containerTabCard: "show-m",
        detailsTabCard: "show-n",
        eventsTabCard: "show-n",
      });
    // } else if(tab == "Documents"){
    //   setState({
    //     ...state,
    //     tabActive: "Documents",
    //     containerTabCard: "show-n",
    //     detailsTabCard: "show-n",
    //     documentsTabCard: "show-m",
    //     eventsTabCard: "show-n",
    //     exceptionTabCard: "show-n"
    //     // filterModeType: { value: "container_number", label: "Container number" }
    //   });
    // } 
    }
    else if(tab == "Events"){
      setState({
        ...state,
        tabActive: "Events",
        containerTabCard: "show-n",
        detailsTabCard: "show-n",
        eventsTabCard: "show-m",
        // filterModeType: { value: "container_number", label: "Container number" }
      });
    } 
    // else if(tab == "Exceptions"){
    //   setState({
    //     ...state,
    //     tabActive: "Exceptions",
    //     containerTabCard: "show-n",
    //     detailsTabCard: "show-n",
    //     documentsTabCard: "show-n",
    //     eventsTabCard: "show-n",
    //     exceptionTabCard: "show-m"
    //     // filterModeType: { value: "container_number", label: "Container number" }
    //   });
    // }

    
  };

  useEffect(() => {
    changeContainerSubData(state.shipmentNo);
  }, [state.shipmentNo]);

  const trackingData = () => {
    setState({
      ...state,
      showLoader: true
    })
    // let payload;
      let payload = {
        shipment_no: searchNumber,
      };
      // payload.shipment_no = searchNumber;
      redirectURL
        .post("/exim/trackingdetails", payload)
        .then((resp) => {
          console.log("trackingdetails data", resp.data);
          let {
            status,
            trackingData,
            mostDelayedContainer,
            latestGeoLocations,
            counterData,
            geoLocShipmentsMap,
            all_data,
          } = resp.data;

          // changeSubTileData(all_data[0].shipment_no)

          let uploadedDocx = mostDelayedContainer.documents;

          latestGeoLocations = JSON.parse(latestGeoLocations);
          let foundLastEvent = false;
          if (status === "success") {
            all_data.forEach((each) => {
              let allEvents = [];
              let { shipment_no, last_event_data } = each;
              last_event_data.forEach((event, i) => {
                let statusCode = "";
                let {
                  actual_time,
                  estimated_time,
                  event_group,
                  event_name,
                  status,
                  location,
                } = event;

                if (actual_time && estimated_time) {
                  let timeDiff = moment(
                    estimated_time,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).diff(moment(actual_time, "YYYY-MM-DD hh:mm:ss A"));
                  if (timeDiff > 0) {
                    statusCode = "Early";
                  } else if (timeDiff < 0) {
                    statusCode = "Delay";
                  } else {
                    statusCode = "On Time";
                  }
                } else if (actual_time && !estimated_time) {
                  statusCode = "On Time";
                } else {
                  statusCode = "On Time";
                }
                try{
                  const delay=(moment(each.last_port_eta)).diff(moment(each.last_port_ata));
                  const duration=moment.duration(delay)
                  const days = duration.days();
                  const hours2 = duration.hours();
                  if(each.last_port_eta===""||each.last_port_ata===""||each.last_port_eta===undefined||each.last_port_ata===undefined){
                    statusCode="NA"
                  }
                  if(days<0){
                    statusCode="Delay"
                  }
                  else if(days>0){
                    statusCode="Early"
                    }
                  else{
                    if(days===0){
                      if(hours2<0){
                        statusCode="Delay"
                      }
                      else if(hours2>0){
                        statusCode="Early"
                      }
                    }
                  }
                  }catch(err){
                    console.log(err,"errorrrrrrr");
                  }
                let eachEvent = {
                  event_name,
                };

                let eventGroup = event_group
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase());

                if (actual_time) {
                  eachEvent["actual_time"] = moment(
                    actual_time,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("Do MMM, YYYY");
                  eachEvent["eventGroup"] = eventGroup;
                  eachEvent["statusCode"] = statusCode;
                  eachEvent["is_last_event"] = false;
                  eachEvent["status"] = status;
                  eachEvent["location"] = location;
                } else {
                  eachEvent["is_last_event"] = false;
                  eachEvent["eventGroup"] = eventGroup;
                  eachEvent["statusCode"] = statusCode;
                  eachEvent["status"] = status;
                  eachEvent["location"] = location;
                }
                allEvents.push(eachEvent);
                if (
                  actual_time === "" &&
                  i > 0 &&
                  allEvents[i - 1]["actual_time"] != "" &&
                  status === "ACTIVE" &&
                  !foundLastEvent
                ) {
                  allEvents[i - 1]["is_last_event"] = true;
                  foundLastEvent = true;
                }
              });
              each["event_data"] = allEvents;
            });
            
            // setExceptionShipmentID(all_data[0].shipment_no)
            
            setState({
              ...state,
              // rowData: trackingData,
              // trackingData: trackingData,
              bl_data: [mostDelayedContainer],
              detailType: "Container",
              // mapinfo: {
              //   ...state.mapinfo,
              //   coords: latestGeoLocations,
              // },
              allShipmentLocations: {
                ...state.mapinfo,
                coords: latestGeoLocations,
              },
              counterData: counterData,
              geoLocShipmentsMap: geoLocShipmentsMap,
              bl_all_data: all_data,
              shipment_unique_id: mostDelayedContainer.shipment_unique_id,
              uploadedDocx: uploadedDocx,
              mapHeight: "54vh",
              eventData: all_data[0].event_data,
              subMaps: true,
              progressCardVisibility: "block",
              searchNumber: mostDelayedContainer.container_no,
              shipmentNo: all_data[0].shipment_no,
              eventsData: mostDelayedContainer.events_data,
            });
            // setContainerData(all_data);
          }
        })

        .catch((err) => {
          console.log("getTrackingData err", err);
        });
      setState({
        ...state,
        detailType: "Container",
      });
    
  }


  // const showSortCard = () => {
  //   // sort-filter-slider-close
  //   //left - open
  //   //right - close
  //   let finalClass = "";
  //   if ($("#sidebarToggleIcon").hasClass("fa-angle-left")) {
  //     finalClass = "sort-filter-slider-open card";
  //   } else {
  //     finalClass = "sort-filter-slider-close card";
  //   }

  //   if (state.showSortCard === "show-n") {
  //     setState({
  //       ...state,
  //       showSortCard: "show-m " + finalClass,
  //     });
  //   } else {
  //     setState({
  //       ...state,
  //       showSortCard: "show-n",
  //     });
  //   }
  // };

  // const sortData = (dataType) => {
  //   console.log(dataType);
  //   setState({
  //     ...state,
  //     showSortCard: "show-n",
  //   });
  // };

  const [docxLength, setDocxLength] = useState(0);
  const setDocumentsLength = (length) => {
    setDocxLength(length);
  };
  const updateDocumentsDataFn = (docxData) => {
    setState({
      ...state,
      uploadedDocx: docxData,
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [document_type, setDocument_type] = useState("");
  const [document_url, setDocument_url] = useState("");

  const previewCard = (document_type, document_url) => {
    setOpenModal(true);
    setDocument_type(document_type);
    setDocument_url(document_url);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const filterCardData = (groupName) => {
    console.log("allShipmentLocations", groupName)
    setState({
      ...state,
      mapHeight: "94vh",
      progressCardVisibility: "none",
      subMaps: false,
      mapinfo: state.allShipmentLocations,
    });

    let data = state.bl_all_data;
    if (groupName === "all") {
      setContainerData(state.bl_all_data);
      return;
    }

    if (groupName === "in_transit") {
      // setContainerData(state.bl_all_data);
      let filteredData = data.filter((each) => {
        if (each.event_group === "in_transit" || each.event_group === "transshipment") {
          return each;
        }
      });
      setContainerData(filteredData);
      return;
    }
    if (groupName === "delayed") {
      // setContainerData(state.bl_all_data);
      let filteredData = data.filter((each) => {
        if (each.delay_status === "Delay" || each.delay_status === "delay" || each.delay_status === "DELAY") {
          return each;
        }
      });
      setContainerData(filteredData);
      return;
    }
    if(groupName==="detained"){
      // setContainerData(state.bl_all_data);
      let filteredData = data.filter((each) => {
        if (each.in_detention === 1) {
          return each;
        }
      });
      setContainerData(filteredData);
      return;
    }
   


    let filteredData = data.filter((each) => {
      if (each.event_group === groupName) {
        return each;
      }
    });
    setContainerData(filteredData);
  };

  useEffect(() => {
    //trackingData();
  }, [openModal]);

  return (
    <>
    <BootstrapLoader showLoader={state.showLoader} />
      <div className="container-fluid">
        {/* <DocumentPreviewCard
          openModal={openModal}
          documentType={document_type}
          documentUrl={document_url}
          closeModal={closeModal}
        />*/}
        <ol
          className="breadcrumb"
          style={{ background: "none", height: "52px", marginLeft: "8px" }}
        >
          <li className="breadcrumb-item" style={{ height: "40px" }}>
            <Link
              to={"/container-tracking"}
              style={{
                textDecoration: "none",
                position: "relative",
                fontWeight: 700,
                marginBottom: "20px",
                paddingLeft: "10px",
              }}
            >
              Container Tracking
            </Link>
          </li>
          <li
            className="breadcrumb-item"
            aria-current="page"
            style={{
              color: "#263238",
              fontSize: "15px",
              fontWeight: 700,
              marginBottom: "20px",
              paddingLeft: "10px",
            }}
          >
            Container Details
          </li>
        </ol> 
        {/* </nav> */}

        {/* Top Card */}
        
            <TrackingTile2
              container_data={container}
              trackingDataFun={trackingData}
            />
        

        <div className="d-flex flex-row justify-content-between ml-4">
          <div className="">
            <ul className={"nav nav-tabs"}>
              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Details" ? "tab-active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => showTabView("Details")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Details
                </a>
              </li>
              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Container" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Container")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Container
                </a>
              </li>

              {/* <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Documents" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Documents")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Documents({docxLength})
                </a>
              </li> */}

              <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Events" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Events")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Events
                </a>
              </li>
              {/* <li className={"nav-item"}>
                <a
                  className={`nav-link ${
                    state.tabActive == "Exceptions" ? "tab-active" : ""
                  }`}
                  onClick={() => showTabView("Exceptions")}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  Exceptions
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      {/* Details Card */}
      <div className={`${state.detailsTabCard} mx-3 pt-3`}>
        <TrackingDetailsCard container_data={container} />
      </div>
      {/* Details Card */}

      {/* Container Card */}
      <div className={`${state.containerTabCard} mx-3 pt-3 pb-4`}>
        <div className="row" style={{ width: "91vw", margin: "auto" }}>
          <div className="col-7">
            {/* <MapCard mapinfo={state.mapinfo}/> */}
            <Mapcard2
              mapinfo={state.mapinfo}
              subMaps={state.subMaps}
              geoLocShipmentsMap={state.geoLocShipmentsMap}
              mapHeight={state.mapHeight}
              container={container}
            />
          </div>
            <div className="col-5">
              <div className="counters-card">
                <ProgressbarCard
                  searchType="container"
                  searchNumber={state.searchNumber}
                  eventData={container.all_events}
                />
              </div>
            </div>
        </div>
      </div>

      {/* Container Card */}

      {/* Documents Card */}
      {/* <div className={`${state.documentsTabCard} mx-3 pt-3`}>
        <DocumentsCard
          updateDocxCount={setDocumentsLength}
          uploadedDocx={state.uploadedDocx}
          previewCard={previewCard}
          updateDocumentsDataFn={updateDocumentsDataFn}
          shipment_unique_id={state.shipment_unique_id}
        />
      </div> */}
      {/* Documents Card */}


      {/* Events Card */}
      <div className={`${state.eventsTabCard} mx-3 pt-3 mb-3`}>
        <EventsCard eventsData={container.all_events} />
      </div>
      {/* Events Card */}
      {/* Exceptions Card */}
      {/* <div className={`${state.exceptionTabCard} mx-3 pt-3 mb-3`}>
      <div className="row" style={{ width: "91vw", margin: "auto" }}>
            <div className="col-12">
            </div>
            <div className="col-6">
            </div>
      </div>        
      </div> */}
      {/* Exceptions Card */}
    </>
  );
};

export default ContainerTrackingDetails;
