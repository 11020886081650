
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const ConsignmentTrucksLegs = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickShowShipmentLegs(props);
    };
    return (
        <div className="map-icon-component">
                        <button onClick={handleRouteClick} className="custom-btn label label-info" title="Batch Items"><i style={{color:"#0664AE"}}  className="icofont icofont-site-map f25"></i> </button>

            {/* <button onClick={handleRouteClick} className="custom-btn label label-success" title="Shipment Legs"><i style={{color:"#FF0000"}} className="icofont icofont-site-map f25"></i> </button> */}
        </div>
    );
};
export default ConsignmentTrucksLegs;
