import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import redirectURL from '../redirectURL';

export default class Counter extends Component {
	constructor(props){
        super(props);
        this.state={
			
		}
	}
	componentDidMount(){
		
	}
    render(){
		
        return (
           
            <div className="col-xl-3 col-md-6 mb-4">
                <div className={"card border-left-"+this.props.colorCode+" shadow h-100 py-2"}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className={"text-sm font-weight-bold text-"+this.props.colorCode+" text-uppercase mb-1"}>
                                    {this.props.counterTitle}</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">{this.props.counterValue}</div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
					
        );
    }
}