import React, { Component } from 'react';
// import './App.css';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

class TrackingGlobeChart extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {

        let root = am5.Root.new("mapdiv");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "rotateX",
            panY: "translateY",
            projection: am5map.geoMercator(),
            // homeZoomLevel: 1,
            // homeGeoPoint: { latitude: 20.5937, longitude: 78.9629 }
        }));


        let cont = chart.children.push(am5.Container.new(root, {
            layout: root.horizontalLayout,
            x: 20,
            y: 40
        }));

        // Add labels and controls
        cont.children.push(am5.Label.new(root, {
            centerY: am5.p50,
            text: "Map"
        }));

        let switchButton = cont.children.push(am5.Button.new(root, {
            themeTags: ["switch"],
            centerY: am5.p50,
            icon: am5.Circle.new(root, {
                themeTags: ["icon"]
            })
        }));

        switchButton.on("active", function () {
            if (!switchButton.get("active")) {
                chart.set("projection", am5map.geoMercator());
                chart.set("panY", "translateY");
                chart.set("rotationY", 0);
                backgroundSeries.mapPolygons.template.set("fillOpacity", 0);
            } else {
                chart.set("projection", am5map.geoOrthographic());
                chart.set("panY", "rotateY")

                backgroundSeries.mapPolygons.template.set("fillOpacity", 0.1);
            }
        });

        cont.children.push(
            am5.Label.new(root, {
                centerY: am5.p50,
                text: "Globe"
            })
        );

        // Create series for background fill
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
        let backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
        backgroundSeries.mapPolygons.template.setAll({
            fill: root.interfaceColors.get("alternativeBackground"),
            fillOpacity: 0,
            strokeOpacity: 0
        });

        // Add background polygon
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
        backgroundSeries.data.push({
            geometry: am5map.getGeoRectangle(90, 180, -90, -180)
        });

        // Create main polygon series for countries
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
        let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_worldLow
        }));

        // Create line series for trajectory lines
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-line-series/
        // let lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
        // lineSeries.mapLines.template.setAll({
        //     stroke: root.interfaceColors.get("alternativeBackground"),
        //     strokeOpacity: 0.3
        // });

        // Create point series for markers
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-point-series/
        let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

        pointSeries.bullets.push(function () {
            let circle = am5.Circle.new(root, {
                radius: 7,
                tooltipText: `{title}[/]\n [bold]avg: {avg_delay}, min: {min_delay}, max: {max_delay}[/]`,
                cursorOverStyle: "pointer",
                tooltipY: 0,
                fill: am5.color(0xffba00),
                stroke: root.interfaceColors.get("background"),
                strokeWidth: 2,
                draggable: false
            });

            // circle.events.on("dragged", function (event) {
            //     let dataItem = event.target.dataItem;
            //     let projection = chart.get("projection");
            //     let geoPoint = chart.invert({ x: circle.x(), y: circle.y() });

            //     dataItem.setAll({
            //         longitude: geoPoint.longitude,
            //         latitude: geoPoint.latitude
            //     });
            // });

            return am5.Bullet.new(root, {
                sprite: circle
            });
        });

        let pointerData = this.props.globeSeries
        console.log('pointerData', pointerData)
        pointerData.forEach(element => {
            // addCity(element)
            pointSeries.pushDataItem({
                latitude: element.lat,
                longitude: element.lng,
                title: element.name,
                avg_delay: element.avg_delay,
                min_delay: element.min_delay,
                max_delay: element.max_delay
            });
        });
        // let paris = addCity({ latitude: 48.8567, longitude: 2.351 }, "Paris");
        // let toronto = addCity({ latitude: 43.8163, longitude: -79.4287 }, "Toronto");
        // let la = addCity({ latitude: 34.3, longitude: -118.15 }, "Los Angeles");
        // let havana = addCity({ latitude: 23, longitude: -82 }, "Havana");

        // let lineDataItem = lineSeries.pushDataItem({
        //     pointsToConnect: [paris, toronto, la, havana]
        // });

        // let planeSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

        // let plane = am5.Graphics.new(root, {
        //     svgPath:
        //         "m2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47",
        //     scale: 0.06,
        //     centerY: am5.p50,
        //     centerX: am5.p50,
        //     fill: am5.color(0x000000)
        // });

        // planeSeries.bullets.push(function () {
        //     let container = am5.Container.new(root, {});
        //     container.children.push(plane);
        //     return am5.Bullet.new(root, { sprite: container });
        // });


        // let planeDataItem = planeSeries.pushDataItem({
        //     // lineDataItem: lineDataItem,
        //     positionOnLine: 0,
        //     autoRotate: true
        // });
        // planeDataItem.dataContext = {};

        // planeDataItem.animate({
        //     key: "positionOnLine",
        //     to: 1,
        //     duration: 10000,
        //     loops: Infinity,
        //     easing: am5.ease.yoyo(am5.ease.linear)
        // });

        // planeDataItem.on("positionOnLine", (value) => {
        //     if (planeDataItem.dataContext.prevPosition < value) {
        //         plane.set("rotation", 0);
        //     }

        //     if (planeDataItem.dataContext.prevPosition > value) {
        //         plane.set("rotation", -180);
        //     }
        //     planeDataItem.dataContext.prevPosition = value;
        // });

        let homeIcon = am5.Graphics.new(root, {
            svgPath:
                "M20 40V28h8v12h10V24h6L24 6 4 24h6v16z",
            scale: 0.6,
            centerY: am5.p50,
            centerX: am5.p50,
            width: 32,
            height: 32,
            fill: am5.color(0xffffff)
        });

        let btn = chart.children.push(am5.Container.new(root, {
            layout: root.horizontalLayout,
            x: am5.p100,
            centerX: am5.p100,
            y: am5.p100,
            centerY: am5.p100,
        }));

        // Add labels and controls
        btn.children.push(am5.Label.new(root, {
            centerY: am5.p50,
            text: ""
        }));

        let homeButton = btn.children.push(am5.Button.new(root, {
            themeTags: ["Home"],
            centerY: am5.p50,
            icon: homeIcon
        }));

        homeButton.get("background").setAll({
            fill: am5.color(0x6794dc),
            fillOpacity: 1
        });

        homeButton.get("background").states.create("hover", {}).setAll({
            fill: am5.color(0x0251d1),
            fillOpacity: 0.8
        });

        homeButton.events.on("click", function () {
            console.log("hit")
            chart.goHome();
        });

        // let exporting = am5plugins_exporting.Exporting.new(root, {
        //     menu: am5plugins_exporting.ExportingMenu.new(root, {
        //         align: "right",
        //         valign: "bottom"
        //     }),
        //     dataSource: pointerData
        // });

        let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {
                container: document.getElementById("exportmapdiv")
            }),
            dataSource: pointerData
        });

        exporting.events.on("dataprocessed", function (ev) {
            console.log(ev)
            // for (var i = 0; i < ev.data.length; i++) {
            //     ev.data[i].name = ev.data[i].max_delay + ev.data[i].min_delay;
            // }
        });

        // button.label.text = "...";
        // button.padding(5, 5, 5, 5);
        // button.width = 20;
        // button.align = "right";
        // button.marginRight = 15;
        // button.events.on("hit", function () {
        //     chart.goHome();
        // });


        // Add cursor
        // chart.set("cursor", am5xy.XYCursor.new(root, {}));
        chart.set("zoomControl", am5map.ZoomControl.new(root, {
            y: am5.p0,
            centerY: am5.p0,
        }));

        let zoomControl = chart.get("zoomControl")

        zoomControl['minusButton'].get("background").setAll({
            fill: am5.color(0x000000),
            fillOpacity: 0.2
        });
        zoomControl['plusButton'].get("background").setAll({
            fill: am5.color(0x000000),
            fillOpacity: 0.2
        });

        this.root = root;
    }


    componentWillUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    }

    render() {
        return (
            <>
                <div id='exportmapdiv' className="col-12 m-4" style={{ width: "97%", height: "40px", borderBottom: "2px solid #eee" }}></div>
                <div id="mapdiv" style={{ width: "100%", height: "500px" }}>
                </div>
            </>


        );
    }
}

export default TrackingGlobeChart;