
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ConsignmentActionsComponent = (props) => {
    console.log(props,"888")
    const handleRouteClick = () => {
        props.context.onShowRouteDiv(props.data);
    };

    return (
        <div className="map-icon-component">
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f25"></i> </button>
       
        </div>
    );
};

export default ConsignmentActionsComponent;
