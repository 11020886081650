import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from "../redirectURL";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Make sure to import CSS for the DatePicker
const PlantWorkOrderPerf = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    var [currentDate, setCurrentDate] = useState(new Date());
    var initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
    var [datefilter, setDatefilter] = useState(initialDate);
    // console.log(datefilter, "datefilter01")
    var [plantCodes, setPlantCodes] = useState();
    var [monthOutlook, setMonthOutlook] = useState();
    var [actualOutlook, setActualOutlook] = useState();
    const [showDatePicker, setShowDatePicker] = useState(false);
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var dateFormat = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;

    var [dateText, setDateText] = useState(dateFormat);
    const options = {
        chart: {
            type: 'column',
            height: 250
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: plantCodes,
            crosshair: true
        },
        yAxis: {
            min: 0,
            // max:1400,
            title: {
                text: "Quantity"
            },
            labels: {
                formatter: function () {
                    return this.value
                }
            },
            // tickInterval: 200,
        },
        tooltip: {
            valueSuffix: ''
        },
        plotOptions: {
            column: {
                // pointPadding: 0.1,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,  // Enable data labels
                    allowOverlap:true,
                    //  formatter: function(){
                    //     if (this.y >= 1000000){
                    //         return (this.y / 1000000).toFixed(1) + 'M';
                    //     }
                    //     if (this.y >= 1000 ){
                    //         return (this.y /1000).toFixed(1)+ 'K';
                    //     }
                    //     return this.y;
                    // },
                    inside: false,  // Set to false to display labels outside the bar
                    style: {
                        color: 'black',  // Set text color, change as needed
                        textOutline: 'none'  // Remove or adjust the text outline
                    },
                    align:'center',
                    verticalAlign:'bottom',
                    y:-10,
                    x:0
                }
            },

        },
        colors: ["#0664AE", "black"],
        series: [
            {
                name: 'Outlook',
                data: monthOutlook,
                pointPlacement:-0.07

            },
            {
                name: 'Actual',
                data: actualOutlook,
                pointPlacement:0.07
            },

        ],
        credits: {
            enabled: false
        }
    };

    useEffect(() => {
        getData(datefilter);
    }, [])

    const getData = (formattedDate) => {
        setloadshow('show-m')
        var date;
        if (formattedDate != '' && formattedDate != undefined) {
            date = formattedDate;
        } else {
            date = datefilter
        }
        var params = {
            month: date
        }

        redirectURL.post('/consignments/getplantvsactual', params).then((resp) => {
            try{
                if (resp.data.status = 'Success') {
                    var data = resp.data.plant_wo_performance
                    var records = JSON.parse(data)
                    if (data != '' && data != undefined) {
                        var plantCodesData = records.map(each => each.plant_code)
                        var monthOutlookData = records.map(each => each.month_outlook)
                        var actualOutlookData = records.map(each => each.actual_outlook)
                        setPlantCodes(plantCodesData);
                        setMonthOutlook(monthOutlookData);
                        setActualOutlook(actualOutlookData);                // setrowData2(data)
                    }
    
    
                }
            }
          catch(e){
            console.error('Error:', e);
            setPlantCodes([]);
            setMonthOutlook([]);
            setActualOutlook([]);  
          }finally{
            setTimeout(() => {
                setloadshow('show-n')
            }, 3000);
          }
            
        }).catch(error => {
            console.error('Fetch error:', error);
            setPlantCodes([]);
            setMonthOutlook([]);
            setActualOutlook([]);
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    }
    // console.log(plantCodes,"platCodes")
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setloadshow('show-m')
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        setDatefilter(formattedDate)
        var dateFormat1 = `${month}-${year}`

        setDateText(dateFormat1)
        // setfilters(prev => ({
        //     ...prev,
        //     datefilter: formattedDate
        // }))
        getData(formattedDate)
        setShowDatePicker(false)
    };
    const handleKeyDown = (e) => {
        e.preventDefault(); // Prevent any keyboard input
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    return (
        <>
            <div className='card-header2'>
                <div className='d-flex' style={{ position: "relative" }}>
                    <h2 className='heading3 mt-20p mb-20p' style={{ width: "800px" }}>Plant Wise (Outlook vs Actual)</h2>
                    <div className='d-flex' style={{ width: "200px", position: "absolute", right: "-170px", top: "15px" }}>
                        <h3 className='heading4 ml-10p' style={{ position: "absolute", top: "8px", right: "180px", width: "80px" }}>{dateText}</h3>

                        <img src={require("../../assets/images/calendar.png")} onClick={() => setShowDatePicker(!showDatePicker)} alt="" width="25px" className="image-icons positionIcon" />
                        {showDatePicker && (
                            <div style={{ position: 'absolute', zIndex: 1000 , top: '30px', right:"400px"}}>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    minDate={new Date(1900, 0)}
                                    maxDate={new Date(2100, 11)}
                                    onKeyDown={handleKeyDown}
                                    className="custom-datepicker"
                                    calendarClassName="custom-datepicker-dropdown"
                                    inline
                                />
                            </div>
                        )}
                    </div>
                </div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>


        </>
    );
}

export default PlantWorkOrderPerf;
