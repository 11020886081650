import { React, useState, useEffect, useRef } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import MainTrackingCounters from "./mainCounters";
import moment from "moment";
import SearchIcon from "../../assets/icons/search-icon.svg";
import FilterIcon from "../../assets/icons/filter-icon.svg";
import SortIcon from "../../assets/icons/sort-icon.svg";
import GridViewActive from "../../assets/icons/grid-view-white.svg";
import GridViewInactive from "../../assets/icons/grid-view-blue.svg";
import TileViewActive from "../../assets/icons/tile-view-white.svg";
import TileViewInactive from "../../assets/icons/tile-view-blue.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import ShipmentBlue from "../../assets/icons/shipment-blue.svg";
import ShipmentWhite from "../../assets/icons/shipment-white.svg";
import TrackingTile from "./trackingTile";
import redirectURL from "../redirectURL";
import EximGrid from "./EximGrid";
import Modal from "react-responsive-modal";
import Select from "react-select";
import $ from "jquery";
import { countries } from "country-flags-svg";
// import Datetime from "react-datetime";
// import "react-datetime/css/react-datetime.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapLoader from "./BootstrapLoader";
import "./tracking.css";
import { Tooltip } from "react-tooltip";
import LocationBlue from "../../assets/icons/location-blue.svg";
import LocationWhite from "../../assets/icons/location-white.svg";
import InfoIcon from '../../assets/icons/info.png';
import NavDropdown from 'react-bootstrap/NavDropdown';
import FlagBlue from "../../assets/icons/flag-blue.svg";
import FlagWhite from "../../assets/icons/flag-white.svg";
import { FaGlobe } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";


const ContainerTracking = () => {
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [openFilterModal,setOpenFilterModal]=useState(false);
  const [openSubCounterChoise, setOpenSubCounterChoise]=useState(false)
  const [tabActiveSCnt, settabActiveSCnt] = useState("0");
  const [ULIPData, setULIPData]=useState([]);
  const[tabActive,setTabActive]=useState("BL/Booking")
  const counters=useRef([
    {
      id: 1,
      title: "Origin",
      imgSrc: LocationBlue,
      activeImgSrc: LocationWhite,
      subCounters:[
        {label:"In-Transit to Consigner",value:"in_transit_to_consignor"},
        {label:"At Consigner",value:"at_consignor"}, 
        {label:"In-transit at POL",value:"in_transit_to_pol"}, 
        {label:"At POL",value:"at_pol"}
      ]
    },
    {
      id: 2,
      title: "In Transit",
      imgSrc: ShipmentBlue,
      activeImgSrc: ShipmentWhite,
      subCounters:[
        {label:"High Seas",value:"high_seas"}, 
        {label:"At Transhipment Port",value:"at_transshipment"}, 
        // {label:"At POD",value:""}, 
        // {label:"In-Transit to Final Destination",value:""}
      ]
    },
    {
      id: 3,
      title: "Destination",
      imgSrc: FlagBlue,
      activeImgSrc: FlagWhite,
      subCounters:[
        {label:"At POD",value:"at_pod"}, 
        {label:"In-Transit to Final Port",value:"in_transit_to_final_port"}, 
        {label:"At Final Port",value:"at_final_port"}, 
        {label:"Out For Delivery",value:"out_for_final_delivery"}, 
        {label:"At Customer",value:"at_customer"}
      ]
    },
    {
      id:4,
      title:"ULIP",
      imgSrc:FlagBlue,
      activeImgSrc:FlagWhite,
      subCounters:[
        {label:"LDB", value:"LDB"},
        {label:"FOIS", value:"FOIS"},
        {label:"ICEGATE", value:"ICEGATE"},
        {label:"PCS", value:"PCS"}
      ]
    }
  ])
  const sub_counter=["in_transit_to_consignor","at_consignor","in_transit_to_pol","at_pol"]
  const subcountersCheckbox=useRef(["in_transit_to_consignor","at_consignor","in_transit_to_pol","at_pol",...ULIPData]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState(["in_transit_to_consignor","at_consignor","in_transit_to_pol","at_pol",...ULIPData]);
  const [subCounters, setSubCounters]=useState(counters.current[0])
  const [state, setState] = useState({
    showLoader: false,
    bl_new_shipments: [],
    bl_untrackable: [],
    container_new_shipments: [],
    container_untrackable: [],
    containerShippingLines: [],
    blShippingLines: [],
    shippingLines: [],
    shippingLine: "",
    tempContainerData: [],
    filterText: "",
    trackingData: [],
    container_data: [],
    all_container_data: [],
    all_bl_data: [],
    bl_data: [],
    cummulativeBalEmf:"",
    balContainerEmf:"",
    cummulativeContainerEmf:"",
    containerEmf:"",
    isTileView: true,
    filterModeType: { value: "bl_number", label: "BL Number" },
    modeOptions: [
      { value: "bl_number", label: "BL Number" },
      { value: "container_number", label: "Container" },
      // { value: "invoice_no", label: "Invoice" },
      // { value: "po_no", label: "PO" },
    ],
    searchInput: "",
    tilesPerPage: { value: "100", label: "ALL" },
    tilesPerPageOptions: [
      { value: "100", label: "ALL" },
      { value: "10", label: "10" },
      { value: "20", label: "20" },
      { value: "30", label: "30" },
      { value: "40", label: "40" },
      { value: "50", label: "50" },
      { value: "60", label: "60" },
    ],
    reportType: "",
    reportTypeOptions: [
      { value: "PDF", label: "PDF" },
      { value: "XLSX", label: "XLSX" },
    ],
    showSortCard: "show-n",
    showFilterCard: "show-n",
    rowData: [],
    originRegionOptions: [],
    originRegion: "",
    destinationRegionOptions: [],
    destinationRegion: "",
    container_org_options: [],
    container_dest_options: [],
    bl_org_options: [],
    bl_dest_options: [],
    overly: "show-n",
    documentsTranslate: "",
    eventName: "all",
    delayed_bl_data: {
      count: 0,
      data: [],
    },
    delayed_containers_data: {
      count: 0,
      data: [],
    },
    bl_detention_data: {
      count: 0,
      containers: [],
    },
    container_detention_data: { 
      count: 0,
      containers: [],
    },
  });

  const [sortCardClass, setSortCardClass] = useState("sortCardHeight_all");

  const openFilterModal2 = () => {
    setOpenFilterModal(true)
  };
  const handleCheckboxChange=(option)=>{
    if (checkedCheckboxes.includes(option)) {
      setCheckedCheckboxes(checkedCheckboxes.filter(item => item !== option));
    } else {
      setCheckedCheckboxes([...checkedCheckboxes, option]);
    }
  }
  const applySubCounters=async (e)=>{
    subcountersCheckbox.current=[...checkedCheckboxes]
    const counters=new Array();
    sub_counter.forEach((each)=>{
      if(subcountersCheckbox.current.includes(each)){
        counters.push(each)
      }
    })
    await redirectURL
        .post("/exim/saveSubCounterOptions", {email:localStorage.getItem("email"), counters:counters})
        .then((resp) => {
          if (resp.data.status === "success") {
            console.log(resp.data.message);
          }
        })
        .catch((e) => {
          console.log(
            "error message while updating customer reference for product",
            e
          );
        });
    setOpenSubCounterChoise(false)
  }
  const closeModal2=()=>{
    setOpenSubCounterChoise(false)
  }
  const closeModal = () => {
    clearAllFilters();
    if (tabActive === "BL/Booking") {
      // let blData = state.all_bl_data;
      setState({
        ...state,
        // bl_data: blData,
        destinationRegion: "",
        originRegion: "",
      });
      setOpenFilterModal(false)
      setFromDate("");
      setToDate("");
      setMovementType("");
    } else {
      // let containerData = state.all_container_data;
      setState({
        ...state,
        // container_data: containerData,
        destinationRegion: "",
        originRegion: "",
      });
      setOpenFilterModal(false)
      setFromDate("");
      setToDate("");
      setMovementType("");
    }
  };

  const getCountryName = (name) => {
    let countryName = "";
    countries.forEach((each) => {
      if (each.name.toLowerCase() == name.toLowerCase()) {
        countryName = each;
      }
    });
    return countryName;
  };

  const [mainCounter, setMainCounters] = useState({
    all: 0,
    origin: 0,
    in_transit: 0,
    destination: 0,
    completed: 0,
    delayed: 0,
    detained: 0,
  });

  const [containerCounters, setContainerCounters] = useState({
    all: 0,
    origin: 0,
    in_transit: 0,
    destination: 0,
    completed: 0,
    delayed: 0,
    detained: 0,
  });

  const [movementType, setMovementType] = useState("");

  const handleOptionChange = (event) => {
    setMovementType(event.target.value);
  };

  const [filterTypes, setFilterTypes] = useState({
    shippingdate: "show-m",
    originregion: "show-m",
    destinationregion: "show-m",
    shippingline: "show-m",
    movementtype: "show-m",
  });
  const selectRef = useRef(null);
  const selectRef1 = useRef(null);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMenuOpen1, setMenuOpen1] = useState(false);

  useEffect(() => {
    // console.log(movementType);
    // if (state.filterText) {
    // }
    let filters = [
      "shippingdate",
      "originregion",
      "destinationregion",
      "shippingline",
      "movementtype",
    ];
    let updateFilters = {};
    filters.forEach((each) => {
      if (each.includes(state.filterText.toLowerCase().split(" ").join(""))) {
        updateFilters[each] = "show-m";
      } else {
        updateFilters[each] = "show-n";
      }
    });
    setFilterTypes(updateFilters);
    document.addEventListener('mousedown', handleClickOutside);
  }, [state.filterText]);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      // Clicked outside the select component
      setMenuOpen(false);
    }

    if (selectRef1.current && !selectRef1.current.contains(event.target)) {
      // Clicked outside the select component
      setMenuOpen1(false);
    }
  };

  const handleMenuOpen = () => {
    // console.log("SA")
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  
  const handleMenuOpen1 = () => {
    // console.log("DD")
    setMenuOpen1(true);
  };

  const handleMenuClose1 = () => {
    setMenuOpen1(false);
  };

  const filterDataFromFilters = () => {
  
    // console.log(state.shippingLine);
    // console.log(fromDate, toDate); //
    // console.log("movementType", movementType); //
    
    setOpenFilterModal(false)
      let containerData;
      if((fromDate != "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine == "" && toDate == ""&& movementType == "") ||
      (fromDate == "" && state.originRegion != "" && state.destinationRegion == "" && state.shippingLine == "" && toDate == ""&& movementType == "") ||
      (fromDate == "" && state.originRegion == "" && state.destinationRegion != "" && state.shippingLine == "" && toDate == ""&& movementType == "") ||
      (fromDate == "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine != "" && toDate == ""&& movementType == "") ||
      (fromDate == "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine == "" && toDate != ""&& movementType == "") ||
      (fromDate == "" && state.originRegion == "" && state.destinationRegion == "" && state.shippingLine == "" && toDate == ""&& movementType != "") 
      )
      {
        containerData=state.all_container_data
      }
      else
      containerData=state.container_data
      let filteredData = [];
      //Movement Type
      if (movementType) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (each.movement_type && each.movement_type === movementType) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (each.movement_type && each.movement_type === movementType) {
              return each;
            }
          });
          if(filteredData.length==0){
          setState({
            ...state,
            container_data:filteredData
          })
          return
        }
        }
      }

      //if only fromDate and no toDate then filter all from or after created_at:"2023-11-15T05:12:56.460Z"
      if (fromDate && !toDate) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.container_created_date))
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.container_created_date))
            ) {
              return each;
            }
          });
        }
        if(filteredData.length==0){
        setState({
          ...state,
          container_data:filteredData
        })
        return
      }
      }

      //if only toDate and no fromDate then filter all before or equal to created_at:"2023-11-15T05:12:56.460Z"
      if (!fromDate && toDate) {
        if (filteredData.length > 0 ) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.container_created_date))
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.container_created_date))
            ) {
              return each;
            }
          });
        }
        if(filteredData.length==0){
        setState({
          ...state,
          container_data:filteredData
        })
        return
      }
      }

      // fromDate <= created_at <= toDate
      if (fromDate && toDate) {
        if (filteredData.length > 0 ) {
          filteredData = filteredData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.container_created_date)) &&
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.container_created_date))
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              moment
                .parseZone(fromDate)
                .isSameOrBefore(moment.parseZone(each.container_created_date)) &&
              moment
                .parseZone(toDate)
                .isSameOrAfter(moment.parseZone(each.container_created_date))
            ) {
              return each;
            }
          });
          if(filteredData.length==0){
          setState({
            ...state,
            container_data:filteredData
          })
          return
        }
        }
      }

      //Origin Region
      if (state.originRegion && state.originRegion.hasOwnProperty("label")) {
        if (filteredData.length > 0) {
          filteredData = filteredData.filter((each) => {
            if (
              each.source_location.toLowerCase() === state.originRegion.label.toLowerCase()
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              each.source_location.toLowerCase() === state.originRegion.label.toLowerCase()
            ) {
              return each;
            }
          });
        }
        if(filteredData.length==0){
        setState({
          ...state,
          container_data:filteredData
        })
        return
      }
      }

      //Destination Region
      if (
        state.destinationRegion &&
        state.destinationRegion.hasOwnProperty("label")
      ) {
        if (filteredData.length > 0 ) {
          filteredData = filteredData.filter((each) => {
            if (
              each.discharge_port_code.toLowerCase() ===
              state.destinationRegion.label.toLowerCase()
            ) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (
              each.discharge_port_code.toLowerCase() ===
              state.destinationRegion.label.toLowerCase()
            ) {
              return each;
            }
          });
        }
        if(filteredData.length==0){
        setState({
          ...state,
          container_data:filteredData
        })
        return
      }
      }

      //Shipping Line
      if (state.shippingLine && state.shippingLine.hasOwnProperty("value")) {
        if (filteredData.length > 0 ) {
          filteredData = filteredData.filter((each) => {
            if (each.shipping_line === state.shippingLine.value) {
              return each;
            }
          });
        } else {
          filteredData = containerData.filter((each) => {
            if (each.shipping_line === state.shippingLine.value) {
              return each;
            }
          });
          if(filteredData.length==0){
          setState({
            ...state,
            container_data:filteredData
          })
          return
        }
        }
      }
      setState({
        ...state,
        //container_data: filteredData.slice(0, tileCount),
        container_data: filteredData
      });
      // await showFilterCard();
    
    // $("#sort-filter")
    //   .addClass("show-n")
    //   .removeClass("show-m")
    //   .removeClass("card");
    // setState({
    //   ...state,
    //   showFilterCard: "show-n",
    // });
  };

  const clearAllFilters = () => {
    let tileCount = 10;

      if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
        tileCount = parseInt(state.tilesPerPage.value);
      }
    //setOpenFilterModal(false)
    let mainCounter=localStorage.getItem("current_counter")
    let subCounter=localStorage.getItem("sub_counter");
    if(subCounter==="")
    filterDataByCounter("main",mainCounter)
  else
    filterDataByCounter("sub",subCounter)
    setFromDate("");
    setToDate("");
    setMovementType("");
    setState({
      ...state,
      container_data:state.all_container_data,
      originRegion:"",
      destinationRegion:"",
    })
      // let containerData = state.all_container_data;
      // setState({
      //   ...state,
      //   container_data: containerData,
      //   destinationRegion: "",
      //   originRegion: "",
      // });
      // setFromDate("");
      // setToDate("");
      // setMovementType("");
  };

  const [eventName, setEventName] = useState("all")

  const [blCounters, setBLCounters] = useState({
    all: 0,
    origin: 0,
    in_transit: 0,
    destination: 0,
    completed: 0,
    delayed: 0,
    detained: 0,
    // sub_empty_pickup: 0,
    // sub_dispatch: 0,
    // sub_gate_in: 0,
    // sub_transhipment: 0,
    // sub_destination: 0,
    // sub_gate_out: 0,
    // sub_empty_return: 0,
  });

  // useEffect(() => {
  //   console.log("useEffect", state.eventName)
  //   if (state.eventName === "all") {
  //     setSortCardHeight("301px");
  //   } else {
  //     setSortCardHeight("285px");
  //   }
  // }, [state.eventName]);
    const sortTabHeight=useRef(0)

  const filterDataByCounter = (counterType, eventName) => {
    let tileCount = 10;

      if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
        tileCount = parseInt(state.tilesPerPage.value);
      }
    // console.log(
    //   "fdghjfilterDataByCounterwertyuiopufdfghjgfdgg",
    //   counterType,
    //   eventName,
    //   state.tabActive
    // );
    settabActiveSCnt(1);
    if(eventName!=="all")
    sortTabHeight.current="313px"
    else
    sortTabHeight.current="191px"

    setEventName(eventName);

    setState({
      ...state,
      counterType: counterType,
      eventName: eventName,
      showLoader: true,
    })
    if (eventName != "all") {
      setSortCardClass("sortCardHeight_others");
    }

      let containerDetentionData = [];

      //container level filter
      if (eventName === "all") {
        // console.log(state.all_container_data);
        setState({
          ...state,
          //container_data: state.all_container_data.slice(0, tileCount),
          container_data: state.all_container_data,
          eventName:"all",
          // showLoader: false,
        });
        return;
      }
      let filteredData = [];
      let containerData = state.all_container_data;
      //main card level sort
      if (counterType === "main") {
        filteredData = containerData.filter((each) => {
          let { event_group } = each.last_event_data[0];
          if (eventName === "in_transit") {
            if (
              event_group === "in_transit" ||
              event_group === "transshipment"
            ) {
              return each;
            }
          }
          if(eventName==="destination" && event_group==="destination"){
            return each
          }
          if(eventName==="origin" && event_group==="origin"){
            return each
          }
          if (eventName==="completed" && event_group==="completed") {
            return each;
          }
          if(eventName.toUpperCase()==="UNTRACKABLE" && each.status==="UNTRACKABLE"){
            return each
          }
        });
        
        //sub card level filter
      } else {
        filteredData = containerData.filter((each) => {
          let { event_code, sub_event_group } = each.last_event_data[0];
          if (sub_event_group === eventName) {
            return each;
          }
        });
      }
      // console.log("filterDataByCounter", eventName);
      // console.log("filteredData", filteredData);
      setState({
        ...state,
        //container_data: filteredData.slice(0, tileCount),
        container_data: filteredData
        // showLoader: false,
      });
    
  };

  const handleShippingDate = () => {};

  const showTabView = (tab) => {
    setTabActive(tab)
    if (tab === "BL/Booking") {
      setState({
        ...state,
        filterModeType: { value: "bl_number", label: "BL/Booking" },
        originRegionOptions: state.bl_org_options,
        destinationRegionOptions: state.bl_dest_options,
        shippingLines: state.blShippingLines,
        bl_data:state.all_bl_data,
        tilesPerPage:{value:state.all_bl_data.length, label:"ALL"}
      });
      localStorage.setItem("sub_counter","");
      setMainCounters(blCounters);
      clearAllFilters()
      settabActiveSCnt(0);

    } else {
      setState({
        ...state,
        filterModeType: {
          value: "container_number",
          label: "Container",
        },
        originRegionOptions: state.container_org_options,
        destinationRegionOptions: state.container_dest_options,
        shippingLines: state.containerShippingLines,
        container_data:state.all_container_data,
        tilesPerPage:{value:state.all_container_data.length,label:"ALL"}
      });
      localStorage.setItem("sub_counter","");
      setMainCounters(containerCounters);
      clearAllFilters();
      
      settabActiveSCnt(0);
    }

    // let inTransitTrackingData = []
    // if (tab == "transit") {
    //   let allTrackingData = [...this.state.allTrackingData]
    //   inTransitTrackingData = allTrackingData.filter(container => container.trip_completed == 0)
    // } else {
    //   let allTrackingData = [...this.state.allTrackingData]
    //   inTransitTrackingData = allTrackingData.filter(container => container.trip_completed == 1)
    // }
    // let trackingDataLength = inTransitTrackingData.length
    // console.log("trackingDataLength", trackingDataLength)
    // console.log(inTransitTrackingData, "inTransitTrackingData")
    // this.setState({
    //   loadshow: 'show-m',
    //   showFilterBox: false,
    //   tabActive: tab,
    //   showFilterBox: false,
    //   searchFilterValue: '',
    //   filterStartDate: '',
    //   filterOriginRegion: '',
    //   filterDestinationRegion: '',
    //   completedCounter: 0,
    //   onTimeCounter: 0,
    //   delayedCounter: 0,
    //   emptyPickupCounter: 0,
    //   dipatchCounter: 0,
    //   gateInCounter: 0,
    //   originCounter: 0,
    //   transhipmentCounter: 0,
    //   destinationCounter: 0,
    //   gateOutCounter: 0,
    //   emptyReturnCounter: 0,
    //   trackingDataLength: trackingDataLength
    // }, () => {
    //   // isTransitTrackingData has 12 records
    //   this.loadTrackingPageData(inTransitTrackingData, true)
    // })
  };

  const trackingData = () => {
    setState({
      ...state,
      showLoader: true,
    });
    let payload = {};
    redirectURL
      .post("/exim/getTrackingData", payload)
      .then((resp) => {
        console.log("getTrackingData data", resp.data);

        if (resp.data.status === "success") {
          let {
            container_data,
            container_new_shipments,
            container_untrackable,
          } = resp.data;
          

          let container_org_options = [];
          let container_dest_options = [];

          
          let containerAllCounters = {
            all: 0,
            origin: 0,
            in_transit: 0,
            destination: 0,
            completed: 0,
            delayed: 0,
            detained: 0,
          };


          let containerAllCount = 0; //count for all trackable containers i.e which have mbl_number
          let containerUnTrackable = 0; //count for all untrackable containers i.e which have no mbl_number
          let untrackableContainers = []; //data of untrackable containers with no mbl_number
          let trackableContainers = []; //data of untrackable containers with no mbl_number

          let containerOrgCountries = [];
          let containerDestCountries = [];

          let containerShippingLines = [];
          let containerShippingDuplicates = [];

          //container_data filtering
          
          container_data.forEach((each) => {
            let {
              loading_port_code,
              discharge_port_code,
              mbl_number,
              shipping_line,
              slab_name,
              last_event_data,
            } = each;

            if (!containerShippingDuplicates.includes(shipping_line)) {
              containerShippingLines.push({
                label: shipping_line,
                value: shipping_line,
              });
              containerShippingDuplicates.push(shipping_line);
            }
            if(each.hasOwnProperty("force_close") && each.force_close===1){
              last_event_data[0].status="COMPLETED"
              last_event_data[0].event_group="completed"
              last_event_data[0].event_name="Force Closed"
              last_event_data[0].sub_event_group="empty_return"
            }
            let { event_name, event_code, event_group, sub_event_group } =
              each.last_event_data[0];

            // if (mbl_number !== "") {
            //
            //to remove duplicate insertion
            let originCountry = getCountryName(each.source_location);
            if (!containerOrgCountries.includes(originCountry)) {
              container_org_options.push({
                label: originCountry.name,
                value: originCountry.ios2,
              });
              containerOrgCountries.push(originCountry);
            }

            let destinationCountry = getCountryName(each.destination_location);

            //to remove duplicate insertion
            if (!containerDestCountries.includes(destinationCountry)) {
              container_dest_options.push({
                label: destinationCountry.name,
                value: destinationCountry.ios2,
              });
              containerDestCountries.push(destinationCountry);
            }
            //

            containerAllCount++;
            trackableContainers.push(each);
            // at main level
                containerAllCounters["in_transit"] = 1;
              
              if (containerAllCounters.hasOwnProperty(event_group)) {
                containerAllCounters[event_group] =
                  containerAllCounters[event_group] + 1; //increase count of main counter by 1 if already exists
              } else {
                containerAllCounters[event_group] = 1;
              }
            

            //at sub_level
            if (sub_event_group) {
              let sublevel_key = "";
              
                sublevel_key = event_group + "_" + sub_event_group;
              
              if (containerAllCounters.hasOwnProperty(sublevel_key)) {
                containerAllCounters[sublevel_key] =
                  containerAllCounters[sublevel_key] + 1; //increase count of main counter by 1 if already exists
              } else {
                containerAllCounters[sublevel_key] = 1;
              }
            }
            each.movement_type="Export"
            

            
          });

          containerAllCounters["containersTrackable"] = containerAllCount;
          containerAllCounters["containersUnTrackable"] = containerUnTrackable;
          containerAllCounters["all"] = containerAllCount + container_untrackable.length+container_new_shipments.length;
          containerAllCounters["untrackable"] = container_untrackable.length;

          let trans = 0;
          let inT = 0;

       
          
          //console.log(blAllCounters,"setMainCounter");
          containerAllCounters.in_transit=0;
          containerAllCounters["delayed"]=0
          containerAllCounters["destination"]=0
          containerAllCounters["detained"]=0
          containerAllCounters["untrackable"]=0
          console.log(containerAllCounters, mainCounter,"setMainCountersss");
          setMainCounters(containerAllCounters);
          const last_event_data=[{status:"",event_name:"",actual_time:"",event_code:"",shipment_no:"",event_group:"",location:"",sub_event_group:""}]
          let allContainerData=container_new_shipments.map(item=>({...item,last_event_data})).concat(trackableContainers,container_untrackable.map(item=>({...item,last_event_data})))

        
          setState({
            ...state,
            tilesPerPage:{value:(containerAllCount + container_untrackable.length+container_new_shipments.length),label:"ALL"},
            tilesPerPageOptions:[
              {value:(containerAllCount + container_untrackable.length+container_new_shipments.length),label:"ALL"},
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "30", label: "30" },
              { value: "40", label: "40" },
              { value: "50", label: "50" },
              { value: "60", label: "60" },
            ],
            //Set All Container Data
            all_container_data: allContainerData,
            trackableContainers:allContainerData,
            untrackableContainers,
            container_data: allContainerData,
            showLoader: false,
            container_org_options,
            container_dest_options,
            containerShippingLines: containerShippingLines,
            container_new_shipments,
            container_untrackable,
            originRegionOptions: container_org_options,
            destinationRegionOptions: container_dest_options,
          });


        }
      })
      .catch((err) => {
        console.log("getTrackingData err", err);
      });
  };

  const handleView = (viewType) => {
    setState({
      ...state,
      showSortCard: "show-n",
    });

    $("#sort-filter")
      .removeClass("show-m")
      .addClass("show-n")
      .removeClass("sort-filter-slider-close")
      .removeClass("sort-filter-slider-open")
      .removeClass("card");

    if (viewType === "tileView") {
      setState({
        ...state,
        isTileView: true,
      });
      // $("#sort-filter").css({"top":"276px"})
    } else {
      setState({
        ...state,
        isTileView: false,
      });
      // $("#sort-filter").css({"top":"495px"})
    }
  };
  
  const filterDataFromSearchBox = () => {
    let tileCount = 10;

      if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
        tileCount = parseInt(state.tilesPerPage.value);
      }
    // console.log("state.filterModeType.value", state.filterModeType.value);
      let containerData = state.container_data;
      
      if (state.searchInput === "") {
        
        let mainCounter=localStorage.getItem("current_counter")
        let subCounter=localStorage.getItem("sub_counter");
        if(subCounter==="")
        filterDataByCounter("main",mainCounter)
        else
        filterDataByCounter("sub",subCounter)
        // setState({
        //   ...state,
        //   //containerData: state.all_container_data.slice(0, tileCount),
        //   containerData: state.all_container_data
        // });
        // localStorage.setItem("current_counter","all")
        // settabActiveSCnt(0)
        return
      }
      if (state.filterModeType.value === "bl_number") {
        if (state.searchInput) {
          try{
            let container_filteredData = containerData.filter((each) => {
            if (
              each.mbl_number.includes(state.searchInput.trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //container_data: container_filteredData.slice(0, tileCount),
            container_data: container_filteredData
          });
        }catch(err){
          setState({
            ...state,
            //container_data: container_filteredData.slice(0, tileCount),
            container_data: []
          });
        }
        } else {
          setState({
            ...state,
            //container_data: containerData.slice(0, tileCount),
            container_data: containerData
          });
        }
      } else if (state.filterModeType.value === "container_number") {
        if (state.searchInput) {
          let container_filteredData = containerData.filter((each) => {
            if (
              each.container_no.includes(state.searchInput.trim())
            ) {
              return each;
            }
          });
          setState({
            ...state,
            //container_data: container_filteredData.slice(0, tileCount),
            container_data: container_filteredData
          });
        } else {
          setState({
            ...state,
            //container_data: containerData.slice(0, tileCount),
            container_data: containerData
          });
        }
      } 
      // else if (state.filterModeType.value === "invoice_no") {
      //   if (state.searchInput) {
      //     let container_filteredData = containerData.filter((each) => {
      //       if (
      //         each.invoice_no &&
      //         each.invoice_no
      //           .toLowerCase()
      //           .includes(state.searchInput.toLowerCase().trim())
      //       ) {
      //         return each;
      //       }
      //     });
      //     setState({
      //       ...state,
      //       //container_data: container_filteredData.slice(0, tileCount),
      //       container_data: container_filteredData
      //     });
      //   }
      // } else if (state.filterModeType.value === "po_no") {
      //   if (state.searchInput) {
      //     let container_filteredData = containerData.filter((each) => {
      //       if (
      //         each.po_no &&
      //         each.po_no
      //           .toLowerCase()
      //           .includes(state.searchInput.toLowerCase().trim())
      //       ) {
      //         return each;
      //       }
      //     });
      //     setState({
      //       ...state,
      //       //container_data: container_filteredData.slice(0, tileCount),
      //       container_data: container_filteredData
      //     });
      //   }
      // }
    
  };

  const trackingDetails = () => {
    // console.log(state.filterModeType);
    // console.log(state.searchInput);

    filterDataFromSearchBox();

    // let { label } = state.filterModeType;
    // let acceptedFormat = "";
    // let payload = {};
    // let urlType = "";
    // if (label === "Container number") {
    //   acceptedFormat = "ABCD1234567";
    //   var regexPattern = /^[A-Z]{4}\d{7}$/;
    //   payload["container_no"] = state.searchInput;
    //   urlType = "/exim/containerTrackingDetails";
    // } else {
    //   acceptedFormat = "BL123456789";
    //   var regexPattern = /^BL\d{9}$/;
    //   payload["container_no"] = state.searchInput;
    //   urlType = "/exim/mblTrackingDetails";
    // }

    // if (!regexPattern.test(state.searchInput)) {
    //   alert(`Invalid ${label} and valid format is ${acceptedFormat}`);
    //   return;
    // }

    // redirectURL
    //   .post(urlType, payload)
    //   .then((resp) => {
    //     console.log("trackingDetails", resp.data);
    //   })
    //   .catch((err) => {
    //     console.log("trackingDetails err", err);
    //   });
  };

  useEffect(() => {
    filterDataFromSearchBox();
  }, [state.searchInput]);

  useEffect(() => {
    let tileCount = 10;

    if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
      tileCount = parseInt(state.tilesPerPage.value);
    }
    if (tabActive === "BL/Booking") {
      // console.log(object)
      
      // let blData = state.bl_data.slice(0, tileCount);
      // setState({
      //   ...state,
      //   bl_data: blData,
      // });
    } else {
      let containerData
      //if((tileCount-state.container_new_shipments.length-state.container_untrackable.length)>0)
      //tileCount=tileCount-state.container_new_shipments.length-state.container_untrackable.length
      //else{
        // console.log(state.container_untrackable.length,"state.container_untrackable.lengthhh");
        // containerData = state.all_container_data.slice(0, tileCount);
        // setState({
        //   ...state,
        //   container_data: containerData,
        // })
      //}
      // setState({
      //   ...state,
      //   container_data: containerData,
      // });
    }

    var mm = 'all'
    if(localStorage.getItem("current_counter") == null)
    {
      mm = 'all';
    }
    else{
      mm = localStorage.getItem("current_counter"); 
    }

    // console.log(state.counterType+" , "+state.eventName+",,"+mm);
    if(localStorage.getItem("sub_counter") != "" && localStorage.getItem("sub_counter") != undefined)
    {
      
      var smm = 'all'
      if(localStorage.getItem("sub_counter") == null || localStorage.getItem("sub_counter") == "")
      {
        smm = 'all';
      }
      else{
        smm = localStorage.getItem("sub_counter"); 
      }
      filterDataByCounter("sub",smm)
    }
    else{
      filterDataByCounter("main",mm)
    }

  }, [state.tilesPerPage]);

  const handleFilterOptions = (selOption, dropdownName) => {
    // console.log("selOption", selOption);
     if(dropdownName.name==="tilesPerPage" && selOption.label==="ALL"){
        setState({
          ...state,
          tilesPerPage: {
            "value": state.all_container_data.length,
            "label": "ALL"
        },
        container_data:state.all_container_data
        });
     }
     else{
    setState({
      ...state,
      [dropdownName.name]: selOption,
    });
  }
  };

  const openDocumentsSlider = () => {
    setState({
      ...state,
      overly: "show-m",
      documentsTranslate: "slider-translate",
    });
  };

  const showSortCard = () => {
    $("#filter-card")
      .addClass("show-n")
      .removeClass("show-m")
      .removeClass("card");

    // sort-filter-slider-close
    //left - open
    //right - close
    let finalClass = "";
    if ($("#sidebarToggleIcon").hasClass("fa-angle-left")) {
      finalClass = "sort-filter-slider-open card";
    } else {
      finalClass = "sort-filter-slider-close card";
    }

    if (state.showSortCard === "show-n") {
      setState({
        ...state,
        showSortCard: "show-m " + finalClass,
      });
    } else {
      setState({
        ...state,
        showSortCard: "show-n",
      });
    }
  };

  const showFilterCard = () => {
    $("#sort-filter")
      .addClass("show-n")
      .removeClass("show-m")
      .removeClass("card");
    if (state.showFilterCard === "show-n") {
      setState({
        ...state,
        showFilterCard: "show-m card",
      });
    } else {
      setState({
        ...state,
        showFilterCard: "show-n",
        destinationRegion: "",
        originRegion: "",
        filterText: "",
      });
      setFromDate("");
    }
  };

  const sortData = (dataType) => {
    let tileCount = 10;

      if (state.tilesPerPage && state.tilesPerPage.hasOwnProperty("value")) {
        tileCount = parseInt(state.tilesPerPage.value);
      }
      var defnew = 0;
      if(localStorage.getItem("current_counter") == "all")
      {
        if (tabActive === "BL/Booking") {
          defnew= state.bl_new_shipments.length;
        }
        else{
          defnew = state.container_new_shipments.length;
        }
      }
    // console.log(dataType);
    // console.log(state.container_data);
    if (dataType === "last_tracking_time") {
      if (tabActive === "BL/Booking") {
        let blData = state.bl_data;
        blData.sort((a, b) =>
          moment(b.tracking_updated_at).diff(moment(a.tracking_updated_at))
        ); //descending order
        // state.container_data
        setState({
          ...state,
          //bl_data: blData.slice(0, tileCount-defnew),
          //bl_data:blData,
          showSortCard: "show-n",
        });
      } else {
        let containerData = state.container_data;
        containerData.sort((a, b) =>
          moment(b.tracking_updated_at).diff(moment(a.tracking_updated_at))
        ); //descending order
        // state.container_data
        setState({
          ...state,
          //container_data: containerData.slice(0, tileCount-defnew),
          container_data:containerData,
          showSortCard: "show-n",
        });
        // $("#sort-filter")
        //   .addClass("show-n")
        //   .removeClass("show-m")
        //   .removeClass("card");
      }
    } else if (dataType === "last_activity_update") {
     
        let containerData = state.container_data;
        // let last_event_time = containerData[0].last_event_data[0].actual_time
        containerData.sort((a, b) =>
          moment(a.last_event_data[0].actual_time).diff(
            moment(b.last_event_data[0].actual_time)
          )
        ); //descending order
        setState({
          ...state,
          //container_data: containerData.slice(0, tileCount-defnew),
          container_data: containerData,
          showSortCard: "show-n",
        });
      
      // $("#sort-filter")
      //   .addClass("show-n")
      //   .removeClass("show-m")
      //   .removeClass("card");
    } else {
      //for final_eta
        let containerData = state.container_data;
        containerData.sort(
          (
            a,
            b //if ata exits then ata else take eta
          ) =>
            moment(b.vessel_etd).diff(
              moment(a.vessel_etd)
            )
        ); //descending order
        // state.container_data
        setState({
          ...state,
          //container_data: containerData.slice(0, tileCount-defnew),
          container_data: containerData,
        });
      

      // $("#sort-filter")
      //   .addClass("show-n")
      //   .removeClass("show-m")
      //   .removeClass("card");
    }
  };

  useEffect(() => {
    localStorage.setItem("current_counter","all");
    localStorage.setItem("sub_counter","");
    trackingData();
    const temp=[]
    let promise=new Promise(
      function(myResolve,myReject){
        redirectURL.get("/exim/getULIPData")
        .then(response=>{
          myResolve(response.data)
        })
        .catch(error=>myReject(error))
      })
      promise.then(function(data) {
          if(data.data[0].ldb_opted==1)
          temp.push("LDB")
          if(data.data[0].fois_opted==1)
          temp.push("FOIS")
          if(data.data[0].icegate_opted==1)
          temp.push("ICEGATE")
          if(data.data[0].pcs_opted==1)
          temp.push("PCS")
        setCheckedCheckboxes(prev=>[...prev,...temp])
        setULIPData(temp) 
      }, 
      function(error) {
        console.log(error);
      })
      let promise2=new Promise(
        function(myResolve,myReject){
          redirectURL.post("/exim/getSubCounterOptions",{email:localStorage.getItem("email")})
          .then(response=>{
            myResolve(response.data)
          })
          .catch(error=>myReject(error))
        })
        promise2.then(function(data) {
          setCheckedCheckboxes([...data.data[0].counters,...temp])
          subcountersCheckbox.current=[...data.data[0].counters,...temp]
        },
        function(error) {
          console.log(error);
        })
  }, []);

  const onClickHideAll = () => {
    setState({
      ...state,
      overly: "show-n",
      documentsTranslate: "",
    });
  };

  

  const handleFromDate = (date) => {
    setFromDate(date);
    // setToDate((prevToDate) => (prevToDate < date ? date : prevToDate));
  };

  const handleToDate = (date) => {
    setToDate(date);
    setFromDate((prevFromDate) => (prevFromDate > date ? date : prevFromDate));
  };

  return (
    <>
       <div>
        <h5 style={{ marginLeft: '40px',marginTop:"25px", marginBottom:"15px"}} className='heading'>
                        Container Tracking
                  </h5>
     </div> 
      <BootstrapLoader showLoader={state.showLoader} />
      <Modal open={openFilterModal} onClose={closeModal}
      center
      classNames={{
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      padding="0px"
      animationDuration={800}
      >
        <div className="" style={{ height: "57px" }}>
          <h5 className="modal-heading" style={{borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>Filters</h5>
        </div>

        <div className="px-2 mb-4">
          {/* Search And Buttons Come Here */}
          <div className="p-2">
            <input
              type="text"
              name=""
              id=""
              className="search_input"
              style={{ width: "44%" }}
              value={state.filterText}
              onChange={(e) => {
                setState({ ...state, filterText: e.target.value });
              }}
            />
            <img
              src={SearchIcon}
              className="filter_search_icon"
              // onClick={trackingDetails}
            />
            <button
              className="btn btn-primary filter_button mr-2"
              // style={{ width: "85px", fontSize: "11px" }}
              onClick={clearAllFilters}
              style={{borderRadius:"10px"}}
            >
              Clear Filters
            </button>
            <button
              className="btn btn-primary filter_button mr-2"
              // style={{ width: "85px", fontSize: "11px" }}
              style={{borderRadius:"10px"}}
              disabled={
                fromDate ||
                state.originRegion ||
                state.destinationRegion ||
                state.shippingLine ||
                toDate ||
                movementType
                  ? null
                  : "disabled"
              }
              onClick={filterDataFromFilters}
            >
              Apply Filters
            </button>
            {/* <button
              class="btn btn-primary filter_button"
              // style={{ width: "85px", fontSize: "11px" }}
              onClick={closeModal}
            >
              Close
            </button> */}
          </div>
          {/* Movement Type */}
          {/* <div
            className={`region_card mt-2 ${filterTypes.movementtype} px-2`}
            id="originregion"
          >
            <label for="" className="mr-3">
              Movement Type
            </label>
            <label className="mr-3">
              <input
                type="radio"
                value="Import"
                checked={movementType === "Import"}
                onChange={handleOptionChange}
                className="radio_button"
              />
              Import
            </label>

            <label>
              <input
                type="radio"
                value="Export"
                checked={movementType === "Export"}
                onChange={handleOptionChange}
                className="radio_button"
              />
              Export
            </label>
          </div> */}
          {/* Shipping Date */}
          <div
            className={`${filterTypes.shippingdate} mt-2 col-3 px-2`}
            id="shippingdate"
          >
            <div className="row">
              <div className="col-6">
                <label for="" style={{ width: "375%" }}>
                  Container Created From Date :
                </label>
                {/* <label for="">From</label> */}
                <DatePicker
                  selected={fromDate}
                  onChange={handleFromDate}
                  dateFormat={"yyyy/MM/dd"}
                  selectsStart
                  startDate={fromDate}
                  endDate={toDate}
                  maxDate={toDate ? toDate : null}
                  wrapperClassName="datepick"
                />
              </div>
              <div
                className="col-6"
                style={{ position: "absolute", left: "307px" }}
              >
                <label for="" style={{ width: "375%" }}>
                  Container Created To Date :
                </label>

                {/* <label for="">To</label> */}
                <DatePicker
                  selected={toDate}
                  onChange={handleToDate}
                  dateFormat={"yyyy/MM/dd"}
                  selectsEnd
                  startDate={fromDate}
                  endDate={toDate}
                  minDate={fromDate ? fromDate : null}
                  wrapperClassName="datepick"
                />
              </div>
            </div>
          </div>
          {/* Origin Region */}
          <div style={{display: "flex"}}>
          <div style={{flex: 1, padding: "10px", border: "1px"}}>
          <div
            id="originregion">
            <label for="">Origin Region</label>
            <Select
              closeMenuOnSelect={true}
              name="originRegion"
              className="border-radius-0"
              onChange={handleFilterOptions}
              options={state.originRegionOptions}
              value={state.originRegion}
              maxMenuHeight={window.innerHeight*0.26}
            />
          </div>
          </div>
          <div style={{flex: 1, padding: "10px", border: "1px"}}>
          <div
            // class="region_card destinationregion"
            id="destinationregion"
          >
            <label for="">Destination Region</label>
            <Select
              closeMenuOnSelect={true}
              name="destinationRegion"
              className="border-radius-0"
              onChange={handleFilterOptions}
              options={state.destinationRegionOptions}
              value={state.destinationRegion}
              required
              maxMenuHeight={window.innerHeight*0.26}
            />
          </div>
          </div>
          </div>


        </div>
      </Modal>
      <Modal open={openSubCounterChoise}
      center
      closeIcon={"  "}
      classNames={{
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      animationDuration={800}>
      <div className="mx-4 mt-5 row align-content-center justify-content-center"
        style={{
          cursor: "pointer",
          height:"54px",
          background: "#e4edfc",
          borderRadius: "12px",
          whiteSpace: "nowrap"
      }}>
       <div className="col-3" style={{
          color:(subCounters.id==1)?"#fff":"#0664AE",
          background:(subCounters.id==1)?"#0664AE":"#00000000",
          borderRadius: "12px",
        }}>
          <button className="col"
          style={{
            color:(subCounters.id==1)?"#fff":"black",
            background:"#00000000",
            height:"54px",
            border:"none",
            borderRadius: "12px",
            fontWeight:"bold"
          }}
          onClick={()=>setSubCounters(counters.current[0])}>
          <img src={(subCounters.id==1)?subCounters.activeImgSrc:counters.current[0].imgSrc}/>&nbsp; Origin
          </button>
        </div>
        <div className="col-3" style={{
          color:(subCounters.id==2)?"#fff":"#0664AE",
          background:(subCounters.id==2)?"#0664AE":"#00000000",
          borderRadius: "12px",
          paddingLeft:"3px"
        }}>
        <button className="col"
           style={{
            color:(subCounters.id==2)?"#fff":"black",
            background:"#00000000",
            height:"54px",
            border:"none",
            borderRadius: "12px",
            fontWeight:"bold"
          }}
          onClick={()=>{
            //setSubCounters(counters.current[1])
          }}>
          <img src={(subCounters.id==2)?subCounters.activeImgSrc:counters.current[1].imgSrc} style={{maxHeight:"35px" ,maxWidth:"30px"}}/>&nbsp; In-transit
          </button>
        </div>
        <div className="col-3" style={{
          color:(subCounters.id==3)?"#fff":"#0664AE",
          background:(subCounters.id==3)?"#0664AE":"#00000000",
          borderRadius: "12px",
          paddingLeft:"2px"
        }}>
        <button className="col"
           style={{
            color:(subCounters.id==3)?"#fff":"black",
            background:"#00000000",
            height:"54px",
            border:"none",
            borderRadius: "12px",
            fontWeight:"bold"
          }}
          onClick={()=>{
            //setSubCounters(counters.current[2])
          }}>
          <img src={(subCounters.id==3)?subCounters.activeImgSrc:counters.current[2].imgSrc}/>&nbsp; Destination
          </button>
        </div>
        <div className="col-3" style={{
          color:(subCounters.id==4)?"#fff":"#0664AE",
          background:(subCounters.id==4)?"#0664AE":"#00000000",
          borderRadius: "12px",
        }}>
        <button className="col"
           style={{
            color:(subCounters.id==4)?"#fff":"black",
            background:"#00000000",
            height:"54px",
            border:"none",
            borderRadius: "12px",
            fontWeight:"bold"
          }}
          onClick={()=>setSubCounters(counters.current[3])}>
          {(subCounters.id==4)?<FaGlobe size={38} style={{color:"#ffff"}}/>:<FaGlobe size={38} style={{color:"#0664AE"}}/>}&nbsp; ULIP
          </button>
        </div>
      </div>
      <div className="row mx-5 my-4 justify-content-center align-content-left" style={{height:"12rem"}}>
        {
          subCounters.subCounters.map((item,index)=>(
            <div key={item.value} className="col-6 my-2 pt-3" style={{fontSize:"medium"}}><input type="checkbox" style={{ transform: "scale(1.3)"}} disabled={(subCounters.id===4)?true:false} value={item.value} checked={checkedCheckboxes.includes(item.value)} onChange={()=>handleCheckboxChange(item.value)}/>&nbsp;&nbsp;&nbsp;{item.label}</div>
          ))
        }
      </div>
      <div className="row mx-4 mb-5 align-content-center justify-content-center">
        <div className="col-6">
          <button className="col"
          style={{
            color:"#0664AE",
            background:"#fff",
            border:"2px solid #0664AE",
            borderRadius: "12px",
            height:"35px"
          }}
          onClick={()=>closeModal2()}
           >{(subCounters.id==4)? "Close":"Cancel"}
          {/* >Cancel */}
          </button>
          </div>
        <div className={(subCounters.id==4)?"d-none":"col-6"}>
          <button className="col"
          style={{
            color:"#fff",
            background:"#0664AE",
            border:"none",
            borderRadius: "12px",
            height:"35px",
          }}
          onClick={applySubCounters}
          >Apply
            </button></div>
      </div>
      </Modal>
      
      <div className="container-fluid">
      
        <div
          className="card col-lg-12 col-md-12 pt-4"
          style={{ borderRadius: "18px" }}
        >
          <div>
            <MainTrackingCounters
              counters={mainCounter}
              filterDataByCounter={filterDataByCounter}
              tabActiveSCnt={tabActiveSCnt}
              subCountersTab={subcountersCheckbox.current}
              clearAllFilters={clearAllFilters}
              // tabActive={state.tabActive}
              // bl_counters={blCounters}
              // containerCounters={containerCounters}
            />
          </div>

          {/* Functionality Card */}
          <div className="d-flex flex-row mb-5 pl-3 mt-4 flex-wrap settings-align">
            <div className="d-flex flex-row">
              {/* <select style={{ width: "115px", height: "31px", borderRadius: "6px", border: "1px solid #cccccc", marginRight: "20px" }} >
  

                        </select> */}

              <div className="region_card mr-3" ref={selectRef}>
                {/* mode-type */}
                <Select
                 menuIsOpen={isMenuOpen}
                 onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  closeMenuOnSelect={true}
                  name="filterModeType"
                  className="border-radius-0"
                  onChange={handleFilterOptions}
                  options={state.modeOptions}
                  value={state.filterModeType}
                  // isDisabled={true}
                  required
                />
              </div>
              <div
                className="d-flex"
                style={{ position: "relative", marginRight: "20px" }}
              >
                <input
                  style={{
                    border: "1px solid #cccccc",
                    borderRadius: "11px",
                    height: "38px",
                    outline: "none",
                    paddingLeft: "6px",
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setState({
                      ...state,
                      searchInput: newValue,
                    });
                  }}
                  value={state.searchInput}
                />
                <img
                  src={SearchIcon}
                  className="search_icon"
                  onClick={trackingDetails}
                />
              </div>
                <div className="d-flex py-2 mr-3 justify-content-center align-items-center"
                style={{
                  background: "white",
                  borderRadius: "11px",
                  border: "1px solid #0664AE",
                  height: "38px",
                  paddingRight:"10px"
                }}>
            <NavDropdown
              className="custom-nav-dropdown"
              title={<CustomDropdownTitle/>}
              style={{
                fontSize: "13px",
                fontWeight: 700,
                color: "#0664AE",
                marginLeft: "10px",
              }}
            >
              {/* <NavDropdown.Item onClick={() => {
                    sortData("last_tracking_time");
                  }}>
              Last Tracking Time</NavDropdown.Item> */}
              <NavDropdown.Item onClick={() => {
                    sortData("last_activity_update");
                  }}>
              Last Activity Update</NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                    sortData("vessel_etd");
                  }}>
              Vessel ETD</NavDropdown.Item>
              </NavDropdown>
                </div>
              {/* </button> */}
              <button
                className="d-flex px-3 py-2 justify-content-center align-items-center"
                style={{
                  background: "white",
                  borderRadius: "11px",
                  border: "1px solid #0664AE",
                  height: "38px",
                }}
              >
                <div className="d-flex flex-row" onClick={openFilterModal2}>
                  <img src={FilterIcon}></img>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 700,
                      color: "#0664AE",
                      marginLeft: "10px",
                    }}
                  >
                    Filters
                  </div>
                </div>
              </button>

              {/* <div
                className={`sort-card ${state.showSortCard} ${sortCardClass}`}
                id="sort-filter"
                style={{
                  left:
                    state.filterModeType.value === "bl_number" ||
                    state.filterModeType.value === "po_no"
                      ? "328px"
                      : "328px",
                  top: sortTabHeight.current
                }}
              >
                <div
                  className="sorting-line cursor-class"
                  onClick={() => {
                    sortData("last_tracking_time");
                  }}
                >
                  <label className="cursor-class" for="">
                    Last Tracking Time
                  </label>
                </div>
                <div
                  className="sorting-line cursor-class"
                  onClick={() => {
                    sortData("last_activity_update");
                  }}
                >
                  <label className="cursor-class" for="">
                    Last Activity Update
                  </label>
                </div>
                <div
                  className="cursor-class"
                  onClick={() => {
                    sortData("final_eta");
                  }}
                >
                  <label className="cursor-class" for="">
                    Final ETA
                  </label>
                </div>
              </div> */}
            </div>
            <div className="d-flex flex-row ml-4">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row">
                  <button
                    className="d-flex flex-row justify-content-center align-items-center px-3"
                    style={{
                      background: state.isTileView ? "#0664AE" : "white",
                      // color: state.isTileView ? "": "#0664AE",
                      borderTopLeftRadius: "11px",
                      borderBottomLeftRadius: "11px",
                      border: "1px solid #0664AE",
                      height: "38px",
                    }}
                    onClick={() => {
                      handleView("tileView");
                    }}
                  >
                    {/* <img src={TileViewActive}/> */}
                    <img
                      src={state.isTileView ? TileViewActive : TileViewInactive}
                    />
                    <div
                      style={{
                        color: state.isTileView ? "white" : "#0664AE",
                        fontSize: "10px",
                        fontWeight: 700,
                        marginLeft: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      Tile
                      <br />
                      View
                    </div>
                  </button>

                  <button
                    className="d-flex flex-row justify-content-center align-items-center px-3"
                    style={{
                      background: !state.isTileView ? "#0664AE" : "white",
                      borderTopRightRadius: "11px",
                      borderBottomRightRadius: "11px",
                      border: "1px solid #0664AE",
                      height: "38px",
                    }}
                    onClick={() => {
                      handleView("gridView");
                    }}
                  >
                    {/* <img src={GridViewInactive}/> */}
                    <img
                      src={
                        !state.isTileView ? GridViewActive : GridViewInactive
                      }
                    />
                    <div
                      style={{
                        color: !state.isTileView ? "white" : "#0664AE",
                        fontSize: "10px",
                        fontWeight: 700,
                        marginLeft: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      Grid
                      <br />
                      View
                    </div>
                  </button>
                </div>
                <div
                  className="d-flex flex-column ml-3"
                  style={{ position: "relative" }}
                >
                  <label
                    style={{
                      fontSize: "10px",
                      color: "#0664AE",
                      position: "absolute",
                      top: "-25px",
                    }}
                  >
                    Tiles per page
                  </label>
                  {/* <select style={{ width: "115px", height: "31px", borderRadius: "6px", border: "1px solid #cccccc", marginRight: "20px", height: "31px" }}></select> */}
                  <div className="tilesPageSelector" ref={selectRef1}>
                {/* mode-type */}
                <Select
                 menuIsOpen={isMenuOpen1}
                 onMenuOpen={handleMenuOpen1}
                  onMenuClose={handleMenuClose1}
                      closeMenuOnSelect={true}
                      className="border-radius-0"
                      name="tilesPerPage"
                      // styles={{
                      //     control: (provided, state) => ({
                      //     ...provided,
                      //     fontSize: "0.8rem",
                      //     color: "#6e707e",
                      //     }),
                      // }}
                      onChange={handleFilterOptions}
                      options={state.tilesPerPageOptions}
                      value={state.tilesPerPage}
                      required
                    />
                  </div>
                </div>
                <div className="row px-5 my-4 settings-right">
          {/* <div className="col containerSettings">
          <span style={{fontSize:"1.5vw"}}></span>
          </div> */}
          <div className="col">
            <button
            className="buttonSettings"
            style={{border:"none", background:"#f8f9fc", color:"#0664AE"}}
              onClick={() => {
                setOpenSubCounterChoise(true)
              }}
            ><IoSettingsOutline size={38}/>
            </button>
          </div>
        </div>
              </div>
            </div>
            <div className="d-none">
              {/* d-flex flex-row  */}
              <div
                className="d-flex flex-column ml-2"
                style={{ position: "relative" }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    color: "#0664AE",
                    position: "absolute",
                    top: "-25px",
                  }}
                >
                  Reports
                </label>
                <div className="region_card mr-3">
                  {/* mode-type */}
                  <Select
                    closeMenuOnSelect={true}
                    name="reportType"
                    className="border-radius-0"
                    // styles={{
                    //     control: (provided, state) => ({
                    //     ...provided,
                    //     fontSize: "0.8rem",
                    //     color: "#6e707e",
                    //     }),
                    // }}
                    onChange={handleFilterOptions}
                    options={state.reportTypeOptions}
                    value={state.reportType}
                    required
                  />
                </div>
              </div>
              <div>
                <button
                  className="d-flex flex-row justify-content-center align-items-center px-3"
                  style={{
                    background: "white",
                    borderRadius: "11px",
                    border: "1px solid #0664AE",
                    height: "38px",
                  }}
                >
                  <img src={DownloadIcon}></img>
                  <div
                    style={{
                      color: "#0664AE",
                      fontSize: "10px",
                      fontWeight: 700,
                      marginLeft: "10px",
                      lineHeight: "12px",
                    }}
                  >
                    Download
                  </div>
                </button>
              </div>
            </div>
          </div>
          {/* Functionality Card */}
           
          {state.isTileView ? (
            <>
              <div>
                <TrackingTile
                  container_data={state.container_data}
                  trackingDataFun={trackingData}
                  eventName={ eventName }
                  tilesPerPage={state.tilesPerPage}
                />
              </div>
            </>
          ) : (
            <>
              <EximGrid
                container_data={state.container_data}
                paginationSize={state.tilesPerPage}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
const CustomDropdownTitle = () => {
  return (
    <span>
      <img src={SortIcon}></img> &nbsp; 
      Sort
    </span>
  );
};

export default ContainerTracking;
