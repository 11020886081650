import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import Counter from "./Counter";
import redirectURL from '../redirectURL';
import SplineChart from "../booking/SplineChart";

export default class Dashboard extends Component {
	constructor(props){
        super(props);
        this.state={
			totalBookings:0,
			pendingAtVendorQty:0,
			pendingAtQA:0,
			approvedQty:0,
			totalCompleted:0
		}
	}
	componentDidMount(){
		if(localStorage.getItem("user_type") === "booking")
		{			
			var params = {}
			if(localStorage.getItem("user_code") !== '' && localStorage.getItem("user_code") !== undefined)
			{
				if(localStorage.getItem("role") === 'vendor')
				{
					params.vendor_code = localStorage.getItem("user_code");
				}
			}
			redirectURL.post("/booking/list",params)
			.then((response) => {
					if(response.data.status === "success")
					{
						var records = response.data.records;
						var batches = response.data.batches;
						// console.log("records ", records)
						var activebookings = [];
						var completedbookings  = [];
						var pendingvendors = [];
						var sendtoqa = [];
						var invconf = [];
						var totalvolume= 0;
						var pendingwithvendorvolume= 0;
						var qavolume= 0;
						var completedvolume= 0;
						var producedvolume= 0;
						var hilapprovedvolume= 0;
						var hilrejectvolume= 0;
						var remainingvolume= 0;
						var batchwisearr = [];
						var invoicebatches = [];
						var totalapprvecount = 0;
						
						var totalbatchcount=0;
						var totalInvbatchcount=0;
						if(records.length > 0)
						{
							records.map((item) => {
								// if(item.po_status === 0)
								// {
								//     item.po_status_txt = "Created";
								//     activebookings.push(item);
								// }
								if(item.poitems !== "" && item.poitems !== undefined)
								{
									var poitems = item.poitemslist;
									if(poitems.length > 0)
									{
										poitems.map((im) => {
											if(localStorage.getItem("user_code") === im.vendor_code)
											{
												totalvolume = parseInt(totalvolume)+parseInt(im.item_qty);
											}
											else{
												totalvolume = parseInt(totalvolume)+parseInt(im.item_qty);
												pendingwithvendorvolume = parseInt(pendingwithvendorvolume)+parseInt(im.item_qty);
											}
											
										})
									}
								}
								if(item.po_status === 0)
								{
									item.po_status_txt = "Pending With Vendor";
									pendingvendors.push(item);
								}
								
							})
						}
						
						if(batches.length >0)
						{
							batches.map((item) => {
								if(item.item_status === 1)
								{
									sendtoqa.push(item);
									qavolume = parseInt(qavolume)+parseInt(item.item_produce_qty)
								}
								
								if(item.item_status === 2)
								{
									invconf.push(item);
									if(item.invoice_document === "" || item.invoice_document === undefined)
									{
										totalapprvecount = parseInt(totalapprvecount)+parseInt(item.approved_qty)
									}
									else{
										totalInvbatchcount = parseInt(totalInvbatchcount)+parseInt(item.approved_qty)
									}
								}
								if(item.item_status === 3)
								{
									completedbookings.push(item);
									completedvolume = parseInt(completedvolume)+parseInt(item.approved_qty)
								}
							})
						}
						
						pendingwithvendorvolume = parseInt(totalvolume)-parseInt(totalapprvecount)-parseInt(completedvolume);
						if(localStorage.getItem("role") === "vendor")
						{
							this.setState({
								totalBookings:totalvolume,
								pendingAtQA:pendingwithvendorvolume,
								totalCompleted:completedvolume,
								approvedQty:totalapprvecount
							})
						}
						else{
							var totBoks = []
							var vendorsdata = groupBy(records, rdata => (rdata.vendor_code));
							// console.log("vendorsdata ", vendorsdata)
							try {
								vendorsdata.forEach((values,key) => {
									var rwv = {}
									var poitemsqty = 0;
									var poprodqty = 0;
									var poaprvqty = 0;
									rwv["vendor_code"] = key;
									if(values.length > 0)
									{
										rwv["vendor_name"] = values[0].vendor_name;
										values.map((it) => {
											if(it.poitemslist != "" && it.poitemslist != undefined)
											{
												var venditems = it.poitemslist;
												if(venditems.length > 0)
												{
													venditems.map((m) => {
														poitemsqty = parseInt(poitemsqty)+parseInt(m.item_qty);
													})
												}
											}
											if(it.vendorproduces != "" && it.vendorproduces != undefined)
											{
												var vendorproducest = it.vendorproduces;
												if(vendorproducest.length > 0)
												{
													vendorproducest.map((m1) => {
														// console.log('m1.approved_qty ', m1.approved_qty)
														poprodqty = parseInt(poprodqty)+parseInt(m1.item_produce_qty);
														if(m1.approved_qty !== undefined && m1.approved_qty !== "")
														{
															poaprvqty = parseInt(poaprvqty)+parseInt(m1.approved_qty);
														}
														
													})
												}
											}
											
										})
									}
									// console.log("poaprvqty ", poaprvqty)
									rwv["totalQty"] = poitemsqty;
									rwv["producedQty"] = poprodqty;
									rwv["approvedQty"] = poaprvqty;
									rwv["remainingQty"] = parseInt(poitemsqty)-parseInt(poaprvqty);
									totBoks.push(rwv);
								})
							} catch (error) {
								
							}
							this.setState({
								totalBookings:totalvolume,
								pendingAtQA:pendingwithvendorvolume,
								totalCompleted:completedvolume,
								approvedQty:totalapprvecount
							})
						}
					}
					else{

					}
			});
		}
	}
    render(){
		if(localStorage.getItem("token") === "" || localStorage.getItem("token") === undefined || localStorage.getItem("token") === null)
		{
			return <Navigate  to={"/login"} push={true} />
		}
        return (
            <div className="container-fluid" style={{marginLeft: "15px"}}>
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
					
				</div>
				{(localStorage.getItem("user_type") === "booking")?
				<div className="row">					
					<Counter
						counterTitle={"Total Bookings"}
						counterValue={this.state.totalBookings}
						colorCode="primary"
					/>
					
					<Counter
						counterTitle={"Pending"}
						counterValue={this.state.pendingAtQA}
						colorCode="danger"
					/>
					
					<Counter
						counterTitle={"Approved"}
						counterValue={this.state.approvedQty}
						colorCode="warning"
					/>
					
					<Counter
						counterTitle={"Completed"}
						counterValue={this.state.totalCompleted}
						colorCode="success"
					/>
					

				</div>
				: <div className="row">	
					Welcome to Dashboard
				</div>
				}
				{/* <div className="row">					
					<div className="col-xl-8 col-lg-7">
						<div className="card shadow mb-4">
							<div
								className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
								<h6 className="m-0 font-weight-bold text-primary">Daily Bookings</h6>
								
							</div>
							<div className="card-body">
								<SplineChart

								/>
							</div>
						</div>
					</div>

				</div> */}
                    

			</div>
        );
    }
}


function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}