import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import redirectURL from "../redirectURL";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHHMM } from '../common/utils';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
// import { head } from '../../../../bajaj_node/routes/routes.exim';


const FourDaysPlan = () => {

    var [rowData, setrowData] = useState([]);
  
    var [showFourDaysView, setShowFourDaysView] = useState("");
    var [rowData2, setrowData2] = useState([]);
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
    var [plantCodes, setPlantCodes] = useState([])

    const [tableHeaders, setTableHeaders] = useState(['Date']); // Initialize with 'Date'


    var columnDefs2 = [
        {
            headerName: "WORK ORDER NO",
            // headerClass: "showDataStyle",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
            cellStyle: { textAlign: "center" }
        },
        {
            headerName: "STUFFING DATE",
            // headerClass:"headerStyle",
            field: "stuffing_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            cellStyle: { textAlign: "center" }
        },
        {
            headerName: "PLANT CODE",
            // headerClass:"headerStyle",
            field: "plant",
            width: 160,
            filter: true,
            resizable: true,
            cellStyle: { textAlign: "center" }
        },

    ]

   useEffect(()=>{
     if (showFourDaysView ==='slide105'){
        document.body.style.overflow = 'hidden';
     }else{
        document.body.style.overflow = 'auto';
     }
     return ()=>{
        document.body.style.overflow = 'auto';
     }
   }, [showFourDaysView])
    useEffect(() => {
        setloadshow('show-m')
        redirectURL.post('/consignments/getfourdaysplandata').then((resp) => {
            try {
                var records = resp.data;
                if(records!='' && records!=undefined){
                    var stuffingPlanned = JSON.parse(records.stuffing_planned);
                    setrowData(stuffingPlanned);
                    var stuffingData = JSON.parse(records.stuffing_data)
                    setrowData2(stuffingData)
                // setrowData2(data)
                }else{
                    setrowData([])
                    setrowData([])
                }
                
                
                // setrowData(records);
            } catch (e) {
                setrowData([])
                setrowData([])
                console.error('Error:', e);
            }finally{
                setTimeout(() => {
                    setloadshow('show-n')
                }, 3000);
            }
        }).catch(error => {
            setrowData([]); // Ensure rowData is empty in case of fetch error
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    
    }, [])
    useEffect(() => {
        redirectURL.post('/consignments/getplantdata').then((resp) => {
            try {
                var plantsdata = resp.data;
                setPlantCodes(Object.values(plantsdata).map(obj => obj.plant_code));
                const plantCodes1 = Object.values(plantsdata).map(obj => obj.plant_code);
                //console.log(plantCodes1,"plantHeaders")
                const uniqueHeaders = Array.from(new Set(['Date', ...plantCodes1]));
                setTableHeaders(uniqueHeaders)
                // setrowData(records);
            } catch (e) {
                console.error('Error:', e);
            }
        })
    }, [])
    const fourDaysplanView = () => {
        setShowFourDaysView("slide105");
        setoverly("show-m");
    }
    const hideSlideBlock = () => {
        setShowFourDaysView("");
        setoverly("show-n");
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      };
      const onClickHideAll = () => {
        setloadshow('show-n')
    }
    
      //console.log(rowData, "888")
    return (
        <>
        <div className='card-header2'>
            <div className='d-flex justify-content-between'>
                <h1 className='heading3 mt-20p'>Four Days Plan Visibility (Stuffing Planned)</h1>
                <div className="form-group">
                    <button type="button" className="btn-showdata mt-15p" style={{ fontSize: "0.8rem", height: "30px" }} onClick={fourDaysplanView}>
                    <i class="fa fa-eye"><span className='ml-2p view-summary'>View Data</span></i>
                                    </button>

                </div>
            </div>
          
            <div className='scrollable-table-container'>
                <table className="table table-small-rows"  style={{ border: '0px', overflow: 'auto', height:"100%" }}>
                    <thead>
                        <tr>
                        {tableHeaders.map((header) => (
                            <th className='table1-fourdays'>{header}</th> // Dynamic headers
                        ))}
                            
                        </tr>
                    </thead>
                    <tbody>
    {rowData.length > 0 ? (
        rowData.map((item, index) => (
            <tr key={index}>
                <td className='table1row-fourdays' style={{height: "20px"}}>{item.stuffing_date}</td>
                {plantCodes.map((header, colIndex) => (
                    (item[header] != null || item[header] != undefined) ? (
                        <td key={colIndex}>{item[header]}</td>
                    ) : null
                ))}
                  {/* <td>{item.CH01}</td>
                                <td>{item.CK01}</td>
                                <td>{item.PT01}</td>
                                <td>{item.WA01}</td>
                                <td>{item.WA10}</td>
                                <td>{item.WA31}</td> */}
            </tr>
        ))
    ) : (
        <tr style={{height: "170px"}}>
            <td colSpan={tableHeaders.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}>No rows to show</td>
        </tr>
    )}
</tbody>

                </table>
            </div>   
          
      
            <div className={"sliderBlock3 " + (showFourDaysView)} >
                <h1 className='fourdays-heading ml-40p mt-40p mb-20p' >Four Days Plan Data View</h1>
                <span className="float-right ffclosebtn1" style={{ marginRight: "25px", fontSize: '20px' }} onClick={hideSlideBlock}>
                    <img src={require('../../assets/images/closeIcon.png')} className='closeIcon mt-5p' style={{ marginTop: "-40px" }} />
                </span>
              
                 <div className="row" >
                    <div className="col-sm-12 ml-40p" >
                        <div id="" style={{ width: "71%", height: "320px" }} className="ag-theme-balham">
                            <AgGridReact
                                // ref={gridApi}
                                // modules={AllModules}
                                columnDefs={columnDefs2}
                                defaultColDef={{
                                    sortable: true,
                                    // headerClass:'headerStyle',
                                    filter: true,
                                    editable: false,
                                    resizable: true,
                                    menuTabs: ['filterMenuTab']
                                }}
                                rowData={rowData2}
                                suppressRowClickSelection={true}
                                enableCellChangeFlash={true}
                                suppressCellFlash={true}
                                enableRangeSelection={true}
                                paginationPageSize={7}
                                // onGridReady={onGridReady}
                                // onGridState={onGridState}
                                rowSelection={'multiple'}
                                masterDetail={true}
                                pagination={true}
                                // frameworkComponents={{ CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                // sideBar={{
                                //     toolPanels: [
                                //         {
                                //             id: "columns",
                                //             labelDefault: "Columns",
                                //             labelKey: "columns",
                                //             iconKey: "columns",
                                //             toolPanel: "agColumnsToolPanel"
                                //         },
                                //         {
                                //             id: "filters",
                                //             labelDefault: "Filters",
                                //             labelKey: "filters",
                                //             iconKey: "filter",
                                //             toolPanel: "agFiltersToolPanel"
                                //         }
                                //     ]
                                // }}
                                // statusBar={{
                                //     statusPanels: [
                                //         { statusPanel: "agFilteredRowCountComponent" },
                                //         { statusPanel: "agSelectedRowCountComponent" },
                                //         { statusPanel: "agAggregationComponent" }
                                //     ]
                                // }}
                                gridOptions={{
                                    icons: {
                                        next: `<img src="${customNextIcon}" alt="Next" />`,
                                        previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                        first: `<img src="${customFirstIcon}" alt="First" />`,
                                        last: `<img src="${customLastIcon}" alt="Last" />`,
                                    },
                                }}
                            // context={{ onClickDOdetails }}
                            />

                        </div>
                    </div>
                </div>
                {/* <div className='ml-40p scrollable-table-container' style={{maxWidth:"350px", maxHeight:"300px", overflow:"auto"}}>
                   <table className="table table-small-rows1" style={{ border: '0px', overflow: 'auto', marginBottom:"0px", marginTop:"0px" }}>
                    <thead>
                        <tr>
                            <th className='table2-fourdays'>Work Order No</th>
                            <th className='table2-fourdays'>Stuffing Date</th>
                            <th className='table2-fourdays'>Plant Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowData2.map((item, index) => (
                            <tr key={index}>
                                <td>{item.work_order_no}</td>
                                <td>{formatDate(item.stuffing_date)}</td>
                                <td>{item.plant}</td>
                               
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div> */}




            </div>
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </>

    )
}

export default FourDaysPlan;