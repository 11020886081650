import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "./constants";
import {getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY} from "../utils";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
export default class ItemAdd extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true,
                menuTabs: ['filterMenuTab']
			},
            frameworkComponents:{
            },
            components: { datePicker: getDatePicker() },
			detailCellRendererParams : {},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            produceRowData:[],
            rowSelection:"single",
            overlay:"show-n",
            rownode:[],
            poitemDesc:[],
            
        }
        this.onCellEdit = this.onCellEdit.bind(this);
    }
	
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    
    addItemRow(){
        var row = [{
            item_produce_desc:"",
            item_produce_qty:"",
            item_produce_date:""
        }]
        this.gridApi.updateRowData({
            add: row
        });
    }
    onClickTriggerParentFn(){
        setTimeout(() => {
            var currentRow = this.props.rownode;
            let rownodes = [];
            this.gridApi.forEachNode(node => rownodes.push(node.data));
            // this.props.context.onClickSaveProduceItems(rowData);
            // console.log("currentRow ", rownodes)
            var params ={
                producItems: JSON.stringify(rownodes),
                vendor_code:currentRow.vendor_code,
                vendor_name:currentRow.vendor_name,
                po_no:currentRow.po_no,
            }
            redirectURL.post("/booking/createbatch",params)
            .then((response) => {
                this.props.context.onClickSaveProduceItems();
            })
        },500)
        
    }
    onCellEdit = async (node) => {
        // console.log(node,"clickedData")
        var updateCheck = 0;
        var clickedValue = node.value;
        if (clickedValue >= 0 && clickedValue != '' && clickedValue != undefined) {
            updateCheck = 1;
        }
        if (updateCheck == 1) {
            var rowData=[];
            this.gridApi.forEachNode((e) => {
                rowData.push(e.data)
            })
            this.setState({
                produceRowData:rowData
            });
            this.gridApi.refreshCells();
        }
    }
    render(){
		
        var poitemDesc = this.props.poitemDesc;
        var columnProduceDefs = [
            
            {
                headerName:Constants.GRID_PO_PRODUCE_DATE,
                field:"item_produce_date",
                editable:true,
                cellEditor: "datePicker",
                // cellRenderer: (data) => {
                //     return data.value.split('/')[1]+'-' +data.value.split('/')[0]+'-'+data.value.split('/')[2];
                // },
            },    
            {
                headerName:Constants.GRID_PO_PRODUCE_QTY,
                field:"item_produce_qty",
                editable:true
            },
            {
                headerName:Constants.GRID_PO_ITEM_DESC,
                field:"item_produce_desc",
                width:240,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: poitemDesc
                },
            }
        ]
        return (

            <div className="col-sm-12 p-20p" style={{marginTop:"20px"}} >   
                <button type="button" className="btn btn-primary float-right" onClick={this.addItemRow.bind(this)}>Add Item</button>     
                <div className="row" style={{marginTop:"20px"}} >   
                                 
                        
                    <div className="col-sm-12 p-20p" style={{marginTop:"20px"}} id="produceItemData">  

                        <div id="myGrid" style={{width:"100%",height:"50vh"}} className="ag-theme-balham">
                            <AgGridReact 
                                rowData={this.state.produceRowData} 
                                columnDefs={columnProduceDefs}
                                stopEditingWhenGridLosesFocus= {false}
                                onGridReady={this.onGridReady}
                                components={this.state.components}
                                statusBar={this.state.statusBar}
                                rowSelection={this.state.rowSelection}
                                onCellEditingStopped={this.onCellEdit}
                            />
                        </div> 
                        <button type="button" onClick={this.onClickTriggerParentFn.bind(this)} className="btn btn-success float-right">Submit To QA</button>

                    </div>
                </div>
            </div>
        );
    }
}   
function onClickUpdateItem(rowdn){
    console.log("rowdn ", rowdn)
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy',minDate:0 });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};