
import React, { Component } from 'react';
 
const EditTruck = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickEditTruck(props);
    };
console.log("props ", props.data)
    return (
        <div>
            {/* <button type="button"
              className={"btn activet font12 mb-5p"} 
              title="Update" 
              onClick={handleClick}>
                   Edit
            </button> */}
            <i class="icofont icofont-edit" onClick={handleClick}>Edit</i>
          
        </div>
    );
};

export default EditTruck;
