import axios from 'axios';
var axiosInstance = axios.create({
   // baseURL: `http://34.131.188.117:5001/autometrics`
  baseURL: `https://bajaj.autometrics.in/autometrics`
   // baseURL: `http://localhost:5011/autometrics` 
});
// axiosInstance.interceptors.request.use(function (params) {
//   var parameters = params.data;
//   // console.log("parameters ", parameters)
//   var newparameters = {}
//   var flag = 0;
//   for(var key in parameters)
//   {
//     if(parameters[key] == "undefined" || parameters[key] == undefined)
//     {
//       newparameters[key] = "";  
//     }
//     else{
//       try{
        
//         var pm = parameters[key].toString();
//         // newparameters[key] =encode(pm);
//         newparameters[key] =pm;
        
//       }
//       catch(e){

//       }
      
//     }
//   }
//   newparameters["token"] = localStorage.getItem("token");
//   params.data=newparameters;
  
//   return params;
  
// })
export default axiosInstance;
