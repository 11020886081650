import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants, { TRIP_FREIGHT_COST } from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import icn from "../../img/undraw_profile.svg";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator';
// import FreightCols from "./freightratecolumns";
import UpdateItem from "./updateItem";
import DeleteItem from "./deleteItem";
import moment from "moment";
const { create,all, im } = require('mathjs')
var math = create(all);
export default class FreightRates extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                menuTabs: ['filterMenuTab']
			},
            frameworkComponents:{
                UpdateItem:UpdateItem,
                DeleteItem:DeleteItem
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            showUploadOtherCost:"",
            slider:"",
            overlay:"show-n",
            csvcontent:"",
            columnDefs:[],
            col_name:"",
            col_cost:"",
            freightcolumns:[],
            showUpdateForm:"",
            route:"",
            source:"",
            destination:"",
            transporter_name:"",
            rate_classification:"",
            contract_approval_no:"",
            contract_valid_from:"",
            contract_valid_to:"",
            truck_type:{value:"",label:"Select"},
            total_distance:"",
            mileage:"",
            base_freight_rate_per_approval:"",
            base_diesel_rate_per_approval_ltr:"",
            base_toll_rate_per_approval:"",
            base_diesel_city:"",
            base_diesel_state:"",
            rowid:"",
            sliderFormula:"",
            sliderDeviationFormula:"",
            totalCostFormula:"",
            othercostcols:[],
            equaton:[],
            totalcosteq:[],
            showUploadDeleteOtherCost:"",
			components: { datePicker: getDatePicker() },
            colOpt:{"value":"","label":""},
            formulas:"",
            avgDieselPrice:0,
            freightapproval:[],
            approval_freight:0,
            changed_diesel_price:0,
            changed_diesel_price_reason:"",
            changed_diesel_price_upload:"",
            deviationcnt:1,
            deviationEquation:[],
            diesel_price_changed_date:""
        }
        this.loadFreightRates = this.loadFreightRates.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickUpdateItem =this.onClickUpdateItem.bind(this);
        this.onClickDeleteItem =this.onClickDeleteItem.bind(this);
        this.onClickShowFormual =this.onClickShowFormual.bind(this);
        this.totalCostFormulaEq = this.totalCostFormulaEq.bind(this);
        this.otherCostCols = this.otherCostCols.bind(this);
        this.getDieselPrices = this.getDieselPrices.bind(this);
        this.getAvgDieselPrices = this.getAvgDieselPrices.bind(this);
        this.getRecentDieselApprovalPrice = this.getRecentDieselApprovalPrice.bind(this);
        this.onClickShowDeviationFormual = this.onClickShowDeviationFormual.bind(this);
        this.deviationFlowsFormula = this.deviationFlowsFormula.bind(this);
    }
	componentDidMount = async () => {
        this.deviationFlowsFormula();
        var FreightCols = [{
            headerName:"",
            field:"_id",
            width:100,
            pinned:"left",
            cellRendererSelector:function(params){
                return {
                    component:"UpdateItem"
                }
            },
        },{
            headerName:"",
            field:"_id",
            width:100,
            cellRendererSelector:function(params){
                return {
                    component:"DeleteItem"
                }
            },
        },{
            headerName:Constants.FREIGHT_RATE_ROUTE,
            field:"route",
            width:120,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_SOURCE,
            field:"source",
            width:120,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_DESTINATION,
            field:"destination",
            width:160,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_LSP,
            field:"transporter_name",
            width:140,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_RATE_CLASSIFICATION,
            field:"rate_classification",
            width:140,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_CONTRACT,
            field:"contract_approval_no",
            width:100,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_CONTRACT_VALID_FROM,
            field:"contract_valid_from",
            width:140,
            // editable:true, 
            // editableCellTemplate: '<input type="text" class="datepicker" />',   
            // cellEditor: DateEditor,
            valueGetter:function(params){
                try{
                    if(params.data.contract_valid_from != "" && params.data.contract_valid_from != undefined && params.data.contract_valid_from != null)
                    {
                        return moment.parseZone(params.data.contract_valid_from).format('DD-MM-YYYY HH:mm'); 
                    }
                    else{
                        return "";
                    }
                }
                catch(e){}
            },
            // comparator: dateComparator1
            
        },
        {
            headerName:Constants.FREIGHT_RATE_CONTRACT_VALID_TO,
            field:"contract_valid_to",
            width:140,
            // editable:true,
            valueGetter:function(params){
                try{
                    if(params.data.contract_valid_to != "" && params.data.contract_valid_to != undefined)
                    {
                        return moment.parseZone(params.data.contract_valid_to).format('DD-MM-YYYY HH:mm'); 
                    }
                }
                catch(e){}
            }
        },
        {
            headerName:Constants.FREIGHT_RATE_TRUCK_TYPE,
            field:"truck_type",
            width:100,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_DISTANCE,
            field:"total_distance",
            width:120,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_MILEAGE,
            field:"mileage",
            width:120,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_BASE_FREIGHT_RATE_PER_APPROVAL,
            field:"base_freight_rate_per_approval",
            width:180,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_BASE_DIESEL_RATE_PER_LTR,
            field:"base_diesel_rate_per_approval_ltr",
            width:180,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_TOLL_RATE_PER_APPROVAL,
            field:"base_toll_rate_per_approval",
            width:180,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_BASE_DIESEL_CITY,
            field:"base_diesel_city",
            width:180,
            // editable:true
        },
        {
            headerName:Constants.FREIGHT_RATE_BASE_DIESEL_STATE,
            field:"base_diesel_state",
            width:180,
            // editable:true
        }];
        this.setState({
            FreightCols:FreightCols
        })

        $( ".datepicker" ).datepicker({
            dateFormat:"dd-mm-yy"
        });
        
        await this.totalCostFormulaEq();
        await redirectURL.post("/configurations")
		.then(async (response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {
                await this.setState({
                    conf:records[0]
                })
                setTimeout(() => {
                    this.otherCostCols();
                },500)
                
            }
            else{
                redirectToConfigurations()
            }
		})
        
        pushNot();
	}
    getDieselPrices(params){
        // console.log("params ", params)
        redirectURL.post("/getDieselPrices",params)
		.then((response) => {
			// console.log("response ", response.data)
            var prices = response.data.prices;
            var avgprice = this.getAvgDieselPrices(prices);
            // console.log("avgprice ", avgprice)

            var conf = this.state.conf;
            var dPrice = 0;
            var dieselbasis = "";
            if(conf.diesel_price_capture_val != "" && conf.diesel_price_capture_val != undefined)
            {
                if(conf.diesel_price_capture_val == "1")
                {
                    var diesel_price_x = parseFloat(conf.diesel_price_x);
                    // console.log("diesel_price_x ", diesel_price_x)
                    // console.log("avgprice ", avgprice)
                    dPrice = parseFloat(avgprice)+(parseFloat(avgprice)*(parseFloat(diesel_price_x)/100));
                    // console.log("dPrice ", dPrice);
                    dieselbasis = "Diesel price changed by "+diesel_price_x+" %";
                    this.setState({
                        avgDieselPrice:dPrice.toFixed(2),
                        dieselbasis:dieselbasis
                    })
                }
                if(conf.diesel_price_capture_val == "2")
                {
                    // console.log("avgprice ", avgprice)
                    dieselbasis = " Daily diesel price averages ";
                    dPrice = avgprice;
                    this.setState({
                        avgDieselPrice:dPrice,
                        dieselbasis:dieselbasis
                    })
                }

            }
            else{
                dPrice = 0;
                this.setState({
                    avgDieselPrice:dPrice,
                    dieselbasis:""
                })
            }
            
            
		})
    }
    getAvgDieselPrices(prices){
        
        var allprices = []
        if(prices.length > 0)
        {
            prices.map((item) => {
                if(item.diesel_price != "" && item.diesel_price != undefined)
                {
                    allprices.push(parseFloat(item.diesel_price));
                }
                
            })
        }
        // console.log("allprices ", allprices.length)
        var avg =0;
        if(allprices.length > 0)
        {
            var sum = 0;
            for (let i = 0; i < allprices.length; i++) {
                sum += allprices[i];
            }
            avg = (sum/allprices.length).toFixed(2);
        }
        return avg;
    }
    otherCostCols(){
        var conf = this.state.conf;
        var FreightCols = this.state.FreightCols;
        redirectURL.post("/freightothercosts")
		.then(async (response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            var formula = this.state.totalCostFormula
            // console.log("recordsconf ",conf)
            var cols = []
            // console.log("onf.other_costs  ", conf.other_costs )
            if(conf.other_costs === 'yes')
            {

                if(records.length > 0)
                {
                   await records.map((item) => {
                        FreightCols.push({
                            headerName:item.freight_other_cost_col_name+" Cost",
                            field:item.freight_other_cost_col_field,
                            width:100,
                            // editable:true
                        })
                    })
                    // console.log("records ",records)
                    await this.setState({
                        freightcolumns:records
                    })
                    
                }
            }
            // console.log("FreightCols ", FreightCols)
            await FreightCols.push(
                {
                headerName:Constants.FREIGHT_RATE_TOTAL_COST,
                field:"total_cost",
                width:180
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_APPLICABLE_DATE,
                field:"revised_diesel_price_applicable_date",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_CONSIDERED_FOR_REVISION,
                field:"diesel_price_consider_for_revision",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_IMPACT_PERCENT,
                field:"diesel_price_impact",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_DIESEL_COST,
                field:"change_in_diesel_cost",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_TOLL_REVISION_APPLICABLE_DATE,
                field:"toll_revision_applicable_date",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_NEW_TOLL_PRICE,
                field:"new_toll_price",
                width:180
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_TOLL_PRICE,
                field:"change_in_toll_price",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.new_toll_price != "" && params.data.new_toll_price != undefined)
                        {
                            if(params.data.new_toll_price !== "" && params.data.new_toll_price !== undefined)
                            {
                                var a = params.data.new_toll_price;
                            }
                            else{
                                var a = 0;
                            }
                            if(params.data.base_toll_rate_per_approval !== "" && params.data.base_toll_rate_per_approval !== undefined)
                            {
                                var b = params.data.base_toll_rate_per_approval; 
                            }
                            else{
                                var b = 0;
                            }
                            
                            return parseInt(a)-parseInt(b);
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_COST,
                field:"revised_freight_cost",
                width:180,
                valueGetter:function(params){
                    try {
                        var a = parseFloat(params.data.base_freight_rate_per_approval);
                        var oCosts = 0;
                        if(conf.other_costs === 'yes')
                        {
                            if(records.length > 0)
                            {
                                records.map((item) => {
                                    oCosts += parseFloat(oCosts)+parseFloat(item.freight_other_cost_col_cost)
                                })
                            }
                        }
                        
                        var b = parseFloat(oCosts);
                        var c = 0;
                        if(params.data.new_toll_price != "" && params.data.new_toll_price != undefined)
                        {
                            if(params.data.new_toll_price !== "" && params.data.new_toll_price !== undefined)
                            {
                                var a1 = params.data.new_toll_price;
                            }
                            else{
                                var a1 = 0;
                            }
                            if(params.data.base_toll_rate_per_approval !== "" && params.data.base_toll_rate_per_approval !== undefined)
                            {
                                var b1 = params.data.base_toll_rate_per_approval; 
                            }
                            else{
                                var b1 = 0;
                            }
                            
                            var c = parseInt(a1)-parseInt(b1);
                        }
                        var d = parseFloat(params.data.diesel_price_impact);
                        return a+b+c+d;
                    } catch (error) {
                        
                    }
                }
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_FROM,
            //     field:"revised_freight_cost",
            //     width:180,
            // },
            // {
            //     headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_TO,
            //     field:"revised_freight_cost",
            //     width:180,
            // }
            );
            
            // console.log('FreightCols ', FreightCols)
            setTimeout(()=>{

                this.setState({
                    columnDefs:FreightCols
                })
                
                this.getRecentDieselApprovalPrice();
            },1000)
            
		})
    }
    totalCostFormulaEq(){
        
        redirectURL.post("/totalcostformula")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records[0].equation ", records)
            this.setState({
                totalcosteq:records
            })
        
		})
    }
    getRecentDieselApprovalPrice(){
        redirectURL.post("/latestapproveddieselprice")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    approval_freight:(records[0].changed_diesel_price !== "" && records[0].changed_diesel_price !== undefined)?records[0].changed_diesel_price:records[0].applicable_diesel_price,
                    changed_diesel_price:records[0].changed_diesel_price,
                    changed_diesel_price_reason:records[0].changed_diesel_price_reason,
                    changed_diesel_price_upload:records[0].changed_diesel_price_upload,
                    diesel_price_changed_date:(records[0].diesel_price_changed_date !== "" && records[0].diesel_price_changed_date !== undefined)?records[0].diesel_price_changed_date:"",
                    freightapproval:records
                })
                this.loadFreightRates();
            }
		});
    }
    
    deviationFlowsFormula(){
        redirectURL.post("/deviationformulas")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            records.sort(GetSortAscOrder("order"));
            if(records.length > 0)
            {
                var html = '';
                for(var i=0;i<records.length;i++)
                {
                    html += ' <div class="mt-4">';
                    if(records[i].condition_type !== 'final')
                    {
                        html +='from <input type="text" size="5" id="inp_from_'+(i+1)+'" value="'+records[i].from+'" data-id="'+(i+1)+'" class="inpfrom" /> days ';
                        html += 'to <input type="text" id="inp_to_'+(i+1)+'" value="'+records[i].to+'" data-id="'+(i+1)+'" size="5" class="inpto" /> days ';
                        html += ' = Price: <input type="text" id="inp_price_'+(i+1)+'" data-id="'+(i+1)+'" value="'+records[i].price+'" size="5" class="inpto" />';
                    }
                    else{
                        html += '<input type="text" size="5" id="inp_final_'+(i+1)+'"  value="'+records[i].from+'" data-id="'+(i+1)+'" class="inpfinal" /> days  = Price: <input type="text" id="inp_final_price" value="'+records[i].price+'" size="5" class="inpto" />'
                    }
                    html +='</div>';
                }
                $("#deviateFormula").append(html);
                
                this.setState({
                    deviationEquation:records
                })
            }
            else{
                var html = '';
                html += '<div className="mt-4">from <input type="text" size="5" id="inp_from_1" data-id="1" className="inpfrom" /> days to <input type="text" id="inp_to_1" data-id="1" size="5" className="inpto" /> days  =  Price: <input type="text" id="inp_price_1" data-id="1" size="5" className="inpto" /></div>'
                $("#deviateFormula").append(html);
            }
		});
    }
    loadFreightRates = async () =>{
        await redirectURL.post("/freightrates")
		.then((response) => {
			// console.log("response ", response.data)
            var conf = this.state.conf;
            var records = response.data.records;
            var recordsarr = [];
            var totalCostFormula = this.state.totalCostFormula;
            // console.log("this.state.totalcosteq.length",this.state.totalcosteq)
            if(this.state.totalcosteq.length > 0)
            {
                var equaton = this.state.totalcosteq[0].equation_txt;
                // console.log("ASdasd ", equaton)
            }
            else{
                var equaton = [];
            
            }
            var formulacode = this.state.conf.diesel_price_formula;
            var diesel_impact_percent = (this.state.conf.diesel_impact_percent != "")?this.state.conf.diesel_impact_percent:0;
            
            if(records.length > 0)
            {
                records.map((item) => {
                    
                    // (Distance * (latest Diesel Price - Base Diesel Price)) / Mileage
                    // "Diesel impact as a percentage of Base Freight Rate * Base Freight Rate * ((Latest Diesel Price - Base Diesel Price) / Base Diesel Price)"
                    // console.log("item ", item)
                    var distance = item.total_distance;
                    var lastest_diesel_price = this.state.avgDieselPrice;
                    var base_diesel_price = item.base_diesel_rate_per_approval_ltr;
                    var mileage = item.mileage;
                    var base_freight_rate = item.base_freight_rate_per_approval;
                    var diesel_price_consider_for_revision = this.state.approval_freight;
                    item.diesel_price_consider_for_revision = diesel_price_consider_for_revision;
                    item.revised_diesel_price_applicable_date = this.state.diesel_price_changed_date;
                    var changeDieselPrice = 0;
                    var diesel_impact = 0;
                    if(formulacode === "1")
                    {   
                        // console.log('(parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)) ', (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))
                        changeDieselPrice = ((parseFloat(distance) * (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))/parseFloat(mileage)).toFixed(2);
                        // console.log("changeDieselPrice ", changeDieselPrice)
                        diesel_impact = 0;
                    }
                    
                    if(formulacode === "2")
                    {   
                        changeDieselPrice = (((parseFloat(diesel_impact_percent)*parseFloat(base_freight_rate))/100) * base_freight_rate * ((lastest_diesel_price) - parseFloat(base_diesel_price)/parseFloat(base_diesel_price))).toFixed(2);
                        diesel_impact = ((parseFloat(diesel_price_consider_for_revision)-parseFloat(base_diesel_price))/parseFloat(base_diesel_price)).toFixed(2);
                    }
                    item.change_in_diesel_cost = changeDieselPrice;
                    item.diesel_price_impact =  diesel_impact*100;
                    var freightcolumns = this.state.freightcolumns;
                    var tcost = 0;
                    var allequat = []
                    // console.log("freightcolumns ", freightcolumns)
                    if(conf.other_costs === 'yes')
                    {

                        if(freightcolumns.length > 0)
                        {
                            freightcolumns.map((itm) =>{
                                // item[itm.freight_other_cost_col_field] =itm.freight_other_cost_col_cost; 
                                try {
                                    if(item[itm.freight_other_cost_col_field] != "" && item[itm.freight_other_cost_col_field] != undefined)
                                    {
                                        // console.log("itm.freight_other_cost_col_field ", {"f":itm.freight_other_cost_col_field,"v":item[itm.freight_other_cost_col_field]})
                                        // totalCostFormula.replace(itm.freight_other_cost_col_field,item[itm.freight_other_cost_col_field].toString());
                                        // // tcost = math.evaluate(totalCostFormula);
                                        
                                        // item["total_cost"] = totalCostFormula;
                                    }
                                    // else{
                                    //     tcost = "0"
                                    // }


                                    


                                    
                                } catch (error) {
                                    
                                }
                            })
                        }
                    }

                    try {
                        // console.log("equaton ",equaton)
                        if(equaton.length > 0)
                        {
                            equaton.map((m) => {
                               if(item[m] != undefined)
                               {
                                    m = item[m];
                                }

                                 
                                allequat.push(m);
                            })
                        }
                    } catch (error) {
                        
                    }
                    // console.log("item", allequat)
                    var c = "";
                    
                    try {
                    if(allequat.length > 0)
                    {
                        var cc = '';
                        allequat.map((it) => {
                            cc += it;
                        })
                        // console.log("cccc ",cc)
                        item["total_cost"] = math.evaluate(cc);
                    }
                    } catch (error) {
                        
                    }
                    // console.log("item ", item)
                    recordsarr.push(item); 
                })
            }
            this.setState({
                rowData:recordsarr
            })
            
		})
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onClickShowCSVForm(){
        this.setState({
            showUploadCSVForm:"show-m",
            slider:"slide30",
            overlay:"show-m"
        })
    }
    hideSlideBlock(){
        this.setState({
            showUploadCSVForm:"show-n",
            showUploadOtherCost:"",
            showUploadDeleteOtherCost:"",
            showUpdateForm:"",
            slider:"",
            sliderFormula:"",
            sliderDeviationFormula:"",
            overlay:"show-n"
        })
    }
    
    changeFileHandler = async (e) => {
        const config = {
            headers: [
                { 
                    name: 'route',
                    inputName: 'route',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'source',
                    inputName: 'source',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'destination',
                    inputName: 'destination',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                // { 
                //     name: 'transporter_code',
                //     inputName: 'transporter_code',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                { 
                    name: 'transporter_name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'rate_classification',
                    inputName: 'rate_classification',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'contract_approval_no',
                    inputName: 'contract_approval_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'valid_from',
                    inputName: 'valid_from',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'valid_to',
                    inputName: 'valid_to',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'truck_type',
                    inputName: 'truck_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                
                { 
                    name: 'total_distance',
                    inputName: 'total_distance',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'mileage',
                    inputName: 'mileage',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'base_freight_rate_per_approval',
                    inputName: 'base_freight_rate_per_approval',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'base_diesel_rate_per_approval_ltr',
                    inputName: 'base_diesel_rate_per_approval_ltr',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'base_toll_rate_per_approval',
                    inputName: 'base_toll_rate_per_approval',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'base_diesel_city',
                    inputName: 'base_diesel_city',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'base_diesel_state',
                    inputName: 'base_diesel_state',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            this.setState({
                csvcontent:csvData.data
            });
        })
        .catch(err => {})
            
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                // console.log("contents ", contents )
                
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
            // console.log("tt ",tt)
        });
        //console.log("Out ", out);
        
        // this.setState({
        //     csvcontent:e.target.files[0]
        // });
    }
    uploadCSVData(event){
        event.preventDefault();
        if(this.state.csvcontent != "" && this.state.csvcontent != undefined)
        {
            var params ={
                csvcontent:JSON.stringify(this.state.csvcontent)
            }
            redirectURL.post("/savecsvfreightrates",params)
            .then((response) => {
                if(response.data.status == "success")
                {
                    window.location.reload();
                }
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please upload csv file",
                basicType:"danger"
            })
        }
    }
    onClickShowOtherCost(){
        this.setState({
            showUploadOtherCost:"slide30",
            overlay:"show-m"
        })
    }
    
    changeHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    formAddColumn(event){
        event.preventDefault();
        var colName = this.state.col_name;
        var colCost = 0;
        var params = {
            freight_col:colName,
            freight_col_field:colName.replace(/ /g,"_"),
            freight_cost:colCost
        }
        redirectURL.post("/savefreightothercols",params)
        .then((response) => {
            if(response.data.status == "success")
            {
                window.location.reload();
            }
        })
    }
    onClickUpdateItem(rownode)
    {
        // console.log("Rowdnode Update ", rownode)
        var row = rownode.data;
        if(row.contract_valid_from != "" && row.contract_valid_from != undefined)
        {
            var contract_valid_from = moment.parseZone(row.contract_valid_from).format("DD-MM-YYYY");
        }
        else{
            var contract_valid_from = "";
        }
        if(row.contract_valid_to != "" && row.contract_valid_to != undefined)
        {
            var contract_valid_to = moment.parseZone(row.contract_valid_to).format("DD-MM-YYYY");
        }
        else{
            var contract_valid_to = "";
        }
        this.setState({
            showUpdateForm:"slide30",
            overlay:"show-m",
            route:row.route,
            source:row.source,
            destination:row.destination,
            transporter_name:row.transporter_name,
            rate_classification:row.rate_classification,
            contract_approval_no:row.contract_approval_no,
            contract_valid_from:contract_valid_from,
            contract_valid_to:contract_valid_to,
            truck_type:{value:row.truck_type,label:row.truck_type},
            total_distance:row.total_distance,
            mileage:row.mileage,
            base_freight_rate_per_approval:row.base_freight_rate_per_approval,
            base_diesel_rate_per_approval_ltr:row.base_diesel_rate_per_approval_ltr,
            base_toll_rate_per_approval:row.base_toll_rate_per_approval,
            base_diesel_city:row.base_diesel_city,
            base_diesel_state:row.base_diesel_state,
            rowid:row._id
        })
        // row.rowid = row._id;
        // // console.log("row ", row)
        // redirectURL.post("/updatefreightratesrow",row)
        // .then((response) => {
        //     if(response.data.status === "success")
        //     {
        //         // window.location.reload();
        //     }
        // })

    }
    fechTruckTypeOptions(){
        var items = [{
            value:"",
            label:"Select"
        }];
        items.push({
            value:"Truck",
            label:"Truck"
        })
        return items;
    }
    handleTruckChange(truck_type){
        this.setState({truck_type})
    }
    onClickDeleteItem(rownode)
    {
        // console.log("Rowdnode Delete ", rownode)
        var row = rownode.data;
        var cnf = window.confirm("Are you sure you want to delete item?")
        if(cnf === true)
        {
            var params = row;
            redirectURL.post("/removefreightrates",params)
            .then((response) => {
                if(response.data.status === "success")
                {
                    window.location.reload();
                }
            })
        }
        else{

        }
        
    }
    formUpdateItem(event){
        event.preventDefault();
        var route = this.state.route;
        var source = this.state.source;
        var destination = this.state.destination;
        var transporter_name = this.state.transporter_name;
        var rate_classification = this.state.rate_classification;
        var contract_approval_no = this.state.contract_approval_no;
        var contract_valid_from = $("#contract_valid_from").val();
        var contract_valid_to = $("#contract_valid_to").val();
        var truck_type = this.state.truck_type.value
        var total_distance = this.state.total_distance;
        var mileage = this.state.mileage;
        var base_freight_rate_per_approval = this.state.base_freight_rate_per_approval;
        var base_diesel_rate_per_approval_ltr = this.state.base_diesel_rate_per_approval_ltr;
        var base_toll_rate_per_approval = this.state.base_toll_rate_per_approval;
        var base_diesel_city = this.state.base_diesel_city;
        var base_diesel_state = this.state.base_diesel_state;
        var rowid = this.state.rowid;
        
        var params = {
            route:route,
            source:source,
            destination:destination,
            transporter_name:transporter_name,
            rate_classification:rate_classification,
            contract_approval_no:contract_approval_no,
            valid_from:contract_valid_from,
            valid_to:contract_valid_to,
            truck_type:truck_type,
            total_distance:total_distance,
            mileage:mileage,
            base_freight_rate_per_approval:base_freight_rate_per_approval,
            base_diesel_rate_per_approval_ltr:base_diesel_rate_per_approval_ltr,
            base_toll_rate_per_approval:base_toll_rate_per_approval,
            base_diesel_city:base_diesel_city,
            base_diesel_state:base_diesel_state,
            rowid:rowid
        };
        $(".othercost").each(function(){
            // console.log("name ", $(this).attr("id"));
            var fild = $(this).attr("id");
            var fildval = $(this).val();
            // console.log("value ", $(this).val());
            params[fild] = fildval;
        });

        // console.log("pp ", params)
        
        // redirectURL.post("/updatefreightrates",params)
        // .then((response) => {
        //     if(response.data.status === "success")
        //     {
        //         window.location.reload();
        //     }
        // })
        redirectURL.post("/updatefreightratesrow",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                window.location.reload();
            }
        })
    }
    onClickShowFormual(){
        this.setState({
            showUploadCSVForm:"show-n",
            sliderFormula:"slide60",
            overlay:"show-m"
        })
    }
    appendVal = async (oVal) =>{
       // var dd = document.getElementById("formulas").innerHTML;
    //     var equaton = this.state.formulas;
    //     console.log("equaton ", equaton)
    //     var currentTrack  = "";
    //     var previousTrack = "";
    //     for (let i = 0; i < equaton.length; i++) {
    //         currentTrack = equaton[i];
    //         previousTrack = equaton[i];
    //         // if (i === 0) {
    //         // //   console.log('previousTrack of ' + currentTrack.key + ' is:', undefined);
    //         // } else {
    //         //   previousTrack = equaton[i - 1];
    //         // }
    //     }
    //     if( previousTrack != oVal)
    //     {
            
    //     }
    //     console.log("previousTrack ", previousTrack)
    //    await equaton.push({
    //         field:oVal
    //     })
    //     setTimeout(() => {
    //         this.setState({
    //             equaton:equaton
    //         })  
    //         this.appendForm();
    //     },500)

        var html= document.getElementById("formulas").value;
        html += oVal+" ";
        document.getElementById("formulas").value = html
        this.setState({
            formulas:html
        })
    }
    appendForm(){
        var equaton = this.state.equaton;
        // console.log("equaton ", equaton)
        var html='';
        var listitesm = '';
        // var ul = document.getElementById("formulasList");
        if(equaton.length > 0)
        {
            equaton.map((item) => {
                html += item.field+" ";
                // var li = document.createElement("li");
                // var b = '<a href="javascript:;" >Delete</a>'
                // li.appendChild(document.createTextNode(item.field));
                // ul.appendChild(li);
                // listitesm += '<li>'+item.field+'</li>';
            })
        }
        document.getElementById("formulas").value = html
        this.setState({
            formulas:html
        })
        // document.getElementById("formulasList").appendChild = listitesm
    }
    onSaveFormula = async () =>{
        var eq = document.getElementById("formulas").value;
        if(eq !== "" && eq !== undefined)
        {
            
            // var equaton = this.state.equaton;
            // var arr = [];
            // if(equaton.length > 0)
            // {
            //     equaton.map((itm) => {
            //         arr.push(itm.field);
            //     })
            // }
            var arr  = [];
            arr = eq.split(" ");
            var aprams = {
                equation : JSON.stringify(arr)
            }
            redirectURL.post("/saveEquation",aprams)
            .then((response) => {
                if(response.data.status === "success")
                {
                    this.setState({
                        sliderFormula:"",
                        overlay:"show-n"
                    });
                    // this.loadFreightRates();
                    window.location.reload();
                }
                
            })
        }
        
    }
    onClickShowDeleteOtherCost(){
        this.setState({
            showUploadDeleteOtherCost:"slide30",
            overlay:"show-m"
        })
    }
    deleteOtherCost(field)
    {
        console.log("field ", field)
        var aprams = {
            field : field
        }
        redirectURL.post("/deleteOtherCostField",aprams)
        .then((response) => {
            if(response.data.status === "success")
            {
                this.setState({
                    showUploadDeleteOtherCost:"slide30",
                    overlay:"show-n"
                });
                // this.loadFreightRates();
                window.location.reload();
            }
            
        })
    }
    formDeleteColumn(event){
        event.preventDefault();
    }
    colOPtions(){
        var items = [];
        var freightcolumns = this.state.columnDefs;
        var conf = this.state.conf;
        
            freightcolumns.map((im) => {
                if(im.headerName != "" && im.field != "")
                {            
                    items.push({
                        value:im.field,
                        label:im.headerName
                    });
                }
            })
        // }
        
        return items;
    }
    changeColOption(colOpt)
    {
        this.setState({colOpt},()=>{
            this.appendVal(this.state.colOpt.value)
        })
    }
    onClickShowDeviationFormual(){
        this.setState({
            sliderDeviationFormula:"slide60",
            overlay:"show-m"
        })
    }
    addDeviationCondition(){
        var html = '';
        if(this.state.deviationEquation.length > 0)
        {
            var cnt = this.state.deviationEquation.length;
        }
        else{
            var cnt = 1;
        }
        var cnt = this.state.deviationEquation.length;
        html += ' <div class="mt-4">from <input type="text" size="5" id="inp_from_'+cnt+'" data-id="'+cnt+'" class="inpfrom" /> days to <input type="text" id="inp_to_'+cnt+'" data-id="'+cnt+'" size="5" class="inpto" /> days  = Price: <input type="text" id="inp_price_'+cnt+'" data-id="'+cnt+'" size="5" class="inpto" />        </div>';
        $("#deviateFormula").append(html);
        var cnt = parseInt(cnt)+1;
        this.setState({
            deviationcnt:cnt
        })
    }
    addDeviationEndCondition(){
        var html = '';
        var cnt = this.state.deviationcnt;
        html += ' <div class="mt-4"><input type="text" size="5" id="inp_final_'+cnt+'" data-id="'+cnt+'" class="inpfinal" /> days  = Price: <input type="text" id="inp_final_price" size="5" class="inpto" />        </div>';
        $("#deviateFinalFormula").append(html);

    }
    appendDeviateVal = async (oVal) =>{
        // var dd = document.getElementById("formulas").innerHTML;
         var equaton = this.state.equaton;
        //  console.log("equaton ", equaton)
         var currentTrack  = "";
         var previousTrack = "";
         for (let i = 0; i < equaton.length; i++) {
             currentTrack = equaton[i];
             previousTrack = equaton[i];
             // if (i === 0) {
             // //   console.log('previousTrack of ' + currentTrack.key + ' is:', undefined);
             // } else {
             //   previousTrack = equaton[i - 1];
             // }
         }
         if( previousTrack != oVal)
         {
             
         }
        //  console.log("previousTrack ", previousTrack)
        await equaton.push({
             field:oVal
         })
         setTimeout(() => {
             this.setState({
                 equaton:equaton
             })  
             this.appendForm();
         },500)
        
    }

    onSaveDeviationFormula = async () =>{
        var inps = $(".inpfrom");
        var arr = [];
        var n=0;
        inps.each((function(){
            var dataID = $(this).attr("data-id");
            var fromc = $("#inp_from_"+dataID).val();
            var toc = $("#inp_to_"+dataID).val();
            var pricec = $("#inp_price_"+dataID).val();
            arr.push({
                fromcost:fromc,
                tocost:toc,
                price:pricec,
                condition_value:fromc+"-"+toc+"-"+pricec,
                order:n,
                condition_type:"condition"
            });
            n=n+1
        }));
        var inpfinal = $(".inpfinal").val();
        var inp_final_price = $("#inp_final_price").val();
        arr.push({
            fromcost:inpfinal,
            tocost:'',
            price:inp_final_price,
            condition_value:inpfinal+"-"+inp_final_price+"-final",
            order:n,
            condition_type:"final"
        })
        // console.log("arr ", arr)
        var params = {
            equation:JSON.stringify(arr)
        }
        redirectURL.post("/saveDeviationEquation",params)
        .then((response) => {
            if(response.data.status === "success")
            {
                this.setState({
                    // deviationEquation:arr
                })
                window.location.reload();
            }
        });
    }
    changeHandlerDynamic(oVal, event){
        // console.log("event ", event)
        var name = event.target.name;
        var id = event.target.id;
        var value = event.target.value;
        $("#"+id).val(value);
    }   
    render(){
		var fm = this.state.totalcosteq;
        var apFormula='';
        if(fm.length > 0)
        {
            var abc = fm[0].equation_txt;
            abc.map((itm) => {
                // console.log("itm ", itm)
                apFormula += itm
            })
        }
        var freightcolumns = this.state.freightcolumns;
        var conf = this.state.conf;
        return (
            <div className="container-fluid">
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">{Constants.PAGE_TITLE_FREIGHT_RATES}
                        <button type="button" onClick={this.onClickShowCSVForm.bind(this)} className="float-right btn btn-warning">Upload Freight Rates</button>
                        {(conf.other_costs === 'yes')?
                            <button type="button" onClick={this.onClickShowOtherCost.bind(this)} className="float-right btn btn-info" style={{marginRight:"10px"}}>Add Other Cost</button>
                        
                        :""}
                        {(conf.other_costs === 'yes')?
                            <button type="button" onClick={this.onClickShowDeleteOtherCost.bind(this)} className="float-right btn btn-danger" style={{marginRight:"10px"}}>Delete Other Cost</button>
                        :""}
                        <button type="button" onClick={this.onClickShowFormual} className="float-right btn btn-success" style={{marginRight:"10px"}}>Add Cost Formula</button>
                        <button type="button" onClick={this.onClickShowDeviationFormual} className="float-right btn btn-secondary" style={{marginRight:"10px"}}>Add Deviation Formula</button>
                    </h1>
				</div>
				<div className="row">

					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                <AgGridReact 
                                    rowData={this.state.rowData} 
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef ={this.state.defaultColDef }
                                    enableRangeSelection={true}
                                    enableCharts={true}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    sideBar={this.state.sideBar}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    
                                    components={this.state.components}
                                />
                                </div> 
                                <div id="replaceFormula"></div>
							</div>
						</div>
					</div>
					
				</div>
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
                <div className={"sliderBlock2 "+(this.state.slider)}>
                    <h5 className="crd-bg p-10p">Upload Data</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.uploadCSVData.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Upload CSV<span className="rclr fbold">*</span></label>
                                        
                                        <input type="file" name="upload_file" id="upload_file" className="form-control" onChange={this.changeFileHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <a href={"./sample_csv_freight_data.csv"} target={"_blank"} className="btn btn-warning">Download Sample CSV File</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
                <div className={"sliderBlock2 "+(this.state.showUploadOtherCost)}>
                    <h5 className="crd-bg p-10p">Add Column</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formAddColumn.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Column Name<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="col_name" id="col_name" value={this.state.col_name} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    {/* <div className="form-group col-sm-12 mb-20p">
                                        <label>Cost<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="col_cost" id="col_cost" value={this.state.col_cost} className="form-control" onChange={this.changeHandler} required />
                                    </div> */}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>

                <div className={"sliderBlock2 "+(this.state.showUploadDeleteOtherCost)}>
                    <h5 className="crd-bg p-10p">Delete Column</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formDeleteColumn.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                   
                                    {(freightcolumns.length > 0)?
                                        freightcolumns.map(im => 
                                            <div className="form-group col-sm-12 mb-20p border-bottom">
                                                {im.freight_other_cost_col_name}
                                                <button type="button" className="label label-danger float-right" onClick={this.deleteOtherCost.bind(this,im.freight_other_cost_col_field)}>Delete</button>
                                            </div>
                                        )
                                    :""}
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
                    
                <div className={"sliderBlock2 "+(this.state.showUpdateForm)}>
                    <h5 className="crd-bg p-10p">Add Column</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        <form onSubmit={this.formUpdateItem.bind(this)}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Route<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="route" id="route" value={this.state.route} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Source<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="source" id="source" value={this.state.source} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Destination<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="destination" id="destination" value={this.state.destination} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                               
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Transporter Name<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="transporter_name" id="transporter_name" value={this.state.transporter_name} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Rate Classification<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="rate_classification" id="rate_classification" value={this.state.rate_classification} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Contract / Approval Note No<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="contract_approval_no" id="contract_approval_no" value={this.state.contract_approval_no} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Valid From<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="contract_valid_from" id="contract_valid_from" value={this.state.contract_valid_from} className="form-control datepicker" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Valid To<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="contract_valid_to" id="contract_valid_to" value={this.state.contract_valid_to} className="form-control datepicker" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Truck Type<span className="rclr fbold">*</span></label>                                        
                                        <Select
                                         value={this.state.truck_type} 
                                         className="" 
                                         onChange={this.handleTruckChange}
                                         options={this.fechTruckTypeOptions()}
                                         />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Total Distance<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="total_distance" id="total_distance" value={this.state.total_distance} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Mileage<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="mileage" id="mileage" value={this.state.mileage} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Base Freight as per approval (INR)<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="base_freight_rate_per_approval" id="base_freight_rate_per_approval" value={this.state.base_freight_rate_per_approval} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Base Diesel Rate as per approval (Rs/ltr)<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="base_diesel_rate_per_approval_ltr" id="base_diesel_rate_per_approval_ltr" value={this.state.base_diesel_rate_per_approval_ltr} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Base Toll Rate as per approval (Rs)<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="base_toll_rate_per_approval" id="base_toll_rate_per_approval" value={this.state.base_toll_rate_per_approval} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Base Diesel City<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="base_diesel_city" id="base_diesel_city" value={this.state.base_diesel_city} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Base Diesel State<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="base_diesel_state" id="base_diesel_state" value={this.state.base_diesel_state} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    {(freightcolumns.length > 0)?
                                        freightcolumns.map(im => 
                                            // <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,im.freight_other_cost_col_field,'field')}>{im.freight_other_cost_col_name}</button>
                                            <div className="form-group col-sm-12 mb-20p">
                                                <label>{im.freight_other_cost_col_name}<span className="rclr fbold">*</span></label>                                        
                                                <input type="text" name={im.freight_other_cost_col_field} id={im.freight_other_cost_col_field}  className="form-control othercost"  required />
                                            </div>
                                        )
                                    :""}
                                    
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>

                <div className={"sliderBlock2 "+(this.state.sliderFormula)}>
                    <h5 className="crd-bg p-10p">Formula</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-sm-4 mb-20p">
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'(','sym')}>(</button>
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,')','sym')}>)</button>
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'+','sym')}>+</button>
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'-','sym')}>-</button>
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'*','sym')}>*</button>
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'/','sym')}>/</button>
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'%','sym')}>%</button>
                                    </div>
                                    <div className="form-group col-sm-3 mb-20p">
                                        <Select
                                            value={this.state.truck_type} 
                                            className="" 
                                            onChange={this.changeColOption.bind(this)}
                                            options={this.colOPtions()}
                                        />
                                        {/* <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'base_freight_rate_per_approval','field')}>Base Freight as per approval (INR)</button>
                                        <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,'base_diesel_rate_per_approval_ltr','field')}>Base Diesel rate as per approval (Rs/ltr)</button>
                                        {(freightcolumns.length > 0)?
                                            freightcolumns.map(im => 
                                                <button type="button" className="btn btn-default eqbtn" onClick={this.appendVal.bind(this,im.freight_other_cost_col_field,'field')}>{im.freight_other_cost_col_name}</button>
                                            )
                                        :""} */}
                                    </div>
                                    
                                    <div className="form-group col-sm-12 mb-20p mt-20p" style={{fontSize:"16px"}}>
                                       Total Cost Formula:
                                    </div>
                                    <div className="form-group col-sm-12 mb-20pt mt-20p brkwrod" style={{fontSize:"25px"}} >
                                       <textarea style={{height:"100px"}} className="form-control"  name="formulas" id="formulas" value={this.state.formulas} onChange={this.changeHandler} rows="8"></textarea>
                                    </div>
                                    <div className="form-group col-sm-4 mb-20pt mt-20p" style={{fontSize:"25px"}}>
                                       <ul id="formulasList" >

                                       </ul>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="button" className="btn btn-success" onClick={this.onSaveFormula.bind(this)}>SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Deviation work flow Formula */}
                <div className={"sliderBlock2 "+(this.state.sliderDeviationFormula)}>
                    <h5 className="crd-bg p-10p">Add Formula</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                        
                            <div className="slide-body">
                                <div className="row p-20p">
                                    {/* <div className="form-group col-sm-4 mb-20p">
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendDeviateVal.bind(this,'>','sym')}>&gt;</button>
                                       <button type="button" className="btn btn-default eqbtn" onClick={this.appendDeviateVal.bind(this,'<','sym')}>&lt;</button>
                                    </div> */}
                                    
                                    <div className="form-group col-sm-12 mt-20p" style={{fontSize:"16px"}}>
                                       <span style={{fontSize:"16px"}}>Add Formula:</span>
                                       <span className="float-right">
                                         <button onClick={this.addDeviationEndCondition.bind(this)} type="button" className="btn btn-primary">Add Final Condition</button>
                                       </span>
                                       <span className="float-right">
                                         <button onClick={this.addDeviationCondition.bind(this)} type="button" className="btn btn-default">Add Condition</button>
                                       </span>
                                    </div>
                                    
                                    <div className="form-group col-sm-12 " style={{fontSize:"25px"}} id="deviateFormula">
                                        {/* {(this.state.deviationEquation.length > 0)?
                                            // (this.state.deviationEquation.map((im,ind)=>
                                                
                                            //     <div className="mt-4">
                                            //         from <input type="text" size="5" name={"inp_from_"+(parseInt(im.order)+1)} id={"inp_from_"+(parseInt(im.order)+1)} onChange={this.changeHandlerDynamic.bind(this,"inp_from_"+(parseInt(im.order)+1))} data-id={(parseInt(im.order)+1)} className="inpfrom" /> days to 
                                            //         {im.condition_type !== 'final'?
                                            //         <span>
                                            //         <input type="text" id={"inp_to_"+(parseInt(im.order)+1)} name={"inp_to_"+(parseInt(im.order)+1)}  data-id={(parseInt(im.order)+1)} size="5" className="inpto" onChange={this.changeHandlerDynamic.bind(this,"inp_from_"+(parseInt(im.order)+1))}  /> days 
                                            //         </span>:""}
                                                    
                                            //         =  Price: <input type="text" name={"inp_price_"+(parseInt(im.order)+1)} id={"inp_price_"+(parseInt(im.order)+1)} data-id={(parseInt(im.order)+1)} size="5" onChange={this.changeHandlerDynamic.bind(this,"inp_from_"+(parseInt(im.order)+1))} className="inpto" />
                                            // </div>  
                                            // ))
                                            ""
                                        :
                                        <div className="mt-4">
                                             from <input type="text" size="5" id="inp_from_1" data-id="1" className="inpfrom" /> days to <input type="text" id="inp_to_1" data-id="1" size="5" className="inpto" /> days  =  Price: <input type="text" id="inp_price_1" data-id="1" size="5" className="inpto" />
                                        </div>
                                        } */}
                                        
                                        
                                    </div>
                                    <div className="form-group col-sm-12 mb-20pt " style={{fontSize:"25px"}} id="deviateFinalFormula">
                                        
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <input type="hidden" name="deviationcnt" id="deviationcnt" value={this.state.deviationEquation.length > 0?(this.state.deviationEquation.length+1):this.state.deviationcnt} />
                                        <button type="button" className="btn btn-success" onClick={this.onSaveDeviationFormula.bind(this)}>SAVE</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
// console.log("icn ", icn)
function pushNot(){
    // showNotification();
    
	// setInterval(function(){ showNotification(); }, 20000);
}


function showNotification() {	
	if (!Notification) {
		$('body').append('<h4 style="color:red">*Browser does not support Web Notification</h4>');
		return;
	}
	if (Notification.permission !== "granted") {		
		Notification.requestPermission();
	} else {		
		$.ajax({
            url:"http://localhost:5005/billing",
			type: "GET",
			success: function(data, textStatus, jqXHR) {
				
                var theurl = "http://localhost:3005/";
                var notifikasi = new Notification("Freight Rates", {
                    icon: icn,
                    body: "You are checking Freight Rates information.",
                });
                notifikasi.onclick = function () {
                    window.open(theurl); 
                    notifikasi.close();     
                };
                setTimeout(function(){
                    notifikasi.close();
                }, 5000);
        
			},
			error: function(jqXHR, textStatus, errorThrown)	{}
		}); 
	}
};
$(function(){
    
    $( ".datepicker" ).datepicker({
        dateFormat:"dd-mm-yy"
    });
})

function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }

//   const getDatePicker = () => {
//     function Datepicker() {}
//     Datepicker.prototype.init = function(params) {
//         const fillZeros = (a) => {
//             return (Number(a) < 10) ? '0' + a : a;
//         }
//         const getFormatedDate = (dateString ) => {
//             const dateParse = new Date(dateString.split('/')[1]+'-' + dateString.split('/')[0]+'-'+dateString.split('/')[2]);
//             const dd = dateParse.getDate();
//             const mm = dateParse.getMonth() + 1; //January is 0!
//             const yyyy = dateParse.getFullYear();
//             console.log(dateString, yyyy + '-' + fillZeros(mm) + '-' + fillZeros(dd));
//             return yyyy + '-' + fillZeros(mm) + '-' + fillZeros(dd);
//         }
//         this.textInput = React.createRef();
//         const eInput = React.createElement(TextField, {
//             type: "date",
//             defaultValue: getFormatedDate(params.value),
//             ref: this.textInput
//         });
//         this.div = document.createElement('div');
//         this.div.className = "ag-cell-parent-append";
//         ReactDOM.render(eInput, this.div);
//     };
//     Datepicker.prototype.getGui = function() {
//         return this.div;
//     };
//     Datepicker.prototype.afterGuiAttached = function() {
//         this.textInput.current.focus();
//     };
//     Datepicker.prototype.getValue = function() {
//         return this.textInput.current.querySelector('input').value;
//     };
//     Datepicker.prototype.destroy = function() {};
//     Datepicker.prototype.isPopup = function() {
//         return false;
//     };
//     return Datepicker;
//   }

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    // this.eInput.setAttribute("class","datepicker hasDatepicker");
    this.eInput.value = params.value;
    console.log("this.eInput ", this.eInput)
    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "dd-mm-yy",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};


function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 