import React, { Component, createRef } from 'react';
// import './App.css';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default class TrackingAmBarChart extends Component {
    constructor(props) {
        super(props)
        this.chartId = React.createRef()
        this.root = null
    }
    componentDidMount() {

        this.root = am5.Root.new(this.chartId.current);

        this.root.setThemes([
            am5themes_Animated.new(this.root)
        ]);

        let chart = this.root.container.children.push(
            am5xy.XYChart.new(this.root, {
                panY: false,
                layout: this.root.verticalLayout,
                // height: am5.percent(70)
            })
        );
        chart.children.unshift(am5.Label.new(this.root, {
            text: this.props.title,
            fontSize: 20,
            // fontWeight: "500",
            textAlign: "center",
            x: am5.percent(50),
            centerX: am5.percent(50),
            paddingTop: 0,
            paddingBottom: 30
        }));

        let data = this.props.barSeries[0].data

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(this.root, {
                renderer: am5xy.AxisRendererY.new(this.root, {})
            })
        );
        yAxis.children.unshift(am5.Label.new(this.root, {
            text: this.props.yAxisTitle,
            textAlign: 'center',
            y: am5.p50,
            rotation: -90,
            fontSize: "12px",
            opacity: 0.8
        }));

        let yRenderer = yAxis.get("renderer");
        yRenderer.grid.template.setAll({
            // stroke: am5.color(0xFF0000),
            // strokeWidth: 1,
            strokeOpacity: 0.1
        });
        yRenderer.labels.template.setAll({
            fontSize: "11px",
            opacity: 0.6
        });

        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(this.root, {
                renderer: am5xy.AxisRendererX.new(this.root, {
                    minGridDistance: 0,
                    cellStartLocation: 0.2,
                    cellEndLocation: 0.8
                }),
                categoryField: "name",
                tooltip: am5.Tooltip.new(this.root, {
                    themeTags: ["axis"],
                    animationDuration: 200
                })
            })
        );
        xAxis.children.push(am5.Label.new(this.root, {
            text: this.props.xAxisTitle,
            textAlign: 'center',
            x: am5.p50,
            fontSize: "12px",
            opacity: 0.8
        }));
        let xRenderer = xAxis.get("renderer");
        xRenderer.grid.template.setAll({
            // stroke: am5.color(0xFF0000),
            // strokeWidth: 1,
            strokeOpacity: 0
        });
        xRenderer.labels.template.setAll({
            oversizedBehavior: "wrap",
            textAlign: "center",
            fontSize: "11px",
            opacity: 0.6
        });

        xRenderer.labels.template.adapters.add("width", function (width, target) {
            let x0 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 0);
            let x1 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 1);
            target.set("maxWidth", x1 - x0)
            return x1 - x0;
        });
        xAxis.data.setAll(data);

        // Create series
        let series = chart.series.push(
            am5xy.ColumnSeries.new(this.root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "y",
                categoryXField: "name",
                // fill: am5.color(0x095256),
            })
        );
        series.data.setAll(data);
        series.columns.template.setAll({
            tooltipText: "{name}\n" + this.props.barSeries[0].name + ": {valueY}",
            width: am5.percent(90),
            tooltipY: 0,
        });
        series.columns.template.adapters.add("fill", function (fill, target) {
            return target.dataItem.dataContext["color"];
        });
        series.columns.template.adapters.add("stroke", function (fill, target) {
            return target.dataItem.dataContext["color"];
        });

        let cellSize = 90;
        series.events.on("datavalidated", function (ev) {

            let series = ev.target;
            let chart = series.chart;
            let xAxis = chart.xAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            let chartHeight = series.data.length * cellSize + xAxis.height() + chart.get("paddingTop", 0) + chart.get("paddingBottom", 0);

            // Set it on chart's container
            chart.root.dom.style.height = chartHeight + "px";
        });

        let exporting = am5plugins_exporting.Exporting.new(this.root, {
            menu: am5plugins_exporting.ExportingMenu.new(this.root, {}),
            dataSource: data
        });


        // Add legend
        // let legend = chart.children.push(am5.Legend.new(this.root, {}));
        // legend.data.setAll(chart.series.values);

        // Add cursor
        // chart.set("cursor", am5xy.XYCursor.new(this.root, {}));

    }

    componentWillUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    }

    render() {
        return (
            <div ref={this.chartId} style={{ width: "100%", height: "500px" }}></div>
        );
    }
}

