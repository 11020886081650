import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import icn from "../../img/undraw_profile.svg";
import $, { param } from 'jquery';
import 'jquery-ui';
import moment from 'moment';
import withRouter from './withRouter';
import UpdateItem from "./updateItem";


class BillingModuleUserAccess extends Component {
    constructor(props){
        super(props);
        // console.log(props)
        this.state={
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                menuTabs: ['filterMenuTab']
			},
            frameworkComponents:{
                UpdateItem:UpdateItem
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            closedDocs:[],
            otherCosts:[],
            overlay:"show-n"
        }
        
        this.loadApprovedDocuments = this.loadApprovedDocuments.bind(this);
    }
	componentDidMount = async () => {
        var currentURL = window.location.pathname;
        
        await setTimeout(() => {
            redirectURL.post("/userlists")
            .then((response) => {
                var records = response.data.records;
                var recordsarr = []
                if(records.length > 0)
                {
                    records.map((item) => {
                        recordsarr.push({
                            email:item.email,
                            document_creation:item.document_creation,
                            is_approve_doc:item.is_approve_doc,
                            created_on:item.created_on
                        })
                    })
                }
                console.log("recordsarr ", recordsarr)
                this.setState({
                    rowData:recordsarr
                })
            })
            
        },500)
        
        this.loadApprovedDocuments();
	}
   
    loadApprovedDocuments(){
        redirectURL.post("/docapprovals")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    closedDocs:records
                })
            }
		})
    }
    onClickUpdateItem = async (rownod) =>{
        await this.setState({
            overlay:"show-m"
        })
        await redirectURL.post("/saveuseraccess",rownod.data)
        .then((response) => {
            this.setState({
                overlay:"show-n"
            })
        })
    }
    
    render(){
        var otherCosts = this.state.otherCosts;
	
        var columnDefs=[
            {
                headerName:"User Emai",
                field:"email",
                width:"140"
            },
            {
                headerName:"Create Document",
                field:"document_creation",
                width:"140",
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: extractValues(options),
                },
                
                refData: options
                // valueSetter:function(params)
                // {
                //     try{
                //         if(params.data.useraccess !== undefined)
                //         {
                //             if(params.data.useraccess.length > 0)
                //             {
                //                 return params.data.useraccess[0].is_approve_doc;
                //             }
                //             else{
                //                 return "No";
                //             }
                //         }
                //         else{
                //             return "No";
                //         }
                //     }
                //     catch(e){}
                // }
            },
            {
                headerName:"Approve/Reject/SendBack",
                field:"is_approve_doc",
                width:"200",
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: extractValues(options),
                },
                refData: options
                
                // valueSetter:function(params)
                // {
                //     try{
                //         if(params.data.useraccess !== undefined)
                //         {
                //             if(params.data.useraccess.length > 0)
                //             {
                //                 return params.data.useraccess[0].is_approve_doc;
                //             }
                //             else{
                //                 return "No";
                //             }
                //         }
                //         else{
                //             return "No";
                //         }
                //     }
                //     catch(e){}
                // }
            },
            {
                headerName:"",
                field:"_id",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"UpdateItem"
                    }
                }
            }
        ]
       
        return (
            <div className="container-fluid">
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">{Constants.PAGE_TITLE_USER_ACCESS}
                     
                    </h1>
				</div>
                
				<div className="row">
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                <AgGridReact 
                                    rowData={this.state.rowData} 
                                    columnDefs={columnDefs}
                                    defaultColDef ={this.state.defaultColDef }
                                    enableRangeSelection={true}
                                    enableCharts={true}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    sideBar={this.state.sideBar}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    
                                />
                                </div> 
                                
							</div>
						</div>
					</div>
					
				</div>

                <div className={"overlay-block "+(this.state.overlay)}></div>
			</div>
                
                
        );
    }
}
const options = {
    '':"",
    Yes: 'Yes',
    No: 'No'
  };
function extractValues(mappings) {
    return Object.keys(mappings);
  }
  
export default withRouter(BillingModuleUserAccess) ;