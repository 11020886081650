import React, { useState, useEffect, useRef, useMemo } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import UpdateAcknowledgeBtnComponent from "./update&aknowledgeBtn";
import UpdateAcknowledgeBtnComponent1 from "./update&aknowledgeBtn1";
import SubmitRevisedBtn from "./submitrevisedbtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import $ from "jquery";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
import Select from "react-select";
import chaBasedUploadDoData from "../../assets/json/cha_based_upload_do_data.csv";
import chabasedAtPlantdetails from "../../assets/json/cha_based_at_plant_details.csv";
import chabasedContainerReport from "../../assets/json/cha_based_container_report.csv";
import chabasedleodate from "../../assets/json/cha_based_leo_date.csv";
import chabasedBillofladingdata from "../../assets/json/cha_based_bl_data.csv";
import chabasedReportingTime from "../../assets/json/cha_based_reporting_time_data.csv";
import chaBasedGateIn from "../../assets/json/cha_based_gate_in.csv";
import chaBasedShippingOnboard from "../../assets/json/cha_based_shipping_onboard.csv";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import PTData from '../../assets/json/payment_term_description.json'
import {
  getHyphenYYYYMMDDHHMMSS,
  getDDMMMYYYY,
  getHyphenDDMMYYYY,
  getHyphenYYYYMMDD,
  getHyphenDDMMYYYYHHMM,
  getHHMM,
} from "../common/utils";
import moment from "moment";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import UploadDo from "./uploadDO";
import InTransitToPort from "./inTransitToPort";
import CountryFlagComponent from "./countryFlag";
import ContainerReporting from "./containerReporting";
import UpdateCHAVesselAvailability from "./updateCHAvesselAvailability";
import UpdateCHAVesselAvailability1 from "./updateCHAesselavailability1";
import GateIn from "./gateIn";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import CHABuffer from "./bufferCHAComponent";
import AtPlantCha from "./atplantcha";
import CompletedComponent from "./completedComponent";
import CHAallComponent from "./chaAllcomponent";
import ShippingOnBoardComponent from "./shippingOnboard";
import BillofLadingComponent from "./billoflading";
import { fetchGrid } from "../fetchGridStructure";
import redirectURL from "../redirectURL";
import Modal from "react-responsive-modal";

const CHAUpdatesComponent = (props) => {
  const gridApi = useRef(null);
  // var [gridApi, setGridApi] = useState(null);
  var [gridColumnApi, setgridColumnApi] = useState(null);
  var gridRef = useRef();
  var [summaryviewcheck, setsummaryviewcheck] = useState(1);
  var [detailedviewcheck, setdetailedviewcheck] = useState(0);
  var [rowData, setrowData] = useState([]);
  var [originalData, setoriginalData] = useState([]);
  var [checkinput, setcheckinput] = useState(1);
  var [checkForDateFilter, setcheckForDateFilter] = useState(0);
  var [loadshow, setloadshow] = useState("show-n");
  var [overly, setoverly] = useState("show-n");
  var [open, setopen] = useState(false);
  var [sliderForBulkupload, setsliderForBulkupload] = useState("");
  var [file, setfile] = useState("");
  var [csvcontent, setcsvcontent] = useState("");
  var [show, setshow] = useState(false);
  var [validbulkfile, setvalidbulkfile] = useState(false);
  var [errormsg, seterrormsg] = useState("");
  var [ptd, setptd] = useState(PTData.PTData ?? [])
  var [basicTitle, setbasicTitle] = useState("");
  var [basicType, setbasicType] = useState("");
  var [selectedval, setselectedval] = useState({
    dropdownval: "",
    inputval: "",
  });
  var [checkforuploaddata, setcheckforuploaddata] = useState(0);
  var [selectedfileurl, setselectedfileurl] = useState("");
  var [checkforbulk, setcheckforbulk] = useState(true);
  var [api, setAPI] = useState("");
  var [check, setcheck] = useState(2);
  var [woReleaseDate, setWoReleaseDate] = useState({
    wo_release_from_date: "",
    wo_release_to_date: "",
  });
  var [csvfilename, setcsvfilename] = useState("");
  var [columnsforbulk, setcolumnsforbulk] = useState([] || []);
  var [bulkUploadFields, setbulkUploadFields] = useState([]);
  var [validcols, setvalidcols] = useState([]);
  var [counters, setCounters] = useState({
    vessel_avai: [],
    upload_do_data: [],
    at_plant_cha: [],
    intransit_port: [],
    container_reporting: [],
    gate_in_data: [],
    buffer: [],
    shipping_on_board: [],
    bill_data: [],
  });
  var [checkresetfilter, setcheckresetfilter] = useState(0);
  var [workordernoList, setworkOrderList] = useState([]);
  const [isClicked, setIsClicked] = useState({
    all: true,
    vessel_avail: false,
    uploadDo: true,
    InTransitToPort: false,
    gate_in: false,
    at_plant: false,
    container_reporting: false,
    closed: false,
    modified: false,
    buffer: false,
    shipping_onboard: false,
    bill_of_lading: false,
  });
  var [tabsForAtPlant, setTabsForAtPlant] = useState({
    at_plant_new: [],
    at_plant_planned: [],
    at_plant_modified: [],
    container_new_1: [],
    container_new_2: [],
    container_new_3: [],
  });
  var [tabsforcontainerReport, setTabsForContainerReport] = useState({
    container_report_new: [],
    container_report_planned: [],
    container_report_modified: [],
    container_new_1: [],
    container_new_2: [],
  });
  const [showAddForm, setshowAddForm] = useState("");
  const [showAddForm1, setshowAddForm1] = useState("");
  const [showAddForm2, setshowAddForm2] = useState("");

  var [rowData1, setrowData1] = useState([]);
  var [showGrid, setshowGrid] = useState(false);
  var [datarefresh, setdatarefresh] = useState(0);
  const [manualUploadDetails, setmanualUploadDetails] = useState({
    wrno: "",
    tentative_shipping_line: "",
    tentative_vessel_etd: "",
    tentative_gate_open_date: "",
    tentative_cut_off_date: "",
    freight_forwarder: "",
    no_of_free_days: "",
    remarks_by_cha: "",
  });
  var [frieghtlist, setfrieghtlist] = useState([]);
  var [refreshSelected, setRefreshSelected] = useState(0);

  const [count, setCount] = useState({
    uploadDodata: 0,
  });
  var [completedCount, setcompletedCount] = useState(0);
  var [tabForCompleted, setTabForCompleted] = useState({
    work_order_level_data: [],
    contaCompletediner_completed: [],
  });
  var [chaData, setchaData] = useState([]);
  const [reloadData, setReloadData] = useState(true);
  var [selected_row, setselected_row] = useState({});
  var [bufferdata, setbufferdata] = useState([]);
  var [classForTab, setclassforTab] = useState({
    planned: 0,
    new: 1,
    modified: 0,
  });
  var [tabDataforvessel, setTabDataForVessel] = useState({
    vessel_data_new: [],
    vessel_data_planned: [],
    vessel_data_modified: [],
  });
  var [tabsforuploadDo, setTabsforUploadDO] = useState({
    cha_do_new: [],
    cha_do_planned: [],
    cha_do_modified: [],
  });
  var [tabForLeo, setTabForLeo] = useState({
    leo_new: [],
    leo_planned: [],
    leo_modified: [],
    container_new_1: [],
    container_new_2: [],
    container_new_3: [],
  });
  var [tabForGateInPort, setTabForGateInPort] = useState({
    gate_new: [],
    gate_planned: [],
    gate_modified: [],
    container_new_1: [],
    container_new_2: [],
    shipping_wrno: [],
    bill_wrno: [],
  });
  var [tabForShippingOnboard, settabForShippingOnboard] = useState({
    onboard_new: [],
    onboard_planned: [],
    onboard_modified: [],
    container_new_1: [],
    container_new_2: [],
    shipping_wrno: [],
    bill_wrno: [],
  });
  var [tabForBillOfLading, settabForBillOfLading] = useState({
    bill_new: [],
    bill_planned: [],
    bill_modified: [],
    container_new_1: [],
    container_new_2: [],
    shipping_wrno: [],
    bill_wrno: [],
  });
  var [shippinglist1, setshippingLineList] = useState([]);
  const [gridWidth, setGridWidth] = useState('10rem'); // Initial width
  const [summaryViewcols, setsummaryViewcols] = useState([
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 140,
      filter: true,
      resizable: true,
    },
    // {
    //     headerName: "Production Date",
    //     field: "production_date",
    //     width: 150,
    //     filter: true,
    //     resizable: true,
    //     // editable: true,
    //     valueGetter: function (params) {
    //         try {
    //             if (params.data.production_date != "" && params.data.production_date != undefined) {
    //                 return getHyphenDDMMYYYY(params.data.production_date);
    //             }
    //             else {
    //                 return "Not Available";
    //             }
    //         }
    //         catch (e) { }
    //     },
    // },
    {
      headerName: "Production Date",
      field: "production_date",
      width: 150,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          // if(params.data.modifiedconsignments != "" && params.data.modifiedconsignments != undefined)
          // {
          //     if(params.data.modifiedconsignments.length > 0)
          //     {
          //         if(params.data.modifiedconsignments[0].revised_production_date != "" && params.data.modifiedconsignments[0].revised_production_date != undefined)
          //         {
          //             return getHyphenDDMMYYYY(params.data.modifiedconsignments[0].revised_production_date);
          //         }
          //         else{
          //             return getHyphenDDMMYYYY(params.data.production_date)
          //         }
          //     }

          // }
          // else if(params.data.modified_revised_production_date!='' && params.data.modified_revised_production_date!=undefined){
          //     return getHyphenDDMMYYYY(params.data.modified_revised_production_date)
          // }
          // else if(params.data.revised_production_date!='' && params.data.revised_production_date!=undefined){
          //     return getHyphenDDMMYYYY(params.data.revised_production_date)
          // }
          // else{
          //     return getHyphenDDMMYYYY(params.data.production_date)
          // }

          if (
            params.data.revised_production_date != "" &&
            params.data.revised_production_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.revised_production_date);
          } else {
            return getHyphenDDMMYYYY(params.data.production_date);
          }
        } catch (e) {}
      },
    },

    {
      headerName: "Ship To Country",
      field: "ship_to_country",
      width: 130,
      filter: true,
      resizable: true,
      // cellRenderer: 'CountryFlagComponent'
    },
    {
      headerName: "Brand",
      field: "brand",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Brand Variant",
      field: "brand_variant",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "40ft Containers",
      field: "ft40_containers",
      width: 140,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          // if(params.data.modified_plant == 1)
          // {

          //     return params.data.ft40_containers;
          // }
          // else{
          //     if(params.data.plant_check == 1 || params.data.planned_plant == 1)
          //     {
          //         if(params.data.modified_ft40_containers != "" && params.data.modified_ft40_containers != undefined)
          //         {
          //             return params.data.modified_ft40_containers;
          //         }
          //         else{
          //             return params.data.ft40_containers;
          //         }
          //     }
          //     else{
          //         return params.data.ft40_containers;
          //     }
          // }
          if (params.data.plant_check == 1 && params.data.modified_plant == 1) {
            return params.data.ft40_containers;
          } else {
            if (params.data.plant_check == 1) {
              if (
                params.data.modified_ft40_containers != "" &&
                params.data.modified_ft40_containers != undefined
              ) {
                return params.data.modified_ft40_containers;
              } else {
                return params.data.ft40_containers;
              }
            } else {
              return params.data.ft40_containers;
            }
          }
        } catch (error) {}
      },
    },
    {
      headerName: "20ft Containers",
      field: "ft20_containers",
      width: 140,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (params.data.plant_check == 1 && params.data.modified_plant == 1) {
            return params.data.ft20_containers;
          } else {
            if (params.data.plant_check == 1) {
              if (
                params.data.modified_ft20_containers != "" &&
                params.data.modified_ft20_containers != undefined
              ) {
                return params.data.modified_ft20_containers;
              } else {
                return params.data.ft20_containers;
              }
            } else {
              return params.data.ft20_containers;
            }
          }
        } catch (error) {}
      },
    },
    {
      headerName: "Trucks",
      field: "truck_and_courier",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "POD",
      field: "pod",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant",
      field: "plant",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "CHA",
      field: "cha",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "CHA Name",
      field: "cha_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant Name",
      field: "plant_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Transporter Name",
      field: "tpt_name",
      width: 200,
      filter: true,
      resizable: true,
    },

    {
      headerName: "PO NO",
      field: "po_no",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quotation",
      field: "quotation",
      width: 130,
      filter: true,
      resizable: true,
    },
    // {
    //     headerName: "Production Date",
    //     field: "production_date",
    //     width: 150,
    //     filter: true,
    //     resizable: true,
    //     editable: true,
    //     valueGetter: function (params) {
    //         try {
    //             if (params.data.production_date != "" && params.data.production_date != undefined) {
    //                 return getHyphenDDMMYYYY(params.data.production_date);
    //             }
    //             else {
    //                 return "Not Available";
    //             }
    //         }
    //         catch (e) { }
    //     },
    // },
    {
      headerName: "Latest Shipment Date",
      field: "latest_shipment_date",
      width: 160,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.latest_shipment_date != "" &&
            params.data.latest_shipment_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.latest_shipment_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Expected Payment Clearance By",
      field: "expected_payment_clearance_by",
      width: 220,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.expected_payment_clearance_by != "" &&
            params.data.expected_payment_clearance_by != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "HAZ Classification",
      field: "haz_classification",
      width: 160,
      filter: true,
      resizable: true,
    },
  ]);
  useEffect(() => {
    if (showAddForm === "slide30") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showAddForm]);
  useEffect(() => {
    if (gridApi.current) {
      getUserGridState();
    }
    loadDateTimeScript();
  }, [gridApi]);

  useEffect(() => {
    setloadshow("show-m");
    loadDateTimeScript();
    getCHAdata();
    getbuffercha();
    redirectURL.post("/consignments/getdropdowndata").then((resp) => {
      var shippingLineList = resp.data.shippingLineList;
      var freight1 = resp.data.freightforwader;

      if (shippingLineList != "" && shippingLineList != undefined) {
        shippingLineList = removeDuplicateObjects(shippingLineList);
        setshippingLineList(shippingLineList);
      }
      if (freight1 != "" && freight1 != undefined) {
        freight1 = removeDuplicateObjects(freight1);
        setfrieghtlist(freight1);
      }
      setloadshow("show-n");
    });
  }, [reloadData]);
  const onGridReady = (params) => {
    gridApi.current = params;
    // gridColumnApi.current = params.columnApi;
    getUserGridState();
  };
  var getbuffercha = () => {
    redirectURL.post("/consignments/getbufferforcha").then((resp) => {
      if (resp.data != "" && resp.data != undefined) {
        var data = resp.data.work_order_level_data;
        data = data.filter((e) => e.pendency_in_buffer > 0);
        setbufferdata({
          wr_data: data,
          container_data: resp.data.containerDetails,
        });
        setCounters((prev) => ({
          ...prev,
          buffer: data,
        }));
      }
    });
    setloadshow("show-n");
  };
  var getCHAdata = () => {
    var chaData = [];
    var paramsforvessel = {};
    if (
      localStorage.getItem("plant_code") != "" &&
      localStorage.getItem("plant_code") != undefined &&
      localStorage.getItem("plant_code") != "undefined" &&
      localStorage.getItem("user_role") != "cha_all"
    ) {
      paramsforvessel.cha = localStorage.getItem("plant_code");
    }
    redirectURL
      .post("/consignments/getvesseldata", paramsforvessel)
      .then((resp) => {
        try {
          var records = resp.data.vessel_new;
          var records1 = resp.data.vessel_planned;
          var records2 = resp.data.vessel_modified;
          var vessel_data_new = records;
          var vessel_data_planned = sortByCreatedDate(records1);
          var vessel_data_modified = sortByCreatedDate(records2);
          //console.log(vessel_data_modified,"modddddd")
          setTabDataForVessel({
            vessel_data_new: vessel_data_new,
            vessel_data_planned: vessel_data_planned,
            vessel_data_modified: vessel_data_modified,
          });
          var work_order_no = [];
          vessel_data_new.map((e) => {
            work_order_no.push({
              label: e.work_order_no,
              value: e.work_order_no,
            });
          });
          sortByCreatedDate(vessel_data_new);
          setrowData(vessel_data_new);
          setoriginalData(vessel_data_new);
          setCounters((prev) => ({
            ...prev,
            vessel_avai: vessel_data_new,
            // upload_do_data: upload_do_data
          }));
          chaData.push(...vessel_data_new);
          setworkOrderList(work_order_no);
        } catch (e) {}
      });
    var paramsfordo = {};
    if (
      localStorage.getItem("plant_code") != "" &&
      localStorage.getItem("plant_code") != undefined &&
      localStorage.getItem("plant_code") != "undefined" &&
      localStorage.getItem("user_role") != "cha_all"
    ) {
      paramsfordo.cha = localStorage.getItem("plant_code");
    }
    redirectURL.post("/consignments/getUploadDO", paramsfordo).then((resp) => {
      try {
        var records = resp.data.records;
        if (records != "" && records != undefined) {
          var upload_do_data = records.filter(
            (e) =>
              e.cha_do_new == 1 ||
              e.cha_do_planned == 1 ||
              e.cha_do_modified == 1
          );
          var cha_do_new = upload_do_data.filter((e) => e.cha_do_new == 1);
          var cha_do_planned = upload_do_data.filter(
            (e) => e.cha_do_planned == 1
          );
          var cha_do_modified = upload_do_data.filter(
            (e) => e.cha_do_modified == 1
          );
          setTabsforUploadDO({
            cha_do_new: cha_do_new,
            cha_do_planned: cha_do_planned,
            cha_do_modified: cha_do_modified,
          });
          setCounters((prev) => ({
            ...prev,
            upload_do_data: upload_do_data,
          }));
          chaData.push(...upload_do_data);
        }
      } catch (e) {}
    });
    redirectURL.post("/consignments/getAtPlantCHA").then((resp3) => {
      try {
        var at_plant_new = resp3.data.work_order_level_data1;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          at_plant_new = at_plant_new.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var container_new_1 = resp3.data.containerDetails1;
        var container_new_2 = resp3.data.containerDetails2;
        var container_new_3 = resp3.data.containerDetails3;
        var at_plant_planned = resp3.data.work_order_level_data2;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          at_plant_planned = at_plant_planned.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var at_plant_modified = resp3.data.work_order_level_data3;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          at_plant_modified = at_plant_planned.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        setTabsForAtPlant({
          at_plant_new: at_plant_new,
          at_plant_planned: at_plant_planned,
          at_plant_modified: at_plant_modified,
          container_new_1: container_new_1,
          container_new_2: container_new_2,
          container_new_3: container_new_3,
        });
      } catch (e) {}
    });
    redirectURL.post("/consignments/getContainersReportData").then((resp3) => {
      try {
        var container_report_new = resp3.data.work_order_level_data1;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          container_report_new = container_report_modified.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var container_new_1 = resp3.data.containerDetails1;
        var container_new_2 = resp3.data.containerDetails2;
        var container_report_planned = resp3.data.work_order_level_data2;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          container_report_planned = container_report_modified.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var container_report_modified = resp3.data.work_order_level_data3;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          container_report_modified = container_report_modified.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        setTabsForContainerReport({
          container_report_new: container_report_new,
          container_report_planned: container_report_planned,
          container_report_modified: container_report_modified,
          container_new_1: container_new_1,
          container_new_2: container_new_2,
        });
        var container_reporting = [...container_report_new];
        setCounters((prev) => ({
          ...prev,
          container_reporting: container_reporting,
        }));
        chaData.push(...container_reporting);
        // if(container_report_new!='' && container_report_new!=undefined && container_new_1!='' && container_new_1!=undefined &&container_new_2!='' && container_new_2!=undefined&&container_report_planned!='' && container_report_planned!=undefined&&container_report_modified!=''&&container_report_modified!=undefined){
        //     setTabsForContainerReport({
        //         container_report_new:container_report_new,
        //         container_report_planned:container_report_planned,
        //         container_report_modified:container_report_modified,
        //       container_new_1:container_new_1,
        //       container_new_2:container_new_2
        //     })
        //     var container_reporting = [...container_report_new]
        //     setCounters(prev => ({
        //         ...prev,
        //         container_reporting: container_reporting
        //     }))
        //     chaData.push(...container_reporting)
        // }
      } catch (e) {}
    });
    redirectURL.post("/consignments/getLeoDateData").then((resp3) => {
      try {
        var leo_new = [];
        if (
          resp3.data.work_order_level_data1 != "" &&
          resp3.data.work_order_level_data1 != undefined
        ) {
          leo_new = resp3.data.work_order_level_data1;
        }
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          leo_new = leo_new.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var container_new_1 = [];
        if (
          resp3.data.containerDetails1 != "" &&
          resp3.data.containerDetails1 != undefined
        ) {
          container_new_1 = resp3.data.containerDetails1;
        }
        var container_new_2 = [];
        if (
          resp3.data.containerDetails2 != "" &&
          resp3.data.containerDetails2 != undefined
        ) {
          container_new_2 = resp3.data.containerDetails2;
        }
        var container_new_3 = [];
        if (
          resp3.data.containerDetails3 != "" &&
          resp3.data.containerDetails3 != undefined
        ) {
          container_new_3 = resp3.data.containerDetails3;
        }
        var leo_planned = [];
        if (
          resp3.data.work_order_level_data2 != "" &&
          resp3.data.work_order_level_data2 != undefined
        ) {
          leo_planned = resp3.data.work_order_level_data2;
        }
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          leo_planned = leo_planned.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var leo_modified = [];
        if (
          resp3.data.work_order_level_data3 != "" &&
          resp3.data.work_order_level_data3 != undefined
        ) {
          leo_modified = resp3.data.work_order_level_data3;
        }
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          leo_modified = leo_modified.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var container_reporting = [];
        if (leo_new != "" && leo_new != undefined) {
          container_reporting = [...leo_new];
        }
        setCounters((prev) => ({
          ...prev,
          container_reporting: container_reporting,
        }));
        setTabForLeo({
          leo_new: leo_new,
          leo_planned: leo_planned,
          leo_modified: leo_modified,
          container_new_1: container_new_1,
          container_new_2: container_new_2,
          container_new_3: container_new_3,
        });
      } catch (e) {}
    });
    redirectURL.post("/consignments/getGateInportscreendata").then((resp3) => {
      try {
        var gate_new = resp3.data.work_order_level_data1;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          gate_new = gate_new.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var container_new_1 = resp3.data.containerDetails1;
        var container_new_2 = resp3.data.containerDetails2;
        var gate_planned = resp3.data.work_order_level_data2;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          gate_planned = gate_planned.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var gate_modified = resp3.data.work_order_level_data3;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          gate_modified = gate_modified.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var shipping_wrno = resp3.data.unique_work_order_no_array3;
        var bill_wrno = resp3.data.unique_work_order_no_array4;
        setTabForGateInPort({
          gate_new: gate_new,
          gate_planned: gate_planned,
          gate_modified: gate_modified,
          container_new_1: container_new_1,
          container_new_2: container_new_2,
          shipping_wrno: shipping_wrno,
          bill_wrno: bill_wrno,
        });
        var gate_in_data = [...gate_new];
        setCounters((prev) => ({
          ...prev,
          gate_in_data: gate_in_data,
        }));
        chaData.push(...gate_in_data);
      } catch (e) {}
    });
    redirectURL
      .post("/consignments/getshippingonboarddetails")
      .then((resp3) => {
        try {
          var onboard_new = resp3.data.work_order_level_data1;
          if (
            localStorage.getItem("plant_code") != "" &&
            localStorage.getItem("plant_code") != undefined &&
            localStorage.getItem("plant_code") != "undefined" &&
            localStorage.getItem("user_role") != "cha_all"
          ) {
            onboard_new = onboard_new.filter(
              (e) => e.cha == localStorage.getItem("plant_code")
            );
          }
          var container_new_1 = resp3.data.containerDetails1;
          var container_new_2 = resp3.data.containerDetails2;
          var onboard_planned = resp3.data.work_order_level_data2;
          if (
            localStorage.getItem("plant_code") != "" &&
            localStorage.getItem("plant_code") != undefined &&
            localStorage.getItem("plant_code") != "undefined" &&
            localStorage.getItem("user_role") != "cha_all"
          ) {
            onboard_planned = onboard_planned.filter(
              (e) => e.cha == localStorage.getItem("plant_code")
            );
          }
          var shipping_wrno = resp3.data.unique_work_order_no_array3;

          settabForShippingOnboard({
            onboard_new: onboard_new,
            onboard_planned: onboard_planned,
            container_new_1: container_new_1,
            container_new_2: container_new_2,
            shipping_wrno: shipping_wrno,
          });
          var onboard_data = [...onboard_new];
          setCounters((prev) => ({
            ...prev,
            shipping_on_board: onboard_data,
          }));
          chaData.push(...onboard_data);
        } catch (e) {}
      });
    redirectURL.post("/consignments/getbillofladingdata").then((resp3) => {
      try {
        var bill_new = resp3.data.work_order_level_data1;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          bill_new = bill_new.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        var container_new_1 = resp3.data.containerDetails1;
        var container_new_2 = resp3.data.containerDetails2;
        var bill_planned = resp3.data.work_order_level_data2;
        if (
          localStorage.getItem("plant_code") != "" &&
          localStorage.getItem("plant_code") != undefined &&
          localStorage.getItem("plant_code") != "undefined" &&
          localStorage.getItem("user_role") != "cha_all"
        ) {
          bill_planned = bill_planned.filter(
            (e) => e.cha == localStorage.getItem("plant_code")
          );
        }
        settabForBillOfLading({
          bill_new: bill_new,
          bill_planned: bill_planned,
          container_new_1: container_new_1,
          container_new_2: container_new_2,
        });
        var bill_data = [...bill_new];
        setCounters((prev) => ({
          ...prev,
          bill_data: bill_data,
        }));
        chaData.push(...bill_data);
      } catch (e) {}
    });
    redirectURL.post("/consignments/getCompletedData").then((resp) => {
      try {
        var work_order_level_data = resp.data.work_order_level_data1;
        var contaCompletediner_completed = resp.data.containerDetails1;
        if (work_order_level_data != "" && work_order_level_data != undefined) {
            if (
                localStorage.getItem("plant_code") != "" &&
                localStorage.getItem("plant_code") != undefined &&
                localStorage.getItem("plant_code") != "undefined" &&
                localStorage.getItem("user_role") != "cha_all"
              ) {
                work_order_level_data = work_order_level_data.filter(
                  (e) => e.cha == localStorage.getItem("plant_code")
                );
              }
          setcompletedCount(work_order_level_data.length);
          setTabForCompleted({
            work_order_level_data: work_order_level_data,
            contaCompletediner_completed: contaCompletediner_completed,
          });
        }
      } catch (e) {}
    });
    setloadshow("show-n");
  };
  var typeofselection = [
    { label: "Ship To Country", value: "ship_to_country" },
    { label: "POD", value: "pod" },
    { label: "Plant", value: "plant" },
    { label: "Production Date", value: "production_date" },
    { label: "Work Order No", value: "work_order_no" },
  ];
  // const rowClassRules = useMemo(() => ({
  //     'black-row': params => params.data.modified_plant == 1,
  //     'white-row': params => params.data.modified_plant !== 1,
  //   }), []);

  const onClickchavessel = (e) => {
    setselected_row(e.data);
    setshowAddForm("slide30");
    setoverly("show-m");
  };
  const onClickchavessel1 = (e) => {
    setselected_row(e.data);
    setshowAddForm1("slide30");
    setoverly("show-m");
    setmanualUploadDetails((prev) => ({
      ...prev,
      freight_forwarder: {
        label: e.data.freight_forwarder,
        value: e.data.freight_forwarder,
      },
      tentative_vessel_etd: getHyphenDDMMYYYY(e.data.tentative_vessel_etd),
      wrno: { label: e.data.work_order_no, value: e.data.work_order_no },
      tentative_shipping_line: {
        label: e.data.tentative_shipping_line,
        value: e.data.tentative_shipping_line,
      },
      tentative_gate_open_date: getHyphenDDMMYYYY(
        e.data.tentative_gate_open_date
      ),
      tentative_cut_off_date: getHyphenDDMMYYYY(e.data.tentative_cut_off_date),
      no_of_free_days: e.data.no_of_free_days,
      remarks_by_cha: e.data.remarks_by_cha,
    }));
  };
  
  const onClickupdateAcknowledgeBtn = (e) => {

    setselected_row(e.data);
    setshowAddForm1("slide30");
    setoverly("show-m");
    setmanualUploadDetails((prev) => ({
      ...prev,
      freight_forwarder: {
        label: e.data.freight_forwarder,
        value: e.data.freight_forwarder,
      },
      tentative_vessel_etd: getHyphenDDMMYYYY(e.data.tentative_vessel_etd),
      wrno: { label: e.data.work_order_no, value: e.data.work_order_no },
      tentative_shipping_line: {
        label: e.data.tentative_shipping_line,
        value: e.data.tentative_shipping_line,
      },
      tentative_gate_open_date: getHyphenDDMMYYYY(
        e.data.tentative_gate_open_date
      ),
      tentative_cut_off_date: getHyphenDDMMYYYY(e.data.tentative_cut_off_date),
      no_of_free_days: e.data.no_of_free_days,
      remarks_by_cha: e.data.remarks_by_cha,
    }));
  };
  const onClickupdateAcknowledgeBtn1 = (e) => {

    setselected_row(e.data);
    setshowAddForm2("slide30");
    setoverly("show-m");
    setmanualUploadDetails((prev) => ({
      ...prev,
      freight_forwarder: {
        label: e.data.freight_forwarder,
        value: e.data.freight_forwarder,
      },
      tentative_vessel_etd: getHyphenDDMMYYYY(e.data.tentative_vessel_etd),
      wrno: { label: e.data.work_order_no, value: e.data.work_order_no },
      tentative_shipping_line: {
        label: e.data.tentative_shipping_line,
        value: e.data.tentative_shipping_line,
      },
      tentative_gate_open_date: getHyphenDDMMYYYY(
        e.data.tentative_gate_open_date
      ),
      tentative_cut_off_date: getHyphenDDMMYYYY(e.data.tentative_cut_off_date),
      no_of_free_days: e.data.no_of_free_days,
      remarks_by_cha: e.data.remarks_by_cha,
    }));
  };
  var columnDefs2 = [
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Freight Forwarder",
      field: "freight_forwarder",
      width: 160,
      filter: true,
      resizable: true,
    },
    // {
    //   headerName: "Tentative Shipping Line",
    //   field: "tentative_shipping_line",
    //   width: 250,
    //   filter: true,
    //   resizable: true,
    // },
    // {
    //   headerName: "Tentative Vessel ETD",
    //   field: "tentative_vessel_etd",
    //   width: 190,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: (params) => {
    //     const date = params.data.tentative_vessel_etd;
    //     // Check if date is in DD-MM-YYYY format
    //     const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    //     if (date && datePattern.test(date)) {
    //       return date;
    //     } else {
    //       return "Invalid Date Format";
    //     }
    //   },
    //   cellStyle: (params) => {
    //     const date = params.value;
    //     // Apply red color if the date format is invalid
    //     if (date === "Invalid Date Format") {
    //       return { background: "red", color: "black" };
    //     }
    //     return null;
    //   },
    // },
    // {
    //   headerName: "Tentative Cut Off Date",
    //   field: "tentative_cut_off_date",
    //   width: 190,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: (params) => {
    //     const date = params.data.tentative_cut_off_date;
    //     // Check if date is in DD-MM-YYYY format
    //     const datePattern = /^\d{2}-\d{2}-\d{4}\s+\d{2}:\d{2}$/;
    //     if (date && datePattern.test(date)) {
    //       return date;
    //     } else {
    //       return "Invalid Date Format";
    //     }
    //   },
    //   cellStyle: (params) => {
    //     const date = params.value;
    //     // Apply red color if the date format is invalid
    //     if (date === "Invalid Date Format") {
    //       return { background: "red", color: "black" };
    //     }
    //     return null;
    //   },
    // },
    {
      headerName: "Tentative Gate Open Date",
      field: "tentative_gate_open_date",
      width: 190,
      filter: true,
      resizable: true,
      valueGetter: (params) => {
        const date = params.data.tentative_gate_open_date;
        // Check if date is in DD-MM-YYYY format
        const datePattern = /^\d{2}-\d{2}-\d{4}\s+\d{2}:\d{2}$/;
        if (date && datePattern.test(date)) {
          return date;
        } else {
          return "Invalid Date Format";
        }
      },
      cellStyle: (params) => {
        const date = params.value;
        // Apply red color if the date format is invalid
        if (date === "Invalid Date Format") {
          return { background: "red", color: "black" };
        }
        return null;
      },
    },
    {
      headerName: "No of Free Days",
      field: "no_of_free_days",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Remark By CHA",
      field: "remarks_by_cha",
      width: 160,
      filter: true,
      resizable: true,
    },
  ];
  var columnDefs1 = [
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Freight Forwarder",
      field: "freight_forwarder",
      width: 160,
      filter: true,
      resizable: true,
    },
    // {
    //   headerName: "Tentative Shipping Line",
    //   field: "tentative_shipping_line",
    //   width: 160,
    //   filter: true,
    //   resizable: true,
    // },
    // {
    //   headerName: "Tentative Vessel ETD",
    //   field: "tentative_vessel_etd",
    //   width: 190,
    //   filter: true,
    //   resizable: true,
    //   editable: (params) => {
    //     const date = params.data.tentative_vessel_etd;
    //     // Check if date is in DD-MM-YYYY format
    //     const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    //     return !(date && datePattern.test(date)); // Editable if date is invalid
    // },
    //   valueGetter: (params) => {
    //     const date = params.data.tentative_vessel_etd;
    //     // Check if date is in DD-MM-YYYY format
    //     const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    //     if (date && datePattern.test(date)) {
    //       return date;
    //     } else {
    //       return "Invalid Date Format";
    //     }
    //   },
    //   cellStyle: (params) => {
    //     const date = params.value;
    //     // Apply red color if the date format is invalid
    //     if (date === "Invalid Date Format") {
    //       return { background: "red", color: "black" };
    //     }
    //     return null;
    //   },
    // },
    // {
    //   headerName: "Tentative Cut Off Date",
    //   field: "tentative_cut_off_date",
    //   width: 190,
    //   filter: true,
    //   resizable: true,
    //   editable: (params) => {
    //     const date = params.data.tentative_cut_off_date;
    //     // Check if date is in DD-MM-YYYY HH:mm format
    //     const dateTimePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
    //     return !(date && dateTimePattern.test(date)); // Editable if date-time is invalid
    //   },
    //   valueGetter: (params) => {
    //     const date = params.data.tentative_cut_off_date;
    //     // Check if date is in DD-MM-YYYY HH:mm format
    //     const dateTimePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
    //     if (date && dateTimePattern.test(date)) {
    //       return date;
    //     } else {
    //       return "Invalid Date Format";
    //     }
    //   },
    //   cellStyle: (params) => {
    //     const date = params.value;
    //     // Apply red color if the date format is invalid
    //     if (date === "Invalid Date Format") {
    //       return { background: "red", color: "black" };
    //     }
    //     return null;
    //   },
    // },
    {
      headerName: "Tentative Gate Open Date",
      field: "tentative_gate_open_date",
      width: 190,
      filter: true,
      resizable: true,
      editable: (params) => {
        const date = params.data.tentative_gate_open_date;
        // Check if date is in DD-MM-YYYY HH:mm format
        const dateTimePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
        return !(date && dateTimePattern.test(date)); // Editable if date-time is invalid
      },
      valueGetter: (params) => {
        const date = params.data.tentative_gate_open_date;
        // Check if date is in DD-MM-YYYY HH:mm format
        const dateTimePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
        if (date && dateTimePattern.test(date)) {
          return date;
        } else {
          return "Invalid Date Format";
        }
      },
      cellStyle: (params) => {
        const date = params.value;
        // Apply red color if the date format is invalid
        if (date === "Invalid Date Format") {
          return { background: "red", color: "black" };
        }
        return null;
      },
    },
    {
      headerName: "No of Free Days",
      field: "no_of_free_days",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Remark By CHA",
      field: "remarks_by_cha",
      width: 160,
      filter: true,
      resizable: true,
    },
  ];
  const getRowStyle = (params) => {
    if (params.data && params.data.modified_plant == 1) {
      return { backgroundColor: "#FFB9B9" };
    }
    return null;
  };
  const onClickAcknowledgeBtn = (rowdata) => {
    var row = rowdata.data;
    if (row.update_ack != 1) {
      var params = {
        work_order_no: row.work_order_no,
        unsetField: "cha_vessel_modified",
        setField: "ib_stuffing_modified",
        record: row,
      };
      redirectURL.post("/consignments/acknowledgeData", params).then((resp) => {
        if (resp.data.status == "Success") {
          setshow(true);
          setbasicTitle("Acknowledgement successful");
          setbasicType("success");
          setclassforTab(
            {
                planned: 0,
                new: 1,
                modified: 0,
            }
        )
          refreshDataNow();
          props.onRequest();
          props.onReloadData(true);
        }
      });
    }
  };
  const onClickRevisedProductionData = (rowdata) => {
    var row = rowdata.data;
    var tentative_vessel_etd = row.tentative_vessel_etd;
    tentative_vessel_etd = tentative_vessel_etd.replace(/\//g, "-");
    tentative_vessel_etd = moment
      .parseZone(tentative_vessel_etd)
      .format("DD-MM-YYYY");
    var data = [
      {
        tentative_vessel_etd: tentative_vessel_etd,
        work_order_no: row.work_order_no,
      },
    ];
    var params = {
      tentative_vessel_etd: tentative_vessel_etd,
      work_order_no: row.work_order_no,
      cha_vessel_modified: 1,
    };

    if (tentative_vessel_etd != "" && tentative_vessel_etd != undefined) {
      redirectURL
        .post("/consignments/updateModifiedFields", params)
        .then((resp) => {
          if (resp.data.status == "Success") {
            setshow(true);
            setbasicTitle("Successfully Updated Tentative Vessel ETD");
            setbasicType("success");
            props.onRequest();
          } else {
            setshow(true);
            setbasicTitle(resp.data.message);
            setbasicType("danger");
          }
          props.onReloadData(true);
        });
    } else {
      setshow(true);
      setbasicTitle("Please Enter Tentative Vessel ETD");
      setbasicType("danger");
    }
  };
  const showdata = (e) => {
    if (e == "detailed view") {
      setdetailedviewcheck(1);
      setsummaryviewcheck(0);
    } else if (e == "summary view") {
      setdetailedviewcheck(0);
      setsummaryviewcheck(1);
    }
  };
  const getUserGridState = () => {
    redirectURL
      .post("/consignments/usergridstates", { screenurl: "/chavesselnew" })
      .then(async (resp) => {
        restoreGridStates(resp.data);
        var grid = resp.data;
        var oCols = fetchGrid(summaryViewcols, grid[0].gridcolumns);

        // setsummaryViewcols(oCols);
      });
  };
  const restoreGridStates = (griddata) => {
    try {
      if (griddata.length > 0) {
        var oCols = fetchGrid(summaryViewcols, griddata[0].gridcolumns);
        setsummaryViewcols(oCols);
      }
    } catch (e) {}
  };
  const onGridState = () => {
    ////console.log(gridApi);

    /*Get  Current Columns State and Store in this.colState */
    const colState = gridApi.current.columnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    const pivotColumns = gridApi.current.columnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */

    window.filterState = gridApi.current.api.getFilterModel();
    gridApi.current.api.setFilterModel(window.filterState);
    getUserGridState();
  };
  var onClickSaveGridState = () => {
    try {
      // //console.log("SaveGrid", gridApi.current.getSortModel())
      window.colState = gridApi.current.columnApi.getColumnState();
      window.groupState = gridApi.current.columnApi.getColumnGroupState();
      // window.sortState = gridApi.current.columnApi.getSortModel();
      // window.filterState = gridApi.current.api.getFilterModel();
      var screenpage = "/chavesselnew";
      let reqparams = {
        gridcolumns: window.colState,
        gridgroup: window.groupState,
        gridcolsort: window.sortState,
        gridcolfilter: window.filterState,
        userId: localStorage.getItem("userid"),
        screenurl: "/chavesselnew",
        screentitle: screenpage,
      };
      redirectURL
        .post("/consignments/saveGridStates", reqparams)
        .then((response) => {
          // if(response.data.status === "200"){
          setshow(true);
          setbasicTitle("Successfully saved grid layout");
          setbasicType("success");
          // setState1({
          // 	screenurl: window.location.pathname,
          // 	screentitle: screenpage
          // })
          setTimeout(() => {
            getUserGridState();
          }, 2000);
          // getUserGridState();
          // }
        })
        .catch(function (e) {});
    } catch (e) {}
  };
  const onclickTab = (e) => {
    if (e == "New") {
      setclassforTab({
        new: 1,
        planned: 0,
        modified: 0,
      });
      setcheckforbulk(true);
      setrowData(tabDataforvessel.vessel_data_new);
      setoriginalData(tabDataforvessel.vessel_data_new);
    } else if (e == "Planned") {
      setclassforTab({
        new: 0,
        planned: 1,
        modified: 0,
      });
      setcheckforbulk(true);
      setrowData(tabDataforvessel.vessel_data_planned);
      setoriginalData(tabDataforvessel.vessel_data_planned);
    } else if (e == "Modified") {
      setclassforTab({
        new: 0,
        planned: 0,
        modified: 1,
      });
      setcheckforbulk(false);
      setrowData(tabDataforvessel.vessel_data_modified);
      setoriginalData(tabDataforvessel.vessel_data_modified);
    }
  };
  const changeHandleDate = (e) => {
    var d = moment(e._d);
    var formattedDate = d.format("DD-MM-YYYY");
    return formattedDate;
  };
  const handleFilterOptions = (e) => {
    if (e.value == "production_date") {
      setcheckForDateFilter(1);
      setcheckinput(0);
    } else {
      setcheckinput(1);
      setcheckForDateFilter(0);
    }
    setselectedval((prev) => ({
      ...prev,
      dropdownval: e,
    }));
  };
  const manualbulkUploadForCha = () => {
    setshowAddForm("slide30");
    setoverly("show-m");
  };
  const handleDatesSelected = (startDate, endDate) => {
    // Access the selected dates in the parent component
    var wo_release_from_date = startDate.format("DD-MM-YYYY");
    var wo_release_to_date = endDate.format("DD-MM-YYYY");
    setWoReleaseDate({
      wo_release_from_date: wo_release_from_date,
      wo_release_to_date: wo_release_to_date,
    });
  };
  const handleInputfilter = (e) => {
    const val = e.target.value.trim().toUpperCase(); // Trim and convert input value to uppercase
    const typeoffield = selectedval.dropdownval.value;
    let filteredData;

    if (typeoffield === "work_order_no") {
      if (val !== "") {
        filteredData = originalData.filter((each) => {
          return each.work_order_no.includes(val);
        });
      } else {
        filteredData = originalData;
      }
    } else {
      // Handle other field types if needed
    }

    setselectedval((prev) => ({
      ...prev,
      inputval: e.target.value,
    }));
    setrowData(filteredData);
  };
  const refreshData = () => {
    setcheckresetfilter(0);
    setrowData(originalData);
    setselectedval((prev) => ({
      ...prev,
      inputval: "",
    }));
  };
  const filterData = () => {
    var typeoffield = selectedval.dropdownval.value;
    var val = selectedval.inputval;
    if (val != "" && val != undefined) {
      if (typeoffield == "ship_to_country") {
        var filteredData = originalData.filter(
          (item) => item.ship_to_country == val
        );
      } else if (typeoffield == "work_order_no") {
        if (val != "" && val != undefined) {
          filteredData = originalData.filter(
            (item) => item.work_order_no == val
          );
        } else {
          filteredData = originalData;
        }
      } else if (typeoffield == "") {
        if (val != "" && val != undefined) {
          filteredData = originalData.filter(
            (item) => item.work_order_no == val
          );
        } else {
          filteredData = originalData;
        }
      } else if (typeoffield == "pod") {
        var filteredData = originalData.filter((item) => item.pod == val);
      } else if (typeoffield == "sku") {
        var filteredData = originalData.filter((item) => item.sku == val);
      } else if (typeoffield == "brand") {
        var filteredData = originalData.filter((item) => item.brand == val);
      } else if (typeoffield == "plant") {
        var filteredData = originalData.filter((item) => item.plant == val);
      } else if (typeoffield == "variant") {
        var filteredData = originalData.filter((item) => item.variant == val);
      }
      //console.log(filteredData);
      setrowData(filteredData);
      setcheckresetfilter(1);
    } else {
      setbasicTitle("Please Enter Value");
      setbasicType("danger");
      setoverly("show-n");
      setshow(true);
    }
  };
  const filterDatafordates = () => {
    var from_date = woReleaseDate.wo_release_from_date;
    var to_date = woReleaseDate.wo_release_to_date;
    if (
      from_date != "" &&
      from_date != undefined &&
      from_date != null &&
      to_date != "" &&
      to_date != undefined &&
      to_date != null
    ) {
      from_date = moment(from_date, "DD-MM-YYYY");
      to_date = moment(to_date, "DD-MM-YYYY");
      var filteredData = originalData.filter((item) => {
        var itemDate = getHyphenDDMMYYYY(item.production_date);
        itemDate = moment(itemDate, "DD-MM-YYYY");
        //console.log(itemDate, "182");
        return itemDate.isBetween(from_date, to_date, null, "[]");
      });
      setrowData(filteredData);
      setRefreshSelected(0);
      setcheckresetfilter(1);
    } else {
      setbasicTitle("Please Select Start Date and End date");
      setbasicType("danger");
      setoverly("show-n");
      setshow(true);
    }
  };
  var hideforplanned;
  if (classForTab.planned == 1) {
    hideforplanned = false;
  } else {
    hideforplanned = true;
  }
  var detailedviewcols = [
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Production Date",
      field: "production_date",
      width: 150,
      filter: true,
      resizable: true,
      // editable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.production_date != "" &&
            params.data.production_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.production_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Ship To Country",
      field: "ship_to_country",
      width: 120,
      filter: true,
      resizable: true,
      // cellRenderer: 'CountryFlagComponent'
    },
    {
      headerName: "Brand",
      field: "brand",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Brand Variant",
      field: "brand_variant",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "CHA Name",
      field: "cha_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant Name",
      field: "plant_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Transporter Name",
      field: "tpt_name",
      width: 200,
      filter: true,
      resizable: true,
    },
    {
      headerName: "40ft Containers",
      field: "ft40_containers",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "20ft Containers",
      field: "ft20_containers",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Trucks",
      field: "truck_and_courier",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "POD",
      field: "pod",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant",
      field: "plant",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "PO NO",
      field: "po_no",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quotation",
      field: "quotation",
      width: 130,
      filter: true,
      resizable: true,
    },
    // {
    //     headerName: "",
    //     field: "",
    //     width: 80,
    //     filter: true, resizable: true,
    //     // cellRenderer: 'SubmitBtn'
    //     cellRendererFramework: SubmitBtn

    // },

    {
      headerName: "Latest Shipment Date",
      field: "latest_shipment_date",
      width: 160,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.latest_shipment_date != "" &&
            params.data.latest_shipment_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.latest_shipment_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Expected Payment Clearance By",
      field: "expected_payment_clearance_by",
      width: 220,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.expected_payment_clearance_by != "" &&
            params.data.expected_payment_clearance_by != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "3rd Party Inspection Req",
      field: "third_party_inspection_req",
      width: 190,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Port Of Loading",
      field: "port_of_loading",
      width: 190,
      filter: true,
      resizable: true,
    },
    {
      headerName: "VIN Type Description",
      field: "vin_type_description",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "VIN Type",
      field: "vin_type",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "HAZ Classification",
      field: "haz_classification",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Assy Config Type",
      field: "assy_config_type",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Payment Term",
      field: "payment_term",
      valueGetter: function (params) {
        try {
            //console.log(ptd, "ptd");
            //console.log(params.data.payment_term, "params.data.payment_term");
            if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                if (temp)
                    return temp.payment_term + " - " + temp.description
                else
                    return params.data.payment_term
            }
            else {
                return "Not Available";
            }
        }
        catch (e) { }
      },
      width: 350,
      filter: true,
      resizable: true,
    },
  ];
  const onsubmitdata = (e) => {
    e.preventDefault();
    var tentative_gate_open_date =
      document.getElementById("gate_open_datetime").value;
    var tentative_cut_off_date =
      document.getElementById("port_cutoff_date").value;
    var data = [
      {
        freight_forwarder: manualUploadDetails.freight_forwarder.value,
        work_order_no: selected_row.work_order_no,
        tentative_shipping_line:
          manualUploadDetails.tentative_shipping_line.value,
        tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
        tentative_gate_open_date: tentative_gate_open_date,
        tentative_cut_off_date: tentative_cut_off_date,
        no_of_free_days: manualUploadDetails.no_of_free_days,
        remarks: manualUploadDetails.remarks_by_cha,
      },
    ];
    var rparams = {
      data: data,
    };
    //console.log(rparams, "rparams");
    if (
      manualUploadDetails.tentative_vessel_etd != "" &&
      manualUploadDetails.tentative_vessel_etd != undefined &&
      tentative_gate_open_date != "" &&
      tentative_gate_open_date != undefined &&
      tentative_cut_off_date != "" &&
      tentative_cut_off_date != undefined &&
      tentative_gate_open_date != "__-__-____ __:__" &&
      tentative_cut_off_date != "__-__-____ __:__" &&
      manualUploadDetails.freight_forwarder != "" &&
      manualUploadDetails.freight_forwarder != undefined
    ) {
      redirectURL
        .post("/consignments/uploadBulkuploadforCHAdata", rparams)
        .then((resp) => {
          //console.log(resp.data, "176");
          if (resp.data.status == "Success") {
            setshow(true);
            setbasicTitle(
              "CHA Vessel Availability Details has been successfully updated!"
            );
            setbasicType("success");
            setloadshow("show-n");
            setoverly("show-n");
            setshowAddForm("");
            refreshDataNow();
            setmanualUploadDetails({});
            setshowAddForm("");
            setmanualUploadDetails({
              freight_forwarder: "",
              wrno: "",
              tentative_shipping_line: "",
              tentative_vessel_etd: null,
              tentative_gate_open_date: "",
              tentative_cut_off_date: "",
              no_of_free_days: "",
              remarks_by_cha: "",
            });
            $("#tentative_cut_off_date").val("");
            $("#tentative_gate_open_date").val("");
            $("#tentative_vessel_etd").val("");
            setshowAddForm("");
          } else {
            setshow(true);
            setbasicTitle(resp.data.message);
            setbasicType("danger");
            setloadshow("show-n");
            // setoverly('show-n')
            // setshowAddForm('')
          }
        });
      setReloadData(true);
    } else {
      setshow(true);
      setbasicTitle("Please Enter All Manditory Fields!");
      setbasicType("danger");
      setloadshow("show-n");
      setoverly("show-n");
    }
  };
  const onsubmitdata1 = (e) => {
    e.preventDefault();
    //console.log("selected_row",selected_row);
    var data = [
          {
            freight_forwarder: manualUploadDetails.freight_forwarder.value,
            work_order_no: selected_row.work_order_no,
            tentative_shipping_line:
              manualUploadDetails.tentative_shipping_line.value,
            tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
            tentative_gate_open_date:
              manualUploadDetails.tentative_gate_open_date,
            tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
            no_of_free_days: manualUploadDetails.no_of_free_days,
            remarks: manualUploadDetails.remarks_by_cha,
            cha_vessel_modified: 1,
          },
        ];
    // if (selected_row.update_ack == 1) {
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line:
    //         manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_modified: 1,
    //     },
    //   ];
    // }
    // else if (selected_row.cha_changes_before_container_pickup == 1 || selected_row.pod_changes_before_container_pickup == 1){
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line:
    //         manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_modified: 1,
    //     },
    //   ];
    // }
    // else if (selected_row.ship_to_country_changes_before_container_pickup == 1){
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line:
    //         manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_modified: 1,
    //     },
    //   ];
    // }

    //  else {
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line: manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_planned: 1,
    //     },
    //   ];
    // }
    var rparams = {
      data: data,
      row: selected_row,
    };
    redirectURL.post("/consignments/updatedata", rparams).then((resp) => {
      //console.log(resp.data, "176");
      if (resp.data.status == "Success") {
        setshow(true);
        setbasicTitle(
          "CHA Vessel Availability Details has been successfully updated!"
        );
        setbasicType("success");
        setclassforTab(
          {
              planned: 0,
              new: 1,
              modified: 0,
          }
        )
        setloadshow("show-n");
        setoverly("show-n");
        refreshDataNow();
        setmanualUploadDetails({});
        setmanualUploadDetails({
          freight_forwarder: "",
          wrno: "",
          tentative_shipping_line: "",
          tentative_vessel_etd: null,
          tentative_gate_open_date: "",
          tentative_cut_off_date: "",
          no_of_free_days: "",
          remarks_by_cha: "",
        });
        $("#tentative_cut_off_date").val("");
        $("#tentative_gate_open_date").val("");
        $("#tentative_vessel_etd").val("");
        setshowAddForm1("");
      } else {
        setshow(true);
        setbasicTitle(resp.data.message);
        setbasicType("danger");
        setloadshow("show-n");
        // setoverly('show-n')
        // setshowAddForm('')
      }
    });
    setReloadData(true);
  };
  const onsubmitdata2 = (e) => {
    e.preventDefault();
    //console.log("selected_row",selected_row);
    var data = [
          {
            freight_forwarder: manualUploadDetails.freight_forwarder.value,
            work_order_no: selected_row.work_order_no,
            tentative_shipping_line:
              manualUploadDetails.tentative_shipping_line.value,
            tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
            tentative_gate_open_date:
              manualUploadDetails.tentative_gate_open_date,
            tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
            no_of_free_days: manualUploadDetails.no_of_free_days,
            remarks: manualUploadDetails.remarks_by_cha,
            cha_vessel_planned: 1,
          },
        ];
    // if (selected_row.update_ack == 1) {
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line:
    //         manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_modified: 1,
    //     },
    //   ];
    // }
    // else if (selected_row.cha_changes_before_container_pickup == 1 || selected_row.pod_changes_before_container_pickup == 1){
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line:
    //         manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_modified: 1,
    //     },
    //   ];
    // }
    // else if (selected_row.ship_to_country_changes_before_container_pickup == 1){
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line:
    //         manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_modified: 1,
    //     },
    //   ];
    // }

    //  else {
    //   var data = [
    //     {
    //       freight_forwarder: manualUploadDetails.freight_forwarder.value,
    //       work_order_no: selected_row.work_order_no,
    //       tentative_shipping_line: manualUploadDetails.tentative_shipping_line.value,
    //       tentative_vessel_etd: manualUploadDetails.tentative_vessel_etd,
    //       tentative_gate_open_date:
    //         manualUploadDetails.tentative_gate_open_date,
    //       tentative_cut_off_date: manualUploadDetails.tentative_cut_off_date,
    //       no_of_free_days: manualUploadDetails.no_of_free_days,
    //       remarks: manualUploadDetails.remarks_by_cha,
    //       cha_vessel_planned: 1,
    //     },
    //   ];
    // }
    var rparams = {
      data: data,
      row: selected_row,
    };
    redirectURL.post("/consignments/updatedataplanned", rparams).then((resp) => {
      //console.log(resp.data, "176");
      if (resp.data.status == "Success") {
        setshow(true);
        setbasicTitle(
          "CHA Vessel Availability Details has been successfully updated!"
        );
        setbasicType("success");
        setclassforTab(
          {
              planned: 0,
              new: 1,
              modified: 0,
          }
        )
        setloadshow("show-n");
        setoverly("show-n");
        refreshDataNow();
        setmanualUploadDetails({});
        setmanualUploadDetails({
          freight_forwarder: "",
          wrno: "",
          tentative_shipping_line: "",
          tentative_vessel_etd: null,
          tentative_gate_open_date: "",
          tentative_cut_off_date: "",
          no_of_free_days: "",
          remarks_by_cha: "",
        });
        $("#tentative_cut_off_date").val("");
        $("#tentative_gate_open_date").val("");
        $("#tentative_vessel_etd").val("");
        setshowAddForm2("");
      } else {
        setshow(true);
        setbasicTitle(resp.data.message);
        setbasicType("danger");
        setloadshow("show-n");
        // setoverly('show-n')
        // setshowAddForm('')
      }
    });
    setReloadData(true);
  };
  var changeOrderFileHandlerFcrBulk1 = async (e) => {
    setfile(e.target.files[0]);
    var validCols = validcols;
    //console.log(validCols, "validCols");
    //console.log(e.target.files[0], "e.target.files[0]");
    var verifyCols = verifyUploadFileColumns(validCols, e.target.files[0])
      .then((result) => {
        //console.log(result,"11134");
        setvalidbulkfile(result);
      })
      .catch((error) => {
        seterrormsg(error);
      });
    var csvdata = await BulkForceFileHandler(e, bulkUploadFields);
    //console.log(csvdata, "1234");
    setcsvcontent(csvdata);
    setshowGrid(true);
  
    setrowData1(csvdata);
  };
  var changeOrderFileHandlerFcrBulk = async (e) => {
    setfile(e.target.files[0]);
    var validCols = [
      "Work Order No",
      "Freight Forwarder",
      "No Of Free Days",
      "Tentative shipping line",
      "Tentative vessel ETD",
      "Tentative Gate Open Date",
      "Tentative cut off date",
      "Remarks(by CHA)",
    ];
    var verifyCols = verifyUploadFileColumns(validCols, e.target.files[0])
      .then((result) => {
        //console.log(result,"00000");
        setvalidbulkfile(result);
      })
      .catch((error) => {
        seterrormsg(error);
      });
    var csvdata = await BulkForceFileHandler(e, [
      { name: "Work Order No", inputName: "work_order_no", required: true },
      {
        name: "Freight Forwarder",
        inputName: "freight_forwarder",
        required: true,
      },
      { name: "No Of Free Days", inputName: "no_of_free_days", required: true },
      {
        name: "Tentative shipping line",
        inputName: "tentative_shipping_line",
        required: true,
      },
      {
        name: "Tentative vessel ETD",
        inputName: "tentative_vessel_etd",
        required: true,
      },
      {
        name: "Tentative Gate Open Date",
        inputName: "tentative_gate_open_date",
        required: true,
      },
      {
        name: "Tentative cut off date",
        inputName: "tentative_cut_off_date",
        required: true,
      },
      { name: "Remarks(by CHA)", inputName: "remarks_by_cha", required: true },
    ]);
    //console.log(csvdata, "1234");
    setshowGrid(true);
    setcsvcontent(csvdata);
    setrowData1(csvdata);
  };
  const onSelectWON = (e) => {
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: e.value, value: e.value },
    }));
  };
  const onCloseModal = () => {
    setopen(false);
    setsliderForBulkupload("");
    setshowGrid(false);
    document.getElementById("uploadfile").value = null;
    setoverly("show-n");
  };
  const onSubmitOrderBulkUpload2 = (event) => {
    event.preventDefault();
    setcheckforuploaddata(0)
    setshowGrid(false);

    //console.log("1896");
    if (file !== "") {
      //console.log("1897");
      var data = [];
      if (csvcontent.length > 0) {
        csvcontent.map((e) => {
          if (e.work_order_no != "" && e.work_order_no != undefined) {
            data.push(e);
          }
        });
        let rparams;
        if (checkforuploaddata==13){
          rparams = data;
        }else{
          rparams = {
            data: data,
          };
        }
        
        //console.log(data, "csv");
        //console.log("check");
        setloadshow("show-m");
        if (validbulkfile == true) {
          //console.log("1912");
          redirectURL.post(`/consignments/${api}`, rparams).then((resp) => {
            if (resp.data.status === "Success") {
              setshow(true);
              setbasicTitle("Successfully Uploaded");
              setbasicType("success");
              setloadshow("show-n");
              setoverly("show-n");
              setsliderForBulkupload("");
              setshowGrid(false);
              getCHAdata();
              refreshDataNow();
              setdatarefresh(1);
            } else {
              setshow(true);
              setbasicTitle(resp.data.message);
              setbasicType("danger");
              setloadshow("show-n");
              setoverly("show-n");
            }
            //console.log(resp.data);
          });
        } else {
          setshow(true);
          setbasicTitle("Please Upload Valid Columns");
          setbasicType("danger");
          setloadshow("show-n");
          setoverly("show-n");
        }
      }
    }
  };
  const onSubmitOrderBulkUpload1 = (event) => {
    event.preventDefault();
    if (file !== "") {
      var data = [];
      if (csvcontent.length > 0) {
        csvcontent.map((e) => {
          if (e.work_order_no != "" && e.work_order_no != undefined) {
            data.push(e);
          }
        });
        var rparams = {
          data: data,
        };
        //console.log(data, "csv");
        //console.log("check");
        setloadshow("show-m");
        if (validbulkfile == true) {
          redirectURL.post("/consignments/updatedata", rparams).then((resp) => {
            if (resp.data.status === "Success") {

              setshow(true);
              setbasicTitle("Successfully Uploaded");
              setbasicType("success");
              setloadshow("show-n");
              setoverly("show-n");
              setsliderForBulkupload("");
              document.getElementById("uploadfile").value = null;
              getCHAdata();
            } else {
              setshow(true);
              setbasicTitle(resp.data.message);
              setbasicType("danger");
              setloadshow("show-n");
              setoverly("show-n");
            }
            //console.log(resp.data);
          });
        } else {
          setshow(true);
          setbasicTitle("Please Upload Valid Columns");
          setbasicType("danger");
          setloadshow("show-n");
          setoverly("show-n");
        }
      }
    }
  };
  const onSubmitOrderBulkUpload = (event) => {
    event.preventDefault();
    if (file !== "") {
      var data = [];
      if (csvcontent.length > 0) {
        csvcontent.map((e) => {
          if (e.work_order_no != "" && e.work_order_no != undefined) {
            data.push(e);
          }
        });
        var rparams = {
          data: data,
        };
        //console.log(data, "csv");
        //console.log("check");
        setloadshow("show-m");
        if (validbulkfile == true) {
          redirectURL
            .post("/consignments/uploadBulkuploadforCHAdata", rparams)
            .then((resp) => {
              if (resp.data.status === "Success") {
                setshow(true);
                setbasicTitle("Successfully Uploaded");
                setbasicType("success");
                setloadshow("show-n");
                setoverly("show-n");
                setsliderForBulkupload("");
                document.getElementById("uploadfile").value = null;
                getCHAdata();
                setshowGrid(false);
              } else {
                setshow(true);
                setbasicTitle(resp.data.message);
                setbasicType("danger");
                setloadshow("show-n");
                setoverly("show-n");
              }
              //console.log(resp.data);
            });
        } else {
          setshow(true);
          setbasicTitle("Please Upload Valid Columns");
          setbasicType("danger");
          setloadshow("show-n");
          setoverly("show-n");
        }
      }
    }
  };
  const onClickHide = () => {
    setsliderForBulkupload("");
    document.getElementById("uploadfile").value = null;
    setoverly("show-n");
  };
  const onClickHideManualUpload = () => {
    setshowAddForm("");
    setoverly("show-n");
    setmanualUploadDetails((prev) => ({
      ...prev,
      freight_forwarder: "",
      tentative_vessel_etd: null,
      wrno: { label: "", value: "" },
      tentative_shipping_line: "",
      tentative_gate_open_date: null,
      tentative_cut_off_date: null,
      no_of_free_days: "",
      remarks_by_cha: "",
    }));
  };
  const onClickHideManualUpload1 = () => {
    setshowAddForm1("");
    setoverly("show-n");
    setmanualUploadDetails((prev) => ({
      ...prev,
      freight_forwarder: "",
      tentative_vessel_etd: null,
      wrno: { label: "", value: "" },
      tentative_shipping_line: "",
      tentative_gate_open_date: null,
      tentative_cut_off_date: null,
      no_of_free_days: "",
      remarks_by_cha: "",
    }));
  };
  const onClickHideManualUpload2 = () => {
    setshowAddForm2("");
    setoverly("show-n");
    setmanualUploadDetails((prev) => ({
      ...prev,
      freight_forwarder: "",
      tentative_vessel_etd: null,
      wrno: { label: "", value: "" },
      tentative_shipping_line: "",
      tentative_gate_open_date: null,
      tentative_cut_off_date: null,
      no_of_free_days: "",
      remarks_by_cha: "",
    }));
  };
  const onClickHideAll = () => {
    setloadshow("show-n");
  };
  var closeAlert = () => {
    setshow(false);
    setloadshow("show-n");
  };
  var onclickbulkupload = () => {
    setsliderForBulkupload("slider-translate");
    setopen(true);
    setoverly("show-m");
    if (isClicked.uploadDo == true) {
      setcheckforuploaddata(2);
      setselectedfileurl(chaBasedUploadDoData);
      // uploadBulkData()
    } else if (isClicked.at_plant == true) {
      setcheckforuploaddata(5);
      setselectedfileurl(chabasedAtPlantdetails);
    } else if (isClicked.container_reporting == true) {
      setcheckforuploaddata(13)
      setselectedfileurl(chabasedleodate);
    } else if (isClicked.bill_of_lading == true) {
      setcheckforuploaddata(3);
      setselectedfileurl(chabasedBillofladingdata);
    } else if (isClicked.InTransitToPort == true) {
      setcheckforuploaddata(11);
      setselectedfileurl(chabasedReportingTime);
    } else if (isClicked.gate_in == true){
      setselectedfileurl(chaBasedGateIn);
      setcheckforuploaddata(4)
    }else if (isClicked.shipping_onboard == true){
      setselectedfileurl(chaBasedShippingOnboard);
      setcheckforuploaddata(10)
    }
  };
  var uploadBulkData = (
    validCols,
    bulkUploadFields,
    apiforupload,
    columndefs
  ) => {
    //console.log("2095", columndefs);
    setvalidcols(validCols);
    setbulkUploadFields(bulkUploadFields);
    setAPI(apiforupload);
    setcsvfilename(csvfilename);
    setcolumnsforbulk(columndefs);
  };
  const onClickCounters = (props) => {
    if (props == "all") {
      setcheck(8);
      setIsClicked({ all: true });
    } else if (props == "vesselAvailability") {
      //console.log("707");
      setcheck(1);
      setIsClicked({ vessel_avail: true });
      setcheckforbulk(true);
    } else if (props == "uploadDO") {
      setcheck(2);
      setIsClicked({ uploadDo: true });
      setcheckforbulk(true);
      setAPI("uploadBulkuploadforUploadDO");
      uploadBulkData();
    } else if (props == "InTransitToPort") {
      setcheck(3);
      setIsClicked({ InTransitToPort: true });
      setcheckforbulk(true);
    } else if (props == "container_reporting") {
      setcheck(6);
      setIsClicked({ container_reporting: true });
      setcheckforbulk(true);
    } else if (props == "gate_in") {
      setcheck(4);
      setIsClicked({ gate_in: true });
      setcheckforbulk(true);
    } else if (props == "at_plant") {
      setcheck(5);
      setIsClicked({ at_plant: true });
      setcheckforbulk(true);
      uploadBulkData();
    } else if (props == "closed") {
      setcheck(7);
      setIsClicked({ closed: true });
      setcheckforbulk(false);
    } else if (props == "modified") {
      setcheck(9);
      setIsClicked({ modified: true });
    } else if (props == "buffer") {
      setcheck(9);
      setIsClicked({ buffer: true });
      setcheckforbulk(false);
    } else if (props == "bill_of_lading") {
      setcheck(11);
      setIsClicked({ bill_of_lading: true });
      setcheckforbulk(true);
    } else if (props == "shipping_onboard") {
      setcheck(10);
      setIsClicked({ shipping_onboard: true });
      setcheckforbulk(true);
      uploadBulkData();

    }
  };
  const refreshDataNow = (params) => {
    // //console.log("paramss", params)
    setloadshow("show-m");
    onClickCounters(params);
    getCHAdata();
    getbuffercha();
    props.refresNow("chdata");
  };
  var sDate = $("#wo_order_date").val();
  var newCount = tabDataforvessel.vessel_data_new;
  var plannedCount = tabDataforvessel.vessel_data_planned;
  var modifiedCount = tabDataforvessel.vessel_data_modified;
  // //console.log(plannedCount,'958')
  var updatecha = {
    headerName: "Update",
    field: "update_cha_vessel_availability",
    width: 80,
    pinned: "left",
    cellClass: "left-align",
    cellRenderer: "UpdateCHAVesselAvailability",
  };
  var updatecha1 = {
    headerName: "Update",
    field: "update_cha_vessel_availability1",
    width: 80,
    pinned: "left",
    cellStyle: { textAlign: "left" },
    cellRenderer: "UpdateCHAVesselAvailability1",
  };
 
  var acknowledgebtn = {
    headerName: "",
    field: "acknowledge_btn_component",
    width: 120,
    filter: true,
    resizable: true,
    pinned: "left",
    cellRendererFramework: AcknowledgeBtnComponent,
  };
  var acknowledgebtn1 = {
    headerName: "",
    field: "update_acknowledge_btn_component",
    width: 120,
    filter: true,
    resizable: true,
    pinned: "left",
    cellRendererFramework: UpdateAcknowledgeBtnComponent,
  };
  var acknowledgebtn2 = {
    headerName: "",
    field: "update_acknowledge_btn_component",
    width: 120,
    filter: true,
    resizable: true,
    pinned: "left",
    cellRendererFramework: UpdateAcknowledgeBtnComponent1,
  };

  var tentativeUpdateFields = [
    {
      headerName: "Tentative Vessel ETD",
      field: "tentative_vessel_etd",
      width: 170,
      filter: true,
      resizable: true,
      //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
      valueGetter: function (params) {
        try {
          if (
            params.data.tentative_vessel_etd != "" &&
            params.data.tentative_vessel_etd != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Tentative Gate Open Date",
      field: "tentative_gate_open_date",
      width: 200,
      filter: true,
      resizable: true,
      editable: true,
      //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
      cellEditor: DateEditor,
      valueGetter: function (params) {
        try {
          if (
            params.data.tentative_gate_open_date != "" &&
            params.data.tentative_gate_open_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
      comparator: dateComparator1,
    },
    {
      headerName: "Tentative Gate Open Time",
      field: "",
      width: 200,
      filter: true,
      resizable: true,
      editable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.tentative_gate_open_date != "" &&
            params.data.tentative_gate_open_date != undefined
          ) {
            return getHHMM(params.data.tentative_gate_open_date);
          } else {
            return "";
          }
        } catch (e) {}
      },
    },

    {
      headerName: "Tentative Cut Off Date ",
      field: "tentative_cut_off_date",
      width: 170,
      filter: true,
      resizable: true,
      editable: true,
      //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
      cellEditor: DateEditor,
      valueGetter: function (params) {
        try {
          if (
            params.data.tentative_cut_off_date != "" &&
            params.data.tentative_cut_off_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
      comparator: dateComparator1,
    },
    {
      headerName: "Tentative Cut Off Time",
      field: "",
      width: 190,
      filter: true,
      resizable: true,
      editable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.tentative_cut_off_date != "" &&
            params.data.tentative_cut_off_date != undefined
          ) {
            return getHHMM(params.data.tentative_cut_off_date);
          } else {
            return "";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Shipping Line",
      field: "tentative_shipping_line",
      width: 250,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Remarks By CHA",
      field: "remarks_by_cha",
      width: 170,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Freight Forwarder",
      field: "freight_forwarder",
      width: 250,
      filter: true,
      resizable: true,
    },
    {
      headerName: "No Of Free Days",
      field: "no_of_free_days",
      width: 160,
      filter: true,
      resizable: true,
    },
  ];
  var columns1 = [...summaryViewcols];
  if (classForTab.new == 1) {
    columns1.push(updatecha);
    detailedviewcols.push(updatecha);
  }
  if (classForTab.planned == 1) {
    columns1.push(...tentativeUpdateFields, acknowledgebtn2 );
    detailedviewcols.push(...tentativeUpdateFields,acknowledgebtn2);
  }
  if (classForTab.modified == 1) {
    columns1.push(acknowledgebtn, acknowledgebtn1, ...tentativeUpdateFields);
    detailedviewcols.push(
      acknowledgebtn,
      acknowledgebtn1,
      ...tentativeUpdateFields
    );
  }
  const columnwithDefs = summaryviewcheck == 1 ? columns1 : detailedviewcols;
  columnwithDefs.forEach((e) => {
    e.cellClass = function (params) {
      const fieldModified = `${e.field}_modified`;
      if (params.data && params.data[fieldModified] === 1) {
        return "bgColorDangerDark";
      } else {
        return null;
      }
    };
  });

  function restrictToNumbers(event) {
    // Remove non-numeric characters from the input value
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
  }
  const refreshData1 = () => {
    setcheckresetfilter(0);
    setrowData(originalData);
    setRefreshSelected(1);
    //console.log("refreshclickfunc");
  };

  const calculateGridWidth = () => {
    if (!columnsforbulk) return '10rem'; 
    const columnWidth = 133.8; // Assuming each column has an approximate width of 100px
    const totalWidth = columnsforbulk.length * columnWidth;
    return `${totalWidth}px`;
  };

  useEffect(() => {
    // Update grid width based on the number of columns
    setGridWidth(calculateGridWidth());
  }, [columnsforbulk]);

  return (
    <div class="container-fluid" style={{ padding: "10px" }}>
      {props.allcounter == 1 ? (
        ""
      ) : (
        <div className="form-group d-flex justify-content-between  mt-2 pl-0 mb-0">
          <h5 className="heading ml-50p">
            <a
              href="/exportoperationscontroltower"
              className="txt-dec-none clr333"
            ></a>
            CHA Activities
          </h5>
          {/* {checkforbulk ? (
            <button
              type="button"
              className="btn-bulkupload mr-20p"
              style={{ fontSize: "0.8rem" }}
              onClick={() => onclickbulkupload()}
            >
              <img
                src={require("../../assets/images/upload.png")}
                alt="Icon"
                className="mr-1"
              />
              Bulk Upload
            </button>
          ) : (
            ""
          )} */}
        </div>
      )}
      <div
        className={props.allcounter == 1 ? "" : "card "}
        style={
          props.allcounter === 1 ? {} : { padding: "25px", margin: "20px" }
        }
      >
        {props.allcounter == 1 ? (
          ""
        ) : (
          <span style={{ textAlign: "end", color: "red", fontSize: "12px" }}>
            * New / Modified
          </span>
        )}
        {/* {props.allcounter==1?<p style={{textAlign:'end'}}>New / Modified</p>} */}
        <div className="row bghighliter" style={{ height: "20%" }}>
          <SweetAlert
            show={show}
            type={basicType}
            title={basicTitle}
            onConfirm={closeAlert}
          ></SweetAlert>
          {/* <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.vessel_avail ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("vesselAvailability")}
          >
            <img
              src={
                isClicked.vessel_avail
                  ? require("../../assets/images/new-white.png")
                  : require("../../assets/images/new.png")
              }
              alt="Icon"
              style={{ height: "25px", margin: "8px" }}
            />
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.vessel_avail ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Vessel Availability
              </h5>
              <div>
                {counters.vessel_avai != "" && counters.vessel_avai != undefined
                  ? counters.vessel_avai.length
                  : 0}{" "}
                /{" "}
                {tabDataforvessel.vessel_data_modified != "" &&
                tabDataforvessel.vessel_data_modified != undefined
                  ? tabDataforvessel.vessel_data_modified.length
                  : 0}
              </div>
            </div>
          </div> */}
          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.uploadDo ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("uploadDO")}
          >
            <img
              src={
                isClicked.uploadDo
                  ? require("../../assets/images/new-white.png")
                  : require("../../assets/images/new.png")
              }
              alt="Icon"
              style={{ height: "25px", margin: "8px" }}
            />
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.uploadDo ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Upload DO
              </h5>
              <div>
                {tabsforuploadDo.cha_do_new != "" &&
                tabsforuploadDo.cha_do_new != undefined
                  ? tabsforuploadDo.cha_do_new.length
                  : 0}{" "}
                /{" "}
                {tabsforuploadDo.cha_do_modified != "" &&
                tabsforuploadDo.cha_do_modified != undefined
                  ? tabsforuploadDo.cha_do_modified.length
                  : 0}
              </div>
            </div>
          </div>
          {/* <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.at_plant ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("at_plant")}
          >
            <img
              src={
                isClicked.at_plant
                  ? require("../../assets/images/plantwhiteicon.png")
                  : require("../../assets/images/plantupdatesicon.png")
              }
              alt="Icon"
              style={{ height: "22px", margin: "8px" }}
            />
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.at_plant ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Update
                <br /> Shipping Bill
              </h5>
              <div>
                {tabsForAtPlant.at_plant_new != "" &&
                tabsForAtPlant.at_plant_new != undefined
                  ? tabsForAtPlant.at_plant_new.length
                  : 0}{" "}
                /{" "}
                {tabsForAtPlant.at_plant_modified != "" &&
                tabsForAtPlant.at_plant_modified != undefined
                  ? tabsForAtPlant.at_plant_modified.length
                  : 0}
              </div>
            </div>
          </div> */}
          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.InTransitToPort ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("InTransitToPort")}
          >
            <img
              src={
                isClicked.InTransitToPort
                  ? require("../../assets/images/CHAwhiteicon.png")
                  : require("../../assets/images/chaupdatesicon.png")
              }
              alt="Icon"
              style={{ height: "14px", margin: "7px" }}
            />
            <div
              className="txt-center"
              style={{
                padding: "3px",
                color: isClicked.InTransitToPort ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Update Reporting Time
              </h5>
              <div>
                {tabsforcontainerReport.container_report_new != "" &&
                tabsforcontainerReport.container_report_new != undefined
                  ? tabsforcontainerReport.container_report_new.length
                  : 0}{" "}
                /{" "}
                {tabsforcontainerReport.container_report_modified != "" &&
                tabsforcontainerReport.container_report_modified != undefined
                  ? tabsforcontainerReport.container_report_modified.length
                  : 0}
              </div>
            </div>
          </div>
          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.container_reporting ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("container_reporting")}
          >
            <img
              src={
                isClicked.container_reporting
                  ? require("../../assets/icons/container-white.png")
                  : require("../../assets/icons/containerReport.png")
              }
              alt="Icon"
              style={{ height: "25px", margin: "8px" }}
            />
            <div
              className="txt-center"
              style={{
                padding: "3px",
                color: isClicked.container_reporting ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Update LEO Date
              </h5>
              <div>
                {tabForLeo.leo_new != "" && tabForLeo.leo_new != undefined
                  ? tabForLeo.leo_new.length
                  : 0}{" "}
                /{" "}
                {tabForLeo.leo_modified != "" &&
                tabForLeo.leo_modified != undefined
                  ? tabForLeo.leo_modified.length
                  : 0}
              </div>
            </div>
          </div>

          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.gate_in ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("gate_in")}
          >
            <img
              src={
                isClicked.gate_in
                  ? require("../../assets/images/gatein-white.png")
                  : require("../../assets/images/gate_in.png")
              }
              alt="Icon"
              style={{ height: "28px", margin: "8px" }}
            />
            <div
              className="txt-center"
              style={{
                padding: "3px",
                color: isClicked.gate_in ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Gate-In To Port
              </h5>
              <div>
                {tabForGateInPort.gate_new != "" &&
                tabForGateInPort.gate_new != undefined
                  ? tabForGateInPort.gate_new.length
                  : 0}{" "}
                /{" "}
                {tabForGateInPort.gate_modified != "" &&
                tabForGateInPort.gate_modified != undefined
                  ? tabForGateInPort.gate_modified.length
                  : 0}
              </div>
            </div>
          </div>
          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.shipping_onboard ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("shipping_onboard")}
          >
            <img
              src={
                isClicked.shipping_onboard
                  ? require("../../assets/images/gatein-white.png")
                  : require("../../assets/images/plantupdatesicon.png")
              }
              alt="Icon"
              style={{ height: "28px", margin: "8px" }}
            />
            <div
              className="txt-center"
              style={{
                padding: "3px",
                color: isClicked.shipping_onboard ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Shipping Onboard
              </h5>
              <div>
                {tabForShippingOnboard.onboard_new != "" &&
                tabForShippingOnboard.onboard_new != undefined
                  ? tabForShippingOnboard.onboard_new.length
                  : 0}{" "}
                /{" "}
                {tabForShippingOnboard.onboard_modified != "" &&
                tabForShippingOnboard.onboard_modified != undefined
                  ? tabForShippingOnboard.onboard_modified.length
                  : 0}
              </div>
            </div>
          </div>
          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.bill_of_lading ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("bill_of_lading")}
          >
            <img
              src={
                isClicked.bill_of_lading
                  ? require("../../assets/images/new-white.png")
                  : require("../../assets/images/new.png")
              }
              alt="Icon"
              style={{ height: "28px", margin: "8px" }}
            />
            <div
              className="txt-center"
              style={{
                padding: "3px",
                color: isClicked.bill_of_lading ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Bill of Lading
              </h5>
              <div>
                {tabForBillOfLading.bill_new != "" &&
                tabForBillOfLading.bill_new != undefined
                  ? tabForBillOfLading.bill_new.length
                  : 0}{" "}
                /{" "}
                {tabForBillOfLading.bill_modified != "" &&
                tabForBillOfLading.bill_modified != undefined
                  ? tabForBillOfLading.bill_modified.length
                  : 0}
              </div>
            </div>
          </div>
          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.closed ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("closed")}
          >
            <img
              src={
                isClicked.closed == 1
                  ? require("../../assets/images/all-white.png")
                  : require("../../assets/images/all.png")
              }
              alt="Icon"
              style={{ height: "21px", margin: "8px" }}
            />

            {/* <img src={isClicked.closed ? require("../../assets/images/closed-white.png") : require("../../assets/icons/closed_clr.png")} alt="Icon" style={{ height: '20px', margin: '8px' }} /> */}
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.closed ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Completed
              </h5>
              <div>{completedCount}</div>
            </div>
          </div>
          <div
            className="counterDiv"
            style={{
              marginTop: "5px",
              background: isClicked.buffer ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("buffer")}
          >
            {/* <img src={isClicked.buffer?require("../../assets/images/closed-white.png"):require("../../assets/icons/closed_clr.png")} alt="Icon" style={{ height: '20px', margin: '8px' }} /> */}
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.buffer ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Buffer Yard
              </h5>
              <div>{counters.buffer.length}</div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
        <div className="form-group">
        {checkforbulk ? (
            <button
              type="button"
              className="btn-bulkupload mr-20p"
              style={{ fontSize: "0.8rem" }}
              onClick={() => onclickbulkupload()}
            >
              <img
                src={require("../../assets/images/upload.png")}
                alt="Icon"
                className="mr-1"
              />
              Bulk Upload
            </button>
          ) : (
            ""
          )}
        </div>
        </div>
        {check == 1 ? (
          <div
            className="card-summary"
            style={{ marginLeft: "10px", marginRight: "5px" }}
          >
            {check == 1 ? (
              <div className="d-flex flex-row" style={{ margin: "3px" }}>
                <div className="d-flex flex-row">
                  <button
                    className="d-flex flex-row justify-content-center align-items-center px-3"
                    style={{
                      background: summaryviewcheck == 1 ? "#0664AE" : "",
                      // color: state.isTileView ? "": "#0664AE",
                      borderTopLeftRadius: "11px",
                      borderBottomLeftRadius: "11px",
                      border: "1px solid #0664AE",
                      height: "38px",
                      padding: "22px",
                    }}
                    onClick={() => showdata("summary view")}
                  >
                    <div
                      style={{
                        color: summaryviewcheck == 1 ? "white" : "#0664AE",
                        fontSize: "12px",
                        fontWeight: 700,
                        marginLeft: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      Summary View
                    </div>
                  </button>

                  <button
                    className="d-flex flex-row justify-content-center align-items-center px-3"
                    style={{
                      background: detailedviewcheck == 1 ? "#0664AE" : "",
                      borderTopRightRadius: "11px",
                      borderBottomRightRadius: "11px",
                      border: "1px solid #0664AE",
                      height: "38px",
                      padding: "22px",
                    }}
                    onClick={() => showdata("detailed view")}
                  >
                    <div
                      style={{
                        color: detailedviewcheck == 1 ? "white" : "#0664AE",
                        fontSize: "12px",
                        fontWeight: 700,
                        marginLeft: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      Detailed View
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {check == 1 ? (
              <div className="margin-view">
                <Select
                  closeMenuOnSelect={true}
                  name="filterModeType"
                  className="border-radius-0"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      fontSize: "0.8rem",
                      color: "#6e707e",
                      borderRadius: "11px",
                      height: "48px",
                      width: "260px",
                      padding: "3px",
                      textAlign: "center",
                    }),
                  }}
                  onChange={handleFilterOptions}
                  options={typeofselection}
                  value={selectedval.dropdownval}
                  required
                />
              </div>
            ) : (
              ""
            )}
            {checkinput == 1 ? (
              <div
                className="margin-view"
                style={{ position: "relative", width: "200px" }}
              >
                <input
                  style={{
                    border: "1px solid #cccccc",
                    borderRadius: "11px",
                    height: "48px",
                    outline: "none",
                    paddingLeft: "6px",
                    // marginLeft:'20px'
                  }}
                  onChange={handleInputfilter}
                  value={selectedval.inputval}
                />
                {checkresetfilter != 1 ? (
                  <img
                    src={SearchIcon}
                    className=""
                    style={{ position: "absolute", right: "10px", top: "18px" }}
                    onClick={filterData}
                  />
                ) : (
                  <img
                    src={RefreshIcon}
                    className=""
                    style={{ position: "absolute", right: "10px", top: "18px" }}
                    onClick={refreshData}
                  />
                )}
              </div>
            ) : (
              ""
            )}
            {checkForDateFilter == 1 ? (
              <div className="margin-view" style={{ whiteSpace: "nowrap" }}>
                {" "}
                <Datepicker
                  id="production_date"
                  onDatesSelected={handleDatesSelected}
                  onRefreshSelected={refreshSelected}
                />
                {checkresetfilter != 1 ? (
                  <img
                    src={SearchIcon}
                    className="search_icon_for_dates pointer"
                    onClick={filterDatafordates}
                  />
                ) : (
                  <img
                    src={RefreshIcon}
                    className="search_icon_for_dates pointer"
                    onClick={refreshData1}
                  />
                )}
              </div>
            ) : (
              ""
            )}
            {/* {check == 1 ?
                            <span className="float-right mt-24p" >
                                <button className='btn_style' onClick={() => manualbulkUploadForCha()} >Update CHA Vessel Availability</button>
                            </span>


                            : ''} */}
          </div>
        ) : (
          ""
        )}
        {
        check == 1 ? (
          <div className="" style={{ margin: "10px", marginTop: "20px" }}>
            <div className="d-flex flex-row" style={{ position: "relative" }}>
              <button
                className="d-flex flex-row justify-content-center align-items-center px-3"
                style={{
                  background: classForTab.new == 1 ? "#0664AE" : "#FFFFFF",
                  // color: state.isTileView ? "": "#0664AE",
                  borderTopLeftRadius: "11px",
                  borderBottomLeftRadius: "11px",
                  border: "1px solid #0664AE",
                  height: "38px",
                  padding: "22px",
                }}
                onClick={() => onclickTab("New")}
              >
                <div
                  style={{
                    color: classForTab.new == 1 ? "white" : "#0664AE",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginLeft: "10px",
                    lineHeight: "12px",
                  }}
                >
                  New -{" "}
                  <span>
                    {newCount != "" && newCount != undefined
                      ? newCount.length
                      : 0}
                  </span>
                </div>
              </button>
              <button
                className="d-flex flex-row justify-content-center align-items-center px-3"
                style={{
                  background: classForTab.planned == 1 ? "#0664AE" : "#FFFFFF",
                  border: "1px solid #0664AE",
                  height: "38px",
                  padding: "22px",
                }}
                onClick={() => onclickTab("Planned")}
              >
                <div
                  style={{
                    color: classForTab.planned == 1 ? "white" : "#0664AE",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginLeft: "10px",
                    lineHeight: "12px",
                  }}
                >
                  Planned -{" "}
                  <span>
                    {plannedCount != "" && plannedCount != undefined
                      ? plannedCount.length
                      : 0}
                  </span>
                </div>
              </button>
              <button
                className="d-flex flex-row justify-content-center align-items-center px-3"
                style={{
                  background: classForTab.modified == 1 ? "#0664AE" : "#FFFFFF",
                  borderTopRightRadius: "11px",
                  borderBottomRightRadius: "11px",
                  border: "1px solid #0664AE",
                  height: "38px",
                  padding: "22px",
                }}
                onClick={() => onclickTab("Modified")}
              >
                <div
                  style={{
                    color: classForTab.modified == 1 ? "white" : "#0664AE",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginLeft: "10px",
                    lineHeight: "12px",
                  }}
                >
                  Modified -{" "}
                  <span>
                    {modifiedCount != "" && modifiedCount != undefined
                      ? modifiedCount.length
                      : 0}
                  </span>
                </div>
              </button>
              {classForTab.new === 1 && summaryviewcheck === 1 ? (
                <div
                  style={{ position: "absolute", right: "0px", top: "-25px" }}
                >
                  <div
                    className="btn_hover"
                    style={{
                      height: "35px",
                      marginTop: "32px",
                      fontSize: "12px",
                      textAlign: "center",
                      paddingTop: "8px",
                      padding: "5px",
                    }}
                    onClick={onClickSaveGridState}
                  >
                    <i
                      className="icofont icofont-save"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Save Grid Layout
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              {/* {props.allcounter==1?
                            <p style={{color:'red'}}>Note: Modification Pending At Previous Action</p>:''} */}
            </div>

            <div
              id="idgrid"
              style={{ width: "100%", height: "478px" }}
              className="ag-theme-balham"
            >
              <AgGridReact
                ref={gridApi}
                columnDefs={columnwithDefs}
                defaultColDef={{
                  sortable: true,
                  filter: true,
                  editable: false,
                  resizable: true,
                  menuTabs: ["filterMenuTab"],
                }}
                //  paddingTop={10}
                // rowHeight={70}
                // headerHeight={70}
                rowData={rowData}
                suppressRowClickSelection={true}
                frameworkComponents={{
                  CountryFlagComponent: CountryFlagComponent,
                  UpdateCHAVesselAvailability: UpdateCHAVesselAvailability,
                  AcknowledgeBtnComponent: AcknowledgeBtnComponent,
                  UpdateAcknowledgeBtnComponent: UpdateAcknowledgeBtnComponent,
                  SubmitRevisedBtn: SubmitRevisedBtn,
                  UpdateCHAVesselAvailability1: UpdateCHAVesselAvailability1,
                }}
                enableCellChangeFlash={true}
                suppressCellFlash={true}
                enableRangeSelection={true}
                paginationPageSize={50}
                onGridReady={onGridReady}
                onGridState={onGridState}
                rowSelection={"multiple"}
                masterDetail={true}
                pagination={true}
                sideBar={{
                  toolPanels: [
                    {
                      id: "columns",
                      labelDefault: "Columns",
                      labelKey: "columns",
                      iconKey: "columns",
                      toolPanel: "agColumnsToolPanel",
                    },
                    {
                      id: "filters",
                      labelDefault: "Filters",
                      labelKey: "filters",
                      iconKey: "filter",
                      toolPanel: "agFiltersToolPanel",
                    },
                  ],
                }}
                statusBar={{
                  statusPanels: [
                    // {
                    //     statusPanel: "agTotalAndFilteredRowCountComponent",
                    //     align: "left"
                    // },
                    // {
                    //     statusPanel: "agTotalRowCountComponent",
                    //     align: "center"
                    // },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" },
                  ],
                }}
                gridOptions={{
                  icons: {
                    // You can customize other icons as well
                    next: `<img src="${customNextIcon}" alt="Next" />`,
                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                    first: `<img src="${customFirstIcon}" alt="First" />`,
                    last: `<img src="${customLastIcon}" alt="Last" />`,
                  },
                }}
                // ref={gridRef}
                context={{
                  onClickupdateAcknowledgeBtn,
                  onClickupdateAcknowledgeBtn1,

                  onClickchavessel1,
                  onClickchavessel,
                  onClickAcknowledgeBtn,
                  onClickRevisedProductionData,
                }}
                getRowStyle={getRowStyle}
                // rowClassRules={rowClassRules}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {check == 8 ? (
          <CHAallComponent
            onRequest={refreshDataNow}
            check={8}
            data={props.allchadata}
          />
        ) : (
          ""
        )}
        {check == 2 ? (
          <UploadDo
            onRequest={refreshDataNow}
            checkforuploaddata={checkforuploaddata}
            check={2}
            data={counters.upload_do_data}
            uploadBulkData={uploadBulkData}
            setcheckforbulk={setcheckforbulk}
          />
        ) : (
          ""
        )}
        {check == 3 ? (
          <InTransitToPort
            onRequest={refreshDataNow}
            data={tabsforcontainerReport}
            checkforuploaddata={checkforuploaddata}
            check={2}
            uploadBulkData={uploadBulkData}
            setcheckforbulk={setcheckforbulk}

          />
        ) : (
          ""
        )}
        {check == 4 ? (
          <GateIn
            onRequest={refreshDataNow}
            data={tabForGateInPort}
            check={4}
            uploadBulkData={uploadBulkData}
            setcheckforbulk={setcheckforbulk}

          />
        ) : (
          ""
        )}
        {check == 5 ? (
          <AtPlantCha
            onRequest={refreshDataNow}
            data={tabsForAtPlant}
            uploadBulkData={uploadBulkData}
            check={5}
            checkforuploaddata={checkforuploaddata}
            setcheckforbulk={setcheckforbulk}
          />
        ) : (
          ""
        )}
        {check == 6 ? (
          <ContainerReporting
            onRequest={refreshDataNow}
            data={tabForLeo}
            check={6}
            checkforuploaddata={checkforuploaddata}
            uploadBulkData={uploadBulkData}
            setcheckforbulk={setcheckforbulk}

          />
        ) : (
          ""
        )}
        {check == 7 ? (
          <CompletedComponent
            onRequest={refreshDataNow}
            data={tabForCompleted}
            check={7}
          />
        ) : (
          ""
        )}
        {check == 9 ? (
          <CHABuffer data={bufferdata} onRequest={refreshDataNow} check={9} />
        ) : (
          ""
        )}
        {check == 10 ? (
          <ShippingOnBoardComponent
            data={tabForShippingOnboard}
            onRequest={refreshDataNow}
            check={4}
            uploadBulkData={uploadBulkData}
          />
        ) : (
          ""
        )}
        {check == 11 ? (
          <BillofLadingComponent
            data={bufferdata}
            datarefresh={datarefresh}
            uploadBulkData={uploadBulkData}
            onRequest={refreshDataNow}
            check={4}
          />
        ) : (
          ""
        )}
      </div>
      {/* <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">showAddForm
                                <a href={require("../../assets/json/cha_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Tentative Shipment Line & Remarks should be in 'String' format. <br />
                                Tentative Vessel ETD should be in 'DD-MM-YYYY' format.Tentative Gate Open Date & Tentative cut off date should be in 'DD-MM-YYYY HH:MM' format</p>
                        </div>
                    </div>
                </div>

            </div> */}
      <div
        className={"sliderBlock2 " + showAddForm}
        style={{ overflow: "auto" }}
      >
        <div className="slide-r-title slider_title_style">
          <h6>Enter CHA Vessel Availability Details</h6>
          <span
            className="float-right closebtn"
            style={{ marginTop: "-30px", marginRight: "25px" }}
            onClick={onClickHideManualUpload}
          >
            X
          </span>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <form onSubmit={onsubmitdata}>
              <div className="slide-body">
                <div className="row p-20p">
                  <div className="form-group col-xl-12 col-lg-12">
                    <p className="mb-5p">
                      Work Order No: {selected_row.work_order_no}
                    </p>
                  </div>{" "}
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Freight Forwarder<span className="err-txt">*</span>
                    </label>
                    {/* <input
                                            type="text"
                                            name="freight_forwarder"
                                            id="freight_forwarder"
                                            value={manualUploadDetails.freight_forwarder}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, freight_forwarder: e.target.value }))}
                                            required
                                        /> */}
                    <Select
                      closeMenuOnSelect={true}
                      value={manualUploadDetails.freight_forwarder}
                      // multi={true}
                      // className={"border-radius-0"}
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          freight_forwarder: e,
                        }))
                      }
                      style={{ borderRadius: "0px" }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          fontSize: "0.8rem",
                          color: "#6e707e",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "0.9rem",
                        }),
                      }}
                      options={frieghtlist}
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Tentative Shipping Line<span className="err-txt">*</span>
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      value={manualUploadDetails.tentative_shipping_line}
                      // multi={true}
                      // className={"border-radius-0"}
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          tentative_shipping_line: e,
                        }))
                      }
                      style={{ borderRadius: "0px" }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          fontSize: "0.8rem",
                          color: "#6e707e",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "0.9rem",
                        }),
                      }}
                      options={shippinglist1}
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Tentative Vessel ETD <span className="err-txt">*</span>
                    </label>
                    <Datetime
                      key={manualUploadDetails.tentative_vessel_etd}
                      placeholder="Date"
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      name="tentative_vessel_etd"
                      value={manualUploadDetails.tentative_vessel_etd}
                      dateFormat="DD-MM-YYYY"
                      id="tentative_vessel_etd"
                      timeFormat={false}
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          tentative_vessel_etd: changeHandleDate(e),
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Tentative Gate Open Date{" "}
                      <span className="err-txt">*</span>
                    </label>
                    {/* <Datetime
                                            key={manualUploadDetails.tentative_gate_open_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="tentative_gate_open_date"
                                            value={manualUploadDetails.tentative_gate_open_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='tentative_gate_open_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, tentative_gate_open_date: changeHandleDate(e) }))}
                                            required /> */}
                    <input
                      type="text"
                      name="replaced_date_time"
                      autoComplete="off"
                      class="form-control transhipment-datetime datetimepicker_mask"
                      id="gate_open_datetime"
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Tentative Cut Off Date <span className="err-txt">*</span>
                    </label>
                    {/* <Datetime
                                            key={manualUploadDetails.tentative_cut_off_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="tentative_cut_off_date"
                                            value={manualUploadDetails.tentative_cut_off_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='tentative_cut_off_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, tentative_cut_off_date: changeHandleDate(e) }))}
                                            required /> */}
                    <input
                      type="text"
                      name="replaced_date_time"
                      autoComplete="off"
                      class="form-control transhipment-datetime datetimepicker_mask"
                      id="port_cutoff_date"
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      No. Of Free Days <span className="err-txt">*</span>
                    </label>
                    <input
                      type="text"
                      name="no_of_free_days"
                      id="no_of_free_days"
                      value={manualUploadDetails.no_of_free_days}
                      className="form-control"
                      maxlength="2"
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          no_of_free_days: e.target.value,
                        }))
                      }
                      onInput={(e) => restrictToNumbers(e)}
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Remarks By CHA <span className="err-txt"></span>
                    </label>
                    <input
                      type="text"
                      name="newPartnerType"
                      // placeholder='Enter Partner Type'
                      id="inputcode"
                      value={manualUploadDetails.remarks_by_cha}
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          remarks_by_cha: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {/* <h6 style={{ color: "red", fontSize: "14px", marginTop: "2%" }}>Note: All date fields should be greater than production date</h6> */}
                  <div className="form-group col-sm-12 mb-20p text-center">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={"sliderBlock2 " + showAddForm1} style={{ overflow: "auto" }}>
    <div className="slide-r-title slider_title_style">
        <h6>Update CHA Vessel Availability Details</h6>
        <span
            className="float-right closebtn"
            style={{ marginTop: "-30px", marginRight: "25px" }}
            onClick={onClickHideManualUpload1}
        >
            X
        </span>
    </div>
    <div className="row">
        <div className="col-sm-12">
            <form onSubmit={onsubmitdata1}>
                <div className="slide-body">
                    <div className="row p-20p">
                        <div className="form-group col-xl-12 col-lg-12">
                            <p className="mb-5p">
                                Work Order No: {selected_row.work_order_no}
                            </p>
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Freight Forwarder<span className="err-txt">*</span>
                            </label>
                            <Select
                                closeMenuOnSelect={true}
                                value={manualUploadDetails.freight_forwarder}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        freight_forwarder: e,
                                    }))
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: "0px",
                                        fontSize: "0.8rem",
                                        color: "#6e707e",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        fontSize: "0.9rem",
                                    }),
                                }}
                                options={frieghtlist}
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Shipping Line<span className="err-txt">*</span>
                            </label>
                            <Select
                                closeMenuOnSelect={true}
                                value={manualUploadDetails.tentative_shipping_line}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_shipping_line: e,
                                    }))
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: "0px",
                                        fontSize: "0.8rem",
                                        color: "#6e707e",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        fontSize: "0.9rem",
                                    }),
                                }}
                                options={shippinglist1}
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Vessel ETD <span className="err-txt">*</span>
                            </label>
                            <Datetime
                                key={manualUploadDetails.tentative_vessel_etd}
                                placeholder="Date"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                name="tentative_vessel_etd"
                                value={manualUploadDetails.tentative_vessel_etd}
                                dateFormat="DD-MM-YYYY"
                                id="tentative_vessel_etd"
                                timeFormat={false}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_vessel_etd: changeHandleDate(e),
                                    }))
                                }
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Gate Open Date <span className="err-txt">*</span>
                            </label>
                            <Datetime
                                key={manualUploadDetails.tentative_gate_open_date}
                                placeholder="Date"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                name="tentative_gate_open_date"
                                value={manualUploadDetails.tentative_gate_open_date}
                                dateFormat="DD-MM-YYYY"
                                id="tentative_gate_open_date"
                                timeFormat={false}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_gate_open_date: changeHandleDate(e),
                                    }))
                                }
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Cut Off Date <span className="err-txt">*</span>
                            </label>
                            <Datetime
                                key={manualUploadDetails.tentative_cut_off_date}
                                placeholder="Date"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                name="tentative_cut_off_date"
                                value={manualUploadDetails.tentative_cut_off_date}
                                dateFormat="DD-MM-YYYY"
                                id="tentative_cut_off_date"
                                timeFormat={false}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_cut_off_date: changeHandleDate(e),
                                    }))
                                }
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                No. Of Free Days <span className="err-txt">*</span>
                            </label>
                            <input
                                type="text"
                                name="no_of_free_days"
                                id="no_of_free_days"
                                value={manualUploadDetails.no_of_free_days}
                                className="form-control"
                                maxLength="2"
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        no_of_free_days: e.target.value,
                                    }))
                                }
                                onInput={(e) => restrictToNumbers(e)}
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Remarks By CHA <span className="err-txt"></span>
                            </label>
                            <input
                                type="text"
                                name="remarks_by_cha"
                                id="remarks_by_cha"
                                value={manualUploadDetails.remarks_by_cha}
                                className="form-control"
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        remarks_by_cha: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="form-group col-sm-12 mb-20p text-center">
                            <button type="submit" className="btn btn-success">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


      <div className={"sliderBlock2 " + showAddForm2} style={{ overflow: "auto" }}>
    <div className="slide-r-title slider_title_style">
        <h6>Update CHA Vessel Availability Details</h6>
        <span
            className="float-right closebtn"
            style={{ marginTop: "-30px", marginRight: "25px" }}
            onClick={onClickHideManualUpload2}
        >
            X
        </span>
    </div>
    <div className="row">
        <div className="col-sm-12">
            <form onSubmit={onsubmitdata2}>
                <div className="slide-body">
                    <div className="row p-20p">
                        <div className="form-group col-xl-12 col-lg-12">
                            <p className="mb-5p">
                                Work Order No: {selected_row.work_order_no}
                            </p>
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Freight Forwarder<span className="err-txt">*</span>
                            </label>
                            <Select
                                closeMenuOnSelect={true}
                                value={manualUploadDetails.freight_forwarder}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        freight_forwarder: e,
                                    }))
                                }
                                style={{ borderRadius: "0px" }}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        fontSize: "0.8rem",
                                        color: "#6e707e",
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        fontSize: "0.9rem",
                                    }),
                                }}
                                options={frieghtlist}
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Shipping Line<span className="err-txt">*</span>
                            </label>
                            <Select
                                closeMenuOnSelect={true}
                                value={manualUploadDetails.tentative_shipping_line}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_shipping_line: e,
                                    }))
                                }
                                style={{ borderRadius: "0px" }}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        fontSize: "0.8rem",
                                        color: "#6e707e",
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        fontSize: "0.9rem",
                                    }),
                                }}
                                options={shippinglist1}
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Vessel ETD <span className="err-txt">*</span>
                            </label>
                            <Datetime
                                key={manualUploadDetails.tentative_vessel_etd}
                                placeholder="Date"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                name="tentative_vessel_etd"
                                value={manualUploadDetails.tentative_vessel_etd}
                                dateFormat="DD-MM-YYYY"
                                id="tentative_vessel_etd"
                                timeFormat={false}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_vessel_etd: changeHandleDate(e),
                                    }))
                                }
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Gate Open Date <span className="err-txt">*</span>
                            </label>
                            <Datetime
                                key={manualUploadDetails.tentative_gate_open_date}
                                placeholder="Date"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                name="tentative_gate_open_date"
                                value={manualUploadDetails.tentative_gate_open_date}
                                dateFormat="DD-MM-YYYY"
                                id="tentative_gate_open_date"
                                timeFormat={false}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_gate_open_date: changeHandleDate(e),
                                    }))
                                }
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Tentative Cut Off Date <span className="err-txt">*</span>
                            </label>
                            <Datetime
                                key={manualUploadDetails.tentative_cut_off_date}
                                placeholder="Date"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                name="tentative_cut_off_date"
                                value={manualUploadDetails.tentative_cut_off_date}
                                dateFormat="DD-MM-YYYY"
                                id="tentative_cut_off_date"
                                timeFormat={false}
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        tentative_cut_off_date: changeHandleDate(e),
                                    }))
                                }
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                No. Of Free Days <span className="err-txt">*</span>
                            </label>
                            <input
                                type="text"
                                name="no_of_free_days"
                                id="no_of_free_days"
                                value={manualUploadDetails.no_of_free_days}
                                className="form-control"
                                maxlength="2"
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        no_of_free_days: e.target.value,
                                    }))
                                }
                                onInput={(e) => restrictToNumbers(e)}
                                required
                            />
                        </div>
                        <div className="form-group col-xl-12 col-lg-12">
                            <label className="mb-5p">
                                Remarks By CHA <span className="err-txt"></span>
                            </label>
                            <input
                                type="text"
                                name="remarks_by_cha"
                                id="remarks_by_cha"
                                value={manualUploadDetails.remarks_by_cha}
                                className="form-control"
                                onChange={(e) =>
                                    setmanualUploadDetails((prev) => ({
                                        ...prev,
                                        remarks_by_cha: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="form-group col-sm-12 mb-20p text-center">
                            <button type="submit" className="btn btn-success">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

      {((checkforuploaddata == 2 && sliderForBulkupload == "slider-translate")||
      (checkforuploaddata == 3 && sliderForBulkupload == "slider-translate") || (checkforuploaddata == 5 && sliderForBulkupload == "slider-translate") || (checkforuploaddata == 11 && sliderForBulkupload == "slider-translate") || (check == 4 && sliderForBulkupload == "slider-translate") || (checkforuploaddata == 10 && sliderForBulkupload == "slider-translate") || (checkforuploaddata == 13 && sliderForBulkupload == "slider-translate"))? (
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{ modal: "custom-modal" }}
        >
          <div className="slide-r-title slider_title_style title-radius">
            <h6>Bulk Upload</h6>
          </div>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
            <div
              className={"row"}
              style={{ padding: "5px 0px", width: "100%", zIndex: "9999" }}
            >
              <div className="theme-form col-xl-12 col-lg-12">
                <form action="" onSubmit={onSubmitOrderBulkUpload2}>
                  <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                    <div className="d-flex justify-content-between">
                      <input
                        type="file"
                        name="uploadfile"
                        id="uploadfile"
                        className="form-control ml-3"
                        onChange={changeOrderFileHandlerFcrBulk1}
                        style={{ width: "400px" }}
                        required
                      />
                      <img
                        src={require("../../assets/icons/red-dot.png")}
                        className="img-dot ml-70p mt-8p"
                      />
                      <span className="mt-8p ml-10p">Invalid Date Format</span>
                    </div>
                  </div>
                  {showGrid && (
                    <div
                      id="idgrid"
                      style={{ width: gridWidth}} // Set grid width dynamically
                      className="ag-theme-balham m-10p ml-15p bulkGridResolution"
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        columnDefs={columnsforbulk}
                        // defaultColDef={this.state.defaultColDef}
                        rowData={rowData1}
                        domLayout="autoHeight"
                      />
                    </div>
                  )}
                  <div className="col-xl-12 col-lg-12 form-group mt-10p">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
                {!showGrid && (
                  <div className="col-xl-12 col-lg-12 form-group">
                    <a
                      href={selectedfileurl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-warning"
                    >
                      Download Template
                    </a>
                  </div>
                )}

                <p style={{ color: "red" }} className="ml-15p">
                  Note:{" "}
                </p>
                {(checkforuploaddata == 2 || checkforuploaddata == 11) &&
                <p className="ml-15p">
                  All Date Fields Should be in 'DD-MM-YYYY HH:MM' format.
                </p>}
                {(checkforuploaddata == 5 || checkforuploaddata == 4 || check == 10 || checkforuploaddata == 13 || checkforuploaddata == 3) && 
                 <p className="ml-15p">
                 All Date Fields Should be in 'DD-MM-YYYY' format.
               </p>
                }
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {sliderForBulkupload == "slider-translate" &&
      isClicked.vessel_avail == true ? (
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{ modal: "custom-modal" }}
        >
          {classForTab.new == true ? (
            <>
              {" "}
              <div className="slide-r-title slider_title_style title-radius">
                <h6>Bulk Upload</h6>
              </div>
              <div className="slide-r-body" style={{ position: "relative" }}>
                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                <div
                  className={"row"}
                  style={{ padding: "5px 0px", width: "100%", zIndex: "9999" }}
                >
                  <div className="theme-form col-xl-12 col-lg-12">
                    <form action="" onSubmit={onSubmitOrderBulkUpload}>
                      <div className="col-xl-12 col-lg-12 row form-group">
                        <label className="col-xl-12 col-lg-12">
                          Upload File *
                        </label>
                        <div className="d-flex justify-content-between">
                          <input
                            type="file"
                            name="uploadfile"
                            id="uploadfile"
                            className="form-control ml-3"
                            onChange={changeOrderFileHandlerFcrBulk}
                            style={{ width: "400px" }}
                            required
                          />
                          <img
                            src={require("../../assets/icons/red-dot.png")}
                            className="img-dot ml-70p mt-8p"
                          />
                          <span className="mt-8p ml-10p">
                            Invalid Date Format
                          </span>
                        </div>
                      </div>
                      {showGrid && (
                        <div
                          id="idgrid"
                          // style={{ maxWidth:'40rem'}}
                          className="ag-theme-balham m-10p ml-15p bulkGridResolution"
                        >
                          <AgGridReact
                            // modules={this.state.modules}
                            columnDefs={columnDefs1}
                            // defaultColDef={this.state.defaultColDef}
                            rowData={rowData1}
                            domLayout="autoHeight"
                          />
                        </div>
                      )}
                      <div className="col-xl-12 col-lg-12 form-group mt-10p">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </form>
                    {!showGrid && (
                      <div className="col-xl-12 col-lg-12 form-group">
                        <a
                          href={require("../../assets/json/cha_based_upload_data.csv")}
                          target="_blank"
                          className="btn btn-warning"
                        >
                          Download Template
                        </a>
                      </div>
                    )}

                    <p style={{ color: "red" }} className="ml-15p">
                      Note:{" "}
                    </p>
                    <p className="ml-15p">
                      Tentative Shipment Line & Remarks should be in 'String'
                      format. <br />
                      Tentative Vessel ETD should be in 'DD-MM-YYYY'
                      format.Tentative Gate Open Date & Tentative cut off date
                      should be in 'DD-MM-YYYY HH:MM' format1
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="slide-r-title slider_title_style title-radius">
                <h6>Bulk Upload</h6>
              </div>
              <div className="slide-r-body" style={{ position: "relative" }}>
                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                <div
                  className={"row"}
                  style={{ padding: "5px 0px", width: "100%", zIndex: "9999" }}
                >
                  <div className="theme-form col-xl-12 col-lg-12">
                    <form action="" onSubmit={onSubmitOrderBulkUpload1}>
                      <div className="col-xl-12 col-lg-12 row form-group">
                        <label className="col-xl-12 col-lg-12">
                          Upload File *
                        </label>
                        <div className="d-flex justify-content-between">
                          <input
                            type="file"
                            name="uploadfile"
                            id="uploadfile"
                            className="form-control ml-3"
                            onChange={changeOrderFileHandlerFcrBulk}
                            style={{ width: "400px" }}
                            required
                          />
                          <img
                            src={require("../../assets/icons/red-dot.png")}
                            className="img-dot ml-70p mt-8p"
                          />
                          <span className="mt-8p ml-10p">
                            Invalid Date Format
                          </span>
                        </div>
                      </div>
                      {showGrid && (
                        <div
                          id="idgrid"
                          // style={{ maxWidth:'40rem' }}
                          className="ag-theme-balham m-10p ml-15p bulkGridResolution"
                        >
                          <AgGridReact
                            // modules={this.state.modules}
                            columnDefs={columnDefs2}
                            // defaultColDef={this.state.defaultColDef}
                            rowData={rowData1}
                            domLayout="autoHeight"
                          />
                        </div>
                      )}
                      <div className="col-xl-12 col-lg-12 form-group mt-10p">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </form>
                    {!showGrid && (
                      <div className="col-xl-12 col-lg-12 form-group">
                        <a
                          href={require("../../assets/json/cha_based_upload_data.csv")}
                          target="_blank"
                          className="btn btn-warning"
                        >
                          Download Template
                        </a>
                      </div>
                    )}
                    <p style={{ color: "red" }} className="ml-15p">
                      Note:{" "}
                    </p>
                    <p className="ml-15p">
                      Tentative Shipment Line & Remarks should be in 'String'
                      format. <br />
                      Tentative Vessel ETD should be in 'DD-MM-YYYY'
                      format.Tentative Gate Open Date & Tentative cut off date
                      should be in 'DD-MM-YYYY HH:MM' format2
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}

      <div className={"dataLoadpage " + loadshow}></div>
      <div className={"dataLoadpageimg " + loadshow}>
        <div className="loader-box">
          <div className="loader-box">
            <div className="rotate dashed colored"></div>
          </div>
        </div>
      </div>
      <div className={"overlay-part " + overly} onClick={onClickHideAll}></div>
    </div>
  );
};
function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
function timeConvert(n) {
  // var num = n/1000;
  // var hours = (num / (3600*24));
  // var rhours = Math.floor(hours);
  // var minutes = (n) / (60*60);
  // var rminutes = Math.round(minutes);

  var diffMs = n;
  var diffDays = Math.floor(diffMs / 86400000); // days
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

  return diffHrs + " hour(s) and " + diffMins / 60 + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
  var timeinfo = new window.google.maps.InfoWindow({
    content: "Time at this location: " + timedet,
  });

  marker.addListener("click", function () {
    if (currentinfowindow != null) {
      currentinfowindow.close();
      currentinfowindow = timeinfo;
      timeinfo.open(marker.get("map"), marker);
    } else {
      currentinfowindow = timeinfo;
      timeinfo.open(marker.get("map"), marker);
    }
  });
}

function secondsToString(seconds) {
  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var numseconds = ((seconds % 86400) % 3600) % 60;
  return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var numseconds = ((seconds % 86400) % 3600) % 60;
  return numdays;
}
function CheckForHtmlTag() {
  var rs = document.getElementById("invalid_reason");
  var reg = /<(.|\n)*?>/g;
  if (reg.test(rs.value)) {
    var ErrorText = "Oops! HTML or Script is not allowed.";
    rs.value = "";
    //alert();
    return 1;
  }
}
function dateComparator(date1, date2) {
  // //console.log("dateComparator");
  // //console.log(date1.length);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // //console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
    // //console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // //console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}
function monthToComparableNumber(date) {
  // //console.log(date,date.length);
  if (date === undefined || date === null || date.length !== 16) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  ////console.log(yearNumber,monthNumber,dayNumber);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

function getMonthNumber(monthName) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var monthNum = months.indexOf(monthName) + 1;
  if (monthNum < 10) {
    monthNum = "0" + monthNum.toString();
  }
  return monthNum;
}

function loadDateTimeScript() {
  $(".datetimepicker_mask").datetimepicker({
    mask: "39-19-9999 29:59",
    format: "d-m-Y H:i",
    onShow: false,
  });
  $(".datetimepicker_date").datetimepicker({
    // mask:'39-19-9999',
    format: "d-m-Y",
    timepicker: false,
    onShow: false,
  });
  var index = window.document.getElementsByTagName("script")[1];
  var script = window.document.createElement("script");
  script.src =
    "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
  //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

function GetSortDescOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortAscOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortASCOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
  var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
  var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
  var result = arr.reduce(function (arr1, e1) {
    var matches = arr1.filter(function (e2) {
      return e1[propName] == e2[propName];
    });
    if (matches.length == 0) arr1.push(e1);
    return arr1;
  }, []);

  return result;
}
function dateComparator1(date1, date2) {
  ////console.log(date1,date2);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // //console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split("-")[1]);
    date1 = date1.replace(date1.split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split("-")[1]);
    date2 = date2.replace(date2.split("-")[1], date_2);
    var date1Number = monthToComparableNumber1(date1);
    var date2Number = monthToComparableNumber1(date2);
    ////console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    //console.log(date1Number, date2Number, "comparator");
    return date1Number - date2Number;
  }
}
function monthToComparableNumber1(date) {
  ////console.log(date.length);

  ////console.log(date);
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  var yearNumber = date.substring(6, 10);

  var monthNumber = date.substring(3, 5);

  var dayNumber = date.substring(0, 2);

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

  return result;
}
function loadDateTimeScript2() {
  // alert("timeDate");
  $(".datetimepicker_mask").datetimepicker({
    format: "d-m-Y",
  });
  $(".device_filter_data").datetimepicker({
    format: "Y-m-d",
    timepicker: true,
  });
  var index = window.document.getElementsByTagName("script")[1];
  var script = window.document.createElement("script");
  script.src =
    "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
  //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}
function getDatePicker() {
  function Datepicker() {}

  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");

    //this.eInput = this.eInput.setAttribute("class","datepicker");

    this.eInput.value = params.value;

    //console.log(this.eInput);

    window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
  };

  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };

  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();

    this.eInput.select();
  };

  Datepicker.prototype.getValue = function () {
    return this.eInput.value;
  };

  Datepicker.prototype.destroy = function () {};

  Datepicker.prototype.isPopup = function () {
    return false;
  };

  return Datepicker;
}
function DateEditor() {}
// gets called once before the renderer is used

DateEditor.prototype.init = function (params) {
  // create the cell

  //console.log(params, "params");

  this.eInput = document.createElement("input");

  this.eInput.value = params.value;
  // https://jqueryui.com/datepicker/

  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",

    changeMonth: true,

    changeYear: true,
  });
};

// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();

  this.eInput.select();
};

// returns the new value after editing

DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here

DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default

  return false;
};
function sortByCreatedDate(arr, descending = true) {
  if (arr != "" && arr != undefined) {
    if (arr.length > 0) {
      const comparison = (a, b) => {
        const dateA = new Date(a.modified_date);
        const dateB = new Date(b.modified_date);
        if (descending) {
          return dateB - dateA; // Ascending order
        } else {
          return dateA - dateB; // Descending order
        }
      };
      arr.sort(comparison);
      return arr;
    }
  } else {
    return arr;
  }
}
function removeDuplicateObjects(arr) {
  const uniqueObjects = new Set();
  const filteredArray = arr.filter((obj) => {
    const key = JSON.stringify(obj);
    if (!uniqueObjects.has(key)) {
      uniqueObjects.add(key);
      return true;
    }
    return false;
  });
  return filteredArray;
}
const verifyUploadFileColumns = async (validCols, file) => {
  //console.log(validCols, "3810");
  return new Promise((resolve, reject) => {
    if (file !== "" && file !== undefined) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const lines = e.target.result.split("\n");
        const headers = lines[0].split(",");
        const invalidCols = headers.filter(
          (header) => !validCols.includes(header.trim())
        );
        if (invalidCols.length === 0) {
          resolve(true);
        } else {
          resolve(`Invalid column(s): ${invalidCols.join(", ")}`);
        }
      };
      reader.readAsText(file);
    } else {
      reject("No file selected");
    }
  });
};

export default CHAUpdatesComponent;
