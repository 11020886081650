import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import redirectURL from "../redirectURL";
import DatePicker from 'react-datepicker';

import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHHMM } from '../common/utils';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import { json } from 'react-router-dom';


const BufferStatus = () => {

    var [rowData, setrowData] = useState([]);
    var [plantsCollection, setPlantsCollection] = useState([]);
    var [columns, setColumns] = useState([]);
    var [showFourDaysView, setShowFourDaysView] = useState("");
    var [rowData2, setrowData2] = useState([]);
    var [overly, setoverly] = useState('show-n')
    const [tableHeaders, setTableHeaders] = useState(['Date']); // Initialize with 'Date'
    var [loadshow, setloadshow] = useState('show-n');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    var [currentDate, setCurrentDate] = useState(new Date());
    var initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
    var [datefilter, setDatefilter] = useState(initialDate);
    var dateFormat = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
    var [dateText, setDateText] = useState(dateFormat);

    var columnDefs2 = [
        {
            headerName: "WORK ORDER NO",
            headerClass: "showDataStyle",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
            cellStyle: { textAlign: "center" }
        },
        {
            headerName: "CHA",
            // headerClass:"headerStyle",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
            // valueGetter: function (params) {
            //     try {
            //         if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
            //             return getHyphenDDMMYYYY(params.data.stuffing_date);
            //         }
            //         else {
            //             return "Not Available";
            //         }
            //     }
            //     catch (e) { }
            // },
            cellStyle: { textAlign: "center" }
        },
        {
            headerName: "B/L (NO OF DAYS)",
            // headerClass:"headerStyle",
            field: "bl_days",
            width: 160,
            filter: true,
            resizable: true,
            cellStyle: { textAlign: "center" }
        },

    ]

    
    useEffect(() => {
        setloadshow('show-m')
        getData(datefilter);
    }, [])
    const getData = (formattedDate) => {
        setloadshow('show-m')
        var date;
        if (formattedDate != '' && formattedDate != undefined) {
            date = formattedDate;
        } else {
            date = datefilter
        }
        var params = {
            month: date
        }

        redirectURL.post('/consignments/getBufferStatusData',params).then((resp) => {
            //console.log(resp.data, "getblstatusdata")
            try {

                var data  = resp.data
                if(data!='' && data!=undefined){
                    data = data.cha_buffer_status
                    if(data!='' && data != undefined){
                        if (data.length>0){
                            data = JSON.parse(data)
                            setrowData(data);
                           
                        }else{
                            setrowData([])
                        }
                    }else{
                        setrowData([])
                    }

                   
                // setrowData2(data)
                }else{
                    setrowData([])
                }
                
               
            } catch (e) {
                setrowData([])
                console.error('Error:', e);
            }finally{
                setTimeout(() => {
                    setloadshow('show-n')
                }, 3000);
            }
        }).catch(error => {
            console.error('Fetch error:', error);
            setrowData([]); // Ensure rowData is empty in case of fetch error
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    
        var uniqueHeaders = ['CHA','0 - 7 Days','7 - 14 Days','>14 Days']
        setTableHeaders(uniqueHeaders)
        
    }
    const fourDaysplanView = () => {
        setShowFourDaysView("slide110");
        setoverly("show-m");
    }
    const hideSlideBlock = () => {
        setShowFourDaysView("");
        setoverly("show-n");
    }
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    //   };


    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        setDatefilter(formattedDate)
        var dateFormat1= `${month}-${year}`

        setDateText(dateFormat1)
        // setfilters(prev => ({
        //     ...prev,
        //     datefilter: formattedDate
        // }))
        getData(formattedDate)
        setShowDatePicker(false)
    };
    const handleKeyDown = (e) => {
        e.preventDefault(); // Prevent any keyboard input
    }
    return (
        <>
        <div className='card-header2'>
            <div className='d-flex justify-content-between'>
                <h1 className='heading3 mt-20p'>Buffer Status</h1>
                {/* <div className='d-flex'>

                <div className='mt-15p mr-20p'>

                        <img src={require("../../assets/images/calendar.png")} onClick={() => setShowDatePicker(!showDatePicker)} alt="" width="25px" className="image-icons positionIcon" />
                        {showDatePicker && (
                            <div style={{ position: 'relative', zIndex: 1000 }}>
                                <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                minDate={new Date(1900, 0)}
                                maxDate={new Date(2100, 11)}
                                onKeyDown={handleKeyDown}
                                className="custom-datepicker"
                                calendarClassName="custom-datepicker-dropdown"
                                inline
                            />
                            </div>
                        )}
                    </div>
              
                </div> */}
            </div>
            <div className='scrollable-table-container'>
                <table className="table table-small-rows mt-10p"  style={{border:"0px", overflow: 'auto', height:"100%" }}>
                    <thead>
                        <tr>
                        {tableHeaders.map((header) => (
                            <th className='table-outlookvsorders'>{header}</th> // Dynamic headers
                        ))}
                        </tr>
                    </thead>
                    <tbody>
    {rowData.length > 0 ? (
        rowData.map((item, index) => {
            if (item.cha_name) {
                let cha = item.cha_name.split(" ");
                let chaLength = cha.length;
                let chaName = chaLength > 2 ? `${cha[0]} ${cha[1]}` : cha[0];
                return (
                    <tr key={index}>
                        <td style={{width: "500px"}}>{chaName}</td>
                        <td>{item.btw_0days_7days}</td>
                        <td>{item.btw_7days_14days}</td>
                        <td>{item.greater_than_14_days}</td>
                    </tr>
                );
            } 
        })
    ) : (
        <tr style={{height: "170px"}}>
            <td colSpan={tableHeaders.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}>No rows to show</td>
        </tr>
    )}
</tbody>

                </table>
            </div>    
         
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </>

    )
}

export default BufferStatus;