import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import redirectURL from "../redirectURL";
import DatePicker from 'react-datepicker';

import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHHMM } from '../common/utils';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import { json } from 'react-router-dom';


const TransporterTAT = () => {

    var [rowData, setrowData] = useState([]);
    var [plantsCollection, setPlantsCollection] = useState([]);
    var [columns, setColumns] = useState([]);
    var [showFourDaysView, setShowFourDaysView] = useState("");
    // var [rowData2, setrowData2] = useState([]);
    var [overly, setoverly] = useState('show-n')
    const [tableHeaders, setTableHeaders] = useState(['Date']); // Initialize with 'Date'
    var [loadshow, setloadshow] = useState('show-n');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    var [currentDate, setCurrentDate] = useState(new Date());
    var initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
    var [datefilter, setDatefilter] = useState(initialDate);
    var dateFormat = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
    var [dateText, setDateText] = useState(dateFormat);



    
    useEffect(() => {
        setloadshow('show-m')
        getData(datefilter);
    }, [])
    const getData = (formattedDate) => {
        setloadshow('show-m')
        var date;
        if (formattedDate != '' && formattedDate != undefined) {
            date = formattedDate;
        } else {
            date = datefilter
        }
        var params = {
            month: date
        }

        redirectURL.post('/consignments/gettransporttatdata',params).then((resp) => {
            //console.log(resp.data, "gettransporttatdata")
            try {
                var data  = resp.data
                if(data!='' && data!=undefined){
                    data = data.transporter_tat
                    if(data!='' && data!=undefined){
                        if(data.length>0){
                            data = JSON.parse(data)
                            setrowData(data);
        
                        }else{
                            setrowData([])
                        }
                    }else{
                        setrowData([])
                    }
                }
                           
            }
             catch (e) {
                setrowData([])
                console.error('Error:', e);
              
                      }finally{
                        setTimeout(() => {
                            setloadshow('show-n')
                        }, 3000);  
                      }
        }).catch(error => {
            console.error('Fetch error:', error);
            setrowData([]); // Ensure rowData is empty in case of fetch error
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    
        var uniqueHeaders = ['Transporter Name', 'TAT (Days)']
        setTableHeaders(uniqueHeaders)
        
    }
    const fourDaysplanView = () => {
        setShowFourDaysView("slide110");
        setoverly("show-m");
    }
    const hideSlideBlock = () => {
        setShowFourDaysView("");
        setoverly("show-n");
    }
    //console.log(rowData, "data1");
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    //   };


    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        setDatefilter(formattedDate)
        var dateFormat1= `${month}-${year}`

        setDateText(dateFormat1)
        // setfilters(prev => ({
        //     ...prev,
        //     datefilter: formattedDate
        // }))
        getData(formattedDate)
        setShowDatePicker(false)
    };
    const handleKeyDown = (e) => {
        e.preventDefault(); // Prevent any keyboard input
    }
    return (
        <>
        <div className='card-header2'>
            <div className='d-flex justify-content-between'>
                <h1 className='heading3 mt-20p'>Transporter Average TAT</h1>
                <div className='d-flex'>
                <h3 className='heading4 mr-10p mt-25p'>{dateText}</h3>

                <div className='mt-15p'>

                        <img src={require("../../assets/images/calendar.png")} onClick={() => setShowDatePicker(!showDatePicker)} alt="" width="25px" className="image-icons positionIcon" />
                        {showDatePicker && (
                            <div style={{ position: 'absolute', zIndex: 1000, top: '50px', right:"250px" }}>
                                <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                minDate={new Date(1900, 0)}
                                maxDate={new Date(2100, 11)}
                                onKeyDown={handleKeyDown}
                                className="custom-datepicker"
                                calendarClassName="custom-datepicker-dropdown"
                                inline
                            />
                            </div>
                        )}
                    </div>
               
                </div>
            </div>
            <div className='scrollable-table-container1 mt-10p'>
                <table className="table table-small-rows"  style={{ border: '0px', overflow: 'auto',width:"90%", height:"100%" }}>
                    <thead>
                        <tr>
                        {tableHeaders.map((header) => (
                            <th className='table-outlookvsorders'>{header}</th> // Dynamic headers
                        ))}
                        </tr>
                    </thead>
                    <tbody>
    {rowData && rowData.length > 0 ? (
        rowData.map((item, index) => {
            if (item.transporter_name) {
                let tpt = item.transporter_name.split(" ");
                let tptLength = tpt.length;
                let tptName = tptLength > 2 ? `${tpt[0]} ${tpt[1]}` : tpt[0];
                return (
                    <tr key={index}>
                        <td style={{width: "250px"}}>{tptName}</td>
                        <td>{item.transporter_tat}</td>
                    </tr>
                );
            } 
        })
    ) : (
        <tr style={{height: "170px"}}>
            <td colSpan={tableHeaders.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}>No rows to show</td>
        </tr>
    )}
</tbody>

                </table>
            </div>    
           
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </>

    )
}

export default TransporterTAT;