import React, { useState, useEffect, useRef, useCallback } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from "react-select";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import SubmitBtn from "./submitBtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
import { object } from "@amcharts/amcharts5";
const TptMasterComponent = () => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [rowData, setrowData] = useState([])
    var [modifiedRowData, setModifiedRowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    const [transList, settransList] = useState([])
    var [trucksdata, setTrucksData] = useState([])
    const currentDate = new Date();
    const initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    var [selectedDate, setSelectedDate] = useState(new Date());
    var [selectedfilters, setfilters] = useState({
        datefilter: initialDate,
        transfilter: { label: 'All', value: 'ALL' }
    })
    var [groupdata, setgroupdata] = useState([])
    const [modifiedRows, setModifiedRows] = useState(new Set());
    var [selecteddata, setselecteddata] = useState([])
    var [modifiedRownodes, setModifiedRownodes] = useState([]);
    var [updatedData, setUpdatedData] = useState([]);
    const datePickerRef = useRef(null);
    var [isOpen, setIsOpen]= useState(false);

    useEffect(() => {
        if (gridRef.current) {
            // setGridApi(gridRef.current.api);
            // setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        redirectURL.post("/dashboard/gettrucksdata").then((resp) => {
            try {
                var records = resp.data.records
                var data = resp.data.total_data
                setTrucksData(data)
                var transporters = [{ label: 'All', value: 'ALL' }]
                data.map((e) => {
                    transporters.push({ label: e.transporter_name, value: e.transporter_name })
                })
                transporters = removeDuplicateObjects(transporters);
                settransList(transporters)
            }
            catch (e) {
            }
        })
        getdata()
        if (datePickerRef.current) {
            datePickerRef.current.readOnly = true;
          }
    }, [])
    const handleCellValueChanged = (params) => {
        var updatedRowData = [...updatedData];
        updatedRowData.push(params.data)
        setUpdatedData(updatedRowData);
        var updatedrecord = []

        updatedRowData.map((e) => {
            var keys = Object.keys(e);
            keys.map((k) => {
                if (k.includes("_name") == true) {
                    var tptName = k.split("_")[0];
                    var tptNamewithoutspaces = tptName.trim().replace(/ /g, "");
                }
                if (e[tptNamewithoutspaces + "_mark"] != undefined && e[tptNamewithoutspaces + "_mark"] != "undefined" && e[tptNamewithoutspaces + "_mark"] != '') {
                    updatedrecord.push({
                        "report_date": e["report_date"],
                        "transporter_name": e[tptNamewithoutspaces + "_name"],
                        "mark_availability": e[tptNamewithoutspaces + "_mark"],
                    });
                }
            });
            // var row = {'report_date':e.report_date,'mark_availability':e.}
        })
        var params = {
            data: updatedrecord
        }
        redirectURL.post('/consignments/insertfortruckavailreport', params).then((resp) => {
            if (resp.data.status == 'Success') {
                // setshow(true)
                // setbasicTitle("Successfully Updated")
                // setbasicType("success")
                getdata()
            }
            else {
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
            }
        })
    };
    useEffect(() => {
        setModifiedRowData(updatedData)
    }, [updatedData])
    const onGridReady = useCallback((params) => {
        gridRef.current = params.api;
    }, []);
    const getRowStyle = (params) => {
        if (!params || !params.data || !params.data.report_date) {
            return null;
        }
        var currentWeekDates = getCurrentWeekDates();
        var reportDate = params.data.report_date;

        if (currentWeekDates.includes(reportDate)) {
            return { backgroundColor: '#90e0ef' };
        }
        return null;
    };



    const submitdata = () => {
        console.log(updatedData, 'updatedDataupdatedData')
        var allgriddata = []
        if (gridRef.current) {
            // console.log("gridRowData ",gridRef.current)
            gridRef.current.stopEditing();
            const gridRowData = gridRef.current.getSelectedRows();
            // console.log("gridRowData ",gridRowData)
            if (gridRowData.length > 0) {
                gridRowData.map((itm) => {
                    allgriddata.push(itm)

                })
            }
        }
        console.log(allgriddata, 'allgriddata')
        var updatedrecord = []
        allgriddata.map((e) => {
            var keys = Object.keys(e);
            keys.map((k) => {
                if (k.includes("_name") == true) {
                    var tptName = k.split("_")[0];
                    var tptNamewithoutspaces = tptName.trim().replace(/ /g, "");
                }
                if (e[tptNamewithoutspaces + "_mark"] != undefined && e[tptNamewithoutspaces + "_mark"] != "undefined" && e[tptNamewithoutspaces + "_mark"] != '') {
                    updatedrecord.push({
                        "report_date": e["report_date"],
                        "transporter_name": e[tptNamewithoutspaces + "_name"],
                        "mark_availability": e[tptNamewithoutspaces + "_mark"],
                    });
                }
            });
            console.log(updatedrecord, 'updatedrecord--1')
            // var row = {'report_date':e.report_date,'mark_availability':e.}
        })
        var params = {
            data: updatedrecord
        }
        redirectURL.post('/consignments/insertfortruckavailreport', params).then((resp) => {
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
                getdata()
            }
            else {
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
            }
        })
    }
    // var columnwithDef = [
    //     {
    //         headerName: "",
    //         field: "report_date",
    //         headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
    //         width: 100,
    //         filter: true,
    //         resizable: true,
    //         children: [
    //             {
    //                 headerName: "Month",
    //                 headerClass: ["cellstylegrid"],
    //                 field: 'report_date',
    //                 width: 100,
    //                 cellClass: (params) => {
    //                     if (params.data && params.data.report_date) {
    //                         const currentWeekDates = getCurrentWeekDates();
    //                         if (currentWeekDates.includes(params.data.report_date)) {
    //                             return 'cellHighlight'; // Apply the cellHighlight class for styling
    //                         }
    //                     }
    //                     return ''; // Return empty string for default styling
    //                 },
    //                 // editable: true
    //             },
    //         ]
    //     }

    // ]
    var columnwithDef = [
        {
            headerName: "",
            field: "report_date",
            headerClass: ["cellTopHeaderTemplateH", "cellstylegrid"],
            width: 100,
            filter: true,
            resizable: true,
            children: [
                {
                    headerName: "Month",
                    headerClass: ["cellstylegrid"],
                    field: 'report_date',
                    width: 100,
                    cellClass: (params) => {
                        if (params.data && params.data.report_date) {
                            const currentWeekDates = getCurrentWeekDates();
                            if (currentWeekDates.includes(params.data.report_date)) {
                                return 'boldCell'; // Apply the cellHighlight class for styling
                            }
                        }
                        // return params.data && params.data.editable ? 'boldCell' : ''; // Return boldCell class for editable rows
                    },
                    // editable: true
                },
            ]
        }
    ];
    
    
    
    var getdata = () => {

        var params = {
            'month': selectedfilters.datefilter,
            'transporter_name': selectedfilters.transfilter.value
        }
        // console.log(params, 'paramss')
        redirectURL.post('/consignments/gettptdata', params)
            .then((resp) => {
                var records = JSON.parse(resp.data.truck_availability);
                if (localStorage.getItem('role') == 'tpt') {
                    if (localStorage.getItem('tpt_name') != '' && localStorage.getItem('tpt_name') != 'undefined') {
                        // console.log(localStorage.getItem('tpt_name'))
                        records = records.filter(e => e.transporter_name == localStorage.getItem('tpt_name'))
                    }
                }
                const trans_names = groupBy(records, (rdata) => rdata.transporter_name);
                const currentMonths = groupBy(records, (rdata) => rdata.report_date);
                const finalData = [];
                const dateKeys = [...currentMonths.keys()];
                if (dateKeys.length > 0) {
                    dateKeys.forEach((month) => {
                        const row = {
                            report_date: month,
                        };
                        if (month) {
                            records.map((item) => {
                                if (month == item.report_date) {
                                    const transporterName  = item.transporter_name.trim().replace(/ /g, "").replace(/[^\w\s-]/g, '');
                                    // const transporterName = item.transporter_name.trim().replace(/ /g, "");
                                    row[`${transporterName}_mark`] = item.mark_availability;
                                    row[`${transporterName}_allocated`] = item.total_trucks_assigned;
                                    row[`${transporterName}_pending`] = item.truck_left_over;
                                    row[`${transporterName}_name`] = item.transporter_name
                                }
                            });
                        }
                        finalData.push(row);
                    });
                }
                setrowData(finalData);
                setgroupdata(trans_names);
            })
            .catch((error) => {
            });
    }

    var handleFilterOptions = (e) => {
        setfilters(prev => ({
            ...prev,
            transfilter: e
        }))
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        console.log(formattedDate, 'formattedDate')
        setfilters(prev => ({
            ...prev,
            datefilter: formattedDate
        }))
    };
    //     const getRandomColor = () => {
    //         const letters = '0123456789ABCDEF';
    //         let color = '#';
    //         for (let i = 0; i < 6; i++) {
    //             color += letters[Math.floor(Math.random() * 16)];
    //         }
    //         return color;
    //     };
    //     const setRandomColorRecursively = (columnDefs) => {
    //         columnDefs.forEach((columnDef) => {
    //             columnDef.cellStyle = { backgroundColor: getRandomColor() };
    //             if (columnDef.children) {
    //                 setRandomColorRecursively(columnDef.children);
    //             }
    //         });
    //     };
    //     function addColorStyle(sanitizedKey, color) {
    //         var style = document.createElement('style');
    //         style.type = 'text/css';
    //         style.innerHTML = `.header-${sanitizedKey}, .cell-${sanitizedKey} { background-color: ${color}; }`;
    //         document.head.appendChild(style);
    //     }
    //     const keys = [...groupdata.keys()];
    //     const colors = ['#FF5733', '#33FF57', '#5733FF', '#33A6FF', '#FF33E9'];

    //     keys.forEach(function (key, index) {
    //         var keyWithoutSpaces = key.trim().replace(/ /g, "");
    //     var sanitizedKey = keyWithoutSpaces.replace(/[^\w\s-]/g, '');

    //     // Use modulo to cycle through the colors array for each key
    //     var colorIndex = index % colors.length;
    //     var color = colors[colorIndex]; // Define the color for this iteration

    //     // Call the function to add the style tag for this group
    //     addColorStyle(sanitizedKey, color); // Apply the style

    //     var columnDefinition = {
    //         headerName: key,
    //         field: '',
    //         headerClass: [`header-${sanitizedKey}`, "cellstylegrid"],
    //         // Set color for the parent header and add other styles if needed
    //         cellStyle: { backgroundColor: color },
    //         children: [
    //             {
    //                 headerName: "Mark Availability",

    //                 field: keyWithoutSpaces + "_mark",
    //                 width: 130,
    //                 cellClass: `cell-${sanitizedKey}`,
    //                 editable: (params) => {
    //                     if (params.data.report_date) {
    //                         const currentWeekDates = getCurrentWeekDates();
    //                         return currentWeekDates.includes(params.data.report_date);
    //                     }
    //                     return false;
    //                 },
    //                 valueSetter: function (params) {
    //                     console.log(params, '225')
    //                     params.data[keyWithoutSpaces + "_mark"] = params.newValue
    //                 },
    //                 valueGetter: function (params) {
    //                     if (params.data && params.data[keyWithoutSpaces + "_mark"] !== undefined) {
    //                         return params.data[keyWithoutSpaces + "_mark"]
    //                     }
    //                     return null;
    //                 }
    //             },
    //             {
    //                 headerName: "Allocated",
    //                 field: keyWithoutSpaces + "_allocated",
    //                 width: 100,
    //                 cellClass: `cell-${sanitizedKey}`,
    //                 valueGetter: function (params) {
    //                     if (params.data && params.data[keyWithoutSpaces + "_allocated"] != undefined) {
    //                         return params.data[keyWithoutSpaces + "_allocated"]
    //                     }
    //                     return null;
    //                 }
    //             },
    //             {
    //                 headerName: "Left Over",
    //                 field: keyWithoutSpaces + "_pending",
    //                 width: 100,
    //                 cellClass: `cell-${sanitizedKey}`,
    //                 valueGetter: function (params) {
    //                     if (params.data && params.data[keyWithoutSpaces + "_pending"] != undefined) {
    //                         return params.data[keyWithoutSpaces + "_pending"]
    //                     }
    //                     return null;
    //                 }
    //             },
    //             {
    //                 headerName: "transporter name",
    //                 field: keyWithoutSpaces + "_name",
    //                 width: 100,
    //                 cellClass: `cell-${sanitizedKey}`,
    //                 hide: true,
    //                 valueGetter: function (params) {
    //                     if (params.data && params.data[keyWithoutSpaces + "_name"] != undefined) {
    //                         return params.data[keyWithoutSpaces + "_name"]
    //                     }
    //                     return null;
    //                 }
    //             },
    //         ]
    //     };
    //     columnwithDef.push(columnDefinition);
    // });
    const getRandomColor = () => {
        const colors = ['#98FF98', '#87CEEB', '#FA8072', '#008080', '#708090']; // Predefined colors
        return colors[Math.floor(Math.random() * colors.length)]; // Randomly pick one
    };

    const colors = ['#98FF98', '#87CEEB', '#FA8072', '#9FE2BF', '#CCCCFF']; // Predefined colors
// Function to add the color style to the document
function addColorStyle(keyWithoutSpaces, color) {
    var style = document.createElement('style');
    document.head.appendChild(style);
    // Add rule for background color
    style.sheet.insertRule(`.header-${keyWithoutSpaces}, .cell-${keyWithoutSpaces} { background-color: ${color}; }`, 0);
    // Add rule to remove header cell borders
    style.sheet.insertRule(`.ag-header-cell.header-${keyWithoutSpaces}, .ag-header-group-cell.header-${keyWithoutSpaces} { border-right: none !important; }`, 1);
}
          const keys = [...groupdata.keys()];
    keys.forEach(function (key, index) {
        var keyWithoutSpaces = key.trim().replace(/ /g, "").replace(/[^\w\s-]/g, '');
        var colorIndex = index % colors.length; // Use modulo to cycle through the colors array
        var color = colors[colorIndex]; // Pick the color based on the index
        // Add the color style
        addColorStyle(keyWithoutSpaces, color);
        var columnDefinition = {
            headerName: key,
            field: '',
            headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the parent header
            cellClass: `cell-${keyWithoutSpaces}`, // Apply the class to parent column cells
            children: [
                {
                    headerName: "Mark Availability",
                    field: keyWithoutSpaces + "_mark",
                    width: 130,
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    editable: (params) => {
                        if (params.data.report_date) {
                            const currentWeekDates = getCurrentWeekDates();
                            return currentWeekDates.includes(params.data.report_date);
                        }
                        return false;
                    },
                    valueSetter: function (params) {
                        console.log(params, '225')
                        params.data[keyWithoutSpaces + "_mark"] = params.newValue
                    },
                    valueGetter: function (params) {
                        if (params.data && params.data[keyWithoutSpaces + "_mark"] !== undefined) {
                            return params.data[keyWithoutSpaces + "_mark"]
                        }
                        return null;
                    }
                },
                               {
                    headerName: "Allocated",
                    field: keyWithoutSpaces + "_allocated",
                    width: 100,
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    valueGetter: function (params) {
                        if (params.data && params.data[keyWithoutSpaces + "_allocated"] != undefined) {
                            return params.data[keyWithoutSpaces + "_allocated"]
                        }
                        return null;
                    }
                },
                {
                    headerName: "Left Over",
                    field: keyWithoutSpaces + "_pending",
                    width: 100,
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    valueGetter: function (params) {
                        if (params.data && params.data[keyWithoutSpaces + "_pending"] != undefined) {
                            return params.data[keyWithoutSpaces + "_pending"]
                        }
                        return null;
                    }
                },
                {
                    headerName: "transporter name",
                    field: keyWithoutSpaces + "_name",
                    width: 100,
                    headerClass: `header-${keyWithoutSpaces}`, // Apply the class to the child header
                    cellClass: `cell-${keyWithoutSpaces}`,
                    hide: true,
                    valueGetter: function (params) {
                        if (params.data && params.data[keyWithoutSpaces + "_name"] != undefined) {
                            return params.data[keyWithoutSpaces + "_name"]
                        }
                        return null;
                    }
                },
                // ... same for other children
            ]
        };

        columnwithDef.push(columnDefinition);
    });
    const toggleDatePicker =()=>{
        setIsOpen(prevIsOpen => !prevIsOpen)
    }

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Truck Availability Report
                    </h1>
                </div>
                <div className="card m-10p">
                    <div className="card-body" id="counterCardBody">
                        <div className="row">
                            <div className="col-sm-8 checkForDateFilter_gap">
                                <div className="row">
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div onClick={toggleDatePicker}>
                                        <DatePicker
                                            ref ={datePickerRef}
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="MM/yyyy" // Format to display only month and year
                                            showMonthYearPicker
                                            minDate={new Date(1900, 0)} // Set the minimum year you want to allow
                                            maxDate={new Date(2100, 11)}
                                            readOnly
                                            open={isOpen}
                                            className="custom-datepicker"
                                            calendarClassName="custom-datepicker-dropdown"// Set the maximum year you want to allow
                                        />
                                        </div>
                                    </div>



                                    {(localStorage.getItem('role') == 'tpt') ? '' :
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <Select
                                                closeMenuOnSelect={true}
                                                name="filterModeType"
                                                // className="border-radius-0"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.9rem",
                                                        color: "#6e707e",
                                                        borderRadius: "11px",
                                                        height: "48px",
                                                        width: "260px",
                                                        // textAlign: "center",
                                                        // marginRight: '25px'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.9rem", // Adjust the font size here
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        width: "260px"
                                                    })
                                                }}
                                                onChange={handleFilterOptions}
                                                options={transList}
                                                value={selectedfilters.transfilter}
                                                required
                                            />
                                        </div>}
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <button
                                            onClick={getdata}
                                            className="btn btn-success"
                                        >
                                            Submit
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col textAlignRight">
                                        <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                                            Back
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ margin: '10px', marginTop: '12px', width: "100%", height: "478px" }}>
                            <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                                <AgGridReact
                                    ref={gridApi}
                                    columnDefs={columnwithDef}
                                    defaultColDef={{
                                        sortable: true,
                                        filter: true,
                                        editable: false,
                                        resizable: true,
                                        menuTabs: ['filterMenuTab']
                                        // rowHeight: 80,
                                    }}
                                    // paddingTop={10}
                                    // rowHeight={70}
                                    //  headerHeight = {70}
                                    // headerHeight={50}
                                    rowData={rowData}
                                    suppressRowClickSelection={true}
                                    // detailCellRendererParams={state.detailCellRendererParams}
                                    // frameworkComponents={{ ConsignmentTrucksLegs: ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    onGridReady={onGridReady}
                                    // onGridState={onGridState}
                                    enableRangeSelection={true}
                                    paginationPageSize={50}
                                    rowSelection={'multiple'}
                                    masterDetail={true}
                                    pagination={true}
                                    sideBar={{
                                        toolPanels: [
                                            {
                                                id: "columns",
                                                labelDefault: "Columns",
                                                labelKey: "columns",
                                                iconKey: "columns",
                                                toolPanel: "agColumnsToolPanel"
                                            },
                                            {
                                                id: "filters",
                                                labelDefault: "Filters",
                                                labelKey: "filters",
                                                iconKey: "filter",
                                                toolPanel: "agFiltersToolPanel"
                                            }
                                        ]
                                    }}
                                    statusBar={{
                                        statusPanels: [
                                            { statusPanel: "agFilteredRowCountComponent" },
                                            { statusPanel: "agSelectedRowCountComponent" },
                                            { statusPanel: "agAggregationComponent" }
                                        ]
                                    }}
                                    gridOptions={{
                                        icons: {
                                            // You can customize other icons as well
                                            next: `<img src="${customNextIcon}" alt="Next" />`,
                                            previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                            first: `<img src="${customFirstIcon}" alt="First" />`,
                                            last: `<img src="${customLastIcon}" alt="Last" />`,
                                        },
                                    }}
                                    onCellEditingStopped={handleCellValueChanged}
                                    // ref={gridRef}
                                    getRowStyle={(params) => ({ textAlign: 'left' })}
                                // context={{ onClickShowShipmentLegs, updateContainerDetails }}
                                />

                            </div>
                        </div>
						
                    </div>
                </div>
                {/* <div style={{ float: 'right', marginRight: '40px',marginBottom:'30px' }}>
                <button type="button" className="btn btn-success" onClick={submitdata}>Submit</button>
            </div> */}

            </div>
        </>
    )
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
const getCurrentWeekDates = () => {
    let currentDate = moment(); // Get the current date
    let endOfWeek = currentDate.clone().add(6, 'days'); // End of the week (1 week from today)

    let dates = []; // Array to store the dates
    for (let date = currentDate; date <= endOfWeek; date = date.clone().add(1, 'days')) {
        dates.push(date.format('DD-MMM-YYYY'));
    }
    return dates;
};



export default TptMasterComponent