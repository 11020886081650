import React, { useState, forwardRef, useImperativeHandle } from 'react';

const CustomerEditcharsanddigits = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    }
  }));

  const handleChange = e => {
    let inputValue = e.target.value;
    // Remove any non-alphabetic characters
    inputValue = inputValue.replace(/[^a-zA-Z]/g, '');
    // Limit the input to 4 characters
    inputValue = inputValue.slice(0, 4);

    let numericValue = e.target.value.replace(/\D/g, '');
    // Limit the numeric input to 7 digits
    numericValue = numericValue.slice(0, 7);

    setValue(inputValue + numericValue);
  };
  
  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
    />
  );
});

export default CustomerEditcharsanddigits;
