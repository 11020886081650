import React, { useEffect, useState } from "react";
import redirectURL from "../redirectURL";

const Mapcard2 = (props) => {
  const [state, setState] = useState({
    loadshow: "show-n",
    mapinfo: "",
    defTransitCoords: "",
    routeLatLngs: [],
    lastVesselLocation: "",
  });
  const [mapData,setMapData]=useState({
    container_no: [],
    mapinfo: {
      coords: [],
      ldb_route:[]
    }
  })

  // useEffect(() => {
    
  // }, [props]);
  useEffect(()=>{
    var params = {
      work_order_no: props.container.work_order_no,
      truck_no: props.container.truck_no,
      container_no: props.container.container_no
    };
    let promise=new Promise(
      function(myResolve,myReject){
        redirectURL.post("/exim/getMapData",params)
        .then(response=>{
          myResolve(response.data)
        })
        .catch(error=>myReject(error))
      })
      promise.then(function(data) {
        try{
          var records;
          var data;
          var allCoordinates = [];
          var allldbcoords = []
          var gps_route=""
          var ldb_route=""
            records=JSON.parse(data.gps_df)
            console.log(records[0],"rrrrrrrrrrrrr");
          
            if(records[0].hasOwnProperty("gps_route") && records[0].gps_route!=""){
              gps_route = JSON.parse(records[0].gps_route)
              gps_route.map((e) => {
                  var lt = e.latitude || e.lat || 0; 
                  var ln = e.longitude || e.lng || 0; 
        
                  allCoordinates.push({
                    lat: lt,
                    lng: ln,
                  });
                });

            }
            if(records[0].hasOwnProperty("ldb_route") && records[0].ldb_route!=""){
              ldb_route= JSON.parse(records[0].ldb_route)
              ldb_route.map((e) => {
                var lt = e.latitude || e.lat || 0; 
                var ln = e.longitude || e.lng || 0; 
      
                allldbcoords.push({
                  lat: lt,
                  lng: ln,
                });
              });
            }
          
          var truck_no;
          if (records.length > 0) {
            truck_no = records[0].truck_no;
          }
          console.log(allCoordinates, 'allCoordinates');
          console.log(allldbcoords, 'allldbcoords ');
          setMapData({
            container_no: truck_no,
                mapinfo: {
                  coords: allCoordinates,
                  ldb_route:allldbcoords
                }
          })
      }catch(err){
        console.log(err,"error");
      }
        
        // allCoordinates.push(...JSON.parse(filteredData[0].geoPoints[0].route));
       
      },
      function(error) {
        console.log(error);
      })
  },[])

        useEffect(()=>{
          renderMap();

        },[mapData])

        const initMap = () => {
          var currentwindow;
          var arr = mapData.mapinfo.coords;
          var arr3 = mapData.mapinfo.ldb_route
          console.log(arr, 'coords')
          if (arr && arr.length > 0) {
            var lt = arr[0].lat;
            var ln = arr[0].lng;
          } else {
            var lt = 28.4519751;
            var ln = 77.0310713;
          }
      
          var mapOptions = {
            zoom: 0,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels: true,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          };
      
          if (arr.length === 0) {
            mapOptions["center"] = new window.google.maps.LatLng(lt, ln);
            mapOptions.zoom = 3;
          }
          var grayscaleStyle = [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#dadada",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#c9c9c9",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
          ];
      
          var map = new window.google.maps.Map(
            document.getElementById("map"),
            mapOptions
          );
          map.setOptions({ styles: grayscaleStyle });
      
          var bounds = new window.google.maps.LatLngBounds();
          if (arr.length === 0) {
            bounds.extend(new window.google.maps.LatLng(29.55555, 87.88));
            bounds.extend(new window.google.maps.LatLng(19.999, 61.9555555));
          }
          var currentinfowindow = null;
          if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
              window.google.maps.event.addListener(
                map,
                "maptypeid_changed",
                function () {
                  var x = map.getZoom();
                  var c = map.getCenter();
                  window.google.maps.event.trigger(map, "resize");
                  map.setZoom(x);
                  map.setCenter(c);
                }
              );
              var line = new window.google.maps.Polyline({
                map: map,
                strokeColor: "#ff0000",
                strokeOpacity: 1.0,
                strokeWeight: 3,
                path: new window.google.maps.LatLng(arr[i].lat, arr[i].lng),
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 6,
                  fillColor: "#333333",
                  fillOpacity: 1,
                  strokeWeight: 0,
                },
              });
              for (i = 0; i < arr.length; i++) {
                var path = line.getPath().getArray();
                let latLng = new window.google.maps.LatLng(
                  arr[i].lat,
                  arr[i].lng
                );
                path.push(latLng);
                line.setPath(path);
              }
              var marker;
              try {
                var infowindow = new window.google.maps.InfoWindow();
                if (arr[0].lat != "" && arr[0].lat != undefined & arr[0].lng != "" && arr[0].lng != undefined) {
                  marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                    icon: require('../../assets/icons/truck-end.png'),
                    map: map,
                  });
                }
                if (arr[arr.length - 1].lat != "" && arr[arr.length - 1].lat != undefined & arr[arr.length - 1].lng != "" && arr[arr.length - 1].lng != undefined) {
                  marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[arr.length - 1].lat, arr[arr.length - 1].lng),
                    icon: require("../../assets/icons/destinations.png"),
                    map: map,
                  });
                }
      
              }
              catch (e) {
      
              }
            }
            arr.forEach((each, j) => {
              let loc2 = { lat: arr[j].lat, lng: arr[j].lng };
              let mappingCoord = `${arr[j].lat}${arr[j].lng}`;
              new window.google.maps.Polyline({
                map: map,
                path: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
                strokeColor: '#ff0000',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 6,
                  fillColor: "#ff0000",
                  fillOpacity: 1,
                  strokeWeight: 0,
                },
              });
              bounds.extend(new window.google.maps.LatLng(arr[j].lat, arr[j].lng));
            });
          }
      
          if (arr3.length > 0) {
            for (let i = 0; i < arr3.length; i++) {
              window.google.maps.event.addListener(
                map,
                "maptypeid_changed",
                function () {
                  var x = map.getZoom();
                  var c = map.getCenter();
                  window.google.maps.event.trigger(map, "resize");
                  map.setZoom(x);
                  map.setCenter(c);
                }
              );
              var line = new window.google.maps.Polyline({
                map: map,
                strokeColor: "#157254",
                strokeOpacity: 1.0,
                strokeWeight: 3,
                path: new window.google.maps.LatLng(arr3[i].lat, arr3[i].lng),
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 6,
                  fillColor: "#333333",
                  fillOpacity: 1,
                  strokeWeight: 0,
                },
              });
              for (i = 0; i < arr3.length; i++) {
                var path = line.getPath().getArray();
                let latLng = new window.google.maps.LatLng(
                  arr3[i].lat,
                  arr3[i].lng
                );
                path.push(latLng);
                line.setPath(path);
              }
              var marker;
              try {
                var infowindow = new window.google.maps.InfoWindow();
                if (arr3[0].lat != "" && arr3[0].lat != undefined & arr3[0].lng != "" && arr3[0].lng != undefined) {
                  marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr3[0].lat, arr3[0].lng),
                    icon: require("../../assets/icons/ship_icon2.png"),
                    map: map,
                  });
                }
                if (arr3[arr3.length - 1].lat != "" && arr3[arr3.length - 1].lat != undefined & arr3[arr3.length - 1].lng != "" && arr3[arr3.length - 1].lng != undefined) {
                  marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr3[arr3.length - 1].lat, arr3[arr3.length - 1].lng),
                    icon: require("../../assets/icons/destinations.png"),
                    map: map,
                  });
                }
      
              }
              catch (e) {
      
              }
            }
            arr.forEach((each, j) => {
              let loc2 = { lat: arr[j].lat, lng: arr[j].lng };
              let mappingCoord = `${arr[j].lat}${arr[j].lng}`;
              new window.google.maps.Polyline({
                map: map,
                path: new window.google.maps.LatLng(arr[j].lat, arr[j].lng),
                strokeColor: '#ff0000',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 6,
                  fillColor: "#ff0000",
                  fillOpacity: 1,
                  strokeWeight: 0,
                },
              });
              bounds.extend(new window.google.maps.LatLng(arr[j].lat, arr[j].lng));
            });
          }
      
          if (arr.length === 1) {
            bounds.extend(
              new window.google.maps.LatLng(arr[0].lat + 20, arr[0].lng + 30)
            );
            bounds.extend(
              new window.google.maps.LatLng(arr[0].lat - 20, arr[0].lng - 30)
            );
          }
          map.fitBounds(bounds);
        };
      
        const renderMap = () => {
          setState({
            ...state,
            //   loadshow: "show-m",
          });
          loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap"
          );
          window.initMap = initMap;
        };
        return (
          <div
            id="map"
            className="card mx-0 border border-gray"
            style={{
              width: "100%",
              height: "100%",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
          ></div>
        );
      };
      
      export default Mapcard2;
      
      function loadScript(url) {
        var index = window.document.getElementsByTagName("script")[0];
        var script = window.document.createElement("script");
        script.src = url;
        script.async = true;
        script.defer = true;
        index.parentNode.insertBefore(script, index);
      }
      