import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS, getDDMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

// import AndriodMapView from './andriodmapview';
import Mapcard2 from "./drawmap";
import redirectURL from "../redirectURL";
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
export default class MobileTripMaps extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			defTransitCoords:'',
            consignercords:'',
            consigneecords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true,
              menuTabs: ['filterMenuTab']
			},
			rowData: null,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },
			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"
		  },
		  rownode:[], 
		  googelRoutes:[], 
		  maptruckno:'',
		  dealer:'',
		  mapinfo:[],
		  defTransitCoords:'',
		  showdetails:'show-n',
		  showsecond:'show-n',
		  map_type_of_tracking:"",
		  iscustomer:0,
		};
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
	}
	componentDidMount = async() =>{
try {
    var path = window.location.pathname
		this.setState({
			loadshow:'show-m',
		});
        let segments = path.split('/');
let work_order_no  = segments[2].substring(1);  // Remove the ':' and get 5437963
let truck_No = segments[3].substring(1);
    var params = {
        work_order_no: work_order_no,
        truck_no: truck_No,
    };
    const resp = await redirectURL.post('/consignments/getmapdata', params);
    console.log(resp.data);
    var gps_route = resp.data.gps_route.coords;
    if (!Array.isArray(gps_route)) {
        gps_route = gps_route.replace(/NaN/g, "0");
        gps_route = JSON.parse(gps_route);
    }
    if (!gps_route || gps_route.length === 0) {
        this.setState({
            show:true,
            basicTitle:'No GPS Data Available',
            basicType:'danger',
            loadshow:'show-n',
            overly:'show-n'
        })
    } else {
        var allCoordinates = [];
        var allldbcoords = [];
        var gps_route =gps_route;
        var ldb_data = resp.data.ldb_route
        var ldb_route = ldb_data.ldb_route;
        console.log('gps_route', gps_route);
        console.log('ldb_route', ldb_route);
        if (ldb_route != '' && ldb_route != undefined) {
            if (ldb_route.length > 0) {
                ldb_route = JSON.parse(ldb_route)
                ldb_route.forEach((e) => {
                    var lt = e.latitude || e.lat || 0;
                    var ln = e.longitude || e.lng || 0;

                    allldbcoords.push({
                        lat: lt,
                        lng: ln,
                    });
                });
            } else {
                console.log('ldb_route is not an array or is empty.');
            }
        }
        console.log(gps_route, 'gps_routegps_route')
        if (gps_route != '' && gps_route != undefined) {
            if (gps_route.length > 0) {
                console.log(typeof gps_route, 'gps_routegps_route')
                gps_route.map((e) => {
                    var lt = e.latitude || e.lat || 0;
                    var ln = e.longitude || e.lng || 0;
                    allCoordinates.push({
                        lat: lt,
                        lng: ln,
                    });
                });
            } else {
                console.log('gps_route is not an array or is empty.');
            }
        }
        var truck_no;
        if (gps_route.length > 0) {
            truck_no = gps_route[0].truck_no;
        }
        console.log(allCoordinates, 'allCoordinates');
        this.setState({
            overly:'show-m',
            loadshow:'show-n'
        })
        // setStateForMap((prev) => ({
        //     ...prev,
        //     sliderRouteTranslate: "slider-translate-75p",
        //     showDiv: 'show-m',
        //     container_no: truck_no,
        //     mapinfo: {
        //         ...state.mapinfo,
        //         coords: allCoordinates,
        //         ldb_route: allldbcoords
        //     },
        //     mapdata:resp.data.gps_route
        // }));
    }
} catch (error) {
    console.error(error);
}
	}
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	};
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();	   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState); 
	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:'',
			showsecond:'show-n'
		});
	}
	onClickShowDetails(){
		this.setState({
			//loadshow:'show-m',
			overly:'show-m',
			showdetails:'show-m',
			inboxSlider:'slider-translatey',
			showsecond:'show-m'
		});
	}
    closeAlert = () => {
		this.setState({
			show: false,
			loadshow: 'show-n'
		});
	}
    render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
      console.log('397')
	  
        return (
           
			<div className="col-xl-12 col-md-12 n-p-0">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
					{this.state.alert}
					{(this.state.mapinfo != '')?
						<div className="col-xl-12 col-md-12 n-p-0">
                            <Mapcard2
                                mapFor={"truck"}
                                consignercords={this.state.consigner_coordinates}
                                tolls={this.state.tolls}
                                consigneecords={this.state.consignee_coordinates}
                                rownode={this.state.rownode}
                                truckno={this.state.maptruckno}
                                dealer={this.state.dealer}
                                mapinfo={this.state.mapinfo}
                                defTransitCoords={this.state.defTransitCoords}
								mobileMap={1} 
								/>
							<div className="andriomap-pos" onClick={this.onClickShowDetails.bind(this)}>
								<i className="icofont icofont-dotted-up ico-color"></i>
							</div>
							
							<div className={"divpos zindex999 "+(this.state.inboxSlider)}>
								<div className={"andriomap-pos2 pt-10px "+(this.state.showsecond)} onClick={this.onClickHideAll.bind(this)}>
									<i className="icofont icofont-dotted-down ico-color"></i>
								</div>
								<div className="crm-numbers mt-40p prel pb-0">
									<div className="col-xl-12 col-lg-12 textAlignCenter row">
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Truck No</label>
											<div>{this.state.routeTruck.truck_no}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Start Time</label>
											<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
										</div>
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">End Time</label>
											<div>{(this.state.routeTruck.endTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">GPS Distance</label>
											<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
										</div>
										{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
										:
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Google Distance</label>
											<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
										</div>
										}
										{(this.state.iscustomer != 1)?
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Tracking Type</label>
											{(this.state.map_type_of_tracking == 1)?
											<div>GPS Tracking</div>
											:
											<div>SIM Tracking</div>
											}
										</div> 
										:""}
									</div>
								</div>
							</div>
							<div className={"dataLoadpage " +(this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " +(this.state.loadshow)}>
								<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
							</div>
							{/* <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}
					
					 </div>
					
					:''}
							
			</div>
        )
    }
}
