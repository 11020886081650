

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import download from 'js-file-download';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class TransSeaImportsgridData extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
            resizable: true,
            menuTabs: ['filterMenuTab']
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        transRowData : [],
        invoiceData:[],
        pre_alert_zip_file:"",
        //components : {datepicker:getDatePicker()}
    }
}
componentDidMount(){

    var transRowData = this.props.transRowData;
    console.log(transRowData,"transRowData")
    this.setState({
        transRowData : transRowData,
    })
}
onRecommGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onRecommGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    invoiceData:[]
    
    });
    
}

showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

closeAlert = () => {
    this.setState({
        show: false
    });
}



viewDetails(e){
    if(e.colDef.field == "invoiceData")
    {
        var invData = [];
        if(e.data.invoiceData !=undefined && e.data.invoiceData !='')
        {
            var invoiceData = JSON.parse(e.data.invoiceData);
            if(invoiceData !=undefined && invoiceData.length > 0)
            {
                invData = invoiceData;
            }
        }
        var sliderTitle = e.data.job_no+" - Pre alert documents";
        var pre_alert_zip_file = "";
        if(e.data.pre_alert_zip_file !=undefined && e.data.pre_alert_zip_file !="")
        {
            pre_alert_zip_file = e.data.pre_alert_zip_file;
        }
        this.setState({
            overly :"show-m",
            invoiceData:invData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle,
            pre_alert_zip_file : pre_alert_zip_file,
        })
    }
    if(e.colDef.field == "other_docs")
    {
        var ringiFile = e.data.other_docs
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadRingiFile",rngParam).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}


handleDownloadZipFile = (idx) => {
    var ringiFile = idx;
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/storeSeaImportsPreAlertDocuments",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

  render() {
    var columnwithDefs = [    
        {
            field:'_id',
            headerName:'Edit',
            colId:"_id",
            //pinned:"left",
            width: 72,
            cellRenderer:function(params){
                let basestring = params.data._id;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<a href="/edittransporterseaimports/'+encryptedstring+'/2" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                return htmloption;
            }            
        },  
        {
            headerName: "Job No",
            field: "job_no",
            width:160,
            filter: true,
            resizable: true,           
        }, 
        {
            headerName: "Freight Forwarder",
            field: "assign_to_ff",
            width:160,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETA Indian Port",
            field: "eta_igi_airport",
            width:220,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.eta_igi_airport_date !='' && params.data.eta_igi_airport_date !=undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.eta_igi_airport_date);
                    }
                }
                catch(e){
                }
            }           
        },
        {
            headerName: "ATA Indian Port",
            field: "ata_igi_airport",
            width:220,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.ata_igi_airport_date !='' && params.data.ata_igi_airport_date !=undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.ata_igi_airport_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Final Port Of Destination",
            field: "final_port_of_destination",
            width:180,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.final_port_of_destination !='' && params.data.final_port_of_destination !=undefined && params.data.final_port_of_destination !="undefined")
                    {
                        return params.data.final_port_of_destination;
                    }
                }
                catch(e){
                }
            }           
        },
        {
            headerName: "OOC Date",
            field: "ooc_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.ooc_date !='' && params.data.ooc_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.ooc_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "BOE No",
            field: "boe_no",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BOE Date",
            field: "boe_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.boe_date !='' && params.data.boe_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.boe_date);
                    }
                }
                catch(e){
                }
            }            
        }, 
        {
            headerName: "Pre alert documents",
            field: "invoiceData",
            width:130,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                return htmloption;
            }         
        },
        {
            headerName: "Vehicle No",
            field: "vehicle_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Movement from Port Date",
            field: "movement_from_port_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.movement_from_port_date !='' && params.data.movement_from_port_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.movement_from_port_date);
                    }
                }
                catch(e){
                }
            }            
        }, 
        {
            headerName: "ETA MSIL",
            field: "eta_msil",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.eta_msil !='' && params.data.eta_msil !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.eta_msil);
                    }
                }
                catch(e){
                }
            }            
        },  
    ]

        
    return (
        <div class="row">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>
                <AgGridReact
                    modules={this.state.modules}
                    //columnDefs={columnwithDefs}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.transRowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onRecommGridReady}
                    onGridState={this.onRecommGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    onCellClicked={this.viewDetails.bind(this)}
                    // components={this.state.components}
                    />
                </div>
            </div>
            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">
                {this.state.sliderTitle}
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>                         
                <div className="container-fluid">
                    {(this.state.pre_alert_zip_file !='' && this.state.pre_alert_zip_file !=undefined) ?
                    <div className="row col-xl-12" style={{marginBottom:"1%"}}>
                        <div className="col-xl-3">
                            <button type="button" className="btn btn-warning" onClick={() => this.handleDownloadZipFile(this.state.pre_alert_zip_file)}>Download Docs</button>
                        </div>
                    </div>
                    :""} 
                    {(this.state.invoiceData.length > 0) ?
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table table-bordered tptinfo">
                                <thead>
                                    <tr>
                                        <th>Documents</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.invoiceData.map((e, index) => (
                                    <tr>
                                        <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                    </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ""}
                </div>
            </div>
        </div>
        </div>
        

    );
  }
}