import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from "../redirectURL";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Make sure to import CSS for the DatePicker
const WeeklyPlanvsActualDashboard = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    var [currentDate, setCurrentDate] = useState(new Date());
    var initialDate =`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
    var [datefilter, setDatefilter]= useState(initialDate);
    // console.log(datefilter, "datefilter01")
    var [plantCodes, setPlantCodes]= useState();
    var [monthOutlook, setMonthOutlook]= useState();
    var [actualOutlook, setActualOutlook]= useState();
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')

    const options = {
        chart: {
            type: 'column',
            height: 250
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: plantCodes,
            crosshair: true
        },
        yAxis: {
            min: 0,
            // max:1400,
            title: {
                text: 'Quantity'
            },
            labels: {
                formatter: function() {
                    return this.value
                }
            },
            // tickInterval: 200,
        },
        tooltip: {
            valueSuffix: ''
        },
        plotOptions: {
            column: {
                // pointPadding: 0.1,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,  // Enable data labels
                    allowOverlap:true,
                    //  formatter: function(){
                    //     if (this.y >= 1000000){
                    //         return (this.y / 1000000).toFixed(1) + 'M';
                    //     }
                    //     if (this.y >= 1000 ){
                    //         return (this.y /1000).toFixed(1)+ 'K';
                    //     }
                    //     return this.y;
                    // },
                    inside: false,  // Set to false to display labels outside the bar
                    style: {
                        color: 'black',  // Set text color, change as needed
                        textOutline: 'none'  // Remove or adjust the text outline
                    },
                    align:'center',
                    verticalAlign:'bottom',
                    y:-10,
                    x:0
                }
            },
        },
        colors: ["#0664AE", "black"],
        series: [
            {
                name: 'Planned',
                data: monthOutlook
            },
            {
                name: 'Actual',
                data: actualOutlook
            }
        ],
        credits:{
            enabled:false
        }
    };
    useEffect(() => {
        getData(datefilter);
    }, [])
    const getData =()=>{
        setloadshow('show-m')
        redirectURL.post('/consignments/getweeklyplanvsactualdata').then((resp) => {
            try{
                if(resp.data.status='Success'){
                    var data = resp.data.weekly_planned_actual
                    var records =JSON.parse(data)
                    if(records!='' && records!=undefined){
                        var plantCodesData = records.map(each=> formatDate(each.stuffing_date))
                        var monthOutlookData = records.map(each=> each.planned)
                        var actualOutlookData = records.map(each=> each.actual)
                        setPlantCodes(plantCodesData);
                        setMonthOutlook(monthOutlookData);
                        setActualOutlook(actualOutlookData);
                    // setrowData2(data)
                    }
                   
                }
            }
          catch(e){
            console.error('Error:', e);
            setPlantCodes([]);
            setMonthOutlook([]);
            setActualOutlook([]);

          }finally{
            setTimeout(() => {
                setloadshow('show-n')
            }, 3000);
          }
           
        }).catch(error => {
            console.error('Fetch error:', error);
            setPlantCodes([]);
            setMonthOutlook([]);
            setActualOutlook([]);
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
    }
    // console.log(plantCodes,"platCodes")
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        setDatefilter(formattedDate)
        // setfilters(prev => ({
        //     ...prev,
        //     datefilter: formattedDate
        // }))
        getData(formattedDate)
    };
    const handleKeyDown = (e) => {
        e.preventDefault(); // Prevent any keyboard input
      }
      const onClickHideAll = () => {
        setloadshow('show-n')
    }

    return (
        <>
        <div className='card-header2'>
        <div className='postioningHeader d-flex' style={{position:"relative"}}>
                <h2 className='heading3 mt-20p mb-20p' style={{width:"800px"}}>Weekly Planned vs Actual Plan
                </h2>
                {/* <div style={{width:"200px", position:"absolute", right:"-110px", top:"10px"}} className='positioningDate'>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    minDate={new Date(1900, 0)}
                    maxDate={new Date(2100, 11)}
                    onKeyDown={handleKeyDown} 
                    className="custom-datepicker"
                    calendarClassName="custom-datepicker-dropdown"
                />
                </div> */}
                </div>
            <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>
    );
}

function formatDate(inputDate) {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Options for toLocaleDateString to format the date as "07-May"
    const optionsDate = { day: '2-digit', month: 'short' };

    // Options for toLocaleDateString to format the day as "Tu"
    const optionsDay = { weekday: 'short' };

    // Getting the formatted date part
    const formattedDate = date.toLocaleDateString('en-GB', optionsDate);

    // Getting the formatted day part
    var formattedDay = date.toLocaleDateString('en-GB', optionsDay);
    formattedDay = formattedDay.slice(0,2);
    // Combine the parts to match the desired format: "07-May (Tu)"
    return `${formattedDate} (${formattedDay})`;
}

export default WeeklyPlanvsActualDashboard;
