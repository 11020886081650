var isNonEmpty = function(string) {
    if(string == '' || string == undefined)
    {
        return false;
    }
    else{
        return true;
    }
}
exports.isNonEmpty = isNonEmpty;

var isObjFieldNonEmpty = function(obj) {
    var isReturn = true;
    for(var oField in obj)
    {
        if(obj[oField] == "")
        {
            isReturn = false;
        }
    }
    return isReturn;
}
exports.isObjFieldNonEmpty = isObjFieldNonEmpty;