
import React from 'react';
 
const ProduceItems = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickShowProductItems(props);
    };
// console.log("props ", props)
    return (
        <div>
            <button type="button" 
            onClick={handleClick}
                target="_blank"
              className={"btn btn-danger nopadding font12"} 
              title="Produce">
                   Produce Items
            </button>
          
        </div>
    );
};

export default ProduceItems;
