import React from 'react'
// import PlantVsActualDashboard from './plantvsactualdashboard';
import DOStatus from './dostatus';
import PlantWorkOrderPerf from './plantworkorderperformance';
import TruckReporting from './truckReportingdashboard';
import GPSActiveStatusDashboard from './gpsactivedashboard';
import CHAWiseBLStatusDashboard from './chaWiseBlStatusdashboard';


const Dashboard2=() =>{
  return (
    <>
    <div className=''>
        <div className='row'>
            
            <div className='col-6 mb-20p'>
            <DOStatus/>
            </div>
            <div className='col-6 mb-20p'>
            <TruckReporting/>
            </div>
        </div>
        <div className='row'>
           
            <div className='col-6 mb-20p'>
            <CHAWiseBLStatusDashboard/>
            </div>
            <div className='col-6 mb-20p'>
            <GPSActiveStatusDashboard/>
            </div>
        </div>
        <div className='row'>
           
            <div className='col-12 mb-20p'>
            <PlantWorkOrderPerf/>

            </div>
        </div>
    </div>
   
    </>
  )
}

export default Dashboard2;