

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import CommentActions from "../layouts/commentsComponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Datetime from 'react-datetime';
import Select from 'react-select';

import redirectURL from "../redirectURL";
import withRouter from '../withRouter';
import download from 'js-file-download';
import Modal from "react-responsive-modal";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class TransporterSeaImports extends Component {
  constructor(props) {
    super(props);
      this.state = {
        pendingTransporterTranslate: "",
        inProcessTransporterTranslate:"",
        selectedRowData: {},
        mapViewSlider: "",
        pickupTime:"",
        truckNumber:"",
        attachmentsDoc: "",
        containerNumber: "",
        activeTab: "pending",
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
            resizable: true,
            menuTabs: ['filterMenuTab']
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        pending:"activet",
        closure:"",
        inProcess:"",
        invoiceData:[],
        pageType:1,
        pendingRowData: [],
        inProcessRowData: [],
        closureRowData: [],
        openModal: false,
        allTrucks: [],
        allRequirementIds: [],
        containerNumbers: 0,
        allSelectedTrucks: {},
        isButtonDisabled: "disabled"
    }
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}

componentDidMount(){    
    this.getBookingLevel();    
}

getBookingLevel = () => {
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });

    let pendingQuery = {
        transporter_flag: 1
    }

    redirectURL.post("/consignments/getBookingData",pendingQuery).then((resp)=>{
        console.log("getBookingData", resp.data.bookingData);
        this.setState({
            rowData: resp.data.bookingData,
            overly:"show-n",
            loadshow:"show-n",
            pendingRowData: resp.data.bookingData
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}

onLoadClosureData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    
    var supplier_code = localStorage.getItem("supplier_code");
    var params = {
        "transporter_code" : supplier_code
    }
    redirectURL.post("/consignments/getTransporterSeaImportsClousers",params)    
    .then((response) => {
        var records = response.data;
        console.log("records ", records)
        this.setState({
            rowData : records,
            overly:"show-n",
            loadshow:"show-n",
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

mapView = (rowData) => {

    this.setState({        
        mapViewSlider: "slider-translate-70p",
        overly:'show-m',
        truckNumber: rowData.truck_number,
        selectedRowData: rowData
    })
    this.renderMapDefault();

}

onClickHideAll(){
    this.state.allRequirementIds.forEach((each)=>{
        this.setState({
            [each]:""
        })
    })

    // $(".rdt input").val(" ")
    // $(".pickupDate+div > input.form-control").val("")

    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide: "",
        invoiceData: [],
        pendingTransporterTranslate: "",
        inProcessTransporterTranslate: "",
        mapViewSlider: "",
        pickupTime: "",
        containerNumber: "",
        attachmentsDoc: "",
        truckNumber: "",
        isButtonDisabled: "disabled"
    });  
}

openDocumentsSlider = (rowData,activeTab) => {
    console.log("rowData", rowData);

    this.setState({
        overly: "show-m",
        girdRowData: rowData
    })
    
    if(activeTab === "pending"){

        let params = {
            transporter_code: rowData.transporter_code
        }
    
        let bookingQuery = {
            booking_no: rowData.booking_no
        }
        redirectURL.post('/consignments/getLegsDataForBooking', bookingQuery).then((resp)=>{
            if(resp.data.status === "success"){
                // console.log(resp, "getLegsDataForBooking")
                let allIds = []
                for (let each of resp.data.legsData){
                    allIds.push(each.requirement_id)
                }
    
                this.setState({
                    allRequirementIds: allIds
                })
            }
        }).catch((err)=>{
            console.log(err, "getLegsDataForBooking err");
        })
    
    
        redirectURL.post("/consignments/getTrucks", params).then(async (resp)=>{
            console.log(resp, "success")
            let allTrucks = []
            if(resp.data.status === "success"){
                let {trucksData} = resp.data
                await trucksData.forEach((each)=>{
                    allTrucks.push({label: each.truck_no, value: each.truck_no})
                })
    
                this.setState({
                    allTrucks: allTrucks,
                    containerNumbers: rowData.container_quantity
                })
            }
        }).catch((err)=>{
            console.log("err",err);
        })


        this.setState({
            pendingTransporterTranslate: "slider-translate",
        });
    }

    if(activeTab === "inProcess"){
        this.setState({
            inProcessTransporterTranslate: "slider-translate",
            // documentUrl: rowData.document_url,
            truckNumber: rowData.truck_number
        });
    }

    if(activeTab === "closure"){
        // this.setState({
        //     pendingTransporterTranslate: "slider-translate",
        // });
    }
};

onClickTab(pageType){
    console.log("pageType",pageType);

    let pendingQuery = {
        transporter_flag: 1
    }

    let inProcessQuery = {
        transporter_flag: 2
    }

    let closureQuery = {
        transporter_flag: 7
    }

    if(pageType == "pending")
    {
        var pending = "activet";
        var inProcess = "";
        var closure = "";
        var activeTab = "pending"
        this.getBookingLevel();
        // this.setState({
        //     overly:"show-m",
        //     loadshow:"show-m",
        // })
        // redirectURL.post("/consignments/getBookingLegsData",pendingQuery).then((resp)=>{
        //     this.setState({
        //         rowData: resp.data.bookingData,
        //         overly:"show-n",
        //         loadshow:"show-n",
        //     })
        // })
        // .catch(function(e){
        //     console.log("Error ",e)
        // })
    } else if(pageType == "inProcess")
    {
        var pending = "";
        var inProcess = "activet";
        var closure = "";
        var activeTab = "inProcess"
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
        })
        redirectURL.post("/consignments/getBookingLegsData", inProcessQuery).then((resp)=>{
            this.setState({
                overly:"show-n",
                loadshow:"show-n",
                rowData: resp.data.bookingData
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    } else if(pageType == "closure")
    {
        var pending = "";
        var inProcess = "";
        var closure = "activet";
        var activeTab = "closure"
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
        })
        // var pageType = 3;
        redirectURL.post("/consignments/getBookingLegsData", closureQuery).then((resp)=>{
            // console.log("closureQuery", resp.data.bookingData);
            this.setState({
                overly:"show-n",
                loadshow:"show-n",
                rowData: resp.data.bookingData
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    this.setState({
        pending:pending,
        closure:closure,
        // pageType:pageType,
        inProcess: inProcess,
        activeTab: activeTab
    })
}

viewDetails(e){
    if(e.colDef.field == "invoiceData")
    {
        var invoiceData = JSON.parse(e.data.invoiceData);
        var sliderTitle = e.data.job_no+" - Pre alert documents";
        this.setState({
            overly :"show-m",
            invoiceData:invoiceData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    if(e.colDef.field == "other_docs")
    {
        var ringiFile = e.data.other_docs
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadRingiFile",rngParam).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadSeaImportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

loadScript = (url) => {

    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
  
}

renderMapDefault = () => {
    this.loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMapDefault"
    );
    window.initMapDefault = this.initMapDefault;
};
  
initMapDefault = () => {
var lt = 23.753838;
var ln = 77.850244;

var map = new window.google.maps.Map(document.getElementById("map_canvas"), {
    zoom: 4.5,
    center: new window.google.maps.LatLng(lt, ln),
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    /*mapTypeControl: true,
        gestureHandling: 'greedy',
        mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_RIGHT
        },
        disableDefaultUI: true,
        zoomControl: true*/
});

// var input = document.getElementById('search');
// var searchBox = new window.google.maps.places.SearchBox(input);
// map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

// Bias the SearchBox results towards current map's viewport.
// map.addListener('bounds_changed', function () {

//  searchBox.setBounds(map.getBounds());
// });
var bounds = new window.google.maps.LatLngBounds();
        
// new LatLng(23.63936, 68.14712), new LatLng(28.20453, 97.34466)

// bounds.extend(new window.google.maps.LatLng(23.63936, 68.14712));
// bounds.extend(new window.google.maps.LatLng(28.20453, 97.34466));

// bounds.extend(new window.google.maps.LatLng(20.474900,73.547326)); 
bounds.extend(new window.google.maps.LatLng(20.474900,73)); 
bounds.extend(new window.google.maps.LatLng(20.474900, 88.282247));
var markers = [];
const position = { lat: -25.344, lng: 131.031 };
// var marker= new window.google.maps.Marker({
//   map: map,
//   position: position,
//   title: "Uluru",
// })
const tourStops = []
// const tourStops = [
//   [{ lat: 27.583773, lng: 78.68602 }],
//   [{ lat: 11.00449, lng: 77.065727 }],
//   [{ lat: 28.38312, lng: 76.910705 }],
//   [{ lat: 26.235873, lng: 73.081657 }],
//   [{ lat: 28.417998, lng: 76.866448 }],
//   [{ lat: 26.875238, lng: 79.319902 }],
//   [{ lat: 23.452338, lng: 71.994112 }],
//   [{ lat: 31.300238, lng: 75.644278 }],
//   [{ lat: 28.6937033, lng: 77.5910566 }],
//   [{ lat: 27.3061366, lng: 79.2138166 }],
// ];
const infowindow = new window.google.maps.InfoWindow();
var image = require("../../assets/icons/truck_null.png");
tourStops.forEach(([position], i) => {
    const marker = new window.google.maps.Marker({
    position,
    map,
    // title: `${i + 1}. ${title}`,
    // label: `${i + 1}`,
    optimized: false,
    icon: image,
    });

    window.google.maps.event.addListener(
    marker,
    "mouseover",
    (function (marker) {
        return function () {
        //console.log("Arr ",marker)
        var contentarr = [];
        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
        var header = "Current Status";
        contentarr.push({ key: "Time ", value: "0000" });
        contentarr.push({ key: "Speed ", value: " KMPH" });

        // var contentString = infoBox("", header, contentarr, "ibl");

        // infowindow.setContent(contentString);
        // currentwindow = infowindow;
        infowindow.open(map, marker);
        $(".gm-ui-hover-effect").hide();
        //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
        };
    })(marker)
    );
    window.google.maps.event.addListener(marker, "mouseout", function () {
    infowindow.close();
    });
    window.google.maps.event.addListener(infowindow, "domready", function () {
    $(".gm-ui-hover-effect").hide();
    });
    // marker.addListener("click", () => {
    // infoWindow.close();
    //   infoWindow.setContent(marker.getTitle());
    //   infoWindow.open(marker.getMap(), marker);
    // });
});
};


changeAttachmentsFileHandler(event) {
    const file = event.target.files[0];
    let validFileExtensions = [
    //   "png",
    //   "jpeg",
    //   "gif",
    //   "bmp",
      "pdf",
    //   "csv",
    //   "xlsx",
    //   "mp4",
    //   "webm",
    //   "mp3",
    ];
    const documentExtension = file.name.split(".").pop();

    if (validFileExtensions.includes(documentExtension)) {
      if (file) {
        this.setState({
          attachmentsDoc: file,
          documentExtension: documentExtension,
        });
      }else{
        this.setState({
            attachmentsDoc: "",
            documentExtension: "",
          });
      }
    } else {
      document.getElementById("attachmentsDoc").value = null;
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Invalid file format.",
      });
    }
}

handleTransporterPendingDetails = (e) => {

    if(Object.keys(this.state.allSelectedTrucks).length === this.state.allRequirementIds.length){
        let params = {
            all_selected_trucks: this.state.allSelectedTrucks,
            all_requirement_ids: this.state.allRequirementIds,
            booking_no: this.state.girdRowData.booking_no
        }
    
        redirectURL.post('/consignments/updatePendingTransporterDetails', params).then((resp)=>{
            if (resp.data.status === "success") {
                this.gridApi.updateRowData({remove : [this.state.girdRowData]})
                this.setState({
                  girdRowData: "",
                },()=>{
                    this.onClickHideAll();
                    // this.onClickTab.bind(this,"pending")
                });
                
            }
            console.log("updatePendingTransporterDetails resp",resp.data);
        }).catch((err) => {
            console.log("updatePendingTransporterDetails err", err);
        })
        
    }else{
        this.setState({
            show: true, 
            basicType: "danger",
            basicTitle: "All truck numbers are required"
        })
    }
    
}

handlerpickupTimeTime = (event) => {

    var d = new Date(event._d);
    var pickupdate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    this.setState({
        pickupTime: pickupdate
    })    
}

handleTransporterInprocessDetails = () =>{
    
    let formattedDate = moment(this.state.pickupTime).format('YYYY-MM-DD HH:mm:ss')

    const formData = new FormData();
    formData.append("container_number", this.state.containerNumber);
    formData.append("pickup_date_time", formattedDate);
    formData.append("lr_file", this.state.attachmentsDoc);  
    formData.append("requirement_id",this.state.girdRowData.requirement_id);
    formData.append("booking_no",this.state.girdRowData.booking_no);

    redirectURL.post('/consignments/updateInprocessTransporterDetails', formData).then((resp)=>{
        if (resp.data.status === "success") {
            document.getElementById("attachmentsDoc").value = null;
            this.gridApi.updateRowData({remove : [this.state.girdRowData]})
            this.setState({
                pickupTime: "",
            })
            this.onClickHideAll();
            this.onClickTab.bind(this,"inProcess")
        }
        console.log("updatePendingTransporterDetails resp",resp.data);
    }).catch((err) => {
        console.log("updatePendingTransporterDetails err", err);
    })

}

newFilterForTrucks = (filterType, selOption) => {
    console.log("filterType", filterType);
    console.log("selOption", selOption);
    this.setState({ [filterType]: selOption }, ()=>{
    
    let leftTrucks = this.state.allTrucks.filter((each)=>{
        if(each.value !== selOption.value){
            return each
        }
    })

    this.setState((prevState) => ({
        allSelectedTrucks: {
            ...prevState.allSelectedTrucks,
            [filterType]: this.state[filterType].value,
        },
        allTrucks: leftTrucks
    }),()=>{
        // console.log("allSelectedTrucks", this.state.allSelectedTrucks);
        if(Object.keys(this.state.allSelectedTrucks).length === this.state.allRequirementIds.length){
            this.setState({
                isButtonDisabled: null
            })
        }
    });
    })
}

previewDocument = (document) => {
    console.log("document", document);
    this.setState({
      openModal: true,
      documentUrl: document.lr_file_url,
      documentType: "pdf",
    });
};

closeModal = () => {
this.setState({
    openModal: false,
});
};

  render() {
    var pageType = this.state.pageType;
    console.log(pageType,"pageType");
    if(pageType == 2)
    {
        var editHide = true;
    }
    else
    {
        var editHide = false;
    }

    var columnwithDefs = [    
        {
            headerName: "Update",
            field: "",
            width: 150,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>{return <button className='btn btn-primary' 
            style={{ fontSize:"12px", width:"125px", cursor:"pointer" }} 
            onClick={this.openDocumentsSlider.bind(this,params.data, this.state.activeTab)}>Update</button>}
        },
        {
            headerName: "Work Order No",
            field: "booking_no",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "CHA Entity",
            field: "cha_entity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Destination",
            field: "destination_country",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Transporter",
            field: "transporter_assigned",
            width: 200,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Item Quantity",
            field: "item_quantity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Size",
            field: "container_size",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Quantity",
            field: "container_quantity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Plant Code",
            field: "plant_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Destination Port",
            field: "destination_port",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Buyer Code",
            field: "buyer_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Type",
            field: "container_type",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Planned Date",
            field: "stuffing_planned_date",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Loading Port",
            field: "loading_port",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Date",
            field: "created_at",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params) {
                try {
                    if(params.data.created_at !== "" && params.data.created_at !== undefined) {
                        return moment.parseZone(params.data.created_at).format("DD-MM-YYYY")
                    }
                    else {
                        return "";
                    }
                } catch (error) {
                    // Handle error if needed
                }
            }          
        }
    ] 

    let mapElement = {
        headerName: "Map",
        field: "",
        width: 60,
        filter:false,
        floatingFilter:false,
        cellRendererFramework: (params)=>{
            return <button className='btn btn-primary' 
            style={{ cursor:"pointer" }} 
            onClick={this.mapView.bind(this, params.data)}><i className='icofont icofont-map-pins f20'></i></button> 
        }
    }

    if(this.state.activeTab === "closure"){
        columnwithDefs.shift()

        columnwithDefs.splice(1, 0,{
            headerName: "Truck Number",
            field: "truck_number",
            width: 150,
            filter:false,
            floatingFilter:false
        })

        columnwithDefs.splice(2, 0,{
            headerName: "Container Number",
            field: "container_number",
            width: 150,
            filter:false,
            floatingFilter:false
        })

        // columnwithDefs.splice(2, 0,{
        //     headerName: "Pickup Date",
        //     field: "pickup_date_time",
        //     width: 150,
        //     filter:false,
        //     floatingFilter:false,
        //     valueGetter: function(params) {
        //         try {
        //             if(params.data.pickup_date_time) {
        //                 return moment.parseZone(params.data.pickup_date_time).format("DD-MM-YYYY")
        //             }
        //             else {
        //                 return "";
        //             }
        //         } catch (error) {
        //             // Handle error if needed
        //         }
        //     }      

        // })

        columnwithDefs.unshift(mapElement)

        columnwithDefs.unshift({
            headerName: "LR",
            field: "",
            width: 150,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>{
                if(params.data.lr_file_url){
                    return <button className='btn btn-primary' 
                    style={{ fontSize:"12px", width:"125px", cursor:"pointer" }} 
                    onClick={this.previewDocument.bind(this, params.data)}>View</button>
                }else{
                    return <button className='btn btn-primary'
                    style={{ fontSize:"12px", width:"125px", cursor:"not-allowed" }} 
                    disabled={true}>View</button>
                }
            }    
        })


    }

    if(this.state.activeTab === "inProcess"){
        columnwithDefs.splice(1, 0,{
            headerName: "Truck Number",
            field: "truck_number",
            width: 150,
            filter:false,
            floatingFilter:false
        })

        columnwithDefs.unshift(mapElement)
    }


    return (
      <div class="container-fluid">
          
          <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Transportation Updates </span>
          </h5>
          {/* <button className="btn btn-success" onClick={this.addNewRow.bind(this)}>Add New Holiday</button> */}
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <Modal open={this.state.openModal} onClose={this.closeModal}>
          <div aria-disabled={true}>
            <h6
              className="bg-primary text-white"
              style={{
                paddingTop: "10px",
                height: "38px",
                paddingLeft: "253px",
              }}
            >
              File Preview
            </h6>
            {this.state.documentType === "pdf" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "csv" ||
              this.state.documentType === "xlsx" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "png" ||
              this.state.documentType === "jpeg" ||
              this.state.documentType === "jpg" ? (
              <div className="p-3">
                <img
                  src={this.state.documentUrl}
                  // frameborder="1"
                  height="500px"
                  width="100%"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>

        <div className="f16" style={{marginBottom:"10px"}}>
            <ul className="d-tabs">
                <li onClick={this.onClickTab.bind(this,"pending")} className={"lttabs "+(this.state.pending)}>
                    <button type="button" className="btn">Truck Allocation</button>
                </li>

                <li onClick={this.onClickTab.bind(this,"inProcess")} className={"lttabs "+(this.state.inProcess)}>
                    <button type="button" className="btn">Container Allocation</button>
                </li>

                <li onClick={this.onClickTab.bind(this,"closure")} className={"lttabs "+(this.state.closure)}>
                    <button type="button" className="btn">Closure</button>
                </li>
            </ul>
        </div>
        {/* In pending transporter details slider */}
        <div
            className={"slide-r " + this.state.pendingTransporterTranslate}
            style={{ overflow: "auto" }}
            >
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6>
                Update Transporter Details
                <span
                    className="float-right closebtn"
                    style={{ marginRight: "25px" }}
                    onClick={this.onClickHideAll.bind(this)}
                >
                    X
                </span>
                </h6>

                <div class="" style={{color:"black"}}>
                        <div className="col form-group mt-20p">
                            {
                                this.state.allRequirementIds.map((each, index)=> {
                                    return <div className='my-4' key={each}>
                                        <label className='mb-2' htmlFor="">Truck Number {parseInt(index)+1}</label>
                                        <Select 
                                            value={this.state[each]} 
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            className="border-radius-0" 
                                            onChange={this.newFilterForTrucks.bind(this, each)} 
                                            style={{ borderRadius: "09px" }} 
                                            options={this.state.allTrucks} 
                                            required 
                                        />
                                    </div>
                                })
                            }
                        </div>
                        <div className="col form-group mt-20p">
                            <button type="submit" className='btn btn-primary' 
                            onClick={this.handleTransporterPendingDetails.bind(this)}
                            disabled={ this.state.isButtonDisabled ? true: false}
                            >Submit</button>
                        </div>
                </div>
            </div>
        </div>

        {/* In process transporter details slider */}
        <div
            className={"slide-r " + this.state.inProcessTransporterTranslate}
            style={{ overflow: "auto" }}
            >
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6>
                Update Transporter Details
                <span
                    className="float-right closebtn"
                    style={{ marginRight: "25px" }}
                    onClick={this.onClickHideAll.bind(this)}
                >
                    X
                </span>
                </h6>

                <div class="" style={{color:"black"}}>

                    <h6 className='p-2 pt-4'>Truck No: {this.state.truckNumber}</h6>
                    <div className="col form-group mt-20p">
                        <label htmlFor="containerNumber">Container Number</label>
                        <input
                            name="containerNumber"
                            className="form-control mr-3 mt-1"
                            id="containerNumber"
                            placeholder="Container Number"
                            style={{ width: "93%" }}
                            onChange={(e) =>
                            this.setState({
                                containerNumber: e.target.value,
                            })
                            }
                            value={this.state.containerNumber}
                            required
                        />
                    </div>

                    {/* <div className="col form-group mt-20p">
                        <label className='pickupDate'>Pickup Date:</label>                                            
                            <Datetime
                                name="rfqEndDate"
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                value={this.state.pickupTime}
                                isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                // timeFormat="HH:mm:ss"
                                onChange={this.handlerpickupTimeTime.bind(this)}
                                required
                            />
                    </div> */}

                    <div className="col form-group mt-20p">
                        <label htmlFor="">LR Details</label>
                        <input
                            type="file"
                            name="attachmentsDoc"
                            style={{ fontSize: "0.8rem" }}
                            id="attachmentsDoc"
                            onChange={this.changeAttachmentsFileHandler.bind(this)}
                            required
                        />
                    </div>

                    
                    <div className="col form-group mt-20p">
                        <button type="submit" className='btn btn-primary' 
                        onClick={this.handleTransporterInprocessDetails.bind(this)}
                        disabled={(this.state.containerNumber && this.state.pickupTime) ? false: true}
                        >Submit</button>
                    </div>
                </div>
            </div>
        </div>
        
        {/* Map View */}
        <div
            className={"slide-r " + this.state.mapViewSlider}
            style={{ overflow: "auto"}}
            >
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6 style={{textAlign:"center"}}>Work Order No: {this.state.selectedRowData.booking_no}                
                <span
                    className="float-right closebtn"
                    style={{ marginRight: "25px" }}
                    onClick={this.onClickHideAll.bind(this)}
                >
                    X
                </span>
                </h6>

                <div id="map_canvas" style={{width:"95%",height:"70vh"}}></div>
                <div style={{color: "black"}}>
                    <h5 className='bg-primary text-white p-2'>Booking Information</h5>
                    <div className="col-xl-12 col-lg-12 row">
                        <div className="col route-block">
                            <label htmlFor="" className="sidebar-label">Truck No</label>
                            <div>{this.state.selectedRowData.hasOwnProperty("truck_number")? this.state.selectedRowData.truck_number: ""}</div>
                        </div>

                        <div className="col route-block">
                            <label htmlFor="" className="sidebar-label">Container No</label>
                            <div>{this.state.selectedRowData.hasOwnProperty("container_number")? this.state.selectedRowData.container_number: ""}</div>
                        </div>

                        {/* <div className="col route-block">
                            <label htmlFor="" className="sidebar-label">Pickup Date</label>
                            <div>{this.state.selectedRowData.hasOwnProperty("pickup_date_time")? this.state.selectedRowData.container_number: ""}</div>
                        </div> */}

                        <div className="col route-block">
                            <label htmlFor="" className="sidebar-label">Loading Port</label>
                            <div>{this.state.selectedRowData.loading_port}</div>
                        </div>

                        <div className="col route-block">
                            <label htmlFor="" className="sidebar-label">Destination Port</label>
                            <div>{this.state.selectedRowData.destination_port}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
            <AgGridReact
                modules={this.state.modules}
                columnDefs={columnwithDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                    context: { componentParent: this }
                }}
                // components={this.state.components}
                enableRangeSelection= {true}
                onCellClicked={this.viewDetails.bind(this)}
                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                />
        </div>
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">
                {this.state.sliderTitle}
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                {(this.state.invoiceData.length > 0) ?                         
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered tptinfo">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.invoiceData.map((e, index) => (
                                        <tr>
                                            <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                        </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                : ""}
            </div>
        </div>

        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
export default withRouter(TransporterSeaImports);