import React, { useState, useEffect, useRef } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import PTData from '../../assets/json/payment_term_description.json'
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY,getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM } from '../common/utils';
import moment from "moment";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import CountryFlagComponent from "./countryFlag";
import UpdateIBTptAllocation from "./updateIBTptAllocationBtn";
import UpdateIBTptAllocation1 from "./updatetptallocation1";
import ViewDOdetailsComponent from "./viewDOdetails";

import download from 'js-file-download';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
const IBUpdatesComponent = (props) => {
    const gridApi = useRef(null);
    // var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [loadshow, setloadshow] = useState('show-n')
    var [ptd, setptd] = useState(PTData.PTData ?? [])
    var [overly, setoverly] = useState('show-n')
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    const [showAddForm3, setshowAddForm3] = useState('')

    var [check, setcheck] = useState(0)
    var [wrdo, setwrdo]=useState();
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [counters, setCounters] = useState({
        vessel_avai: []
    })
    var [transportersList, settransportersList] = useState([])
    var [workOrderList, setworkOrderList] = useState([])

    const [isClicked, setIsClicked] = useState({
        vessel_avail: false,
        uploadDo: false
    });
    var [ files1, setFile1] = useState({
        file1 : '',
        file2 :''
    })
    const [showAddForm, setshowAddForm] = useState('')
    const [showAddForm1, setshowAddForm1] = useState('')


    const [manualUploadDetails, setmanualUploadDetails] = useState({
        wrno:'',
        transporter: '',
    })
    var [selected_row,setselected_row] = useState({})
    var [checkresetfilter,setcheckresetfilter] = useState(0)
    var [checkresetdatefilters,setcheckresetdatefilters]= useState(0)

    var [reloadcheck,setreloadcheck] = useState(false)
    var [tabDatafortpt,settabDatafortpt] = useState({
        tpt_new:[],
        tpt_planned:[],
        tpt_modified:[]
    })
    var [classForTab, setclassforTab] = useState({
        planned: 0,
        new: 1,
        modified: 0,
    })
    var [refreshSelected, setRefreshSelected ] = useState(0);

    const [summaryViewcols, setsummaryViewcols] = useState([
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            cellEditor:DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,
            
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "vessel_etd_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.vessel_etd_date != "" && params.data.vessel_etd_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.vessel_etd_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 120,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 180,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer:CountryFlagComponent
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 180,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            resizable: true,
        },
        {
            headerName: "Rejected By",
            field: "rejected_tpt",
            width: 200,
            resizable: true,
        },
        
        {
            headerName: "DO Copy",
            field: "do_copy",
            width: 100,
            resizable: true,
            cellRenderer:ViewDOdetailsComponent
        },
    ])
    useEffect(()=>{
        if (showAddForm ==='slide30'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showAddForm])
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
        loadDateTimeScript();
    }, [gridApi]);
    useEffect(() => {
        if (props.check == 2) {
          const validCols = ["Work Order No","Transporter Code"];
          const bulkUploadFields = [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Transporter Code', inputName: 'transporter_code', required: true },
        ]
          const apiForUpload = 'updateTransporterDetailToWO';
          const csvfilename = '../../assets/json/ib_based_tpt_data.csv'
          const columndefs = [
            {
                headerName: "Work Order No",
                field: "work_order_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 120,
                filter: true,
                resizable: true,
            },
          ]
          props.uploadBulkData(validCols, bulkUploadFields, apiForUpload,columndefs);
        }
      }, [props.check]);
    useEffect(() => {
        setloadshow("show-m")
        loadDateTimeScript();
        var records = props.data 
        //console.log(records,'919191')
        var new_tpt = records.ib_tpt_allocation_new
        var planned_tpt =records.ib_tpt_allocation_plannd
        var modified_tpt = records.ib_tpt_allocation_modified
        settabDatafortpt({
            tpt_new:sortByCreatedDate(new_tpt),
            tpt_planned:sortByCreatedDate(planned_tpt),
            tpt_modified:sortByCreatedDate(modified_tpt)
        })
        sortByCreatedDate(new_tpt)
        setrowData(new_tpt)
        setoriginalData(new_tpt)
        redirectURL.post('/consignments/getTransporters').then((resp)=>{
            var trucksrecord = resp.data
            var tpt = []
            trucksrecord.map((e) => {
                    tpt.push({ label: e.transporter_name, value: e.transporter_code })
                })
                //console.log(tpt,'tpt')
                tpt = removeDuplicates(tpt,'label')
                //console.log(tpt,'tptafter')
                settransportersList(tpt)
        })
        setloadshow("show-n");
    }, [props.data])
    var typeofselection = [
        { label: 'Ship To Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' }, { label: 'CHA', value: 'cha' },
        { label: 'Plant', value: 'plant' }, { label: 'Production Date', value: 'production_date' },
        { label: 'Work Order No', value: 'work_order_no' },
    ]
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/ibtptnew' })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
            })
    }
    const onClickHideManualUpload3 = () => {
        setshowAddForm3('')
        setoverly('show-n')
       


    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/ibtptnew'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/ibtptnew',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }

    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const changeHandleDate = (e) => {
        var d = moment(e._d);
        var formattedDate = d.format('DD-MM-YYYY');
        return formattedDate
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'production_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const onClickAcknowledgeBtn=(rowdata)=>{
        var row = rowdata.data 
        var params = {work_order_no:row.work_order_no,unsetField:'ib_tpt_modified',setField:'ib_tpt_planned',record:row}
        // redirectURL.post('/consignments/acknowledgeData',params).then((resp)=>{
        //     if(resp.data.status=='Success'){
        //         setshow(true)
        //             setbasicTitle("Acknowledgement successful")
        //             setbasicType("success")
        //             props.onRequest()
        //             props.onReloadData(true);
        //     }
        // })
        redirectURL.post('/consignments/acknowledgeforupdatetpt',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                    setbasicTitle("Acknowledgement successful")
                    setbasicType("success")
                    setclassforTab(
                        {
                            planned: 0,
                            new: 1,
                            modified: 0,
                        }
                    )
                    props.onRequest()
                    props.onReloadData(true);
            }
        })
    }
    const onClickDOdetails=(params)=>{
        //console.log('163',params)
        setshowAddForm3("slide33")
        //console.log(params.data.attachmentFileName)
        setwrdo(params.data.work_order_no)
        //console.log(params.data.additionl_do_copy_arr);
        setFile1({file1:params.data.uploaded_file,
            file2:params.data.additionl_do_copy_arr
        })
    }
    const onClickuploadtptBtn=(e)=>{
        setselected_row(e.data)
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onClickuploadtptBtn1=(e)=>{
        setselected_row(e.data)
        setshowAddForm1("slide30")
        setoverly("show-m")
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno:{label:e.data.work_order_no,value:e.data.work_order_no},
            transporter:{label:e.data.transporter_name,value:e.data.transporter_name}
        }))

    }
    const getRowClass = (params)=>{
        //console.log(params,'185')
        if(params.data && (params.data.modified_plant==1||params.data.cha_vessel_modified==1||params.data.ib_stuffing_modified==1||params.data.cha_do_modified==1)){
            return { backgroundColor: '#FFB9B9' }; 
        }
        else if(params.data && params.data.transporter_rejected==1&&params.data.ib_tpt_new==1){
            return {backgroundColor:'#FF7F7F'}
        }
        return null
    }
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const handleInputfilter = (e) => {
        const val = e.target.value.trim().toUpperCase(); // Trim and convert input value to uppercase
        const typeoffield = selectedval.dropdownval.value;
        let filteredData;

        if (typeoffield === 'work_order_no') {
            if (val !== '') {
                filteredData = originalData.filter((each) => {
                    return each.work_order_no.includes(val);
                });
            } else {
                filteredData = originalData;
            }
        } else {
            // Handle other field types if needed
        }

        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
        setrowData(filteredData);

    }
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))

    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if(val!='' && val!=undefined){
            if (typeoffield == 'ship_to_country') {
                var filteredData = originalData.filter(item => item.ship_to_country == val)
            }
            else if (typeoffield == 'work_order_no') {
                if (val != '' && val != undefined) {
                    filteredData = originalData.filter(item => item.work_order_no == val);
                }
                else {
                    filteredData = originalData
                }
            }
            else if (typeoffield == '') {
                if (val != '' && val != undefined) {
                    filteredData = originalData.filter(item => item.work_order_no == val);
                }
                else {
                    filteredData = originalData
                }
            }
            else if (typeoffield == 'pod') {
                var filteredData = originalData.filter(item => item.pod == val)
            }
            else if (typeoffield == 'plant') {
                var filteredData = originalData.filter(item => item.plant == val)
            }
            else if (typeoffield == 'cha') {
                var filteredData = originalData.filter(item => item.cha == val)
            }
            else if (typeoffield == 'variant') {
                var filteredData = originalData.filter(item => item.variant == val)
            }
            //console.log(filteredData)
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
        else{
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    const onclickTab = (e) => {
        //console.log(tabDatafortpt.tpt_modified,"modifieddsgfdhg")
        if (e == 'New') {
            setclassforTab({
                new:1,
                planned:0,
                modified:0
            })
            props.setcheckforbulk(true)

            setrowData(tabDatafortpt.tpt_new)
            setoriginalData(tabDatafortpt.tpt_new)
        }
        else if (e == 'Planned') {
            setclassforTab({
                new:0,
                planned:1,
                modified:0
            })
            props.setcheckforbulk(true)


            setrowData(tabDatafortpt.tpt_planned)
            setoriginalData(tabDatafortpt.tpt_planned)
        }
        else if (e == 'Modified') {
            setclassforTab({
                new:0,
                planned:0,
                modified:1
            })
            props.setcheckforbulk(false)
            setrowData(tabDatafortpt.tpt_modified)
            setoriginalData(tabDatafortpt.tpt_modified)
        }
    }
    const onAcknowledgeSuccess = () => {
        setrowData(tabDatafortpt.tpt_modified)
    }

    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        var to_date = woReleaseDate.wo_release_to_date
        if(from_date!='' && from_date!=undefined && from_date!=null && to_date!='' && to_date!=undefined && to_date!=null){
            from_date = moment(from_date, 'DD-MM-YYYY')
            to_date = moment(to_date, 'DD-MM-YYYY')
            var selectedOpt = selectedval.dropdownval.value
            if(selectedOpt=='production_date'){
                var filteredData = originalData.filter(item => {
                    var itemDate = getHyphenDDMMYYYY(item.production_date)
                    itemDate = moment(itemDate, 'DD-MM-YYYY')
                    //console.log(itemDate,'182')
                    return itemDate.isBetween(from_date, to_date, null, '[]')
                });
                setrowData(filteredData)
                setRefreshSelected(0);
                setcheckresetdatefilters(1);
            }
        }
        else{
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
        
    }
    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 120,
            filter: true,
            resizable: true,
            // cellRenderer:CountryFlagComponent
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
          },
          {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
          },
          {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
          },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            valueGetter: function (params) {
                try {
                    //console.log(ptd, "ptd");
                    //console.log(params.data.payment_term, "params.data.payment_term");
                    if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                        let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                        if (temp)
                            return temp.payment_term + " - " + temp.description
                        else
                            return params.data.payment_term
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            width: 350,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Tentative Shipping Line",
        //     field: "tentative_shipping_line",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Tentative Gate Open Date",
        //     field: "tentative_gate_open_date",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        // {
        //     headerName: "Tentative Cut Off Date",
        //     field: "tentative_cut_off_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_cut_off_date); 
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "DO Valid From",
            field: "do_valid_from",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.do_valid_from != "" && params.data.do_valid_from != undefined) {
                        return getHyphenDDMMYYYY(params.data.do_valid_from);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "DO Valid To",
            field: "do_valid_to",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.do_valid_to != "" && params.data.do_valid_to != undefined) {
                        return getHyphenDDMMYYYY(params.data.do_valid_to);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Freight Forwarder",
            field: "freight_forwarder",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Voyage No",
            field: "voyage_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Vessel ETD (date)",
            field: "vessel_etd_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.vessel_etd_date != "" && params.data.vessel_etd_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.vessel_etd_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Gate Open Date",
            field: "gate_open_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.gate_open_date != "" && params.data.gate_open_date != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_open_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Port cutoff Date",
            field: "port_cutoff_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.port_cutoff_date != "" && params.data.port_cutoff_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.port_cutoff_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Port cutoff Date",
            field: "sl_cutoff_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.sl_cutoff_date != "" && params.data.sl_cutoff_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.sl_cutoff_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Terminal",
            field: "terminal",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Pick Up Yard",
            field: "pickup_yard",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Remarks (By IB)",
            field: "remarks_by_ib",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "",
            field: "",
            width: 100,
            resizable: true,
            cellRenderer:ViewDOdetailsComponent
        },


    ]
    const onsubmitdata = (e) => {
        e.preventDefault()
        var data = [{
            work_order_no: selected_row.work_order_no,
            transporter_code:manualUploadDetails.transporter.value,
        }]
        //console.log(data, 'data')
        var rparams = {
            data: data,
            row:selected_row
        }
        if(manualUploadDetails.transporter.value!='' && manualUploadDetails.transporter.value!=undefined){
            redirectURL.post('/consignments/updateTransporterDetailToWO', rparams).then((resp) => {
                //console.log(resp.data, '176')
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Transporter has been Successfully Assigned")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm('')
                    props.onRequest('2')
                    setreloadcheck(true)
                }
            })
        }
        else{
            setshow(true)
            setbasicTitle("Please Select Transporter")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno:{label:'',value:''},
            transporter:{label:'',value:''}
        }))
    }
    const downloadB1=(e)=>{
        //console.log(files1, "filessssss1");
                var fileparams = {
                    attachmentFileName : files1.file1,
                    work_order_no:wrdo,
                    file1: 1
                }
                //console.log(fileparams, "fileparamsssss1")
                redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
                }).then((response)=>{
                    const imageURL = response.data;
                     window.open(imageURL, '_blank');
                }).catch(function(error){
                    //console.log(error);
                })
    }
    const downloadB2 = (fileName = "") => {
        if (fileName === "") {
          return;
        }
     
        redirectURL
          .post("/consignments/downloadFile", {
              fileName: fileName}
          )
          .then((res) => {
            // //console.log(res);
            if (res.status === 200) {
              const downloadUrl = res.data.url;
    
              
              const link = document.createElement("a");
              link.href = downloadUrl;
              link.setAttribute("download", fileName); // Optional: specify download filename
              link.setAttribute("target", "_blank");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              // //console.log("clicked");
            }
          })
          .catch((err) => {
            //console.log("err - ", err);
          });
      };
//     const downloadB2=(e)=>{
       
//             const imageURL = e;
//             window.open(imageURL, '_blank');
       
// }
    const onsubmitdata1 = (e) => {
        e.preventDefault()
        var data = [{
            work_order_no: selected_row.work_order_no,
            transporter_code:manualUploadDetails.transporter.value,
        }]
        //console.log(data, 'data')
        var rparams = {
            data: data,
            row:selected_row
        }
        if(manualUploadDetails.transporter.value!='' && manualUploadDetails.transporter.value!=undefined){
            redirectURL.post('/consignments/updateTransporterDetailToWO', rparams).then((resp) => {
                //console.log(resp.data, '176')
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("IB TPT Allocation has been successfully updated!")
                    setbasicType("success")
                    setclassforTab(
                        {
                            planned: 0,
                            new: 0,
                            modified: 1,
                        }
                    )
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm1('')
                    // props.onRequest('2')
                    // setreloadcheck(true)
                }
            })
        }
        else{
            setshow(true)
            setbasicTitle("Please Select Transporter")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno:{label:'',value:''},
            transporter:{label:'',value:''}
        }))
    }
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Tentative shipping line', inputName: 'tentative_shipping_line', required: true },
            { name: 'Tentative vessel ETD', inputName: 'tentative_vessel_etd', required: true },
            { name: 'Tentative Gate Open Date', inputName: 'tentative_gate_open_date', required: true },
            { name: 'Tentative cut off date', inputName: 'tentative_cut_off_date', required: true },
            { name: 'Remarks(by CHA)', inputName: 'remarks', required: true },
        ])
        //console.log(csvdata, '1234')
        setcsvcontent(csvdata)
    }
    const onSelectTpt = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            transporter: { label: e.label, value: e.value }
        }))
    }
    const onSelectWON = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: { label: e.label, value: e.value }
        }))
    }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                data: csvcontent,
            }
            redirectURL.post('/consignments/uploadBulkuploadforCHAdata', rparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                else {
                    setshow(true)
                    // setbasicTitle("Successfully Uploaded")
                    setbasicType("Failure")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                //console.log(resp.data)
            })
        }
    }
    const onClickHide = () => {
        setsliderForBulkupload('')
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno:{label:'',value:''},
            transporter:{label:'',value:''}
        }))
    }
    const onClickHideManualUpload1 = () => {
        setshowAddForm1('')
        setoverly('show-n')
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno:{label:'',value:''},
            transporter:{label:'',value:''}
        }))
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    // var onclickbulkupload = () => {
    //     setsliderForBulkupload("slider-translate")
    //     setoverly('show-m')
    // }
    const onClickCounters = (props) => {
        if (props == 'vesselAvailability') {
            setcheck(1)
            setIsClicked({ vessel_avail: true })
        }
        if (props == 'uploadDO') {
            setcheck(2)
            setIsClicked({ uploadDo: true })
        }
        else {
            setIsClicked(false)
        }
    }
    var sDate = $("#wo_order_date").val()
    var tpt_update= {
        headerName: "Update",
        field: "update_tpt_allocation",
        width: 80,
        pinned: 'left',
        cellRenderer: 'UpdateIBTptAllocation'
    }
    var tpt_update1= {
        headerName: "Update",
        field: "",
        width: 80,
        pinned: 'left',
        cellRenderer: 'UpdateIBTptAllocation1'
    }
    var acknowledgebtn= {
        headerName: "",
        field: "",
        width: 120,
        filter: true, resizable: true,
        pinned: 'left',
        cellRendererFramework: AcknowledgeBtnComponent
    }
    var columns1 = [...summaryViewcols]
    if(classForTab.new==1){
        columns1.push(tpt_update)
        detailedviewcols.push(tpt_update)
    }
    if(classForTab.modified==1){
        columns1.push(acknowledgebtn,tpt_update1)
        detailedviewcols.push(acknowledgebtn,tpt_update1)

    }
    if(classForTab.planned==1){
        columns1.push(tpt_update1)
        detailedviewcols.push(tpt_update1)

    }
    const columnwithDefs = summaryviewcheck == 1 ? columns1 : detailedviewcols
    columnwithDefs.forEach(e => {
        e.cellClass = function (params) {
          if (params.data && params.data[`${e.field}_modified`] === 1) {
            return "bgColorDangerDark";
          } else {
            return null;
          }
        };
      });
    const handlebulkupload = ()=>{
        props.onclickbulkupload();
    }

    var newCount = tabDatafortpt.tpt_new
        var plannedCount = tabDatafortpt.tpt_planned
        var modifiedCount = tabDatafortpt.tpt_modified

    const refreshData1=()=>{
            setcheckresetdatefilters(0);
            setrowData(originalData);
            setRefreshSelected(1);
            //console.log("refreshclickfunc")
     }  
     var downloadBtn = "#1976d2";
     var  cursorPoint = "pointer";  
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>

            <div className="row" style={{marginLeft:"14px"}}>
                {props.check == 2 ?
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-row">
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: summaryviewcheck == 1 ? '#0664AE' : "",
                                    // color: state.isTileView ? "": "#0664AE",
                                    borderTopLeftRadius: "11px",
                                    borderBottomLeftRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('summary view')}
                            >
                                <div
                                    style={{
                                        color: summaryviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Summary View
                                </div>
                            </button>

                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: detailedviewcheck == 1 ? '#0664AE' : "",
                                    borderTopRightRadius: "11px",
                                    borderBottomRightRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('detailed view')}
                            >
                                <div
                                    style={{
                                        color: detailedviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Detailed View
                                </div>
                            </button>
                        </div>

                    </div> : ''}
                {props.check == 2 ?

                    <div className="form-group col-xl-2 col-lg-2 mt-14p">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                            ...provided,
                                            fontSize: "0.8rem",
                                            color: "#6e707e",
                                            borderRadius:"11px",
                                            height:"48px",
                                            width:"260px",
                                            // padding:"22px",
                                            textAlign:"center"
                                        }),
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                    </div> : ''}
                {checkinput == 1 ?
                    <div
                        className="d-flex mt-14p mt-d mr-10p"
                        style={{ position: "relative" }}
                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                         {checkresetfilter != 1 ?
                            <img
                                src={SearchIcon}
                                className="search_icon"
                                onClick={filterData}
                            /> : <img
                                src={RefreshIcon}
                                className="search_icon"
                                onClick={refreshData}
                            />}
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="mt-14p mt-d">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} onRefreshSelected={refreshSelected}/>
                    {checkresetdatefilters!=1?
                      <img
                      src={SearchIcon}
                      className="search_icon_for_dates pointer"
                      onClick={filterDatafordates}
                  /> 
                  : <img
                      src={RefreshIcon}
                      className="search_icon_for_dates pointer"
                      onClick={refreshData1}
                  />}
                    </div>
                    : ""}
                {/* {props.check == 2 ?
                    <span className="float-right mt-24p" >
                        <button className='btn_style' onClick={() => manualbulkUploadForCha()} >Update IB TPT Allocation</button>
                    </span>


                    : ''} */}
                      {/* <div className="form-group d-flex justify-content-between  mt-2 pl-0 mb-0 btn-position" style={{right:"5px"}}> */}
           {/* <h5 className="heading ml-50p">
           <a href="/exportoperationscontroltower" className="txt-dec-none clr333">
                                </a>Booking Management System
           </h5> */}
           {/* <button type="button" className="btn-bulkupload mr-20p" style={{ fontSize: "0.8rem", }} onClick={handlebulkupload}>
               <img src={require("../../assets/images/upload.png")} alt="Icon" className="mr-1" />
               Bulk Upload</button> */}
            {/* </div> */}
            </div>


            {props.check == 2 ?

                <div className="" style={{ margin: '10px', marginTop: '12px', width: "100%", height: "478px" }}>
                    <div className="d-flex flex-row" style={{position:"relative"}}>
                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: classForTab.new == 1 ? '#0664AE' : '#FFFFFF',
                                        // color: state.isTileView ? "": "#0664AE",
                                        borderTopLeftRadius: "11px",
                                        borderBottomLeftRadius: "11px",
                                        border: "1px solid #0664AE",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('New')}
                                >
                                    <div
                                        style={{
                                            color: classForTab.new == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        New - <span>{(newCount!=''&&newCount!=undefined)?newCount.length:0}</span>
                                    </div>
                                </button>

                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: classForTab.planned == 1 ? '#0664AE' : "#FFFFFF",
                                        border: "1px solid #0664AE",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('Planned')}
                                >
                                    <div
                                        style={{
                                            color: classForTab.planned == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        Planned - <span>{(plannedCount!=''&&plannedCount!=undefined)?plannedCount.length:0}</span>
                                    </div>
                                </button>
                                <button
                                    className="d-flex flex-row justify-content-center align-items-center px-3"
                                    style={{
                                        background: classForTab.modified == 1 ? '#0664AE' : "#FFFFFF",
                                        borderTopRightRadius: "11px",
                                        borderBottomRightRadius: "11px",
                                        border: "1px solid #0664AE",
                                        height: "38px",
                                        padding: '22px'
                                    }}
                                    onClick={() => onclickTab('Modified')}
                                >
                                    <div
                                        style={{
                                            color: classForTab.modified == 1 ? "white" : '#0664AE',
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            marginLeft: "10px",
                                            lineHeight: "12px",
                                        }}
                                    >
                                        Modified - <span>{(modifiedCount!=''&&modifiedCount!=undefined)?modifiedCount.length:0}</span>
                                    </div>
                                </button>
                                {(classForTab.new===1  && summaryviewcheck===1)?
                         <div style={{position:"absolute", right:"10px",top:"-25px" }}>
                            <div className='btn_hover' style={{ height: "35px", marginTop: "32px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding:"5px" }} onClick={onClickSaveGridState}>
                             <i className="icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                         </div>
                        </div>
                     :''}
                            </div>
                            {/* <div style={{display:'flex',justifyContent:'end'}}>

                            <p style={{color:'red'}}>Note: Modification Pending At Previous Action</p>
                    </div> */}
                    <div className="" style={{ margin: '10px', marginTop: '0px' }}>
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                         ref={gridApi}
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                                // rowHeight:80,
                            }}
                            // paddingTop={10}
                            // rowHeight={70}
                            // headerHeight = {70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            frameworkComponents={{UpdateIBTptAllocation1:UpdateIBTptAllocation1,CountryFlagComponent:CountryFlagComponent,UpdateIBTptAllocation:UpdateIBTptAllocation,AcknowledgeBtnComponent:AcknowledgeBtnComponent}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            onGridReady={onGridReady}
                            onGridState={onGridState}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    // {
                                    //     statusPanel: "agTotalAndFilteredRowCountComponent",
                                    //     align: "left"
                                    // },
                                    // {
                                    //     statusPanel: "agTotalRowCountComponent",
                                    //     align: "center"
                                    // },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                  },
                            }}
                            // ref={gridRef}
                            getRowStyle={getRowClass}
                            context={{onClickuploadtptBtn1,onClickuploadtptBtn,onClickDOdetails,onClickAcknowledgeBtn}}
                        />

                    </div>
                    </div>
                </div> : ''}

            <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/cha_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Tentative Shipment Line & Remarks should be in 'String' format. <br />
                                Tentative Vessel ETD & Tentative Gate Open Date & Tentative cut off date should be in 'DD-MM-YYYY' format</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Transporter</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No: {selected_row.work_order_no}</label>
                                        {/* <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workOrderList}
                                        /> */}
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Transporter <span className="err-txt">*</span> </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.transporter}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectTpt(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={transportersList}
                                        />
                                    </div>


                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"sliderBlock2 " + showAddForm1} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Update Transporter</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload1} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata1}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No: {selected_row.work_order_no}</label>
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Transporter <span className="err-txt"></span> </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.transporter}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectTpt(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={transportersList}
                                        />
                                    </div>


                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"sliderBlock2 " + showAddForm3} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Download DO Copies</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload3} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">

                       <div className="col-xl-12 col-lg-12 form-group mt-15p">
                        <button onClick={() => downloadB2(files1.file1)} className="btn btn-warning1">Download File 1<i style={{color:downloadBtn,cursor:cursorPoint,marginLeft:"10px", marginTop:"5px", marginBottom:"5px"}} className="fa fa-download f20"></i> </button>
                        </div>
                        <div className="col-xl-12 col-lg-12 form-group">
                        <div>
                            {
                                (files1.hasOwnProperty("file2")&& files1.file2 != undefined && files1.file2!="")?
                                files1.file2.map((file, index) => {
                                    return (<div className="mb-15p"><button key={index} onClick={() => downloadB2(file)} className="btn btn-warning1">{`Download File ${index+2}`}<i style={{color:downloadBtn,cursor:cursorPoint,marginLeft:"10px", marginTop:"5px", marginBottom:"5px"}} className="fa fa-download f20"></i> </button>
                                <br/>
                                </div>)
                                }
                                    
                                ):""
                            }
                            
                        
                        </div>
                        </div>
                    
                  
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>
    )
}
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
      const propValue = obj[prop];
      const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
      if (seen.has(propString)) {
        return false;
      }
      seen.add(propString);
      return true;
    });
  }
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    // var num = n/1000;
    // var hours = (num / (3600*24));
    // var rhours = Math.floor(hours);
    // var minutes = (n) / (60*60);
    // var rminutes = Math.round(minutes);

    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}



function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    // //console.log("dateComparator");
    // //console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // //console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // //console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // //console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // //console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    ////console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        // mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    ////console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // //console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        ////console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        //console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    ////console.log(date.length);

    ////console.log(date);
    if (date === undefined || date === null || date.length !== 10) {

        return null;

    }

    var yearNumber = date.substring(6, 10);

    var monthNumber = date.substring(3, 5);

    var dayNumber = date.substring(0, 2);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

    return result;

}
function loadDateTimeScript2() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {

    function Datepicker() { }

    Datepicker.prototype.init = function (params) {

        this.eInput = document.createElement("input");

        //this.eInput = this.eInput.setAttribute("class","datepicker");

        this.eInput.value = params.value;

        //console.log(this.eInput)

        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });

    };

    Datepicker.prototype.getGui = function () {

        return this.eInput;

    };

    Datepicker.prototype.afterGuiAttached = function () {

        this.eInput.focus();

        this.eInput.select();

    };

    Datepicker.prototype.getValue = function () {

        return this.eInput.value;

    };

    Datepicker.prototype.destroy = function () { };

    Datepicker.prototype.isPopup = function () {

        return false;

    };

    return Datepicker;

}
function DateEditor() { }
// gets called once before the renderer is used

DateEditor.prototype.init = function (params) {

    // create the cell

    //console.log(params, "params")

    this.eInput = document.createElement('input');

    this.eInput.value = params.value;
    // https://jqueryui.com/datepicker/

    $(this.eInput).datetimepicker({

        dateFormat: "yy-mm-dd",

        changeMonth: true,

        changeYear: true

    });

};




// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function () {

    return this.eInput;

};




// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function () {

    this.eInput.focus();

    this.eInput.select();

};




// returns the new value after editing

DateEditor.prototype.getValue = function () {

    return this.eInput.value;

};




// any cleanup we need to be done here

DateEditor.prototype.destroy = function () {

    // but this example is simple, no cleanup, we could

    // even leave this method out as it's optional

};




// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function () {

    // and we could leave this method out also, false is the default

    return false;

};
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
export default IBUpdatesComponent