import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import PTData from '../../assets/json/payment_term_description.json'
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD ,getHyphenDDMMYYYY, getHyphenDDMMYYYYHHMM} from '../common/utils';
import ConsignmentTrucksLegs from "./containertruckslegs";
import CountryFlagComponent from "./countryFlag";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import Select from "react-select";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Mapcard2 from "./drawmap";
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
import ViewDOdetailsComponent from "./viewDOdetails";

var initialState = {
    detailCellRendererParams: {},
};

var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        //  //console.log('6767')
        //  return {
        //      ...state,
        //      detailCellRendererParams: action.payload,
        //  };
        default:
            return state;
    }
};
const CompletedComponent = (props) => {
    const gridApi = useRef(null);
    var [state, dispatch] = useReducer(reducer, initialState);
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    // var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    const [showAddForm, setshowAddForm] = useState('')
    var [checkfortruck, setcheckfortruck] = useState(0)
    var [ptd, setptd] = useState(PTData.PTData ?? [])
    var selectconfirmn = [
        { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }
    ]
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [trucksList, settrucksList] = useState([])
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        trucks: '',
        wrno: ''
    })
    var [ files1, setFile1] = useState({
        file1 : '',
        file2 :''
    })
    var [wrdo, setwrdo]=useState();
    const [showAddForm3, setshowAddForm3] = useState('')

    var [workordernoList, setworkOrderList] = useState([])
    var [containerLegsData, setContainerLegsData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [parentrow, setparentrow] = useState({})
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        mapdata:[],
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    const [summaryViewcols, setsummaryViewcols] = useState([
        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Status",
        //     field: "status",
        //     width: 150,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         if(params.data.new_plant==1){
        //             return 'Plant New'
        //         }
        //         else if(params.data.planned_plant==1){
        //             return 'Plant Planned'
        //         }
        //         else if(params.data.modified_plant==1){
        //             return 'Plant Modified'
        //         }
        //         else if(params.data.cha_vessel_new==1){
        //             return 'CHA Vessel New'
        //         }
        //         else if(params.data.cha_vessel_planned==1){
        //             return 'CHA Vessel Planned'
        //         }
        //         else if(params.data.cha_vessel_modified==1){
        //             return 'CHA Vessel Modified'
        //         }
        //         else if(params.data.ib_stuffing_new==1){
        //             return 'IB Stuffing New'
        //         }
        //         else if(params.data.ib_stuffing_planned==1){
        //             return 'IB Stuffing Planned'
        //         }
        //         else if(params.data.ib_stuffing_modified==1){
        //             return 'IB Stuffing Modified'
        //         }
        //         else if(params.data.cha_do_new==1){
        //             return 'CHA DO New'
        //         }
        //         else if(params.data.cha_do_planned==1){
        //             return 'CHA DO Planned'
        //         }
        //         else if(params.data.cha_do_modified==1){
        //             return 'CHA DO Modified'
        //         }
        //         else if(params.data.ib_tpt_new==1){
        //             return 'IB TPT New'
        //         }
        //         else if(params.data.ib_tpt_planned==1){
        //             return 'IB TPT Planned'
        //         }
        //         else if(params.data.ib_tpt_modified==1){
        //             return 'IB TPT Modified'
        //         }
        //         else if(params.data.tpt_trucks_new==1){
        //             return 'New in update trucks stage'
        //         }
        //         else if(params.data.tpt_trucks_planned==1){
        //             return 'Planned in update trucks stage'
        //         }
        //         else if(params.data.tpt_trucks_modified==1){
        //             return 'Modified in update trucks stage'
        //         }
        //         else if(params.data.tpt_containers_new==1){
        //             return 'New in update containers stage'
        //         }
        //         else if(params.data.tpt_containers_planned==1){
        //             return 'Planned in update containers stage'
        //         }
        //         else if(params.data.tpt_containers_modified==1){
        //             return 'Modified in update containers stage'
        //         }
        //         else if(params.data.ib_plant_gate_in_new==1){
        //             return 'Intransit to plant New'
        //         }
        //         else if(params.data.ib_plant_gate_in_planned==1){
        //             return 'Intransit to plant Planned'
        //         }
        //         else if(params.data.ib_plant_gate_in_modified==1){
        //             return 'Intransit to plant Modified'
        //         }
        //         else if(params.data.at_plant_new==1){
        //             return 'At plant New'
        //         }
        //         else if(params.data.at_plant_planned==1){
        //             return 'At plant Planned'
        //         }
        //         else if(params.data.at_plant_modified==1){
        //             return 'At plant Modified'
        //         }
        //         else if(params.data.shipping_new==1){
        //             return 'Shipping Details New'
        //         }
        //         else if(params.data.shipping_planned==1){
        //             return 'Shipping Details Planned'
        //         }
        //         else if(params.data.shipping_modified==1){
        //             return 'Shipping Details Modified'
        //         }
        //         else if(params.data.cha_reporting_new==1){
        //             return 'Container Reporting New'
        //         }
        //         else if(params.data.cha_reporting_planned==1){
        //             return 'Container Reporting Planned'
        //         }
        //         else if(params.data.cha_reporting_modified==1){
        //             return 'Container Reporting Modified'
        //         }
        //         else if(params.data.cha_leo_new==1){
        //             return 'Update Leo New'
        //         }
        //         else if(params.data.cha_leo_planned==1){
        //             return 'Update Leo Planned'
        //         }
        //         else if(params.data.cha_leo_modified==1){
        //             return 'Update Leo Modified'
        //         }
        //         else if(params.data.cha_gate_in_port_new==1){
        //             return 'Gate into Port New'
        //         }
        //         else if(params.data.cha_gate_in_port_planned==1){
        //             return 'Gate into Port Planned'
        //         }
        //         else if(params.data.cha_gate_in_port_modified==1){
        //             return 'Gate into Port Modified'
        //         }
        //     }
        // },
        {
            headerName: "Ship To Country",
             field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            valueGetter: function (params) {
                try {
                    //console.log(ptd, "ptd");
                    //console.log(params.data.payment_term, "params.data.payment_term");
                    if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                        let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                        if (temp)
                            return temp.payment_term + " - " + temp.description
                        else
                            return params.data.payment_term
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            width: 350,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Tentative Gate Open Date",
        //     field: "tentative_gate_open_date",
        //     width: 175,
        //     filter: true,
        //     resizable: true,
        //     editable: true,
        //     //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
        //     cellEditor:DateEditor,
        //     valueGetter: function (params) {
        //         try {
        //          if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
        //                  return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
        //             } else {
        //                 return "Not Available";
        //          }
        //          } catch (e) {
        //          }
        //      },
        //      comparator: dateComparator1,
        //  },
         
        //  {
        //     headerName: "Tentative Cut Off Date ",
        //     field: "tentative_cut_off_date",
        //     width: 170,
        //     filter: true,
        //     resizable: true,
        //     editable: true,
        //     //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
        //     cellEditor:DateEditor,
        //     valueGetter: function (params) {
        //         try {
        //          if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
        //                  return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
        //             } else {
        //                 return "Not Available";
        //          }
        //          } catch (e) {
        //          }
        //      },
        //      comparator: dateComparator1,
        //  },
         {
            headerName: "SL Cut Off Date",
            field: "sl_cutoff_date",
            width: 170,
            filter: true,
            resizable: true,
            editable: true,
            //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
            cellEditor:DateEditor,
            valueGetter: function (params) {
                try {
                 if (params.data.sl_cutoff_date != "" && params.data.sl_cutoff_date != undefined) {
                         return getHyphenDDMMYYYY(params.data.sl_cutoff_date);
                    } else {
                        return "Not Available";
                 }
                 } catch (e) {
                 }
             },
             comparator: dateComparator1,
         },
         {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 170,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Freight Forwarder",
            field: "freight_forwarder",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "No Of Free Days",
            field: "no_of_free_days",
            width: 200,
            filter: true,
            resizable: true,
        },
     
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        }, {
            headerName: "Booking No",
            field: "booking_no",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "DO Valid From",
            field: "do_valid_from",
            width: 190,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.do_valid_from != "" && params.data.do_valid_from != undefined) {
                        return getHyphenDDMMYYYY(params.data.do_valid_from);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "DO Valid To",
            field: "do_valid_to",
            width: 190,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.do_valid_to != "" && params.data.do_valid_to != undefined) {
                        return getHyphenDDMMYYYY(params.data.do_valid_to);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Freight Forwarder",
            field: "freight_forwarder",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Pickup Yard",
            field: "pickup_yard",
            width: 200,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Shipping Bill No",
        //     field: "shipping_bill_no",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Shipping Bill Date",
        //     field: "shipping_bill_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.shipping_bill_date != "" && params.data.shipping_bill_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.shipping_bill_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: 'LEO Date',
            field: "leo_date",
            resizable: true,
            width: 180,
            filter: true,
            valueGetter: function (params) {
                try {
                    if (params.data.leo_date != "" && params.data.leo_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.leo_date);
                    } else {
                        return "";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
        },
        {
            headerName: "DO Copy",
            field: "do_copy",
            width: 100,
            resizable: true,
            cellRenderer:ViewDOdetailsComponent
        },


    ])
    useEffect(() => {
		if (gridApi.current) {
		    getUserGridState();
		}
		loadDateTimeScript();
	}, [gridApi]);
       useEffect (() => {
        var records = props.data.work_order_level_data 
        //console.log(records,'691691')
        try{
            if (records!='' && records!=undefined){
                sortByCreatedDate(records)
            }
            setrowData(records)
        }
        catch(e){}

        // if(records!='' && records!=undefined){
        //     setrowData(props.data.work_order_level_data)
        // }
    }, [props.data])
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/completed' })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onClickHideManualUpload3 = () => {
        setshowAddForm3('')
        setoverly('show-n')
       


    }
    const onGridState = () => {
		////console.log(gridApi);

		/*Get  Current Columns State and Store in this.colState */
		const colState = gridApi.current.columnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		const rowGroupState =  gridApi.current.columnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		const pivotColumns =  gridApi.current.columnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = gridApi.current.api.getFilterModel();
	    gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();
	}
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const updateBtn = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onSelectWON = (e) => {
        //console.log(e)
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: e
        }))
    }
    const onClickDOdetails=(params)=>{
        //console.log('163',params)
        setshowAddForm3("slide33")
        //console.log(params.data.attachmentFileName)
        setwrdo(params.data.work_order_no)
        //console.log(params.data.additionl_do_copy_arr);
        setFile1({file1:params.data.uploaded_file,
            file2:params.data.additionl_do_copy_arr})
    }
    var updateContainerDetails = async (e) => {
        var params = {
            container_no: e.container_no,
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
        }
        var check = 0
        if (e.reporting_at_parking != undefined && e.reporting_at_parking != null && e.reporting_at_parking != "") {
            params.reporting_at_parking = e.reporting_at_parking
            check = 1
        }
        if (check != 0) {
            //console.log(params, 'params')
            redirectURL.post('/consignments/updateContainerreportingtimeatplaza', params).then((resp) => {
                //console.log(resp.data)
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Updated")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                } else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
        } else {
            setshow(true)
            setbasicTitle("Please Provide Reporting Container Date")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }
    }
    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
    }
    const onClickOpenexaminationfile=(params)=>{
        //console.log('163',params)
        var fileparams = {
            container_no:params.container_no
        }
        redirectURL.post("/consignments/downloadOpenExamination",fileparams,{
        }).then((response)=>{
            //console.log(response.data,'357')
            const imageURL = response.data;
            
             window.open(imageURL, '_blank');
        }).catch(function(error){
            //console.log(error);
        })
    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        };
        setloadshow('show-m');
        setoverly('show-m');

        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            //console.log(resp.data);
            var gps_route = resp.data.gps_route.coords;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');

            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route =gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;

                //console.log('gps_route', gps_route);
                //console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('ldb_route is not an array or is empty.');
                    }
                }
                //console.log(gps_route, 'gps_routegps_route')
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        //console.log(typeof gps_route, 'gps_routegps_route')
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('gps_route is not an array or is empty.');
                    }
                }

                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                //console.log(allCoordinates, 'allCoordinates');
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                        ldb_route: allldbcoords
                    },
                    mapdata:resp.data.gps_route
                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    };
    // const onShowShipmentLegRouteMap = async (e) => {
    //     var params = {
    //       work_order_no: e.work_order_no,
    //       truck_no: e.truck_no,
    //       container_no: e.container_no
    //     };
      
    //     setloadshow('show-m');
    //     setoverly('show-m');
      
    //     try {
    //       const resp = await redirectURL.post('/consignments/getmapdata', params);
    //       //console.log(resp.data);
    //       var records = resp.data.gps_df;
    //       //console.log(records, '342');
      
    //       if (!Array.isArray(records)) {
    //         records = records.replace(/NaN/g, "0");
    //         records = JSON.parse(records);
    //       }
    //       //console.log(records, '169');
    //       if (!records || records.length === 0) {
    //         setbasicTitle('No GPS Data Available');
    //         setbasicType('danger');
    //         setloadshow('show-n');
    //         setoverly('show-n');
    //       } else {
    //         var data = records[0];
    //         var allCoordinates = [];
    //         var allldbcoords = []
    //         var gps_route = data.gps_route;
    //         var ldb_route = data.ldb_route
    //         gps_route = JSON.parse(gps_route)
    //         ldb_route= JSON.parse(ldb_route)
    //         ldb_route.map((e) => {
    //             var lt = e.latitude || e.lat || 0; 
    //             var ln = e.longitude || e.lng || 0; 
      
    //             allldbcoords.push({
    //               lat: lt,
    //               lng: ln,
    //             });
    //           });
    //         //console.log(gps_route.length, '365');
    //         //console.log(ldb_route.length, '366');
    //         gps_route.map((e) => {
    //             var lt = e.latitude || e.lat || 0; 
    //             var ln = e.longitude || e.lng || 0; 
      
    //             allCoordinates.push({
    //               lat: lt,
    //               lng: ln,
    //             });
    //           });
    //         var truck_no;
    //         if (records.length > 0) {
    //           truck_no = records[0].truck_no;
    //         }
    //         //console.log(allCoordinates, 'allCoordinates');
    //         if (allCoordinates.length > 0) {
    //             setoverly('show-m')
    //           setStateForMap((prev) => ({
    //             ...prev,
    //             sliderRouteTranslate: "slider-translate-60p",
    //             showDiv: 'show-m',
    //             container_no: truck_no,
    //             mapinfo: {
    //               ...state.mapinfo,
    //               coords: allCoordinates,
    //               ldb_route:allldbcoords
    //             },
    //           }));
    //         } else {
    //           setbasicTitle('No GPS Data Available');
    //           setbasicType('danger');
    //           setloadshow('show-n');
    //           setshow(true);
    //         }
    //         setloadshow('show-n');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    var onClickShowShipmentLegs = async (rownode) => {
        var commentHeaders = [
            // {
            //     headerName: 'Requirement Id',
            //     field: "requirement_id",
            //     resizable: true,
            //     width: 180,
            //     filter: true
            // },
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            {
                headerName: "Open Examination",
                field: "",
                width: 140,
                cellRendererFramework: (params) => {
                    return (
                        params.data.uploaded_open_examination !== '' && params.data.uploaded_open_examination !== undefined ? (
                            <div className="map-icon-component" onClick={() => onClickOpenexaminationfile(params.data)}>
                                <span className="custom-btn label label-success" title="Download Attachment">
                                    <i style={{ color: "#1976d2", cursor: "pointer", marginTop: "10%" }} className="fa fa-download f20"></i>
                                </span>
                            </div>
                        ) :  (
                            <div className="map-icon-component" >
                                <span className="custom-btn label label-success" title="Download Attachment">
                                    <i style={{ color: "#fff", cursor: "pointer", marginTop: "10%" }} className="fa fa-download f20"></i>
                                </span>
                            </div>
                        ) 
                    );
                },
            },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            // },
            // {
            //     headerName: 'Container Tare Weight(Kgs)',
            //     field: 'container_tare_weight',
            //     resizable: true,
            //     width: 190,
            //     filter: true,
            // },
            // {
            //     headerName: 'Container Gross Weight(Kgs)',
            //     field: 'container_gross_weight',
            //     resizable: true,
            //     width: 200,
            //     filter: true,
                
            // },
            // {
            //     headerName: 'Driver Mobile No',
            //     field: 'driver_mobile_no',
            //     resizable: true,
            //     width: 160,
            //     filter: true,
            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,

            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Plant Gate-In Time',
                field: "gate_in_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
           
            {
                headerName: 'Plant Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
           
          
            {
                headerName: 'Reporting Time At Parking Plaza',
                field: "reporting_at_parking",
                resizable: true,
                width: 240,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.reporting_at_parking);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
           
            {
                headerName: 'Gate-In Time At Port',
                field: "gate_in_time_port",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time_port != "" && params.data.gate_in_time_port != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_in_time_port);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Shipping On Board Date',
                field: "shipping_on_board_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.shipping_on_board_date != "" && params.data.shipping_on_board_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.shipping_on_board_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'BL No',
                field: "bl_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'BL Date',
                field: "bl_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.bl_date != "" && params.data.bl_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.bl_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'BL Submission Date',
                field: "bl_submission_date",
                resizable: true,
                width: 180,
                filter: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.bl_submission_date != "" && params.data.bl_submission_date != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.bl_submission_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                    }
                },
            },
            {
                headerName: 'Destination ETA',
                field: "destination_eta",
                resizable: true,
                width: 180,
                filter: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.destination_eta != "" && params.data.destination_eta != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.destination_eta);
                        } else {
                            return "";
                        }
                    } catch (e) {
                    }
                },
            },
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',

            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no: row.work_order_no
                }
                // await redirectURL.post("/consignments/truckslegsdata",parameter).then(async (response) => {
                //     //console.log(response.data,'response')
                //     var records = response.data 
                // var records = containerLegsData.filter(e => e.work_order_no == row.work_order_no)
                var records = props.data.contaCompletediner_completed
                records = records.filter(e=>e.work_order_no==row.work_order_no)
                param.successCallback(records);
                // }).catch(function (error) {
                //     //console.log(error);
                // })
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
        setTimeout(() => {
            if (rownode.column.colDef.field == 'containerLegs') {
                rownode.node.setExpanded(!rownode.node.expanded);

            }
            else {

                rownode.node.setExpanded(false);
            }
        }, 0)
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')

    }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var trucks = manualUploadDetails.trucks
        var truckList = []
        trucks.map((e) => {
            truckList.push(e.value)
        })
        //console.log(truckList, 'truckList')
        var wrno = manualUploadDetails.wrno.value
        //console.log(originalData, 'originalData')
        var record = originalData.filter(e => e.work_order_no == wrno)
        record = record[0]

        var containerCount = record.container_count
        //console.log(containerCount, 'containerCount')
        var params = {
            work_order_no: wrno,
            trucks: truckList
        }
        if (containerCount == trucks.length) {
            redirectURL.post('/consignments/updateTrucksDetails', params).then((resp) => {

            })
        }
        else {

        }
    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/completed'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/completed',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onSelectConfirm = (e) => {
        if (e.value == 'yes') {
            setcheckfortruck(1)
        }
        else {
            setshowAddForm('')
            setoverly('show-n')
        }
    }
    const handleTrucksList = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            trucks: e
        }))
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const downloadB1=(e)=>{
        //console.log(files1, "filessssss1");
                var fileparams = {
                    attachmentFileName : files1.file1,
                    work_order_no:wrdo,
                    file1: 1
                }
                //console.log(fileparams, "fileparamsssss1")
                redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
                }).then((response)=>{
                    const imageURL = response.data;
                     window.open(imageURL, '_blank');
                }).catch(function(error){
                    //console.log(error);
                })
    }
//     const downloadB2=(e)=>{
//         // var fileparams = {
//         //     attachmentFileName : files1.file2,
//         //     work_order_no:wrdo,
//         //     file1: 2
//         // }
//         // //console.log(fileparams, "fileparamsssss2")

//         // redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
//         // }).then((response)=>{
//             const imageURL = e;
//              window.open(imageURL, '_blank');
//         // }).catch(function(error){
//         //     //console.log(error);
//         // })
// }
const downloadB2 = (fileName = "") => {
    if (fileName === "") {
      return;
    }
 
    redirectURL
      .post("/consignments/downloadFile", {
          fileName: fileName}
      )
      .then((res) => {
        // //console.log(res);
        if (res.status === 200) {
          const downloadUrl = res.data.url;

          
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName); // Optional: specify download filename
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // //console.log("clicked");
        }
      })
      .catch((err) => {
        //console.log("err - ", err);
      });
  };
    const detailedviewcols = []
    var columns1 = [...summaryViewcols]
    const columnwithDefs = columns1
    var downloadBtn = "#1976d2";
    var  cursorPoint = "pointer";

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div style={{ display: 'flex', justifyContent: 'flex-end', position:"relative",right:"-10px",bottom:"-5px"}}>
                    <div className='btn_hover' style={{ height: "35px",marginTop:"5px",marginBottom:"0px", fontSize: "12px", textAlign: "center",paddingTo:"8px", padding:"5px" }} onClick={onClickSaveGridState}>
                        <i className="icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                    </div>
                </div>
            <div className="" style={{ margin: '10px', width: "100%", height: "478px" }}>
            <div id="idgrid" style={{ width: "100%", height: "478px"}} className="ag-theme-balham">
                <AgGridReact
                 ref={gridApi}
                    columnDefs={columnwithDefs}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        editable: false,
                        resizable: true,
                        menuTabs: ['filterMenuTab']
                        // rowHeight: 80,
                    }}
                    // paddingTop={10}
                    // rowHeight={70}
                    // headerHeight = {70}
                    rowData={rowData}
                    suppressRowClickSelection={true}
                    detailCellRendererParams={state.detailCellRendererParams}
                    frameworkComponents={{ ConsignmentTrucksLegs: ConsignmentTrucksLegs,CountryFlagComponent:CountryFlagComponent, ViewDOdetailsComponent:ViewDOdetailsComponent }}
                    enableCellChangeFlash={true}
                    suppressCellFlash={true}
                    enableRangeSelection={true}
                    onGridReady={onGridReady}
                    onGridState={onGridState}
                    paginationPageSize={50}
                    rowSelection={'multiple'}
                    masterDetail={true}
                    pagination={true}
                    sideBar={{
                        toolPanels: [
                            {
                                id: "columns",
                                labelDefault: "Columns",
                                labelKey: "columns",
                                iconKey: "columns",
                                toolPanel: "agColumnsToolPanel"
                            },
                            {
                                id: "filters",
                                labelDefault: "Filters",
                                labelKey: "filters",
                                iconKey: "filter",
                                toolPanel: "agFiltersToolPanel"
                            }
                        ]
                    }}
                    statusBar={{
                        statusPanels: [
                            // {
                            //     statusPanel: "agTotalAndFilteredRowCountComponent",
                            //     align: "left"
                            // },
                            // {
                            //     statusPanel: "agTotalRowCountComponent",
                            //     align: "center"
                            // },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" }
                        ]
                    }}
                    gridOptions={{
                        icons: {
                            // You can customize other icons as well
                            next: `<img src="${customNextIcon}" alt="Next" />`,
                            previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                            first: `<img src="${customFirstIcon}" alt="First" />`,
                            last: `<img src="${customLastIcon}" alt="Last" />`,
                          },
                    }}
                    // ref={gridRef}
                    context={{ onClickShowShipmentLegs, updateContainerDetails,onClickDOdetails }}
                />

            </div>
            </div>
            {stateForMap.sliderRouteTranslate != '' ?
				<div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							{(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{  marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            {/* {//console.log("this.state.mapinfo ", this.state.mapinfo)} */}
                            <Mapcard2
                                mapinfo={stateForMap.mapinfo}
                                mapHeight={"100vh"}
                                mapFor={"intransit"}
                                mapData={stateForMap.mapdata}
                               geoLocShipmentsMap={state.geoLocShipmentsMap}

                            />
						</div>
					</div>

				</div>
				: ""
			}
             <div className={"sliderBlock2 " + showAddForm3} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Download DO Copies</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload3} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">

                       <div className="col-xl-12 col-lg-12 form-group mt-15p">
                        <button onClick={() => downloadB2(files1.file1)} className="btn btn-warning1">Download File 1<i style={{color:downloadBtn,cursor:cursorPoint,marginLeft:"10px", marginTop:"5px", marginBottom:"5px"}} className="fa fa-download f20"></i> </button>
                        </div>
                        <div className="col-xl-12 col-lg-12 form-group">
                        <div>
                            {
                                (files1.hasOwnProperty("file2")&& files1.file2 != undefined && files1.file2!="")?
                                files1.file2.map((file, index) => {
                                    return (<div className="mb-15p"><button key={index} onClick={() => downloadB2(file)} className="btn btn-warning1">{`Download File ${index+2}`}<i style={{color:downloadBtn,cursor:cursorPoint,marginLeft:"10px", marginTop:"5px", marginBottom:"5px"}} className="fa fa-download f20"></i> </button>
                                <br/>
                                </div>)
                                }
                                    
                                ):""
                            }
                            
                        
                        </div>
                        </div>
                    
                  
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>



    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {
        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        //console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        //console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
export default CompletedComponent