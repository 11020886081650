import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import SearchIcon from "../../assets/icons/search-icon.svg";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Datepicker from "../layouts/datepicker";
import CountryFlagComponent from "./countryFlag";
import DestinationFlagComponent from "./destinationCountryFlag";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import moment from "moment";
import Select from "react-select";
import download from 'js-file-download';
import ViewDOdetailsComponent from "./viewDOdetails";
import SubmitRevisedBtn from "./submitrevisedbtn";
import redirectURL from "../redirectURL";


const IBStuffingForPlant = (props) => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [checkinput, setcheckinput] = useState(0)
    var [rowData, setrowData] = useState(props.data)

    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [originalData, setoriginalData] = useState(props.data)
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var typeofselection = [
        { label: 'Ship to Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'SKU', value: 'sku_code' },
        { label: 'Brand', value: 'brand' },
        { label: 'Variant', value: 'variant' }, { label: 'WO Release Date', value: 'wo_release_date' }
    ]
    useEffect(() => {
        setrowData(props.data)
        setoriginalData(props.data)
    },[props.data])
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'wo_release_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const onClickRevisedProductionData = (rowdata) => {
        var row = rowdata.data
        var revised_production_date = row.revised_production_date
        console.log(revised_production_date)
        var data=[{'revised_production_date': revised_production_date,'work_order_no': row.work_order_no}]
        var params = {
            data:data
        }
        redirectURL.post('/consignments/uploadBulkuploadforRevisedProductiondates',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
            }
            else{
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
                
            }
            props.onReloadData(true);
            console.log(resp.data)
           })
    }
    const onClickDOdetails=(params)=>{
        console.log('163',params)
        console.log(params.data.attachmentFileName)
        if(params.data.attachmentFileName !=undefined && params.data.attachmentFileName !="")
        {
            var fileparams = {
                attachmentFileName : params.data.attachmentFileName,
                work_order_no:params.data.work_order_no
            }
            redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
            }).then((response)=>{
                const imageURL = response.data;
                 window.open(imageURL, '_blank');
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        var filteredData
        if (typeoffield == 'ship_to_country') {
            filteredData = originalData.filter(item => item.ship_to_country.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'pod') {
            filteredData = originalData.filter(item => item.pod.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'sku_code') {
            filteredData = originalData.filter(item => item.sku_code.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'brand') {
            console.log('96')
            filteredData = originalData.filter(item => item.brand.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'variant') {
            filteredData = originalData.filter(item => item.variant.toLowerCase() == val.toLowerCase());
        }
        console.log(filteredData)
        setrowData(filteredData)
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var summaryViewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "IB Stuffing Date",
            field: "ib_stuffing_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.ib_stuffing_date != "" && params.data.ib_stuffing_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.ib_stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Status",
            field: "status",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.status == 1) {
                    return 'New'
                }
                else if (params.data.status == 2) {
                    return 'Planned'
                }
                else if (params.data.status == 3) {
                    return 'Modified'
                }
            }
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
    //     {
    //         headerName: "Revised Production Date",
    //         field: "revised_production_date",
    //         width: 170,
    //         filter: true,
    //         resizable: true,
    //         editable: true,
    //         //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
    //         cellEditor:DateEditor,
    //         valueGetter: function (params) {
    //             try {
    //              if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
    //                      return getHyphenDDMMYYYY(params.data.revised_production_date);
    //                 } else {
    //                     return "Not Available";
    //              }
    //              } catch (e) {
    //                  // Handle the exception if needed
    //              }
    //          },
    //          comparator: dateComparator1,
    //      },
    //    {
    //         headerName: "",
    //         field: "",
    //         width: 80,
    //         filter: true, resizable: true,
    //         // hide:hideformodified,
    //         // cellRenderer: 'SubmitBtn'
    //         cellRendererFramework: SubmitRevisedBtn

    //     }
    ]

    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Created Date",
            field: "wo_created_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_created_date != "" && params.data.wo_created_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.wo_created_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Destination Country",
            field: "destination_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'DestinationFlagComponent'
        },
        {
            headerName: "POD",
            field: "pod",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Order Type",
            field: "order_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "2wh/3wh/4wh",
            field: "wh2_wh3_wh4",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Requested Delivery Date",
            field: "requested_delivery_date",
            width: 190,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.requested_delivery_date != "" && params.data.requested_delivery_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.requested_delivery_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Ref No",
            field: "lc_ref_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Actual LC No",
            field: "actual_lc_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Expiry Date",
            field: "lc_expiry_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.lc_expiry_date != "" && params.data.lc_expiry_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.lc_expiry_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 170,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CP Invoice Date",
            field: "cp_invoice_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.cp_invoice_date != "" && params.data.cp_invoice_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.cp_invoice_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Delivery Block",
            field: "delivery_block",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Status",
            field: "payment_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenYYYYMMDD(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Weight Status",
            field: "weight_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Buyer",
            field: "buyer",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Consignee",
            field: "consignee",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
       {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.production_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },        
        },
       {
            headerName: "Revised Production Date",
            field: "revised_production_date",
            width: 170,
            filter: true,
            resizable: true,
            editable: true,
            //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
            cellEditor:DateEditor,
            valueGetter: function (params) {
                try {
                 if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
                         return getHyphenYYYYMMDD(params.data.revised_production_date);
                    } else {
                        return "Not Available";
                 }
                 } catch (e) {
                     // Handle the exception if needed
                 }
             },
             comparator: dateComparator1,
         },
       {
            headerName: "",
            field: "",
            width: 80,
            filter: true, resizable: true,
            // hide:hideformodified,
            // cellRenderer: 'SubmitBtn'
            cellRendererFramework: SubmitRevisedBtn

        }


    ]
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        from_date = moment(from_date, 'DD-MM-YYYY')
        console.log(from_date, 'from_date')
        var to_date = woReleaseDate.wo_release_to_date
        to_date = moment(to_date, 'DD-MM-YYYY')
        console.log(to_date, 'to_date')

        var filteredData = originalData.filter(item => {
            var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
            itemDate = moment(itemDate, 'DD-MM-YYYY')
            console.log(itemDate, 'itemDate')
            return itemDate.isBetween(from_date, to_date, null, '[]')
        });
        console.log(filteredData, 'filteredData')
        setrowData(filteredData)
    }
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    // const handlebulkupload = ()=>{
    //     props.onclickbulkupload();
    // }

    const columnwithDefs = summaryviewcheck == 1 ? summaryViewcols : detailedviewcols
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            {/* <div className="row">
                {props.check == 2 ?
                    <div className="d-flex flex-row m-24p">
                        <div className="d-flex flex-row">
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: summaryviewcheck == 1 ? '#0664AE' : "",
                                    // color: state.isTileView ? "": "#0664AE",
                                    borderTopLeftRadius: "11px",
                                    borderBottomLeftRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('summary view')}
                            >
                                <div
                                    style={{
                                        color: summaryviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Summary View
                                </div>
                            </button>

                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: detailedviewcheck == 1 ? '#0664AE' : "",
                                    borderTopRightRadius: "11px",
                                    borderBottomRightRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('detailed view')}
                            >
                                <div
                                    style={{
                                        color: detailedviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Detailed View
                                </div>
                            </button>
                        </div>

                    </div> : ''}
                <div style={{ gap: '190px', display: 'flex' }} className="checkForDateFilter_gap">
                    {props.check == 2 ?

                        <div className="form-group col-xl-2 col-lg-2 mt-24p">
                            <Select
                                closeMenuOnSelect={true}
                                name="filterModeType"
                                className="border-radius-0"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        fontSize: "0.8rem",
                                        color: "#6e707e",
                                        borderRadius: "11px",
                                        height: "48px",
                                        width: "260px",
                                        // padding:"22px",
                                        textAlign: "center"
                                    }),
                                }}
                                onChange={handleFilterOptions}
                                options={typeofselection}
                                value={selectedval.dropdownval}
                                required
                            />
                        </div> : ''}
                    {checkinput == 1 ?
                        <div
                            className="d-flex mt-24p ml-8p mr-10p"
                            style={{ position: "relative" }}
                        >
                            <input
                                style={{
                                    border: "1px solid #cccccc",
                                    borderRadius: "11px",
                                    height: "48px",
                                    outline: "none",
                                    paddingLeft: "6px",
                                }}
                                onChange={handleInputfilter}
                                value={selectedval.inputval}
                            />
                            <img
                                src={SearchIcon}
                                className="search_icon"
                                onClick={filterData}
                            />
                        </div>
                        : ''}
                    {checkForDateFilter == 1 ?
                        <div className="mt-24p">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} /> <img
                            src={SearchIcon}
                            className="search_icon_for_dates"
                            onClick={filterDatafordates}
                        /></div>

                        : ""}
                </div>

                
            </div> */}
           
            <div className="" style={{ margin: '10px', marginTop: '25px' }}>
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            paddingTop={10}
                            rowHeight={70}
                            headerHeight = {70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            frameworkComponents={{CountryFlagComponent:CountryFlagComponent,DestinationFlagComponent:DestinationFlagComponent}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={10}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    {
                                        statusPanel: "agTotalAndFilteredRowCountComponent",
                                        align: "left"
                                    },
                                    {
                                        statusPanel: "agTotalRowCountComponent",
                                        align: "center"
                                    },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            ref={gridRef}
                            context={{onClickRevisedProductionData,onClickDOdetails}}
                        />

                    </div>
                </div>

        </>
    )

}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
export default IBStuffingForPlant