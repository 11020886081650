import React from 'react';
import ReactDOM from "react-dom/client";
import App from "./App";

import { BrowserRouter, Routes, Route} from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import NoPage from "./components/pages/NoPage";
import Test from "./components/pages/Test";
import Grid from "./components/pages/Grid";
import Login from "./components/login/Login";
import Configurations from "./components/billing/Configurations";
import APIConfigurations from "./components/billing/ApiConfigurations";
import BillingTrips from "./components/billing/BillingTripsComponent";
import UploadBillingTrips from "./components/billing/BillingTripsUploadComponent";
import FreightRates from "./components/billing/FreightRateComponent";
import DieselPriceApproval from "./components/billing/DieselPricesApproval";
import BillingDieselPrices from "./components/billing/BillingDieselPricescreen";
import FreightApprovalSubmit from "./components/billing/freightapprovalaftersubmit";
import FreighLevelApprovals from "./components/billing/BillingFreightLevelApprovals";
import ViewFreightDocument from "./components/billing/freightapprovalaftersubmit";
import OnlyViewFreightDocument from "./components/billing/freightapprovalaftersubmit";
import DownloadFreightDocument from "./components/billing/DownloadFreightDocument";
import BillingModuleUserAccess from "./components/billing/BillingModuleUserAccess";
import BillingTransporterGST from "./components/billing/BillingTransporterGST";
import BillingDeviationFlow from "./components/billing/BillingDeviationflow";
import CreatePO from "./components/booking/createPOComponent";
import Bookings from "./components/booking/bookingsComponent";
import AirImportsCreateNewRequest from "./components/airimports/createnewrequest";
import AirImportsActiveBookings from "./components/airimports/activebookings";
// import VendorProcureDashboard from './components/procurement/vendorProcureDashboard';
// import EventDetails from './components/procurement/eventDetails';
// import OrganiserProcureDashboard from './components/procurement/organiserProcureDashboard';
// import ReverseAuction from './components/procurement/reverseAuction';
// import RequestForQuotation from './components/procurement/requestForQuotation';
// import RfqResponse from './components/procurement/rfqResponse';
// import VendorRegistration from './components/procurement/VendorRegistration';
// import RfqSummary from './components/procurement/rfqSummary';
// import RfqComparitiveAnalysis from './components/procurement/rfqComparitiveAnalysis';
import EximBookings from './components/exim/bookings'

// import FreightForwarder from './components/exim/freightforwader';
// import CHA from './components/exim/chaupdates';
// import Transporter from './components/exim/transporter';
import CreateEximBooking from './components/exim/createBooking';
import ShipmentDetails from './components/exim/shipmentDetails';
import EximTracking from './components/exim/tracking';
import TrackingDetails from './components/exim/trackingDetails';
import TrackingDashboard from './components/exim/trackingDashboard';
import SeaImportsForm from "./components/layouts/seaimportsform";
import SeaImportsListComponent from "./components/layouts/seaimportslist";
import FFSeaImportsList from "./components/layouts/ffseaimportsList";
import ChaSeaImportsList from "./components/layouts/chaseaimportsList";
import TransporterSeaImports from "./components/layouts/transporterseaimports";
import EditTransporterSeaImports from "./components/layouts/edittransporterseaimports";
import EditSeaImports from "./components/layouts/editseaimportsform";
import EditFFSeaImports from "./components/layouts/editffseaimportsform";
import EditChaSeaImports from "./components/layouts/editchaseaimportsform";
// import RequestForQuotationUpdated from './components/procurement/requestForQuotationUpdated';
// import RfqRevamp from './components/procurement/rfqRevamp';
// import Approverdashboard from './components/procurement/Approverdashboard';
// import RARevamp from './components/procurement/RARevamp';
// import VendorProcureDashboardRevamp from './components/procurement/vendorProcureDashboardRevamp';
// import RfqPreviewAndApprovePage from './components/procurement/rfqPreviewAndApprovePage';
// import RfqResponseRevamp from './components/procurement/rfqResponseRevamp';
// import RfqVendorComparison from './components/procurement/rfqVendorComparison';
// import VendorAcceptance from './components/procurement/VendorAcceptance';
import SummaryDashboard from './components/layouts/summarydashboard';
import Truck from './components/layouts/truckmaster';
// import Partners from './components/layouts/partners';

import MasterData from './components/layouts/masterdata';
import Plant from './components/layouts/plantUpdates';
import CHAUpdatesComponent from './components/layouts/chaUpdates';
import IBUpdatesComponent from './components/layouts/ibUpdatesComponent';
import TransporterComponent from './components/layouts/transporterComponent';
import ChaUploadDO from './components/cha/chaDOupload';
import ContainerTracking from './components/tracking/eximTracking';
import "./assets/css/Modal.css"
import ContainerTrackingDetails from './components/tracking/ContainerTrackingDetails';
import TruckGPS from './components/layouts/trucksgpss';
import TrucksGPSRoute from './components/layouts/trucksgpsroute';
import Geofence from './components/geofence/geofence';
import AddGeofence from './components/geofence/addgeofence';
import EditGeoFence from './components/geofence/editgeofence';
import TruckWithGeofenceComponent from './components/geofence/truckwithgeofence';
import TptMasterComponent from './components/layouts/tptmastercomponent';
import ShipmentPlanning from './components/exim/shipmentPlanning';
import ShipmentExecution from './components/exim/shipmentExecution';
import DaywiseTracking from './components/exim/daywisetracking';
import BufferReport from './components/exim/bufferreport';
// import Report from './components/exim/report';
import AskingRateReport from './components/exim/askingratereport';
import TptOnTimePerf from './components/exim/tptontimeperf';
import TransporterTAT from './components/layouts/transportertatcomponent';
import TruckTrackingComponent from './components/exim/truckTrackingreport';
import PlantTAT from './components/layouts/planttatcomponent';
import ReportScreen from './components/layouts/reportscreen';
import OnTimePerformanceComponent from './components/layouts/onTimePerformanceComponent';
import UpTimeGPSData from './components/layouts/upTimeGpsData';
import EmergencyAlerts from './components/layouts/emergencyalerts';
import TptTargetvsAcheivComponent from './components/layouts/tpttargetvsach';
import LspAccidentsData from './components/layouts/lspaccidentdata';
import EnmUserLogin from './components/login/enmovilUserLogin';
import Users from './components/layouts/users';
import ResetPassword from './components/login/reset-password';
import DashboardSummary from './components/layouts/plantwrbasedperformancedashboard';
import MobileTripMaps from './components/layouts/mobiletripmaps';
export default function Root() {
  return (
    <BrowserRouter>
      <Routes>
      {/* <Route path="/vendorAcceptance/:rfq_event_id/:vendor_id" element={<VendorAcceptance />} /> */}
      {/* <Route path="/vendorRegister/:rfq_event_id" element={<VendorRegistration/>} /> */}
	  
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword/>} />

        <Route path="/enmuserlogin/:token" element={<EnmUserLogin/>} />
        <Route path="/" element={<App />}>
          <Route path="/" element={<SummaryDashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/test" element={<Test />} />
          <Route path="/ag-grid" element={<Grid />} />
          <Route path="/configurations" element={<Configurations />} />
          <Route path="/apisetup" element={<APIConfigurations />} />
          <Route path="/trips" element={<BillingTrips />} />
          <Route path="/upload-trips" element={<UploadBillingTrips />} />
          <Route path="/freightrates" element={<FreightRates />} />
          <Route path="/dieselpriceapprovals" element={<DieselPriceApproval />} />
          <Route path="/dieselprices" element={<BillingDieselPrices />} />
          <Route path="/documents/:document_no" element={<FreightApprovalSubmit />} />
          <Route path="/mobiletripmap/:workOrderNo/:truckNo" element={<MobileTripMaps />} />
          <Route path="/view-document-approval/:document_no" element={<ViewFreightDocument />} />
          <Route path="/view-document/:document_no" element={<OnlyViewFreightDocument />} />
          <Route path="/view-closed-document/:document_no" element={<OnlyViewFreightDocument />} />
          <Route path="/download-document/:document_no" element={<DownloadFreightDocument />} />
          <Route path="/user-access" element={<BillingModuleUserAccess />} />
          <Route path="/transporter-gst" element={<BillingTransporterGST />} />
          <Route path="/deviations" element={<BillingDeviationFlow />} />
          <Route path="/approvals" element={<FreighLevelApprovals />} />
          <Route path="/create-booking" element={<CreatePO />} />
          <Route exact path="/bookings" element={<Bookings />} />
          <Route path="/airimports/createnewrequest" element={<AirImportsCreateNewRequest />} />
          <Route path="/airimports/activebookings" element={<AirImportsActiveBookings />} />
          {/* <Route path="/organiser-dashboard" element={<OrganiserProcureDashboard/>} /> */}
          {/* <Route path="/admin-dashboard" element={<Approverdashboard />} />
          <Route path="/vendor-dashboard" element={<VendorProcureDashboard/>} />
          <Route path="/vendor-dashboard-revamp" element={<VendorProcureDashboardRevamp/>} /> */}
          {/* <Route path="/event-details/:id" element={<EventDetails/>} /> */}
          {/* <Route path="/reverse-auction" element={<ReverseAuction/>} /> */}
          {/* <Route path="/request-quotation" element={<RequestForQuotation/>} /> */}
          {/* <Route path="/request-quotation" element={<RfqRevamp/>} /> */}
          {/* <Route path="/request-quotation/:id" element={<RfqRevamp/>} /> */}
          {/* <Route path="/request-quotation1" element={<RequestForQuotationUpdated/>} /> */}
          {/* <Route path="/rqRevamp" element={<RARevamp/>} /> */}
          
          {/* <Route path="/rfqResponse/:event_id" element={<RfqResponse/>} /> */}
          {/* <Route path="/rfqResponse/:event_id" element={<RfqResponseRevamp/>} /> */}
          {/* <Route path="/vendorRegistration" element={<VendorRegistration/>} /> */}
          {/* <Route path="/vendorRegister" element={<VendorRegistration/>} /> */}
          {/* <Route path="/rfqSummary" element={<RfqSummary/>} /> */}
          {/* <Route path="/rfqComparitiveAnalysis/:id" element={<RfqComparitiveAnalysis/>} /> */}
          {/* <Route path="/rfqPreviewAndApprove/:id" element={<RfqPreviewAndApprovePage />} /> */}
          {/* <Route path="/rfqVendorComparison/:event_id" element={<RfqVendorComparison />} /> */}
          <Route path="/booking-shipments" element={<EximBookings/>} />
          {/* <Route path="/bookings/freightforwader" element={<FreightForwarder />} />     */}
          <Route path="/tracking" element={<EximTracking/>} />
          <Route path="/tracking-dashboard" element={<TrackingDashboard />} />
          <Route path="/tracking-details/:id" element={<TrackingDetails/>} />
          {/* <Route path="/createEximbooking" element={<CreateEximBooking/>} />
          <Route path="/freightforwader" element={<FreightForwarder />} />
          <Route path="/chaupdates" element={<CHA />} />
          <Route path="/transporterupdates" element={<Transporter />} />
          <Route path="/shipment-details/:id" element={<ShipmentDetails/>} /> */}
          <Route path="/createEximbooking" element={<CreateEximBooking/>} />
          <Route path="/shipment-details/:id" element={<ShipmentDetails/>} />
          <Route path={"/bookingform"} element={<SeaImportsForm />} />
          <Route path={"/bookingstracking"} element={<SeaImportsListComponent />} />
          <Route path={"/ffseaimports"} element={<FFSeaImportsList />} />
          {/* <Route path={"/generalInfo"} element={<Partners />} /> */}
          <Route path={"/generalInfo"} element={<MasterData />} />
          <Route path={"/trucksgps"} element={<TrucksGPSRoute />} />
          <Route path={"/trucksgpsintegration"} element={<TruckGPS />} />
          <Route path={"/chaseaimports"} element={<ChaSeaImportsList />} />
          <Route path={"/transportationseaimports"} element={<TransporterSeaImports />} />
          <Route path={"/edittransporterseaimports/:eventname/:pagetype"} element={<EditTransporterSeaImports />} />
          <Route path={"/editseaimportsform/:eventname"} element={<EditSeaImports />} />
          <Route path={"/editffseaimportsform/:eventname/:pagetype"} element={<EditFFSeaImports />} />
          <Route path={"/editchaseaimportsform/:eventname/:pagetype"} element={<EditChaSeaImports />} />
          <Route path="*" element={<NoPage />} /> 
          <Route path={"/exportoperationscontroltower"} element={<SummaryDashboard />} />
          <Route path={"/trucks"} element={<Truck />} />
          <Route path={"/booking/plant"} element={<Plant />} />
          <Route path={"/booking/cha"} element={<CHAUpdatesComponent />} />
          <Route path={"/booking/iBstuffingdata"} element={<IBUpdatesComponent />} />
          <Route path={"/booking/transporter"} element={<TransporterComponent />} />
          <Route path={"/ChaUploadDO"} element={<ChaUploadDO />} />
          {/* Container Tracking */}
          <Route path={"/container-tracking"} element={<ContainerTracking />} />
          <Route path="/tracking_details/:searchNumber" element={<ContainerTrackingDetails />}/>
          <Route path="/geofence" element={<Geofence />}/>
          <Route path="/add-geofence" element={<AddGeofence />}/>
          <Route path="/editGeofence/:modifiedName" element={<EditGeoFence />}/>
          {/* <Route element={<EditGeoFence/>} /> */}
          {/* <Route path={`${process.env.PUBLIC_URL}/editGeofence/:geofencename`} component={EditGeoFence} /> */}
          <Route path="/truckwithgeofence" element={<TruckWithGeofenceComponent/>} />
          <Route path="/truckavailabilityreport" element={<TptMasterComponent/>} />
          <Route path="/shipmentplanningreport" element={<ShipmentPlanning/>}/>
          <Route path="/shipmentexecutionreport" element={<ShipmentExecution/>}/>
          <Route path="/daywisetracking" element={<DaywiseTracking/>}/>
          <Route path="/bufferreport" element={<BufferReport/>}/>
          {/* <Route path="/report" element={<Report/>}/> */}
          <Route path="/askingratereport" element={<AskingRateReport/>}/>
          <Route path="/tptontimeperf" element={<TptOnTimePerf/>}/>
          <Route path="/transportertat" element={<TransporterTAT/>}/>
          <Route path="/trucktrackingreport" element={<TruckTrackingComponent/>}/>
          <Route path="/planttat" element={<PlantTAT/>}/>
          <Route path="/reportscreen" element={<ReportScreen/>}/>
          <Route path="/ontimePerformanceReport" element={<OnTimePerformanceComponent/>}/>
          <Route path="/uptimegpsdata" element={<UpTimeGPSData/>}/>
          <Route path="/emergencyalerts" element={<EmergencyAlerts/>}/>
          <Route path="/transportertargetvsachievment" element={<TptTargetvsAcheivComponent/>}/>
          <Route path="/lspAccidentsData" element={<LspAccidentsData/>}/>
          <Route path="/users" element={<Users/>} />
          <Route path="/dashboard" element={<DashboardSummary/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);


//const rootElement = document.getElementById('root')
//ReactDOM.render(<Root />, rootElement)