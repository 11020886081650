import React, { useState, useEffect, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Select from "react-select";
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY } from '../common/utils';
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import CountryFlagComponent from '../layouts/countryFlag';
import DestinationFlagComponent from '../layouts/destinationCountryFlag';
import ViewDOdetailsComponent from "../layouts/viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
const ShipmentPlanning = () => {
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);

    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [rowData, setrowData] = useState([])
    var [selectedData,setselectedData] = useState([])
    var [filterdata, setfilterdata] = useState([])
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [checkresetfilter, setcheckresetfilter] = useState(0)
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
    var typeofselection = [
        { label: 'Stuffing Date', value: 'stuffing_date' },
        { label: 'Plant', value: 'plant' },
        { label: 'CHA', value: 'cha' },
        { label: 'Ship to Country', value: 'ship_to_country' },
    ]
    var [showFilterForm, setShowFilterForm] = useState("");

    // var [woReleaseDate, setWoReleaseDate] = useState({
    //     wo_release_from_date: '',
    //     wo_release_to_date: ''
    // })
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: moment().format('DD-MM-YYYY'),
        wo_release_to_date: moment().add(2, 'days').format('DD-MM-YYYY')
    });
    var [plant, setPlant] = useState({ 'label': 'All', 'value': 'All' });
    var [cha, setCha] = useState({ label: 'All', value: 'All' });
    var [shiptocountry, setShiptoCountry] = useState({ label: 'All', value: 'All' });
    var [plantList, setPlantList] = useState([]);
    var [chaList, setChaList] = useState([]);
    var [shipToCountryList, setShipToCountryList] = useState([]);
    const [summaryViewcols, setsummaryViewcols] = useState( [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 140,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Destination Country",
            field: "destination_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'DestinationFlagComponent'

        },
        {
            headerName: "POD",
            field: "pod",
            width: 180,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Model",
            field: "model",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter:function(params){
                return params.data.brand+" "+params.data.brand_variant
            }
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 100,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA",
            field: "cha",
            width: 90,
            filter: true,
            resizable: true,
            // valueGetter: function(params){
            //     return params.data.vin_type+" ("+params.data.unique_code+")"
            // }
        },
        {
            headerName: "MC / CV",
            field: "mc/cv",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 100,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Status",
            field: "payment_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Order Release Date",
            field: "wo_release_date",
            width: 180,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_release_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Production Plan Date",
            field: "production_date_old",
            width: 180,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date_old != "" && params.data.production_date_old != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date_old);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Revised Production Plan Date",
            field: "revised_production_date",
            width: 200,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.revised_production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Vessel ETD",
            field: "vessel_etd_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.vessel_etd_date != "" && params.data.vessel_etd_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.vessel_etd_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd_old",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd_old != "" && params.data.tentative_vessel_etd_old != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd_old);
        //             }
        //             else {
        //                 return "";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Revised ETD",
            field: "revised_tentative_vessel_etd",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.revised_tentative_vessel_etd != "" && params.data.revised_tentative_vessel_etd != undefined) {
                        return getHyphenDDMMYYYY(params.data.revised_tentative_vessel_etd);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date_old",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date_old != "" && params.data.stuffing_date_old != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date_old);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Revised Stuffing Date",
            field: "revised_stuffing_date",
            width: 200,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.revised_stuffing_date != "" && params.data.revised_stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.revised_stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Stuffing Date Reason For Change",
            field: "remarks_by_ib",
            width: 220,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 110,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Gate Open Date",
            field: "gate_open_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.gate_open_date != "" && params.data.gate_open_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.gate_open_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "DO Copy",
            field: "do_copy",
            width: 160,
            filter: true,
            resizable: true,
            cellRenderer: ViewDOdetailsComponent

        },
    ]
)
useEffect(()=>{
    if (showFilterForm ==='slide30'){
       document.body.style.overflow = 'hidden';
    }else{
       document.body.style.overflow = 'auto';
    }
    return ()=>{
       document.body.style.overflow = 'auto';
    }
  }, [showFilterForm])
useEffect(() => {
    if (gridApi.current) {
        getUserGridState();
    }
}, [gridApi]);
    useEffect(() => {
        redirectURL.post('/consignments/getshippingplanedata').then((resp) => {
            try {
                var records = resp.data.records;
                //console.log(records, '75');
                
                if (records != '' && records != undefined) {
                    var data = records
                    setoriginalData(data);
                    records = sortByCreatedDate(records);
                    if(localStorage.getItem('role')=='tpt'){
                        data = data.filter(k=>k.transporter_name==localStorage.getItem('tpt_name'))
                    }
                    //console.log(data, 'data5678')
                    //console.log(records, '80')
                    var from_date = woReleaseDate.wo_release_from_date;
                    var to_date = woReleaseDate.wo_release_to_date;
                    if (from_date && to_date) {
                        from_date = moment(from_date, 'DD-MM-YYYY');
                        to_date = moment(to_date, 'DD-MM-YYYY');
                        //console.log(from_date, '271');
                       //console.log(to_date, '272');
                        var filteredData = data.filter(item => {
                            var wo_release = getHyphenDDMMYYYY(item.wo_release_date
                                )
                            var itemDate = moment(wo_release, 'DD-MM-YYYY')
                            return itemDate.isBetween(from_date, to_date, null, '[]');
                        });
                        //console.log(filteredData, 'filteredData');

                        setfilterdata(filteredData);
                        setrowData(filteredData);
                        setselectedData(filteredData)
                        var plantdata = filteredData
                        var plantList = [{ 'label': 'All', 'value': 'All' }]
                        plantdata.map((f) => {
                            //console.log(f, '103')
                            plantList.push({ 'label': f.plant, 'value': f.plant })
                        })
                        plantList = removeDuplicateObjects(plantList)

                        setPlantList(plantList);
                        //cha
                        var chadata = filteredData
                        var chaList = [{ 'label': 'All', 'value': 'All' }]
                        chadata.map((f) => {
                            //console.log(f, '103')
                            chaList.push({ 'label': f.cha, 'value': f.cha })
                        })
                        chaList = removeDuplicateObjects(chaList)
                        setChaList(chaList);
                        //ship_to_coun
                        var shiptocountrydata = filteredData
                        var shipToCountryList = [{ 'label': 'All', 'value': 'All' }]
                        shiptocountrydata.map((f) => {
                            //console.log(f, '103')
                            shipToCountryList.push({ 'label': f.ship_to_country, 'value': f.ship_to_country })
                        })
                        shipToCountryList = removeDuplicateObjects(shipToCountryList)
                        setShipToCountryList(shipToCountryList);
                    }
                }

            } catch (e) {
                console.error('Error:', e);
            }
        });

    }, [])
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/shipmentplanningreport' })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
                
                // setsummaryViewcols(oCols);
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    const handleFilterOptions = (e) => {
        if (e.value == '') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if (val != '' && val != undefined) {
            var filteredData
            if (typeoffield == 'ship_to_country') {
                filteredData = originalData.filter(item => item.ship_to_country.toLowerCase() == val.toLowerCase());
            }
            else if (typeoffield == 'plant') {
                filteredData = originalData.filter(item => item.plant.toLowerCase() == val.toLowerCase());
            }
            else {
                //console.log('96')
                filteredData = originalData.filter(item => item.cha.toLowerCase() == val.toLowerCase());
            }
            if(filteredData!='' && filteredData!=undefined){
                if(localStorage.getItem('role')=='tpt'){
                    filteredData = filteredData.filter(k=>k.transporter_name==localStorage.getItem('tpt_name'))
                }
            }
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
        else {
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/shipmentplanningreport'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }

    // const onClickSaveGridState = () => {
    //     try {
    //         if (gridApi.current) {
    //             const columnState = gridColumnApi.current.getColumnState();
    //             const columnGroupState = gridColumnApi.current.getColumnGroupState();
    //             const sortState = gridApi.current.api.getSortModel(); // Accessing getSortModel from gridApi.current.api
    //             const filterState = gridApi.current.api.getFilterModel();

    //             var screenpage = '/shipmentplanningreport';
    //             let reqparams = {
    //                 gridcolumns: columnState,
    //                 gridgroup: columnGroupState,
    //                 gridcolsort: sortState,
    //                 gridcolfilter: filterState,
    //                 userId: localStorage.getItem("userid"),
    //                 screenurl: window.location.pathname,
    //                 screentitle: screenpage
    //             };

    //             redirectURL.post("/consignments/saveGridStates", reqparams)
    //                 .then((response) => {
    //                     setshow(true);
    //                     setbasicTitle("Successfully saved grid layout");
    //                     setbasicType("success");
    //                     setTimeout(() => {
    //                         getUserGridState();
    //                     }, 2000);
    //                 })
    //                 .catch(function (e) {
    //                     //console.log("Error ", e);
    //                 });
    //         } else {
    //             console.error('Grid API is .');
    //         }
    //     } catch (e) {
    //         //console.log(e);
    //     }
    // };
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        var to_date = woReleaseDate.wo_release_to_date
        //console.log(from_date, "formDate55")
        //console.log(to_date, "toDate55")
        //console.log(originalData, '129')
        if (from_date=="Invalid date"){
            from_date = '';
        }
        if (to_date =='Invalid date'){
            to_date = '';
        }
        
        if (from_date !=''){
            const dateObj = moment(from_date, 'DD-MM-YYYY');
            const formattedFromDate = dateObj.toDate();
            from_date= formattedFromDate;
        }
        if (to_date !=''){
            const dateObj = moment(to_date, 'DD-MM-YYYY');
            const formattedFromDate = dateObj.toDate();
            to_date= formattedFromDate;
        }

   
        //console.log((from_date), "fromDate555")
        //console.log(to_date, "toDate55")
        const valid = from_date<to_date
        //console.log(valid, "condition")
        if (from_date != '' && from_date != undefined && from_date != null && to_date != '' && to_date != undefined && to_date != null) {
            if (from_date <= to_date){
                from_date = moment(from_date, 'DD-MM-YYYY')
                to_date = moment(to_date, 'DD-MM-YYYY')
                var filteredData = originalData.filter(item => {
                    var itemDate = getHyphenDDMMYYYY(item.wo_release_date
                        )
                    itemDate = moment(itemDate, 'DD-MM-YYYY')
                    return itemDate.isBetween(from_date, to_date, null, '[]') || itemDate.isSame(from_date);
                });
                //console.log(filteredData, 'filteredData1')
                if(filteredData!='' && filteredData!=undefined){
                    if(localStorage.getItem('role')=='tpt'){
                        filteredData = filteredData.filter(k=>k.transporter_name==localStorage.getItem('tpt_name'))
                    }
                }
                setrowData(filteredData)
                setselectedData(filteredData)
                var plantdata = filteredData
                var plantList = [{ 'label': 'All', 'value': 'All' }]
                plantdata.map((f) => {
                    //console.log(f, '103')
                    plantList.push({ 'label': f.plant, 'value': f.plant })
                })
                plantList = removeDuplicateObjects(plantList)

                setPlantList(plantList);
                //cha
                var chadata = filteredData
                var chaList = [{ 'label': 'All', 'value': 'All' }]
                chadata.map((f) => {
                    //console.log(f, '103')
                    chaList.push({ 'label': f.cha, 'value': f.cha })
                })
                chaList = removeDuplicateObjects(chaList)
                setChaList(chaList);
                //ship_to_coun
                var shiptocountrydata = filteredData
                var shipToCountryList = [{ 'label': 'All', 'value': 'All' }]
                shiptocountrydata.map((f) => {
                    //console.log(f, '103')
                    shipToCountryList.push({ 'label': f.ship_to_country, 'value': f.ship_to_country })
                })
                shipToCountryList = removeDuplicateObjects(shipToCountryList)
                setShipToCountryList(shipToCountryList);
            }
            else{
                setbasicTitle('From Date sholud be less than To Date')
                setbasicType('danger')
                setoverly('show-n')
                setshow(true)
            }
        }
        else {
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    const handleDatesSelected = (e) => {
        //console.log(e, "8989")
      
        const date = moment(e).format('DD-MM-YYYY');

        //console.log(date, "datemoment")

        setWoReleaseDate(prev => ({
            ...prev,
            wo_release_from_date: date
        }))
       
    };
    const handleDatesSelected1 = (e) => {
        //console.log(e, "8989")
        const date = moment(e).format('DD-MM-YYYY');

        //console.log(date, "datemoment")
        setWoReleaseDate(prev => ({
            ...prev,
            wo_release_to_date: date
        }))
    };
    const onClickDOdetails = (params) => {
        //console.log('163', params)
        //console.log(params.data.attachmentFileName)
        if (params.data.attachmentFileName != undefined && params.data.attachmentFileName != "") {
            var fileparams = {
                attachmentFileName: params.data.attachmentFileName,
                work_order_no: params.data.work_order_no
            }
            redirectURL.post("/consignments/downloadDOFileFile", fileparams, {
            }).then((response) => {
                const imageURL = response.data;

                window.open(imageURL, '_blank');
            }).catch(function (error) {
                //console.log(error);
            })
        }
    }
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))
    }
    const columnDefs = summaryViewcols

    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onClickFilters = () => {
        setShowFilterForm("slide30")
        setoverly("show-m")
    }

    const hideSlideBlock = () => {

        // showUpdateForm:"show-n",
        setShowFilterForm("")
        // showAddForm: "show-n",
        setoverly("show-n")
        // setloadshow("show-n")
        // showInvoiceForm: "show-n"

    }
    // const onChangeHandler=(e)=>{
    //     if (e.value=="plant"){
    //         setPlant(e);
    //     }else if (e.value=="cha"){
    //         setCha(e);
    //     }else if (e.value=="shiptocountry"){
    //         setShiptoCountry(e);
    //     }

    // }


    const filtersData = (e) => {
        e.preventDefault();
        setShowFilterForm('')
        setoverly('show-n')
        var data = selectedData
        //console.log(data, '723')
        if (plant.value != '' && plant.value != undefined && plant.value != 'All') {
            data = data.filter(f => f.plant == plant.value)
        }
        else {
            data = data
        }
        //console.log(cha.value, '723')
        if (cha.value != '' && cha.value != undefined && cha.value != 'All') {
            data = data.filter(f => f.cha == cha.value)
        } else {
            data = data
        }
        //console.log(data, '723')

        if (shiptocountry.value != '' && shiptocountry.value != undefined && shiptocountry.value != 'All') {
            data = data.filter(f => f.ship_to_country == shiptocountry.value)
        } else {
            data = data
        }
        //console.log(data, '723')
        if(data!='' && data!=undefined){
            if(localStorage.getItem('role')=='tpt'){
                data = data.filter(k=>k.transporter_name==localStorage.getItem('tpt_name'))
            }
        }
        setrowData(data);
        // setPlant({'label':'','value':''})
        // setCha({'label':'','value':''})
        // setShiptoCountry({'label':'','value':''})
        // setPlant("");
        // setCha("");
        // setShiptoCountry("");

        // const filtersDataForm = {
        //     "Plant":plant,
        //     "CHA":cha,
        //     "ShiptoCountry":shiptocountry
        // }
        // //console.log(filtersDataForm);
        // var filteredData = originalData.filter((item)=>{
        //     return item.plant==plant || item.cha==cha || item.ship_to_country==shiptocountry});
        // setrowData(filteredData);

    }
    const restrictToOther=(e)=>{
        e.target.value = e.target.value.replace()
    }

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Shipment Planning Report
                    </h1>
                </div>
                <div className="card m-10p">
                    <div className="card-body" id="counterCardBody">
                        <div className="row">
                            <div className="col-sm-8 checkForDateFilter_gap">
                                <div className="row">

                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> From Date(Work Order Release) </label>
                                            <Datetime
                                                key={woReleaseDate.wo_release_from_date}
                                                // onKeyDown ={(e)=> restrictToOther(e)}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="from_date"
                                                inputProps={{readOnly:'true'}}
                                                value={woReleaseDate.wo_release_from_date}
                                                dateFormat="DD-MM-YYYY"
                                                id='from_date'
                                                timeFormat={false}
                                                
                                                onChange={(e) => handleDatesSelected(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> To Date(Work Order Release) </label>
                                            <Datetime
                                                key={woReleaseDate.wo_release_to_date}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="to_date"
                                                inputProps={{readOnly:'true'}}
                                                value={woReleaseDate.wo_release_to_date}
                                                dateFormat="DD-MM-YYYY"
                                                id='to_date'
                                                timeFormat={false}
                                                onChange={(e) => handleDatesSelected1(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <button className="btn btn-success mt-30p" onClick={filterDatafordates}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col textAlignRight">
                                        <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                                            Back
                                        </a>
                                        <button type="button" className="f12 mr-5p btn btn-outline-primary" onClick={onClickFilters}>
                                            <img src={require("../../assets/images/filter1.png")} style={{ height: "10px", marginRight: '5px' }} />Filters
                                        </button>
                                        <button type="button" className="f12 btn btn-outline-danger" onClick={onClickSaveGridState}>
                                            <i className="f12 icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-sm-12" >
                                <div id="idgrid" style={{ width: "100%", height: "450px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        ref={gridApi}
                                        // modules={AllModules}
                                        columnDefs={columnDefs}
                                        defaultColDef={{
                                            sortable: true,
                                            filter: true,
                                            editable: false,
                                            resizable: true,
                                            menuTabs: ['filterMenuTab']
                                        }}
                                        rowData={rowData}
                                        suppressRowClickSelection={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        enableRangeSelection={true}
                                        paginationPageSize={100}
                                        onGridReady={onGridReady}
                                        onGridState={onGridState}
                                        rowSelection={'multiple'}
                                        masterDetail={true}
                                        pagination={true}
                                        frameworkComponents={{ CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel"
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                        gridOptions={{
                                            icons: {
                                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                                last: `<img src="${customLastIcon}" alt="Last" />`,
                                            },
                                        }}
                                        context={{ onClickDOdetails }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className={"sliderBlock2 " + showFilterForm}>
                    <div className="slide-r-title slider_title_style">
                        <h6>Select Shipment Planning Filters</h6>
                        <span className="float-right ffclosebtn" style={{ marginRight: "5px", marginTop: "-29px" }} onClick={hideSlideBlock} >X</span>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={filtersData}>
                                <div className="slide-body">
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12 mb-20p" style={{ color: "grey", borderRadius: "10px" }}>
                                            <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Plant</label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                value={plant}
                                                // multi={true}
                                                // className={"border-radius-0"} 
                                                onChange={(e) => setPlant(e)}
                                                style={{ borderRadius: "0px" }}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.8rem",
                                                        color: "#6e707e",
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '0.9rem'
                                                    })
                                                }}
                                                options={plantList}
                                            />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                            <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>CHA</label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                value={cha}
                                                // multi={true}
                                                // className={"border-radius-0"} 
                                                onChange={(e) => setCha(e)}
                                                style={{ borderRadius: "0px" }}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.8rem",
                                                        color: "#6e707e",
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '0.9rem'
                                                    })
                                                }}
                                                options={chaList}
                                            />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p" style={{ color: "grey" }}>
                                            <label style={{ color: "#808080", fontSize: "14px", fontFamily: "Inter" }}>Ship to Country</label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                value={shiptocountry}
                                                // multi={true}
                                                // className={"border-radius-0"} 
                                                onChange={(e) => setShiptoCountry(e)}
                                                style={{ borderRadius: "0px" }}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.8rem",
                                                        color: "#6e707e",
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '0.9rem'
                                                    })
                                                }}
                                                options={shipToCountryList}
                                            />
                                        </div>

                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success" style={{ fontFamily: "Inter" }}>Submit</button>
                                        </div>

                                    </div>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (overly)}></div>
        </>

    )
}
function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
        if (arr.length > 0) {
            const comparison = (a, b) => {
                const dateA = new Date(a.modified_date);
                const dateB = new Date(b.modified_date);
                if (descending) {
                    return dateB - dateA; // Ascending order
                } else {
                    return dateA - dateB; // Descending order
                }
            };
            arr.sort(comparison);
            return arr;
        }
    } else {
        return arr;
    }
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}

export default ShipmentPlanning;