
import React, { Component } from 'react';
 
const DeleteTruck = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickDeleteTruck(props);
    };
console.log("props ", props.data)
    return (
        <div>
            {/* <button type="button"
              className={"btn activet font12 mb-5p"} 
              title="Update" 
              onClick={handleClick}>
                   
            </button> */}
          <i class="fa fa-trash" onClick={handleClick}></i>
        </div>
    );
};

export default DeleteTruck;
