

import React, { Component, createRef, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import CommentActions from "../layouts/commentsComponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenDDMMMYYYY } from '../common/utils'; 
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import UpdateTransporterDetails from './UpdateTransporterDetails';
import DisabledUpdateTransporterDetails from './DisabledUpdateTransporterDetails';
import UpdateInvoice from './UpdateInvoice';
// import expandLegs from './expandLegs';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import Datetime from 'react-datetime';
import download from 'js-file-download';
import redirectURL from "../redirectURL";
import withRouter from '../withRouter';
import Select from 'react-select';
import Modal from "react-responsive-modal";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class FFSeaImportsList extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
            resizable: true,
            menuTabs: ['filterMenuTab']
        },
        eventAction: null,
        balpendingbtn: "activet",
        show: false,
        frameworkComponents : {
          UpdateTransporterDetails : UpdateTransporterDetails,
          DisabledUpdateTransporterDetails: DisabledUpdateTransporterDetails,
          UpdateInvoice: UpdateInvoice,
        //   expandLegs: expandLegs,
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        transporter: { value: "", label: "Select" },
        transporterName: "",
        showInvoiceForm: "",
        transporterOptions: [],
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        drilldownArr:[],
        file:"",
        selectedBookingNo: "",
        selectedRowInPending: "",
        selectedRowInClosure: "",
        selectedRowInProcess: "",
        selectedClosureRow: "",
        rowDataInProcess: "",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'show-n',
        selectedRowRequirementId: "",
        rowData:[],
        showUpdateForm: "",
        transporterArr: [],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        overlay: "",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        plants:[],
        plant_name:"",
        cellPlants:[],
        closure:"",
        invoiceData:[],
        pageType:1,
        invoiceNumber: "",
        invoiceDate: "",
        ringiData: [{}],
        job_no:"",
        pre_alert_zip_file:"",
        detailCellRendererParams:{},
        openModal:false
        //components : {datepicker:getDatePicker()}
    }
    this.downloadRingiFile = this.downloadRingiFile.bind(this);
    this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
    this.onUpdateInvoice = this.onUpdateInvoice.bind(this);
    this.onExpand = this.onExpand.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}
previewDocument = (document, type) => {
    console.log("document", document);
    if(type == "LR"){
        this.setState({
            openModal: true,
            documentUrl: document.lr_file_url,
            documentType: "pdf",
        });
    } else if(type == "BL"){
        this.setState({
            openModal: true,
            documentUrl: document.bl_file_url,
            documentType: "pdf"
        });
    }else if(type=="DO"){
        this.setState({
            openModal: true,
            documentUrl: document.do_file_url,
            documentType: "pdf"
        });
    }
};
// previewBLDocument = (document) => {
//     console.log("document", document);
//     this.setState({
//       openModal: true,
//       documentUrl: document.bl_file_url,
//       documentType: "pdf",
//     });
// }
// previewDODocument = (document) => {
//     console.log("document", document);
//     this.setState({
//       openModal: true,
//       documentUrl: document.do_file_url,
//       documentType: "pdf",
//     });
// }
componentDidMount (){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
}

onLoadPageData() {
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    var params = {
        transporter_assigned: {$exists: 0}
    }
    redirectURL.post("/consignments/getBookingData", params)    
    .then((response) => {
        let allBookingDetails = response.data.bookingData;
            console.log(allBookingDetails, "allBookingDetails")

            let balDispatchPendingRowDetails = allBookingDetails.filter((item) => {
                if(item.bal_flag === 1){
                    return item;
                }
            })
            console.log("balDispatchPendingRowDetails",balDispatchPendingRowDetails);

            // let balDispatchInProcessRowDetails = allBookingDetails.filter((item)=> {
            //     if(item.bal_flag === 2){
            //         return item;
            //     }
            // })
            // console.log(balDispatchInProcessRowDetails, "balDispatchInProcessRowDetails")

            // let balDispatchInClosureRowDetails = allBookingDetails.filter((item) => {
            //     if(item.bal_flag === 7){
            //         return item;
            //     }
            // })
            // console.log("balDispatchInClosureRowDetails",balDispatchInClosureRowDetails);

            this.setState({
                overly:"show-n",
                rowData: balDispatchPendingRowDetails,
                inBalPending : balDispatchPendingRowDetails,
                // inBalInProcess: balDispatchInProcessRowDetails,
                // inBalClosure : balDispatchInClosureRowDetails,
            })
    }, () => {
        console.log(this.state.rowData, "setting rowData for pending")
    })
    .catch(function(e){
        console.log("Error ",e)
    })
    this.setState({
        overly:"show-n",
        loadshow:"show-n",
    });
}

closeModal = () => {
    this.setState({
        openModal: false,
    });
};
onShowTicketHistory = async (rowdata) =>{
    console.log("rowdata ", rowdata)

    var balInDetailedData=[];
    
    await this.setState({
            
        detailCellRendererParams:{
            suppressRefresh: true,
            detailGridOptions: {
                columnDefs: [					
                    { 
                        headerName: "Work Order No",
                        field: "booking_no",
                        width:100,
                        resizable: true,
                    },
                    { 
                        headerName: "Truck No",
                        field: "truck_number", 
                        width:180,
                        resizable: true,
                    },
                    {
                        headerName: "Container No",
                        field: "container_number", 
                        width:180,
                        resizable: true,
                    },
                    // {
                    //     headerName: "Pickup Date",
                    //     field: "pickup_date_time",
                    //     width:130,
                    //     filter: true,
                    //     resizable: true, 
                    //     valueGetter:function(params){
                    //         if(params.data.pickup_date_time != ""){
                    //             return getHyphenDDMMMYYYY(params.data.pickup_date_time);
                    //           }
                    //           else{
                    //               return "";
                    //           }   
                    //     },         
                    // },
                     { 
                         headerName: "Reporting to CHA Time",
                         field: "reporting_to_cha_datetime", 
                         width:180,
                         resizable: true,
                         valueGetter:function(params){
                            if(params.data.reporting_to_cha_datetime != ""){
                                return getHyphenDDMMMYYYY(params.data.reporting_to_cha_datetime);
                              }
                              else{
                                  return "";
                              }   
                        },
                      },
                      { 
                          headerName: "Gate In Time",
                          field: "gatein_datetime", 
                          width:180,
                          resizable: true,
                          valueGetter:function(params){
                            if(params.data.gatein_datetime != ""){
                                return getHyphenDDMMMYYYY(params.data.gatein_datetime);
                              }
                              else{
                                  return "";
                              }   
                        },
                       },						
                      { 
                          headerName: "Shipped Onboard Time",
                          field: "shipped_onboard_time", 
                          width:180,
                          resizable: true,
                          valueGetter:function(params){
                            if(params.data.shipped_onboard_time != ""){
                                return getHyphenDDMMMYYYY(params.data.shipped_onboard_time);
                              }
                              else{
                                  return "";
                              }   
                        },
                       },					
                       { 
                        headerName: "View LR Details",
                        field: "", 
                        width:180,
                        resizable: true,
                        cellRendererFramework: (params)=>{ return <button className='btn btn-primary'
                            style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                            onClick={this.previewDocument.bind(this, params, "LR")}>View LR Details</button>
                        }
                        // valueGetter:function(params){
                        //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                        // },
                    },	
                    { 
                        headerName: "View BL Details",
                        field: "", 
                        width:180,
                        resizable: true,
                        cellRendererFramework: (params)=>{ return <button className='btn btn-primary'
                            style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                            onClick={this.previewDocument.bind(this, params, "BL")}>View BL Details</button>
                        }
                        // valueGetter:function(params){
                        //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                        // },
                    },
                    { 
                        headerName: "View DO Details",
                        field: "", 
                        width:180,
                        resizable: true,
                        cellRendererFramework: (params)=>{ return <button className='btn btn-primary'
                            style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                            onClick={this.previewDocument.bind(this, params, "DO")}>View DO Details</button>
                        }
                        // valueGetter:function(params){
                        //     return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                        // },
                    },					
                ],
                overlayNoRowsTemplate: 'No data available',                     
            },
            // getDetailRowData: function(param) {
            //     //console.log("Step 4 ",transitedelaydata)
            //     // console.log("Step 4 ",param)
            //     //----------------------------------//

            //     var legdata=[{
            //         _id:param.data._id,
            //         // consignment_codes:param.data.consignment_codes,
            //         // exception_closed:param.data.exception_closed,
            //         booking_no:param.data.booking_no,
            //         requirement_id:param.data.requirement_id,
            //         reporting_to_cha_datetime:param.data.reporting_to_cha_datetime,
            //         gatein_datetime:param.data.gatein_datetime,
            //         shipped_onboard_time:param.data.shipped_onboard_time,
            //         // ready_to_notify:param.data.ready_to_notify,
            //         // packet_received_on:packetreceived,
            //         // reset_packet_latitude:param.data.reset_packet_latitude,
            //         // reset_packet_longitude:param.data.reset_packet_longitude,
            //         // no_gps_ticket:param.data.no_gps_ticket,
            //         // _id: param.data._id,
            //         // consignment_codes:""
            //     }]
            //     // legsarr.push(legdata)
            //     param.successCallback(legdata);

            getDetailRowData: function(params) {
                console.log("Step 4 ",params)
                var qu = {
                    // ticket_id:params.data.ticket_id,
                    booking_no:params.data.booking_no,
                    cha_flag: {$in:[2,3,4,5, 7]}
                }
                redirectURL.post('/consignments/getBookingLegsData', qu)
                .then(async (response) =>{
                    console.log("Step 1 ",response.data)


                    params.successCallback(response.data.bookingData);
                    //console.log("Step 2 ",response.data)
                    
                });
                
            }
        }
    });

    //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
    if(rowdata.column.colDef.field == '_id')
    {
        rowdata.node.setExpanded(!rowdata.node.expanded);
        if(rowdata.node.expanded == false)
        {
            balInDetailedData=[]
        }
    }
    else{

        rowdata.node.setExpanded(false);
    }
}
onLoadPageDataForInProcess () {
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    redirectURL.post("/consignments/getBookingData")    
    .then((response) => {
        let allBookingDetails = response.data.bookingData;
            console.log(allBookingDetails, "allBookingDetails")
            let balDispatchInProcessRowDetails = [];
            balDispatchInProcessRowDetails = allBookingDetails.filter((item) => {
                if(item.bal_flag === 2){
                    return item;
                }
            })

            console.log("balDispatchInProcessRowDetails", balDispatchInProcessRowDetails);
            this.setState({
                overly: "show-n",
                loadshow: "show-n",
                rowData: balDispatchInProcessRowDetails,
                inBalInProcess : balDispatchInProcessRowDetails,
            })
    }, () => {
        console.log(this.state.rowData, "balupdates inprocess")
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}
onLoadPageDataForClosure () {
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    redirectURL.post("/consignments/getBookingData")    
    .then((response) => {
        let allBookingDetails = response.data.bookingData;
            console.log(allBookingDetails, "allBookingDetails")
            let balDispatchInClosureRowDetails = [];
            balDispatchInClosureRowDetails = allBookingDetails.filter((item) => {
                if(item.bal_flag === 7){
                    return item;
                }
            })

            console.log("balDispatchInClosureRowDetails", balDispatchInClosureRowDetails);
            this.setState({
                overly: "show-n",
                loadshow: "show-n",
                rowData: balDispatchInClosureRowDetails,
                inBalClosure : balDispatchInClosureRowDetails,
            })
    }, () => {
        console.log(this.state.rowData, "ffupdates pending")
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}
invoiceNumberHandler = (e) => {
    this.setState({
        invoiceNumber: e.target.value,
    })
}
invoiceDateHandler = (e) => {
    this.setState({
        invoiceDate: e.target.value,
    })
}
// onLoadPageData = async (dataType) => {
    // this.setState({
    //     overly:"show-m",
    //     loadshow:"show-m",
    // });
    // var supplier_code = localStorage.getItem("supplier_code");
    // var params = {
    //     "ff_code" : supplier_code
    // }
    // redirectURL.post("/consignments/getFFSeaImports",params)    
    
// }

// onLoadClosureData(){
//     this.setState({
//         overly:"show-m",
//         loadshow:"show-m",
//     });
//     var supplier_code = localStorage.getItem("supplier_code");
//     var params = {
//         "ff_code" : supplier_code
//     }
//     redirectURL.post("/consignments/getFFSeaImportsClosures",params)    
//     .then((response) => {
//         var records = response.data;
//         console.log("records ", records)
//         this.setState({
//             rowData : records,
//             overly:"show-n",
//             loadshow:"show-n",
//         })
//     })
//     .catch(function(e){
//         console.log("Error ",e)
//     })
// }
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}

onClickUpdateItem = async (rownode) => 
    {
        console.log("Rowdnode Update", rownode)
        var selectedRowData = rownode.data;
        // console.log(selectedRowData, "selectedRowData")
        var selectedBookingNo = selectedRowData.booking_no;
        console.log(selectedBookingNo, "selectedBookingNo")
        this.setState({
            showUpdateForm:"slide30",
            showInvoiceForm: "",
            overly:"show-m",
            selectedRowInPending: selectedRowData,
            selectedBookingNo: selectedBookingNo
        })
        await redirectURL
        .get('/consignments/gettransporterDetails')
        .then((resp)=> {
            let TransporterDetails = resp.data.transporterDetails;
            console.log(TransporterDetails, "TranporterDetails");
            let transporterArr = TransporterDetails.map((obj)=> 
                ({ transporter_name: obj.transporter_name, transporter_code: obj.transporter_code })
            )
            console.log(transporterArr, "transporterArr")
            this.setState({
                transporterArr: transporterArr
            }, ()=>{
                let transporterOptions = this.state.transporterArr.map((i)=> ({
                    value: i.transporter_code,
                    label: i.transporter_name
                }))
                console.log(transporterOptions, "select transport")
                this.setState({
                    transporterOptions: transporterOptions
                })
            })
            // let transporterNames = TransporterDetails.map((item)=> {
            //     return item.transporter_name
            // })
            // console.log(transporterNames, "transporterNames")
            // this.setState({
            //     transporterArr: transporterNames
            // }, () => {
            //     console.log(this.state.transporterArr, "transporter Arr List")
            //     const transporterOptions = this.state.transporterArr.map((transporter) => ({
            //         value: transporter,
            //         label: transporter,
            //     }));
            //     console.log(transporterOptions, "transporterOptions")
            //     this.setState({
            //         transporterOptions: transporterOptions
            //     })
            // })
            
        })
}
onUpdateInvoice = async (rownode) => {
    console.log("Rowdnode Update", rownode)
        var selectedRowDataInProcess = rownode.data;
        // console.log(selectedRowData, "selectedRowData")
        var rowDataInProcess = selectedRowDataInProcess.booking_no;
        console.log(rowDataInProcess, "selectedBookingNo")
        this.setState({
            showUpdateForm:"",
            showInvoiceForm: "slide30",
            overly:"show-m",
            selectedRowInProcess: selectedRowDataInProcess,
            rowDataInProcess: rowDataInProcess
        })
}
onExpand = async (rownode) =>{
    // console.log("Expand this row", rownode)
    let bookingNoToExpand = rownode.data.booking_no;
    let payload = {
        booking_no: bookingNoToExpand
    }
    await redirectURL
        .post("/consignments/getInDetailedFromLegsForBookingNo", payload)
        .then((response) => {
            if(response.status == 200){
                console.log(response.data.bookingDataFromLegs, "bookingDataFromLegs")
                let data = response.data.bookingDataFromLegs;
                let drilldownRows = data.map((item) => ({
                    booking_no: item.booking_no,
                    lr_file_url : item.lr_file_url,
                    truck_number: item.truck_number,
                    shipped_onboard_time: item.shipped_onboard_time,
                    reporting_to_cha_datetime: item.reporting_to_cha_datetime,
                    gatein_datetime: item.gatein_datetime,
                    container_number: item.container_number
                }))
                console.log(drilldownRows, "405")
                this.setState({
                    drilldownArr: [...drilldownRows]
                })
            }
        });

}
closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        invoiceData:[],
        ringiData: [{}],    
    });
    
}

onClickTab(Oval){
    if(Oval === 1)
    {
        this.onLoadPageData();
        this.setState({
            balpendingbtn: "activet",
            balInProcessbtn: "",
            balInClosurebtn: "",
            // rowData: this.state.inBalPending
        })
    }
    else if(Oval === 2)
    {
        console.log("entered second")
        this.onLoadPageDataForInProcess()
        this.setState({
            balpendingbtn: "",
            balInProcessbtn: "activet",
            balInClosurebtn: "",
        })
    } else if(Oval === 3){
        console.log("entered second")
        this.onLoadPageDataForClosure()
        this.setState({
            balpendingbtn: "",
            balInProcessbtn: "",
            balInClosurebtn: "activet"
        })
    }
}

viewDetails(e){
    if(e.colDef.field == "invoiceData")
    {
        var invData = [];
        if(e.data.invoiceData !=undefined && e.data.invoiceData !='')
        {
            var invoiceData = JSON.parse(e.data.invoiceData);
            if(invoiceData !=undefined && invoiceData.length > 0)
            {
                invData = invoiceData;
            }
        }
        var sliderTitle = e.data.job_no+" - Pre alert documents";
        var pre_alert_zip_file = "";
        if(e.data.pre_alert_zip_file !=undefined && e.data.pre_alert_zip_file !="")
        {
            pre_alert_zip_file = e.data.pre_alert_zip_file;
        }
        this.setState({
            overly :"show-m",
            job_no:e.data.job_no,
            invoiceData:invData,
            ringiData: invData, 
            bulkslide : "slider-translate-60p",
            sliderTitle : sliderTitle,
            pre_alert_zip_file : pre_alert_zip_file
        })
    }
    if(e.colDef.field == "other_docs")
    {
        var ringiFile = e.data.other_docs
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadRingiFile",rngParam).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadSeaImportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
    };
    this.setState({
        ringiData: [...this.state.ringiData, item]
    });
};

handleRemoveRingiRow = (idx) => {
    this.state.ringiData.splice(idx, 1);
    this.setState({ ringiData: this.state.ringiData });
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        if(window.confirm("Are You Sure! Do you want really delete?"))
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFile",rngParam).then((response)=>{
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

handleRemoveRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        if(window.confirm("Are You Sure! Do you want really delete?"))
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFile",rngParam).then((response)=>{
                $(".rnFile_"+idx).html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

handleDownloadRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadZipFile = (idx) => {
    var ringiFile = idx;
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/storeSeaImportsPreAlertDocuments",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
hideSlideBlock(){
    this.setState({
        showUpdateForm:"show-n",
        overly:"show-n",
        showInvoiceForm: "show-n"
    })
}
formUpdateItem = async (e) => {
    e.preventDefault();
    // console.log(this.state.transporter, "selected value")
    // const formData = new FormData();
    // formData.append("transporter_assigned", this.state.transporter)
    // formData.append("booking_no", this.state.selectedBookingNo)
    console.log(this.state.selectedBookingNo, "610")
    var res = this.gridApi.updateRowData({ remove: [this.state.selectedRowInPending] });
    let payload = {
        booking_no: this.state.selectedBookingNo,
        transporter_assigned: this.state.transporter.label,
        transporter_code: this.state.transporter.value
    }
    // console.log(payload, "503")
    if(this.state.transporter){
        // console.log("hi")
        redirectURL
        .post("/consignments/onAssignedTransporter", payload)
        .then((response) => {
            if(response.status == 200){
                console.log(response.updatedData, "updatedRecordWithTransporterAssigned")
                this.hideSlideBlock();
            }
        });
        // formData.append('ffp', ffp);
        // formData.append('exim_booking_pdf', this.state.bookingPDF);
        // formData.append('booking_id', this.state.bookingId);
        // if(this.state.isBookingPDFChanged) {
        //     redirectURL
        //         .post("/exim/saveFreightForwarderBookingAttchmnts", formData)
        //         .then((response) => {
        //         if (response.data.status === "success") {
        //             document.getElementById("bookingDoc").value = null;
        //             this.setState({
        //                 BookingId: "",
        //                 documentExtension: "",
        //             });
        //             this.hideSlideBlock();
        //         }
        //         });
        // }
    }else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Fields cannot be empty",
        });
    }    
    this.onLoadPageData();
    // console.log(this.state.selectedRowRequirementId, "selectedRowRequirementId")
    
    // let payload = {
    //     requirement_id: this.state.selectedRowRequirementId,
    //     booking_id: this.state.bookingId,
    //     ffp: this.state.ffp
    // }
    // console.log(payload, "payload for updating FFP")
    // await redirectURL
    // .post("/exim/updateBookingRecordFfp", payload)
    // .then((resp) => {
    //     console.log(resp.data,"updatedBookingRecord with FFP")
    //   })
    // .catch((err) => {
    //     console.log("updatedBookingRecord with FFP couldn't happen", err);
    // });

    
    // if(indexToRemove !== -1){
        
    // }
    
    
    // console.log(this.state.rowData, "after submitting the updated rowData is")
    // var route = this.state.route;
    // var source = this.state.source;
    
    // var params = {
    //     route:route,
    //     source:source,
    // };
    // $(".othercost").each(function(){
    //     // console.log("name ", $(this).attr("id"));
    //     var fild = $(this).attr("id");
    //     var fildval = $(this).val();
    //     // console.log("value ", $(this).val());
    //     params[fild] = fildval;
    // });

    // console.log("pp ", params)
    
    // redirectURL.post("/updatefreightrates",params)
    // .then((response) => {
    //     if(response.data.status === "success")
    //     {
    //         window.location.reload();
    //     }
    // })
    // redirectURL.post("/updatefreightratesrow",params)
    // .then((response) => {
    //     if(response.data.status === "success")
    //     {
    //         window.location.reload();
    //     }
    // })
} 
invoiceFormUpdateItem = async (e) => {
    e.preventDefault();
    var res = this.gridApi.updateRowData({ remove: [this.state.selectedRowInProcess] });
    let payload = {
        invoice_number: this.state.invoiceNumber,
        invoice_date: this.state.invoiceDate,
        booking_no: this.state.rowDataInProcess
    }
   await redirectURL
        .post("/consignments/onAssignedInvoice", payload)
        .then((response) => {
            if(response.status == 200){
                console.log(response.updatedData, "updatedRecordWithTransporterAssigned")
                this.hideSlideBlock();
            }
        });
}
newFilter = (filterType, selOption) => {
    this.setState({ [filterType]: selOption });
};
formHandler(event){
    event.preventDefault();

    let saveData = new FormData();
    //console.log("file ",this.state.file)
    var rngLength=$("#rngLength").val();
    console.log(rngLength,"rngLength")
    saveData.append("rngLength", rngLength);
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
        if(ringiFile !='')
        {
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
        else
        {
            var ringiFile=$("#hiddenRingiFile_"+r).val();
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
    }
    ringiData=JSON.stringify(ringiData);
    console.log(ringiData,"ringiData")
    for(var rn=0;rn<rngLength;rn++)
    {
        var ringiFile="ringi_file_"+rn;
        
        saveData.append(ringiFile,this.state[ringiFile]);
        console.log(this.state[ringiFile]);

        var hdringiFile = $("#hiddenRingiFile_"+rn).val();
        var hdringiName = "hiddenRingiFile_"+rn;
        saveData.append(hdringiName,hdringiFile);
        console.log(hdringiFile);
    }
    saveData.append("job_no",this.state.job_no);
    saveData.append("ringiData",ringiData);
    saveData.append("updated_by_email",localStorage.getItem("email"));
    //console.log(this.state.airCargoFFId);
    
    redirectURL.post("/consignments/updateffCargoInvoiceData",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
}
onChangeInvoiceDate= (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    this.setState({
        // invoiceDate:getHyphenDDMMYYYYHHMM(d)
        invoiceDate:moment.parseZone(d).format('YYYY-MM-DD')

        // return moment.parseZone(params.data.est_time_departure).format('YYYY-MM-DD');
    });
    //let value = event.target.value;
    //this.setState({'startDate':value});
}
  render() {

    // var pageType = this.state.pageType;
    // console.log(pageType,"pageType");
    // var editHide = false;
    // if(pageType == 2)
    // {
    //     editHide = true;
    // }
    // console.log(editHide,"editHide")
    // var columnwithDefs = [    
    //     {
    //         field:'_id',
    //         headerName:'Edit',
    //         colId:"_id",
    //         //pinned:"left",
    //         width: 72,
    //         hide: editHide,
    //         cellRenderer:function(params){
    //             let basestring = params.data._id;
    //             let encryptedstring = window.btoa(basestring);
    //             var htmloption = '<a href="/editffseaimportsform/'+encryptedstring+'/1" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
    //             if(pageType == 1)
    //             {
    //                 return htmloption;
    //             }
    //             else
    //             {
    //                 return "N/A";
    //             }
    //         }            
    //     },  
    //     {
    //         headerName: "Job No",
    //         field: "job_no",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Request Type",
    //         field: "request_type",
    //         width:120,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.request_type == 1)
    //             {
    //                 return "FCL";
    //             }
    //             else
    //             {
    //                 return "LCL";
    //             }
    //         }         
    //     },
    //     {
    //         headerName: "Pre alert documents",
    //         field: "invoiceData",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         cellRenderer:function(params){
    //             var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
    //             return htmloption;
    //         }         
    //     },
    //     {
    //         headerName: "Counterpart Details",
    //         field: "counterpart_details",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.counterpart_details !=undefined && params.data.counterpart_details !="undefined")
    //             {
    //                 return params.data.counterpart_details;
    //             }
    //         }           
    //     },
    //     {
    //         headerName: "Cargo reciving date",
    //         field: "cargo_reciving_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.cargo_reciving_date !=undefined && params.data.cargo_reciving_date !='')
    //             {
    //                 return moment.parseZone(params.data.cargo_reciving_date).format("DD-MM-YYYY")
    //             }
    //         }         
    //     },
    //     {
    //         headerName: "Shipping Line Name",
    //         field: "shipping_line_name",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Vessel Name",
    //         field: "vessel_name",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Dispatch Port Name",
    //         field: "port_name",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "ETD Port date",
    //         field: "etd_port_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.etd_port_date !=undefined && params.data.etd_port_date !='')
    //             {
    //                 return moment.parseZone(params.data.etd_port_date).format("DD-MM-YYYY")
    //             }
    //         }            
    //     },
    //     {
    //         headerName: "ATD Port date",
    //         field: "atd_port_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.atd_port_date !=undefined && params.data.atd_port_date !='')
    //             {
    //                 return moment.parseZone(params.data.atd_port_date).format("DD-MM-YYYY")
    //             }
    //         }            
    //     },
    //     {
    //         headerName: "ETA Indian Port",
    //         field: "eta_igi_airport_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !='')
    //             {
    //                 return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
    //             }
    //         }            
    //     },
    //     {
    //         headerName: "ATA Indian Port",
    //         field: "ata_igi_airport_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !='')
    //             {
    //                 return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY")
    //             }
    //         }            
    //     },
    //     {
    //         headerName: "IF ETD and ATD not same",
    //         field: "if_etd_atd_not_same",
    //         width:150,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "If ATD and ATA not same",
    //         field: "if_atd_ata_not_same",
    //         width:150,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "MBL No",
    //         field: "mbl_no",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "HBL No",
    //         field: "hbl_no",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "HBL date",
    //         field: "hbl_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.hbl_date !=undefined && params.data.hbl_date !='')
    //             {
    //                 return moment.parseZone(params.data.hbl_date).format("DD-MM-YYYY")
    //             }
    //         }            
    //     },
    //     {
    //         headerName: "No. of Containers",
    //         field: "ff_no_of_containers",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.ff_no_of_containers !=undefined && params.data.ff_no_of_containers !="undefined")
    //             {
    //                 return params.data.ff_no_of_containers;
    //             }
    //         }         
    //     },
    //     {
    //         headerName: "Remarks (Current Status of Shipment)",
    //         field: "current_status_of_shipment",
    //         width:130,
    //         filter: true,
    //         resizable: true
    //     },
    //     {
    //         headerName: "Final Port Of Destination",
    //         field: "final_port_of_destination",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    //     {
    //         headerName: "Do Handover Date",
    //         field: "do_handover_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.do_handover_date !=undefined && params.data.do_handover_date !='')
    //             {
    //                 return moment.parseZone(params.data.do_handover_date).format("DD-MM-YYYY")
    //             }
    //         }          
    //     },
    //     {
    //         headerName: "Rail Out Date",
    //         field: "rail_out_date",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.rail_out_date !=undefined && params.data.rail_out_date !='')
    //             {
    //                 return moment.parseZone(params.data.rail_out_date).format("DD-MM-YYYY")
    //             }
    //         }          
    //     },
    //     {
    //         headerName: "Arrival at FPOD",
    //         field: "arrival_at_fpod",
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data.arrival_at_fpod !=undefined && params.data.arrival_at_fpod !='')
    //             {
    //                 return moment.parseZone(params.data.arrival_at_fpod).format("DD-MM-YYYY")
    //             }
    //         }          
    //     },
    //     {
    //         headerName: "Any other docs",
    //         field: "other_docs",
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     },
    // ]
    var columnsInBalPending = [
        {
            headerName: "Update Transporter",
            field: "",
            width:100,
            filter: true,
            resizable: true,
            cellRendererSelector:function(params){
                if (params.data.transporter_assigned) {
                    // return <button className='btn btn-primary'
                    //     style={{ fontSize:"12px", width:"125px", cursor:"pointer" }}
                    //     disabled>Update</button>
                    return {
                        component: "DisabledUpdateTransporterDetails",
                    };
                } else {
                    return {
                        component:"UpdateTransporterDetails"
                    }
                }
            }       
        },
        {
            headerName: "Work Order No",
            field: "booking_no",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "CHA Entity",
            field: "cha_entity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Destination",
            field: "destination_country",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Item Quantity",
            field: "item_quantity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Size",
            field: "container_size",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Quantity",
            field: "container_quantity",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Plant Code",
            field: "plant_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Gate Open Date",
            field: "gate_open_date",
            width:130,
            filter: true,
            resizable: true,  
            valueGetter:function(params){
                if(params.data.gate_open_date != ""){
                    return getHyphenDDMMMYYYY(params.data.gate_open_date);
                  }
                  else{
                      return "";
                  }   
            },
        },
        {
            headerName: "SL Cut Off Date",
            field: "sl_cutoff_date",
            width:130,
            filter: true,
            resizable: true,  
            valueGetter:function(params){
                if(params.data.sl_cutoff_date != ""){
                    return getHyphenDDMMMYYYY(params.data.sl_cutoff_date);
                  }
                  else{
                      return "";
                  }   
            },
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width:130,
            filter: true,
            resizable: true,  
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width:130,
            filter: true,
            resizable: true,  
        },
        {
            headerName: "Vessel ETD",
            field: "vessel_etd",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter:function(params){
                if(params.data.vessel_etd != ""){
                    return getHyphenDDMMMYYYY(params.data.vessel_etd);
                  }
                  else{
                      return "";
                  }   
            }, 
        },
        {
            headerName: "Destination Port",
            field: "destination_port",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Buyer Code",
            field: "buyer_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Container Type",
            field: "container_type",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Stuffing Planned Date",
            field: "stuffing_planned_date",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter:function(params){
                if(params.data.stuffing_planned_date != ""){
                    return getHyphenDDMMMYYYY(params.data.stuffing_planned_date);
                  }
                  else{
                      return "";
                  }   
            },         
        },
        {
            headerName: "Loading Port",
            field: "loading_port",
            width:130,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Created Date",
            field: "created_at",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params) {
                try {
                    if(params.data.created_at !== "" && params.data.created_at !== undefined) {
                        return moment.parseZone(params.data.created_at).format("DD-MM-YYYY")
                    }
                    else {
                        return "";
                    }
                } catch (error) {
                    // Handle error if needed
                }
            }          
        }
    ];

    var inProcessColumnDefs = [
        {
            headerName: "Legs",
            field: "_id",
            width: 70,
            filter:false,
            floatingFilter:false,
            cellRendererFramework: (params)=>
            {{return <a style={{ fontSize:"20px", fontWeight: "bold", cursor:"pointer" }}
                onClick={this.onShowTicketHistory.bind(this, params)}><i className='icofont icofont-rounded-expand'></i></a>}
            }
        },
        {
            headerName: "Update Invoice",
            field: "",
            width:100,
            filter: true,
            resizable: true,
            cellRendererSelector:function(params){
                return {
                    component:"UpdateInvoice"
                }
            }       
        },
        {
            headerName: "Transporter",
            field: "transporter_assigned",
            width:130,
            filter: true,
            resizable: true,          
        },
    ]

    var closureColumnDefs = [
        {
            headerName: "Invoice No",
            field: "invoice_number",
            width:130,
            filter: true,
            resizable: true, 
        },
        {
            headerName: "Invoice Date",
            field: "invoice_date",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter:function(params){
                if(params.data.invoice_date != ""){
                    return getHyphenDDMMMYYYY(params.data.invoice_date);
                  }
                  else{
                      return "";
                  }   
            },
        },
    ]
    if(this.state.balpendingbtn === "activet")
    {
        var gridColumnDefs=columnsInBalPending;
    }else if(this.state.balInProcessbtn === "activet")
    {
       var gridColumnDefs=[...inProcessColumnDefs, ...columnsInBalPending.slice(1), ...inProcessColumnDefs.slice(2)];
    }else if(this.state.balInClosurebtn === "activet"){
        closureColumnDefs.unshift(inProcessColumnDefs[0])
        var gridColumnDefs=[...closureColumnDefs, ...inProcessColumnDefs.slice(2), ...columnsInBalPending.slice(1)];
    }
    return (
      <div class="container-fluid">
          
        <h5>
            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>BAL Updates </span>
            {/* {(localStorage.getItem("supplier_code") !=undefined && localStorage.getItem("supplier_code") !="undefined" && localStorage.getItem("supplier_code") !='') ?
            <a className="btn btn-info" style={{float:"right"}} href="/createffaircargos">Create New Booking Request</a>
            : ""} */}
        </h5>
          {/* <button className="btn btn-success" onClick={this.addNewRow.bind(this)}>Add New Holiday</button> */}
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <Modal open={this.state.openModal} onClose={this.closeModal}>
          <div aria-disabled={true}>
            <h6
              className="bg-primary text-white"
              style={{
                paddingTop: "10px",
                height: "38px",
                paddingLeft: "253px",
              }}
            >
              File Preview
            </h6>
            {this.state.documentType === "pdf" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "csv" ||
              this.state.documentType === "xlsx" ? (
              <div className="p-3">
                <iframe
                  src={`https://docs.google.com/viewer?url=${this.state.documentUrl}&embedded=true`}
                  frameborder="1"
                  height="500px"
                  width="100%"
                ></iframe>
              </div>
            ) : (
              <></>
            )}

            {this.state.documentType === "png" ||
              this.state.documentType === "jpeg" ||
              this.state.documentType === "jpg" ? (
              <div className="p-3">
                <img
                  src={this.state.documentUrl}
                  // frameborder="1"
                  height="500px"
                  width="100%"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>
        <div className="f16" style={{marginBottom:"10px"}}>
            <ul className="d-tabs">
                <li onClick={this.onClickTab.bind(this,1)} className={"lttabs "+(this.state.balpendingbtn)}>
                    <button type="button" className="btn">Transporter Allocation</button>
                </li>

                <li onClick={this.onClickTab.bind(this,2)} className={"lttabs "+(this.state.balInProcessbtn)}>
                    <button type="button" className="btn">Invoice</button>
                </li>
                <li onClick={this.onClickTab.bind(this,3)} className={"lttabs "+(this.state.balInClosurebtn)}>
                    <button type="button" className="btn">In Closure</button>
                </li>
            </ul>
        </div>
        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
            <AgGridReact
                modules={this.state.modules}
                columnDefs={gridColumnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                masterDetail={true}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridSetate}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                    context: { componentParent: this }
                }}
                detailCellRendererParams={this.state.detailCellRendererParams}
                // components={this.state.components}
                enableRangeSelection= {true}
                onCellClicked={this.viewDetails.bind(this)}
                suppressRowClickSelection={true}
                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
            />
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
        <div className={"sliderBlock2 "+(this.state.showUpdateForm)}>
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6>BAL Dispatch Transporter Assign</h6>
                <span className="float-right ffclosebtn" style={{ marginRight: "25px"}} onClick={this.hideSlideBlock.bind(this)} >X</span>
                <div className="row">
                    <div className="col-sm-12">
                    <form onSubmit={this.formUpdateItem.bind(this)}>
                        <div className="slide-body">
                            <div className="row p-20p">
                                <div className="form-group col-sm-12 mb-20p" style={{color: "grey"}}>
                                    <label style={{color:"#000"}}>Update Transporter Details<span className="rclr fbold">*</span></label>                                        
                                <Select
                                    closeMenuOnSelect={true}
                                    name="TransporterAssigned"
                                    className="border-radius-0"
                                    onChange={this.newFilter.bind(this, "transporter")}
                                    options={this.state.transporterOptions}
                                    value={this.state.transporter}
                                    required
                                    />
                                </div>
                                <div className="form-group col-sm-12 mb-20p text-center">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <div className={"sliderBlock2 "+(this.state.showInvoiceForm)}>
            <div className="document-slider bg-primary text-white pt-2 px-3">
                <h6>BAL Dispatch Invoice Form</h6>
                <span className="float-right ffclosebtn" style={{ marginRight: "25px" }} onClick={this.hideSlideBlock.bind(this)} >X</span>
                <div className="row">
                    <div className="col-sm-12">
                    <form onSubmit={this.invoiceFormUpdateItem.bind(this)}>
                        <div className="slide-body">
                            <div className="row p-20p">
                                <div className="form-group col-sm-12 mb-20p">
                                    <label style={{color:"#000"}}>Invoice Number<span className="rclr fbold">*</span></label>                                        
                                    <input type="text" onChange={this.invoiceNumberHandler} className="form-control" name="Invoice Number"/>
                                </div>
                                <div className="form-group col-sm-12 mb-20p" style={{color: "gray"}}>
                                    <label>Invoice Date<span className="rclr fbold">*</span></label>                                        
                                    <Datetime 
                                        inputProps={{ placeholder: 'Invoice Date', name: 'startDate', autoComplete:'off', required:true }} 
                                        dateFormat="YYYY-MM-DD" 
                                        timeFormat={false}  
                                        closeOnSelect={true}
                                        name="poDate" 
                                        onChange={this.onChangeInvoiceDate.bind(this)}
                                    />
                                </div>
                                <div className="form-group col-sm-12 mb-20p text-center">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>    
        </div>
      </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
export default  withRouter(FFSeaImportsList);