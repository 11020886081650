import React, {Component} from 'react';
import './App.css';
import './assets/css/fontawesome.css';
import './assets/css/icofont.css';
import './assets/css/encss.css';
import { Outlet, Link } from "react-router-dom";
import NavBar from './components/header/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import PoweredByEnmovil from "./assets/icons/poweredByEnmovil.svg";
import Error from './components/errorComponent';
import MyContext from './components/sidebar/context';
// import SidebarRevamp from './components/sidebar/SidebarRevamp';

class App extends React.Component {
  state ={
    error: null, 
    errorInfo: null,
    currentActivity:''
  }
  componentDidCatch(error, errorInfo){
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }
  setCurrentActivity = ()=>{
    this.setState(this.state.currentActivity)
  }
  render() {
    const {currentActivity}= this.state;
    if (this.state.errorInfo) {
      var err = this.state.error.toString();
      if(err.includes("cross-origin error") === true)
      {
        window.location.reload()
      }
      return (
         (err.includes("cross-origin error") === true)?"Loading ..."
         :
         <div id="wrapper">
          <div  className="col-md-4"></div>
          <div id="content-wrapper" className="col-md-4" style={{fontSize:"15px",lineHeight:"35px",backgroundColor:"#fdf4f4",color:"#000",padding:"30px"}}>
              <h2>Something went wrong.</h2>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
              
            </div>
            
          <div  className="col-md-4"></div>
        </div>
        );
     }
    return (
      <MyContext.Provider value={{currentActivity, setCurrentActivity:this.setCurrentActivity}}>

		<div id="wrapper">
        <div className="sicon"></div>
        {/* <Sidebar /> */}
        <Sidebar />
		  <div id="content-wrapper" className="d-flex flex-column mh90vh">
			
            <div id="content">
				        <NavBar />
                <Error >{this.props.children}</Error>
                <Outlet props={this.props.children} />
                <div class="row justify-content-end poweredByLogo">
              <div class="">
                <img src={PoweredByEnmovil} alt="" />
              </div>
            </div>
            </div>

        </div>
			
      </div>
      </MyContext.Provider>
    );
  }
}

export default App;
