
import React, { Component } from 'react';
 
const ViewDocument = (props) => {
    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onClickViewPOD(props);
    // };
// console.log("props ", props.data)
    return (
        <div>
            <a href={"/view-document-approval/"+props.data.document_no} 
              className={"btn btn-info nopadding font12"} 
              title="View Document" >
                  <i className="icofont icofont-eye f16"></i>&nbsp; View
            </a>
          
        </div>
    );
};

export default ViewDocument;
