import React from 'react';
import { countries } from "country-flags-svg";

const DestinationFlagComponent = (props) => {
    const getFlagIcon = (countryName) => {
        let country_name = "";
        const srcFlag = countries.filter((eachCountry) => {
            if (eachCountry.name === countryName) {
                country_name = eachCountry.name;
                return eachCountry;
            }
        });
        if (srcFlag && srcFlag[0]?.flag) {
            return {
                img: srcFlag[0]?.flag,
                name: country_name,
            };
        } else {
            return null;
        }
    };

    var countryIcon = getFlagIcon(props.data.destination_country);

    return (
        <div>
            {countryIcon && countryIcon.img ? (
                <img style={{ height: '15px', width: '30px' }} src={countryIcon.img} alt={countryIcon.name} />
            ) : (
                ''
            )}
            <span style={{ marginLeft: '5px' }}>{props.data.destination_country}</span>
        </div>
    );
};

export default DestinationFlagComponent;
