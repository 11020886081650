import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';


import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import redirectURL from "../redirectURL";

import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import moment from "moment";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY } from '../common/utils';


const DOStatus = () => {

    var [rowData, setrowData] = useState([]);
    var [overly, setoverly] = useState('show-n')
    const [tableHeaders, setTableHeaders] = useState(['Date']); // Initialize with 'Date'
    var [loadshow, setloadshow] = useState('show-n');
    // const [selectedDate, setSelectedDate] = useState(new Date());
   

    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [from_date,setfrom_date] = useState( moment().format('DD-MM-YYYY'))
    var [end_date,setend_date] = useState( moment().add(2, 'days').format('DD-MM-YYYY'))
    useEffect(() => {
        getData(from_date, end_date);
    }, [])
   
    const getData = (from_date, to_date) => {
        setloadshow('show-m')
        var from_date;
        var to_date;
        if (from_date!= '' && from_date != undefined && to_date!='' && to_date!=undefined) {
            from_date = from_date;
            to_date = to_date;
        } else {
            from_date = from_date;
            to_date = to_date;
        }
        var params = {
            from_date: formatDate(from_date),
            to_date: formatDate(to_date)
        }
        redirectURL.post('/consignments/getdostatus', params).then((resp) => {
            try {
                var data = resp.data
                if (data != '' && data != undefined) {
                    data = resp.data.cha_do_status
                    //console.log(data, "dostatusrowdata")
                    if (data != '' && data != undefined) {
                        if (data.length > 0) {
                            data = JSON.parse(data)
                            setrowData(data);

                        }else{
                            setrowData([]);
                        }
                    }else{
                        setrowData([])
                    }
                }
            } catch (e) {
                setrowData([])
                console.error('Error:', e);
            }finally{
                setTimeout(() => {
                    setloadshow('show-n')
                }, 3000);
            }
        }).catch(error => {
            console.error('Fetch error:', error);
            setrowData([]); // Ensure rowData is empty in case of fetch error
            setTimeout(() => {
                setloadshow('show-n'); // Ensure loader is stopped on fetch error
            }, 3000);
        });
        var uniqueHeaders = ['CHA Name', 'Required', 'Received']
        setTableHeaders(uniqueHeaders)

    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }

   
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
   
    const filterDatafordates = () => {
        // setloadshow('show-m')
        var from_date1 = from_date
        var to_date1 = end_date
        var from_date2 = from_date
        var to_date2 = end_date
        
        
        if (from_date1=="Invalid date"){
          from_date1 = '';
      }
      if (to_date1 =='Invalid date'){
          to_date1 = '';
      }
      
      if (from_date1 !=''){
          const dateObj = moment(from_date1, 'DD-MM-YYYY');
          const formattedFromDate = dateObj.toDate();
          from_date1= formattedFromDate;
      }
      if (to_date1 !=''){
          const dateObj = moment(to_date1, 'DD-MM-YYYY');
          const formattedFromDate = dateObj.toDate();
          to_date1= formattedFromDate;
      }
        if (from_date1 != '' && from_date1 != undefined && from_date1 != null && to_date1 != '' && to_date1 != undefined && to_date1 != null) {
            if (from_date1 <= to_date1) {
               getData(from_date2, to_date2)
                // setselectedData(filteredData)
                // setloadshow('show-n')
  
            }
              else{
                  setbasicTitle('From Date sholud be less than To Date')
                  setbasicType('danger')
                  setoverly('show-n')
                  setshow(true)
              }
          }
          else {
              setbasicTitle('Please Select Start Date and End date')
              setbasicType('danger')
              setoverly('show-n')
              setshow(true)
          }
      }
    const handleDatesSelected = (e) => {
        const date = moment(e).format('DD-MM-YYYY');
        setfrom_date(date)
    };
    const handleDatesSelected1 = (e) => {
        const date = moment(e).format('DD-MM-YYYY');
        setend_date(date)
    };
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className='card-header2'>
                <div className='d-flex justify-content-between'>
                    <h1 className='heading3 mt-20p ml-5p' style={{ width: "240px" }}>DO Status</h1>
                    <div className='d-flex'>
                      
                        <div className="row">

                            <div className="form-group col-xl-4 col-lg-4">
                                <div>
                                    <label className='dateStyling'> From Date </label>
                                    <Datetime
                                        className='smaller-datetime'
                                        key={from_date}
                                        // onKeyDown ={(e)=> restrictToOther(e)}
                                        placeholder="Date"
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        name="from_date"
                                        inputProps={{ readOnly: 'true' }}
                                        value={from_date}
                                        dateFormat="DD-MM-YYYY"
                                        id='from_date'
                                        timeFormat={false}

                                        onChange={(e) => handleDatesSelected(e)}
                                        required />
                                </div>
                            </div>
                            <div className="form-group col-xl-4 col-lg-4">
                                <div>
                                    <label className='dateStyling'> To Date </label>
                                    <Datetime
                                        className='smaller-datetime'
                                        key={end_date}
                                        placeholder="Date"
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        name="to_date"
                                        inputProps={{ readOnly: 'true' }}
                                        value={end_date}
                                        dateFormat="DD-MM-YYYY"
                                        id='to_date'
                                        timeFormat={false}
                                        onChange={(e) => handleDatesSelected1(e)}
                                        required />
                                </div>
                            </div>
                            <div className="form-group col-xl-4 col-lg-4">
                                <button className="custom-btn1 f10 label label-success mt-30p" onClick={filterDatafordates}>Submit</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='scrollable-table-container'>
                    <table className="table table-small-rows" style={{ border: '0px', overflow: 'auto', width: "97%", height:"100%" }}>
                        <thead>
                            <tr>
                                {tableHeaders.map((header, index) => (
                                    <th key={index} className='table-outlookvsorders'>{header}</th>  // Added keys for better performance
                                ))}
                            </tr>
                        </thead>
                        <tbody>
    {rowData && rowData.length > 0 ? (
        rowData.map((item, index) => {
            //console.log(item,"dostatusitem")
            if (item.cha) {
                let cha = item.cha.split(" ");
                let chaLength = cha.length;
                let chaName = chaLength > 2 ? `${cha[0]} ${cha[1]}` : cha[0];
                return (
                    <tr key={index}>
                        <td style={{width: "300px"}}>{chaName}</td>
                        <td>{item.required_do}</td>
                        <td>{item.actual_do}</td>
                    </tr>
                );
            } 
        })
    ) : (
        <tr style={{height: "170px"}}>
            <td colSpan={tableHeaders.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}>No rows to show</td>
        </tr>
    )}
</tbody>

                    </table>
                </div>


            </div>
            <div className={"dataLoadpage1 " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </>

    )
}

function formatDate(dateString) {
    // Split the date string into components
    const parts = dateString.split('-');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Reformat the date string
    const newDateString = `${year}-${month}-${day}`;
    return newDateString;
}

export default DOStatus;