import React, { useState, forwardRef, useImperativeHandle } from 'react';

const CustomCellEditorforChar = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    }
  }));

  const handleChange = e => {
    let inputValue = e.target.value;
    // Remove any non-alphanumeric characters
    inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    // Limit the input to a maximum of 25 characters
    if (inputValue.length <= 25) {
      setValue(inputValue);
    }
  };
  
  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      maxLength={25}
    />
  );
});

export default CustomCellEditorforChar;
