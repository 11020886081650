import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../utils";
import { createStaticHandler } from '@remix-run/router';
import BillingTripsData from "./BillingTripsDataGrid";
import CountUp from 'react-countup';
import ViewItems from '../booking/viewitems';

export default class BillingTrips extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            rowData:[],
            liable:"btn-active-y",
            pendinglsp:"btn-default",
            pendingcust:"btn-default",
            submitfin:"btn-default",
            paystat:"btn-default",
            liableshow:"show-m",
            pendinglspshow:"show-n",
            pendingcustshow:"show-n",
            submitfinshow:"show-n",
            paystatshow:"show-n",
            actiontype:"liable",
            overlay:"show-n",            
            liabilityCnt:[],
            pendinglspCnt:[],
            pendingcustomerCnt:[],
            counterpendcust:0,
            counterpendfin:0,
            counterpendclose:0,
            submitFinanceCnt:[],
            paymentCloseCnt:[],
            oRowData:[],
            dieselFreightRate:0
        }
        this.loadTrips = this.loadTrips.bind(this);
        this.onApproveLSPData = this.onApproveLSPData.bind(this);
        this.latestDieselPrice = this.latestDieselPrice.bind(this);
        this.onRejectCustomer = this.onRejectCustomer.bind(this);
    }
	componentDidMount(){
        redirectURL.post("/configurations")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {
                if(records[0].trips_data_from == "api")
                {                    
                    this.setState({
                        isuploadcsv:0
                    })
                }
                else{
                    this.setState({
                        isuploadcsv:1
                    })
                }
                this.latestDieselPrice();
            }
            else{
                redirectToConfigurations()
            }
		})
	}
    latestDieselPrice(){
        redirectURL.post("/latestapproveddieselprice")
        .then(async (response) => {
            var dieselFreightRate = 0;
            if(response.data.status === "success")
            {
                var records = response.data.records;
                
                dieselFreightRate = (records[0].changed_diesel_price !== "" && records[0].changed_diesel_price !== undefined)?records[0].changed_diesel_price:records[0].applicable_diesel_price;
                await this.setState({
                    dieselFreightRate:dieselFreightRate
                })
                
                await this.loadTrips();
            }
           
        })
    }
    loadTrips(){
        redirectURL.post("/trips")
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            var liabilityCnt=[];
            var pendinglspCnt=[];
            var pendingcustomerCnt=[];
            var counterpendcust = [];
            var counterpendfin=[];
            var counterpendclose=[];
            var submitFinanceCnt=[];
            var paymentCloseCnt=[];
            var recordsarr = [];
            var allbatches = [];
            var batchitems= [];
            if(records.length > 0)
            {
                records.map((item) => {
                    // console.log("item Start ",item)
                    var allbatches = [];
                    if(item.batches !== "" && item.batches !== undefined)
                    {
                        if(item.batches.length > 0)
                        {
                            item.batches.map((it) => {
                                batchitems.push(it);
                            })
                        }
                    }
                    item.allbatches = allbatches;
                    if(item.trip_status === 0)
                    {
                        liabilityCnt.push(item);
                    }
                    if(item.trip_status === 1)
                    {
                        pendinglspCnt.push(item);
                    }
                    // if(item.trip_status === 2)
                    // {
                    //     pendingcustomerCnt.push(item);
                    // }
                    // if(item.trip_status === 3)
                    // {
                    //     submitFinanceCnt.push(item);
                    // }
                    // if(item.trip_status === 4)
                    // {
                    //     paymentCloseCnt.push(item);
                    // }
                    item.freight_rate = this.state.dieselFreightRate;
                    var fr = 0;
                    var tds = 0;
                    if(this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined)
                    {
                        fr = parseFloat(this.state.dieselFreightRate);
                        tds = parseFloat(item.total_travelled_trip_dist_km);
                        var totlcost = fr*tds;
                        item.total_cost=totlcost;
                    }
                    else{
                        item.total_cost=0;
                    }
                    // console.log("item ", item)
                    recordsarr.push(item);
                })
            }
            if(batchitems.length > 0)
            {                
                batchitems.map((it) => {
                    if(it.batch_status === 1 || it.batch_status === 5)
                    {
                        pendingcustomerCnt.push(it);
                        counterpendcust.push(it)
                    }
                    if(it.batch_status === 2)
                    {
                        submitFinanceCnt.push(it);
                        counterpendfin.push(it)
                    }
                    if(it.batch_status === 3)
                    {
                        paymentCloseCnt.push(it);
                        counterpendclose.push(it)
                    }
                })
                       
            }
            var pendingwithcustomer=[];
            var pendingwithfin=[];
            var paycls=[];
            var batchlists = groupBy(pendingcustomerCnt, rdata => (rdata.batch_no));
            var finbatchlists = groupBy(submitFinanceCnt, rdata => (rdata.batch_no));
            var paybatchlists = groupBy(counterpendclose, rdata => (rdata.batch_no));
            try {
                batchlists.forEach((values,key) =>{
                   var totalcost= 0;
                    var rw = {};
                    rw["batch_no"] = key;
                    if(values.length > 0)
                    {
                        var itemlist = [];
                        values.map((itm) => {
                            itm.freight_rate = this.state.dieselFreightRate;
                            var fr = 0;
                            var tds = 0;
                            if(this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined)
                            {
                                fr = parseFloat(this.state.dieselFreightRate);
                                tds = parseFloat(itm.total_travelled_trip_dist_km);
                                console.log("itm.total_travelled_trip_dist_km ", itm.total_travelled_trip_dist_km)
                                var totlcost = parseFloat(fr)*parseFloat(tds);
                                itm.total_cost=parseFloat(totlcost);
                                totalcost = parseFloat(totalcost) +parseFloat(totlcost); 
                            }
                            else{
                                itm.total_cost=0;
                            }
                            itemlist.push(itm);
                            
                        })
                    }
                    rw["total_batch_cost"] = totalcost;
                    rw["itemlist"] = itemlist;
                    pendingwithcustomer.push(rw);
                    // console.log("Rws ", rw)
                   
                })

                finbatchlists.forEach((values,key) =>{
                    var totalcost= 0;
                     var rw = {};
                     rw["batch_no"] = key;
                     if(values.length > 0)
                     {
                         var itemlist = [];
                         values.map((itm) => {
                             itm.freight_rate = this.state.dieselFreightRate;
                             var fr = 0;
                             var tds = 0;
                             if(this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined)
                             {
                                 fr = parseFloat(this.state.dieselFreightRate);
                                 tds = parseFloat(itm.total_travelled_trip_dist_km);
                                 console.log("itm.total_travelled_trip_dist_km ", itm.total_travelled_trip_dist_km)
                                 var totlcost = parseFloat(fr)*parseFloat(tds);
                                 itm.total_cost=parseFloat(totlcost);
                                 totalcost = parseFloat(totalcost) +parseFloat(totlcost); 
                             }
                             else{
                                 itm.total_cost=0;
                             }
                             itemlist.push(itm);
                             
                         })
                     }
                     rw["total_batch_cost"] = totalcost;
                     rw["itemlist"] = itemlist;
                     pendingwithfin.push(rw);
                     // console.log("Rws ", rw)
                    
                 })
                 paybatchlists.forEach((values,key) =>{
                     var totalcost= 0;
                      var rw = {};
                      rw["batch_no"] = key;
                      if(values.length > 0)
                      {
                          var itemlist = [];
                          values.map((itm) => {
                              itm.freight_rate = this.state.dieselFreightRate;
                              var fr = 0;
                              var tds = 0;
                              if(this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined)
                              {
                                  fr = parseFloat(this.state.dieselFreightRate);
                                  tds = parseFloat(itm.total_travelled_trip_dist_km);
                                  console.log("itm.total_travelled_trip_dist_km ", itm.total_travelled_trip_dist_km)
                                  var totlcost = parseFloat(fr)*parseFloat(tds);
                                  itm.total_cost=parseFloat(totlcost);
                                  totalcost = parseFloat(totalcost) +parseFloat(totlcost); 
                              }
                              else{
                                  itm.total_cost=0;
                              }
                              itemlist.push(itm);
                              
                          })
                      }
                      rw["total_batch_cost"] = totalcost;
                      rw["itemlist"] = itemlist;
                      paycls.push(rw);
                      // console.log("Rws ", rw)
                     
                  })
            } catch (error) {
                
            }

        //    
            this.setState({
                rowData:recordsarr,
                oRowData:recordsarr,
                liabilityCnt:liabilityCnt,
                pendinglspCnt:pendinglspCnt,
                pendingcustomerCnt:pendingwithcustomer,
                submitFinanceCnt:pendingwithfin,
                paymentCloseCnt:paycls,
                counterpendcust:counterpendcust.length,
                counterpendfin:counterpendfin.length,
                counterpendclose:counterpendclose.length,
            })
            this.showTabView(1);
		})
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    showTabView(oVal){
        this.setState({

            overlay:"show-m"
        })
        setTimeout(()=>{
            if(oVal === 1)
            {
                this.setState({
                    liable:"btn-active-y",
                    pendinglsp:"btn-default",
                    pendingcust:"btn-default",
                    submitfin:"btn-default",
                    paystat:"btn-default",
                    liableshow:"show-m",
                    pendinglspshow:"show-n",
                    pendingcustshow:"show-n",
                    submitfinshow:"show-n",
                    paystatshow:"show-n",
                    actiontype:"liable",
                    overlay:"show-n",
                    showSendBackBtn:0,
                    showSendApproveBtn:0,
                    showSendRejectBtn:0,
                    rowData:this.state.liabilityCnt
                });
            }
            
            if(oVal === 2)
            {
                this.setState({
                    liable:"btn-default",
                    pendinglsp:"btn-active-y",
                    pendingcust:"btn-default",
                    submitfin:"btn-default",
                    paystat:"btn-default",
                    liableshow:"show-m",
                    pendinglspshow:"show-n",
                    pendingcustshow:"show-n",
                    submitfinshow:"show-n",
                    paystatshow:"show-n",
                    actiontype:"pendinglsp",
                    overlay:"show-n",
                    showSendBackBtn:0,
                    showSendApproveBtn:1,
                    showSendRejectBtn:0,
                    rowData:this.state.pendinglspCnt,
                });
            }
            
            if(oVal === 3)
            {
                this.setState({
                    liable:"btn-default",
                    pendinglsp:"btn-default",
                    pendingcust:"btn-active-y",
                    submitfin:"btn-default",
                    paystat:"btn-default",
                    liableshow:"show-m",
                    pendinglspshow:"show-n",
                    pendingcustshow:"show-n",
                    submitfinshow:"show-n",
                    paystatshow:"show-n",
                    actiontype:"pendingcust",
                    overlay:"show-n",
                    showSendBackBtn:1,
                    showSendApproveBtn:1,
                    showSendRejectBtn:1,
                    rowData:this.state.pendingcustomerCnt,
                });
            }
            
            if(oVal === 4)
            {
                this.setState({
                    liable:"btn-default",
                    pendinglsp:"btn-default",
                    pendingcust:"btn-default",
                    submitfin:"btn-active-y",
                    paystat:"btn-default",
                    liableshow:"show-m",
                    pendinglspshow:"show-n",
                    pendingcustshow:"show-n",
                    submitfinshow:"show-n",
                    paystatshow:"show-n",
                    actiontype:"submitfin",
                    overlay:"show-n",
                    showSendBackBtn:0,
                    showSendApproveBtn:1,
                    showSendRejectBtn:1,
                    rowData:this.state.submitFinanceCnt,
                });
            }
            
            if(oVal === 5)
            {
                this.setState({
                    liable:"btn-default",
                    pendinglsp:"btn-default",
                    pendingcust:"btn-default",
                    submitfin:"btn-default",
                    paystat:"btn-active-y",
                    liableshow:"show-m",
                    pendinglspshow:"show-n",
                    pendingcustshow:"show-n",
                    submitfinshow:"show-n",
                    paystatshow:"show-n",
                    actiontype:"paystatus",
                    overlay:"show-n",
                    showSendBackBtn:0,
                    showSendApproveBtn:0,
                    showSendRejectBtn:0,
                    rowData:this.state.paymentCloseCnt,
                });
            }
        },500)
    }
    
    hideSlideBlock(){
        this.setState({
            slider:"",
            overlay:"show-n"
        })
    }
    onApproveLSPData(rownodes, actiontype){
        // console.log("paramets", rownodes)
        if(rownodes.length > 0)
        {
            var params={
                rownodelist:JSON.stringify(rownodes)
            }
            if(actiontype === "pendinglsp")
            {   
                redirectURL.post("/lspaction",params)
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        this.loadTrips();
                    }
                    else{

                    }
                })
            }
            
            if(actiontype === "pendingcust")
            {   
                redirectURL.post("/customerapprv",params)
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        this.loadTrips();
                    }
                    else{

                    }
                })
            }
            if(actiontype === "submitfin")
            {   
                redirectURL.post("/finapprv",params)
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        this.loadTrips();
                    }
                    else{

                    }
                })
            }
        }
    }
    onRejectCustomer(rownodes,actiontype)
    {
        // console.log('rownodes ', rownodes)
        if(rownodes.length > 0)
        {
            var params={
                rownodelist:JSON.stringify(rownodes)
            }
            
            if(actiontype === "pendingcust")
            {
                redirectURL.post("/customeraction",params)
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        this.loadTrips();
                    }
                    else{

                    }
                })
            }
            if(actiontype === "submitfin")
            {   
                redirectURL.post("/finrej",params)
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        this.loadTrips();
                    }
                    else{

                    }
                })
            }
        }
    }
    render(){
		
        return (
            <div className="container-fluid">
                 <div className="row mb-4">
                    <div className="col-xl-12 col-md-12 ">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cursorPointer">
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Total</span>
                                        <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.oRowData.length}/></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorPointer">
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Liability</span>
                                        <h4 className="txt-danger f40"><span className="counter"><CountUp end={this.state.liabilityCnt.length}/></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorPointer">
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-truck f24 greenfont"></i><br /> Pending With Transporters</span>
                                        <h4 className="txt-primary f40"><span className="counter"><CountUp end={this.state.pendinglspCnt.length}/></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorPointer">
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-users f24 greenfont"></i><br /> Pending With Customer</span>
                                        <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.state.counterpendcust}/></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorPointer">
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-braille f24 greenfont"></i><br /> Submitted to Finance</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.counterpendfin}/></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorPointer">
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-check f24 greenfont"></i><br /> Payment Close</span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={this.state.counterpendclose}/></span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">Trips Data
                        {this.state.isuploadcsv == 1?
                        <a href={"/upload-trips"} className="float-right btn btn-warning">Upload Trips</a>
                        :""}
                    </h1>
				</div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 ">
                        <ul className={"navtabs "}>
                            <li>
                                <button onClick={this.showTabView.bind(this,1)} type="button" className={"btn "+(this.state.liable)}>Liability</button>
                            </li>
                            <li>
                                <button onClick={this.showTabView.bind(this,2)} type="button" className={"btn "+(this.state.pendinglsp)}>Pending With Transporter</button>
                            </li>
                            <li>
                                <button onClick={this.showTabView.bind(this,3)} type="button" className={"btn "+(this.state.pendingcust)}>Pending With Customer</button>
                            </li>
                            <li>
                                <button onClick={this.showTabView.bind(this,4)} type="button" className={"btn "+(this.state.submitfin)}>Submitted To Finance</button>
                            </li>
                            <li>
                                <button onClick={this.showTabView.bind(this,5)} type="button" className={"btn "+(this.state.paystat)}>Payment Close</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={this.state.liableshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={this.state.actiontype}
                        showSendBackBtn={this.state.showSendBackBtn}
                        showSendApproveBtn={this.state.showSendApproveBtn}
                        showSendRejectBtn={this.state.showSendRejectBtn}
                        context={this}
                    />
				</div>
                {/* <div className={this.state.pendinglspshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"transporterpending"}
                    />
				</div>
                <div className={this.state.pendingcustshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"customerpending"}
                    />
				</div>
                <div className={this.state.submitfinshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"finance"}
                    />
				</div>
                <div className={this.state.paystatshow}>
                    <BillingTripsData
                        rowData={this.state.rowData}
                        actiontype={"payment"}
                    />
				</div> */}
                    

                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div>
			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}