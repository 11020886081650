import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

const UpdateAcknowledgeBtnComponent = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        // props.context.componentParent.onClickEditProductionData(props);
        props.context.onClickupdateAcknowledgeBtn(props);
    };
    var colr = props.data.comment_exists;
    
    return (
        <div>
                {((props.data.update_ack==1)|| (props.data.update_ib_ack==1) || (props.data.container_count_increase_check ==1) || (props.data.container_count_decrease_check ==1) || (props.data.ship_to_country_changes_before_container_pickup == 1) || (props.data.pod_changes_before_container_pickup == 1) || (props.data.cha_changes_before_container_pickup ==1))?<button
                    style={{ color: "#fff" }}
                    onClick={handleRouteClick}
                    className="btn btn-primary custom-btn f12 label label-success"
                    title=""
                >
                    Update & Ack
                </button>:<button
                    style={{ color: "#fff" }}
                    onClick={handleRouteClick}
                    className="btn btn-primary custom-btn f12 label label-success"
                    title=""
                >
                    Update & Ack
                </button>}
            </div>
    );
};

export default UpdateAcknowledgeBtnComponent;
