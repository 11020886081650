
import React, { Component } from 'react';
 
const DownloadDocAction = (props) => {
    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onClickUpdateItem(props);
    // };
// console.log("props ", props.data)
    return (
        <div>
            <a href={"/download-document/"+props.data.document_no}
              className={"btn btn-secondary nopadding font12"} 
              title="Download" 
              target="_blank">
                  <i className="icofont icofont-eye f16"></i>&nbsp; Download
            </a>
          
        </div>
    );
};

export default DownloadDocAction;
