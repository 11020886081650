import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const RejectBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickrejectbtn(props);
    };
    return (
        <div className="map-icon-component">
 <button onClick={(e) => handleRouteClick(e)} className="btn btn-success f12 label label-success">
                           Submit
                        </button>
            {/* <button onClick={handleRouteClick} className="custom-btn label label-success" title="Shipment Legs"><i style={{color:"#FF0000"}} className="icofont icofont-site-map f25"></i> </button> */}
        </div>
    );
};
export default RejectBtn;
