import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import '../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../node_modules/react-resizable/css/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Datetime from 'react-datetime';
import Select from "react-select";


// import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import SearchIcon from "../../assets/icons/search-icon.svg";



import Modal from 'react-responsive-modal';

import axios from 'axios';

// import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
 import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Mapcard2 from './drawmap';
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";

 import Trucklocaction from './trucklocation';
var infoBox = require('../common/google-infowindow');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var map;
var viewMarkersArr=[];
export default class TrucksGPSRoute extends Component {
    constructor(props) {
        super(props);
        this.state = 
        {
            pageTitle: "Truck GPS Data",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            routemap:'',
            allmarkers:'',
            trucks:[],
            truck_no:'',
            selected_truck_no : "",
            startDate:'',
            endDate:'',
            formatedsdate:'',
            formatededate:'',
            mapinfo:'',
            mapinfoclick:'',
            modules: AllModules,
            defTransitCoords:'',
            activeMarker: {},
            selectedPlace: {},
            dealerCode:'',
            timelinesmarkers:[],
            viewtimelinemarkers:true,
            viewgoogleroutes:true,
            googleroutepath:[],
            loadshow:'show-n',
            open: false,
            alert:null,
            show: false,
            basicTitle:'',
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true,
              menuTabs: ['filterMenuTab']

            },
            gridshow:'show-m',
            filteredData : [],
            mapshow:'show-m',
            actgridbtn:"btn-success",
            actmapbtn:"btn-default",
            rowData: null,
            mapData:[],
            showTrucksList : "show-n",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
               customLoadingCellRenderer: CustomLoadingCellRenderer,
              trucklocaction:Trucklocaction
          },

            animateRows: true,
            debug: true,
            showToolPanel: false,
          uppressAggFuncInHeader: true,
              
          childconfs: [],
          childrow:[],
          rowModelType: "serverSide",
          paginationPageSize:50,
          cacheBlockSize: 10,
          maxBlocksInCache: 1,
          truckNoList:[],
          overly : "show-n",
          statusBar: {
              statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
              ]
          },
          sideBar: {
              toolPanels: [
                  {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel"
                  },
                  {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel"
                  }
              ]
          },
          detailCellRendererParams:{},
          rowClassRules: {
              "highlitRow": "data.transit_delay == 1",
              "green":"data.status >= 3"

          } ,
          tolls : [],
          frequency:"",
          showmarkers : 0,
          maptruck: "",
          tottalDistance: [],
          totaldistanceval: 0
                
              
        };
        this.setTruckno = this.setTruckno.bind(this);
        this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        //console.log("Box ",localStorage.getItem('token'));
        this.logPageView(); // Log page view to GA
      
        redirectURL.post("consignments/gettrucksdata1").then((response)=>{
            // console.log(response,'response.data')
            var trucks_record = response.data.total_data;
            var trucks = [];
            trucks_record.map((e)=>{
                trucks.push({label: e.truck_no, value:e.truck_no})
            })
            this.setState({truckNoList: trucks})

            
        })
        this.renderMap()
    }

        
        
    
    selectOptionsItems() {
        let items = [];     
        //console.log(this.state.trucks);
        this.state.trucks.forEach(item => {
            if(item != '' && item != null)
            {
                items.push(<option key={item.truck_no} value={item.truck_no}>{item.truck_no}</option>); 
            }
            
        });
        
        
        return items;
    }
   
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
        
        this.setState({
            startDate:getYYYYMMDDHHMMSS(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
        this.setState({
            endDate:getYYYYMMDDHHMMSS(d)
        });
        
        
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var filteredResult = dataset.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
    onClickTruck(event)
    {
         $("#inputTruck").val($(this).text());
    }
    formHandler = async (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedGenerate,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        var sdate = this.state.formatedsdate 
        var edate = this.state.formatededate 
        // console.log(sdate,edate,'283')
        if(sdate != undefined && sdate != "" && edate != undefined && edate != "" && this.state.trucks.value!=''&&this.state.trucks.value!=undefined&&this.state.trucks.value!='undefined'){
			if(sdate > edate)
			{
                this.setState({
                    show: true,
                    basicTitle: "Start Date should be less than End Date",
                    basicType: "danger",
                    loadshow:'show-n',
                    overly:'show-n',
                })
			}
            else {
                sdate = moment.parseZone(sdate).format('DD-MM-YYYY')
                edate = moment.parseZone(edate).format('DD-MM-YYYY')
                var formdata= {
                    truck_no:this.state.trucks.value,
                    from_date:sdate,
                    to_date:edate,
            }       
                if(this.state.frequency != "" && this.state.frequency != undefined)
                {
                    formdata.freq_mins = this.state.frequency;
                }
                // console.log("formdata ", formdata)
                // console.log(this.state.trucks,'288')
                    this.setState({
                        loadshow:'show-m',
                        overly:'show-m'
                    })
                    redirectURL.post('/consignments/getviewcarrierroutedata', formdata).then((resp) => {
                        var data = resp.data 
                        var allCoordinates = [];
                        if(data.status!='failure'){
                            if(data!='' && data!=undefined){
                                var records= data.coords 
                                console.log(records, "55566")
                                var dataarr=[];
                                records.map((e) => {
                                    var lt = e.latitude || e.lat || 0;
                                    var ln = e.longitude || e.lng || 0;
                                    allCoordinates.push({
                                        lat: lt,
                                        lng: ln,
                                    });
                                });
                                records.map((item) => {
                                        dataarr.push({
                                        "truck_no":item.truck_no, 
                                        "lat":item.lat, 
                                        "lng":item.lng, 
                                        "speed":item.speed, 
                                        "stime":item.stime,
                                        "dist_from_prev_point":item.dist_from_prev_point, 
                                        "received_on":item.received_on, 
                                        "time_from_prev_point":item.time_from_prev_point,
                                        "area" : item.area,
                                        "state" : item.state,
                                        "cumm_distance": item.cumm_distance
                                    })
                                   

                                });
                               
                               
                            }
                            if(dataarr.length > 0){
                                this.setState({
                                    mapinfo:allCoordinates,
                                    rowData:dataarr,
                                    loadshow:'show-n',
                                    overly:'show-n',
                                    mapData:dataarr,
                                    // tolls : JSON.parse(jsondata.data).tolls,
                                    maptruck: formdata.truck_no,

                                    // tottalDistance: [pinnedBottomRowData],
                                    // totaldistanceval: arrayMax(tottalDistance)
                                })
                            }
                        }
                        else{
                            this.setState({
                                show: true,
                                basicTitle: "No Data Available",
                                basicType: "danger",
                                loadshow:'show-n',
                                overly:'show-n',
                            })
                        }
                
                        // console.log(allCoordinates,'293')
                })
               
            }
		}
        else{
            this.setState({
                show: true,
                basicTitle: "Please Select All Fields",
                basicType: "danger"
            })
        }
    }

    onClickShowGrid(){
        this.setState({
            gridshow:'show-m',
            mapshow:'show-n',
            mapinfoclick:"",
            actgridbtn:"btn-success",
            actmapbtn:"btn-default"
        });
    }
    
    onClickShowMap(){
        this.setState({
            gridshow:'show-n',
            mapshow:'show-m',
            mapinfoclick:this.state.mapinfo,
            actgridbtn:"btn-default",
            actmapbtn:"btn-success"
        });
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            showTrucksList : "show-n",
        });
        
    }
    hideList(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            showTrucksList : "show-n",
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    setTruckno = (event)=>{
        this.setState({
            selected_truck_no : event.target.text
        })
        // console.log(event.target.text)
    }

    checkHandler = (event) => {
        
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    renderMap = () => {   
        
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }
    initMap = () => {
        //console.log("arr ",arr)
        var currentwindow;
        var dealer='';
        var tolls = this.state.tolls;
        var arr=[];
        // var locations = jsondata.breaks;
        var arr = this.state.rowData;
        // console.log("jsondata ", arr)

        if(arr.length > 0 && typeof arr.length != undefined)
        {
            var lt=arr[0].lat;
            var ln=arr[0].lng;
        }
        else{
            var lt=21.141058047268885;
            var ln=72.65782317936426;
            
        }
        var routeinfo ;
        try{
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels:true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(lt,ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
                    
        }
        catch(e){
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels:true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(28.4519751,77.0310713),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
                    
        }
        try{
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        }catch(e){}
        
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(lt,ln));
            // Create our info window content
        var line=new window.google.maps.Polyline(
        {
            map:map,
            strokeColor: '#157254',
            strokeOpacity: 1.0,
            strokeWeight: 2.5,
            // icons: [{
            //      icon: {
            //              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //              strokeColor:'#ff8c52',
            //              fillColor:'#ff8c52',
            //              fillOpacity:1,
            //              strokeWeight: 2
            //      },
            //      repeat:'100px',
            //      path:[]
            //  }]
            });
        if(arr.length > 0)
        {
            var allpoints = [];
        
            var arr1=[];
            //console.log("Total ",ratio);
            if(this.state.timelinesmarkers.length > 0)
            {
                arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
                    return (index % ratio == 0);
                } );
                
    
                //var arr1 = this.state.timelinesmarkers;
            }
            
            var arr2=[];
            var darr=[];
            if(arr.length > 0)
            {
                darr = arr.filter(function (value, index, arr) {
                    //console.log("Vale ",value)
                    return (value.dist_from_prev_point > 0);
                } );
                if(darr.length < 2000)
                {
                    var ratio = 1;
                }
                else{
                    var ratio = 20;
                }
                //console.log("darr.length ", darr.length)
                arr2 = darr.filter(function (value, index, darr) {
                    return (index % ratio == 0);
                } );
                
    
                //var arr1 = this.state.timelinesmarkers;
            }
            // view markers code
            if(arr2.length > 0)
            {
                
                viewMarkersArr = arr;
                    // //console.log("allpoints ", allpoints)
                    
                    for(var a=0;a<arr2.length;a++)
                    {
                        //console.log(arr2[a],"arr2[a]");
                        var marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
                            icon: {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    strokeColor:'#157254',
                                    fillColor:'#157254',
                                    fillOpacity:1,
                                    strokeWeight: 5,
                                    scale:1,
                                },
                            map: map,
                            content:arr2[a]
                        });
                        marker.setVisible(false);
                        //  //console.log("Arr ",arr1[a])

                        window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
                            return function() {
                                //console.log("Arr ",marker)
                                var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
                            contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
                            
                            var contentString = infoBox("", header, contentarr,'')
                            
                            
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                            }
                        })(marker));
                        window.google.maps.event.addListener(marker, 'mouseout', function() {
                            infowindow.close();
                        });
        
                        
                    }
            }
            var routeinfo = arr.route_details;
            var icon_cnt = 100
            for(var i=0;i< arr.length-1;i++)
            {
                console.log('574')
                var locationLatLng = [];
                locationLatLng.push(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
                locationLatLng.push(new window.google.maps.LatLng(arr[i+1].lat,arr[i+1].lng));
                if(icon_cnt % 10 == 0)
                {
                    var lineicon = [{
                                // icon: {
                                //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                //         strokeColor:'#FF0000',
                                //         fillColor:'#FF0000',
                                //         fillOpacity:1,
                                //         strokeWeight: 2
                                // },
                                repeat:'100px',
                                path:[]
                            }];
                }else{lineicon=[]}
                icon_cnt = icon_cnt + 1
                var consignments_missing_route_line = new window.google.maps.Polyline({
                    map:map,
                    path : locationLatLng,
                    strokeColor: '#157254',
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    icons: lineicon
                });
                // console.log(arr2[i].msgtype)
                if(arr2[i].msgtype == "G")
                {  
                    consignments_missing_route_line.setOptions({
                        strokeColor : "#452a68",
                        strokeWeight: 5.5,
                    })
                }
                bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            }
                
                // for (let i = 0; i < arr2.length; i++) {
                //     //if(arr2[i].speed > 0)
                //     //{
                //         var linecolr = "#157254";
                //         if(arr2[i].set  == 2)
                //         {
                //             linecolr = "#0000ff";
                //         }
                //         if(arr2[i].set  == 3)
                //         {
                //             linecolr = "#980000"
                //         }
                //         line.setOptions({strokeColor: linecolr}); 
                //     var path=line.getPath().getArray();
                            
                //     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
                //     path.push(latLng);
                //     line.setPath(path);
                        
                //     //Change line color based on map type
                //     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
                //         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
                //         {
                //             var line=new window.google.maps.Polyline(
                //             {
                //                 map:map,
                //                 strokeColor: '#FFFFFF',
                //                 strokeOpacity: 1.0,
                //                 strokeWeight: 2.5,
                //                 icons: [{
                //                         icon: {
                //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                //                                 strokeColor:'#ff8c52',
                //                                 fillColor:'#ff8c52',
                //                                 fillOpacity:1,
                //                                 strokeWeight: 2
                //                                 },
                //                         repeat:'100px',
                //                         path:[]
                //                     }]
                //                 });                
                //         }
                //         else
                //         {
                //             var line=new window.google.maps.Polyline(
                //             {
                //                 map:map,
                //                 strokeColor: '#157254',
                //                 strokeOpacity: 1.0,
                //                 strokeWeight: 2.5,
                //                 icons: [{
                //                         icon: {
                //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                //                                 strokeColor:'#ff8c52',
                //                                 fillColor:'#ff8c52',
                //                                 fillOpacity:1,
                //                                 strokeWeight: 2
                //                                 },
                //                         repeat:'100px',
                //                         path:[]
                //                     }]
                //                 });

                //         }

                //         for (i = 0; i < arr2.length; i++) {
                //                 var path=line.getPath().getArray();
                //                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
                //                 path.push(latLng);
                //                 line.setPath(path);
                //                         //map.setCenter(latLng);
                //             }
                                        
                //         var x = map.getZoom();
                //         var c = map.getCenter();
                //         window.google.maps.event.trigger(map, 'resize');
                //         map.setZoom(x);
                //         map.setCenter(c);
                //     } );
                //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
                //     //}
                // }

                // var infowindow = new window.google.maps.InfoWindow();
                    var marker, l;

                    // for (l = 0; l < locations.length; l++) {
                    // marker = new window.google.maps.Marker({
                    // position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                    // icon:require('../../assets/icons/cf.png'),
                    // map: map,
                    // });

                    // window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                    //     return function() {
                    //         //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
                        
                    //     var contentarr = []
                    //     //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    //     var header = "Break";
                    //     try{
                    //         if(typeof this.props.mapFor != 'undefined')
                    //         {
                    //             var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
                    //             contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                    //             contentarr.push({"key":"Break End", "value":locations[l].break_end})
                    //             contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
                    //         }
                    //         else
                    //         {
                    //             contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                    //             contentarr.push({"key":"Break End", "value":locations[l].break_end})
                    //             contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
                    //         }
                    //     }
                    //     catch(e){
                    //         contentarr.push({"key":"Break Start", "value":locations[l].break_start})
                    //         contentarr.push({"key":"Break End", "value":locations[l].break_end})
                    //         contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
                    //     }
                        
                        
                    //     var contentString = infoBox(marker.icon, header, contentarr,'')
                        
                    //     infowindow.setContent(contentString);
                    //     currentwindow = infowindow;
                    //     infowindow.open(map, marker);
                    //     //console.log(marker.position.toJSON());
                    //     console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                    //     }
                    // })(marker, l));
                    // }
                    // console.log(arra, "arr222")
                    marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                        // title:'Truck No.:'+ arra[0].truck_no,
                        icon:require('../../assets/icons/track_start.png'),
                        map: map,   
                    });
                window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            //  console.log(clusters[0])
                            
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        // var header = 'Truck No.:'+ arra[0].truck_no
                        // contentarr.push({"key":"Started at", "value": getDDMMYYYYHHMMSS(arra[0].timestamp)})
                        // contentarr.push({"key":"Address", "value": getDDMMYYYYHHMMSS(arra[0].timestamp)})
                        
                        var contentString = infoBox(marker.icon, contentarr,'')
                        
                        let infowindow = new window.google.maps.InfoWindow({
                            content: contentString
                        })
                        // infowindow.setContent(contentString);
                        // currentwindow = infowindow;
                        infowindow.open(map, marker);
                        // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
                    icon:require('../../assets/icons/truck-end.png'),
                    map: map,   
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            //  console.log(clusters[0])
                            
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                        var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = "End Point"
                        contentarr.push({"key":"End at", "value":routeinfo.end_time})
                        
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                            
                        infowindow.setContent(contentString);   
                        infowindow.setContent(contentString);
                        currentwindow = infowindow;
                        infowindow.open(map, marker);
                        // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
            
            }
            try{
                if(tolls.length > 0)
                {
                    var infowindow = new window.google.maps.InfoWindow();
                    var l;
                    tolls.map(function(e,index){
                        var tollMarker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(e.lat, e.lon),
                            icon:require('../../assets/icons/barrier.png'),
                            map: map,
                        });
                        window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
                            return function() 
                            {
                                var contentarr = []
                                var header = "Toll - "+e.name
                                contentarr.push({"key":"Address", "value":e.location})
                                var contentString = infoBox(tollMarker.icon, header, contentarr)
                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, tollMarker);
                            }
                        })(tollMarker, index));
                        window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
                            infowindow.close();
                            });
                    })  
                    
                }
            }catch(e){}
            
        
            map.fitBounds(bounds)

    }

    

    onClickShowTruckLocation(e){
        // console.log("CLiclable ", e)
        var lat = parseFloat(e.data.lat);
        var lng =parseFloat(e.data.lng);
        var data =e.data;
        map.setCenter(new window.google.maps.LatLng(lat,lng));
        map.setZoom(22);
        var markerLatlng = new window.google.maps.LatLng(lat, lng);
        //var image =  require('../../assets/icons/truckblue.png');
        var image =  require('../../assets/icons/gmarker.png');
        
        var marker = new window.google.maps.Marker({
            position: markerLatlng,
            map: map,
            title:data.truck_no,
            icon:image
        });

        var contentarr = []
        var header = data.truck_no
        //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
        contentarr.push({"key":"City/Area", "value":data.area})
        contentarr.push({"key":"State", "value":data.state})

        var contentString = infoBox(marker.icon, header, contentarr,'')
            
        var infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });
        
        marker.setAnimation(window.google.maps.Animation.DROP)
        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });
        
        marker.setMap(map);
        
    }
  
    showmarkers(event)
    {
        // console.log(event.target.value);
        // console.log("viewMarkersArr ", viewMarkersArr)
        // if(event.target.value == 0)
        // {
        //     // viewMarkersArr.map(function(e){
        //  //  e.setVisible(true);
        //     // });
        //     this.setState({
        //         showmarkers : 1
        //     })
        // }
        // else
        // {
        //  // viewMarkersArr.map(function(e){
        //  //  e.setVisible(false);
        //     // });
        //     this.setState({
        //         showmarkers : 0
        //     })
        // }

        var infowindow = new window.google.maps.InfoWindow();
        var markersArray = viewMarkersArr;
        if(event.target.value == 0)
        {
            if(markersArray.length > 0)
            {
                var stepby = Math.round(markersArray.length * 0.007)
                // var counter = ( stepby > 0)?stepby:1;
                // var incrementby = counter
                var counter = 1;
                var incrementby = 1;
                // console.log(markersArray.length,counter);
                while(counter < markersArray.length)
                {
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            strokeColor:'#157254',
                            fillColor:'#157254',
                            fillOpacity:1,
                                strokeWeight: 5,
                                scale:1,
                            },
                        map: map,
                        content:markersArray[counter]
                    });
                    // marker.setVisible(false);
                    window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
                        return function() {
                            //console.log("Arr ",marker)
                            var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = "Current Status"
                        contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
                        contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
                        
                        var contentString = infoBox("", header, contentarr,'')
                        
                        
                        infowindow.setContent(contentString);
                        infowindow.open(map, marker);
                        //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                    window.google.maps.event.addListener(marker, 'mouseout', function() {
                        infowindow.close();
                    });

                    // viewMarkersArr.push(marker);
                    counter = counter + incrementby;
                    // console.log("counter",counter)
                }
            }
        }
        else
        {
            viewMarkersArr.map(function(e){
                e.setVisible(false);
            });
            this.setState({
                showmarkers : 0
            })
        }
    }
    render(){
        let order_cnt=[];

        const dStyles={
                width:'100%',
                height:'100%'
        }
        const modalStyles  = {
            width:'500px !important',
            }
    const { open } = this.state;

    // const changeHandleDate = (e) => {
    //     var d = moment(e._d);
    //     var formattedDate = d.format('DD-MM-YYYY');
    //     console.log(formattedDate, "date1123")
    //     return formattedDate
    // }
   const changeHandleDate  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var edate = d.getFullYear()+"-"+((d.getMonth()+1)<10?"0"+(d.getMonth()+1):(d.getMonth()+1))+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        var endDate = moment.parseZone(edate).format('DD-MM-YYYY')
        // console.log(edate,'1031')
        this.setState({
    		endDate:endDate,
            formatededate:edate
    	});
	}
    const changeHandleDate1  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var sdate = d.getFullYear()+"-"+((d.getMonth()+1)<10?"0"+(d.getMonth()+1):(d.getMonth()+1))+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        // console.log(sdate,'1030')
        var startDate = moment.parseZone(sdate).format('DD-MM-YYYY')
        this.setState({
    		startDate:startDate,
            formatedsdate:sdate
    	});
	}
    const handleTrucksList = (e) => {
        // console.log(e, 'ee')
        this.setState({
            trucks: e
        })
    }
    const columnwithDefs=[
        // {
        //     headerName: "",
        //     field: "",
        //     width: 50,
        //     cellRendererSelector: function (params) {
        //         if (params.data._id != "total") {
        //             return {
        //                 component: "trucklocaction"
        //             }
        //         }
        //     }
        // },
        {
            headerName: "Truck no",
            field: "truck_no",
            width: 100,
            filter: true,
            resizable: true

        },
        {
            headerName: "Latitude",
            field: "lat",
            width: 120,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                return params.data.lat;
            }

        },
        {
            headerName: "Longitude",
            field: "lng",
            width: 120,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                return params.data.lng;
            }

        },
        {
            headerName: "Area / City",
            field: "area",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "State",
            field: "state",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Speed (Kms)",
            field: "speed",
            width: 80,
            filter: true,
            resizable: true

        },
        {
            headerName: "Packet Time",
            field: "stime",
            width: 140,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                //console.log(params);
                if (params.data.stime != "") {
                    return getHyphenDDMMMYYYYHHMM(params.data.stime);
                }
                else {
                    return "";
                }

            },
            // valueGetter:function(params){
            // 	console.log(params);
            // 	return params.data.stime;
            // }
            //   // filter: "agDateColumnFilter",
            comparator: dateComparator,

        },
        {
            headerName: "Received On",
            field: "received_on",
            width: 140,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                // console.log(params.data.received_on);
                if (params.data.received_on != "NaT") {
                    return getHyphenDDMMMYYYYHHMM(params.data.received_on);
                }
                else {
                    return "";
                }

            },
            // // filter: "agDateColumnFilter",
            comparator: dateComparator,


        },
        {
            headerName: "Time from previous point",
            field: "time_from_prev_point",
            width: 240,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                // return ConvertSeconds(params.data.time_from_prev_point);
                if (params.data.time_from_prev_point != 'Total') {
                    return secondsToDhms(params.data.time_from_prev_point);
                }
                else {
                    return "";
                }
            }

        },
        {
            headerName: "Distance from previous point (Meters)",
            field: "dist_from_prev_point",
            width: 240,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                // return  convertMeters(params.data.dist_from_prev_point);
                if (params.data.dist_from_prev_point != 'Total') {
                    return convertMeters(params.data.dist_from_prev_point);
                }
                else {
                    return "Total";
                }
            }

        },
        {
            headerName: "Cummulative Distance (KM)",
            field: "cumm_distance",
            width: 140,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                return convertMeters(params.data.cumm_distance);
            }

        },
          
    ]

        var listData = [] 
        this.state.filteredData.map(function(e){
        listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        // console.log(this.state.trucks,'1128')
        return (
            <div >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Trucks GPS
                    </h1>
                </div>
                <div className="card m-10p">
                <div className="card-body" style={{position:"relative"}} id="counterCardBody">
                <div className="mb-50p" style={{position:"absolute", right:"20px"}}>    
                <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                                            Back
                                        </a>
                </div>
                    {this.state.alert}
                {/*Container-fluid starts*/} 
                <div className="container-fluid">
                     <div className="row col-xl-12 col-lg-12 mt-60p ml-4p">
                         <div className={"trucks-dropdown "+this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                         </div>
                         <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                            <div className="col-xl-3 col-lg-3 form-group">
                                  <Select
                                            placeholder="Select Truck No"
                                            closeMenuOnSelect={true}
                                            value={this.state.trucks}
											isMulti={false}
                                            className={"border-radius-0"}
                                            onChange={(e) => handleTrucksList(e)}
                                            
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                    marginLeft:"10px"
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={this.state.truckNoList}
                                        />
                                    </div>
                              
                            <div className="col-xl-3 col-lg-3 form-group">
                                <Datetime
                                            key={this.state.startDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off', required:true, readOnly:"true" }} 
                                            timeFormat={false}
                                            value={this.state.startDate}
                                            dateFormat="DD-MM-YYYY"
                                            id='startDate'
                                            onChange={changeHandleDate1}
                                            required />
                                </div>

                            <div className="col-xl-3 col-lg-3 form-group">
                                <Datetime
                                            key={this.state.endDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off', required:true, readOnly:"true" }} 
                                            timeFormat={false}
                                            value={this.state.endDate}
                                            dateFormat="DD-MM-YYYY"
                                            id='endDate'
                                            onChange={changeHandleDate}
                                            required />
                                </div>

                            <div className="col-xl-2 col-lg-2 form-group">
                                <input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
                            </div>
                            <div className="col-xl-1 col-lg-1 form-group">
                                <button type="submit" className="btn btn-success">Generate</button>
                            </div>
                         </form>
                    </div>
                    {(this.state.rowData != null)?
                        <div className="row col-xl-12 col-lg-12">
                            {/* <button type="button" className={"btn "+(this.state.actgridbtn)} onClick={this.onClickShowGrid.bind(this)}>Grid</button>
                            <button type="button" className={"btn "+(this.state.actmapbtn)} onClick={this.onClickShowMap.bind(this)}>Map</button> */}
                              <div className="col-xl-6 col-lg-6">
                                    <div id="myGrid" style={{ height: "820px",width: "100%",marginTop:'10px'}}  className={"ag-theme-balham "+(this.state.gridshow)}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        // gridOptions={{
                                        //     context: { componentParent: this }
                                        // }}
                                        gridOptions={{
                                            icons: {
                                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                                last: `<img src="${customLastIcon}" alt="Last" />`,
                                            },
                                        }}
                                        
                                        pinnedBottomRowData={this.state.tottalDistance}
                                        />

                                    </div>
                                  </div>
                                  {/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
                                <div className={"col-xl-6 col-lg-6 white-bg "} style={{padding:"10px"}}>
                                    {/* <input type="checkbox" style={{marginLeft: "2em",marginBottom: "1em",}} value={this.state.showmarkers} onClick={this.showmarkers.bind(this)} /> view markers */}
                                    <Mapcard2 
                                    context={this} 
                                    mapFor={"truck"} 
                                    tolls = {this.state.tolls}
                                    mapinfo={this.state.mapinfo} 
                                    mapData={this.state.mapData}
                                    defTransitCoords={""} 
                                    fullscreenControl={true}/>
                                   
                                   <div className="col-xl-12 col-lg-12 n-p-0">
                                    {/* <div className="crm-numbers pb-0">
                                        <h3 className="subH">View Details:</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Truck No</label>
                                                <div>{this.state.maptruck}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>{(this.state.mapinfo.route_details.start_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "NA"}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>{(this.state.mapinfo.route_details.end_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "NA"}</div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">Distance</label>
                                                <div>{(this.state.totaldistanceval / 1000).toFixed(2)} Kms</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">Tracking Type</label>
                                                {(this.state.mapinfo.type_of_tracking == 0)?
                                                <div>N/A</div>
                                                :""}
                                                {(this.state.mapinfo.type_of_tracking == 1)?
                                                <div>GPS Tracking</div>
                                                :""}
                                                {(this.state.mapinfo.type_of_tracking == 2)?
                                                <div>SIM Tracking</div>
                                                :""}
                                                {(this.state.mapinfo.type_of_tracking == 3)?
                                                <div>GPS &amp; SIM Tracking</div>
                                                :""}
                                                {(this.state.mapinfo.type_of_tracking == 99)?
                                                <div>N/A</div>
                                                :""}
                                                {(this.state.mapinfo.type_of_tracking == undefined || this.state.mapinfo.type_of_tracking == "undefined" || this.state.mapinfo.type_of_tracking == "")?
                                                <div>N/A</div>
                                                :""}
                                            </div>

                                        </div>
                                    </div> */}
                                </div>

                                </div> 
                                {/* :""} */}
                        </div>
                                
                   :""}

                    {/* <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div>
                        </div>
                        </div>
                    </div> */}
                     <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                </div>
                {/*Container-fluid Ends*/}
                
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>
                </div>
                </div>
            </div>
        );
    }
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

  function timeConvert(n) {
      var num = n;
      var hours = (num / (3600));
      var rhours = parseInt(hours);
      var minutes = (num-(rhours*3600)) / (60);
      var rminutes = Math.round(minutes);
      return rhours + " hour(s) and " + rminutes + " minute(s).";
  }   

function ConvertMinutes(num){
    var d = Math.floor(num/1440); // 60*24
    var h = Math.floor((num-(d*1440))/60);
    var m = Math.round(num%60);
    return d+" Day(s) "+h+" Hrs "+m+" mins"
}

function ConvertSeconds(totalSeconds)
{
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
}

function convertMeters(metersValue)
{
    return (metersValue/1000).toFixed(2);
}

$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
});

function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}


