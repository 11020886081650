import CSVFileValidator from 'csv-file-validator';

var BulkForceFileHandler = async (e, headers) => {
    const config = {
        headers: headers.map(header => ({
            name: header.name,
            inputName: header.inputName,
            required: header.required || false,
            requiredError: function(headerName, rowNumber, columnNumber) {
                return `${headerName} is required in row ${rowNumber} at column ${columnNumber}`;
            }
        })),
        // Add a flag to only allow specified columns
        allowExtraHeaders: false,
        extraHeadersError: function (extraHeaderName, rowNumber, columnNumber) {
            return `Extra column '${extraHeaderName}' is not allowed in row ${rowNumber} at column ${columnNumber}`;
        }
    };

    if (e.target.files[0]) {
        try {
            const csvData = await CSVFileValidator(e.target.files[0], config);
            console.log("Validated CSV data:", csvData);
            return csvData.data; // Returning the processed data from the CSV
        } catch (error) {
            console.error("Error validating CSV file:", error);
            throw error; // Rethrow or handle error appropriately
        }
    }
};

export default BulkForceFileHandler;
