import React, { Component } from "react";
import CountUp from "react-countup";
import './TrackingCounters.css'

export default class TrackingCounters extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    return (
      <div className="crm-numbers pb-0">
        {this.props.tabActive == 'transit' ?
          <div className="row">
            {/* Empty Pickup */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClick={this.props.setfilter.bind(this,'CEP')}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fas fa-truck-loading f24 greenfont icon-tracking"></i>
                  <br /> Empty Pickup
                </span>
                <h4 className="f32" style={{ color: "black", pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp
                      end={this.props.emptyPickupCounter}
                    // end={10}
                    />
                  </span>
                </h4>
              </div>
            </div>
            {/* Dispatch */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClickCapture={this.props.setfilter.bind(this,'DSP')}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fas fa-truck-moving f24 greenfont icon-tracking"></i>
                  <br /> Dispatch
                </span>
                <h4 className="txt-primary f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.dipatchCounter} />
                  </span>
                </h4>
              </div>
            </div>
            {/* Gate In */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClick={this.props.setfilter.bind(this,'CGI')}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fas fa-pallet f24 greenfont icon-tracking"></i>
                  <br /> Gate In
                </span>
                <h4 className="txt-secondary f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.gateInCounter} />
                  </span>
                </h4>
              </div>
            </div>
            {/* Origin */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClick={this.props.setfilter.bind(this,'VDL')} >
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fi-ss-ship-side f24 greenfont icon-tracking"></i>
                  <br /> Origin
                </span>
                <h4 className="txt-secondary f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.originCounter} />
                  </span>
                </h4>
              </div>
            </div>
            {/* Transhipment */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClick={this.props.setfilter.bind(this,'TRANS')}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fas fa-ship  f24 greenfont icon-tracking"></i>
                  <br /> Transhipment
                </span>
                <h4 className="txt-info f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.transhipmentCounter} />
                  </span>
                </h4>
              </div>
            </div>
            {/* Destination */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClick={this.props.setfilter.bind(this,'VAD')} >
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fi-ss-ship-side f24 greenfont icon-tracking"></i>
                  <br /> Destination
                </span>
                <h4 className="txt-info f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.destinationCounter} />
                  </span>
                </h4>
              </div>
            </div>
            {/* Gate Out */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClick={this.props.setfilter.bind(this,'CGO')}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fas fa-pallet f24 greenfont icon-tracking"></i>
                  <br /> Gate Out
                </span>
                <h4 className="f32" style={{ color: "green", pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.gateOutCounter} />
                  </span>
                </h4>
              </div>
            </div>
            {/* Empty Return */}
            <div className="col">
              <div className=" circle-tracking-counter cursorPointer" onClick={this.props.setfilter.bind(this,'CER')}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fa fa-industry f24 greenfont icon-tracking"></i>
                  <br /> Empty Return
                </span>
                <h4 className="txt-info f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.emptyReturnCounter} />
                  </span>
                </h4>
              </div>
            </div>
          </div>
          : 
          <div className="row">
            {/* Completed */}
            <div className="col">
              <div className=" circle-eta-counter cursorPointer selected-counter"  onClick={this.props.setEtaFilter.bind(this,'All')} style={{ paddingTop: "8px" }}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fas fa-flag-checkered f24 greenfont icon-tracking"></i>
                  <br /> Completed
                </span>
                <h4 className="f32" style={{ color: "black", pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp
                      end={this.props.completedCounter}
                    // end={10}
                    />
                  </span>
                </h4>
              </div>
            </div>
            {/* On Time */}
            <div className="col">
              <div className=" circle-eta-counter cursorPointer"  onClick={this.props.setEtaFilter.bind(this,'On Time')} style={{"paddingTop":"8px"}}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fi-br-time-check f24 greenfont icon-tracking"></i>
                  <br /> On Time
                </span>
                <h4 className="txt-primary f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.onTimeCounter} />
                  </span>
                </h4>
              </div>
            </div>
            {/* Delayed */}
            <div className="col">
              <div className=" circle-eta-counter cursorPointer" onClick={this.props.setEtaFilter.bind(this,'Delayed')} style={{"paddingTop":"8px"}}>
                <span className="sptile-tracking" style={{pointerEvents: 'none'}}>
                  <i className="fi-br-time-delete f24 greenfont icon-tracking"></i>
                  <br /> Delayed
                </span>
                <h4 className="txt-secondary f32" style={{pointerEvents: 'none'}}>
                  <span className="counter">
                    <CountUp end={this.props.delayedCounter} />
                  </span>
                </h4>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
