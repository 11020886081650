

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import download from 'js-file-download';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class PreclosuregridData extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
            resizable: true,
            menuTabs: ['filterMenuTab']
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        ffRowData : [],
        invoiceData:[]
        //components : {datepicker:getDatePicker()}
    }
}
componentDidMount(){

    var ffRowData = this.props.ffRowData;
    console.log(ffRowData,"ffRowData")
    this.setState({
        ffRowData : ffRowData,
    })
}
onRecommGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onRecommGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    invoiceData:[]
    
    });
    
}

showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

closeAlert = () => {
    this.setState({
        show: false
    });
}



viewDetails(e){
    if(e.colDef.field == "invoiceData")
    {
        var invData = [];
        if(e.data.invoiceData !=undefined && e.data.invoiceData !='')
        {
            var invoiceData = JSON.parse(e.data.invoiceData);
            if(invoiceData !=undefined && invoiceData.length > 0)
            {
                invData = invoiceData;
            }
        }
        var sliderTitle = e.data.job_no+" - Pre alert documents";
        this.setState({
            overly :"show-m",
            invoiceData:invData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    if(e.colDef.field == "other_docs")
    {
        var ringiFile = e.data.other_docs
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadRingiFile",rngParam).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

  render() {
    var columnwithDefs = [      
        {
            field:'_id',
            headerName:'',
            colId:"_id",
            //pinned:"left",
            width: 72,
            cellRenderer:function(params){
                let basestring = params.data._id;
                let encryptedstring = window.btoa(basestring);
                if(params.data.pending_with == 1)
                {
                    var editUrl = "/editffaircargoform";
                }
                else
                {
                    var editUrl = "/editchaaircargoform";
                }
                var htmloption = '<a href="'+editUrl+'/'+encryptedstring+'/2" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                return htmloption;
            }            
        },
        {
            headerName: "Job No",
            field: "job_no",
            width:130,
            filter: true,
            resizable: true,           
        },  
        {
            headerName: "Pending With",
            field: "pending_with",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.pending_with == 1)
                {
                    return "Freight Forwarder";
                }
                else
                {
                    return "CHA";
                }
            }         
        },
        {
            headerName: "Fright Forwarder",
            field: "assign_to_ff",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "CHA",
            field: "assign_to_cha",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Date",
            field: "date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.date !=undefined && params.data.date !='')
                {
                    return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                }
            }
        },
        {
            headerName: "Department Name",
            field: "dept_name",
            width:120,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Name",
            field: "indenter_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Mobile No",
            field: "mobile",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Email",
            field: "indenter_email",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Counterpart Details",
            field: "counterpart_details",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Pick up date",
            field: "pickup_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.pickup_date !=undefined && params.data.pickup_date !='')
                {
                    return moment.parseZone(params.data.pickup_date).format("DD-MM-YYYY")
                }
            }         
        },
        {
            headerName: "Cargo reciving date",
            field: "cargo_reciving_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.cargo_reciving_date !=undefined && params.data.cargo_reciving_date !='')
                {
                    return moment.parseZone(params.data.cargo_reciving_date).format("DD-MM-YYYY")
                }
            }         
        },
        {
            headerName: "Airlines Name",
            field: "airlines_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Direct Flight",
            field: "direct_flight",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Port Name",
            field: "port_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETD Port date",
            field: "etd_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.etd_port_date !=undefined && params.data.etd_port_date !='')
                {
                    return moment.parseZone(params.data.etd_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATD Port date",
            field: "atd_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.atd_port_date !=undefined && params.data.atd_port_date !='')
                {
                    return moment.parseZone(params.data.atd_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ETA Transit Port Date",
            field: "eta_transit_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_transit_port_date !=undefined && params.data.eta_transit_port_date !='')
                {
                    return moment.parseZone(params.data.eta_transit_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATA Transit Port Date",
            field: "ata_transit_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_transit_port_date !=undefined && params.data.ata_transit_port_date !='')
                {
                    return moment.parseZone(params.data.ata_transit_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ETD Transit Port Date",
            field: "etd_transit_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.etd_transit_port_date !=undefined && params.data.etd_transit_port_date !='')
                {
                    return moment.parseZone(params.data.etd_transit_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATD Transit Port Date",
            field: "atd_transit_port_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.atd_transit_port_date !=undefined && params.data.atd_transit_port_date !='')
                {
                    return moment.parseZone(params.data.atd_transit_port_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ETA IGI Airport Date",
            field: "eta_igi_airport_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !='')
                {
                    return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "ATA IGI Airport Date",
            field: "ata_igi_airport_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !='')
                {
                    return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "IF ETD and ATD not same",
            field: "if_etd_atd_not_same",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "If ATD and ATA not same",
            field: "if_atd_ata_not_same",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "MAWB No",
            field: "mawb_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "HAWB No",
            field: "hawb_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "HAWB date",
            field: "hawb_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.hawb_date !=undefined && params.data.hawb_date !='')
                {
                    return moment.parseZone(params.data.hawb_date).format("DD-MM-YYYY")
                }
            }            
        },
        {
            headerName: "No. of pkt",
            field: "no_of_pkt",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.no_of_pkt !=undefined && params.data.no_of_pkt !="undefined")
                {
                    return params.data.no_of_pkt;
                }
            }         
        },
        {
            headerName: "Gross wt(In Kgs)",
            field: "gross_wt",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.gross_wt !=undefined && params.data.gross_wt !="undefined")
                {
                    return params.data.gross_wt;
                }
            }         
        },        
        {
            headerName: "Chargeable Weight(In Kgs)",
            field: "chargeble_weight",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.chargeble_weight !=undefined && params.data.chargeble_weight !="undefined")
                {
                    return params.data.chargeble_weight;
                }
            }         
        },        
        {
            headerName: "Invoice No",
            field: "invoice_no",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.invoice_no !=undefined && params.data.invoice_no !="undefined")
                {
                    return params.data.invoice_no;
                }
            }         
        },
        {
            headerName: "Pre alert documents",
            field: "invoiceData",
            width:130,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                return htmloption;
            }         
        },
        {
            headerName: "Air freight Amount",
            field: "airfreight_amount",
            width:130,
            filter: true,
            resizable: true
        },
        {
            headerName: "FCA/EXW Amount",
            field: "fca_exw_amount",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Air freight Currecny",
            field: "airfreight_currency",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Any other docs",
            field: "other_docs",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "IGM Date",
            field: "igm_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.igm_date !='' && params.data.igm_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.igm_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Inward Date",
            field: "inward_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.inward_date !='' && params.data.inward_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.inward_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Segregation Date",
            field: "segregation_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.segregation_date !='' && params.data.segregation_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.segregation_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "BOE No",
            field: "boe_no",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BOE Date",
            field: "boe_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.boe_date !='' && params.data.boe_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.boe_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Status",
            field: "status",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Exam Date",
            field: "exam_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.exam_date !='' && params.data.exam_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.exam_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "OTP Date",
            field: "otp_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.otp_date !='' && params.data.otp_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.otp_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "OOC Date",
            field: "ooc_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.ooc_date !='' && params.data.ooc_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.ooc_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Exchange Rate",
            field: "exchange_rate",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Assessable Value",
            field: "assessable_value",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BCD",
            field: "bcd",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "SWS",
            field: "sws",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "IGST",
            field: "igst",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Total Custom Duty",
            field: "total_custom_duty",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "LPC",
            field: "lpc",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Interest",
            field: "interest",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "MEIS",
            field: "meis",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Duty payable",
            field: "duty_payable",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Reason For Delay",
            field: "lpc_reason_for_delay",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BC No 1",
            field: "bc_no_1",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BC-1 Date",
            field: "bc_1_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.bc_1_date !='' && params.data.bc_1_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.bc_1_date);
                    }
                }
                catch(e){
                }
            }          
        },
        
        {
            headerName: "BC No 2",
            field: "bc_no_2",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BC-2 Date",
            field: "bc_2_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.bc_2_date !='' && params.data.bc_2_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.bc_2_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "TSP charges",
            field: "tsp_charges",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Charges",
            field: "demmurage_charges",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Charges Amount",
            field: "demmurage_charges_amount",
            width:150,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Start Date",
            field: "demmurage_start_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.demmurage_start_date !='' && params.data.demmurage_start_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.demmurage_start_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Demmurage End Date",
            field: "demmurage_end_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.demmurage_end_date !='' && params.data.demmurage_end_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.demmurage_end_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Reason For Delay",
            field: "reason_for_delay",
            width:120,
            filter: true,
            resizable: true,          
        }, 
    ]

        
    return (
        <div class="row">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>
                <AgGridReact
                    modules={this.state.modules}
                    //columnDefs={columnwithDefs}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.ffRowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onRecommGridReady}
                    onGridState={this.onRecommGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    onCellClicked={this.viewDetails.bind(this)}
                    // components={this.state.components}
                    />
                </div>
            </div>
            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">
                {this.state.sliderTitle}
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                {(this.state.invoiceData.length > 0) ?                         
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered tptinfo">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.invoiceData.map((e, index) => (
                                        <tr>
                                            <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                        </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                : ""}
            </div>
        </div>
        </div>
        

    );
  }
}