import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

const SubmitRevisedBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        // props.context.componentParent.onClickEditProductionData(props);
        props.context.onClickRevisedProductionData(props);
    };
    var colr = props.data.comment_exists;
    
    return (
        <div>
            {(props.data.tpt_container_planned!=1)?
                <button
                    style={{ color: "#fff",backgroundColor:'rgb(64,81,120)' }}
                    onClick={handleRouteClick}
                    className="btn custom-btn f12 label label-success mb-10p"
                    title=""
                >
                    Submit
                </button>: <button
                 style={{ backgroundColor: "#8D918D" ,color:'#FFFFFF'}}
                 onClick={handleRouteClick}
                 className="btn custom-btn f12 label label-success mb-10p"
                 title=""
             >
                 Submit
             </button>}
            </div>
    );
};

export default SubmitRevisedBtn;
