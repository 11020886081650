import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import redirectURL from "../redirectURL";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import withRouter from "../withRouter";

class Bookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowData: [],
      loadshow: "show-n",
      isDataLoaded: false,
      gridApi: "",
      gridColumnApi: "",
      shipmentRoute: "",
      requirementId: "",
      defaultColDef: {
        // flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
        menuTabs: ['filterMenuTab']
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      paginationPageSize: 100,
    };
  }

  componentDidMount = () => {
    this.setState({
      loadshow: "show-m",
    });
    redirectURL
      .get("/exim/getAllEximBookings")
      .then((resp) => {
        console.log("getAllEximBookings", resp.data);
        var bookingData = resp.data.bookingData;
        this.setState({
          rowData: bookingData,
          isDataLoaded: true,
          loadshow: "show-n",
        });
      })
      .catch((err) => {
        console.log("getAllEximBookings err", err);
      });
  };

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
      gridColumnApi: params.columnApi,
    });
  };

  onGridState = () => {
    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel;
    this.gridApi.setFilterModel(window.filterState);
  };

  handleRowClick = (params) => {
    console.log("handleCellClick", params.data);
    const { navigate } = this.props.match;
    navigate(`/shipment-details/${params.data.requirement_id}`)
  };

  render() {
    const columnwithDefs = [
      {
        headerName: "Shipment Name",
        field: "shipment_name",
        // width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "PO Numbers",
        field: "po_numbers",
        // width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Origin City",
        field: "origin_cityname",
        // width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Destination City",
        field: "destination_cityname",
        // width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Booking Status",
        field: "booking_status",
        // width: 100,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Product",
        field: "product_name",
        // width: 100,
        filter: true,
        resizable: true,
      },
    ];
    if (!this.state.isDataLoaded) {
      return (
        <>
          <div className={"dataLoadpage " + this.state.loadshow}></div>
          <div className={"dataLoadpageimg " + this.state.loadshow}>
            <div className="spinner-grow text-primary mr-2" role="status"></div>
            <div className="spinner-grow text-primary mr-2" role="status"></div>
            <div className="spinner-grow text-primary" role="status"></div>
          </div>
        </>
      );
    } else if (this.state.isDataLoaded && this.state.rowData.length > 0) {
      return (
        <>
          <div className="card shadow p-2 mx-3">
            <div className="mb-2">
              <button className="btn btn-primary">
                <Link
                  to={"/createEximbooking"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Create Booking
                </Link>
              </button>
            </div>
            <div
              id="myGrid"
              style={{ height: "400px", width: "100%" }}
              className="ag-theme-balham"
            >
              <AgGridReact
                columnDefs={columnwithDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                enableCharts={true}
                enableRangeSelection={true}
                autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                statusBar={this.state.statusBar}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                sideBar={this.state.sideBar}
                animateRows={true}
                onRowClicked={this.handleRowClick.bind(this)}
              />
            </div>
          </div>
        </>
      );
    } else {
      return <Navigate to={"/createEximbooking"} push={true} />;
    }
  }
}
export default withRouter(Bookings)