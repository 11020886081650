import React,{useState} from 'react'
const ChaUploadDO = () => {
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    return (
        <div className="row">
           
                <div className="d-flex flex-row m-24p">
                    <div className="d-flex flex-row">
                        <button
                            className="d-flex flex-row justify-content-center align-items-center px-3"
                            style={{
                                background: summaryviewcheck == 1 ? '#FF9900' : "",
                                // color: state.isTileView ? "": "#0664AE",
                                borderTopLeftRadius: "11px",
                                borderBottomLeftRadius: "11px",
                                border: "1px solid #FF9900",
                                height: "38px",
                                padding: '22px'
                            }}
                            onClick={() => showdata('summary view')}
                        >
                            <div
                                style={{
                                    color: summaryviewcheck == 1 ? "white" : '#FF9900',
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    marginLeft: "10px",
                                    lineHeight: "12px",
                                }}
                            >
                                Summary View
                            </div>
                        </button>

                        <button
                            className="d-flex flex-row justify-content-center align-items-center px-3"
                            style={{
                                background: detailedviewcheck == 1 ? '#FF9900' : "",
                                borderTopRightRadius: "11px",
                                borderBottomRightRadius: "11px",
                                border: "1px solid #FF9900",
                                height: "38px",
                                padding: '22px'
                            }}
                            onClick={() => showdata('detailed view')}
                        >
                            <div
                                style={{
                                    color: detailedviewcheck == 1 ? "white" : '#FF9900',
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    marginLeft: "10px",
                                    lineHeight: "12px",
                                }}
                            >
                                Detailed View
                            </div>
                        </button>
                    </div>

                </div> 

        </div>
    )
}
export default ChaUploadDO