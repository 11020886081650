import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';

import 'react-datetime/css/react-datetime.css';
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHHMM } from '../common/utils';
import ConsignmentTrucksLegs from "./containertruckslegs";
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import Datepicker from "../layouts/datepicker";
import { countries } from "country-flags-svg";
import CountryFlagComponent from "./countryFlag";
import DestinationFlagComponent from "./destinationCountryFlag";
import Mapcard2 from "./drawmap";
import moment from "moment";
import Select from "react-select";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import redirectURL from "../redirectURL";
import { fetchGrid } from "../fetchGridStructure"
import { getHyphenDDMMYYYYHHMMSS } from "../utils";
import PTData from '../../assets/json/payment_term_description.json'

import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";

var initialState = {
    detailCellRendererParams: {},
};

var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        //  console.log('6767')
        //  return {
        //      ...state,
        //      detailCellRendererParams: action.payload,
        //  };
        default:
            return state;
    }
};
const AllComponent = (props) => {
    const gridApi = useRef(null);
    var [state, dispatch] = useReducer(reducer, initialState);
    var [rowData, setrowData] = useState(props.data)
    var [originalData, setoriginalData] = useState([])
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    const [showAddForm, setshowAddForm] = useState('')
    var [checkfortruck, setcheckfortruck] = useState(0)
    var selectconfirmn = [
        { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }
    ]
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [trucksList, settrucksList] = useState([])
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        trucks: '',
        wrno: ''
    })
    var [workordernoList, setworkOrderList] = useState([])
    var [containerLegsData, setContainerLegsData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [parentrow, setparentrow] = useState({})
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [ptd, setptd] = useState(PTData.PTData ?? [])
    var typeofselection = [
        { label: 'Work Order No', value: 'work_order_no' }, { label: 'WO Release Date', value: 'wo_release_date' }
    ]
    var [checkresetfilter, setcheckresetfilter] = useState(0)
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        mapdata: [],
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    var [refreshSelected, setRefreshSelected] = useState(0);

    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
    }, [gridApi]);


    useEffect(() => {
        // redirectURL.post('/consignments/getAllConsignmentsData', { "status": 18 }).then((resp) => {
        //     var work_order_level_data = resp.data.work_order_level_data
        //     work_order_level_data.sort((firstItem, secondItem) => secondItem.status - firstItem.status);
        //     work_order_level_data=sortByStatus(work_order_level_data)
        //     setrowData(work_order_level_data)
        //     setoriginalData(work_order_level_data)
        // })
        var data = sortByCreatedDate(props.data)
        setrowData(data)
        setoriginalData(data)
    }, [props.data])
    useEffect(() => {
        const fetchData = async () => {
            const resp = await redirectURL.post("/consignments/usergridstates", { screenurl: '/all' });
            restoreGridStates(resp.data);
        };
        fetchData();
    }, []);

    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const getFlagIcon = (countryName) => {
        let country_name = "";
        var srcFlag = countries.filter((eachCountry) => {
            if (eachCountry.iso2 === countryName) {
                country_name = eachCountry.name;
                return eachCountry;
            }
        });
        if (srcFlag && srcFlag[0]?.flag) {
            return {
                img: srcFlag[0]?.flag,
                name: country_name,
            };
        } else {
            return "";
        }
    };
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const updateBtn = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onSelectWON = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: e
        }))
    }
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        var to_date = woReleaseDate.wo_release_to_date
        if (from_date != '' && from_date != undefined && from_date != null && to_date != '' && to_date != undefined && to_date != null) {
            from_date = moment(from_date, 'DD-MM-YYYY')
            to_date = moment(to_date, 'DD-MM-YYYY')
            var filteredData = originalData.filter(item => {
                var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
                itemDate = moment(itemDate, 'DD-MM-YYYY')
                return itemDate.isBetween(from_date, to_date, null, '[]')
            });
            setrowData(filteredData)
            setRefreshSelected(0);
            setcheckresetfilter(1);

        }
        else {
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    var updateContainerDetails = async (e) => {
        var params = {
            container_no: e.container_no,
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
        }
        var check = 0
        if (e.reporting_at_parking != undefined && e.reporting_at_parking != null && e.reporting_at_parking != "") {
            params.reporting_at_parking = e.reporting_at_parking
            check = 1
        }
        if (check != 0) {
            redirectURL.post('/consignments/updateContainerreportingtimeatplaza', params).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Reporting Time At Parking Plaza has been updated successfully!")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                } else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
        } else {
            setshow(true)
            setbasicTitle("Please Provide Reporting Container Date")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }

    }
    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        };
        setloadshow('show-m');
        setoverly('show-m');

        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            // console.log(resp, "map")
            var gps_route = resp.data.gps_route.coords;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');
            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route = gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;
                // console.log('gps_route', gps_route);
                // console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                    }
                }
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                    }
                }
                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                        ldb_route: allldbcoords
                    },
                    mapdata: resp.data.gps_route
                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    };
    // const onShowShipmentLegRouteMap = async (e) => {
    //     var params = {
    //         work_order_no: e.work_order_no,
    //         truck_no: e.truck_no,
    //         container_no: e.container_no
    //     };

    //     setloadshow('show-m');
    //     setoverly('show-m');

    //     try {
    //         const resp = await redirectURL.post('/consignments/getmapdata', params);
    //         var records = resp.data.gps_df;
    //         if (!Array.isArray(records)) {
    //             records = records.replace(/NaN/g, "0");
    //             records = JSON.parse(records);
    //         }
    //         if (!records || records.length === 0) {
    //             setbasicTitle('No GPS Data Available');
    //             setbasicType('danger');
    //             setloadshow('show-n');
    //             setoverly('show-n');
    //         } else {
    //             var data = records[0];
    //             var allCoordinates = [];
    //             var allldbcoords = []
    //             var gps_route = data.gps_route;
    //             var ldb_route = data.ldb_route
    //             gps_route = JSON.parse(gps_route)
    //             ldb_route = JSON.parse(ldb_route)
    //             ldb_route.map((e) => {
    //                 var lt = e.latitude || e.lat || 0;
    //                 var ln = e.longitude || e.lng || 0;

    //                 allldbcoords.push({
    //                     lat: lt,
    //                     lng: ln,
    //                 });
    //             });

    //             gps_route.map((e) => {
    //                 var lt = e.latitude || e.lat || 0;
    //                 var ln = e.longitude || e.lng || 0;

    //                 allCoordinates.push({
    //                     lat: lt,
    //                     lng: ln,
    //                 });
    //             });
    //             var truck_no;
    //             if (records.length > 0) {
    //                 truck_no = records[0].truck_no;
    //             }
    //             console.log(allCoordinates, 'allCoordinates');
    //             if (allCoordinates.length > 0) {
    //                 setoverly('show-m')
    //                 setStateForMap((prev) => ({
    //                     ...prev,
    //                     sliderRouteTranslate: "slider-translate-60p",
    //                     showDiv: 'show-m',
    //                     container_no: truck_no,
    //                     mapinfo: {
    //                         ...state.mapinfo,
    //                         coords: allCoordinates,
    //                         ldb_route: allldbcoords
    //                     },
    //                 }));
    //             } else {
    //                 setbasicTitle('No GPS Data Available');
    //                 setbasicType('danger');
    //                 setloadshow('show-n');
    //                 setshow(true);
    //             }
    //             setloadshow('show-n');
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    var onClickShowShipmentLegs = async (rownode) => {
        var commentHeaders = [
            // {
            //     headerName: 'Requirement Id',
            //     field: "requirement_id",
            //     resizable: true,
            //     width: 180,
            //     filter: true
            // },
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHHMM(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,

            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Plant Gate-In Time',
                field: "gate_in_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },

            {
                headerName: 'Plant Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },


            {
                headerName: 'Reporting Time At Parking Plaza',
                field: "reporting_at_parking",
                resizable: true,
                width: 240,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.reporting_at_parking);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },

            {
                headerName: 'Gate-In Time At Port',
                field: "gate_in_time_port",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time_port != "" && params.data.gate_in_time_port != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time_port);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Shipping On Board Date',
                field: "shipping_on_board_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.shipping_on_board_date != "" && params.data.shipping_on_board_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.shipping_on_board_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'BL No',
                field: "bl_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Bl Date',
                field: "bl_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.bl_date != "" && params.data.bl_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.bl_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            }
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',
            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no: row.work_order_no
                }
                await redirectURL.post("/consignments/truckslegsdata", parameter).then(async (response) => {
                    var records = response.data
                    // var records = containerLegsData.filter(e =>e.work_order_no==row.work_order_no)
                    param.successCallback(records);
                }).catch(function (error) {
                })
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
        setTimeout(() => {
            if (rownode.column.colDef.field == 'containerLegs') {
                rownode.node.setExpanded(!rownode.node.expanded);
            }
            else {
                rownode.node.setExpanded(false);
            }
        }, 0)
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
    }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var trucks = manualUploadDetails.trucks
        var truckList = []
        trucks.map((e) => {
            truckList.push(e.value)
        })
        var wrno = manualUploadDetails.wrno.value
        var record = originalData.filter(e => e.work_order_no == wrno)
        record = record[0]
        var containerCount = record.container_count
        var params = {
            work_order_no: wrno,
            trucks: truckList
        }
        if (containerCount == trucks.length) {
            redirectURL.post('/consignments/updateTrucksDetails', params).then((resp) => {

            })
        }
        else {
        }
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onSelectConfirm = (e) => {
        if (e.value == 'yes') {
            setcheckfortruck(1)
        }
        else {
            setshowAddForm('')
            setoverly('show-n')
        }
    }
    const handleTrucksList = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            trucks: e
        }))
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const [summaryViewcols, setsummaryViewcols] = useState([
        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Status",
            field: "status",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                if (!params.data) {
                    return null;
                }
                if (params.data.new_plant == 1) {
                    return 'New'
                }
                else if (params.data.cha_vessel_new == 1) {
                    return 'At CHA Vessel Availability'
                }
                else if (params.data.ib_stuffing_new == 1) {
                    return 'At IB Stuffing'
                }
                else if (params.data.cha_do_new == 1) {
                    return 'At Upload DO'
                }
                else if (params.data.ib_tpt_new == 1) {
                    return 'At IB Tpt Allocation'
                }
                else if (params.data.tpt_trucks_new == 1) {
                    return 'At Update Trucks'
                }
                else if (params.data.tpt_container_new == 1) {
                    return 'At Update Containers'
                }
                else if (params.data.ib_plant_gate_in_new == 1) {
                    return 'In Transit to Plant'
                }
                else if (params.data.at_plant_new == 1) {
                    return 'At Plant'
                }
                else if (params.data.shipping_new == 1) {
                    return 'At Shipping Bill'
                }
                else if (params.data.cha_reporting_new == 1) {
                    return 'In Transit to Parking Plaza'
                }
                else if (params.data.cha_leo_new == 1) {
                    return 'At LEO'
                }
                else if (params.data.cha_gate_in_port_new == 1) {
                    return 'At Port Gate In'
                }
                else if (params.data.cha_shipping_onboard_new == 1) {
                    return 'At Shipping Onboard'
                }
                else if (params.data.cha_bl_new == 1) {
                    return 'At B/L'
                }
                else if (params.data.buffer_raised == 1) {
                    return 'Awaiting At Buffer Approval'
                }
                else if (params.data.buffer_accepted == 1) {
                    return 'At Buffer Yard'
                }
                else if (params.data.booking_completed == 1) {
                    return 'Completed'
                }
                // else{
                //     return 'Completed'
                // }
            }
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Destination Country",
            field: "destination_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'DestinationFlagComponent'
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Pendency",
        //     field: "total_trucks",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        // },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            valueGetter: function (params) {
                try {
                    // console.log(ptd, "ptd");
                    // console.log(params.data.payment_term, "params.data.payment_term");
                    if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                        let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                        if (temp)
                            return temp.payment_term + " - " + temp.description
                        else
                            return params.data.payment_term
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            width: 350,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Tentative Gate Open Date",
        //     field: "tentative_gate_open_date",
        //     width: 175,
        //     filter: true,
        //     resizable: true,
        //     editable: true,
        //     //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
        //     cellEditor: DateEditor,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
        //             } else {
        //                 return "Not Available";
        //             }
        //         } catch (e) {
        //         }
        //     },
        //     comparator: dateComparator1,
        // },
        // {
        //     headerName: "Tentative Cut Off Date ",
        //     field: "tentative_cut_off_date",
        //     width: 170,
        //     filter: true,
        //     resizable: true,
        //     editable: true,
        //     //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
        //     cellEditor: DateEditor,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
        //             } else {
        //                 return "Not Available";
        //             }
        //         } catch (e) {
        //         }
        //     },
        //     comparator: dateComparator1,
        // },
        {
            headerName: "SL Cut Off Date",
            field: "sl_cutoff_date",
            width: 170,
            filter: true,
            resizable: true,
            editable: true,
            //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
            cellEditor: DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.sl_cutoff_date != "" && params.data.sl_cutoff_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.sl_cutoff_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                }
            },
            comparator: dateComparator1,
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        // {
        //     headerName: "Booking No",
        //     field: "booking_no",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        }, {
            headerName: "Booking No",
            field: "booking_no",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "DO Valid From",
            field: "do_valid_from",
            width: 190,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.do_valid_from != "" && params.data.do_valid_from != undefined) {
                        return getHyphenDDMMYYYY(params.data.do_valid_from);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "DO Valid To",
            field: "do_valid_to",
            width: 190,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.do_valid_to != "" && params.data.do_valid_to != undefined) {
                        return getHyphenDDMMYYYY(params.data.do_valid_to);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Freight Forwarder",
            field: "freight_forwarder",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Pickup Yard",
            field: "pickup_yard",
            width: 200,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Shipping Bill No",
        //     field: "shipping_bill_no",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Shipping Bill Date",
        //     field: "shipping_bill_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.shipping_bill_date != "" && params.data.shipping_bill_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.shipping_bill_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: 'LEO Date',
            field: "leo_date",
            resizable: true,
            width: 180,
            filter: true,
            editable: false,
            valueGetter: function (params) {
                try {
                    if (params.data.leo_date != "" && params.data.leo_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.leo_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
    ])
    const handleFilterOptions = (e) => {
        if (e.value == 'wo_release_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    // const handleInputfilter = (e) => {
    //     setselectedval(prev => ({
    //         ...prev,
    //         inputval: e.target.value
    //     }))
    //     var typeoffield = selectedval.dropdownval.value
    //     var val = e.target.value
    //     var filteredData
    //     if (typeoffield == 'work_order_no') {
    //         console.log('1198', val)
    //         if (val != '' && val != undefined) {
    //             filteredData = originalData.filter((each) => {
    //                 if (
    //                     each.work_order_no.includes(state.searchInput.toUpperCase().trim())
    //                 ) {
    //                     return each;
    //                 }
    //             }
    //         else {
    //             filteredData = originalData
    //         }
    //     }
    // }
    const handleInputfilter = (e) => {
        const val = e.target.value.trim().toUpperCase(); // Trim and convert input value to uppercase
        const typeoffield = selectedval.dropdownval.value;
        let filteredData;

        if (typeoffield === 'work_order_no') {
            if (val !== '') {
                filteredData = originalData.filter((each) => {
                    return each.work_order_no.includes(val);
                });
            } else {
                filteredData = originalData;
            }
        } else {
            // Handle other field types if needed
        }

        setselectedval((prev) => ({
            ...prev,
            inputval: e.target.value,
        }));

        setrowData(filteredData);
    };
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if (val != '' && val != undefined) {
            var filteredData
            if (typeoffield == 'work_order_no') {
                if (val != '' && val != undefined) {
                    filteredData = originalData.filter(item => item.work_order_no == val);
                }
                else {
                    filteredData = originalData
                }
            }
            if (typeoffield == '') {
                if (val != '' && val != undefined) {
                    filteredData = originalData.filter(item => item.work_order_no == val);
                }
                else {
                    filteredData = originalData
                }
            }
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
        else {
            setbasicTitle('Please Select Work Order No')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }

    }
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))

    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/all' })
            .then(async (resp) => {
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols = fetchGrid(summaryViewcols, grid[0].gridcolumns)
                // setsummaryViewcols(oCols);
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols = fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            // console.log(e);
        }
    };




    const onGridState = () => {
        //console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();

            var screenpage = '/all'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/all',
                screentitle: screenpage
            }
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                })
        }
        catch (e) {
            // console.log(e)
        }
    }

    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const detailedviewcols = []
    const columnwithDefs = summaryviewcheck == 1 ? summaryViewcols : detailedviewcols

    const refreshData1 = () => {
        setcheckresetfilter(0);
        setrowData(originalData);
        setRefreshSelected(1);
        // console.log("refreshclickfunc")
    }

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="" style={{ margin: '10px', marginTop: '20px' }}>
                <div style={{ position: "relative" }}>
                    <div className="d-flex flex-row">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    borderRadius: "11px",
                                    height: "48px",
                                    width: "260px",
                                    // padding:"22px",
                                    textAlign: "center"
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: 9999, // You can set the desired z-index value here
                                }),
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                        {checkinput == 1 ?
                            <div
                                className="d-flex  ml-40p mr-10p"
                                style={{ position: "relative" }}
                            >
                                <input
                                    style={{
                                        border: "1px solid #cccccc",
                                        borderRadius: "11px",
                                        height: "48px",
                                        outline: "none",
                                        paddingLeft: "6px",
                                    }}
                                    onChange={handleInputfilter}
                                    value={selectedval.inputval}
                                />
                                {checkresetfilter != 1 ?
                                    <img
                                        src={SearchIcon}
                                        className="search_icon"
                                        onClick={filterData}
                                    /> : <img
                                        src={RefreshIcon}
                                        className="search_icon"
                                        onClick={refreshData}
                                    />}
                            </div>
                            : ''}
                        {checkForDateFilter == 1 ?
                            <div className="ml-40p">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} onRefreshSelected={refreshSelected} />
                                {checkresetfilter != 1 ?
                                    <img
                                        src={SearchIcon}
                                        className="search_icon_for_dates pointer"
                                        onClick={filterDatafordates}
                                    />
                                    : <img
                                        src={RefreshIcon}
                                        className="search_icon_for_dates pointer"
                                        onClick={refreshData1}
                                    />}
                            </div>
                            : ""}
                    </div>
                    <div style={{ position: "absolute", right: "-15px", top: "-25px" }}>
                        <div className='btn_hover' style={{ height: "35px", marginTop: "32px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding: "5px" }} onClick={onClickSaveGridState}>
                            <i className="icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                        </div>
                    </div>
                </div>
            </div>
            <div className="" style={{ margin: '10px', marginTop: '12px', width: "100%", height: "478px" }}>
                <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                    <AgGridReact
                        ref={gridApi}
                        columnDefs={columnwithDefs}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            editable: false,
                            resizable: true,
                            menuTabs: ['filterMenuTab']
                            // rowHeight: 80,
                        }}
                        // paddingTop={10}
                        // rowHeight={70}
                        //  headerHeight = {70}
                        // headerHeight={50}
                        rowData={rowData}
                        suppressRowClickSelection={true}
                        detailCellRendererParams={state.detailCellRendererParams}
                        frameworkComponents={{ ConsignmentTrucksLegs: ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                        enableCellChangeFlash={true}
                        suppressCellFlash={true}
                        onGridReady={onGridReady}
                        onGridState={onGridState}
                        enableRangeSelection={true}
                        paginationPageSize={50}
                        rowSelection={'multiple'}
                        masterDetail={true}
                        pagination={true}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: "columns",
                                    labelDefault: "Columns",
                                    labelKey: "columns",
                                    iconKey: "columns",
                                    toolPanel: "agColumnsToolPanel"
                                },
                                {
                                    id: "filters",
                                    labelDefault: "Filters",
                                    labelKey: "filters",
                                    iconKey: "filter",
                                    toolPanel: "agFiltersToolPanel"
                                }
                            ]
                        }}
                        statusBar={{
                            statusPanels: [
                                { statusPanel: "agFilteredRowCountComponent" },
                                { statusPanel: "agSelectedRowCountComponent" },
                                { statusPanel: "agAggregationComponent" }
                            ]
                        }}
                        gridOptions={{
                            icons: {
                                // You can customize other icons as well
                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                last: `<img src="${customLastIcon}" alt="Last" />`,
                            },
                        }}
                        // ref={gridRef}
                        getRowStyle={(params) => ({ textAlign: 'left' })}
                        context={{ onClickShowShipmentLegs, updateContainerDetails }}
                    />

                </div>
            </div>
            {stateForMap.sliderRouteTranslate != '' ?
                <div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            {(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{ marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            <Mapcard2
                                mapinfo={stateForMap.mapinfo}
                                mapHeight={"100vh"}
                                mapFor={"truck"}
                                mapdata={stateForMap.mapdata}
                                geoLocShipmentsMap={state.geoLocShipmentsMap}
                            />
                        </div>
                    </div>

                </div>
                : ""
            }

            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>



    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
        if (arr.length > 0) {
            const comparison = (a, b) => {
                const dateA = new Date(a.modified_date);
                const dateB = new Date(b.modified_date);
                if (descending) {
                    return dateB - dateA; // Ascending order
                } else {
                    return dateA - dateB; // Descending order
                }
            };
            arr.sort(comparison);
            return arr;
        }
    } else {
        return arr;
    }
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
function sortByStatus(arr) {
    return arr.slice().sort((firstItem, secondItem) => firstItem.status - secondItem.status);
}
export default AllComponent