
import React from 'react';
 
const ChecklistItems = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.onClickUpdateItem(props);
    };
    const handleInvClick = (e) => {
        e.stopPropagation();
        props.context.onClickInvoiceItem(props);
    };
    // console.log("props ", props.data)
    return (
        <div>
            {(props.data.item_status === 1)?
            <button type="button" 
                onClick={handleClick}
                target="_blank"
                className={"btn btn-warning nopadding font12"} 
                title="Checklist">
                <i className="icofont icofont-eye f16"></i>&nbsp; Checklist
            </button>
      
            :
            ((props.data.item_status === 2)?
            <span>
                <a href={props.data.checklist_document} 
                    target="_blank"
                    className={"btn btn-info nopadding font12"} 
                    title="View Document">
                    <i className="icofont icofont-eye f16"></i>&nbsp; View Document
                </a>
            <button type="button" 
                onClick={handleInvClick}
                target="_blank"
                className={"btn btn-warning nopadding font12"} 
                title="Upload Invoice" style={{marginLeft:"5px"}}>
                  <i className="icofont icofont-upload f16"></i>&nbsp; Upload Invoice
            </button>
            </span>
            :
            <a href={props.data.invoice_document} 
                target="_blank"
                className={"btn btn-warning nopadding font12"} 
                title="View Invoice">
                  <i className="icofont icofont-eye f16"></i>&nbsp; View Invoice
            </a>
            )
          }
            
        </div>
    );
};

export default ChecklistItems;
