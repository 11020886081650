
import React, { Component } from 'react';
 
const EditPartner = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        console.log(props,'77')
        props.context.componentParent.onClickEditItem(props);
    };
// console.log("props ", props.data)
    return (
            <>
            {/* <button type="button"
              className={"btn activet font12 mb-5p"} 
              title="Update" 
              onClick={handleClick}>
                   Edit
            </button> */}
            {/* <i class="icofont icofont-edit" >Edit</i> */}
            <img src={require("../../assets/images/Update.png")} onClick={handleClick} style={{height: "15px", marginLeft: "30px"}}/>
            </>
    );
};

export default EditPartner;
