import React, {Component} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import {Navigate} from 'react-router-dom';
export default class Grid extends Component {
	constructor(props)
	{
		super(props);
		this.state={
			defaultColDef :{
				flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
				sortable:true,
				menuTabs: ['filterMenuTab']
			},
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
			isNav:0
		}
	}
	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		const updateData = (data) => {
		  this.setState({ rowData: data });
		};

		fetch('https://www.ag-grid.com/example-assets/master-detail-data.json')
		  .then((resp) => resp.json())
		  .then((data) => updateData(data));
	  };

	  onFirstDataRendered = (params) => {
		// arbitrarily expand a row for presentational purposes
		setTimeout(function () {
		  params.api.getDisplayedRowAtIndex(1).setExpanded(true);
		}, 0);
	  };
	  onClickNavPage(){
		this.setState({
			isNav:1
		});
	  }
    render(){
		if(this.state.isNav == 1)
		{
			return <Navigate  to={"/test"} push={true} />
		}
		var columnDefs = [
			// group cell renderer needed for expand / collapse icons
			{ field: 'name', cellRenderer: 'agGroupCellRenderer' },
			{ field: 'account' },
			{ field: 'calls' },
			{ field: 'minutes', valueFormatter: "x.toLocaleString() + 'm'" },
		  ]
        return (
            <div className="container-fluid">
				 <h1 className="h3 mb-2 text-gray-800">Ag-Grid Demo</h1>
				 <div className="card shadow mb-4">
                        
                        <div className="card-body">
                          <button type="button" className="btn btn-info" onClick={this.onClickNavPage.bind(this)}>Page</button>
							<div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
							  <AgGridReact 
								rowData={this.state.rowData} 
								columnDefs={columnDefs}
								defaultColDef ={this.state.defaultColDef }
								enableRangeSelection={true}
								enableCharts={true}
								detailCellRendererParams={this.state.detailCellRendererParams}
								masterDetail={true}
								onGridReady={this.onGridReady}
								onFirstDataRendered={this.onFirstDataRendered.bind(this)}
								sideBar={this.state.sideBar}
							  />
							</div>  
                        </div>
                    </div>

			</div>
        )
    }
}