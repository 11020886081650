import React, { Component } from "react";
import { Link } from "react-router-dom";
// import ProcurementIcon from "../../assets/icons/procurement.png";
import BookingIcon from "../../assets/icons/booking.png";
// import DashboardIcon from "../../assets/icons/dashboard.png";
// import EximIcon from "../../assets/icons/exim.png";
import BillingIcon from "../../assets/icons/billing.png";
// import AutometricsEIcon from "../../assets/icons/left-align.png"
import $ from "jquery";
import "./Sidebar.css";
import MyContext from '../sidebar/context';



export default class Sidebar extends Component {
    static contextType = MyContext;
    constructor(props) {
        super(props);

        this.state = {
            toggleIcon: "fa-angle-right",
            sibebarToggler: "on-close-sidebar",
            tooltipVisible: true
            //   logoOnSidebarOpen: "show-n"
        };
    }

    handleSidebar = () => {
        //for left it is open and here we close it and make it right
        $("#sort-filter").addClass("show-n").removeClass("show-m").removeClass("card")
        $("#filter-card").addClass("show-n").removeClass("show-m").removeClass("card")
        var element = document.evaluate('//*[@id="content"]/div', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

        // Check if the element was found and then add the "overlay" class


        //Close
        if (this.state.toggleIcon === "fa-angle-left") {
            this.setState({
                toggleIcon: "fa-angle-right",
                sibebarToggler: "on-close-sidebar",
            }, () => {
                // this.setState({
                //     logoOnSidebarOpen: "show-n"
                // })
                // $("#sort-filter").addClass("sort-filter-slider-close").removeClass("sort-filter-slider-open")
                // $('//*[@id="content"]/div').removeClass("overlay").removeClass("blur-main-card")



                if (element) {
                    // element.classList.remove('overlay');
                    // element.classList.remove('blur-main-card');
                }

                $("#content-wrapper").removeClass("overlay")


                $("#navIcon").removeClass("d-none");
                $("#sidebar").toggleClass("active");
                $("span.blockName").addClass("d-none")
                $("i.fi").addClass("flaticon-position")
                $(".image-icons positionIcon").addClass("positionIcon")
                $("#dashboardImage").addClass("dashboard-img").css('left', '-13px')
                $("#collapseTwo a").toggleClass("dropdown-options")
                $("#collapseThree a").toggleClass("dropdown-options");
                $("#collapseSeven a").toggleClass("dropdown-options");

                $("#collapseFour a").toggleClass("dropdown-options");
                $("#collapseFive a").toggleClass("dropdown-options");
                $("#collapseSix a").toggleClass("dropdown-options");
                $("#collapseEight a").toggleClass("dropdown-options");
                if (!$("#logo-card-navbar").hasClass("logo-on-close")) {
                    $("#logo-card-navbar").addClass("logo-on-close")
                }

                if ($("#logo-card-navbar").hasClass("logo-on-open")) {
                    $("#logo-card-navbar").removeClass("logo-on-open")
                }

                // $("#logo-card-navbar").css('width','191px').css('padding-left','6px')
            })
        } else {
            this.setState({
                toggleIcon: "fa-angle-left",
                sibebarToggler: "on-open-sidebar",
            }, () => {
                // this.setState({
                //     logoOnSidebarOpen: "show-m"
                // })
                // $("#sort-filter").removeClass("sort-filter-slider-close").addClass("sort-filter-slider-open")
                // $('//*[@id="content"]/div').addClass("overlay").addClass("blur-main-card")
                if (element) {
                    // element.classList.add('overlay');
                    // element.classList.add('blur-main-card');
                }
                $("#content-wrapper").addClass("overlay")
                $("#navIcon").addClass("d-none");
                $("#sidebar").toggleClass("active");
                $("span.blockName").removeClass("d-none")
                $("i.fi").removeClass("flaticon-position")
                $(".image-icons positionIcon").removeClass("positionIcon")
                $("#dashboardImage").removeClass("dashboard-img").css('left', '-6px').css('position', 'relative')
                $("#collapseTwo a").toggleClass("dropdown-options");
                $("#collapseThree a").toggleClass("dropdown-options");
                $("#collapseSeven a").toggleClass("dropdown-options");
                $("#collapseFour a").toggleClass("dropdown-options");
                $("#collapseFive a").toggleClass("dropdown-options");
                $("#collapseSix a").toggleClass("dropdown-options");
                $("#collapseEight a").toggleClass("dropdown-options");
                // $("#logo-card-navbar").css('width','270px').css('padding-left','24px')

                if ($("#logo-card-navbar").hasClass("logo-on-close")) {
                    $("#logo-card-navbar").removeClass("logo-on-close")
                }

                if (!$("#logo-card-navbar").hasClass("logo-on-open")) {
                    $("#logo-card-navbar").addClass("logo-on-open")
                }
            })
        }
    };

    handleChevronFor2 = () => {
        if ($("#collapseTwo").hasClass("collapse")) {
            $("#billingBlock").addClass("fi-br-angle-small-down")
            $("#billingBlock").removeClass("fi-br-angle-small-up")
        } else {
            $("#billingBlock").removeClass("fi-br-angle-small-down")
            $("#billingBlock").addClass("fi-br-angle-small-up")
            document.querySelector("#content > div").classList.addClass("overlay")
        }
    }

    handleChevronFor3 = () => {
        if ($("#collapseThree").hasClass("collapse")) {
            $("#bookingBlock").addClass("fi-br-angle-small-down")
            $("#bookingBlock").removeClass("fi-br-angle-small-up")
        } else {
            $("#bookingBlock").removeClass("fi-br-angle-small-down")
            $("#bookingBlock").addClass("fi-br-angle-small-up")
        }
    }

    handleChevronFor4 = () => {
        if ($("#collapseFour").hasClass("collapse")) {
            $("#procurementBlock").addClass("fi-br-angle-small-down")
            $("#procurementBlock").removeClass("fi-br-angle-small-up")
        } else {
            $("#procurementBlock").removeClass("fi-br-angle-small-down")
            $("#procurementBlock").addClass("fi-br-angle-small-up")
        }
    }

    handleChevronFor5 = () => {
        if ($("#collapseFive").hasClass("collapse")) {
            $("#eximBlock").addClass("fi-br-angle-small-down")
            $("#eximBlock").removeClass("fi-br-angle-small-up")
        } else {
            $("#eximBlock").removeClass("fi-br-angle-small-down")
            $("#eximBlock").addClass("fi-br-angle-small-up")
        }
    }

    handleChevronFor6 = () => {
        if ($("#collapseSix").hasClass("collapse")) {
            $("#eximBlock").addClass("fi-br-angle-small-down")
            $("#eximBlock").removeClass("fi-br-angle-small-up")
        } else {
            $("#eximBlock").removeClass("fi-br-angle-small-down")
            $("#eximBlock").addClass("fi-br-angle-small-up")
        }
    }
    handleChevronFor7 = () => {
        if ($("#collapseSeven").hasClass("collapse")) {
            $("#bookingBlock").addClass("fi-br-angle-small-down")
            $("#bookingBlock").removeClass("fi-br-angle-small-up")
        } else {
            $("#bookingBlock").removeClass("fi-br-angle-small-down")
            $("#bookingBlock").addClass("fi-br-angle-small-up")
        }
    }
    handleChevronFor8 = () => {
        if ($("#collapseEight").hasClass("collapse")) {
            $("#bookingBlock").addClass("fi-br-angle-small-down")
            $("#bookingBlock").removeClass("fi-br-angle-small-up")
        } else {
            $("#bookingBlock").removeClass("fi-br-angle-small-down")
            $("#bookingBlock").addClass("fi-br-angle-small-up")
        }
    }
    

    hideTooltip = () => {
        this.setState({ tooltipVisible: false })
    }


    showDropdown = (liNum) => {
        // if(!$("#sidebar").hasClass("active")){
        //     $("#sidebar").removeClass("active")
        // }
        // $("#sidebar").removeClass("active")
        // this.handleSidebar();

        if (liNum === 2) {
            $("#collapseTwo").toggleClass("collapse")
            if (!$("#collapseTwo").hasClass("collapse")) {
                $("#collapseThree").addClass("collapse")
                $("#collapseFour").addClass("collapse")
                $("#collapseFive").addClass("collapse")
                $("#collapseSix").addClass("collapse")
                $("#collapseSeven").addClass("collapse")
                $("#collapseEight").addClass("collapse")
            }
        }

        if (liNum === 3) {
            $("#collapseThree").toggleClass("collapse")
            if (!$("#collapseThree").hasClass("collapse")) {
                $("#collapseTwo").addClass("collapse")
                $("#collapseFour").addClass("collapse")
                $("#collapseFive").addClass("collapse")
                $("#collapseSix").addClass("collapse")
                $("#collapseSeven").addClass("collapse")
                $("#collapseEight").addClass("collapse")
            }
        }
        if (liNum === 4) {
            $("#collapseFour").toggleClass("collapse")
            if (!$("#collapseFour").hasClass("collapse")) {
                $("#collapseTwo").addClass("collapse")
                $("#collapseThree").addClass("collapse")
                $("#collapseFive").addClass("collapse")
                $("#collapseSix").addClass("collapse")
                $("#collapseSeven").addClass("collapse")
                $("#collapseEight").addClass("collapse")
            }
        }

        if (liNum === 5) {
            $("#collapseFive").toggleClass("collapse")
            if (!$("#collapseFive").hasClass("collapse")) {
                $("#collapseTwo").addClass("collapse")
                $("#collapseFour").addClass("collapse")
                $("#collapseThree").addClass("collapse")
                $("#collapseSix").addClass("collapse")
                $("#collapseSeven").addClass("collapse")
                $("#collapseEight").addClass("collapse")
            }
        }

        if (liNum === 6) {
            $("#collapseSix").toggleClass("collapse")
            if (!$("#collapseSix").hasClass("collapse")) {
                $("#collapseTwo").addClass("collapse")
                $("#collapseFour").addClass("collapse")
                $("#collapseThree").addClass("collapse")
                $("#collapseFive").addClass("collapse")
                $("#collapseSeven").addClass("collapse")
                $("#collapseEight").addClass("collapse")
            }
        }
        if (liNum === 7) {
            $("#collapseSeven").toggleClass("collapse")
            if (!$("#collapseSeven").hasClass("collapse")) {
                $("#collapseTwo").addClass("collapse")
                $("#collapseFour").addClass("collapse")
                $("#collapseThree").addClass("collapse")
                $("#collapseFive").addClass("collapse")
                $("#collapseSix").addClass("collapse")
                $("#collapseEight").addClass("collapse")
            }
        }
        if (liNum === 8) {
            $("#collapseEight").toggleClass("collapse")
            if (!$("#collapseEight").hasClass("collapse")) {
                $("#collapseTwo").addClass("collapse")
                $("#collapseFour").addClass("collapse")
                $("#collapseThree").addClass("collapse")
                $("#collapseFive").addClass("collapse")
                $("#collapseSix").addClass("collapse")
            }
        }

        this.handleChevronFor2()
        this.handleChevronFor3()
        this.handleChevronFor4()
        this.handleChevronFor5()
        this.handleChevronFor6()
        this.handleChevronFor7()
        this.handleChevronFor8()
    }

    hamburgerButton = () => {
        const isZoomedIn = window.devicePixelRatio;
        if (isZoomedIn > 1.8) {
            $(".sidebarMain").toggleClass("d-none")
        }

    }

    updateZoomLevel = () => {
        const isZoomedIn = window.devicePixelRatio;
        if (isZoomedIn > 1.8) {
            $("#hamburgerMenu").removeClass("d-none")
            $(".main-logo").css('font-size', '25px');
            $(".logo").addClass('max-logo')
            $(".sidebarMain").addClass("d-none")
        } else {
            $("#hamburgerMenu").addClass("d-none")
            $(".main-logo").css('font-size', '29px');
            $(".logo").removeClass('max-logo')

            if ($(".sidebarMain").hasClass("d-none")) {
                $(".sidebarMain").removeClass("d-none")
            }
        }
    }



    componentDidMount() {
        // Add an event listener to detect changes in device pixel ratio
        window.addEventListener('resize', this.updateZoomLevel);
    }

    componentWillUnmount() {
        // Remove the event listener when the component is unmounted
        window.removeEventListener('resize', this.updateZoomLevel);
    }

    onChangeActivity = () =>{
        this.context.setCurrentActivity("sidebar")
    }




    render() {
        const tooltipVisible = this.state;
        var dashboardUrl
        if(localStorage.getItem('role')=='tpt'){
            dashboardUrl = '/booking/transporter'
        }
        else if(localStorage.getItem('role')=='cha'){
            dashboardUrl = '/booking/cha'
        }
        else if(localStorage.getItem('role')=='plant'){
            dashboardUrl = '/booking/plant'
        }
        else{
            dashboardUrl = '/exportoperationscontroltower'
        }
        return (
            <>
                <div className="sidebarMain" style={{ minHeight: "100.5vh", background: "#0664AE", zIndex: "11" }}>
                    {/* <div className={`${this.state.sibebarToggler}`} onClick={this.handleSidebar} style={{cursor: "pointer"}}>
                <i className={`fas fa-2x ${this.state.toggleIcon}`} style={{color: "blue"}} id="sidebarToggleIcon"></i>
            </div>   */}
                    <div className="active mt-3">
                        <a href={dashboardUrl} className="txt-dec-none clr333">
                            {/* <img src={DashboardIcon} alt="" width="25px" id="dashboardImage" className="dashboard-img"/> */}
                            <div className="info-icon" onClick={this.hideTooltip}>
                                <i className="icofont icofont-home image-home-icon blockname ml-3" style={{ color: "White" }}></i>
                                {tooltipVisible && <div className="info-tooltip text-center" style={{ color: "black", border: "3px solid #809fff", top: "25px" }}>
                                    <p>Home</p>
                                </div>}
                            </div>
                        </a>
                    </div>
                    <nav id="sidebar" className="active" style={{ position: 'relative', top: '48px' }}>
                        <ul className="list-unstyled components mb-5" style={{ position: "relative", top: "-10px" }}>

                            {(localStorage.getItem("user_type") === "booking" || localStorage.getItem("user_type") === "admin") ?

                                <li className="nav-item">
                                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseThree"
                                        aria-expanded="true" aria-controls="collapseThree" onClick={this.showDropdown.bind(this, 3)}>
                                        {/* <i className="fas fa-fw fa-cog"></i> */}
                                        <div className="info-icon">
                                            <img src={require("../../assets/images/activities.png")} alt="" width="25px" className="image-icons positionIcon" />
                                            <div className="info-tooltip" style={{ color: "black", border: "3px solid #809fff" }}>
                                                <p>Activities</p>
                                            </div>
                                            <span className='blockName ml-3 d-none'>Activities</span>
                                            <i id="bookingBlock" className="fi fi-br-angle-small-down float-right mt-1 flaticon-position"></i>
                                        </div>
                                    </a>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                        <div className="collapse-inner">
                                            <Link className="collapse-item" data-parent="#menu1sub1" onClick={this.onChangeActivity} to={"/booking/plant"}>Plant Activities</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" onClick={this.onChangeActivity} to={"/booking/cha"}>CHA Activities</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" onClick={this.onChangeActivity} to={"/booking/iBstuffingdata"}>IB Activities</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" onClick={this.onChangeActivity} to={"/booking/transporter"}>Transporter Activities</Link>
                                        </div>
                                    </div>
                                </li>
                                : ""}
                            {((localStorage.getItem("role") !== "cha" && localStorage.getItem("role") !== "tpt" && localStorage.getItem('role')!='plant')) ?

                                <li className="nav-item" >
                                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                                        aria-expanded="true" aria-controls="collapseTwo" onClick={this.showDropdown.bind(this, 2)}>
                                        {/* <i className="fas fa-fw fa-cog"></i> */}
                                        {/* <i className="fi fi-rs-receipt colorWhite"></i> */}
                                        <div className="info-icon">
                                            <img src={BillingIcon} alt="" width="25px" className="image-icons positionIcon" />
                                            <div className="info-tooltip" style={{ color: "black", border: "3px solid #809fff" }}>
                                                <p>Master Data</p>
                                            </div>
                                            <span className='blockName ml-3 d-none'>Master Data</span>
                                            <i id="billingBlock" className="fi fi-br-angle-small-down float-right mt-1 flaticon-position"></i>
                                        </div>

                                    </a>

                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                        <div className="collapse-inner">
                                            <Link className="collapse-item margin-set" data-parent="#menu1sub3" onClick={this.onChangeActivity} to={"/generalInfo"}>Master Data</Link>
                                            {/* <Link className="collapse-item" data-parent="#menu1sub3" to={"/trucksgps"}>Trucks GPS</Link>	 */}
                                        </div>
                                    </div>
                                </li> : ""}
                            
                            {/* {(localStorage.getItem("user_type") === "exim" || localStorage.getItem("user_type") == "admin" )?
                    <li className="nav-item" >
                        <a className="nav-link collapsed" id="menu1" href="#" data-toggle="collapse" data-target="#collapseFive"
                            aria-expanded="true" aria-controls="collapseFive" onClick={this.showDropdown.bind(this, 5)}> */}
                            {/* <img src={EximIcon} alt="" width="25px" className='iconStyle image-icons positionIcon'/> */}
                            {/* <span className='blockName ml-3 d-none'>EX&#47;IM</span> */}
                            {/* <i id="eximBlock" className="fi fi-br-angle-small-down float-right mt-1 flaticon-position"></i> */}
                            {/* </a> */}
                            {/* id and aria-controls provide relationship */}
                            {/* <div id="collapseFive" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-drk collapse-inner"> */}
                            {/* <a href="#menu1sub1" className="collapse-item dropdown-options" data-toggle="collapse" aria-expanded="false">Bookings</a> */}
                            {/* <div className="collapse" id="menu1sub1" style={{paddingLeft: "20px"}}>
                                    <Link className="collapse-item dropdown-options" data-parent="#menu1sub1" to={"/bookingstracking"}>Active Bookings</Link>
                                    <Link className="collapse-item dropdown-options" data-parent="#menu1sub1" to={"/ffseaimports"}>BAL Updates</Link>
                                    <Link className="collapse-item dropdown-options" data-parent="#menu1sub1" to={"/chaseaimports"}>CHA Updates</Link>
                                    <Link className="collapse-item dropdown-options" data-parent="#menu1sub1" to={"/transportationseaimports"}>Transporter Updates</Link>
                                </div> */}
                            {/* <Link className="collapse-item dropdown-options" to={"/create-shipment"}>Create Shipment</Link>	 */}

                            {/* </div>
                    </div>
                    </li>:""
                    } */}
                            {/* Container Tracking */}
                            {(localStorage.getItem('role')!='plant')?<li className="nav-item" >
                                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSix"
                                    aria-expanded="true" aria-controls="collapseSix" onClick={this.showDropdown.bind(this, 6)}>
                                    {/* <i className="fas fa-fw fa-cog"></i> */}
                                    {/* <i className="fi fi-rs-receipt colorWhite"></i> */}
                                    <div className="info-icon">
                                        <img src={require("../../assets/images/CHAwhiteicon.png")} alt="" width="25px" className="image-icons positionIcon" />
                                        <div className="info-tooltip" style={{ color: "black", border: "3px solid #809fff", height: "48px" }}>
                                            <p>Container Tracking</p>
                                        </div>
                                        <span className='blockName ml-3 d-none'>Container Tracking</span>
                                        <i id="billingBlock" className="fi fi-br-angle-small-down float-right mt-1 flaticon-position"></i>
                                    </div>
                                </a>

                                <div id="collapseSix" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="collapse-inner">
                                        {/* <Link className="collapse-item" data-parent="#menu1sub3" to={"/generalInfo"}>Master Data</Link>	 */}
                                        <Link className="collapse-item margin-set" data-parent="#menu1sub4" onClick={this.onChangeActivity} to={"/container-tracking"}>Container Tracking</Link>
                                    </div>
                                </div>

                            </li>:''}
                          
                            <li className="nav-item">
                                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSeven"
                                        aria-expanded="true" aria-controls="collapseSeven" onClick={this.showDropdown.bind(this, 7)}>
                                        {/* <i className="fas fa-fw fa-cog"></i> */}
                                        <div className="info-icon">
                                            <img src={require("../../assets/images/reports.png")} alt="" width="25px" className="image-icons positionIcon" />
                                            <div className="info-tooltip" style={{ color: "black", border: "3px solid #809fff" }}>
                                                <p>Reports</p>
                                            </div>
                                            <span className='blockName ml-3 d-none'>Reports</span>
                                            <i id="bookingBlock" className="fi fi-br-angle-small-down float-right mt-1 flaticon-position"></i>
                                        </div>
                                    </a>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                        <div className="collapse-inner">
                                            <Link className="collapse-item" data-parent="#menu1sub1" onClick={this.onChangeActivity} to={"/reportscreen"}>Reports</Link>
                                            {/* <Link className="collapse-item" data-parent="#menu1sub1" to={"/shipmentexecutionreport"}>Shipment Execution Report</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" to={"/truckavailabilityreport"}>Truck Availability Report</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" to={"/trucksgps"}>Trucks GPS</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" to={"/daywisetracking"}>Day Wise Tracking</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" to={"/bufferreport"}>Buffer</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" to={"/transportertat"}>Transporter TAT</Link>
                                            <Link className="collapse-item" data-parent="#menu1sub1" to={"/planttat"}>Plant TAT</Link> */}
                                        </div>
                                    </div>
                                </li>
                                {(localStorage.getItem('role')=='admin')?<li className="nav-item" >
                                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseEight"
                                    aria-expanded="true" aria-controls="collapseEight" onClick={this.showDropdown.bind(this, 8)}>
                                    {/* <i className="fas fa-fw fa-cog"></i> */}
                                    {/* <i className="fi fi-rs-receipt colorWhite"></i> */}
                                    <div className="info-icon">
                                        <img src={require("../../assets/images/usersIcon.png")} alt="" width="25px" className="image-icons positionIcon" />
                                        <div className="info-tooltip" style={{ color: "black", border: "3px solid #809fff", height: "48px" }}>
                                            <p>User Managment</p>
                                        </div>
                                        <span className='blockName ml-3 d-none'>User Management</span>
                                        <i id="billingBlock" className="fi fi-br-angle-small-down float-right mt-1 flaticon-position"></i>
                                    </div>
                                </a>

                                <div id="collapseEight" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="collapse-inner">
                                        {/* <Link className="collapse-item" data-parent="#menu1sub3" to={"/generalInfo"}>Master Data</Link>	 */}
                                        <Link className="collapse-item margin-set" data-parent="#menu1sub4" onClick={this.onChangeActivity} to={"/users"}>User Managment</Link>
                                    </div>
                                </div>

                            </li>:''}

                        </ul>
                    </nav>

                </div>
            </>
        );
    }
}
