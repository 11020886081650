module.exports = {
    baseURL: "http://localhost:5004/billing/",
    BOOKING_PAGE_TITLE:"Bookings",
    GRID_PO_NO:"Booking No",
    GRID_BATCH_NO:"Batch No",
    GRID_TRANSPORTER_CODE:"Transporter Code",
    GRID_TRANSPORTER_NAME:"Transporter Name",
    GRID_VENDOR_CODE:"Vendor Code",
    GRID_VENDOR_NAME:"Vendor Name",
    GRID_DELIVERED_DATE:"Deliver Date",
    GRID_DELIVER_ADDRESS:"Deliver Address",
    GRID_PO_ITEMS:"View Items",
    GRID_PO_DOC:"View PO",
    GRID_COUNTER_TOTAL:"Total Order Quantity",
    GRID_COUNTER_TOTAL_PRODUCTION:"Total Production",
    GRID_COUNTER_APPROVED_QTY:"Pending Invoice",
    GRID_COUNTER_INVOICE_QTY:"Invoice Quantity",
    GRID_COUNTER_BALANCE_QTY:"Balance Quantity",
    GRID_COUNTER_PENDING_VENDOR:"Pending with Vendor",
    GRID_COUNTER_PENDING_QA:"Pending with QA",
    GRID_COUNTER_INV:"Invoiced",
    GRID_COUNTER_COMPLETED:"Completed",
    GRID_COUNTER_INV_QTY:"Invoice Quantity",
    BOOKING_CREATE_PAGE_TITLE:"Create Booking",
    GRID_PO_ITEM_NO:"Item No",
    GRID_PO_ITEM_DESC:"Item Description",
    GRID_PO_ITEM_QTY:"Quantity",
    GRID_PO_ITEM_UM:"Item UM",
    GRID_PO_ITEM_HSN:"Item HSN",
    GRID_PO_ITEM_PRICE:"Item Price",
    GRID_PO_PRODUCE_QTY:"Produced Quantity",
    GRID_PO_APPROVED_QTY:"Approved Quantity",
    GRID_PO_BAL_QTY:"Balance Quantity",
    GRID_PO_BATCH_QTY:"Batch Damage Quantity",
    GRID_PO_TOTAL_QTY:"Total Quantity",
    GRID_PO_PRODUCE_DATE:"Produce Date",
    GRID_COUNTER_PRODUCED:"Produced Quantity",
    GRID_COUNTER_HIL_APPROVED:"HIL Approved",
    GRID_COUNTER_HIL_REJECTED:"HIL Rejected",
    GRID_COUNTER_REMAINING:"Remaining",
    GRID_PRODUCE_DATE:"Produced Date",
    GRID_PRODUCE_STATUS:"Status",
    GRID_PO_OPEN_STOCK:"Opening Stock"
}