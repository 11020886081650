import React,{useState,useEffect,useRef} from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import Select from "react-select";
import UpdatetrucksBtn from "./updateTrucksBtn";
import CountryFlagComponent from "./countryFlag";
import ViewDOdetailsComponent from "./viewDOdetails";
import download from 'js-file-download';

import redirectURL from "../redirectURL";

const TransallComponent = (props)=>{
    const [rowData,setrowdata] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    const [showAddForm, setshowAddForm] = useState('')
    var [checkfortruck,setcheckfortruck] = useState(0)
    var selectconfirmn =[
        {label:'Yes',value:'yes'},{label:'No',value:'no'}
    ]
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [trucksList,settrucksList] = useState([])
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        trucks:'',
        wrno:'',
        confirm:''
    })
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [workordernoList, setworkOrderList] = useState([])
    var [selected_row,setselected_row] = useState({})
    useEffect(() => {
        if (gridRef.current) {
            setGridApi(gridRef.current.api);
            setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);

   useEffect(()=>{
    var records = props.data
    setrowdata(records)
    setoriginalData(records)
         var work_order_no = []
         if(records!='' && records!=undefined){
            records.map((e) => {
                work_order_no.push({ label: e.work_order_no, value: e.work_order_no })
            })
         }
        setworkOrderList(work_order_no)
    redirectURL.post('/consignments/getTrucksData').then((resp)=>{
        var trucksrecord = resp.data 
        var trucks = []
        trucksrecord.map((e) => {
            trucks.push({ label: e.truck_no, value: e.truck_no })
            })
            trucks = removeDuplicates(trucks,'label')
            settrucksList(trucks)
    })
   },[])
   const showdata = (e) => {
    if (e == 'detailed view') {
        setdetailedviewcheck(1)
        setsummaryviewcheck(0)
    }
    else if (e == 'summary view') {
        setdetailedviewcheck(0)
        setsummaryviewcheck(1)
    }
}
const updateBtn=()=>{
    setshowAddForm("slide30")
    setoverly("show-m")
}
const onSelectWON = (e) => {
    console.log(e)
    setmanualUploadDetails(prev => ({
        ...prev,
        wrno:e
    }))
}
const onClickHideManualUpload = () => {
    setshowAddForm('')
    setoverly('show-n')
    setmanualUploadDetails(prev=>({
        ...prev,
        trucks:{label:'',value:''},
        wrno:{label:'',value:''},
        confirm:{label:'',value:''}
    }))
}
const onsubmitdata =(e)=>{
    e.preventDefault()
    var trucks = manualUploadDetails.trucks 
    var truckList = []
    trucks.map((e)=>{
        truckList.push(e.value)
    })
    console.log(truckList,'truckList')
    // var wrno = manualUploadDetails.wrno.value
    var wrno=selected_row.work_order_no
    console.log(originalData,'originalData')
    var record = originalData.filter(e=>e.work_order_no==wrno)
    console.log(record,'104')
    record = record[0]

    var containerCount = record.container_count 
    console.log(containerCount,'containerCount')
    var params = {
        work_order_no:selected_row.work_order_no,
        trucks:truckList
    }
    if(containerCount==trucks.length){
        redirectURL.post('/consignments/updateTrucksDetails',params).then((resp)=>{
            setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
                setloadshow('show-n')
                setoverly('show-n')
        })
    }   
    else{
        setshow(true)
                setbasicTitle("Please Select Appropriate No Of Trucks")
                setbasicType("danger")
                setloadshow('show-n')
                setoverly('show-n')
    }
    setmanualUploadDetails(prev=>({
        ...prev,
        trucks:{label:'',value:''},
        wrno:{label:'',value:''},
        confirm:{label:'',value:''}
    }))
    setselected_row({})
    setshowAddForm('')
}
const onSelectConfirm = (e)=>{
    if(e.value=='yes'){
        setcheckfortruck(1)
    }
    else{
        setshowAddForm('')
        setoverly('show-n')
        const isConfirmed = window.confirm('Do you want to reject this workorder?');
        if(isConfirmed){
            var params = {
                work_order_no:manualUploadDetails.wrno.value
            }
            console.log(params)
            redirectURL.post('/consignments/returnWorkorder',params).then((resp)=>{
                console.log(resp.data)
            })
        }
        else{

        }
    }
    setmanualUploadDetails(prev=>({
        ...prev,
        confirm:e
    }))
}
const handleTrucksList=(e)=>{
    setmanualUploadDetails(prev=>({
        ...prev,
        trucks:e
    }))


}
const onClickHideAll = () => {
    setloadshow('show-n')
}
var closeAlert = () => {
    setshow(false)
    setloadshow('show-n')
}
const summaryViewcols=[
    {
        headerName: "Work Order No",
        field: "work_order_no",
        width: 140,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Ship To Country",
        field: "ship_to_country",
        width: 130,
        filter: true,
        resizable: true,
        // cellRenderer:CountryFlagComponent
    },
    {
        headerName: "Status",
        field: "status",
        width: 180,
        filter: true,
        resizable: true,
        valueGetter: function (params) {
            if (params.data.status == 1) {
                return 'New'
            }
            else if (params.data.status == 2 || params.data.status == 3) {
                return 'Pending For CHA Vessel Availability'
            }
            else if (params.data.status == 5) {
                return 'Pending For Stuffing Date'
            }
            else if (params.data.status == 6) {
                return 'Pending For Upload DO'
            }
            else if (params.data.status == 7) {
                return 'Pending For IB TPT Allocation'
            }
            else if (params.data.status == 8) {
                return 'Pending To Update Truck'
            }
            else if (params.data.status == 12) {
                return 'Shipping Details Raised'
            }
        }
    },
    {
        headerName: "40ft Containers",
        field: "ft40_containers",
        width: 140,
        filter: true,
        resizable: true,
    },
    {
        headerName: "20ft Containers",
        field: "ft20_containers",
        width: 140,
        filter: true,
        resizable: true,
    },
    {
        headerName: "POD",
        field: "pod",
        width: 180,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Plant",
        field: "plant",
        width: 120,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Stuffing Date",
        field: "stuffing_date",
        width: 150,
        filter: true,
        resizable: true,
        editable: true,
        valueGetter: function (params) {
            try {
                if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                    return getHyphenYYYYMMDD(params.data.stuffing_date);
                }
                else {
                    return "Not Available";
                }
            }
            catch (e) { }
        },
    },
    {
        headerName: "Booking No",
        field: "booking_no",
        width: 160,
        filter: true,
        resizable: true,
    },

    {
        headerName: "CHA",
        field: "cha",
        width: 140,
        filter: true,
        resizable: true,
    },
    {
        headerName: "3rd Party Inspection Req",
        field: "third_party_inspection_req",
        width: 190,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Port Of Loading",
        field: "port_of_loading",
        width: 190,
        filter: true,
        resizable: true,
    },
    {
        headerName: "VIN Type Description",
        field: "vin_type_description",
        width: 160,
        filter: true,
        resizable: true,
    },
    {
        headerName: "VIN Type",
        field: "vin_type",
        width: 160,
        filter: true,
        resizable: true,
    },
    {
        headerName: 'Reporting Time At Parking Plaza',
        field: "reporting_at_parking",
        resizable: true,
        width: 240,
        filter: true,
        editable: false,
        valueGetter: function (params) {
            try {
                if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                    return getHyphenYYYYMMDD(params.data.reporting_at_parking);
                } else {
                    return "";
                }
            } catch (e) {
                // Handle the exception if needed
            }
        },
    },
    {
        headerName: "DO Copy",
        field: "",
        width: 100,
        filter: true,
        resizable: true,
        cellRenderer:ViewDOdetailsComponent
    },

]
const onClickDOdetails=(params)=>{
    console.log('163',params)
    console.log(params.data.attachmentFileName)
    if(params.data.attachmentFileName !=undefined && params.data.attachmentFileName !="")
    {
        var fileparams = {
            attachmentFileName : params.data.attachmentFileName,
            work_order_no:params.data.work_order_no
        }
        redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
        }).then((response)=>{
            const imageURL = response.data;
             window.open(imageURL, '_blank');
        }).catch(function(error){
            console.log(error);
        })
    }
}
const onClickentertruckbtn = (e)=>{
    setselected_row(e.data)
    setshowAddForm("slide30")
    setoverly("show-m")
}
const detailedviewcols=[]
   const columnwithDefs = summaryviewcheck == 1 ? summaryViewcols : detailedviewcols
    return(
        <>
        <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
        {/* <div className="float-right" >
                        <button className='btn_style' onClick={updateBtn} >Update</button>
                     </div> */}
            {/* <div className="" style={{ margin: '10px', marginTop: '25px', width: "100%", height: "478px" }}> */}
            <div id="idgrid" style={{ width: "100%", height: "478px"}} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                                // rowHeight:80,
                            }}
                            paddingTop={10}
                            rowHeight={70}
                            headerHeight = {70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            frameworkComponents={{UpdatetrucksBtn:UpdatetrucksBtn,CountryFlagComponent:CountryFlagComponent,ViewDOdetailsComponent:ViewDOdetailsComponent}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={10}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    {
                                        statusPanel: "agTotalAndFilteredRowCountComponent",
                                        align: "left"
                                    },
                                    {
                                        statusPanel: "agTotalRowCountComponent",
                                        align: "center"
                                    },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            ref={gridRef}
                            context={{onClickentertruckbtn,onClickDOdetails}}
                        />

                    </div>
            {/* </div> */}
                    <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No: </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                             onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                             options={workordernoList}
                                        />
                                    </div> */}
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Confirm</label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                             value={manualUploadDetails.confirm}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                             onChange={(e) => onSelectConfirm(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={selectconfirmn}
                                        />
                                    </div>
                                    {checkfortruck==1?
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Trucks*</label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                             value={manualUploadDetails.trucks}
                                             isMulti="true"
                                             className={"border-radius-0"} 
                                             onChange={(e) => handleTrucksList(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                             options={trucksList}
                                        />
                                    </div> :''}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>
        
               
        
    )
}
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
      const propValue = obj[prop];
      const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
      if (seen.has(propString)) {
        return false;
      }
      seen.add(propString);
      return true;
    });
  }
export default TransallComponent