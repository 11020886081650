import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';

const Datepicker = ({ id, onDatesSelected, onRefreshSelected }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(true);
  
  useEffect(() => {
    if (onRefreshSelected === 1) {
        console.log("refresh active");
        setStartDate(null);
        setEndDate(null);
    }
}, [onRefreshSelected]);

  

    const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    // Log the selected dates in DD-MM-YYYY format
    if (startDate && endDate) {
      console.log(`[${id}] Selected dates: ${startDate.format('DD-MM-YYYY')} to ${endDate.format('DD-MM-YYYY')}`);
      // Pass the selected dates to the parent component or another callback function
      if(onDatesSelected){
        onDatesSelected(startDate, endDate);
        }  }
    

  };

 

  const renderCalendarInfo = () => (
    <div style={{ fontSize: '4px' }}>
      {/* Customize the font size for the selected dates */}
      Selected dates: {startDate && endDate ? `${startDate.format('YYYY-MM-DD')} to ${endDate.format('YYYY-MM-DD')}` : 'None'}
    </div>
  );

  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      onDatesChange={handleDatesChange}
      displayFormat="DD-MM-YYYY"
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      minimumNights={0} // bug.no: 3073 It's accepting same start and end dates.
      isOutsideRange={() => false}
      readOnly={true}
      renderCalendarInfo={renderCalendarInfo}
    />
  );
};

export default Datepicker;
