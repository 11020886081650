
import React, { Component } from 'react';
 
const UploadPODButton = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUploadPOD(props);
    };
// console.log("props ", props.data)
    return (
        <div>
            <a href={"javascript:;"} 
                onClick={handleClick}
              className={"btn btn-danger nopadding font12"} 
              title="Upload POD" >
                  <i className="icofont icofont-eye f16"></i>&nbsp; Upload POD
            </a>
          
        </div>
    );
};

export default UploadPODButton;
