/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
require("highcharts/highcharts-more")(Highcharts);

drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var highchartoptions;
var criticaldata=[]
export default class SplineChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng",
			drilldowndata:[]
		}
	}

	componentDidMount = async () =>{
		
		
	}

	componentWillReceiveProps (newprops){
      
	}
    
    render(){
        const dStyles={
				width:'100%',
				height:'100%'
		}
		var stacking = false
		var highchartoptions = {
            chart: {
                type: 'spline'
            },
            title: {
                align: 'center',
                text: this.props.title
            },
            subtitle: {
                align: 'left',
                text: ''
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                title:{
                    text:this.props.xtitle
                }
            },
            yAxis: {
                title: {
                    text: this.props.ytitle
                }
        
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}'
                    }
                }
            },
        
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
            },
            // colors:[ '#7f84e9', '#f35e82','#FF5370', '#f15c80', '#92A8CD','#A47D7C','#f3d800',  '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
		
            series: this.props.series,
            drilldown: [],
            credits:false
        };
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}