import React, { Component } from "react";
import moment from "moment";
import redirectURL from '../redirectURL';
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
import TrackingPieChart from "./trackingPieChart";
import TrackingBarChart from "./trackingBarChart";

import './TrackingDashboard.module.css'
import TrackingGlobeChart from "./trackingGlobeChart";
import TrackingAmBarChart from "./trackingAmBarChart";
import TrackingAmLineChart from "./trackingAmLineChart";
import TrackingAmPieChart from "./trackingAmPieChart";
export default class TrackingDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            basicTitle: '',
            basicType: "default",
            loadshow: "show-n",
            showFilterBox: false,
            filterCount: 2,
            searchFilterValue: "",
            shipmentMode: { value: "", label: "Select" },
            created_at: "show-m",
            mode_of_shipment: "show-m",
            origin_region: "show-m",
            destination_region: "show-m",
            origin_country: "show-m",
            destination_country: "show-m",
            origin_state: "show-m",
            destination_state: "show-m",
            origin_port_city: "show-m",
            destination_port_city: "show-m",
            dispatch_starting_date_before: "show-m",
            client_teams: "show-m",
            consignee: "show-m",
            allTrackingData: [],
            trackingCount: 0,
            destinationCounter: 0,
            filterOptions: [
                "created_at",
                "mode_of_shipment",
                "origin_region",
                "destination_region",
                "origin_country",
                "destination_country",
                "origin_state",
                "destination_state",
                "origin_port_city",
                "destination_port_city",
                "dispatch_starting_date_before",
                "client_teams",
                "consignee"
            ],
            delayedCounter: 0,
            allTrackingData: [],
            trackingCount: [],
            delayedCounter: [],
            destinationCounter: [],
            // portDwellingData: [],
            colorPalette: ['#012a4aff', ' #013a63ff', '#01497cff', '#014f86ff', '#2a6f97ff', '#2c7da0ff', '#468fafff', '#61a5c2ff', '#89c2d9ff', '#a9d6e5ff'],
            colorPalette1: ['#757bc8', ' #8187dc', '#8e94f2', '#9fa0ff', '#ada7ff', '#bbadff', '#cbb2fe', '#dab6fc', '#ddbdfc', '#e0c3fc']
            // colorPalette: ['#99e2b4', ' #88d4ab', '#78c6a3', '#67b99a', '#56ab91', '#469d89', '#358f80', '#248277', '#14746f', '#036666']
        }


        this.handleShowFilter = this.handleShowFilter.bind(this)
    }

    handleShowFilter() {
        this.setState({
            showFilterBox: !this.state.showFilterBox,
        });
    }

    newFilter = (filterType, selOption) => {
        this.setState({ [filterType]: selOption })
    }

    fetchTrackingData = async () => {
        let email = localStorage.getItem("email");
        let payload = {
            email,
        };
        await redirectURL
            .post("/exim/getTrackingDetailsForUser", payload)
            .then((resp) => {
                if (resp.data.status == 200) {
                    let delayArray = []
                    let allTrackingData = [...resp.data.trackingData]

                    allTrackingData.forEach((each) => {
                        if (each.delay_days > 0 && each.delay_days) {
                            delayArray.push(each.delay_days)
                        }
                    })
                    let destinationTrueEvents = allTrackingData.filter(container => container.events.some(e => (e.tracking_status === 'VAD' && e.actual === true)))
                    let destinationCounter = destinationTrueEvents.filter(container => container.events.some(e => (e.tracking_status === 'CGO' && e.actual === false))).length

                    let topCarriersData = this.getTopCarriersData(allTrackingData)
                    let carrierDelayData = this.getCarrierDelayData(allTrackingData)
                    let [getAvgGateOutDelay, portDwellingData] = this.getAvgGateOutDelay(allTrackingData)
                    let containerAddedPerMonth = this.getContainerAddedPerMonth(allTrackingData)
                    let topOriginCountriesData = this.getTopCountriesData(allTrackingData, 'origin_country')
                    let topDestinationCountriesData = this.getTopCountriesData(allTrackingData, 'destination_country')

                    this.setState({
                        allTrackingData: resp.data.trackingData,
                        trackingCount: allTrackingData.length,
                        delayedCounter: delayArray.length,
                        destinationCounter: destinationCounter,
                        topCarriersData: topCarriersData,
                        carrierDelayPerCarrier: carrierDelayData[0],
                        carrierOnTimePercentage: carrierDelayData[1],
                        carrierDelayedPercentage: carrierDelayData[2],
                        getAvgGateOutDelay: getAvgGateOutDelay,
                        containerAddedPerMonth: containerAddedPerMonth,
                        topOriginCountriesData: topOriginCountriesData,
                        topDestinationCountriesData: topDestinationCountriesData,
                        portDwellingData: portDwellingData
                    }, () => {
                        this.setState({
                            loadshow: "show-n",
                        })
                    })

                }
                else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: `Something went wrong`,
                        loadshow: 'show-n'
                    })
                }
            });
    }

    getTopCarriersData = (trackingData) => {
        let topCarriersData = trackingData.reduce((acc, container) => {
            let name = container.carrier_type;
            if (!acc.hasOwnProperty(name)) {
                acc[name] = 0;
            }
            acc[name]++;
            return acc;
        }, {});
        let topCarriersDataArr = Object.keys(topCarriersData).map(k => {
            return { name: k, y: topCarriersData[k] };
        });
        let sortedArray = [{ name: "Carriers", data: topCarriersDataArr.sort((a, b) => b.y - a.y).slice(0, 5) }]

        return sortedArray
    }

    getCarrierDelayData = (trackingData) => {
        let colorPalette = this.state.colorPalette
        let colorPalette1 = this.state.colorPalette1
        let revercolorPalette = this.state.colorPalette.toReversed()
        let carrierDelay = trackingData.reduce((acc, container) => {
            let name = container.carrier_type;
            if (Number.isInteger(container.delay_days)) {
                if (!acc.hasOwnProperty(name)) {
                    acc[name] = {}
                    acc[name]['delay'] = 0
                    acc[name]['count'] = 0
                    acc[name]['onTime'] = 0
                }
                acc[name]['count']++
                let delay = container.delay_days < 0 ? 0 : container.delay_days
                acc[name]['delay'] = (acc[name]['delay'] + delay);

                if (container.delay_days <= 0) acc[name]['onTime']++
            }
            return acc;
        }, {});
        let carrierAvgDelay = Object.keys(carrierDelay).map((k, i) => {
            return { name: k, y: carrierDelay[k]['delay'] / carrierDelay[k]['count'], color: colorPalette1[i] };
        });
        let carierOnTimePercentage = Object.keys(carrierDelay).map((k, i) => {
            let percent = parseFloat(((carrierDelay[k]['onTime'] * 100) / carrierDelay[k]['count']).toFixed(2))
            return { name: k, y: percent, color: revercolorPalette[i] };
        });
        let carrierDelayedPercentage = Object.keys(carrierDelay).map((k, i) => {
            let percent = parseFloat(((carrierDelay[k]['onTime'] * 100) / carrierDelay[k]['count']).toFixed(2))
            return { name: k, y: 100 - percent, color: colorPalette[i] };
        });
        return [[{ name: "Average Delay in Days", data: carrierAvgDelay }], [{ name: "Percentage on Time", data: carierOnTimePercentage }], [{ name: "Percentage Delayed", data: carrierDelayedPercentage }]]
    }

    getAvgGateOutDelay = (trackingData) => {
        const today = moment();
        const colorPalette = this.state.colorPalette
        const monthsCount = 6
        const lastNMonths = []
        for (let i = 0; i < monthsCount; i++) {
            const monthYear = moment().subtract(i, 'months').format('MMMM YYYY');
            lastNMonths.push(monthYear);
        }
        const nMonthsAgo = today.subtract(6, 'months');
        let gateOutFilter = trackingData.filter(container => container.events.some(e => (e.tracking_status === 'CGO' && e.actual === true && moment(container.created_at).isSameOrAfter(nMonthsAgo, 'month') && moment(container.created_at).isSameOrAfter(nMonthsAgo, 'year'))))

        let avgDelay = gateOutFilter.reduce((acc, container) => {
            let destinationArrivalEvent = container.events.filter(e => e.tracking_status == 'VAD');
            let gateOutEvent = container.events.filter(e => e.tracking_status == 'CGO');
            if (gateOutEvent[0].date && destinationArrivalEvent[0].date) {
                const portDwellingDuration = moment.duration(moment(gateOutEvent[0].date).diff(moment(destinationArrivalEvent[0].planned_date)));
                const portDwellingDelay = parseFloat(portDwellingDuration.asDays())
                let portName = gateOutEvent[0].location_details.name + ', ' + gateOutEvent[0].location_details.country + ', ' + gateOutEvent[0].location_details.locode
                if (!acc['port'].hasOwnProperty(portName)) {
                    acc['port'][portName] = {}
                    acc['port'][portName]['delay'] = 0
                    acc['port'][portName]['count'] = 0
                    acc['port'][portName]['max_delay'] = 0
                    acc['port'][portName]['min_delay'] = portDwellingDelay
                    acc['port'][portName]['location'] = {}
                }
                acc['port'][portName]['count']++
                if (portDwellingDelay > acc['port'][portName]['max_delay']) acc['port'][portName]['max_delay'] = portDwellingDelay
                if (portDwellingDelay < acc['port'][portName]['min_delay']) acc['port'][portName]['min_delay'] = portDwellingDelay
                acc['port'][portName]['delay'] = acc['port'][portName]['delay'] + portDwellingDelay;
                acc['port'][portName]['location'].lat = gateOutEvent[0].location_details.lat
                acc['port'][portName]['location'].lng = gateOutEvent[0].location_details.lng
            }

            if (gateOutEvent[0].date && gateOutEvent[0].planned_date) {
                const duration = moment.duration(moment(gateOutEvent[0].date).diff(moment(gateOutEvent[0].planned_date)));
                const delayDays = parseFloat(duration.asDays())
                let name = moment(container.created_at).format("MMMM YYYY")
                if (!acc['month'].hasOwnProperty(name)) {
                    acc['month'][name] = {}
                    acc['month'][name]['delay'] = 0
                    acc['month'][name]['count'] = 0
                }
                acc['month'][name]['count']++
                acc['month'][name]['delay'] = acc['month'][name]['delay'] + delayDays
            }
            return acc
        }, { port: {}, month: {} })
        let avgPortDelay = avgDelay.port
        let avgPortDelayDataArr = Object.keys(avgPortDelay).map((k, i) => {
            let delayPerPort = parseFloat((avgPortDelay[k]['delay'] / avgPortDelay[k]['count']).toFixed(2))
            return { name: k, avg_delay: delayPerPort, min_delay: parseFloat(avgPortDelay[k]['min_delay'].toFixed(2)), max_delay: parseFloat(avgPortDelay[k]['max_delay'].toFixed(2)), lat: avgPortDelay[k]['location'].lat, lng: avgPortDelay[k]['location'].lng }
        })

        let avgMonthDelay = {}
        lastNMonths.forEach(month => {
            if (!(month in avgDelay.month)) {
                avgMonthDelay[month] = { delay: 0, count: 1 }
            } else {
                avgMonthDelay[month] = { delay: avgDelay.month[month].delay, count: avgDelay.month[month].count }
            }
        })

        const sortedData = Object.entries(avgMonthDelay).
            sort(([keyA], [keyB]) => moment(keyA, 'MMMM YYYY').diff(moment(keyB, 'MMMM YYYY'))).
            reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

        let monthlyContainerDataArr = Object.keys(sortedData).map((k, i) => {
            let delayPerMonth = parseFloat((sortedData[k]['delay'] / sortedData[k]['count']).toFixed(2))
            return { name: k, y: delayPerMonth, color: colorPalette[i] }
        })

        return [{ name: "Delay Per Month", data: monthlyContainerDataArr }, [...avgPortDelayDataArr]]
    }

    getContainerAddedPerMonth = (trackingData) => {
        const today = moment()
        const monthsCount = 6
        let colorPalette = this.state.colorPalette1
        const lastNMonths = []
        for (let i = 0; i < monthsCount; i++) {
            const monthYear = moment().subtract(i, 'months').format('MMMM YYYY')
            lastNMonths.push(monthYear)
        }

        const nMonthsAgo = today.subtract(monthsCount, 'months')
        let filteredContainers = trackingData.filter(container => (moment(container.created_at).isSameOrAfter(nMonthsAgo, 'month') && moment(container.created_at).isSameOrAfter(nMonthsAgo, 'year')))
        let monthlyContainerData = filteredContainers.reduce((acc, container) => {
            let name = moment(container.created_at).format("MMMM YYYY")
            if (!acc.hasOwnProperty(name)) {
                acc[name] = 0
            }
            acc[name]++
            return acc
        }, {})

        lastNMonths.forEach(month => {
            if (!(month in monthlyContainerData)) {
                monthlyContainerData[month] = 0
            }
        })

        const sortedData = Object.entries(monthlyContainerData).
            sort(([keyA], [keyB]) => moment(keyA, 'MMMM YYYY').diff(moment(keyB, 'MMMM YYYY'))).
            reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

        let monthlyContainerDataArr = Object.keys(sortedData).map((k, i) => {
            return { name: k, y: sortedData[k], color: colorPalette[i] }
        })

        return { name: "Packages Per Month", data: monthlyContainerDataArr }
    }

    getTopCountriesData = (trackingData, type) => {
        let topCountriesData = trackingData.reduce((acc, container) => {
            let name = container[type]
            if (!acc.hasOwnProperty(name)) {
                acc[name] = 0
            }
            acc[name]++
            return acc
        }, {})
        let topCountriesDataArr = Object.keys(topCountriesData).map(k => {
            return { name: k, y: topCountriesData[k] }
        })
        let sortedArray = [{ name: "Shipments", data: topCountriesDataArr.sort((a, b) => b.y - a.y).slice(0, 5) }]

        return sortedArray
    }

    componentDidMount = async () => {
        this.setState({
            loadshow: "show-m",
        })
        await this.fetchTrackingData();
        $(".tracking-card").hover(
            function () {
                $(this).css({
                    transform: "scale(1.05)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                });
            },
            function () {
                $(this).css({
                    transform: "scale(1)",
                    transition: "transform 0.3s ease",
                    cursor: "auto",
                });
            }
        );
    }

    render() {
        return (
            <div className="container-fluid">
                <div className={"dataLoadpage " + this.state.loadshow} style={{ height: "100%" }}>
                    <div className={"dataLoadpageimg " + this.state.loadshow} style={{ top: "40%" }}>
                        <div className="spinner-grow text-primary mr-2" role="status"></div>
                        <div className="spinner-grow text-primary mr-2" role="status"></div>
                        <div className="spinner-grow text-primary" role="status"></div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-xl-12 col-md-12 mb-4">
                            {/* <div className="card shadow">
                                <div className="row py-3 px-2">
                                    <div className="col-4">
                                        <button className="btn btn-info" onClick={this.handleShowFilter}><i className="fas fa-filter mr-2"></i>Filter {this.state.filterCount > 0 ? <span>({this.state.filterCount})</span> : ""}</button>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card shadow">
                                <div className="row py-4 px-3">
                                    <div className="col-3 tracking-card" style={{ height: "155px" }}>
                                        <div className="card shadow" style={{ padding: "20px", backgroundColor: "#eefffa", padding: "20px" }}>
                                            <div className="row" style={{ color: "#2e9173" }}>
                                                <div className="col-8">
                                                    <h1>{this.state.trackingCount}</h1>
                                                </div>
                                                <div className="col-4">
                                                    <h1><i class="fas fa-sharp fa-regular fa-ship"></i></h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h5 className="pl-10px pt-10px" style={{ color: "#2e9173" }}>Shipments</h5>
                                            </div>
                                            {/* <div className="row pt-10px pl-100px">
                                                <h6 style={{ color: "#2e9173" }}>View Details <i class="fas fa-arrow-right"></i></h6>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-3 tracking-card" style={{ height: "155px" }}>
                                        <div className="card shadow" style={{ padding: "20px", backgroundColor: "#eef3ff", padding: "20px" }}>
                                            <div className="row" style={{ color: "#1055fb" }}>
                                                <div className="col-8">
                                                    <h1>{this.state.trackingCount}</h1>
                                                </div>
                                                <div className="col-4">
                                                    <h1><i class="fas fa-box"></i></h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h5 className="pl-10px pt-10px" style={{ color: "#1055fb" }}>Containers</h5>
                                            </div>
                                            {/* <div className="row pt-10px pl-100px">
                                                <h6 style={{ color: "#1055fb" }}>View Details <i class="fas fa-arrow-right"></i></h6>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-3 tracking-card" style={{ height: "155px" }}>
                                        <div className="card shadow" style={{ padding: "20px", backgroundColor: "#ffeaea", padding: "20px" }}>
                                            <div className="row" style={{ color: "#dd4746" }}>
                                                <div className="col-8">
                                                    <h1>{this.state.delayedCounter}</h1>
                                                </div>
                                                <div className="col-4">
                                                    <h1><i class="fas fa-anchor"></i></h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h5 className="pl-10px pt-10px" style={{ color: "#dd4746" }}>Delayed Shipments</h5>
                                            </div>
                                            {/* <div className="row pt-10px pl-100px">
                                                <h6 style={{ color: "#dd4746" }}>View Details <i class="fas fa-arrow-right"></i></h6>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-3 tracking-card" style={{ height: "155px" }}>
                                        <div className="card shadow" style={{ padding: "20px", backgroundColor: "#fff8ee", padding: "20px" }}>
                                            <div className="row" style={{ color: "#ff8800" }}>
                                                <div className="col-8">
                                                    <h1>{this.state.destinationCounter}</h1>
                                                </div>
                                                <div className="col-4">
                                                    <h1><i class="fas fa-sharp fa-regular fa-ship"></i></h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h5 className="pl-10px pt-10px" style={{ color: "#ff8800" }}>Shipments at POD</h5>
                                            </div>
                                            {/* <div className="row pt-10px pl-100px">
                                                <h6 style={{ color: "#ff8800" }}>View Details <i class="fas fa-arrow-right"></i></h6>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <h5 className="px-3 pt-5 "><b>Carrier Analysis:</b></h5>
                                <div className="row p-4">
                                    <div className="col-6 card">
                                        {this.state.topCarriersData ?
                                            <TrackingAmPieChart
                                                title="Carrier Distribution"
                                                subtitle=""
                                                piSeries={this.state.topCarriersData}
                                                colors={['#003049', '#d62828', '#f77f00', "#fcbf49", "#eae2b7"]}
                                            /> : ""}
                                    </div>
                                    <div className="col-6 card">
                                        {this.state.carrierDelayPerCarrier ?
                                            <TrackingAmBarChart
                                                title="Avg. Delayed Containers/Packages"
                                                subTitle=""
                                                xAxisTitle="Carrier Name"
                                                yAxisTitle="Avg. Delayed (In Days) for Containers/Packages"
                                                barSeries={this.state.carrierDelayPerCarrier}
                                            /> : ""}
                                    </div>
                                    <div className="col-6 card">
                                        {this.state.carrierOnTimePercentage ?
                                            <TrackingAmBarChart
                                                title="Carrier Performance"
                                                subTitle=""
                                                xAxisTitle="Carrier Name"
                                                yAxisTitle="Percentage On Time Performance"
                                                barSeries={this.state.carrierOnTimePercentage}
                                            /> : ""}
                                    </div>
                                    <div className="col-6 card">
                                        {this.state.carrierDelayedPercentage ?
                                            <TrackingAmBarChart
                                                title="Delayed Containers/Packages"
                                                chartType="column"
                                                subTitle=""
                                                xAxisTitle="Carrier Name"
                                                yAxisTitle="Percentage Delayed Containers/Packages"
                                                barSeries={this.state.carrierDelayedPercentage}
                                            /> : ""}
                                    </div>
                                </div>
                                <h5 className="px-3 pt-3 "><b>Shipment Analysis:</b></h5>
                                <div className="row p-4">
                                    <div className="col-6 card">
                                        {
                                            this.state.getAvgGateOutDelay ?
                                                <TrackingAmLineChart
                                                    title="Avg. Gate Out Delay"
                                                    chartType="line"
                                                    subTitle=""
                                                    xAxisTitle="Months"
                                                    yAxisTitle="Number of Days"
                                                    barSeries={this.state.getAvgGateOutDelay}
                                                /> : ""}
                                    </div>
                                    <div className="col-6 card">
                                        {this.state.containerAddedPerMonth ?
                                            <TrackingAmLineChart
                                                title="Containers/Packages Added"
                                                chartType="line"
                                                subTitle=""
                                                xAxisTitle="Months"
                                                yAxisTitle="Number of Containers/Packages"
                                                barSeries={this.state.containerAddedPerMonth}
                                            /> : ""}
                                    </div>
                                </div>
                                <h5 className="px-3 pt-3 "><b>Regional Analysis:</b></h5>
                                <div className="row p-4">
                                    <div className="col-6 card">
                                        {this.state.topOriginCountriesData ?
                                            <TrackingAmPieChart
                                                title="Top 5 Origin Countries"
                                                subtitle=""
                                                piSeries={this.state.topOriginCountriesData}
                                                colors={['#0d3b66', '#faf0ca', '#f4d35e', "#ee964b", "#f95738"]}
                                            /> : ""}
                                    </div>
                                    <div className="col-6 card">
                                        {this.state.topDestinationCountriesData ?
                                            <TrackingAmPieChart
                                                title="Top 5 Destination Countries"
                                                subtitle=""
                                                piSeries={this.state.topDestinationCountriesData}
                                                colors={['#003049', '#d62828', '#f77f00', "#fcbf49", "#eae2b7"]}
                                            /> : ""}
                                    </div>
                                </div>
                                <div className="row p-4">
                                    <div className="col-12">
                                        <h5>Port Dwell</h5>
                                        {this.state.portDwellingData ?
                                            <TrackingGlobeChart
                                                globeSeries={this.state.portDwellingData}
                                            /> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}
