import React, { useState, forwardRef, useImperativeHandle } from 'react';

const CustomCellEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    }
  }));

  const handleChange = e => {
    let inputValue = e.target.value;
    // Remove any non-digit characters
    inputValue = inputValue.replace(/\D/g, '');
    // Limit the input to exactly 10 digits
    if (inputValue.length <= 10) {
      setValue(inputValue);
    }
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      placeholder="Enter 10-digit mobile number"
    />
  );
});

export default CustomCellEditor;
