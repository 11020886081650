import React, { Component, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

// var Constant = require('../common/Constant');
import redirectURL from "../redirectURL";
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
export const validAlphaNumericSpaceSlash = new RegExp(/^[a-zA-Z0-9._&// ]{1,255}$/);
export default function EditGeofence(props) {
	console.log(props,"jjgjggg"); 
	//console.log(rowData,"khkhkhkjkjojo")
	//const {geofence_type,geofence_name} = rowData
	
	//console.log(geofence_name,geofence_type)
    var [gridActions, setgridActions] = useState({
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        eventAction: null,
    })
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [pageTitle, setpageTitle] = useState('Geofences')
    var [geo_type, setgeo_type] = useState('')
    var [initialDate, setData] = useState({
        plantshow: 'show-m',
        consigneeshow: "show-n",
        consignee_code: ""
    })
    var [consigneecode, setconsigneecode] = useState('')
    var [plant_code, setplant_code] = useState('')
    var [allplants, setallplants] = useState([])
    var [plantOpt, setplantOpt] = useState([])
    var [geo_name, setgeo_name] = useState('')
    var coords
    var [errmsg, seterrmsg] = useState({
        errcode: '',
        errcodemsg: ''
    })
    var [customers, setcustomers] = useState([])
    var [selectedData, setselectedData] = useState({
        type: {},
        geofence_name: {}
    })
    var [geofence_names_list, setgeofence_names_list] = useState([])
    var [masterdata, setmasterdata] = useState({
        plantdata: [],
        pickupyardData: [],
        parkingplazadata: [],
        bufferyarddata: [],
    })
    var fenchtypeoptions = [{ label: 'Plant', value: 'plant' }, { label: 'Container Pickup', value: 'container_pickup' }, { label: 'Parking Plaza', value: 'parking_plaza' }, { label: 'Buffer Yard', value: 'buffer_yard' }, { label: 'Port Gate', value: 'port_gate' }]
    useEffect(() => {
        logPageView()
        window.initMap = initMap
        showMap()

    }, [])
    useEffect(() => {
        redirectURL.post("/exim/fetchPlantDetails")
            .then((response) => {
                if (response.data.status === "success") {
                    let plantData = response.data.plantDetails;
                    setmasterdata(prev => ({
                        ...prev,
                        plantdata: plantData
                    }))
                }
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
        redirectURL.post("/exim/fetchContainerPickupYardDetails")
            .then((response) => {
                let pickUpYardDetails = response.data.containerPickupYardDetails;
                setmasterdata(prev => ({
                    ...prev,
                    pickupyardData: pickUpYardDetails
                }))
            })
            .catch((e) => {
                console.log("message is", e.message)
            })
        redirectURL.post("/exim/fetchParkingPlazaDetails")
            .then((response) => {
                let parkingPlazaDetails = response.data.parkingPlazaDetails;
                setmasterdata(prev => ({
                    ...prev,
                    parkingplazadata: parkingPlazaDetails
                }))
            })
            .catch((e) => {
                console.log("message is", e.message)
            })
        redirectURL.post("/exim/fetchBufferYardDetails")
            .then((response) => {
                let bufferYardDetails = response.data.bufferYardDetails;
                setmasterdata(prev => ({
                    ...prev,
                    bufferyarddata: bufferYardDetails
                }))
            })
            .catch((e) => {
                console.log("message is", e.message)
            })
    }, [])
    var showMap = () => {
        renderMap();
    }
    var renderMap = () => {

        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
        window.initMap = initMap
    }
    var logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                setgridActions(prevState => ({
                    eventGridAction: eventGridAction,
                    eventCounterAction: eventCounterAction,
                    eventFormAction: eventFormAction
                }))
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    var closeAlert = () => {
        setshow(false)
    }
    var initMap = () => {
        var coordsvalues;
        var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(21.141058047268885, 72.65782317936426),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
            },
            disableDefaultUI: true,
            zoomControl: true
        });

        //added by nanda for places dropdown...
        var input = document.getElementById('search');
        var searchBox = new window.google.maps.places.SearchBox(input);
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', function () {
            searchBox.setBounds(map.getBounds());
        });

        var markers = [];
        // console.log("searchBox",searchBox);
        searchBox.addListener('places_changed', function () {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach(function (marker) {
                marker.setMap(null);
            });
            markers = [];
            // For each place, get the icon, name and location.
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function (place) {
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }
                var icon = {
                    url: place.icon,
                    size: new window.google.maps.Size(71, 71),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(17, 34),
                    scaledSize: new window.google.maps.Size(25, 25)
                };

                // Create a marker for each place.
                markers.push(new window.google.maps.Marker({
                    map: map,
                    icon: icon,
                    title: place.name,
                    position: place.geometry.location
                }));

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });
        //End of adding by nanda

        var polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.70,
            editable: true
        };
        // Creates a drawing manager attached to the map that allows the user to draw
        // markers, lines, and shapes.
        drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
                drawingModes: [
                    window.google.maps.drawing.OverlayType.POLYGON
                ]
            },
            markerOptions: {
                draggable: true
            },
            polylineOptions: {
                editable: true
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: map
        });

        window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (e) {
            if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
                // Switch back to non-drawing mode after drawing a shape.
                drawingManager.setDrawingMode(null);
                // To hide:
                drawingManager.setOptions({
                    drawingControl: false
                });

                // Add an event listener that selects the newly-drawn shape when the user
                // mouses down on it.
                var newShape = e.overlay;
                newShape.type = e.type;
                window.google.maps.event.addListener(newShape, 'click', function () {
                    setSelection(newShape);
                });
                setSelection(newShape);
                //console.log("selectedShape",newShape);
                fencingLength = selectedShape.getPath().getLength();

                var latlngObj = [];
                var htmlStr = "";
                for (var i = 0; i < fencingLength; i++) {
                    var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
                    var in_latlng_array = { "lat": parseFloat(each_lat_lng[0]), "lng": parseFloat(each_lat_lng[1]) };
                    latlngObj.push(in_latlng_array);
                    coordinates.push(in_latlng_array);
                }

            }

        });


        // Clear the current selection when the drawing mode is changed, or when the
        // map is clicked.
        window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function () {
            clearSelection()
        });
        window.google.maps.event.addListener(map, 'click', function () {
            clearSelection()
        });
        /*
         * window.google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', function(){
                deleteSelectedShape()
        });
        */


        //buildColorPalette();
        map.addListener('zoom_changed', function () {
            mapZoom = map.getZoom();
        });

        /*window.google.maps.event.addDomListener(document.getElementById('saveGeo'), 'click', function(){
            getSelectedShape()
        });*/

        console.log("coordinates", coordinates);
        document.getElementById("crds").value = coordinates;

    }
    var formHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
                "category": pageTitle,
                "action": gridActions.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        console.log(coordinates, 'coordinates')
        if (coordinates.length > 3) {
            console.log('317')
            var flag = 0;
            if (flag == 0 && selectedData.type != "" && selectedData.type != undefined && selectedData.geofence_name != '' && selectedData.geofence_name != undefined) {
                console.log(selectedData, 'selectedData')
                var params = {'geofence_type':selectedData.type.value,'geofence_name':selectedData.geofence_name.value,'coords':JSON.stringify(coordinates)}
                redirectURL.post('/consignments/addGeo',params).then((resp)=>{
                    if(resp.data.status=='success'){
                        setshow(true)
                        setbasicTitle('Geofence Added Successfully')
                        setbasicType('success')  
                    }
                })
            }
            else {
                if (geo_type.value == "") {
                    seterrmsg(prev => ({
                        errcode: "show-m",
                        errcodemsg: "Please select type."
                    }))
                }
                else {
                    seterrmsg(prev => ({
                        errcode: "show-m",
                        errcodemsg: "Please enter customer code."
                    }))
                }

            }

        }
        else {
            seterrmsg(prev => ({
                errcode: "show-m",
                errcodemsg: "Please add atleast 3 coordinate points"
            }))
        }

    }
    var redurl = "/manage/geofences";
    const dStyles = {
        width: '100%',
        height: '500px'
    }
    const onSelectType = (e) => {
        console.log(e)
        setselectedData(prev => ({
            ...prev,
            type: e
        }))
        if (e.value == 'plant') {
            var geo_names = []
            var records = masterdata.plantdata
            var plant_names = records.map((e) => {
                geo_names.push({ label: e.plant_name, value: e.plant_name })
            })
            setgeofence_names_list(geo_names)
        }
        else if(e.value=='container_pickup'){
            var geo_names = []
            var records = masterdata.pickupyardData
            var pickup_names = records.map((e) => {
                geo_names.push({ label: e.pickup_yard_name, value: e.pickup_yard_name })
            })
            setgeofence_names_list(geo_names)
        }
        else if(e.value=='parking_plaza'){
            var geo_names = []
            var records = masterdata.parkingplazadata
            var pickup_names = records.map((e) => {
                geo_names.push({ label: e.parking_plaza_name, value: e.parking_plaza_name })
            })
            setgeofence_names_list(geo_names)
        }
        else if(e.value=='buffer_yard'){
            var geo_names = []
            var records = masterdata.bufferyarddata
            var pickup_names = records.map((e) => {
                geo_names.push({ label: e.parking_plaza_name, value: e.parking_plaza_name })
            })
            setgeofence_names_list(geo_names)
        }
        else{
            setgeofence_names_list([])
        }
    }
	console.log('datamadhuri', props);
    return (
        <div className="container-fluid">
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}>
            </SweetAlert>
            <div className="form-group d-flex justify-content-between  mt-2 pl-0 mb-0">
                    <h5 className="heading ml-22p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Edit Geofence
                    </h5>
                </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="row card-body">
                            <form className=" theme-form col-xl-4 col-lg-4" onSubmit={formHandler}>
                                <div className="col-xl-12 col-lg-12">
                                    <div className="form-group">
                                        <label className="c-lbl">Type *:</label>
                                        <Select
                                            value={selectedData.type}
                                            onChange={onSelectType}
                                            style={{ borderRadius: "0px" }}
                                            options={fenchtypeoptions} required />
                                    </div>
                                    <input type="hidden" id="crds" />
                                    <div className="form-group">
                                        <label className="c-lbl">Geofence Name *:</label>
                                        <Select
                                            value={selectedData.geofence_name}
                                            onChange={(e) => setselectedData(prev => ({ ...prev, geofence_name: e }))}
                                            style={{ borderRadius: "0px" }}
                                            options={geofence_names_list} required />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save</button>
                                        <a href={"/geofence"} className="btn btn-info cs-btn">Cancel</a>

                                    </div>
                                </div>
                            </form>


                            <div className="col-xl-8 col-lg-8">

                                {/* {this.state.erroMessage == '' ? '':
										<div className="alert alert-danger"></div>
								} */}
                                <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />


                                <div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>

                                <div className="col-xl-12 col-lg-12">
                                    <input type="hidden" name="coords" id="coords" value={coords} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
function loadScript(url) {
    try {
        var index = window.document.getElementsByTagName("script")[0]
        var script = window.document.createElement("script")
        script.src = url
        script.async = true
        script.defer = true
        index.parentNode.insertBefore(script, index)
    }
    catch (e) {

    }

}

function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = Math.floor(hours);
    var minutes = (n) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}


function clearSelection() {
    if (selectedShape) {
        selectedShape.setEditable(false);
        selectedShape = null;
    }
}

function setSelection(shape) {
    clearSelection();
    selectedShape = shape;
    //console.log("selectedShape",selectedShape);
    shape.setEditable(true);

    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function deleteSelectedShape() {
    fencingLength = 0;
    if (selectedShape) {
        selectedShape.setMap(null);
    }
    // To show:
    drawingManager.setOptions({
        drawingControl: true
    });
}


function selectColor(color) {
    selectedColor = color;
    for (var i = 0; i < colors.length; ++i) {
        var currColor = colors[i];
        //console.log(colorButtons[currColor]);
        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
    }

    // Retrieves the current options from the drawing manager and replaces the
    // stroke or fill color as appropriate.
    var polylineOptions = drawingManager.get('polylineOptions');
    polylineOptions.strokeColor = color;
    drawingManager.set('polylineOptions', polylineOptions);

    var rectangleOptions = drawingManager.get('rectangleOptions');
    rectangleOptions.fillColor = color;
    drawingManager.set('rectangleOptions', rectangleOptions);

    var circleOptions = drawingManager.get('circleOptions');
    circleOptions.fillColor = color;
    drawingManager.set('circleOptions', circleOptions);

    var polygonOptions = drawingManager.get('polygonOptions');
    polygonOptions.fillColor = color;
    drawingManager.set('polygonOptions', polygonOptions);
}

function setSelectedShapeColor(color) {
    if (selectedShape) {
        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
            selectedShape.set('strokeColor', color);
        } else {
            selectedShape.set('fillColor', color);
        }
    }
}

function makeColorButton(color) {
    var button = document.createElement('span');
    button.className = 'color-button';
    button.style.backgroundColor = color;
    window.google.maps.event.addDomListener(button, 'click', function () {
        selectColor(color);
        setSelectedShapeColor(color);
    });

    return button;
}

function buildColorPalette() {
    var colorPalette = document.getElementById('color-palette');
    for (var i = 0; i < colors.length; ++i) {
        var currColor = colors[i];
        var colorButton = makeColorButton(currColor);
        colorPalette.appendChild(colorButton);
        colorButtons[currColor] = colorButton;
    }
    selectColor(colors[0]);
}

function getSelectedShape(coords) {
    var lt = []
    //console.log("coordsLatlngs ",coords);
    for (let value of Object.values(coords)) {
        //console.log("Val is ",value); // John, then 30
        lt.push(value)
    }
    //console.log("Combine ",lt);
    document.getElementById('coords').value = lt;
    //return lt;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}


