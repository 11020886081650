import React, { useState, useEffect } from "react"
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import Select from "react-select";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenDDMMMYYYY } from '../common/utils';
import EditTruck from "./editTruck";
import DeleteTruck from "./deleteTruck";
import redirectURL from "../redirectURL";

const Truck = () => {
    const [rowData, setrowdata] = useState([])
    const columnwithdefs = [
        // {
        //     headerName: "",
        //     field: "_id",
        //     width: 90,
        //     cellRendererFramework: EditPartner
        //     // suppressSizeToFit: true,
        // },
        // {
        //     headerName: "",
        //     field: "_id",
        //     width: 50,
        //     cellRendererFramework: DeletePartner
        //     // suppressSizeToFit: true,
        // },
        {
            headerName: "Truck No",
            field: "truck_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width: 230,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Truck Model",
            field: "truck_model",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Container Type",
            field: "container_type",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "M.V Regd.Date",
            field: "mv_regd_date",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Valid From",
            field: "valid_from",
            width: 130,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.valid_from != '' && params.data.valid_from != undefined) {
                        return getHyphenDDMMYYYY(params.data.valid_from);
                    }
                }
                catch (e) {
                }
            }
        },
        {
            headerName: "Valid To",
            field: "valid_to",
            width: 130,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.valid_to != '' && params.data.valid_to != undefined) {
                        return getHyphenDDMMYYYY(params.data.valid_to);
                    }
                }
                catch (e) {
                }
            }
        },
        {
            headerName: "Deletion Indicator",
            field: "deletion_indicator",
            width: 160,
            filter: true,
            resizable: true,
        },
    ]
    useEffect(() => {
        redirectURL.post('/consignments/getTrucksData').then((resp) => {
            var records = resp.data
            if (records != '' && records != undefined) {
                setrowdata(records)
            }
        })
    })
    return (
        <div className='ag-theme-balham' style={{ padding: "20px", margin: '45px', boxShadow: "0 2px 11px 0 rgb(0 0 0 / 41%)" }}>
            <div className=''>
                <h5 style={{ marginLeft: '30px', marginLeft: '7px' }}>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Trucks Master
                </h5>
                <div style={{ display: "flex", justifyContent: "right", marginBottom: "20px" }}>
                    <button className='btn btn-primary' style={{ marginRight: "10px" }}>+ Add Truck</button>
                    <button className='btn btn-primary'>Bulk Upload</button>
                </div>
                <div className="f16">
                </div>
            </div>
            {/* <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert> */}
            <div id="myGrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                <AgGridReact
                    columnDefs={columnwithdefs}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        editable: false,
                        resizable: true,
                        menuTabs: ['filterMenuTab']
                    }}
                    rowData={rowData}
                />
            </div>

        </div>
    )
}
export default Truck