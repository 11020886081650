import React, { useEffect } from "react";

const BootstrapLoader = (props) => {
  useEffect(()=>{},[props])
  // console.log("BootstrapLoader",props)
  return (
    <div
      className={`${
        props.showLoader ? "show-m loader_styling overlay-part" : "show-n"
      }`}
      style={{background: "#f6f6f6"}}
    >
      <div className="loading_card">
        <div class="spinner-grow text-primary" role="status"></div>
        <div class="spinner-grow text-primary" role="status"></div>
        <div class="spinner-grow text-primary" role="status"></div>
      </div>
    </div>
  );
};

export default BootstrapLoader;
