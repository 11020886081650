import React, { useState, useEffect, useRef, useCallback } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UpdateAskingrategrid from "../layouts/updategridforaskingreport";
import Select from "react-select";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenYYYYMMDD } from '../common/utils';
import Datepicker from "../layouts/datepicker";
import moment from "moment";
// import SubmitBtn from "./submitBtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
import { object } from "@amcharts/amcharts5";
const AskingRateReport = () => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    const [showAddForm, setshowAddForm] = useState('')
    const [transList, settransList] = useState([])
    var [trucksdata,setTrucksData] = useState([])
    const currentDate = new Date();
    const initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    var [selectedDate, setSelectedDate] = useState(new Date());
    var [selectedfilters, setfilters] = useState({
        datefilter: initialDate,
        transfilter: { label: 'All', value: 'ALL' }
    })
    var [groupdata, setgroupdata] = useState([])
    const [modifiedRows, setModifiedRows] = useState(new Set());
    var [selecteddata,setselecteddata] = useState([])
    var [modifiedRownodes, setModifiedRownodes] = useState([]);
    var [updatedData, setUpdatedData] = useState([]);
    var [manualUploadDetails,setmanualUploadDetails]=useState({
        plant_code:{},
        month_outlook:''
    })
    var [plantList,setplantList] = useState([])
    var [isOpen, setIsOpen]= useState(false);
    const datePickerRef = useRef(null);
    useEffect(() => {
        if (gridRef.current) {
            // setGridApi(gridRef.current.api);
            // setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        redirectURL.post("/consignments/getplantdata").then((resp) => {
            try {
                var data = resp.data
                var plant = []
                data.map((e) => {
                    plant.push({ label: e.plant_code, value: e.plant_code })
                })
                plant = removeDuplicateObjects(plant);
                setplantList(plant)
            }
            catch (e) {

            }
        })
        getData()
        if (datePickerRef.current){
            datePickerRef.current.readOnly = true;
        }

    }, [])
    const handleCellValueChanged = (params) => {
        var updatedRowData = [...updatedData];
        updatedRowData.push(params.data)
        setUpdatedData(updatedRowData);
      };
    const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
    }, []);
    const getRowStyle = (params) => {
        if (!params || !params.data || !params.data.report_date) {
            return null;
        }
        var currentWeekDates = getCurrentWeekDates();
        var reportDate = params.data.report_date;
        if (currentWeekDates.includes(reportDate)) {
            return { backgroundColor: '#FF7F7F' }; 
        }
        return null;
    };
    const submitdata = (e)=>{
        e.preventDefault();
        var updatedrecord = []
        updatedData.map((e)=>{
            var keys = Object.keys(e);
            keys.map((k)=>{
                if(k.includes("_name") == true)
                {
                    var tptName = k.split("_")[0];
                    var tptNamewithoutspaces = tptName.trim().replace(/ /g, "");
                }
                if(e[tptNamewithoutspaces+"_mark"] !=undefined && e[tptNamewithoutspaces+"_mark"] !="undefined"&&e[tptNamewithoutspaces+"_mark"] !='')
                {
                    updatedrecord.push({
                        "report_date" : e["report_date"],
                        "transporter_name" : e[tptNamewithoutspaces+"_name"],
                        "mark_availability" : e[tptNamewithoutspaces+"_mark"],
                    }); 
                }
            });
            // var row = {'report_date':e.report_date,'mark_availability':e.}
        })
        var params = {
            data:updatedrecord
        }
       redirectURL.post('/consignments/insertfortruckavailreport',params).then((resp)=>{
        if(resp.data.status=='Success'){
            setshow(true)
            setbasicTitle("Successfully Updated")
            setbasicType("success")
            getData()
        }
        else{
            setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
        }
       })
    }
    const getData = (formattedDate) => {
        setloadshow('show-m')
        setoverly('show-m')
        if (formattedDate != '' && formattedDate != undefined) {
            var date = formattedDate
        }
        else {
            var date = selectedfilters.datefilter
        }
        var params = {
            month: date
        }
        redirectURL.post('/consignments/fetchingaskingratedata', params).then((resp) => {
            var data = resp.data.asking_rate_report
            data = JSON.parse(data)
            setrowData(data)
            setoriginalData(data)
            setloadshow('show-n')
            setoverly('show-n')
            //setcontainersdata(resp.data.legsdata)
        })
    }
    var handleFilterOptions = (e) => {
        setfilters(prev => ({
            ...prev,
            transfilter: e
        }))
    }
    const columnwithDef = [
        {
            headerName: "Plant",
            field: "plant",
            width: 80,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Month Outlook",
            field: "month_outlook",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "No. Of Working Days",
            field: "no_of_working_days",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Avg. Rate (per day)",
            field: "avg_rate",
            width: 160,
            filter: true,
            resizable: true,

        },
        {
            headerName: "No. Of Days Passed",
            field: "no_of_days_passed",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Required Dispatch (as per avg. rate)",
            field: "required_dispatch",
            width: 250,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Actual Dispatch",
            field: "actual_dispatch",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Shortfall",
            field: "shortfall",
            width: 100,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Pending Dispatches",
            field: "pending_dispatches",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "No. Of Days Pending",
            field: "no_of_days_pending",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Asking Rate",
            field: "asking_rate",
            width: 140,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: Object.keys(params.data.date_list[1])[0],
        //     field: "params.data.date_list[0]",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter:function(params){
        //         return params.data.date_list[0]
        //     }
        // },
        // {
        //     headerName: Object.keys(params.data.date_list[1])[0],
        //     field: "stuffing_plan_15",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Stuffing Plan (04 Mar)",
        //     field: "stuffing_plan_16",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
    ]
    let addedDates = new Set();
    rowData.forEach((e) => {
      e.date_list.forEach((dateObj) => {
        var dateKey = Object.keys(dateObj)[0];
        if (!addedDates.has(dateKey)) {
          var columnDefinition = {  
            headerName: dateKey,
            field: dateKey,
            width: 120,
            filter: true,
            resizable: true,
            valueGetter: function(params) {
                const dateObj = params.data.date_list.find(obj => obj.hasOwnProperty(params.colDef.field));
                return dateObj ? dateObj[params.colDef.field] : null;
              }
          };
          columnwithDef.push(columnDefinition);
              addedDates.add(dateKey);
        }
      });
    });
    
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const selectedrow=(e)=>{
        onsubmitdata(e)
      }
      const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
    }
      const onClickbtn=()=>{
        setshowAddForm("slide30")
        setoverly("show-m")
      }
      const onsubmitdata=(e)=>{
        e.preventDefault()
        var date = selectedfilters.datefilter
        var params = {
            month: date,
            plant_code:manualUploadDetails.plant_code.value,
            month_outlook:manualUploadDetails.month_outlook
        }
        redirectURL.post('/consignments/getaskingratereportdata',params).then((resp)=>{
            var data = resp.data.asking_rate_report
            data = JSON.parse(data)
            setrowData(data)
            setoriginalData(data)
            setloadshow('show-n')
            setoverly('show-n')
            setshowAddForm('')
        })
      }
      function restrictToNumbers(event) {
        // Remove non-numeric characters from the input value
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        setfilters(prev => ({
            ...prev,
            datefilter: formattedDate
        }))
        getData(formattedDate)
    };
    const toggleDatePicker =()=>{
        setIsOpen(prevIsOpen => !prevIsOpen)
    }
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
           <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
           <div className="form-group d-flex justify-content-between">
               <h1 className="heading mt-30p">
                   <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Asking Rate Report
               </h1>
           </div>
           <div className="card m-10p">
            <div className="card-body" id="counterCardBody">
                <div className="row">
                <div className="col-sm-8 checkForDateFilter_gap">
                <div className="row">
                <div className="form-group col-xl-3 col-lg-3">
                    <div onClick={toggleDatePicker}>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy" // Format to display only month and year
                        showMonthYearPicker
                        minDate={new Date(1900, 0)} // Set the minimum year you want to allow
                        maxDate={new Date(2100, 11)}
                        readOnly
                        open={isOpen}
                        className="custom-datepicker"
                        calendarClassName="custom-datepicker-dropdown"// Set the maximum year you want to allow
                    />
                    </div>
                </div>
                
                
                
                {/* {(localStorage.getItem('role')=='tpt')?'':
                <div className="form-group col-xl-4 col-lg-4">
                    <Select
                        closeMenuOnSelect={true}
                        name="filterModeType"
                        // className="border-radius-0"
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                fontSize: "0.9rem",
                                color: "#6e707e",
                                borderRadius: "11px",
                                height: "48px",
                                width: "260px",
                                // textAlign: "center",
                                // marginRight: '25px'
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                fontSize: "0.9rem", // Adjust the font size here
                            }),
                            menu:(provided, state)=>({
                                ...provided,
                                width:"260px"
                            })
                        }}
                        onChange={handleFilterOptions}
                        options={transList}
                        value={selectedfilters.transfilter}
                        required
                    />
                </div>} */}
                {/* <div className="form-group col-xl-4 col-lg-4">
                    <button
                        onClick={getdata}
                        className="btn btn-success"
                    >
                        Submit
                    </button>
                </div> */}

                </div>
                </div>

                <div className="col-sm-4">
                    <div className="row">
                    <div className="col textAlignRight">                   
                    <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                    Back 
                    </a>
                    {/* <a onClick={onClickbtn} className="f12 mr-5p btn btn-default">
                    Month Outlook 
                    </a> */}
                     </div>
                    </div>
                </div>
                </div>
                <div className="row" >
                <div className="col-sm-12" >
                <div id="idgrid" style={{ width: "100%", height: "480px" }} className="ag-theme-balham ">
                <AgGridReact
                    columnDefs={columnwithDef}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        editable: false,
                        resizable: true,
                        menuTabs: ['filterMenuTab']
                    }}
                    headerHeight={70}
                     // rowHeight={70}
                    rowData={rowData}
                    suppressRowClickSelection={true}
                    frameworkComponents={{}}
                    enableCellChangeFlash={true}
                    suppressCellFlash={true}
                    enableRangeSelection={true}
                    paginationPageSize={100}
                    rowSelection={'multiple'}
                    masterDetail={true}
                    pagination={true}
                    sideBar={{
                        toolPanels: [
                            {
                                id: "columns",
                                labelDefault: "Columns",
                                labelKey: "columns",
                                iconKey: "columns",
                                toolPanel: "agColumnsToolPanel"
                            },
                            {
                                id: "filters",
                                labelDefault: "Filters",
                                labelKey: "filters",
                                iconKey: "filter",
                                toolPanel: "agFiltersToolPanel"
                            }
                        ]
                    }}
                    statusBar={{
                        statusPanels: [
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" }
                        ]
                    }}
                    gridOptions={{
                        icons: {
                            next: `<img src="${customNextIcon}" alt="Next" />`,
                            previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                            first: `<img src="${customFirstIcon}" alt="First" />`,
                            last: `<img src="${customLastIcon}" alt="Last" />`,
                        },
                    }}
                    ref={gridRef}
                     onCellEditingStopped={handleCellValueChanged}
                      onGridReady={onGridReady}
                    context={{}}
                    getRowStyle={getRowStyle}
                />
                </div>
            
            
            </div>
                </div>
            </div>
            </div>  
            </div>  
            {/* <div style={{ float: 'right', marginRight: '40px',marginBottom:'30px' }} onClick={submitdata}>
                <button type="submit" className="btn btn-success">Submit</button>
            </div> */}
             <div className={"sliderBlock2 " + showAddForm}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Month Outlook</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                    </div> <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Plant<span className="err-txt">*</span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.plant_code}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, plant_code: e }))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={plantList}
                                        />
                                      
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Month Outlook<span className="err-txt"></span></label>
                                        <input
                                            type="text"
                                            name="no_of_free_days"
                                            id="no_of_free_days"
                                            value={manualUploadDetails.month_outlook}
                                            className="form-control"
                                            maxlength="6"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, month_outlook: e.target.value }))}
                                            onInput = {e=> restrictToNumbers(e)}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>

                                    {/* <h6 style={{ color: "red", fontSize: "14px", marginTop: "2%" }}>Note: All date fields should be greater than production date</h6> */}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>

            </>
    )
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
// const getCurrentWeekDates = () => {
//     let currentDate = moment(); // Get the current date
//     let startOfWeek = currentDate.clone().startOf('week'); // Get the start of the week (Sunday)
//     let endOfWeek = startOfWeek.clone().add(6, 'days'); // Get the end of the week (Saturday)

//     // Adjust startOfWeek to be the current date if it's later in the week than Sunday
//     if (currentDate > startOfWeek) {
//         startOfWeek = currentDate.clone();
//     }

//     let dates = []; // Array to store the dates

//     // Loop through each day of the week and format the date as '22-Feb-2024'
//     for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'days')) {
//         dates.push(date.format('DD-MMM-YYYY'));
//     }

//     return dates;
// };
const getCurrentWeekDates = () => {
    let currentDate = moment(); // Get the current date

    // Calculate the start date as today
    let startOfWeek = currentDate.clone();

    // Calculate the end date as the 3rd of next month
    let endOfWeek = moment().endOf('month').add(6, 'days');

    let dates = []; // Array to store the dates

    // Loop through each day of the week and format the date as '22-Feb-2024'
    for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'days')) {
        dates.push(date.format('DD-MMM-YYYY'));
    }

    return dates;
};

export default AskingRateReport