import React, { useState, useEffect, useRef, useReducer,useCallback } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
const UpdatecontainerGrid = (props)=>{
    // console.log("props.rowData1 ", props.rowData1)
    const gridApi = useRef(null);
    var [modifiedRownodes, setModifiedRownodes] = useState([]);
    // var [gridApi, setGridApi] = useState(null);
    // var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    const [updatedData, setUpdatedData] = useState([]);
    const [editRowIds, setEditRowsIds] = useState([]);
    const [modifiedRows, setModifiedRows] = useState(new Set());
    useEffect(() => {
        if (gridRef.current) {
            // setGridApi(gridRef.current.api);
            // setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    // const handleCellValueChanged = (params) => {
    //     console.log(params.data);
    //     if (gridApi) {
    //         gridApi.stopEditing();
    //          const gridRowData = [];
    //         gridApi.forEachNode((node) => {
    //              console.log("node ",node)
    //             gridRowData.push(node.data);
    //         });
    //         setUpdatedData(gridRowData)
    //     }
    // };
    const onsubmitdata = (f)=>{
        f.preventDefault()
        const modifiedData = Array.from(modifiedRows).map(node => node);
        var updateIDs =[]
        modifiedData.map((e)=>{
            updateIDs.push(e.id)
        })
        if (gridApi.current) {
            gridApi.current.stopEditing();
            const gridRowData = gridApi.current.getSelectedRows();
            console.log("gridRowData ",gridRowData)
            var gridRowDataarr = []
            if(gridRowData.length > 0)
            {
                gridRowData.map((itm) => {
                    gridRowDataarr.push(itm)
                    // if(itm.container_no != "" && itm.pick_up_date != "" && itm.shipping_seal != "" && itm.container_no != undefined && itm.pick_up_date != undefined && itm.shipping_seal != undefined)
                    // {
                    //     gridRowDataarr.push(itm)
                    // }
                })
            }
            props.onselectedrows(gridRowDataarr)
            // if(gridRowDataarr.length > 0)
            // {
            //     props.onselectedrows(gridRowDataarr)
            // }
            setModifiedRownodes([])
        }
    }
//   useEffect(() => {
//     setModifiedRows(new Set())
//   },[modifiedRows])  
    // const handleCellValueChanged = (params) => {
    //     var pushUpdatenodes = []
    //     console.log(params.node,'61')
    //     pushUpdatenodes.push(params.node.id);
    //     updateCallBackNodes(pushUpdatenodes)
    //     console.log(pushUpdatenodes,'pushUpdatenodes')
    //     console.log(params.data._id)
    //     // const updatedRowData = [...updatedData];
    //     // const existingRow = updatedRowData.find((row) => row._id === params.data._id);
    //     // if (existingRow) {
    //     //   // If the row is already in updatedData, update the specific cell value.
    //     //   existingRow[params.colDef.field] = params.newValue;
    //     // } else {
    //     //   // If the row is not in updatedData, add the entire row with the updated cell value.
    //     //   updatedRowData.push({ ...params.data, [params.colDef.field]: params.newValue });
    //     // }
        
    //     // setUpdatedData(updatedRowData);
    //   };
    // Declare pushUpdatenodes array outside the handleCellValueChanged function.

const handleCellValueChanged = (params) => {
    params.api.selectNode(params.node, true, false);
    console.log("params",params.node.id)
    const modifiedNodes = new Set(modifiedRows);
    modifiedNodes.add(params.node);
    var id = params.node.id;
    setEditRowsIds(prevState => ([...prevState, id]));
    setModifiedRows(modifiedNodes);
    
  };

      const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
      }, []);
    
    var updateCallBackNodes = (pushUpdatenodes) =>{
        
        setModifiedRownodes(prevState =>({
            ...prevState,
            ["modifiedRownodes"] : pushUpdatenodes
        }));
        console.log("ronwdodes", modifiedRownodes)
    }
    return (
        <form onSubmit={onsubmitdata}>
        <div className="slide-body">
            <div className="row p-20p">
                <div className="form-group col-xl-12 col-lg-12">
                    <p className='mb-15p'><span>Work Order No: </span> {props.selected_row.work_order_no}</p>
                    <div id="idgrid" style={{ width: "100%", height: "250px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={props.columnwithdefs2}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            rowData={props.rowData1}
                            suppressRowClickSelection={true}
                            frameworkComponents={{}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            // enableRangeSelection={true}
                            paginationPageSize={50}
                            suppressPaginationPanel={true}
                            // rowStyle={{ display: 'none' }}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            ref={gridRef}
                            // onCellValueChanged={handleCellValueChanged}
                            onCellEditingStopped={handleCellValueChanged}
                            onGridReady={onGridReady}
                            context={{}}
                        />
                    </div>
                </div>

                <div className="form-group col-sm-12 mb-20p text-center">
                    <button type="submit" className="btn btn-success">Submit</button>
                </div>

            </div>
        </div>
    </form>
    )
}
export default UpdatecontainerGrid