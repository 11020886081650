import React, { useState } from 'react';
import Select, { components } from 'react-select';
import Option from './checkboxComponent';

const CustomSelect = ({ options, value, onChange }) => {
    return (
        <Select
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isMulti
            value={value}
            onChange={onChange}
            styles={{
                control: (provided) => ({
                    ...provided,
                    fontSize: "0.8rem",
                    color: "#6e707e",
                }),
                singleValue: (provided) => ({
                    ...provided,
                    fontSize: '0.9rem',
                }),
            }}
            options={options}
            components={{ Option }}
        />
    );
};
export default CustomSelect