import React from 'react';

const AcknowledgeBtnComponent = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // console.log(props, '888888');
        props.context.onClickAcknowledgeBtn(props);
    };

    const {
        update_ack,
        update_ib_ack,
        update_prod_ack,
        container_count_increase_check,
        cha_do_modified,
        tpt_trucks_modified,
        tpt_container_modified,
        ib_plant_gate_in_modified,
        at_plant_modified,
        shipping_modified,
        modified_plant,
        pod_changes_before_container_pickup,
        ship_to_country_changes_before_container_pickup
    } = props.data;

    const shouldHideButton = update_ack === 1 || update_ib_ack === 1 || (modified_plant === 0 || modified_plant === "undefined") ||  
                             update_prod_ack === 1 || 
                             (container_count_increase_check === 1 && (cha_do_modified === 1 || tpt_trucks_modified === 1 || tpt_container_modified === 1 || ib_plant_gate_in_modified === 1 || at_plant_modified === 1 || shipping_modified === 1));

    return (
        <div>
            {!shouldHideButton && (
                <button
                    style={{ color: "#fff" }}
                    onClick={handleRouteClick}
                    className="btn btn-primary custom-btn f12 label label-success"
                    title=""
                >
                    Acknowledge
                </button>
            )}
        </div>
    );
};

export default AcknowledgeBtnComponent;
