import React, { useState, useEffect, useRef, useReducer,useCallback } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
const ShippingonboardGrid = (props)=>{
    const gridApi = useRef(null);
    // var [gridApi, setGridApi] = useState(null);
    // var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    const [updatedData, setUpdatedData] = useState([]);
    useEffect(() => {
        if (gridRef.current) {
            // setGridApi(gridRef.current.api);
            // setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    // const handleCellValueChanged = (params) => {
    //     console.log(params.data);
    //     if (gridApi) {
    //         gridApi.stopEditing();
    //          const gridRowData = [];
    //         gridApi.forEachNode((node) => {
    //              console.log("node ",node)
    //             gridRowData.push(node.data);
    //         });
    //         setUpdatedData(gridRowData)
    //     }
    // };
    const onsubmitdata = (f)=>{
        f.preventDefault()
        console.log(updatedData,'updatedData')
        if (gridApi.current) {
            gridApi.current.stopEditing();
            const gridRowData = [];
            gridApi.current.forEachNode((node) => {
                // console.log("node ",node)
                gridRowData.push(node.data);
            });
            console.log("gridRowData ",gridRowData)
            props.onselectedrows(gridRowData)
        }
    }
    const handleCellValueChanged = (params) => {
        console.log(params.data._id)
        const updatedRowData = [...updatedData];
        const existingRow = updatedRowData.find((row) => row._id === params.data._id);
        if (existingRow) {
          // If the row is already in updatedData, update the specific cell value.
          existingRow[params.colDef.field] = params.newValue;
        } else {
          // If the row is not in updatedData, add the entire row with the updated cell value.
          updatedRowData.push({ ...params.data, [params.colDef.field]: params.newValue });
        }
        
        setUpdatedData(updatedRowData);
      };
      const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
      }, []);
    
     
    return (
        <form onSubmit={onsubmitdata}>
        <div className="slide-body">
            <div className="row p-20p">
                <div className="form-group col-xl-12 col-lg-12">
                    <p className='mb-15p'><span>Work Order No: </span> {props.selected_row.work_order_no}</p>
                    <div id="idgrid" style={{ width: "100%", height: "250px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={props.columnwithdefs2}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            rowData={props.rowData1}
                            suppressRowClickSelection={true}
                            frameworkComponents={{}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            suppressPaginationPanel={true}
                            // rowStyle={{ display: 'none' }}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            ref={gridRef}
                            onCellValueChanged={handleCellValueChanged}
                            onGridReady={onGridReady}
                            context={{}}
                        />
                    </div>
                </div>

                <div className="form-group col-sm-12 mb-20p text-center">
                    <button type="submit" className="btn btn-success">Submit</button>
                </div>

            </div>
        </div>
    </form>
    )
}
export default ShippingonboardGrid