import React, { Component } from "react";
import Select from "react-select";
import redirectURL from "../redirectURL";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import "./shipmentDetails.css";
import CSVFileValidator from "csv-file-validator";
import {
  getYYYYMMDDHHMMSS,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMMYYYY,
} from "../utils";
import withRouter from "../withRouter";
import { useParams } from "react-router-dom";

class ShipmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      basicTitle: "",
      carrierType:"",
      basicType: "default",
      defaultColDef: {
        minWidth: 100,
        filter: true,
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
        menuTabs: ['filterMenuTab']
      },
      paginationPageSize: 200,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            { field: "callId" },
            { field: "direction" },
            { field: "number", minWidth: 150 },
            { field: "duration", valueFormatter: "x.toLocaleString() + 's'" },
            { field: "switchCode", minWidth: 150 },
          ],
          defaultColDef: {
            flex: 1,
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.callRecords);
        },
      },
      rowPOData: [],
      rowVendorData: [],
      rowBidPreviewData: [],
      rowParticipantsData: [],
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      viewBookingDetails: "show-m",
      viewRouteForm: "show-n",
      viewProductDetailsForm: "show-n",
      viewInviteVendorsForm: "show-n",
      viewPODetailsGrid: "show-m",
      viewVendorDetailsGrid: "show-m",
      viewBidPreviewGrid: "show-m",
      viewParticipantsGrid: "show-m",
      rfqItemCode: "",
      deliveryLocation: "",
      rfqItemRemarks: "",
      tatDays: "",
      poNumber: "",
      unitRate: "",
      searchVendorName: [],
      poDate: "",
      poValue: "",
      activeStep: 1,
      vendorGroup: { value: "", label: "" },
      vendorGroupOptions: [],
      inviteVendorName: [],
      selectedVendorListForInvitation: [],
      vendorNameOptions: [{ value: "", label: "Select" }],
      approverNameOptions: [{ value: "", label: "Select" }],
      finalRemarks: "",
      isComAccActive: false,
      isAttAccActive: false,
      isQuesAccActive: false,
      // isFormSubmitted: 0,
      isIdGenerated: false,
      isTCDocChanged: false,
      isAttachmentsDocChanged: false,
      isCommercialTermsDocChanged: false,
      sliderRso: "",
      overlay: "show-n",
      loadshow: "show-n",
      tabActive: "booking",
      showBookingData: "show-m",
      bookingEventId: "",

      shipmentName: "",
      shipmentPO: "",
      shipmentReadyDate: "",
      shipmentMode: { value: "", label: "Select" },
      incoTerms: [{ value: "", label: "" }],
      shipmentHSCodes: "",
      originConsigner: "",
      originAddress1: "",
      originAddress2: "",
      originCountry: "",
      originPostalCode: "",
      deliveryConsignee: "",
      deliveryAddress1: "",
      deliveryAddress2: "",
      deliveryCountry: "",
      deliveryPostalCode: "",
      allProductsData: [],
      productDetails: {
        _id:"",
        productName: "",
        productDescription: "",
        productType:"",
        productWeight: "",
        productValue: "",
        productLength: "",
        productWidth: "",
        productHeight: "",
        productCbm: "0"
      },
      shipment_event_id: "",
      commercialShipment: false,
      hazardousShipment: false,
      quoteInsurance: false,
      quoteCustoms: false,
      checkedbox:"",
      checkedInsurance: "",
      checkedCustoms: "",
      AllproductKeys:[],
      isInputChanged: false,
      isRouteInputChanged: false,
      isProductInputChanged: false,
      productsCount: 0,
      weightsCount: 0,
      cbmCount: 0,
      valueCount: 0.0
    };

    // this.state = {
    //   activeStep: 1,
    //   approvers: [],
    //   attachmentTitle: "",
    //   attachmentsDoc: "",
    //   attachmentsDocArr: [],
    //   basicTitle: "",
    //   basicType: "default",
    //   commercialTermsDoc: "",
    //   commercialTermsDocArr: [],
    //   commercialTermsTitle: "",
    //   commercialTermsTitleArr: [],
    //   conversionRate: "",
    //   currency: { value: "", label: "Select" },
    //   defaultColDef: {
    //       autoHeaderHeight: true,
    //       filter: true,
    //       initialWidth: 200,
    //       minWidth: 100,
    //       resizable: true,
    //       sortable: true,
    //       wrapHeaderText: true,
    //   },
    //   deliveryLocation: "",
    //   detailCellRendererParams: {
    //       detailGridOptions: {
    //           columnDefs: [
    //               { field: "callId" },
    //               { field: "direction" },
    //               { field: "number", minWidth: 150 },
    //               { field: "duration", valueFormatter: "x.toLocaleString() + 's'" },
    //               { field: "switchCode", minWidth: 150 },
    //           ],
    //           defaultColDef: {
    //               flex: 1,
    //           },
    //       },
    //       getDetailRowData: (params) => {
    //           params.successCallback(params.data.callRecords);
    //       },
    //   },
    //   filters: {
    //       toolPanels: [
    //           {
    //               id: "columns",
    //               iconKey: "columns",
    //               labelDefault: "Columns",
    //               labelKey: "columns",
    //               toolPanel: "agColumnsToolPanel",
    //           },
    //           {
    //               id: "filters",
    //               iconKey: "filter",
    //               labelDefault: "Filters",
    //               labelKey: "filters",
    //               toolPanel: "agFiltersToolPanel",
    //           },
    //       ],
    //   },
    //   inviteVendorName: [],
    //   isAttAccActive: false,
    //   isCommercialTermsDocChanged: false,
    //   isFormSubmitted: 0,
    //   isIdGenerated: false,
    //   isTCDocChanged: false,
    //   loadshow: "show-n",
    //   overlay: "show-n",
    //   paginationPageSize: 200,
    //   poDate: "",
    //   poNumber: "",
    //   poValue: "",
    //   questionsArr: [],
    //   questionTitle: "",
    //   rfqDesc: "",
    //   rfqEndDate: "",
    //   rfqEventId: "",
    //   rfqItem: "",
    //   rfqItemCode: "",
    //   rfqItemDesc: "",
    //   rfqItemQuantity: "",
    //   rfqItemRemarks: "",
    //   rfqItemUOM: { value: "", label: "" },
    //   rfqStartDate: "",
    //   rfqSubject: "",
    //   rfqTargetPrice: "",
    //   rowBidPreviewData: [],
    //   rowParticipantsData: [],
    //   rowPOData: [],
    //   rowVendorData: [],
    //   searchVendorName: [],
    //   selectedVendorListForInvitation: [],
    //   shipmentName: '',
    //   sideBar: {
    //       toolPanels: [
    //           {
    //               id: "columns",
    //               iconKey: "columns",
    //               labelDefault: "Columns",
    //               labelKey: "columns",
    //               toolPanel: "agColumnsToolPanel",
    //           },
    //           {
    //               id: "filters",
    //               iconKey: "filter",
    //               labelDefault: "Filters",
    //               labelKey: "filters",
    //               toolPanel: "agFiltersToolPanel",
    //           },
    //       ],
    //   },
    //   showBookingData: "show-m",
    //   sliderRso: "",
    //   tabActive: "booking",
    //   tcDoc: "",
    //   tcDocFileUrl: "",
    //   tatDays: "",
    //   technicalApproval: { value: "", label: "" },
    //   unitRate: "",
    //   vendorGroup: { value: "", label: "" },
    //   vendorGroupOptions: [],
    //   vendorNameOptions: [{ value: "", label: "Select" }],
    //   viewAdditionalDetailsForm: "show-n",
    //   viewBidPreviewGrid: "show-m",
    //   viewInviteVendorsForm: "show-n",
    //   viewParticipantsGrid: "show-m",
    //   viewPODetailsGrid: "show-n",
    //   viewRFQDetailsForm:"show-m"

    // }
    //
  }

  componentDidMount = async () => {
    // debugger
    let shipment_event_id = this.props.match.params.id;
    let userEmail = localStorage.getItem("email");

    // let shipmentNumber = id.split("-")[1];
    // let carrierType = id.split("-")[0];
    this.setState({
      shipment_event_id: shipment_event_id,
      email: userEmail,
      // carrierType: carrierType
    })
    let payload = {
      requirement_id: shipment_event_id,
      // carrierType: carrierType
    };    
    
    this.setState({
      allProductsData: this.state.allProductsData.concat(
        this.state.productDetails
      ),
    });

    // get shipment complete details
    await redirectURL
      .post("/exim/getshipmentdetails", payload)
      .then((resp) => {
        let bookingDetails = resp.data.bookingDetails[0];
        console.log("Booking details :", bookingDetails)
        const {
          shipment_name,
          shipment_po,
          shipment_ready_date,
          shipment_mode,
          shipment_incoterms,
          shipment_hs_codes,
          commercial_shipment,
          hazardous_shipment,
          quote_insurance,
          quote_customs,
          // routing variables start here
          origin_consigner,
          origin_address1,
          origin_address2,
          origin_country,
          origin_postalcode,
          delivery_consignee,
          delivery_address1,
          delivery_address2,
          delivery_country,
          delivery_postalcode
          
        } = bookingDetails;
        this.setState({
          shipmentName: shipment_name,
          shipmentPO: shipment_po,
          shipmentReadyDate: shipment_ready_date,
          shipmentMode: shipment_mode,
          incoTerms: shipment_incoterms,
          shipmentHSCodes: shipment_hs_codes,
          commercialShipment: commercial_shipment,
          hazardousShipment: hazardous_shipment,
          quoteInsurance: quote_insurance,
          quoteCustoms: quote_customs,
          originConsigner: origin_consigner,
          originAddress1: origin_address1,
          originAddress2: origin_address2,
          originCountry: origin_country,
          originPostalCode: origin_postalcode,
          deliveryConsignee: delivery_consignee,
          deliveryAddress1: delivery_address1,
          deliveryAddress2: delivery_address2,
          deliveryCountry: delivery_country,
          deliveryPostalCode: delivery_postalcode,
        });
        
      })
      .catch((err) => {
        console.log("getBookingDetailsId", err);
      });
      
    await this.getProductDataForBooking();
  }

  // Booking details filter function
  newFilter = (filterType, selOption) => {
    this.setState({ [filterType]: selOption, isInputChanged: true });
  };


  // handleShipmentTypeCheckboxChange = (checkboxType) => {
  //   console.log(checkboxType);
  //   this.setState({
  //     Shipment_Type_CheckBoxes: {
  //       ...this.state.Shipment_Type_CheckBoxes,
  //       [checkboxType]: !this.state.Shipment_Type_CheckBoxes[checkboxType],
  //     },
  //     isInputChanged: true,
  //   });
  //   console.log(
  //     "this.state.Shipment_Type_CheckBoxes[checkboxType]",
  //     this.state.Shipment_Type_CheckBoxes[checkboxType]
  //   );
  // };
  // handleQuoteCheckboxChange = (checkboxQuote) => {
  //   console.log(checkboxQuote);
  //   this.setState({
  //     Quote_CheckBoxes: {
  //       ...this.state.Quote_CheckBoxes,
  //       [checkboxQuote]: !this.state.Quote_CheckBoxes[checkboxQuote],
  //     },
  //     isInputChanged: true,
  //   });
  //   console.log(
  //     "this.state.Quote_CheckBoxes[checkboxQuote]",
  //     this.state.Quote_CheckBoxes[checkboxQuote]
  //   );
  // };

  //commercial checkbox handle
  commercialShipmentHandle = () => {
    this.setState({
      commercialShipment: !this.state.commercialShipment,
      isInputChanged:true
    })
  }

  // hazardous checkbox handle
  hazardousShipmentHandle = () => {
    this.setState({
      hazardousShipment: !this.state.hazardousShipment,
      isInputChanged:true
    })
  }

  //quoteInsurance checkbox handle
  quoteInsuranceHandle = () => {
    this.setState({
      quoteInsurance: !this.state.quoteInsurance,
      isInputChanged:true
    })
  }

  // quoteCustom checkbox handle
  quoteCustomHandle = () => {
    this.setState({
      quoteCustoms: !this.state.quoteCustoms,
      isInputChanged:true
    })
  }
  // product info block filter function
  productFilter = (filterType, index, selOption) => {
    this.setState((prevState) => {
      const newState = [...prevState.allProductsData];
      newState[index] = {
        ...newState[index],
        [filterType]: selOption,
      };
      return { allProductsData: newState };
    });
    
  };

  // Can be used to display products that has been added
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  // changeTCFileHandler(event) {
  //   const file = event.target.files[0];
  //   const previousFile = this.state.tcDoc;
  //   if (file && file !== previousFile) {
  //     this.setState({
  //       isTCDocChanged: true,
  //       tcDoc: file,
  //     });
  //   } else {
  //     this.setState({
  //       isTCDocChanged: false,
  //     });
  //   }
  //   // this.setState({
  //   //     previousTCDoc: file,
  //   //     // tcDocFileUrl: fileUrl
  //   // })
  // }

  // changeCommercialTermsFileHandler(event) {
  //   const file = event.target.files[0];
  //   const previousFile = this.state.commercialTermsDoc;
  //   if (file && file !== previousFile) {
  //     this.setState({
  //       isCommercialTermsDocChanged: true,
  //       commercialTermsDoc: file,
  //     });
  //   } else {
  //     this.setState({
  //       isCommercialTermsDocChanged: false,
  //     });
  //   }
  // }

  // changeAttachmentsFileHandler(event) {
  //   const file = event.target.files[0];
  //   const previousFile = this.state.attachmentsDoc;
  //   if (file && file !== previousFile) {
  //     this.setState({
  //       isAttachmentsDocChanged: true,
  //       attachmentsDoc: file,
  //     });
  //   } else {
  //     this.setState({
  //       isAttachmentsDocChanged: false,
  //     });
  //   }
  // }

  

  
// not used anywhere
  handleVendorAddBtnClick() {
    if (this.state.inviteVendorName) {
      var invite_vendor_list = this.state.inviteVendorName.map((e) => e.value);

      const formData = new FormData();
      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append("vendor_list", JSON.stringify(invite_vendor_list));

      redirectURL
        .post("/procurement/saverfqinvitevendorinfo", formData)
        .then((response) => {
          if (response.data.status === "success") {
            var row_data = [];
            response.data.rfq_invite_vendor_list.map((vendor_list) => {
              row_data.push({
                invite_vendor_name: vendor_list.company_name,
                invite_vendor_email: vendor_list.email,
                invite_vendor_id: vendor_list.vendor_id,
                is_invitation_sent: vendor_list.is_invitation_sent
                  ? "Sent"
                  : "Not Sent",
              });
            });
            this.setState({
              rowVendorData: row_data,
            });
          }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }
// not used anywhere
  onVendorRowSelected(e) {
    if (e.node.selected) {
      this.setState((prevState, props) => ({
        selectedVendorListForInvitation: [
          ...prevState.selectedVendorListForInvitation,
          {
            vendor_id: e.node.data.invite_vendor_id,
            email: e.node.data.invite_vendor_email,
          },
        ],
      }));
    } else {
      this.setState((prevState, props) => ({
        selectedVendorListForInvitation:
          prevState.selectedVendorListForInvitation.filter(
            (arr) => arr.vendor_id != e.node.data.invite_vendor_id
          ),
      }));
    }
  }
// not used anywhere
  sendVendorInvite(e) {
    if (this.state.selectedVendorListForInvitation) {
      var selected_vendor_list = this.state.selectedVendorListForInvitation;
      const formData = new FormData();
      formData.append("rfq_event_id", this.state.rfqEventId);
      formData.append(
        "selected_vendor_list",
        JSON.stringify(selected_vendor_list)
      );

      redirectURL
        .post("/procurement/sendrfqvendorinvite", formData)
        .then((response) => {
          if (response.data.status === "success") {
            var row_data = [];
            response.data.rfq_invite_vendor_list.map((vendor_list) => {
              row_data.push({
                invite_vendor_name: vendor_list.company_name,
                invite_vendor_email: vendor_list.email,
                invite_vendor_id: vendor_list.vendor_id,
                is_invitation_sent: vendor_list.is_invitation_sent
                  ? "Sent"
                  : "Not Sent",
              });
            });
            this.setState({
              rowVendorData: row_data,
            });
          }
        });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    }
  }
// not used anywhere
  handleInviteVendorsBackBtnClick() {
    this.setState({
      activeStep: 3,
      viewRouteForm: "show-n",
      viewInviteVendorsForm: "show-n",
      viewProductDetailsForm: "show-m",
    });
  }
// not used anywhere
  handleBidPreviewBtnClick() {
    this.setState({
      sliderTranslate: "slider-translate-70p",
    });
  }

  handleBookingStartDateTime = (event) => {
    var d = new Date(event._d);
    var shipmentReadyDate = new Date(getHyphenYYYYMMDDHHMMSS(d));
    var rfqenddate = new Date(this.state.rfqEndDate);
    var now = new Date();
    if (shipmentReadyDate) {
      if (shipmentReadyDate < now) {
        this.setState({
          shipmentReadyDate: getHyphenYYYYMMDDHHMMSS(now),
          isInputChanged: true,
        });
      } else if (rfqenddate < shipmentReadyDate) {
        this.setState({
          rfqEndDate: getHyphenYYYYMMDDHHMMSS(shipmentReadyDate),
          shipmentReadyDate: getHyphenYYYYMMDDHHMMSS(d),
          isInputChanged: true,
        });
      } else {
        this.setState({
          shipmentReadyDate: getHyphenYYYYMMDDHHMMSS(d),
          isInputChanged: true,
        });
      }
    } else {
      this.setState({ shipmentReadyDate: "" });
    }
  };
  // Booking details saved on clicking save and next button function
  handleBookingNextBtnClick() {
    // debugger
    var shipmentName = this.state.shipmentName; // mandatory
    var shipmentPO = this.state.shipmentPO; // mandatory
    var shipmentReadyDate = this.state.shipmentReadyDate; // mandatory
    var shipmentMode = this.state.shipmentMode.value; // mandatory

    if (!shipmentName || !shipmentPO || !shipmentReadyDate || !shipmentMode) {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Fields cannot be empty",
      });
    } else {
      this.setState({
        viewBookingDetails: "show-n",
        viewRouteForm: "show-m",
        activeStep: 2,
      });
      let payload = {
        shipment_name: this.state.shipmentName,
        shipment_po: this.state.shipmentPO,
        shipment_ready_date: this.state.shipmentReadyDate,
        shipment_mode: this.state.shipmentMode,
        shipment_incoterms: this.state.incoTerms,
        shipment_hs_codes: this.state.shipmentHSCodes,
        commercial_shipment: this.state.commercialShipment,
        hazardous_shipment: this.state.hazardousShipment,
        quote_insurance: this.state.quoteInsurance,
        quote_customs: this.state.quoteCustoms,
        shipment_event_id: this.state.shipment_event_id,
      };
      if (this.state.isInputChanged) {
        redirectURL
          .post("/exim/savebookingdetails", payload)
          .then((resp) => {
            console.log("savebookingdetails", resp.data);
          })
          .catch((err) => {
            console.log("update the booking Details is failed", err);
          });
      }
    }
  }

  // Route back button
  handleRouteBackBtnClick() {
    this.setState({
      activeStep: 1,
      viewBookingDetails: "show-m",
      viewRouteForm: "show-n",
    });
  }

  // Route save details on Save and Next Button
  handleRouteNextBtnClick() {
    let payload = {
      shipment_event_id: this.state.shipment_event_id,
      origin_consigner: this.state.originConsigner,
      origin_address1: this.state.originAddress1,
      origin_address2: this.state.originAddress2,
      origin_country: this.state.originCountry,
      origin_postalcode: this.state.originPostalCode,
      delivery_consignee: this.state.deliveryConsignee,
      delivery_address1: this.state.deliveryAddress1,
      delivery_address2: this.state.deliveryAddress2,
      delivery_country: this.state.deliveryCountry,
      delivery_postalcode: this.state.deliveryPostalCode,
    };
    if (this.state.isRouteInputChanged) {
      console.log("Route inputs are changed----------------------------------------------------------------------------")
      redirectURL
        .post("/exim/saveshipmentroutedetails", payload)
        .then((resp) => {
          console.log("resp.data", resp.data);
        })
        .catch((err) => {
          console.log("error message", err);
        });
    }
    this.setState({
      activeStep: 3,
      viewRouteForm: "show-n",
      viewProductDetailsForm: "show-m",
    });
  }

  // new id assigned to product adding functionality
  
  handleAddBlockForProductInfo() {
    this.setState(prevState => ({
      allProductsData: prevState.allProductsData.concat(this.state.productDetails),
    }));
  }
  

  // Product Back Button
  handleProductBackBtnClick() {
    this.setState({
      activeStep: 2,
      viewProductDetailsForm: "show-n",
      viewRouteForm: "show-m",
    });
  }
 

 

 
  updateWeightCount = () => {
    var totalWeight = 0;
    this.state.allProductsData.forEach((eachKey) => {
      let { productWeight } = eachKey;
      if (productWeight) {
        productWeight = parseInt(productWeight);
        totalWeight += productWeight;
      }
    });
    this.setState({
      weightsCount: totalWeight,
    });
  };

  updateValueCount = () => {
    var totalValue = 0;
    this.state.allProductsData.forEach((eachKey) => {
      let { productValue } = eachKey;
      if (productValue) {
        productValue = parseInt(productValue);
        totalValue += productValue;
      }
    });
    this.setState({
      valueCount: totalValue,
    });
  };

  // product details are fetched onRefresh
  getProductDataForBooking = async () => {
    let data = {
      requirement_id: this.state.shipment_event_id,
    };
    await redirectURL
      .post("/exim/getProductDataForBooking", data)
      .then((resp) => {
        console.log("getProductDataForBooking", resp.data);
        if(resp.data.productsData.length > 0){
          let productsData = resp.data.productsData[0].productDetails;
          let modifiedData = productsData.map((each) => 
          {
            let dummyData = {}
            dummyData._id = each._id;
            dummyData.productDescription = each.product_description;
            dummyData.productName = each.product_name;
            dummyData.productType = each.product_type;
            dummyData.productWeight = each.product_weight;
            dummyData.productValue = each.product_value;
            dummyData.productLength = each.product_length;
            dummyData.productWidth = each.product_width;
            dummyData.productHeight = each.product_height;
            dummyData.productCbm = each.product_cbm;
  
            let option = {
              label: each.product_type,
              value: each.product_type,
            };
            dummyData.productType = option;
            return dummyData;
          });
          this.setState(
            {
              allProductsData: modifiedData,
            },
            () => {
              this.updateWeightCount();
              this.updateCbmCount();
              this.updateValueCount();
            }
          );
        }else if(resp.data.productsData.length === 0){
          this.setState({
            allProductsData: []
          })
        }
        
      })
      .catch((err) => {
        console.log("getProductDataForContainerNum err", err);
      });
  };

  // product input changes handler
  handleOnChangeForProducts = (index, keyValue, changedValue) => {
    console.log("index",index);
    console.log("keyValue",keyValue);
    let volumeArr = ["productLength", "productWidth", "productHeight"];
    this.setState(
      (prevState) => {
        const newState = [...prevState.allProductsData];
        newState[index] = {
          ...newState[index],
          [keyValue]: changedValue,
          // product_valuesChanged:true
        };
        return { allProductsData: newState };
      },
      () => {
        console.log("changed data",this.state.allProductsData[index]);
        if (volumeArr.includes(keyValue)) {
          this.updateCubicMeterCount(index);
          this.updateCbmCount();
        }
        // // count container box 
        // if (keyValue === "productCbm") {
          
        // }

        if (keyValue === "productWeight") {
          this.updateWeightCount();
        }

        if (keyValue === "productValue") {
          this.updateValueCount();
        }
      }
    );
  };

   // product meausurement functions 
   updateCubicMeterCount = async (outerIndex) => {
    let { productLength, productWidth, productHeight } =
      this.state.allProductsData[outerIndex];
    if (productLength && productWidth && productHeight) {
      var volume = 0;
      productLength = parseFloat(productLength);
      productWidth = parseFloat(productWidth);
      productHeight = parseFloat(productHeight);
      volume = productLength * productWidth * productHeight;
      volume = volume / 1000000;
      // this.setState({
      //   productCbm : volume
      // })
      this.handleOnChangeForProducts(outerIndex, "productCbm", volume);
    }
  };

  // count container box 
  updateCbmCount = () => {
    var totalVolume = 0;
    this.state.allProductsData.forEach((eachData) => {
      let { productLength, productWidth, productHeight } = eachData;
      if (productLength && productWidth && productHeight) {
        productLength = parseFloat(productLength);
        productWidth = parseFloat(productWidth);
        productHeight = parseFloat(productHeight);
        let volume = productLength * productWidth * productHeight;
        totalVolume = totalVolume + volume;
      }
    });
    totalVolume = totalVolume / 1000000;
    this.setState({
      cbmCount: totalVolume,
    });
  };
  
  // remove product form handler 
  removeProductAndUpdateCounters = (index) => {
    let allProducts = this.state.allProductsData;
    let productToBeDeleted = this.state.allProductsData[index];
    if (productToBeDeleted._id === "") {
      const updatedArray = [
        ...allProducts.slice(0, index),
        ...allProducts.slice(index + 1),
      ];
      this.setState({ allProductsData: updatedArray }, () => {
        this.updateCbmCount();
        this.updateWeightCount();
        this.updateValueCount();
      });
    } else {
      let payload = {
        _id: productToBeDeleted._id,
      };
      redirectURL
        .post("/exim/deleteBookingProduct", payload)
        .then((resp) => {
          console.log("deleteProduct", resp.data);
          if (resp.data.success) {
            this.setState(
              {
                show: true,
                basicType: "success",
                basicTitle: resp.data.message,
              },
              () => {
                this.getProductDataForBooking();
              }
            );
          }
        })
        .catch((err) => {
          console.log("deleteProduct err", err);
        });
    }
    // console.log("data",data);
  };

  // save product form handler
  saveAndUpdateProductData = async (index) => {
    console.log("index",index);
    // let productData = this.state.allProductsData[index];
    // console.log("productData", this.state.allProductsData);

    let {
      _id,
      productDescription,
      productType,
      productName,
      productWeight,
      productValue,
      productLength,
      productWidth,
      productHeight,
      productCbm,
    } = this.state.allProductsData[index];
    console.log("this.state.allProductsData[index]",this.state.allProductsData[index]);

    let payload = {
      requirement_id: this.state.shipment_event_id,
      email: this.state.email,
      _id,
      product_description: productDescription,
      product_type: productType.value,
      product_name: productName,
      product_weight: productWeight,
      product_value: productValue,
      product_length: productLength,
      product_width: productWidth,
      product_height: productHeight,
      product_cbm: productCbm,
    };

    console.log("productData at index", payload);

    if (productName === "") {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Product name should not be empty",
      });
    } else {
      await redirectURL
        .post("/exim/saveAndUpdateBookingProductData", payload)
        .then((resp) => {
          if (resp.data.success) {
            this.setState(
              {
                show: true,
                basicType: "success",
                basicTitle: resp.data.message,
              },
              async () => {
                this.getProductDataForBooking();
              }
            );
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: resp.data.message,
            });
          }
          console.log("saveAndUpdateProductData", resp.data);
        })
        .catch((err) => {
          console.log("saveAndUpdateProductData err", err);
        });
    }
  };
  
  // Product onClicking preview
  handleProductPreviewBtn() {
    this.setState({
      sliderTranslate: "slider-translate-70p",
    });
    // let payload = {
    //   shipment_event_id: this.state.shipment_event_id,
    //   all_products_data: this.state.allProductsData,
    // };
    // console.log("product payload:", payload)

    //   redirectURL
    //     .post("/exim/updateproductdetails", payload)
    //     .then((resp) => {
    //       console.log("resp.data", resp.data);
    //     })
    //     .catch((e) => {
    //       console.log("error while updating the product", e);
    //     });
  }

  handleCancelBidBtnClick() {}

  handleAddApproversBtnClick() {}

  handleExportExcelBtnClick() {}

  handleImportExcelBtnClick() {
    this.setState({
      overlay: "show-m",
      sliderRso: "slider-translate",
    });
  }

  // handleAddRFQItemBtnClick() {
  //   var rfqItem = this.state.rfqItem;
  //   var rfqItemDesc = this.state.rfqItemDesc;
  //   var rfqItemQuantity = this.state.rfqItemQuantity;
  //   var rfqItemRemarks = this.state.rfqItemRemarks;
  //   var rfqItemCode = this.state.rfqItemCode;
  //   var rfqItemUom = this.state.rfqItemUOM.value;
  //   var rfqItemTargetPrice = this.state.rfqTargetPrice;
  //   var rfqItemDeliveryLocation = this.state.deliveryLocation;
  //   var rfqItemTatDays = this.state.tatDays;
  //   var rfqItemPoNumber = this.state.poNumber;
  //   var rfqItemPoDate = this.state.poDate;
  //   var rfqItemSearchVendorName = JSON.stringify(
  //     this.state.searchVendorName.map((e) => e.label)
  //   );
  //   var rfqItemUnitRate = this.state.unitRate;
  //   var rfqItemPoValue = this.state.poValue;

  //   if (rfqItem && rfqItemDesc && rfqItemQuantity && rfqItemDeliveryLocation) {
  //     const formData = new FormData();

  //     formData.append("rfq_event_id", this.state.rfqEventId);
  //     // formData.append("rfqItem", rfqItem);
  //     // formData.append("rfqItemDesc", rfqItemDesc);
  //     // formData.append("rfqItemQuantity", rfqItemQuantity);
  //     // formData.append("rfqItemRemarks", rfqItemRemarks);
  //     // formData.append("rfqItemCode", rfqItemCode);
  //     // formData.append("rfqItemUom", rfqItemUom);
  //     // formData.append("rfqItemTargetPrice", rfqItemTargetPrice);
  //     // formData.append("rfqItemDeliveryLocation", rfqItemDeliveryLocation);
  //     // formData.append("rfqItemTatDays", rfqItemTatDays);
  //     // formData.append("rfqItemPoNumber", rfqItemPoNumber);
  //     // formData.append("rfqItemPoDate", rfqItemPoDate);
  //     // formData.append("rfqItemSearchVendorName", rfqItemSearchVendorName);
  //     // formData.append("rfqItemUnitRate", rfqItemUnitRate);
  //     // formData.append("rfqItemPoValue", rfqItemPoValue);
  //     // formData.append("is_form_submitted", this.state.isFormSubmitted);

  //     redirectURL
  //       .post("/procurement/saverfqitemdetailsinfo", formData)
  //       .then((response) => {
  //         if (response.data.status === "success") {
  //           this.state.rowPOData = this.state.rowPOData.filter(
  //             (obj) => obj.rfq_item_code != response.data.rfq_item_code
  //           );
  //           var rowData = [
  //             {
  //               rfq_item: rfqItem,
  //               rfq_item_description: rfqItemDesc,
  //               rfq_item_quantity: rfqItemQuantity,
  //               rfq_item_remarks: rfqItemRemarks,
  //               rfq_item_code: rfqItemCode,
  //               rfq_item_uom: rfqItemUom,
  //               rfq_item_target_price: rfqItemTargetPrice,
  //               rfq_item_delivery_location: rfqItemDeliveryLocation,
  //               rfq_item_tat_days: rfqItemTatDays,
  //               rfq_item_po_number: rfqItemPoNumber,
  //               rfq_item_po_date: rfqItemPoDate,
  //               rfq_item_search_vendor_name: this.state.searchVendorName
  //                 .map((e) => e.label)
  //                 .toString(),
  //               rfq_item_unit_rate: rfqItemUnitRate,
  //               rfq_item_po_value: rfqItemPoValue,
  //             },
  //           ];
  //           this.setState({
  //             rowPOData: [...this.state.rowPOData, ...rowData],
  //           });
  //         }
  //       });
  //   }
  // }

  // handleAddCommercialTermsBtn(e) {
  //   e.preventDefault();
  //   if (this.state.commercialTermsTitle && this.state.commercialTermsDoc) {
  //     const formData = new FormData();
  //     formData.append("rfq_event_id", this.state.rfqEventId);
  //     formData.append("rfq_title", this.state.commercialTermsTitle);
  //     formData.append("rfq_doc", this.state.commercialTermsDoc);
  //     formData.append("is_doc_changed", this.state.isCommercialTermsDocChanged);
  //     formData.append("procure_rfq_table", "procure_rfq_commercial_terms");

  //     redirectURL
  //       .post("/procurement/saverfqadditionaldetailsinfo", formData)
  //       .then((response) => {
  //         if (response.data.status === "success") {
  //           if (response.data.rfq_title) {
  //             this.state.commercialTermsDocArr =
  //               this.state.commercialTermsDocArr.filter(
  //                 (obj) =>
  //                   obj.rfq_commercial_terms_title != response.data.rfq_title
  //               );
  //             let commercialTermsDocArr = [
  //               {
  //                 rfq_commercial_terms_title: response.data.rfq_title,
  //                 rfq_commercial_terms_doc: response.data.rfq_file_url,
  //               },
  //             ];
  //             this.setState({
  //               commercialTermsDocArr: [
  //                 ...this.state.commercialTermsDocArr,
  //                 ...commercialTermsDocArr,
  //               ],
  //             });
  //           }
  //         }
  //       });
  //   } else {
  //     this.setState({
  //       show: true,
  //       basicType: "danger",
  //       basicTitle: "Fields cannot be empty",
  //     });
  //   }
  // }

  // handleAddAttachmentsBtn(e) {
  //   e.preventDefault();
  //   if (this.state.attachmentTitle && this.state.attachmentsDoc) {
  //     const formData = new FormData();
  //     formData.append("rfq_event_id", this.state.rfqEventId);
  //     formData.append("rfq_title", this.state.attachmentTitle);
  //     formData.append("rfq_doc", this.state.attachmentsDoc);
  //     formData.append("is_doc_changed", this.state.isAttachmentsDocChanged);
  //     formData.append("procure_rfq_table", "procure_rfq_attachments");

  //     redirectURL
  //       .post("/procurement/saverfqadditionaldetailsinfo", formData)
  //       .then((response) => {
  //         if (response.data.status === "success") {
  //           if (response.data.rfq_title) {
  //             this.state.attachmentsDocArr =
  //               this.state.attachmentsDocArr.filter(
  //                 (obj) => obj.rfq_attachments_title != response.data.rfq_title
  //               );
  //             let attachmentsDocArr = [
  //               {
  //                 rfq_attachments_title: response.data.rfq_title,
  //                 rfq_attachments_doc: response.data.rfq_file_url,
  //               },
  //             ];
  //             this.setState({
  //               attachmentsDocArr: [
  //                 ...this.state.attachmentsDocArr,
  //                 ...attachmentsDocArr,
  //               ],
  //             });
  //           }
  //         }
  //       });
  //   } else {
  //     this.setState({
  //       show: true,
  //       basicType: "danger",
  //       basicTitle: "Fields cannot be empty",
  //     });
  //   }
  // }

  // handleAddQuestionsBtn(e) {
  //   e.preventDefault();
  //   if (this.state.questionTitle) {
  //     const formData = new FormData();
  //     formData.append("rfq_event_id", this.state.rfqEventId);
  //     formData.append("rfq_title", this.state.questionTitle);
  //     formData.append("is_doc_changed", 0);
  //     formData.append("procure_rfq_table", "procure_rfq_questions");

  //     redirectURL
  //       .post("/procurement/saverfqadditionaldetailsinfo", formData)
  //       .then((response) => {
  //         if (response.data.status === "success") {
  //           if (response.data.rfq_title) {
  //             this.state.questionsArr = this.state.questionsArr.filter(
  //               (obj) => obj.rfq_questions_title != response.data.rfq_title
  //             );
  //             let questionsArr = [
  //               {
  //                 rfq_questions_title: response.data.rfq_title,
  //               },
  //             ];
  //             this.setState({
  //               questionsArr: [...this.state.questionsArr, ...questionsArr],
  //             });
  //           }
  //         }
  //       });
  //   } else {
  //     this.setState({
  //       show: true,
  //       basicType: "danger",
  //       basicTitle: "Fields cannot be empty",
  //     });
  //   }
  // }

  onSubmitConfigurations = (e) => {
    e.preventDefault();
    localStorage.removeItem("rfq_event_id");
    this.setState({ isIdGenerated: false });
  };

  onCloseBidPreviewSlider = () => {
    this.setState({
      sliderTranslate: "",
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onClickHideAll() {
    // var uploadfilebulk = $("#uploadfile").val(null)
    this.setState({
      loadshow: "show-n",
      overlay: "show-n",
      slideuploadeway: "",
      sliderRouteTranslate: "",
      sliderSegmentTranslate: "",
      tabsliderTranslate: "",
      sliderTranslatesidebar: "",
      sliderForceCloseTranslate: "",
      sliderCommentTranslate: "",
      bulkPrioritySlide: "",
      sliderCreateConsignmentTranslate: "",
      sliderRso: "",
      // uploadfilebulk:uploadfilebulk
    });
  }

  // changeFileHandlerFc = async (e) => {
  //   var dt = "";
  //   // const config = {
  //   // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
  //   // }

  //   const config = {
  //     headers: [
  //       {
  //         name: "ItemCode",
  //         inputName: "rfq_item_code",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "ItemProductService",
  //         inputName: "rfq_item",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "TargetPrice",
  //         inputName: "rfq_item_target_price",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "Quantity",
  //         inputName: "rfq_item_quantity",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "UOM",
  //         inputName: "rfq_item_uom",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "Description",
  //         inputName: "rfq_item_description",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "DeliveryLocation",
  //         inputName: "rfq_item_delivery_location",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },

  //       {
  //         name: "TAT",
  //         inputName: "rfq_item_tat_days",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "Remarks",
  //         inputName: "rfq_item_remarks",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "PONo",
  //         inputName: "rfq_item_po_number",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "VendorName",
  //         inputName: "rfq_item_search_vendor_name",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "UnitRate",
  //         inputName: "rfq_item_unit_rate",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "PODate",
  //         inputName: "rfq_item_po_date",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //       {
  //         name: "POValue",
  //         inputName: "rfq_item_po_value",
  //         required: true,
  //         requiredError: function (headerName, rowNumber, columnNumber) {
  //           return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
  //         },
  //       },
  //     ],
  //   };
  //   var data = CSVFileValidator(e.target.files[0], config)
  //     .then((csvData) => {
  //       this.setState({
  //         csvcontent: csvData.data,
  //       });
  //     })
  //     .catch((err) => {});
  //   var contentList = [];
  //   try {
  //     var out = new Promise(function (reject, resolve) {
  //       var reader = new FileReader();
  //       if (reader) {
  //         reader.onload = async function (e) {
  //           var contents = await e.target.result;
  //           resolve(contents);
  //         };
  //         if (e.target.files[0]) {
  //           var tt = reader.readAsText(e.target.files[0]);
  //         }
  //       }
  //     });
  //     this.setState({
  //       file: e.target.files[0],
  //     });
  //     if (e.target.files[0]) {
  //       if (
  //         e.target.files[0].type == "text/csv" ||
  //         e.target.files[0].type == "application/vnd.ms-excel"
  //       ) {
  //         // console.log(fileData);
  //         // console.log(typeof(fileData))
  //       } else {
  //         e.target.value = null;
  //         this.setState({
  //           uploadFile: "",
  //           file: "",
  //           show: true,
  //           basicType: "danger",
  //           basicTitle: "Please upload file having extensions .csv only.",
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     console.log("errrrrror ==>", e);
  //   }
  // };

  // onSubmitBulkUpload = async (event) => {
  //   event.preventDefault();

  //   this.setState({
  //     loadshow: "show-m",
  //     overly: "show-m",
  //   });

  //   if (this.state.csvcontent) {
  //     if (this.state.csvcontent.length > 0) {
  //       var duplicateitemcode = [];
  //       var duplicatetruckno = [];
  //       var po_date_flag = 0;
  //       var customermobilenumber = [];
  //       var specialCharcters = 0;
  //       var specialCharcters1 = 0;
  //       var numberlength = true;
  //       this.state.csvcontent.map((em, i) => {
  //         if (em.rfq_item_code) {
  //           duplicateitemcode.push(em.rfq_item_code);
  //         }

  //         var podate = em.rfq_item_po_date;
  //         if (podate.indexOf("/") > 0) {
  //           podate = podate.split("/");
  //           if (
  //             podate[0].length !== 2 ||
  //             podate[1].length !== 2 ||
  //             podate[2].length !== 4
  //           ) {
  //             po_date_flag = 1;
  //           }

  //           if (po_date_flag == 0) {
  //             em.rfq_item_po_date =
  //               podate[2] + "-" + podate[1] + "-" + podate[0];
  //           }
  //         } else if (podate.indexOf("-") > 0) {
  //           podate = podate.split("-");
  //           if (
  //             podate[0].length !== 2 ||
  //             podate[1].length !== 2 ||
  //             podate[2].length !== 4
  //           ) {
  //             po_date_flag = 1;
  //           }
  //           if (po_date_flag == 0) {
  //             em.rfq_item_po_date =
  //               podate[2] + "-" + podate[1] + "-" + podate[0];
  //           }
  //         } else {
  //         }
  //       });

  //       duplicateitemcode.map((e) => {
  //         if (e.match(/[^a-zA-Z0-9]/g)) {
  //           specialCharcters1 = 1;
  //         }
  //       });

  //       var uniqueCheck = checkIfArrayIsUnique(duplicateitemcode);
  //       var rfq_event_id = localStorage.getItem("rfq_event_id");

  //       var params = {
  //         csvcontent: JSON.stringify(this.state.csvcontent),
  //       };

  //       params.rfq_event_id = rfq_event_id;
  //       await redirectURL
  //         .post("/procurement/savebulkrfqitemdetailsinfo", params)
  //         .then(async (response) => {
  //           if (response.data.eventItemData) {
  //             let eventItemData = response.data.eventItemData;
  //             let latestEventItemData = eventItemData[eventItemData.length - 1];
  //             this.setState({
  //               rfqItem: latestEventItemData.rfq_item,
  //               rfqItemDesc: latestEventItemData.rfq_item_description,
  //               rfqItemQuantity: latestEventItemData.rfq_item_quantity,
  //               rfqItemRemarks: latestEventItemData.rfq_item_remarks,
  //               rfqItemCode: latestEventItemData.rfq_item_code,
  //               rfqItemUOM: {
  //                 value: latestEventItemData.rfq_item_uom,
  //                 label: latestEventItemData.rfq_item_uom,
  //               },
  //               rfqTargetPrice: latestEventItemData.rfq_item_target_price,
  //               deliveryLocation:
  //                 latestEventItemData.rfq_item_delivery_location,
  //               tatDays: latestEventItemData.rfq_item_tat_days,
  //               poNumber: latestEventItemData.rfq_item_po_number,
  //               poDate: moment(latestEventItemData.rfq_item_po_date).format(
  //                 "DD/MM/YYYY hh:mm A"
  //               ),
  //               searchVendorName: {
  //                 value: latestEventItemData.rfq_item_search_vendor_name,
  //                 label: latestEventItemData.rfq_item_search_vendor_name,
  //               },
  //               unitRate: latestEventItemData.rfq_item_unit_rate,
  //               poValue: latestEventItemData.rfq_item_po_value,
  //               rowPOData: [...eventItemData],
  //             });
  //           }

  //           this.setState({
  //             loadshow: "show-n",
  //             overlay: "show-n",
  //             slideuploadeway: "",
  //             sliderRouteTranslate: "",
  //             sliderSegmentTranslate: "",
  //             tabsliderTranslate: "",
  //             sliderTranslatesidebar: "",
  //             sliderForceCloseTranslate: "",
  //             sliderCommentTranslate: "",
  //             bulkPrioritySlide: "",
  //             sliderCreateConsignmentTranslate: "",
  //             sliderRso: "",
  //           });
  //         });
  //     }
  //   } else {
  //     this.setState({
  //       show: true,
  //       basicTitle: "Choose file to continue",
  //       basicType: "danger",
  //       loadshow: "show-n",
  //       overly: "show-n",
  //     });
  //   }
  // };

  handleEventSubmitBtnClick() {
    const formData = new FormData();
    // formData.append("rfq_event_id", this.state.rfqEventId);
    // formData.append("rfq_event_remarks", this.state.finalRemarks);

    // redirectURL
    //   .post("/procurement/submitrfqevent", formData)
    //   .then((response) => {
    //     if (response.data.status === "success") {
    //       alert(response.data.message);
    //       let initialState = this.initialState;
    //       localStorage.removeItem("rfq_event_id");
    //       this.setState({
    //         ...initialState,
    //       });
    //       this.setState(
    //         {
    //           sliderTranslate: "",
    //         },
    //         () => {
    //           this.onPageLoad();
    //         }
    //       );
    //     }
    //   });
  }

  showTabView(tab) {
    tab == "booking"
      ? this.setState({ tabActive: tab, showBookingData: "show-m" })
      : this.setState({ tabActive: tab, showBookingData: "show-n" });
  }
  onMoveToBookings(){
    
      this.setState({
        viewBookingDetails: "show-m",
        viewRouteForm: "show-n",
        viewProductDetailsForm: "show-n",
        activeStep: 1,
      });

  }
  onMoveToRoute(){
    if(this.state.activeStep == 1){
      console.log("active step is 1")
      if(!this.state.shipmentName || !this.state.shipmentMode || !this.state.shipmentReadyDate || !this.state.shipmentPO){
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Fields cannot be empty",
        });
      }else{
        let payload = {
          shipment_name: this.state.shipmentName,
          shipment_po: this.state.shipmentPO,
          shipment_ready_date: this.state.shipmentReadyDate,
          shipment_mode: this.state.shipmentMode,
          shipment_incoterms: this.state.incoTerms,
          shipment_hs_codes: this.state.shipmentHSCodes,
          shipment_type: this.state.Shipment_Type_CheckBoxes,
          shipment_quote: this.state.Quote_CheckBoxes,
          shipment_event_id: this.state.shipment_event_id,
        };
        if (this.state.isInputChanged) {
          redirectURL
            .post("/exim/savebookingdetails", payload)
            .then((resp) => {
              console.log("savebookingdetails", resp.data);
            })
            .catch((err) => {
              console.log("savebookingdetails", err);
            });
        }
        this.setState({
          viewBookingDetails: "show-n",
          viewRouteForm: "show-m",
          viewProductDetailsForm: "show-n",
          activeStep: 2
        })
      }
    }else{
      this.setState({
        viewBookingDetails: "show-n",
        viewRouteForm: "show-m",
        viewProductDetailsForm: "show-n",
        activeStep: 2,
      });
    }
  }
  onMoveToProduct(){
    if(this.state.activeStep == 1){
      console.log("active step is 1")
      if(!this.state.shipmentName || !this.state.shipmentMode || !this.state.shipmentReadyDate || !this.state.shipmentPO){
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Fields cannot be empty",
        });
      }else{
        let payload = {
          shipment_name: this.state.shipmentName,
          shipment_po: this.state.shipmentPO,
          shipment_ready_date: this.state.shipmentReadyDate,
          shipment_mode: this.state.shipmentMode,
          shipment_incoterms: this.state.incoTerms,
          shipment_hs_codes: this.state.shipmentHSCodes,
          shipment_type: this.state.Shipment_Type_CheckBoxes,
          shipment_quote: this.state.Quote_CheckBoxes,
          shipment_event_id: this.state.shipment_event_id,
        };
        if (this.state.isInputChanged) {
        redirectURL
          .post("/exim/savebookingdetails", payload)
          .then((resp) => {
            console.log("savebookingdetails", resp.data);
          })
          .catch((err) => {
            console.log("savebookingdetails", err);
          });
      }
        this.setState({
          viewBookingDetails: "show-n",
          viewRouteForm: "show-n",
          viewProductDetailsForm: "show-m",
          activeStep: 3
        })
      }
    }else if(this.state.activeStep == 2){
      let payload = {
        shipment_event_id: this.state.shipment_event_id,
        origin_consigner: this.state.originConsigner,
        origin_address1: this.state.originAddress1,
        origin_address2: this.state.originAddress2,
        origin_country: this.state.originCountry,
        origin_postalcode: this.state.originPostalCode,
        delivery_consignee: this.state.deliveryConsignee,
        delivery_address1: this.state.deliveryAddress1,
        delivery_address2: this.state.deliveryAddress2,
        delivery_country: this.state.deliveryCountry,
        delivery_postalcode: this.state.deliveryPostalCode,
      };
      if (this.state.isRouteInputChanged) {
        console.log("Route inputs are changed----------------------------------------------------------------------------")
        redirectURL
          .post("/exim/saveshipmentroutedetails", payload)
          .then((resp) => {
            console.log("resp.data", resp.data);
          })
          .catch((err) => {
            console.log("error message", err);
          });
      }
      this.setState({
        viewBookingDetails: "show-n",
        viewRouteForm: "show-n",
        viewProductDetailsForm: "show-m",
        activeStep: 3,
      });
    }
  }

  render() {
    // const columnWithDefs = [
    //   {
    //     headerName: "Item Code",
    //     field: "rfq_item_code",
    //     width: 100,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Item/Product/Service",
    //     field: "rfq_item",
    //     width: 170,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Target Price",
    //     field: "rfq_item_target_price",
    //     width: 110,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Quantity",
    //     field: "rfq_item_quantity",
    //     width: 75,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "UOM",
    //     field: "rfq_item_uom",
    //     width: 75,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Description",
    //     field: "rfq_item_description",
    //     width: 200,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Delivery Location",
    //     field: "rfq_item_delivery_location",
    //     width: 150,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "TAT",
    //     field: "rfq_item_tat_days",
    //     width: 50,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Remarks",
    //     field: "rfq_item_remarks",
    //     width: 100,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "PO No.",
    //     field: "rfq_item_po_number",
    //     width: 100,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Vendor Name",
    //     field: "rfq_item_search_vendor_name",
    //     width: 120,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "Unit Rate",
    //     field: "rfq_item_unit_rate",
    //     width: 100,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "PO Date",
    //     field: "rfq_item_po_date",
    //     width: 100,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    //   {
    //     headerName: "PO Value",
    //     field: "rfq_item_po_value",
    //     width: 100,
    //     hide: false,
    //     filter: "agSetColumnFilter",
    //     cellRenderer: "",
    //     resizable: true,
    //   },
    // ];
    const columnWithDefs = [
      {
        headerName: "Item Code",
        field: "rfq_item_code",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Item/Product/Service",
        field: "rfq_item",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Target Price",
        field: "rfq_item_target_price",
        width: 110,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Quantity",
        field: "rfq_item_quantity",
        width: 75,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "UOM",
        field: "rfq_item_uom",
        width: 75,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Description",
        field: "rfq_item_description",
        width: 200,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Delivery Location",
        field: "rfq_item_delivery_location",
        width: 150,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "TAT",
        field: "rfq_item_tat_days",
        width: 50,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Remarks",
        field: "rfq_item_remarks",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "PO No.",
        field: "rfq_item_po_number",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vendor Name",
        field: "rfq_item_search_vendor_name",
        width: 120,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Unit Rate",
        field: "rfq_item_unit_rate",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "PO Date",
        field: "rfq_item_po_date",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "PO Value",
        field: "rfq_item_po_value",
        width: 100,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
    ];
    const cellClassRules = {
      "cell-green": (params) => params.value == "Sent",
      "cell-red": (params) => params.value == "Not Sent",
    };

    const columnWithVendorDetails = [
      {
        headerName: "Vendor Id",
        field: "invite_vendor_id",
        width: 300,
        hide: true,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vendor Name",
        field: "invite_vendor_name",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        width: 400,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vendor Email",
        field: "invite_vendor_email",
        width: 380,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Invitation Status",
        cellClassRules: cellClassRules,
        field: "is_invitation_sent",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //     headerName: "Target Price",
      // 	field: "rfq_item_target_price",
      // 	width: 110,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
      // {
      //     headerName: "Quantity",
      // 	field: "rfq_item_quantity",
      // 	width: 75,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
    ];

    const columnWithVendorPreviewDetails = [
      {
        headerName: "Vendor Id",
        field: "invite_vendor_id",
        width: 250,
        hide: true,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vendor Name",
        field: "invite_vendor_name",
        width: 320,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Vendor Email",
        field: "invite_vendor_email",
        width: 250,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      {
        headerName: "Invitation Status",
        cellClassRules: cellClassRules,
        field: "is_invitation_sent",
        width: 170,
        hide: false,
        filter: "agSetColumnFilter",
        cellRenderer: "",
        resizable: true,
      },
      // {
      //     headerName: "Target Price",
      // 	field: "rfq_item_target_price",
      // 	width: 110,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
      // {
      //     headerName: "Quantity",
      // 	field: "rfq_item_quantity",
      // 	width: 75,
      // 	hide: false,
      // 	filter: "agSetColumnFilter",
      // 	cellRenderer: '',
      //     resizable: true
      // },
    ];

      return (
        <div className="container-fluid">
          <div className={"dataLoadpage " + this.state.loadshow}></div>
          <div className={"dataLoadpageimg " + this.state.loadshow}>
            <div className="loader-box">
              <div className="loader-box">
                <div className="rotate dashed colored"></div>
              </div>
            </div>
          </div>
          <div
            className={"overlay-part " + this.state.overlay}
            onClick={this.onClickHideAll.bind(this)}
          ></div>
          <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
          ></SweetAlert>

          {/* page top headers */}
          <div className="row">
            <div className="col-xl-12 col-md-12 ">
              <ul className={"nav navtabs"}>
                <li className={"nav-item"}>
                  <a
                    className={`nav-link ${
                      this.state.tabActive == "booking" ? "tab-active" : ""
                    }`}
                    aria-current="page"
                    onClick={this.showTabView.bind(this, "booking")}
                  >
                    Edit Booking Request
                  </a>
                </li>
                <li className={"nav-item"}>
                  <a
                    className={`nav-link ${
                      this.state.tabActive == "review" ? "tab-active" : ""
                    }`}
                    onClick={this.showTabView.bind(this, "review")}
                  >
                    Review Quotes <span className="circle">0</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* tracking header-sections */}
          <div className={"row " + this.state.showBookingData}>
            <div className="col-xl-12 col-md-12 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="collg-12 col-md-12">
                  <div className="row mx-4 mr-40p justify-content-between card-body">
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <h1 className="h5 font-weight-bold text-gray-800">
                        Booking Details
                      </h1>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mr-80p">
                      <h1 className="h5 font-weight-bold text-gray-800">Route</h1>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <h1 className="h5 font-weight-bold text-gray-800">
                        Product
                      </h1>
                    </div>
                  </div>
                  <div className="row mx-4 mb-3 pl-5 justify-content-between progressbar">
                    <div
                      className={`progress ${
                        this.state.activeStep == 2 ||
                        this.state.activeStep == 3 ||
                        this.state.activeStep == 4
                          ? "progress-step-active"
                          : ""
                      }`}
                      style={
                        this.state.activeStep == 2
                          ? { width: "45%" }
                          : this.state.activeStep == 3
                          ? { width: "90%" }
                          : {}
                      }
                    ></div>
                    <div
                      className={`progress-step ${
                        this.state.activeStep == 1 ||
                        this.state.activeStep == 2 ||
                        this.state.activeStep == 3 ||
                        this.state.activeStep == 4
                          ? "progress-step-active"
                          : ""
                      }`}
                      
                      onClick={this.onMoveToBookings.bind(this)}
                      ></div>
                    <div
                      className={`progress-step mr-4 ${
                        this.state.activeStep == 2 ||
                        this.state.activeStep == 3 ||
                        this.state.activeStep == 4
                          ? "progress-step-active"
                          : ""
                      }`}
                      onClick={this.onMoveToRoute.bind(this)}
                    ></div>
                    <div
                      className={`progress-step mr-5 ${
                        this.state.activeStep == 3 || this.state.activeStep == 4
                          ? "progress-step-active"
                          : ""
                      }`}
                      onClick={this.onMoveToProduct.bind(this)}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"row " + this.state.showBookingData}>
            <div className="col-xl-12 col-md-12 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="card-body">
                  <form
                    className="user"
                    onSubmit={this.onSubmitConfigurations.bind(this)}
                  >
                    {/* Booking details block HERE */}
                    <div className={" " + this.state.viewBookingDetails}>
                      {/* Shipment input area - Mandatory(shipmentName) - field to be filled */}
                      <div className="row form-group no-gutters align-items-center">
                        <div className="col-2 text-right pr-3">
                          <label>
                            Shipment Name <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-9">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="shipmentName"
                              value={this.state.shipmentName}
                              onChange={(e) =>
                                this.setState({
                                  shipmentName: e.target.value,
                                  isInputChanged: true,
                                })
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>

                      {/* Description text-area - Mandatory(shipmentPO) - field to be filled */}
                      <div className="row form-group no-gutters align-items-center">
                        <div className="col-2 text-right pr-3">
                          <label>
                            PO Numbers <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-9">
                          <div className="form-group">
                            <textarea
                              type="text"
                              className="form-control pb-4"
                              name="shipmentPO"
                              value={this.state.shipmentPO}
                              onChange={(e) =>
                                this.setState({
                                  shipmentPO: e.target.value,
                                  isInputChanged: true,
                                })
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>

                      {/* Ready-Date section - Mandatory(Booking ready date and Mode ) - field to be filled */}
                      <div className="row form-group no-gutters align-items-center">
                        <div className="col-2 text-right pr-3">
                          <label>
                            Ready Date <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <Datetime
                              name="shipmentReadyDate"
                              disableCloseOnClickOutside={false}
                              closeOnSelect={true}
                              isValidDate={(current) =>
                                current.isAfter(moment().subtract(1, "day"))
                              }
                              value={this.state.shipmentReadyDate}
                              // initialValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                              dateFormat="YYYY-MM-DD"
                              timeFormat="HH:mm:ss"
                              onChange={this.handleBookingStartDateTime.bind(
                                this
                              )}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-3 text-right pr-3">
                          <label>
                            Mode <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <Select
                              closeMenuOnSelect={true}
                              name="shipmentMode"
                              className="border-radius-0"
                              onChange={this.newFilter.bind(this, "shipmentMode")}
                              options={[
                                { value: "LCL", label: "LCL" },
                                { value: "FCL", label: "FCL" },
                              ]}
                              value={this.state.shipmentMode}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      {/* Inco-terms select box  */}
                      <div className="row form-group no-gutters align-items-center">
                        <div className="col-2 text-right pr-3">
                          <label>Incoterms</label>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <Select
                              closeMenuOnSelect={true}
                              name="incoTerms"
                              isMulti={true}
                              className="border-radius-0"
                              onChange={this.newFilter.bind(this, "incoTerms")}
                              options={[
                                { value: "CFR", label: "CFR" },
                                { value: "CIF", label: "CIF" },
                                { value: "CIP", label: "CIP" },
                                { value: "CPT", label: "CPT" },
                                { value: "DAP", label: "DAP" },
                                { value: "DDP", label: "DDP" },
                              ]}
                              value={this.state.incoTerms}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-3 text-right pr-3">
                          <label>HS Codes</label>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="shipmentHSCodes"
                              value={this.state.shipmentHSCodes}
                              onChange={(e) =>
                                this.setState({
                                  shipmentHSCodes: e.target.value,
                                  isInputChanged: true,
                                })
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>

                      {/* CheckBoxes section */}
                      <div className="row form-group no-gutters align-items-center group-checkboxes">
                        <div className="col-2 text-right pr-3">
                          <label>Commercial</label>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              name="commercial"
                              className="ml-1 my-4"
                              checked={
                                this.state.commercialShipment
                              }
                              onClick={this.commercialShipmentHandle.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-2 text-right pr-3">
                          <label>Hazardous</label>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              name="hazardous"
                              className="ml-1 my-4"
                              checked={
                                this.state.hazardousShipment
                              }
                              onClick={this.hazardousShipmentHandle.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-2 text-right pr-3">
                          <label>Quote Insurance</label>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              name="quoteInsurance"
                              className="ml-1 my-4"
                              checked={this.state.quoteInsurance}
                              onClick={this.quoteInsuranceHandle.bind(this)}
                            />
                          </div>
                        </div>

                        <div className="col-2 text-right pr-3">
                          <label>Quote Customs</label>
                        </div>
                        <div className="col-1">
                          <div>
                            <input
                              type="checkbox"
                              name="quoteCustoms"
                              className="ml-1 my-4"
                              checked={this.state.quoteCustoms}
                              onClick={this.quoteCustomHandle.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Booking-Details save and next button */}
                      <div className="row form-group no-gutters align-items-center mt-4">
                        <div className="col-12">
                          <div className="form-group text-center">
                            <button
                              type="button"
                              onClick={this.handleBookingNextBtnClick.bind(this)}
                              className="btn btn-info"
                            >
                              Save &amp; Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Route block HERE */}
                    <div className={" " + this.state.viewRouteForm}>
                      <div className="row px-5">
                        {/* Origin Consigner address block  */}
                        <div className="col-12 mb-20p">
                          <div className="bg-light p-3 mb-3">
                            <h5 className="mb-0">Origin Address</h5>
                          </div>
                          <div className="bg-white" style={{ padding: "49px 0" }}>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Consigner</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="originConsigner"
                                    value={this.state.originConsigner}
                                    onChange={(e) =>
                                      this.setState({
                                        originConsigner: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged
                                      })
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Address 1</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="originAddr1"
                                    value={this.state.originAddress1}
                                    onChange={(e) => {
                                      this.setState({
                                        originAddress1: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Address 2</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="originAddr2"
                                    value={this.state.originAddress2}
                                    onChange={(e) => {
                                      this.setState({
                                        originAddress2: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>City, Country</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="originCountry"
                                    value={this.state.originCountry}
                                    onChange={(e) => {
                                      this.setState({
                                        originCountry: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Postcode</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="originPostalCode"
                                    value={this.state.originPostalCode}
                                    onChange={(e) => {
                                      this.setState({
                                        originPostalCode: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Delivery Consignee address block */}
                        <div className="col-12">
                          <div className="bg-light p-3 mb-3">
                            <h5 className="mb-0">Delivery Address</h5>
                          </div>
                          <div className="bg-white p-3">
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Consignee</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="deliveryConsigne"
                                    value={this.state.deliveryConsignee}
                                    onChange={(e) => {
                                      this.setState({
                                        deliveryConsignee: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Address 1</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="deliveryAddr1"
                                    value={this.state.deliveryAddress1}
                                    onChange={(e) => {
                                      this.setState({
                                        deliveryAddress1: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Address 2</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="deliveryAddr2"
                                    value={this.state.deliveryAddress2}
                                    onChange={(e) => {
                                      this.setState({
                                        deliveryAddress2: e.target.value,
                                        isRouteInputChanged: !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>City, Country</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="deliveryCountry"
                                    value={this.state.deliveryCountry}
                                    onChange={(e) => {
                                      this.setState({
                                        deliveryCountry: e.target.value,
                                        isRouteInputChanged:
                                          !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-group no-gutters align-items-center">
                              <div className="col-1 text-right">
                                <label>Postcode</label>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-9">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="deliveryPostalCode"
                                    value={this.state.deliveryPostalCode}
                                    onChange={(e) => {
                                      this.setState({
                                        deliveryPostalCode: e.target.value,
                                        isRouteInputChanged:
                                          !this.state.isRouteInputChanged,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group no-gutters align-items-center mt-4">
                        <div className="col-12">
                          <div className="form-group text-center">
                            <button
                              type="button"
                              onClick={this.handleRouteBackBtnClick.bind(this)}
                              className="btn btn-secondary mr-2"
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              onClick={this.handleRouteNextBtnClick.bind(this)}
                              className="btn btn-info"
                            >
                              Save &amp; Next
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        className={"slide-r " + this.state.sliderRso}
                        style={{ overflow: "auto" }}
                      >
                        <div className="slide-r-title">
                          <h4>
                            Bulk Upload
                            <span
                              className="float-right closebtn"
                              style={{ marginRight: "25px" }}
                              onClick={this.onClickHideAll.bind(this)}
                            >
                              X
                            </span>
                          </h4>
                        </div>
                        <div
                          className="slide-r-body"
                          style={{ position: "relative" }}
                        >
                          <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                          <div
                            className={"row"}
                            style={{
                              padding: "5px 0px",
                              width: "100%",
                              zIndex: "9999",
                            }}
                          >
                            <div className="theme-form col-xl-12 col-lg-12">
                              <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">
                                  Upload File *
                                </label>
                                <input
                                  type="file"
                                  name="uploadfile"
                                  id="uploadfile"
                                  className="form-control"
                                  onChange={this.changeFileHandlerFc.bind(this)}
                                  required
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 form-group">
                                <button
                                  type="button"
                                  onClick={this.onSubmitBulkUpload.bind(this)}
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                              <div
                                className="col-xl-12 col-lg-12 form-group"
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                Date format should be in dd-mm-yyyy hh:mm:ss
                              </div>
                              <div className="col-xl-12 col-lg-12 form-group">
                                <a
                                  href={require("../../assets/json/sample_upload_items_data.csv")}
                                  target="_blank"
                                  className="btn btn-warning"
                                >
                                  Download Template
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    {/* Product block HERE */}
                    <div className={" " + this.state.viewProductDetailsForm}>
                      <div className="row px-5">
                        <div className="col-4 mb-30p">
                          <button
                            className="btn btn-info"
                            onClick={this.handleAddBlockForProductInfo.bind(this)}
                          >
                            Add Another Product +
                          </button>
                        </div>
                      </div>
                      {/* { productInfoDivs } */}
                      {this.state.allProductsData.map((each, outerIndex) => {
                        return (
                          <React.Fragment key={outerIndex}>
                            <div className="col-12 mb-20p">
                              <div className="row bg-light p-3 mb-3">
                                <div className="col-3">
                                  <h5 className="pt-15px">
                                    <label
                                      htmlFor=""
                                      className="mt-2"
                                      style={{ color: "dimgray", fontWeight: 800 }}
                                    >
                                      {each.productName === ""
                                        ? "Product"
                                        : each.productName}
                                    </label>
                                  </h5>
                                </div>
                              </div>
                              <div className="bg-white" style={{ padding: "49px 0" }}>
                                {/* name */}
                                <div className="row form-group no-gutters align-items-center">
                                  <div className="col-2 text-right pr-3">
                                    <label>Name</label>
                                  </div>
                                  <div className="col-9">
                                    <div className="form-group">
                                      { each._id === "" ? <input
                                        type="text"
                                        className="form-control"
                                        name="productName"
                                        value={each.productName}
                                        onChange={(e) => {
                                          this.handleOnChangeForProducts(
                                            outerIndex,
                                            "productName",
                                            e.target.value
                                          );
                                        }}
                                      /> : each.productName }
                                     
                                    </div>
                                  </div>
                                </div>
                                {/* description */}
                                <div className="row form-group no-gutters align-items-center">
                                  <div className="col-2 text-right pr-3">
                                    <label>Description</label>
                                  </div>
                                  <div className="col-9">
                                    <div className="form-group">
                                      <textarea
                                        type="text"
                                        className="form-control pb-4"
                                        name="productDescription"
                                        value={each.productDescription}
                                        onChange={(e) => {
                                          this.handleOnChangeForProducts(
                                            outerIndex,
                                            "productDescription",
                                            e.target.value
                                          );
                                        }}
                                        
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Product type select and Weight*/}
                                <div className="row form-group no-gutters align-items-center">
                                  <div className="col-2 text-right pr-3">
                                    <label>Product Type</label>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <Select
                                        closeMenuOnSelect={true}
                                        name="productType"
                                        className="border-radius-0"
                                        styles={{
                                          control: (provided, state) => ({
                                            ...provided,
                                            fontSize: "0.8rem",
                                            color: "#6e707e",
                                          }),
                                        }}
                                        value={each.productType}
                                        onChange={this.productFilter.bind(
                                          this,
                                          "productType",
                                          outerIndex
                                        )}
                                        
                                        options={[
                                          { value: "LCL", label: "LCL" },
                                          { value: "FCL", label: "FCL" },
                                        ]}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3 text-right pr-3">
                                    <label>Weight</label>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="productWeight"
                                        value={each.productWeight}
                                        onChange={(e) => {
                                          this.handleOnChangeForProducts(
                                            outerIndex,
                                            "productWeight",
                                            e.target.value
                                          );
                                        }}                                     
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Value, Length */}
                                <div className="row form-group no-gutters align-items-center">
                                  <div className="col-2 text-right pr-3">
                                    <label>Value</label>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="productValue"
                                        value={each.productValue}
                                        onChange={(e) => {
                                          this.handleOnChangeForProducts(
                                            outerIndex,
                                            "productValue",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3 text-right pr-3">
                                    <label>Length</label>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="productLength"
                                        value={each.productLength}
                                        onChange={(e) => {
                                          this.handleOnChangeForProducts(
                                            outerIndex,
                                            "productLength",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* , Width, Height */}
                                <div className="row form-group no-gutters align-items-center">
                                  <div className="col-2 text-right pr-3">
                                    <label>Width</label>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="productWidth"
                                        value={each.productWidth}
                                        onChange={(e) => {
                                          this.handleOnChangeForProducts(
                                            outerIndex,
                                            "productWidth",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3 text-right pr-3">
                                    <label>Height</label>
                                  </div>
                                  <div className="col-3">
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="productHeight"
                                        value={each.productHeight}
                                        onChange={async (e) => {
                                          this.handleOnChangeForProducts(
                                            outerIndex,
                                            "productHeight",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Cubic Meters */}
                                <div className="row form-group no-gutters align-items-center">
                                  <div className="col-2 text-right pr-3">
                                    <label>Cubic Meters</label>
                                  </div>
                                  <div className="col-7 ">
                                    <label htmlFor="">{each.productCbm} m3</label>
                                  </div>
                                </div>
                              </div>
                              <div
                              className="row justify-content-end"
                              style={{ background: "#ededed" }}
                            >
                              <button
                                className="btn btn-primary my-2"
                                onClick={this.removeProductAndUpdateCounters.bind(
                                  this,
                                  outerIndex
                                )}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Cancel
                                </label>
                              </button>
                              <button
                                className="btn btn-primary m-2 px-3"
                                // onClick={this.handleContainerDetails}
                                onClick={this.saveAndUpdateProductData.bind(
                                  this,
                                  outerIndex
                                )}
                              >
                                <label
                                  htmlFor=""
                                  role="button"
                                  className="mb-0"
                                >
                                  Save
                                </label>
                              </button>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}

                      <div className="row form-group no-gutters align-items-center mt-4">
                        <div className="col-12">
                          <div className="form-group text-center">
                            <button
                              type="button"
                              onClick={this.handleProductBackBtnClick.bind(this)}
                              className="btn btn-secondary mr-2"
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              onClick={this.handleProductPreviewBtn.bind(this)}
                              className="btn btn-info"
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* preview block HERE */}
                    <div
                      className={"slide-r " + this.state.sliderTranslate}
                      style={{ overflow: "auto" }}
                    >
                      <div className="slide-r-title">
                        <h4
                          className="h4 row align-items-center justify-content-between ml-2"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Bookings{" "}
                          <span
                            className="float-right closebtn mr-5"
                            style={{ marginRight: "25px" }}
                            onClick={this.onCloseBidPreviewSlider.bind(this)}
                          >
                            X
                          </span>
                        </h4>
                      </div>
                      <div
                        className="slide-r-body"
                        style={{ position: "relative" }}
                      >
                        <div className="col-xl-12 col-lg-12">
                          <div className="card shadow h-100">
                            <div className="card-body">
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <h1 className="h5 text-gray-800 font-weight-bold">
                                  Booking Details
                                </h1>
                              </div>
                              <div className="p-3 border">
                                <div className="col">
                                  <div className="row pl-2">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                      Shipment :
                                    </label>
                                    <label className="h6 text-black-700">
                                      {this.state.shipmentName}
                                    </label>
                                  </div>
                                  <div className="row pl-2">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                      Booking PO :
                                    </label>
                                    <label className="h6 text-black-700">
                                      {this.state.shipmentPO}
                                    </label>
                                  </div>
                                </div>
                                <div className="row align-items-center justify-content-between mt-4">
                                  <div
                                    className="col-5"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        Event ID :
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.shipment_event_id}
                                      </label>
                                    </div>
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        incoTerms :
                                      </label>
                                      {this.state.incoTerms?.map((each) => {
                                        const { value, label } = each;
                                        if (
                                          value !== "" &&
                                          this.state.incoTerms.includes(each)
                                        ) {
                                          return (
                                            <label className="h6 text-black-700">
                                              {label} &nbsp{" "}
                                            </label>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        Shipment Start Date
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.shipmentReadyDate}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col ml-2 mt-4">
                                  <div className="row">
                                    <div className="col">
                                      <div className="row">
                                        <label className="h6 text-black-700 font-weight-bold mr-2">
                                          Mode Type :
                                        </label>
                                        <label className="h6 text-black-700">
                                          {this.state.shipmentMode?.value}
                                        </label>
                                      </div>
                                      <div className="row">
                                        <label className="h6 text-black-700 font-weight-bold mr-2">
                                          HS Codes :
                                        </label>
                                        <label className="h6 text-black-700">
                                          {this.state.shipmentHSCodes}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col ml-2 mt-4">
                                  <div className="row">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                      Shipment Type : {
                                        this.state.commercialShipment && <>Commerical</>
                                      }{
                                        this.state.hazardousShipment && <>Hazardous</>
                                      }
                                    </label>
                                    
                                    <label className="h6 text-black-700">
                                      
                                    </label>
                                  </div>
                                  <div className="row">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                        Quote From : {this.state.quoteInsurance && <>Insurance</>}{this.state.quoteCustoms && this.state.quoteInsurance && ","}{this.state.quoteCustoms && <>Customs</>}
                                    </label>
                                    
                                    <label className="h6 text-black-700">
                                      
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                          <div className="card shadow h-100">
                            <div className="card-body">
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <h1 className="h5 text-gray-800 font-weight-bold">
                                  Origin Address
                                </h1>
                              </div>
                              <div className="p-3 border">
                                <div className="col">
                                  <div className="row pl-2">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                      Consigner :
                                    </label>
                                    <label className="h6 text-black-700">
                                      {this.state.originConsigner}
                                    </label>
                                  </div>
                                  <div className="row pl-2">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                      Address1 :
                                    </label>
                                    <label className="h6 text-black-700">
                                      {this.state.originAddress1}
                                    </label>
                                  </div>
                                </div>
                                <div className="row align-items-center justify-content-between mt-4">
                                  <div
                                    className="col-5"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        Address2 :
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.originAddress2}
                                      </label>
                                    </div>
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        City, Country :
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.originCountry}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        Postal Code :
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.originPostalCode}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <h1 className="h5 text-gray-800 font-weight-bold">
                                  Delivery Address
                                </h1>
                              </div>
                              <div className="p-3 border">
                                <div className="col">
                                  <div className="row pl-2">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                      Consignee :
                                    </label>
                                    <label className="h6 text-black-700">
                                      {this.state.deliveryConsignee}
                                    </label>
                                  </div>
                                  <div className="row pl-2">
                                    <label className="h6 text-black-700 font-weight-bold mr-2">
                                      Address1 :
                                    </label>
                                    <label className="h6 text-black-700">
                                      {this.state.deliveryAddress1}
                                    </label>
                                  </div>
                                </div>
                                <div className="row align-items-center justify-content-between mt-4">
                                  <div
                                    className="col-5"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        Address2 :
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.deliveryAddress2}
                                      </label>
                                    </div>
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        City, Country :
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.deliveryCountry}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="row">
                                      <label className="h6 text-black-700 font-weight-bold mr-2">
                                        Postal Code
                                      </label>
                                      <label className="h6 text-black-700">
                                        {this.state.deliveryPostalCode}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Grid display */}
                        <div className="col-xl-12 col-lg-12 mt-3">
                          <div className="card shadow h-100">
                            <div className="card-body">
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <h1 className="h5 text-gray-800 font-weight-bold">
                                  Products Details
                                </h1>
                              </div>
                              <div className="p-3 border">
                                <div className="row justify-content-between m-2 px-3">
                                  <div
                                    className="col-2 text-center"
                                    style={{
                                      backgroundColor: "#ededed",
                                      borderRadius: "10px",
                                      color: "gray",
                                    }}
                                  >
                                    <div style={{ paddingTop: "5px" }}>
                                      <label htmlFor="">
                                        {this.state.allProductsData.length}
                                      </label>
                                    </div>
                                    <div>
                                      <label htmlFor="">Products</label>
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 text-center"
                                    style={{
                                      backgroundColor: "#ededed",
                                      borderRadius: "10px",
                                      color: "gray",
                                    }}
                                  >
                                    <div style={{ paddingTop: "5px" }}>
                                      <label htmlFor="">{this.state.weightsCount} Kgs</label>
                                    </div>
                                    <div>
                                      <label htmlFor="">Weight</label>
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 text-center"
                                    style={{
                                      backgroundColor: "#ededed",
                                      borderRadius: "10px",
                                      color: "gray",
                                    }}
                                  >
                                    <div style={{ paddingTop: "5px" }}>
                                      <label htmlFor="">
                                        {this.state.cbmCount} m
                                        <label
                                          style={{
                                            position: "relative",
                                            // right: "6px",
                                            bottom: "5px",
                                            fontSize: "9px",
                                          }}
                                        >
                                          {" "}
                                          3
                                        </label>
                                      </label>
                                    </div>
                                    <div style={{ marginTop: "-8px" }}>
                                      <label htmlFor="" className="mb-2 pr-1">
                                        CBM
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 text-center"
                                    style={{
                                      backgroundColor: "#ededed",
                                      borderRadius: "10px",
                                      color: "gray",
                                    }}
                                  >
                                    <div style={{ paddingTop: "5px" }}>
                                      <label htmlFor="">$ {this.state.valueCount}</label>
                                    </div>
                                    <div>
                                      <label htmlFor="">Value</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-3">
                                <div className="row justify-content-between m-2 px-3">
                                    <div className="col-4">
                                      <h3>Product</h3>
                                    </div>
                                    <div>
                                    {this.state.allProductsData.map((product) => (
                                      <div key={product._id}>
                                        <h4>Product Name: {product.productName}</h4>
                                        <p>Type: {product.productType.value}</p>
                                        <p>Weight: {product.productWeight}</p>
                                        <p>Height: {product.productHeight}</p>
                                        <p>Length: {product.productLength}</p>
                                        <p>Width: {product.productWidth}</p>
                                        <p>Value: {product.productValue}</p>
                                        <p>CBM: {product.productCbm}</p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-xl-12 col-lg-12">
                              <div className="card shadow h-100">
                                  <div className='card-body'>
                                      <div className="d-sm-flex align-items-center justify-content-between">
                                          <h1 className="h5 text-gray-800 font-weight-bold">Additional Details</h1>
                                      </div>
                                      <div className='p-3 border'>
                                          <div className='col'>
                                              <div className='row pl-2'>
                                                  <label className='h6 text-black-700 font-weight-bold mr-2'>Commercial Terms :</label>
                                                  <table className="table table-bordered tptinfo">
                                                      <thead>
                                                          <tr>
                                                              <th style={{width:"20%"}}><p>File Name</p></th> 
                                                              <th><p>File URL</p></th> 
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          {
                                                              this.state.commercialTermsDocArr.map(function (feature, i) {
                                                              return <tr key={`commercialTermsTitle-${i}`}>
                                                                      <td key={`commercialTermsTitle-${i}`}>{feature.rfq_commercial_terms_title}</td>
                                                                      <td key={`commercialTermsUrl-${i}`}>{feature.rfq_commercial_terms_doc}</td>
                                                                  </tr>
                                                              })
                                                          }
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className='col'>
                                              <div className='row pl-2'>
                                                  <label className='h6 text-black-700 font-weight-bold mr-2'>Attachments :</label>
                                                  <table className="table table-bordered tptinfo">
                                                      <thead>
                                                          <tr>
                                                              <th style={{width:"20%"}}><p>Attachment Name</p></th> 
                                                              <th><p>Attachment URL</p></th> 
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          {
                                                              this.state.attachmentsDocArr.map(function (feature, i) {
                                                              return <tr key={`attachmentsTitle-${i}`}>
                                                                      <td key={`attachmentsTitle-${i}`}>{feature.rfq_attachments_title}</td>
                                                                      <td key={`attachmentsUrl-${i}`}>{feature.rfq_attachments_doc}</td>
                                                                  </tr>
                                                              })
                                                          }
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div className='col'>
                                              <div className='row pl-2'>
                                                  <label className='h6 text-black-700 font-weight-bold mr-2'>Questions :</label>
                                                  <table className="table table-bordered tptinfo">
                                                      <thead>
                                                          <tr>
                                                              <th><p>Question</p></th> 
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          {
                                                              this.state.questionsArr.map(function (feature, i) {
                                                                return <tr key={`questionsTitle-${i}`}>
                                                                        <td key={`questionsTitle-${i}`}>{feature.rfq_questions_title}</td>
                                                                    </tr>
                                                                }
                                                              )
                                                          }
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div> */}

                        {/* grid display */}
                        {/* <div className="col-xl-12 col-lg-12 mt-3">
                              <div className="card shadow h-100">
                                  <div className='card-body'>
                                      <div className="d-sm-flex align-items-center justify-content-between">
                                          <h1 className="h5 text-gray-800 font-weight-bold">Participants Details</h1>
                                      </div>
                                      <div className='p-3 border'>
                                          <div className={" "+(this.state.viewParticipantsGrid)}>
                                              <div className={"row "}>
                                                  <div className="col-xl-12 col-md-12 mb-4">
                                                      <div className="card shadow h-100 py-2">
                                                          <div className="card-body">
                                                              <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                                              <AgGridReact 
                                                                  rowData={this.state.rowVendorData} 
                                                                  columnDefs={columnWithVendorPreviewDetails}
                                                                  defaultColDef ={this.state.defaultColDef }
                                                                  enableRangeSelection={true}
                                                                  enableCharts={true}
                                                                  detailCellRendererParams={this.state.detailCellRendererParams}
                                                                  masterDetail={true}
                                                                  onGridReady={this.onGridReady}
                                                                  sideBar={this.state.sideBar}
                                                                  // frameworkComponents={this.state.frameworkComponents}
                                                                  // statusBar={this.state.statusBar}
                                                                  stopEditingWhenGridLosesFocus= {true}
                                                                  paginationPageSize={this.state.paginationPageSize}
                                                                  pagination={true}
                                                                  gridOptions={{
                                                                      context: { componentParent: this }
                                                                  }}     
                                                              />
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className='row m-2'>
                                              <label className='h6 text-gray-900'>Remarks</label>
                                              <textarea
                                                  type="text" 
                                                  className="form-control pb-4" 
                                                  name="finalRemarks"
                                                  value={this.state.finalRemarks}
                                                  onChange={(e) => this.setState({finalRemarks: e.target.value})}
                                              />
                                          </div>
                                          <div className="row form-group no-gutters align-items-center mt-4">
                                              <div className="col-12">
                                                  <div className="row align-items-center justify-content-center form-group text-center">
                                                      <button type="submit" className="btn btn-primary mr-2" onClick={this.handleEventSubmitBtnClick.bind(this)}>Submit</button>
                                                      <button type='button' className='btn btn-success mr-2' onClick={this.handleExportExcelBtnClick.bind(this)}>Export To Excel</button>
                                                      <button type="button" onClick={this.onCloseBidPreviewSlider.bind(this)}className="btn btn-secondary mr-2">Close</button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

function checkIfArrayIsUnique(myArray) {
  return myArray.length === new Set(myArray).size;
}

export default withRouter(ShipmentDetails);
